import { DeductionDTO, DeductionStatus } from '../../../api/origin/vendor-accounting-service'

export const sortDeductions = (deductions: DeductionDTO[]): DeductionDTO[] => {
  if (!deductions) {
    return []
  }

  const sortByCreatedAtDate = (_deductions: DeductionDTO[]): DeductionDTO[] => {
    return _deductions.sort((a, b) => {
      if (!a.createdAt) {
        return -1
      }

      if (!b.createdAt) {
        return 1
      }

      return Date.parse(a.createdAt) - Date.parse(b.createdAt)
    })
  }

  const closedDeductions = sortByCreatedAtDate(deductions.filter(({ status }) => status === DeductionStatus.CLOSED))
  const completedDeductions = sortByCreatedAtDate(
    deductions.filter(({ status }) => status === DeductionStatus.COMPLETED)
  )
  const otherDeductions = sortByCreatedAtDate(
    deductions.filter(({ status }) => ![DeductionStatus.CLOSED, DeductionStatus.COMPLETED].includes(status))
  )

  return closedDeductions.concat(completedDeductions).concat(otherDeductions)
}
