import * as R from 'remeda'
import { ContainerViewDTO } from '../../../../../api/api'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'

export const parseContainers = async (containers: ContainerViewDTO[]): Promise<TListsStateUpdate> => {
  const result = generateEmptyListsState()

  try {
    containers.forEach(container => {
      if (container.steamShipLine) {
        // @ts-ignore
        result[EntityType.steamShipLine].push(container.steamShipLine)
      }

      result[EntityType.container].push(R.omit(container, ['steamShipLine']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
