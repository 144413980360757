import { NotificationDTO } from '../../../api/origin/communication-hub-service'
import { INotificationFilters } from '../../../store/reducers/communicationHub/interfaces'
import { debuggingMode } from '../../debug'
import { testNitification } from '../test/testNitification'

type Props = {
  notifications: NotificationDTO[]
  filters: INotificationFilters
}

export const filterNotifications = (props: Props): NotificationDTO[] => {
  const { filters, notifications } = props

  return notifications.filter(notification => {
    const testNotificationResult = testNitification({ notification, filters })

    if (debuggingMode.common) {
      // tslint:disable-next-line:no-console
      console.log('Test Notification', { filters, notification })
      // tslint:disable-next-line:no-console
      console.log('Result', testNotificationResult.testProps)
    }

    return testNotificationResult.isPass
  })
}
