import { TransportationActivityViewDTO } from '../../../api/api'
import { ActivityGroup } from '../../DTO/activity/interfaces'
import { getGroupedActivitiesByGroupId } from '../get/getGroupedActivitiesByGroupId'
import { isBobtailGotoActivity, isDropBobtailGotoActivity, isPickBobtailGotoActivity } from './isBobtailGotoActivity'
import { isUnsuccessfulActivity } from './isUnsuccessfulActivity'

export const isMoreThanOneNextToBobtails = (
  transportationActivities: TransportationActivityViewDTO[],
  bobtailType?: 'drop' | 'pick'
): boolean => {
  const groupedActivitiesByGroupId = getGroupedActivitiesByGroupId(transportationActivities)
  const isActualBobtail = (group: ActivityGroup) => {
    return (
      group &&
      'gotoActivity' in group &&
      !isUnsuccessfulActivity(group.gotoActivity) &&
      (bobtailType === 'drop'
        ? isDropBobtailGotoActivity(group.gotoActivity)
        : bobtailType === 'pick'
        ? isPickBobtailGotoActivity(group.gotoActivity)
        : isBobtailGotoActivity(group.gotoActivity))
    )
  }

  return groupedActivitiesByGroupId.some((activityGroup, index, array) => {
    return isActualBobtail(activityGroup) && isActualBobtail(array[index + 1])
  })
}
