import * as React from 'react'
import cn from 'classnames'
import { Container, StyledInput, ClearButton } from '../styles'
import { isHighlighted, getStringValue } from '../functions'
import { FieldContainer } from '../../FieldContainer'
import { useRegExp } from '../../../../../services/functions/regExp'
import { Whitespace } from '../../../../../services/keyboardService/keys'

export type TProps = {
  id?: string
  dataId?: string
  disabled?: boolean
  title?: string
  value: any
  onChange: (value: any) => void
  onClear: () => void
  required?: boolean
  requiredMarker?: boolean
  valid?: boolean
  highlighted?: boolean
  placeholder?: string
  filled: boolean
  maxLength: number
  minLength: number
  inputContainerStyle?: any
  regExp?: RegExp
  errorMessage?: JSX.Element | string
}

export type TStateProps = {
  editState: boolean
  inputRef: React.RefObject<HTMLInputElement>
  value: any
}

export const SecurityInput = (props: TProps) => {
  const { required, requiredMarker, filled, title, maxLength, minLength, disabled, id, dataId, regExp, onClear } = props
  const inputRef = React.useRef(null)
  const [editState, setEditState] = React.useState(false)
  const [value, setValue] = React.useState(getStringValue(props.value) || '')
  const isEmptyValue = (value === undefined || value === null || value === '') && !filled
  const state: TStateProps = {
    editState,
    inputRef,
    value: value
  }

  React.useEffect(() => {
    setValue(getStringValue(props.value) || '')
  }, [props])

  const onInputChange = (e: any) => {
    const currentValue = useRegExp(regExp, e.target.value)
    const updatedValue = String(currentValue)

    setValue(updatedValue)
  }

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.removeAttribute('readonly')
    }

    setValue('')
    setEditState(true)
  }

  const handleBlur = () => {
    const prevValueState = getStringValue(props.value)

    if (value || prevValueState) {
      if (
        value !== prevValueState &&
        (typeof maxLength !== 'number' || maxLength >= value.length) &&
        (typeof minLength !== 'number' || minLength <= value.length)
      ) {
        props.onChange(value ? value.trim() : null)
      } else {
        setValue(prevValueState || '')
      }
    }

    setEditState(false)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === Whitespace.Enter) {
      e.preventDefault()
      e.stopPropagation()
      // @ts-ignore
      e.target.blur()
    }
  }

  const inputValue = value

  return (
    <FieldContainer title={title} required={required || requiredMarker} disabled={disabled}>
      <Container
        style={{ width: '100%', ...props.inputContainerStyle } as any}
        className={cn('input-container', {
          'extra-padding': isEmptyValue || disabled,
          highlighted: isHighlighted(props, state),
          disabled
        })}
      >
        <StyledInput
          id={id}
          data-id={dataId}
          disabled={disabled}
          ref={inputRef}
          type={value && value.length <= maxLength ? 'text' : 'password'}
          // @ts-ignore
          onKeyPress={handleKeyPress}
          placeholder={props.placeholder || '000000000'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete={'off'}
          readOnly={true}
          value={inputValue || (filled && !editState ? '·········' : '')}
          minLength={minLength}
          maxLength={maxLength}
          onChange={onInputChange}
          data-copy-value-on-mousedown={true}
        />
        {!isEmptyValue && !disabled && <ClearButton className={'mdi mdi-close-circle'} onClick={onClear} />}
      </Container>
    </FieldContainer>
  )
}
