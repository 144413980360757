import * as React from 'react'
import { BusinessPartnerViewDTO, ContactDTO, SteamShipLineViewDTO } from '../api/api'

export interface IGridItemSteamShipLineContext {
  steamShipLine: SteamShipLineViewDTO
  businessPartner: BusinessPartnerViewDTO
  isExpanded: boolean
  primaryContact: ContactDTO | undefined
}

export const GridItemSteamShipLineContext = React.createContext<IGridItemSteamShipLineContext>({
  steamShipLine: undefined,
  businessPartner: undefined,
  isExpanded: undefined,
  primaryContact: undefined
})
