export { Input } from './inputTypes/simple'
import { ThousandSeparator as _ThousandSeparator } from './inputTypes/thousandSeparator'
import { TextMask as _TextMask } from './inputTypes/textMask'
import { Numeric as _Numeric } from './inputTypes/numeric'
import { Integer as _Integer } from './inputTypes/integer'
import { Money as _Money } from './inputTypes/money'
import { PhoneNumber as _PhoneNumber } from './inputTypes/phoneNumber'
import { Email as _Email } from './inputTypes/email'
import { Zip as _Zip } from './inputTypes/zip'
import { Percentage as _Percentage } from './inputTypes/percentage'

export namespace InputField {
  export const ThousandSeparator = _ThousandSeparator
  export const TextMask = _TextMask
  export const Numeric = _Numeric
  export const Integer = _Integer
  export const PhoneNumber = _PhoneNumber
  export const Money = _Money
  export const Email = _Email
  export const Zip = _Zip
  export const Percentage = _Percentage
}
