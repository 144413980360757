import * as R from 'remeda'

export const splitIntoMultipleRequestIds = (_ids: string[], maxIdsInOneRequest: number = 30): string[][] => {
  const arrayOfRequestedIdsArrays: string[][] = []
  const ids = R.uniq(_ids)

  while (ids.length) {
    arrayOfRequestedIdsArrays.push(ids.splice(0, maxIdsInOneRequest))
  }

  return arrayOfRequestedIdsArrays
}
