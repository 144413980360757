import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { TableHeaderRow, TableHeaderCell } from '../styles'
import Column from './column'
import { combinedColumnFilterSettings } from '../../../../../services/uiSettingsService/tabs'
import { TGridColumns, TabDTO, ColumnDTO } from '../../../../../store/reducers/tabs/interfaces'

type OwnProps = {
  disallowFilter?: boolean
  scrollable?: boolean
  scrollToZero: boolean
  withCheckbox?: boolean
  tabType: TabDTO.Type
  enableFiltering: boolean
  enableSorting: boolean
  filters: ColumnDTO.Filter[]
  sorting: any
  columns: TGridColumns
  updateColumns: (columns: TGridColumns) => void
  updateSorting: (sorting: any) => void
  updateFilters: (updatedFilters: ColumnDTO.Filter[]) => void
}

type Props = OwnProps

export const Header = (props: Props) => {
  const {
    disallowFilter,
    scrollable,
    scrollToZero,
    withCheckbox,
    tabType,
    enableFiltering,
    enableSorting,
    updateColumns,
    updateSorting,
    updateFilters,
    filters,
    sorting,
    columns
  } = props

  const ref = React.useRef(null)

  React.useEffect(() => {
    if (!ref || !ref.current) {
      return
    }

    ref.current.style.left = 0
  }, [scrollToZero])

  const columnTitles = Object.keys(columns)

  const updateColumnSize = React.useCallback(
    (columnHeading: string, width: number) => {
      return updateColumns({ ...columns, [columnHeading]: { ...columns[columnHeading], width } })
    },
    [columns]
  )

  const filterSettings = oc(combinedColumnFilterSettings())[tabType]({})

  return (
    <TableHeaderRow
      ref={ref}
      className={cn('table__header', { table__header_scrollable: scrollable, scroll_item_with_grid: !scrollable })}
    >
      {withCheckbox && <TableHeaderCell style={{ width: 40 }} />}
      {columnTitles.map(title => {
        const columnProps = columns[title]

        if (!columnProps.active) {
          return null
        }

        return (
          <Column
            key={title}
            heading={title}
            updateColumnSize={updateColumnSize}
            columnProps={columnProps}
            updateSorting={updateSorting}
            updateFilters={updateFilters}
            sorting={sorting}
            appliedFilters={filters}
            tabType={tabType}
            enableFiltering={!disallowFilter && enableFiltering}
            enableSorting={!disallowFilter && enableSorting}
            settings={oc(filterSettings)[title]({})}
          />
        )
      })}
    </TableHeaderRow>
  )
}
