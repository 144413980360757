import styled from 'styled-components'

export const PreviewRow = styled.div`
  display: flex;
  justify-content: space-between;
`
export const FlexBox = styled.div`
  display: flex;
`
export const EffectiveFlexBox = styled.div`
  display: flex;

  > * {
    width: 0;
    flex-grow: 1;
  }
`
