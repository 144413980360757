import { DispatchDeliveryOrderViewDTO } from '../../../../api/origin/business-logic'
import { DateType } from '../../../UI/DatePicker/Interfaces'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO, SsqRateGroupDTO } from '../../../../api/origin/qmp-service'
import { TNewCustomerQuotes } from '../../../../services/DTO/customerQuote/interfaces'

export enum QMPTab {
  RATE = 'Rates',
  CUSTOMERQUOTE = 'Customer Quotes',
  RULES = 'Rules'
}

export enum RateType {
  ssq = 'ssq',
  bsq = 'bsq'
}

export enum RateState {
  new = 'new',
  edit = 'edit',
  warning = 'warning'
}

export interface IQMPExtraData {
  [QMPTab.RATE]: any
  [QMPTab.CUSTOMERQUOTE]: any
  [QMPTab.RULES]: any
}

export interface IQMPFilters {
  [QMPTab.RATE]: IRateFiltersState
  [QMPTab.CUSTOMERQUOTE]: ICustomerQuoterFilterState
  [QMPTab.RULES]: IRulesFilterState
}

export interface IQMPTabData {
  activeTab: QMPTab
  openedCustomerQuoteId: string
  filters: IQMPFilters
  extraData: IQMPExtraData
  ssqIds: string[]
  bsqIds: string[]
  customerQuoteIds: string[]
  newSSQRates: NewRateDTO[]
  newBSQRates: NewRateDTO[]
  newCustomerQuotes?: TNewCustomerQuotes
  ratesTabVisited: boolean
  customerQuotesTabVisited: boolean
}

export enum RateField {
  number = 'number',
  type = 'type',
  customerId = 'customerId',
  vendorId = 'vendorId',
  pickupLocationId = 'pickupLocationId',
  deliveryLocation = 'deliveryLocation',
  returnLocationId = 'returnLocationId',
  deliveryOrderType = 'deliveryOrderType',
  containerTypeId = 'containerTypeId',
  loadType = 'loadType',
  ruleIds = 'ruleIds',
  status = 'status',
  quoteDate = 'quoteDate'
}

export enum CustomerQuoteFieldType {
  number = 'number',
  customerId = 'customerId',
  vendorId = 'vendorId',
  pickupLocationId = 'pickupLocationId',
  deliveryLocation = 'deliveryLocation',
  returnLocationId = 'returnLocationId',
  deliveryOrderType = 'deliveryOrderType',
  containerTypeId = 'containerTypeId',
  loadType = 'loadType',
  status = 'status'
}

export enum RulesFieldType {
  name = 'name',
  parameter = 'parameter',
  function = 'function',
  dateUpdated = 'dateUpdated',
  createdBy = 'createdBy'
}

export interface IRateFiltersState {
  number: string
  type: SellSideQuoteRateDTO.TypeEnum[]
  customerId:
    | string
    | {
        label: string
        value: string
      }[]
  vendorId:
    | string
    | {
        label: string
        value: string
      }[]
  pickupLocationId: string
  deliveryLocation: { city: string; stateId: string; postalCode: string }
  returnLocationId: string
  deliveryOrderType: SellSideQuoteRateDTO.DeliveryOrderTypeEnum[]
  containerTypeId: string[]
  loadType: (SellSideQuoteRateDTO.LoadTypeEnum | BuySideQuoteRateDTO.LoadTypeEnum)[]
  ruleIds: string[]
  status: (SellSideQuoteRateDTO.StatusEnum)[]
  quoteDate: string
}
export interface IRulesFilterState {
  name: string
  parameter: string
  function: string
  dateUpdated: DateType
  createdBy: string
}
export interface ICustomerQuoterFilterState {
  number: string
  customerId:
    | string
    | {
        label: string
        value: string
      }[]
  vendorId:
    | string
    | {
        label: string
        value: string
      }[]
  pickupLocationId: string
  deliveryLocation: { city: string; stateId: string; postalCode: string }
  returnLocationId: string
  deliveryOrderType: SellSideQuoteRateDTO.DeliveryOrderTypeEnum[]
  containerTypeId: string[]
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum[]
  status: (SellSideQuoteRateDTO.StatusEnum)[]
}

export enum ColumnSize {
  default = 'default',
  wideSSQ = 'wide SSQ',
  wideBSQ = 'wide BSQ',
  customerQuote = 'customerQuote'
}

export type NewRateDTO = (SellSideQuoteRateDTO | BuySideQuoteRateDTO) & {
  vendorId?: string
  customerId?: string
  state: RateState
  duplicateIds: string[]
  warningMessage: string
  warningMessageDescription: string
}
export type SellSideQuoteRateGroupDTO = SsqRateGroupDTO & {
  id: string
  number?: number
  state?: RateState
}
export type BuySideQuoteRateGroupDTO = SellSideQuoteRateGroupDTO & {
  vendorId?: string
}

export type AnyRateDTO =
  | SellSideQuoteRateDTO
  | BuySideQuoteRateDTO
  | NewRateDTO
  | SellSideQuoteRateGroupDTO
  | BuySideQuoteRateGroupDTO

export enum RateColumn {
  id = 'ID',
  typeOfRate = 'Type of rate',
  typeOfQuote = 'Type of quote',
  type = 'Type',
  pickup = 'Pickup',
  delivery = 'Delivery',
  return = 'Return',
  doType = 'DO Type',
  containerType = 'Container Type',
  loadType = 'Load Type',
  calculationType = 'Calculation Type',
  rule = 'Rule',
  effectiveDate = 'Effective Date',
  expirationDate = 'Expiration Date',
  amount = 'Amount'
}
