import * as React from 'react'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
import styled from 'styled-components'
import * as R from 'remeda'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { Checkbox } from '../../../../../UI/Radio'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { preventEvent } from '../../../../../../services/functions/basic'

type Props = {
  selectedValues: DispatchDeliveryOrderViewDTO.StatusEnum[]
  onApplyClick: (values: DispatchDeliveryOrderViewDTO.StatusEnum[]) => void
}

export const DropDown = (props: Props) => {
  const { selectedValues, onApplyClick } = props
  const [localStatuses, setLocalStatuses] = React.useState<DispatchDeliveryOrderViewDTO.StatusEnum[]>(
    selectedValues || []
  )

  const list: Record<string, DispatchDeliveryOrderViewDTO.StatusEnum[]> = {
    NEW: [
      DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
      DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
      DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED
    ],
    HOLD: [
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE
    ],
    RELEASED: [
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE
    ],
    'READY FOR DISPATCH': [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH],
    DISPATCHED: [
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED
    ],
    'IN-PROCESS': [
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
    ],
    COMPLETED: [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED],
    CANCELLED: [DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]
  }

  const titleColorList = {
    NEW: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.NEW],
    HOLD: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED],
    RELEASED: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED],
    'READY FOR DISPATCH': ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH],
    DISPATCHED: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED],
    'IN-PROCESS': '#4F4F4F',
    COMPLETED: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED],
    CANCELLED: ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]
  }

  const removeSectionItems = (section: string) =>
    setLocalStatuses(localStatuses.filter(listItem => !list[section].includes(listItem)))
  const addSectionItems = (section: string) => setLocalStatuses(R.uniq(localStatuses.concat(list[section])))

  return (
    <StyledDropDown>
      {Object.keys(list).map(section => {
        const isSectionSelected = list[section].every(_ => localStatuses.includes(_))

        return (
          <Section key={section}>
            <SectionColorLine style={{ backgroundColor: titleColorList[section] || '#4F4F4F' }} />
            <SectionTitle
              onClick={event => {
                preventEvent(event)
                return (isSectionSelected ? removeSectionItems : addSectionItems)(section)
              }}
            >
              <Checkbox
                active={isSectionSelected}
                style={{ color: isSectionSelected ? titleColorList[section] : '#4F4F4F' }}
              />
              <Label>{section}</Label>
            </SectionTitle>

            {list[section].length === 1
              ? null
              : list[section].map(listItem => {
                  const isListItemSelected = localStatuses.includes(listItem)

                  return (
                    <SectionItem
                      key={listItem}
                      onClick={event => {
                        preventEvent(event)
                        return setLocalStatuses(
                          isListItemSelected
                            ? localStatuses.filter(_ => _ !== listItem)
                            : localStatuses.concat(listItem)
                        )
                      }}
                    >
                      <ColorLine style={{ backgroundColor: ddoDirectory.statusColor[listItem] || '#4F4F4F' }} />
                      <Checkbox
                        active={isListItemSelected}
                        style={{
                          color: isListItemSelected ? ddoDirectory.statusColor[listItem] || '#4F4F4F' : '#4F4F4F'
                        }}
                      />
                      <Label>{ddoDirectory.status[listItem]}</Label>
                    </SectionItem>
                  )
                })}
          </Section>
        )
      })}
      <ApplyButtonContainer>
        <ActionButton
          round={true}
          filled={true}
          disabled={!onApplyClick}
          onClick={() => onApplyClick(localStatuses.length ? localStatuses : undefined)}
        >
          Apply
        </ActionButton>
      </ApplyButtonContainer>
    </StyledDropDown>
  )
}

const StyledDropDown = styled.div`
  background-color: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: default;
  overflow: auto;
`
const Section = styled.div`
  position: relative;
  margin: 4px 0;
  user-select: none;
`
const ColorLine = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
`
const SectionColorLine = styled(ColorLine)`
  border-radius: 2px 0 0 2px;
`
const SectionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #323e4e;
  padding: 3px 8px 3px 20px;
  cursor: pointer;

  &:last-child {
    ${ColorLine} {
      border-radius: 0 0 0 2px;
    }
  }

  &:hover {
    background-color: #f5f6fa;
  }
`
const Label = styled.div`
  color: #323e4e;
  margin-left: 5px;
`

const SectionTitle = styled(SectionItem)`
  font-weight: 500;
  padding: 5px 8px;

  span {
    text-transform: uppercase;
  }
`

const ApplyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`
