import { ContactDTO, callAPI, callAPIWithErrorMessage, contactAPI } from '../../../../api/api'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewId, isNewObject } from '../../../../services/DTO'
import { getListsState } from '../../../../store'

export const requestContactIds = (ids: string[]): Promise<ContactDTO[]> => {
  if (ids && ids.length) {
    const storeContactMapping = getListsState()[EntityType.contact]
    const requestIds = ids.filter(id => !isNewId(id) && !storeContactMapping[id])

    if (!requestIds.length) {
      return Promise.resolve([])
    }

    return Promise.all(requestIds.map(id => callAPI(contactAPI.findById, id).toPromise())).then(async contacts => {
      await pushListItemsToStore({
        update: { [EntityType.contact]: contacts }
      })

      return contacts
    })
  }

  return Promise.resolve([])
}

const requestCreateContact = (contact: ContactDTO): Promise<ContactDTO> => {
  return callAPIWithErrorMessage(contactAPI.create, { ...contact, id: undefined })
}

const requestUpdateContact = (contact: ContactDTO): Promise<ContactDTO> => {
  if (isNewObject(contact)) {
    return requestCreateContact(contact)
  }

  return callAPIWithErrorMessage(contactAPI.update, contact.id, contact)
}

export const requestUpdateContacts = (contacts: ContactDTO[]): Promise<ContactDTO[]> => {
  return Promise.all((contacts || []).map(requestUpdateContact)).then(async requestedContacts => {
    if (requestedContacts && requestedContacts.length) {
      await pushListItemsToStore({ update: { [EntityType.contact]: requestedContacts } })
    }

    return requestedContacts
  })
}
