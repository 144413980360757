import * as React from 'react'
// @ts-ignore
import { debounce } from 'debounce'
import { tabSessionStorage } from '../services/tabs/functions'

type Props = {
  tabId: string
  bind?: boolean
}

export const useTabContentScrollPosition = (props: Props): React.RefObject<any | HTMLDivElement> => {
  const { tabId, bind } = props
  const tabContentRef: React.RefObject<any | HTMLDivElement> = React.createRef()

  React.useLayoutEffect(() => {
    const handleScrolling = debounce((event: any) => {
      try {
        tabSessionStorage.scrollPosition.setTabPosition(tabId, {
          top: event.target.scrollTop,
          left: 0
        })
      } catch (e) {}
    }, 150)

    if (bind !== false && tabContentRef.current) {
      tabContentRef.current.scrollTop = tabSessionStorage.scrollPosition.getTabPosition(tabId).top
      tabContentRef.current.addEventListener('scroll', handleScrolling)
    }

    return () => {
      if (tabContentRef.current) {
        tabContentRef.current.removeEventListener('scroll', handleScrolling)
      }
    }
  }, [bind])

  return tabContentRef
}
