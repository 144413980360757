import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { Column, GridColumn, ColumnRow } from '../../../UI/GridSystem'
import {
  DeliveryOrderViewDTO,
  CargoDTO,
  CustomerViewDTO,
  SteamShipLineDTO,
  DeliveryOrderDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../api/api'
import styled from 'styled-components'
import { Input } from '../../../UI/DataFields/Input'
import { CustomerSearch, SteamShipLineSearch } from '../../../UI/DataFields/SearchRequest'
import { Select } from '../../../UI/DataFields/Select'
import { DOIndicator } from '../../dispatchDeliveryOrder/views/Details/General/styles'
import { CheckFieldByRequest } from '../../../UI/CheckFieldByRequest'
import {
  isValidDescription,
  isValidPurchaseOrderNumber,
  isValidReferenceNumber,
  isValidVesselName,
  isValidVoyageNumber
} from '../../../../services/DTO/deliveryOrder'
import { OrderNumber } from './OrderNumber'
import { deliveryOrderLists } from '../../../../services/select/deliveryOrderLists'
import { deliveryOrderDirectory } from '../../../../services/DTO/deliveryOrder/directory'
import { alertOnChangingMainStreetTurnPropsOfDO } from '../../../../services/DTO/dispatchDeliveryOrder/functions'
import { regExpOnlyNumbersAndLetters } from '../../../../services/functions/regExp'
import { DateTimePicker } from '../../../UI/DataFields/DateTimePicker/views'
import { isNewItem } from '../../../../services/utils'
import { assembleEntity } from '../../../../services/functions/assembleListEntity/assembleEntity'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { useDeliveryOrderTab } from '../../../../hooks/useDeliveryOrderTab'

type OwnProps = {}

type DispatchProps = {}

const TypeSelecterContainer = styled(Column)`
  .selected {
    font-size: 18px;
  }
`

type Props = OwnProps & DispatchProps

export const LeftSide = (props: Props) => {
  const {
    isModified,
    modifyListItems,
    modifyParentObject,
    modifyParentObjectField,
    deleteModifiedListItems
  } = useExpandedItem()
  const { deliveryOrder, isSteamShipLineRequired } = useDeliveryOrderTab()
  const isImport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const isNew = isNewItem(deliveryOrder)

  const changeSteamShipLine = (steamShipLine: SteamShipLineDTO) => {
    const updatedDO = {
      ...deliveryOrder,
      steamShipLineId: oc(steamShipLine).id()
    }

    alertOnChangingMainStreetTurnPropsOfDO(updatedDO, deliveryOrder)
    modifyParentObjectField('steamShipLineId')(oc(steamShipLine).id())
  }

  const updateCargo = (fieldName: keyof CargoDTO) => (value: string) => {
    modifyParentObjectField('cargo')({ ...deliveryOrder.cargo, [fieldName]: value })
  }

  const changeCustomer = (_customer: CustomerViewDTO) => {
    modifyParentObject({
      ...deliveryOrder,
      customerId: oc(_customer).id(),
      subClientId: undefined
    })
  }

  return (
    <GridColumn style={{ width: '100%' }} isGrid={true} flexClear={true}>
      <ColumnRow margin={{ bottom: 15 }}>
        <Column margin={{ right: 15 }}>
          <OrderNumber orderNumber={deliveryOrder.number} />
        </Column>
        {isNew ? (
          <TypeSelecterContainer margin={{ right: 15 }} id={'do-type'}>
            <Select
              title={'Type'}
              required={true}
              selectedValue={deliveryOrder.type}
              list={deliveryOrderLists.type}
              onSelect={type => {
                let updatedDO = R.clone(deliveryOrder)
                const dispatchDeliveryOrders = oc(deliveryOrder)
                  .dispatchDeliveryOrderIds([])
                  .map(id => {
                    return assembleEntity({ entityId: id, entityType: EntityType.dispatchDeliveryOrder })
                  })
                  .filter(Boolean) as DispatchDeliveryOrderViewDTO[]
                const updatedDispatchDeliveryOrders: DispatchDeliveryOrderViewDTO[] = []

                updatedDO.type = type
                updatedDO.loadType = updatedDO.loadType || DeliveryOrderDTO.LoadTypeEnum.LIVELOAD

                switch (type) {
                  case DeliveryOrderViewDTO.TypeEnum.IMPORT:
                    updatedDO.vesselDepartureDate = undefined
                    updatedDO.lastFreeDatePerDiem = undefined
                    updatedDO.bookingNumber = undefined

                    dispatchDeliveryOrders.forEach(ddo => {
                      const isEmptyDeliveryStage = Object.keys(ddo.deliveryStage || {}).length === 0

                      if (isEmptyDeliveryStage) {
                        updatedDispatchDeliveryOrders.push({
                          ...R.clone(ddo),
                          deliveryStage: {
                            locationId: updatedDO.deliveryLocationId,
                            plannedAppointmentDateTimeRange: updatedDO.appointmentDateTimeRange
                          }
                        })
                      }
                    })
                    break
                  case DeliveryOrderViewDTO.TypeEnum.EXPORT:
                    updatedDO.billOfLadingNumber = undefined
                    updatedDO.vesselArrivalDate = undefined
                    updatedDO.lastFreeDateDemurrage = undefined

                    dispatchDeliveryOrders.forEach(ddo => {
                      const isEmptyDeliveryStage = Object.keys(ddo.deliveryStage || {}).length === 0

                      if (isEmptyDeliveryStage) {
                        updatedDispatchDeliveryOrders.push({
                          ...R.clone(ddo),
                          deliveryStage: {
                            locationId: updatedDO.deliveryLocationId,
                            plannedAppointmentDateTimeRange: updatedDO.appointmentDateTimeRange
                          }
                        })
                      }
                    })
                    break
                  case DeliveryOrderViewDTO.TypeEnum.REPOSITION:
                    updatedDO = R.omit(updatedDO, [
                      'deliveryLocation',
                      'deliveryLocationId',
                      'loadType',
                      'generalCutoffDate',
                      'lastFreeDatePerDiem',
                      'billOfLadingNumber',
                      'appointmentDateTimeRange'
                    ])
                    updatedDO.equipmentFirstPickupDate = deliveryOrder.date
                    updatedDO.cargo.description = 'REPO'
                    updatedDO.cargo.referenceNumber = updatedDO.cargo.referenceNumber || updatedDO.bookingNumber
                    updatedDO.dispatchDeliveryOrders = oc(updatedDO)
                      .dispatchDeliveryOrders([])
                      .map((ddo: DispatchDeliveryOrderViewDTO) => {
                        const correctDDO = R.clone(ddo)
                        correctDDO.deliveryStage = {}
                        return R.omit(correctDDO, ['loadType'])
                      })

                    dispatchDeliveryOrders.forEach(ddo => {
                      const isDeliveryStageCorrect =
                        typeof ddo.deliveryStage === 'object' && Object.keys(ddo.deliveryStage || {}).length === 0
                      const isLoadTypeCorrect = !ddo.loadType

                      if (!(isDeliveryStageCorrect && isLoadTypeCorrect)) {
                        updatedDispatchDeliveryOrders.push({
                          ...R.omit(ddo, ['loadType']),
                          deliveryStage: {}
                        })
                      }
                    })
                    break
                  default:
                }

                modifyListItems({
                  [EntityType.deliveryOrder]: [updatedDO],
                  [EntityType.dispatchDeliveryOrder]: updatedDispatchDeliveryOrders
                })
              }}
            />
          </TypeSelecterContainer>
        ) : (
          <Column justifyCenter={true} padding={{ top: 22 }} margin={{ right: 15 }}>
            <DOIndicator id={'do-type'}>{deliveryOrderDirectory.type[deliveryOrder.type] || ''}</DOIndicator>
          </Column>
        )}
        <Column id={'do-date'}>
          <DateTimePicker
            title={'Delivery Order Date'}
            required={true}
            disabled={deliveryOrder.dateLocked}
            date={deliveryOrder.date}
            onChange={modifyParentObjectField('date')}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 20 }}>
        <Column id={'do-customer'}>
          <CustomerSearch title={'Customer'} required={true} id={deliveryOrder.customerId} onChange={changeCustomer} />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 40 }}>
        {isImport && (
          <Column maxColumns={10} margin={{ right: 15 }} id={'do-billOfLadingNumber'}>
            <Input
              required={true}
              title={'Bill of Lading #'}
              maxLength={64}
              regExp={regExpOnlyNumbersAndLetters}
              highlighted={oc(deliveryOrder).billOfLadingNumber('').length < 4}
              value={deliveryOrder.billOfLadingNumber}
              onChange={modifyParentObjectField('billOfLadingNumber')}
            />
          </Column>
        )}
        <Column maxColumns={10} id={'do-bookingNumber'}>
          <Input
            required={isExport || isRepo}
            title={'Booking #'}
            errorMessage={
              deliveryOrder.bookingNumber && deliveryOrder.bookingNumber.length < 4 && 'size must be between 4 and 64'
            }
            maxLength={64}
            regExp={regExpOnlyNumbersAndLetters}
            highlighted={deliveryOrder.bookingNumber && deliveryOrder.bookingNumber.length < 4}
            value={deliveryOrder.bookingNumber}
            onChange={value => {
              const updatedDO = R.clone(deliveryOrder)

              updatedDO.bookingNumber = value

              if (isRepo) {
                updatedDO.cargo.referenceNumber = updatedDO.cargo.referenceNumber || updatedDO.bookingNumber
              }

              modifyParentObject(updatedDO)
            }}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 20 }}>
        <Column id={'do-cargo-description'}>
          <Input
            required={true}
            title={'Cargo Description'}
            valid={isValidDescription(deliveryOrder.cargo.description)}
            value={deliveryOrder.cargo.description}
            onChange={updateCargo('description')}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 40 }}>
        <Column margin={{ right: 15 }} id={'do-cargo-referenceNumber'}>
          <Input
            title={'Reference #'}
            required={true}
            regExp={regExpOnlyNumbersAndLetters}
            valid={isValidReferenceNumber(deliveryOrder.cargo.referenceNumber)}
            value={deliveryOrder.cargo.referenceNumber}
            onChange={updateCargo('referenceNumber')}
          />
          <CheckFieldByRequest
            currentDeliveryOrderId={deliveryOrder.id}
            value={deliveryOrder.cargo.referenceNumber}
            minLength={4}
          />
        </Column>
        <Column id={'do-cargo-purchaseOrderNumber'}>
          <Input
            title={'Purchase Order'}
            valid={isValidPurchaseOrderNumber(deliveryOrder.cargo.purchaseOrderNumber)}
            value={deliveryOrder.cargo.purchaseOrderNumber}
            onChange={updateCargo('purchaseOrderNumber')}
          />
        </Column>
      </ColumnRow>
      <ColumnRow margin={{ bottom: 35 }}>
        <Column margin={{ right: 15 }} id={'do-steamShipLine'}>
          <SteamShipLineSearch
            title={'SSL Name'}
            required={isSteamShipLineRequired}
            id={deliveryOrder.steamShipLineId}
            onChange={changeSteamShipLine}
          />
        </Column>
        <Column margin={{ right: 15 }} id={'do-vesselName'}>
          <Input
            title={'Vessel Name'}
            maxLength={120}
            valid={isValidVesselName(deliveryOrder.vesselName)}
            value={deliveryOrder.vesselName}
            onChange={modifyParentObjectField('vesselName')}
          />
        </Column>
        <Column id={'do-voyageNumber'}>
          <Input
            title={'Voyage  #'}
            maxLength={120}
            valid={isValidVoyageNumber(deliveryOrder.voyageNumber)}
            value={deliveryOrder.voyageNumber}
            onChange={modifyParentObjectField('voyageNumber')}
          />
        </Column>
      </ColumnRow>
    </GridColumn>
  )
}
