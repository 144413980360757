export const maxCompleteness = 15
// public enum Completeness {
//  CODE1(1),
//  CODE2(2),
//  NAME(3),
//  CODE4(4),
//  CODE5(5),
//  GRID(6),
//  CODE7(7),
//  CODE8(8),
//  SIMPLE(9),
//  CODE10(10),
//  CODE11(11),
//  CREATE(12),
//  CODE13(13),
//  CODE14(14),
//  FULL(15);

//  private final int code;
// }
