import { GridColumn, Container } from '../GridSystem'
import { Title } from '../styledComponents'
import theme from '../../../styles/theme'
import styled from '../../../styles/styledComponents'

export const transport = {
  BOX_TRUCK: require('../../../img/transport/truck.svg'),
  TRACTOR: require('../../../img/transport/tractor.svg')
}

const border =
  'background: none;' +
  'background-image: linear-gradient(rgba(255, 255, 255, 0), #e0e0e0),' +
  'linear-gradient(rgba(255, 255, 255, 0), #e0e0e0);' +
  'background-size: 1px 5px;' +
  'background-position: 0 calc(100% - 6px), 100% calc(100% - 6px);' +
  'background-repeat: no-repeat;'

export const Label = styled(Title)`
  padding-left: 10px;
`
export const EmptyValue = styled(GridColumn)`
  color: rgba(51, 51, 51, 0.3);
`
export const Selected = styled(GridColumn)`
  background: ${theme.colors.basicBackgroundColor};
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.withIcon > div {
    position: static;
  }

  &:before {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: ${theme.colors.basicBlueColor} !important;
    font-size: 18px !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.highlight {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }

  &.noBorder {
    background: none;
  }

  &.isEmpty {
    background: ${theme.colors.basicBackgroundColor};
  }

  &.gridItem {
    background: none;
    &:before {
      display: none !important;
    }

    > div {
      text-align: left;
      padding: 0;
    }
  }

  &.open {
    background: ${theme.colors.basicBackgroundColor} !important;
    border-radius: 5px 5px 0 0;
  }

  &.disable {
    background: white;
    cursor: default;

    &:before {
      display: none !important;
    }
  }

  &.isEmpty.disable {
    opacity: 0;
  }
`
export const Dropdown = styled(GridColumn)`
  z-index: 10;
  position: absolute;
  width: 100%;
  min-width: 150px;
  font-size: 12px;
  background: white;
  border-radius: 0 0 5px 5px;
  border-top: 2px solid ${theme.colors.basicBlueColor};
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);

  > div {
    max-height: 245px;
    margin: 0 -10px;
    padding: 0 10px;
    overflow: auto;

    > div:first-child {
      border: none;
    }
  }

  ${Container} {
    border-top: 1px solid ${theme.colors.basicBorderColor};
    cursor: pointer;

    > ${GridColumn} {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -10px;
      right: -10px;
      padding: 0 10px;
    }

    &:hover,
    &:hover + div {
      border-color: transparent;
    }

    &:hover > ${GridColumn} {
      background-color: rgba(69, 85, 208, 0.05);
    }
  }

  input {
    height: 35px;
    width: 100%;
    line-height: 35px;
    font-size: 14px;
    border-radius: 5px;
    background: ${theme.colors.basicBackgroundColor} !important;
    border: none;
    padding: 0 10px;
    margin-top: 5px;
    margin-bottom: 10px;

    &::placeholder {
      color: rgba(51, 51, 51, 0.3);
    }
  }
`
export const StyledSelecter = styled(GridColumn)`
  user-select: none;

  img {
    margin-right: 10px;
  }

  &.locationType.withIcon {
    .mdi {
      display: flex;
      align-items: center;
      height: 100%;

      &:before {
        display: flex !important;
        justify-content: center;
        font-size: 20px !important;
        color: ${theme.colors.basicBlueColor};
        margin-right: 5px;
      }
    }
  }

  &.state {
    &.code .selected {
      .state-name {
        display: none;
      }
    }

    .textLine {
      width: 100%;

      > span {
        display: block;
      }
    }
  }
`

export const MultiSelectContainer = styled(GridColumn)`
  > div {
    width: 100%;
  }

  svg {
    display: none;
  }

  //&.noSearch {
  //  .react-select__value-container {
  //    > div:last-child {
  //      position: absolute;
  //      top: -999999999px;
  //      left: -999999999px;
  //      opacity: 0;
  //    }
  //  }
  //}

  &:not(.active) {
    .react-select__value-container {
      > input {
        display: none;
      }
    }
  }

  .react-select__placeholder {
    color: rgba(51, 51, 51, 0.3);
  }

  .react-select__control {
    position: relative;
    min-height: 35px;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    background: #f5f6fa;
    padding-right: 30px;
  }

  .react-select__value-container {
    max-width: 100%;
    padding: 3px 0 3px 10px;

    .react-select__multi-value {
      max-width: calc(100% - 10px);
      background-color: #4555d0;
      border-radius: 5px;
      margin: 1px;

      .react-select__multi-value__label {
        position: relative;
        font-size: 14px;
        color: #f1f1f1;
      }

      .react-select__multi-value__remove {
        position: relative;
        width: 20px;
        cursor: pointer;
        border-radius: 0 5px 5px 0;

        &:before {
          z-index: 1;
          content: '\\F156';
          display: flex;
          align-items: center;
          justify-content: center;
          color: #f1f1f1;
          font: normal normal normal 24px/1 'Material Design Icons';
          font-size: 14px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        }

        &:hover {
          background: none;
        }
      }
    }
  }

  .react-select__indicators {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30px;

    .react-select__indicator-separator,
    .react-select__clear-indicator {
      display: none;
    }

    .react-select__dropdown-indicator {
      position: relative;
      width: 30px;

      &:before {
        content: '\f349';
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4555d0;
        font: normal normal normal 24px/1 'Material Design Icons';
        font-size: 18px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

  .react-select__menu {
    top: auto;
    margin: 0;
    border-radius: 0 0 5px 5px;
    border: none;
    border-top: 2px solid ${theme.colors.basicBlueColor};
    box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);

    .react-select__option {
      cursor: pointer;
      background: none;
    }
  }

  .react-select__menu-notice--no-options {
    color: #333333;
    text-align: left;
  }
`
