import { TRecentObjects } from './LocalStorageRecent'
import { UserDTO } from '../../../api/origin/user-service'

export enum LocalStorageProp {
  recent = 'recent'
}

export type TLocalStorage = {
  [userId: string]: TRecentObjects
}

export type LocalStorageOptions = {
  user: UserDTO
}
