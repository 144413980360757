import { StateDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'

export const generateAddressState = (options?: Partial<StateDTO>): StateDTO => {
  return {
    code: '',
    countryCode: '',
    completeness: maxCompleteness,
    id: undefined,
    name: '',
    ...(options || {})
  }
}
