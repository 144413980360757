import * as React from 'react'
import { TableRow, StyledCell } from '../../../../Grid/styles'
import { columnProps, columns } from './'
import { BlueText, BoldText } from './styles'

export const Row = () => {
  return (
    <TableRow>
      {columns.map(column => (
        <Cell key={column} column={column} data={null} />
      ))}
    </TableRow>
  )
}

const Cell = ({ column, data }: { column: string; data: any }) => {
  return <StyledCell style={columnProps[column].styles}>{getCellValue(column, data)}</StyledCell>
}

const getCellValue = (column: string, data: any) => {
  switch (column) {
    case 'ID':
      return '001'
    case 'Name':
      return <BoldText>Distance ≥ 50 Mi from PNCT</BoldText>
    case 'Definition':
      return (
        <>
          <div>
            <BlueText>Distance</BlueText>
            <BlueText>≥</BlueText>
            <BoldText>50 Mi</BoldText>
          </div>
          <div>
            <BlueText>And</BlueText>
          </div>
          <div>
            <BlueText>LOCATION</BlueText>
            <BlueText>is</BlueText>
            <BoldText>PNCT</BoldText>
          </div>
        </>
      )
    case 'Updated':
      return '01/30/20'
    case 'Created by':
      return 'MarionZ'
    default:
      return ''
  }
}
