import * as React from 'react'
import { oc } from 'ts-optchain'
import { BoldLine, Text, Title } from '../../styles'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

export const Window = () => {
  const { deliveryOrder, steamShipLine } = useGridItemDDO()

  return (
    <>
      <Title rows={2} margin={{ bottom: 10 }}>
        SSL
      </Title>
      <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
        {oc(steamShipLine).name()}
      </BoldLine>
      <Text className={'opacity uppercase'} rows={3} alignCenter={true}>
        {[oc(deliveryOrder).vesselName(), oc(deliveryOrder).voyageNumber()].filter(Boolean).join(', ')}
      </Text>
    </>
  )
}
