import { RootState } from '..'
import { OngoingActivityGroupDTO } from '../../api/api'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectOngoingActivity = (id: string) => (state: RootState): OngoingActivityGroupDTO | undefined => {
  return (
    state.lists[EntityType.ongoingActivityDateStage][id] ||
    state.lists[EntityType.ongoingActivityDateStageNow][id] ||
    state.lists[EntityType.ongoingActivityDateDriver][id]
  )
}
export const selectOngoingActivityDateStage = (id: string) => (
  state: RootState
): OngoingActivityGroupDTO | undefined => {
  return state.lists[EntityType.ongoingActivityDateStage][id]
}
export const selectOngoingActivityDateStageNow = (id: string) => (
  state: RootState
): OngoingActivityGroupDTO | undefined => {
  return state.lists[EntityType.ongoingActivityDateStageNow][id]
}
export const selectOngoingActivityDateDriver = (id: string) => (
  state: RootState
): OngoingActivityGroupDTO | undefined => {
  return state.lists[EntityType.ongoingActivityDateDriver][id]
}

// SELECT MANY
export const selectOngoingActivitisDateStage = (state: RootState): Record<string, OngoingActivityGroupDTO> => {
  return state.lists[EntityType.ongoingActivityDateStage]
}
export const selectOngoingActivitisDateStageNow = (state: RootState): Record<string, OngoingActivityGroupDTO> => {
  return state.lists[EntityType.ongoingActivityDateStageNow]
}
export const selectOngoingActivitisDateDriver = (state: RootState): Record<string, OngoingActivityGroupDTO> => {
  return state.lists[EntityType.ongoingActivityDateDriver]
}
