import { oc } from 'ts-optchain'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  LocationNameDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { ActivityGroup } from '../../DTO/activity/interfaces'
import { isUnsuccessfulActivityGroup } from '../test/isUnsuccessfulActivity'
import { getTemplateActivityTypes } from './getTemplateActivityTypes'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroups: ActivityGroup[]
  strict?: boolean
}

export const getNeededActivityTypesByStages = (
  props: Props
): Record<TransportationActivityViewDTO.StageEnum, TransportationActivityViewDTO.TypeEnum[]> => {
  const { dispatchDeliveryOrder, deliveryOrderType, activityGroups, strict } = props
  const getStageDocumentationActivityGroups = (stage: TransportationActivityViewDTO.StageEnum) => {
    return activityGroups.filter(
      group =>
        'documentationActivity' in group &&
        group.documentationActivity.stage === stage &&
        (group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.SUBMITTED ||
          group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.APPROVED)
    )
  }
  const getStageTransportationActivityGroups = (stage: TransportationActivityViewDTO.StageEnum) => {
    const storeLocations = getListsState()[EntityType.location]

    return activityGroups.filter(
      group =>
        'gotoActivity' in group &&
        'businessActivity' in group &&
        group.gotoActivity.stage === stage &&
        !isUnsuccessfulActivityGroup(group) &&
        oc(storeLocations)[oc(group).gotoActivity.destinationId()].type() !== LocationNameDTO.TypeEnum.CONTAINERYARD
    )
  }

  const stages = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]

  return stages.reduce((acc, stage) => {
    acc[stage] = ((): TransportationActivityViewDTO.TypeEnum[] => {
      if (getStageDocumentationActivityGroups(stage).length) {
        return []
      }

      const stageTransportationActivityGroups = getStageTransportationActivityGroups(stage)

      if (!strict) {
        if (
          (stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
            deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION) ||
          stageTransportationActivityGroups.length === 0
        ) {
          return []
        }
      }

      const requiredStageTypes = getTemplateActivityTypes({
        deliveryOrderType,
        loadType: oc(dispatchDeliveryOrder).loadType(),
        stage
      })

      let dispatchDeliveryOrderLocationId: string = undefined

      switch (stage) {
        case TransportationActivityViewDTO.StageEnum.PICKUP:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.DELIVERY:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.RETURN:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
          break
        default:
      }

      return requiredStageTypes.filter(
        stageTemplateType =>
          !stageTransportationActivityGroups.some(
            group =>
              'businessActivity' in group &&
              group.businessActivity.template &&
              group.businessActivity.type === stageTemplateType &&
              group.businessActivity.destinationId === dispatchDeliveryOrderLocationId
          )
      )
    })()

    return acc
  }, {})
}
