import * as React from 'react'
import { oc } from 'ts-optchain'
import { ActivitiesViewDTO, DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, DriverViewDTO } from '../../api/api'
import { isDDOStatusCancelled } from './test/isDDOStatusCancelled'
import { isLoadTypeActitiesValid } from './test/isLoadTypeActitiesValid'
import { ActivityGroup } from '../DTO/activity/interfaces'
import { isMoreThanOneNextToBobtails } from './test/isMoreThanOneNextToBobtails'
import { isDropBobtailGotoActivity } from './test/isBobtailGotoActivity'
import { isDropBobtailValid } from './test/isDropBobtailValid'
import { getListsState } from '../../store'
import { getAssignedDrivers } from '../DTO/activity/functions'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { assembleEntity } from './assembleListEntity/assembleEntity'
import { testDriverStatus } from './test/testDriverStatus'
import { showErrorModalWindow } from '../../store/reducers/modalWindow/functions'
// tslint:disable:max-line-length
import { generateRequiredLoadTypeActivitiesErrorMessage } from './generate/generateRequiredLoadTypeActivitiesErrorMessage'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  initialDispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroups: ActivityGroup[]
  activities: ActivitiesViewDTO
}

export const checkDDOActivitiesBeforeSave = (props: Props): boolean => {
  const { dispatchDeliveryOrder, initialDispatchDeliveryOrder, activities, deliveryOrderType, activityGroups } = props

  if (
    !isDDOStatusCancelled(dispatchDeliveryOrder.status) &&
    !isDDOStatusCancelled(oc(initialDispatchDeliveryOrder).status())
  ) {
    const isLoadTypeActityListValid =
      dispatchDeliveryOrder.loadType !== oc(initialDispatchDeliveryOrder).loadType() ||
      isLoadTypeActitiesValid({ dispatchDeliveryOrder, deliveryOrderType, activityGroups })
    const moreThanOneNextToDropBobtails = isMoreThanOneNextToBobtails(activities.transportationActivities, 'drop')
    const allBobtailsHasDriver = activities.transportationActivities.every(activity =>
      isDropBobtailGotoActivity(activity) ? isDropBobtailValid(activity) : true
    )
    const driverMapping = getListsState().driver
    const assignedOffDutyDrivers = getAssignedDrivers(
      oc(
        assembleEntity({
          deep: true,
          modifiedLists: {},
          entityId: dispatchDeliveryOrder.id,
          entityType: EntityType.dispatchDeliveryOrder
        })
      ).activities.transportationActivities([]),
      oc(activities).transportationActivities([])
    )
      .map(item => driverMapping[item.vendorId])
      .filter(
        driver =>
          driver &&
          !testDriverStatus(driver.status).isActive &&
          driver.status !== DriverViewDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS
      )

    if (!isLoadTypeActityListValid) {
      showErrorModalWindow({
        width: 445,
        title: 'Invalid set of activities',
        content: (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {generateRequiredLoadTypeActivitiesErrorMessage({
              dispatchDeliveryOrder,
              deliveryOrderType,
              activityGroups
            })}
          </div>
        ),
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })

      return false
    }

    if (assignedOffDutyDrivers.length) {
      showErrorModalWindow({
        width: 445,
        title: "Activities can't be assigned",
        content: `Driver${assignedOffDutyDrivers.length > 1 ? 's' : ''} ${assignedOffDutyDrivers
          .map(driver => driver.name)
          .join(', ')} ${assignedOffDutyDrivers.length > 1 ? 'are' : 'is'} not available now`,
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })

      return false
    }

    if (!allBobtailsHasDriver) {
      showErrorModalWindow({
        width: 445,
        title: 'Invalid activity status',
        content: 'Please set a driver for the Drop activity or delete Not Assigned bobtail activity',
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })

      return false
    }

    if (moreThanOneNextToDropBobtails) {
      showErrorModalWindow({
        width: 445,
        title: 'Invalid activity status',
        content: 'You can not save with more than one unfinished bobtail after Drop',
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })

      return false
    }
  }

  return true
}
