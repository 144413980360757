import * as React from 'react'
import { GridColumn } from '../../../../../UI/GridSystem'
import { StyledGeneral } from './styledComponents'
import { SteamshipLineInfo } from './SteamshipLineInfo'
import { PrimaryContact } from '../../../../contact/views/PrimaryContact'
import { useGridItemSteamShipLine } from '../../../../../../hooks/useGridItemSteamShipLine'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { ContainerDetentionPerDiemFeeSchedule } from './ContainerDetentionPerDiemFeeSchedule'

type Props = {}

export const General = (props: Props) => {
  const { businessPartner } = useGridItemSteamShipLine()

  return (
    <StyledGeneral minRows={70}>
      <GridColumn flexBasis={'auto'} padding={{ top: 25, left: 50, right: 50 }}>
        <SteamshipLineInfo />
        <PrimaryContact parentEntity={businessPartner} entityType={EntityType.businessPartner} />
      </GridColumn>
      <ContainerDetentionPerDiemFeeSchedule />
    </StyledGeneral>
  )
}
