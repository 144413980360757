import * as React from 'react'
import { DispatchDeliveryOrderPopupDetails } from './DispatchDeliveryOrderPopupDetails'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { GridItemDDOProvider } from '../../../../../../../providers/GridItemDDOProvider'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DispatchDeliveryOrderPopup = (props: Props) => {
  const { parentItemId } = useExpandedItem()

  if (!parentItemId) {
    return null
  }

  return (
    <GridItemDDOProvider id={parentItemId}>
      <DispatchDeliveryOrderPopupDetails />
    </GridItemDDOProvider>
  )
}
