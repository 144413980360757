import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useAppSelector } from './useAppSelector'
import { selectContacts } from '../store/select/contactSelect'
import { BusinessPartnerViewDTO, LocationViewDTO } from '../api/api'

type Props = {
  parentEntity: LocationViewDTO | BusinessPartnerViewDTO
  modifiedLists?: Partial<TListsState>
}

export const useContacts = (props: Props) => {
  const { parentEntity, modifiedLists } = props
  const storeContactsMapping = useAppSelector(selectContacts)
  const modifiedContactsMapping = oc(modifiedLists)[EntityType.contact]({})
  const primaryContactId = oc(parentEntity).primaryContactId()

  const primaryContact = modifiedContactsMapping[primaryContactId] || storeContactsMapping[primaryContactId]
  const contacts = React.useMemo(() => {
    return oc(parentEntity)
      .contactIds([])
      .map(id => modifiedContactsMapping[id] || storeContactsMapping[id])
      .filter(Boolean)
  }, [oc(parentEntity).contactIds([]), storeContactsMapping, oc(modifiedLists)[EntityType.contact]()])

  return { primaryContact, contacts }
}
