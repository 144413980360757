import * as React from 'react'
import { DriverReportDTO } from '../api/origin/document-service'

type Report = { [key in DriverReportDTO.TypeEnum]?: DriverReportDTO }
type History = { [key in DriverReportDTO.TypeEnum]: DriverReportDTO[] }
export interface IReports extends Report {
  history: History
}

export interface IVendorAccountingReportsContext {
  fetchError: boolean
  reports: IReports
  createReport: (reportType: DriverReportDTO.TypeEnum) => void
  updateReport: (reportType: DriverReportDTO.TypeEnum, report: DriverReportDTO) => void
  requestReports: (props: { spinner?: boolean }) => void
}

export const VendorAccountingReportsContext = React.createContext<IVendorAccountingReportsContext>({
  fetchError: null,
  reports: {
    [DriverReportDTO.TypeEnum.MVR]: undefined,
    [DriverReportDTO.TypeEnum.PSP]: undefined,
    history: {
      [DriverReportDTO.TypeEnum.MVR]: [],
      [DriverReportDTO.TypeEnum.PSP]: []
    }
  },
  createReport: null,
  updateReport: null,
  requestReports: null
})
