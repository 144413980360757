import styled from 'styled-components'

export const StyledScheduler = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 250);
  height: calc(100vh - 85px);
  overflow: auto;
`
