import * as React from 'react'
import { Whitespace } from '../../../../services/keyboardService/keys'
import { TProps, TStateProps } from './inputTypes/simple'

export const getStringValue = (value: any) => {
  return typeof value === 'number' ? String(value) : value
}

export const isHighlighted = (props: TProps, state: TStateProps): boolean => {
  const { highlighted, valid, required, minLength } = props
  const { value, editState } = state
  let validationError = valid !== undefined && !valid && !(!required && !value)

  if (minLength && typeof value === 'string' && value.length !== 0 && value.length < minLength) {
    validationError = true
  }

  return highlighted || validationError || (required && !value && !editState)
}

export const handleKeyPress = (props: TProps, state: TStateProps) => (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (e.key === Whitespace.Enter) {
    e.preventDefault()
    e.stopPropagation()
    // @ts-ignore
    e.target.blur()
    props.onChange(state.value)
  }
}
