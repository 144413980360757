import { oc } from 'ts-optchain'
import * as R from 'remeda'
import equal = require('fast-deep-equal')
import { DateISOString } from '../../../../api/origin/business-logic'
import {
  AnyRateDTO,
  BuySideQuoteRateGroupDTO,
  QMPTab,
  NewRateDTO,
  SellSideQuoteRateGroupDTO,
  RateType,
  IRateFiltersState
} from '../interfaces'
import { TNewCustomerQuotes } from '../../../../services/DTO/customerQuote/interfaces'
import { getStore } from '../../../../store/configureStore'
import { showModal, TMsgType } from '../../../UI/Modal/actions'
import { AlertButtonColor } from '../../../UI/Modal'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../api/origin/qmp-service'
import { callAPI } from '../../../../api/api'
import { correctLoadTypeFilter } from './Tabs/Rate/functions'
import { createId } from '../../../../services/utils'
import { convertErrorToMessage } from '../../../../services/DTO/saveDTO'
import { resetTimeToZero } from '../../../../services/timeService/dateUtils'
import { showErrorModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { MiscSurchargeDTO, SurchargeDTO } from '../../../../services/DTO/rate/interfaces'

export const individualRateField = {
  ssq: 'customerId',
  bsq: 'vendorId'
}

export const promiseFetchObjectsByIdAndPushToStore = (
  ids: string[],
  apiActionForEachId: any,
  addListToStoreAction: any,
  filterUniqIds?: boolean,
  storeForFilterOfMissed?: any
): Promise<any[]> => {
  let fetchIds = ids && ids.length ? ids : []
  fetchIds = filterUniqIds ? R.uniq(fetchIds) : fetchIds
  fetchIds = storeForFilterOfMissed ? fetchIds.filter(id => id && !storeForFilterOfMissed[id]) : fetchIds

  if (fetchIds.length) {
    const arrayOfActions = fetchIds.map((id: string) => callAPI(apiActionForEachId, id).toPromise())
    return Promise.all(arrayOfActions).then(objects => {
      addListToStoreAction(objects)
      return objects
    })
  } else {
    return Promise.resolve([])
  }
}

export const validateSave = (rateType: RateType, newRates: NewRateDTO[], customerQuote?: TNewCustomerQuotes) => {
  if (customerQuote) {
    if (rateType === RateType.ssq) {
      return (
        Boolean(customerQuote.effectiveDate && customerQuote.expirationDate && customerQuote.type) &&
        (!newRates || newRates.every(({ effectiveDate }) => effectiveDate))
      )
    } else {
      return newRates.length && newRates.every(({ effectiveDate }) => effectiveDate)
    }
  } else {
    return newRates.length && newRates.every(({ effectiveDate }) => effectiveDate)
  }
}

export const matchRates = (rates: NewRateDTO[], isCustomerQuoteParent?: boolean) => {
  if (rates.length === 1) {
    return
  }

  const ratesToCheck: NewRateDTO[] = isCustomerQuoteParent
    ? rates.map(rate => ({ ...rate, deliveryOrderType: undefined, containerTypeId: undefined }))
    : rates

  // if rate is Similar
  const { dispatch } = getStore()
  let similarRatesPresent: boolean = false

  ratesToCheck.forEach((currRate, currIndex) => {
    if (!similarRatesPresent) {
      const r1Start = Date.parse(currRate.effectiveDate)
      const r1End = currRate.expirationDate ? Date.parse(currRate.expirationDate) : null

      ratesToCheck.forEach((item, index) => {
        if (similarRatesPresent || index <= currIndex) {
          return
        }

        const isSameRateType =
          currRate.type === item.type ||
          (currRate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN && item.type === SellSideQuoteRateDTO.TypeEnum.BASE) ||
          (currRate.type === SellSideQuoteRateDTO.TypeEnum.BASE && item.type === SellSideQuoteRateDTO.TypeEnum.ALLIN)

        if (isSameRateType) {
          if (!isSameRules(currRate.ruleIds || [], item.ruleIds || [])) {
            return
          }

          const omitProps: (keyof SellSideQuoteRateDTO)[] = [
            'id',
            'effectiveDate',
            'expirationDate',
            'type',
            'amount',
            'externalId',
            'number',
            'status',
            'version',
            'ruleIds'
          ]
          const isDifferent = !equal(R.omit(currRate, omitProps), R.omit(item, omitProps))

          if (isDifferent) {
            return
          }

          const r2Start = Date.parse(item.effectiveDate)
          const r2End = item.expirationDate ? Date.parse(item.expirationDate) : null

          if (!r1End && !r2End) {
            similarRatesPresent = true
            return
          }

          if (
            (!r1End && (r1Start <= r2Start || (r1Start >= r2Start && r1Start <= r2End))) ||
            (!r2End && (r2Start <= r1Start || (r2Start >= r1Start && r2Start <= r1End)))
          ) {
            similarRatesPresent = true
            return
          }

          if (
            (r1Start >= r2Start && r1Start <= r2End) ||
            (r1End >= r2Start && r1End <= r2End) ||
            (r2Start >= r1Start && r2Start <= r1End) ||
            (r2End >= r1Start && r2End <= r1End)
          ) {
            similarRatesPresent = true
            return
          }
        }
      })
    }
  })

  if (similarRatesPresent) {
    return dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.blue,
            label: 'Ok',
            action: () => {}
            // action: () => updateNewRates(ratesWithWarnings)
          }
        },
        message:
          // tslint:disable:max-line-length
          'You are trying to create several similar Rates'
      })
    )
  }
}

const isSameRules = (rules1: string[], rules2: string[]): boolean => {
  if (rules1.length === 0 && rules2.length === 0) {
    return true
  }

  if (rules1.length === 0 || rules2.length === 0) {
    return false
  }

  return rules1.every(ruleId => rules2.includes(ruleId)) && rules2.every(ruleId => rules1.includes(ruleId))
}

export const checkEffectiveDate = (date: DateISOString, expirationDate: DateISOString) => {
  if (!date) {
    return null
  }

  if (expirationDate && Date.parse(date) > Date.parse(expirationDate)) {
    return null
  }

  return date
}
export const checkExpirationDate = (experationDate: DateISOString, effectiveDate: DateISOString) => {
  if (!experationDate) {
    return null
  }

  const startDateNowParced = Date.parse(resetTimeToZero(null, false, true))
  const experationDateParced = Date.parse(experationDate)
  const effectiveDateParced = Date.parse(effectiveDate)

  if (effectiveDate && experationDateParced < effectiveDateParced) {
    return null
  }

  if (startDateNowParced <= experationDateParced) {
    return experationDate
  }
}

export const calcCustomerQuoteRatesAmount = (
  rates: (AnyRateDTO | SurchargeDTO | MiscSurchargeDTO)[],
  returnOnNullResult?: any
): number | null => {
  return null
  // if (rates.length) {
  //   const mainRate = rates.find(
  //     (rate: any) =>
  //       rate.type &&
  //       (rate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN || rate.type === SellSideQuoteRateDTO.TypeEnum.BASE)
  //   )
  //   if (mainRate) {
  //     return rates.reduce((acc, curr) => acc + calcSurcharge(mainRate.amount, curr, 1), 0)
  //   }
  // }
  // return returnOnNullResult !== undefined ? returnOnNullResult : null
}

export const removeExtraZero = (str: string) => {
  let result = str

  while (result && result[0] === '0') {
    result = result.slice(1)
  }

  return result
}

export const makeGroupOfRates = (rates: AnyRateDTO[]): SellSideQuoteRateGroupDTO | BuySideQuoteRateGroupDTO => {
  const groupData = rates
    .slice()
    .reverse()
    .reduce((acc, curr) => ({ ...acc, ...curr } as AnyRateDTO), {} as AnyRateDTO)
  return {
    id: groupData.id,
    number: rates.length === 1 ? groupData.number : undefined,
    amount: groupData.amount || 0,
    calculationType: groupData.calculationType,
    effectiveDate: groupData.effectiveDate,
    expirationDate: groupData.expirationDate,
    customerId: (groupData as SellSideQuoteRateDTO).customerId,
    vendorId: (groupData as BuySideQuoteRateDTO).vendorId,
    containerTypeId: (groupData as SellSideQuoteRateDTO).containerTypeId
      ? (R.uniq(rates.map(({ containerTypeId }) => containerTypeId)) as any)
      : undefined,
    pickupLocationId: groupData.pickupLocationId,
    returnLocationId: groupData.returnLocationId,
    deliveryCity: groupData.deliveryCity,
    deliveryPostalCode: groupData.deliveryPostalCode,
    deliveryStateId: groupData.deliveryStateId,
    deliveryOrderType: groupData.deliveryOrderType
      ? R.uniq(rates.map(({ deliveryOrderType }: any) => deliveryOrderType))
      : undefined,
    ruleIds: groupData.ruleIds,
    state: (groupData as NewRateDTO).state,
    type: groupData.type,
    loadType: groupData.loadType ? (R.uniq(rates.map(({ loadType }: any) => loadType)) as any) : undefined,
    rates: rates as SellSideQuoteRateDTO[]
  }
}

export const makeGroupsWithDifferentRateTypes = (rates: AnyRateDTO[]): SellSideQuoteRateGroupDTO[] => {
  const groupedByType: Record<SellSideQuoteRateDTO.TypeEnum, AnyRateDTO[]> = {}

  rates.forEach(rate => {
    if (groupedByType[rate.type]) {
      groupedByType[rate.type].push(rate)
    } else {
      groupedByType[rate.type] = [rate]
    }
  })

  return Object.values(groupedByType).map(makeGroupOfRates)
}

export const makeGroupsWithSimilarDifferentRates = (rates: AnyRateDTO[]): SellSideQuoteRateGroupDTO[] => {
  const groupedByType: Record<string, AnyRateDTO[]> = {}

  rates.forEach(rate => {
    const stringOfProps =
      String(rate.type) +
      String(rate.calculationType) +
      JSON.stringify(rate.ruleIds) +
      (rate as any).vendorId +
      (rate as any).customerId +
      String(rate.loadType) +
      JSON.stringify(rate.effectiveDate) +
      JSON.stringify(rate.expirationDate) +
      String(rate.amount)

    if (groupedByType[stringOfProps]) {
      groupedByType[stringOfProps].push(rate)
    } else {
      groupedByType[stringOfProps] = [rate]
    }
  })

  return Object.values(groupedByType).map(makeGroupOfRates)
}

export const getOnlyOneRateOfEachType = (rates: AnyRateDTO[]) => {
  const groupedByType: Record<SellSideQuoteRateDTO.TypeEnum, AnyRateDTO> = {}

  rates.forEach(rate => {
    if (groupedByType[rate.type]) {
      return
    } else {
      groupedByType[rate.type] = rate
    }
  })

  return Object.values(groupedByType)
}

export const makeNewRates = (
  rateType: RateType,
  rate: NewRateDTO,
  appliedFilters: IRateFiltersState,
  makeGroupRates: boolean
): (NewRateDTO | SellSideQuoteRateGroupDTO)[] => {
  const rateTypeField = individualRateField[rateType]
  let filledFilterFields: string[] = []
  const rateCopy = { ...rate }
  // @ts-ignore
  rateCopy[rateTypeField] = rate[rateTypeField] === 'TFF' ? undefined : rate[rateTypeField]

  // >>> fill rate props by filter
  switch (rate.type) {
    case SellSideQuoteRateDTO.TypeEnum.ALLIN:
    case SellSideQuoteRateDTO.TypeEnum.BASE:
      rateCopy.pickupLocationId = oc(appliedFilters).pickupLocationId()
      rateCopy.deliveryCity = oc(appliedFilters).deliveryLocation.city()
      rateCopy.deliveryPostalCode = oc(appliedFilters).deliveryLocation.postalCode()
      rateCopy.deliveryStateId = oc(appliedFilters).deliveryLocation.stateId()
      rateCopy.returnLocationId = oc(appliedFilters).returnLocationId()

      filledFilterFields = ['deliveryOrderType', 'containerTypeId', 'loadType']
      break
    case SellSideQuoteRateDTO.TypeEnum.TOLLS:
      rateCopy.pickupLocationId = oc(appliedFilters).pickupLocationId()
      rateCopy.deliveryCity = oc(appliedFilters).deliveryLocation.city()
      rateCopy.deliveryPostalCode = oc(appliedFilters).deliveryLocation.postalCode()
      rateCopy.deliveryStateId = oc(appliedFilters).deliveryLocation.stateId()
      rateCopy.returnLocationId = oc(appliedFilters).returnLocationId()
      break
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLE:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEHAZ:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEOW:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEREEFER:
      rateCopy.pickupLocationId = oc(appliedFilters).pickupLocationId()
      rateCopy.returnLocationId = oc(appliedFilters).returnLocationId()
      rateCopy.deliveryOrderType = oc(appliedFilters).deliveryOrderType[0]()

      filledFilterFields = ['deliveryOrderType']
      break
    case SellSideQuoteRateDTO.TypeEnum.PREPULL:
    case SellSideQuoteRateDTO.TypeEnum.DEDUCTION:
    case SellSideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER:
      rateCopy.pickupLocationId = oc(appliedFilters).pickupLocationId()
      rateCopy.returnLocationId = oc(appliedFilters).returnLocationId()
      break
    case SellSideQuoteRateDTO.TypeEnum.CONGESTION:
      filledFilterFields = ['deliveryOrderType']
      break
    default:
  }
  // <<<

  // >>> make array of rates
  const makeAllVariantsOfNewRate = (newRate: NewRateDTO): NewRateDTO[] => {
    return (
      filledFilterFields
        // @ts-ignore
        .filter(filterName => appliedFilters[filterName] && appliedFilters[filterName].length > 0)
        .reduce(
          (acc: NewRateDTO[], filterName: string) => {
            let array: NewRateDTO[] = []
            // @ts-ignore
            let filterFieldValue = appliedFilters[filterName]

            if (!makeGroupRates && filterName === 'loadType') {
              filterFieldValue = correctLoadTypeFilter(rateType, filterFieldValue)
            }

            filterFieldValue.forEach((filterValue: string) => {
              array.push(...acc.map(r => ({ ...r, id: createId(), [filterName]: filterValue })))
            })

            // correct rate list
            array = array.map(rateItem => {
              let correctedRate = { ...rateItem }
              if (correctedRate.deliveryOrderType === SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION) {
                correctedRate = R.omit(correctedRate, ['loadType'])
              }

              return correctedRate
            })

            // filter uniq items
            const result: NewRateDTO[] = []
            array.forEach(rateItem => {
              if (!result.some(resultRateItem => equal(R.omit(resultRateItem, ['id']), R.omit(rateItem, ['id'])))) {
                result.push(rateItem)
              }
            })

            return result
          },
          [newRate]
        )
    )
  }
  // <<<

  let newRatesToCreate: NewRateDTO[] = []
  let ratesToGroup = []

  switch (rate.type) {
    case BuySideQuoteRateDTO.TypeEnum.BASE:
    case BuySideQuoteRateDTO.TypeEnum.ALLIN: {
      newRatesToCreate = makeAllVariantsOfNewRate(rateCopy)
      ratesToGroup = [newRatesToCreate]

      if (rateType === RateType.bsq && newRatesToCreate[0].loadType === SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK) {
        ratesToGroup = [
          newRatesToCreate.map(_ => ({ ..._, id: createId(), loadType: BuySideQuoteRateDTO.LoadTypeEnum.DROP })),
          newRatesToCreate.map(_ => ({ ..._, id: createId(), loadType: BuySideQuoteRateDTO.LoadTypeEnum.PICK }))
        ]
      }

      break
    }
    case BuySideQuoteRateDTO.TypeEnum.DEDUCTION:
    case BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER:
    case BuySideQuoteRateDTO.TypeEnum.SHUTTLE:
    case BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ:
    case BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW:
    case BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER: {
      const isBothLocationsFilled = oc(appliedFilters).pickupLocationId() && oc(appliedFilters).returnLocationId()
      if (isBothLocationsFilled) {
        ratesToGroup = [
          makeAllVariantsOfNewRate({ ...rateCopy, id: createId(), pickupLocationId: undefined }),
          makeAllVariantsOfNewRate({ ...rateCopy, id: createId(), returnLocationId: undefined })
        ]

        newRatesToCreate = [...ratesToGroup[0], ...ratesToGroup[1]]
      } else {
        newRatesToCreate = makeAllVariantsOfNewRate(rateCopy)
        ratesToGroup = [newRatesToCreate]
      }

      break
    }
    case SellSideQuoteRateDTO.TypeEnum.CONGESTION:
      const locations = [
        {
          pickupLocationId: oc(appliedFilters).pickupLocationId()
        },
        {
          deliveryCity: oc(appliedFilters).deliveryLocation.city(),
          deliveryPostalCode: oc(appliedFilters).deliveryLocation.postalCode(),
          deliveryStateId: oc(appliedFilters).deliveryLocation.stateId()
        },
        {
          returnLocationId: oc(appliedFilters).returnLocationId()
        }
      ].filter(_ => Object.values(_).some(Boolean))

      ratesToGroup = locations.length
        ? locations.map(locationProps => makeAllVariantsOfNewRate({ ...rateCopy, id: createId(), ...locationProps }))
        : [makeAllVariantsOfNewRate(rateCopy)]

      newRatesToCreate = ratesToGroup.reduce((acc: any, curr: any) => {
        acc.push(...curr)
        return acc
      }, [])
      break
    default:
      newRatesToCreate = makeAllVariantsOfNewRate(rateCopy)
      ratesToGroup = [newRatesToCreate]
  }

  return makeGroupRates ? ratesToGroup.map(makeGroupOfRates as any) : newRatesToCreate
}

export const catchDuplicatesError = (itemsToRequest: any[], getDuplicatedItems: (ids: string[]) => any) => async (
  errors: any
): Promise<{ rejectedItems: any[]; duplicateItems: any[] }> => {
  let rejectedItems: any[] = []
  let duplicateItems: any[] = []

  if (errors && errors.json) {
    await errors.json().then(async (errorMessages: any) => {
      if (errors.status === 406) {
        const rejectedIds: string[] = []
        const duplicateIds: string[] = []
        errorMessages.forEach((message: any) => {
          if (message.duplicateIds && Array.isArray(message.duplicateIds) && message.duplicateIds.length) {
            if (message.externalId) {
              rejectedIds.push(message.externalId)
            }
            duplicateIds.push(...message.duplicateIds)
          }
        })

        rejectedItems = itemsToRequest.filter((item: any) => rejectedIds.includes(item.id))

        if (duplicateIds.length) {
          duplicateItems = await getDuplicatedItems(R.uniq(duplicateIds))
        }
      } else {
        const message = errorMessages.length
          ? oc(errorMessages as any[])([]).reduce((acc, curr) => {
              return acc + `\n` + curr.message
            }, '')
          : oc(errorMessages).message(null)
        showErrorModalWindow({ content: convertErrorToMessage(message) })
      }
    })
  } else {
    showErrorModalWindow({ content: 'Error' })
  }

  return { rejectedItems, duplicateItems }
}
