import * as React from 'react'
import { BusinessPartnerViewDTO, ContactDTO, CustomerViewDTO } from '../api/api'

export interface IGridItemCustomerContext {
  isExpanded: boolean
  customer: CustomerViewDTO
  businessPartner: BusinessPartnerViewDTO
  primaryContact: ContactDTO
}

export const GridItemCustomerContext = React.createContext<IGridItemCustomerContext>({
  isExpanded: null,
  customer: null,
  businessPartner: null,
  primaryContact: null
})
