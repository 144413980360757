import * as React from 'react'
import styled from 'styled-components'
import { DispatchDeliveryOrderSearchDTO } from '../../../../api/origin/business-logic'
import { GridItemSpinner } from '../../../UI/Spinner/Spinner'
import { ChannelBrowserSearchResultItem } from './ChannelBrowserSearchResultItem'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelsMeta } from '../../../../store/select/communicationHubSelect'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import {
  ChannelSorting,
  ICHubChannel,
  IDispatchDeliveryOrderSearchResult
} from '../../../../store/reducers/communicationHub/interfaces'

type OwnProps = {
  fetching: boolean
  searchResults: DispatchDeliveryOrderSearchDTO[]
  searchValue: string
  sorting: ChannelSorting
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ChannelBrowserBody = React.memo((props: Props) => {
  const { fetching, searchResults, searchValue, sorting } = props
  const dispatch = useAppDispatch()
  const channelsMeta = useAppSelector(selectCommunictaionHubChannelsMeta)
  const highlight = React.useMemo(() => searchValue.trim(), [searchValue])

  const onSearchResultItemClick = React.useCallback((channel: ICHubChannel) => {
    leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.pushActiveChannel({ channel })))
  }, [])

  const RenderSearchResultList = React.useMemo(() => {
    if (fetching) {
      return <GridItemSpinner />
    }

    const searchResultsWithUpdatedAt: IDispatchDeliveryOrderSearchResult[] = []
    const searchResultsWithoutUpdatedAt: IDispatchDeliveryOrderSearchResult[] = []

    searchResults.forEach(res => {
      const channelMeta = channelsMeta[res.id]

      if (channelMeta && channelMeta.updatedAt) {
        searchResultsWithUpdatedAt.push({ ...res, updatedAt: channelMeta.updatedAt })
      } else {
        searchResultsWithoutUpdatedAt.push(res)
      }
    })

    searchResultsWithUpdatedAt.sort((a, b) => {
      const channelA = channelsMeta[a.id]
      const channelB = channelsMeta[b.id]

      if (sorting === ChannelSorting.recentlyUpdatedFirst) {
        return Date.parse(channelB.updatedAt) - Date.parse(channelA.updatedAt)
      } else if (sorting === ChannelSorting.recentlyUpdatedLast) {
        return Date.parse(channelA.updatedAt) - Date.parse(channelB.updatedAt)
      }
    })

    return searchResultsWithUpdatedAt
      .concat(searchResultsWithoutUpdatedAt)
      .map(searchItem => (
        <ChannelBrowserSearchResultItem
          key={searchItem.id}
          searchItem={searchItem}
          onClick={onSearchResultItemClick}
          highlight={highlight}
        />
      ))
  }, [fetching, searchResults, sorting, channelsMeta])

  return <Container data-id={'ChannelBrowser-SearchResults'}>{RenderSearchResultList}</Container>
})

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 32px 14px 0;
  overflow: auto;
`
