import { oc } from 'ts-optchain'
import { getListsState } from '../../../store'
import { RateDTO, SurchargeDTO } from '../../DTO/rate/interfaces'
import { createId } from '../../utils'

export const generateSurcharge = (rate: RateDTO): SurchargeDTO => {
  const quote: SurchargeDTO = {
    id: createId(),
    automatic: undefined,
    rateId: rate.id,
    amount: rate.amount,
    activityId: undefined,
    calculationType: rate.calculationType,
    quantity: 0,
    type: rate.type as any
  }

  if (rate.ruleIds && rate.ruleIds.length) {
    const storeRules = getListsState().rule
    const rules = rate.ruleIds
      .map(ruleId => oc(storeRules[ruleId]).name())
      .filter(Boolean)
      .join(', ')

    if (rules.length) {
      quote.rules = rules
    }
  }

  return quote
}
