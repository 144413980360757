import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from '../../../../../styles/styledComponents'
import { TabDTO } from '../../../../../store/reducers/tabs/interfaces'
import { createTab } from '../../../../../store/reducers/tabs/functions'
import { generateTabExpandedItem } from '../../../../../services/functions/generate/generateTabExpandedItem'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'

export const DeliveryOrderNumberColumn = () => {
  const { deliveryOrder } = useGridItemDDO()

  if (!deliveryOrder) {
    return null
  }

  const { id, completionMonitor } = deliveryOrder
  const doNumber = oc(deliveryOrder).number()
  const process = completionMonitor ? completionMonitor.replace('/', ' of ') + ' processed' : ''

  return (
    <div>
      <DONumber
        onClick={event => {
          event.stopPropagation()
          createTab(TabDTO.Type.deliveryOrder, {
            label: 'DO #' + doNumber,
            expandedItem: generateTabExpandedItem({ id, entityType: EntityType.deliveryOrder })
          })
        }}
      >
        {doNumber}
      </DONumber>
      <CompletionMonitor>{process}</CompletionMonitor>
    </div>
  )
}

const DONumber = styled.span`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #4555d0;
  cursor: pointer;
`
const CompletionMonitor = styled.p`
  display: block;
  font-size: 10px;
  line-height: 11px;
  color: #445366;
  font-weight: 300;
  margin-top: 2px;
`
