import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'
import { DocumentMetadataDTO, DocumentValidationDTO, TroubleTicketDTO } from '../../../../../../../../../../../api/api'
import { DocumentDTO } from '../../../../../../../../../../../api/origin/document-service'
import { openFileLink } from '../../../../../../../../../../../services/filesService'
import { FileCategory, IAttachment } from '../../../../../../../../../../../services/filesService/interfaces'
import { preventEvent } from '../../../../../../../../../../../services/functions/basic'
import theme from '../../../../../../../../../../../styles/theme'
import { ActionButton } from '../../../../../../../../../../UI/Buttons/ActionButton'
import { ActivityDocumentPopupView } from '../../../../../../../../interfaces'
import { Title } from '../styles'
import { ActivityDocumentStatus } from './interfaces'
import { CheckmarkCircleIcon } from '../../../../../../../../../../UI/icons/CheckmarkIcon'
import { RefreshIcon } from '../../../../../../../../../../UI/icons/RefreshIcon'
import { validateFile } from '../../../../../../../../../../../services/filesService/validateFile'
import { oc } from 'ts-optchain'
import { useUploadFiles } from '../../../../../../../../../../../hooks/useUploadFiles'

const fileTypes: string[] = ['pdf', 'jpeg', 'png', 'jpg', 'bmp']

type Props = {
  view: ActivityDocumentPopupView
  isActivityEditable: boolean
  isTroubleTicket: boolean
  activityId: string
  activityDocumentStatus: ActivityDocumentStatus
  documentType: TroubleTicketDTO.TypeEnum | FileCategory.TIR | FileCategory.POD
  uploadingFileUrl: string
  file: IAttachment
  metadata: DocumentMetadataDTO
  validationErrorMessages: string[] | undefined
  updateActivityDocumentState: (state: {
    uploadedDocumentData?: DocumentDTO
    validatedManually?: boolean
    uploadingFile?: IAttachment
    view?: ActivityDocumentPopupView
  }) => void
  onValidateManually: () => void
}

export const ActivityDocumentFileUploader = React.memo((props: Props) => {
  const {
    view,
    isActivityEditable,
    isTroubleTicket,
    activityId,
    activityDocumentStatus,
    documentType,
    uploadingFileUrl,
    file,
    validationErrorMessages,
    metadata,
    onValidateManually,
    updateActivityDocumentState
  } = props
  const { onFilesSelect, uploadingFilesState } = useUploadFiles({
    streamId: activityId + documentType,
    // @ts-ignore
    documentType,
    metadata,
    onFileUploaded: _document => {
      const state: any = {
        uploadedDocumentData: _document as DocumentDTO,
        validatedManually: undefined
      }

      if (
        view === ActivityDocumentPopupView.INFO ||
        (view === ActivityDocumentPopupView.CREATETROUBLETICKET &&
          oc(_document as DocumentDTO).validation.status() === DocumentValidationDTO.StatusEnum.ACCEPTED)
      ) {
        state.view = ActivityDocumentPopupView.CREATEDOCUMENT
      }

      updateActivityDocumentState(state)
    },
    onFileUploadError: () => {
      updateActivityDocumentState({
        uploadingFile: undefined,
        uploadedDocumentData: undefined,
        validatedManually: undefined
      })
    }
  })

  React.useEffect(() => {
    if (uploadingFilesState && uploadingFilesState[0]) {
      updateActivityDocumentState({
        uploadingFile: uploadingFilesState[0],
        uploadedDocumentData: undefined,
        validatedManually: undefined
      })
    }
  }, [uploadingFilesState])

  let title = 'Upload'

  if (documentType === FileCategory.TIR || documentType === FileCategory.POD) {
    title += ` ${documentType}`
  }

  const RenderStatusIcon = () => {
    switch (activityDocumentStatus) {
      case ActivityDocumentStatus.UPLOADING:
        return <IconRotation children={RefreshIcon} />
      case ActivityDocumentStatus.VERIFICATIONACCEPTED:
      case ActivityDocumentStatus.VALIDATEDMANUALLY:
        return <CheckmarkCircleIcon />
      case ActivityDocumentStatus.VERIFICATIONFAILD:
        return <i className="mdi mdi-alert-circle" style={{ color: theme.colors.defaultRed }} />
      default:
        return null
    }
  }

  const RenderDocumentPreview = () => {
    switch (activityDocumentStatus) {
      case ActivityDocumentStatus.UPLOADING:
        return validateFile(file).contentType.isImage ? (
          <img src={file.thumb || file.url} alt="Documnet" />
        ) : (
          <NotImageDocumentIcon className={cn('mdi mdi-file')} />
        )
      case ActivityDocumentStatus.VERIFICATIONACCEPTED:
      case ActivityDocumentStatus.VERIFICATIONFAILD:
      case ActivityDocumentStatus.VALIDATEDMANUALLY:
        return (
          <DocumentLink className={'document-link'} href={oc(file).url()} target={'_blank'}>
            {validateFile(file).contentType.isImage ? (
              <img src={uploadingFileUrl || oc(file).thumb() || oc(file).url()} alt="Documnet" />
            ) : (
              <NotImageDocumentIcon className={cn('mdi mdi-file')} />
            )}
          </DocumentLink>
        )
      default:
        return null
    }
  }

  const RenderDocumentDetais = () => {
    switch (activityDocumentStatus) {
      case ActivityDocumentStatus.UPLOADING:
      case ActivityDocumentStatus.VERIFICATIONACCEPTED:
      case ActivityDocumentStatus.VALIDATEDMANUALLY: {
        const label =
          activityDocumentStatus === ActivityDocumentStatus.VALIDATEDMANUALLY
            ? ActivityDocumentStatus.VERIFICATIONACCEPTED
            : activityDocumentStatus
        return <UploadFileStatusLabel children={`${documentType} ${label}`} />
      }
      case ActivityDocumentStatus.VERIFICATIONFAILD:
        return (
          <div>
            <UploadFileStatusLabel children={`${documentType} ${activityDocumentStatus}`} />
            {Boolean(validationErrorMessages && validationErrorMessages.length) && (
              <UploadFileErrorMessages>
                {validationErrorMessages.map((errorMessage, i) => (
                  <li key={i} children={errorMessage} />
                ))}
              </UploadFileErrorMessages>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <UploadTitleAndActionsContainer>
        <Title
          className={
            view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE ||
            view === ActivityDocumentPopupView.CREATEDOCUMENT ||
            (view === ActivityDocumentPopupView.INFO && !isTroubleTicket)
              ? 'required'
              : undefined
          }
          style={{ marginBottom: 12 }}
        >
          {title}
        </Title>
        <UploadFileActions>
          {((view === ActivityDocumentPopupView.INFO && isActivityEditable && !isTroubleTicket) ||
            view === ActivityDocumentPopupView.CREATEDOCUMENT ||
            view === ActivityDocumentPopupView.CREATETROUBLETICKET ||
            view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) &&
            file && (
              <label>
                <ActionButton
                  onlyText={true}
                  styles={{ height: 25, minHeight: 25, color: theme.colors.basicBlueColor }}
                  onClick={undefined}
                >
                  Reload
                </ActionButton>
                <input
                  type={'file'}
                  accept={fileTypes.map(type => ('.' + type).toLowerCase()).join(',')} // {'application/pdf, image/*'}
                  onChange={e => onFilesSelect(e.target.files)}
                  hidden={true}
                />
              </label>
            )}
          {(view === ActivityDocumentPopupView.CREATEDOCUMENT ||
            view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) &&
            activityDocumentStatus === ActivityDocumentStatus.VERIFICATIONFAILD && (
              <ActionButton
                filled={true}
                round={true}
                styles={{ height: 25, backgroundColor: theme.colors.orange, borderColor: theme.colors.orange }}
                onClick={onValidateManually}
              >
                Validate Manually
              </ActionButton>
            )}
          {view === ActivityDocumentPopupView.CREATEDOCUMENT &&
            activityDocumentStatus === ActivityDocumentStatus.VERIFICATIONFAILD && (
              <ActionButton
                filled={true}
                round={true}
                styles={{
                  height: 25,
                  backgroundColor: theme.colors.defaultRed,
                  borderColor: theme.colors.defaultRed,
                  marginLeft: 10
                }}
                onClick={() => updateActivityDocumentState({ view: ActivityDocumentPopupView.CREATETROUBLETICKET })}
              >
                Create TT
              </ActionButton>
            )}
        </UploadFileActions>
      </UploadTitleAndActionsContainer>
      <div
        onClickCapture={
          file
            ? (event: any) => {
                preventEvent(event)
                const linkButton = event.target.closest('a.document-link')

                if (linkButton && linkButton.href) {
                  openFileLink(linkButton.href)
                }
              }
            : undefined
        }
      >
        <FileUploader
          classes={'drop_area drop_zone'}
          handleChange={(_file: File) => onFilesSelect([_file] as any)}
          types={fileTypes}
          dropMessageStyle={{
            color: 'white',
            backgroundColor: theme.colors.basicBlueColor,
            opacity: 0.8,
            fontSize: 24
          }}
          onTypeError={(error: any) => {
            // tslint:disable-next-line:no-console
            console.error(error)
            toast.error(
              <>
                <i className={'mdi mdi-close'} />
                {error}
              </>
            )
          }}
        >
          <UploadFileContainer
            className={cn('UploadFileInnerContainer', {
              empty: !file,
              verificationProcessing: activityDocumentStatus === ActivityDocumentStatus.UPLOADING,
              verificationAccepted: activityDocumentStatus === ActivityDocumentStatus.VERIFICATIONACCEPTED,
              verificationFailed: activityDocumentStatus === ActivityDocumentStatus.VERIFICATIONFAILD,
              verificationAcceptedManually: activityDocumentStatus === ActivityDocumentStatus.VALIDATEDMANUALLY
            })}
          >
            {!file ? (
              <UploadFileButton />
            ) : (
              <>
                <RenderStatusIcon />
                <RenderDocumentPreview />
                <RenderDocumentDetais />
              </>
            )}
          </UploadFileContainer>
        </FileUploader>
      </div>
    </>
  )
})

const UploadTitleAndActionsContainer = styled.div`
  display: flex;
`
const UploadFileActions = styled.div`
  display: flex;
  margin-left: auto;
  transform: translateY(-6px);
`
const UploadFileButton = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  border-radius: 4px;
  border: 1px dashed #d8d8d8;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 26px;
    height: 1px;
    background-color: #b3bbc7;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 26px;
    background-color: #b3bbc7;
  }

  &:hover {
    border-color: ${theme.colors.basicBlueColor};

    &:before,
    &:after {
      background-color: ${theme.colors.basicBlueColor};
    }
  }
`
const UploadFileContainer = styled.div`
  min-height: 100px;
  align-items: center;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 20px 67px 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  padding: 10px;

  svg {
    width: 16px;
  }

  i {
    font-size: 20px;
  }

  img {
    width: 100%;
    height: 60px;
    object-fit: contain;
    user-select: none;
  }

  &.empty {
    grid-template-columns: 1fr;
    border-radius: 8px;
    border: none;
    padding: 0;

    div {
      border-style: dashed;
      border-width: 1px;
    }
  }

  &.verificationProcessing {
    background: rgba(207, 166, 62, 0.1);
    border: 1px solid rgba(207, 166, 62, 0.1);
  }

  &.verificationAccepted {
    background: rgba(218, 243, 219, 0.5);
    border: 1px solid rgba(218, 243, 219, 0.5);
  }

  &.verificationFailed {
    background: rgba(255, 228, 222, 0.5);
    border: 1px solid ${theme.colors.defaultRed};
  }

  &.verificationAcceptedManually {
    background: rgba(255, 228, 222, 0.5) !important;
    border: 1px solid rgba(255, 228, 222, 0.5) !important;

    i {
      color: ${theme.colors.orange};
    }

    svg path {
      fill: ${theme.colors.orange};
    }
  }

  &:nth-of-type(2) ${UploadFileButton} {
    border: none !important;

    &:before,
    &:after {
      content: none;
    }
  }
`

const IconRotation = styled.div`
  svg {
    animation: ItemRotation 2s linear infinite;
  }
`
const NotImageDocumentIcon = styled.i`
  font-size: 56px !important;
  color: ${theme.colors.basicBlueColor};
`
const DocumentLink = styled.a`
  display: flex;
  align-items: center;
  justify-self: center;
  height: 100%;
`
const UploadFileStatusLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #445366;
`
const UploadFileErrorMessages = styled.ul`
  font-size: 10px;
  line-height: 1.2;
  color: ${theme.colors.defaultRed};
  list-style-type: disc;
  padding-left: 10px;
  margin-top: 4px;
`
