import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import theme from '../../../../../../../../styles/theme'

type Props = {
  active: boolean
  style?: React.CSSProperties
}

export const ActivityNotesButton = React.memo((props: Props) => {
  const { style, active } = props
  return <NotesButton className={cn('mdi mdi-file-edit', { active })} style={style} />
})

const NotesButton = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #b2b2b2;
  border-radius: 4px;
  padding: 2px;
  margin-left: auto;

  &.active {
    color: ${theme.colors.basicBlueColor};
  }
`
