import { oc } from 'ts-optchain'
import { LocationDetailsDto } from '../../../api/origin/location'
import { LocationDTO, LocationViewDTO } from '../../../api/origin/business-logic'
import { getAddressState } from '../../functions/get/getAddressState'
import { getStateMapping } from '../../../store'
import { isFullObject } from '../../functions/test/isFullObject'

export const combineLocations = (databaseLocation: LocationViewDTO, googleLocation: LocationDetailsDto) => ({
  ...databaseLocation,
  address: {
    ...databaseLocation.address,
    postalCode: oc(googleLocation).address.postalCode(null),
    street: oc(googleLocation).address.street(null),
    street2: oc(googleLocation).address.street2(null),
    stateId: getAddressState({
      codes: {
        stateCode: oc(googleLocation).address.stateCode(null),
        countryCode: oc(googleLocation).address.countryCode(null)
      }
    }).id,
    city: oc(googleLocation).address.city(null)
  },
  latitude: oc(googleLocation).latitude(null),
  longitude: oc(googleLocation).longitude(null),
  placeId: oc(googleLocation).placeId(null)
})

export const makeLocationTitle = (location: LocationViewDTO | LocationDTO) => {
  if (!location) {
    return ''
  }

  const { name, shortName, code, address } = location
  const { city, stateId, postalCode } = address || {}
  const stateCode = stateId ? oc(getStateMapping())[stateId].code('') : ''

  const result = [
    isFullObject(location) ? code || name : shortName,
    city,
    [stateCode, postalCode].filter(Boolean).join(' ')
  ]
    .filter(Boolean)
    .join(', ')

  return result.length > 1 ? result : ''
}

export const makeLocationLabel = (location: LocationViewDTO | LocationDTO, isShortName?: boolean): string => {
  if (!location) {
    return ''
  }

  if (isShortName) {
    return isFullObject(location)
      ? location.code || location.name || makeLocationTitle(location)
      : location.shortName || location.longName || location.name
  } else {
    return isFullObject(location)
      ? makeLocationTitle(location)
      : [location.shortName, location.cityStateZip].filter(Boolean).join(', ')
  }
}
