import { OngoingActivityGroupDTO } from '../../../../api/origin/business-logic/api'
import { DateISOString, DateTimeRangeDTO } from '../../../../api/origin/communication-hub-service'
import { ITab, TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { TSortOngoingActivityGroups } from '../functions/sortOngoingActivityGroups'

export enum SchedulerSortBy {
  date = 'Date',
  deliveryLocationCity = 'Delivery Location City',
  deliveryStageDriver = 'Driver: Delivery Stage',
  driverAllStages = 'Driver: All Stages',
  driverActivities = 'Driver: Activities P-A-C'
}

export enum SchedulerFilter {
  dispatchDeliveryOrderNumber = 'dispatchDeliveryOrderNumber',
  dispatchDeliveryOrderId = 'dispatchDeliveryOrderId',
  activityGroupDate = 'activityGroupDate',
  grouping = 'grouping',
  specificDate = 'specificDate',
  syncWithRouteBuilder = 'syncWithRouteBuilder'
}

export interface IDateActivityGrid {
  timeGroup: {
    [id: string]: OngoingActivityGroupDTO[]
  }
  rowsNumber: number
  headersNumber: number
  kpi: {
    cutoff: number
    lfd: number
    pickupCount: number
    deliveryCount: number
    returnCount: number
  }
}

export type ISchedulerTabData = {
  showMissed: boolean
  showNow: boolean
  favoriteItemIds: {
    [ongoingActivityGroupId: string]: boolean
  }
}

export type ISchedulerTab = ITab & {
  data?: ISchedulerTabData
  uiSettings: TabDTO.UISettings & {
    filter: (
      | { column: SchedulerFilter.dispatchDeliveryOrderNumber; value: string }
      | { column: SchedulerFilter.dispatchDeliveryOrderId; value: string }
      | { column: SchedulerFilter.specificDate; value: DateISOString }
      | { column: SchedulerFilter.activityGroupDate; value: DateTimeRangeDTO }
      | { column: SchedulerFilter.grouping; value: OngoingActivityGroupDTO.GroupingEnum }
      | { column: SchedulerFilter.syncWithRouteBuilder; value: boolean })[]
    sorting: SchedulerSortBy
  }
}
export interface ISchedulerTabFilterMapping {
  [SchedulerFilter.dispatchDeliveryOrderNumber]: number
  [SchedulerFilter.dispatchDeliveryOrderId]: string
  [SchedulerFilter.specificDate]: DateISOString
  [SchedulerFilter.activityGroupDate]: DateTimeRangeDTO
  [SchedulerFilter.grouping]: OngoingActivityGroupDTO.GroupingEnum
  [SchedulerFilter.syncWithRouteBuilder]: boolean
}

export type SortedOngoingActivityGroups = {
  sortBy: SchedulerSortBy
  missed: TSortOngoingActivityGroups
  activeDates: string[]
}
