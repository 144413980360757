import * as React from 'react'
import theme from '../../../../../../../styles/theme'
import { MaintenanceHeader, ColumnTitles, Row, AddRow, Date } from '../../General/styledComponents'
import { Delete } from '../../../../../../UI/styledComponents'
import { GridColumn, Container } from '../../../../../../UI/GridSystem'
import { Select } from '../../../../../../UI/DataFields/Select'
import { ViewingObjectFileUploader } from '../../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import {
  MaintenanceInspectionDTO,
  EquipmentDTO,
  DateISOString,
  PowerUnitViewDTO
} from '../../../../../../../api/origin/business-logic'
import styled from '../../../../../../../styles/styledComponents'
import { powerUnitLists } from '../../../../../../../services/select/powerUnitLists'
import { DateTimePicker } from '../../../../../../UI/DataFields/DateTimePicker/views'

const MaintenanceContainer = styled(GridColumn)``

type Props = {
  powerUnit: PowerUnitViewDTO
  addMaintenance: () => void
  deleteMaintenance: (id: string) => void
  changeMaintenance: (maintenance: MaintenanceInspectionDTO) => void
  changeMaintenanceInspectionFrequency: (
    maintenanceInspectionFrequency: EquipmentDTO.MaintenanceInspectionFrequencyEnum
  ) => void
  setFetching: (isFetching: boolean | string) => void
}

export const Maintenance = (props: Props) => {
  const {
    powerUnit,
    addMaintenance,
    deleteMaintenance,
    changeMaintenance,
    changeMaintenanceInspectionFrequency,
    setFetching
  } = props
  return (
    <MaintenanceContainer
      columns={28}
      maxColumns={40}
      flexBasis={'auto'}
      isGrid={true}
      background={theme.colors.lightGrayBackground}
      padding={{ top: 40, left: 20, right: 20 }}
    >
      <MaintenanceHeader rows={7} alignCenter={true} margin={{ bottom: 30 }}>
        <span>Maintenance Inspection</span>
        <Container columns={11} isFixed={true}>
          <Select
            title={'Frequency'}
            required={true}
            placeholder={'Select Frequency'}
            selectedValue={powerUnit.maintenanceInspectionFrequency}
            list={powerUnitLists.maintenanceInspectionFrequency}
            onSelect={changeMaintenanceInspectionFrequency}
          />
        </Container>
      </MaintenanceHeader>

      <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 20 }}>
        <Container className={'required'} columns={7}>
          Inspection Date
        </Container>
        <Container className={'required'} columns={7}>
          Exp. Date
        </Container>
        <Container className={'required'} columns={6}>
          Doc. Image
        </Container>
      </ColumnTitles>

      {powerUnit.maintenanceInspections.map(maintenance => (
        <Row rows={9} margin={{ bottom: 10 }} key={maintenance.id}>
          <Date columns={7}>
            <DateTimePicker
              required={true}
              date={maintenance.performedDate}
              // @ts-ignore
              onChange={performedDate => changeMaintenance({ ...maintenance, performedDate })}
            />
          </Date>
          <Date columns={7}>
            <DateTimePicker
              required={true}
              date={maintenance.expirationDate}
              // @ts-ignore
              onChange={expirationDate => changeMaintenance({ ...maintenance, expirationDate })}
            />
          </Date>
          <GridColumn columns={6}>
            <ViewingObjectFileUploader
              required={true}
              uploadingFileId={maintenance.id + ' maintenance.documentFile'}
              file={maintenance.documentFile}
              onFileUploaded={(documentFile, modifiedObject) => ({
                ...modifiedObject,
                maintenanceInspections: (modifiedObject as PowerUnitViewDTO).maintenanceInspections.map(item =>
                  item.id === maintenance.id ? { ...item, documentFile } : item
                )
              })}
              onClearFile={() => changeMaintenance({ ...maintenance, documentFile: undefined })}
            />
          </GridColumn>
          <Delete
            className="mdi mdi-delete"
            columns={2}
            isFixed={true}
            onClick={() => deleteMaintenance(maintenance.id)}
          />
        </Row>
      ))}

      <AddRow rows={9} alignCenter={true} justifyCenter={true} margin={{ bottom: 20 }} onClick={() => addMaintenance()}>
        Add Inspection
      </AddRow>
    </MaintenanceContainer>
  )
}
