import * as React from 'react'
import { BusinessPartnerViewDTO, SubClientViewDTO } from '../api/api'

export interface ISubClientsTabContext {
  isModifiedMode: boolean
  createSubClient: () => void
  updateSubClientField: (subClient: SubClientViewDTO) => (prop: keyof SubClientViewDTO) => (value: any) => void
  deleteSubClient: (props: { subClient: SubClientViewDTO; businessPartner: BusinessPartnerViewDTO }) => void
}

export const SubClientsTabContext = React.createContext<ISubClientsTabContext>({
  isModifiedMode: false,
  createSubClient: null,
  updateSubClientField: null,
  deleteSubClient: null
})
