import { RootState } from '..'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../api/origin/qmp-service'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectRate = (id: string) => (
  state: RootState
): SellSideQuoteRateDTO | BuySideQuoteRateDTO | undefined => {
  return state.lists[EntityType.rate][id]
}

// SELECT MANY
export const selectRates = (state: RootState): Record<string, SellSideQuoteRateDTO | BuySideQuoteRateDTO> => {
  return state.lists[EntityType.rate]
}
