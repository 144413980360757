import * as React from 'react'
import { StyledWarning, Icon, Message, Title, Description, Actions, Action } from './styles'
import { NewRateDTO } from '../../../../../../interfaces'
import { Portal } from 'react-portal'

type Props = {
  id?: string
  rowRef: any
  rate: NewRateDTO
  onMouseEnter: () => void
  onMouseLeave: () => void
  onCancel: () => void
  onEdit: () => void
  onSaveAnyway: () => void
}

export const RateWarning = (props: Props) => {
  const {
    id,
    rowRef,
    rate: { duplicateIds, warningMessage, warningMessageDescription, state },
    onEdit,
    onCancel,
    onSaveAnyway,
    onMouseEnter,
    onMouseLeave
  } = props

  if (!(rowRef && rowRef.current)) {
    return null
  }

  const rowProps = rowRef.current.getBoundingClientRect()
  const title = warningMessage || ''
  const description = warningMessageDescription || ''

  return Boolean(title || description) ? (
    <Portal>
      <StyledWarning
        id={id}
        style={{
          zIndex: 9,
          position: 'fixed',
          top: rowProps.bottom - 2,
          left: rowProps.left
        }}
        className={'warning-box'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Icon className={'mdi mdi-close-box'} />
        <Message>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Actions>
            {duplicateIds ? (
              <>
                <Action onClick={onCancel}>Remove</Action>
                <Action onClick={onEdit}>Edit</Action>
                <Action className={'blue bold'} onClick={onSaveAnyway}>
                  Save Anyway
                </Action>
              </>
            ) : (
              <>
                <Action onClick={onCancel}>Remove</Action>
                <Action onClick={onEdit}>Edit</Action>
              </>
            )}
          </Actions>
        </Message>
      </StyledWarning>
    </Portal>
  ) : null
}
