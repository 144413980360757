import styled, { withProps } from '../../styles/styledComponents'

const column = {
  height: 5,
  width: 20
}

interface IGridProps {
  isFixed?: boolean
  columns?: number
  maxColumns?: number
  maxWidth?: number
  minWidth?: number
  rows?: number
  minRows?: number
  maxRows?: number
  margin?: {
    top?: number
    right?: number
    bottom?: number
    left?: number | 'auto'
  }
  padding?: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }
  flexBasis?: string
  flexStart?: boolean
  flexEnd?: boolean
  alignCenter?: boolean
  justifyCenter?: boolean
  isGrid?: boolean
  cursor?: string
  index?: number
  background?: string
  flexClear?: boolean
  disableUserSelect?: boolean
}

// @ts-ignore
export const GridColumn = withProps<IGridProps, HTMLDivElement>(styled.div)`
  z-index: ${props => props.index};
  position: relative;
  display: flex;
  ${props => (props.isFixed ? `max-width: ${props.columns * column.width}px` : 'flex: 1 1 100%')};
  ${props => props.flexStart && 'align-items: flex-start;'}
  ${props => props.flexEnd && 'align-items: flex-end;'}
  ${props => props.alignCenter && 'align-items: center;'}
  ${props => props.justifyCenter && 'justify-content: center;'}
  ${props => props.isGrid && 'flex-direction: column;'}
  flex-basis: ${props => props.flexBasis};
  min-width: ${props => (props.columns || 1) * column.width}px;
  min-width: ${props => (props.minWidth ? props.minWidth + 'px' : null)};
  max-width: ${props => (props.maxColumns ? props.maxColumns * column.width + 'px' : null)};
  max-width: ${props => (props.maxWidth ? props.maxWidth + 'px' : null)};
  ${props =>
    props.rows
      ? `
  min-height: ${props.rows * column.height}px;
  height: ${props.rows * column.height}px;
  max-height: ${props.rows * column.height}px; 
  `
      : null};
  min-height: ${props => (props.minRows ? props.minRows * column.height + 'px' : null)};
  max-height: ${props => (props.maxRows ? props.maxRows * column.height + 'px' : null)};
  background: ${props => props.background};
  margin: ${props =>
    props.margin
      ? `${props.margin.top || 0}px ${props.margin.right || 0}px ${props.margin.bottom || 0}px ${props.margin.left ||
          0}px`
      : null};
  padding: ${props =>
    props.padding
      ? `${props.padding.top || 0}px ${props.padding.right || 0}px ${props.padding.bottom || 0}px ${props.padding
          .left || 0}px`
      : null};
  cursor: ${props => props.cursor};
  ${props => props.disableUserSelect && 'user-select: none;'}
  ${props => props.flexClear && 'flex: unset;'}
`

export const Container = styled(GridColumn)``
export const Column = styled(GridColumn)``
export const DefaultColumn = styled(GridColumn)`
  position: static;
`
export const ColumnRow = styled(GridColumn)`
  height: 55px;
  max-height: 55px;
  min-height: 55px;
`

export const ColumnRowMini = styled(GridColumn)`
  margin: 10px 0;
`
