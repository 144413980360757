export const downloadFile = (props: { url: string; contentType?: string }) => {
  const { url, contentType } = props

  try {
    const a = document.createElement('a')
    a.href = url
    a.download = 'true'
    a.target = '_blank'
    a.innerHTML = 'download file'
    a.type = contentType
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (e) {}
}
