import { activityListCollector, ongoingActivityGroupsCollector } from '../DTO/activity/functions'
import { websocketCollector } from './collectors'
import { QMPAddObjectsToStoreAndTabs, QMPObjectType, QMPUpdateStoreObject } from './qmpActions'
import { tryAddStreetTurnsToStore } from '../DTO/streetTurn/epics'
import { debuggingMode, consoleLogStyles } from '../debug'
import { WSProps, WebsocketEvent } from './interfaces'
import { updateStoreObjectsWithTabsItemList } from './actions'
import { movesAction } from './movesActions'
import { getListsState } from '../../store'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../store/reducers/lists/functions/pushListItemsToStore'
import {
  BusinessPartnerViewDTO,
  ContainerViewDTO,
  CustomerViewDTO,
  DispatchDeliveryOrderViewDTO,
  DriverViewDTO,
  EquipmentDTO,
  LocationViewDTO,
  PowerUnitViewDTO,
  SteamShipLineViewDTO,
  SubClientViewDTO
} from '../../api/api'
import { oc } from 'ts-optchain'
import { correctDriverPosition } from '../functions/correctDriverPosition'

const activityListWebsocket = activityListCollector()
const ongoingActivityGroupsWebsocket = ongoingActivityGroupsCollector()

const equipmentCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'equipment',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: EquipmentDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.equipment]: items } })
      }
    })
})
const powerUnitCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'powerUnit',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: PowerUnitViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.powerUnit]: items } })
      }
    })
})
const locationCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'location',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: LocationViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.location]: items } })
      }
    })
})
const contactCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'contact',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: ContainerViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.contact]: items } })
      }
    })
})
const businessPartnerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'businessPartner',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: BusinessPartnerViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.businessPartner]: items } })
      }
    })
})
const driverCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'driver',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      listState: getListsState().driver,
      actionToUpdate: (items: DriverViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.driver]: items } })
      }
    })
})
const steamShipLineCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'steamShipLine',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: SteamShipLineViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.steamShipLine]: items } })
      }
    })
})
const containerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'container',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: ContainerViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.container]: items } })
      }
    })
})
const subClientCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'subClient',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: SubClientViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.subClient]: items } })
      }
    })
})
const customerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'customer',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: CustomerViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.customer]: items } })
      }
    })
})
const dispatchDeliveryOrderCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'dispatchDeliveryOrder',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: (items: DispatchDeliveryOrderViewDTO[]) => {
        return pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: items } })
      }
    })
})
const deliveryOrderCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    return pushListItemsToStore({ update: { [EntityType.deliveryOrder]: addItems.concat(updateItems) } })
  }
})
const sellSideQuoteRateCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: async ({ addItems, updateItems }) => {
    await pushListItemsToStore({ update: { [EntityType.rate]: addItems.concat(updateItems) } })
    QMPAddObjectsToStoreAndTabs(QMPObjectType.ssqRates, addItems)
    QMPUpdateStoreObject(QMPObjectType.ssqRates, updateItems)
  }
})
const buySideQuoteRateCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: async ({ addItems, updateItems }) => {
    await pushListItemsToStore({ update: { [EntityType.rate]: addItems.concat(updateItems) } })
    QMPAddObjectsToStoreAndTabs(QMPObjectType.bsqRates, addItems)
    QMPUpdateStoreObject(QMPObjectType.bsqRates, updateItems)
  }
})
const customerQuoteCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    QMPAddObjectsToStoreAndTabs(QMPObjectType.customerQuotes, addItems)
    QMPUpdateStoreObject(QMPObjectType.customerQuotes, updateItems)
  }
})
const sellSideQuoteCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems, deleteItems }) => {
    return pushListItemsToStore({
      update: { [EntityType.sellSideQuote]: addItems.concat(updateItems) },
      delete: { [EntityType.sellSideQuote]: deleteItems }
    })
  }
})
const buySideQuoteCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems, deleteItems }) => {
    return pushListItemsToStore({
      update: { [EntityType.buySideQuote]: addItems.concat(updateItems) },
      delete: { [EntityType.buySideQuote]: deleteItems }
    })
  }
})
const streetTurnCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) => tryAddStreetTurnsToStore(addItems.concat(updateItems))
})
const moveCollector = websocketCollector({
  delay: 1000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: movesAction
})
// const moveItemCollector = websocketCollector({
//   delay: 1000,
//   doNotFilterLatestItems: true,
//   receivedObjectsAction: moveItemsAction
// })
const driverPositionCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    return pushListItemsToStore({
      update: { [EntityType.driverPosition]: addItems.concat(updateItems).map(correctDriverPosition) }
    })
  }
})
const driverActivityCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    return pushListItemsToStore({ update: { [EntityType.driverActivity]: addItems.concat(updateItems) } })
  }
})

export const websocketRequestForDTOUpdate = (props: WSProps): void => {
  const { eventType, DTOType } = props
  let data = props.data

  if (eventType === WebsocketEvent.DELETED) {
    if (typeof data === 'object' && oc(data as any).id()) {
      data = oc(data as any).id()
    }
  }

  logWS(props)

  switch (DTOType.toUpperCase().replace(/VIEW|DTO|-/g, '')) {
    case 'EQUIPMENT': {
      equipmentCollector(data, eventType)
      break
    }
    case 'POWERUNIT': {
      powerUnitCollector(data, eventType)
      break
    }
    case 'LOCATION': {
      locationCollector(data, eventType)
      break
    }
    case 'CONTACT': {
      contactCollector(data, eventType)
      break
    }
    case 'BUSINESSPARTNER': {
      businessPartnerCollector(data, eventType)
      break
    }
    case 'DRIVER': {
      driverCollector(data, eventType)
      break
    }
    case 'STEAMSHIPLINE': {
      steamShipLineCollector(data, eventType)
      break
    }
    case 'CONTAINER': {
      containerCollector(data, eventType)
      break
    }
    case 'SUBCLIENT': {
      subClientCollector(data, eventType)
      break
    }
    case 'CUSTOMER': {
      customerCollector(data, eventType)
      break
    }
    case 'DELIVERYORDER': {
      if (getListsState()[EntityType.deliveryOrder][data.id]) {
        deliveryOrderCollector(data, eventType)
      }
      break
    }
    case 'DISPATCHDELIVERYORDER': {
      dispatchDeliveryOrderCollector(data, eventType)
      break
    }
    case 'DOCUMENTATIONACTIVITY':
    case 'TRANSPORTATIONACTIVITY': {
      activityListWebsocket(data, eventType)
      break
    }
    case 'ONGOINGACTIVITYGROUP': {
      ongoingActivityGroupsWebsocket(data, eventType)
      break
    }
    case 'SELLSIDEQUOTERATE': {
      sellSideQuoteRateCollector(data, eventType)
      break
    }
    case 'BUYSIDEQUOTERATE': {
      buySideQuoteRateCollector(data, eventType)
      break
    }
    case 'SELLSIDEQUOTE': {
      sellSideQuoteCollector(data, eventType)
      break
    }
    case 'BUYSIDEQUOTE': {
      buySideQuoteCollector(data, eventType)
      break
    }
    case 'CUSTOMERQUOTE': {
      customerQuoteCollector(data, eventType)
      break
    }
    case 'DISPATCHDELIVERYORDERSTREETTURN': {
      streetTurnCollector(data, eventType)
      break
    }
    case 'ROUTEBUILDERMOVES': {
      moveCollector(data, eventType)
      break
    }
    // case 'ROUTEBUILDERMOVEITEMS': {
    //   moveItemCollector(data, eventType)
    //   break
    // }
    case 'DRIVERPOSITION': {
      driverPositionCollector(data, eventType)
      break
    }
    case 'DRIVERACTIVENESS': {
      data.updatedAt = new Date().getTime()
      driverActivityCollector(data, eventType)
      break
    }
    default:
      break
  }
}

export const logWS = ({ eventType, DTOType, data, service }: WSProps) => {
  if (!debuggingMode.websoket) {
    return
  }

  let details = ''

  if (typeof data === 'object') {
    switch (DTOType.toUpperCase().replace(/VIEW|DTO/g, '')) {
      case 'DISPATCHDELIVERYORDER':
        details += ' DDO#: ' + data.number
        break
      case 'ONGOINGACTIVITYORDERGROUP':
        details += ' DDO#: ' + data.dispatchDeliveryOrderNumber
        details += ', ID: ' + data.id
        break
      default:
    }
  }

  // tslint:disable-next-line:no-console
  console.log(
    `%c[WS ${service}] %c${eventType} %c${DTOType} %c${details}`,
    consoleLogStyles.title,
    consoleLogStyles.websocketAction[eventType] || consoleLogStyles.websocketAction.DEFAULT,
    consoleLogStyles.type,
    consoleLogStyles.details
  )
  // tslint:disable-next-line:no-console
  console.log(data)
}
