import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import { List, ListRowProps } from 'react-virtualized'
import { ChatTab, IChannelMeta } from '../../../../store/reducers/communicationHub/interfaces'
import { AllUnreadChannel } from './AllUnreadChannel'
import { useApplicationSize } from '../../../../hooks/useApplicationSize'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'
import { MessagesTab } from '../ChannelView/MessagesTab'
import { getChatMessages, setChatChannelLastOpen } from '../../epics'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { GridSpinner } from '../../../UI/Spinner/Spinner'

interface IMarkRead {
  secondsLeft: number
  timer: NodeJS.Timeout & number
}

type OwnProps = {
  channelMetaListWithNewMessages: IChannelMeta[]
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const allUnreadRowHeight = 52
let channelIdindexMapping = {}

export const AllUnreadBody = (props: Props) => {
  const { channelMetaListWithNewMessages } = props
  const dispatch = useAppDispatch()
  const mountedRef = React.useRef(true)
  const { applicationWidth, resize } = useApplicationSize()
  const prevExpandedChannelId = React.useRef(null)
  const [expandedChannelId, setExpandedChannelId] = React.useState<string>(null)
  const [fetch, setFetch] = React.useState<boolean>(true)
  const [markReadTimers, setMarkReadTimers] = React.useState<Record<string, IMarkRead>>({})
  const [scrollPosition, setScrollPosition] = React.useState<number>(0)
  const expandedRowHeight = Math.min(700, window.innerHeight - (48 + allUnreadRowHeight))

  const clearMarkRead = React.useCallback((channelId: string) => {
    setMarkReadTimers(state => {
      const timerData = state[channelId]

      if (timerData.timer) {
        clearInterval(timerData.timer)
        return { ...state, [channelId]: undefined }
      }

      return state
    })
  }, [])

  const setMarkRead = React.useCallback((channelId: string) => {
    setExpandedChannelId(_expandedChannelId => {
      if (_expandedChannelId && _expandedChannelId === channelId) {
        return undefined
      }

      return _expandedChannelId
    })

    setMarkReadTimers(state => {
      const timerData: IMarkRead = {
        secondsLeft: 5,
        timer: undefined
      }

      // @ts-ignore
      timerData.timer = setInterval(() => {
        if (timerData && timerData.secondsLeft > 1) {
          timerData.secondsLeft--
          if (mountedRef.current) {
            setMarkReadTimers(_state => ({ ..._state }))
          }
        } else {
          clearInterval(timerData.timer)

          if (mountedRef.current) {
            setMarkReadTimers(_state => ({ ..._state, [channelId]: undefined }))
            dispatch(communicationHubActions.clearChannelIdUnreadCount(channelId))
            setChatChannelLastOpen(channelId)
          }
        }
      }, 1000)

      return { ...state, [channelId]: timerData }
    })
  }, [])

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
      channelIdindexMapping = {}
    }
  }, [])

  React.useLayoutEffect(() => {
    let isExpiredData = false

    if (expandedChannelId) {
      setFetch(true)

      try {
        getChatMessages(expandedChannelId)
          .then(messages => {
            if (!isExpiredData && messages && messages.length) {
              dispatch(communicationHubActions.setMessages(messages))
            }
          })
          .finally(() => {
            if (!isExpiredData) {
              setFetch(false)
            }
          })
      } catch (e) {}
    }

    if (
      prevExpandedChannelId.current &&
      expandedChannelId &&
      prevExpandedChannelId.current !== expandedChannelId &&
      channelIdindexMapping[prevExpandedChannelId.current] < channelIdindexMapping[expandedChannelId]
    ) {
      setScrollPosition(n => n - (expandedRowHeight - allUnreadRowHeight))
    }

    prevExpandedChannelId.current = expandedChannelId

    return () => {
      isExpiredData = true
    }
  }, [expandedChannelId])

  const RowRenderer = ({ key, index, style }: ListRowProps) => {
    const channelMeta = channelMetaListWithNewMessages[index]
    const isExpanded = expandedChannelId === channelMeta.id
    channelIdindexMapping[channelMeta.id] = index
    const markReadTimerData = markReadTimers[channelMeta.id]

    return (
      <RowContainer
        key={key}
        style={style}
        className={cn({
          odd: index % 2 === 0,
          markedRead: markReadTimerData && markReadTimerData.secondsLeft
        })}
      >
        <React.Fragment key={channelMeta.id}>
          <AllUnreadChannel
            channelMeta={channelMeta}
            isExpanded={isExpanded}
            setExpandedChannelId={setExpandedChannelId}
            markReadSecondsLeft={markReadTimerData && markReadTimerData.secondsLeft}
            setMarkRead={() => setMarkRead(channelMeta.id)}
            clearMarkRead={() => clearMarkRead(channelMeta.id)}
          />
          {isExpanded && (
            <ChannelContainer>
              {fetch ? (
                <GridSpinner />
              ) : (
                <MessagesTab
                  channelId={channelMeta.id}
                  chatTab={ChatTab.Messages}
                  disableMessageActions={true}
                  showOnlyUreadMessages={true}
                />
              )}
            </ChannelContainer>
          )}
        </React.Fragment>
      </RowContainer>
    )
  }

  return (
    <List
      key={expandedChannelId}
      scrollToAlignment={'start'}
      scrollTop={scrollPosition}
      onScroll={scrollProps => setScrollPosition(scrollProps.scrollTop)}
      width={CommunicationHubConstants.channelViewWidth}
      height={window.innerHeight - 48}
      rowCount={channelMetaListWithNewMessages.length}
      rowHeight={({ index }) =>
        channelMetaListWithNewMessages[index].id === expandedChannelId ? expandedRowHeight : allUnreadRowHeight
      }
      rowRenderer={RowRenderer}
    />
  )
}

const RowContainer = styled.div`
  &.odd {
    background-color: #f2f3f7;
  }

  &.markedRead {
    background-color: #e3e8ea;
  }
`
const ChannelContainer = styled.div`
  height: calc(100% - ${allUnreadRowHeight}px - 17px);
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  margin: 0 17px 17px 17px;
  overflow: auto;
`
export const Button = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  color: ${theme.colors.basicBlueColor};
  font-size: 10px;
  margin-right: 8px;
  padding: 8px 16px;
  border: 1px solid ${theme.colors.basicBlueColor};
  border-radius: 15px;

  &:hover {
    background-color: ${theme.colors.basicBlueColor};
    color: white;
  }
`
