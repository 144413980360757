import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { NewRateDTO, RateColumn, RateType } from '../../../../../interfaces'
import { columnsSettings } from '../settings'
import { generateRateNumber } from '../../../../../../../../services/functions/generate/generateRateNumber'
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../../../../store/select/driverSelect'
import { selectCustomerName } from '../../../../../../../../store/select/customerSelect'
import { quoteDirectory } from '../../../../../../../../services/DTO/sellSideQuote/directory'
import { selectLocation } from '../../../../../../../../store/select/locationSelect'
import { deliveryOrderDirectory } from '../../../../../../../../services/DTO/deliveryOrder/directory'
import { selectRules } from '../../../../../../../../store/select/ruleSelect'
import { SellSideQuoteRateDTO } from '../../../../../../../../api/origin/qmp-service'
import { dateService } from '../../../../../../../../services/timeService'
import { transformMoney } from '../../../../../../../../services/functions/transform/transformMoney'
import { selectState } from '../../../../../../../../store/select/stateSelect'
import { selectContainerTypes } from '../../../../../../../../store/select/containerTypeSelect'
import { ColumnValueWithPopover } from '../../../../../../../UI/Column'

type Props = {
  rateType: RateType
  column: RateColumn
  rate: NewRateDTO
}

export const RowColumn = React.memo((props: Props) => {
  const { column, rate, rateType } = props
  const columnDataId = `${rate.id}-${rateType}RateColumn-${columnIdNameList[column]}-${rate.number}`
  const columnStyle = oc(columnsSettings)[column].styles()

  return (
    <Container data-id={columnDataId} style={columnStyle}>
      <RowColumnContent {...props} />
    </Container>
  )
})

const RowColumnContent = React.memo((props: Props) => {
  const { column, rate } = props
  const rules = useAppSelector(selectRules)
  const containerTypes = useAppSelector(selectContainerTypes)
  const vendorName = useAppSelector(selectDriverName(rate.vendorId))
  const customerName = useAppSelector(selectCustomerName(rate.customerId))
  const pickupLocation = useAppSelector(selectLocation(rate.pickupLocationId))
  const returnLocation = useAppSelector(selectLocation(rate.returnLocationId))
  const deliveryState = useAppSelector(selectState(rate.deliveryStateId))
  let text: string | JSX.Element = ''

  switch (column) {
    case RateColumn.id:
      text = generateRateNumber(rate.number, 5)
      break
    case RateColumn.typeOfRate:
      text = <div style={{ letterSpacing: '0.75px', fontWeight: 500 }}>{quoteDirectory.typeOfRate[rate.type]}</div>
      break
    case RateColumn.typeOfQuote:
      text = (
        <div style={{ letterSpacing: '0.75px', fontWeight: 500 }}>{quoteDirectory.customerQuoteType[rate.type]}</div>
      )
      break
    case RateColumn.type: {
      text = 'TFF'

      if (rate.vendorId) {
        text = vendorName || '–'
      } else if (rate.customerId) {
        text = customerName || '–'
      }

      break
    }
    case RateColumn.pickup: {
      text = oc(pickupLocation).code() || oc(pickupLocation).name() || '–'

      break
    }
    case RateColumn.delivery:
      text = '–'
      const city = rate.deliveryCity
      const state = oc(deliveryState).code()
      const zip = rate.deliveryPostalCode

      if (city || state || zip) {
        text = (
          <div style={{ overflow: 'hidden', marginRight: -8 }}>
            {city && <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{city}</div>}
            {state && <div>{state}</div>}
            {zip && <div>{zip}</div>}
          </div>
        )
      }

      break
    case RateColumn.return: {
      text = oc(returnLocation).code() || oc(returnLocation).name() || '–'

      break
    }
    case RateColumn.doType: {
      text = Array.isArray(rate.deliveryOrderType)
        ? rate.deliveryOrderType.map(item => deliveryOrderDirectory.columnType[item].toUpperCase()).join(', ')
        : (deliveryOrderDirectory.columnType[rate.deliveryOrderType] || '–').toUpperCase()

      break
    }
    case RateColumn.containerType: {
      text = '–'

      if (Array.isArray(rate.containerTypeId)) {
        const values = rate.containerTypeId
          .map(id => containerTypes[id])
          .filter(Boolean)
          .map(({ code }) => code)

        if (rate.containerTypeId.length === 1) {
          text = values[0]
        }

        return (
          <>
            <ColumnValueWithPopover values={values} showNumberAsValue={true} />
          </>
        )
      } else {
        text = oc(containerTypes)[rate.containerTypeId].code('–')
      }

      break
    }
    case RateColumn.loadType: {
      text = Array.isArray(rate.loadType)
        ? rate.loadType.map(item => quoteDirectory.loadTypeAll[item]).join(', ')
        : quoteDirectory.loadTypeAll[rate.loadType] || '–'

      break
    }
    case RateColumn.calculationType: {
      text = Array.isArray(rate.calculationType)
        ? rate.calculationType.map(item => quoteDirectory.calculationType[item]).join(', ')
        : quoteDirectory.calculationType[rate.calculationType] || '–'

      break
    }
    case RateColumn.rule: {
      text = '–'

      if (oc(rate).ruleIds([]).length) {
        let ruleList: string[] = []
        ruleList = rate.ruleIds.reduce((acc, currId) => {
          if (rules[currId] && rules[currId].name) {
            acc.push(rules[currId].name)
          }
          return acc
        }, ruleList)

        if (ruleList.length) {
          text = (
            <Rules>
              <div>
                <ul data-for={'rules'} data-tip={ruleList.join('\n')}>
                  {ruleList.map((rule: string, index: number) => {
                    return <li key={index}>{rule}</li>
                  })}
                </ul>
              </div>
            </Rules>
          )
        }
      }

      break
    }
    case RateColumn.effectiveDate: {
      const isExpired = rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED
      text = (
        <span style={isExpired ? { color: 'red' } : undefined}>
          {dateService.makeLabel(rate.effectiveDate, { hideTime: true }) || (
            <span style={{ color: 'red' }}>--/--/--</span>
          )}
        </span>
      )

      break
    }
    case RateColumn.expirationDate: {
      const isExpired = rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED
      text = (
        <span style={isExpired ? { color: 'red' } : undefined}>
          {dateService.makeLabel(rate.expirationDate, { hideTime: true }) || '–'}
        </span>
      )

      break
    }
    case RateColumn.amount:
      text = (
        <div style={{ fontWeight: 500 }}>
          {rate.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE && rate.amount
            ? rate.amount + '%'
            : transformMoney(rate.amount) || ''}
        </div>
      )

      break
    default:
  }

  return <>{text}</>
})

const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  color: #445366;
  flex-wrap: wrap;
  font-size: 14px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0 8px;
`

const Rules = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: auto;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      width: 100%;
      li {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`

const columnIdNameList = {
  [RateColumn.amount]: 'amount',
  [RateColumn.rule]: 'rule',
  [RateColumn.loadType]: 'loadType',
  [RateColumn.pickup]: 'pickup',
  [RateColumn.delivery]: 'delivery',
  [RateColumn.return]: 'return',
  [RateColumn.type]: 'type',
  [RateColumn.typeOfQuote]: 'typeOfQuote',
  [RateColumn.typeOfRate]: 'typeOfRate',
  [RateColumn.calculationType]: 'calculationType',
  [RateColumn.containerType]: 'containerType',
  [RateColumn.doType]: 'doType',
  [RateColumn.effectiveDate]: 'effectiveDate',
  [RateColumn.expirationDate]: 'expirationDate',
  [RateColumn.id]: 'id'
}
