import * as R from 'remeda'
import { callAPI, callAPIWithErrorMessage, powerUnitAPI } from '../../../../api/api'
import { PowerUnitDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewObject } from '../../../../services/DTO'

export const requestPowerUnitById = (id: string): Promise<PowerUnitDTO> =>
  callAPI(powerUnitAPI.findById, id)
    .toPromise()
    .then(async powerUnit => {
      await pushListItemsToStore({ update: { [EntityType.powerUnit]: [powerUnit] } })

      return powerUnit
    })

export const requestPowerUnits = async ({ sort, filter }: any): Promise<PowerUnitDTO[]> => {
  return callAPI(powerUnitAPI.findAll, filter, sort)
    .toPromise()
    .then(async powerUnits => {
      await pushListItemsToStore({ update: { [EntityType.powerUnit]: powerUnits } })

      return powerUnits
    })
    .catch(catchListErrors)
}

export const createPowerUnit = (powerUnit: PowerUnitDTO): Promise<PowerUnitDTO> => {
  return callAPIWithErrorMessage(powerUnitAPI.create, R.omit(powerUnit, ['id'])).then(async requestedPowerUnit => {
    await pushListItemsToStore({ update: { [EntityType.powerUnit]: [requestedPowerUnit] } })

    return requestedPowerUnit
  })
}

export const updatePowerUnit = (powerUnit: PowerUnitDTO): Promise<PowerUnitDTO> => {
  if (isNewObject(powerUnit)) {
    return createPowerUnit(powerUnit)
  }

  return callAPIWithErrorMessage(powerUnitAPI.update, powerUnit.id, powerUnit).then(async requestedPowerUnit => {
    await pushListItemsToStore({ update: { [EntityType.powerUnit]: [requestedPowerUnit] } })

    return requestedPowerUnit
  })
}
