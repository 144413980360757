export const preventEvent = (event: any) => {
  if (!event) {
    return
  }

  try {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
}

export const stopPropagationEvent = (event: any) => {
  if (!event) {
    return
  }

  try {
    event.stopPropagation()
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
}
