import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { ActivitiesTopSection } from './ActivitiesTopSection'
import { Table } from './Table'
import { getDDOWorkingStatus } from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { requestEquipmentById } from '../../../../equipment/epics'
import { DDOWorkingStatus } from '../../../../../../services/DTO/activity/controller'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectEquipment } from '../../../../../../store/select/equipmentSelect'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'

type Props = {
  showUserInfo?: boolean
  unlimitedHeight?: boolean
}

export const ActivitiesTab = (props: Props) => {
  const { dispatchDeliveryOrder, activities, actualDDOStatus, enableEditing } = useGridItemDDO()
  const { showUserInfo, unlimitedHeight } = props
  const equipment = useAppSelector(selectEquipment(oc(dispatchDeliveryOrder).equipmentId()))
  const allowModifying = enableEditing && getDDOWorkingStatus(actualDDOStatus) !== DDOWorkingStatus.none

  React.useEffect(() => {
    if (!equipment && dispatchDeliveryOrder && dispatchDeliveryOrder.equipmentId) {
      requestEquipmentById(dispatchDeliveryOrder.equipmentId)
    }
  }, [])

  return (
    <DispatchDeliveryOrderTabContent style={unlimitedHeight ? { height: 'auto', paddingBottom: 10 } : undefined}>
      {Boolean(activities.documentationActivities.length || activities.transportationActivities.length) && (
        <>
          <ActivitiesTopSection showDates={showUserInfo} />
          <Table
            unlimitedHeight={unlimitedHeight}
            allowModifying={allowModifying && !dispatchDeliveryOrder.troubleTicketId}
          />
        </>
      )}
    </DispatchDeliveryOrderTabContent>
  )
}
