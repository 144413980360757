import { DeliveryOrderViewDTO, DispatchDeliveryOrderDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { dateService } from '../../timeService'
import { createId } from '../../utils'

export const generateDeliveryOrder = (options?: Partial<DeliveryOrderViewDTO>): DeliveryOrderViewDTO => {
  return {
    completeness: maxCompleteness,
    id: createId(),
    cargoId: undefined,
    cargo: { referenceNumber: undefined, description: undefined },
    customerId: undefined,
    deliveryLocationId: undefined,
    pickupLocationId: undefined,
    firstReceivingDate: undefined,
    generalCutoffDate: undefined,
    type: undefined,
    loadType: DispatchDeliveryOrderDTO.LoadTypeEnum.LIVELOAD,
    billOfLadingNumber: undefined,
    bookingNumber: undefined,
    steamShipLineId: undefined,
    dispatchDeliveryOrderIds: [],
    date: dateService.createStringDate.now,
    ...(options || {})
  }
}
