import * as React from 'react'
import { SubClientsPreviewTab } from './SubClientsPreviewTab'
import { CustomerViewDTO } from '../../../../api/api'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { SubClientsTabProvider } from '../../../../providers/SubClientsTabProvider'

type Props = {
  parentEntity: CustomerViewDTO
  entityType: EntityType.customer
}

export const SubClientsTab = (props: Props) => {
  const { parentEntity, entityType } = props

  return (
    <SubClientsTabProvider parentEntity={parentEntity} entityType={entityType}>
      <SubClientsPreviewTab subClientIds={parentEntity.subClientIds} />
    </SubClientsTabProvider>
  )
}
