import * as R from 'remeda'
import { callAPI, DeliveryOrderViewDTO, deliveryOrderAPI, cargoAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { createTab } from '../../../../store/reducers/tabs/functions'
import { TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { generateTabExpandedItem } from '../../../../services/functions/generate/generateTabExpandedItem'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { generateDeliveryOrder } from '../../../../services/functions/generate/generateDeliveryOrder'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { isNewItem } from '../../../../services/utils'

export const createDeliveryOrderAndTab = () => {
  const deliveryOrder = generateDeliveryOrder()

  createTab(TabDTO.Type.deliveryOrder, {
    label: 'New Delivery Order',
    permissions: { localStorage: false },
    expandedItem: generateTabExpandedItem({
      id: deliveryOrder.id,
      entityType: EntityType.deliveryOrder,
      modifiedLists: {
        [EntityType.deliveryOrder]: {
          [deliveryOrder.id]: deliveryOrder
        }
      }
    })
  })
}

export const requestDeliveryOrderById = (id: string, forceUpdate?: boolean): Promise<DeliveryOrderViewDTO> => {
  return callAPI(deliveryOrderAPI.findById, id)
    .toPromise()
    .then(async deliveryOrder => {
      if (forceUpdate) {
        // @ts-ignore
        deliveryOrder.forceUpdate = forceUpdate
      }

      await pushListItemsToStore({ update: { [EntityType.deliveryOrder]: [deliveryOrder] } })

      return deliveryOrder
    })
}

export const findAllDeliveryOrders = async ({ sort, filter }: any): Promise<DeliveryOrderViewDTO[]> => {
  return callAPI(deliveryOrderAPI.findAll, filter, sort)
    .toPromise()
    .then(async deliveryOrders => {
      await pushListItemsToStore({
        update: {
          [EntityType.deliveryOrder]: deliveryOrders
        }
      })
      return deliveryOrders
    })
}

export const requestCreateDeliveryOrder = (deliveryOrder: DeliveryOrderViewDTO): Promise<DeliveryOrderViewDTO> => {
  return callAPIWithErrorMessage(cargoAPI.create, deliveryOrder.cargo)
    .then(cargo =>
      callAPIWithErrorMessage(
        deliveryOrderAPI.create,
        R.omit({ ...deliveryOrder, cargoId: cargo.id }, [
          'pickupLocation',
          'deliveryLocation',
          'returnLocation',
          'steamShipLine',
          'customer',
          'cargo',
          'id'
        ])
      )
    )
    .then(async _deliveryOrder => {
      await pushListItemsToStore({
        update: {
          [EntityType.deliveryOrder]: [_deliveryOrder]
        }
      })

      return _deliveryOrder
    })
}

export const requestUpdateDeliveryOrder = (deliveryOrder: DeliveryOrderViewDTO): Promise<DeliveryOrderViewDTO> => {
  if (isNewItem(deliveryOrder)) {
    return requestCreateDeliveryOrder(deliveryOrder)
  }

  return callAPIWithErrorMessage(cargoAPI.update, deliveryOrder.cargoId, deliveryOrder.cargo)
    .then(() =>
      callAPIWithErrorMessage(
        deliveryOrderAPI.update,
        deliveryOrder.id,
        R.omit(deliveryOrder, [
          'pickupLocation',
          'deliveryLocation',
          'returnLocation',
          'steamShipLine',
          'customer',
          'cargo'
        ])
      )
    )
    .then(async _deliveryOrder => {
      await pushListItemsToStore({
        update: {
          [EntityType.deliveryOrder]: [_deliveryOrder]
        }
      })

      return _deliveryOrder
    })
}
