import * as React from 'react'
import styled from 'styled-components'
import { Icon, IconMode, IconName } from '../Icon'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { NavigationChannelsSectionSearch } from './NavigationChannelsSectionSearch'
import { ChannelType } from '../../../../store/reducers/communicationHub/interfaces'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'

type Props = {
  channelType: ChannelType
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  listIsNotEmpty: boolean
  title?: string
  innerSearch?: boolean
  innerSearchPlaceholder?: string
  collapsible?: boolean
}

export const NavigationChannelsSectionHeader = React.memo((props: Props) => {
  const {
    channelType,
    search,
    setSearch,
    setOpen,
    listIsNotEmpty,
    title,
    innerSearch,
    innerSearchPlaceholder,
    collapsible
  } = props
  const dispatch = useAppDispatch()

  // const onAddNewChannelClick = undefined

  return innerSearch ? (
    <NavigationChannelsSectionSearch
      title={title || channelType}
      search={search}
      setSearch={setSearch}
      innerSearchPlaceholder={innerSearchPlaceholder}
    />
  ) : (
    <HeaderContainer>
      <HeaderTitle>{title || channelType}</HeaderTitle>
      <HeaderActions>
        {/* {Boolean(onAddNewChannelClick) && (
          <Icon icon={IconName.PLUS} style={{ marginRight: 5 }} mode={IconMode.LIGHT} onClick={onAddNewChannelClick} />
        )} */}
        {channelType === ChannelType.dispatchDeliveryOrder && (
          <Icon
            icon={IconName.SEARCH}
            style={{ marginRight: 5 }}
            mode={IconMode.LIGHT}
            onClick={() => {
              leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.setChannelBrowser({ channelType })))
            }}
          />
        )}
        {collapsible && listIsNotEmpty && (
          <Icon
            icon={open ? IconName.ARROW_BOTTOM : IconName.ARROW_RIGHT}
            mode={IconMode.LIGHT}
            onClick={() => setOpen(state => !state)}
          />
        )}
      </HeaderActions>
    </HeaderContainer>
  )
})

const HeaderContainer = styled.div`
  flex-shrink: 0;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 3px 0 8px;
`
const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
  color: white;
  padding-left: 10px;
`
const HeaderActions = styled.div`
  margin-left: auto;
`
