import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { useTable } from '../../../hooks/useTable'
import theme from '../../../styles/theme'

type Props = {}

export const TableHeader = (props: Props) => {
  const {} = props
  const { settings, CSSRuleGridTemplateColumns, styleHeader } = useTable()

  return (
    <Container
      className={'tableHeader'}
      style={{
        gridTemplateColumns: CSSRuleGridTemplateColumns,
        ...(styleHeader || {})
      }}
    >
      {settings.map(({ label, isRequired }, index) => {
        return (
          <Column
            key={index}
            className={cn('tableHeaderColumn', { required: isRequired })}
            style={index !== settings.length - 1 && label ? { borderRight: '1px solid #E5E5E5' } : undefined}
          >
            {label}
          </Column>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  min-height: 20px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 104px 104px;
  margin-bottom: 15px;
`

const Column = styled.div`
  display: flex;
  align-items: center;
  color: #6c707d;
  font-size: 12px;
  padding-left: 5px;

  &.required::after {
    content: '*';
    color: ${theme.colors.defaultRed};
    margin-left: 4px;
  }
`
