import * as React from 'react'
import { ContactDTO, LocationViewDTO } from '../api/api'

export interface IGridItemLocationContext {
  location: LocationViewDTO
  isExpanded: boolean
  primaryContact: ContactDTO | undefined
}

export const GridItemLocationContext = React.createContext<IGridItemLocationContext>({
  location: undefined,
  isExpanded: undefined,
  primaryContact: undefined
})
