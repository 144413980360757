import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'

type Props = {
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  stage: TransportationActivityViewDTO.StageEnum
}

export const getTemplateActivityTypes = (props: Props): TransportationActivityViewDTO.TypeEnum[] => {
  const { deliveryOrderType, stage, loadType } = props

  if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT) {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return [TransportationActivityViewDTO.TypeEnum.PICKUPFULL]
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        return loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD
          ? [TransportationActivityViewDTO.TypeEnum.GETUNLOADED]
          : [
              TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
              TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
            ]
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS]
      default:
    }
  }

  if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return [TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY]
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        return loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD
          ? [TransportationActivityViewDTO.TypeEnum.GETLOADED]
          : [
              TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
              TransportationActivityViewDTO.TypeEnum.PICKUPFULL
            ]
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS]
      default:
    }
  }

  if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION) {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return [TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY]
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS]
      default:
    }
  }

  return []
}
