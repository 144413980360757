import { DriverDTO, DriverViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateDriver = (props?: Partial<DriverViewDTO>): DriverViewDTO => {
  return {
    id: createId(),
    completeness: maxCompleteness,
    status: DriverDTO.StatusEnum.AVAILABLE,
    birthday: null,
    driverType: DriverDTO.DriverTypeEnum.OWNEROPERATOR,
    eld: false,
    eldNumber: null,
    email: null,
    endorsement: null,
    endorsementExpDate: null,
    firstName: null,
    lastName: null,
    licenseDocumentFile: null,
    licenseBackDocumentFile: null,
    licenseExpDate: null,
    licenseIssueStateId: null,
    licenseIssueDate: null,
    licenseNumber: null,
    medicalExamCardDocumentFile: null,
    medicalExamCardExpDate: null,
    middleName: null,
    millageRestriction: null,
    notes: null,
    number: null,
    operationalPreference: DriverDTO.OperationalPreferenceEnum.SINGLE,
    phone: null,
    powerUnitId: null,
    portCredentialsDocumentFile: null,
    portCredentialsExpDate: null,
    twicDocumentFile: null,
    twicExpDate: null,
    w4DocumentFile: null,
    w9DocumentFile: null,
    mobileApp: false,
    ...(props || {})
  }
}
