import * as React from 'react'

export const highlightPhrase = (
  text: string,
  highlight: string[],
  highlightPhraseContainer?: (props: any) => JSX.Element
): JSX.Element => {
  if (!text) {
    return undefined
  }

  const RenderHighlightPhraseContainer = highlightPhraseContainer
    ? highlightPhraseContainer
    : (props: any) => <span>{props.children}</span>

  const variants = highlight.map(_ => _.toLowerCase())
  const parts = text.split(new RegExp(`(${highlight.join('|')})`, 'gi')).map((part, i) => {
    const isHighlighted = variants.includes(part.toLowerCase())

    if (!isHighlighted) {
      return part
    }

    return <RenderHighlightPhraseContainer key={i}>{part}</RenderHighlightPhraseContainer>
  })

  return <>{parts}</>
}
