import * as Sentry from '@sentry/browser'
import { sentryConfig } from './config'

export const initSentry = () => {
  const { sentryKey, sentryOrganization, sentryProject, release } = sentryConfig

  if (release !== 'Local Development') {
    Sentry.init({
      enabled: release !== 'Local Development',
      dsn: `https://${sentryKey}@${sentryOrganization}.ingest.sentry.io/${sentryProject}`,
      release: release
    })
  }
}
