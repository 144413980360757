export const transformAmount = (_value: string | number): string => {
  let result = ''
  const value = typeof _value === 'number' ? String(_value) : _value

  value.split('.').forEach((subString: string, index: number) => {
    if (index === 0) {
      result = subString
    }

    if (index === 1 && subString.length !== 0) {
      let doit = subString.slice(0, 2)
      if (doit.length === 2 && doit.slice(-1) === '0') {
        doit = doit.slice(0, -1)
      }

      if (doit !== '0') {
        result += '.' + doit
      }
    }
  })

  return result
}
