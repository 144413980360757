import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { Container } from '../../../UI/GridSystem'
import { Row } from '../../contact/views/styledComponents'
import { Delete } from '../../../UI/styledComponents'
import { InputField, Input } from '../../../UI/DataFields/Input'
import { BusinessPartnerViewDTO, ContactDTO } from '../../../../api/origin/business-logic/api'
import AddressField from '../../../UI/AddressField'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { useSubClientsTab } from '../../../../hooks/useSubClientsTab'
import { useBusinessPartner } from '../../../../hooks/useBusinessPartner'
import { useContact } from '../../../../hooks/useContact'
import { useSubClient } from '../../../../hooks/useSubClient'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { generateContact } from '../../../../services/functions/generate/generateContact'
import { isNewItem } from '../../../../services/utils'
import { isContactFilled } from '../../../../services/functions/test/isContactFilled'

type Props = {
  id: string
}

export const SubClientRow = (props: Props) => {
  const { id } = props
  const { modifiedLists, modifyListItems, deleteModifiedListItems } = useExpandedItem()
  const { deleteSubClient } = useSubClientsTab()
  const subClient = useSubClient({ id, modifiedLists })
  const isNew = isNewItem(subClient)
  const businessPartner = useBusinessPartner({ id: oc(subClient).businessPartnerId(), modifiedLists })
  const primaryContact = useContact({ id: oc(businessPartner).primaryContactId(), modifiedLists })

  const modifyBusinessPartner = (prop: keyof BusinessPartnerViewDTO) => (value: any) => {
    modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, [prop]: value }] })
  }

  const modifyPrimaryContact = (prop: keyof ContactDTO) => (value: any) => {
    if (businessPartner) {
      const updatedPrimaryContact = { ...(primaryContact || generateContact({ primary: true })), [prop]: value }
      const modifyListItemsData = { [EntityType.contact]: [updatedPrimaryContact] }
      const isNewContact = isNewItem(updatedPrimaryContact)
      const isClearContact = !isContactFilled(updatedPrimaryContact)

      if (isNewContact && isClearContact) {
        delete modifyListItemsData[EntityType.contact]

        if (businessPartner.contactIds.includes(updatedPrimaryContact.id)) {
          modifyListItemsData[EntityType.businessPartner] = [
            {
              ...businessPartner,
              primaryContactId: undefined,
              contactIds: businessPartner.contactIds.filter(_id => _id !== updatedPrimaryContact.id)
            }
          ]
        }

        modifyListItems(modifyListItemsData)
        deleteModifiedListItems({ [EntityType.contact]: [updatedPrimaryContact.id] })
      } else {
        if (isNewContact) {
          modifyListItemsData[EntityType.businessPartner] = [
            {
              ...businessPartner,
              primaryContactId: updatedPrimaryContact.id,
              contactIds: businessPartner.contactIds
                .filter(_id => _id !== updatedPrimaryContact.id)
                .concat(updatedPrimaryContact.id)
            }
          ]
        }

        modifyListItems(modifyListItemsData)
      }
    }
  }

  return (
    <Row rows={9} margin={{ bottom: 10 }}>
      <Container columns={8} margin={{ left: 20 }}>
        <Input
          required={true}
          placeholder={'Name'}
          value={oc(businessPartner).legalName()}
          onChange={modifyBusinessPartner('legalName')}
        />
      </Container>
      <Container columns={22}>
        <AddressField
          required={true}
          address={oc(businessPartner).legalAddress(null)}
          onChange={modifyBusinessPartner('legalAddress')}
        />
      </Container>
      <Container columns={9}>
        <Input
          placeholder={'First Name'}
          value={oc(primaryContact).firstName()}
          onChange={modifyPrimaryContact('firstName')}
        />
      </Container>
      <Container columns={9}>
        <Input
          placeholder={'Last Name'}
          value={oc(primaryContact).lastName()}
          onChange={modifyPrimaryContact('lastName')}
        />
      </Container>
      <Container columns={9}>
        <InputField.PhoneNumber
          placeholder={'Phone'}
          value={oc(primaryContact).phone()}
          onChange={modifyPrimaryContact('phone')}
        />
      </Container>
      <Container columns={9}>
        <InputField.Email
          placeholder={'Email'}
          value={oc(primaryContact).email()}
          onChange={modifyPrimaryContact('email')}
        />
      </Container>
      <Delete
        className={cn('mdi mdi-delete', { disabled: !isNew })}
        columns={2}
        isFixed={true}
        onClick={isNew ? () => deleteSubClient({ subClient, businessPartner }) : undefined}
      />
    </Row>
  )
}
