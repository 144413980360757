import * as React from 'react'
import * as R from 'remeda'
import { IUploadingFiles, IUploadingFilesContext, UploadingFilesContext } from '../contexts/UploadingFilesContext'

type Props = {
  children?: any
}

export const UploadingFilesProvider = ({ children }: Props) => {
  const [uploadingFiles, setUploadingFiles] = React.useState<IUploadingFiles>({})

  const actions = React.useMemo(
    (): IUploadingFilesContext => ({
      uploadingFiles: undefined,
      setUploadingFiles(steamId, fieldId, data) {
        setUploadingFiles(state => ({ ...state, [steamId]: { ...(state[steamId] || {}), [fieldId]: data } }))
      },
      clearUploadingFiles(steamId, fieldId) {
        setUploadingFiles(state => {
          if (!state[steamId]) {
            return state
          }

          const updatedSteamIdState = R.omit(state[steamId], [fieldId])

          if (!Object.keys(updatedSteamIdState).length) {
            return R.omit(state, [steamId])
          }

          return { ...state, [steamId]: updatedSteamIdState }
        })
      }
    }),
    []
  )

  return (
    <UploadingFilesContext.Provider value={{ ...actions, uploadingFiles }}>{children}</UploadingFilesContext.Provider>
  )
}
