export enum SteamShipLineColumn {
  Name = 'Name',
  ReferenceNumber = 'Reference #',
  FreeDetentionAllowance = 'Free Detention Allowance',
  PrimaryContact = 'Primary Contact'
}

export const steamShipLineColumnSettings = {
  [SteamShipLineColumn.Name]: { width: 160, active: true },
  [SteamShipLineColumn.ReferenceNumber]: { width: 160, active: true },
  [SteamShipLineColumn.FreeDetentionAllowance]: { width: 160, active: true },
  [SteamShipLineColumn.PrimaryContact]: { width: 160, active: true }
}
