import styled from 'styled-components'

export const StyledInput = styled.input`
  width: 10px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  padding-left: 10px;

  &::placeholder {
    color: rgba(51, 51, 51, 0.3);
  }

  &[disabled] {
    cursor: default;
  }

  &:focus {
    cursor: text;
  }

  .inputContainer &:focus {
    opacity: 0.5;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 35px;
  width: 100%;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 4px;

  &.extra-padding {
    padding-right: 23px;
  }

  &.highlighted {
    box-shadow: 0 0 0 1px #ff5e5e;
  }

  &.disabled {
    background-color: white;
    border: 1px solid rgba(229, 229, 229, 0.4);

    input::placeholder {
      opacity: 0;
    }
  }
`

export const ClearButton = styled.span`
  display: flex;
  align-items: center;
  flex: none;
  font-size: 18px;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.25s;
  padding-right: 8px;

  &:hover {
    color: #445366;
  }
`
