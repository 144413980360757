import * as React from 'react'
import { StyledReset } from '../styles'
import { CustomerQuoteDTO } from '../../../../../../api/origin/qmp-service'

type TResetButton = {
  filters: any
  changeFilters: (filters: any) => void
}
export const ResetButton = ({ filters, changeFilters }: TResetButton) => {
  const active = Boolean(Object.values(filters).some(Boolean))

  const clearFilters = active ? () => changeFilters(resetObjectValues(filters)) : undefined

  return (
    <StyledReset className={active ? 'active' : ''} onClick={clearFilters}>
      Reset Filters
    </StyledReset>
  )
}

export const resetObjectValues = (object: any): any => ({
  ...Object.keys(object).reduce((acc, curr) => ({ ...acc, [curr]: null }), {}),
  status: [CustomerQuoteDTO.StatusEnum.NEW]
})
