import { VendorDTO } from '../../../../../api/origin/vendor-accounting-service'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.vendorAccounting]: VendorDTO[]
}

export const parseVendorAccountings = async (vendorAccountings: VendorDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.vendorAccounting]: []
  }

  try {
    vendorAccountings.forEach(vendorAccounting => {
      result[EntityType.vendorAccounting].push(vendorAccounting)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
