import { FeeScheduleDTO } from '../../../api/api'
import { createId } from '../../utils'

export const generateContainerDetentionPerDiemFeeSchedule = (options?: Partial<FeeScheduleDTO>): FeeScheduleDTO => {
  return {
    id: createId(),
    toDay: null,
    fromDay: null,
    cost: null,
    ...(options || {})
  }
}
