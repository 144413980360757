import * as React from 'react'
import { SearchRequest } from '../searchRequest'
import { ContainerViewDTO, callAPI, containerAPI } from '../../../../../api/api'
import { getValidationErrorMessage, TSearchField } from '../index'
import { isNewObject } from '../../../../../services/DTO'
import { schemaContainerNumber } from '../../../../../services/yupScheme'
import { regExpOnlyNumbersAndLetters } from '../../../../../services/functions/regExp'
import { generateContainer } from '../../../../../services/functions/generate/generateContainer'
import { pushListItemsToStore } from '../../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { useContainer } from '../../../../../hooks/useContainer'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { requestCreateContainer } from '../../../../common/containers/epics'
import { oc } from 'ts-optchain'

type Props = TSearchField & { containerTypeId?: string; steamShipLineId?: string; id: string }

export const ContainerSearch = (props: Props) => {
  const { id, containerTypeId = null, steamShipLineId = null } = props
  const { modifiedLists, setFetching } = useExpandedItem()
  const [temporaryNewContainer, setTemporaryNewContainer] = React.useState<ContainerViewDTO>(null)
  const container = useContainer({ id, modifiedLists })
  const useFilters = true
  const getListRequest = (term: string) =>
    callAPI(
      containerAPI.search,
      useFilters ? containerTypeId : null,
      null,
      useFilters ? steamShipLineId : null,
      term
    ).toPromise()

  let getList = getListRequest

  if (props.allowCreate) {
    getList = (term: string) =>
      getListRequest(term).then(list => {
        if (term && !list.length) {
          return [
            generateContainer({
              number: term,
              containerTypeId,
              steamShipLineId
            })
          ]
        }

        return list
      })
  }

  return (
    <SearchRequest
      {...props}
      toUpperCase={true}
      label={oc(temporaryNewContainer).number() || oc(container).number()}
      termRegExp={regExpOnlyNumbersAndLetters}
      filtering={false}
      getList={getList}
      getDetails={details => callAPI(containerAPI.findById, details.id).toPromise()}
      addToStore={(_container: ContainerViewDTO) =>
        pushListItemsToStore({ update: { [EntityType.container]: [_container] } })
      }
      makeDropDownItem={_container => {
        if (isNewObject(_container)) {
          const errorMessage = getValidationErrorMessage(
            [
              schemaContainerNumber.isValidSync(_container.number),
              Boolean(_container.containerTypeId),
              Boolean(_container.steamShipLineId)
            ],
            ['• invalid number', '• Equipment must be set', '• SSL must be set']
          )
          const disabled = Boolean(errorMessage)

          return {
            disabled,
            className: 'create validation' + (errorMessage ? ' invalid' : ''),
            before: 'Create',
            after: errorMessage,
            label: _container.number,
            value: _container,
            onClick: disabled
              ? undefined
              : () => {
                  setTemporaryNewContainer(_container)
                  setFetching(true)

                  requestCreateContainer(_container)
                    .then(savedContainer => {
                      props.onChange(savedContainer)
                    })
                    .finally(() => {
                      setTemporaryNewContainer(null)
                      setFetching(false)
                    })
                }
          }
        } else {
          return {
            label: _container.number,
            value: _container
          }
        }
      }}
    />
  )
}
