import * as React from 'react'
import { StyledStatistics, Cell, CellIcon, CellLabel, CellValue } from './styles'
import { IDateActivityGrid } from '../../../../interfaces'
import { PhaseKeyPerformanceIndicator } from '../../../../../../../api/origin/business-logic'
import { getSchedulerKeyPerformanceIndicator } from '../../../../epics'

const extraLinkStyles = { cursor: 'pointer' }

type OwnProps = {
  isToday: boolean
  date: IDateActivityGrid
  openCutoff: () => void
  openLfd: () => void
}

type StateProps = {
  keyPerformanceIndicator?: PhaseKeyPerformanceIndicator
}

type Props = OwnProps & StateProps

export const Statistics = React.memo((props: Props) => {
  const { openCutoff, openLfd, date, isToday } = props
  const [keyPerformanceIndicator, setKeyPerformanceIndicator] = React.useState(null)
  const linkCutoff = date.kpi.cutoff ? openCutoff : undefined
  const linkLFD = date.kpi.lfd ? openLfd : undefined

  React.useEffect(() => {
    let mounted = true

    if (isToday) {
      getSchedulerKeyPerformanceIndicator().then(data => {
        if (mounted) {
          setKeyPerformanceIndicator(data)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [])

  return (
    <StyledStatistics>
      <Cell className={'border'}>
        <CellLabel>Pickup</CellLabel>
        <CellValue>{date.kpi.pickupCount}</CellValue>
      </Cell>
      <Cell className={'border'}>
        <CellLabel>Delivery</CellLabel>
        <CellValue>{date.kpi.deliveryCount}</CellValue>
      </Cell>
      <Cell className={'border'}>
        <CellLabel>Return</CellLabel>
        <CellValue>{date.kpi.returnCount}</CellValue>
      </Cell>
      {isToday && Boolean(keyPerformanceIndicator) && (
        <>
          <Cell className={'border'}>
            <CellLabel>Unsuccessful</CellLabel>
            <CellValue>{keyPerformanceIndicator.unsuccessful}</CellValue>
          </Cell>
          <Cell className={'border'}>
            <CellLabel>In-process</CellLabel>
            <CellValue>{keyPerformanceIndicator.inProcess}</CellValue>
          </Cell>
          <Cell className={'border'}>
            <CellLabel>DEL COMPL</CellLabel>
            <CellValue>{keyPerformanceIndicator.completed}</CellValue>
          </Cell>
        </>
      )}

      <Cell
        className={'text-color-white'}
        style={Boolean(linkCutoff) ? extraLinkStyles : undefined}
        onClick={linkCutoff}
      >
        <CellIcon className={'mdi mdi-link'} />
        <CellLabel>VIEW CUTOFF</CellLabel>
        <CellValue>{date.kpi.cutoff}</CellValue>
      </Cell>
      <Cell className={'text-color-white'} style={Boolean(linkLFD) ? extraLinkStyles : undefined} onClick={linkLFD}>
        <CellIcon className={'mdi mdi-link'} />
        <CellLabel>VIEW LFD</CellLabel>
        <CellValue>{date.kpi.lfd}</CellValue>
      </Cell>
    </StyledStatistics>
  )
})
