import styled from 'styled-components'
import { theme } from '../../../../../../../../styles/theme'

export const BoldText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #445366;
  line-height: 1.2;
  margin-right: 8px;
`

export const BlueText = styled.span`
  color: ${theme.colors.basicBlueColor};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.2;
  margin-right: 8px;
`
