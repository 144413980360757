import * as React from 'react'
import { transport } from '../../components/UI/Selecter/styledComponents'
import { powerUnitDirectory } from '../DTO/powerUnit/directory'
import { createSelectListFromDirectory } from './index'

const powerUnitType = Object.keys(powerUnitDirectory.powerUnitType).map(_ => ({
  label: powerUnitDirectory.powerUnitType[_],
  htmlLabel: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img width={20} height={30} src={transport[_]} style={{ marginRight: 10 }} />
      {powerUnitDirectory.powerUnitType[_]}
    </div>
  ),
  value: _
}))

const truckType = createSelectListFromDirectory(powerUnitDirectory.truckType)
const ownership = createSelectListFromDirectory(powerUnitDirectory.ownership)
const restrictions = createSelectListFromDirectory(powerUnitDirectory.restrictions)
const insuranceType = createSelectListFromDirectory(powerUnitDirectory.insuranceType)
const repairType = createSelectListFromDirectory(powerUnitDirectory.repairType)
const maintenanceInspectionFrequency = createSelectListFromDirectory(powerUnitDirectory.maintenanceInspectionFrequency)

export const powerUnitLists = {
  powerUnitType,
  truckType,
  ownership,
  restrictions,
  insuranceType,
  repairType,
  maintenanceInspectionFrequency
}
