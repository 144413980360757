import * as React from 'react'
import { DispatchDeliveryOrderStreetTurnDTO, DispatchDeliveryOrderViewDTO } from '../../../api/origin/business-logic'
import theme from '../../../styles/theme'
// tslint:disable:max-line-length

const status: Record<DispatchDeliveryOrderViewDTO.StatusEnum, string> = {
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEW]: 'New',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED]: 'New Accepted',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]: 'New Rejected',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED]: 'On-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED]: 'Arrived',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED]: 'Off-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE]: 'Not on File',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED]: 'On-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED]: 'Arrived',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED]: 'Off-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE]: 'On-File',
  [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: 'Ready for Dispatch',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: 'Planned',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: 'Assigned',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: 'Confirmed',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP]: 'In-process: Pick-Up',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP]: 'Completed: Pick-Up',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY]: 'In-process: Delivery',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY]: 'Completed: Delivery',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN]: 'In-process: Return',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED]: 'Completed',
  [DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]: 'Cancelled'
}

const statusColor: Record<DispatchDeliveryOrderViewDTO.StatusEnum, string> = {
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEW]: '#74ABE2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED]: '#74ABE2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]: '#BCBEC0',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED]: '#F06A93',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED]: '#F06A93',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED]: '#F06A93',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE]: '#F06A93',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED]: '#F38787',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED]: '#F38787',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED]: '#F38787',
  [DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE]: '#F38787',
  [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: '#EF8D5D',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: '#3AB5C2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: '#3AB5C2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: '#3AB5C2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP]: '#6973F6',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY]: '#A97DD8',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN]: '#3FB68E',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED]: '#4D78A2',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY]: '#A97DD8',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP]: '#6973F6',
  [DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]: '#8CA2AB'
}

const icons = {
  refrigerator: (
    <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9H15.83L18.37 6.46C18.76 6.07 18.76 5.44 18.37 5.05C17.98 4.66 17.34 4.66 16.95 5.05L13 9H11V7L14.95 3.05C15.34 2.66 15.34 2.02 14.95 1.63C14.56 1.24 13.93 1.24 13.54 1.63L11 4.17V1C11 0.45 10.55 0 10 0C9.45 0 9 0.45 9 1V4.17L6.46 1.63C6.07 1.24 5.44 1.24 5.05 1.63C4.66 2.02 4.66 2.66 5.05 3.05L9 7V9H7L3.05 5.05C2.66 4.66 2.02 4.66 1.63 5.05C1.24 5.44 1.24 6.07 1.63 6.46L4.17 9H1C0.45 9 0 9.45 0 10C0 10.55 0.45 11 1 11H4.17L1.63 13.54C1.24 13.93 1.24 14.56 1.63 14.95C2.02 15.34 2.66 15.34 3.05 14.95L7 11H9V13L5.05 16.95C4.66 17.34 4.66 17.98 5.05 18.37C5.44 18.76 6.07 18.76 6.46 18.37L9 15.83V19C9 19.55 9.45 20 10 20C10.55 20 11 19.55 11 19V15.83L13.54 18.37C13.93 18.76 14.56 18.76 14.95 18.37C15.34 17.98 15.34 17.34 14.95 16.95L11 13V11H13L16.95 14.95C17.34 15.34 17.98 15.34 18.37 14.95C18.76 14.56 18.76 13.93 18.37 13.54L15.83 11H19C19.55 11 20 10.55 20 10C20 9.45 19.55 9 19 9Z"
        fill="#5CD8FF"
      />
    </svg>
  ),
  overweight: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.01634 4.57218C3.28139 3.74377 4.05144 3.18164 4.92122 3.18164H9.12372C9.9967 3.18164 10.7688 3.74785 11.0312 4.58045L13.1801 11.3986C13.5863 12.6874 12.6239 13.9998 11.2726 13.9998H2.73977C1.38481 13.9998 0.421995 12.6808 0.834892 11.3903L3.01634 4.57218Z"
        fill="#E26D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02608 3.79998C8.11026 3.79998 8.98917 2.94933 8.98917 1.89999C8.98917 0.850655 8.11026 0 7.02608 0C5.94189 0 5.06299 0.850655 5.06299 1.89999C5.06299 2.94933 5.94189 3.79998 7.02608 3.79998ZM7.02604 2.90028C7.59667 2.90028 8.05925 2.45257 8.05925 1.90029C8.05925 1.34801 7.59667 0.900293 7.02604 0.900293C6.45542 0.900293 5.99284 1.34801 5.99284 1.90029C5.99284 2.45257 6.45542 2.90028 7.02604 2.90028Z"
        fill="#E26D3A"
      />
      <path
        d="M3.335 11.4016C3.29411 11.4016 3.2573 11.3892 3.22458 11.3645C3.19595 11.3362 3.18164 11.3044 3.18164 11.269V7.82738C3.18164 7.78849 3.19595 7.75668 3.22458 7.73193C3.25321 7.70365 3.29002 7.6895 3.335 7.6895H4.15703C4.20202 7.6895 4.23882 7.70365 4.26745 7.73193C4.29608 7.75668 4.31039 7.78849 4.31039 7.82738V8.98344L5.3962 7.79556C5.44119 7.72486 5.52094 7.6895 5.63545 7.6895H6.57403C6.60675 7.6895 6.63538 7.70188 6.65992 7.72663C6.68854 7.74784 6.70286 7.77259 6.70286 7.80087C6.70286 7.82915 6.69468 7.85036 6.67832 7.8645L5.24898 9.46601L6.79487 11.2266C6.81123 11.2408 6.81941 11.262 6.81941 11.2902C6.81941 11.3185 6.8051 11.345 6.77647 11.3698C6.75193 11.391 6.72126 11.4016 6.68445 11.4016H5.72133C5.6559 11.4016 5.60273 11.391 5.56184 11.3698C5.52094 11.345 5.49231 11.3203 5.47595 11.2956L4.31039 10.0016V11.269C4.31039 11.3044 4.29608 11.3362 4.26745 11.3645C4.23882 11.3892 4.20202 11.4016 4.15703 11.4016H3.335Z"
        fill="white"
      />
      <path
        d="M8.86106 11.4546C8.26806 11.4546 7.80388 11.3309 7.46852 11.0834C7.13317 10.8324 6.95527 10.4754 6.93482 10.0122C6.93073 9.91323 6.92868 9.75238 6.92868 9.52965C6.92868 9.30692 6.93073 9.1443 6.93482 9.04177C6.95118 8.59632 7.12908 8.25162 7.46852 8.00769C7.81206 7.76021 8.27623 7.63647 8.86106 7.63647C9.25367 7.63647 9.59516 7.69127 9.88552 7.80087C10.18 7.91046 10.4029 8.05188 10.5542 8.22511C10.7096 8.3948 10.7914 8.5698 10.7996 8.75011C10.7996 8.78192 10.7853 8.81021 10.7566 8.83495C10.7321 8.85617 10.7014 8.86677 10.6646 8.86677H9.71376C9.67286 8.86677 9.64014 8.86147 9.61561 8.85086C9.59516 8.83672 9.57471 8.81728 9.55426 8.79253C9.50518 8.69354 9.42544 8.60869 9.31501 8.53799C9.20868 8.46728 9.05736 8.43193 8.86106 8.43193C8.38666 8.43193 8.14127 8.64405 8.12492 9.06829C8.12083 9.16728 8.11878 9.3193 8.11878 9.52435C8.11878 9.7294 8.12083 9.88318 8.12492 9.98571C8.14127 10.4347 8.39075 10.6592 8.87333 10.6592C9.11053 10.6592 9.29866 10.6079 9.4377 10.5054C9.57675 10.4029 9.64628 10.2473 9.64628 10.0387V9.94328H9.10031C9.05532 9.94328 9.01647 9.93091 8.98375 9.90616C8.95512 9.87788 8.94081 9.84429 8.94081 9.80541V9.39177C8.94081 9.35288 8.95512 9.32106 8.98375 9.29632C9.01647 9.26803 9.05532 9.25389 9.10031 9.25389H10.6585C10.7035 9.25389 10.7403 9.26803 10.7689 9.29632C10.8016 9.32106 10.818 9.35288 10.818 9.39177V10.0069C10.818 10.3074 10.7362 10.5673 10.5726 10.7865C10.4131 11.0021 10.1861 11.1683 9.89166 11.2849C9.5972 11.3981 9.25367 11.4546 8.86106 11.4546Z"
        fill="white"
      />
    </svg>
  ),
  hazmat: (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9.93457" fill="#FFE352" />
      <circle cx="12" cy="12" r="9.93457" fill="#FFE352" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50008 20.9679L7.50008 20.5001L6.7693 20.0001L11.4119 12.5271L2.4248 12.5226L2.5 12.0001L2.4248 10.3797L6.00008 4.71887L7.00008 4.00006L7.39145 3.72998L11.9999 12.0252L17 3.84315L17.5 4.5L18.2636 4.50006L21.5956 10.4001V11.6467L21.5 12.5271H12.5871L16.8436 20.1095L16.0001 20.5001L15.5001 20.9679H8.50008Z"
        fill="black"
      />
      <circle cx="12" cy="12" r="9.43457" stroke="#FFE352" />
      <circle cx="12.0001" cy="11.9999" r="2.1417" fill="black" stroke="#FFE352" />
    </svg>
  ),
  auto: (
    <span style={{ color: theme.colors.basicBlueColor, transform: 'translateY(1px)' }} className={'mdi mdi-truck'} />
  )
}

const streetTurnStatusColor = {
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE]: '#EAAE76',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED]: '#4555D0',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED]: '#27AE60',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.REJECTED]: '#FF5E5E'
}

const streetTurnStatusLabel = {
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE]: 'Available',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED]: 'Submitted',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED]: 'Approved',
  [DispatchDeliveryOrderStreetTurnDTO.StatusEnum.REJECTED]: 'Rejected'
}

const loadType = {
  [DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD]: 'LIVE',
  [DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK]: 'D&P'
}

const cargoType = {
  autoIndicator: 'Auto',
  hazmatIndicator: 'Hazmat'
}

export const ddoDirectory = {
  status,
  statusColor,
  loadType,
  cargoType,
  streetTurnStatusColor,
  streetTurnStatusLabel,
  icons
}
