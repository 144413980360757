import { PreviewNavigation } from '../../../common/grid/views/styledComponents'
import * as React from 'react'
import cn from 'classnames'
import { whatIsIt } from '../../../../services/functions/test/whatIsIt'

type TNavigation = {
  parentItem: any
  headings: string[] | ((unit: any) => string[])
  activeTab: string
  setActiveTab: (tab: string) => void
}

export const Navigation = ({ headings, activeTab, setActiveTab, parentItem }: TNavigation) => {
  const tabs = whatIsIt(headings).isFunction
    ? (headings as (parentItem: any) => string[])(parentItem)
    : (headings as string[])

  return (
    <PreviewNavigation>
      {tabs.map(heading => (
        <div key={heading} className={cn({ active: activeTab === heading })} onClick={() => setActiveTab(heading)}>
          {heading}
        </div>
      ))}
    </PreviewNavigation>
  )
}
