import * as React from 'react'
import { oc } from 'ts-optchain'
import { StickiedLabel } from '../../styledComponents'
import { DeliveryOrderViewDTO, OngoingActivityGroupDTO } from '../../../../../../api/origin/business-logic'
import { OngoingActivityRow } from './OngoingActivityRow/OngoingActivityRow'
import { IDateActivityGrid, SchedulerSortBy } from '../../../interfaces'
import { DateGroupBody, DateGroupHeader, Group as StyledGroup, GroupBlock, GroupHeader } from './styles'
import { Statistics } from './Statistics'
import { sortOngoingActivityGroups, TSortOngoingActivityGroups } from '../../../functions/sortOngoingActivityGroups'
// tslint:disable:max-line-length
import { dateService } from '../../../../../../services/timeService'
import { dispatchDeliveryOrderFilterSettings } from '../../../../../../services/DTO/dispatchDeliveryOrder/filterSettings'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectUndefined } from '../../../../../../store/select/undefinedSelect'
import { selectDrivers } from '../../../../../../store/select/driverSelect'
import { createTab } from '../../../../../../store/reducers/tabs/functions'
import { TabDTO } from '../../../../../../store/reducers/tabs/interfaces'
import { generateTab } from '../../../../../../services/functions/generate/generateTab'
import { DispatchDeliveryOrderColumn } from '../../../../grid/columnSettings/dispatchDeliveryOrderColumnSettings'

const titleWithoutDataMapping: Record<SchedulerSortBy, string> = {
  [SchedulerSortBy.driverAllStages]: 'DDO: No Driver Assigned',
  [SchedulerSortBy.deliveryStageDriver]: 'DDO: No Driver Assigned on Delivery Stage',
  [SchedulerSortBy.deliveryLocationCity]: 'Other',
  [SchedulerSortBy.date]: 'Other',
  [SchedulerSortBy.driverActivities]: 'Other'
}

type OwnProps = {
  date: IDateActivityGrid
  dateName: string
  sortBy: SchedulerSortBy
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Group = React.memo((props: Props) => {
  const { date, dateName, sortBy } = props
  const driverMapping = useAppSelector(sortBy === SchedulerSortBy.deliveryStageDriver ? selectDrivers : selectUndefined)
  const openLfd = React.useCallback(() => createTabWithSettings('LFD'), [])
  const openCutoff = React.useCallback(() => createTabWithSettings('CutOff'), [])
  const isToday =
    dateName === dateService.makeLabel(dateService.createStringDate.startDay, { long: true, fullYear: true })

  const createTabWithSettings = (dateType: 'LFD' | 'CutOff') => {
    const doType = dateType === 'LFD' ? DeliveryOrderViewDTO.TypeEnum.IMPORT : DeliveryOrderViewDTO.TypeEnum.EXPORT

    createTab(TabDTO.Type.dispatchDeliveryOrder, {
      label: `${dateType}: ${props.dateName}`,
      uiSettings: {
        filter: [
          ...generateTab(TabDTO.Type.dispatchDeliveryOrder).uiSettings.filter,
          {
            column: dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.CutOffLFD].name,
            // if there is any 'Last Free' or 'Cutoff' then it will be set time and activity
            // value: resetTimeToZero(`${props.dateName} ${new Date().getFullYear()}`)
            value: {
              from: dateService.convertStringDate(dateName).startDay,
              to: dateService.convertStringDate(dateName).endDay
            }
          },
          {
            column: dispatchDeliveryOrderFilterSettings[DispatchDeliveryOrderColumn.Type].name,
            // if there is any 'Last Free' or 'Cutoff' then it will be set time and activity
            // value: resetTimeToZero(`${props.dateName} ${new Date().getFullYear()}`)
            value: String(doType)
          }
        ]
      }
    })
  }

  let sortedOngoingActivityGroups: TSortOngoingActivityGroups = {
    sortedListWithTargetData: {},
    sortedListWithoutTargetData: []
  }

  switch (sortBy) {
    case SchedulerSortBy.deliveryStageDriver:
    case SchedulerSortBy.deliveryLocationCity:
    case SchedulerSortBy.driverAllStages:
      const sortedData = sortOngoingActivityGroups(sortBy, driverMapping)(
        Object.values(date.timeGroup).reduce((acc, curr) => {
          acc.push(...curr)
          return acc
        }, [])
      )

      sortedOngoingActivityGroups = sortedData
      break
    case SchedulerSortBy.date:
      sortedOngoingActivityGroups = {
        sortedListWithTargetData: date.timeGroup,
        sortedListWithoutTargetData: []
      }
      break
    default:
  }

  const renderRow = (ongoingActivity: OngoingActivityGroupDTO) => (
    <OngoingActivityRow key={ongoingActivity.id} activityGroup={ongoingActivity} />
  )

  return (
    <StyledGroup>
      <DateGroupHeader style={isToday ? extraCurrentDateHeaderStyles : extraDateHeaderStyles}>
        <StickiedLabel className={'left'}>{dateName}</StickiedLabel>
        {sortBy !== SchedulerSortBy.driverAllStages && (
          <StickiedLabel className={'right'}>
            <Statistics date={date} isToday={isToday} openCutoff={openCutoff} openLfd={openLfd} />
          </StickiedLabel>
        )}
      </DateGroupHeader>
      <DateGroupBody>
        {Object.keys(sortedOngoingActivityGroups.sortedListWithTargetData)
          .sort((a, b) => {
            switch (sortBy) {
              case SchedulerSortBy.deliveryStageDriver:
              case SchedulerSortBy.deliveryLocationCity:
              case SchedulerSortBy.driverAllStages:
                return a.localeCompare(b)
              case SchedulerSortBy.date:
              default:
                return parseInt(a, 10) - parseInt(b, 10)
            }
          })
          .map(label => (
            <GroupBlock key={label}>
              <GroupHeader>
                <StickiedLabel className={'left'}>
                  {label + (sortBy === SchedulerSortBy.date ? ':00' : '')}
                </StickiedLabel>
              </GroupHeader>
              {sortedOngoingActivityGroups.sortedListWithTargetData[label].map(renderRow)}
            </GroupBlock>
          ))}
        {Boolean(oc(sortedOngoingActivityGroups.sortedListWithoutTargetData).length()) && (
          <GroupBlock>
            <GroupHeader>
              <StickiedLabel className={'left'}>{titleWithoutDataMapping[sortBy] || 'Other'}</StickiedLabel>
            </GroupHeader>
            {sortedOngoingActivityGroups.sortedListWithoutTargetData.map(renderRow)}
          </GroupBlock>
        )}
      </DateGroupBody>
    </StyledGroup>
  )
})

const extraDateHeaderStyles = { justifyContent: 'space-between' }
const extraCurrentDateHeaderStyles = { ...extraDateHeaderStyles, backgroundColor: '#4555d0' }
