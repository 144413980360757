import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../..'
import { SsqBsqWidgetProvider } from '../../../../../providers/SsqBsqWidgetProvider'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { SsqBsqWidgetWindow } from './SsqBsqWidgetWindow'
import { SsqBsqWidgetPopover } from './SsqBsqWidgetPopover'
import { showWarningModalWindow } from '../../../../../store/reducers/modalWindow/functions'

type Props = {
  small?: boolean
}

export const SsqBsqWidget = React.memo((props: Props) => {
  const { small } = props
  const { sellSideQuote, buySideQuotes, isQuotesModified, cancelQuotesModifies } = useGridItemDDO()
  const highlighted = !oc(sellSideQuote)
    .miscSurcharges([])
    .concat(...buySideQuotes.map(buySideQuote => oc(buySideQuote).miscSurcharges([])))
    .every(miscSurcharge => miscSurcharge.description)

  const preventCloseWidget = isQuotesModified
    ? (closeWidget: () => void) => {
        showWarningModalWindow({
          width: 400,
          title: 'Cancel SSQ/BSQ changes?',
          buttons: [
            {
              label: 'Yes, close Window',
              outline: true,
              onClick: () => {
                cancelQuotesModifies()
                closeWidget()
              }
            },
            { label: 'Stay in the Window' }
          ]
        })
      }
    : undefined

  return (
    <SsqBsqWidgetProvider>
      <Widget
        isInitialExpanded={isQuotesModified}
        fullScreen={true}
        highlighted={highlighted}
        extraPreviewStyle={small ? extraStylesForSmallWidget : undefined}
        isExternal={true}
        title={'SSQ/BSQ'}
        RenderPreview={SsqBsqWidgetWindow}
        RenderDetails={SsqBsqWidgetPopover}
        preventCloseWidget={preventCloseWidget}
        previewProps={{ small }}
      />
    </SsqBsqWidgetProvider>
  )
})

const extraStylesForSmallWidget = {
  height: 40,
  padding: '4px 8px 0'
}
