import * as React from 'react'
import { Header } from './header'
import { Body } from './body'
import styled from 'styled-components'
import { customerAPI, driverAPI, locationAPI } from '../../../../../../../api/api'
import { GridSpinner } from '../../../../../../UI/Spinner/Spinner'
import { getStore } from '../../../../../../../store/configureStore'
import { promiseFetchObjectsByIdAndPushToStore } from '../../../functions'
import { QMPTab, ICustomerQuoterFilterState } from '../../../../interfaces'
import { getCustomerQuotes } from '../../../../../../../services/DTO/customerQuote/epics'
// @ts-ignore
import { debounce } from 'debounce'
import { ApplicationSizeProvider } from '../../../../../../../providers/ApplicationSizeProvider'
import { useQMP } from '../../../../../../../hooks/useQMP'

const StyledTableContainer = styled.div`
  position: relative;
  height: 100%;
  max-width: 100vw;
  flex: 1 0 auto;
`

type Props = {}

export const Table = (props: Props) => {
  return <div>Table</div>

  // const mounted = React.useRef(null)
  // const tableRef = React.useRef(null)
  // const qmpContext = useQMP()
  // const { customerQuotes } = qmpContext
  // const appliedFilters = qmpContext.filters.state[QMPTab.CUSTOMERQUOTE]
  // const [fetching, setFetching] = React.useState(false)
  //
  // React.useEffect(() => {
  //   if (tableRef && tableRef.current) {
  //     const table = tableRef.current
  //     table.parentElement.scrollTop = 0
  //   }
  // }, [Boolean(customerQuotes.newCustomerQuotes.state)])
  //
  // React.useEffect(() => {
  //   const isFirstRenderButNotFirstTabVisit = !mounted.current && customerQuotes.visited
  //   mounted.current = true
  //
  //   if (isFirstRenderButNotFirstTabVisit) {
  //     return
  //   }
  //
  //   setFetching(true)
  //   doRequests(appliedFilters, customerQuotes.setCustomerQuotes, setFetching)
  // }, [
  //   appliedFilters.deliveryOrderType,
  //   appliedFilters.pickupLocationId,
  //   appliedFilters.loadType,
  //   appliedFilters.deliveryLocation,
  //   appliedFilters.customerId,
  //   appliedFilters.containerTypeId,
  //   appliedFilters.returnLocationId,
  //   appliedFilters.status,
  //   appliedFilters.number
  // ])
  //
  // return (
  //   <StyledTableContainer ref={tableRef}>
  //     {fetching && <GridSpinner />}
  //     <Header />
  //     <ApplicationSizeProvider>
  //       <Body
  //         customerQuoteIds={customerQuotes.customerQuoteIds}
  //         openedCustomerQuoteId={customerQuotes.openedCustomerQuoteId}
  //       />
  //     </ApplicationSizeProvider>
  //   </StyledTableContainer>
  // )
}
//
// const doRequests = debounce((appliedFilters: CustomerQuoterFilterState, setCustomerQuotes: any, setFetching: any)
// => {
//   const fetchLocationIds: string[] = [appliedFilters.pickupLocationId, appliedFilters.returnLocationId]
//   const fetchCustomerIds: string[] = Array.isArray(appliedFilters.customerId)
//     ? appliedFilters.customerId.map(item => item.value)
//     : []
//   const fetchVendorIds: string[] = Array.isArray(appliedFilters.vendorId)
//     ? appliedFilters.vendorId.map(item => item.value)
//     : []
//
//   getCustomerQuotes(appliedFilters).then(customerQuotesData => {
//     const store = getStore().getState()
//
//     customerQuotesData.forEach(customerQuote => {
//       fetchLocationIds.push(customerQuote.pickupLocationId, customerQuote.returnLocationId)
//       fetchCustomerIds.push(customerQuote.customerId)
//     })
//
//     Promise.all([
//       promiseFetchObjectsByIdAndPushToStore(
//         fetchLocationIds,
//         locationAPI.findById,
//         parseDTO.location,
//         true,
//         store.location
//       ),
//       promiseFetchObjectsByIdAndPushToStore(
//         fetchCustomerIds,
//         customerAPI.findById,
//         parseDTO.customer,
//         true,
//         store.customer
//       ),
//   promiseFetchObjectsByIdAndPushToStore(fetchVendorIds, driverAPI.findById, tryAddDriverToStore, true, store.driver)
//     ]).finally(() => {
//       setFetching(false)
//       setCustomerQuotes(customerQuotesData)
//     })
//   })
// }, 1000)
