import actionCreatorFactory, { Action } from 'typescript-fsa'
import { AjaxError } from 'rxjs/ajax'
import { ILoginRedirectResponce } from '../interfaces'

const actionCreator = actionCreatorFactory('AUTHORIZATION')

export const authLoginWithRedirect = actionCreator('AUTH_LOGIN_WITH_REDIRECT')
export const authProcessRedirect = actionCreator<ILoginRedirectResponce>('AUTH_PROCESS_REDIRECT')
export const authGetTokens = actionCreator.async<null, null, AjaxError>('ASYNC/AUTH_GET_TOKENS')
export const logout = actionCreator('LOGOUT')

export const dispatchActionAfterAuthorization = actionCreator<Action<any>>('DISPATCH_ACTION_WHEN_AUTHORIZED')
export const cleanUpDispatchedActionsAfterAuthorization = actionCreator('CLEAN_UP_DISPATCHED_ACTIONS_WHEN_AUTHORIZED')

export const authClientOrServerError = actionCreator('CLIENT_OR_SERVER_ERROR')
