import { ContainerTypeDTO } from '../../../api/origin/business-logic'

export const getContainerTypeNameWithCode = (container: ContainerTypeDTO, ifEmpty: any = null) => {
  if (container) {
    const code = container.code
    const name = container.name

    if (code && name) {
      return code + ' – ' + name
    }

    if (code) {
      return code
    }

    if (name) {
      return name
    }
  }

  return ifEmpty
}

export const getContainerTypesDropDownList = (list: ContainerTypeDTO[], withCode?: boolean) =>
  list && list.length
    ? list.map((container: ContainerTypeDTO) => ({
        label: withCode ? getContainerTypeNameWithCode(container, '') : container.name,
        value: container
      }))
    : []
