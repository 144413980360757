import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import theme from '../../../../../../../styles/theme'
import { TCalcPhase } from '../../../../functions'
import { activityListPhasePlaceholderBase64 } from '../../../../../../UI/icons/ActivityListPhasePlaceholder'
import {
  OngoingActivityDTO,
  OngoingActivityGroupDTO,
  TransportationActivityViewDTO
} from '../../../../../../../api/origin/business-logic'
import { activityDirectory } from '../../../../../../../services/DTO/activity/directory'
import { PreviewPhaseDateColumn } from './PreviewPhaseDateColumn/PreviewPhaseDateColumn'
import { PreviewPhaseTypeColumn } from './PreviewPhaseTypeColumn'
import { PreviewPhaseDestinationColumn } from './PreviewPhaseDestinationColumn/PreviewPhaseDestinationColumn'
import { PreviewPhaseVendorColumn } from './PreviewPhaseVendorColumn/PreviewPhaseVendorColumn'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectIsDriverOnline } from '../../../../../../../store/select/driverActivitySelect'
import { isGotoActivity } from '../../../../../../../services/functions/test/isGotoActivity'

type Props = {
  activityGroupId: string
  stage: TransportationActivityViewDTO.StageEnum
  dispatchDeliveryOrderNumber: number
  activityGroupDate: string
  activityGroupStage: OngoingActivityGroupDTO.ActivityGroupStageEnum
  highlight: OngoingActivityGroupDTO.HighlightEnum
  phase: TCalcPhase
  isFavorite: boolean
  isMissed?: boolean
  isNowSection?: boolean
}

export const PreviewPhase = React.memo((props: Props) => {
  const {
    activityGroupId,
    stage,
    phase,
    activityGroupDate,
    highlight,
    isFavorite,
    dispatchDeliveryOrderNumber,
    isMissed,
    isNowSection,
    activityGroupStage
  } = props

  const { activity, extraActivity, isPending, isActive, isAssigned } = phase || ({} as TCalcPhase)
  const activityDriverId = oc(activity).driverId()
  const extraActivityDriverId = oc(extraActivity).driverId()
  const isActivityDriverOnline = useAppSelector(selectIsDriverOnline(activityDriverId))
  const isExtraActivityDriverOnline = useAppSelector(selectIsDriverOnline(extraActivityDriverId))
  const rowHighlighted = highlight === OngoingActivityGroupDTO.HighlightEnum.ROW
  const isActivePhase = isPending || isActive || isAssigned || rowHighlighted
  const isOneActivityView = !extraActivity
  const isTwoActivitiesView = !isOneActivityView
  const isCurrentPhase = !isMissed && String(stage) === String(activityGroupStage)
  const isGoto = isGotoActivity(extraActivity || activity)
  const isGotoCompleted = isGoto && activity.status === OngoingActivityDTO.StatusEnum.COMPLETED
  const showOfflineIndicator =
    isActivePhase &&
    (oc(activity).isLive() || oc(extraActivity).isLive()) &&
    (isActivityDriverOnline === false || isExtraActivityDriverOnline === false)

  const generateGradient = (color1: string, color2: string = '#fcfdff'): string => {
    return `repeating-linear-gradient(-45deg,${color2},${color2} 45px,${color1} 45px,${color1} 85px)`
  }
  const inProgressActivityLinearGradient = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: generateGradient('#eff1ff'),
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: generateGradient('#ede6f7'),
    [TransportationActivityViewDTO.StageEnum.RETURN]: generateGradient('#ecf7ef')
  }
  const extraInProgressActivityLinearGradient = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: generateGradient('#5C6FFF50', '#5C6FFF20'),
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: generateGradient('#A180D050', '#A180D020'),
    [TransportationActivityViewDTO.StageEnum.RETURN]: generateGradient('#5FB18F50', '#5FB18F20')
  }

  const outlineColorMapping = {
    red: theme.colors.defaultRed,
    offline: theme.colors.offline,
    yellow: '#ffd600',
    'stage-color': activityDirectory.stageColor[stage]
  }
  const outlineColor = (() => {
    if (activity && activity.troubleTicketId) {
      return 'red'
    } else if (showOfflineIndicator) {
      return 'offline'
    } else if (rowHighlighted || isPending) {
      return 'yellow'
    } else if (isActive || isAssigned) {
      return 'stage-color'
    } else {
      return undefined
    }
  })()
  const rowStyle = {
    color: isPending || isActive || isAssigned ? undefined : '#727272',
    background: phase ? undefined : `no-repeat center/contain`,
    backgroundColor:
      activity && activity.troubleTicketId
        ? '#ffeaea'
        : isFavorite && isActivePhase
        ? '#e3edfd'
        : isActivePhase
        ? 'white'
        : undefined,
    backgroundImage: phase
      ? isActive && !isGotoCompleted
        ? inProgressActivityLinearGradient[stage]
        : undefined
      : `url(${activityListPhasePlaceholderBase64})`,
    outlineColor: outlineColorMapping[outlineColor]
  }

  return (
    <Phase
      data-id={[
        `ddoNumber-${dispatchDeliveryOrderNumber}`,
        `activityGroupDate-${activityGroupDate}`,
        `stage-${stage}`,
        `highlight-${outlineColor}`
      ]
        .filter(Boolean)
        .join(' ')}
      className={cn(stage, {
        twoActivitiesView: isTwoActivitiesView,
        isNowSection,
        isActive
      })}
      style={
        isOneActivityView
          ? {
              color: rowStyle.color,
              background: rowStyle.background,
              backgroundColor: rowStyle.backgroundColor,
              backgroundImage: rowStyle.backgroundImage,
              outlineColor: rowStyle.outlineColor
            }
          : {
              outlineColor: rowStyle.outlineColor
            }
      }
    >
      {phase && activity ? (
        isTwoActivitiesView ? (
          <>
            <Phase
              className={'extra-ongoing-activity-line'}
              style={{
                backgroundColor: 'white',
                backgroundImage: isGotoCompleted ? undefined : extraInProgressActivityLinearGradient[stage]
              }}
            >
              <PreviewPhaseDateColumn
                activityGroupDate={activityGroupDate}
                phase={phase}
                isCurrentPhase={isCurrentPhase}
                isMissed={isMissed}
                isExtraActivity={true}
                isTwoActivitiesView={true}
                isDriverOnline={isExtraActivityDriverOnline}
              />
              <PreviewPhaseVendorColumn
                activityGroupId={activityGroupId}
                phase={phase}
                isExtraActivity={true}
                isTwoActivitiesView={true}
                isActivePhase={isActivePhase}
                isDriverOnline={isExtraActivityDriverOnline}
              />
              <PreviewPhaseDestinationColumn phase={phase} isExtraActivity={true} isTwoActivitiesView={true} />
              <PreviewPhaseTypeColumn phase={phase} isExtraActivity={true} isTwoActivitiesView={true} />
            </Phase>
            <Phase
              className={'ongoing-activity-line'}
              style={{
                color: rowStyle.color,
                backgroundColor: rowStyle.backgroundColor
              }}
            >
              <PreviewPhaseDateColumn
                activityGroupDate={activityGroupDate}
                phase={phase}
                isCurrentPhase={isCurrentPhase}
                isMissed={isMissed}
                isTwoActivitiesView={true}
                isDriverOnline={isActivityDriverOnline}
              />
              <PreviewPhaseVendorColumn
                activityGroupId={activityGroupId}
                phase={phase}
                isTwoActivitiesView={true}
                isActivePhase={isActivePhase}
                isDriverOnline={isActivityDriverOnline}
              />
              <PreviewPhaseDestinationColumn phase={phase} isTwoActivitiesView={true} />
              <PreviewPhaseTypeColumn phase={phase} isTwoActivitiesView={true} />
            </Phase>
          </>
        ) : (
          <>
            <PreviewPhaseDateColumn
              activityGroupDate={activityGroupDate}
              phase={phase}
              isCurrentPhase={isCurrentPhase}
              isMissed={isMissed}
              isNowSection={isNowSection}
              isDriverOnline={isActivityDriverOnline}
            />
            <PreviewPhaseVendorColumn
              activityGroupId={activityGroupId}
              phase={phase}
              isActivePhase={isActivePhase}
              isDriverOnline={isActivityDriverOnline}
            />
            <PreviewPhaseDestinationColumn phase={phase} isNowSection={isNowSection} />
            <PreviewPhaseTypeColumn phase={phase} isNowSection={isNowSection} />
          </>
        )
      ) : (
        <Column />
      )}
    </Phase>
  )
})

export const Container = styled.div`
  display: grid;
  outline: 1px solid #dce6f5;
  background: #f5f7f7;
  border-radius: 2px;
  /* overflow: hidden; */
`

export const Column = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-left: 1px solid rgb(220, 230, 245, 0.5);
  padding: 7px;
`
export const Phase = styled(Container)`
  // Dates | Driver | Location | Activity Type
  grid-template-columns: 1fr 18% 24% 20%;

  &.twoActivitiesView {
    grid-template-columns: none;

    ${Column} {
      height: 25px;
    }
  }

  &.extra-ongoing-activity-line {
    // Date | Driver & Activity Status | Location | Activity Type
    grid-template-columns: 1fr 20% 21% 17%;
  }

  &.ongoing-activity-line {
    // Date | Driver | Location | Activity Type
    grid-template-columns: 1fr 20% 21% 17%;
  }
`
export const TextLines = styled.div`
  width: 100%;
  overflow: hidden;

  &.space-between {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const Text = styled.div`
  line-height: 16px;
`
export const ThinText = styled(Text)`
  font-weight: 300;
`
export const TextLine = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ThinTextLine = styled(ThinText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DDOLink = styled.div`
  color: ${theme.colors.basicBlueColor};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #5599ff;
  }
`
