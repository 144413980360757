import * as React from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import { NotificationDTO } from '../../../../../api/origin/communication-hub-service'
import { Icon, IconMode, IconName } from '../../Icon'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'
import { requestNotificationComplete, requestNotificationDelete } from '../../../epics'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../../store/reducers/communicationHub'
import { showModalWindow, showWarningModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { fadeOutElement } from '../../../../../services/functions/fadeOutElement'

type Props = {
  containerRef: React.MutableRefObject<any>
  notification: NotificationDTO
}

export const NotificationActions = (props: Props) => {
  const { containerRef, notification } = props
  const { status, replies, type } = notification
  const dispatch = useAppDispatch()
  const mountedRef = React.useRef(true)
  const popoverRef = React.useRef(null)
  const { statePopover, wrapperRef, togglePopover, setPopoverState } = useOutsideClick({
    capture: false,
    hideOnScroll: true
  })
  const isSimpleNitificationActionAvailable =
    status === NotificationDTO.StatusEnum.COMPLETED && type === NotificationDTO.TypeEnum.SIMPLE
  const isActionsAvailable =
    status !== NotificationDTO.StatusEnum.COMPLETED && status !== NotificationDTO.StatusEnum.DELETED

  const completeNotification = () => {
    setPopoverState(false)

    showWarningModalWindow({
      width: 300,
      title: 'Complete Notification',
      closeButton: true,
      buttons: [
        { label: 'Cancel', outline: true },
        {
          label: 'Confirm',
          onClick: () => {
            fadeOutElement(containerRef, ({ showElementFn }) => {
              requestNotificationComplete(notification.id)
                .then(updatedNotification => {
                  dispatch(communicationHubActions.pushNotificationToStorage(updatedNotification))
                })
                .catch(() => {
                  if (mountedRef.current) {
                    showElementFn()
                  }
                })
            })()
          }
        }
      ]
    })
  }

  const deleteNotification = () => {
    setPopoverState(false)

    showWarningModalWindow({
      width: 300,
      title: 'Delete Notification',
      closeButton: true,
      buttons: [
        { label: 'Cancel', outline: true },
        {
          label: 'Confirm',
          onClick: () => {
            fadeOutElement(containerRef, ({ showElementFn }) => {
              requestNotificationDelete(notification.id)
                .then(updatedNotification => {
                  dispatch(communicationHubActions.pushNotificationToStorage(updatedNotification))
                })
                .catch(() => {
                  if (mountedRef.current) {
                    showElementFn()
                  }
                })
            })()
          }
        }
      ]
    })
  }

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    if (statePopover && popoverRef.current) {
      const buttonProps = wrapperRef.current.getBoundingClientRect()

      popoverRef.current.style.top = buttonProps.top + 'px'
    }
  }, [statePopover])

  return (
    <Container ref={wrapperRef}>
      {(isActionsAvailable || isSimpleNitificationActionAvailable) && (
        <>
          <Icon active={statePopover} icon={IconName.DOTS} mode={IconMode.DARK} onClick={togglePopover} />
          {statePopover && (
            <Portal>
              <Popover ref={popoverRef}>
                {isSimpleNitificationActionAvailable ? (
                  <Action onClick={deleteNotification}>Delete</Action>
                ) : Boolean(replies && replies.length) ? (
                  <Action onClick={completeNotification}>Complete</Action>
                ) : (
                  <Action onClick={deleteNotification}>Delete</Action>
                )}
              </Popover>
            </Portal>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div``

const Popover = styled.div`
  z-index: 1;
  width: 130px;
  position: absolute;
  right: 30px;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 0;
  user-select: none;
`
const Action = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #323e4e;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(229, 231, 239, 0.22);
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`
