import { RootState } from '..'
import { UserDTO } from '../../api/origin/user-service'

// SELECT ONE
export const selectUser = (id: string) => (state: RootState): UserDTO | undefined => {
  return state.lists.user[id]
}

// SELECT MANY
export const selectUsers = (state: RootState): Record<string, UserDTO> => {
  return state.lists.user
}
