import * as React from 'react'
import { IMove, RouteBuilderGridView } from '../store/reducers/communicationHub/interfaces'
// tslint:disable:max-line-length
import { RouteBuilderToastMessageType } from '../components/CommunicationHub/views/RouteBuilderView/RouteBuilderToastMessage'

export type TRouteBuilderContext = {
  view: RouteBuilderGridView
  isDriverAssignable: boolean
  activeTabDate: string
  vendorId: string
  authUserId: string
  setFetch: React.Dispatch<React.SetStateAction<boolean>>
  getCurrentTabDayMoves(): Promise<IMove[]>
  setMoveErrorMappingRef: { current: Record<string, React.Dispatch<React.SetStateAction<string>>> }
  setRouteBuiderToastMessage: React.Dispatch<
    React.SetStateAction<{
      type: RouteBuilderToastMessageType
      message: string
    }>
  >
}

export const RouteBuilderContext: React.Context<TRouteBuilderContext> = React.createContext({
  view: null,
  isDriverAssignable: null,
  activeTabDate: null,
  vendorId: null,
  authUserId: null,
  setFetch: null,
  getCurrentTabDayMoves: null,
  setMoveErrorMappingRef: null,
  setRouteBuiderToastMessage: null
})
