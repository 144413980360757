import * as React from 'react'
import { oc } from 'ts-optchain'
import { TroubleTicketDTO } from '../../../../../api/origin/business-logic'
import {
  NotificationContainer,
  NotificationStatus,
  NotificationTitle,
  NotificationDescription,
  ButtonClose
} from './styles'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'
import { fadeOutElement } from '../../../../../services/functions/fadeOutElement'
import { IMessage } from '../../../../../store/reducers/communicationHub/interfaces'

type Props = {
  data: IMessage
  removeNotification: () => void
}

export const TroubleTicketAlert = React.memo((props: Props) => {
  const ref = React.useRef(null)
  const { data, removeNotification } = props
  const alertMeta = data.metadata
  const params = oc(alertMeta).params({})

  if (!data) {
    return null
  }

  const resolvedTroubleTicket = data.status !== TroubleTicketDTO.StatusEnum.NEW

  React.useEffect(() => {
    if (resolvedTroubleTicket) {
      setTimeout(() => {
        fadeOutElement(ref, removeNotification)()
      }, 5000)
    }
  }, [resolvedTroubleTicket])

  let title1 = ''
  let title2 = ''
  let description = ''
  const ddoNumber = oc(alertMeta).ddo.number() || '#'
  const driverName = oc(alertMeta).driver.name('Name')

  if (resolvedTroubleTicket) {
    title1 = `Trouble ticket resolved as ${capitalizeFirstLetter(String(data.status).toLowerCase())}`
    description = `DDO ${ddoNumber}, Driver: ${driverName}`
  } else {
    title1 = `Trouble ticket for DDO ${ddoNumber}`
    title2 = `Driver: ${driverName}`
    const location = [params.locationShortName, oc(params).locationFullName('Location')].filter(Boolean).join(', ')
    description = `${oc(params).activityType('Activity Type')} at ${location}`
  }

  return (
    <NotificationContainer
      data-id={'alert-notifications-list-item alert-notification-trouble-ticket'}
      ref={ref}
      className={'notification ' + (resolvedTroubleTicket ? 'notification_success' : 'notification_error')}
      style={{ cursor: 'pointer' }}
      onClick={(event: any) => {
        if (oc(alertMeta).ddo.id()) {
          openDDOinNewTab({
            event,
            ddoNumber: alertMeta.ddo.number,
            ddoId: alertMeta.ddo.id
          })
        }
      }}
    >
      {resolvedTroubleTicket && (
        <ButtonClose
          data-id={'alert-notification-trouble-ticket-close-button'}
          className={'mdi mdi-close'}
          onClick={fadeOutElement(ref, removeNotification)}
        />
      )}
      <NotificationStatus
        data-id={'alert-notification-trouble-ticket-status'}
        className={'mdi ' + (resolvedTroubleTicket ? 'mdi-checkbox-marked-circle' : 'mdi-alert-decagram')}
      >
        {resolvedTroubleTicket ? 'Confirmation' : 'Alert'}
      </NotificationStatus>
      {title1 && <NotificationTitle data-id={'alert-notification-trouble-title1'}>{title1}</NotificationTitle>}
      {title2 && <NotificationTitle data-id={'alert-notification-trouble-title2'}>{title2}</NotificationTitle>}
      {description && (
        <NotificationDescription data-id={'alert-notification-trouble-ticket-description'}>
          {description}
        </NotificationDescription>
      )}
    </NotificationContainer>
  )
})

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
