import { buySideQuoteRateAPI, callAPIWithErrorMessage, sellSideQuoteRateAPI } from '../../../../api/api'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../api/origin/qmp-service'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

export const requestExpireSsqRate = (id: string): Promise<SellSideQuoteRateDTO> => {
  return callAPIWithErrorMessage(sellSideQuoteRateAPI.expireSellSideQuote, id).then(async rate => {
    await pushListItemsToStore({ update: { [EntityType.rate]: [rate] } })

    return rate
  })
}

export const requestExpireBsqRate = (id: string): Promise<BuySideQuoteRateDTO> => {
  return callAPIWithErrorMessage(buySideQuoteRateAPI.expireBuySideQuote, id).then(async rate => {
    await pushListItemsToStore({ update: { [EntityType.rate]: [rate] } })

    return rate
  })
}

export const requestCreateSsqRate = (rate: SellSideQuoteRateDTO, overwrite: boolean): Promise<SellSideQuoteRateDTO> => {
  return callAPIWithErrorMessage(sellSideQuoteRateAPI.createSellSideQuoteRate, overwrite, rate).then(
    async createdRate => {
      await pushListItemsToStore({ update: { [EntityType.rate]: [createdRate] } })

      return createdRate
    }
  )
}

export const requestCreateBsqRate = (rate: BuySideQuoteRateDTO, overwrite: boolean): Promise<BuySideQuoteRateDTO> => {
  return callAPIWithErrorMessage(buySideQuoteRateAPI.createBuySideQuoteRate, overwrite, rate).then(
    async createdRate => {
      await pushListItemsToStore({ update: { [EntityType.rate]: [createdRate] } })

      return createdRate
    }
  )
}
