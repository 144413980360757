import * as R from 'remeda'
import { CustomerViewDTO, DeliveryOrderViewDTO, LocationViewDTO, SteamShipLineViewDTO } from '../../../../../api/api'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { parseCustomers } from './parseCustomers'
import { mergeParsedLists } from './mergeParsedLists'
import { parseLocations } from './parseLocations'
import { parseSteamShipLines } from './parseSteamShipLines'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'

export const parseDeliveryOrders = async (deliveryOrders: DeliveryOrderViewDTO[]): Promise<TListsStateUpdate> => {
  const customersToParse: CustomerViewDTO[] = []
  const steamShipLinesToParse: SteamShipLineViewDTO[] = []
  const locationsToParse: LocationViewDTO[] = []
  const result = generateEmptyListsState()

  try {
    deliveryOrders.forEach(deliveryOrder => {
      let ignoreDeliveryOrder = false

      if (deliveryOrder.pickupLocation) {
        // @ts-ignore
        locationsToParse.push(deliveryOrder.pickupLocation)
      }
      if (deliveryOrder.deliveryLocation) {
        // @ts-ignore
        locationsToParse.push(deliveryOrder.deliveryLocation)
      }
      if (deliveryOrder.returnLocation) {
        // @ts-ignore
        locationsToParse.push(deliveryOrder.returnLocation)
      }
      if (deliveryOrder.customer) {
        // @ts-ignore
        customersToParse.push(deliveryOrder.customer)
      }
      if (deliveryOrder.steamShipLine) {
        // @ts-ignore
        steamShipLinesToParse.push(deliveryOrder.steamShipLine)
      }

      if (isFullObject(deliveryOrder) && !(deliveryOrder.dispatchDeliveryOrderIds || []).length) {
        ignoreDeliveryOrder = true
      }

      if (!ignoreDeliveryOrder) {
        result[EntityType.deliveryOrder].push(
          R.omit(deliveryOrder, ['pickupLocation', 'deliveryLocation', 'returnLocation', 'customer', 'steamShipLine'])
        )
      }
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (customersToParse.length) {
    await parseCustomers(customersToParse).then(mergeParsedLists(result))
  }

  if (steamShipLinesToParse.length) {
    await parseSteamShipLines(steamShipLinesToParse).then(mergeParsedLists(result))
  }

  if (locationsToParse.length) {
    await parseLocations(locationsToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}
