import { TransportationActivityViewDTO } from '../../../api/api'
import { sortActivities } from '../sort/sortActivities'

export const getStageActivities = (
  stage: TransportationActivityViewDTO.StageEnum,
  activities: TransportationActivityViewDTO[]
): TransportationActivityViewDTO[] => {
  if (!Array.isArray(activities)) {
    return activities
  }

  return sortActivities(activities.filter(activity => activity.stage === stage))
}
