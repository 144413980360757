import { COMMUNICATION_HUB_API_BASE_PATH } from '../../../api/communication-hub'
import { getDefaultHeaders } from '../../../api/defaultHeaders'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { convertErrorToMessage } from '../../../services/DTO/saveDTO'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'
import { BUSINESS_LOGIC_API_BASE_PATH } from '../../../api/businessLogicApi'

const axiosIstance = axios.create({
  baseURL: BUSINESS_LOGIC_API_BASE_PATH
})

axiosIstance.interceptors.request.use(
  _config => {
    if (_config && _config.headers) {
      const defaultHeaders = getDefaultHeaders()
      Object.keys(defaultHeaders).map(key => {
        _config.headers[key] = defaultHeaders[key]
      })
    }

    return _config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosIstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // Do something with response error
    // const errorData = error.response.data
    //
    // const pushError = (err: any) => {
    //   getStore().dispatch(addServerMessage({ type: 'error', message: convertErrorToMessage(err) }))
    // }
    //
    // if (Array.isArray(errorData)) {
    //   errorData.forEach(pushError)
    // } else {
    //   pushError(errorData)
    // }

    Sentry.captureException(error.response.data)
    return Promise.reject(error.response)
  }
)

export const handleMoveServiceError = (returnOnError?: any) => (errorResponse: any) => {
  const errorData = errorResponse.data

  if (errorResponse.status === 409) {
    // do nothing. Show toast message (move service)
  } else {
    const pushError = (err: any) => {
      showErrorModalWindow({ content: convertErrorToMessage(err) })
    }

    if (Array.isArray(errorData)) {
      errorData.forEach(pushError)
    } else {
      pushError(errorData)
    }

    if (returnOnError !== undefined) {
      return returnOnError
    }
  }

  throw errorResponse
}

export default axiosIstance
