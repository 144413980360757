import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const chubAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('CHUB/ASYNC');
import { FetchAPI, FetchArgs, ActivityDTO, AddressDTO, AlertDTO, AnswersDTO, BatchCreateRepliesDTO, ChannelDTO, ContainerDTO, CreateAlertDTO, CreateChannelDTO, CreateDocumentDTO, CreateMessageDTO, CreateNotificationDTO, CreateNotificationReplyDTO, DateTimeRangeDTO, DestinationDTO, DocumentMetadataDTO, DocumentValidationDTO, DriverCounterDTO, DriverInfoDTO, DriverInviteDTO, DriverNotificationDTO, FileDataDTO, GeneralCounterDTO, GeoDataDTO, GetNotificationListDTO, InviteDTO, LastMessageDTO, LocalDateTime, MessageDTO, MetadataDTO, MetadataDdoDTO, MetadataDriverDTO, MoveDTO, NotificationDTO, NotificationOptionDTO, NotificationReplyDTO, PhoneNumberDTO, PhoneNumberInstance, PhoneNumberStatDTO, RawDraftContentBlockDTO, RawDraftContentStateDTO, RawDraftEntityRangeDTO, RemovePhoneNumbersDTO, SearchDocumentDTO, SendMmsDTO, SendSmsDTO, SmsTestingDTO, SmsTestingSendDTO, UpdateAlertDTO, UpdateChannelDTO, UpdateMessageDTO, UserChannelDTO, UserCounterDTO, UserDataDTO, VendorDTO, WebHookMailgunDTO, WebHookTwilioStatusDTO, WebHookTwilioSuccessDTO, WorkOrderDTO, DateISOString } from './api';
export interface IAlertAlertControllerCreateRequest { body: CreateAlertDTO, options?: any }
export type IAlertAlertControllerCreateSuccess = AlertDTO;
export type IAlertAlertControllerCreateError = any;
export const alertAlertControllerCreateAction = chubAsyncActionCreator.async<
  IAlertAlertControllerCreateRequest, IAlertAlertControllerCreateSuccess, IAlertAlertControllerCreateError
>('ALERT_ALERTCONTROLLERCREATE');

export interface IAlertAlertControllerGetAlertsRequest { channelId?: string, status?: 'NEW' | 'PROCEED' | 'UNSUCCESSFUL' | 'RESOLVE', options?: any }
export type IAlertAlertControllerGetAlertsSuccess = MessageDTO[];
export type IAlertAlertControllerGetAlertsError = any;
export const alertAlertControllerGetAlertsAction = chubAsyncActionCreator.async<
  IAlertAlertControllerGetAlertsRequest, IAlertAlertControllerGetAlertsSuccess, IAlertAlertControllerGetAlertsError
>('ALERT_ALERTCONTROLLERGETALERTS');

export interface IAlertAlertControllerRemoveRequest { id: string, options?: any }
export type IAlertAlertControllerRemoveSuccess = AlertDTO;
export type IAlertAlertControllerRemoveError = any;
export const alertAlertControllerRemoveAction = chubAsyncActionCreator.async<
  IAlertAlertControllerRemoveRequest, IAlertAlertControllerRemoveSuccess, IAlertAlertControllerRemoveError
>('ALERT_ALERTCONTROLLERREMOVE');

export interface IAlertAlertControllerUpdateRequest { id: string, body: UpdateAlertDTO, options?: any }
export type IAlertAlertControllerUpdateSuccess = AlertDTO;
export type IAlertAlertControllerUpdateError = any;
export const alertAlertControllerUpdateAction = chubAsyncActionCreator.async<
  IAlertAlertControllerUpdateRequest, IAlertAlertControllerUpdateSuccess, IAlertAlertControllerUpdateError
>('ALERT_ALERTCONTROLLERUPDATE');

export interface IChannelChannelControllerCreateRequest { body: CreateChannelDTO, options?: any }
export type IChannelChannelControllerCreateSuccess = ChannelDTO;
export type IChannelChannelControllerCreateError = any;
export const channelChannelControllerCreateAction = chubAsyncActionCreator.async<
  IChannelChannelControllerCreateRequest, IChannelChannelControllerCreateSuccess, IChannelChannelControllerCreateError
>('CHANNEL_CHANNELCONTROLLERCREATE');

export interface IChannelChannelControllerMuteRequest { id: string, options?: any }
export type IChannelChannelControllerMuteSuccess = UserChannelDTO;
export type IChannelChannelControllerMuteError = any;
export const channelChannelControllerMuteAction = chubAsyncActionCreator.async<
  IChannelChannelControllerMuteRequest, IChannelChannelControllerMuteSuccess, IChannelChannelControllerMuteError
>('CHANNEL_CHANNELCONTROLLERMUTE');

export interface IChannelChannelControllerOpenRequest { id: string, options?: any }
export type IChannelChannelControllerOpenSuccess = UserChannelDTO;
export type IChannelChannelControllerOpenError = any;
export const channelChannelControllerOpenAction = chubAsyncActionCreator.async<
  IChannelChannelControllerOpenRequest, IChannelChannelControllerOpenSuccess, IChannelChannelControllerOpenError
>('CHANNEL_CHANNELCONTROLLEROPEN');

export interface IChannelChannelControllerRemoveRequest { id: string, options?: any }
export type IChannelChannelControllerRemoveSuccess = ChannelDTO;
export type IChannelChannelControllerRemoveError = any;
export const channelChannelControllerRemoveAction = chubAsyncActionCreator.async<
  IChannelChannelControllerRemoveRequest, IChannelChannelControllerRemoveSuccess, IChannelChannelControllerRemoveError
>('CHANNEL_CHANNELCONTROLLERREMOVE');

export interface IChannelChannelControllerUnmuteRequest { id: string, options?: any }
export type IChannelChannelControllerUnmuteSuccess = UserChannelDTO;
export type IChannelChannelControllerUnmuteError = any;
export const channelChannelControllerUnmuteAction = chubAsyncActionCreator.async<
  IChannelChannelControllerUnmuteRequest, IChannelChannelControllerUnmuteSuccess, IChannelChannelControllerUnmuteError
>('CHANNEL_CHANNELCONTROLLERUNMUTE');

export interface IChannelChannelControllerUpdateRequest { id: string, body: UpdateChannelDTO, options?: any }
export type IChannelChannelControllerUpdateSuccess = ChannelDTO;
export type IChannelChannelControllerUpdateError = any;
export const channelChannelControllerUpdateAction = chubAsyncActionCreator.async<
  IChannelChannelControllerUpdateRequest, IChannelChannelControllerUpdateSuccess, IChannelChannelControllerUpdateError
>('CHANNEL_CHANNELCONTROLLERUPDATE');

export interface ICounterCounterControllerGetDriverCountersRequest { pageNo: number, pageSize: number, term?: string, options?: any }
export type ICounterCounterControllerGetDriverCountersSuccess = DriverCounterDTO[];
export type ICounterCounterControllerGetDriverCountersError = any;
export const counterCounterControllerGetDriverCountersAction = chubAsyncActionCreator.async<
  ICounterCounterControllerGetDriverCountersRequest, ICounterCounterControllerGetDriverCountersSuccess, ICounterCounterControllerGetDriverCountersError
>('COUNTER_COUNTERCONTROLLERGETDRIVERCOUNTERS');

export interface ICounterCounterControllerGetGeneralCountersRequest { options?: any }
export type ICounterCounterControllerGetGeneralCountersSuccess = GeneralCounterDTO[];
export type ICounterCounterControllerGetGeneralCountersError = any;
export const counterCounterControllerGetGeneralCountersAction = chubAsyncActionCreator.async<
  ICounterCounterControllerGetGeneralCountersRequest, ICounterCounterControllerGetGeneralCountersSuccess, ICounterCounterControllerGetGeneralCountersError
>('COUNTER_COUNTERCONTROLLERGETGENERALCOUNTERS');

export interface ICounterCounterControllerGetUserCountersRequest { options?: any }
export type ICounterCounterControllerGetUserCountersSuccess = UserCounterDTO[];
export type ICounterCounterControllerGetUserCountersError = any;
export const counterCounterControllerGetUserCountersAction = chubAsyncActionCreator.async<
  ICounterCounterControllerGetUserCountersRequest, ICounterCounterControllerGetUserCountersSuccess, ICounterCounterControllerGetUserCountersError
>('COUNTER_COUNTERCONTROLLERGETUSERCOUNTERS');

export interface IDocumentDocumentControllerCreateRequest { body: CreateDocumentDTO, options?: any }
export type IDocumentDocumentControllerCreateSuccess = MessageDTO;
export type IDocumentDocumentControllerCreateError = any;
export const documentDocumentControllerCreateAction = chubAsyncActionCreator.async<
  IDocumentDocumentControllerCreateRequest, IDocumentDocumentControllerCreateSuccess, IDocumentDocumentControllerCreateError
>('DOCUMENT_DOCUMENTCONTROLLERCREATE');

export interface IDocumentDocumentControllerGetDocumentsRequest { channelId: string, options?: any }
export type IDocumentDocumentControllerGetDocumentsSuccess = MessageDTO[];
export type IDocumentDocumentControllerGetDocumentsError = any;
export const documentDocumentControllerGetDocumentsAction = chubAsyncActionCreator.async<
  IDocumentDocumentControllerGetDocumentsRequest, IDocumentDocumentControllerGetDocumentsSuccess, IDocumentDocumentControllerGetDocumentsError
>('DOCUMENT_DOCUMENTCONTROLLERGETDOCUMENTS');

export interface IDocumentDocumentControllerSearchDocumentRequest { body: SearchDocumentDTO, options?: any }
export type IDocumentDocumentControllerSearchDocumentSuccess = MessageDTO;
export type IDocumentDocumentControllerSearchDocumentError = any;
export const documentDocumentControllerSearchDocumentAction = chubAsyncActionCreator.async<
  IDocumentDocumentControllerSearchDocumentRequest, IDocumentDocumentControllerSearchDocumentSuccess, IDocumentDocumentControllerSearchDocumentError
>('DOCUMENT_DOCUMENTCONTROLLERSEARCHDOCUMENT');

export interface IInviteInviteControllerInviteRequest { body: DriverInviteDTO, options?: any }
export type IInviteInviteControllerInviteSuccess = InviteDTO;
export type IInviteInviteControllerInviteError = any;
export const inviteInviteControllerInviteAction = chubAsyncActionCreator.async<
  IInviteInviteControllerInviteRequest, IInviteInviteControllerInviteSuccess, IInviteInviteControllerInviteError
>('INVITE_INVITECONTROLLERINVITE');

export interface IInviteInviteControllerUploadCodesRequest { body: Array<string>, options?: any }
export type IInviteInviteControllerUploadCodesSuccess = number;
export type IInviteInviteControllerUploadCodesError = any;
export const inviteInviteControllerUploadCodesAction = chubAsyncActionCreator.async<
  IInviteInviteControllerUploadCodesRequest, IInviteInviteControllerUploadCodesSuccess, IInviteInviteControllerUploadCodesError
>('INVITE_INVITECONTROLLERUPLOADCODES');

export interface IMessageMessageControllerCreateRequest { body: CreateMessageDTO, options?: any }
export type IMessageMessageControllerCreateSuccess = MessageDTO;
export type IMessageMessageControllerCreateError = any;
export const messageMessageControllerCreateAction = chubAsyncActionCreator.async<
  IMessageMessageControllerCreateRequest, IMessageMessageControllerCreateSuccess, IMessageMessageControllerCreateError
>('MESSAGE_MESSAGECONTROLLERCREATE');

export interface IMessageMessageControllerGetAllUnreadMessagesRequest { options?: any }
export type IMessageMessageControllerGetAllUnreadMessagesSuccess = MessageDTO[];
export type IMessageMessageControllerGetAllUnreadMessagesError = any;
export const messageMessageControllerGetAllUnreadMessagesAction = chubAsyncActionCreator.async<
  IMessageMessageControllerGetAllUnreadMessagesRequest, IMessageMessageControllerGetAllUnreadMessagesSuccess, IMessageMessageControllerGetAllUnreadMessagesError
>('MESSAGE_MESSAGECONTROLLERGETALLUNREADMESSAGES');

export interface IMessageMessageControllerGetMessagesRequest { channelId: string, limit: number, page: number, options?: any }
export type IMessageMessageControllerGetMessagesSuccess = MessageDTO[];
export type IMessageMessageControllerGetMessagesError = any;
export const messageMessageControllerGetMessagesAction = chubAsyncActionCreator.async<
  IMessageMessageControllerGetMessagesRequest, IMessageMessageControllerGetMessagesSuccess, IMessageMessageControllerGetMessagesError
>('MESSAGE_MESSAGECONTROLLERGETMESSAGES');

export interface IMessageMessageControllerGetUnhandledDriverSmsRequest { limit: number, page: number, options?: any }
export type IMessageMessageControllerGetUnhandledDriverSmsSuccess = MessageDTO[];
export type IMessageMessageControllerGetUnhandledDriverSmsError = any;
export const messageMessageControllerGetUnhandledDriverSmsAction = chubAsyncActionCreator.async<
  IMessageMessageControllerGetUnhandledDriverSmsRequest, IMessageMessageControllerGetUnhandledDriverSmsSuccess, IMessageMessageControllerGetUnhandledDriverSmsError
>('MESSAGE_MESSAGECONTROLLERGETUNHANDLEDDRIVERSMS');

export interface IMessageMessageControllerMarkAllReadRequest { options?: any }
export type IMessageMessageControllerMarkAllReadSuccess = UserCounterDTO[];
export type IMessageMessageControllerMarkAllReadError = any;
export const messageMessageControllerMarkAllReadAction = chubAsyncActionCreator.async<
  IMessageMessageControllerMarkAllReadRequest, IMessageMessageControllerMarkAllReadSuccess, IMessageMessageControllerMarkAllReadError
>('MESSAGE_MESSAGECONTROLLERMARKALLREAD');

export interface IMessageMessageControllerPinRequest { id: string, options?: any }
export type IMessageMessageControllerPinSuccess = MessageDTO;
export type IMessageMessageControllerPinError = any;
export const messageMessageControllerPinAction = chubAsyncActionCreator.async<
  IMessageMessageControllerPinRequest, IMessageMessageControllerPinSuccess, IMessageMessageControllerPinError
>('MESSAGE_MESSAGECONTROLLERPIN');

export interface IMessageMessageControllerReadRequest { id: string, options?: any }
export type IMessageMessageControllerReadSuccess = MessageDTO;
export type IMessageMessageControllerReadError = any;
export const messageMessageControllerReadAction = chubAsyncActionCreator.async<
  IMessageMessageControllerReadRequest, IMessageMessageControllerReadSuccess, IMessageMessageControllerReadError
>('MESSAGE_MESSAGECONTROLLERREAD');

export interface IMessageMessageControllerRemoveRequest { id: string, options?: any }
export type IMessageMessageControllerRemoveSuccess = MessageDTO;
export type IMessageMessageControllerRemoveError = any;
export const messageMessageControllerRemoveAction = chubAsyncActionCreator.async<
  IMessageMessageControllerRemoveRequest, IMessageMessageControllerRemoveSuccess, IMessageMessageControllerRemoveError
>('MESSAGE_MESSAGECONTROLLERREMOVE');

export interface IMessageMessageControllerUnpinRequest { id: string, options?: any }
export type IMessageMessageControllerUnpinSuccess = MessageDTO;
export type IMessageMessageControllerUnpinError = any;
export const messageMessageControllerUnpinAction = chubAsyncActionCreator.async<
  IMessageMessageControllerUnpinRequest, IMessageMessageControllerUnpinSuccess, IMessageMessageControllerUnpinError
>('MESSAGE_MESSAGECONTROLLERUNPIN');

export interface IMessageMessageControllerUnreadRequest { id: string, options?: any }
export type IMessageMessageControllerUnreadSuccess = MessageDTO;
export type IMessageMessageControllerUnreadError = any;
export const messageMessageControllerUnreadAction = chubAsyncActionCreator.async<
  IMessageMessageControllerUnreadRequest, IMessageMessageControllerUnreadSuccess, IMessageMessageControllerUnreadError
>('MESSAGE_MESSAGECONTROLLERUNREAD');

export interface IMessageMessageControllerUpdateRequest { id: string, body: UpdateMessageDTO, options?: any }
export type IMessageMessageControllerUpdateSuccess = MessageDTO;
export type IMessageMessageControllerUpdateError = any;
export const messageMessageControllerUpdateAction = chubAsyncActionCreator.async<
  IMessageMessageControllerUpdateRequest, IMessageMessageControllerUpdateSuccess, IMessageMessageControllerUpdateError
>('MESSAGE_MESSAGECONTROLLERUPDATE');

export interface INotificationNotificationControllerAddReplyRequest { id: string, body: AnswersDTO, options?: any }
export type INotificationNotificationControllerAddReplySuccess = NotificationReplyDTO;
export type INotificationNotificationControllerAddReplyError = any;
export const notificationNotificationControllerAddReplyAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerAddReplyRequest, INotificationNotificationControllerAddReplySuccess, INotificationNotificationControllerAddReplyError
>('NOTIFICATION_NOTIFICATIONCONTROLLERADDREPLY');

export interface INotificationNotificationControllerBatchRepliesRequest { body: BatchCreateRepliesDTO, options?: any }
export type INotificationNotificationControllerBatchRepliesSuccess = NotificationReplyDTO[];
export type INotificationNotificationControllerBatchRepliesError = any;
export const notificationNotificationControllerBatchRepliesAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerBatchRepliesRequest, INotificationNotificationControllerBatchRepliesSuccess, INotificationNotificationControllerBatchRepliesError
>('NOTIFICATION_NOTIFICATIONCONTROLLERBATCHREPLIES');

export interface INotificationNotificationControllerCompleteRequest { id: string, options?: any }
export type INotificationNotificationControllerCompleteSuccess = NotificationDTO;
export type INotificationNotificationControllerCompleteError = any;
export const notificationNotificationControllerCompleteAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerCompleteRequest, INotificationNotificationControllerCompleteSuccess, INotificationNotificationControllerCompleteError
>('NOTIFICATION_NOTIFICATIONCONTROLLERCOMPLETE');

export interface INotificationNotificationControllerCreateRequest { body: CreateNotificationDTO, options?: any }
export type INotificationNotificationControllerCreateSuccess = NotificationDTO;
export type INotificationNotificationControllerCreateError = any;
export const notificationNotificationControllerCreateAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerCreateRequest, INotificationNotificationControllerCreateSuccess, INotificationNotificationControllerCreateError
>('NOTIFICATION_NOTIFICATIONCONTROLLERCREATE');

export interface INotificationNotificationControllerDeleteRequest { id: string, options?: any }
export type INotificationNotificationControllerDeleteSuccess = NotificationDTO;
export type INotificationNotificationControllerDeleteError = any;
export const notificationNotificationControllerDeleteAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerDeleteRequest, INotificationNotificationControllerDeleteSuccess, INotificationNotificationControllerDeleteError
>('NOTIFICATION_NOTIFICATIONCONTROLLERDELETE');

export interface INotificationNotificationControllerFindAllRequest { body: GetNotificationListDTO, options?: any }
export type INotificationNotificationControllerFindAllSuccess = NotificationDTO[];
export type INotificationNotificationControllerFindAllError = any;
export const notificationNotificationControllerFindAllAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerFindAllRequest, INotificationNotificationControllerFindAllSuccess, INotificationNotificationControllerFindAllError
>('NOTIFICATION_NOTIFICATIONCONTROLLERFINDALL');

export interface INotificationNotificationControllerFindAllForDriverRequest { limit: number, page: number, options?: any }
export type INotificationNotificationControllerFindAllForDriverSuccess = DriverNotificationDTO[];
export type INotificationNotificationControllerFindAllForDriverError = any;
export const notificationNotificationControllerFindAllForDriverAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerFindAllForDriverRequest, INotificationNotificationControllerFindAllForDriverSuccess, INotificationNotificationControllerFindAllForDriverError
>('NOTIFICATION_NOTIFICATIONCONTROLLERFINDALLFORDRIVER');

export interface INotificationNotificationControllerFindByIdRequest { id: string, options?: any }
export type INotificationNotificationControllerFindByIdSuccess = DriverNotificationDTO;
export type INotificationNotificationControllerFindByIdError = any;
export const notificationNotificationControllerFindByIdAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerFindByIdRequest, INotificationNotificationControllerFindByIdSuccess, INotificationNotificationControllerFindByIdError
>('NOTIFICATION_NOTIFICATIONCONTROLLERFINDBYID');

export interface INotificationNotificationControllerGetLatestRequiredNotificationRequest { options?: any }
export type INotificationNotificationControllerGetLatestRequiredNotificationSuccess = DriverNotificationDTO;
export type INotificationNotificationControllerGetLatestRequiredNotificationError = any;
export const notificationNotificationControllerGetLatestRequiredNotificationAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerGetLatestRequiredNotificationRequest, INotificationNotificationControllerGetLatestRequiredNotificationSuccess, INotificationNotificationControllerGetLatestRequiredNotificationError
>('NOTIFICATION_NOTIFICATIONCONTROLLERGETLATESTREQUIREDNOTIFICATION');

export interface INotificationNotificationControllerGetRepliesRequest { id: string, options?: any }
export type INotificationNotificationControllerGetRepliesSuccess = Response;
export type INotificationNotificationControllerGetRepliesError = any;
export const notificationNotificationControllerGetRepliesAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerGetRepliesRequest, INotificationNotificationControllerGetRepliesSuccess, INotificationNotificationControllerGetRepliesError
>('NOTIFICATION_NOTIFICATIONCONTROLLERGETREPLIES');

export interface INotificationNotificationControllerPushNotificationRequest { recipientId: string, options?: any }
export type INotificationNotificationControllerPushNotificationSuccess = Response;
export type INotificationNotificationControllerPushNotificationError = any;
export const notificationNotificationControllerPushNotificationAction = chubAsyncActionCreator.async<
  INotificationNotificationControllerPushNotificationRequest, INotificationNotificationControllerPushNotificationSuccess, INotificationNotificationControllerPushNotificationError
>('NOTIFICATION_NOTIFICATIONCONTROLLERPUSHNOTIFICATION');

export interface IPhoneNumberPhoneNumberControllerDetachPhoneNumberRequest { channelId: string, options?: any }
export type IPhoneNumberPhoneNumberControllerDetachPhoneNumberSuccess = PhoneNumberDTO;
export type IPhoneNumberPhoneNumberControllerDetachPhoneNumberError = any;
export const phoneNumberPhoneNumberControllerDetachPhoneNumberAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerDetachPhoneNumberRequest, IPhoneNumberPhoneNumberControllerDetachPhoneNumberSuccess, IPhoneNumberPhoneNumberControllerDetachPhoneNumberError
>('PHONENUMBER_PHONENUMBERCONTROLLERDETACHPHONENUMBER');

export interface IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberRequest { channelId: string, options?: any }
export type IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberSuccess = PhoneNumberInstance;
export type IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberError = any;
export const phoneNumberPhoneNumberControllerForceBuyAndAttachNumberAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberRequest, IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberSuccess, IPhoneNumberPhoneNumberControllerForceBuyAndAttachNumberError
>('PHONENUMBER_PHONENUMBERCONTROLLERFORCEBUYANDATTACHNUMBER');

export interface IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdRequest { channelId: string, options?: any }
export type IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdSuccess = PhoneNumberDTO;
export type IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdError = any;
export const phoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdRequest, IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdSuccess, IPhoneNumberPhoneNumberControllerGetPhoneNumberByChannelIdError
>('PHONENUMBER_PHONENUMBERCONTROLLERGETPHONENUMBERBYCHANNELID');

export interface IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsRequest { options?: any }
export type IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsSuccess = PhoneNumberStatDTO;
export type IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsError = any;
export const phoneNumberPhoneNumberControllerGetPhoneNumberStatisticsAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsRequest, IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsSuccess, IPhoneNumberPhoneNumberControllerGetPhoneNumberStatisticsError
>('PHONENUMBER_PHONENUMBERCONTROLLERGETPHONENUMBERSTATISTICS');

export interface IPhoneNumberPhoneNumberControllerGetStatsPageRequest { options?: any }
export type IPhoneNumberPhoneNumberControllerGetStatsPageSuccess = string;
export type IPhoneNumberPhoneNumberControllerGetStatsPageError = any;
export const phoneNumberPhoneNumberControllerGetStatsPageAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerGetStatsPageRequest, IPhoneNumberPhoneNumberControllerGetStatsPageSuccess, IPhoneNumberPhoneNumberControllerGetStatsPageError
>('PHONENUMBER_PHONENUMBERCONTROLLERGETSTATSPAGE');

export interface IPhoneNumberPhoneNumberControllerRemovePhoneNumbersRequest { options?: any }
export type IPhoneNumberPhoneNumberControllerRemovePhoneNumbersSuccess = RemovePhoneNumbersDTO;
export type IPhoneNumberPhoneNumberControllerRemovePhoneNumbersError = any;
export const phoneNumberPhoneNumberControllerRemovePhoneNumbersAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerRemovePhoneNumbersRequest, IPhoneNumberPhoneNumberControllerRemovePhoneNumbersSuccess, IPhoneNumberPhoneNumberControllerRemovePhoneNumbersError
>('PHONENUMBER_PHONENUMBERCONTROLLERREMOVEPHONENUMBERS');

export interface IPhoneNumberPhoneNumberControllerSetMessagingServiceSidRequest { phoneSid: string, options?: any }
export type IPhoneNumberPhoneNumberControllerSetMessagingServiceSidSuccess = PhoneNumberInstance;
export type IPhoneNumberPhoneNumberControllerSetMessagingServiceSidError = any;
export const phoneNumberPhoneNumberControllerSetMessagingServiceSidAction = chubAsyncActionCreator.async<
  IPhoneNumberPhoneNumberControllerSetMessagingServiceSidRequest, IPhoneNumberPhoneNumberControllerSetMessagingServiceSidSuccess, IPhoneNumberPhoneNumberControllerSetMessagingServiceSidError
>('PHONENUMBER_PHONENUMBERCONTROLLERSETMESSAGINGSERVICESID');

export interface ISmsSmsControllerGetListRequest { firstNumber: string, secondNumber: string, options?: any }
export type ISmsSmsControllerGetListSuccess = SmsTestingDTO[];
export type ISmsSmsControllerGetListError = any;
export const smsSmsControllerGetListAction = chubAsyncActionCreator.async<
  ISmsSmsControllerGetListRequest, ISmsSmsControllerGetListSuccess, ISmsSmsControllerGetListError
>('SMS_SMSCONTROLLERGETLIST');

export interface ISmsSmsControllerGetTestingPageRequest { options?: any }
export type ISmsSmsControllerGetTestingPageSuccess = string;
export type ISmsSmsControllerGetTestingPageError = any;
export const smsSmsControllerGetTestingPageAction = chubAsyncActionCreator.async<
  ISmsSmsControllerGetTestingPageRequest, ISmsSmsControllerGetTestingPageSuccess, ISmsSmsControllerGetTestingPageError
>('SMS_SMSCONTROLLERGETTESTINGPAGE');

export interface ISmsSmsControllerSendMessageRequest { body: CreateMessageDTO, options?: any }
export type ISmsSmsControllerSendMessageSuccess = CreateMessageDTO;
export type ISmsSmsControllerSendMessageError = any;
export const smsSmsControllerSendMessageAction = chubAsyncActionCreator.async<
  ISmsSmsControllerSendMessageRequest, ISmsSmsControllerSendMessageSuccess, ISmsSmsControllerSendMessageError
>('SMS_SMSCONTROLLERSENDMESSAGE');

export interface ISmsSmsControllerSendPlainSmsRequest { body: SendSmsDTO, options?: any }
export type ISmsSmsControllerSendPlainSmsSuccess = Response;
export type ISmsSmsControllerSendPlainSmsError = any;
export const smsSmsControllerSendPlainSmsAction = chubAsyncActionCreator.async<
  ISmsSmsControllerSendPlainSmsRequest, ISmsSmsControllerSendPlainSmsSuccess, ISmsSmsControllerSendPlainSmsError
>('SMS_SMSCONTROLLERSENDPLAINSMS');

export interface ISmsSmsControllerSendTestMessageRequest { body: SmsTestingSendDTO, options?: any }
export type ISmsSmsControllerSendTestMessageSuccess = SmsTestingDTO;
export type ISmsSmsControllerSendTestMessageError = any;
export const smsSmsControllerSendTestMessageAction = chubAsyncActionCreator.async<
  ISmsSmsControllerSendTestMessageRequest, ISmsSmsControllerSendTestMessageSuccess, ISmsSmsControllerSendTestMessageError
>('SMS_SMSCONTROLLERSENDTESTMESSAGE');

export interface ISmsSmsControllerSendTestMmsRequest { body: SendMmsDTO, options?: any }
export type ISmsSmsControllerSendTestMmsSuccess = Response;
export type ISmsSmsControllerSendTestMmsError = any;
export const smsSmsControllerSendTestMmsAction = chubAsyncActionCreator.async<
  ISmsSmsControllerSendTestMmsRequest, ISmsSmsControllerSendTestMmsSuccess, ISmsSmsControllerSendTestMmsError
>('SMS_SMSCONTROLLERSENDTESTMMS');

export interface IWebhookWebHookControllerHandleMailgunWebhookRequest { recipient: string, sender: string, strippedText: string, timestamp: string, token: string, signature: string, options?: any }
export type IWebhookWebHookControllerHandleMailgunWebhookSuccess = string;
export type IWebhookWebHookControllerHandleMailgunWebhookError = any;
export const webhookWebHookControllerHandleMailgunWebhookAction = chubAsyncActionCreator.async<
  IWebhookWebHookControllerHandleMailgunWebhookRequest, IWebhookWebHookControllerHandleMailgunWebhookSuccess, IWebhookWebHookControllerHandleMailgunWebhookError
>('WEBHOOK_WEBHOOKCONTROLLERHANDLEMAILGUNWEBHOOK');

export interface IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookRequest { messageId: string, body: WebHookTwilioStatusDTO, options?: any }
export type IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookSuccess = string;
export type IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookError = any;
export const webhookWebHookControllerHandlePlainSmsTwilioStatusWebhookAction = chubAsyncActionCreator.async<
  IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookRequest, IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookSuccess, IWebhookWebHookControllerHandlePlainSmsTwilioStatusWebhookError
>('WEBHOOK_WEBHOOKCONTROLLERHANDLEPLAINSMSTWILIOSTATUSWEBHOOK');

export interface IWebhookWebHookControllerHandleTwilioFallbackWebhookRequest { channelId: string, options?: any }
export type IWebhookWebHookControllerHandleTwilioFallbackWebhookSuccess = string;
export type IWebhookWebHookControllerHandleTwilioFallbackWebhookError = any;
export const webhookWebHookControllerHandleTwilioFallbackWebhookAction = chubAsyncActionCreator.async<
  IWebhookWebHookControllerHandleTwilioFallbackWebhookRequest, IWebhookWebHookControllerHandleTwilioFallbackWebhookSuccess, IWebhookWebHookControllerHandleTwilioFallbackWebhookError
>('WEBHOOK_WEBHOOKCONTROLLERHANDLETWILIOFALLBACKWEBHOOK');

export interface IWebhookWebHookControllerHandleTwilioStatusWebhookRequest { messageId: string, body: WebHookTwilioStatusDTO, options?: any }
export type IWebhookWebHookControllerHandleTwilioStatusWebhookSuccess = string;
export type IWebhookWebHookControllerHandleTwilioStatusWebhookError = any;
export const webhookWebHookControllerHandleTwilioStatusWebhookAction = chubAsyncActionCreator.async<
  IWebhookWebHookControllerHandleTwilioStatusWebhookRequest, IWebhookWebHookControllerHandleTwilioStatusWebhookSuccess, IWebhookWebHookControllerHandleTwilioStatusWebhookError
>('WEBHOOK_WEBHOOKCONTROLLERHANDLETWILIOSTATUSWEBHOOK');

export interface IWebhookWebHookControllerHandleTwilioSuccessWebhookRequest { channelId: string, body: WebHookTwilioSuccessDTO, options?: any }
export type IWebhookWebHookControllerHandleTwilioSuccessWebhookSuccess = string;
export type IWebhookWebHookControllerHandleTwilioSuccessWebhookError = any;
export const webhookWebHookControllerHandleTwilioSuccessWebhookAction = chubAsyncActionCreator.async<
  IWebhookWebHookControllerHandleTwilioSuccessWebhookRequest, IWebhookWebHookControllerHandleTwilioSuccessWebhookSuccess, IWebhookWebHookControllerHandleTwilioSuccessWebhookError
>('WEBHOOK_WEBHOOKCONTROLLERHANDLETWILIOSUCCESSWEBHOOK');

export interface IWorkOrderWorkOrderControllerCreateRequest { body: WorkOrderDTO, options?: any }
export type IWorkOrderWorkOrderControllerCreateSuccess = MessageDTO[];
export type IWorkOrderWorkOrderControllerCreateError = any;
export const workOrderWorkOrderControllerCreateAction = chubAsyncActionCreator.async<
  IWorkOrderWorkOrderControllerCreateRequest, IWorkOrderWorkOrderControllerCreateSuccess, IWorkOrderWorkOrderControllerCreateError
>('WORKORDER_WORKORDERCONTROLLERCREATE');

export interface IWorkOrderWorkOrderControllerGenerateWorkOrderPageRequest { dispatchDeliveryOrderId: string, driverId: string, hash: string, options?: any }
export type IWorkOrderWorkOrderControllerGenerateWorkOrderPageSuccess = string;
export type IWorkOrderWorkOrderControllerGenerateWorkOrderPageError = any;
export const workOrderWorkOrderControllerGenerateWorkOrderPageAction = chubAsyncActionCreator.async<
  IWorkOrderWorkOrderControllerGenerateWorkOrderPageRequest, IWorkOrderWorkOrderControllerGenerateWorkOrderPageSuccess, IWorkOrderWorkOrderControllerGenerateWorkOrderPageError
>('WORKORDER_WORKORDERCONTROLLERGENERATEWORKORDERPAGE');


