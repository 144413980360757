import { oc } from 'ts-optchain'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { quoteDirectory } from '../../DTO/sellSideQuote/directory'
import { AnyRateDTO } from '../../../components/common/QMP/interfaces'
import { transformMoney } from '../transform/transformMoney'

export const generateExpireRateText = (rate: AnyRateDTO): string => {
  if (!rate) {
    return ''
  }

  try {
    const _rate = rate as any
    const storeLists = getListsState()
    const customer = oc(storeLists[EntityType.customer][_rate.customerId]).name()
    const driver = oc(storeLists[EntityType.driver][_rate.vendorId]).name()
    const rateNumber = `Rate ID: ${rate.number}`
    const rateType = `Type: ${quoteDirectory.typeOfRate[rate.type]}`
    const rateTariff = `${customer ? 'Customer: ' : driver ? 'Driver: ' : ''}${customer || driver || 'TFF'}`
    const rateAmount = `Amount: ${transformMoney(rate.amount)}`

    return [rateNumber, rateType, rateTariff, rateAmount].join('\n')
  } catch (e) {
    return ''
  }
}
