import { SubClientViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateSubClient = (props?: Partial<SubClientViewDTO>): SubClientViewDTO => {
  return {
    completeness: maxCompleteness,
    id: createId(),
    businessPartnerId: undefined,
    customerId: undefined,
    ...(props || {})
  }
}
