import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Notification } from './Notification/Notification'
import { GridItemSpinner } from '../../../UI/Spinner/Spinner'
import { useNotificationList } from '../../../../hooks/useNotificationList'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NotificationsViewBody = (props: Props) => {
  const {
    _temporaryProps,
    initialFetch,
    notificationIdList,
    isFullContentLoaded,
    sortedNotificationsByDate,
    requestPrevPageNotifications
  } = useNotificationList({})

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [sortedNotificationsByDate, initialFetch])

  return (
    <Container id={'scrollable-notification-list'}>
      {initialFetch ? (
        <GridItemSpinner />
      ) : notificationIdList.length ? (
        <InfiniteScroll
          scrollableTarget={'scrollable-notification-list'}
          style={{ height: '100%', padding: '14px 8px 30px 8px', display: 'flex', flexDirection: 'column-reverse' }}
          dataLength={notificationIdList.length + sortedNotificationsByDate.length}
          next={requestPrevPageNotifications}
          hasMore={!isFullContentLoaded}
          initialScrollY={_temporaryProps.initialScrollY}
          onScroll={e => {
            // @ts-ignore
            _temporaryProps.initialScrollY = e.target.scrollTop
          }}
          loader={<PrevPageLoading children={<GridItemSpinner />} />}
        >
          {sortedNotificationsByDate.map(({ date, items }) => {
            return (
              <React.Fragment key={date}>
                {items.map(notification => (
                  <Notification key={notification.id} id={notification.id} styles={{ marginTop: 16 }} />
                ))}
                <DateTitle key={date + 1}>{date}</DateTitle>
              </React.Fragment>
            )
          })}
        </InfiniteScroll>
      ) : (
        <Placeholder>No notifications found</Placeholder>
      )}
    </Container>
  )
}

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
`

const PrevPageLoading = styled.div`
  height: 100px;
  position: relative;
`

export const DateTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  cursor: default;
  user-select: none;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    display: block;
    height: 1px;
    background-color: #333333;
    margin: 0 15px;
  }
`

export const Placeholder = styled.div`
  color: #c4c6cb;
  font-size: 14px;
  margin: auto;
  user-select: none;
`
