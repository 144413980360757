import { dateService } from '../../timeService'

type Props = {
  items: any[]
  getItemDateFn: (item: any) => string
  sort?: 'ASC' | 'DESC'
}

type Items = any[]

type SortedGroup = {
  date: string
  items: Items
}

export const groupItemsByDate = (props: Props): SortedGroup[] => {
  const { items, sort, getItemDateFn } = props
  const itemsGroupMapping: Record<string, Items> = {}
  const result: SortedGroup[] = []

  const compareNumbersFn =
    sort === 'DESC'
      ? (num1: number, num2: number) => {
          return num2 - num1
        }
      : (num1: number, num2: number) => {
          return num1 - num2
        }

  try {
    items.forEach(item => {
      const itemDate = getItemDateFn(item)
      const dateDay = dateService.getStringDay(itemDate)

      if (!itemsGroupMapping[dateDay]) {
        itemsGroupMapping[dateDay] = []
      }

      itemsGroupMapping[dateDay].push(item)
    })

    const groupedDays = Object.keys(itemsGroupMapping)

    groupedDays.sort((dayA, dayB) => compareNumbersFn(Date.parse(dayA), Date.parse(dayB)))
    groupedDays.forEach(key => {
      result.push({
        date: key,
        items: itemsGroupMapping[key].sort((a, b) => {
          const dateA = getItemDateFn(a)
          const dateB = getItemDateFn(b)

          return compareNumbersFn(Date.parse(dateA), Date.parse(dateB))
        })
      })
    })

    return result
  } catch (e) {}

  return []
}
