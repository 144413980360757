import * as React from 'react'

export const OpenInNewTabIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // tslint:disable-next-line:max-line-length
      d="M6.80039 0.400024C6.35856 0.400024 6.00039 0.758197 6.00039 1.20002C6.00039 1.64185 6.35856 2.00002 6.80039 2.00002H8.86902L3.83471 7.03434C3.52229 7.34676 3.52229 7.85329 3.83471 8.16571C4.14712 8.47813 4.65366 8.47813 4.96608 8.16571L10.0004 3.1314V5.20002C10.0004 5.64185 10.3586 6.00002 10.8004 6.00002C11.2422 6.00002 11.6004 5.64185 11.6004 5.20002V1.20002C11.6004 0.758197 11.2422 0.400024 10.8004 0.400024H6.80039Z"
      fill="#4555D0"
    />
    <path
      // tslint:disable-next-line:max-line-length
      d="M2.00039 2.00002C1.11674 2.00002 0.400391 2.71637 0.400391 3.60002V10C0.400391 10.8837 1.11673 11.6 2.00039 11.6H8.40039C9.28405 11.6 10.0004 10.8837 10.0004 10V7.60002C10.0004 7.1582 9.64222 6.80002 9.20039 6.80002C8.75856 6.80002 8.40039 7.1582 8.40039 7.60002V10H2.00039V3.60002L4.40039 3.60002C4.84222 3.60002 5.20039 3.24185 5.20039 2.80002C5.20039 2.3582 4.84222 2.00002 4.40039 2.00002H2.00039Z"
      fill="#4555D0"
    />
  </svg>
)
