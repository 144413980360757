import { RootState } from '..'
import { ContainerTypeDTO } from '../../api/api'

// SELECT ONE
export const selectContainerType = (id: string) => (state: RootState): ContainerTypeDTO | undefined => {
  const {
    lists: { containerType }
  } = state

  return containerType[id]
}

// SELECT MANY
export const selectContainerTypes = (state: RootState): Record<string, ContainerTypeDTO> => {
  return state.lists.containerType
}
