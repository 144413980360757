import * as React from 'react'
import styled from 'styled-components'
import { NavigationChannel } from './NavigationChannel'
import { useCommunicationHubDraftMessage } from '../../../../hooks/useCommunicationHubDraftMessage'
import { NavigationChannelsSectionHeader } from './NavigationChannelsSectionHeader'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { ChannelType } from '../../../../store/reducers/communicationHub/interfaces'
import { selectCommunictaionHubChannelIds } from '../../../../store/select/communicationHubSelect'

type OwnProps = {
  channelType: ChannelType
  collapsible?: boolean
  title?: string
  maxHeight?: number | string
  channelIds?: string[]
  innerSearch?: true
  innerSearchPlaceholder?: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

// CHANNEL BLOCK
export const NavigationChannelsSection = (props: Props) => {
  const { title, channelType, maxHeight, collapsible, innerSearch, innerSearchPlaceholder } = props
  const channelIds = props.channelIds || useAppSelector(selectCommunictaionHubChannelIds(channelType))
  const [open, setOpen] = React.useState(true)
  const [search, setSearch] = React.useState('')
  const { draftMessages } = useCommunicationHubDraftMessage()

  return (
    <>
      <NavigationChannelsSectionHeader
        channelType={channelType}
        search={search}
        setSearch={setSearch}
        setOpen={setOpen}
        listIsNotEmpty={Boolean(channelIds.length)}
        title={title}
        innerSearch={innerSearch}
        innerSearchPlaceholder={innerSearchPlaceholder}
        collapsible={collapsible}
      />
      {open && (
        <NavigationChannelList
          style={
            maxHeight ? { maxHeight, flexShrink: 0, minHeight: channelIds.length > 0 ? 38 : undefined } : undefined
          }
        >
          {channelIds.map(channelId => (
            <NavigationChannel
              key={channelId}
              channelId={channelId}
              hasDraftMessage={Boolean(draftMessages[channelId])}
              isRecentOpenedChannel={channelType === ChannelType.recentlyOpened}
              search={search}
            />
          ))}
        </NavigationChannelList>
      )}
    </>
  )
}

const NavigationChannelList = styled.div`
  overflow: auto;
`
