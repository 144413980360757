import { oc } from 'ts-optchain'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { TransportationActivityGroup, DocumentationActivityGroup, ActivityGroup } from '../../DTO/activity/interfaces'

export const isUnsuccessfulActivity = (activity: TransportationActivityViewDTO): boolean => {
  return isUnsuccessfulActivityStatus(oc(activity).status())
}

export const isUnsuccessfulActivityStatus = (status: TransportationActivityViewDTO.StatusEnum): boolean => {
  return status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
}

export const isUnsuccessfulActivityGroup = (activityGroup: ActivityGroup): boolean => {
  return (
    isUnsuccessfulActivity(oc(activityGroup as TransportationActivityGroup).gotoActivity()) ||
    isUnsuccessfulActivity(oc(activityGroup as TransportationActivityGroup).businessActivity()) ||
    oc(activityGroup as DocumentationActivityGroup).documentationActivity.status() ===
      DocumentationActivityDTO.StatusEnum.REJECTED
  )
}
