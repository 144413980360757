import * as React from 'react'
import styled from 'styled-components'
import { uniq } from 'remeda'
import cn from 'classnames'
import { CreateNotificationDTO } from '../../../../../api/origin/communication-hub-service'
import { driverNotificationStatuses } from '../../../../../constants/driverStatuses'
import { FieldContainer } from '../../../../UI/DataFields/FieldContainer'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'
import { WindowPopover } from '../../../../UI/windowPopover'
import { DropdownItem, DropdownItemValue, Selected, StyledDropdown } from '../../../../UI/DataFields/Select/styles'
import { Checkbox } from '../../../../UI/Radio'
import { preventEvent } from '../../../../../services/functions/basic'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'

type Props = {
  title: string
  required: boolean
  containerStyle: React.CSSProperties
  selected: CreateNotificationDTO.DriverStatusesEnum[]
  onChange: (items: CreateNotificationDTO.DriverStatusesEnum[]) => void
  placeholder?: string
}

const totalListItemsNumber = Object.values(driverNotificationStatuses).reduce((acc, curr) => acc + curr.length, 0)

export const SelectDriverStatatus = (props: Props) => {
  const { title, required, placeholder, containerStyle, onChange } = props
  const { wrapperRef, statePopover, togglePopover } = useOutsideClick({ capture: false })
  const selected = props.selected || []
  const list = React.useMemo(() => {
    return [
      { label: 'Active Drivers', key: 'Active' },
      { label: 'Inactive Drivers', key: 'Inactive' },
      { label: 'Candidates', key: 'Candidate' }
    ]
  }, [])
  const isSelectedAll = selected.length === totalListItemsNumber

  const ListItem = React.useCallback(
    ({
      label,
      isSelected,
      isSecondary,
      onClick
    }: {
      label: string
      isSelected: boolean
      isSecondary?: boolean
      onClick: () => void
    }) => {
      return (
        <DropdownItem style={isSecondary ? { paddingLeft: 14 } : { marginTop: 5 }}>
          <DropdownItemValue
            style={{ fontWeight: isSecondary ? 400 : 500 }}
            onClick={e => {
              preventEvent(e)
              onClick()
            }}
          >
            <Checkbox style={{ marginRight: 8, fontSize: 22 }} active={isSelected} />
            {label}
          </DropdownItemValue>
        </DropdownItem>
      )
    },
    []
  )

  return (
    <FieldContainer title={title} required={required} style={containerStyle}>
      <Container ref={wrapperRef}>
        <SelectContainer>
          <Selected
            className={cn('selected__label mdi', {
              'mdi-menu-down': !statePopover,
              'mdi-menu-up': statePopover,
              placeholder: !selected.length
            })}
            onClick={togglePopover}
          >
            <Label>
              {selected.length
                ? isSelectedAll
                  ? 'All'
                  : selected
                      .map(item => driverDirectory.status[item])
                      .filter(Boolean)
                      .join(', ')
                : placeholder || 'Select'}
            </Label>
          </Selected>
        </SelectContainer>
        {statePopover && (
          <WindowPopover bindToRef={wrapperRef} isExternal={true} containerStyles={{ zIndex: 999 }}>
            <StyledDropdown style={{ paddingTop: 0, maxHeight: 'unset' }}>
              <ListItem
                label={'All'}
                isSelected={isSelectedAll}
                onClick={() => {
                  onChange(
                    isSelectedAll
                      ? []
                      : Object.values(driverNotificationStatuses).reduce((acc, curr) => acc.concat(curr), [] as any[])
                  )
                }}
              />
              <Columns>
                {list.map(({ label, key }) => {
                  const items: CreateNotificationDTO.DriverStatusesEnum[] = driverNotificationStatuses[key]

                  if (!items) {
                    return null
                  }
                  const isSelectedSection = items.every(_ => selected.includes(_))

                  return (
                    <div key={key}>
                      <ListItem
                        label={label}
                        isSelected={isSelectedSection}
                        onClick={() => {
                          onChange(
                            isSelectedSection ? selected.filter(_ => !items.includes(_)) : uniq(selected.concat(items))
                          )
                        }}
                      />
                      {items.map(item => {
                        const isSelected = selected.includes(item)

                        return (
                          <ListItem
                            key={item}
                            isSecondary={true}
                            label={driverDirectory.status[item] || 'ERROR'}
                            isSelected={isSelected}
                            onClick={() => {
                              onChange(isSelected ? selected.filter(_ => _ !== item) : selected.concat(item))
                            }}
                          />
                        )
                      })}
                    </div>
                  )
                })}
              </Columns>
            </StyledDropdown>
          </WindowPopover>
        )}
      </Container>
    </FieldContainer>
  )
}

const Container = styled.div`
  position: relative;
  margin: 4px 0;
  user-select: none;
`

const SelectContainer = styled.div`
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 4px;
`
const Columns = styled.div`
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  /* grid-column-gap: 40px; */
`

const Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
