import * as firebase from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore'
import { requestFirebaseToken } from '../../components/CommunicationHub/epics'
import { firebaseConfig } from '../../api/firebase'
import { SecretKey } from './interfaces'

export const requestSecretKey = async (key: SecretKey): Promise<string> => {
  const firebaseToken = await requestFirebaseToken()
    .then(data => {
      return data.firebaseToken
    })
    .catch(() => '')

  if (!firebaseToken) {
    return
  }

  const app = firebase.initializeApp(firebaseConfig)
  const firebaseAuth = getAuth(app)
  await signInWithCustomToken(firebaseAuth, firebaseToken)

  const db = getFirestore(app)
  const secretKeysCollection = collection(db, 'secret_keys')
  const docRef = doc(secretKeysCollection, 'ssn')
  const secretKeySnap = await getDoc(docRef)

  if (secretKeySnap.exists()) {
    const secretKeyData = secretKeySnap.data()

    return secretKeyData ? secretKeyData[key] : undefined
  }
}
