import * as React from 'react'
import { encodeSvg } from '../../../services/functions/encodeSvg'

// tslint:disable:max-line-length
export const ActivityListPhasePlaceholder = () => (
  <svg width="828" height="44" viewBox="0 0 828 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="826" height="43" rx="1.5" fill="#F5F7F7" />
    <path opacity="0.5" d="M265.5 43V1" stroke="#E3E8EA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.5 11C274.5 8.23858 276.739 6 279.5 6L301.5 6C304.261 6 306.5 8.23858 306.5 11V33C306.5 35.7614 304.261 38 301.5 38H279.5C276.739 38 274.5 35.7614 274.5 33V11Z"
      fill="#E4E8EA"
    />
    <path opacity="0.5" d="M505.5 43V1" stroke="#E3E8EA" />
    <rect x="514.5" y="7.97961" width="96.8408" height="10" rx="2" fill="#E4E8EA" />
    <rect x="314.285" y="7.97961" width="96.8408" height="10" rx="2" fill="#E4E8EA" />
    <rect x="8.60596" y="7.97961" width="96.8408" height="10" rx="2" fill="#E4E8EA" />
    <rect x="514.5" y="25.9608" width="157.731" height="10" rx="2" fill="#E4E8EA" />
    <rect x="314.285" y="25.9608" width="157.731" height="10" rx="2" fill="#E4E8EA" />
    <rect x="8.60596" y="25.9608" width="157.731" height="10" rx="2" fill="#E4E8EA" />
  </svg>
)

export const activityListPhasePlaceholderBase64 = encodeSvg(<ActivityListPhasePlaceholder />)
