import { EntityType } from '../store/reducers/lists/interfaces'
import { TabDTO } from '../store/reducers/tabs/interfaces'

export const entityTypeByTabTypeMapping: Partial<Record<TabDTO.Type, EntityType>> = {
  [TabDTO.Type.container]: EntityType.container,
  [TabDTO.Type.customer]: EntityType.customer,
  [TabDTO.Type.deliveryOrder]: EntityType.deliveryOrder,
  [TabDTO.Type.dispatchDeliveryOrder]: EntityType.dispatchDeliveryOrder,
  [TabDTO.Type.driver]: EntityType.driver,
  [TabDTO.Type.equipment]: EntityType.equipment,
  [TabDTO.Type.location]: EntityType.location,
  [TabDTO.Type.locationGroup]: EntityType.locationGroup,
  [TabDTO.Type.powerUnit]: EntityType.powerUnit,
  [TabDTO.Type.steamShipLine]: EntityType.steamShipLine,
  [TabDTO.Type.subClient]: EntityType.subClient,
  [TabDTO.Type.scheduler]: EntityType.dispatchDeliveryOrder
}
