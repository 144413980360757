import * as R from 'remeda'
import { callAPI, containerAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { ContainerViewDTO } from '../../../../api/origin/business-logic'
import { isNewObject } from '../../../../services/DTO'
import { catchListErrors } from '../../../../services/tabs/functions'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

export const requestContainerById = (id: string): Promise<ContainerViewDTO> =>
  callAPI(containerAPI.findById, id)
    .toPromise()
    .then(async container => {
      await pushListItemsToStore({ update: { [EntityType.container]: [container] } })

      return container
    })

export const requestContainers = async ({ sort, filter }: any): Promise<ContainerViewDTO[]> => {
  return callAPI(containerAPI.findAll, filter, sort)
    .toPromise()
    .then(async containers => {
      await pushListItemsToStore({ update: { [EntityType.container]: containers } })

      return containers
    })
    .catch(catchListErrors)
}

const omitContainerProps: (keyof ContainerViewDTO)[] = ['steamShipLine']

export const requestCreateContainer = (container: ContainerViewDTO): Promise<ContainerViewDTO> => {
  return callAPIWithErrorMessage(containerAPI.create, R.omit(
    container,
    omitContainerProps.concat('id')
  ) as ContainerViewDTO).then(async requestedContainer => {
    await pushListItemsToStore({ update: { [EntityType.container]: [requestedContainer] } })

    return requestedContainer
  })
}

export const requestUpdateContainer = (container: ContainerViewDTO): Promise<ContainerViewDTO> => {
  if (isNewObject(container)) {
    return requestCreateContainer(container)
  }

  return callAPIWithErrorMessage(containerAPI.update, container.id, R.omit(
    container,
    omitContainerProps
  ) as ContainerViewDTO).then(async requestedContainer => {
    await pushListItemsToStore({ update: { [EntityType.container]: [requestedContainer] } })

    return requestedContainer
  })
}
