import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'
import { ChannelType, IChannels } from '../interfaces'

export const adjustChannelsNumber = (channels: IChannels, channelType?: ChannelType): IChannels => {
  const { idListByType } = channels

  const adjustChannelCount = (_channelType: ChannelType) => {
    const maxChannelsNumber = CommunicationHubConstants.maxChannelsNumber[_channelType]

    if (maxChannelsNumber && idListByType[_channelType].length > maxChannelsNumber) {
      idListByType[_channelType] = idListByType[_channelType].slice(0, maxChannelsNumber)
    }
  }

  if (channelType) {
    adjustChannelCount(channelType)
  } else {
    Object.keys(CommunicationHubConstants.maxChannelsNumber).forEach(_ => adjustChannelCount(_ as ChannelType))
  }

  return channels
}
