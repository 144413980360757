import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import theme from '../../../../styles/theme'
import { ActionButton } from '../../Buttons/ActionButton'

type Props = {
  tooltipId: string
  disabled: boolean
  note: string | undefined
  onChange: (note: string | undefined) => void
  title?: string
  placeholder?: string
  styleNoteButton?: React.CSSProperties
  maxLength?: number
}

export const Note = (props: Props) => {
  const { tooltipId, disabled, title, placeholder, note, onChange, styleNoteButton, maxLength } = props
  const tooltipRef = React.useRef(null)
  const [state, setState] = React.useState(note)

  React.useEffect(() => {
    setState(note)
  }, [note])

  const closeTooltip = () => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.tooltipRef = null
      ReactTooltip.hide()
    }
  }

  const isStringChanged = (_str1: string, _str2: string): boolean => {
    const str1 = (_str1 || '').trim()
    const str2 = (_str2 || '').trim()

    return str1 !== str2
  }

  if (disabled && !note) {
    return null
  }

  return (
    <>
      <NoteButton
        style={styleNoteButton}
        data-for={tooltipId}
        data-tip={true}
        className={'mdi ' + (note ? 'mdi-file-edit' : 'mdi-file-edit-outline')}
      />
      <ReactTooltip
        className={'react-tooltip-custom-inner'}
        id={tooltipId}
        place={'bottom'}
        event={'click'}
        // globalEventOff={'click'}
        clickable={true}
        offset={{ top: 5, left: 5, right: 5, bottom: 5 }}
        ref={tooltipRef}
      >
        <NotePopup>
          <NotePopupTitle>
            {title || 'Note'}
            <CloseButton
              className={'mdi mdi-close'}
              onClick={() => {
                closeTooltip()
                setState(note)
              }}
            />
          </NotePopupTitle>
          <NotePopupTextArea
            disabled={disabled}
            value={state || ''}
            onChange={e => setState(e.target.value)}
            placeholder={disabled ? undefined : placeholder || 'Enter your note here'}
            maxLength={maxLength}
          />
          {!disabled && (
            <NotePopupActions>
              {state ? (
                <ActionButton styles={{ marginLeft: -10 }} onlyText={true} onClick={() => setState('')}>
                  Clear
                </ActionButton>
              ) : (
                <div />
              )}
              <ActionButton
                disabled={!isStringChanged(note, state)}
                filled={true}
                round={true}
                onClick={() => {
                  const newValue = (state || '').trim()

                  closeTooltip()
                  setState(newValue)
                  onChange(newValue)
                }}
              >
                Save
              </ActionButton>
            </NotePopupActions>
          )}
        </NotePopup>
      </ReactTooltip>
    </>
  )
}

const NoteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    background-color: #d5ddf0 !important;
  }

  &.mdi-file-edit {
    color: ${theme.colors.basicBlueColor};
  }
`
const NotePopup = styled.div`
  width: 455px;
  background-color: white;
  border: 1px solid #e3e8ea;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 20px 15px 20px;
`
const NotePopupTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #475364;
  user-select: none;
`
const CloseButton = styled.div`
  margin-left: auto;
  transform: translateX(5px);
  cursor: pointer;

  &:not(:hover) {
    color: #a6a9ba;
  }
`
const NotePopupTextArea = styled.textarea`
  width: 100%;
  height: 85px;
  background: #fafbfd;
  border: 1px solid #e3e8ea;
  border-radius: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #445366;
  padding: 13px 17px;
  margin: 10px 0;

  &::placeholder {
    color: #b2b2b2;
  }
`
const NotePopupActions = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
