import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { DriverViewDTO } from '../api/api'
import { useListItem } from './useListItem'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useDriver = (props: Props): DriverViewDTO => {
  const { id, modifiedLists } = props
  const driver = useListItem({ id, type: EntityType.driver, modifiedLists })

  return driver
}
