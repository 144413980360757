import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { MiscSurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import styled from 'styled-components'
import { SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { Input, InputField } from '../../../DataFields/Input'
import { HoursMinutes } from '../../../DataFields/Input/inputTypes/hoursMinutes'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import { SellSideQuoteMiscSurchargeDTO, SellSideQuoteSurchargeDTO } from '../../../../../api/api'
import { getSurchargeAmount } from '../../../../../services/functions/get/getSurchargeAmount'
import { quoteLists } from '../../../../../services/select/quoteLists'
import { Select } from '../../../DataFields/Select'
import { makeActivityColumn } from './SsqBsqWidgetRateColumn'

export enum SsqBsqWidgetMiscSurchargeColumnType {
  Activity = 'Activity',
  CalculationType = 'Calculation Type',
  Description = 'Description',
  Amount = 'Amount',
  Delete = 'Delete'
}

type Props = {
  columnType: SsqBsqWidgetMiscSurchargeColumnType
  miscSurcharge: MiscSurchargeDTO
  modifyMiscSurcharge: (miscSurcharge: MiscSurchargeDTO) => void
  deleteMiscSurcharge: () => void
  disable: boolean
}

export const SsqBsqWidgetMiscSurchargeColumn = React.memo((props: Props) => {
  const { columnType, miscSurcharge, disable, modifyMiscSurcharge, deleteMiscSurcharge } = props
  const { businessActivities, bobtailActivities } = useSsqBsqWidget()
  const isBobtail = oc(miscSurcharge).type() === SellSideQuoteMiscSurchargeDTO.TypeEnum.BOBTAIL

  const RenderCalculationTypeColumn = () => {
    const calculationTypeList = isBobtail
      ? quoteLists.calculationType.filter(item => item.value === SellSideQuoteRateDTO.CalculationTypeEnum.FIXEDAMOUNT)
      : quoteLists.calculationType

    switch (miscSurcharge.calculationType) {
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERDAY:
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERMILE: {
        return (
          <div style={{ display: 'flex' }}>
            <div>
              <Select
                disabled={isBobtail || disable}
                selectedValue={miscSurcharge.calculationType}
                list={calculationTypeList}
                onSelect={value => {
                  return modifyMiscSurcharge({
                    ...miscSurcharge,
                    calculationType: value,
                    quantity: 0,
                    amount: 0
                  })
                }}
              />
            </div>
            <StyledQuantity>
              <InputField.Numeric
                disabled={disable}
                placeholder={'000'}
                value={miscSurcharge.quantity}
                onChange={value => modifyMiscSurcharge({ ...miscSurcharge, quantity: value || 0 })}
              />
            </StyledQuantity>
          </div>
        )
      }
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERHOUR:
        return (
          <div style={{ display: 'flex' }}>
            <div>
              <Select
                disabled={isBobtail || disable}
                selectedValue={miscSurcharge.calculationType}
                list={calculationTypeList}
                onSelect={value => {
                  return modifyMiscSurcharge({
                    ...miscSurcharge,
                    calculationType: value,
                    quantity: 0,
                    amount: 0
                  })
                }}
              />
            </div>
            <StyledMinutesQuantity>
              <HoursMinutes
                disabled={disable}
                minutes={miscSurcharge.quantity}
                onChange={value => modifyMiscSurcharge({ ...miscSurcharge, quantity: value || 0 })}
              />
            </StyledMinutesQuantity>
          </div>
        )
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.FIXEDAMOUNT:
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERCENTAGE:
      default: {
        return (
          <Select
            disabled={isBobtail || disable}
            selectedValue={miscSurcharge.calculationType}
            list={calculationTypeList}
            onSelect={value =>
              modifyMiscSurcharge({
                ...miscSurcharge,
                calculationType: value,
                quantity: 0,
                amount: 0
              })
            }
          />
        )
      }
    }
  }

  switch (columnType) {
    case SsqBsqWidgetMiscSurchargeColumnType.CalculationType: {
      return (
        <Column>
          <RenderCalculationTypeColumn />
          <div style={{ width: 100, flexShrink: 0, paddingLeft: 8 }}>
            {[
              SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR,
              SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY,
              SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE
            ].includes(miscSurcharge.calculationType) && (
              <InputField.Money
                disabled={disable}
                value={miscSurcharge.amount}
                onChange={value => modifyMiscSurcharge({ ...miscSurcharge, amount: value || 0 })}
              />
            )}
          </div>
        </Column>
      )
    }
    case SsqBsqWidgetMiscSurchargeColumnType.Amount: {
      let amount: number | string = 0

      if (
        [
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERDAY,
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERHOUR,
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERMILE
        ].includes(miscSurcharge.calculationType)
      ) {
        amount = transformMoney(getSurchargeAmount({ mainRateAmount: 0, surcharge: miscSurcharge, defaultQuantity: 0 }))
      } else if (miscSurcharge.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE) {
        return (
          <Column style={{ paddingRight: 10 }}>
            <InputField.Percentage
              disabled={disable}
              value={miscSurcharge.amount}
              onChange={value => modifyMiscSurcharge({ ...miscSurcharge, amount: value || 0 })}
            />
          </Column>
        )
      } else if (miscSurcharge.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.FIXEDAMOUNT) {
        return (
          <Column style={{ paddingRight: 10 }}>
            <InputField.Money
              disabled={disable}
              isPositiveOrNegative={true}
              value={miscSurcharge.amount}
              onChange={value => modifyMiscSurcharge({ ...miscSurcharge, amount: value || 0 })}
            />
          </Column>
        )
      } else {
        amount = transformMoney(miscSurcharge.amount)
      }

      return <Column style={{ fontWeight: 500, justifyContent: 'flex-end', paddingRight: 10 }}>{amount}</Column>
    }
    case SsqBsqWidgetMiscSurchargeColumnType.Activity: {
      return (
        <Column>
          {makeActivityColumn({
            disableActivityColumn: isBobtail || disable,
            rate: miscSurcharge,
            activities: isBobtail ? bobtailActivities : businessActivities,
            availableActivitiesToAttach: businessActivities,
            onActivityIdSelect: activityId => modifyMiscSurcharge({ ...miscSurcharge, activityId }),
            showIcon: true
          })}
        </Column>
      )
    }
    case SsqBsqWidgetMiscSurchargeColumnType.Description: {
      return (
        <Column>
          <Input
            disabled={disable}
            required={true}
            value={miscSurcharge.description}
            onChange={description => modifyMiscSurcharge({ ...miscSurcharge, description })}
          />
        </Column>
      )
    }
    case SsqBsqWidgetMiscSurchargeColumnType.Delete: {
      return (
        <Column style={{ justifyContent: 'center' }}>
          {!disable && <DeleteButton className={cn('mdi', 'mdi-minus-circle')} onClick={deleteMiscSurcharge} />}
        </Column>
      )
    }
    default:
      return <Column />
  }
})

const Column = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 0 8px;
`
const RateAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  padding: 0 5px;
  margin-left: auto;
`
const CellWithDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .details {
    font-weight: 400;
  }
`

const StyledQuantity = styled.div`
  width: 60px;
  margin-left: 8px;
`
const StyledMinutesQuantity = styled.div`
  width: 130px;
  margin-left: 8px;
`

const DeleteButton = styled.div`
  color: rgba(68, 83, 102, 0.3);
  font-size: 22px;
  cursor: pointer;

  &:hover {
    color: rgba(68, 83, 102, 1);
  }
`
