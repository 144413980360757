import { callAPI, containerTypeAPI } from '../../../api/api'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { containerLists } from '../../select/containerLists'

export const requestContainerTypes = () => {
  return callAPI(containerTypeAPI.findAll)
    .toPromise()
    .then(async containerTypes => {
      const containerTypeCodesList: any[] = []
      const containerTypeNamesList: any[] = []
      const containerTypeListWithDescription: any[] = []
      containerTypes.forEach(item => {
        containerTypeCodesList.push({ label: item.code, value: item.id })
        containerTypeNamesList.push({ label: item.name, value: item.id })
        containerTypeListWithDescription.push({
          label: item.code,
          htmlLabel: item.code + ' – ' + item.name,
          value: item.id
        })
      })

      containerLists.containerTypes = containerTypes
      containerLists.containerTypeCodesList = containerTypeCodesList
      containerLists.containerTypeNamesList = containerTypeNamesList
      containerLists.containerTypeWithDescription = containerTypeListWithDescription

      await pushListItemsToStore({ update: { [EntityType.containerType]: containerTypes } })
    })
}
