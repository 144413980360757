import * as React from 'react'
import { locationTypeIcons } from '../../../../../services/DTO/location'
import { NoWrap } from '../../../grid/views/styles'
import { LocationViewDTO } from '../../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'

type TShuttleLocation = {
  location: LocationViewDTO
  isShuttle: boolean
}

export const ShuttleLocationColumn = (props: TShuttleLocation) => {
  const { location, isShuttle } = props

  if (!location) {
    return null
  }

  const shortName = oc(location).shortName()
  const isInvalid = isLocationGEOValid(location) === false

  return (
    <div
      className={isShuttle ? undefined : locationTypeIcons[location.type]}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        color: isInvalid ? theme.colors.defaultRed : undefined
      }}
    >
      {isShuttle && <SHLabel />}
      <NoWrap style={{ marginLeft: 5, fontSize: 12 }}>{shortName}</NoWrap>
    </div>
  )
}

const SHLabel = styled.div`
  display: inline-flex;
  font-size: 12px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #323e4e;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  width: 22px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 0;

  &:before {
    content: 'SH';
  }
`
