import * as React from 'react'
import { Input, TProps } from './simple'

export const Numeric = (props: TProps) => (
  <Input
    {...props}
    onChange={value => props.onChange(value ? Number(value) : null)}
    transformOnInputChange={value => value.replace(/\D/gi, '')}
  />
)
