export const filterLatestObjects = (objects: any[]): any[] => {
  const getLatestOrForce = (objs: any[]): object => {
    let latestObject: any = undefined

    objs
      .slice()
      .reverse()
      .forEach(obj => {
        if (!latestObject) {
          latestObject = obj
          return
        }

        const notVersionData = obj.version === undefined && obj.updatedAt === undefined

        // no version & no updatedAt – get latestObject
        if (notVersionData && obj.forceUpdate && !latestObject.forceUpdate) {
          latestObject = obj
          return
        }
        if (notVersionData) {
          return
        }

        // version
        if (latestObject.version !== undefined && latestObject.version < obj.version) {
          latestObject = obj
          return
        }

        // updatedAt
        if (obj.updatedAt && (!latestObject.updatedAt || obj.updatedAt > latestObject.updatedAt)) {
          latestObject = obj
          return
        }

        // forceUpdate
        if (
          obj.forceUpdate &&
          !latestObject.forceUpdate &&
          latestObject.version === obj.version &&
          latestObject.updatedAt === obj.updatedAt
        ) {
          latestObject = obj
          return
        }
      })

    return latestObject
  }

  const objectsByID = objects.reduce((acc, curr) => {
    const key = curr.id
    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(curr)
    return acc
  }, {})

  const filteredObjectsByID = Object.keys(objectsByID).reduce((acc, key) => {
    acc[key] = getLatestOrForce(objectsByID[key])
    return acc
  }, {})

  return Object.values(filteredObjectsByID)
}
