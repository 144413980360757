import { DeductionDTO, DeductionType } from '../../../api/origin/vendor-accounting-service'
import { getDeductionTotalAmount } from '../get/getDeductionTotalAmount'
import { isWeeklyDeduction } from './isWeeklyDeduction'

export const isDeductionAmountValid = (deduction: DeductionDTO): boolean => {
  if (!deduction || isWeeklyDeduction(deduction) || typeof deduction.deductionAmount !== 'number') {
    return true
  }

  if (deduction.type === DeductionType.ESCROW) {
    return deduction.deductionAmount <= (deduction.principle || 0)
  }

  return deduction.deductionAmount <= getDeductionTotalAmount(deduction)
}
