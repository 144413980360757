import * as React from 'react'
import Columns from './columns'
import { GridItemProviderMapping } from '../../../providers'
import { TGridColumns, TabDTO } from '../../../store/reducers/tabs/interfaces'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { tabActions } from '../../../store/reducers/tabs'
import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectListItem } from '../../../store/select/listItemSelect'

type OwnProps = {
  gridItemId: string
  columnsSettings: TGridColumns
  disable: boolean
  tabId: string
  tabType: TabDTO.Type
  handleGridItemVersion: (gridItem: any) => void
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabDTO.Actions
}

type StateProps = {}

type DispatchProps = {}

export type TGridItemProps = StateProps & OwnProps & DispatchProps

export const GridItem = React.memo((props: TGridItemProps) => {
  const {
    gridItemId,
    columnsSettings,
    disable,
    tabId,
    tabType,
    isSelected,
    isDisabledSelect,
    isPinned,
    actionType
  } = props
  const dispatch = useAppDispatch()
  const Provider = GridItemProviderMapping[tabType]
  // @ts-ignore
  const storeItem = useAppSelector(selectListItem({ id: gridItemId, type: tabType }))

  const togglePreview = React.useCallback(() => {
    if (!disable) {
      dispatch(tabActions.setExpandedItem({ tabId, expandedItem: { id: gridItemId } }))
    }
  }, [disable, gridItemId])

  // console.log('storeItem', storeItem)
  // console.log('props', { id: gridItemId, type: tabType })

  if (!(storeItem && Provider)) {
    return null
  }

  return (
    <Provider id={gridItemId}>
      <Columns
        columnsSettings={columnsSettings}
        gridItemId={gridItemId}
        isHighlightRed={Boolean(
          tabType === TabDTO.Type.dispatchDeliveryOrder &&
            storeItem &&
            (storeItem as DispatchDeliveryOrderViewDTO).troubleTicketId
        )}
        tabType={tabType}
        togglePreview={togglePreview}
        isSelected={isSelected}
        isDisabledSelect={isDisabledSelect}
        isPinned={isPinned}
        actionType={actionType}
      />
    </Provider>
  )
})
