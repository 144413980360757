import * as React from 'react'

export const DownloadIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6001 8.1C1.6001 9.6464 2.8537 10.9 4.4001 10.9H7.2001V12.9686L6.16578 11.9343C5.85336 11.6219 5.34683 11.6219 5.03441 11.9343C4.72199 12.2467 4.72199 12.7533 5.03441 13.0657L7.43441 15.4657C7.74683 15.7781 8.25336 15.7781 8.56578 15.4657L10.9658 13.0657C11.2782 12.7533 11.2782 12.2467 10.9658 11.9343C10.6534 11.6219 10.1468 11.6219 9.83441 11.9343L8.8001 12.9686V10.9H10.8001C12.7883 10.9 14.4001 9.28823 14.4001 7.3C14.4001 5.31178 12.7883 3.7 10.8001 3.7C10.6331 3.7 10.4687 3.71138 10.3077 3.7334C9.9642 2.3363 8.70317 1.3 7.2001 1.3C5.43279 1.3 4.0001 2.73269 4.0001 4.5C4.0001 4.78178 4.03652 5.05506 4.10491 5.31538C2.69727 5.46286 1.6001 6.6533 1.6001 8.1ZM8.8001 10.9H7.2001V6.9C7.2001 6.45818 7.55827 6.1 8.0001 6.1C8.44193 6.1 8.8001 6.45818 8.8001 6.9V10.9Z"
      fill="white"
    />
  </svg>
)
