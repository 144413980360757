import * as React from 'react'
import { TextLine } from '../PreviewPhase'
import theme from '../../../../../../../../styles/theme'
import { ActivityNotesButton } from './ActivityNotesButton'

type Props = {
  isActive: boolean
  isPending: boolean
  isAssigned: boolean
  hasActivityNotes: boolean
  isActivePhase: boolean
  isUnsuccessful: boolean
  isTwoActivitiesView: boolean
}

export const DriverPlaceholder = React.memo((props: Props) => {
  const {
    hasActivityNotes,
    isActivePhase,
    isUnsuccessful,
    isTwoActivitiesView,
    isActive,
    isPending,
    isAssigned
  } = props

  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <TextLine style={{ width: '100%', fontSize: 13 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i
              className={'mdi mdi-close-circle'}
              style={{ fontSize: 16, marginRight: 4, color: isActivePhase ? theme.colors.defaultRed : '#B2B2B2' }}
            />
            Driver
          </div>
          {!isTwoActivitiesView && (
            <div
              style={{
                fontWeight: 500,
                fontSize: 14,
                marginTop: 3,
                color: isUnsuccessful ? theme.colors.defaultRed : undefined,
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              Not Assigned
            </div>
          )}
        </TextLine>
      </div>
      {hasActivityNotes && (
        <ActivityNotesButton
          active={isActive || isPending || isAssigned}
          style={isTwoActivitiesView ? undefined : { alignSelf: 'flex-end' }}
        />
      )}
    </>
  )
})
