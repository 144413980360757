import styled from 'styled-components'
import { theme } from '../../../../../../../../styles/theme'

export const RuleContent = styled.div`
  background: white;
  border: 1px solid ${theme.colors.darkBorderColor};
  border-radius: 2px;
  padding: 15px;
`

export const BlockTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  min-height: 17px;
  letter-spacing: -0.3px;
  color: black;
  margin-bottom: 15px;

  &.border-bottom:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    border-bottom: 1px solid ${theme.colors.darkBorderColor};
  }
`

export const StyledConditionList = styled.div``

export const StyledCondition = styled.div`
  position: relative;
  padding: 15px 0 25px;

  &:not(:first-child) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -15px;
      right: -15px;
      border-top: 1px dashed ${theme.colors.darkBorderColor};
    }
  }
`

export const ConditionInputs = styled.div`
  display: flex;

  > div:not(:first-child) {
    margin-left: 15px;
  }

  .primary .selected-item {
    color: ${theme.colors.basicBlueColor};
    font-weight: 400;
  }
`
