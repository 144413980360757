import * as React from 'react'
import styled from 'styled-components'

type Props = {
  text: string | JSX.Element
  color: string
  styleButton?: React.CSSProperties
  onClick?: () => void
  onLeftArrowClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onRightArrowClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  containerRef?: any
  attributes?: any
}

export const ArrowActionButton = (props: Props) => {
  const { text, color, styleButton, containerRef, attributes, onLeftArrowClick, onRightArrowClick } = props

  return (
    <Container ref={containerRef} style={{ backgroundColor: color, ...(styleButton || {}) }}>
      {onLeftArrowClick && (
        <ArrowButton
          className={'mdi mdi-menu-left'}
          style={{ borderRadius: '4px 0 0 4px', borderRightWidth: 1 }}
          onClick={onLeftArrowClick}
        />
      )}
      <ButtonContent {...attributes || {}}>{text}</ButtonContent>
      {onRightArrowClick && (
        <ArrowButton
          className={'mdi mdi-menu-right'}
          style={{ borderRadius: '0 4px 4px 0', borderLeftWidth: 1 }}
          onClick={onRightArrowClick}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 26px;
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  color: white;
  background: #98a4b4;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
`
const ButtonContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.button {
    cursor: pointer;
  }
`

const ArrowButton = styled.div`
  flex-shrink: 0;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 0 solid white;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
