import * as React from 'react'
import { ChatTab } from '../../../../store/reducers/communicationHub/interfaces'
import { ChannelViewHeader } from './ChannelViewHeader'
import { ChannelViewBody } from './ChannelViewBody'
import { CommunicationHubSuggestionsProvider } from '../../../../providers/CommunicationHubSuggestionsProvider'
import { AllUnread } from '../AllUnread/AllUnread'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectCommunictaionHubActiveChannel,
  selectCommunictaionHubActiveChannelTab,
  selectCommunictaionHubIsAllUnreadView
} from '../../../../store/select/communicationHubSelect'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ChannelView = (props: Props) => {
  const channel = useAppSelector(selectCommunictaionHubActiveChannel)
  const isAllUnreadView = useAppSelector(selectCommunictaionHubIsAllUnreadView)
  const chatTab = useAppSelector(selectCommunictaionHubActiveChannelTab)

  if (isAllUnreadView) {
    return (
      <React.Fragment key={'all unread'}>
        <AllUnread />
      </React.Fragment>
    )
  }

  if (!channel) {
    return null
  }

  return (
    <React.Fragment key={channel.id}>
      <CommunicationHubSuggestionsProvider dispatchDeliveryOrderId={channel.id}>
        <ChannelViewHeader channel={channel} chatTab={chatTab || ChatTab.Messages} />
        <ChannelViewBody channel={channel} chatTab={chatTab || ChatTab.Messages} />
      </CommunicationHubSuggestionsProvider>
    </React.Fragment>
  )
}
