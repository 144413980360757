import { callAPI, vendorAccountingAPI } from '../../../api/api'
import { VendorDTO } from '../../../api/origin/vendor-accounting-service'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { catchListErrors } from '../../tabs/functions'

export const requestVendorAccounting = (vendorId: string): Promise<VendorDTO> => {
  // @ts-ignore
  return callAPI(vendorAccountingAPI.vendorControllerGet, vendorId)
    .toPromise()
    .then(async vendorAccounting => {
      await pushListItemsToStore({ update: { [EntityType.vendorAccounting]: [vendorAccounting] } })

      return vendorAccounting as VendorDTO
    })
    .catch(catchListErrors)
}

export const requestVendorAccountingUpdate = (vendor: VendorDTO): Promise<VendorDTO> => {
  // @ts-ignore
  return callAPI(vendorAccountingAPI.vendorControllerUpdate, vendor.id, vendor)
    .toPromise()
    .then(async vendorAccounting => {
      await pushListItemsToStore({ update: { [EntityType.vendorAccounting]: [vendorAccounting] } })

      return vendorAccounting as VendorDTO
    })
    .catch(catchListErrors)
}
