import { IDropdownItem } from '../../components/UI/DataFields/Select/Dropdown'

export const createSelectListFromDirectory = (directory: any, ignoreList: any[] = []): IDropdownItem[] =>
  Object.keys(directory)
    .filter(key => !ignoreList.includes(key))
    .map(key => ({
      label: directory[key],
      value: key
    }))

export const createSelectListWithHTMLFromDirectory = (directory: any, ignoreList: any[] = []): IDropdownItem[] =>
  Object.keys(directory)
    .filter(key => !ignoreList.includes(key))
    .map(key => ({
      label: directory[key].label,
      htmlLabel: directory[key].htmlLabel,
      value: key
    }))

export const createFilteredSelectListFromDirectory = (listOfValues: any[], directory: any): IDropdownItem[] =>
  listOfValues.map(key => ({
    label: directory[key],
    value: key
  }))
