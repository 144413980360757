import * as React from 'react'
import { Condition, TRuleCondition } from './Condition'
import { StyledConditionList } from '../../styles'

type Props = {
  list: TRuleCondition[]
}

export const ConditionList = (props: Props) => {
  const { list } = props

  return (
    <StyledConditionList>
      {list.map(conditionProps => (
        <Condition key={conditionProps.number} {...conditionProps} />
      ))}
    </StyledConditionList>
  )
}
