import { RootState } from '..'
import { DispatchDeliveryOrderGridItemDTO, DispatchDeliveryOrderViewDTO } from '../../api/api'

// SELECT ONE
export const selectDispatchDeliveryOrder = (id: string) => (
  state: RootState
): DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO | undefined => {
  const {
    lists: { dispatchDeliveryOrder }
  } = state

  return dispatchDeliveryOrder[id]
}

// SELECT MANY
export const selectDispatchDeliveryOrders = (
  state: RootState
): Record<string, DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO> => {
  return state.lists.dispatchDeliveryOrder
}
