import { PowerUnitRegistrationDTO } from '../../api/origin/business-logic'
import { ddoDirectory } from './dispatchDeliveryOrder/directory'
import { activityDirectory } from './activity/directory'
import { customerDirectory } from './customer/directory'
import { locationDirectory } from './location/directory'
import { quoteDirectory } from './sellSideQuote/directory'
import { driverDirectory } from './driver/directory'
import { equipmentDirectory } from './equipment/directory'
import { powerUnitDirectory } from './powerUnit/directory'
import { containerDirectory } from './container/directory'

const weightUnit = {
  [PowerUnitRegistrationDTO.WeightUnitEnum.KGS]: 'KGS',
  [PowerUnitRegistrationDTO.WeightUnitEnum.LBS]: 'LBS'
}

export const commonDirectory = {
  weightUnit
}

export const allDirectoriesSearch = (value: any): string | undefined => {
  const allDirectories = [
    {
      loadType: ddoDirectory.loadType,
      streetTurnStatus: ddoDirectory.streetTurnStatusLabel,
      status: ddoDirectory.status,
      cargoType: ddoDirectory.cargoType
    },
    activityDirectory,
    customerDirectory,
    locationDirectory,
    quoteDirectory,
    driverDirectory,
    equipmentDirectory,
    powerUnitDirectory,
    containerDirectory
  ]

  let result: string

  allDirectories.forEach(directory => {
    if (result) {
      return
    }

    const mapping = Object.values(directory).reduce(
      (acc, curr) => ({
        ...acc,
        ...curr
      }),
      {}
    )

    result = mapping[value]
  })

  return result
}
