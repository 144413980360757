import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const StyledPopover = styled.div`
  max-width: 325px;
  display: flex;
  cursor: default;
  font-weight: 500;
  font-size: 14px;

  ul,
  div {
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid ${theme.colors.darkBorderColor};
    padding: 8px;
  }

  li {
    margin-bottom: 3px;
  }

  div {
    display: flex;

    span {
      line-height: 1.4;

      &:not(:last-child):after {
        content: ',';
        margin-right: 4px;
      }
    }
  }
`

export const StyledColumnValueWithPopover = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledLabel = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledValueAsNumber = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    line-height: 18px;
    background: ${theme.colors.basicBlueColor};
    font-weight: 500;
    font-size: 14px;
    color: #f5f6fa;
    border-radius: 2px;
    padding: 0 5px;
  }
`

export const StyledValue = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  > div {
    flex: 0 0 auto;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
