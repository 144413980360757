import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { PowerUnitViewDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const usePowerUnit = (props: Props): PowerUnitViewDTO => {
  const { id, modifiedLists } = props
  const powerUnit = useListItem({ id, type: EntityType.powerUnit, modifiedLists })

  return powerUnit
}
