import styled from 'styled-components'
import theme from '../../../styles/theme'

export const Icon = styled.span`
  display: block;
  color: ${theme.colors.defaultRed};
  font-size: 16px;
  cursor: pointer;
`

export const Message = styled.div`
  width: 200px;
  font-size: 14px;
  line-height: 1.2;
  white-space: pre-wrap;
  display: none;
  position: absolute;
  bottom: 100%;
  left: 10px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px 5px 5px 0;
  padding: 10px;
`

export const StyledFieldWarning = styled.div`
  z-index: 5;
  position: relative;
  height: 0;
  width: 0;

  &:hover {
    ${Message} {
      display: block;
    }
  }
`
