import { RootState } from '..'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../api/api'

// SELECT ONE
export const selectActivity = (id: string) => (
  state: RootState
): TransportationActivityViewDTO | DocumentationActivityDTO | undefined => {
  return state.lists.activity[id]
}

// SELECT MANY
export const selectActivities = (
  state: RootState
): Record<string, TransportationActivityViewDTO | DocumentationActivityDTO> => {
  return state.lists.activity
}
