import styled from 'styled-components'
import { theme } from '../../../../styles/theme'

export const WindowOverlay = styled.div`
  @keyframes appear {
    to {
      opacity: 1;
    }
  }

  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: appear 0.25s linear forwards;
`
export const StyledModalWindow = styled.div`
  min-width: 400px;
  background-color: white;
  border: 1px solid ${theme.colors.spaceGray};
  color: rgb(68, 83, 102);
  border-radius: 6px;
  padding: 15px;
`
export const TopSection = styled.section`
  width: 100%;
  text-align: center;
  display: flex;
  white-space: pre-wrap;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  padding-bottom: 15px;
`
export const MiddleSection = styled.section`
  position: relative;
  padding: 15px 1px;
`
export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
export const CopyContentButton = styled.div`
  color: gray;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border-radius: 3px;
  padding: 2px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
export const BottomSection = styled.section`
  display: flex;
  padding-top: 15px;
  margin: 0 -10px;

  > * {
    margin: 0 10px;
  }
`
