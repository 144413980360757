import * as React from 'react'
import cn from 'classnames'
import { EmptyGrid, StyledGrid } from './styledComponents'
import { Table, TableRow } from './styles'
import { scrollGridItems, TableBody } from './TableBody'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { FloatingAddButton } from '../../../UI/FloatingGridItems/FloatingAddButton'
import { FloatingGridItemsCounter } from '../../../UI/FloatingGridItems/FloatingGridItemsCounter'
import { TableHeader } from './TableHeader'
import { GridActions } from '../../../pages/GridActions'
import { tabSessionStorage } from '../../../../services/tabs/functions'
import { ApplicationSizeProvider } from '../../../../providers/ApplicationSizeProvider'
import { useGrid } from '../../../../hooks/useGrid'
import { TGridColumns, TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { ExpandedGridItem } from '../../../pages/GridItem/ExpandedGridItem'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectActiveApplicationTabGridItemIds,
  selectActiveApplicationTabIsModifiedMode
} from '../../../../store/select/applicationTabSelect'

type Props = {
  extandedItemId: string
  isFetching: boolean
  columnsSettings: TGridColumns
  fetchGridItems: (withoutSpinner?: boolean) => void
}

export const GridTable = React.memo((props: Props) => {
  const gridRef = React.useRef(null)
  const { isFetching, fetchGridItems, extandedItemId, columnsSettings } = props
  const gridItemIds = useAppSelector(selectActiveApplicationTabGridItemIds)
  const isModifiedMode = useAppSelector(selectActiveApplicationTabIsModifiedMode)
  const { tabType, tabId, actionsType, selectedIds, pinnedIds, disabledSelectIds } = useGrid()

  const gridLength = (gridItemIds && gridItemIds.length) || 0
  const isEmptyGrid = (!gridItemIds || !gridLength) && (!pinnedIds || !pinnedIds.length)
  const hideGrid = React.useMemo(() => extandedItemId && isFullHeightExpandedGridItems(tabType), [extandedItemId])

  // TODO
  // console.log('RERENDER, GridTable')

  React.useLayoutEffect(() => {
    if (!gridRef || !gridRef.current) {
      return
    }

    if (hideGrid) {
      scrollGridItems(0)
      gridRef.current.scrollTo(0, 0)
      return
    }

    if (isEmptyGrid) {
      const scrollLeft = tabSessionStorage.scrollPosition.getTabPosition(tabId).left
      scrollGridItems(0)
      gridRef.current.scrollTo(scrollLeft, 0)
      return
    }

    gridRef.current.scrollTo(0, 0)
  }, [isEmptyGrid, hideGrid])

  React.useLayoutEffect(() => {
    if (!gridRef || !gridRef.current) {
      return
    }

    if (extandedItemId && !hideGrid) {
      scrollGridItems(tabSessionStorage.scrollPosition.getTabPosition(tabId).left)
    }
  }, [extandedItemId])

  const showEmptyGridMessage = gridItemIds && isEmptyGrid && !isFetching && !extandedItemId
  const classNames = React.useMemo(
    () =>
      cn({
        emptyGrid: isEmptyGrid,
        scrollable: hideGrid
      }),
    [hideGrid, isEmptyGrid]
  )

  return (
    <StyledGrid ref={gridRef} className={classNames}>
      {Boolean(actionsType) && <GridActions actionsType={actionsType} fetchGridItems={fetchGridItems as any} />}

      {hideGrid && <TableHeader disallowFilter={true} scrollable={true} scrollToZero={false} />}

      {extandedItemId && (
        <TableRow
          key={extandedItemId}
          className={cn('table__row table__row_opened', { 'table__row_full-height': hideGrid })}
        >
          <ExpandedGridItem
            columnsSettings={columnsSettings}
            tabType={tabType}
            tabId={tabId}
            isSelected={selectedIds.includes(extandedItemId)}
            isDisabledSelect={disabledSelectIds.includes(extandedItemId)}
            isPinned={pinnedIds.includes(extandedItemId)}
            actionType={actionsType}
          />
        </TableRow>
      )}

      {!hideGrid && (
        <Table id={'grid'} className={'grid'}>
          <TableHeader scrollToZero={false} />
          {showEmptyGridMessage && <EmptyGrid>No results found</EmptyGrid>}
          {!isEmptyGrid && (
            <ApplicationSizeProvider rerenderOnResize={true}>
              <TableBody
                columnsSettings={columnsSettings}
                isModifiedMode={isModifiedMode}
                extandedItemId={extandedItemId}
                gridItemIds={gridItemIds}
              />
            </ApplicationSizeProvider>
          )}
        </Table>
      )}

      {!isModifiedMode && <FloatingAddButton tabId={tabId} type={tabType} />}
      {gridItemIds && <FloatingGridItemsCounter unitType={tabType} itemsNumber={gridItemIds.length} />}
      {isFetching && <GridSpinner />}
    </StyledGrid>
  )
})

const isFullHeightExpandedGridItems = (listType: TabDTO.Type): boolean => {
  switch (listType) {
    case TabDTO.Type.dispatchDeliveryOrder:
      return true
    default:
      return false
  }
}
