import { BuySideQuoteDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.buySideQuote]: BuySideQuoteDTO[]
}

export const parseBuySideQuotes = async (buySideQuotes: BuySideQuoteDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.buySideQuote]: []
  }

  try {
    buySideQuotes.forEach(buySideQuote => {
      result[EntityType.buySideQuote].push(buySideQuote)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
