import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { CombinedSurchargeRateDTO, RateDTO, SurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import { SsqBsqWidgetRateColumnType } from './SsqBsqWidgetRateColumn'
import { SsqBsqWidgetTableTitleWithLink } from './SsqBsqWidgetTableTitleWithLink'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { generateCombinedSurchargeRate } from '../../../../../services/functions/generate/generateCombinedSurchargeRate'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectRates } from '../../../../../store/select/rateSelect'
import {
  applicableRateColumns,
  applicableRateColumnsWOActivity,
  SsqBsqWidgetApplicableRate
} from './SsqBsqWidgetApplicableRate'

type Props = {
  surcharges: SurchargeDTO[]
  applicableRates: RateDTO[]
  onOpenRatesClick: () => void
  onlyOneSurchargeForRate: boolean
  hideActivityColumn?: boolean
  addSurcharge: (surcharge: SurchargeDTO) => void
  deleteSurchargeId: (surchargeId: string) => void
  modifySurcharge: (surcharge: SurchargeDTO) => void
}

export const SsqBsqWidgetApplicableRates = React.memo((props: Props) => {
  const {
    surcharges,
    onlyOneSurchargeForRate,
    hideActivityColumn,
    addSurcharge,
    deleteSurchargeId,
    modifySurcharge,
    onOpenRatesClick
  } = props
  const { enableEditing } = useSsqBsqWidget()
  const rateMapping = useAppSelector(selectRates)
  const disable = !enableEditing
  const applicableRates = onlyOneSurchargeForRate
    ? props.applicableRates.filter(rate => !surcharges.some(surcharge => surcharge.rateId === rate.id))
    : props.applicableRates

  const selectedCombinedSurchargeRateList: CombinedSurchargeRateDTO[] = React.useMemo(() => {
    const mainRateTypes = [
      SellSideQuoteRateDTO.TypeEnum.BASE,
      SellSideQuoteRateDTO.TypeEnum.FUEL,
      SellSideQuoteRateDTO.TypeEnum.ALLIN
    ]

    return (surcharges || [])
      .filter(surcharge => !mainRateTypes.includes(surcharge.type))
      .map(surcharge => generateCombinedSurchargeRate({ rate: rateMapping[surcharge.rateId], surcharge }))
  }, [rateMapping, surcharges])

  return (
    <>
      <SsqBsqWidgetTableTitleWithLink
        title={'Applicable Rates'}
        link={
          disable
            ? null
            : {
                label: 'Go to Rates',
                onClick: onOpenRatesClick
              }
        }
      />
      {Boolean((surcharges || []).length || applicableRates.length) && (
        <Container>
          <Table className={cn({ hideActivityColumn })}>
            {(hideActivityColumn ? applicableRateColumnsWOActivity : applicableRateColumns).map(title => (
              <TableTitle
                style={{
                  justifyContent: title === SsqBsqWidgetRateColumnType.Amount ? 'flex-end' : undefined
                }}
                key={title}
              >
                {title}
              </TableTitle>
            ))}
            {selectedCombinedSurchargeRateList.map(surcharge => (
              <SsqBsqWidgetApplicableRate
                key={surcharge.id}
                rate={surcharge}
                surcharges={surcharges}
                disable={disable}
                hideActivityColumn={hideActivityColumn}
                addSurcharge={undefined}
                deleteSurcharge={() => deleteSurchargeId(surcharge.id)}
                modifySurcharge={modifySurcharge}
              />
            ))}
            {applicableRates.map(rate => (
              <SsqBsqWidgetApplicableRate
                key={rate.id}
                rate={rate}
                surcharges={surcharges}
                disable={disable}
                hideActivityColumn={hideActivityColumn}
                addSurcharge={addSurcharge}
                deleteSurcharge={undefined}
                modifySurcharge={modifySurcharge}
              />
            ))}
          </Table>
        </Container>
      )}
    </>
  )
})

const Container = styled.div`
  flex-grow: 1;
  background-color: #fff;
  overflow-y: scroll;
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 30fr 50fr 135fr 105fr 45fr 275fr 100fr 90fr 90fr 100fr;

  &.hideActivityColumn {
    grid-template-columns: 30fr 50fr 105fr 45fr 275fr 100fr 90fr 90fr 100fr;
  }
`
const TableTitle = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  color: rgb(108, 112, 125);
  border-bottom: 1px solid rgb(229, 229, 229);
  font-size: 10px;
  background-color: #fff;
  padding: 4px 8px;

  &:not(:first-child)::before {
    content: '';
    width: 1px;
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
    bottom: 4px;
    background-color: rgb(229, 229, 229);
  }
`
const BasePlusFuelAmount = styled.div`
  text-align: right;
  padding: 10px 10px 20px;

  span {
    font-weight: 500;
    margin-left: 8px;
  }
`
