export const SchedulerConstants = {
  phaseWidth: 540,
  specsWidth: 825,
  innerGap: 3,
  mediaQueriesBreakpoints: {
    large: 2560, // 2560
    medium: 2035, // 1735 + 300
    small: 1530 // 1230 + 300
  }
}
