import styled from 'styled-components'
import { withProps } from '../../../styles/styledComponents'
import theme, { IStyledComponentsProps } from '../../../styles/theme'
// @ts-ignore
import { PositionProperty } from 'csstype'
import { GridColumn } from '../GridSystem'

export const StyledDateField = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  position: relative;
  display: flex;
  padding-left: ${props => (props.padding && props.padding.left >= 0 ? props.padding.left + 'px' : '8px')};
  align-items: center;
  height: 35px;
  border-radius: 5px;
  background: ${props => (!props.disabled ? props.background : null)};
  border: 1px solid rgba(229, 229, 229, ${props => (props.disabled ? 0.4 : 1)});
  font-size: 13px;
  color: #333333;
  ${props => (props.margin && props.margin.bottom >= 0 ? `margin-bottom: ${props.margin.bottom}px;` : null)}
  
  &.highlight {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }

  span {
    color: ${theme.colors.basicGreyColor};
    margin: 0;

    &.date {
      color: #333;
    }
  }
  
  .b-reset-all {
    display: none;
  }
  
  :hover {
    .b-reset-all {
      display: block;
    }
   }
   
    .error{
       color: red;
    }
  }
`

export const ResetDate = styled.i`
  font-size: 18px;
  position: absolute;
  right: 3px;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.25s;

  &:hover {
    color: #445366;
  }
`

export const cssMaskedDateInput = {
  display: 'inline-block',
  width: '100%',
  border: 'none',
  position: 'relative' as PositionProperty,
  background: 'none',
  marginLeft: '5px',
  padding: 0
}

export const cssMaskedTimeInput = {
  display: 'inline-block',
  width: '36px',
  border: 'none',
  position: 'relative' as PositionProperty,
  background: 'none'
}

export const TimeWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  margin-right: 22px;

  &:before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    background-color: #e2e5f1;
  }

  input:first-child {
    text-align: right;
  }
`
export const Tabs = styled(GridColumn)`
  z-index: 1;
  position: absolute;
  top: -5px;
  right: 0;

  span {
    position: relative;
    display: block;
    height: 25px;
    padding: 5px 5px 0;
    border-radius: 5px 5px 0 0;
    font-size: 10px;
    cursor: default;
    user-select: none;
    transition: background 0.25s;
    overflow: hidden;

    &.active {
      position: relative;
      color: #445366;
      background-color: #f5f6fa;
    }

    p {
      display: block;
      padding-bottom: 3px;
      background-position: -1px bottom !important;
      background-size: 7px 1px !important;
      background-repeat: repeat-x !important;
      transition: background 0.25s;
    }

    &:not(.active) {
      color: #4555d0;
      cursor: pointer;

      p {
        background: linear-gradient(to right, #e3e6f1 50%, rgba(255, 255, 255, 0) 0%);
      }

      &:not(.disabled):hover p {
        background: linear-gradient(to right, rgba(69, 85, 208, 0.5) 50%, rgba(255, 255, 255, 0) 0%);
      }
    }

    &.disabled {
      color: rgba(68, 83, 102, 0.5);
      cursor: default;
    }
  }

  & ~ span + .date-picker-container .date-field {
    border-radius: 5px 0 5px 5px;
  }
`
export const SaveCancelContainer = styled.div`
   {
    position: relative;
    height: 50px;
    margin-bottom: -10px;
    margin-right: -20px;
  }
`
