import * as React from 'react'
import theme from '../../../styles/theme'
import { GridColumn } from '../GridSystem'
import styled from '../../../styles/styledComponents'

type Props = {
  checked: boolean | undefined | null
  onChange?: (value: boolean) => void
}

export const Toggle = ({ checked, onChange }: Props) => (
  <StyledToggleSwitch
    className={checked ? 'checked' : ''}
    isFixed={true}
    alignCenter={true}
    justifyCenter={true}
    onClick={onChange && (() => onChange(!checked))}
  />
)

export default Toggle

const StyledToggleSwitch = styled(GridColumn)`
  transition: all 0.2s ease-in;
  height: 15px;
  width: 34px;
  display: inline-block;
  background: rgba(189, 189, 189, 0.4);
  margin-right: 10px;
  border-radius: 7px;
  cursor: pointer;

  &:after {
    content: '';
    transition: all 0.2s ease-in;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #bdbdbd;
    border-radius: 10px;
    margin: -2px 0 0 -2px;
  }

  &.checked {
    background: rgba(69, 85, 208, 0.4);
    &:after {
      margin-left: 15px;
      background: ${theme.colors.basicBlueColor};
    }
  }
`
