import * as React from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import cn from 'classnames'
import { WindowOverlay } from '../ModalWindow/views/styles'
import { useApplicationSize } from '../../../hooks/useApplicationSize'
import { ApplicationSizeProvider } from '../../../providers/ApplicationSizeProvider'

type Props = {
  isCommunicationHubVisible?: boolean
  startBelowTabs?: boolean
  children?: any
  windowStyles?: React.CSSProperties
  contentWrapperStyles?: React.CSSProperties
  removeDefaultWindowStyles?: boolean
  onButtonCloseClick?: (event?: any) => void
}

const Popup = (props: Props) => {
  const {
    children,
    startBelowTabs,
    isCommunicationHubVisible,
    windowStyles,
    contentWrapperStyles,
    removeDefaultWindowStyles,
    onButtonCloseClick
  } = props
  const { communicationHubWidth } = useApplicationSize()
  const hasCloseButton = Boolean(onButtonCloseClick)
  const extraStyles: React.CSSProperties = {}

  if (startBelowTabs) {
    extraStyles.top = 85
  }

  if (isCommunicationHubVisible) {
    extraStyles.right = communicationHubWidth
  }

  return (
    <Portal>
      <Overlay className={'Overlay'} style={Object.keys(extraStyles).length ? extraStyles : undefined}>
        <StyledWindow
          className={cn('StyledWindow', { default: !removeDefaultWindowStyles, hasCloseButton })}
          style={windowStyles}
        >
          <ContentWrapper className={'ContentWrapper'} style={contentWrapperStyles}>
            {hasCloseButton && <ButtonClose className={'mdi mdi-close'} onClick={onButtonCloseClick} />}
            {children}
          </ContentWrapper>
        </StyledWindow>
      </Overlay>
    </Portal>
  )
}

export const Window = (props: Props) => {
  return (
    <ApplicationSizeProvider>
      <Popup {...props} />
    </ApplicationSizeProvider>
  )
}

const Overlay = styled(WindowOverlay)`
  padding: 12px;
`

const StyledWindow = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding-top: 57px;

  &.hasCloseButton {
    /* padding-top: 57px; */
  }

  &.default {
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 4px 64px rgb(0 0 0 / 25%);
    overflow: hidden;
  }
`
const ContentWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`
const ButtonClose = styled.div`
  position: absolute;
  top: 13px;
  right: 8px;
  font-size: 20px;
  opacity: 0.3;
  padding: 5px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
