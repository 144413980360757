import * as React from 'react'
import { Input, TProps } from './simple'
import { transformThousandSeparators } from '../../../../../services/functions/transform/transformThousandSeparators'

export const ThousandSeparator = (props: TProps) => (
  <Input
    {...props}
    transformInputLabel={transformThousandSeparators}
    transformOnInputChange={value => value.replace(/\D/gi, '')}
    onChange={value => props.onChange(value ? parseInt(value, 10) : null)}
  />
)
