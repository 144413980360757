import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { AdditionalRowActions, ColorRowLine, TableRowWithActions } from '../../../../Grid/styles'
import { columns, columnsSettings, isAllowCopyRate } from '../settings'
import { RowActions } from './RowActions'
import { SellSideQuoteRateDTO } from '../../../../../../../../api/origin/qmp-service'
import { AnyRateDTO, ColumnSize, NewRateDTO, RateType, RateState } from '../../../../../interfaces'
import { RateWarning } from './RateWarning'
import { showWarningModalWindow } from '../../../../../../../../store/reducers/modalWindow/functions'
import { RateDTO } from '../../../../../../../../services/DTO/rate/interfaces'
import { generateExpireRateText } from '../../../../../../../../services/functions/generate/generateExpireRateText'
import { requestExpireBsqRate, requestExpireSsqRate } from '../../../../../epics'
import { useRate } from '../../../../../../../../hooks/useRate'
import { RowColumn } from './RowColumn'

type Props = {
  columnSize: ColumnSize
  readOnly: boolean
  checkMark?: JSX.Element
  hideActions?: boolean
  rateType: RateType
  newRate?: AnyRateDTO
  rateId?: string
  onDelete?: () => void
  onEdit?: () => void
  onSaveAnyway?: () => void
  duplicateRate?: (rate: RateDTO) => void
  copyRate: (rate: RateDTO) => void
  className?: string
}

export const Row = React.memo((props: Props) => {
  const { newRate, rateId } = props
  const storeRate = useRate({ id: rateId })
  const rate = storeRate || newRate

  if (!rate) {
    return null
  }

  return <RowDetails {...props} rate={rate} />
})

const RowDetails = (props: Props & { rate: AnyRateDTO }) => {
  const {
    columnSize,
    readOnly,
    checkMark,
    hideActions,
    rateType,
    rate,
    rateId,
    onDelete,
    onEdit,
    onSaveAnyway,
    duplicateRate,
    copyRate,
    className
  } = props
  const timer = React.useRef(null)
  const rowRef = React.useRef(null)
  const { state } = rate as NewRateDTO
  const [showWarning, setShowWarning] = React.useState(false)
  const hasWarning = state === RateState.warning

  const clearTimer = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
  }, [])

  const { onMouseLeave, onMouseEnter } = React.useMemo(
    () =>
      hasWarning
        ? {
            onMouseLeave() {
              clearTimer()
              timer.current = setTimeout(() => (rowRef && rowRef.current ? setShowWarning(false) : undefined), 500)
            },
            onMouseEnter() {
              clearTimer()
              setShowWarning(true)
            }
          }
        : {},
    [setShowWarning, clearTimer, hasWarning]
  )

  const canBeExpired = Boolean(oc(rate as any).status() === SellSideQuoteRateDTO.StatusEnum.NEW)

  const RenderActions = React.useCallback(() => {
    // @ts-ignore
    const onCopyToAnotherRateList = copyRate && isAllowCopyRate(rateType, rate.type) ? () => copyRate(rate) : undefined
    const onDuplicate = duplicateRate ? () => duplicateRate(rate as RateDTO) : undefined

    switch (state) {
      case RateState.new:
        return (
          <AdditionalRowActions>
            <span className={'mdi mdi-pencil'} onClick={onEdit} />
            <span className={'mdi mdi-minus-circle'} onClick={onDelete} />
          </AdditionalRowActions>
        )
      case RateState.edit:
        return null
      case RateState.warning:
        return null
      default:
        const enableActions = Boolean(
          (columnSize === ColumnSize.default ||
            (columnSize === ColumnSize.wideSSQ && rateType === RateType.ssq) ||
            (columnSize === ColumnSize.wideBSQ && rateType === RateType.bsq)) &&
            (onCopyToAnotherRateList || onDuplicate || canBeExpired)
        )

        return enableActions ? (
          <RowActions
            rateId={rate.id}
            rateNumber={rate.number}
            rateType={rateType}
            onCopyToAnotherRateList={onCopyToAnotherRateList}
            onDuplicate={onDuplicate}
            expire={
              canBeExpired
                ? () => {
                    showWarningModalWindow({
                      width: 360,
                      title: 'Expire Rate?',
                      content: generateExpireRateData(rate),
                      closeButton: true,
                      buttons: [
                        { label: 'No', outline: true },
                        {
                          label: 'Yes',
                          onClick: () => {
                            if (rateType === RateType.ssq) {
                              requestExpireSsqRate(rate.id)
                            } else if (rateType === RateType.bsq) {
                              requestExpireBsqRate(rate.id)
                            }
                          }
                        }
                      ]
                    })
                  }
                : undefined
            }
          />
        ) : null
    }
  }, [
    rateId,
    state,
    columnSize,
    copyRate,
    duplicateRate,
    canBeExpired,
    rate.id,
    rate.number,
    rate.type,
    rateType,
    onEdit,
    onDelete
  ])

  return (
    <>
      <TableRowWithActions
        id={[
          rate.number,
          rateType,
          ((): string => {
            switch (state) {
              case RateState.new:
                return 'row-new'
              case RateState.warning:
                return 'warning-row'
              default:
                return ''
            }
          })(),
          rate.id
        ]
          .filter(Boolean)
          .join('-')}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={rowRef}
        className={cn({ [className]: Boolean(className), [state]: Boolean(state) })}
      >
        {Boolean(state) && <ColorRowLine className={state} />}
        {Boolean(checkMark !== undefined) && (
          <div style={{ flex: '0 0 30px', paddingLeft: 10, alignSelf: 'center' }}>{checkMark}</div>
        )}
        {columns.map(
          column =>
            (!columnsSettings[column].visibility || columnsSettings[column].visibility.includes(columnSize)) && (
              <RowColumn key={column} rateType={rateType} column={column} rate={rate as any} />
            )
        )}
        {!readOnly && !hideActions ? <RenderActions /> : null}
      </TableRowWithActions>
      {hasWarning && showWarning && (
        <RateWarning
          id={`${rateType}-warning-box`}
          rowRef={rowRef}
          rate={rate as NewRateDTO}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onCancel={onDelete}
          onEdit={onEdit}
          onSaveAnyway={onSaveAnyway}
        />
      )}
    </>
  )
}

const generateExpireRateData = (rate: AnyRateDTO): JSX.Element => {
  const text = generateExpireRateText(rate)
  const blocks = text.split('\n')

  return (
    <div style={{ lineHeight: 1.3, whiteSpace: 'pre-wrap' }}>
      {blocks.map((item, index) => {
        const lineData = item.split(':')
        const title = lineData[0]
        const value = lineData[1]

        return (
          <React.Fragment key={index}>
            {title && (
              <span style={{ display: 'inline-block', width: 90, fontWeight: 500 }}>
                {title}
                {value ? ':' : undefined}
              </span>
            )}
            {value}
            <br />
          </React.Fragment>
        )
      })}
      <br />
      Are you sure this rate is expired?
    </div>
  )
}
