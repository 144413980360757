import { PowerUnitViewDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.powerUnit]: PowerUnitViewDTO[]
}

export const parsePowerUnits = async (powerUnits: PowerUnitViewDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.powerUnit]: []
  }

  try {
    powerUnits.forEach(powerUnit => {
      result[EntityType.powerUnit].push(powerUnit)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
