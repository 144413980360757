import { UserDTO } from '../../../../../api/origin/user-service'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.user]: UserDTO[]
}

export const parseUsers = async (users: UserDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.user]: []
  }

  try {
    users.forEach(user => {
      result[EntityType.user].push(user)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
