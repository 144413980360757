import * as React from 'react'
import { dateService } from '../services/timeService'

type Props = {
  date: string | Date
  date2?: string | Date
  delay?: number
}

export const useDateDuration = (props: Props): string => {
  const { date, date2, delay } = props
  const [duration, setDuration] = React.useState('')

  React.useEffect(() => {
    let interval: any

    if (date) {
      setDuration(dateService.makeDurationLabel(date, date2))
      interval = setInterval(() => setDuration(dateService.makeDurationLabel(date, date2)), delay || 60 * 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [date, date2])

  return duration
}
