import * as React from 'react'
import { Column, ColumnRow, Container, GridColumn } from '../../../../../UI/GridSystem'
import { Title } from './styledComponents'
import { InputField, Input } from '../../../../../UI/DataFields/Input'
import { schemaScac } from '../../../../../../services/yupScheme'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemSteamShipLine } from '../../../../../../hooks/useGridItemSteamShipLine'

export const SteamshipLineInfo = () => {
  const { businessPartner, steamShipLine } = useGridItemSteamShipLine()
  const { modifyParentObjectField, modifyListItems } = useExpandedItem()
  return (
    <Column columns={15} isGrid={true} margin={{ right: 40 }}>
      <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
        Steamship Line Info
      </Title>
      <Container rows={12} margin={{ bottom: 20 }}>
        <Input
          title={'Name'}
          required={true}
          value={businessPartner.legalName}
          onChange={(legalName: string) => {
            modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, legalName }] })
          }}
        />
      </Container>

      <Container rows={12} margin={{ bottom: 20 }}>
        <Input
          title={'Reference #'}
          maxLength={64}
          value={steamShipLine.referenceNumber}
          onChange={modifyParentObjectField('referenceNumber')}
        />
      </Container>
      <Container>
        <GridColumn columns={8} isGrid={true} padding={{ right: 10 }}>
          <ColumnRow margin={{ bottom: 25 }}>
            <InputField.ThousandSeparator
              title={'Free Detention Allowance'}
              value={steamShipLine.freeDetentionAllowance}
              onChange={modifyParentObjectField('freeDetentionAllowance')}
            />
          </ColumnRow>
        </GridColumn>
        <GridColumn columns={8} isGrid={true}>
          <ColumnRow margin={{ bottom: 25 }}>
            <InputField.TextMask
              title={'SCAC'}
              maxLength={4}
              required={true}
              value={steamShipLine.scac}
              valid={schemaScac.isValidSync(steamShipLine.scac)}
              transformOnInputChange={value => (value ? value.toUpperCase() : value)}
              onChange={modifyParentObjectField('scac')}
              textMask={[/(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/]}
            />
          </ColumnRow>
        </GridColumn>
      </Container>
    </Column>
  )
}
