import { TransportationActivityViewDTO } from '../../../api/api'

export const isActualActivity = (activity: TransportationActivityViewDTO): boolean => {
  return activity && isActualActivityStatus(activity.status)
}

export const isActualActivityStatus = (activityStatus: TransportationActivityViewDTO.StatusEnum): boolean => {
  return (
    activityStatus !== TransportationActivityViewDTO.StatusEnum.COMPLETED &&
    activityStatus !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
  )
}
