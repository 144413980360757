import styled, { withProps } from '../../../styles/styledComponents'
import theme, { IStyledComponentsProps } from '../../../styles/theme'

export const Value = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  width: 100%;
  max-height: 100%;
  line-height: ${props => props.height}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  
  span {
    opacity: 0.3;
    user-select: none;
  }
  
  p {
    display: inline-block;
  }
  
  :invalid {
    background: #f66;  
  }
`

export const Clear = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 14px;
  cursor: pointer;
`

export const SInput = withProps<IStyledComponentsProps, HTMLInputElement>(styled.input)`
  display: block;
  width: 100%;
  border: none;
  background: none;
  padding: 0 10px;
  height: ${props => props.height}px;
  line-height: ${props => props.height}px;
  position: absolute;
  left: 0;
  top: 0;
    
  :invalid {
    background: #f66;  
  }
`
export const TextArea = withProps<IStyledComponentsProps, HTMLTextAreaElement>(styled.textarea)`
  display: block;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  background: none;
  padding: 9px 10px 10px 10px;
  line-height: 1.2;
  position: absolute;
  left: 0;
  top: 0;
    
  :invalid {
    background: #f66;  
  }
`

export const StyledInput = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${props => props.height}px;
  width: ${props => (props.width ? props.width + 'px' : '100%')};
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: 0 10px;
  color: #333;
  
  &.highlight,
  &.invalid {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }
  
  .reactInputMask {
    display: block;
    height: 35px;
    line-height: 35px;
  }
  
  &.clearButton {
    justify-content: flex-end;
  
    >*:not(.mdi-close) {
      padding-right: 20px;
    }
        
    .mdi-close {
      margin-right: -5px;
    }
    
    &.isGridColumnTitle {
      .mdi-close {
        margin-right: -10px;
      }
    }
  }
  
  &.isHours ${Value} {
    p:after {
      content: 'hours';
      margin-left: 5px;
      opacity: 0.3;      
    }
  }
  
  input::placeholder,
  textarea::placeholder{
    color: rgba(51, 51, 51, 0.3);
  }
  
  &.darkBackground, &.disabled{
    background: white;
  }
  
  
  &.checkbox {
   ${Value}, ${SInput}{
    left: 30px;
    width: calc(100% - 30px);
   }
  }
  
  &.textArea {
    height: 100%;
  
    ${Value}{
      line-height: 1.2;
      padding: 9px 10px 10px 10px;
      white-space: pre-wrap;
      overflow: auto;
      bottom: 0;
    }
  }
  
  &:not(:hover) {
    ${Clear} {
      opacity: 0;
    }
  }
`

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
`
