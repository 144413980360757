import { oc } from 'ts-optchain'
import { TransportationActivityViewDTO } from '../../../api/api'
import { isActualActivity } from '../../../services/functions/test/isActualActivity'
import { isBobtailGotoActivity } from '../../../services/functions/test/isBobtailGotoActivity'
import { IMoveDTO, IMoveItemDTO, MoveStatus } from '../../../store/reducers/communicationHub/interfaces'
import { getFirstActualMoveItem } from './getFirstActualMoveItem'

export const groupDoubleMoves = (moves: IMoveDTO[]): IMoveDTO[][] => {
  const omitGroupedMoveIds: string[] = []

  return moves.reduce((acc, currMove) => {
    if (!omitGroupedMoveIds.includes(currMove.id)) {
      const doubleMoves = [currMove]

      while (true) {
        const lastMoveInGroup = doubleMoves[doubleMoves.length - 1]

        if (lastMoveInGroup.nextDoubleMoveId) {
          const nextToMove = moves.find(item => item.id === lastMoveInGroup.nextDoubleMoveId)

          if (nextToMove) {
            omitGroupedMoveIds.push(lastMoveInGroup.nextDoubleMoveId)
            doubleMoves.push(nextToMove)
          } else {
            break
          }
        } else {
          break
        }
      }

      acc.push(doubleMoves)
    }

    return acc
  }, [])
}

export const isDoubleMoveAvailableToConnect = (
  group1: IMoveDTO[],
  group2: IMoveDTO[],
  transportationActivityMapping: Record<string, TransportationActivityViewDTO>
): {
  doubleMove: boolean
  bobtail: boolean
} => {
  const result = {
    doubleMove: false,
    bobtail: false
  }

  if (!(group1 && group2 && group1.length && group2.length)) {
    return result
  }

  const lastMoveOfGroup1 = group1[group1.length - 1]
  const firstMoveOfGroup2 = group2[0]
  const conflictedStatuses = [MoveStatus.INPROCESS]
  const nowAllowedStatuses = [MoveStatus.UNSUCCESSFUL, MoveStatus.COMPLETED]
  const isMoveItemBobtail = ({ gotoActivityId }: IMoveItemDTO): boolean => {
    return (
      gotoActivityId &&
      transportationActivityMapping[gotoActivityId] &&
      oc(transportationActivityMapping[gotoActivityId]).comboType() ===
        TransportationActivityViewDTO.ComboTypeEnum.EMPTY
    )
  }
  const isLastActivityOfGroup1Bobtail = isMoveItemBobtail(lastMoveOfGroup1.items[lastMoveOfGroup1.items.length - 1])

  result.doubleMove =
    lastMoveOfGroup1.validForNextDoubleMove === true &&
    firstMoveOfGroup2.validForPrevDoubleMove === true &&
    !nowAllowedStatuses.includes(lastMoveOfGroup1.status) &&
    !nowAllowedStatuses.includes(firstMoveOfGroup2.status) &&
    !(conflictedStatuses.includes(lastMoveOfGroup1.status) && conflictedStatuses.includes(firstMoveOfGroup2.status)) &&
    lastMoveOfGroup1.dispatchDeliveryOrderId !== firstMoveOfGroup2.dispatchDeliveryOrderId &&
    (!conflictedStatuses.includes(lastMoveOfGroup1.status) || !firstMoveOfGroup2.startDate) &&
    !isMoveItemBobtail(firstMoveOfGroup2.items[0]) &&
    !isLastActivityOfGroup1Bobtail &&
    [group1, group2].every(group => group.every(move => move.status !== MoveStatus.REFUSED))

  if (isLastActivityOfGroup1Bobtail) {
    let activityAfterBobtail = undefined
    const firstActualMoveItemOfGroup2 = getFirstActualMoveItem({
      move: firstMoveOfGroup2,
      transportationActivityMapping,
      withoutConnections: true
    })
    activityAfterBobtail =
      firstActualMoveItemOfGroup2 && transportationActivityMapping[firstActualMoveItemOfGroup2.businessActivityId]

    result.bobtail =
      lastMoveOfGroup1.validForNextDoubleMove === true &&
      firstMoveOfGroup2.validForPrevDoubleMove === true &&
      lastMoveOfGroup1.dispatchDeliveryOrderId !== firstMoveOfGroup2.dispatchDeliveryOrderId &&
      Boolean(activityAfterBobtail && activityAfterBobtail.vendorId && activityAfterBobtail.destinationId) &&
      !firstMoveOfGroup2.startDate
  }

  return result
}

export const isDoubleMoveAvailableToDisconnect = (move1: IMoveDTO, move2: IMoveDTO): boolean => {
  if (!(move1 && move2)) {
    return false
  }

  const conflictedStatuses = [MoveStatus.INPROCESS]

  return !(conflictedStatuses.includes(move1.status) && conflictedStatuses.includes(move2.status))
}
