import styled from 'styled-components'
import { GridColumn } from '../../../../../UI/GridSystem'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'

export const MiddleColumn = styled.div`
  width: 0;
  flex-grow: 1;
  max-height: 550px;
  padding: 10px 20px 0 20px;

  .ready-for-dispatch-required {
    label {
      display: flex;

      &:after {
        content: '*';
        color: ${ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]};
        font-size: 30px;
        transform: translate(3px, 3px);
      }
    }
  }
`
export const StyledLeft = styled.div`
  width: 21%;
  background: linear-gradient(#e3e6f1 50%, rgba(255, 255, 255, 0) 0%) repeat-y right/1px 12px;
`
export const StyledRight = styled.div`
  width: 21%;
  background: linear-gradient(#e3e6f1 50%, rgba(255, 255, 255, 0) 0%) repeat-y left/1px 12px;
`
export const Top = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background: linear-gradient(to right, #e3e6f1 50%, rgba(255, 255, 255, 0) 0%) repeat-x bottom/12px 1px;
  padding: 0 16px;
`
export const DOIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  background: rgba(124, 127, 207, 0.1);
  text-transform: uppercase;
  font-weight: 500;
  color: #445366;
  font-size: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  user-select: none;
  cursor: default;
`
