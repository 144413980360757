import * as React from 'react'
import { oc } from 'ts-optchain'
import { ButtonWithIcon } from '../styledComponents'
import { Popover } from '../../../UI/Dropdowns/Popover'
import { logout } from '../../../../services/authService/actions/authServiceActions'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { DebugMode } from './DebugMode'
import { getStore } from '../../../../store/configureStore'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectAuthUser } from '../../../../store/select/authUserSelect'

type Props = {}

export const Account = React.memo((props: Props) => {
  const user = useAppSelector(selectAuthUser)
  const [dropdown, setDropdown] = React.useState(false)

  // React.useEffect(() => {
  //   showModalWindow({
  //     title: 'Debug Mode',
  //     middleSectionContent: <DebugMode />
  //   })
  // }, [])

  return (
    <Popover
      side={'right'}
      isOpen={dropdown}
      byHover={true}
      lifetime={1}
      changeState={setDropdown}
      button={<ButtonWithIcon className={'mdi mdi-account-circle'} />}
    >
      <div>User: {oc(user).name('')}</div>
      <div
        onClick={() => {
          showModalWindow({
            title: 'Debug Mode',
            content: <DebugMode />
          })
        }}
      >
        Debug Mode
      </div>
      <div onClick={() => getStore().dispatch(logout())}>Logout</div>
    </Popover>
  )
})
