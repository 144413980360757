import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { BuySideQuoteDTO } from '../../../../../api/api'
import { GridItemSpinner } from '../../../Spinner/Spinner'
import { RateDTO } from '../../../../../services/DTO/rate/interfaces'
import { requestBuySideQuoteRates } from '../../../../../services/DTO/rate/epics'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { suitableLoadTypes } from '../../../../common/QMP/views/Tabs/Rate/functions'
import { RateType } from '../../../../common/QMP/interfaces'
import { BuySideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { buySideQuoteTypeGroups } from '../../../../../constants/buySideQuoteTypeGroups'
import { SsqBsqWidgetMainRates } from './SsqBsqWidgetMainRates'
import { SsqBsqWidgetAdditionalSurcharges } from './SsqBsqWidgetAdditionalSurcharges'
import { SsqBsqWidgetApplicableRates } from './SsqBsqWidgetApplicableRates'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'

type Props = {
  buySideQuote: BuySideQuoteDTO
  activityVendorName: string
}

export const BsqWidgetRowDetails = React.memo((props: Props) => {
  const { buySideQuote, activityVendorName } = props
  const { requestFilters, unsuitableSurchargeTypes, buySideQuotes, modifyQuote, onOpenRatesClick } = useSsqBsqWidget()
  const [fetching, setFetching] = React.useState(false)
  const [applicableRates, setApplicableRates] = React.useState<RateDTO[]>([])

  React.useEffect(() => {
    setFetching(true)
    const surchargeIds: string[] = oc(buySideQuote)
      .surcharges([])
      .map(s => s.rateId)
    const calcLoadType = loadTypeFromBSQType(buySideQuote.type)
    const loadType = calcLoadType ? [calcLoadType] : suitableLoadTypes(RateType.bsq, requestFilters.loadType)
    const vendorId = buySideQuote.vendorId || '%%'
    const omitRateTypes = [...unsuitableSurchargeTypes, ...omitExtraSurchargeTypes(buySideQuote.type, buySideQuotes)]

    Promise.all([
      requestBuySideQuoteRates({
        filters: {
          ...requestFilters,
          loadType,
          vendorId,
          quoteDate: buySideQuote.date
        },
        rateTypes:
          buySideQuote.type === BuySideQuoteDTO.TypeEnum.BOBTAIL ? [BuySideQuoteRateDTO.TypeEnum.BOBTAIL] : undefined,
        omitRateTypes
      }),
      surchargeIds.length ? requestBuySideQuoteRates({ filters: { id: surchargeIds } }) : undefined
    ])
      .then(([rates]) => {
        if (rates.length) {
          setApplicableRates(rates)
        }
      })
      .finally(() => setFetching(false))
  }, [])

  const BsqActions = modifyQuote(EntityType.buySideQuote, buySideQuote)

  return (
    <>
      {fetching && <GridItemSpinner />}
      <SsqBsqWidgetMainRates surcharges={buySideQuote.surcharges} />
      <SsqBsqWidgetAdditionalSurcharges
        hideActivityColumn={true}
        miscSurcharges={buySideQuote.miscSurcharges}
        addMiscSurcharge={BsqActions.addMiscSurcharge}
        deleteMiscSurchargeId={BsqActions.deleteMiscSurchargeId}
        modifyMiscSurcharge={BsqActions.modifyMiscSurcharge}
      />
      <SsqBsqWidgetApplicableRates
        onOpenRatesClick={() => {
          onOpenRatesClick(oc(buySideQuote).date())(
            buySideQuote.vendorId ? { label: activityVendorName, value: buySideQuote.vendorId } : undefined
          )
        }}
        hideActivityColumn={true}
        surcharges={buySideQuote.surcharges}
        applicableRates={applicableRates}
        onlyOneSurchargeForRate={true}
        addSurcharge={BsqActions.addSurcharge}
        deleteSurchargeId={BsqActions.deleteSurchargeId}
        modifySurcharge={BsqActions.modifySurcharge}
      />
    </>
  )
})

const loadTypeFromBSQType = (bsqType: BuySideQuoteDTO.TypeEnum): BuySideQuoteRateDTO.LoadTypeEnum => {
  if (bsqType === BuySideQuoteDTO.TypeEnum.DROPFULL || bsqType === BuySideQuoteDTO.TypeEnum.DROPEMPTY) {
    return BuySideQuoteRateDTO.LoadTypeEnum.DROP
  } else if (bsqType === BuySideQuoteDTO.TypeEnum.PICKFULL || bsqType === BuySideQuoteDTO.TypeEnum.PICKEMPTY) {
    return BuySideQuoteRateDTO.LoadTypeEnum.PICK
  } else if (bsqType === BuySideQuoteDTO.TypeEnum.LIVELOAD || bsqType === BuySideQuoteDTO.TypeEnum.LIVEUNLOAD) {
    return BuySideQuoteRateDTO.LoadTypeEnum.LIVELOAD
  }

  return null
}

const omitExtraSurchargeTypes = (
  bsqType: BuySideQuoteDTO.TypeEnum,
  bsqListByType: BuySideQuoteDTO[]
): BuySideQuoteRateDTO.TypeEnum[] => {
  const omitTypes = []
  const isShuttleInList = bsqListByType.some(bsq => buySideQuoteTypeGroups.shuttle.includes(bsq.type))

  if (!(buySideQuoteTypeGroups.delivery.includes(bsqType) && isShuttleInList)) {
    omitTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER, BuySideQuoteRateDTO.TypeEnum.DEDUCTION)
  }

  if (!buySideQuoteTypeGroups.shuttle.includes(bsqType)) {
    omitTypes.push(
      BuySideQuoteRateDTO.TypeEnum.SHUTTLE,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ
    )
  }

  if (buySideQuoteTypeGroups.live.includes(bsqType)) {
    omitTypes.push(BuySideQuoteRateDTO.TypeEnum.DROPANDPICKUP)
  }

  return omitTypes.filter(Boolean)
}
