import * as React from 'react'
import { MessageDTO } from '../../../api/origin/communication-hub-service'

export const SMSStatusIconMapping: Record<MessageDTO.SmsStatusEnum, JSX.Element> = {
  [MessageDTO.SmsStatusEnum.SENT]: (
    // tslint:disable:max-line-length
    <svg width="38" height="14" viewBox="0 0 38 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="24 / basic / check-mark">
        <rect id="Rectangle 836" width="38" height="14" rx="3" fill="#6973F6" />
        <g id="24 / basic / check-mark_2">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.6626 8.33736L33.0835 2.9165L33.9085 3.74146L27.6626 9.98727L24.3335 6.65813L25.1585 5.83317L27.6626 8.33736Z"
            fill="white"
          />
        </g>
        <g id="SMS">
          <path
            d="M5.93699 11C5.2993 11 4.81704 10.8333 4.49023 10.5C4.16341 10.1593 4 9.67407 4 9.04444V8.6H5.2435V9.13333C5.2435 9.63704 5.47068 9.88889 5.92504 9.88889C6.14823 9.88889 6.31562 9.82963 6.42722 9.71111C6.54679 9.58519 6.60657 9.38519 6.60657 9.11111C6.60657 8.78519 6.52686 8.5 6.36744 8.25556C6.20801 8.0037 5.91308 7.7037 5.48264 7.35556C4.9406 6.91111 4.56197 6.51111 4.34675 6.15556C4.13152 5.79259 4.02391 5.38519 4.02391 4.93333C4.02391 4.31852 4.19131 3.84444 4.5261 3.51111C4.86089 3.17037 5.34713 3 5.98482 3C6.61454 3 7.08883 3.17037 7.40767 3.51111C7.73449 3.84444 7.8979 4.32593 7.8979 4.95556V5.27778H6.6544V4.87778C6.6544 4.61111 6.5986 4.41852 6.487 4.3C6.37541 4.17407 6.212 4.11111 5.99678 4.11111C5.55836 4.11111 5.33916 4.35926 5.33916 4.85556C5.33916 5.13704 5.41887 5.4 5.57829 5.64444C5.74569 5.88889 6.0446 6.18519 6.47505 6.53333C7.02506 6.97778 7.40369 7.38148 7.61094 7.74444C7.81819 8.10741 7.92181 8.53333 7.92181 9.02222C7.92181 9.65926 7.75043 10.1481 7.40767 10.4889C7.07289 10.8296 6.58266 11 5.93699 11Z"
            fill="white"
          />
          <path
            d="M9.27377 3.11111H11.151L11.988 8.67778H12.0119L12.8488 3.11111H14.726V10.8889H13.4825V5H13.4586L12.5021 10.8889H11.4021L10.4455 5H10.4216V10.8889H9.27377V3.11111Z"
            fill="white"
          />
          <path
            d="M18.0152 11C17.3775 11 16.8952 10.8333 16.5684 10.5C16.2416 10.1593 16.0782 9.67407 16.0782 9.04444V8.6H17.3217V9.13333C17.3217 9.63704 17.5489 9.88889 18.0032 9.88889C18.2264 9.88889 18.3938 9.82963 18.5054 9.71111C18.625 9.58519 18.6848 9.38519 18.6848 9.11111C18.6848 8.78519 18.605 8.5 18.4456 8.25556C18.2862 8.0037 17.9913 7.7037 17.5608 7.35556C17.0188 6.91111 16.6402 6.51111 16.4249 6.15556C16.2097 5.79259 16.1021 5.38519 16.1021 4.93333C16.1021 4.31852 16.2695 3.84444 16.6043 3.51111C16.9391 3.17037 17.4253 3 18.063 3C18.6927 3 19.167 3.17037 19.4859 3.51111C19.8127 3.84444 19.9761 4.32593 19.9761 4.95556V5.27778H18.7326V4.87778C18.7326 4.61111 18.6768 4.41852 18.5652 4.3C18.4536 4.17407 18.2902 4.11111 18.075 4.11111C17.6365 4.11111 17.4173 4.35926 17.4173 4.85556C17.4173 5.13704 17.4971 5.4 17.6565 5.64444C17.8239 5.88889 18.1228 6.18519 18.5532 6.53333C19.1032 6.97778 19.4819 7.38148 19.6891 7.74444C19.8964 8.10741 20 8.53333 20 9.02222C20 9.65926 19.8286 10.1481 19.4859 10.4889C19.1511 10.8296 18.6608 11 18.0152 11Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  ),
  [MessageDTO.SmsStatusEnum.DELIVERED]: (
    <svg width="38" height="14" viewBox="0 0 38 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="24 / basic / check-mark">
        <rect id="Rectangle 836" width="38" height="14" rx="3" fill="#27AE60" />
        <g id="24 / basic / check-marks">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.5279 3.5L25.8185 8.28327L23.9568 6.38747L23.1514 7.20418L25.8185 9.91667L31.3334 4.3167L30.5279 3.5ZM28.2222 8.80502L29.3185 9.91667L34.8334 4.3167L34.0279 3.5L29.3185 8.28327L29.027 7.98773L28.2222 8.80502Z"
            fill="white"
          />
        </g>
        <g id="SMS">
          <path
            d="M5.93699 11C5.2993 11 4.81704 10.8333 4.49023 10.5C4.16341 10.1593 4 9.67407 4 9.04444V8.6H5.2435V9.13333C5.2435 9.63704 5.47068 9.88889 5.92504 9.88889C6.14823 9.88889 6.31562 9.82963 6.42722 9.71111C6.54679 9.58519 6.60657 9.38519 6.60657 9.11111C6.60657 8.78519 6.52686 8.5 6.36744 8.25556C6.20801 8.0037 5.91308 7.7037 5.48264 7.35556C4.9406 6.91111 4.56197 6.51111 4.34675 6.15556C4.13152 5.79259 4.02391 5.38519 4.02391 4.93333C4.02391 4.31852 4.19131 3.84444 4.5261 3.51111C4.86089 3.17037 5.34713 3 5.98482 3C6.61454 3 7.08883 3.17037 7.40767 3.51111C7.73449 3.84444 7.8979 4.32593 7.8979 4.95556V5.27778H6.6544V4.87778C6.6544 4.61111 6.5986 4.41852 6.487 4.3C6.37541 4.17407 6.212 4.11111 5.99678 4.11111C5.55836 4.11111 5.33916 4.35926 5.33916 4.85556C5.33916 5.13704 5.41887 5.4 5.57829 5.64444C5.74569 5.88889 6.0446 6.18519 6.47505 6.53333C7.02506 6.97778 7.40369 7.38148 7.61094 7.74444C7.81819 8.10741 7.92181 8.53333 7.92181 9.02222C7.92181 9.65926 7.75043 10.1481 7.40767 10.4889C7.07289 10.8296 6.58266 11 5.93699 11Z"
            fill="white"
          />
          <path
            d="M9.27377 3.11111H11.151L11.988 8.67778H12.0119L12.8488 3.11111H14.726V10.8889H13.4825V5H13.4586L12.5021 10.8889H11.4021L10.4455 5H10.4216V10.8889H9.27377V3.11111Z"
            fill="white"
          />
          <path
            d="M18.0152 11C17.3775 11 16.8952 10.8333 16.5684 10.5C16.2416 10.1593 16.0782 9.67407 16.0782 9.04444V8.6H17.3217V9.13333C17.3217 9.63704 17.5489 9.88889 18.0032 9.88889C18.2264 9.88889 18.3938 9.82963 18.5054 9.71111C18.625 9.58519 18.6848 9.38519 18.6848 9.11111C18.6848 8.78519 18.605 8.5 18.4456 8.25556C18.2862 8.0037 17.9913 7.7037 17.5608 7.35556C17.0188 6.91111 16.6402 6.51111 16.4249 6.15556C16.2097 5.79259 16.1021 5.38519 16.1021 4.93333C16.1021 4.31852 16.2695 3.84444 16.6043 3.51111C16.9391 3.17037 17.4253 3 18.063 3C18.6927 3 19.167 3.17037 19.4859 3.51111C19.8127 3.84444 19.9761 4.32593 19.9761 4.95556V5.27778H18.7326V4.87778C18.7326 4.61111 18.6768 4.41852 18.5652 4.3C18.4536 4.17407 18.2902 4.11111 18.075 4.11111C17.6365 4.11111 17.4173 4.35926 17.4173 4.85556C17.4173 5.13704 17.4971 5.4 17.6565 5.64444C17.8239 5.88889 18.1228 6.18519 18.5532 6.53333C19.1032 6.97778 19.4819 7.38148 19.6891 7.74444C19.8964 8.10741 20 8.53333 20 9.02222C20 9.65926 19.8286 10.1481 19.4859 10.4889C19.1511 10.8296 18.6608 11 18.0152 11Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  ),
  [MessageDTO.SmsStatusEnum.UNDELIVERED]: (
    <svg width="38" height="14" viewBox="0 0 38 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="24 / basic / check-mark">
        <rect id="Rectangle 836" width="38" height="14" rx="3" fill="#FF5E5E" />
        <g id="16 / info">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 7C34 4.23858 31.7614 2 29 2C26.2386 2 24 4.23858 24 7C24 9.76142 26.2386 12 29 12C31.7614 12 34 9.76142 34 7ZM25.25 7C25.25 4.92893 26.9289 3.25 29 3.25C31.0711 3.25 32.75 4.92893 32.75 7C32.75 9.07107 31.0711 10.75 29 10.75C26.9289 10.75 25.25 9.07107 25.25 7ZM28.3727 5.12603H29.6232V7.62603H28.3727V5.12603ZM28.3746 8.87603C28.3746 8.53086 28.6545 8.25103 28.9998 8.25103C29.3451 8.25103 29.625 8.53086 29.625 8.87603C29.625 9.22121 29.3451 9.50103 28.9998 9.50103C28.6545 9.50103 28.3746 9.22121 28.3746 8.87603Z"
            fill="white"
          />
        </g>
        <g id="SMS">
          <path
            d="M5.93699 11C5.2993 11 4.81704 10.8333 4.49023 10.5C4.16341 10.1593 4 9.67407 4 9.04444V8.6H5.2435V9.13333C5.2435 9.63704 5.47068 9.88889 5.92504 9.88889C6.14823 9.88889 6.31562 9.82963 6.42722 9.71111C6.54679 9.58519 6.60657 9.38519 6.60657 9.11111C6.60657 8.78519 6.52686 8.5 6.36744 8.25556C6.20801 8.0037 5.91308 7.7037 5.48264 7.35556C4.9406 6.91111 4.56197 6.51111 4.34675 6.15556C4.13152 5.79259 4.02391 5.38519 4.02391 4.93333C4.02391 4.31852 4.19131 3.84444 4.5261 3.51111C4.86089 3.17037 5.34713 3 5.98482 3C6.61454 3 7.08883 3.17037 7.40767 3.51111C7.73449 3.84444 7.8979 4.32593 7.8979 4.95556V5.27778H6.6544V4.87778C6.6544 4.61111 6.5986 4.41852 6.487 4.3C6.37541 4.17407 6.212 4.11111 5.99678 4.11111C5.55836 4.11111 5.33916 4.35926 5.33916 4.85556C5.33916 5.13704 5.41887 5.4 5.57829 5.64444C5.74569 5.88889 6.0446 6.18519 6.47505 6.53333C7.02506 6.97778 7.40369 7.38148 7.61094 7.74444C7.81819 8.10741 7.92181 8.53333 7.92181 9.02222C7.92181 9.65926 7.75043 10.1481 7.40767 10.4889C7.07289 10.8296 6.58266 11 5.93699 11Z"
            fill="white"
          />
          <path
            d="M9.27377 3.11111H11.151L11.988 8.67778H12.0119L12.8488 3.11111H14.726V10.8889H13.4825V5H13.4586L12.5021 10.8889H11.4021L10.4455 5H10.4216V10.8889H9.27377V3.11111Z"
            fill="white"
          />
          <path
            d="M18.0152 11C17.3775 11 16.8952 10.8333 16.5684 10.5C16.2416 10.1593 16.0782 9.67407 16.0782 9.04444V8.6H17.3217V9.13333C17.3217 9.63704 17.5489 9.88889 18.0032 9.88889C18.2264 9.88889 18.3938 9.82963 18.5054 9.71111C18.625 9.58519 18.6848 9.38519 18.6848 9.11111C18.6848 8.78519 18.605 8.5 18.4456 8.25556C18.2862 8.0037 17.9913 7.7037 17.5608 7.35556C17.0188 6.91111 16.6402 6.51111 16.4249 6.15556C16.2097 5.79259 16.1021 5.38519 16.1021 4.93333C16.1021 4.31852 16.2695 3.84444 16.6043 3.51111C16.9391 3.17037 17.4253 3 18.063 3C18.6927 3 19.167 3.17037 19.4859 3.51111C19.8127 3.84444 19.9761 4.32593 19.9761 4.95556V5.27778H18.7326V4.87778C18.7326 4.61111 18.6768 4.41852 18.5652 4.3C18.4536 4.17407 18.2902 4.11111 18.075 4.11111C17.6365 4.11111 17.4173 4.35926 17.4173 4.85556C17.4173 5.13704 17.4971 5.4 17.6565 5.64444C17.8239 5.88889 18.1228 6.18519 18.5532 6.53333C19.1032 6.97778 19.4819 7.38148 19.6891 7.74444C19.8964 8.10741 20 8.53333 20 9.02222C20 9.65926 19.8286 10.1481 19.4859 10.4889C19.1511 10.8296 18.6608 11 18.0152 11Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  ),
  [MessageDTO.SmsStatusEnum.UNSENT]: (
    <svg width="38" height="14" viewBox="0 0 38 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="24 / basic / check-mark">
        <rect id="Rectangle 836" width="38" height="14" rx="3" fill="#FF5E5E" />
        <g id="16 / info">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 7C34 4.23858 31.7614 2 29 2C26.2386 2 24 4.23858 24 7C24 9.76142 26.2386 12 29 12C31.7614 12 34 9.76142 34 7ZM25.25 7C25.25 4.92893 26.9289 3.25 29 3.25C31.0711 3.25 32.75 4.92893 32.75 7C32.75 9.07107 31.0711 10.75 29 10.75C26.9289 10.75 25.25 9.07107 25.25 7ZM28.3727 5.12603H29.6232V7.62603H28.3727V5.12603ZM28.3746 8.87603C28.3746 8.53086 28.6545 8.25103 28.9998 8.25103C29.3451 8.25103 29.625 8.53086 29.625 8.87603C29.625 9.22121 29.3451 9.50103 28.9998 9.50103C28.6545 9.50103 28.3746 9.22121 28.3746 8.87603Z"
            fill="white"
          />
        </g>
        <g id="SMS">
          <path
            d="M5.93699 11C5.2993 11 4.81704 10.8333 4.49023 10.5C4.16341 10.1593 4 9.67407 4 9.04444V8.6H5.2435V9.13333C5.2435 9.63704 5.47068 9.88889 5.92504 9.88889C6.14823 9.88889 6.31562 9.82963 6.42722 9.71111C6.54679 9.58519 6.60657 9.38519 6.60657 9.11111C6.60657 8.78519 6.52686 8.5 6.36744 8.25556C6.20801 8.0037 5.91308 7.7037 5.48264 7.35556C4.9406 6.91111 4.56197 6.51111 4.34675 6.15556C4.13152 5.79259 4.02391 5.38519 4.02391 4.93333C4.02391 4.31852 4.19131 3.84444 4.5261 3.51111C4.86089 3.17037 5.34713 3 5.98482 3C6.61454 3 7.08883 3.17037 7.40767 3.51111C7.73449 3.84444 7.8979 4.32593 7.8979 4.95556V5.27778H6.6544V4.87778C6.6544 4.61111 6.5986 4.41852 6.487 4.3C6.37541 4.17407 6.212 4.11111 5.99678 4.11111C5.55836 4.11111 5.33916 4.35926 5.33916 4.85556C5.33916 5.13704 5.41887 5.4 5.57829 5.64444C5.74569 5.88889 6.0446 6.18519 6.47505 6.53333C7.02506 6.97778 7.40369 7.38148 7.61094 7.74444C7.81819 8.10741 7.92181 8.53333 7.92181 9.02222C7.92181 9.65926 7.75043 10.1481 7.40767 10.4889C7.07289 10.8296 6.58266 11 5.93699 11Z"
            fill="white"
          />
          <path
            d="M9.27377 3.11111H11.151L11.988 8.67778H12.0119L12.8488 3.11111H14.726V10.8889H13.4825V5H13.4586L12.5021 10.8889H11.4021L10.4455 5H10.4216V10.8889H9.27377V3.11111Z"
            fill="white"
          />
          <path
            d="M18.0152 11C17.3775 11 16.8952 10.8333 16.5684 10.5C16.2416 10.1593 16.0782 9.67407 16.0782 9.04444V8.6H17.3217V9.13333C17.3217 9.63704 17.5489 9.88889 18.0032 9.88889C18.2264 9.88889 18.3938 9.82963 18.5054 9.71111C18.625 9.58519 18.6848 9.38519 18.6848 9.11111C18.6848 8.78519 18.605 8.5 18.4456 8.25556C18.2862 8.0037 17.9913 7.7037 17.5608 7.35556C17.0188 6.91111 16.6402 6.51111 16.4249 6.15556C16.2097 5.79259 16.1021 5.38519 16.1021 4.93333C16.1021 4.31852 16.2695 3.84444 16.6043 3.51111C16.9391 3.17037 17.4253 3 18.063 3C18.6927 3 19.167 3.17037 19.4859 3.51111C19.8127 3.84444 19.9761 4.32593 19.9761 4.95556V5.27778H18.7326V4.87778C18.7326 4.61111 18.6768 4.41852 18.5652 4.3C18.4536 4.17407 18.2902 4.11111 18.075 4.11111C17.6365 4.11111 17.4173 4.35926 17.4173 4.85556C17.4173 5.13704 17.4971 5.4 17.6565 5.64444C17.8239 5.88889 18.1228 6.18519 18.5532 6.53333C19.1032 6.97778 19.4819 7.38148 19.6891 7.74444C19.8964 8.10741 20 8.53333 20 9.02222C20 9.65926 19.8286 10.1481 19.4859 10.4889C19.1511 10.8296 18.6608 11 18.0152 11Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}
