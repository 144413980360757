import * as R from 'remeda'
import { callAPI, callAPIWithErrorMessage, steamShipLineAPI } from '../../../../api/api'
import { SteamShipLineViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { isNewObject } from '../../../../services/DTO'

export const requestSteamShipLineById = (id: string): Promise<SteamShipLineViewDTO> =>
  callAPI(steamShipLineAPI.findById, id)
    .toPromise()
    .then(async steamShipLine => {
      await pushListItemsToStore({ update: { [EntityType.steamShipLine]: [steamShipLine] } })

      return steamShipLine
    })

export const requestSteamShipLines = async ({ sort, filter }: any): Promise<SteamShipLineViewDTO[]> => {
  return callAPI(steamShipLineAPI.findAll, filter, sort)
    .toPromise()
    .then(async steamShipLines => {
      await pushListItemsToStore({ update: { [EntityType.steamShipLine]: steamShipLines } })

      return steamShipLines
    })
    .catch(catchListErrors)
}

const omitSteamShipLineProps: (keyof SteamShipLineViewDTO)[] = ['businessPartner']

export const requestCreateSteamShipLine = (steamShipLine: SteamShipLineViewDTO): Promise<SteamShipLineViewDTO> => {
  return callAPIWithErrorMessage(steamShipLineAPI.create, R.omit(
    steamShipLine,
    omitSteamShipLineProps.concat('id')
  ) as SteamShipLineViewDTO).then(async requestedSteamShipLine => {
    await pushListItemsToStore({ update: { [EntityType.steamShipLine]: [requestedSteamShipLine] } })

    return requestedSteamShipLine
  })
}

export const requestUpdateSteamShipLine = (steamShipLine: SteamShipLineViewDTO): Promise<SteamShipLineViewDTO> => {
  if (isNewObject(steamShipLine)) {
    return requestCreateSteamShipLine(steamShipLine)
  }

  return callAPIWithErrorMessage(steamShipLineAPI.update, steamShipLine.id, R.omit(
    steamShipLine,
    omitSteamShipLineProps
  ) as SteamShipLineViewDTO).then(async requestedSteamShipLine => {
    await pushListItemsToStore({ update: { [EntityType.steamShipLine]: [requestedSteamShipLine] } })

    return requestedSteamShipLine
  })
}
