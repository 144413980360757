import { oc } from 'ts-optchain'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { getTemplateActivityTypes } from '../../functions/get/getTemplateActivityTypes'
import { dateService } from '../../timeService'
import { isBusinessActivity } from '../../functions/test/isBusinessActivity'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isBobtailGotoActivity } from '../../functions/test/isBobtailGotoActivity'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'

const convertComboType = (comboType: TransportationActivityViewDTO.ComboTypeEnum) =>
  comboType
    .toString()
    .replace(/_/g, ' ')
    .replace(/ WITH CHASSIS/g, '')
    .replace(/ WITH OUT CHASSIS/g, '')

type Props = {
  fullDispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  driverActivities: TransportationActivityViewDTO[]
  note?: string
  needConfirmation?: boolean
}

export const constructMessageToDriver = (props: Props): string => {
  const { fullDispatchDeliveryOrder, driverActivities, needConfirmation, note } = props
  const storeLists = getListsState()
  const containerTypeId = oc(fullDispatchDeliveryOrder).containerId()
  const containerType = oc(storeLists[EntityType.containerType])[containerTypeId].code('')
  const containerOwnerName = oc(fullDispatchDeliveryOrder).deliveryOrder.steamShipLine.name('')
  const containerNumber = oc(fullDispatchDeliveryOrder).container.number()
  const bookingNumber = fullDispatchDeliveryOrder.deliveryOrder.bookingNumber
  const billOfLandingNumber = fullDispatchDeliveryOrder.deliveryOrder.billOfLadingNumber

  const autoIndicator = fullDispatchDeliveryOrder.autoIndicator && 'AUTO'
  const reeferIndicator = fullDispatchDeliveryOrder.temperatureControl && 'RF'
  const overweightIndicator = fullDispatchDeliveryOrder.overweightIndicator && 'OW'
  const hazmatIndicator = fullDispatchDeliveryOrder.hazmatIndicator && 'HAZ'

  const isImport = fullDispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = fullDispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = fullDispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION

  const deliveryLocationId = oc(fullDispatchDeliveryOrder).deliveryStage.locationId()
  const deliveryStageTemplateTypes = getTemplateActivityTypes({
    deliveryOrderType: fullDispatchDeliveryOrder.deliveryOrder.type,
    stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
    loadType: fullDispatchDeliveryOrder.loadType
  })

  let messageToDriver = ''
  let orderData = ''

  if (containerNumber) {
    orderData += `\n\nContainer#: ${containerNumber}`
  }

  if ((isExport || isRepo) && bookingNumber) {
    orderData += `\n\nBooking#: ${bookingNumber}`
  }
  if (isImport && billOfLandingNumber) {
    orderData += `\n\nBL#: ${billOfLandingNumber}`
  }
  if (containerType) {
    orderData += `\n\nType: ${containerType} ${containerOwnerName}`
  }
  if (overweightIndicator || autoIndicator || hazmatIndicator || reeferIndicator) {
    orderData += `\n\nAdd: `
    orderData += [overweightIndicator, autoIndicator, hazmatIndicator, reeferIndicator].filter(Boolean).join(', ')
  }

  const filteredDriverActivities = driverActivities.filter(item => isBusinessActivity(item))

  filteredDriverActivities.forEach((activity, i) => {
    let comboType = convertComboType(activity.comboType)
    let isBobtail = false

    if (isGotoActivity(activity)) {
      return
    }

    const gotoActivity = driverActivities.find(item => item.groupId === activity.groupId)

    if (gotoActivity && isBobtailGotoActivity(gotoActivity)) {
      isBobtail = true
    }

    messageToDriver += i === 0 ? '1) ' : `\n\n${i + 1}) `

    if (activity.comboType === TransportationActivityViewDTO.ComboTypeEnum.EMPTY) {
      comboType = ''
    }

    if (isBobtail) {
      comboType = 'BOBTAIL EN ROUTE TO ' + comboType
    }

    const isDeliveryStageTemplateActivity =
      activity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      activity.destinationId === deliveryLocationId &&
      deliveryStageTemplateTypes.includes(activity.type)

    if (isDeliveryStageTemplateActivity) {
      messageToDriver += `Delivery Wrh: ${activity.destination.name}`
      messageToDriver += `\n\nDelivery address: ${[
        activity.destination.address.street,
        activity.destination.address.street2,
        activity.destination.cityStateZip
      ]
        .filter(Boolean)
        .join(', ')}`
      messageToDriver += `\n\n${comboType}`

      const appointmentTimeFrom = dateService.makeLabel(oc(activity).startPlannedDateTimeRange.from(), {
        hideYear: true,
        monthName: 'short',
        shortSpace: true
      })

      if (appointmentTimeFrom) {
        const appointmentTimeTo = dateService.makeLabel(oc(activity).startPlannedDateTimeRange.to(), {
          onlyTime: true
        })
        messageToDriver += `\n\nAppt time: ${appointmentTimeFrom}${appointmentTimeTo ? ` - ${appointmentTimeTo}` : ''}`
      }
    } else {
      messageToDriver += `${comboType}: `
      messageToDriver += `${oc(activity).destination.longName('')}`
    }

    if (i === 0) {
      messageToDriver += orderData
    }
  })
  if (note && note.length) {
    messageToDriver += `\n\nNotes: ${note}`
  }
  if (needConfirmation) {
    messageToDriver += `
    \n\nreply 1 to accept
    \n\nreply 2 to reject\n\n
    `
  }
  return messageToDriver
}
