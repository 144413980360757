import * as React from 'react'
import { TProps } from './simple'
import { TextMask } from './textMask'

export const Zip = (props: TProps) => (
  <TextMask
    {...props}
    valid={props.value ? props.value.length >= 5 : true}
    textMask={[
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/
    ]}
  />
)
