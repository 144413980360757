import { CustomerViewDTO } from '../../../api/origin/business-logic'

export const netTerms = {
  [CustomerViewDTO.NetTermsEnum.NET7]: '7',
  [CustomerViewDTO.NetTermsEnum.NET15]: '15',
  [CustomerViewDTO.NetTermsEnum.NET30]: '30',
  [CustomerViewDTO.NetTermsEnum.NET45]: '45',
  [CustomerViewDTO.NetTermsEnum.NET60]: '60',
  [CustomerViewDTO.NetTermsEnum.NET90]: '90',
  [CustomerViewDTO.NetTermsEnum.OTHER]: 'Other'
}

export const netTermsUnit = {
  [CustomerViewDTO.NetTermsUnitEnum.NET]: 'Net',
  [CustomerViewDTO.NetTermsUnitEnum.ONDEMAND]: 'Ondemand'
}

export const customerDirectory = {
  netTerms,
  netTermsUnit
}
