import { oc } from 'ts-optchain'
import { RootState } from '..'
import {
  ActiveChatChannelIdSpecialTypes,
  INotificationFilters,
  INotificationItemsMapping,
  INotifications,
  NotificationDocumentForm
} from '../reducers/communicationHub/interfaces'
import { NotificationDTO } from '../../api/origin/communication-hub-service'

export const selectNotificationsIsNotifications = (state: RootState): boolean => {
  return oc(state).communicationHub.activeChannel.id() === ActiveChatChannelIdSpecialTypes.NOTIFICATIONS
}

// SELECT ONE
export const selectNotification = (id: string) => (state: RootState): NotificationDTO | undefined => {
  return state.communicationHub.notifications.itemsMapping[id]
}

export const selectNotificationDocumentForm = (options: Partial<NotificationDocumentForm>) => (
  state: RootState
): NotificationDocumentForm | undefined => {
  const keys = Object.keys(options || {})
  const documentTypeKeys = ['documentType', 'type']

  if (!keys.length) {
    return undefined
  }

  return state.communicationHub.notifications.documentForms.find(doc =>
    keys.every(key => {
      const value = options[key]

      if (documentTypeKeys.includes(key)) {
        return documentTypeKeys.some(_key => doc[_key] === value)
      }

      return doc[key] === value
    })
  )
}

// SELECT MANY
export const selectNotificationIdList = (state: RootState): string[] => {
  return state.communicationHub.notifications.sortedIds
}

export const selectNotificationMapping = (state: RootState): INotificationItemsMapping => {
  return state.communicationHub.notifications.itemsMapping
}

export const selectNotificationDocumentForms = (state: RootState): NotificationDocumentForm[] => {
  return state.communicationHub.notifications.documentForms
}

export const selectNotificationFilters = (state: RootState): INotificationFilters => {
  return state.communicationHub.notifications.filters
}
