import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../api/api'
import { showWarningModalWindow } from '../../store/reducers/modalWindow/functions'
import { checkDates } from '../DTO/dispatchDeliveryOrder/functions'
import { getRelatedActivityType } from './get/getRelatedActivityType'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  transportationActivities: TransportationActivityViewDTO[]
  modifyActivities: (props: { transportationActivities: TransportationActivityViewDTO[] }) => void
  modifyDispatchDeliveryOrder: (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) => void
  onOkButtonClick: () => void
}

export const checkDDODatesBeforeSave = (props: Props): boolean => {
  const {
    dispatchDeliveryOrder,
    deliveryOrder,
    transportationActivities,
    modifyActivities,
    modifyDispatchDeliveryOrder,
    onOkButtonClick
  } = props
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT

  if (checkDates({ dispatchDeliveryOrder, deliveryOrder })) {
    showWarningModalWindow({
      title: 'Warning',
      content: isExport
        ? 'You are scheduling return after Cutoff date to proceed click OK or CANCEL to return with out changes'
        : 'You are scheduling pick up after LFD date to proceed click OK or CANCEL to return with out changes',
      buttons: [
        {
          label: 'Cancel',
          outline: true,
          onClick: () => {
            const relatedActivity = isExport
              ? getRelatedActivityType({
                  transportationActivities: transportationActivities,
                  phaseType: 'return'
                })
              : getRelatedActivityType({
                  transportationActivities: transportationActivities,
                  phaseType: 'pickup'
                })

            if (relatedActivity) {
              const updateActivity = transportationActivities.find(a => a.id === relatedActivity.id)

              if (updateActivity) {
                modifyActivities({
                  transportationActivities: [{ ...updateActivity, startPlannedDateTimeRange: { from: null, to: null } }]
                })
              }
            }

            const stageName: keyof DispatchDeliveryOrderViewDTO = isExport ? 'returnStage' : 'pickupStage'

            modifyDispatchDeliveryOrder({
              ...dispatchDeliveryOrder,
              [stageName]: {
                ...dispatchDeliveryOrder[stageName],
                plannedAppointmentDateTimeRange: { from: null, to: null }
              }
            })
          }
        },
        { label: 'OK', onClick: onOkButtonClick }
      ]
    })

    return false
  }

  return true
}
