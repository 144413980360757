import { NotificationDTO } from '../../../api/origin/communication-hub-service'
import { INotificationFilters, NotificationFilter } from '../../../store/reducers/communicationHub/interfaces'

type Props = {
  notification: NotificationDTO
  filters: INotificationFilters
}

export const testNitification = (
  props: Props
): {
  isPass: boolean
  testProps: {
    status: boolean
    type: boolean
    sender: boolean
    dates: boolean
    driverStatuses: boolean
    recipientUsers: boolean
    text: boolean
  }
} => {
  const { notification, filters } = props
  const status = filters[NotificationFilter.status]
  const type = filters[NotificationFilter.type]
  const driverStatuses = filters[NotificationFilter.driverStatuses]
  const recipientUserIds = filters[NotificationFilter.recipientUserIds]
  const senderUserIds = filters[NotificationFilter.senderUserIds]
  const startDate = filters[NotificationFilter.startDate]
  const endDate = filters[NotificationFilter.endDate]
  const text = filters[NotificationFilter.text]

  const checkStatus = () => {
    return !status || notification.status === status
  }
  const checkType = () => {
    return !type || notification.type === type
  }
  const checkSender = () => {
    return !(senderUserIds && senderUserIds.length) || senderUserIds.includes(notification.senderUserId)
  }
  const checkDates = () => {
    return (!startDate || notification.createdAt > startDate) && (!endDate || notification.createdAt < endDate)
  }
  const checkDriverStatuses = () => {
    return (
      !(driverStatuses && driverStatuses.length) ||
      // @ts-ignore
      driverStatuses.some(item => notification.driverStatuses.includes(item))
    )
  }
  const checkRecipientUsers = () => {
    return (
      !(recipientUserIds && recipientUserIds.length) ||
      recipientUserIds.some(id => notification.recipientUserIds.includes(id))
    )
  }
  const checkText = () => {
    if (!text) {
      return true
    }

    const _text = text.toUpperCase()

    return (
      (notification.subject || '').toUpperCase().includes(_text) ||
      JSON.stringify(notification.text)
        .toUpperCase()
        .includes(_text)
    )
  }

  const testProps = {
    status: checkStatus(),
    type: checkType(),
    sender: checkSender(),
    dates: checkDates(),
    driverStatuses: checkDriverStatuses(),
    recipientUsers: checkRecipientUsers(),
    text: checkText()
  }

  return {
    isPass: Object.values(testProps).every(Boolean),
    testProps
  }
}
