export enum EquipmentColumn {
  EquipmentNumber = 'Equipment #',
  ActiveAvailability = 'Active / Availability',
  Type = 'Type',
  Year = 'Year',
  Make = 'Make',
  Model = 'Model',
  MaxCargoWeight = 'Max Cargo Weight',
  Ownership = 'Ownership',
  LicensePlateNumber = 'License Plate #',
  RegistrationState = 'Registration State',
  RegistrationDate = 'Registration Date',
  RegistrationExpDate = 'Registration Exp. Date',
  DOTSafetyInspectionDate = 'DOT Safety Inspection Date',
  DOTSafetyInspectionAnnualExpDate = 'DOT Safety Inspection Annual Exp. Date',
  MaintenanceInspectionFrequency = 'Maintenance Inspection Frequency',
  InsuranceType = 'Insurance Type',
  InsuranceCarrier = 'Insurance Carrier',
  InsuranceExpDate = 'Insurance Exp. Date',
  InsurancePolicyNumber = 'Insurance Policy #'
}

export const equipmentColumnSettings = {
  [EquipmentColumn.EquipmentNumber]: { width: 160, active: true },
  [EquipmentColumn.ActiveAvailability]: { width: 260, active: true },
  [EquipmentColumn.Type]: { width: 180, active: true },
  [EquipmentColumn.Year]: { width: 160, active: true },
  [EquipmentColumn.Make]: { width: 160, active: true },
  [EquipmentColumn.Model]: { width: 160, active: true },
  [EquipmentColumn.MaxCargoWeight]: { width: 160, active: true },
  [EquipmentColumn.Ownership]: { width: 160, active: true },
  [EquipmentColumn.LicensePlateNumber]: { width: 160, active: true },
  [EquipmentColumn.RegistrationState]: { width: 160, active: true },
  [EquipmentColumn.RegistrationDate]: { width: 160, active: true },
  [EquipmentColumn.RegistrationExpDate]: { width: 160, active: true },
  [EquipmentColumn.DOTSafetyInspectionDate]: { width: 180, active: true },
  [EquipmentColumn.DOTSafetyInspectionAnnualExpDate]: {
    width: 190,
    active: true
  },
  [EquipmentColumn.MaintenanceInspectionFrequency]: {
    width: 170,
    active: true
  },
  [EquipmentColumn.InsuranceType]: {
    width: 160,
    active: true
  },
  [EquipmentColumn.InsuranceCarrier]: { width: 160, active: true },
  [EquipmentColumn.InsuranceExpDate]: { width: 160, active: true },
  [EquipmentColumn.InsurancePolicyNumber]: { width: 160, active: true }
}
