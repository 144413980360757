// @ts-ignore
import { debounce } from 'debounce'
import { oc } from 'ts-optchain'
import { requestDispatchDeliveryOrderById } from '../../common/dispatchDeliveryOrder/epics'
import { requestLocationById } from '../../common/location/epics'
import { requestEquipmentById } from '../../common/equipment/epics'
import { requestCustomerById } from '../../common/customer/epics'
import { requestSteamShipLineById } from '../../common/steamShipLine/epics'
// import { getStreetTurnDispatchDeliveryOrderById } from '../../../services/DTO/streetTurn/epics'
import { getStore } from '../../../store/configureStore'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { getApplicationTabsState, getListsState } from '../../../store'
import { requestDriverById } from '../../common/drivers/epics'

export const getFullUnit = (type: TabDTO.Type | TabDTO.Request.List, id: string): Promise<any> => {
  switch (type) {
    case TabDTO.Type.driver:
      return requestDriverById(id)
    case TabDTO.Type.dispatchDeliveryOrder:
      return requestDispatchDeliveryOrderById(id)
    case TabDTO.Type.location:
      return requestLocationById(id)
    case TabDTO.Type.equipment:
      return requestEquipmentById(id)
    case TabDTO.Type.customer:
      return requestCustomerById(id)
    case TabDTO.Type.steamShipLine:
      return requestSteamShipLineById(id)
    case TabDTO.Request.List.streetTurn:
      return requestDispatchDeliveryOrderById(id)
    default:
      return Promise.resolve()
  }
}

type TCheckGridItemOnRerender = {
  tabId: string
  listRequestType: TabDTO.Request.List
  tabType: TabDTO.Type
  unit: any
  fetchGridItems: (withoutSpinner?: boolean) => Promise<any>
}
export const checkUpdatedGridItem = (props: TCheckGridItemOnRerender) => {
  const { listRequestType, tabType } = props

  switch (listRequestType || tabType) {
    case TabDTO.Request.List.streetTurn: {
      // checkStreetTurnGridItem(props)
      break
    }
    default:
      return
  }
}

export const actionOnUnitExpand = (type: TabDTO.Type | TabDTO.Request.List, object: any) => {
  switch (type) {
    case TabDTO.Type.dispatchDeliveryOrder: {
      const store = getStore().getState()

      // if (
      //   oc(store)
      //     .deliveryOrder[object.deliveryOrderId].dispatchDeliveryOrderIds([])
      //     .some(id => !store.dispatchDeliveryOrder[id])
      // ) {
      //   callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders,
      // 'deliveryOrder.id%%' + object.deliveryOrderId)
      //     .toPromise()
      //     .then(receivedDDOs => {
      //       parseDTO.dispatchDeliveryOrder(receivedDDOs)
      //     })
      // }
      break
    }
    default:
  }
}
