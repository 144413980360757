import * as React from 'react'
import { Widget } from './Widget'
import { DashboardDTO } from '../../../../../../api/origin/business-logic'
import { LinksWithCounts } from './LinksWithCounts'
import { callAPI, dashboardAPI } from '../../../../../../api/api'
import styled from 'styled-components'

type OwnProps = {}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Dashboard = React.memo((props: Props) => {
  const [dashboard, setDashboard] = React.useState<DashboardDTO>({
    activeDriverCount: 0,
    cancelledOrderCount: 0,
    completedOrderCount: 0,
    inProcessExportOrderCount: 0,
    inProcessImportOrderCount: 0,
    newExportOrderCount: 0,
    newImportOrderCount: 0,
    readyExportForDispatchOrderCount: 0,
    readyImportForDispatchOrderCount: 0,
    unassignedDriverCount: 0,
    topFiveCustomers: [],
    activeDrivers: [],
    unassignedDrivers: [],
    cutOffNewOrders: [],
    lfdNewOrders: []
  })

  React.useEffect(() => {
    let mounted = true

    callAPI(dashboardAPI.getDashboard)
      .toPromise()
      .then(data => {
        if (mounted) {
          setDashboard(data)
        }
      })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <LinksWithCounts />
      <WidgetsContainer>
        <Widget
          style={{ marginBottom: 10 }}
          title={'New Orders'}
          previewData={[
            { label: 'Import', value: dashboard.newImportOrderCount },
            { label: 'Export', value: dashboard.newExportOrderCount }
          ]}
          popoverData={[
            {
              tabTitle: 'Top Customers',
              tabList: dashboard.topFiveCustomers.map(({ customerName, ddoCount }) => ({
                label: customerName,
                value: ddoCount
              })),
              headers: {
                label: 'Customer',
                value: 'Containers'
              }
            },
            {
              tabTitle: 'LFD',
              tabList: dashboard.lfdNewOrders.map(({ dateName, ddoCount }) => ({
                label: dateName,
                value: ddoCount
              })),
              headers: {
                label: 'Date',
                value: 'Containers'
              }
            },
            {
              tabTitle: 'CUTOFF',
              tabList: dashboard.cutOffNewOrders.map(({ dateName, ddoCount }) => ({
                label: dateName,
                value: ddoCount
              })),
              headers: {
                label: 'Date',
                value: 'Containers'
              }
            }
          ]}
        />
        <Widget
          style={{ marginTop: 'auto' }}
          title={'Ready for Dispatch'}
          previewData={[
            { label: 'Import', value: dashboard.readyImportForDispatchOrderCount },
            { label: 'Export', value: dashboard.readyExportForDispatchOrderCount }
          ]}
        />
      </WidgetsContainer>
    </>
  )
})

const WidgetsContainer = styled.div`
  flex-shrink: 0;
  width: 210px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  padding: 8px 10px;
  margin-left: 10px;
`
