import { StyledActivityRowConfig } from '../styles'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../../../../styles/theme'

export const DDOActivityTableHeader = () => (
  <StyledActivityRowConfig>
    <div />
    <div />
    <div />
    <div />
    <Column className={'required'}>Status</Column>
    <Column>Planned</Column>
    <Column>Actual Start</Column>
    <Column>Actual Complete</Column>
    <Column>SSQ</Column>
    <Column>BSQ</Column>
    <Column>DOC</Column>
    <div />
  </StyledActivityRowConfig>
)

const Column = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(227, 230, 241);
  color: rgb(108, 112, 125);
  font-size: 12px;
  padding-left: 20px;
  user-select: none;

  &.required::after {
    content: '*';
    color: ${theme.colors.defaultRed};
    margin-left: 3px;
  }
`
