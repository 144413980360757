import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../styles/theme'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectApplicationTabsByType } from '../../../store/select/applicationTabSelect'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { tabDirectory } from '../../../store/reducers/tabs/directory'
import { createTab } from '../../../store/reducers/tabs/functions'

export const CreateTab = () => {
  const timerRef = React.useRef(null)
  const [showPopover, setShowPopover] = React.useState(false)

  return (
    <Container
      onMouseEnter={() => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
      }}
      onMouseLeave={() => {
        if (showPopover) {
          timerRef.current = setTimeout(() => setShowPopover(false), 500)
        }
      }}
    >
      <Button className={'mdi mdi-plus'} onClick={() => setShowPopover(state => !state)} />
      {showPopover && <CreateTabPopover />}
    </Container>
  )
}

export const CreateTabPopover = () => {
  const schedulerTabs = useAppSelector(selectApplicationTabsByType(TabDTO.Type.scheduler))
  const list = [
    TabDTO.Type.dispatchDeliveryOrder,
    TabDTO.Type.scheduler,
    TabDTO.Type.QMP,
    TabDTO.Type.locationGroup,
    TabDTO.Type.powerUnit,
    TabDTO.Type.equipment,
    TabDTO.Type.container,
    TabDTO.Type.location,
    TabDTO.Type.driver,
    TabDTO.Type.customer,
    TabDTO.Type.steamShipLine,
    TabDTO.Type.subClient
  ]

  return (
    <List>
      {list.map(type => {
        const disabled = Boolean(type === TabDTO.Type.scheduler && schedulerTabs.length)

        return (
          <ListItem
            key={type}
            className={cn(tabDirectory.iconClassName[type], { disabled })}
            onClick={
              disabled
                ? undefined
                : () => {
                    createTab(type)
                  }
            }
          >
            {tabDirectory.tabLabel[type]}
          </ListItem>
        )
      })}
    </List>
  )
}

const Container = styled.div``

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: ${theme.colors.spaceGray};
  font-size: 18px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: white;
  }
`
const List = styled.div`
  z-index: 1;
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  border-top: solid 2px ${theme.colors.basicBlueColor};
  background: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  padding-top: 3px;
`

const ListItem = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  padding: 3px 14px;
  cursor: pointer;

  &:before {
    font-size: 14px;
    margin-right: 5px;
  }

  &:not(:last-child) {
    border-bottom: solid 1px ${theme.colors.basicBorderColor};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`
