import { oc } from 'ts-optchain'
import { IMove, IMoveDTO } from '../../../store/reducers/communicationHub/interfaces'
import { isDDOStatusCancelled } from './isDDOStatusCancelled'
import { getListsState } from '../../../store'

export const isMoveDisabled = (move: IMove | IMoveDTO): boolean => {
  const dispatchDeliveryOrder = getListsState().dispatchDeliveryOrder[move.dispatchDeliveryOrderId]
  const disable = isDDOStatusCancelled(oc(dispatchDeliveryOrder).status()) || move._isNextDayMove || move._isPrevDayMove

  return disable
}
