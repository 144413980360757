import { getRouteBuilderMoveDay } from '../../../hooks/useRouteBuilderNextToDayMove'
import { IMoveDTO } from '../../../store/reducers/communicationHub/interfaces'

const isChangeableMovesOrder = (move1: IMoveDTO, move2: IMoveDTO): boolean => {
  return Boolean(
    move1 &&
      move2 &&
      move1.dispatchDeliveryOrderId !== move2.dispatchDeliveryOrderId &&
      (!move1.plannedDateFixed || !move2.plannedDateFixed) &&
      !move1.startDate &&
      !move2.startDate
  )
}

export const isChangeableMoveGroupsOrder = (group1: IMoveDTO[], group2: IMoveDTO[]): boolean => {
  return Boolean(
    group1 &&
      group2 &&
      group1.length &&
      group2.length &&
      group1.every(move1 => {
        const move1Day = getRouteBuilderMoveDay(move1)
        return group2.every(move2 => move1Day === getRouteBuilderMoveDay(move2) && isChangeableMovesOrder(move1, move2))
      })
  )
}
