import { EntityType } from '../../interfaces'

export const mergeParsedLists = (mainList: Partial<Record<EntityType, any[]>>) => (
  secondaryList: Partial<Record<EntityType, any[]>>
): Partial<Record<EntityType, any[]>> => {
  Object.keys(secondaryList).forEach(entityType => {
    mainList[entityType].push(...secondaryList[entityType])
  })

  return mainList
}
