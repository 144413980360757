import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { Portal } from 'react-portal'
import { useTable } from '../../../hooks/useTable'
import { ITableRowActionButton, ITableRowItem, RowActionButtonType } from '../../../contexts/TableContext'
import { isNewObject } from '../../../services/DTO'
import { TrashIcon } from '../icons/TrashIcon'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

type Props = {
  item: ITableRowItem
}

export const TableRow = (props: Props) => {
  const { item } = props
  const { settings, styleRow, CSSRuleGridTemplateColumns, getRowClassName, rowActionButton } = useTable()
  const actionButton = rowActionButton ? rowActionButton(item) : undefined

  return (
    <Container
      className={cn('tableRow', getRowClassName ? getRowClassName(item) : '', { isNew: isNewObject(item) })}
      style={{ gridTemplateColumns: CSSRuleGridTemplateColumns, ...(styleRow || {}) }}
    >
      {settings.map(({ label, getValue }) => {
        return <Column key={label}>{getValue(item)}</Column>
      })}
      {Boolean(actionButton) && (
        <ActionColumn
          className={cn({ disabled: actionButton.disabled })}
          children={
            actionButton.type === RowActionButtonType.DELETE ? <TrashIcon /> : <OptionsButton {...actionButton} />
          }
          onClick={actionButton.disabled ? undefined : actionButton.onClick}
        />
      )}
    </Container>
  )
}

const OptionsButton = (props: ITableRowActionButton) => {
  const { disabled, options } = props
  const popoverRef = React.useRef(null)
  const { statePopover, wrapperRef, togglePopover, setPopoverState } = useOutsideClick({ capture: false })

  React.useEffect(() => {
    if (!statePopover || !popoverRef.current) {
      return
    }

    const buttonProps = wrapperRef.current.getBoundingClientRect()
    const closeDropdownOnScroll = (event: Event) => {
      if (!wrapperRef.current.contains(event.target)) {
        setPopoverState(false)
      }
    }

    popoverRef.current.style.top = buttonProps.top + 'px'
    popoverRef.current.style.left = buttonProps.left + buttonProps.width + 'px'
    window.addEventListener('scroll', closeDropdownOnScroll, true)

    return () => {
      window.removeEventListener('scroll', closeDropdownOnScroll, true)
    }
  }, [statePopover])

  const doAction = (action: () => void) => () => {
    if (action) {
      action()
    }

    setPopoverState(false)
  }

  return (
    <ActionsContainer ref={wrapperRef} className={cn({ disabled })} onClick={disabled ? undefined : togglePopover}>
      <i className={'mdi mdi-dots-vertical'} />

      {Boolean(options.length) && statePopover && (
        <Portal>
          <ActionsPopover ref={popoverRef}>
            {options.map((option, index) => {
              return (
                !option.hidden && (
                  <ActionsPopoverOption
                    key={index}
                    className={cn({ disabled: option.disabled })}
                    onClick={option.disabled ? undefined : doAction(option.onClick)}
                  >
                    {option.label}
                  </ActionsPopoverOption>
                )
              )
            })}
          </ActionsPopover>
        </Portal>
      )}
    </ActionsContainer>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;

  &.isNew {
    background-color: #ebf6ee;
  }

  .select-container,
  .input-container {
    background-color: #fff;
  }
`

const Column = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
`
const ActionColumn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f3f5;
  border-left: 1px solid #e5e5e5;
  cursor: pointer;

  &.disabled {
    cursor: default;

    svg,
    path {
      fill: #cfd0d1;
    }
  }
`

const ActionsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
  }

  &.disabled {
    cursor: default;

    i {
      color: #cfd0d1;
    }
  }
`
const ActionsPopover = styled.div`
  z-index: 3;
  position: fixed;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 8px 0;
`
const ActionsPopoverOption = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
  color: #404957;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  user-select: none;
  cursor: pointer;

  &:not(.disabled):hover {
    background-color: #f5f6fa;
  }

  &.disabled {
    color: #cfd0d1;
    cursor: default;

    svg,
    path {
      fill: #cfd0d1;
    }

    i {
      color: #cfd0d1;
    }
  }
`
