import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { CustomerViewDTO } from '../../../api/api'
import { saveCustomer as saveCustomerPromise } from '../../saveEntity/saveCustomer'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { getListsState } from '../../../store'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { filterModifiedContacts } from '../../functions/filter/filterModifiedContacts'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'
import { isNewItem } from '../../utils'
import { getModifiedCustomerEntities } from '../../functions/get/getModifiedCustomerEntities'
import { getCustomerSubClients } from '../../functions/get/getCustomerSubClients'

export const saveCustomer = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const storeLists = getListsState()
  const customer: CustomerViewDTO = parentItem
  const modifiedData = getModifiedCustomerEntities({ customer, modifiedLists, storeLists })
  const storeBusinessPartner = oc(storeLists)[EntityType.businessPartner][customer.businessPartnerId]()
  const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][customer.businessPartnerId]()
  const businessPartnercontactIds = oc(modifiedBusinessPartner || storeBusinessPartner).contactIds([])

  const condition =
    testEntityVersion(storeLists, EntityType.customer)(modifiedData.customer) &&
    modifiedData.businessPartner.every(testEntityVersion(storeLists, EntityType.businessPartner)) &&
    modifiedData.subClient.every(testEntityVersion(storeLists, EntityType.subClient)) &&
    modifiedData.contact.every(testEntityVersion(storeLists, EntityType.contact))

  const save = () => {
    return saveCustomerPromise({
      customer: modifiedData.customer,
      businessPartner: modifiedBusinessPartner,
      contacts: filterModifiedContacts({ contactIds: businessPartnercontactIds, modifiedLists }),
      subClients: getCustomerSubClients({ customer, modifiedLists, storeLists })
    }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(customer) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
