import * as React from 'react'
import { oc } from 'ts-optchain'
import { Groups, InnerContainer, NoActivity } from './styles'
import { Group } from './Group'
import { GridCellProps } from 'react-virtualized'
import { CollapsibleGroup } from './CollapsibleGroup'
import { ISchedulerTabFilterMapping, SchedulerFilter, SchedulerSortBy } from '../../interfaces'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectOngoingActivitisDateStage } from '../../../../../store/select/ongoingActivitySelect'
import { sortTime } from '../../functions'
import { NowActivityGroups } from './NowActivityGroups'
import theme from '../../../../../styles/theme'
import { filterOngoingActivityGroups } from '../../functions/filterOngoingActivityGroups'
import { dateService } from '../../../../../services/timeService'
import { getListOfUsedIdsOnSpecificDate, getOngoingActivities } from '../../epics'
import { GridItemSpinner } from '../../../../UI/Spinner/Spinner'
import { OngoingActivityGroupDTO } from '../../../../../api/api'
import { createFilterRequest } from '../../../../../services/uiSettingsService/filter'
import { tabSessionStorage } from '../../../../../services/tabs/functions'
import { useTabContentScrollPosition } from '../../../../../hooks/useTabContentScrollPosition'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../../store/reducers/communicationHub'
import { updateTab, updateTabData } from '../../../../../store/reducers/tabs/functions'
import { ColumnDTO } from '../../../../../store/reducers/tabs/interfaces'

type OwnProps = {
  currentTabVisited: boolean
  currentTabId: string
  sortBy: SchedulerSortBy
  showMissed: boolean
  showNow: boolean
  fetching: boolean
  filter: ISchedulerTabFilterMapping
}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

let fullStageListHasBeenLoaded = false
const groupVisited: {
  [tabId: string]: boolean
} = {}

export const StageGroupingView = React.memo((props: Props) => {
  const {
    currentTabVisited,
    currentTabId,
    showMissed,
    showNow,
    sortBy,
    fetching,
    filter: { specificDate, activityGroupDate, dispatchDeliveryOrderNumber }
  } = props
  const dispatch = useAppDispatch()
  const mountedRef = React.useRef(false)
  const containerRef = useTabContentScrollPosition({
    tabId: currentTabId + SchedulerSortBy.date,
    bind: fetching === false
  })
  const ongoingActivityList = useAppSelector(selectOngoingActivitisDateStage)
  const { missedActivities, ongoingActivities, sortedOngoingActivityGroups } = React.useMemo(() => {
    const filteredOngoingActivityGroups = filterOngoingActivityGroups(Object.values(ongoingActivityList), props.filter)
    const sortedByDateAndTime = sortTime(filteredOngoingActivityGroups, {
      separateMissedGroup: sortBy !== SchedulerSortBy.driverAllStages,
      countHeaders: sortBy === SchedulerSortBy.driverAllStages,
      countRows: sortBy === SchedulerSortBy.driverAllStages,
      showCounters: sortBy !== SchedulerSortBy.driverAllStages
    })

    return {
      missedActivities: sortedByDateAndTime.missedActivities,
      ongoingActivities: sortedByDateAndTime.ongoingActivities,
      sortedOngoingActivityGroups: Object.keys(sortedByDateAndTime.ongoingActivities).sort(
        (a, b) => parseInt(a, 10) - parseInt(b, 10)
      )
    }
  }, [ongoingActivityList, sortBy, activityGroupDate, dispatchDeliveryOrderNumber])
  const ongoinglistIsEmpty = !Object.keys(ongoingActivities).length

  React.useEffect(() => {
    const getSchedulerList = async () => {
      if (fetching) {
        return
      }

      updateTab(currentTabId, { fetching: true, visited: true })

      const requestProps: ColumnDTO.Filter[] = [
        {
          column: SchedulerFilter.grouping,
          value: [
            OngoingActivityGroupDTO.GroupingEnum.STAGE.toString(),
            OngoingActivityGroupDTO.GroupingEnum.STAGENOW.toString()
          ]
        }
      ]

      if (dispatchDeliveryOrderNumber) {
        requestProps.push({
          column: SchedulerFilter.dispatchDeliveryOrderNumber,
          value: String(dispatchDeliveryOrderNumber)
        })
      } else {
        fullStageListHasBeenLoaded = true
      }

      getOngoingActivities({ sort: undefined, filter: createFilterRequest(requestProps) })
        .then(data => {
          tabSessionStorage.scrollPosition.setTabPosition(currentTabId, {
            top: 0,
            left: 0
          })

          updateTabData(
            currentTabId,
            {
              showMissed: Boolean(dispatchDeliveryOrderNumber),
              ...(Boolean(dispatchDeliveryOrderNumber) ? { showNow: true } : {})
            },
            true
          )
        })
        .finally(() => {
          updateTab(currentTabId, { fetching: false })
        })
    }

    if (!mountedRef.current) {
      mountedRef.current = true

      if (!(currentTabVisited && groupVisited[currentTabId]) && !fullStageListHasBeenLoaded) {
        getSchedulerList()
      }

      groupVisited[currentTabId] = true
    } else if (!fullStageListHasBeenLoaded) {
      getSchedulerList()
    }
  }, [dispatchDeliveryOrderNumber])

  React.useEffect(() => {
    let isExpiredData = false

    if (sortBy === SchedulerSortBy.deliveryStageDriver && specificDate) {
      getListOfUsedIdsOnSpecificDate(specificDate).then(activeDeliveryStageVendorIds => {
        if (!isExpiredData) {
          dispatch(
            communicationHubActions.setActiveRouteBuilderSchedulerTabData({
              specificDate,
              ongingDispatchDeliveryOrderIds: Object.values(ongoingActivities).reduce((acc, currDayGroup) => {
                Object.values(currDayGroup.timeGroup).forEach(ongoingActivityGroups =>
                  ongoingActivityGroups.forEach(({ dispatchDeliveryOrderId }) => {
                    acc.push(dispatchDeliveryOrderId)
                  })
                )

                return acc
              }, []),
              activeDeliveryStageVendorIds
            })
          )
        }
      })
    } else {
      dispatch(communicationHubActions.clearActiveRouteBuilderSchedulerTabData())
    }

    return () => {
      isExpiredData = true
    }
  }, [ongoingActivities])

  React.useEffect(() => {
    return () => {
      dispatch(communicationHubActions.clearActiveRouteBuilderSchedulerTabData())
    }
  }, [])

  const groupRenderer = ({ key, rowIndex, style, date }: GridCellProps & { date?: string }) => {
    const groupDate = date || sortedOngoingActivityGroups[rowIndex]

    return (
      <div key={key} style={style}>
        <Group sortBy={sortBy} dateName={groupDate} date={ongoingActivities[groupDate]} />
      </div>
    )
  }

  return fetching ? (
    <GridItemSpinner />
  ) : (
    <Groups ref={containerRef}>
      <InnerContainer>
        <CollapsibleGroup
          title={'Missed'}
          isMissed={true}
          labelBackgroundColor={theme.colors.defaultRed}
          expanded={showMissed}
          sortBy={sortBy}
          activityGroups={missedActivities}
          withoutLabel={sortBy === SchedulerSortBy.date}
          onToggle={() => {
            updateTabData(currentTabId, { showMissed: !showMissed })
          }}
        />
        <NowActivityGroups
          expanded={showNow}
          sortBy={sortBy}
          filter={props.filter}
          todayTimeGroupActivities={
            dateService.isCurrentDay(specificDate)
              ? oc(ongoingActivities)[dateService.makeLabel(specificDate, { long: true, fullYear: true })].timeGroup()
              : undefined
          }
          onToggle={() => {
            updateTabData(currentTabId, { showNow: !showNow })
          }}
        />
        {ongoinglistIsEmpty ? (
          specificDate ? (
            <Group
              sortBy={sortBy}
              dateName={dateService.makeLabel(specificDate, { long: true, fullYear: true })}
              date={{
                timeGroup: {},
                rowsNumber: 0,
                headersNumber: 0,
                kpi: {
                  cutoff: 0,
                  lfd: 0,
                  pickupCount: 0,
                  deliveryCount: 0,
                  returnCount: 0
                }
              }}
            />
          ) : (
            <NoActivity>No Activities for the nearest future</NoActivity>
          )
        ) : (
          sortedOngoingActivityGroups.map((date: string) =>
            groupRenderer({
              key: date,
              date,
              style: undefined,
              rowIndex: undefined,
              columnIndex: undefined,
              isScrolling: undefined,
              isVisible: undefined,
              parent: undefined
            })
          )
        )}
      </InnerContainer>
    </Groups>
  )
})
