import * as React from 'react'
import { ContactsTab } from '../../../../contact/views/ContactsTab'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemSteamShipLine } from '../../../../../../hooks/useGridItemSteamShipLine'

type Props = {}

export const Contacts = (props: Props) => {
  const { businessPartner } = useGridItemSteamShipLine()

  return <ContactsTab parentEntity={businessPartner} entityType={EntityType.businessPartner} />
}
