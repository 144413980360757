import * as React from 'react'
import { Container, Column } from '../../../../../UI/GridSystem'
import { Credentials, Title, Date } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { Select } from '../../../../../UI/DataFields/Select'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { Maintenance } from './Maintenance'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { powerUnitLists } from '../../../../../../services/select/powerUnitLists'
// tslint:disable:max-line-length
import { generateMaintenanceInspection } from '../../../../../../services/functions/generate/generateMaintenanceInspection'
import { FileVersionDTO } from '../../../../../../api/origin/document-service'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemPowerUnit } from '../../../../../../hooks/useGridItemPowerUnit'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'

type Props = {}

const isValidCarrier = createValidator(2, 64)
const isValidPolicyNumber = createValidator(8, 24)

export const PowerUnitCredentials = (props: Props) => {
  const { powerUnit } = useGridItemPowerUnit()
  const { modifyParentObjectField, setFetching } = useExpandedItem()

  return (
    <Credentials minRows={70}>
      <Container rows={50} flexBasis={'auto'} margin={{ top: 40, left: 50, right: 50 }}>
        <Column columns={9} isGrid={true} margin={{ right: 100 }}>
          <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
            Annual Inspections
          </Title>
          {/*dotSafetyInspection*/}
          <Column columns={9} isGrid={true}>
            <Date margin={{ bottom: 25 }}>
              <DateTimePicker
                title={'Emissions Inspection Date'}
                required={true}
                date={powerUnit.dotSafetyInspection.performedDate}
                onChange={performedDate =>
                  modifyParentObjectField('dotSafetyInspection')({ ...powerUnit.dotSafetyInspection, performedDate })
                }
              />
            </Date>
            <Date margin={{ bottom: 20 }}>
              <DateTimePicker
                title={'Annual FMCSR Inspection Date'}
                required={true}
                date={powerUnit.dotSafetyInspection.expirationDate}
                onChange={expirationDate =>
                  modifyParentObjectField('dotSafetyInspection')({ ...powerUnit.dotSafetyInspection, expirationDate })
                }
              />
            </Date>
            <ViewingObjectFileUploader
              required={true}
              uploadingFileId={powerUnit.id + ' powerUnit.dotSafetyInspection.documentFile'}
              title={'Doc. Image'}
              file={powerUnit.dotSafetyInspection.documentFile}
              historyProps={{ entityId: powerUnit.id, type: FileVersionDTO.TypeEnum.MACHINERYDOTSAFETYINSPECTION }}
              onFileUploaded={(documentFile, modifiedObject) => ({
                ...modifiedObject,
                dotSafetyInspection: { ...modifiedObject.dotSafetyInspection, documentFile }
              })}
              onClearFile={() =>
                modifyParentObjectField('dotSafetyInspection')({
                  ...powerUnit.dotSafetyInspection,
                  documentFile: undefined
                })
              }
            />
          </Column>
        </Column>

        <Column columns={9} isGrid={true} margin={{ right: 40 }}>
          <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
            Insurance
          </Title>
          <Select
            title={'Type'}
            required={true}
            selectedValue={powerUnit.insurance.insuranceType}
            list={powerUnitLists.insuranceType}
            onSelect={insuranceType => modifyParentObjectField('insurance')({ ...powerUnit.insurance, insuranceType })}
          />
          <Container margin={{ top: 25, bottom: 20 }}>
            <Input
              title={'Carrier'}
              required={true}
              valid={isValidCarrier(powerUnit.insurance.carrier)}
              maxLength={64}
              value={powerUnit.insurance.carrier}
              onChange={carrier => modifyParentObjectField('insurance')({ ...powerUnit.insurance, carrier })}
            />
          </Container>
          <Date>
            <DateTimePicker
              title={'Exp. Date'}
              required={true}
              date={powerUnit.insurance.expirationDate}
              onChange={expirationDate =>
                modifyParentObjectField('insurance')({ ...powerUnit.insurance, expirationDate })
              }
            />
          </Date>
        </Column>

        <Column columns={9} rows={26} isGrid={true} margin={{ top: 120 }}>
          <Container margin={{ bottom: 20 }}>
            <Input
              title={'Policy #'}
              required={true}
              value={powerUnit.insurance.policyNumber}
              valid={isValidPolicyNumber(powerUnit.insurance.policyNumber)}
              maxLength={24}
              onChange={policyNumber => modifyParentObjectField('insurance')({ ...powerUnit.insurance, policyNumber })}
            />
          </Container>
          <ViewingObjectFileUploader
            required={true}
            uploadingFileId={powerUnit.id + ' powerUnit.insurance.coverageCardFile'}
            title={'Image of Ins. Coverage Card'}
            file={powerUnit.insurance.coverageCardFile}
            historyProps={{ entityId: powerUnit.id, type: FileVersionDTO.TypeEnum.MACHINERYINSURANCECOVERAGE }}
            onFileUploaded={(coverageCardFile, modifiedObject) => ({
              ...modifiedObject,
              insurance: { ...modifiedObject.insurance, coverageCardFile }
            })}
            onClearFile={() =>
              modifyParentObjectField('insurance')({
                ...powerUnit.insurance,
                coverageCardFile: undefined
              })
            }
          />
        </Column>
      </Container>
      <Maintenance
        powerUnit={powerUnit}
        addMaintenance={() =>
          modifyParentObjectField('maintenanceInspections')([
            ...powerUnit.maintenanceInspections,
            generateMaintenanceInspection()
          ])
        }
        deleteMaintenance={id =>
          modifyParentObjectField('maintenanceInspections')(
            powerUnit.maintenanceInspections.filter(item => item.id !== id)
          )
        }
        changeMaintenance={maintenance =>
          modifyParentObjectField('maintenanceInspections')(
            powerUnit.maintenanceInspections.map(item => (item.id === maintenance.id ? maintenance : item))
          )
        }
        changeMaintenanceInspectionFrequency={modifyParentObjectField('maintenanceInspectionFrequency')}
        setFetching={setFetching}
      />
    </Credentials>
  )
}
