import * as React from 'react'
import styled from '../../../styles/styledComponents'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { addNewGridItem } from '../../../store/reducers/tabs/functions'
import { CommunicationHubConstants } from '../../../constants/CommunicationHubConstants'

type StateProps = {
  tabId: string
  type: TabDTO.Type
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const FloatingAddButton = (props: Props) => {
  const { type, tabId } = props

  if (type === TabDTO.Type.dispatchDeliveryOrder) {
    return null
  }

  return (
    <AddButton className={'add-object-button mdi mdi-plus-circle'} onClick={() => addNewGridItem({ tabId, type })} />
  )
}

const AddButton = styled.div`
  position: fixed;
  bottom: 50px;
  right: calc(${CommunicationHubConstants.navigationViewWidth}px + 50px);
  color: #475264;
  font-size: 60px;
  transition: 0.25s;
  cursor: pointer;
  user-select: none;

  .communicationHubExpanded & {
    right: calc(${CommunicationHubConstants.navigationViewWidth + CommunicationHubConstants.channelViewWidth}px + 50px);
  }

  .communicationHubHidden & {
    right: 50px;
  }

  &:hover {
    color: #4555d0;
    transform: scale(1.1);
  }

  &:after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
  }
`
