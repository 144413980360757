import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { isNewId, isNewObject } from '../index'
import { tryToSave } from '../saveDTO'
import { DeliveryOrderViewDTO } from '../../../api/api'
import { updateTab } from '../../../store/reducers/tabs/functions'
import { getDispatch, getExpandedItemState, getListsState } from '../../../store'
import { tabActions } from '../../../store/reducers/tabs'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { filterModifiedDDOs } from '../../functions/filter/filterModifiedDDOs'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { requestDeliveryOrderById, requestUpdateDeliveryOrder } from '../../../components/common/deliveryOrder/epics'
import { requestDispatchDeliveryOrderBatchUpdate } from '../../../components/common/dispatchDeliveryOrder/epics'

export const saveDeliveryOrder = (expandedItemContext: TExpandedItemContext) => {
  const {
    tabId,
    parentItem,
    parentItemId,
    modifiedLists,
    setFetching,
    clearExpandedItemModifies,
    modifyListItems,
    deleteModifiedListItems
  } = expandedItemContext
  const deliveryOrder: DeliveryOrderViewDTO = parentItem
  const modifiedDeliveryOrder = oc(modifiedLists)[EntityType.deliveryOrder][parentItemId]()
  const store = getListsState()
  const isNewDeliveryOrder = isNewObject(deliveryOrder)
  let savedDeliveryOrder = deliveryOrder

  let modifiedDDOs = filterModifiedDDOs({ ddoIds: oc(deliveryOrder).dispatchDeliveryOrderIds([]), modifiedLists })
  const condition =
    testEntityVersion(store, EntityType.deliveryOrder)(modifiedDeliveryOrder) &&
    modifiedDDOs.every(testEntityVersion(store, EntityType.dispatchDeliveryOrder))

  const save = async () => {
    try {
      // CREATE DELIVERY ORDER
      if (modifiedDeliveryOrder && isNewDeliveryOrder) {
        savedDeliveryOrder = await requestUpdateDeliveryOrder({
          ...modifiedDeliveryOrder,
          dispatchDeliveryOrderIds: []
        })

        updateTab(tabId, {
          label: 'DO #' + savedDeliveryOrder.number,
          permissions: {
            localStorage: true
          },
          expandedItem: { ...getExpandedItemState(tabId), id: savedDeliveryOrder.id }
        })

        deleteModifiedListItems({ [EntityType.deliveryOrder]: [modifiedDeliveryOrder.id] })
        modifyListItems({
          [EntityType.deliveryOrder]: [
            {
              ...savedDeliveryOrder,
              dispatchDeliveryOrderIds: oc(modifiedDeliveryOrder).dispatchDeliveryOrderIds([])
            }
          ]
        })
      }

      // CREATE | UPDATE DISAPTCH DELIVERY ORDERS
      if (modifiedDDOs && modifiedDDOs.length) {
        if (isNewDeliveryOrder) {
          modifiedDDOs = modifiedDDOs.map(item => ({ ...item, deliveryOrderId: savedDeliveryOrder.id }))
          modifyListItems({ [EntityType.dispatchDeliveryOrder]: modifiedDDOs })
        }

        const savedDDOs = await requestDispatchDeliveryOrderBatchUpdate(modifiedDDOs)

        deleteModifiedListItems({ [EntityType.dispatchDeliveryOrder]: savedDDOs.map(_ => _.id) })

        if (modifiedDDOs.some(item => isNewObject(item))) {
          await requestDeliveryOrderById(savedDeliveryOrder.id)
        }
      }

      //  UPDATE DELIVERY ORDER
      if (modifiedDeliveryOrder && !isNewDeliveryOrder) {
        savedDeliveryOrder = await requestUpdateDeliveryOrder({
          ...modifiedDeliveryOrder,
          dispatchDeliveryOrderIds: oc(modifiedDeliveryOrder)
            .dispatchDeliveryOrderIds([])
            .filter(id => !isNewId(id))
        })
      }

      await requestDeliveryOrderById(savedDeliveryOrder.id, true)

      getDispatch()(tabActions.clearExpandedItemModifies({ tabId }))
    } catch (e) {}
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
