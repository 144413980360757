import { SellSideQuoteRateDTO } from '../../../api/origin/qmp-service'
import { AnyRateDTO } from '../../../components/common/QMP/interfaces'
import { MiscSurchargeDTO, SurchargeDTO } from '../../DTO/rate/interfaces'
import { getSurchargeAmount } from './getSurchargeAmount'

type Props = {
  rates: (AnyRateDTO | SurchargeDTO | MiscSurchargeDTO)[]
  returnOnNullResult?: any
}

export const getQuoteAmount = (props: Props): number | null => {
  const { rates, returnOnNullResult } = props

  if (rates.length) {
    const mainRate = rates.find(
      (rate: any) =>
        rate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN || rate.type === SellSideQuoteRateDTO.TypeEnum.BASE
    )
    return rates.reduce(
      (acc, surcharge) =>
        acc +
        getSurchargeAmount({
          mainRateAmount: mainRate ? mainRate.amount : 0,
          surcharge
        }),
      0
    )
  }

  return returnOnNullResult !== undefined ? returnOnNullResult : null
}
