import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'

export const ContainerSpinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StyledSpinner = styled.div`
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .lds-dual-ring {
    display: inline-block;
    width: 46px;
    height: 46px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid;
    border-color: ${theme.colors.basicBlueColor} transparent ${theme.colors.basicBlueColor} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledGridSpinner = styled(StyledSpinner)`
  background: rgba(245, 246, 250, 0.5);
`

const StyledGridItemSpinner = styled(StyledSpinner)`
  & ~ * {
    opacity: 0.5;
  }
`

type SpinnerProps = {
  style?: any
  text?: string
}

export const GridSpinner = ({ style }: SpinnerProps) => (
  // @ts-ignore
  <StyledGridSpinner style={style}>
    <div className={'lds-dual-ring'} />
  </StyledGridSpinner>
)

export const GridItemSpinner = ({ style, text }: SpinnerProps) => (
  // @ts-ignore
  <StyledGridItemSpinner style={style}>
    {text ? <FetchingTitle>{text}</FetchingTitle> : <div className={'lds-dual-ring'} />}
  </StyledGridItemSpinner>
)

export const InlineSpinner = () => (
  <ContainerSpinner>
    <LdsEllipsis backgroundColor={theme.colors.basicBlueColor}>
      <div />
      <div />
      <div />
    </LdsEllipsis>
  </ContainerSpinner>
)

// const Loader = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   z-index: 9999;
//   background: rgba(245, 246, 250, 0.5);
// `

const FetchingTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`

const LdsEllipsis = styled.div<{ backgroundColor: any }>`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${props => props.backgroundColor || '#fff'};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`
