import * as React from 'react'
import { Grid } from 'react-virtualized'
import { ColumnSize, NewRateDTO, RateType } from '../../../../../interfaces'
import { Row } from '../Row'
import { RateDTO } from '../../../../../../../../services/DTO/rate/interfaces'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { useApplicationSize } from '../../../../../../../../hooks/useApplicationSize'

type OwnProps = {
  columnSize: ColumnSize
  readOnly: boolean
  bodyRef: any
  rateType: RateType
  newRates: NewRateDTO[]
  // existedRates: AnyRateDTO[]
  rateIds: string[]
  // conflictOnSaveRates: AnyRateDTO[]
  editRate: (rate: NewRateDTO) => void
  onDelete: (rate: NewRateDTO) => void
  onSaveAnyway: (rate: NewRateDTO) => void
  copyRate: (rate: RateDTO) => void
  duplicateRate: (rate: RateDTO) => void
  disallowCopyRateToAnotherList: boolean
}

type StateProps = {}

type Props = OwnProps & StateProps

export const RateTabBlocks = React.memo((props: Props) => {
  const {
    columnSize,
    readOnly,
    bodyRef,
    rateType,
    newRates,
    rateIds,
    // conflictOnSaveRates,
    onSaveAnyway,
    onDelete,
    editRate,
    copyRate,
    duplicateRate,
    disallowCopyRateToAnotherList
  } = props
  const { communicationHubWidth } = useApplicationSize()
  const [resize, setResize] = React.useState<string>(null)
  const prevNewRatesCount = React.useRef(newRates.length)
  const scrollTop: boolean = prevNewRatesCount.current !== newRates.length
  prevNewRatesCount.current = newRates.length
  const containerHeight = oc(bodyRef).current.offsetHeight(6) - 10
  const containerWidth = oc(bodyRef).current.offsetWidth(6) - 6
  const gridItems: (string | NewRateDTO)[] = React.useMemo(() => {
    const result: (string | NewRateDTO)[] = []
    const conflictRateIds: string[] = []
    const conflictOnSaveNewRates: NewRateDTO[] = []
    const otherNewRates: NewRateDTO[] = []

    newRates.forEach(r => {
      if (r.duplicateIds && r.duplicateIds.length) {
        conflictOnSaveNewRates.push(r)
      } else {
        otherNewRates.push(r)
      }
    })

    conflictOnSaveNewRates.forEach(r => {
      result.push(...r.duplicateIds, r)
      conflictRateIds.push(...r.duplicateIds)
    })

    if (conflictOnSaveNewRates.length) {
      result.push(undefined) // to have an empty row for extra space
    }

    result.push(...otherNewRates)

    if (otherNewRates.length) {
      result.push(undefined) // to have an empty row for extra space
    }

    result.push(...rateIds.filter(id => !conflictRateIds.includes(id)))

    return result
  }, [newRates, rateIds])

  React.useLayoutEffect(() => {
    setResize(`${columnSize}${communicationHubWidth}`)
  }, [columnSize, communicationHubWidth])

  const rowRenderer = React.useCallback(
    ({ key, rowIndex, style }: any) => {
      const gridItem = gridItems[rowIndex]

      if (!gridItem) {
        return <div key={key} style={style} />
      }

      const isNewRate = typeof gridItem === 'object'
      const newRate = isNewRate ? gridItem : undefined
      const rateId = !isNewRate ? gridItem : undefined

      return (
        <div key={key} style={style}>
          <Row
            className={cn({
              table__row_dark: rowIndex % 2 === 0,
              table__row_first: rowIndex === 0,
              table__row_last: rowIndex === gridItems.length - 1
            })}
            columnSize={columnSize}
            readOnly={readOnly}
            rateType={rateType}
            newRate={newRate}
            rateId={rateId}
            onEdit={isNewRate ? () => editRate(gridItem) : undefined}
            onDelete={isNewRate ? () => onDelete(gridItem) : undefined}
            onSaveAnyway={isNewRate ? () => onSaveAnyway(gridItem) : undefined}
            duplicateRate={!isNewRate ? duplicateRate : undefined}
            copyRate={!isNewRate && !disallowCopyRateToAnotherList ? copyRate : undefined}
          />
        </div>
      )
    },
    [
      gridItems,
      rateType,
      editRate,
      onDelete,
      onSaveAnyway,
      duplicateRate,
      disallowCopyRateToAnotherList,
      copyRate,
      columnSize
    ]
  )

  return (
    <Grid
      height={containerHeight}
      width={containerWidth}
      columnCount={1}
      columnWidth={containerWidth - 7}
      rowCount={gridItems.length}
      rowHeight={50}
      scrollToRow={scrollTop ? 0 : undefined}
      cellRenderer={rowRenderer}
    />
  )
})
