import * as React from 'react'
import styled from 'styled-components'
import { ArrowIcon } from '../../../../UI/icons/ArrowIcon'
import { ILocationGroup } from '../../interfaces'
import { oc } from 'ts-optchain'

type Props = {
  group: ILocationGroup
  onClick: () => void
}

export const LocationGroupListItem = (props: Props) => {
  const { group, onClick } = props

  return (
    <Container onClick={onClick}>
      <div>
        <Name>{group.name || 'Name'}</Name>
        <Details>{oc(group).ids([]).length + ' items'}</Details>
      </div>
      <Arrow className={'mdi mdi-chevron-right'} />
    </Container>
  )
}

const Arrow = styled.span`
  flex-shrink: 0;
  align-self: flex-start;
  font-size: 28px;
  color: #333333;
  opacity: 0.5;
  margin-left: auto;
  cursor: pointer;
  visibility: hidden;
`

const Name = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
`

const Details = styled.div`
  color: #333;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.5;
`

const Container = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f2;
  padding: 12px 0 12px 6px;

  &:hover {
    background-color: rgba(239, 241, 247, 0.7);
    border-radius: 8px;
    cursor: pointer;

    ${Arrow} {
      visibility: visible;
    }
  }
`
