import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useAppSelector } from './useAppSelector'
import { selectDispatchDeliveryOrders } from '../store/select/dispatchDeliveryOrderSelect'

type Props = {
  dispatchDeliveryOrderIds: string[]
  modifiedLists?: Partial<TListsState>
}

export const useDispatchDeliveryOrders = (props: Props) => {
  const { dispatchDeliveryOrderIds, modifiedLists } = props
  const storeDDOsMapping = useAppSelector(selectDispatchDeliveryOrders)
  const modifiedDDOsMapping = oc(modifiedLists)[EntityType.dispatchDeliveryOrder]({})

  const dispatchDeliveryOrders = React.useMemo(() => {
    return (dispatchDeliveryOrderIds || []).map(id => modifiedDDOsMapping[id] || storeDDOsMapping[id]).filter(Boolean)
  }, [dispatchDeliveryOrderIds, storeDDOsMapping, oc(modifiedLists)[EntityType.dispatchDeliveryOrder]()])

  return dispatchDeliveryOrders
}
