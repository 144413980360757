import { ContainerViewDTO } from '../../../api/origin/business-logic'

const ownership = {
  [ContainerViewDTO.OwnershipEnum.LINEOWNED]: 'SSL Owned',
  [ContainerViewDTO.OwnershipEnum.SHIPPEROWNED]: 'Shipper Owned'
}

export const containerDirectory = {
  ownership
}
