import styled from 'styled-components'

export const AppContainer = styled.div`
  display: flex;
  height: 100%;
`
export const IntermodalAppContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`
