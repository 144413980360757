import * as React from 'react'
import * as StyledComponents from 'styled-components'
import { ITheme } from './theme'

type StyledFunction<T> = StyledComponents.ThemedStyledFunction<T, ITheme>

const withProps = <T, U extends HTMLElement = HTMLElement>(
  styledFunction: StyledFunction<React.HTMLProps<U>>
): StyledFunction<T & React.HTMLProps<U>> => (styledFunction as any) as StyledFunction<T & React.HTMLProps<U>>

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = (StyledComponents as unknown) as StyledComponents.ThemedStyledComponentsModule<ITheme>

export { css, createGlobalStyle, keyframes, ThemeProvider, withProps }
export default styled
