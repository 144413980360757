export const generateRateNumber = (rateNumber: number, count?: number): string => {
  if (rateNumber === null || rateNumber === undefined) {
    return ''
  }

  const rateNum = String(rateNumber)
  if ((count || 5) > rateNum.length) {
    return Array((count || 5) - rateNum.length + 1).join('0') + rateNum
  } else {
    return rateNum
  }
}
