import axios from './axios'
import { DocumentDTO, FileDTO } from '../../api/origin/document-service'
import { FileCategory } from './interfaces'

export const fileServiceAPI = {
  uploadFile(props: {
    file: File
    type?: FileCategory.POD | FileCategory.TIR | undefined
    metadata?: any
    progressCallback?: (progress: number) => void
    options?: any
  }): Promise<FileDTO> {
    const { type, metadata, file, progressCallback, options } = props
    const fileData = new FormData()
    fileData.append('file', file)
    let _options = options

    if (metadata) {
      fileData.append('metadata', JSON.stringify(metadata))
    }

    if (progressCallback) {
      const onUploadProgress = (progressEvent: any) => {
        const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
        progressCallback(progress === 100 ? 99 : progress)
      }
      _options = _options ? { ..._options, onUploadProgress } : { onUploadProgress }
    }

    return axios
      .post('/file/upload', fileData, _options)
      .then(data => {
        if (data && typeof data.data === 'object') {
          return data.data
        } else {
          return null
        }
      })
      .catch(e => {
        throw e
      })
  },
  uploadDocument(props: {
    file: File
    type: FileCategory.POD | FileCategory.TIR
    metadata?: any
    progressCallback?: (progress: number) => void
    options?: any
  }): Promise<DocumentDTO> {
    const { type, metadata, file, progressCallback, options } = props
    const fileData = new FormData()
    let path = '/file/upload'
    let _options = options

    if (type === FileCategory.TIR) {
      path = '/document/tir'
      fileData.append('document', file)
    } else if (type === FileCategory.POD) {
      path = '/document/pod'
      fileData.append('document', file)
    } else {
      fileData.append('file', file)
    }

    if (metadata) {
      fileData.append('metadata', JSON.stringify(metadata))
    }

    if (progressCallback) {
      const onUploadProgress = (progressEvent: any) => {
        const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
        progressCallback(progress === 100 ? 99 : progress)
      }
      _options = _options ? { ..._options, onUploadProgress } : { onUploadProgress }
    }

    return axios
      .post(path, fileData, _options)
      .then(data => {
        if (data && typeof data.data === 'object') {
          return data.data
        } else {
          return null
        }
      })
      .catch(e => {
        throw e
      })
  }
}

export const openFileLink = (
  url: string,
  options?: {
    predownload?: boolean
    name?: string
  }
): void => {
  const { predownload, name } = options || {}

  const action = (href: string) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.style.display = 'none'
    link.download = name
    link.href = href
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (predownload) {
    fetch(url).then(t => {
      t.blob().then(b => {
        action(URL.createObjectURL(b))
      })
    })
  } else {
    action(url)
  }
}
