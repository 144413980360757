import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../api/origin/qmp-service'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useRate = (props: Props): SellSideQuoteRateDTO | BuySideQuoteRateDTO => {
  const { id, modifiedLists } = props
  const rate = useListItem({ id, type: EntityType.rate, modifiedLists })

  return rate
}
