import { createSelectListFromDirectory } from './index'
import { deductionDirectory } from '../DTO/deduction/directory'
import { DeductionType } from '../../api/origin/vendor-accounting-service'

const type = createSelectListFromDirectory(deductionDirectory.type)
const tableType = createSelectListFromDirectory(deductionDirectory.type, [
  DeductionType.ELD,
  DeductionType.PARKING,
  DeductionType.WCINSURANCE
])
const frequency = createSelectListFromDirectory(deductionDirectory.frequency)
const checkType = createSelectListFromDirectory(deductionDirectory.checkType)

export const deductionLists = {
  type,
  tableType,
  frequency,
  checkType
}
