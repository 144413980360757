import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import cn from 'classnames'
import DateField from '../../../../../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../../../../../UI/DatePicker/Interfaces'
import { TransportationActivityViewDTO } from '../../../../../../../../../../api/origin/business-logic'
import { ActivityColumn, DabbleColumn } from '../../../styles'
import { ActionButton } from '../../../../../../../../../UI/Buttons/ActionButton'
import theme from '../../../../../../../../../../styles/theme'
import { FileCategory } from '../../../../../../../../../../services/filesService/interfaces'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import { ActivityDocumentPopupView } from '../../../../../../../interfaces'

type Props = {}

export const CompletionActualDateActivityColumn = (props: Props) => {
  const {
    isDropBobtail,
    isPickBobtail,
    isModifiedMode,
    transportationRow,
    updateCompletionActualDate,
    rowNumber,
    stage,
    enablePODDocumnet,
    enableTIRDocumnet,
    gotoActivityCompleteDateRequired,
    gotoActivityCompleteDateDisabled,
    businessActivityCompleteDateRequired,
    businessActivityCompleteDateDisabled,
    podDocument,
    tirDocument,
    openActivityDocumentPopup
  } = useTransportationActivityRow()
  const showDocumentButtons =
    transportationRow.activityGroup.businessActivity.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
    ((enablePODDocumnet && !podDocument) || (enableTIRDocumnet && !tirDocument)) &&
    !businessActivityCompleteDateDisabled &&
    !transportationRow.activityGroup.businessActivity.completionActualDate

  React.useEffect(() => {
    if (
      isModifiedMode &&
      transportationRow.activityGroup.businessActivity.status !==
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
      !businessActivityCompleteDateDisabled &&
      transportationRow.activityGroup.businessActivity.completionActualDate &&
      ((enablePODDocumnet && !podDocument) || (enableTIRDocumnet && !tirDocument))
    ) {
      updateCompletionActualDate({ date: undefined, isGotoActivity: false })
    }
  })

  return (
    <>
      <DabbleColumn>
        <ActivityColumn
          data-id={[
            'go-to-activity-completionActualDate',
            transportationRow.activityGroup.gotoActivity.completionActualDate,
            'rowNumber-' + rowNumber,
            'stage-' + stage
          ].join(' ')}
          className={'border-bottom'}
        >
          <DateField
            disabled={gotoActivityCompleteDateDisabled}
            required={gotoActivityCompleteDateRequired}
            showTime={true}
            setTimeNowOnEmpty={true}
            placeholder={'MM/DD/YY'}
            date={transportationRow.activityGroup.gotoActivity.completionActualDate}
            dateType={DateTypes.DateISOString}
            // @ts-ignore
            onUpdate={date => updateCompletionActualDate({ date, isGotoActivity: true })}
          />
        </ActivityColumn>
        <ActivityColumn
          data-id={[
            'business-activity-completionActualDate',
            transportationRow.activityGroup.businessActivity.completionActualDate,
            'rowNumber-' + rowNumber,
            'stage-' + stage
          ].join(' ')}
          data-tip={true}
          data-for={showDocumentButtons ? 'disabled-create-activity-document-action' : undefined}
        >
          {!isDropBobtail && (
            <>
              {showDocumentButtons ? (
                <>
                  <ActionButton
                    filled={true}
                    styles={{ width: 110 }}
                    disabled={isModifiedMode}
                    onClick={() => openActivityDocumentPopup(ActivityDocumentPopupView.CREATEDOCUMENT, false)}
                  >
                    Upload {enableTIRDocumnet ? FileCategory.TIR : enablePODDocumnet ? FileCategory.POD : 'Documnet'}
                  </ActionButton>
                  <TroubleTicketButton
                    className={cn('mdi mdi-alert', { disable: isModifiedMode })}
                    onClick={() =>
                      isModifiedMode
                        ? undefined
                        : openActivityDocumentPopup(ActivityDocumentPopupView.CREATETROUBLETICKET, false)
                    }
                  />
                  {isModifiedMode && (
                    <ReactTooltip
                      className={'react-tooltip react-tooltip_no-opacity'}
                      id={'disabled-create-activity-document-action'}
                      backgroundColor={'white'}
                      textColor={'black'}
                      borderColor={'#e5e5e5'}
                      border={true}
                    >
                      Save DDO changes to upload documents
                    </ReactTooltip>
                  )}
                </>
              ) : (
                <DateField
                  disabled={businessActivityCompleteDateDisabled}
                  required={businessActivityCompleteDateRequired}
                  showTime={true}
                  setTimeNowOnEmpty={true}
                  placeholder={'MM/DD/YY'}
                  date={transportationRow.activityGroup.businessActivity.completionActualDate}
                  dateType={DateTypes.DateISOString}
                  // @ts-ignore
                  onUpdate={date => updateCompletionActualDate({ date, isGotoActivity: false })}
                />
              )}
            </>
          )}
        </ActivityColumn>
      </DabbleColumn>
    </>
  )
}

export const completionActualDayNeedStatuses = [
  TransportationActivityViewDTO.StatusEnum.COMPLETED,
  TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
]

const TroubleTicketButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe7e7;
  border-radius: 4px;
  font-size: 18px;
  color: ${theme.colors.defaultRed};
  margin-left: auto;

  &.disable {
    opacity: 0.8;
  }

  &:not(.disable):hover {
    color: white;
    background-color: ${theme.colors.defaultRed};
    cursor: pointer;
  }
`
