export const animateMoveReorder = async (props: {
  element: HTMLElement
  direction: 'up' | 'down'
  onAnimationFinishCallback?: () => void
}) => {
  const { element, direction, onAnimationFinishCallback } = props

  if (element) {
    try {
      const nextToElement = (direction === 'up' ? element.previousSibling : element.nextSibling) as HTMLElement

      if (nextToElement) {
        element.classList.add('movement')
        nextToElement.classList.add('movement')
        element.style.cssText = `z-index: 1;transform: translateY(${
          direction === 'up' ? -nextToElement.offsetHeight : nextToElement.offsetHeight
        }px);`
        nextToElement.style.cssText = `transform: translateY(${
          direction === 'up' ? element.offsetHeight : -element.offsetHeight
        }px);`

        await new Promise(resolve => {
          setTimeout(() => {
            element.classList.remove('movement')
            nextToElement.classList.remove('movement')
            element.style.cssText = ''
            nextToElement.style.cssText = ''
            resolve(undefined)
          }, 500)
        })
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
    }
  }

  if (onAnimationFinishCallback) {
    onAnimationFinishCallback()
  }
}
