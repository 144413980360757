import { RootState } from '..'
import { EquipmentDTO } from '../../api/api'

// SELECT ONE
export const selectEquipment = (id: string) => (state: RootState): EquipmentDTO | undefined => {
  const {
    lists: { equipment }
  } = state

  return equipment[id]
}

// SELECT MANY
export const selectEquipments = (state: RootState): Record<string, EquipmentDTO> => {
  return state.lists.equipment
}
