import * as React from 'react'
import { ColumnHeader, ColumnHeaderTitle } from '../../../../../Grid/styles'
import { ActionButton } from '../../../../../../../../UI/Buttons/ActionButton'

type Props = {
  title: string
  onAddNewRule: () => void
}

export const RuleHeader = (props: Props) => {
  const { title, onAddNewRule } = props

  return (
    <ColumnHeader>
      <ColumnHeaderTitle>{title}</ColumnHeaderTitle>
      <ActionButton
        disabled={true}
        filled={true}
        className={'mdi mdi-plus-circle-outline'}
        styles={{ position: 'absolute', right: 15, top: '50%', transform: 'translateY(-50%)' }}
        onClick={onAddNewRule}
      >
        Add new
      </ActionButton>
    </ColumnHeader>
  )
}
