import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderTabContent } from '../styles'
import styled from '../../../../../../styles/styledComponents'
import { getDispatchDeliveryOrderNotes } from '../../../epics'
import { GridSpinner } from '../../../../../UI/Spinner/Spinner'
import { getStringConvertISODateToDateWithHoursMins } from '../../../../../../services/timeService/dateUtils'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'

const extraContainerStyles = {
  fontSize: 14,
  overflow: 'auto',
  padding: '25px 60px'
}

export const TerminalNotes = () => {
  const { dispatchDeliveryOrder } = useGridItemDDO()
  const { isFetching, setFetching } = useExpandedItem()
  const [terminalNotes, setTerminalNotes] = React.useState([])

  React.useEffect(() => {
    setFetching(true)
    getDispatchDeliveryOrderNotes(oc(dispatchDeliveryOrder).id())
      .then(requestedTerminalNotes => {
        if (requestedTerminalNotes && requestedTerminalNotes.length) {
          setTerminalNotes(requestedTerminalNotes)
        }
      })
      .finally(() => {
        setFetching(false)
      })
  }, [oc(dispatchDeliveryOrder).id()])

  return (
    <DispatchDeliveryOrderTabContent style={extraContainerStyles}>
      {isFetching && <GridSpinner />}
      <SContainer>
        {terminalNotes.map(terminalNote => (
          <STerminalNote key={terminalNote.createdAt}>
            <SCreatedAt>{getStringConvertISODateToDateWithHoursMins(terminalNote.createdAt, true, false)}</SCreatedAt>
            <SText>{terminalNote.notes || 'Empty note'}</SText>
          </STerminalNote>
        ))}
      </SContainer>
    </DispatchDeliveryOrderTabContent>
  )
}

const SContainer = styled.div`
  width: 100%;
`
const STerminalNote = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid grey;
`
const SCreatedAt = styled.div`
  color: #4555d0;
  font-size: 12px;
  margin-bottom: 15px;
`
const SText = styled.div``
