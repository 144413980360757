import { arrayMove } from 'react-sortable-hoc'
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ITab, ITabUpdate, ITabExpandedItem, ITabExpandedItemData, TabDTO } from './interfaces'
import { isTabIdPresents } from './functions'
import { generateTab } from '../../../services/functions/generate/generateTab'
import { tabSessionStorage } from '../../../services/tabs/functions'
import { EntityType } from '../lists/interfaces'
import { generateTabExpandedItem } from '../../../services/functions/generate/generateTabExpandedItem'
import { entityTypeByTabTypeMapping } from '../../../constants/entityTypeByTabTypeMapping'
import { isNewId } from '../../../services/DTO'

const saveToLocalStorageOnAction = ({ type }: AnyAction): boolean => {
  return [
    String(tabActions.createTab),
    String(tabActions.setActiveTabId),
    String(tabActions.deleteAllTabsExcept),
    String(tabActions.updateTab),
    String(tabActions.setTabData),
    String(tabActions.updateTabData),
    String(tabActions.moveTab),
    String(tabActions.duplicateTab),
    String(tabActions.deleteTab),
    String(tabActions.deleteRightTabs),
    String(tabActions.setExpandedItem),
    String(tabActions.replaceCreatedGridItem),
    String(tabActions.setExpandedItemActiveTab),
    String(tabActions.clearExpandedItemModifies),
    String(tabActions.closeExpandedItem)
  ].includes(type)
}

const tabsSlice = createSlice({
  name: 'tabs',
  initialState: [] as ITab[],
  reducers: {
    set: (tabsState, { payload }: PayloadAction<ITab[]>) => {
      return payload
    },
    setActiveTabId: (tabsState, { payload }: PayloadAction<string>) => {
      const tabId = payload

      if (isTabIdPresents(tabsState, tabId)) {
        return tabsState.map(tab => {
          if (tab.id === tabId) {
            return { ...tab, active: true }
          } else if (tab.active) {
            return { ...tab, active: false }
          }

          return tab
        })
      }
    },
    setTabFetching: (tabsState, { payload }: PayloadAction<{ tabId: string; fetchingState: boolean }>) => {
      const { tabId, fetchingState } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        return tabsState.map(tab => (tab.id === tabId ? { ...tab, fetching: fetchingState } : tab))
      }
    },
    createTab: (tabsState, { payload }: PayloadAction<{ type: TabDTO.Type; options?: Partial<ITabUpdate> }>) => {
      const { type, options } = payload
      const tabTypeCount = tabsState.filter(tab => tab.type === type).length
      const newTab = generateTab(type, options, tabTypeCount + 1)
      newTab.active = true

      return tabsState.map(tab => (tab.active ? { ...tab, active: false } : tab)).concat(newTab)
    },
    updateTab: (tabsState, { payload }: PayloadAction<{ tabId: string; options: Partial<ITabUpdate> }>) => {
      const { tabId, options } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        return tabsState.map(tab => {
          if (tab.id === tabId) {
            const permissions = { ...tab.permissions, ...(options.permissions || {}) }
            const uiSettings = { ...tab.uiSettings, ...(options.uiSettings || {}) }
            const data = { ...(tab.data || {}), ...(options.data || {}) }

            return { ...tab, ...options, permissions, uiSettings, data }
          }

          return tab
        })
      }
    },
    moveTab: (tabsState, { payload }: PayloadAction<{ currentTab: ITab; insertBeforeTab: ITab }>) => {
      const { currentTab, insertBeforeTab } = payload
      const currentTabIndex = tabsState.findIndex(({ id }) => id === currentTab.id)
      const insertBeforeTabIndex = tabsState.findIndex(({ id }) => id === insertBeforeTab.id)
      // let updatedTabsState = arrayMove(tabsState, currentTabIndex, insertBeforeTabIndex)

      // if (!currentTab.active) {
      //   updatedTabsState = updatedTabsState.map(tab =>
      //     tab.id === currentTab.id ? { ...tab, active: true } : tab.active ? { ...tab, active: false } : tab
      //   )
      // }

      return arrayMove(tabsState, currentTabIndex, insertBeforeTabIndex)
    },
    duplicateTab: (tabsState, { payload }: PayloadAction<{ originalTab: ITab }>) => {
      const { originalTab } = payload
      const tabTypeCount = tabsState.filter(tab => tab.type === originalTab.type).length
      const newTab = generateTab(originalTab.type, R.omit(originalTab, ['label', 'id']), tabTypeCount + 1)
      newTab.active = true

      return tabsState.map(tab => (tab.active ? { ...tab, active: false } : tab)).concat(newTab)
    },
    setTabData: (tabsState, { payload }: PayloadAction<{ tabId: string; data: any; visited?: boolean }>) => {
      const { tabId, data, visited } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        return tabsState.map(tab =>
          tab.id === tabId ? { ...tab, data, visited: typeof visited === 'boolean' ? visited : tab.visited } : tab
        )
      }
    },
    updateTabData: (tabsState, { payload }: PayloadAction<{ tabId: string; options: any; visited?: boolean }>) => {
      const { tabId, options, visited } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        return tabsState.map(tab =>
          tab.id === tabId
            ? {
                ...tab,
                data: { ...(tab.data || {}), ...options },
                visited: typeof visited === 'boolean' ? visited : tab.visited
              }
            : tab
        )
      }
    },
    deleteTab: (tabsState, { payload }: PayloadAction<string>) => {
      const tabId = payload

      if (isTabIdPresents(tabsState, tabId)) {
        let deletedTabIndex = 0
        const selectedTab = tabsState.find((tab, index) => {
          if (tab.id === tabId) {
            deletedTabIndex = index

            return true
          }
        })

        let updatedTabs = tabsState.filter(tab => tab.id !== tabId)

        if (selectedTab.active) {
          const newActiveTabIndex = deletedTabIndex === 0 ? 0 : deletedTabIndex - 1
          updatedTabs = updatedTabs.map((tab, index) => (index === newActiveTabIndex ? { ...tab, active: true } : tab))
        }

        return updatedTabs
      }
    },
    deleteAllTabsExcept: (tabsState, { payload }: PayloadAction<{ tabId: string }>) => {
      const { tabId } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        const selectedTab = tabsState.find(tab => tab.id === tabId)

        return selectedTab.active ? [selectedTab] : [{ ...selectedTab, active: true }]
      }
    },
    deleteRightTabs: (tabsState, { payload }: PayloadAction<{ tabId: string }>) => {
      const { tabId } = payload

      if (isTabIdPresents(tabsState, tabId)) {
        const selectedTabIndex = tabsState.findIndex(tab => tab.id === tabId)

        return tabsState
          .filter((tab, index) => index <= selectedTabIndex)
          .map(tab => {
            if (tab.id === tabId) {
              return tab.active ? tab : { ...tab, active: true }
            }

            return tab.active ? { ...tab, active: false } : tab
          })
      }
    },
    replaceCreatedGridItem: (tabsState, { payload }: PayloadAction<{ tabId: string; gridItemId: string }>) => {
      const { tabId, gridItemId } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        const gridItemIds = tab.gridItemIds || []
        if (!gridItemIds.includes(gridItemId)) {
          tab.gridItemIds = [gridItemId, ...gridItemIds]
          tab.expandedItem = {
            ...tab.expandedItem,
            id: gridItemId,
            modifiedLists: {},
            initialLists: {},
            data: {}
          }
        }
      }
    },
    unshiftGridItem: (tabsState, { payload }: PayloadAction<{ tabId: string; gridItemId: string }>) => {
      const { tabId, gridItemId } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        if (!(tab.gridItemIds || []).includes(gridItemId)) {
          tab.gridItemIds = [gridItemId, ...(tab.gridItemIds || [])]
        }
      }
    },
    setFavoriteOngoingActivityState: (
      tabsState,
      { payload }: PayloadAction<{ tabId: string; ongoingActivityId: string; favoriteState: boolean }>
    ) => {
      const { tabId, ongoingActivityId, favoriteState } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.data = {
          ...(tab.data || {}),
          favoriteItemIds: { ...oc(tab).data.favoriteItemIds({}), [ongoingActivityId]: favoriteState }
        }
      }
    },
    setExpandedItem: (
      tabsState,
      { payload }: PayloadAction<{ tabId?: string; expandedItem: Partial<ITabExpandedItem> }>
    ) => {
      const { tabId, expandedItem } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        if (!expandedItem.entityType) {
          expandedItem.entityType = entityTypeByTabTypeMapping[tab.type]
        }

        tab.expandedItem = generateTabExpandedItem(expandedItem)
      }
    },
    mergeExpandedItem: (tabsState, { payload }: PayloadAction<{ tabId?: string; expandedItem: ITabExpandedItem }>) => {
      const { tabId, expandedItem } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem = { ...tab.expandedItem, ...expandedItem }
      }
    },
    setExpandedItemActiveTab: (tabsState, { payload }: PayloadAction<{ tabId?: string; activeTab: string }>) => {
      const { tabId, activeTab } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem.activeTab = activeTab
      }
    },
    modifyListItems: (
      tabsState,
      {
        payload
      }: PayloadAction<{
        tabId?: string
        items: {
          entityType: EntityType
          modifiedState: any
          initialState?: any
        }[]
      }>
    ) => {
      const { tabId, items } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        const { modifiedLists, initialLists } = tab.expandedItem

        items.forEach(item => {
          const { entityType, modifiedState, initialState } = item

          modifiedLists[entityType] = {
            ...(modifiedLists[entityType] || {}),
            [modifiedState.id]: modifiedState
          }

          if (initialState) {
            initialLists[entityType] = {
              ...(initialLists[entityType] || {}),
              [initialState.id]: initialState
            }
          }
        })
      }
    },
    deleteModifiedListItems: (
      tabsState,
      {
        payload
      }: PayloadAction<{
        tabId?: string
        items: {
          entityType: EntityType
          id: string
        }[]
      }>
    ) => {
      const { tabId, items } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        const { modifiedLists, initialLists } = tab.expandedItem

        items.forEach(item => {
          const { entityType, id } = item

          if (modifiedLists[entityType] && modifiedLists[entityType][id]) {
            delete modifiedLists[entityType][id]
          }

          if (initialLists[entityType] && initialLists[entityType][id]) {
            delete initialLists[entityType][id]
          }
        })
      }
    },
    setExpandedItemData: (tabsState, { payload }: PayloadAction<{ tabId?: string; data: ITabExpandedItemData }>) => {
      const { tabId, data } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem.data = data
      }
    },
    mergeExpandedItemData: (
      tabsState,
      { payload }: PayloadAction<{ tabId?: string; mergeProps: Partial<ITabExpandedItemData> }>
    ) => {
      const { tabId, mergeProps } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem.data = {
          ...(tab.expandedItem.data || {}),
          ...mergeProps
        }
      }
    },
    clearExpandedItemModifies: (tabsState, { payload }: PayloadAction<{ tabId: string }>) => {
      const { tabId } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem = { ...tab.expandedItem, modifiedLists: {}, initialLists: {} }
      }
    },
    closeExpandedItem: (tabsState, { payload }: PayloadAction<{ tabId?: string }>) => {
      const { tabId } = payload
      const tab = seletectTab(tabId, tabsState)

      if (tab) {
        tab.expandedItem = generateTabExpandedItem()
      }
    }
  },
  extraReducers(builder) {
    builder.addMatcher(saveToLocalStorageOnAction, (tabState, action) => {
      const correctViewingObject = (expandedItem: ITabExpandedItem) => {
        if (isNewId(expandedItem.id)) {
          return generateTabExpandedItem()
        }

        return R.omit(
          {
            ...expandedItem,
            modifiedLists: {},
            initialLists: {}
          },
          ['data']
        )
      }

      const updateTabs = tabState
        .filter(tab => tab.permissions.localStorage)
        .map(
          (tab): ITab => {
            const correctedTab = {
              ...tab,
              visited: false,
              fetching: false,
              expandedItem: correctViewingObject(tab.expandedItem)
            }

            if (tab.type === TabDTO.Type.QMP) {
              correctedTab.data = {
                ...correctedTab.data,
                openedCustomerQuoteId: null,
                ssqIds: [],
                bsqIds: [],
                customerQuoteIds: [],
                newSSQRates: [],
                newBSQRates: [],
                newCustomerQuotes: null,
                ratesTabVisited: false,
                customerQuotesTabVisited: false
              }
            } else {
              correctedTab.gridItemIds = null
            }

            return correctedTab
          }
        )
      const activeTab = updateTabs.find(tab => tab.active)

      if (!activeTab) {
        const lastTab = updateTabs[updateTabs.length - 1]

        if (lastTab) {
          updateTabs[updateTabs.length - 1] = { ...lastTab, active: true }
        }
      }

      tabSessionStorage.tabs.set(updateTabs)
    })
  }
})

const seletectTab = (tabId: string | undefined, tabs: ITab[]): ITab => {
  if (tabId) {
    return tabs.find(tab => tab.id === tabId)
  }

  return tabs.find(tab => tab.active)
}

export const tabActions = tabsSlice.actions
export default tabsSlice.reducer
