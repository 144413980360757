import * as React from 'react'
import { axiosCallAPIWithErrorMessage } from '../api/api'
import { DocumentDTO, FileDTO } from '../api/origin/document-service'
import { fileServiceAPI } from '../services/filesService'
import { generateFileTemplate } from '../services/filesService/generateFileTemplate'
import { FileCategory, IAttachment } from '../services/filesService/interfaces'
import { useUploadFilesStore } from './useUploadFilesStore'

type Props = {
  streamId: string
  documentType?: FileCategory.POD | FileCategory.TIR | undefined
  metadata?: any
  onFileUploaded?: (file: FileDTO | DocumentDTO) => void
  onFileUploadError?: (error: any) => void
  inputRef?: React.MutableRefObject<HTMLInputElement>
}

type ReturnProps = {
  onFilesSelect: (files: FileList) => void
  uploadingFilesState: IAttachment[] | undefined
}

export const useUploadFiles = (props: Props): ReturnProps => {
  const { documentType, metadata, streamId, inputRef, onFileUploaded, onFileUploadError } = props
  const { uploadingFiles, setUploadingFiles, clearUploadingFiles } = useUploadFilesStore()

  const uploadingFilesState = React.useMemo(
    () => uploadingFiles[streamId] && Object.values(uploadingFiles[streamId] as Record<string, IAttachment>),
    [uploadingFiles && uploadingFiles[streamId]]
  )

  const clearInputFiles = () => {
    if (!(inputRef && inputRef.current)) {
      return
    }

    inputRef.current.type = '' // It's a hack to reset input
    inputRef.current.type = 'file' // To allow attach same files multiple times
  }

  const onFilesSelect = (files: FileList) => {
    if (files && files[0]) {
      Array.from(files).forEach((file, index) => {
        const temporaryAttachment = generateFileTemplate(file, {
          uploadingProgress: 0
        })

        const fileId = String(index)
        setUploadingFiles(streamId, fileId, temporaryAttachment)

        let request = fileServiceAPI.uploadFile
        const requestProps = {
          file,
          metadata,
          progressCallback: (uploadingProgress: number) => {
            setUploadingFiles(streamId, fileId, { ...temporaryAttachment, uploadingProgress })
          }
        }

        if (documentType) {
          // @ts-ignore
          requestProps.type = documentType
          // @ts-ignore
          request = fileServiceAPI.uploadDocument
        }

        axiosCallAPIWithErrorMessage(request, requestProps)
          .then((resFile: FileDTO) => {
            if (onFileUploaded) {
              onFileUploaded(resFile)
            }

            clearUploadingFiles(streamId, fileId)
          })
          .catch(error => {
            // tslint:disable-next-line:no-console
            console.error('FileUploader error', error)

            if (onFileUploadError) {
              onFileUploadError(error)
            }

            clearUploadingFiles(streamId, fileId)
          })
      })
    }

    clearInputFiles()
  }

  return {
    onFilesSelect,
    uploadingFilesState
  }
}
