import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { UserAvatar, UserAvatarSize } from '../UserAvatar'
import { MessageActions } from './MessageActions'
import { Icon, IconMode, IconName } from '../Icon'
import theme from '../../../../styles/theme'
import { Attachments } from '../Attachments/Attachments'
import { TroubleTicketDTO } from '../../../../api/api'
// tslint:disable:max-line-length
import { LabelResolve } from '../../../common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Popups/TroubleTicket/styles'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { troubleTicketActionsId } from '../../../Hints/TroubleTicketActions'
import { deleteChatMessage, pinChatMessage, unpinChatMessage } from '../../epics'
import { MessageDTO, MessageType } from '../../../../api/origin/communication-hub-service'
import { InfoSender, MessageHeader } from './MessageHeader'
import { MessageBody } from './MessageBody'
import { MessageEditor } from './MessageEditor'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectCommunictaionHubEditMessageId,
  selectCommunictaionHubMessage
} from '../../../../store/select/communicationHubSelect'
import { selectUser } from '../../../../store/select/userSelect'
import { MessageDeliveryStatus } from '../../../../store/reducers/communicationHub/interfaces'
import { selectAuthUser } from '../../../../store/select/authUserSelect'
import { isMessageRead } from '../../../../services/functions/test/isMessageRead'

type TMessageInfoData = {
  icon: JSX.Element
  senderName: string
  senderId: string
}

type OwnProps = {
  messageId: string
  disableMessageActions?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Message = React.memo((props: Props) => {
  const { messageId, disableMessageActions } = props
  const dispatch = useAppDispatch()
  const authUser = useAppSelector(selectAuthUser)
  const message = useAppSelector(selectCommunictaionHubMessage(messageId))
  const editMessageId = useAppSelector(selectCommunictaionHubEditMessageId)
  const sender = useAppSelector(selectUser(oc(message).senderId()))
  const resolvedByUser = useAppSelector(selectUser(oc(message).metadata.resolvedByUserId()))
  const { isSMS, isPinned, metadata } = message
  const [showActions, setShowActions] = React.useState(false)
  const isMessageOwner: boolean = React.useMemo(() => oc(sender).id() === oc(authUser).id(), [])
  const isWorkOrder = message.type === MessageType.WORKORDER
  // @ts-ignoreauthUser
  const alertDocumentType = oc(message).metadata.type() as TroubleTicketDTO.TypeEnum
  const isSMSError = [MessageDTO.SmsStatusEnum.UNSENT, MessageDTO.SmsStatusEnum.UNDELIVERED].includes(message.smsStatus)
  // const deliveryStatus: MessageDeliveryStatus | undefined = React.useMemo(() => {
  //   if (message && [MessageType.MESSAGE, MessageType.ALERT].includes(message.type)) {
  //     if (isMessageRead(message)) {
  //       return MessageDeliveryStatus.VIEWED
  //     } else {
  //       return MessageDeliveryStatus.DELIVERED
  //     }

  //     // return MessageDeliveryStatus.SENDING
  //   }

  //   return undefined
  // }, [])

  const disableActions = React.useMemo(
    () => disableMessageActions || message.type === MessageType.ALERT || editMessageId === message.id,
    [message.type, editMessageId, disableMessageActions]
  )

  const onMouseEnter = React.useCallback(disableActions ? undefined : () => setShowActions(true), [
    disableActions,
    setShowActions
  ])
  const onMouseLeave = React.useCallback(disableActions ? undefined : () => setShowActions(false), [
    disableActions,
    setShowActions
  ])
  const messageInfoData: TMessageInfoData = React.useMemo(() => {
    const result: TMessageInfoData = {
      icon: null,
      senderName: '',
      senderId: undefined
    }

    switch (message.type) {
      case MessageType.ALERT:
        result.senderName = 'Alert'
        result.icon = (
          <AlertIcon
            className={cn('mdi mdi-alert-decagram', {
              resolved: message.status !== TroubleTicketDTO.StatusEnum.NEW
            })}
          />
        )
        break
      default:
        result.senderName = oc(sender).name('Default User Name')
        result.senderId = oc(sender).id()
        result.icon = <UserAvatar showUserDetails={true} user={sender} size={UserAvatarSize.medium} />
    }

    return result
  }, [message.type, message.status])

  const setEditMessage = React.useCallback(() => {
    dispatch(communicationHubActions.setEditMessageId(message.id))
  }, [message.id])

  if (!message) {
    return null
  }

  const hideModifiedStatus = message.type === MessageType.ALERT

  const RenderBottomSection = React.useMemo(() => {
    switch (message.smsStatus || message.type) {
      case MessageType.ALERT: {
        const isNew = message.status === TroubleTicketDTO.StatusEnum.NEW
        const isProceed = message.status === TroubleTicketDTO.StatusEnum.PROCEED
        const isUnsuccessful = message.status === TroubleTicketDTO.StatusEnum.UNSUCCESSFUL
        const RenderComponents: JSX.Element[] = [<Attachments key={'attachments'} attachments={message.attachments} />]

        if (oc(metadata).troubleTicketId()) {
          if (isProceed || isUnsuccessful) {
            RenderComponents.push(
              <ResolvedAlertStatus key={'resolved-trouble-ticket'}>
                <span
                  className={cn('mdi mdi-18px', {
                    'mdi-play-circle': isProceed,
                    'mdi-close-circle': isUnsuccessful
                  })}
                />
                <div>Resolved {resolvedByUser ? 'by' : ''}</div>
                <ResolvedBy>
                  {Boolean(resolvedByUser) && (
                    <InfoSender data-for={'user-details-hint'} data-tip={resolvedByUser.id}>
                      {resolvedByUser.name}
                    </InfoSender>
                  )}
                </ResolvedBy>
                <div>
                  as <span>{isProceed ? 'Proceed' : 'Unsuccessful'}</span>
                </div>
              </ResolvedAlertStatus>
            )
          } else if (
            isNew &&
            !(
              alertDocumentType === TroubleTicketDTO.TypeEnum.TIR || alertDocumentType === TroubleTicketDTO.TypeEnum.POD
            )
          ) {
            RenderComponents.push(
              <TroubleTicketAlertButtons key={'trouble-ticket-actions'}>
                <ActionButton
                  round={true}
                  filled={true}
                  onClick={undefined}
                  options={{ 'data-for': troubleTicketActionsId, 'data-tip': metadata.troubleTicketId }}
                >
                  <LabelResolve className={'mdi mdi-menu-down'}>Resolve</LabelResolve>
                </ActionButton>
              </TroubleTicketAlertButtons>
            )
          }
        }

        if (oc(message).metadata.notes()) {
          RenderComponents.push(<Notes key={'trouble-ticket-notes'}>{message.metadata.notes}</Notes>)
        }
        return RenderComponents
      }
      case MessageDTO.SmsStatusEnum.UNSENT:
      case MessageDTO.SmsStatusEnum.UNDELIVERED: {
        const errorMessage = oc(message).errorMessage('Error sending the message')

        return (
          <SMSErrorSection>
            {errorMessage === "Can't send sms to user. Please try again later"
              ? "Can't send sms to user."
              : errorMessage}
            {/*{errorMessage === "Can't send sms to user. Please try again later" && (*/}
            {/*<ResendSMSButton>Click to try again</ResendSMSButton>*/}
            {/*)}*/}
          </SMSErrorSection>
        )
      }
      default:
        return
    }
  }, [message.type, message.status, message.attachments, alertDocumentType])

  return (
    <MessageContainer
      className={cn({ pinned: isPinned, error: isSMSError, 'edit-mode': editMessageId })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MessageIcons>
        {isPinned && <Icon style={{ color: '#E8743B' }} icon={IconName.PIN} mode={IconMode.DARK} />}
      </MessageIcons>
      {messageInfoData.icon}
      <MessageDetails>
        <MessageHeader
          hideModifiedStatus={hideModifiedStatus}
          senderId={messageInfoData.senderId}
          senderName={messageInfoData.senderName}
          messageCreatedAt={message.createdAt}
          messageUpdatedAt={message.updatedAt}
          smsStatus={message.smsStatus}
          // deliveryStatus={deliveryStatus}
        />
        {message.id === editMessageId ? (
          <MessageEditor message={message} />
        ) : (
          <>
            <MessageBody
              type={message.type}
              alertDocumentType={alertDocumentType}
              status={message.smsStatus || message.status}
              // @ts-ignore
              text={message.text}
              mentionUserIds={message.mentionUserIds}
              bottomSection={RenderBottomSection}
            />
            {message.type !== MessageType.ALERT && <Attachments attachments={message.attachments} />}
          </>
        )}
      </MessageDetails>
      {showActions && !disableActions && (
        <MessageActions
          pinned={isPinned}
          actionEdit={isMessageOwner && !isSMS && !isWorkOrder && !editMessageId ? setEditMessage : undefined}
          actionPin={() => (isPinned ? unpinChatMessage(message.id, message) : pinChatMessage(message.id, message))}
          actionDelete={
            isMessageOwner && !isSMS && !isWorkOrder
              ? () => {
                  showModalWindow({
                    width: 320,
                    title: 'Delete the message?',
                    buttons: [{ label: 'No' }, { label: 'Yes', onClick: () => deleteChatMessage(message.id) }]
                  })
                }
              : undefined
          }
        />
      )}
    </MessageContainer>
  )
})

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  padding: 6px 20px 6px 15px;
  cursor: default;

  &:hover {
    background-color: #e5e7ef25;
  }

  &.edit-mode {
    background: #e5e7ef25;
  }

  &.error,
  &.pinned {
    &:before {
      content: '';
      width: 3px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.error {
    background-color: ${theme.colors.defaultRed}15;

    &:before {
      background-color: ${theme.colors.defaultRed};
    }

    &.edit-mode {
      background-color: ${theme.colors.defaultRed}15 !important;
    }

    &:hover {
      background-color: ${theme.colors.defaultRed}25;
    }
  }

  &.pinned {
    background-color: rgba(255, 214, 0, 0.15);

    &:before {
      background-color: rgba(255, 214, 0, 1);
    }

    &.edit-mode {
      background-color: rgba(255, 214, 0, 0.15) !important;
    }

    &:hover {
      background-color: rgba(255, 214, 0, 0.25);
    }
  }
`
export const MessageDetails = styled.div`
  flex-grow: 1;
  padding-left: 8px;
`
const AlertIcon = styled.div`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.defaultRed};
  color: white;
  font-size: 18px;
  border-radius: 5px;

  &.resolved {
    background: ${theme.colors.defaultGray};
  }
`
const MessageIcons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 20px;
`
const TroubleTicketAlertButtons = styled.div`
  display: flex;
  margin-top: 8px;
`
const ResolvedAlertStatus = styled.div`
  color: #484848;
  cursor: default;
  display: flex;
  align-items: center;

  .mdi {
    margin: 0 6px 0 2px;
    color: #6973f6;
  }

  span {
    font-weight: 500;
  }
`
const Notes = styled.div`
  margin-top: 15px;

  &:before {
    content: 'Notes';
    display: block;
    font-weight: 500;
  }
`
const ResolvedBy = styled.div`
  margin: 0 2px;
`
const SMSErrorSection = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 500;
  color: ${theme.colors.defaultRed};
  line-height: 12px;
  margin-top: 8px;
  user-select: none;
`
const ResendSMSButton = styled.div`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 4px;
  cursor: pointer;
`
