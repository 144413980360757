import * as R from 'remeda'
import { isNewObject } from '..'
import { callAPI, callAPIWithErrorMessage, deductionAPI, deductionTransactionControllerAPI } from '../../../api/api'
import { CreateDeductionTransactionDTO, DeductionDTO } from '../../../api/origin/vendor-accounting-service'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { catchListErrors } from '../../tabs/functions'

export const requestDriverDeduction = (deductionId: string): Promise<DeductionDTO> => {
  return callAPI(deductionAPI.deductionControllerGetDeduction, deductionId)
    .toPromise()
    .then(async deduction => {
      await pushListItemsToStore({ update: { [EntityType.deduction]: [deduction] } })

      return deduction
    })
}

export const requestDriverDeductions = (driverId: string): Promise<DeductionDTO[]> => {
  return callAPI(deductionAPI.deductionControllerGetDeductionsByVendorId, driverId)
    .toPromise()
    .then(async deductions => {
      await pushListItemsToStore({ update: { [EntityType.deduction]: deductions } })

      return deductions as DeductionDTO[]
    })
    .catch(catchListErrors)
}

export const deleteDeductionById = (deductionId: string): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerRemove, deductionId)
}

export const createDeduction = (deduction: DeductionDTO): Promise<DeductionDTO> => {
  // @ts-ignore
  return callAPI(deductionAPI.deductionControllerCreate, R.omit(deduction, ['id', 'status', 'totalAmount']))
    .toPromise()
    .then(async _deduction => {
      await pushListItemsToStore({ update: { [EntityType.deduction]: [_deduction] } })

      return _deduction
    })
    .catch(catchListErrors)
}

export const updateDeduction = (deduction: DeductionDTO): Promise<DeductionDTO> => {
  if (isNewObject(deduction)) {
    return createDeduction(deduction)
  }
  // @ts-ignore
  return callAPI(deductionAPI.deductionControllerUpdate, deduction.id, R.omit(deduction, ['status', 'totalAmount']))
    .toPromise()
    .then(async _deduction => {
      await pushListItemsToStore({ update: { [EntityType.deduction]: [_deduction] } })

      return _deduction
    })
    .catch(catchListErrors)
}

export const requestCloseDeductionId = (deductionId: string): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerClose, deductionId)
}

export const requsetDeductionTransactions = (deductionId: string) => {
  return callAPIWithErrorMessage(
    deductionTransactionControllerAPI.deductionTransactionControllerGetDeductionTransactionsByDeductionId,
    deductionId
  )
}

export const requestCreateDeductionTransaction = (
  deductionTransaction: CreateDeductionTransactionDTO
): Promise<CreateDeductionTransactionDTO> => {
  return callAPIWithErrorMessage(
    deductionTransactionControllerAPI.deductionTransactionControllerCreate,
    deductionTransaction
  )
}

export const requsetDudectionTransactionReport = (deductionId: string): Promise<any> => {
  return callAPI(deductionTransactionControllerAPI.deductionTransactionControllerGetReport, deductionId)
    .toPromise()
    .then(data => data.text())
}
