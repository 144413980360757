import * as React from 'react'
import { oc } from 'ts-optchain'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TableCell } from '../../../grid/views/styles'
import { PowerUnitColumn } from '../../../grid/columnSettings/powerUnitColumnSettings'
import { equipmentDirectory } from '../../../../../services/DTO/equipment/directory'
import { getDateFromISOString } from '../../../../../services/timeService/dateUtils'
import { useGridItemPowerUnit } from '../../../../../hooks/useGridItemPowerUnit'
import { transport } from '../../../../UI/Selecter/styledComponents'
import { powerUnitDirectory } from '../../../../../services/DTO/powerUnit/directory'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'

const Column = React.memo((props: TGridColumn) => {
  const { className, columnName, style } = props
  const { powerUnit } = useGridItemPowerUnit()
  let text = columnName

  switch (columnName) {
    case PowerUnitColumn.TruckNumber: {
      text = oc(powerUnit).number()
      break
    }
    case PowerUnitColumn.ActiveAvailability:
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className} data-copy-value-on-mousedown={true}>
          <AvailabilityColumn />
        </TableCell>
      )
    case PowerUnitColumn.Type: {
      text = powerUnitDirectory.powerUnitType[oc(powerUnit).powerUnitType()]

      return (
        <TableCell className={className} style={style} title={text}>
          {text && (
            <>
              <img
                style={{ marginRight: 10 }}
                src={transport[oc(powerUnit).powerUnitType()]}
                width={20}
                height={30}
                alt={text}
              />
              {text}
            </>
          )}
        </TableCell>
      )
    }
    case PowerUnitColumn.Drivers: {
      text = oc(powerUnit)
        .drivers([])
        .map(driver => oc(driver).name())
        .filter(Boolean)
        .join(', ')
      break
    }
    case PowerUnitColumn.Year: {
      text = oc(powerUnit).year() && String(powerUnit.year)
      break
    }
    case PowerUnitColumn.Make: {
      text = oc(powerUnit).make()
      break
    }
    case PowerUnitColumn.Model: {
      text = oc(powerUnit).model()
      break
    }
    case PowerUnitColumn.GVW: {
      text =
        oc(powerUnit).weight() && oc(powerUnit).weightUnit() ? powerUnit.weight + ' ' + powerUnit.weightUnit : undefined
      break
    }
    case PowerUnitColumn.Ownership: {
      text = powerUnitDirectory.ownership[oc(powerUnit).ownership()]
      break
    }
    case PowerUnitColumn.LicensePlateNumber: {
      text = oc(powerUnit).licensePlate()
      break
    }
    case PowerUnitColumn.PortPassRFID: {
      text = oc(powerUnit).portPassRfid() ? oc(powerUnit).rfid() || 'Yes' : 'No'
      break
    }
    case PowerUnitColumn.RegistrationState: {
      text = getAddressState({ stateId: oc(powerUnit).registration.stateId() }).name
      break
    }
    case PowerUnitColumn.RegistrationDate: {
      text = getDateFromISOString(oc(powerUnit).registration.registeredDate())
      break
    }
    case PowerUnitColumn.RegistrationExpDate: {
      text = getDateFromISOString(oc(powerUnit).registration.expirationDate())
      break
    }
    case PowerUnitColumn.RegistrationGVW: {
      text = oc(powerUnit).registration.weight()
        ? oc(powerUnit).registration.weight() + ' ' + oc(powerUnit).registration.weightUnit()
        : undefined
      break
    }
    case PowerUnitColumn.DOTSafetyInspectionDate: {
      text = getDateFromISOString(oc(powerUnit).dotSafetyInspection.performedDate())
      break
    }
    case PowerUnitColumn.DOTSafetyInspectionAnnualExpDate: {
      text = getDateFromISOString(oc(powerUnit).dotSafetyInspection.expirationDate())
      break
    }
    case PowerUnitColumn.MaintenanceInspectionFrequency: {
      text = equipmentDirectory.maintenanceInspectionFrequency[oc(powerUnit).maintenanceInspectionFrequency()]
      break
    }
    case PowerUnitColumn.InsuranceType: {
      text = equipmentDirectory.insuranceType[oc(powerUnit).insurance.insuranceType()]
      break
    }
    case PowerUnitColumn.InsuranceCarrier: {
      text = oc(powerUnit).insurance.carrier()
      break
    }
    case PowerUnitColumn.InsuranceExpDate: {
      text = getDateFromISOString(oc(powerUnit).insurance.expirationDate())
      break
    }
    case PowerUnitColumn.InsurancePolicyNumber: {
      text = oc(powerUnit).insurance.policyNumber()
      break
    }
    case PowerUnitColumn.RegistrationRestrictions: {
      text = powerUnitDirectory.restrictions[oc(powerUnit).registration.restrictions()]
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
})

export default Column
