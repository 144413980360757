import { Action } from 'typescript-fsa'
// import { IUserState } from '../components/common/administration/users/interfaces'
import { TMsgType } from '../components/UI/Modal/actions'
import { TModalButtonSettings } from '../components/UI/Modal'
import { ICustomerQuotesState } from '../services/DTO/customerQuote/interfaces'
import { IStreetTurnState } from '../services/DTO/streetTurn/interfaces'

export enum ApplicationContext {
  main = 'main',
  admin = 'admin'
}

export interface IModalState {
  visible: boolean
  message: string
  msgType: TMsgType | ''
  buttonSettings?: TModalButtonSettings
  onConfirm: () => void
  onCancel: () => void
}

export enum AuthorizationState {
  Initial = 'Initial',
  Authorizing = 'Authorizing',
  Authorized = 'Authorized',
  FailedWithAuth = 'FailedWithAuth',
  ServerError = 'ServerError'
}

export interface IAuthorizationState {
  state: AuthorizationState
}

export interface IActionsToDispatchAfterSuccessfulAuthorizationState extends Array<Action<any>> {}

export interface IStore {
  applicationContext: ApplicationContext
  actionsToDispatchAfterSuccessfulAuthorization: IActionsToDispatchAfterSuccessfulAuthorizationState
  authorization: IAuthorizationState
  streetTurn: IStreetTurnState
  customerQuotes: ICustomerQuotesState
  modal: IModalState
}
