import * as React from 'react'
import { oc } from 'ts-optchain'
import { BusinessPartnerViewDTO, ContactDTO, LocationViewDTO } from '../api/api'
import { EntityType } from '../store/reducers/lists/interfaces'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { generateContact } from '../services/functions/generate/generateContact'
import { useContact } from '../hooks/useContact'
import { isContactFilled } from '../services/functions/test/isContactFilled'

interface IPrimaryContactContext {
  primaryContact: ContactDTO
  modifyPrimaryContactField: (field: keyof ContactDTO) => (value: any) => void
}

type Props = {
  parentEntity: LocationViewDTO | BusinessPartnerViewDTO
  entityType: EntityType.businessPartner | EntityType.location
}

export const usePrimaryContact = (props: Props): IPrimaryContactContext => {
  const { parentEntity, entityType } = props
  const { modifyListItems, modifiedLists, deleteModifiedListItems } = useExpandedItem()
  const primaryContactId = oc(parentEntity).primaryContactId()
  const primaryContact = useContact({ modifiedLists, id: primaryContactId })

  const modifyPrimaryContactField = (field: keyof ContactDTO) => (value: any) => {
    const contact = primaryContact || generateContact({ primary: true })

    if (!primaryContact) {
      modifyListItems({
        [entityType]: [
          { ...parentEntity, primaryContactId: contact.id, contactIds: [...parentEntity.contactIds, contact.id] }
        ]
      })
    }

    modifyListItems({ [EntityType.contact]: [{ ...contact, [field]: value }] })
  }

  React.useEffect(() => {
    if (primaryContact && !isContactFilled(primaryContact)) {
      const updatedContactIds = parentEntity.contactIds.filter((id: string) => id !== primaryContactId)
      const updateParentEntity = { ...parentEntity, contactIds: updatedContactIds }

      if (parentEntity.primaryContactId && !updatedContactIds.includes(parentEntity.primaryContactId)) {
        updateParentEntity.primaryContactId = undefined
      }

      modifyListItems({ [entityType]: [updateParentEntity] })
      deleteModifiedListItems({ [EntityType.contact]: [primaryContactId] })
    }
  }, [primaryContact])

  return {
    primaryContact,
    modifyPrimaryContactField
  }
}
