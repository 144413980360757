import * as R from 'remeda'
import { BusinessPartnerViewDTO, ContactDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.businessPartner]: BusinessPartnerViewDTO[]
  [EntityType.contact]: ContactDTO[]
}

export const parseBusinessPartners = async (businessPartners: BusinessPartnerViewDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.businessPartner]: [],
    [EntityType.contact]: []
  }

  try {
    businessPartners.forEach(businessPartner => {
      if (businessPartner.primaryContact) {
        result[EntityType.contact].push(businessPartner.primaryContact)
      }

      result[EntityType.businessPartner].push(R.omit(businessPartner, ['primaryContact']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
