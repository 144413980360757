import * as React from 'react'
import { oc } from 'ts-optchain'
import { Column, DDOLink, Text, TextLine, TextLines } from '../PreviewPhase'
import { openDDOinNewTab } from '../../../../../../../../services/DTO/dispatchDeliveryOrder'
import { TCalcPhase } from '../../../../../functions'
import { OngoingActivityDTO, TransportationActivityViewDTO } from '../../../../../../../../api/api'
import { DestinationTypeWithName } from './DestinationTypeWithName'
import { isLocationGEOValid } from '../../../../../../../../services/functions/test/isLocationGEOValid'
import theme from '../../../../../../../../styles/theme'
import { useLocation } from '../../../../../../../../hooks/useLocation'
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector'
import { selectState } from '../../../../../../../../store/select/stateSelect'

type Props = {
  phase: TCalcPhase
  isNowSection?: boolean
  isExtraActivity?: boolean
  isTwoActivitiesView?: boolean
}

export const PreviewPhaseDestinationColumn = React.memo((props: Props) => {
  const { phase, isNowSection, isTwoActivitiesView, isExtraActivity } = props
  const { stage, isActive } = phase
  const activity = isExtraActivity ? phase.extraActivity : phase.activity
  const isStreetTurn = (activity.comboType || activity.type) === OngoingActivityDTO.TypeEnum.STREETTURN
  const isDeliveryPhase = stage === TransportationActivityViewDTO.StageEnum.DELIVERY
  const destination = useLocation({ id: oc(activity).destinationId() })
  const state = useAppSelector(selectState(oc(destination).address.stateId()))
  const city = oc(destination).address.city()
  const postalCode = oc(destination).address.postalCode()
  const stateCode = oc(state).code()

  const getDestinationCityStatePostalCode = () => {
    return [city, [stateCode, postalCode].filter(Boolean).join(' ')].filter(Boolean).join(', ')
  }

  const locationPrefix = isTwoActivitiesView ? (isExtraActivity ? 'topL' : 'bottomL') : 'l'

  return (
    <Column
      style={isLocationGEOValid(destination) === false ? { color: theme.colors.defaultRed } : undefined}
      data-copy-value-on-mousedown={true}
      data-id={[
        `phase-location`,
        destination && `${locationPrefix}ocationName='${oc(destination).shortName() || oc(destination).name()}'`,
        destination && `${locationPrefix}ocationType='${oc(destination).type()}'`,
        `isStreetTurn=${isStreetTurn}`
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {isStreetTurn ? (
        activity.matchedDDOId && activity.matchedDDONumber ? (
          <DDOLink
            onClick={event =>
              openDDOinNewTab({
                // @ts-ignore
                event,
                ddoNumber: activity.matchedDDONumber,
                ddoId: activity.matchedDDOId
              })
            }
          >
            DDO# {activity.matchedDDONumber}
          </DDOLink>
        ) : null
      ) : isTwoActivitiesView ? (
        <TextLine style={{ paddingBottom: 1 }}>
          <DestinationTypeWithName
            destinationType={oc(destination).type()}
            destinationName={oc(destination).shortName() || oc(destination).name()}
          />
          {(city || stateCode || postalCode) && (
            <span style={{ marginLeft: 8, fontWeight: isExtraActivity ? 500 : 300 }}>
              {getDestinationCityStatePostalCode()}
            </span>
          )}
        </TextLine>
      ) : (
        <TextLines className={isActive ? 'space-between' : undefined}>
          <TextLine
            style={
              isActive
                ? { fontSize: 12, fontWeight: 400 }
                : {
                    fontSize: isDeliveryPhase ? 13 : 14,
                    fontWeight: isDeliveryPhase ? 300 : 400
                  }
            }
          >
            <DestinationTypeWithName
              destinationType={oc(destination).type()}
              destinationName={oc(destination).shortName() || oc(destination).name()}
            />
          </TextLine>
          <TextLine style={{ fontSize: 12, fontWeight: 500 }}>
            {city || stateCode || postalCode ? getDestinationCityStatePostalCode() : <Text>Location</Text>}
          </TextLine>
        </TextLines>
      )}
    </Column>
  )
})
