import * as R from 'remeda'
import { DriverViewDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.driver]: DriverViewDTO[]
}

export const parseDrivers = async (drivers: DriverViewDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.driver]: []
  }

  try {
    drivers.forEach(driver => {
      result[EntityType.driver].push(driver)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
