import * as React from 'react'
import styled from 'styled-components'
import { Column, DefaultColumn } from '../../../../../UI/GridSystem'
import { StyledLeft, Top, DOIndicator } from './styles'
import Activity from '../../../../../UI/Widget/widgets/activity/'
import Customer from '../../../../../UI/Widget/widgets/customer/'
import Equipment from '../../../../../UI/Widget/widgets/equipment'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'
import { SsqBsqWidget } from '../../../../../UI/Widget/widgets/SsqBsqWidget/SsqBsqWidget'

type Props = {}

const Left = (props: Props) => {
  const { enableEditing, dispatchDeliveryOrder, deliveryOrder, isReefer, modifyDeliveryOrderField } = useGridItemDDO()

  return (
    <StyledLeft>
      <Top>
        <DOIndicator style={{ marginLeft: -5 }}>{deliveryOrder.type}</DOIndicator>
        <Column columns={7.5} isFixed={true} margin={{ left: 20 }}>
          <DateTimePicker
            title={'DO Date'}
            required={true}
            disabled={enableEditing === false || deliveryOrder.dateLocked}
            date={deliveryOrder.date}
            onChange={modifyDeliveryOrderField('date')}
          />
        </Column>
        <Icons>
          {isReefer && ddoDirectory.icons.refrigerator}
          {dispatchDeliveryOrder.hazmatIndicator && ddoDirectory.icons.hazmat}
          {dispatchDeliveryOrder.overweightIndicator && ddoDirectory.icons.overweight}
          {dispatchDeliveryOrder.autoIndicator && ddoDirectory.icons.auto}
        </Icons>
      </Top>

      <DefaultColumn isGrid={true} flexClear={true} padding={{ top: 15, right: 16, left: 16 }}>
        <DefaultColumn margin={{ bottom: 15 }}>
          <Activity />
        </DefaultColumn>

        <DefaultColumn margin={{ bottom: 15 }}>
          <Customer id={deliveryOrder.customerId} changeCustomerId={id => modifyDeliveryOrderField('customerId')(id)} />
        </DefaultColumn>

        <DefaultColumn>
          <Equipment />
        </DefaultColumn>

        <DefaultColumn margin={{ top: 25 }}>
          <SsqBsqWidget />
        </DefaultColumn>
      </DefaultColumn>
    </StyledLeft>
  )
}

const Icons = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, auto);
  grid-gap: 5px;
  font-size: 18px;
  margin-left: auto;
`

export default Left
