import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../../../api/origin/business-logic'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import { WidgetContext } from '../../index'
import { getStringConvertISODateToDateWithHoursMins } from '../../../../../services/timeService/dateUtils'
import { Table } from './view/Table'
import { ManualStatuses } from './view/ManualStatuses'
import { BoldLine } from '../../styles'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useLocation } from '../../../../../hooks/useLocation'

type Props = {
  currentActivity: TransportationActivityViewDTO
  windowProps: {
    title: string
    activityTypeOrDDOStatus: string
    activityVendor: string
    titleAndIconColor: string
  }
}

export const Popover = (props: Props) => {
  const { currentActivity, windowProps } = props
  const { closePopover } = React.useContext(WidgetContext)
  const {
    enableEditing,
    deliveryOrderType,
    actualDDOStatus,
    modifyDispatchDeliveryOrderField,
    cancelDispatchDeliveryOrder
  } = useGridItemDDO()
  const destination = useLocation({ id: oc(currentActivity).destinationId() })

  if (currentActivity) {
    return (
      <Table
        rows={{
          Type: windowProps.activityTypeOrDDOStatus,
          Status: activityDirectory.status[currentActivity.status],
          'Start Date': getStringConvertISODateToDateWithHoursMins(currentActivity.startActualDate, false, false),
          'Completed Date': getStringConvertISODateToDateWithHoursMins(
            currentActivity.completionActualDate,
            false,
            false
          ),
          Location: oc(destination).longName(''),
          Driver: windowProps.activityVendor
        }}
      />
    )
  }

  if (
    [
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN,
      DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
    ].includes(actualDDOStatus)
  ) {
    return (
      <BoldLine style={{ color: windowProps.titleAndIconColor, textTransform: 'uppercase' }}>
        {windowProps.activityTypeOrDDOStatus}
      </BoldLine>
    )
  }

  return (
    <ManualStatuses
      disabled={enableEditing === false}
      deliveryOrderType={deliveryOrderType}
      currentStatus={actualDDOStatus}
      onChangeStatus={changedStatus => {
        if (changedStatus === DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED) {
          cancelDispatchDeliveryOrder()
          closePopover()
        } else {
          modifyDispatchDeliveryOrderField('status')(changedStatus)
        }
      }}
    />
  )
}
