import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { OngoingActivityGroupDTO } from '../../../../../../../api/origin/business-logic'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../../../../services/debug'
import { Preview } from './Preview'
import { MessagesButton } from './MessagesButton'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { ViewingRowMode } from './ViewingRowMode'
import { FavoritesButton } from './FavoritesButton'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectRouteBuilderIncludesDDOId } from '../../../../../../../store/select/routeBuilderSelect'
import { setFavoriteOngoingActivityState } from '../../../../../../../store/reducers/tabs/functions'
import {
  selectApplicationTabFavoriteOngoingActivityState,
  selectExpandedTabItemId
} from '../../../../../../../store/select/applicationTabSelect'
import { tabActions } from '../../../../../../../store/reducers/tabs'
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch'

type Props = {
  activityGroup: OngoingActivityGroupDTO
  isMissed?: boolean
  isNowSection?: boolean
}

export const OngoingActivityRow = (props: Props) => {
  const { activityGroup, isMissed, isNowSection } = props
  const dispatch = useAppDispatch()
  const expandedTabItemId = useAppSelector(selectExpandedTabItemId)
  const storeFavoriteState = useAppSelector(selectApplicationTabFavoriteOngoingActivityState(activityGroup.id))
  const isFavorite = storeFavoriteState === undefined ? activityGroup.lfd || activityGroup.cutoff : storeFavoriteState
  const presentsInRouteBuilder = useAppSelector(selectRouteBuilderIncludesDDOId(activityGroup.dispatchDeliveryOrderId))
  const isViewingCurrentObject = expandedTabItemId === activityGroup.dispatchDeliveryOrderId

  const openDetails = (event: any) => {
    // >>> Debugging
    if (isShowDetailsOnClick(event)) {
      showDetailsOnClick({
        title: 'Ongoing Activity Order Group',
        data: activityGroup,
        blink: { class: 'OngoingActivityOrderGroup', event }
      })
      return
    }
    // <<<

    dispatch(
      tabActions.setExpandedItem({
        expandedItem: { id: activityGroup.dispatchDeliveryOrderId }
      })
    )
  }

  return (
    <>
      <Container
        data-id={[
          `id-${activityGroup.id}`,
          `ddoNumber-${activityGroup.dispatchDeliveryOrderNumber}`,
          `activityGroupDate-${activityGroup.activityGroupDate}`,
          `highlight-${activityGroup.highlight}`,
          `presentsInRouteBuilder-${presentsInRouteBuilder}`
        ].join(' ')}
        className={cn('OngoingActivityOrderGroup', { presentsInRouteBuilder })}
      >
        <FavoritesButton
          isFavorite={isFavorite}
          lfd={activityGroup.lfd}
          cutoff={activityGroup.cutoff}
          onClick={() =>
            setFavoriteOngoingActivityState({ ongoingActivityId: activityGroup.id, favoriteState: !isFavorite })
          }
        />
        <MessagesButton
          dispatchDeliveryOrderId={activityGroup.dispatchDeliveryOrderId}
          hasAlerts={Boolean(activityGroup.troubleTicketId)}
        />
        <Preview
          isFavorite={isFavorite}
          isMissed={isMissed}
          isNowSection={isNowSection}
          activityGroup={activityGroup}
          onClick={openDetails}
        />
        {/*Open details in Window*/}
      </Container>

      {isViewingCurrentObject && (
        <ViewingRowMode
          isFavorite={isFavorite}
          isMissed={isMissed}
          activityGroup={activityGroup}
          isNowSection={isNowSection}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 35px 35px 1fr;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &.presentsInRouteBuilder {
    box-shadow: 0 0 0 1px rgb(227, 232, 234), 0 0 0 3px #5cd8ff;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
