import { oc } from 'ts-optchain'
import { BusinessPartnerViewDTO, ContactDTO, SteamShipLineViewDTO } from '../../api/api'
import { isNewObject } from '../DTO'
import { saveBusinessPartner } from './saveBusinessPartner'
import { requestUpdateSteamShipLine, requestCreateSteamShipLine } from '../../components/common/steamShipLine/epics'

type Props = {
  steamShipLine: SteamShipLineViewDTO
  businessPartner: BusinessPartnerViewDTO | undefined
  contacts: ContactDTO[] | undefined
}

export const saveSteamShipLine = async (props: Props): Promise<SteamShipLineViewDTO> => {
  const { steamShipLine, businessPartner, contacts } = props
  const isNewSteamShipLine = isNewObject(steamShipLine)
  const savedBusinessPartner: BusinessPartnerViewDTO = await saveBusinessPartner({ businessPartner, contacts })
  let savedSteamShipLine: SteamShipLineViewDTO = steamShipLine

  if (steamShipLine) {
    let _steamShipLine = steamShipLine

    if (
      oc(steamShipLine)
        .containerDetentionPerDiemFeeSchedule([])
        .some(isNewObject)
    ) {
      _steamShipLine = {
        ..._steamShipLine,
        containerDetentionPerDiemFeeSchedule: _steamShipLine.containerDetentionPerDiemFeeSchedule.map(item =>
          isNewObject(item) ? { ...item, id: undefined } : item
        )
      }
    }
    savedSteamShipLine = await (isNewSteamShipLine
      ? requestCreateSteamShipLine({ ..._steamShipLine, businessPartnerId: oc(savedBusinessPartner).id() })
      : requestUpdateSteamShipLine(_steamShipLine))
  }

  return savedSteamShipLine
}
