import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, EquipmentDTO, DispatchDeliveryOrderViewDTO } from '../../../../../../../api/api'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { checkWeatherContainerTypeIsReefer } from '../../../../../../../services/DTO/containerType/functions'
import { deliveryOrderDirectory } from '../../../../../../../services/DTO/deliveryOrder/directory'
import { openDDOinNewTab } from '../../../../../../../services/DTO/dispatchDeliveryOrder'
import { ddoDirectory } from '../../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { dateService } from '../../../../../../../services/timeService'
import theme from '../../../../../../../styles/theme'
import { SpecsIconsContainer } from '../OngoingActivityRow/PreviewSpecs'
import { useDeliveryOrder } from '../../../../../../../hooks/useDeliveryOrder'
import { useEquipment } from '../../../../../../../hooks/useEquipment'
import { useContainer } from '../../../../../../../hooks/useContainer'
import { useContainerType } from '../../../../../../../hooks/useContainerType'
import { useSteamShipLine } from '../../../../../../../hooks/useSteamShipLine'
import { useCustomer } from '../../../../../../../hooks/useCustomer'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
}

export const MoveRowSpecs = React.memo((props: Props) => {
  const { dispatchDeliveryOrder } = props
  const { deliveryOrderId, containerTypeId, containerId, equipmentId } = dispatchDeliveryOrder || {}
  const deliveryOrder = useDeliveryOrder({ id: deliveryOrderId })
  const equipment = useEquipment({ id: equipmentId })
  const container = useContainer({ id: containerId })
  const containerType = useContainerType({ id: containerTypeId })
  const steamShipLine = useSteamShipLine({ id: oc(deliveryOrder).steamShipLineId() })
  const customer = useCustomer({ id: oc(deliveryOrder).customerId() })

  const DDOLink = () => {
    return (
      <Column style={{ border: 'none' }} data-copy-value-on-mousedown={true}>
        <ColumnTextLink
          onClick={event => {
            openDDOinNewTab({ ddoId: dispatchDeliveryOrder.id, ddoNumber: dispatchDeliveryOrder.number, event })
          }}
        >
          {dispatchDeliveryOrder.number}
        </ColumnTextLink>
      </Column>
    )
  }

  const DOType = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        {deliveryOrder && (
          <ColumnBoldText style={{ textTransform: 'uppercase' }}>
            {deliveryOrderDirectory.columnType[deliveryOrder.type]}
          </ColumnBoldText>
        )}
      </Column>
    )
  }

  const LoadType = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText style={{ lineHeight: '16px' }}>
          {ddoDirectory.loadType[dispatchDeliveryOrder.loadType]}
        </ColumnBoldText>
        <SpecsIconsContainer>
          {checkWeatherContainerTypeIsReefer({ containerTypeId: dispatchDeliveryOrder.containerTypeId }) &&
            ddoDirectory.icons.refrigerator}
          {dispatchDeliveryOrder.hazmatIndicator && ddoDirectory.icons.hazmat}
          {dispatchDeliveryOrder.overweightIndicator && ddoDirectory.icons.overweight}
        </SpecsIconsContainer>
      </Column>
    )
  }

  const Days = () => {
    return (
      <Column
        data-copy-value-on-mousedown={true}
        style={
          dispatchDeliveryOrder.daysOut && dispatchDeliveryOrder.daysOut > 2
            ? { color: theme.colors.defaultRed }
            : undefined
        }
      >
        <ColumnText>{dispatchDeliveryOrder.daysOut || '–'}</ColumnText>
        <ColumnThinText>Day(s)</ColumnThinText>
      </Column>
    )
  }

  const Container = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText>{container ? container.number : '–'}</ColumnBoldText>
        <ColumnThinText>{containerType ? containerType.code : '–'}</ColumnThinText>
      </Column>
    )
  }

  const BookingBL = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText>
          {deliveryOrder ? deliveryOrder.bookingNumber || deliveryOrder.billOfLadingNumber : '–'}
        </ColumnBoldText>
        <ColumnThinText>Booking/BL</ColumnThinText>
      </Column>
    )
  }

  const SSL = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText>{steamShipLine ? steamShipLine.name : '–'}</ColumnBoldText>
        <ColumnThinText>SSL</ColumnThinText>
      </Column>
    )
  }

  const Customer = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText>{oc(customer).name('–')}</ColumnBoldText>
        <ColumnThinText>Customer</ColumnThinText>
      </Column>
    )
  }

  const Equipment = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        <ColumnBoldText>
          {equipment ? (equipment as EquipmentDTO).number || equipment.chassisNumber : '–'}
        </ColumnBoldText>
        <ColumnThinText>Chassis</ColumnThinText>
      </Column>
    )
  }

  const LFDCutOff = () => {
    return (
      <Column data-copy-value-on-mousedown={true}>
        {deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT && (
          <>
            <ColumnBoldText>
              {dateService.makeLabel(oc(deliveryOrder).lastFreeDateDemurrage(), { hideTime: true }) || '–'}
            </ColumnBoldText>
            <ColumnThinText>LFD Date</ColumnThinText>
          </>
        )}
        {deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT && (
          <>
            <ColumnBoldText>
              {dateService.makeLabel(oc(deliveryOrder).generalCutoffDate(), { hideTime: true }) || '–'}
            </ColumnBoldText>
            <ColumnThinText>Cut-off Date</ColumnThinText>
          </>
        )}
      </Column>
    )
  }

  return (
    <Specs className={'MoveRowSpecs'}>
      <DDOLink />
      <DOType />
      <LoadType />
      <Days />
      <Container />
      <BookingBL />
      <SSL />
      <Customer />
      <Equipment />
      <LFDCutOff />
    </Specs>
  )
})

export const Specs = styled.div`
  min-height: 48px;
  display: grid;
  align-items: center;
  /* grid-template-columns: 7% 7% 7% 7% 12% 12% 9% 9% 12% 9% 9%; */
  grid-template-columns: 8% 8% 8% 8% 13% 13% 9.5% 9.5% 13% 10%;
  border-radius: 2px;
  outline: 1px solid #dce6f5;
  overflow: hidden;
`
export const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-left: ${SchedulerConstants.innerGap}px solid #e3e8ea90;
  padding: 6px;
`
const ColumnText = styled.div`
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const ColumnBoldText = styled(ColumnText)`
  font-weight: 500;
  font-size: 12px;
  color: #333333;
`
const ColumnTextLink = styled(ColumnBoldText)`
  text-decoration: underline;
  color: ${theme.colors.basicBlueColor};
  user-select: none;
  cursor: pointer;
`
export const ColumnThinText = styled(ColumnText)`
  font-weight: 300;
  color: #4f4f4f;
`
