import * as React from 'react'
import styled from 'styled-components'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import theme from '../../../../../styles/theme'

type Props = {
  quoteType: string
  total: number
  profit?: number
}

export const SsqBsqWidgetColumnHeader = React.memo((props: Props) => {
  const { quoteType, profit, total } = props

  return (
    <Container>
      <div>
        {typeof profit === 'number' && (
          <>
            <Title>PROFIT</Title>
            {'  '}
            <ProfitAmount style={{ color: profit < 0 ? theme.colors.defaultRed : profit > 0 ? '#27ae60' : undefined }}>
              {transformMoney(profit)}
            </ProfitAmount>
          </>
        )}
      </div>
      <div>
        <>
          <Title>{quoteType}</Title>
          {'  '}
          <span>Total</span>
          {'  '}
          {typeof total === 'number' ? (
            <Title style={{ color: total < 0 ? theme.colors.defaultRed : total > 0 ? '#27ae60' : undefined }}>
              {transformMoney(total)}
            </Title>
          ) : (
            <Title>–</Title>
          )}
        </>
      </div>
    </Container>
  )
})

const Container = styled.div`
  flex-shrink: 0;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #f2f2f2;
  padding: 0 16px;
`
const Title = styled.span`
  font-weight: 500;
  letter-spacing: 0.75px;
  color: #445366;
`
const ProfitAmount = styled.span`
  font-weight: 500;
  color: #d8d8d8;
`
