import { Action, ActionCreator, isType } from 'typescript-fsa'
import { filter } from 'rxjs/operators'
import { Observable } from 'rxjs'

const ofActions = (actionCreators: ActionCreator<any>[]) => (source: Observable<Action<any>>) =>
  source.pipe(
    filter((action: Action<any>) => {
      return actionCreators.some(actionCreator => {
        return isType(action, actionCreator)
      })
    })
  )

export default ofActions
