import { oc } from 'ts-optchain'
import { BusinessPartnerViewDTO, ContactDTO, SubClientViewDTO } from '../../api/api'
import { isNewObject } from '../DTO'
import { saveBusinessPartner } from './saveBusinessPartner'
import { requestUpdateSubClient, requestCreateSubClient } from '../../components/common/subClient/epics'

type Props = {
  subClient: SubClientViewDTO
  businessPartner: BusinessPartnerViewDTO | undefined
  contacts: ContactDTO[] | undefined
}

export const saveSubClient = async (props: Props): Promise<SubClientViewDTO> => {
  const { subClient, businessPartner, contacts } = props
  const isNewSubClient = isNewObject(subClient)
  const savedBusinessPartner: BusinessPartnerViewDTO = await saveBusinessPartner({ businessPartner, contacts })
  let savedSubClient: SubClientViewDTO = subClient

  if (subClient) {
    savedSubClient = await (isNewSubClient
      ? requestCreateSubClient({ ...subClient, businessPartnerId: oc(savedBusinessPartner).id() })
      : requestUpdateSubClient(subClient))
  }

  return savedSubClient
}
