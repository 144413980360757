import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { SubClientViewDTO } from '../../../api/api'
import { oc } from 'ts-optchain'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { getListsState } from '../../../store'
import { isSubClientValid } from '../../functions/test/isSubClientValid'
import { isBusinessPartnerValid } from '../../functions/test/isBusinessPartnerValid'
import { isContactValid } from '../../functions/test/isContactValid'

export const checkSubClientValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { modifiedLists, parentItem } = expandedItemContext
  const subClient = parentItem as SubClientViewDTO
  const businessPartnerId = subClient.businessPartnerId
  const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][businessPartnerId]()
  const businessPartner = modifiedBusinessPartner || getListsState()[EntityType.businessPartner][businessPartnerId]
  const updatedContactMapping = oc(modifiedLists)[EntityType.contact]({})
  const updatedContacts = businessPartner.contactIds.map(id => updatedContactMapping[id]).filter(Boolean)

  return (
    isSubClientValid(subClient) &&
    (!modifiedBusinessPartner || isBusinessPartnerValid(modifiedBusinessPartner, true)) &&
    updatedContacts.every(isContactValid)
  )
}
