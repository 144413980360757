import styled from 'styled-components'
import { theme } from '../../../../../../../../../styles/theme'

export const StyledWarning = styled.div`
  display: flex;
  width: 320px;
  background-color: white;
  border: 1px solid rgba(198, 204, 227, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  padding: 8px 16px 8px 10px;
`
export const Message = styled.div`
  flex-grow: 1;
`
export const Icon = styled.div`
  font-size: 36px;
  color: ${theme.colors.defaultRed};
  margin-right: 10px;
`
export const Title = styled.div`
  font-size: 14px;
  color: ${theme.colors.defaultRed};
`
export const Description = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #4f4f4f;
  margin-top: 4px;
`
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Action = styled.div`
  display: inline-flex;
  font-size: 12px;
  color: rgba(68, 83, 102, 0.8);
  margin: 8px 0 0 16px;
  cursor: pointer;

  &.blue {
    color: ${theme.colors.basicBlueColor};
  }
  &.bold {
    font-weight: 700;
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};
    text-decoration: underline;
  }
`
