import { FileDTO } from '../../api/origin/document-service'
import { FileCategory } from './interfaces'

export const generateFileTemplate = (
  file: File,
  attachmentProps?: {
    uploadingProgress?: number
    category?: FileCategory
  }
): FileDTO => {
  return file
    ? {
        id: file.name,
        name: file.name,
        size: file.size,
        contentType: file.type,
        url: URL.createObjectURL(file),
        thumb: (file.type || '').includes('image') ? URL.createObjectURL(file) : undefined,
        ...(attachmentProps || {})
      }
    : null
}
