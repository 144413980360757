import { GeneralCounterDTO } from '../../../../api/origin/communication-hub-service'
import { IChannelMeta, ICommunicationHub } from '../interfaces'

export const defaultChannelMeta = (channelId?: string): IChannelMeta => ({
  id: channelId,
  type: GeneralCounterDTO.TypeEnum.DDO,
  count: 0,
  mute: false,
  mentionMsgIds: [],
  pinnedMsgIds: [],
  alertMsgIds: [],
  updatedAt: null
})

export const getChannelIdMeta = (state: ICommunicationHub, channelId: string): IChannelMeta => {
  return state.channelsMeta[channelId] || defaultChannelMeta(channelId)
}
