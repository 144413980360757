import * as React from 'react'
import { callAPI, nonPlannedActivityAPI, NonPlannedActivityCountDTO } from '../../../../../../api/api'
import styled from 'styled-components'
import theme from '../../../../../../styles/theme'
import { TabDTO } from '../../../../../../store/reducers/tabs/interfaces'
import { createTab } from '../../../../../../store/reducers/tabs/functions'

type Props = {}

export const LinksWithCounts = (props: Props) => {
  const [counts, setCounts] = React.useState<NonPlannedActivityCountDTO>({})

  React.useEffect(() => {
    let mounted = true

    const updatedCounts = () => {
      callAPI(nonPlannedActivityAPI.getNonPlannedActivityCounts)
        .toPromise()
        .then((c: NonPlannedActivityCountDTO) => {
          if (mounted) {
            setCounts(c)
          }
        })
    }

    updatedCounts()

    const interval = 60 * 1000
    const timer = setInterval(updatedCounts, interval)

    return () => {
      mounted = false
      clearInterval(timer)
    }
  }, [])

  const openLink = (label: string, listRequestType: TabDTO.Request.List) => () => {
    createTab(TabDTO.Type.dispatchDeliveryOrder, {
      label,
      refrashOnTabEnter: true,
      permissions: {
        websoketsUpdate: false
      },
      request: {
        listRequestType
      },
      ...(listRequestType === TabDTO.Request.List.droppedAtDeliveryLocation
        ? { uiSettings: { sorting: 'deliveryStage.activity.completionActualDate' } }
        : {})
    })
  }

  const links = [
    {
      label: 'Dropped At Yard',
      listRequestType: TabDTO.Request.List.droppedAtYard,
      count: (Number.isInteger(counts.droppedAtYard) && counts.droppedAtYard) || '0'
    },
    {
      label: 'Picked-Up No Del Apt',
      listRequestType: TabDTO.Request.List.pickedUpNoDelivery,
      count: (Number.isInteger(counts.pickedUpNoDelivery) && counts.pickedUpNoDelivery) || '0'
    },
    {
      label: 'Dropped At Del Location',
      listRequestType: TabDTO.Request.List.droppedAtDeliveryLocation,
      count: (Number.isInteger(counts.droppedAtDeliveryLocation) && counts.droppedAtDeliveryLocation) || '0'
    },
    {
      label: 'Not Returned',
      listRequestType: TabDTO.Request.List.deliveryStageCompletedNoReturned,
      count:
        (Number.isInteger(counts.deliveryStageCompletedNoReturned) && counts.deliveryStageCompletedNoReturned) || '0'
    }
  ]

  return (
    <Container>
      {links.map(link => (
        <Link
          key={link.listRequestType}
          className={'mdi mdi-link-variant'}
          onClick={openLink(link.label, link.listRequestType)}
          data-count={link.count}
        >
          {link.label}
        </Link>
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  padding: 6px 16px;
  margin-left: auto;
`
const Link = styled.div`
  flex-grow: 1;
  height: 25px;
  display: flex;
  align-items: center;
  color: #697585;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  user-select: none;

  &:before {
    color: #b2b2b2;
    margin-right: 8px;
  }

  &:after {
    content: attr(data-count);
    font-weight: 700;
    font-size: 12px;
    color: #445366;
    margin-left: auto;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f5f6fa;
  }

  &:hover {
    &,
    &:before {
      color: ${theme.colors.basicBlueColor};
    }
  }
`
