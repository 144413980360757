import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { LabelResolve } from './styles'
import { TroubleTicketActionsStyledComponents } from '../../../../../../../../../../Hints/TroubleTicketActions'
import theme from '../../../../../../../../../../../styles/theme'
import { ActionButton } from '../../../../../../../../../../UI/Buttons/ActionButton'
import { TroubleTicketResolveAction } from '../../../../../../../../../activity/epics'

type Props = {
  onSelect: (action: TroubleTicketResolveAction) => void
  isValidation?: boolean
}

export const ResolveButton = (props: Props) => {
  const { onSelect, isValidation } = props
  const { Dropdown, DropdownOption, DropdownTitle } = TroubleTicketActionsStyledComponents

  return (
    <>
      <ActionButton
        round={true}
        filled={true}
        onClick={undefined}
        options={{
          'data-tip': 'true',
          'data-for': 'troubleTicketPopupActions'
        }}
      >
        <LabelResolve className={'mdi mdi-menu-down'}>Resolve</LabelResolve>
      </ActionButton>

      <ReactTooltip
        id={'troubleTicketPopupActions'}
        className={'react-tooltip-custom-inner'}
        place={'bottom'}
        event={'click'}
        globalEventOff={'click'}
        clickable={true}
      >
        <Dropdown>
          <DropdownTitle>Select a decision</DropdownTitle>
          <DropdownOption onClick={() => onSelect(TroubleTicketResolveAction.proceed)}>
            <span
              className={cn('mdi', { 'mdi-checkbox-marked-circle': isValidation, 'mdi-play-circle': !isValidation })}
              style={{ color: '#6973F6' }}
            />
            {isValidation ? 'Validate' : 'Proceed'}
          </DropdownOption>
          <DropdownOption onClick={() => onSelect(TroubleTicketResolveAction.unsuccessful)}>
            <span className={'mdi mdi-close-circle'} style={{ color: theme.colors.defaultRed }} />
            Unsuccessful
          </DropdownOption>
        </Dropdown>
      </ReactTooltip>
    </>
  )
}
