import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridItemContainerContext } from '../contexts/GridItemContainerContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useContainer } from '../hooks/useContainer'
import { useContainerType } from '../hooks/useContainerType'
import { useSteamShipLine } from '../hooks/useSteamShipLine'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemContainerProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const container = useContainer({ id, modifiedLists })
  const containerType = useContainerType({ id: oc(container).containerTypeId(), modifiedLists })
  const steamShipLine = useSteamShipLine({ id: oc(container).steamShipLineId(), modifiedLists })

  return (
    <GridItemContainerContext.Provider
      value={{ isExpanded: Boolean(modifiedLists), container, steamShipLine, containerType }}
      children={children}
    />
  )
})
