import { SellSideQuoteDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.sellSideQuote]: SellSideQuoteDTO[]
}

export const parseSellSideQuotes = async (sellSideQuotes: SellSideQuoteDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.sellSideQuote]: []
  }

  try {
    sellSideQuotes.forEach(sellSideQuote => {
      result[EntityType.sellSideQuote].push(sellSideQuote)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
