import { callAPIWithErrorMessage, dispatchDeliveryOrderAPI, hazmatAPI, HazmatDTO } from '../../../../api/api'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

export const requestHazmatByTerm = (term: string): Promise<HazmatDTO[]> => {
  return callAPIWithErrorMessage(hazmatAPI.search, 99, term || '').catch(() => [])
}

export const requestHazmatById = (id: string): Promise<HazmatDTO> => {
  return callAPIWithErrorMessage(hazmatAPI.findById, id).then(async hazmat => {
    await pushListItemsToStore({ update: { [EntityType.hazmat]: [hazmat] } })

    return hazmat
  })
}

export const requestHazmatByDispatchDeliveryOrderId = (ddoId: string): Promise<HazmatDTO> => {
  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.getHazmat, ddoId).then(async hazmat => {
    if (hazmat) {
      await pushListItemsToStore({ update: { [EntityType.hazmat]: [hazmat] } })
    }

    return hazmat
  })
}
