import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'
import { containerLists } from '../../select/containerLists'
import { ContainerColumn } from '../../../components/common/grid/columnSettings/containerColumnSettings'

export const containerFilterSettings = {
  [ContainerColumn.ContainerNumber]: {
    name: 'number',
    type: ColumnDTO.Type.string
  },
  [ContainerColumn.ActiveAvailability]: {
    name: 'availability',
    type: ColumnDTO.Type.list,
    items: [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]
  },
  [ContainerColumn.Type]: {
    name: 'containerType.id',
    path: 'containerTypeId',
    type: ColumnDTO.Type.list,
    get items() {
      return containerLists.containerTypeNamesList
    }
  },
  [ContainerColumn.MaxCargoWeight]: {
    name: 'weight',
    type: ColumnDTO.Type.number
  },
  [ContainerColumn.Ownership]: {
    name: 'ownership',
    type: ColumnDTO.Type.list,
    items: containerLists.ownership
  },
  [ContainerColumn.SSL]: {
    name: 'steamShipLine.name',
    type: ColumnDTO.Type.string
  }
}
