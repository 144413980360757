import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import cn from 'classnames'

export enum AttachmentActionType {
  uploading = 'AttachmentActionType_Uploading',
  remove = 'AttachmentActionType_Remove',
  download = 'AttachmentActionType_Download',
  openOnNewTab = 'AttachmentActionType_OpenOnNewTab'
}

type Props = {
  type: AttachmentActionType
  uploadingProgress?: number
  onClick?(event: React.MouseEvent): void
}

export const AttachmentAction = (props: Props) => {
  const { type, uploadingProgress, onClick } = props

  return (
    <Container className={type}>
      <Icon
        // @ts-ignore
        onClick={onClick}
        className={cn('mdi', {
          clickable: Boolean(onClick),
          'mdi-download-circle': type === AttachmentActionType.download,
          'mdi-window-close': type === AttachmentActionType.remove,
          'mdi-loading': type === AttachmentActionType.uploading,
          'mdi-file-eye': type === AttachmentActionType.openOnNewTab
        })}
        data-uploading-progress={uploadingProgress}
      />
    </Container>
  )
}

const Icon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &.clickable {
    cursor: pointer;
  }

  &.mdi-loading {
    color: ${theme.colors.basicBlueColor};

    &:before {
      animation: UploadingFileProcess 1s linear infinite;
    }

    &:after {
      content: attr(data-uploading-progress);
      position: absolute;
      font-size: 10px;
    }
  }

  @keyframes UploadingFileProcess {
    to {
      transform: rotate(360deg);
    }
  }
`

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:hover {
    background: linear-gradient(242.32deg, #ffffff 16.06%, rgba(255, 255, 255, 0) 70.68%);
  }

  &.${AttachmentActionType.uploading} {
    background: linear-gradient(242.32deg, #ffffff 16.06%, rgba(255, 255, 255, 0) 70.68%);
    font-size: 24px;
  }

  &.${AttachmentActionType.remove}, &.${AttachmentActionType.download}, &.${AttachmentActionType.openOnNewTab} {
    ${Icon} {
      display: none;
    }

    &:hover ${Icon} {
      display: block;
    }
  }

  &.${AttachmentActionType.download}, &.${AttachmentActionType.openOnNewTab} {
    ${Icon} {
      font-size: 22px;
      color: ${theme.colors.basicBlueColor};
    }
  }
`
