import * as React from 'react'
import cn from 'classnames'
import { StyledFieldContainer, Title, ChildWrapper } from './styles'
import { FieldWarning } from '../../FieldWarning'

type TFieldContainer = {
  disabled?: boolean
  className?: string
  titleCondition?: any
  turnOver?: boolean
  highlighted?: boolean
  errorMessage?: string
  errorMessageWidth?: number | string
  title?: string
  required?: boolean
  children: any
  wrapChild?: boolean
  wrapperHeight?: number | string
  childrenStyle?: any
  style?: React.CSSProperties
}

export const FieldContainer = ({
  disabled,
  className = '',
  turnOver,
  highlighted,
  title,
  errorMessage,
  errorMessageWidth,
  required,
  children,
  wrapChild,
  wrapperHeight,
  childrenStyle,
  style
}: TFieldContainer) => {
  return (
    <StyledFieldContainer className={cn({ highlighted, turnOver, [className]: className })} style={style}>
      {title && !turnOver && (
        <Title className={cn({ required, disabled })}>
          {title}
          {errorMessage && (
            <FieldWarning style={{ left: 10, top: -1 }} width={errorMessageWidth} message={errorMessage} />
          )}
        </Title>
      )}
      {Boolean(wrapChild || wrapperHeight) ? (
        <ChildWrapper style={{ height: wrapperHeight, ...(childrenStyle || {}) }}>
          <div>{children}</div>
        </ChildWrapper>
      ) : (
        children
      )}
      {title && turnOver && <Title className={cn({ required, disabled })}>{title}</Title>}
    </StyledFieldContainer>
  )
}
