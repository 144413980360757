import { modalWindowActions } from '../../../providers/ModalWindowProvider'
import { IModalWindow, ModalWindow } from './interfaces'

export const showModalWindow = (state: IModalWindow) => {
  return modalWindowActions.setModalWindow(state)
}

export const setModalWindowButtons = (buttons: ModalWindow.Button[]) => {
  return modalWindowActions.setModalWindow(modalWindow => (modalWindow ? { ...modalWindow, buttons } : modalWindow))
}

export const closeModalWindow = () => {
  return modalWindowActions.setModalWindow(null)
}

export const showSuccessModalWindow = (props: IModalWindow) => {
  return showModalWindow({
    ...props,
    width: props.width || 445,
    type: ModalWindow.Type.SUCCESS,
    buttons: props.buttons || [{ label: 'Ok' }]
  })
}

export const showWarningModalWindow = (props: IModalWindow) => {
  return showModalWindow({
    ...props,
    width: props.width || 445,
    type: ModalWindow.Type.WARNING,
    buttons: props.buttons || [{ label: 'Ok' }]
  })
}

export const showErrorModalWindow = (props: Partial<IModalWindow>) => {
  return showModalWindow({
    ...props,
    title: props.title || 'Alert',
    width: props.width || 445,
    type: ModalWindow.Type.ALERT,
    buttons: props.buttons || [{ label: 'Ok' }]
  })
}
