import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { communicationHubLocalStorageService } from './functions/communicationHubLocalStorageService'
import {
  ActiveChatChannelIdSpecialTypes,
  ChannelType,
  ChatTab,
  CommunicationHubTab,
  IActiveChatChannel,
  IActiveRouteBuilder,
  IActiveRouteBuilderSchedulerTabData,
  IActiveRouteBuilderTab,
  IChannelBrowser,
  IChannelMeta,
  IChannelsMeta,
  ICHubChannel,
  ICommunicationHub,
  IMessage,
  IMoveDTO,
  IMoveItemDTO,
  INotificationFilters,
  NotificationDocumentForm,
  NotificationFilter,
  RouteBuilderGridView
} from './interfaces'
import { dateService } from '../../../services/timeService'
import { adjustChannelsNumber } from './functions/adjustChannelsNumber'
import { sortChannelIds } from './functions/sortChannelIds'
import { getChannelIdMeta } from './functions/getChannelIdMeta'
import { generateInitialCommunicationHubState } from './functions/generateInitialCommunicationHubState'
import { addRecentOpenedChannelId } from './functions/addRecentOpenedChannelId'
import { isChannelIdPresentsInChannelIds } from './functions/isChannelIdPresentsInChannelIds'
import { MessageType, NotificationDTO, UserCounterDTO } from '../../../api/origin/communication-hub-service'
import { sortChannels } from './functions/sortChannels'
import { sortMovesBySequenceNumber } from './functions/sortMovesBySequenceNumber'
import { addMoveProperties } from './functions/addMoveProperties'
import { filterLatestObjects } from '../../../services/websocket/functions'
import { filterNotifications } from '../../../services/functions/filter/filterNotifications'

const saveToLocalStorageOnAction = ({ type }: AnyAction): boolean => {
  return [
    String(communicationHubActions.openAllUnread),
    String(communicationHubActions.setActiveChannel),
    String(communicationHubActions.pushActiveChannel),
    String(communicationHubActions.closeChannel),
    String(communicationHubActions.closeDetailsView),
    String(communicationHubActions.setActiveTab),
    String(communicationHubActions.setActiveRouteBuilder),
    String(communicationHubActions.setActiveRouteBuilderVendor),
    String(communicationHubActions.setActivityIdHiglight),
    String(communicationHubActions.removeActivityIdHiglight),
    String(communicationHubActions.closeActiveRouteBuilder),
    String(communicationHubActions.setActiveRouteBuilderTabIndexDate),
    String(communicationHubActions.setActiveRouteBuilderTabIndex),
    String(communicationHubActions.toggleActiveRouteBuilderView),
    String(communicationHubActions.setNotificationFilter),
    String(communicationHubActions.setNotificationFilters),
    String(communicationHubActions.setDocumentForms)
  ].includes(type)
}

const clearNotificationsOnAction = ({ type }: AnyAction): boolean => {
  return [
    String(communicationHubActions.setActiveChannel),
    String(communicationHubActions.setActiveChannel),
    String(communicationHubActions.pushActiveChannel),
    String(communicationHubActions.closeChannel),
    String(communicationHubActions.closeDetailsView)
  ].includes(type)
}

const communicationHubSlice = createSlice({
  name: 'communicationHub',
  initialState: communicationHubLocalStorageService.initialState,
  reducers: {
    setInitialData: (state, { payload: { channelsMeta } }: PayloadAction<{ channelsMeta: IChannelsMeta }>) => {
      state.channelsMeta = channelsMeta || {}
    },
    updateChannelsMeta: (state, { payload }: PayloadAction<(IChannelMeta | UserCounterDTO)[]>) => {
      state.channelsMeta = payload.reduce(
        (acc, currChannelMeta) => {
          const channelMeta = getChannelIdMeta(state, currChannelMeta.id)
          acc[currChannelMeta.id] = { ...channelMeta, ...currChannelMeta }

          return acc
        },
        { ...state.channelsMeta }
      )
    },
    setActiveTab: (
      state,
      { payload: { activeTab, expand } }: PayloadAction<{ activeTab: CommunicationHubTab; expand?: boolean }>
    ) => {
      const updatedState: ICommunicationHub = {
        ...state,
        activeTab,
        channelBrowser: undefined,
        activeChannel: state.activeChannel && {
          ...state.activeChannel,
          updateUnreadCount: activeTab === CommunicationHubTab.routeBuilder
        }
      }

      if (activeTab === CommunicationHubTab.messenger) {
        if (updatedState.activeRouteBuilder && !updatedState.activeChannel) {
          updatedState.channelBrowser = { channelType: ChannelType.dispatchDeliveryOrder }
        }
      } else if (activeTab === CommunicationHubTab.routeBuilder) {
        if (!updatedState.activeRouteBuilder && (updatedState.activeChannel || expand)) {
          updatedState.activeRouteBuilder = {
            vendorId: undefined,
            authUserId: undefined,
            view: RouteBuilderGridView.grid,
            tabs: [
              {
                isActive: true,
                date: dateService.createStringDate.startWorkDay,
                moves: []
              }
            ]
          }
        }
      }

      return updatedState
    },
    setChannelIdList: (
      state,
      {
        payload: { channelType, channelIdList, channelList }
      }: PayloadAction<{
        channelType: ChannelType
        channelIdList: string[]
        channelList?: ICHubChannel[]
      }>
    ) => {
      state.channels = adjustChannelsNumber(
        {
          ...state.channels,
          idListByType: {
            ...state.channels.idListByType,
            [channelType]: sortChannelIds(state.channelsMeta, channelIdList)
          },
          mapping: {
            ...state.channels.mapping,
            ...(channelList
              ? channelList.reduce((acc, curr) => {
                  acc[curr.id] = curr
                  return acc
                }, {})
              : {})
          }
        },
        channelType
      )
    },
    setChannel: (
      state,
      {
        payload: { channelType, channel, updatedAt }
      }: PayloadAction<{
        channelType: ChannelType
        channel: ICHubChannel
        updatedAt: string
      }>
    ) => {
      if (channel && channel.id) {
        const channelId = channel.id
        const typeChannelIdList: string[] = R.uniq([channelId, ...state.channels.idListByType[channelType]])
        const mapping = { ...state.channels.mapping, [channelId]: channel }
        const channelMeta = getChannelIdMeta(state, channelId)
        const channelsMeta = { ...state.channelsMeta, [channelId]: { ...channelMeta, updatedAt } }

        state.channels = adjustChannelsNumber(
          {
            ...state.channels,
            idListByType: {
              ...state.channels.idListByType,
              [channelType]: sortChannelIds(channelsMeta, typeChannelIdList)
            },
            mapping
          },
          channelType
        )
      }
    },
    //  >>> open channel
    setActiveChannel: (
      state,
      {
        payload: { activeChannel, omitRecentChannelsUpdate }
      }: PayloadAction<{
        activeChannel: IActiveChatChannel
        omitRecentChannelsUpdate?: boolean
      }>
    ) => {
      const isTheSameActiveChannel = oc(state).activeChannel.id() === activeChannel.id

      return {
        ...state,
        activeChannel,
        messages: isTheSameActiveChannel ? state.messages : generateInitialCommunicationHubState().messages,
        channels:
          isTheSameActiveChannel || omitRecentChannelsUpdate
            ? state.channels
            : addRecentOpenedChannelId(state.channels, activeChannel.id),
        channelBrowser: undefined,
        activeTab: CommunicationHubTab.messenger
      }
    },
    pushActiveChannel: (
      state,
      {
        payload: { channel, options }
      }: PayloadAction<{
        channel: ICHubChannel
        options?: {
          chatTab?: ChatTab
          updateUnreadCount?: boolean
        }
      }>
    ) => {
      if (channel && channel.id && channel.type) {
        const isTheSameActiveChannel = channel.id === oc(state).activeChannel.id()
        const alreadyExists = state.channels.idListByType[channel.type].includes(channel.id)
        let channels = state.channels

        if (!alreadyExists) {
          channels = adjustChannelsNumber(
            {
              ...state.channels,
              idListByType: {
                ...state.channels.idListByType,
                [channel.type]: sortChannelIds(state.channelsMeta, [
                  ...state.channels.idListByType[channel.type],
                  channel.id
                ])
              },
              mapping: {
                ...state.channels.mapping,
                [channel.id]: channel
              }
            },
            channel.type
          )
        }

        return {
          ...state,
          activeChannel: {
            id: channel.id,
            updateUnreadCount: false,
            isRecentOpenedChannel: !isChannelIdPresentsInChannelIds(channel.id, channels),
            ...(options || {})
          },
          channels: isTheSameActiveChannel ? channels : addRecentOpenedChannelId(channels, channel.id),
          messages: isTheSameActiveChannel ? state.messages : generateInitialCommunicationHubState().messages,
          channelBrowser: undefined,
          activeTab: CommunicationHubTab.messenger
        }
      }
    },
    // <<< open channel
    setActiveChannelTab: (state, { payload }: PayloadAction<ChatTab>) => {
      const { activeChannel } = state

      if (activeChannel) {
        return {
          ...state,
          activeChannel: { ...activeChannel, chatTab: payload },
          channelBrowser: undefined
        }
      }
    },
    closeChannel: state => {
      return { ...state, activeChannel: undefined, messages: generateInitialCommunicationHubState().messages }
    },
    setChannelBrowser: (state, { payload }: PayloadAction<IChannelBrowser>) => {
      state.channelBrowser = payload
    },
    closeChannelBrowser: state => {
      state.channelBrowser = undefined
    },
    closeDetailsView: state => {
      return {
        ...state,
        channelBrowser: undefined,
        activeChannel: undefined,
        activeRouteBuilder: undefined,
        messages: generateInitialCommunicationHubState().messages
      }
    },
    setMessages: (state, { payload }: PayloadAction<IMessage[]>) => {
      const ids: string[] = []
      const mapping = payload.reduce((acc, curr) => {
        acc[curr.id] = curr
        ids.push(curr.id)

        return acc
      }, {})

      state.messages = { ...state.messages, ids, mapping }
    },
    pushMessageToStorage: (
      state,
      {
        payload: { message }
      }: PayloadAction<{
        message: IMessage
      }>
    ) => {
      if (oc(state).activeChannel.id() !== message.channelId) {
        return
      }

      const messages = { ...state.messages }
      let channelsMeta = state.channelsMeta
      let channels = state.channels

      const channelType = ChannelType.dispatchDeliveryOrder // TODO remove particular type
      const channelId = message.channelId
      const channelMeta = getChannelIdMeta(state, channelId)
      channelsMeta = { ...channelsMeta, [channelId]: { ...channelMeta, updatedAt: message.updatedAt } }

      if (!channels.idListByType[channelType].includes(channelId)) {
        channels = {
          ...channels,
          idListByType: {
            ...channels.idListByType,
            [channelType]: [channelId, ...channels.idListByType[channelType]]
          }
        }
      }

      channels = sortChannels(channelsMeta, channels)

      return {
        ...state,
        channelsMeta,
        channels,
        messages: {
          ...messages,
          ids: messages.ids.includes(message.id) ? messages.ids : messages.ids.concat(message.id),
          mapping: {
            ...messages.mapping,
            [message.id]: message
          }
        }
      }
    },
    removeMessageFromStorage: (state, { payload }: PayloadAction<IMessage>) => {
      if (!state.activeChannel || state.activeChannel.id !== payload.channelId) {
        return
      }

      state.messages = {
        ...state.messages,
        ids: state.messages.ids.filter(id => id !== payload.id),
        mapping: R.omit(state.messages.mapping, [payload.id])
      }
    },
    openAllUnread: state => {
      return {
        ...state,
        channelBrowser: undefined,
        activeChannel: {
          id: undefined,
          updateUnreadCount: undefined
        }
      }
    },
    setChannelsMeta: (state, { payload }: PayloadAction<IChannelsMeta>) => {
      return {
        ...state,
        channelsMeta: payload
      }
    },
    addPinnedMessageIdForChannelId: (
      state,
      { payload: { messageId, channelId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (!messageId) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = { ...channelMeta, pinnedMsgIds: [...(channelMeta.pinnedMsgIds || []), messageId] }
    },
    removePinnedMessageIdForChannelId: (
      state,
      { payload: { messageId, channelId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (!messageId) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        pinnedMsgIds: (channelMeta.pinnedMsgIds || []).filter(id => id !== messageId)
      }
    },
    addAlertMessageIdForChannelId: (
      state,
      { payload: { messageId, channelId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (!messageId) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = { ...channelMeta, alertMsgIds: [...(channelMeta.alertMsgIds || []), messageId] }
    },
    removeAlertMessageIdForChannelId: (
      state,
      { payload: { messageId, channelId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (!messageId) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        alertMsgIds: (channelMeta.alertMsgIds || []).filter(id => id !== messageId)
      }
    },
    addMentionMessageIdForChannelId: (
      state,
      { payload: { channelId, messageId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (oc(state).activeChannel.id() === channelId && !oc(state).activeChannel.updateUnreadCount()) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        mentionMsgIds: [...(channelMeta.mentionMsgIds || []), messageId]
      }
    },
    removeMentionMessageIdForChannelId: (
      state,
      { payload: { channelId, messageId } }: PayloadAction<{ messageId: string; channelId: string }>
    ) => {
      if (oc(state).activeChannel.id() === channelId && !oc(state).activeChannel.updateUnreadCount()) {
        return
      }

      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        mentionMsgIds: (channelMeta.mentionMsgIds || []).filter(id => id !== messageId),
        count: channelMeta.count > 0 ? channelMeta.count - 1 : 0
      }
    },
    incrementChannelIdUnreadCount: (state, { payload }: PayloadAction<IMessage>) => {
      const message = payload
      const isWorkOrder = message.type === MessageType.WORKORDER
      const channelMeta = getChannelIdMeta(state, message.channelId)
      const updatedAt = !isWorkOrder ? message.createdAt : channelMeta.updatedAt
      const channelsMeta = {
        ...state.channelsMeta,
        [message.channelId]: {
          ...channelMeta,
          updatedAt,
          count: oc(state).channelsMeta[message.channelId].count(0) + 1
        }
      }

      return {
        ...state,
        channelsMeta,
        channels: sortChannels(channelsMeta, state.channels)
      }
    },
    setChannelIdUnreadCount: (
      state,
      { payload: { channelId, count } }: PayloadAction<{ channelId: string; count: number }>
    ) => {
      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        count
      }
    },
    clearChannelIdUnreadCount: (state, { payload }: PayloadAction<string>) => {
      const channelId = payload
      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        count: 0,
        mentionMsgIds: []
      }
    },
    setUpdateActiveChannelUnreadCount: (state, { payload }: PayloadAction<boolean>) => {
      if (!state.activeChannel) {
        return
      }

      state.activeChannel.updateUnreadCount = payload
    },
    setChannelIdMuteState: (
      state,
      { payload: { channelId, mute } }: PayloadAction<{ channelId: string; mute: boolean }>
    ) => {
      const channelMeta = getChannelIdMeta(state, channelId)

      state.channelsMeta[channelId] = {
        ...channelMeta,
        mute
      }
    },
    // >>> Route Builder
    setActiveRouteBuilder: (state, { payload }: PayloadAction<IActiveRouteBuilder>) => {
      state.activeRouteBuilder = payload
    },
    setActiveRouteBuilderVendor: (
      state,
      {
        payload: { vendorId, authUserId, date, forceDate, higlightActivityId }
      }: PayloadAction<{
        vendorId: string
        authUserId: string
        date?: string
        forceDate?: string
        higlightActivityId?: string
      }>
    ) => {
      state.activeTab = CommunicationHubTab.routeBuilder
      state.channelBrowser = undefined

      if (state.activeRouteBuilder && state.activeRouteBuilder.vendorId) {
        state.activeRouteBuilder = {
          ...state.activeRouteBuilder,
          vendorId,
          authUserId,
          higlightActivityId,
          tabs: state.activeRouteBuilder.tabs.map(tab => {
            if (vendorId === state.activeRouteBuilder.vendorId && tab.date === (date || forceDate)) {
              return tab
            }

            return { ...tab, moves: [], date: forceDate || tab.date }
          })
        }
      } else {
        state.activeRouteBuilder = {
          vendorId,
          authUserId,
          view: RouteBuilderGridView.grid,
          higlightActivityId,
          tabs: [
            {
              isActive: true,
              date: forceDate || date || dateService.createStringDate.startWorkDay,
              moves: []
            }
          ]
        }
      }
    },
    setActivityIdHiglight: (
      state,
      {
        payload: { higlightActivityId }
      }: PayloadAction<{
        higlightActivityId: string | undefined
      }>
    ) => {
      if (state.activeRouteBuilder && state.activeRouteBuilder.vendorId) {
        state.activeRouteBuilder.higlightActivityId = higlightActivityId
      }
    },
    removeActivityIdHiglight: state => {
      if (state.activeRouteBuilder && state.activeRouteBuilder.vendorId) {
        state.activeRouteBuilder.higlightActivityId = undefined
      }
    },
    closeActiveRouteBuilder: state => {
      state.activeRouteBuilder = undefined
    },
    setActiveRouteBuilderTabs: (state, { payload }: PayloadAction<IActiveRouteBuilderTab[]>) => {
      if (!state.activeRouteBuilder) {
        return
      }

      return {
        ...state,
        activeRouteBuilder: {
          ...state.activeRouteBuilder,
          tabs: payload.map(tab => ({ ...tab, moves: sortMovesBySequenceNumber(tab.moves) }))
        }
      }
    },
    setActiveRouteBuilderMoves: (
      state,
      { payload: { date, moves } }: PayloadAction<{ date: string; moves: IMoveDTO[] }>
    ) => {
      if (!state.activeRouteBuilder) {
        return
      }

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map(tab =>
        tab.date === date
          ? {
              ...tab,
              moves: sortMovesBySequenceNumber(moves.map(m => addMoveProperties(m, { activeTabDate: tab.date })))
            }
          : tab
      )
    },
    pushActiveRouteBuilderMoves: (
      state,
      { payload: { date, moves, deleteIds } }: PayloadAction<{ date: string; moves: IMoveDTO[]; deleteIds?: string[] }>
    ) => {
      if (!state.activeRouteBuilder) {
        return
      }

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map(tab => {
        if (tab.date === date) {
          let movesToUpdate = tab.moves.concat(moves.map(m => addMoveProperties(m, { activeTabDate: tab.date })))

          if (deleteIds && deleteIds.length) {
            movesToUpdate = movesToUpdate.filter(move => !deleteIds.includes(move.id))
          }

          return { ...tab, moves: sortMovesBySequenceNumber(filterLatestObjects(movesToUpdate)) }
        }

        return tab
      })
    },
    deleteActiveRouteBuilderMoveIds: (state, { payload }: PayloadAction<string[]>) => {
      if (!state.activeRouteBuilder) {
        return
      }

      const deleteMoveIds = payload

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map(tab => {
        if (tab.moves.some(move => deleteMoveIds.includes(move.id))) {
          return {
            ...tab,
            moves: sortMovesBySequenceNumber(tab.moves.filter(move => !deleteMoveIds.includes(move.id)))
          }
        }

        return tab
      })
    },
    pushActiveRouteBuilderMoveItems: (
      state,
      {
        payload: { moveItems, deleteIds, date }
      }: PayloadAction<{ date: string; moveItems?: IMoveItemDTO[]; deleteIds?: string[] }>
    ) => {
      if (!state.activeRouteBuilder) {
        return
      }

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map(tab => {
        if (tab.date === date) {
          return {
            ...tab,
            moves: tab.moves.map(move => {
              const moveItemsToUpdate = moveItems.filter(({ moveId }) => moveId === move.id)
              let updatedMoveItems = filterLatestObjects(move.items.concat(moveItemsToUpdate))

              if (deleteIds && deleteIds.length) {
                updatedMoveItems = updatedMoveItems.filter(({ id }) => !deleteIds.includes(id))
              }

              return {
                ...move,
                items: updatedMoveItems
              }
            })
          }
        }

        return tab
      })
    },
    setActiveRouteBuilderTabIndex: (state, { payload }: PayloadAction<number>) => {
      if (!state.activeRouteBuilder) {
        return
      }

      const activeTabIndex = payload

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map((tab, index) => ({
        ...tab,
        isActive: activeTabIndex === index
      }))
    },
    toggleActiveRouteBuilderView: state => {
      if (!state.activeRouteBuilder) {
        return
      }

      state.activeRouteBuilder.view =
        state.activeRouteBuilder.view === RouteBuilderGridView.list
          ? RouteBuilderGridView.grid
          : RouteBuilderGridView.list
    },
    setActiveRouteBuilderTabDate: (state, { payload }: PayloadAction<string>) => {
      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map(tab =>
        tab.isActive ? { ...tab, date: payload } : tab
      )
    },
    setActiveRouteBuilderTabIndexDate: (
      state,
      { payload: { tabIndex, date, isActive } }: PayloadAction<{ tabIndex: number; date: string; isActive?: true }>
    ) => {
      if (!state.activeRouteBuilder) {
        return
      }

      state.activeRouteBuilder.tabs = state.activeRouteBuilder.tabs.map((tab, index) => {
        const isSelectedTab = tabIndex === index

        if (isActive) {
          return isSelectedTab ? { ...tab, date, isActive: true, moves: [] } : { ...tab, isActive: false }
        } else {
          return isSelectedTab ? { ...tab, date, moves: [] } : tab
        }
      })
    },
    setActiveRouteBuilderSchedulerTabData: (state, { payload }: PayloadAction<IActiveRouteBuilderSchedulerTabData>) => {
      state.schedulerTabData = payload
    },
    updateActiveRouteBuilderSchedulerTabData: (
      state,
      {
        payload
      }: PayloadAction<{
        specificDate?: string
        ongingDispatchDeliveryOrderIds?: string[]
        activeDeliveryStageVendorIds?: string[]
      }>
    ) => {
      if (!state.schedulerTabData) {
        return
      }

      const updatedSchedulerTabData = payload

      state.schedulerTabData = { ...state.schedulerTabData, ...updatedSchedulerTabData }
    },
    clearActiveRouteBuilderSchedulerTabData: state => {
      state.schedulerTabData = undefined
    },
    // <<< Route Builder
    // EDIT MESSAGES
    setEditMessageId: (state, { payload }: PayloadAction<string>) => {
      state.editMessageId = payload
    },
    // >>> NOTIFICATIONS
    setNotificationFilters: (state, { payload }: PayloadAction<INotificationFilters>) => {
      const filters = payload

      state.notifications.filters = filters
    },
    setNotificationFilter: (state, { payload }: PayloadAction<({ filter: NotificationFilter; value: any })[]>) => {
      const updateFilters = payload

      updateFilters.forEach(({ filter, value }) => {
        // @ts-ignore
        state.notifications.filters[filter] = value
      })
    },
    pushPrevPageNotificationsToStorage: (state, { payload }: PayloadAction<NotificationDTO[]>) => {
      const notifications = payload
      const newSortedIds: string[] = []
      const newItemsMapping: Record<string, NotificationDTO> = {}

      if (Array.isArray(notifications) && notifications.length) {
        notifications.forEach(notification => {
          if (notification && notification.id) {
            newSortedIds.push(notification.id)
            newItemsMapping[notification.id] = notification
          }
        })

        state.notifications.itemsMapping = { ...state.notifications.itemsMapping, ...newItemsMapping }
        state.notifications.sortedIds = R.uniq(state.notifications.sortedIds.concat(newSortedIds))
      }
    },
    setInitialNotificationsToStorage: (state, { payload }: PayloadAction<NotificationDTO[]>) => {
      const notifications = payload
      const newSortedIds: string[] = []
      const newItemsMapping: Record<string, NotificationDTO> = {}

      if (Array.isArray(notifications)) {
        notifications.forEach(notification => {
          if (notification && notification.id) {
            newSortedIds.push(notification.id)
            newItemsMapping[notification.id] = notification
          }
        })

        state.notifications.itemsMapping = newItemsMapping
        state.notifications.sortedIds = newSortedIds
      }
    },
    pushNotificationToStorage: (state, { payload }: PayloadAction<NotificationDTO>) => {
      const notification = payload

      if (notification && notification.id) {
        const { sortedIds, filters } = state.notifications
        const testedNotification = filterNotifications({
          notifications: [notification],
          filters
        })[0]

        if (testedNotification) {
          state.notifications.itemsMapping[notification.id] = notification

          if (!sortedIds.includes(notification.id)) {
            state.notifications.sortedIds.unshift(notification.id)
          }
        } else if (sortedIds.includes(notification.id)) {
          state.notifications.itemsMapping[notification.id] = undefined
          state.notifications.sortedIds = state.notifications.sortedIds.filter(id => id !== notification.id)
        }
      }
    },
    deleteNotificationId: (state, { payload }: PayloadAction<string>) => {
      const notificationId = payload

      state.notifications.itemsMapping[notificationId] = undefined
      state.notifications.sortedIds = state.notifications.sortedIds.filter(id => id !== notificationId)
    },
    setDocumentForms: (state, { payload }: PayloadAction<NotificationDocumentForm[]>) => {
      const documentForms = payload

      if (documentForms && documentForms.length) {
        state.notifications.documentForms = documentForms
      }
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(saveToLocalStorageOnAction, (communicationHub, action) => {
        const initialState = generateInitialCommunicationHubState()

        // >>> save some needed props
        initialState.activeTab = communicationHub.activeTab
        initialState.activeChannel = communicationHub.activeChannel
        initialState.activeRouteBuilder = communicationHub.activeRouteBuilder
          ? {
              ...communicationHub.activeRouteBuilder,
              tabs: communicationHub.activeRouteBuilder.tabs.map(tab => ({ ...tab, moves: [] }))
            }
          : communicationHub.activeRouteBuilder
        initialState.channels.idListByType[ChannelType.recentlyOpened] =
          communicationHub.channels.idListByType[ChannelType.recentlyOpened] || []

        if (communicationHub.notifications) {
          if (communicationHub.notifications.filters) {
            initialState.notifications.filters = communicationHub.notifications.filters
          }
          if (communicationHub.notifications.documentForms) {
            initialState.notifications.documentForms = communicationHub.notifications.documentForms
          }
        }
        // <<<

        communicationHubLocalStorageService.initialState = initialState
      })
      .addMatcher(clearNotificationsOnAction, communicationHub => {
        const isNotificationsActive =
          oc(communicationHub).activeChannel.id() === ActiveChatChannelIdSpecialTypes.NOTIFICATIONS

        if (!isNotificationsActive) {
          communicationHub.notifications.sortedIds = []
          communicationHub.notifications.itemsMapping = {}
        }
      })
  }
})

export const communicationHubActions = communicationHubSlice.actions
export default communicationHubSlice.reducer
