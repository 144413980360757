import { RootState } from '..'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectListItem = (props: { id: string; type: EntityType }) => (state: RootState): any | undefined => {
  const { id, type } = props

  if (id && type) {
    return state.lists[type][id]
  }
}
