import * as React from 'react'

export const CheckmarkCircleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // tslint:disable-next-line:max-line-length
      d="M9.9999 19.6C15.3018 19.6 19.5999 15.3019 19.5999 10C19.5999 4.69807 15.3018 0.400002 9.9999 0.400002C4.69797 0.400002 0.399902 4.69807 0.399902 10C0.399902 15.3019 4.69797 19.6 9.9999 19.6ZM14.4484 8.44853C14.9171 7.9799 14.9171 7.2201 14.4484 6.75147C13.9798 6.28284 13.22 6.28284 12.7514 6.75147L8.7999 10.7029L7.24843 9.15147C6.7798 8.68284 6.02 8.68284 5.55137 9.15147C5.08275 9.6201 5.08275 10.3799 5.55137 10.8485L7.95137 13.2485C8.42 13.7172 9.1798 13.7172 9.64843 13.2485L14.4484 8.44853Z"
      fill="#7CCF81"
    />
  </svg>
)

export const CheckmarkIcon = () => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // tslint:disable-next-line:max-line-length
      d="M13.0356 0.763604C13.3871 1.11508 13.3871 1.68492 13.0356 2.0364L5.83561 9.2364C5.48414 9.58787 4.91429 9.58787 4.56282 9.2364L0.962823 5.6364C0.611351 5.28492 0.611351 4.71508 0.962823 4.3636C1.31429 4.01213 1.88414 4.01213 2.23562 4.3636L5.19922 7.32721L11.7628 0.763604C12.1143 0.412132 12.6841 0.412132 13.0356 0.763604Z"
      fill="#697585"
    />
  </svg>
)
