import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { DriverReportDTO } from '../../../../api/origin/document-service'
import { DriverReportButton } from './DriverReportButton'
import { FieldContainer } from '../FieldContainer'
import { DateTimePicker } from '../DateTimePicker/views'

type Props = {
  reportType: DriverReportDTO.TypeEnum
  report: DriverReportDTO
  reportHistory: DriverReportDTO[]
  onGenerateReportClick: () => void
  disable?: boolean
}

export const DriverReport = (props: Props) => {
  const { disable, reportType, report, reportHistory, onGenerateReportClick } = props

  return (
    <FieldContainer
      title={reportType + ' Report'}
      errorMessageWidth={500}
      errorMessage={oc(report).status() === DriverReportDTO.StatusEnum.FAILED ? report.comment : undefined}
    >
      <Container>
        <DateTimePicker
          disabled={true}
          date={oc(report).date()}
          placeholder={'Report date'}
          hideTimeInput={true}
          onChange={undefined}
        />
        <DriverReportButton
          disable={disable}
          reportType={reportType}
          report={report}
          reportHistory={reportHistory}
          onGenerateReportClick={onGenerateReportClick}
        />
      </Container>
    </FieldContainer>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`
