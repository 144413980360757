import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { FileCategory, IAttachment } from '../../../../services/filesService/interfaces'
import { File } from '../Attachments/File'
import { NoMessagesText } from './MessagesTab'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectUsers } from '../../../../store/select/userSelect'
import {
  selectCommunictaionHubMessageIds,
  selectCommunictaionHubMessages
} from '../../../../store/select/communicationHubSelect'

type AttachmentsByCategories = Record<string, IAttachment[]>

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DocumentsTab = React.memo((props: Props) => {
  const users = useAppSelector(selectUsers)
  const messageIds = useAppSelector(selectCommunictaionHubMessageIds)
  const messageMapping = useAppSelector(selectCommunictaionHubMessages)

  const fileCategoryTypes: FileCategory[] = [
    FileCategory.IMAGE,
    FileCategory.WORKORDER,
    FileCategory.SCALETICKET,
    FileCategory.POD,
    FileCategory.TIR,
    FileCategory.TROUBLETICKET,
    FileCategory.OTHER
  ]

  const fileCategoryTypesMapping = {
    [FileCategory.IMAGE]: 'Images',
    [FileCategory.WORKORDER]: 'Work Orders',
    [FileCategory.SCALETICKET]: 'Scale Tickets',
    [FileCategory.POD]: 'POD',
    [FileCategory.TIR]: 'TIR',
    [FileCategory.TROUBLETICKET]: 'Trouble Ticket',
    [FileCategory.OTHER]: 'Other'
  }

  const RenderAttachment = React.useCallback((attachment: IAttachment): JSX.Element => {
    return <File key={attachment.id} file={attachment} isDocumentTabView={true} />
  }, [])

  const attachmentsByCategories: AttachmentsByCategories = React.useMemo(() => {
    const _attachmentsByCategories: AttachmentsByCategories = {}

    messageIds.forEach(id => {
      const message = messageMapping[id]

      if (message && message.attachments && message.attachments.length) {
        message.attachments.forEach(attachment => {
          const attachmentCategory = attachment.category

          // if (validateFile(attachment).contentType.isImage) {
          //   attachmentCategory = FileCategory.IMAGE
          // }

          if (attachmentCategory) {
            if (!_attachmentsByCategories[attachmentCategory]) {
              _attachmentsByCategories[attachmentCategory] = []
            }

            _attachmentsByCategories[attachmentCategory].push({
              ...attachment,
              senderName: oc(users[message.senderId]).name('Undefined Name'),
              massageCreatedAt: message.createdAt,
              messageMetadata: message.metadata
            })
          }
        })
      }
    })

    Object.keys(_attachmentsByCategories).forEach(category => {
      _attachmentsByCategories[category].sort((a, b) => Date.parse(a.massageCreatedAt) - Date.parse(b.massageCreatedAt))
    })

    return _attachmentsByCategories
  }, [messageMapping, users])

  const noDocuments = React.useMemo(
    () =>
      fileCategoryTypes.every(
        category => !attachmentsByCategories[category] || !attachmentsByCategories[category].length
      ),
    [attachmentsByCategories]
  )

  return (
    <>
      {noDocuments ? (
        <NoMessagesText>No documents yet</NoMessagesText>
      ) : (
        fileCategoryTypes.map(category => {
          if (!attachmentsByCategories[category]) {
            return null
          }

          // work-order-Albert-Enstein -> Albert Enstein Work Order
          if (category === FileCategory.WORKORDER) {
            attachmentsByCategories[category].forEach(attachment => {
              const tokens = attachment.name
                .split('-')
                .map((s: string) => s.replace(/\w\S*/g, (w: any) => w.replace(/^\w/, (c: any) => c.toUpperCase())))
              attachment.name = `${tokens.slice(2).join(' ')} ${tokens[0]} ${tokens[1]}`
            })
          }
          return (
            <React.Fragment key={category}>
              <Title>{fileCategoryTypesMapping[category]}</Title>
              <div>{attachmentsByCategories[category].map(RenderAttachment)}</div>
            </React.Fragment>
          )
        })
      )}
    </>
  )
})

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 24px 0 6px 14px;
  user-select: none;
`
