import * as React from 'react'
import { locationTypeIcons } from '../../../../../services/DTO/location'
import styled from '../../../../../styles/styledComponents'
import { LocationWidgetLabel } from './popover'
import theme from '../../../../../styles/theme'
import { LocationViewDTO } from '../../../../../api/api'

type Props = {
  boldText: string
  locationCompany: string
  locationAddress: string
  locationType: LocationViewDTO.TypeEnum
  isInvalid: boolean
  partialIndicator: boolean
  terminalIntegration: any
}

export const Window = (props: Props) => {
  const {
    boldText,
    locationCompany,
    locationAddress,
    isInvalid,
    locationType,
    terminalIntegration,
    partialIndicator
  } = props

  return (
    <Location>
      {(partialIndicator || isInvalid) && (
        <LabelsBlock>
          {partialIndicator && <PartialLabel />}
          {isInvalid && <InvalidLabel />}
        </LabelsBlock>
      )}

      {terminalIntegration && (
        <TerminalIntegrationIndicator className={terminalIntegration.active ? 'active' : undefined} />
      )}
      <LocationIcon
        style={isInvalid ? { color: theme.colors.defaultRed } : undefined}
        className={locationType ? locationTypeIcons[locationType] : ''}
      />
      <LocationInfo>
        <Title>{boldText}</Title>
        {locationCompany && <CompanyName>{locationCompany}</CompanyName>}
        <Subtitle>{locationAddress}</Subtitle>
      </LocationInfo>
    </Location>
  )
}

const LabelsBlock = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
  left: 4px;

  > * {
    margin-right: 5px;
  }
`

const PartialLabel = styled(LocationWidgetLabel)`
  &:before {
    content: 'Partial';
  }
`
const InvalidLabel = styled(LocationWidgetLabel)`
  background: ${theme.colors.defaultRed};

  &:before {
    content: 'Invalid';
  }
`
const TerminalIntegrationIndicator = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #bdbdbd;
  top: 4px;
  right: 4px;
  &.active {
    background: #27ae60;
  }
`
const Location = styled.div`
  display: flex;
  padding-top: 10px;
`
const LocationIcon = styled.div`
  font-size: 24px;
  color: #4555d0;
  margin-top: -2px;
`
const LocationInfo = styled.div`
  width: calc(100% - 24px);
  padding-left: 10px;
`
const Text = styled.div`
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Title = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  color: #445366;
`
const Subtitle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0.008em;
  color: rgba(68, 83, 102, 0.51);
  margin-top: 5px;
`
const CompanyName = styled(Subtitle)`
  color: #445366;
  margin-top: 4px;
  margin-bottom: -3px;
`
