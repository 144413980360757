import * as React from 'react'
import styled from 'styled-components'
import { GridColumn } from '../../../UI/GridSystem'
import { StyledGeneral } from '../../dispatchDeliveryOrder/views/Details/Activities/styledComponents'
import { DispatchDeliveryOrders } from './DispatchDeliveryOrders'
import { LeftSide } from './LeftSide'
import { RightSide } from './RightSide'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { useDeliveryOrderTab } from '../../../../hooks/useDeliveryOrderTab'
import UpdateInfo from '../../../UI/UpdateInfo'
import { showWarningModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { tabActions } from '../../../../store/reducers/tabs'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { isNewItem } from '../../../../services/utils'
import ActionsSaveCancel from '../../../UI/Buttons/ActionsSaveCancel'
import { saveDeliveryOrder } from '../../../../services/DTO/deliveryOrder/save'
import { isNewObject } from '../../../../services/DTO'
import { checkDeliveryOrderValidation } from '../../../../services/DTO/deliveryOrder'
import { isFullObject } from '../../../../services/functions/test/isFullObject'

type Props = {}

export const DeliveryOrderView = (props: Props) => {
  const dispatch = useAppDispatch()
  const expandedItemData = useExpandedItem()
  const { isFetching, isModified, tabId, clearExpandedItemModifies } = expandedItemData
  const { deliveryOrder } = useDeliveryOrderTab()

  if (!isFullObject(deliveryOrder)) {
    return (
      <StyledPreview background={'#E5E7EF'} padding={{ top: 10, left: 10, right: 10 }}>
        <GridSpinner />
      </StyledPreview>
    )
  }

  return (
    <StyledPreview background={'#E5E7EF'} padding={{ top: 10, left: 10, right: 10 }}>
      {isFetching && <GridSpinner />}
      <div className={'delivery-order_container'}>
        <StyledGeneral background={'white'} padding={{ top: 20, left: 25, right: 25, bottom: 40 }}>
          <InfoContainer>
            {isModified ? (
              <ActionsSaveCancel
                disableSave={!checkDeliveryOrderValidation(expandedItemData)}
                showCancel={!isNewItem(deliveryOrder)}
                onSave={() => saveDeliveryOrder(expandedItemData)}
                onCancel={
                  isNewObject(deliveryOrder)
                    ? () => {
                        showWarningModalWindow({
                          title:
                            'Are you sure you want to close the "New Delivery Order" tab? All changes will be lost',
                          buttons: [
                            { label: 'Cancel' },
                            { label: 'Confirm', onClick: () => dispatch(tabActions.deleteTab(tabId)) }
                          ]
                        })
                      }
                    : clearExpandedItemModifies
                }
              />
            ) : (
              <UpdateInfo
                updatedAt={deliveryOrder.updatedAt}
                updatedBy={deliveryOrder.updatedBy}
                createdAt={deliveryOrder.createdAt}
                createdBy={deliveryOrder.createdBy}
              />
            )}
          </InfoContainer>
          <LeftSide />
          <RightSide />
        </StyledGeneral>

        <DispatchDeliveryOrders />
      </div>
    </StyledPreview>
  )
}

const StyledPreview = styled(GridColumn)`
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 85px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-top: none;

  .delivery-order_container {
    width: 100%;
    position: relative;
  }

  .updatedInfo,
  .actions-save-cancel {
    z-index: 1;
    height: 45px;
    top: 10px;
    bottom: unset;
  }

  ${StyledGeneral} {
    border-radius: 5px;
    padding-top: 75px;
  }
`

const InfoContainer = styled.div`
  position: absolute;
  right: 0;
  top: 6px;

  .updatedInfo {
    margin-top: 10px;
    .created-info,
    .updated-info {
      width: auto;
      margin-right: 30px;
    }
  }
`
