import * as React from 'react'
import cn from 'classnames'
import { StyledSelect, Selected, ButtonClear } from './styles'
import { Dropdown, IDropdownItem } from './Dropdown'
import { FieldContainer } from '../FieldContainer'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { getLabelAndPopover } from './functions'

type Props = {
  disabled?: boolean
  search?: boolean
  multiselect?: boolean
  separatePopoverRows?: boolean
  title?: string
  placeholder?: string
  highlighted?: boolean
  required?: boolean
  mutuallyExclusive?: (string | IDropdownItem)[] | (string | IDropdownItem | (string | IDropdownItem)[])[]
  valid?: boolean
  containerStyle?: React.CSSProperties
  style?: React.CSSProperties
  dropdownStyle?: object
  popover?: string | JSX.Element
  label?: string | JSX.Element
  selectedValue: string | string[] | any
  list: IDropdownItem[] | IDropdownItem[][]
  onSelect?: (value: string | string[] | any) => void
  hidePopover?: boolean
  buttonClear?: boolean
  mapping?: IDropdownItem[]
}

export const Select = (props: Props) => {
  const {
    disabled,
    search,
    multiselect,
    separatePopoverRows,
    containerStyle,
    style,
    dropdownStyle,
    title,
    placeholder = 'Select',
    mutuallyExclusive,
    highlighted,
    required,
    popover,
    label,
    selectedValue,
    list,
    onSelect,
    hidePopover,
    buttonClear,
    mapping
  } = props

  const { statePopover, togglePopover, wrapperRef, setPopoverState } = useOutsideClick()
  const [searchValue, setSearchValue] = React.useState('')
  const isEmpty = !label && !selectedValue
  const dropdownList = Array.isArray(list[0]) ? (list as IDropdownItem[][]) : ([list] as IDropdownItem[][])
  const toggle = React.useCallback(() => (disabled ? undefined : togglePopover()), [disabled, togglePopover])
  const { Label, Popover } = getLabelAndPopover({
    multiselect,
    separatePopoverRows,
    popover,
    placeholder,
    label,
    list: dropdownList,
    selectedValue,
    mapping
  })

  React.useEffect(() => {
    if (!statePopover) {
      return
    }

    const closeDropdownOnScroll = (event: Event) => {
      if (!wrapperRef.current.contains(event.target)) {
        setPopoverState(false)
      }
    }

    window.addEventListener('scroll', closeDropdownOnScroll, true)

    return () => {
      window.removeEventListener('scroll', closeDropdownOnScroll, true)
    }
  }, [statePopover])

  return (
    <FieldContainer
      title={title}
      required={required}
      turnOver={true}
      className={'select__container'}
      disabled={disabled}
      style={containerStyle}
    >
      <StyledSelect
        className={cn('select-container selected', {
          'hide-popover': hidePopover,
          disabled,
          opened: statePopover,
          hasPopover: Popover,
          highlighted: highlighted || (required && !selectedValue)
        })}
        ref={wrapperRef}
        style={style}
      >
        <Selected
          className={cn('selected__label mdi', {
            disabled,
            'mdi-menu-down': !statePopover,
            'mdi-menu-up': statePopover,
            placeholder: isEmpty
          })}
          onClick={toggle}
        >
          {Label}
          {buttonClear && selectedValue && (
            <ButtonClear
              className={'mdi mdi-close-circle'}
              onClick={event => {
                event.stopPropagation()
                return onSelect(null)
              }}
            />
          )}
        </Selected>
        {!hidePopover && !statePopover && Popover}
        {statePopover && (
          <Dropdown
            wrapperRef={wrapperRef}
            search={search}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            multiselect={multiselect}
            style={dropdownStyle}
            mutuallyExclusive={mutuallyExclusive}
            selectedValue={selectedValue}
            list={dropdownList}
            onClick={value => {
              if (!multiselect) {
                toggle()
              }
              onSelect(value)
            }}
          />
        )}
      </StyledSelect>
    </FieldContainer>
  )
}
