import { RootState } from '..'
import {
  CommunicationHubTab,
  IActiveRouteBuilder,
  IActiveRouteBuilderSchedulerTabData
} from '../reducers/communicationHub/interfaces'

export const selectRouteBuilder = (state: RootState): IActiveRouteBuilder | undefined => {
  return state.communicationHub.activeRouteBuilder
}

export const selectRouteBuilderIsRouteBuilderViewExpanded = (state: RootState): boolean => {
  const { activeRouteBuilder } = state.communicationHub

  return Boolean(activeRouteBuilder)
}

export const selectRouteBuilderIsRouteBuilderView = (state: RootState): boolean => {
  const { activeTab, activeRouteBuilder } = state.communicationHub

  return activeTab === CommunicationHubTab.routeBuilder && Boolean(activeRouteBuilder)
}

export const selectRouteBuilderDriverId = (state: RootState): string | undefined => {
  const { vendorId } = state.communicationHub.activeRouteBuilder || {}

  return vendorId
}

export const selectRouteBuilderDriverAuthUserId = (state: RootState): string | undefined => {
  const { authUserId } = state.communicationHub.activeRouteBuilder || {}

  return authUserId
}

export const selectRouteBuilderSchedulerTabData = (state: RootState): IActiveRouteBuilderSchedulerTabData => {
  return state.communicationHub.schedulerTabData
}

export const selectRouteBuilderIncludesMoveId = (moveId: string) => (state: RootState): boolean => {
  const {
    communicationHub: { activeRouteBuilder, activeTab }
  } = state
  const activeRouteBuilderTab =
    activeTab === CommunicationHubTab.routeBuilder &&
    activeRouteBuilder &&
    activeRouteBuilder.tabs.find(({ isActive }) => isActive)

  return activeRouteBuilderTab ? activeRouteBuilderTab.moves.some(({ id }) => id === moveId) : false
}

export const selectRouteBuilderIncludesDDOId = (dispatchDeliveryOrderId: string) => (state: RootState): boolean => {
  const {
    communicationHub: { activeRouteBuilder, activeTab }
  } = state
  const activeRouteBuilderTab =
    activeTab === CommunicationHubTab.routeBuilder &&
    activeRouteBuilder &&
    activeRouteBuilder.tabs.find(({ isActive }) => isActive)

  return activeRouteBuilderTab
    ? activeRouteBuilderTab.moves.some(item => item.dispatchDeliveryOrderId === dispatchDeliveryOrderId)
    : false
}

export const selectRouteBuilderIsActiveSelectedVendor = ({
  vendorId,
  authUserId
}: {
  vendorId?: string
  authUserId?: string
}) => (state: RootState): boolean => {
  const { activeTab, activeRouteBuilder } = state.communicationHub

  if (activeTab === CommunicationHubTab.routeBuilder && activeRouteBuilder) {
    if (vendorId) {
      return activeRouteBuilder.vendorId === vendorId
    }

    if (authUserId) {
      return activeRouteBuilder.authUserId === authUserId
    }
  }

  return false
}
