import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { BuySideQuoteDTO } from '../../../../../api/api'
import { BsqWidgetRowDetails } from './BsqWidgetRowDetails'
import theme from '../../../../../styles/theme'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import activityIcons from '../../../../../constants/activityIcons'
import { quoteDirectory } from '../../../../../services/DTO/sellSideQuote/directory'
import constants from '../../../../../constants'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../store/select/driverSelect'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

type Props = {
  buySideQuote: BuySideQuoteDTO
  isExpanded: boolean
  toggleExpand: () => void
}

export const BsqWidgetRow = React.memo((props: Props) => {
  const { buySideQuote, isExpanded, toggleExpand } = props
  const {
    quoteAmounts: { buySideQuotesAmount }
  } = useSsqBsqWidget()
  const { activities } = useGridItemDDO()
  const isUnsuccessful = buySideQuote.type === BuySideQuoteDTO.TypeEnum.UNSUCCESSFUL
  const activity = activities.transportationActivities.find(a => a.id === buySideQuote.activityId)
  const activityVendorName = useAppSelector(selectDriverName(oc(activity).vendorId()))
  let activityTypeName = oc(constants.bsqTypes[buySideQuote.type]).title('Empty Type')
  let RenderIcon: () => JSX.Element = oc(constants.bsqTypes[buySideQuote.type]).icon(() => <div />)

  if (
    [BuySideQuoteDTO.TypeEnum.ADDITIONALSURCHARGE, BuySideQuoteDTO.TypeEnum.UNSUCCESSFUL].includes(buySideQuote.type)
  ) {
    activityTypeName = activityDirectory.type[activity.comboType] || ''
    RenderIcon = activity.comboType ? activityIcons[activity.comboType].icon : () => <div />
  } else if (buySideQuote.type === BuySideQuoteDTO.TypeEnum.BOBTAIL) {
    activityTypeName = quoteDirectory.typeOfRate[BuySideQuoteDTO.TypeEnum.BOBTAIL]
    RenderIcon = activity.comboType ? activityIcons[activity.comboType].icon : () => <div />
  }

  return (
    <>
      <Row onClick={toggleExpand}>
        <Icon className={cn(String(activity.stage), { UNSUCCESSFUL: isUnsuccessful })}>
          <RenderIcon />
        </Icon>
        <Title>{isUnsuccessful ? <GrayText>{activityTypeName}</GrayText> : activityTypeName}</Title>
        <Driver>
          {activityVendorName ? (
            isUnsuccessful ? (
              <GrayText>{activityVendorName}</GrayText>
            ) : (
              activityVendorName
            )
          ) : (
            <GrayText>No driver assigned</GrayText>
          )}
        </Driver>
        <Amount>{transformMoney(buySideQuotesAmount[buySideQuote.id])}</Amount>
        <OpenMarker className={'mdi ' + (isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')} />
      </Row>
      {isExpanded && <BsqWidgetRowDetails buySideQuote={buySideQuote} activityVendorName={activityVendorName} />}
    </>
  )
})

const Row = styled.div`
  flex-shrink: 0;
  height: 54px;
  display: grid;
  grid-template-columns: 60px minmax(80px, auto) 1fr auto 50px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  color: #445366;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.rowHoverColor};
  }
`
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.UNSUCCESSFUL {
    opacity: 0.7;
  }

  &.PICKUP {
    svg {
      fill: #7c7fcf;
    }
  }

  &.DELIVERY {
    svg {
      fill: #c179d1;
    }
  }

  &.RETURN {
    svg {
      fill: #7ccf81;
    }
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
`
const GrayText = styled.span`
  color: #bdbdbd;
`
const Driver = styled.div`
  font-weight: 500;
  font-size: 16px;
  padding: 0 25px;
`
const Amount = styled.div`
  justify-self: flex-end;
  font-weight: 500;
  font-size: 14px;
`
const OpenMarker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #bdbdbd;
`
