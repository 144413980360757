import * as equal from 'fast-deep-equal'
import * as R from 'remeda'
import { saveLocation } from './location/save'
import { savePowerUnit } from './powerUnit/save'
import { saveEquipment } from './equipment/save'
import { saveDriver } from './driver/save'
import { saveSteamShipLine } from './steamShipLine/save'
import { saveContainer } from './container/save'
import { saveSubClient } from './subClient/save'
import { saveCustomer } from './customer/save'
import { saveDispatchDeliveryOrder } from './dispatchDeliveryOrder/save'
import { showWarningModalWindow } from '../../store/reducers/modalWindow/functions'
import { TabDTO } from '../../store/reducers/tabs/interfaces'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { oc } from 'ts-optchain'
import { IModalWindow } from '../../store/reducers/modalWindow/interfaces'

export const convertErrorToMessage = (error: any | any[], promiseInfo?: string): string => {
  try {
    const generateError = (err: any): string => {
      if (typeof err === 'string') {
        return err
      } else if (err.message) {
        return [err.field, err.message].filter(Boolean).join(': ')
      }

      throw undefined
    }
    if (Array.isArray(error)) {
      return error.map(generateError).join(`\n`)
    } else {
      return generateError(error)
    }
  } catch (e) {
    return 'Unknown error'
  }
}

export const omitUpdateInfo = (obj: any) => {
  return obj ? R.omit(obj, ['updatedAt', 'updatedBy', 'createdBy', 'createdAt']) : obj
}

export const isEqualObjects = (obj1: any = {}, obj2: any = {}, omit: string[] = []) => {
  const commonArray = ['updatedAt', 'updatedBy', 'createdBy', 'createdAt', ...omit]
  return equal(R.omit(obj1, commonArray), R.omit(obj2, commonArray))
}
type TSave = {
  condition: boolean
  discardChanges: () => void
  save: () => void
  cancel?: () => void
  hideSpinner?: () => void
  modalWindowProps?: Partial<IModalWindow>
}

export const tryToSave = ({
  condition,
  modalWindowProps,
  save,
  discardChanges = () => {},
  cancel = () => {},
  hideSpinner = () => {}
}: TSave) => {
  if (condition) {
    return save()
  }

  hideSpinner()
  showWarningModalWindow({
    title: 'There is newer data for the object',
    content: oc(modalWindowProps).content(),
    buttons: [
      { label: 'Save anyway', onClick: save },
      { label: 'Discard my changes', onClick: discardChanges },
      { label: 'Close', onClick: cancel }
    ],
    ...(modalWindowProps || {})
  })
}

// COMBINED SAVE ACTIONS
export const saveObject: Partial<Record<EntityType, any>> = {
  [TabDTO.Type.location]: saveLocation,
  [TabDTO.Type.powerUnit]: savePowerUnit,
  [TabDTO.Type.equipment]: saveEquipment,
  [TabDTO.Type.driver]: saveDriver,
  [TabDTO.Type.steamShipLine]: saveSteamShipLine,
  [TabDTO.Type.container]: saveContainer,
  [TabDTO.Type.subClient]: saveSubClient,
  [TabDTO.Type.customer]: saveCustomer,
  [TabDTO.Type.dispatchDeliveryOrder]: saveDispatchDeliveryOrder
}
