import { oc } from 'ts-optchain'
import { ITabExpandedItem } from '../../../store/reducers/tabs/interfaces'

export const getModifiedObjectState = (tabExpandedItem: ITabExpandedItem): any => {
  if (!(tabExpandedItem && tabExpandedItem.modifiedLists)) {
    return
  }

  const { id, entityType, modifiedLists } = tabExpandedItem

  return oc(modifiedLists)[entityType][id]()
}
