import * as React from 'react'
import { toast } from 'react-toastify'
import { oc } from 'ts-optchain'
import {
  callAPI,
  troubleTicketDocumentAPI,
  transportationActivityAPI,
  TransportationActivityViewDTO,
  callAPIWithErrorMessage,
  dispatchDeliveryOrderAPI,
  ActivitiesDTO
} from '../../../../api/api'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

export const requestUpdateActivities = (props: {
  activities: ActivitiesDTO
  ddoId: string
}): Promise<ActivitiesDTO> => {
  const { activities, ddoId } = props

  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.updateActivities, ddoId, activities).then(
    async (updatedActivities: ActivitiesDTO) => {
      const items = [
        ...oc(updatedActivities).transportationActivities([]),
        ...oc(updatedActivities).documentationActivities([])
      ]

      await pushListItemsToStore({ update: { [EntityType.activity]: items } })

      return updatedActivities
    }
  )
}

export enum TroubleTicketResolveAction {
  proceed = 'proceed',
  unsuccessful = 'unsuccessful'
}

export const resolveTroubleTicketId = async (props: {
  action: TroubleTicketResolveAction
  troubleTicketId: string
  notes?: string
  handleError?: boolean
}) => {
  const { troubleTicketId, notes, handleError } = props
  let action = undefined

  switch (props.action) {
    case TroubleTicketResolveAction.proceed:
      action = troubleTicketDocumentAPI.proceed
      break
    case TroubleTicketResolveAction.unsuccessful:
      action = troubleTicketDocumentAPI.unsuccessful
      break
    default:
      return
  }

  return callAPI(action, troubleTicketId, { id: troubleTicketId, notes })
    .toPromise()
    .catch(async error => {
      let message = 'Resolve Trouble Ticket error: ' + troubleTicketId

      if (error && error.json) {
        const details = await error.json()
        message = details ? details.message || (typeof details === 'string' && details) || message : message
      }

      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>{message}</div>
          </div>
        </>
      )

      if (handleError) {
        throw message
      }
    })
}

export const requestTransporationActivitiesByIds = async (
  activityIds: string[]
): Promise<TransportationActivityViewDTO[]> => {
  if (!(activityIds && activityIds.length)) {
    return Promise.resolve([])
  }

  const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(activityIds)
  const requestedTransporationActivities: (TransportationActivityViewDTO)[] = []

  if (arrayOfRequestedIdsArrays.length) {
    await Promise.all(
      arrayOfRequestedIdsArrays.map(ids =>
        requestTransporationActivities({ filter: 'id%%' + ids.join(',') }, false).then(transporationActivities => {
          requestedTransporationActivities.push(...transporationActivities)
        })
      )
    )

    await pushListItemsToStore({ update: { [EntityType.activity]: requestedTransporationActivities } })
  }

  return requestedTransporationActivities
}

export const requestTransporationActivities = (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<TransportationActivityViewDTO[]> => {
  return callAPI(transportationActivityAPI.findAll, filter, sort)
    .toPromise()
    .then(async transportationActivities => {
      if (pushToStore) {
        await pushListItemsToStore({ update: { [EntityType.activity]: transportationActivities } })
      }

      return transportationActivities
    })
}
