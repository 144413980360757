import * as React from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectCommunictaionHubIsVisible } from '../../../store/select/communicationHubSelect'
import { NavigationHeader } from '../../CommunicationHub/views/Navigation/NavigationHeader'
import { leaveChatChannelIfNoEditing } from '../../CommunicationHub/functions/leaveChatChannelIfNoEditing'
import { communicationHubActions } from '../../../store/reducers/communicationHub'

export const CommunicationHubButtons = () => {
  const dispatch = useAppDispatch()
  const isCommunicationHubVisible = useAppSelector(selectCommunictaionHubIsVisible)

  return isCommunicationHubVisible ? (
    <HideButton
      className={'mdi mdi-chevron-right'}
      onClick={() =>
        leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.setActiveTab({ activeTab: undefined })))
      }
    />
  ) : (
    <Container>
      <NavigationHeader />
    </Container>
  )
}

const Container = styled.div`
  width: 300px;

  > * {
    height: 100%;
    border: none;
  }

  .c-hub-tab {
    color: #d8dae5;

    &:first-child {
      border-right: 1px solid #3e4856;
    }

    &:hover {
      color: white;
      background: #475264;
    }
  }
`
const HideButton = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #445366;
  font-size: 24px;
  background-color: rgb(7, 21, 56);
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  &:hover {
    color: white;
  }
`
