import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { EditorState } from 'draft-js'
import { ReactInput } from './ReactInput'
import { sendChannelMessage } from '../../functions/sendChannelMessage'
import { useCommunicationHubDraftMessage } from '../../../../hooks/useCommunicationHubDraftMessage'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelName } from '../../../../store/select/communicationHubSelect'
import { IAttachment } from '../../../../services/filesService/interfaces'

type OwnProps = {
  channelId: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DraftMessageEditor = React.memo((props: Props) => {
  const { channelId } = props
  const channelName = useAppSelector(selectCommunictaionHubChannelName(channelId))
  const { draftMessages, setDraftMessageEditor, setDraftMessageState } = useCommunicationHubDraftMessage()
  const [rerenderInput, setRerenderInput] = React.useState<number>(0)
  const draftMessage = draftMessages[channelId]
  const noAttachmentUploading = React.useMemo(() => {
    return oc(draftMessage)
      .attachments([])
      .every((a: IAttachment) => a.uploadingProgress === undefined)
  }, [oc(draftMessage).attachments()])
  const editorState = React.useMemo(() => oc(draftMessage).editor(), [oc(draftMessage).editor()])

  const sendMessage = React.useCallback(
    editorState
      ? () => {
          sendChannelMessage({
            editorState,
            attachments: oc(draftMessage).attachments([]),
            channelId
          })

          setDraftMessageState({ channelId, draftMessage: undefined })
          setRerenderInput(n => n + 1)
        }
      : undefined,
    [draftMessage]
  )

  const onEditorChange = React.useCallback((editor: EditorState) => setDraftMessageEditor({ channelId, editor }), [])

  return (
    <Container>
      <ReactInput
        key={rerenderInput}
        channelId={channelId}
        placeholder={`Send message to ${channelName || 'the channel'}`}
        editorState={editorState}
        onEditorChange={onEditorChange}
        onSendMessage={noAttachmentUploading ? sendMessage : undefined}
      />
    </Container>
  )
})

const Container = styled.div`
  flex-shrink: 0;
  position: relative;
  font-size: 14px;
  padding: 0 15px 12px;
  margin-top: auto;
`
