import * as React from 'react'
import styled from 'styled-components'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'
import { ChannelBrowser } from '../ChannelBrowser/ChannelBrowser'
import { ChannelView } from '../ChannelView/ChannelView'
import { RouteBuilderView } from '../RouteBuilderView/RouteBuilderView'
import {
  selectCommunictaionHubIsActiveChannelView,
  selectCommunictaionHubIsChannelBrowserView
} from '../../../../store/select/communicationHubSelect'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectRouteBuilderIsRouteBuilderView } from '../../../../store/select/routeBuilderSelect'
import { selectNotificationsIsNotifications } from '../../../../store/select/notificationSelect'
import { NavigationsView } from '../Notifications/NotificationsView'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DetailsView = (props: Props) => {
  const isChannelBrowser = useAppSelector(selectCommunictaionHubIsChannelBrowserView)
  const isActiveChannel = useAppSelector(selectCommunictaionHubIsActiveChannelView)
  const isRouteBuilder = useAppSelector(selectRouteBuilderIsRouteBuilderView)
  const isNotifications = useAppSelector(selectNotificationsIsNotifications)

  const RenderViewComponent = React.useMemo(() => {
    switch (true) {
      case isChannelBrowser:
        return <ChannelBrowser />
      case isRouteBuilder:
        return <RouteBuilderView />
      case isNotifications:
        return <NavigationsView />
      case isActiveChannel:
        return <ChannelView />
      default:
        return null
    }
  }, [isChannelBrowser, isActiveChannel, isRouteBuilder, isNotifications])

  return RenderViewComponent ? <Container>{RenderViewComponent}</Container> : null
}

const Container = styled.div`
  position: relative;
  width: ${CommunicationHubConstants.channelViewWidth}px;
  display: flex;
  flex-direction: column;
  background-color: #fcfdff;
`
