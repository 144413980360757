import { oc } from 'ts-optchain'
import { IWebsocketData, WebsocketEvent } from './interfaces'
import { DriverReportDTO } from '../../api/origin/document-service'
import { getApplicationTabsState, getDispatch, getListsState } from '../../store'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { ITabExpandedItem, TabDTO } from '../../store/reducers/tabs/interfaces'
import { tabActions } from '../../store/reducers/tabs'

export const documentServiceWebsocket = (data: IWebsocketData) => {
  const { type, eventType, payload } = data

  if (type === 'DriverReportDTO') {
    const report = payload as DriverReportDTO
    const driverMapping = getListsState()[EntityType.driver]
    const driverTabs = getApplicationTabsState().filter(tab => tab.type === TabDTO.Type.driver)
    // const viewingObjects = store.viewingObjects

    driverTabs.forEach(tab => {
      const expandedItem: ITabExpandedItem = tab.expandedItem
      const expandedDriverId = expandedItem.id

      if (
        expandedDriverId &&
        oc(report).userId() &&
        oc(driverMapping)[expandedDriverId].authUserId() === report.userId &&
        oc(expandedItem).data.vendorAccountingReports()
      ) {
        const updatedVendorAccountingReports = { ...expandedItem.data.vendorAccountingReports }

        updatedVendorAccountingReports[report.type] = {
          ...(updatedVendorAccountingReports[report.type] || {}),
          [report.id]: report
        }

        getDispatch()(
          tabActions.mergeExpandedItemData({
            mergeProps: { vendorAccountingReports: updatedVendorAccountingReports }
          })
        )
      }
    })
  }
}
