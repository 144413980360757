import { TAuditTrailFilter, TAuditTrailListItem } from './interfaces'
import { resetTimeToZero } from '../../../../../../services/timeService/dateUtils'

export const transformString = (str: string) => (str ? str.toLowerCase().replace(/\W/gi, '') : str)
export const makeListItems = (list: Record<string, any>) => {
  return list.map((item: TAuditTrailFilter) => {
    return {
      ...item,
      filterValues: {
        updatedBy: transformString(item.updatedBy),
        fieldName: transformString(item.fieldName),
        newValue: transformString(item.newValue),
        oldValue: transformString(item.oldValue)
      }
    }
  })
}

export const testListItem = (item: TAuditTrailListItem, filter: TAuditTrailFilter): boolean => {
  const updatedAt =
    !filter.updatedAt ||
    (Date.parse(resetTimeToZero(filter.updatedAt)) < Date.parse(item.updatedAt) &&
      Date.parse(item.updatedAt) < Date.parse(resetTimeToZero(filter.updatedAt, true)))
  const updatedBy = !filter.updatedBy || item.filterValues.updatedBy.indexOf(transformString(filter.updatedBy)) !== -1
  const fieldName = !filter.fieldName || item.filterValues.fieldName.indexOf(transformString(filter.fieldName)) !== -1
  const newValue = !filter.newValue || item.filterValues.newValue.indexOf(transformString(filter.newValue)) !== -1
  const oldValue = !filter.oldValue || item.filterValues.oldValue.indexOf(transformString(filter.oldValue)) !== -1
  const activityNumber =
    !filter.activityNumber ||
    (item.activityNumber && String(item.activityNumber).indexOf(filter.activityNumber.trim()) !== -1)

  return updatedAt && updatedBy && fieldName && newValue && oldValue && activityNumber
}
