import * as React from 'react'
import { oc } from 'ts-optchain'
import { TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { SteamShipLineColumn } from '../../../grid/columnSettings/steamShipLineColumnSettings'
import { useGridItemSteamShipLine } from '../../../../../hooks/useGridItemSteamShipLine'
import { getContactName } from '../../../../../services/functions/get/getContactName'

const Column = React.memo((props: TGridColumn) => {
  const { className, columnName, style } = props
  const { steamShipLine, businessPartner, primaryContact } = useGridItemSteamShipLine()
  let text = columnName

  switch (columnName) {
    case SteamShipLineColumn.Name: {
      text = oc(businessPartner).legalName()
      break
    }
    case SteamShipLineColumn.ReferenceNumber: {
      text = oc(steamShipLine).referenceNumber()
      break
    }
    case SteamShipLineColumn.FreeDetentionAllowance: {
      text = steamShipLine.freeDetentionAllowance ? steamShipLine.freeDetentionAllowance + ' days' : undefined
      break
    }
    case SteamShipLineColumn.PrimaryContact: {
      text = getContactName(primaryContact)
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
})

export default Column
