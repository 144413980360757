import * as React from 'react'
import styled from 'styled-components'
import { SaveCancelButtons } from '../../../UI/Buttons/ActionsSaveCancel'
import UpdateInfo from '../../../UI/UpdateInfo'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../../../services/utils'
import { useGridItemDDO } from '../../../../hooks/useGridItemDDO'

export type TUnitStatus = {
  isValid: boolean
}

type Props = TUnitStatus

export const UnitStatus = (props: Props) => {
  const { isValid } = props
  const onSaveClickRef = React.useRef(null)
  const {
    isModified,
    parentItem,
    entityType,
    clearExpandedItemModifies,
    clearExpandedItem,
    saveUpdatedEntities
  } = useExpandedItem()
  let onSaveClick = () => saveUpdatedEntities()

  if (entityType === EntityType.dispatchDeliveryOrder) {
    const gridItemDDOProviderData = useGridItemDDO()

    onSaveClick = (options?: any) => {
      saveUpdatedEntities({ gridItemDDOProviderData, retrySave: onSaveClickRef.current, options: options || {} })
    }
  }

  onSaveClickRef.current = onSaveClick

  if (isModified) {
    return (
      <Container>
        <SaveCancelButtons
          onCancel={isNewItem(parentItem) ? clearExpandedItem : clearExpandedItemModifies}
          onSave={onSaveClick}
          disableSave={!isValid}
        />
      </Container>
    )
  }

  return entityType === EntityType.dispatchDeliveryOrder ? null : (
    <Container>
      <UpdateInfo
        updatedAt={parentItem.updatedAt}
        updatedBy={parentItem.updatedBy}
        createdAt={parentItem.createdAt}
        createdBy={parentItem.createdBy}
      />
    </Container>
  )
}

const Container = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: 20px;
`
