import { EquipmentDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.equipment]: EquipmentDTO[]
}

export const parseEquipments = async (equipments: EquipmentDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.equipment]: []
  }

  try {
    equipments.forEach(equipment => {
      result[EntityType.equipment].push(equipment)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
