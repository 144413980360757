import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { Title } from './AccountingTab'
import { InputField } from '../../../../../UI/DataFields/Input'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { DeductionDTO, DeductionType } from '../../../../../../api/origin/vendor-accounting-service'
import { isNewObject } from '../../../../../../services/DTO'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { DriverViewDTO } from '../../../../../../api/api'
import theme from '../../../../../../styles/theme'
import { testVendorAccounting } from '../../../../../../services/functions/test/testVendorAccounting'
import { isDriverFieldRequired } from '../../../../../../services/functions/test/isDriverFieldRequired'

type Props = {}

export const AccountingTabDeductionsWeekly = (props: Props) => {
  const {
    driver,
    vendorAccounting,
    weeklyDeductions,
    createDeduction,
    removeDeductionById,
    updateDeductionField,
    setShowAccountingPopup
  } = useDriverDeductions()
  const driverType = oc(driver).driverType()
  const hideBankingDatails = Boolean(driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && oc(driver).parentId())
  let WCInsuranceDeduction: DeductionDTO = undefined
  let ParkingDeduction: DeductionDTO = undefined
  let ELDDeduction: DeductionDTO = undefined

  weeklyDeductions.forEach(deduction => {
    if (deduction.type === DeductionType.WCINSURANCE) {
      WCInsuranceDeduction = deduction
    } else if (deduction.type === DeductionType.PARKING) {
      ParkingDeduction = deduction
    } else if (deduction.type === DeductionType.ELD) {
      ELDDeduction = deduction
    }
  })

  const setDeductionAmount = (deduction: DeductionDTO) => (type: DeductionType) => (deductionAmount: number) => {
    if (typeof deductionAmount !== 'number' && isNewObject(deduction)) {
      removeDeductionById(deduction.id)
    } else {
      if (deduction) {
        updateDeductionField(deduction)('deductionAmount')(deductionAmount)
      } else {
        createDeduction({ deductionAmount, type })
      }
    }
  }

  return (
    <Container>
      {driverType !== DriverViewDTO.DriverTypeEnum.VENDOR && (
        <>
          <Title style={{ marginBottom: 24 }}>Deductions (weekly)</Title>
          <InputField.Money
            inputContainerStyle={{ marginBottom: 17 }}
            title={'WC Insurance'}
            value={oc(WCInsuranceDeduction).deductionAmount()}
            placeholder={'$'}
            onChange={setDeductionAmount(WCInsuranceDeduction)(DeductionType.WCINSURANCE)}
          />
          <InputField.Money
            inputContainerStyle={{ marginBottom: 17 }}
            title={'Parking'}
            value={oc(ParkingDeduction).deductionAmount()}
            placeholder={'$'}
            onChange={setDeductionAmount(ParkingDeduction)(DeductionType.PARKING)}
          />
          <InputField.Money
            inputContainerStyle={{ marginBottom: 28 }}
            title={'ELD'}
            value={oc(ELDDeduction).deductionAmount()}
            placeholder={'$'}
            onChange={setDeductionAmount(ELDDeduction)(DeductionType.ELD)}
          />
        </>
      )}

      {!hideBankingDatails && (
        <ActionButton
          styles={{
            width: '100%',
            height: 35,
            marginBottom: 15
          }}
          red={
            vendorAccounting && isDriverFieldRequired(driver).bankingDetails && !testVendorAccounting(vendorAccounting)
          }
          filled={true}
          onClick={() => setShowAccountingPopup(true)}
        >
          Show Banking Details
        </ActionButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 28px 40px 22px 26px;
`
