import { LocationViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'
import { generateAddress } from './generateAddress'

export const generateLocation = (props?: Partial<LocationViewDTO>): LocationViewDTO => ({
  completeness: maxCompleteness,
  address: generateAddress(),
  altitude: null,
  careOf: null,
  contactIds: [],
  description: null,
  id: createId(),
  status: LocationViewDTO.StatusEnum.FULL,
  latitude: null,
  longitude: null,
  name: null,
  notes: null,
  placeId: null,
  primary: false,
  primaryContactId: null,
  rating: null,
  timezone: null,
  type: LocationViewDTO.TypeEnum.WAREHOUSEWITHDOCK,
  unlocode: null,
  webSite: null,
  workingHours: null,
  active: true,
  ...(props || {})
})
