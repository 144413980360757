import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { SubClientViewDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useSubClient = (props: Props): SubClientViewDTO => {
  const { id, modifiedLists } = props
  const subClient = useListItem({ id, type: EntityType.subClient, modifiedLists })

  return subClient
}
