import * as React from 'react'
import { BusinessPartnerViewDTO, CustomerViewDTO, SubClientViewDTO } from '../api/api'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { EntityType } from '../store/reducers/lists/interfaces'
import { SubClientsTabContext } from '../contexts/SubClientsTabContext'
import { generateBusinessPartner } from '../services/functions/generate/generateBusinessPartner'
import { generateSubClient } from '../services/functions/generate/generateSubClient'
import { requestSubClientIds } from '../components/common/subClient/epics'

type Props = {
  parentEntity: CustomerViewDTO
  entityType: EntityType.customer
  children?: any
}

export const SubClientsTabProvider = (props: Props) => {
  const { parentEntity, entityType, children } = props
  const { isModified, modifyListItems, deleteModifiedListItems } = useExpandedItem()

  const createSubClient = () => {
    const newBusinessPartner = generateBusinessPartner()
    const newSubClient = generateSubClient({ customerId: parentEntity.id, businessPartnerId: newBusinessPartner.id })

    modifyListItems({
      [entityType]: [{ ...parentEntity, subClientIds: [...parentEntity.subClientIds, newSubClient.id] }],
      [EntityType.subClient]: [newSubClient],
      [EntityType.businessPartner]: [newBusinessPartner]
    })
  }

  const updateSubClientField = (subClient: SubClientViewDTO) => (prop: keyof SubClientViewDTO) => (value: any) => {
    if (!(subClient && prop)) {
      return
    }

    modifyListItems({
      [EntityType.subClient]: [{ ...subClient, [prop]: value }]
    })
  }

  const deleteSubClient = (_props: { subClient: SubClientViewDTO; businessPartner: BusinessPartnerViewDTO }) => {
    const { subClient, businessPartner } = _props

    if (!subClient) {
      return
    }

    const updatedSubClientIds = parentEntity.subClientIds.filter((id: string) => id !== subClient.id)

    modifyListItems({ [entityType]: [{ ...parentEntity, subClientIds: updatedSubClientIds }] })
    deleteModifiedListItems({
      [EntityType.contact]: [subClient.id],
      [EntityType.businessPartner]: [subClient.businessPartnerId],
      ...(businessPartner.primaryContactId ? { [EntityType.contact]: [businessPartner.primaryContactId] } : {})
    })
  }

  React.useEffect(() => {
    requestSubClientIds(parentEntity.subClientIds)
  }, [])

  return (
    <SubClientsTabContext.Provider
      value={{
        isModifiedMode: isModified,
        createSubClient,
        updateSubClientField,
        deleteSubClient
      }}
      children={children}
    />
  )
}
