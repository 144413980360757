import * as yup from 'yup'
import { BusinessPartnerViewDTO } from '../../../api/api'
import { schemaBusinessPartner } from '../../yupScheme'

export const isBusinessPartnerValid = (
  businessPartner: BusinessPartnerViewDTO,
  isLegalAddressNullable?: boolean
): boolean => {
  if (isLegalAddressNullable === true) {
    return schemaBusinessPartner.shape({ legalAddress: yup.object().nullable(true) }).isValidSync(businessPartner)
  }

  return schemaBusinessPartner.isValidSync(businessPartner)
}
