import * as React from 'react'
import styled from 'styled-components'
import { LocationGroupMap } from '../common/locationGroup/views/Map/LocationGroupMap'
import { SideBar } from '../common/locationGroup/views/SideBar'
import { LocationGroupsProvider } from '../../providers/LocationGroupsProvider'

type OwnProps = {}
type Props = OwnProps

export const LocationGroupPage = React.memo((props: Props) => {
  return (
    <SideBar />
    // <LocationGroupProvider>
    //   <LocationGroupSideBar />
    //   <LocationGroupMap />
    // </LocationGroupProvider>
  )
})

const Container = styled.div``
