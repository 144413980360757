import * as React from 'react'
import theme from '../../../../../../styles/theme'
import { LocationViewDTO, callAPI, locationAPI } from '../../../../../../api/api'
import { combineLocations } from '../../../../../../services/DTO/location/functions'
import { SearchRequest } from './searchRequest'
import { TSearchField } from '../../index'
import { locationControllerApi } from '../../../../../../api/location'
import { generateLocation } from '../../../../../../services/functions/generate/generateLocation'
import { pushListItemsToStore } from '../../../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { locationTypeIcons } from '../../../../../../services/DTO/location'
import { isLocationGEOValid } from '../../../../../../services/functions/test/isLocationGEOValid'
import { useLocation } from '../../../../../../hooks/useLocation'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'

export const LocationSearchWithGoogle = (
  props: TSearchField & {
    id: string
    isShortName?: boolean
  }
) => {
  const { id, isShortName } = props
  const { modifiedLists } = useExpandedItem()
  const location = useLocation({ id, modifiedLists })
  const getOwnDatabaseList = (term: string): Promise<any[]> => callAPI(locationAPI.search, null, term + '').toPromise()
  const getOwnDatabaseDetails = (_location: any): Promise<any> =>
    callAPI(locationAPI.findById, _location.id).toPromise()
  const getList = getOwnDatabaseList
  const getDetails = (_location: any) => {
    if (_location.googleResult) {
      return locationControllerApi
        .locationByPlaceId(_location.placeId)
        .then(data => combineLocations(generateLocation(), data))
    } else if (_location.create) {
      return Promise.resolve(generateLocation())
    } else {
      return getOwnDatabaseDetails(_location)
    }
  }

  const findInGoogle = (term: string) => {
    if (term.length > 2) {
      return locationControllerApi
        .searchLocation(term)
        .then((items: any) => (items ? items.map((_: any) => ({ ..._, googleResult: true })) : items))
        .catch(() => [{ create: true, description: term }])
    } else {
      return Promise.resolve([])
    }
  }

  return (
    <SearchRequest
      {...props}
      minWidth={300}
      filtering={false}
      label={location ? (isShortName ? location.shortName : location.longName) || location.name : null}
      getList={getList}
      findInGoogle={findInGoogle}
      getDetails={getDetails}
      addToStore={(_location: LocationViewDTO) => {
        pushListItemsToStore({ update: { [EntityType.location]: [_location] } })
      }}
      makeDropDownItem={response =>
        response.googleResult || response.create
          ? {
              className: 'create' + (response.googleResult ? ' google' : ''),
              before: 'Create',
              label: response.description,
              value: response
            }
          : {
              componentBefore: response.type ? (
                <i
                  style={{
                    fontSize: 18,
                    marginRight: 5,
                    alignSelf: 'flex-start',
                    color: isLocationGEOValid(response) ? undefined : theme.colors.defaultRed
                  }}
                  className={'mdi ' + locationTypeIcons[response.type]}
                />
              ) : (
                undefined
              ),
              label: response.longName,
              value: response
            }
      }
    />
  )
}
