import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { FileCategory, IAttachment } from '../../../../services/filesService/interfaces'
import theme from '../../../../styles/theme'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'
import { dateService } from '../../../../services/timeService'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { AttachmentAction, AttachmentActionType } from './AttachmentAction'
import { openFileLink } from '../../../../services/filesService'
import ImageViewer from 'react-simple-image-viewer'
import { Portal } from 'react-portal'
import { activityDirectory } from '../../../../services/DTO/activity/directory'

type Props = {
  file: IAttachment
  remove?: () => void
  isDocumentTabView?: boolean
}

export const File = React.memo((props: Props) => {
  const { file, remove, isDocumentTabView } = props
  const [imageViewerState, setImageViewerState] = React.useState(false)

  const removeFile = React.useCallback(() => {
    showModalWindow({
      width: 320,
      title: 'Delete this file?',
      content: (
        <DeleteFileName>
          <span>{file.name}</span>
        </DeleteFileName>
      ),
      buttons: [
        {
          label: 'No'
        },
        {
          label: 'Yes',
          onClick: remove
        }
      ]
    })
  }, [remove, file.name])

  const showImageViewer = React.useCallback(() => setImageViewerState(true), [setImageViewerState])
  const hideImageViewer = React.useCallback(() => setImageViewerState(false), [setImageViewerState])

  const isUploading = typeof file.uploadingProgress === 'number'
  const isWorkOrder = file.category === FileCategory.WORKORDER
  const isActivityDocument = [FileCategory.POD, FileCategory.TIR].includes(file.category)

  const fileIconProps = React.useMemo(() => {
    let style: React.CSSProperties = undefined
    let className: string =
      CommunicationHubConstants.fileCategoryMapping[file.category || FileCategory.OTHER].iconClassName

    if (file.thumb) {
      style = {
        background: `url(${file.thumb}) center/cover`,
        ...(isDocumentTabView
          ? {
              cursor: 'zoom-in',
              zIndex: 2,
              position: 'relative'
            }
          : {})
      }
      className = undefined
    } else if (file.category === FileCategory.TROUBLETICKET) {
      style = { backgroundColor: theme.colors.defaultRed }
    }

    return { style, className }
  }, [file.category, file.thumb, isDocumentTabView])

  const RenderActionButton = React.useMemo(() => {
    switch (true) {
      case isUploading:
        return <AttachmentAction type={AttachmentActionType.uploading} uploadingProgress={file.uploadingProgress} />
      case Boolean(remove):
        return <AttachmentAction type={AttachmentActionType.remove} onClick={removeFile} />
      case Boolean(file.url): {
        return (
          <AttachmentAction
            type={isWorkOrder ? AttachmentActionType.openOnNewTab : AttachmentActionType.download}
            onClick={() => openFileLink(file.url, { predownload: !isWorkOrder, name: file.name })}
          />
        )
      }
      default:
        return null
    }
  }, [isUploading, removeFile, file])

  const fileName = React.useMemo(() => {
    if (isWorkOrder && !isDocumentTabView) {
      return 'Work Order'
    } else {
      return file.name
    }
  }, [file.name, isWorkOrder, isDocumentTabView])

  return (
    <>
      <Container className={cn({ 'document-tab-view': isDocumentTabView, 'activity-document': isActivityDocument })}>
        <FileIcon
          // @ts-ignore
          style={fileIconProps.style}
          className={fileIconProps.className}
          onClick={isDocumentTabView && file.thumb ? showImageViewer : undefined}
        />
        <FileDetailsContainer>
          <FileDetails>
            {isDocumentTabView && isActivityDocument ? (
              <>
                {oc(file).messageMetadata.activities[0].type() && (
                  <>
                    <ActivityDocumentLabel
                      style={{
                        fontWeight: 500,
                        color: activityDirectory.stageColor[oc(file).messageMetadata.activities[0].stage()]
                      }}
                    >
                      {activityDirectory.type[file.messageMetadata.activities[0].type]}
                    </ActivityDocumentLabel>
                    <br />
                  </>
                )}
                <ActivityDocumentLabel>{fileName}</ActivityDocumentLabel>
                <br />
                {file.senderName && <ActivityDocumentCreator>{file.senderName}</ActivityDocumentCreator>}
                {oc(file).messageMetadata.validation.validatedManually() && (
                  <>
                    <br />
                    <ActivityDocumentCreatedManualy style={{ color: theme.colors.orange }}>
                      Validated Manually
                    </ActivityDocumentCreatedManualy>
                  </>
                )}
              </>
            ) : (
              <>
                <FileName>{fileName}</FileName>
                <br />
                {file.senderName && <FileInfo>{file.senderName}</FileInfo>}
              </>
            )}
          </FileDetails>
        </FileDetailsContainer>
        {Boolean(file.massageCreatedAt) && <FileDate>{dateService.makeLabel(file.massageCreatedAt)}</FileDate>}
        {RenderActionButton}
      </Container>

      {isDocumentTabView && imageViewerState && (
        <Portal>
          <ImageViewer
            src={[file.url]}
            currentIndex={0}
            onClose={hideImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)'
            }}
            closeOnClickOutside={true}
          />
        </Portal>
      )}
    </>
  )
})

const FollowLinkButton = styled.a`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: 3px;
  font-size: 36px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
`
const RemoveFileButton = styled.div`
  z-index: 1;
  //display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background: white;
  transform: translate(50%, -50%);
  color: #e0e0e0;
  font-size: 22px;
  cursor: pointer;

  &:hover {
    color: #445366;
  }
`
const FileIcon = styled.div`
  flex-shrink: 0;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #6973f6;
  color: white;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 8px;
`
const Container = styled.div`
  position: relative;
  height: 70px;
  display: flex;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid rgba(198, 204, 227, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 8px;

  &.document-tab-view {
    width: 100%;
    height: 90px;
    border: none;
    box-shadow: unset;
    border-radius: unset;
    padding: 18px 14px;

    &:before {
      content: '';
      display: block;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 0;
      left: 14px;
      right: 14px;
    }

    &.activity-document {
      height: 110px;
    }
  }

  &:hover {
    ${RemoveFileButton} {
      display: flex;
    }

    ${FollowLinkButton} {
      transition: opacity 0.25s;
      opacity: 1;
    }
  }
`
const FileDetailsContainer = styled.div`
  position: relative;
  flex-grow: 1;
`

const FileDetails = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileName = styled.span`
  font-size: 14px;
  line-height: 27px;
`
const FileInfo = styled.span`
  font-size: 12px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.5);
`
const FileDate = styled.div`
  flex-shrink: 0;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  margin-left: auto;
`

const DeleteFileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ActivityDocumentLabel = styled(FileName)`
  line-height: 1.3;
`
const ActivityDocumentCreator = styled(FileInfo)`
  line-height: 1.2;
`
const ActivityDocumentCreatedManualy = styled(ActivityDocumentCreator)``
