import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'
import {
  showErrorModalWindow,
  showModalWindow,
  showWarningModalWindow
} from '../../../../../store/reducers/modalWindow/functions'
import { locationControllerApi } from '../../../../../api/location'
import { SelectLocation } from '../../../../../services/DTO/location/SelectLocation'
import { ContactDTO, LocationViewDTO } from '../../../../../api/api'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'
import { saveLocation } from '../../../../../services/saveEntity/saveLocation'
import { getListsState } from '../../../../../store'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../../../../services/functions/test/testEntityVersion'
import { tryToSave } from '../../../../../services/DTO/saveDTO'

type Props = {
  modifiedLocation: LocationViewDTO
  modifiedPrimaryContact: ContactDTO
  initialLocation: LocationViewDTO
  setFetching: (state: boolean) => void
  modifyLocation: (location: LocationViewDTO) => void
  onLocationSaveComplete?: (location: LocationViewDTO) => void
  cancelModifies?: () => void
  placeIdPassed?: boolean
}

export const saveWidgetLocation = async (props: Props) => {
  const {
    modifiedLocation,
    initialLocation,
    modifiedPrimaryContact,
    onLocationSaveComplete,
    modifyLocation,
    setFetching,
    cancelModifies,
    placeIdPassed
  } = props
  let location: LocationViewDTO = modifiedLocation
  const prevLocationVersion = initialLocation
  setFetching(true)

  if (!placeIdPassed) {
    let locationDataChanged = false
    const addressString = generateAddressLabel(location.address)
    const placeId = oc(location).placeId()
    const locationLatitude = oc(location).latitude()
    const locationLongitude = oc(location).longitude()

    if (prevLocationVersion) {
      const prevAddressString = generateAddressLabel(prevLocationVersion.address)
      locationDataChanged = prevAddressString !== addressString
    } else {
      locationDataChanged = true
    }

    if (locationDataChanged || !(placeId && locationLatitude && locationLongitude)) {
      const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

      if (searchResults.length) {
        // const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId
        const isPlaceIdCorrect = false // always show confirm popup

        if (!(isPlaceIdCorrect && locationLatitude && locationLongitude)) {
          setFetching(false)

          return showModalWindow({
            width: 445,
            title: 'Select location',
            content: (
              <SelectLocation
                selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
                locations={searchResults}
                onSelect={selectedPlaceId => {
                  if (selectedPlaceId) {
                    setFetching(true)

                    locationControllerApi
                      .locationByPlaceId(selectedPlaceId)
                      .then(requestedLocation => {
                        const { latitude, longitude } = requestedLocation
                        const requestedAddress = oc(requestedLocation).address()

                        location = { ...location, placeId: selectedPlaceId, latitude, longitude }

                        if (requestedAddress) {
                          if (requestedAddress.city) {
                            location.address = {
                              ...location.address,
                              street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                              city: requestedAddress.city || location.address.city,
                              postalCode: requestedAddress.postalCode || location.address.postalCode,
                              stateId:
                                requestedAddress.stateCode && requestedAddress.countryCode
                                  ? getAddressState({
                                      codes: {
                                        stateCode: requestedAddress.stateCode,
                                        countryCode: requestedAddress.countryCode
                                      }
                                    }).id
                                  : location.address.stateId
                            }
                          }
                        }

                        modifyLocation(location)
                        saveWidgetLocation({ ...props, modifiedLocation: location, placeIdPassed: true })
                      })
                      .catch(() => {
                        setFetching(false)

                        showErrorModalWindow({
                          title: 'Location search error'
                        })
                      })
                  }
                }}
              />
            ),
            buttons: [
              {
                label: 'Cancel'
              },
              {
                label: 'Apply',
                disabled: true
              }
            ]
          })
        }
      } else {
        setFetching(false)
        return showErrorModalWindow({
          content: 'Location with the entered address is not found in Google'
        })
      }
    }
  }

  const updatedContacts = [modifiedPrimaryContact].filter(Boolean)
  const store = getListsState()
  const condition =
    testEntityVersion(store, EntityType.location)(modifiedLocation) &&
    updatedContacts.every(testEntityVersion(store, EntityType.contact))

  const save = () => {
    return saveLocation({ location, contacts: updatedContacts }).then(savedLocation => {
      if (onLocationSaveComplete) {
        onLocationSaveComplete(savedLocation)
      }
    })
  }

  tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: cancelModifies
  })
}
