import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { preventEvent } from '../../services/functions/basic'
import theme from '../../styles/theme'
import { resolveTroubleTicketId, TroubleTicketResolveAction } from '../common/activity/epics'

export const troubleTicketActionsId = 'trouble-ticket-actions'

export const TroubleTicketActions = () => {
  return (
    <>
      <ReactTooltip
        id={troubleTicketActionsId}
        className={'react-tooltip-custom-inner'}
        place={'bottom'}
        event={'click'}
        globalEventOff={'click'}
        clickable={true}
        getContent={(troubleTicketId: string) => {
          if (!troubleTicketId) {
            return null
          }

          const handleClick = (action: TroubleTicketResolveAction) => {
            ReactTooltip.hide()
            const element = document.getElementById(troubleTicketActionsId)

            if (element) {
              element.classList.remove('show')
            }

            resolveTroubleTicketId({ troubleTicketId, action })
          }

          return (
            <Dropdown onClick={preventEvent}>
              <DropdownTitle>Select a decision</DropdownTitle>
              <DropdownOption onClick={() => handleClick(TroubleTicketResolveAction.proceed)}>
                <span className={'mdi mdi-play-circle'} style={{ color: '#6973F6' }} />
                Proceed
              </DropdownOption>
              <DropdownOption onClick={() => handleClick(TroubleTicketResolveAction.unsuccessful)}>
                <span className={'mdi mdi-close-circle'} style={{ color: theme.colors.defaultRed }} />
                Unsuccessful
              </DropdownOption>
            </Dropdown>
          )
        }}
      />
    </>
  )
}

const Dropdown = styled.div`
  width: 200px;
  background: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 0;
`
const DropdownTitle = styled.div`
  font-size: 10px;
  color: #bdbdbd;
  padding: 0 10px;
  margin-bottom: 5px;
`
const DropdownOption = styled.div`
  box-sizing: content-box;
  height: 25px;
  display: flex;
  align-items: center;
  color: #484848;
  padding: 4px 6px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .mdi {
    font-size: 24px;
    margin-right: 6px;
  }
`

export const TroubleTicketActionsStyledComponents = {
  Dropdown,
  DropdownTitle,
  DropdownOption
}
