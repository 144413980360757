import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import theme from '../../../../../../styles/theme'
import { leaveChatChannelIfNoEditing } from '../../../../../CommunicationHub/functions/leaveChatChannelIfNoEditing'
import { ICHubChannel } from '../../../../../../store/reducers/communicationHub/interfaces'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectCommunictaionHubIsActiveChannelId } from '../../../../../../store/select/communicationHubSelect'
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../../../store/reducers/communicationHub'

type OwnProps = {
  channel: ICHubChannel
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const EntityChat = React.memo((props: Props) => {
  const { channel } = props
  const dispatch = useAppDispatch()
  const active = useAppSelector(selectCommunictaionHubIsActiveChannelId(channel.id))

  const openDDOChannel = React.useCallback(() => {
    leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.pushActiveChannel({ channel })))
  }, [])

  return (
    <Container className={cn({ active })} onClick={active ? undefined : openDDOChannel}>
      <IconButton className={'mdi mdi-forum'} />
    </Container>
  )
})

const Container = styled.div`
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: ${theme.colors.basicGreyColor};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.basicBlueColor};
  }

  &.active {
    background-color: ${theme.colors.basicBlueColor};
    cursor: default;
  }
`
const IconButton = styled.div`
  color: white;
  font-size: 24px;
`
