import * as React from 'react'
import { Select } from '../../../../UI/DataFields/Select'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { TDropDownItem } from '../../../../UI/DataFields/SearchByList'
import { UserDTO, UserType } from '../../../../../api/origin/user-service'
import { sortByString } from '../../../../../services/functions/sort/sortByString'
import { oc } from 'ts-optchain'
import { selectUsers } from '../../../../../store/select/userSelect'
import { ColumnDTO } from '../../../../../store/reducers/tabs/interfaces'

type Props = {
  placeholder: string
  filterItem: ColumnDTO.Filter
  onChange: (values: string[]) => void
}

export const SalesRepresentativeHeaderColumn = (props: Props) => {
  const { placeholder, filterItem, onChange } = props
  const filterItemValue = filterItem && (filterItem.value as string[])
  const selectedValues: string[] = filterItemValue
  const users: Record<string, UserDTO> = useAppSelector(selectUsers)
  const list: TDropDownItem[] = React.useMemo(
    () =>
      sortByString(Object.values(users).filter(item => item && item.type === UserType.SALESREP), (item: UserDTO) =>
        oc(item).name()
      ).map(item => ({
        label: item.name,
        value: item.id
      })),
    [users]
  )

  return (
    <Select
      placeholder={placeholder}
      search={true}
      list={list}
      selectedValue={selectedValues}
      multiselect={true}
      onSelect={onChange}
      buttonClear={true}
      style={{ boxShadow: 'unset' }}
    />
  )
}
