import { DeductionDTO } from '../../../api/origin/vendor-accounting-service'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  deductionIds: string[]
  modifiedLists: Partial<TListsState>
}

export const filterModifiedDeductions = (props: Props): DeductionDTO[] => {
  const { deductionIds, modifiedLists } = props
  let updatedDeductions: DeductionDTO[] = []

  if (modifiedLists[EntityType.deduction] && deductionIds) {
    updatedDeductions = deductionIds.map(id => modifiedLists[EntityType.deduction][id]).filter(Boolean)
  }

  return updatedDeductions
}
