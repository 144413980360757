import { oc } from 'ts-optchain'
import { SteamShipLineViewDTO } from '../../../api/api'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isSteamShipLineValid } from '../../functions/test/isSteamShipLineValid'
import { isBusinessPartnerValid } from '../../functions/test/isBusinessPartnerValid'
import { isContactValid } from '../../functions/test/isContactValid'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'

export const checkSteamShipLineValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { modifiedLists, parentItem } = expandedItemContext
  const steamShipLine = parentItem as SteamShipLineViewDTO
  const businessPartnerId = steamShipLine.businessPartnerId
  const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][businessPartnerId]()
  const businessPartner = modifiedBusinessPartner || getListsState()[EntityType.businessPartner][businessPartnerId]
  const updatedContactMapping = oc(modifiedLists)[EntityType.contact]({})
  const updatedContacts = businessPartner.contactIds.map(id => updatedContactMapping[id]).filter(Boolean)

  return (
    isSteamShipLineValid(steamShipLine) &&
    (!modifiedBusinessPartner || isBusinessPartnerValid(modifiedBusinessPartner, true)) &&
    updatedContacts.every(isContactValid)
  )
}
