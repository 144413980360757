import * as React from 'react'
import cn from 'classnames'
import styled from '../../../../styles/styledComponents'
import theme from '../../../../styles/theme'
import { CollectionDropdown } from './Dropdown'
import { TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { tabDirectory } from '../../../../store/reducers/tabs/directory'

type OwnProps = {
  tabType: TabDTO.Type
  closeMenu: () => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Collection = (props: Props) => {
  const { tabType, closeMenu } = props
  const [open, setOpen] = React.useState(false)
  const onMouseEnter = React.useCallback(() => setOpen(true), [])
  const onMouseLeave = React.useCallback(() => setOpen(false), [])
  const hideDropdownAction = React.useCallback(() => {
    onMouseLeave()
    closeMenu()
  }, [closeMenu])

  return (
    <StyledCollection onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Title className={cn('menu-title', { active: open })}>{tabDirectory.tabLabel[tabType]}</Title>
      {open && <CollectionDropdown tabType={tabType} hideDropdownAction={hideDropdownAction} />}
    </StyledCollection>
  )
}

const StyledCollection = styled.div`
  position: relative;
  font-size: 12px;
  cursor: default;
`
const Title = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 12px 2px 12px;
  position: relative;
  color: white;
  margin-top: 13px;
  user-select: none;

  &:after {
    content: '';
    display: none;
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 5px;
    background-color: #fff;
  }

  &.active {
    border-radius: 6px 6px 0 0;
    background-color: ${theme.colors.basicBlueColor};
  }
`
