import * as React from 'react'
import { connect } from 'react-redux'
import { Tab } from './Tab'
import ContextMenu from '../../UI/ContextMenu'
import { StyledTabs, TabsList, WrapperSortTabs } from './styledComponents'
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd } from 'react-sortable-hoc'
import { showModal, TMsgType } from '../../UI/Modal/actions'
import { tabSessionStorage } from '../../../services/tabs/functions'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectApplicationTabs } from '../../../store/select/applicationTabSelect'
import { generateTab } from '../../../services/functions/generate/generateTab'
import { ITab, TabDTO } from '../../../store/reducers/tabs/interfaces'
import {
  deleteAllTabsExcept,
  deleteRightTabs,
  deleteTabById,
  duplicateTab,
  moveTab,
  setActiveTabId,
  setInitialTabsState
} from '../../../store/reducers/tabs/functions'
import { CreateTab } from './CreateTab'

type StateProps = {}

type DispatchProps = {
  showModal: typeof showModal
}

type Props = StateProps & DispatchProps

export const Tabs = connect(
  null,
  {
    showModal
  }
)((props: Props) => {
  const tabs = useAppSelector(selectApplicationTabs)
  const [contextMenu, setContaxtMenu] = React.useState<{
    tab: ITab
    position: { top: number; left: number }
  }>(null)

  React.useEffect(() => {
    const localStoreTabs = tabSessionStorage.tabs.get()
    const tabScrollPosition = tabSessionStorage.scrollPosition.get()

    if (!tabScrollPosition) {
      // Session Storage: create tab's scroll position
      tabSessionStorage.scrollPosition.set({})
    }

    // put Settings from Session Storage to Redux Store
    setInitialTabsState(localStoreTabs || [generateTab(TabDTO.Type.dispatchDeliveryOrder, { active: true })])
    // setInitialTabsState(localStoreTabs || [generateTab(TabDTO.Type.dispatchDeliveryOrder, { active: true })])
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const currentTab = tabs[oldIndex]
    const insertBeforeTab = tabs[newIndex]
    moveTab({ currentTab, insertBeforeTab })
  }

  const tabActions = {
    delete: (tab: ITab) => () => {
      if (tabs.length === 1) {
        props.showModal({
          msgType: TMsgType.info,
          message: "Let's not remove this one for now",
          onConfirm: () => {},
          onCancel: () => {}
        })
        return
      }

      if (tab.type === TabDTO.Type.deliveryOrder && tab.expandedItem.id) {
        props.showModal({
          msgType: TMsgType.delete,
          message: 'Are you sure you want to close the "New Delivery Order" tab? All changes will be lost',
          onCancel: () => {},
          onConfirm: () => deleteTabById(tab.id)
        })
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message:
          'Do you really want to remove tab "' + tab.label.substr(0, 25) + (tab.label.length > 25 ? '...' : '') + '"?',
        onConfirm: () => deleteTabById(tab.id),
        onCancel: () => {}
      })
    },
    removeOtherTabs: (tab: ITab) => () => {
      if (tabs.length === 1) {
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message:
          'Do you really want to remove other tabs besides "' +
          tab.label.substr(0, 25) +
          (tab.label.length > 25 ? '...' : '') +
          '"?',
        onConfirm: () => deleteAllTabsExcept(tab.id),
        onCancel: () => {}
      })
    },
    removeRightTabs: (tab: ITab) => () => {
      if (tabs.length === 1) {
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message: 'Do you really want to close all tabs to the right?',
        onConfirm: () => deleteRightTabs(tab.id),
        onCancel: () => {}
      })
    },
    duplicate: (tab: ITab) => () => duplicateTab(tab)
  }

  const SortableItem = SortableElement(({ children }: any) => children)

  const SortableList: React.ComponentClass<{ items: ITab[] } & SortableContainerProps> = SortableContainer(
    ({ items }: { items: ITab[] } & SortableContainerProps) => (
      <WrapperSortTabs className={'tabs'}>
        {items.map((tab, index) => {
          const isLast = index === items.length - 1
          const isSingle = items.length === 1

          return (
            // @ts-ignore
            <SortableItem key={index} index={index}>
              <div style={{ flexShrink: 1, width: 200 }}>
                <Tab
                  key={tab.id}
                  tab={tab}
                  deleteTab={tabActions.delete(tab)}
                  onTabSelect={() => setActiveTabId(tab.id)}
                  setContaxtMenu={setContaxtMenu}
                />
                {Boolean(contextMenu) && contextMenu.tab.id === tab.id && (
                  <ContextMenu
                    position={contextMenu && contextMenu.position}
                    closeContextMenu={() => setContaxtMenu(null)}
                    actions={(() => {
                      const actions = {
                        duplicate: { title: 'Duplicate', callToAction: tabActions.duplicate(tab), separator: true },
                        closeTab: isSingle
                          ? undefined
                          : { title: 'Close Tab', callToAction: tabActions.delete(tab), separator: false },
                        closeOtherTabs: isSingle
                          ? undefined
                          : {
                              title: 'Close Other Tabs',
                              callToAction: tabActions.removeOtherTabs(tab),
                              separator: false
                            },
                        closeTabsToTheRight:
                          isSingle || isLast
                            ? undefined
                            : {
                                title: 'Close Tabs to the Right',
                                callToAction: tabActions.removeRightTabs(tab),
                                separator: false
                              }
                      }
                      const tabContextActions = []

                      switch (tab.type) {
                        case TabDTO.Type.deliveryOrder:
                          tabContextActions.push(actions.closeTab, actions.closeOtherTabs, actions.closeTabsToTheRight)
                          break
                        default:
                          tabContextActions.push(
                            actions.duplicate,
                            actions.closeTab,
                            actions.closeOtherTabs,
                            actions.closeTabsToTheRight
                          )
                      }

                      return tabContextActions.filter(Boolean)
                    })()}
                  />
                )}
              </div>
            </SortableItem>
          )
        })}
      </WrapperSortTabs>
    )
  )

  return (
    <StyledTabs>
      <TabsList>
        <CreateTab />

        <SortableList
          items={tabs}
          onSortEnd={onSortEnd}
          axis={'x'}
          lockAxis={'x'}
          lockToContainerEdges={true}
          distance={10}
          helperClass={'draggable-tab'}
        />
      </TabsList>
    </StyledTabs>
  )
})
