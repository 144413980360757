import * as R from 'remeda'
import { EntityType } from '../../interfaces'
import { IDriverActivityDTO } from '../../../../../services/DTO/driverActivity/interfaces'

interface IParsedProps {
  [EntityType.driverActivity]: IDriverActivityDTO[]
}

export const parseDriversActivity = async (driversActivity: IDriverActivityDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.driverActivity]: []
  }

  try {
    driversActivity.forEach(driverActivity => {
      result[EntityType.driverActivity].push(driverActivity)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
