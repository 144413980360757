import { ChannelType, IChannels, IChannelsMeta } from '../interfaces'
import { adjustChannelsNumber } from './adjustChannelsNumber'
import { sortChannelIds } from './sortChannelIds'

export const sortChannels = (channelsMeta: IChannelsMeta, channels: IChannels): IChannels => {
  return adjustChannelsNumber({
    ...channels,
    idListByType: {
      ...channels.idListByType,
      [ChannelType.operator]: sortChannelIds(channelsMeta, channels.idListByType[ChannelType.operator]),
      [ChannelType.dispatchDeliveryOrder]: sortChannelIds(
        channelsMeta,
        channels.idListByType[ChannelType.dispatchDeliveryOrder]
      ),
      [ChannelType.driver]: sortChannelIds(channelsMeta, channels.idListByType[ChannelType.driver])
    }
  })
}
