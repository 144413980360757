import { OngoingActivityGroupDTO } from '../../../../api/api'
import { dateService } from '../../../../services/timeService'
import { ISchedulerTabFilterMapping } from '../interfaces'

export const filterOngoingActivityGroups = (
  ongoingAvtivityGroups: OngoingActivityGroupDTO[],
  filters: ISchedulerTabFilterMapping
): OngoingActivityGroupDTO[] => {
  if (!(ongoingAvtivityGroups && ongoingAvtivityGroups.length)) {
    return ongoingAvtivityGroups
  }

  const { dispatchDeliveryOrderId, activityGroupDate } = filters

  if (dispatchDeliveryOrderId || activityGroupDate) {
    const startToday = dateService.createStringDate.startDay

    return ongoingAvtivityGroups.filter(item => {
      if (dispatchDeliveryOrderId) {
        if (item.dispatchDeliveryOrderId !== dispatchDeliveryOrderId) {
          return false
        }
      }
      if (activityGroupDate) {
        if (
          item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGENOW ||
          (item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE && item.activityGroupDate < startToday)
        ) {
          return true
        }

        if (
          (activityGroupDate.from && activityGroupDate.from > item.activityGroupDate) ||
          (activityGroupDate.to && activityGroupDate.to < item.activityGroupDate)
        ) {
          return false
        }
      }

      return true
    })
  } else {
    return ongoingAvtivityGroups
  }
}
