import * as React from 'react'
import { Container } from './styles'
import { DownloadWorkOrder } from './DownloadWorkOrder'
import { UncancelDDO } from './UncancelDDO'
import { StreetTurn } from './StreetTurn'
import { EntityChat } from './EntityChat'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
// tslint:disable:max-line-length
import { generateDispatchDeliveryOrderChatChannel } from '../../../../../../services/functions/generate/generateDispatchDeliveryOrderChatChannel'
import { AcceptRejectButtons } from './AcceptRejectButtons'
// import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
// import { oc } from 'ts-optchain'

export type Props = {}

export const CustomHeader = (props: Props) => {
  const { parentItem, parentItemId } = useExpandedItem()

  const channel = React.useMemo(() => {
    return generateDispatchDeliveryOrderChatChannel(parentItem)
  }, [parentItemId])

  return (
    <>
      <StreetTurn {...props} />
      <AcceptRejectButtons />
      <UncancelDDO />

      <Container>
        <DownloadWorkOrder />
        <EntityChat channel={channel} />
      </Container>
    </>
  )
}
