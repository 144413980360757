import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { SellSideQuoteSurchargeDTO } from '../../../../../api/origin/business-logic'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectRates } from '../../../../../store/select/rateSelect'
import { generateCombinedSurchargeRate } from '../../../../../services/functions/generate/generateCombinedSurchargeRate'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import { mainRateColumns, SsqBsqWidgetMainRate } from './SsqBsqWidgetMainRate'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import { SsqBsqWidgetRateColumnType } from './SsqBsqWidgetRateColumn'
import { SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'

type Props = {
  surcharges: SurchargeDTO[]
}

export const SsqBsqWidgetMainRates = React.memo((props: Props) => {
  const { surcharges } = props
  const rateMapping = useAppSelector(selectRates)
  const combinedSurchargeRateList: CombinedSurchargeRateDTO[] = React.useMemo(() => {
    const mainRateTypes = [
      SellSideQuoteRateDTO.TypeEnum.BASE,
      SellSideQuoteRateDTO.TypeEnum.FUEL,
      SellSideQuoteRateDTO.TypeEnum.ALLIN
    ]

    return (surcharges || [])
      .filter(surcharge => mainRateTypes.includes(surcharge.type))
      .map(surcharge => generateCombinedSurchargeRate({ rate: rateMapping[surcharge.rateId], surcharge }))
      .sort((a, b) => {
        const typeValue = {
          [SellSideQuoteSurchargeDTO.TypeEnum.FUEL]: 1,
          [SellSideQuoteSurchargeDTO.TypeEnum.ALLIN]: 2,
          [SellSideQuoteSurchargeDTO.TypeEnum.BASE]: 3
        }
        // @ts-ignore
        const typeA = typeValue[a.type] || 0
        // @ts-ignore
        const typeB = typeValue[b.type] || 0

        return typeB - typeA
      })
  }, [rateMapping, surcharges])
  const baseRate = combinedSurchargeRateList.find(
    surcharge => surcharge.type === SellSideQuoteSurchargeDTO.TypeEnum.BASE
  )
  const fuelRate = combinedSurchargeRateList.find(
    surcharge => surcharge.type === SellSideQuoteSurchargeDTO.TypeEnum.FUEL
  )
  let basePlusFuelAmount: string = undefined

  if (baseRate && fuelRate) {
    basePlusFuelAmount = transformMoney(
      oc(baseRate).amount(0) + oc(baseRate).amount(0) * (oc(fuelRate).amount(0) / 100)
    )
  }

  return combinedSurchargeRateList.length ? (
    <Container>
      <Table>
        {mainRateColumns.map(title => (
          <TableTitle
            style={{
              justifyContent:
                title === SsqBsqWidgetRateColumnType.Amount
                  ? 'flex-end'
                  : title === SsqBsqWidgetRateColumnType.ID
                  ? 'center'
                  : undefined
            }}
            key={title}
          >
            {title}
          </TableTitle>
        ))}
        {combinedSurchargeRateList.map(surcharge => (
          <SsqBsqWidgetMainRate key={surcharge.id} rate={surcharge} baseRate={baseRate} disable={false} />
        ))}
      </Table>
      {basePlusFuelAmount !== undefined && (
        <BasePlusFuelAmount>
          Base + Fuel:<span>{basePlusFuelAmount}</span>
        </BasePlusFuelAmount>
      )}
    </Container>
  ) : null
})

const Container = styled.div`
  flex-shrink: 0;
  background-color: #fff;
  overflow-y: scroll;
  margin-bottom: 8px;
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 90fr 105fr 45fr 275fr 100fr 90fr 90fr 100fr;
`
const TableTitle = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  color: rgb(108, 112, 125);
  border-bottom: 1px solid rgb(229, 229, 229);
  font-size: 10px;
  background-color: #fff;
  padding: 4px 8px;

  &:not(:first-child)::before {
    content: '';
    width: 1px;
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
    bottom: 4px;
    background-color: rgb(229, 229, 229);
  }
`
const BasePlusFuelAmount = styled.div`
  text-align: right;
  padding: 10px 10px 20px;

  span {
    font-weight: 500;
    margin-left: 8px;
  }
`
