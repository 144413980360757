import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { DispatchDeliveryOrderViewDTO } from '../../../api/origin/business-logic'
import { ddoDirectory } from '../../../services/DTO/dispatchDeliveryOrder/directory'

export enum IconMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  GRAY = 'GRAY'
}

export enum IconName {
  ARROW_RIGHT = 'mdi-menu-right',
  ARROW_BOTTOM = 'mdi-menu-down',
  CLOSE = 'mdi-window-close',
  CLOSE_DETAILS = 'mdi-keyboard-tab',
  DOTS = 'mdi-dots-vertical',
  PLUS = 'mdi-plus-circle-outline',
  PIN = 'mdi-pin',
  DOCUMENT = 'mdi-file-document',
  LATTICE = 'mdi-pound',
  SEARCH = 'mdi-magnify',
  PHONE = 'mdi-cellphone-iphone',
  PENCIL = 'mdi-pencil',
  CLEAR = 'mdi-close-circle',
  FILTER = 'mdi-filter-variant',
  BELL_OFF = 'mdi-bell-off'
}

type Props = {
  icon: IconName
  mode?: IconMode
  active?: boolean
  onClick?: (event?: any) => void
  fontSize?: 14 | 16 | 18 | 20 | 24
  style?: React.CSSProperties
  options?: any
}

export const Icon = React.memo((props: Props) => {
  const { icon, active, fontSize, onClick, style, options } = props
  const mode = props.mode || IconMode.DARK

  return (
    <Container
      // @ts-ignore
      style={style}
      className={cn('interactive-icon mdi', {
        [icon]: icon,
        [mode]: mode,
        active: active,
        ['fontSize-' + fontSize]: Boolean(fontSize),
        interactive: Boolean(onClick)
      })}
      onClick={onClick}
      {...options || {}}
    />
  )
})

export const DispatchDeliveryOrderColorStatusIcon = React.memo(
  ({ status }: { status: DispatchDeliveryOrderViewDTO.StatusEnum }) => {
    return <DDOColorStatusIcon style={{ backgroundColor: ddoDirectory.statusColor[status] }} />
  }
)

const DDOColorStatusIcon = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
`

const Container = styled.i`
  font-size: 18px;
  padding: 3px;

  &.fontSize-14 {
    font-size: 14px;
  }
  &.fontSize-16 {
    font-size: 16px;
  }
  &.fontSize-20 {
    font-size: 20px;
  }
  &.fontSize-24 {
    font-size: 24px;
  }

  &.interactive {
    opacity: 0.5;
    cursor: pointer;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  &.${IconMode.DARK} {
    color: #253447;
  }

  &.${IconMode.LIGHT} {
    color: #e5e7ef;
  }
`
