import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'
import * as React from 'react'
import { convertISODateToDateWithHoursMins } from '../../../services/timeService/dateUtils'

type Props = {
  updatedAt: string
  updatedBy: string
  createdAt?: string
  createdBy?: string
}

const UpdateInfo = ({ updatedAt, updatedBy, createdAt, createdBy }: Props) => (
  <Container className={'updatedInfo'}>
    {Boolean(createdAt) && (
      <div className={'created-info'}>
        <span>Created:</span>
        {updatedAt ? `${convertISODateToDateWithHoursMins(createdAt)} by ${createdBy}` : ''}
      </div>
    )}
    <div className={'updated-info'}>
      <span>Last update:</span>
      {updatedAt ? `${convertISODateToDateWithHoursMins(updatedAt)} by ${updatedBy}` : ''}
    </div>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  align-self: stretch;
  color: ${theme.colors.darkGreyColor};

  .created-info {
    margin-bottom: 5px;
    width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .updated-info {
    width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    display: inline-block;
    width: 60px;
    font-size: 10px;
    color: ${theme.colors.basicBlueColor};
    margin-right: 5px;
  }
`

export default UpdateInfo
