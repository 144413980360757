import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { SellSideQuoteDTO } from '../api/api'
import { useListItem } from './useListItem'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useSellSideQuote = (props: Props): SellSideQuoteDTO => {
  const { id, modifiedLists } = props
  const sellSideQuote = useListItem({ id, type: EntityType.sellSideQuote, modifiedLists })

  return sellSideQuote
}
