import * as React from 'react'
import { StyledFieldWarning, Message, Icon } from './styles'

type TFieldWarning = {
  message?: string
  children?: any
  mdi?: string
  color?: string
  zIndex?: number
  width?: number | string
  style?: React.CSSProperties
}

export const FieldWarning = ({
  message = '',
  children,
  mdi = 'mdi-alert-circle',
  color,
  zIndex,
  width,
  style
}: TFieldWarning) => {
  return (
    <StyledFieldWarning className={'hint'} style={style}>
      <Icon style={{ color, zIndex }} className={`mdi ${mdi}`} />
      <Message style={{ width }}>{children || message}</Message>
    </StyledFieldWarning>
  )
}
