import { callAPI } from '../../../api/api'
import moveServiceAPI, { handleMoveServiceError } from '../../../components/CommunicationHub/epics/moveServiceAxios'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'
import { correctDriverPosition } from '../../functions/correctDriverPosition'
import { IDriverPositionDTO } from './interfaces'

export const getDriverPositionByActivityIds = async (activityIds: string[]): Promise<IDriverPositionDTO[]> => {
  return callAPI(() => moveServiceAPI.post(`/position/search`, { activityIds }))
    .toPromise()
    .then(async data => {
      if (data && data.data && Array.isArray(data.data)) {
        const requestedDriversPositions = data.data.map(correctDriverPosition)

        if (requestedDriversPositions.length) {
          await pushListItemsToStore({
            update: { [EntityType.driverPosition]: requestedDriversPositions }
          })
        }

        return requestedDriversPositions
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError([]))
}

export const getDriverPosition = async (authUserId: string): Promise<IDriverPositionDTO[]> => {
  return callAPI(() => moveServiceAPI.get(`/position/vendor/` + authUserId))
    .toPromise()
    .then(async data => {
      if (data && data.data) {
        const driverPosition = data.data
        const requestedDriverPosition = correctDriverPosition(driverPosition)

        if (requestedDriverPosition) {
          await pushListItemsToStore({
            update: { [EntityType.driverPosition]: [requestedDriverPosition] }
          })
        }

        return requestedDriverPosition
      } else {
        return null
      }
    })
    .catch(e => {
      showErrorModalWindow({ content: "ETA and Miles can't be calculated for the driver" })

      return undefined
    })
}
