import * as React from 'react'
import { mapActivities } from '../../../../functions'
import { OngoingActivityGroupDTO, TransportationActivityViewDTO } from '../../../../../../../api/origin/business-logic'
import { PreviewPhase, Column, Phase } from './PreviewPhase'
import { PreviewSpecs, Specs } from './PreviewSpecs'
import styled from 'styled-components'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { CommunicationHubConstants } from '../../../../../../../constants/CommunicationHubConstants'

type Props = {
  isFavorite: boolean
  activityGroup: OngoingActivityGroupDTO
  isMissed?: boolean
  isNowSection?: boolean
  onClick?: (event: any) => void
}

export const Preview = React.memo((props: Props) => {
  const { isFavorite, isMissed, isNowSection, activityGroup, onClick } = props
  const { phases, highlight } = React.useMemo(() => mapActivities(activityGroup), [activityGroup])

  return (
    <PreviewContainer className={'PreviewContainer-PreviewContainer'} onClick={onClick}>
      {[
        TransportationActivityViewDTO.StageEnum.PICKUP,
        TransportationActivityViewDTO.StageEnum.DELIVERY,
        TransportationActivityViewDTO.StageEnum.RETURN
      ].map(stage => (
        <PreviewPhase
          key={stage}
          activityGroupId={activityGroup.id}
          stage={stage}
          dispatchDeliveryOrderNumber={activityGroup.dispatchDeliveryOrderNumber}
          highlight={highlight}
          activityGroupDate={activityGroup.activityGroupDate}
          activityGroupStage={activityGroup.activityGroupStage}
          isMissed={isMissed}
          isNowSection={isNowSection}
          isFavorite={isFavorite}
          phase={phases[stage]}
        />
      ))}
      <PreviewSpecs activityGroup={activityGroup} isFavorite={isFavorite} />
    </PreviewContainer>
  )
})

const {
  mediaQueriesBreakpoints: { large, medium, small }
} = SchedulerConstants
const { navigationViewWidth, channelViewWidth } = CommunicationHubConstants

const PreviewContainer = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: repeat(3, calc(33.3% - ${SchedulerConstants.specsWidth}px/3)) ${
  SchedulerConstants.specsWidth
}px;
  cursor: pointer;

  .communicationHubNotExpanded & {
    @media screen 
    and (min-width: ${medium + navigationViewWidth}px) 
    and (max-width: ${large + navigationViewWidth}px) {
      grid-template-columns: repeat(3, 33.3%);

      ${Specs} {
        grid-column: 1/4;
      }
    }

    @media screen 
    and (min-width: ${small + navigationViewWidth}px) 
    and (max-width: ${medium + navigationViewWidth}px) {
      grid-template-areas: 
      '${TransportationActivityViewDTO.StageEnum.PICKUP}phase specs'
      '${TransportationActivityViewDTO.StageEnum.DELIVERY}phase specs'
      '${TransportationActivityViewDTO.StageEnum.RETURN}phase specs';
      grid-template-columns: 3fr 2fr;

      ${Phase} {
        &.${TransportationActivityViewDTO.StageEnum.PICKUP} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.PICKUP}phase;
        }
        &.${TransportationActivityViewDTO.StageEnum.DELIVERY} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.DELIVERY}phase;
        }
        &.${TransportationActivityViewDTO.StageEnum.RETURN} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.RETURN}phase;
        }
      }
      ${Specs} {
        grid-area: specs;
        grid-gap: ${SchedulerConstants.innerGap}px;
        grid-template-columns: 25% 25% 25% 25%;
        
        &:after {
          content: '';
          display: block;
        }

        ${Column} {
          border: none;
        }
      }
    }

    @media screen 
    and (max-width: ${small + navigationViewWidth}px) {
      grid-template-columns: 1fr;
    }
  }

  .communicationHubExpanded & {
    @media screen 
    and (min-width: ${medium + navigationViewWidth + channelViewWidth}px) 
    and (max-width: ${large + navigationViewWidth + channelViewWidth}px) {
      grid-template-columns: repeat(3, 33.3%);

      ${Specs} {
        grid-column: 1/4;
      }
    }

    @media screen 
    and (min-width: ${small + navigationViewWidth + channelViewWidth}px) 
    and (max-width: ${medium + navigationViewWidth + channelViewWidth}px) {
      grid-template-areas: 
      '${TransportationActivityViewDTO.StageEnum.PICKUP}phase specs'
      '${TransportationActivityViewDTO.StageEnum.DELIVERY}phase specs'
      '${TransportationActivityViewDTO.StageEnum.RETURN}phase specs';
      grid-template-columns: 3fr 2fr;

      ${Phase} {
        &.${TransportationActivityViewDTO.StageEnum.PICKUP} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.PICKUP}phase;
        }
        &.${TransportationActivityViewDTO.StageEnum.DELIVERY} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.DELIVERY}phase;
        }
        &.${TransportationActivityViewDTO.StageEnum.RETURN} {
          grid-area: ${TransportationActivityViewDTO.StageEnum.RETURN}phase;
        }
      }
      ${Specs} {
        grid-area: specs;
        grid-gap: ${SchedulerConstants.innerGap}px;
        grid-template-columns: 25% 25% 25% 25%;
        
        &:after {
          content: '';
          display: block;
        }

        ${Column} {
          border: none;
        }
      }
    }

    @media screen 
    and (max-width: ${small + navigationViewWidth + channelViewWidth}px) {
      grid-template-columns: 1fr;
    }
  }
  
  .details & {
    grid-template-columns: 1fr 1fr 1fr;

    ${Specs} {
      grid-column: 1/4;
    }
  }
`
