import { StateDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.state]: StateDTO[]
}

export const parseStates = async (states: StateDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.state]: []
  }

  try {
    states.forEach(state => {
      result[EntityType.state].push(state)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
