import * as React from 'react'
import { dateService } from '../services/timeService'
import { IMoveDTO, MoveStatus } from '../store/reducers/communicationHub/interfaces'

type Props = {
  move: IMoveDTO
  activeTabDate: string
}

export const useRouteBuilderNextToDayMove = (
  props: Props
): {
  isNextDayMove: boolean
  isPrevDayMove: boolean
  showNextToDayMoveDetails: boolean
  setShowNextToDayMoveDetails: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const { move, activeTabDate } = props
  const [showNextToDayMoveDetails, setShowNextToDayMoveDetails] = React.useState<boolean>(false)

  if (!(move && activeTabDate)) {
    return {
      isNextDayMove: false,
      isPrevDayMove: false,
      showNextToDayMoveDetails: true,
      setShowNextToDayMoveDetails: () => {}
    }
  }

  return {
    isNextDayMove: move.firstMoveOfDay && move._isNextDayMove,
    isPrevDayMove: move.lastMoveOfDay && move._isPrevDayMove,
    showNextToDayMoveDetails,
    setShowNextToDayMoveDetails
  }
}

export const isRouteBuilderPrevDayMove = (props: { activeTabDate: string; moveDate: string }): boolean => {
  const { activeTabDate, moveDate } = props
  return Boolean(
    activeTabDate && moveDate && dateService.isTheSameDay(activeTabDate, dateService.moveDate(moveDate, { addDay: 1 }))
  )
}

export const isRouteBuilderNextDayMove = (props: { activeTabDate: string; moveDate: string }): boolean => {
  const { activeTabDate, moveDate } = props
  return Boolean(
    activeTabDate && moveDate && dateService.isTheSameDay(activeTabDate, dateService.moveDate(moveDate, { addDay: -1 }))
  )
}

export const getRouteBuilderMoveDay = (move: IMoveDTO): string => {
  return dateService.getStringDay(getRouteBuilderMoveDate(move))
}

export const getRouteBuilderMoveDate = (move: IMoveDTO): string => {
  return [MoveStatus.UNSUCCESSFUL, MoveStatus.COMPLETED].includes(move.status) ? move.completeDate : move.plannedDate
}
