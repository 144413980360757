import * as React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import configureStore from './store/configureStore'
import { Root } from './components/Root'
import './styles/common.scss'
import { Store } from 'redux'
import { IStore } from './store/store.interface'
require('./favicon.ico') // tslint:disable-line:no-var-requires

const store: Store<IStore> = configureStore()

const getAppContainer = () => (
  <AppContainer>
    <Root store={store} />
  </AppContainer>
)

render(getAppContainer(), document.getElementById('app'))

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    render(getAppContainer(), document.getElementById('app'))
  })
}
