import * as React from 'react'
import { IDropdownItem } from './Dropdown'
import { StyledMultiselectValue, StyledSelectedPopover, SelectedPopoverItem, SingleValue } from './styles'

type LabelProps = {
  multiselect?: boolean
  value: IDropdownItem | IDropdownItem[]
  placeholder?: string
  label?: string | JSX.Element
}

export const Label = (props: LabelProps) => {
  const { multiselect, value, label, placeholder } = props

  const createLabel = () => {
    if (!value) {
      return null
    }

    if (multiselect) {
      if ((value as IDropdownItem[]).length === 0) {
        return null
      }
      return (value as IDropdownItem[]).length > 1 ? (
        <StyledMultiselectValue>
          <span>{(value as IDropdownItem[]).length}</span>
        </StyledMultiselectValue>
      ) : (
        <SingleValue>{value[0].label}</SingleValue>
      )
    }

    return <SingleValue>{(value as IDropdownItem).label}</SingleValue>
  }

  return <>{label || createLabel() || placeholder}</>
}

type LabelPopoverProps = {
  multiselect?: boolean
  separatePopoverRows?: boolean
  value: IDropdownItem | IDropdownItem[]
  popover?: string | JSX.Element
}
export const LabelPopover = (props: LabelPopoverProps) => {
  const { multiselect, separatePopoverRows, popover, value } = props

  const createPopover = () => {
    return multiselect && value && (value as IDropdownItem[]).length > 1
      ? (value as IDropdownItem[]).map(item => (
          <SelectedPopoverItem style={separatePopoverRows ? { display: 'block' } : undefined} key={item.label}>
            {item.label}
          </SelectedPopoverItem>
        ))
      : null
  }

  const labelPopover = popover || createPopover()
  return labelPopover ? (
    <StyledSelectedPopover>
      <div>{labelPopover}</div>
    </StyledSelectedPopover>
  ) : null
}
