import * as React from 'react'
import { Table } from './table'
import { IQMPFilters } from '../../../interfaces'
import { TNewCustomerQuotes } from '../../../../../../services/DTO/customerQuote/interfaces'

type OwnProps = {
  filters: IQMPFilters
  visited: boolean
  extraData: any
  newCustomerQuotes: TNewCustomerQuotes
}

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & OwnProps & DispatchProps

export const CustomerQuote = (props: Props) => {
  return <Table />
}
