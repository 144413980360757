import { getItemFromStorage, StorageKeys } from '../services/storageService/storageService'
import { UserDTO } from '../api/origin/user-service'
import { WEBSOCKET_URL, WEBSOCKET_PING_INTERVAL, WEBSOCKET_PING_TIMEOUT } from '../config'
import { handleReceivedChatMessage } from './CommunicationHub/functions/handleReceivedChatMessage'
import { WebsocketEvent, IWebsocketPingData, IWebsocketData, WebsocketService } from '../services/websocket/interfaces'
import { logWS, websocketRequestForDTOUpdate } from '../services/websocket'
import { correctDriverPosition } from '../services/functions/correctDriverPosition'
import { EntityType } from '../store/reducers/lists/interfaces'
import { documentServiceWebsocket } from '../services/websocket/documentServiceWebsocket'
import { handleReceivedNotification } from './CommunicationHub/functions/handleReceivedNotification'
import { pushListItemsToStore } from '../store/reducers/lists/functions/pushListItemsToStore'
import { refreshAuthToken } from '../services/functions/refreshAuthToken'

class Sockets {
  ws: WebSocket = null
  user: any = null
  intervalId: any = null
  ping: number = new Date().getTime()

  public init(user: UserDTO | null) {
    this.user = user
    this.wsConnect()

    return true
  }

  public destroy() {
    return false
  }

  wsConnect = () => {
    if (!WEBSOCKET_URL) {
      // tslint:disable-next-line:no-console
      return console.error('WEBSOCKET_URL not exist')
    }

    if (this.ws) {
      this.ws.close()
      return
    }

    const accessToken = getItemFromStorage(StorageKeys.AccessToken)
    this.ws = new WebSocket(WEBSOCKET_URL, accessToken)
    this.ws.onmessage = this.onMessage
    this.ws.onclose = this.onClose
    this.ws.onerror = this.onError

    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    this.intervalId = setInterval(this.onPing, WEBSOCKET_PING_INTERVAL)
  }

  onPing = () => {
    const ts = new Date().getTime()

    if (ts - this.ping > WEBSOCKET_PING_TIMEOUT) {
      this.ws.close()
    }
    if (this.ws && this.ws.readyState === 1) {
      this.ws.send(JSON.stringify({ ping: ts }))
    }
  }

  onClose = (event: CloseEvent) => {
    clearInterval(this.intervalId)
    this.ws = undefined

    if (event && event.code === 4001) {
      // tslint:disable-next-line:no-console
      console.error('WebSocket closed due to token expiration:', event.reason)
      refreshAuthToken().then(this.wsConnect)
    } else {
      this.wsConnect()
    }
  }

  onError = (error: any) => {
    // tslint:disable-next-line:no-console
    console.error('websocket error', error)
  }

  onMessage = async (event: any) => {
    const body = event.data
    let data

    try {
      data = JSON.parse(body) as IWebsocketPingData | IWebsocketData
    } catch (error) {
      // tslint:disable-next-line:no-console
      return console.error(`Can't parse message`, body)
    }

    if ('ping' in data) {
      this.ping = new Date().getTime()
    } else if ('service' in data) {
      switch (data.service) {
        case WebsocketService.DELIVERY_ORDER:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type,
                service: data.service
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.QMP:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type,
                service: data.service
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.USER:
          if (data.payload) {
            logWS({
              data: data.payload,
              eventType: data.eventType,
              DTOType: data.type,
              service: data.service
            })

            pushListItemsToStore({
              update: { [EntityType.user]: [data.payload] }
            })
          }

          break
        case WebsocketService.CHUB:
          if (data.payload) {
            logWS({
              data: data.payload,
              eventType: data.eventType,
              DTOType: data.type,
              service: data.service
            })

            if (data.type === 'DriverNotificationDTO') {
              handleReceivedNotification(data.payload, data.eventType)
            } else {
              handleReceivedChatMessage(data.payload)
            }
          }

          break
        case WebsocketService.ROUTE:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: correctDriverPosition(data.payload),
                eventType: WebsocketEvent.UPDATED,
                DTOType: 'DRIVERPOSITION',
                service: data.service
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.MOVE:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type,
                service: data.service
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse MOVE`, error)
            }
          }

          break
        case WebsocketService.DOCUMENT:
          if (data.payload) {
            try {
              documentServiceWebsocket(data)
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse DOCUMENT`, error)
            }
          }

          break
        case WebsocketService.DISPATCH_VENDOR:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type,
                service: data.service
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse MOVE`, error)
            }
          }

          break
        default:
      }
    }
  }
}

export const WsHubSockets = new Sockets()
