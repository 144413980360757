import { oc } from 'ts-optchain'
import { MessageType } from '../../../api/origin/communication-hub-service'
import { getCommunicationHubState } from '../../../store'
import { IMessage } from '../../../store/reducers/communicationHub/interfaces'

export const isMessageMuted = (message: IMessage): boolean => {
  try {
    const data = getCommunicationHubState()
    const { channelsMeta } = data
    const { type, channelId } = message || {}
    const muteMessageTypes = [MessageType.SYSTEM, MessageType.WORKORDER, MessageType.DOCUMENT]
    const isMutedChannel = oc(channelsMeta[channelId]).mute() === true

    // TODO check mute status here
    return isMutedChannel || muteMessageTypes.includes(type)
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
    return false
  }
}
