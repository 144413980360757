import * as React from 'react'
import { Radio } from '../../../../UI/Radio'
import { useGrid } from '../../../../../hooks/useGrid'
import { updateTabData } from '../../../../../store/reducers/tabs/functions'

type OwnProps = {
  gridItemId: string
  isSelected?: boolean
  isDisabledSelect?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const StreetTurnSelect = (props: Props) => {
  const { gridItemId, isSelected, isDisabledSelect } = props
  const { tabId, tabData, listRequestProps } = useGrid()

  const streetTurnRequested = tabData.streetTurnRequested
  const disabled = isDisabledSelect || streetTurnRequested

  return (
    <Radio
      active={isSelected}
      onClick={(event: any) => {
        event.stopPropagation()

        if (disabled) {
          return
        }

        const requesterId = listRequestProps.dispatchDeliveryOrderId

        updateTabData(tabId, {
          selectedIds: isSelected ? [requesterId] : [requesterId, gridItemId],
          pinnedIds: isSelected ? [requesterId] : [requesterId, gridItemId]
        })
      }}
      disabled={disabled}
    />
  )
}
