import { TabDTO } from './interfaces'

const iconClassName: Record<TabDTO.Type, string> = {
  [TabDTO.Type.powerUnit]: 'mdi mdi-truck',
  [TabDTO.Type.equipment]: 'mdi mdi-truck-trailer',
  [TabDTO.Type.location]: 'mdi mdi-map-marker',
  [TabDTO.Type.container]: 'mdi mdi-barcode',
  [TabDTO.Type.driver]: 'mdi mdi-seat-recline-normal',
  [TabDTO.Type.customer]: 'mdi mdi-tie',
  [TabDTO.Type.steamShipLine]: 'mdi mdi-anchor',
  [TabDTO.Type.subClient]: 'mdi mdi-tie',
  [TabDTO.Type.dispatchDeliveryOrder]: 'mdi mdi-file-document',
  [TabDTO.Type.deliveryOrder]: 'mdi mdi-file-document',
  [TabDTO.Type.QMP]: 'mdi mdi-cash-usd',
  [TabDTO.Type.scheduler]: 'mdi mdi-view-dashboard',
  [TabDTO.Type.locationGroup]: 'mdi mdi-view-dashboard',
  [TabDTO.Type.user]: 'mdi mdi-tie',
  [TabDTO.Type.serviceArea]: 'mdi mdi-tie'
}

const tabLabel: Record<TabDTO.Type, string> = {
  [TabDTO.Type.powerUnit]: 'Power Units',
  [TabDTO.Type.equipment]: 'Equipments',
  [TabDTO.Type.location]: 'Locations',
  [TabDTO.Type.container]: 'Containers',
  [TabDTO.Type.driver]: 'Drivers',
  [TabDTO.Type.customer]: 'Customers',
  [TabDTO.Type.steamShipLine]: 'SSL',
  [TabDTO.Type.subClient]: 'SubClients',
  [TabDTO.Type.dispatchDeliveryOrder]: 'Dispatch DO',
  [TabDTO.Type.deliveryOrder]: 'Delivery Order',
  [TabDTO.Type.QMP]: 'QMP',
  [TabDTO.Type.scheduler]: 'Scheduler',
  [TabDTO.Type.locationGroup]: 'Location Groups',
  [TabDTO.Type.user]: 'Users',
  [TabDTO.Type.serviceArea]: 'Service Areas'
}

export const tabDirectory = { tabLabel, iconClassName }
