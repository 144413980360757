import * as React from 'react'
import styled from 'styled-components'

export const qmpLists = {
  rules: [] as any,
  rulesWithDescription: [] as any
}

const StyledRule = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #445366;
`
export const makeReadableDescription = (text: string) => <StyledRule>{text}</StyledRule>
