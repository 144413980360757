import * as React from 'react'
import { StyledSelect, Selected, ButtonClear } from '../styles'
import { FieldContainer } from '../../FieldContainer'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'
import { Label } from './Label'
import cn from 'classnames'
// @ts-ignore
import { Widget } from './Widget'

export type TSelecterWidgetListItem = {
  label: string
  value: string
}

export type TSelecterWidgetStatisItem = {
  label: string
  isSelected: boolean
  selecterValue: any
}

type Props = {
  disabled?: boolean
  title?: string
  placeholder?: string
  highlighted?: boolean
  required?: boolean
  valid?: boolean
  style?: object
  label?: string | JSX.Element
  selectedItems: TSelecterWidgetListItem[]
  staticList: TSelecterWidgetStatisItem[]
  getAllItems: () => Promise<any[]>
  onSelect?: (value: string | string[] | any) => void
}

export const SelecterWidget = (props: Props) => {
  const {
    disabled,
    style,
    title,
    placeholder = 'Select',
    highlighted,
    required,
    label,
    selectedItems,
    staticList,
    getAllItems,
    onSelect
  } = props

  const { statePopover, togglePopover, wrapperRef } = useOutsideClick()
  const isEmpty = !label && !selectedItems
  const toggle = disabled ? undefined : togglePopover

  return (
    <FieldContainer
      title={title}
      required={required}
      turnOver={true}
      className={'select__container'}
      disabled={disabled}
    >
      <StyledSelect
        className={cn('select-container selected', {
          disabled,
          opened: statePopover,
          highlighted: highlighted || (required && !selectedItems)
        })}
        ref={wrapperRef}
        style={style}
      >
        <Selected
          className={cn('selected__label mdi', {
            disabled,
            'mdi-menu-down': !statePopover,
            'mdi-menu-up': statePopover,
            placeholder: isEmpty
          })}
          onClick={toggle}
        >
          <Label label={label} selectedItems={selectedItems} placeholder={placeholder} />
          {Boolean(selectedItems && selectedItems.length) && (
            <ButtonClear
              className={'mdi mdi-close-circle'}
              onClick={event => {
                event.stopPropagation()
                return onSelect(null)
              }}
            />
          )}
        </Selected>
        {statePopover && (
          <Widget
            wrapperRef={wrapperRef}
            staticList={staticList}
            selectedItems={selectedItems}
            getAllItems={getAllItems}
            togglePopover={togglePopover}
            onSelect={onSelect}
          />
        )}
      </StyledSelect>
    </FieldContainer>
  )
}
