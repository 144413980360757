import { TabDTO } from '../store/reducers/tabs/interfaces'
import { GridItemContainerProvider } from './GridItemContainerProvider'
import { GridItemCustomerProvider } from './GridItemCustomerProvider'
import { GridItemDDOProvider } from './GridItemDDOProvider'
import { GridItemDriverProvider } from './GridItemDriverProvider'
import { GridItemEquipmentProvider } from './GridItemEquipmentProvider'
import { GridItemLocationProvider } from './GridItemLocationProvider'
import { GridItemPowerUnitProvider } from './GridItemPowerUnitProvider'
import { GridItemSteamShipLineProvider } from './GridItemSteamShipLineProvider'
import { GridItemSubClientProvider } from './GridItemSubClientProvider'

export const GridItemProviderMapping = {
  [TabDTO.Type.powerUnit]: GridItemPowerUnitProvider,
  [TabDTO.Type.equipment]: GridItemEquipmentProvider,
  [TabDTO.Type.location]: GridItemLocationProvider,
  [TabDTO.Type.steamShipLine]: GridItemSteamShipLineProvider,
  [TabDTO.Type.subClient]: GridItemSubClientProvider,
  [TabDTO.Type.container]: GridItemContainerProvider,
  [TabDTO.Type.customer]: GridItemCustomerProvider,
  [TabDTO.Type.driver]: GridItemDriverProvider,
  [TabDTO.Type.dispatchDeliveryOrder]: GridItemDDOProvider
}
