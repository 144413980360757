import * as R from 'remeda'
import { oc } from 'ts-optchain'
import {
  ActivitiesViewDTO,
  DocumentationActivityDTO,
  OngoingActivityGroupDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { filterUsefulTransportationActivities } from './index'
import { TransportationActivityGroup } from './interfaces'
import { WebsocketEvent } from '../../websocket/interfaces'
import { filterLatestObjects } from '../../websocket/functions'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { requestDispatchDeliveryOrdersByIds } from '../../../components/common/dispatchDeliveryOrder/epics'
import { getCommunicationHubState, getDispatch, getApplicationTabsState, getListsState } from '../../../store'
import { getListOfUsedIdsOnSpecificDate } from '../../../components/common/scheduler/epics'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isUnsuccessfulActivityGroup } from '../../functions/test/isUnsuccessfulActivity'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { tabActions } from '../../../store/reducers/tabs'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { isFullObject } from '../../functions/test/isFullObject'

export const isInProcessOrCompletedGroup = (activityGroup: TransportationActivityGroup): boolean => {
  return (
    !isUnsuccessfulActivityGroup(activityGroup) &&
    (oc(activityGroup).gotoActivity.status() === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
      oc(activityGroup).businessActivity.status() === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
      oc(activityGroup).gotoActivity.status() === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
      oc(activityGroup).businessActivity.status() === TransportationActivityViewDTO.StatusEnum.COMPLETED)
  )
}

export const activityListCollector = () => {
  let ddoIds: string[] = []
  let ddoIdsOfUpdatedActivityDeliveryStage: string[] = []
  let removeActivities: string[] = []
  let forceUpdateActivities: ActivitiesViewDTO = {
    transportationActivities: [],
    documentationActivities: []
  }
  let timer: any

  return (data: ActivityViewDTO | string, eventType: string) => {
    const activity = typeof data === 'string' ? undefined : data
    const activityId = typeof data === 'string' ? data : undefined

    switch (eventType) {
      case WebsocketEvent.DELETED: {
        const store = getListsState()
        const storeActivity = store.activity[activityId]

        if (storeActivity) {
          ddoIds.push(storeActivity.dispatchDeliveryOrderId)
          removeActivities.push(activityId)
        }
        break
      }
      case WebsocketEvent.UPDATED_FORCE: {
        const store = getListsState()
        const storeTransportationActivity = store.activity[activity.id]
        const storeDocumentationActivity = store.activity[activity.id]

        if (storeTransportationActivity) {
          forceUpdateActivities.transportationActivities.push({
            ...(activity as TransportationActivityViewDTO),
            forceUpdate: true
          } as TransportationActivityViewDTO)
        }
        if (storeDocumentationActivity) {
          forceUpdateActivities.documentationActivities.push({
            ...(activity as DocumentationActivityDTO),
            forceUpdate: true
          } as DocumentationActivityDTO)
        }

        break
      }
      default:
        ddoIds.push(activity.dispatchDeliveryOrderId)

        if (activity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
          ddoIdsOfUpdatedActivityDeliveryStage.push(activity.dispatchDeliveryOrderId)
        }
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      const store = getListsState()
      const { schedulerTabData } = getCommunicationHubState()
      ddoIds = R.uniq(ddoIds)
      ddoIdsOfUpdatedActivityDeliveryStage = R.uniq(ddoIdsOfUpdatedActivityDeliveryStage)

      if (
        forceUpdateActivities.transportationActivities.length ||
        forceUpdateActivities.documentationActivities.length
      ) {
        pushListItemsToStore({
          update: {
            [EntityType.activity]: [
              ...oc(forceUpdateActivities).transportationActivities([]),
              ...oc(forceUpdateActivities).documentationActivities([])
            ]
          }
        })
      }

      if (
        ddoIdsOfUpdatedActivityDeliveryStage.length &&
        schedulerTabData &&
        ddoIdsOfUpdatedActivityDeliveryStage.some(id => schedulerTabData.ongingDispatchDeliveryOrderIds.includes(id))
      ) {
        getListOfUsedIdsOnSpecificDate(schedulerTabData.specificDate).then(activeDeliveryStageVendorIds => {
          if (schedulerTabData === oc(getCommunicationHubState()).schedulerTabData()) {
            communicationHubActions.updateActiveRouteBuilderSchedulerTabData({ activeDeliveryStageVendorIds })
          }
        })
      }

      const usedDDOIds = ddoIds.filter(id => isFullObject(store.dispatchDeliveryOrder[id]))
      const activitiesToDelete = [...removeActivities]
      if (usedDDOIds.length) {
        requestDispatchDeliveryOrdersByIds(usedDDOIds).then(() => {
          if (activitiesToDelete.length) {
            pushListItemsToStore({ delete: { [EntityType.activity]: activitiesToDelete } })
          }
        })
      }

      ddoIds = []
      ddoIdsOfUpdatedActivityDeliveryStage = []
      removeActivities = []
      forceUpdateActivities = {
        transportationActivities: [],
        documentationActivities: []
      }
    }, 3000)
  }
}

export const ongoingActivityGroupsCollector = () => {
  let deletedGroupActivitiesIds: string[] = []
  let putGroupActivities: OngoingActivityGroupDTO[] = []
  let timer: any

  return (activityRow: OngoingActivityGroupDTO | string, eventType: string) => {
    switch (eventType) {
      case WebsocketEvent.DELETED: {
        const activityRowId = activityRow as string
        deletedGroupActivitiesIds.push(activityRowId)
        putGroupActivities = putGroupActivities.filter(({ id }) => id !== activityRowId)
        break
      }
      case WebsocketEvent.UPDATED_FORCE: {
        putGroupActivities.push({
          ...(activityRow as OngoingActivityGroupDTO),
          forceUpdate: true
        } as OngoingActivityGroupDTO)

        break
      }
      default: {
        putGroupActivities.push(activityRow as OngoingActivityGroupDTO)
      }
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      const filteredLatestObjects = filterLatestObjects(putGroupActivities)
      updateSchedulerTabs({ deletedGroupActivitiesIds, putGroupActivities: filteredLatestObjects })

      deletedGroupActivitiesIds = []
      putGroupActivities = []
    }, 3000)
  }
}

const updateSchedulerTabs = async ({
  deletedGroupActivitiesIds,
  putGroupActivities
}: {
  deletedGroupActivitiesIds: string[]
  putGroupActivities: OngoingActivityGroupDTO[]
}) => {
  if (!deletedGroupActivitiesIds.length && !putGroupActivities.length) {
    return
  }

  const dispatch = getDispatch()
  const updateIds = R.uniq(putGroupActivities.map((_: any) => _.id))
  const idsToClose = deletedGroupActivitiesIds.filter(id => !updateIds.includes(id))
  const ongoingActivityDateStage: OngoingActivityGroupDTO[] = []
  const ongoingActivityDateStageNow: OngoingActivityGroupDTO[] = []
  const ongoingActivityDateDriver: OngoingActivityGroupDTO[] = []

  putGroupActivities.forEach(item => {
    if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE) {
      ongoingActivityDateStage.push(item)
    } else if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGENOW) {
      ongoingActivityDateStageNow.push(item)
    } else if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.DRIVER) {
      ongoingActivityDateDriver.push(item)
    }
  })

  await pushListItemsToStore({
    update: {
      [EntityType.ongoingActivityDateStage]: ongoingActivityDateStage,
      [EntityType.ongoingActivityDateStageNow]: ongoingActivityDateStageNow,
      [EntityType.ongoingActivityDateDriver]: ongoingActivityDateDriver
    },
    delete: {
      [EntityType.ongoingActivityDateStage]: deletedGroupActivitiesIds,
      [EntityType.ongoingActivityDateStageNow]: deletedGroupActivitiesIds,
      [EntityType.ongoingActivityDateDriver]: deletedGroupActivitiesIds
    }
  })

  const schedulerTabs = getApplicationTabsState().filter(tab => tab.type === TabDTO.Type.scheduler)

  schedulerTabs.forEach(tab => {
    const viewingObjectId = oc(tab).expandedItem.id()

    if (viewingObjectId && idsToClose.includes(viewingObjectId)) {
      dispatch(tabActions.closeExpandedItem({ tabId: tab.id }))
    }
  })
}

export type TAssignedDrivers = { vendorId: string; authUserId: string }[]

export const getAssignedDrivers = (
  previousTransportationActivities: TransportationActivityViewDTO[],
  updatedTransportationActivities: TransportationActivityViewDTO[]
): TAssignedDrivers => {
  if (!previousTransportationActivities.length || !updatedTransportationActivities.length) {
    return []
  }

  const driverMapping = getListsState()[EntityType.driver]

  return filterUsefulTransportationActivities(updatedTransportationActivities)
    .filter(activity => {
      return isGotoActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
    })
    .filter(activity => {
      const prevActivityState = previousTransportationActivities.find(_ => _.id === activity.id)

      if (!prevActivityState) {
        // is new activity
        return true
      }

      if (activity.vendorId && oc(prevActivityState).vendorId() !== activity.vendorId) {
        // driver changed
        return true
      }

      // prev activity status wasn't DRIVERASSIGNED but now it is
      return oc(prevActivityState).status() !== TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
    })
    .reduce((acc, activity) => {
      if (acc.some(({ vendorId }) => vendorId === activity.vendorId)) {
        return acc
      }

      const vendor = driverMapping[activity.vendorId]
      acc.push({ vendorId: activity.vendorId, authUserId: oc(vendor).authUserId() })
      return acc
    }, [])
}
