import * as React from 'react'
// tslint:disable:max-line-length
import { useTransportationActivityRow } from '../../../../../../../../../../../hooks/useTransportationActivityRow'
import { ActivityDocumentPopupView } from '../../../../../../../../interfaces'
import { Container, TroubleTicketButton } from './styles'
import { TroubleTicketPopup } from './TroubleTicketPopup'

type Props = {
  troubleTicketId: string
}

export const TroubleTicket = React.memo((props: Props) => {
  const { troubleTicketId } = props
  // const [showPopup, setShowPopup] = React.useState(false)
  const { openActivityDocumentPopup } = useTransportationActivityRow()

  return (
    <>
      {troubleTicketId && (
        <Container>
          <TroubleTicketButton onClick={() => openActivityDocumentPopup(ActivityDocumentPopupView.INFO, true)}>
            <span className={'mdi mdi-alert-decagram'} /> Trouble Ticket
          </TroubleTicketButton>
        </Container>
      )}
      {/* {showPopup && <TroubleTicketPopup troubleTicketId={troubleTicketId} closePopup={() => setShowPopup(false)} />} */}
    </>
  )
})
