import * as React from 'react'
import { oc } from 'ts-optchain'
import { Table as StyledTable, Spinner, TableContainer } from '../styled'
import { Header } from './Header'
import { Row } from './Row'
import { callAPI, AuditTrailAPI } from '../../../../../../../api/api'
import { GridSpinner } from '../../../../../../UI/Spinner/Spinner'
import { makeListItems, testListItem } from '../functions'
import { TAuditTrailFilter, TAuditTrailListItem } from '../interfaces'
import { useGridItemDDO } from '../../../../../../../hooks/useGridItemDDO'

type Props = {}

export const Table = (props: Props) => {
  const { dispatchDeliveryOrder } = useGridItemDDO()
  const [list, setList] = React.useState([] as TAuditTrailListItem[])
  const [isFetching, setFetching] = React.useState(true)
  const [filter, setFilter] = React.useState({
    updatedAt: null,
    updatedBy: null,
    fieldName: null,
    newValue: null,
    oldValue: null,
    activityNumber: null
  } as TAuditTrailFilter)

  React.useEffect(() => {
    callAPI(AuditTrailAPI.getAuditTrail, oc(dispatchDeliveryOrder).id())
      .toPromise()
      .then(data => {
        setList(makeListItems(data))
        setFetching(false)
      })
  }, [oc(dispatchDeliveryOrder).id()])

  const doFilter = () => list.filter(item => testListItem(item, filter))
  const filteredList = Object.values(filter).every(_ => !_) ? list : doFilter()

  return (
    <TableContainer>
      <Header filter={filter} setFilter={setFilter} />
      <StyledTable>
        {isFetching && (
          <Spinner>
            <GridSpinner />
          </Spinner>
        )}
        {filteredList.map((_props, index) => (
          <Row key={index} {..._props} />
        ))}
      </StyledTable>
    </TableContainer>
  )
}
