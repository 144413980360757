import { TransportationActivityViewDTO } from '../../../api/api'

export const filterAvailableActivitiesToAttach = (
  activities: TransportationActivityViewDTO[],
  surcharges: any[]
): TransportationActivityViewDTO[] => {
  return (activities || []).filter(activity =>
    (surcharges || []).every(surcharge => surcharge.activityId !== activity.id)
  )
}
