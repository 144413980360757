import { oc } from 'ts-optchain'
import { RootState } from '..'
import {
  ICommunicationHub,
  CommunicationHubTab,
  TMessages,
  IChannels,
  TUserMeta,
  IChannelsMeta,
  IChannelBrowser,
  IActiveChatChannel,
  ChannelType,
  IChannelMeta,
  ICHubChannel,
  ChatTab,
  IMessage
} from '../reducers/communicationHub/interfaces'

export const selectCommunictaionHubIsVisible = (state: RootState): boolean => {
  const { activeTab } = state.communicationHub

  return Boolean(activeTab)
}

export const selectCommunictaionHubIsExpanded = (state: RootState): boolean => {
  const { activeTab, activeChannel, activeRouteBuilder, channelBrowser } = state.communicationHub

  return Boolean(
    (activeTab === CommunicationHubTab.messenger && (activeChannel || channelBrowser)) ||
      (activeTab === CommunicationHubTab.routeBuilder && activeRouteBuilder)
  )
}

export const selectCommunictaionHub = (state: RootState): ICommunicationHub => {
  return state.communicationHub
}

export const selectCommunictaionHubTab = (state: RootState): CommunicationHubTab => {
  return state.communicationHub.activeTab
}

export const selectCommunictaionHubMessageIds = (state: RootState): string[] => {
  return state.communicationHub.messages.ids
}

export const selectCommunictaionHubMessages = (state: RootState): Record<string, IMessage> => {
  return state.communicationHub.messages.mapping
}

export const selectCommunictaionHubMessage = (messageId: string) => (state: RootState): IMessage => {
  return state.communicationHub.messages.mapping[messageId]
}

export const selectCommunictaionHubChannelIds = (channelType: ChannelType) => (state: RootState): string[] => {
  return state.communicationHub.channels.idListByType[channelType]
}

export const selectCommunictaionHubUserMeta = (state: RootState): TUserMeta => {
  return state.communicationHub.userMeta
}

export const selectCommunictaionHubChannelsMeta = (state: RootState): IChannelsMeta => {
  return state.communicationHub.channelsMeta
}

export const selectCommunictaionHubChannelMeta = (channelId: string) => (state: RootState): IChannelMeta => {
  return state.communicationHub.channelsMeta[channelId]
}

export const selectCommunictaionHubChannelMetaUnreadMessagesNumber = (channelId?: string) => (
  state: RootState
): number => {
  const { channelsMeta, activeChannel } = state.communicationHub

  return oc(channelsMeta)[channelId || oc(activeChannel).id()].count(0)
}

export const selectCommunictaionHubHasUnreadMentionedMessages = (state: RootState): boolean => {
  const { channelsMeta } = state.communicationHub

  return Object.values(channelsMeta).some(channelMeta => {
    const hasNewMentionedMessages = Boolean(channelMeta.mentionMsgIds && channelMeta.mentionMsgIds.length)

    return hasNewMentionedMessages
  })
}

export const selectCommunictaionHubChannelBrowser = (state: RootState): IChannelBrowser => {
  return state.communicationHub.channelBrowser
}

export const selectCommunictaionHubChannels = (
  state: RootState
): {
  [channelId: string]: ICHubChannel
} => {
  return state.communicationHub.channels.mapping
}

export const selectCommunictaionHubChannel = (channelId: string) => (state: RootState): ICHubChannel => {
  return state.communicationHub.channels.mapping[channelId]
}

export const selectCommunictaionHubChannelName = (channelId: string) => (state: RootState): string => {
  const { channels } = state.communicationHub

  return oc(channels).mapping[channelId].name()
}

export const selectCommunictaionHubChannelHasNewMasseges = (channelId: string) => (state: RootState): boolean => {
  const { count, mentionMsgIds, mute } = oc(state).communicationHub.channelsMeta[channelId]({} as IChannelMeta)

  return Boolean((!mute && count) || (mentionMsgIds && mentionMsgIds.length))
}

export const selectCommunictaionHubChannelMentionsCount = (channelId: string) => (state: RootState): number => {
  const { mentionMsgIds } = oc(state).communicationHub.channelsMeta[channelId]({} as IChannelMeta)

  return mentionMsgIds ? mentionMsgIds.length : 0
}

export const selectCommunictaionHubIsChannelMuted = (channelId: string) => (state: RootState): boolean => {
  const channelMeta = state.communicationHub.channelsMeta[channelId]

  return Boolean(oc(channelMeta).mute())
}

export const selectCommunictaionHubChannelHasNewNotes = (channelId: string) => (state: RootState): boolean => {
  const { pinnedMsgIds } = oc(state).communicationHub.channelsMeta[channelId]({} as IChannelMeta)

  return Boolean(pinnedMsgIds && pinnedMsgIds.length)
}

export const selectCommunictaionHubActiveChannel = (state: RootState): ICHubChannel => {
  const {
    activeChannel,
    channels: { mapping }
  } = state.communicationHub

  return activeChannel && activeChannel.id ? mapping[activeChannel.id] : undefined
}

export const selectCommunictaionHubActiveChannelTab = (state: RootState): ChatTab => {
  const { activeChannel } = state.communicationHub

  return activeChannel && activeChannel.chatTab
}

export const selectCommunictaionHubActiveChannelIsUpdateUnreadCount = (state: RootState): boolean => {
  const { activeChannel } = state.communicationHub

  return Boolean(activeChannel && activeChannel.updateUnreadCount)
}

export const selectCommunictaionHubEditMessageId = (state: RootState): string => {
  return state.communicationHub.editMessageId
}

export const selectCommunictaionHubIsAllUnreadView = (state: RootState): boolean => {
  return Boolean(state.communicationHub.activeChannel && !state.communicationHub.activeChannel.id)
}

export const selectCommunictaionHubIsChannelBrowserView = (state: RootState): boolean => {
  return Boolean(state.communicationHub.channelBrowser)
}

export const selectCommunictaionHubIsActiveChannelView = (state: RootState): boolean => {
  const { activeTab, activeChannel } = state.communicationHub

  return activeTab === CommunicationHubTab.messenger && Boolean(activeChannel)
}

export const selectCommunictaionHubIsActiveChannelId = (channelId: string, isRecentOpenedChannel?: boolean) => (
  state: RootState
): boolean => {
  const { activeTab, activeChannel } = state.communicationHub

  return (
    activeTab === CommunicationHubTab.messenger &&
    oc(activeChannel).id() === channelId &&
    (typeof isRecentOpenedChannel !== 'boolean' || oc(activeChannel).isRecentOpenedChannel() === isRecentOpenedChannel)
  )
}
