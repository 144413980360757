import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridItemSubClientContext } from '../contexts/GridItemSubClientContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useBusinessPartner } from '../hooks/useBusinessPartner'
import { useSubClient } from '../hooks/useSubClient'
import { useContact } from '../hooks/useContact'
import { useCustomer } from '../hooks/useCustomer'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemSubClientProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const subClient = useSubClient({ id, modifiedLists })
  const businessPartner = useBusinessPartner({ id: subClient.businessPartnerId, modifiedLists })
  const primaryContact = useContact({ modifiedLists, id: oc(businessPartner).primaryContactId() })
  const customer = useCustomer({ id: oc(subClient).customerId(), modifiedLists })
  const customerBusinessPartner = useBusinessPartner({ id: oc(customer).businessPartnerId(), modifiedLists })

  return (
    <GridItemSubClientContext.Provider
      value={{
        isExpanded: Boolean(modifiedLists),
        subClient,
        businessPartner,
        primaryContact,
        customer,
        customerBusinessPartner
      }}
      children={children}
    />
  )
})
