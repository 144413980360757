import { oc } from 'ts-optchain'
import { ConfirmedLocalDateTimeRangeDTO, DateISOString, DateTimeRangeDTO } from '../../../api/api'
import { isDiffRanges } from '../../../components/UI/DatePicker/Functions'

type Props = {
  activityCompletionActualDate: DateISOString
  ddoPlannedAppointmentDateTimeRange: ConfirmedLocalDateTimeRangeDTO
  activityStartPlannedDateTimeRange: DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO
}

export const getCorrectPlannedAppointmentDate = (props: Props): ConfirmedLocalDateTimeRangeDTO => {
  const { activityCompletionActualDate, activityStartPlannedDateTimeRange, ddoPlannedAppointmentDateTimeRange } = props
  const appointmentDateChanged = isDiffRanges(ddoPlannedAppointmentDateTimeRange, activityStartPlannedDateTimeRange)

  if (!appointmentDateChanged) {
    return ddoPlannedAppointmentDateTimeRange
      ? {
          from: oc(ddoPlannedAppointmentDateTimeRange).from(),
          to: oc(ddoPlannedAppointmentDateTimeRange).to(),
          confirmed: Boolean(activityCompletionActualDate) || oc(ddoPlannedAppointmentDateTimeRange).confirmed(null)
        }
      : ddoPlannedAppointmentDateTimeRange
  }

  const hasDate = Boolean(oc(activityStartPlannedDateTimeRange).from() || oc(activityStartPlannedDateTimeRange).to())

  return hasDate
    ? {
        confirmed: true,
        from: oc(activityStartPlannedDateTimeRange).from(),
        to: oc(activityStartPlannedDateTimeRange).to()
      }
    : undefined
}
