import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { ChatTab, ICHubChannel } from '../../../../store/reducers/communicationHub/interfaces'
import { getChatMessages, setChatChannelLastOpen } from '../../epics'
import { MessagesTab } from './MessagesTab'
import { DocumentsTab } from './DocumentsTab'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { DraftMessageEditor } from '../Editor/DraftMessageEditor'
import { AttachmentsUploader } from '../Editor/AttachmentsUploader'
import { NewMessagesNotification } from './NewMessagesNotification'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
// tslint:disable:max-line-length
import { selectCommunictaionHubActiveChannelIsUpdateUnreadCount } from '../../../../store/select/communicationHubSelect'
import { UserDTO } from '../../../../api/origin/user-service'
import { getCommunicationHubState } from '../../../../store'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'

type OwnProps = {
  chatTab: ChatTab
  channel: ICHubChannel | UserDTO
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ChannelViewBody = (props: Props) => {
  const { chatTab, channel } = props
  const dispatch = useAppDispatch()
  const updateUnreadCount = useAppSelector(selectCommunictaionHubActiveChannelIsUpdateUnreadCount)
  const doNotCorrectScrollPosition = React.useRef(updateUnreadCount)
  const containerRef = React.useRef(null)
  const [fetch, setFetch] = React.useState(true)
  doNotCorrectScrollPosition.current = updateUnreadCount

  React.useEffect(() => {
    if (fetch) {
      return
    }

    if (updateUnreadCount === false) {
      const communicationHub = getCommunicationHubState()
      const needToClear = Boolean(
        oc(communicationHub).channelsMeta[channel.id].count() ||
          oc(communicationHub).channelsMeta[channel.id].mentionMsgIds([]).length
      )

      if (needToClear) {
        dispatch(communicationHubActions.clearChannelIdUnreadCount(channel.id))
        setChatChannelLastOpen(channel.id)
      }
    }
  }, [updateUnreadCount])

  const isTabWithMessages = React.useMemo(() => {
    return [ChatTab.Messages, ChatTab.Notes, ChatTab.Alerts].includes(chatTab)
  }, [chatTab])

  const scrollTop = React.useCallback(() => {
    if (!containerRef || !containerRef.current) {
      return
    }

    containerRef.current.scrollTop = 0
  }, [])

  const scrollBottom = React.useCallback(() => {
    if (!containerRef || !containerRef.current) {
      return
    }

    containerRef.current.scrollTop = containerRef.current.scrollHeight
  }, [])

  React.useLayoutEffect(() => {
    if (isTabWithMessages) {
      scrollBottom()
    } else {
      scrollTop()
    }
  }, [chatTab, scrollBottom, isTabWithMessages])

  const onMessageListChange = React.useCallback(() => {
    if (
      !containerRef ||
      !containerRef.current ||
      getCommunicationHubState().editMessageId ||
      doNotCorrectScrollPosition.current
    ) {
      return
    }

    scrollBottom()
  }, [])

  const handleMessagesContainerScroll = React.useCallback(() => {
    if (!containerRef || !containerRef.current || !isTabWithMessages) {
      return
    }

    const { scrollHeight, scrollTop: scrollTopPosition, offsetHeight } = containerRef.current
    const newUpdateUnreadCountState = scrollHeight - (scrollTopPosition + offsetHeight) > 10

    if (newUpdateUnreadCountState !== doNotCorrectScrollPosition.current) {
      dispatch(communicationHubActions.setUpdateActiveChannelUnreadCount(newUpdateUnreadCountState))
    }
  }, [chatTab, isTabWithMessages])

  React.useEffect(() => {
    try {
      getChatMessages(channel.id)
        .then(messages => {
          if (messages && messages.length) {
            dispatch(communicationHubActions.setMessages(messages))
          }

          setChatChannelLastOpen(channel.id).then(() => {
            dispatch(communicationHubActions.clearChannelIdUnreadCount(channel.id))
          })
        })
        .finally(() => setFetch(false))
    } catch (e) {}
  }, [channel.id])

  const RenderTabContent = React.useMemo(() => {
    switch (chatTab) {
      case ChatTab.Messages:
      case ChatTab.Notes:
      case ChatTab.Alerts:
        return <MessagesTab chatTab={chatTab} onMessageListChange={onMessageListChange} />
      case ChatTab.Documents:
        return <DocumentsTab />
      default:
        return null
    }
  }, [chatTab, onMessageListChange])

  return (
    <>
      <MessagesContainer ref={containerRef} onScroll={handleMessagesContainerScroll}>
        {fetch ? <GridSpinner /> : RenderTabContent}
      </MessagesContainer>
      {!fetch && (
        <>
          {updateUnreadCount && (
            <NewMessagesNotification channelId={channel.id} scrollBottom={scrollBottom} chatTab={chatTab} />
          )}
          {chatTab === ChatTab.Messages && <DraftMessageEditor channelId={channel.id} />}
          {chatTab === ChatTab.Documents && <AttachmentsUploader channelId={channel.id} />}
        </>
      )}
    </>
  )
}

const MessagesContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow: auto;
`
