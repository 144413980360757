import * as React from 'react'
import { oc } from 'ts-optchain'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import styled from 'styled-components'
import { Checkbox } from '../../../Radio'
import theme from '../../../../../styles/theme'
import { generateRateNumber } from '../../../../../services/functions/generate/generateRateNumber'
import { SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { quoteDirectory } from '../../../../../services/DTO/sellSideQuote/directory'
import { InputField } from '../../../DataFields/Input'
import { HoursMinutes } from '../../../DataFields/Input/inputTypes/hoursMinutes'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectRules } from '../../../../../store/select/ruleSelect'
import { dateService } from '../../../../../services/timeService'
import { SellSideQuoteSurchargeDTO, TransportationActivityViewDTO } from '../../../../../api/api'
import { getSurchargeAmount } from '../../../../../services/functions/get/getSurchargeAmount'
// tslint:disable:max-line-length
import { filterAvailableActivitiesToAttach } from '../../../../../services/functions/filter/filterAvailableActivitiesToAttach'
import { generateSurcharge } from '../../../../../services/functions/generate/generateSurcharge'
import { activityLists } from '../../../../../services/select/activityLists'
import { Select } from '../../../DataFields/Select'
import { openQMPRatesTab } from '../../../../../services/functions/openTab/openQMPRatesTab'

export enum SsqBsqWidgetRateColumnType {
  Select = 'Select',
  ID = 'ID',
  Activity = 'Activity',
  Surcharges = 'Surcharges',
  Type = 'Type',
  CalculationType = 'Calculation Type',
  Rule = 'Rule',
  EffectiveDate = 'Effective Date',
  ExpirationDate = 'Expiration Date',
  Amount = 'Amount'
}

type Props = {
  columnType: SsqBsqWidgetRateColumnType
  rate: CombinedSurchargeRateDTO
  baseRate: CombinedSurchargeRateDTO | undefined
  surcharges?: SurchargeDTO[]
  addSurcharge: (surcharge: SurchargeDTO) => void
  deleteSurcharge: () => void
  modifySurcharge: (surcharge: SurchargeDTO) => void
  disable: boolean
}

export const SsqBsqWidgetRateColumn = React.memo((props: Props) => {
  const { columnType, rate, baseRate, surcharges, disable, addSurcharge, deleteSurcharge, modifySurcharge } = props
  const { businessActivities, bobtailActivities } = useSsqBsqWidget()
  const ruleMapping = useAppSelector(selectRules)
  const isSurcharge = 'rateId' in rate

  switch (columnType) {
    case SsqBsqWidgetRateColumnType.Select: {
      return (
        <Column>
          {!disable && (
            <Checkbox
              style={{ margin: '0 10px' }}
              active={isSurcharge}
              onClick={isSurcharge ? deleteSurcharge : () => addSurcharge(generateSurcharge(rate))}
            />
          )}
        </Column>
      )
    }
    case SsqBsqWidgetRateColumnType.ID: {
      // @ts-ignore
      const rateNumber: number = rate.number

      return (
        <Column style={{ justifyContent: 'center' }}>
          <Link
            onClick={() => {
              openQMPRatesTab({
                filledFilters: {
                  number: rateNumber,
                  status: [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED]
                }
              })
            }}
          >
            {generateRateNumber(rateNumber, 5)}
          </Link>
        </Column>
      )
    }
    case SsqBsqWidgetRateColumnType.Surcharges: {
      return <Column style={{ fontWeight: 500 }}>{quoteDirectory.typeOfRate[rate.type]}</Column>
    }
    case SsqBsqWidgetRateColumnType.Type: {
      // @ts-ignore
      return <Column>{rate.customerId || rate.vendorId ? 'PRT' : 'TFF'}</Column>
    }
    case SsqBsqWidgetRateColumnType.CalculationType: {
      const showMoney =
        isSurcharge &&
        [
          SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR,
          SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY,
          SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE
        ].includes(rate.calculationType)

      return (
        <Column>
          {/* @ts-ignore */}
          {makeSurchargeCalculationTypeColumn(isSurcharge, rate, quantity => modifySurcharge({ ...rate, quantity }))}
          {showMoney && <RateAmount>{transformMoney(rate.amount)}</RateAmount>}
        </Column>
      )
    }
    case SsqBsqWidgetRateColumnType.Rule: {
      let rulesHTML = null
      // @ts-ignore
      const ruleIds = oc(rate).ruleIds([]) as string[]

      if (Boolean(ruleIds.length)) {
        const ruleList = ruleIds.reduce((acc: string[], currId: string) => {
          const rule = ruleMapping[currId]
          if (rule && rule.name) {
            acc.push(ruleMapping[currId].name)
          }
          return acc
        }, [])

        rulesHTML = ruleList.length ? (
          <ul data-for={'rules'} data-tip={ruleList.join('\n')}>
            {ruleList.map((rule: string, index: number) => {
              return <li key={index}>{rule}</li>
            })}
          </ul>
        ) : (
          undefined
        )
      }

      return (
        <Column>
          <Rules>
            <div>{rulesHTML || '–'}</div>
          </Rules>
        </Column>
      )
    }
    case SsqBsqWidgetRateColumnType.EffectiveDate: {
      // @ts-ignore
      return <Column>{dateService.makeLabel(rate.effectiveDate, { hideTime: true }) || '–'}</Column>
    }
    case SsqBsqWidgetRateColumnType.ExpirationDate: {
      const isExpired =
        // @ts-ignore
        rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED ||
        // @ts-ignore
        (rate.expirationDate ? Date.parse(rate.expirationDate) < new Date().getTime() : false)

      return (
        <Column style={{ color: isExpired ? 'red' : undefined }}>
          {/* @ts-ignore */}
          {dateService.makeLabel(rate.expirationDate, { hideTime: true }) || '–'}
        </Column>
      )
    }
    case SsqBsqWidgetRateColumnType.Amount: {
      let amount: number | string = 0

      if (
        [
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERDAY,
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERHOUR,
          SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERMILE
        ].includes(rate.calculationType)
      ) {
        amount = isSurcharge
          ? transformMoney(getSurchargeAmount({ mainRateAmount: 0, surcharge: rate, defaultQuantity: 0 }))
          : transformMoney(rate.amount)
      } else if (rate.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE) {
        if (baseRate) {
          return (
            <Column style={{ fontWeight: 500, justifyContent: 'flex-end', paddingRight: 10 }}>
              <CellWithDetails
                data-for={'hint'}
                data-tip={transformMoney((oc(rate).amount(0) / 100) * oc(baseRate).amount())}
              >
                {rate.amount + '%'}
              </CellWithDetails>
            </Column>
          )
        }

        amount = rate.amount + '%'
      } else {
        amount = transformMoney(rate.amount)
      }

      return <Column style={{ fontWeight: 500, justifyContent: 'flex-end', paddingRight: 10 }}>{amount}</Column>
    }
    case SsqBsqWidgetRateColumnType.Activity: {
      const isBobtail = rate.type === SellSideQuoteRateDTO.TypeEnum.BOBTAIL
      const activities = isBobtail ? bobtailActivities : businessActivities

      return (
        <Column>
          {isSurcharge ? (
            makeActivityColumn({
              rate,
              activities,
              availableActivitiesToAttach: filterAvailableActivitiesToAttach(activities, surcharges),
              onActivityIdSelect: activityId => modifySurcharge({ ...rate, activityId }),
              disableActivityColumn: disable,
              showIcon: false
            })
          ) : (
            <span style={{ fontWeight: 500 }}>DDO</span>
          )}
        </Column>
      )
    }
    default:
      return <Column />
  }
})

const Column = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 0 8px;
`

const Link = styled.div`
  color: ${theme.colors.basicBlueColor};
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`
const RateAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  padding: 0 5px;
  margin-left: auto;
`
const Rules = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: auto;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      width: 100%;
      li {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`
const CellWithDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .details {
    font-weight: 400;
  }
`

export const makeActivityColumn = ({
  rate,
  activities,
  availableActivitiesToAttach,
  showIcon,
  onActivityIdSelect,
  disableActivityColumn
}: {
  rate: any
  activities: TransportationActivityViewDTO[]
  availableActivitiesToAttach: TransportationActivityViewDTO[]
  onActivityIdSelect: (activityId: string) => void
  showIcon: boolean
  disableActivityColumn?: boolean
}) => {
  const mapping = [
    { label: 'DDO', htmlLabel: <div style={{ fontWeight: 500 }}>DDO</div>, value: 'DDO' } as any,
    ...activityLists.makeTypeListWithIconAndActivityNumber(activities, showIcon)
  ]
  const actualList = [
    { label: 'DDO', htmlLabel: <div style={{ fontWeight: 500 }}>DDO</div>, value: 'DDO' } as any,
    ...activityLists.makeTypeListWithIconAndActivityNumber(availableActivitiesToAttach, showIcon)
  ]
  const selectedItem = mapping.find(({ value }: any) => value === rate.activityId)
  const list = rate.activityId ? actualList : actualList.slice(1)

  return (
    <Select
      mapping={mapping}
      disabled={disableActivityColumn || !list.length}
      label={oc(selectedItem).htmlLabel('DDO')}
      selectedValue={rate.activityId}
      list={list}
      onSelect={(activityId: string) => onActivityIdSelect(activityId === 'DDO' ? undefined : activityId)}
      dropdownStyle={{ minWidth: 220 }}
    />
  )
}

const makeSurchargeCalculationTypeColumn = (
  allowChangeQuantity: boolean,
  rate: any,
  onQuantityChange: (value: number) => void
) => {
  const selectedCalcType = quoteDirectory.calculationType[rate.calculationType]

  if (allowChangeQuantity) {
    switch (rate.calculationType) {
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY:
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE:
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            {selectedCalcType}
            <div style={{ width: 67, paddingLeft: 5 }}>
              <InputField.Numeric
                value={rate.quantity}
                onChange={value => onQuantityChange(value)}
                placeholder={'00'}
              />
            </div>
          </div>
        )
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR:
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            {selectedCalcType}
            <div style={{ width: 135, paddingLeft: 5 }}>
              <HoursMinutes minutes={rate.quantity} onChange={value => onQuantityChange(value)} />
            </div>
          </div>
        )
      default:
    }
  }

  return selectedCalcType
}
