import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../styles/theme'

const StyledColumn = styled.li`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.basicBlueColor};
    font-size: 14px;
    min-width: 16px;
    height: 16px;
    background: ${theme.colors.basicBackgroundColor};
    border-radius: 5px;
    margin-right: 7px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`

interface IColumnProps {
  name: string
  active: boolean
  onChange(columnName: string): void
}

class Multiselector extends React.PureComponent<IColumnProps> {
  render() {
    const { name, active, onChange } = this.props
    return (
      <StyledColumn onClick={() => onChange(name)}>
        <span className={active ? 'mdi mdi-check' : ''} />
        {name}
      </StyledColumn>
    )
  }
}

export default Multiselector
