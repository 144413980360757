import * as React from 'react'
import { TableCell } from '../../../../common/grid/views/styles'
import { StreetTurnSelect } from './StreetTurnSelect'
import { TabDTO } from '../../../../../store/reducers/tabs/interfaces'

type OwnProps = {
  gridItemId: string
  isSelected?: boolean
  isDisabledSelect?: boolean
  actionType?: TabDTO.Actions
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const SelectColumn = (props: Props) => {
  const { gridItemId, actionType, isSelected, isDisabledSelect } = props

  let select = null

  switch (actionType) {
    case TabDTO.Actions.streetTurn:
      select = <StreetTurnSelect gridItemId={gridItemId} isSelected={isSelected} isDisabledSelect={isDisabledSelect} />
      break
    default:
      select = <div />
  }

  return (
    <TableCell>
      <div style={extraColumnStyles}>{select}</div>
    </TableCell>
  )
}

const extraColumnStyles = { width: 20 }
