import * as React from 'react'
import DatePickerComponent from 'react-datepicker'
import { dateService } from '../../../../../services/timeService/'
import cn from 'classnames'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'
import { stopPropagationEvent } from '../../../../../services/functions/basic'

export enum TimesOfDay {
  startDay = 'startDate',
  endDay = 'endDate',
  startWorkDay = 'startWorkDay'
}

type Props = {
  renderButtonComponent?: JSX.Element
  date: string
  onChange: (date: string) => void
  minDate?: Date
  maxDate?: Date
  excludeDates?: Date[]
  calendarPosition?: 'left' | 'right' | 'center'
  timesOfDay?: TimesOfDay
  applyButton?: true
}

export const DateTimePickerButton = React.memo((props: Props) => {
  const { renderButtonComponent, date, onChange, calendarPosition, timesOfDay, applyButton } = props
  const [dateToApply, setDateToApply] = React.useState<Date>(null)
  const { wrapperRef, statePopover, setPopoverState } = useOutsideClick()

  React.useEffect(() => {
    setDateToApply(null)
  }, [applyButton ? statePopover : undefined])

  const selectDatePickerDate = React.useCallback(
    (_date: Date) => {
      const isDateChanged = dateService.convertToServer(_date) !== date

      if (!isDateChanged) {
        return
      }

      if (!_date) {
        return _date
      }

      const _timesOfDay = (timesOfDay === TimesOfDay.endDay
        ? dateService.createStringDate.endDay
        : timesOfDay === TimesOfDay.startWorkDay
        ? dateService.createStringDate.startWorkDay
        : dateService.createStringDate.startDay
      ).split('T')[1]

      return onChange(dateService.convertToServer(_date).split('T')[0] + 'T' + _timesOfDay)
    },
    [onChange, date]
  )

  const handleChange = React.useCallback(
    (_date: Date) => {
      setPopoverState(_ => !_)
      selectDatePickerDate(_date)
    },
    [selectDatePickerDate, setPopoverState]
  )

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      setPopoverState(_ => !_)
    },
    [setPopoverState]
  )

  return (
    <Container
      ref={wrapperRef}
      className={cn({ 'active-calendar-popup': statePopover })}
      onClick={renderButtonComponent ? handleClick : undefined}
    >
      {renderButtonComponent || (
        <CalendarIconButton className={cn('mdi mdi-calendar-blank', { active: statePopover })} onClick={handleClick} />
      )}
      {statePopover && (
        <Popup className={calendarPosition} onClick={stopPropagationEvent}>
          <DatePickerComponent
            selected={dateToApply || dateService.convertFromServer(date)}
            onChange={applyButton ? setDateToApply : handleChange}
            inline={true}
            excludeDates={props.excludeDates}
            minDate={props.minDate}
            maxDate={props.maxDate}
          />
          {Boolean(dateToApply) && (
            <ApplyDateSection>
              <ApplyDateButton onClick={() => setPopoverState(false)}>Cancel</ApplyDateButton>
              <ApplyDateButton onClick={() => handleChange(dateToApply)}>Apply</ApplyDateButton>
            </ApplyDateSection>
          )}
        </Popup>
      )}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  display: inline-block;
`
export const CalendarIconButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3edfd;
  border-radius: 50%;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${theme.colors.basicBlueColor};
    color: white;
  }

  &.disabled {
    color: white;
    background-color: #98a4b4;
    opacity: 0.3;
    cursor: default;
  }
`
const Popup = styled.div`
  z-index: 99999;
  position: absolute;
  top: 100%;
  border-radius: 0.3rem;
  box-shadow: 0 2px 12px rgba(68, 83, 102, 0.3);

  > div {
    display: flex;
  }

  &.left {
    right: 0;
  }
  &.right {
    left: 0;
  }
  &.center {
    left: 50%;
    transform: translateX(-50%);
  }
`
const ApplyDateSection = styled.div`
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 4px 4px 4px 0;
`
const ApplyDateButton = styled.div`
  width: 50%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  margin-left: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #d5ddf0;
  }
`
