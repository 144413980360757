import { oc } from 'ts-optchain'
import { getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { ActivitiesViewDTO, DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { isDocumentationActivity } from '../test/isDocumentationActivity'

type Props = {
  acivityIds: string[]
  modifiedLists?: Partial<TListsState>
}

export const assembleActivities = (props: Props) => {
  const { acivityIds, modifiedLists } = props
  const storeLists = getListsState()
  const storeActivitiesMapping = storeLists[EntityType.activity]
  const storeLocationMapping = storeLists[EntityType.location]
  // const storeDriverMapping = storeLists[EntityType.driver]
  const modifiedActivitiesMapping = oc(modifiedLists)[EntityType.activity]({})
  const modifiedLocationsMapping = oc(modifiedLists)[EntityType.location]({})
  const activities: ActivitiesViewDTO = {
    transportationActivities: [],
    documentationActivities: []
  }
  const activityList = (acivityIds || [])
    .map(id => modifiedActivitiesMapping[id] || storeActivitiesMapping[id])
    .filter(Boolean)

  activityList.forEach(activity => {
    if (isDocumentationActivity(activity)) {
      activities.documentationActivities.push(activity as DocumentationActivityDTO)
    } else {
      const _activity = activity as TransportationActivityViewDTO
      // const vendorId = _activity.vendorId
      const destinationId = _activity.destinationId

      activities.transportationActivities.push({
        ..._activity,
        // vendor: storeDriverMapping[vendorId],
        destination: modifiedLocationsMapping[destinationId] || storeLocationMapping[destinationId]
      })
    }
  })

  return activities
}
