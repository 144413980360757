import {
  TransportationActivityViewDTO,
  OngoingActivityDTO,
  DocumentationActivityDTO,
  DeliveryOrderViewDTO
} from '../../../api/origin/business-logic'
import theme from '../../../styles/theme'

const phases = {
  [OngoingActivityDTO.PhaseEnum.PLANNED]: 'Planned',
  [OngoingActivityDTO.PhaseEnum.INPROCESS]: 'Start',
  [OngoingActivityDTO.PhaseEnum.COMPLETED]: 'Complete',
  [OngoingActivityDTO.PhaseEnum.UNSUCCESSFUL]: 'Unsuccessful'
}

const shortStatus = {
  [TransportationActivityViewDTO.StatusEnum.NEW]: 'Not Assigned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: 'Planned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: 'Assigned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: 'Confirmed',
  [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]: 'Refused',
  [TransportationActivityViewDTO.StatusEnum.INPROCESS]: 'In-Process',
  [TransportationActivityViewDTO.StatusEnum.COMPLETED]: 'Completed',
  [TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]: 'Unsuccessful'
}

const status = {
  [TransportationActivityViewDTO.StatusEnum.NEW]: 'Not Assigned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: 'Driver Planned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: 'Driver Assigned',
  [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: 'Driver Confirmed',
  [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]: 'Driver Refused',
  [TransportationActivityViewDTO.StatusEnum.INPROCESS]: 'In-Process',
  [TransportationActivityViewDTO.StatusEnum.COMPLETED]: 'Completed',
  [TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]: 'Unsuccessful'
}

const statusColor = {
  [TransportationActivityViewDTO.StatusEnum.NEW]: '#9BA8B8',
  [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: '#EBCD33',
  [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: '#7B61FF',
  [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: '#7CCF81',
  [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]: '#F5A85F',
  [TransportationActivityViewDTO.StatusEnum.INPROCESS]: '#B947FF',
  [TransportationActivityViewDTO.StatusEnum.COMPLETED]: '#9BA8B8',
  [TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]: theme.colors.defaultRed
}

const stage = {
  [TransportationActivityViewDTO.StageEnum.PICKUP]: 'Pickup',
  [TransportationActivityViewDTO.StageEnum.DELIVERY]: 'Delivery',
  [TransportationActivityViewDTO.StageEnum.RETURN]: 'Return'
}

const stageColor = {
  [TransportationActivityViewDTO.StageEnum.PICKUP]: '#6973F6',
  [TransportationActivityViewDTO.StageEnum.DELIVERY]: '#A97DD8',
  [TransportationActivityViewDTO.StageEnum.RETURN]: '#3FB68E'
}
const stageStrongColor = {
  [TransportationActivityViewDTO.StageEnum.PICKUP]: '#4459C9',
  [TransportationActivityViewDTO.StageEnum.DELIVERY]: '#7A31DA',
  [TransportationActivityViewDTO.StageEnum.RETURN]: '#3D8E5E'
}

const type = {
  [TransportationActivityViewDTO.TypeEnum.GOTO]: 'En Route to',
  [TransportationActivityViewDTO.TypeEnum.EMPTY]: 'Bobtail En Route to',
  [TransportationActivityViewDTO.TypeEnum.OFFDUTY]: 'Off Duty',
  [TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO]: 'Bobtail En Route to',
  [TransportationActivityViewDTO.TypeEnum.DROPCHASSIS]: 'Drop Chassis',
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS]: 'Drop Empty w/ Chassis',
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS]: 'Drop Empty w/o Chassis',
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS]: 'Drop Full w/ Chassis',
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS]: 'Drop Full w/o Chassis',
  [TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY]: 'Pick-up Empty',
  [TransportationActivityViewDTO.TypeEnum.PICKUPFULL]: 'Pick-up Full',
  [TransportationActivityViewDTO.TypeEnum.GETUNLOADED]: 'Get Unloaded',
  [TransportationActivityViewDTO.TypeEnum.GETLOADED]: 'Get Loaded',
  [TransportationActivityViewDTO.TypeEnum.PICKCHASSIS]: 'Pick Chassis',
  [TransportationActivityViewDTO.TypeEnum.SCALETARE]: 'Scale - Tare',
  [TransportationActivityViewDTO.TypeEnum.SCALEFULL]: 'Scale - Full'
}

const documentationType = {
  [DocumentationActivityDTO.TypeEnum.STREETTURN]: 'Street Turn'
}

const documentationStatus = {
  [DocumentationActivityDTO.StatusEnum.SUBMITTED]: 'Submitted',
  [DocumentationActivityDTO.StatusEnum.APPROVED]: 'Approved',
  [DocumentationActivityDTO.StatusEnum.REJECTED]: 'Rejected'
}

const deliveryOrderType = {
  [DeliveryOrderViewDTO.TypeEnum.IMPORT]: 'IM',
  [DeliveryOrderViewDTO.TypeEnum.EXPORT]: 'EXP',
  [DeliveryOrderViewDTO.TypeEnum.REPOSITION]: 'REPO'
}

export const activityDirectory = {
  type,
  stage,
  stageColor,
  stageStrongColor,
  shortStatus,
  status,
  phases,
  documentationType,
  documentationStatus,
  deliveryOrderType,
  statusColor
}
