import * as React from 'react'
import cn from 'classnames'
import { Logo } from './Logo'
import { Account } from './Account'
import { CreateEntity } from './CreateEntity'
import { Collection } from './Collection'
import {
  Block,
  CollectionContainer,
  CollectionMenuButton,
  CollectionMenu,
  StyledHeader,
  TextBold
} from './styledComponents'
import { ActionButton } from '../../UI/Buttons/ActionButton'
import { Accounting } from '../../pages/AccountingPage'
import { Notifications } from '../../UI/Notification/view'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { CommunicationHubButtons } from './CommunicationHubButtons'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const Header = (props: Props) => {
  const [accountingPopup, setAccountingPopupState] = React.useState(false)
  const { wrapperRef, statePopover, togglePopover, setPopoverState } = useOutsideClick()
  const closeMenu = React.useCallback(() => setPopoverState(false), [setPopoverState])
  const list = [
    TabDTO.Type.dispatchDeliveryOrder,
    TabDTO.Type.scheduler,
    TabDTO.Type.QMP,
    // TabDTO.Type.locationGroup,
    TabDTO.Type.powerUnit,
    TabDTO.Type.equipment,
    TabDTO.Type.container,
    TabDTO.Type.location,
    TabDTO.Type.driver,
    TabDTO.Type.customer,
    TabDTO.Type.steamShipLine,
    TabDTO.Type.subClient
  ]

  return (
    <>
      <StyledHeader>
        <Block>
          <Logo />
          <TextBold>Intermodal</TextBold>
        </Block>

        <CollectionContainer ref={wrapperRef} className={cn({ active: statePopover })}>
          <CollectionMenuButton className={'mdi mdi-menu-down'} onClick={togglePopover}>
            Menu
          </CollectionMenuButton>
          <CollectionMenu>
            {list.map(tabType => (
              <Collection key={tabType} tabType={tabType} closeMenu={closeMenu} />
            ))}
          </CollectionMenu>
        </CollectionContainer>

        <ActionButton
          className={'header-accounting-button'}
          styles={{ height: 30, margin: 'auto 0 auto 12px' }}
          filled={true}
          onClick={() => setAccountingPopupState(true)}
        >
          Accounting
        </ActionButton>

        <Block style={{ marginLeft: 'auto' }}>
          {/*<SearchBar active={this.state.searchBar} toggleSearchBar={this.toggleSearchBar} />*/}
          <Notifications />
          <CreateEntity />
          <Account />
          <CommunicationHubButtons />
        </Block>
      </StyledHeader>
      {accountingPopup && <Accounting closePopup={() => setAccountingPopupState(false)} />}
    </>
  )
}
