import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import styled from 'styled-components'
import { ActivityColumnWithLabel, DabbleColumn } from '../../../styles'
import { activityDirectory } from '../../../../../../../../../../services/DTO/activity/directory'
import { Select } from '../../../../../../../../../UI/DataFields/Select'
import {
  ActivityGroupAction,
  TransportationActivityRow
} from '../../../../../../../../../../services/DTO/activity/controller'
import { activityLists } from '../../../../../../../../../../services/select/activityLists'
import {
  DeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../../../../../../../../api/origin/business-logic'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import theme from '../../../../../../../../../../styles/theme'
import { isPickActivityType } from '../../../../../../../../../../services/functions/test/isPickActivity'
import {
  isDropActivity,
  isDropActivityType
} from '../../../../../../../../../../services/functions/test/isDropActivity'
// tslint:disable:max-line-length
import { getActivityDropBobtailGroup } from '../../../../../../../../../../services/functions/get/getActivityDropBobtailGroup'
import { openDDOinNewTab } from '../../../../../../../../../../services/DTO/dispatchDeliveryOrder'
import { AddBobtailButton } from '../../AddBobtailButton'
import { Note } from '../../../../../../../../../UI/DataFields/Note/Note'
import { useGridItemDDO } from '../../../../../../../../../../hooks/useGridItemDDO'

const hideTypesForRepo = [
  TransportationActivityViewDTO.TypeEnum.GETLOADED,
  TransportationActivityViewDTO.TypeEnum.GETUNLOADED
]

export const TypeActivityColumn = () => {
  const {
    isBobtail,
    isDropBobtail,
    isPickBobtail,
    isLinkedActivityToBobtail,
    isUnsuccessful,
    disabled,
    transportationRow,
    stageActivityRows,
    updateActivityRow,
    dispatchDeliveryOrder,
    rowNumber,
    stage,
    anotherDOOActivityLink,
    pickTypeActivityRowToCreateBobtail,
    dropTypeActivityRowToCreateBobtail
  } = useTransportationActivityRow()
  const { activities, isRepo } = useGridItemDDO()
  const noteTooltipId = 'activity-note-' + transportationRow.activityGroup.businessActivity.id
  const disableEditBusinessActivityStatus =
    transportationRow.activityGroup.businessActivity.type &&
    (disabled || isLinkedActivityToBobtail || transportationRow.activityGroup.businessActivity.template)

  const generateBobtailLabel = () => {
    const labelWords = activityDirectory.type[transportationRow.activityGroup.gotoActivity.type].split(' ')
    const firstWord = labelWords[0]
    const otherWords = labelWords.filter((w, i) => i !== 0).join(' ')
    return (
      <div>
        <div style={{ color: theme.colors.defaultRed }}>{firstWord}</div>
        {otherWords}
      </div>
    )
  }

  const RenderBobtailDDOlink = () => {
    let ddoId = oc(anotherDOOActivityLink).dispatchDeliveryOrderId()
    let ddoNumber = oc(anotherDOOActivityLink).dispatchDeliveryOrderNumber()

    if (isBobtail && isUnsuccessful) {
      ddoId = oc(transportationRow).activityGroup.gotoActivity.referenceId()
      ddoNumber = oc(transportationRow).activityGroup.gotoActivity.referenceNumber()
    }

    return (
      <DDOLink
        style={{
          marginLeft: 11,
          color:
            isUnsuccessful && isBobtail
              ? '#C9CDDD' // gray
              : isLinkedActivityToBobtail
              ? theme.colors.defaultRed
              : theme.colors.basicBlueColor
        }}
        onClick={() =>
          openDDOinNewTab({
            ddoId,
            ddoNumber
          })
        }
      >
        DDO #{ddoNumber}
      </DDOLink>
    )
  }

  return (
    <DabbleColumn className={'type-column'} style={{ border: 'none' }}>
      <AddBobtailButton bobtailType={'pick'} />
      <ActivityColumnWithLabel
        data-id={[
          'go-to-activity-type',
          transportationRow.activityGroup.gotoActivity.type,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={`border-bottom ${transportationRow.stage.toString()}`}
      >
        {isLinkedActivityToBobtail ? (
          <RenderBobtailDDOlink />
        ) : (
          <div className={'label'}>
            {isBobtail
              ? generateBobtailLabel()
              : activityDirectory.type[transportationRow.activityGroup.gotoActivity.type]}
          </div>
        )}
        <Note
          tooltipId={noteTooltipId}
          disabled={disabled}
          note={transportationRow.activityGroup.businessActivity.notes}
          maxLength={50}
          onChange={notes => {
            updateActivityRow(
              {
                ...transportationRow,
                activityGroup: {
                  ...transportationRow.activityGroup,
                  businessActivity: { ...transportationRow.activityGroup.businessActivity, notes }
                }
              },
              ActivityGroupAction.update
            )
          }}
        />
      </ActivityColumnWithLabel>
      <ActivityColumnWithLabel
        data-id={[
          'business-activity-type',
          transportationRow.activityGroup.businessActivity.type,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={transportationRow.stage.toString()}
      >
        {isDropBobtail ? (
          transportationRow.activityGroup.businessActivity.type === TransportationActivityViewDTO.TypeEnum.EMPTY &&
          (anotherDOOActivityLink || (isUnsuccessful && transportationRow.activityGroup.gotoActivity.referenceId)) ? (
            <RenderBobtailDDOlink />
          ) : (
            <OffDutyLabel
              className={'label'}
              style={{
                color:
                  transportationRow.activityGroup.businessActivity.type ===
                  TransportationActivityViewDTO.TypeEnum.OFFDUTY
                    ? undefined
                    : '#C9CDDD'
              }}
            >
              {!disabled ||
              transportationRow.activityGroup.businessActivity.type === TransportationActivityViewDTO.TypeEnum.OFFDUTY
                ? 'Off Duty'
                : ''}
            </OffDutyLabel>
          )
        ) : disableEditBusinessActivityStatus ? (
          <div className={'label'}>{activityDirectory.type[transportationRow.activityGroup.businessActivity.type]}</div>
        ) : (
          <Select
            required={true}
            mapping={activityLists.type}
            list={
              isPickBobtail
                ? activityLists.pickBobtailBusinessType
                : isRepo
                ? activityLists.businessType.filter(item => !hideTypesForRepo.includes(item.value))
                : activityLists.businessType
            }
            selectedValue={transportationRow.activityGroup.businessActivity.type}
            onSelect={type => {
              const updatedRow = R.clone(transportationRow)
              const { gotoActivity, businessActivity } = updatedRow.activityGroup

              gotoActivity.comboType = type
              businessActivity.type = type
              businessActivity.comboType = type

              if (isPickBobtail && !isPickActivityType(type)) {
                gotoActivity.type = TransportationActivityViewDTO.TypeEnum.GOTO
              }

              updateActivityRow(updatedRow, ActivityGroupAction.update)

              if (isDropActivity(transportationRow.activityGroup.gotoActivity) && !isDropActivityType(type)) {
                const dropActivityBobtails = getActivityDropBobtailGroup({
                  activity: transportationRow.activityGroup.gotoActivity,
                  activities: activities.transportationActivities
                })

                if (dropActivityBobtails.length) {
                  const rowsToDelete = (stageActivityRows as TransportationActivityRow[])
                    .filter(row => dropActivityBobtails.some(group => oc(group).gotoActivity.id() === row.id))
                    .filter(Boolean)

                  if (rowsToDelete.length) {
                    updateActivityRow(rowsToDelete, ActivityGroupAction.remove)
                  }
                }
              }
            }}
          />
        )}
      </ActivityColumnWithLabel>
      <AddBobtailButton bobtailType={'drop'} />
    </DabbleColumn>
  )
}

const OffDutyLabel = styled.div`
  padding: 11px;
  user-select: none;
  cursor: default;
`

const DDOLink = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.colors.basicBlueColor};
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
