import * as React from 'react'
import Input from '../'

const transformPhone = (str: string | null): string | null =>
  str
    ? str
        .split('')
        .filter(value => value !== ' ' && value !== '(' && value !== ')' && value !== '-')
        .join('')
    : null

type Props = {
  noBorder?: boolean
  value?: string
  tabIndex?: number
  height?: number
  placeholder?: string
  disabled?: boolean
  isColumn?: boolean
  darkBackground?: boolean
  title?: string
  required?: boolean
  onChange(value: string): void
}

export const isValidPhone = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  if (value.length === 10) {
    return true
  }

  return false
}

const Phone = (props: Props) => (
  <Input
    {...props}
    type={'tel'}
    onChange={value => props.onChange(transformPhone(value))}
    valid={isValidPhone(props.value)}
    textMask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    onCopy={e => {
      const result = transformPhone(props.value)
      e.clipboardData.setData('text/plain', result)
      e.preventDefault()
    }}
  />
)

export default Phone
