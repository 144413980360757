import actionCreatorFactory from 'typescript-fsa'
import { TModalButtonSettings } from '../index'

export enum TMsgType {
  delete = 'delete',
  info = 'info',
  combine = 'combine',
  warning = 'warning'
}

const actionCreator = actionCreatorFactory('MODAL')

export const showModal = actionCreator<{
  type?: string
  message: string
  saveLabel?: string
  msgType: TMsgType
  buttonSettings?: TModalButtonSettings
  onConfirm?: () => void
  onCancel?: () => void
}>('SHOW')

export const closeModal = actionCreator('CLOSE')
