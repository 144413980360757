export enum SubClientColumn {
  Name = 'Name',
  Address = 'Address',
  Customer = 'Customer',
  PrimaryContact = 'Primary Contact'
}

export const subClientColumnSettings = {
  [SubClientColumn.Name]: { width: 160, active: true },
  [SubClientColumn.Address]: { width: 260, active: true },
  [SubClientColumn.Customer]: { width: 200, active: true },
  [SubClientColumn.PrimaryContact]: { width: 160, active: true }
}
