import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../../../api/origin/business-logic'
import { Widget } from '../../'
import { Popover } from './popover'
import { Preview } from './preview'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import { showModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { checkValidation } from '../../../../../services/DTO/validation'
import { isGotoActivity } from '../../../../../services/functions/test/isGotoActivity'
import { dispatchDeliveryOrderStatusIndex } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useDriver } from '../../../../../hooks/useDriver'

type Props = {}

const Activity = (props: Props) => {
  const expandedItem = useExpandedItem()
  const { saveUpdatedEntities } = expandedItem
  const gridItemDDOProviderData = useGridItemDDO()
  const {
    dispatchDeliveryOrder,
    activities,
    currentActivityGroup,
    actualDDOStatus,
    cancelDispatchDeliveryOrder
  } = gridItemDDOProviderData
  const saveCanceldDdoRef = React.useRef(null)
  let currentActivity = undefined
  let activityTypeOrDDOStatus = ddoDirectory.status[actualDDOStatus]

  saveCanceldDdoRef.current = (options?: any) => {
    saveUpdatedEntities({ gridItemDDOProviderData, retrySave: saveCanceledDdo, options: options || {} })
  }

  const saveCanceledDdo = (options?: any) => {
    setTimeout(() => saveCanceldDdoRef.current(options))
  }

  if (currentActivityGroup) {
    currentActivity = [currentActivityGroup.businessActivity, currentActivityGroup.gotoActivity].find(
      activity =>
        activity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )

    if (currentActivity) {
      activityTypeOrDDOStatus = isGotoActivity(currentActivity)
        ? activityDirectory.type[currentActivity.type].slice(0, -2) +
          activityDirectory.type[currentActivityGroup.businessActivity.type]
        : activityDirectory.type[currentActivity.type]
    }
  }

  const currentActivityVendor = useDriver({ id: oc(currentActivity).vendorId() })
  const windowProps = {
    title: currentActivityGroup ? 'Current Activity' : 'DDO Status',
    activityTypeOrDDOStatus,
    activityVendor: oc(currentActivityVendor).name(''),
    titleAndIconColor: currentActivity
      ? activityDirectory.stageColor[currentActivity.stage]
      : ddoDirectory.statusColor[actualDDOStatus]
  }

  const showCancelButton: boolean = Boolean(
    actualDDOStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED &&
      !dispatchDeliveryOrder.isAcceptRejectRequired &&
      !dispatchDeliveryOrder.troubleTicketId &&
      (dispatchDeliveryOrderStatusIndex[actualDDOStatus] <=
        dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH] ||
        oc(activities)
          .transportationActivities([])
          .every(_ => _.status !== TransportationActivityViewDTO.StatusEnum.INPROCESS))
  )

  return (
    <Widget
      title={windowProps.title}
      fixedWidth={true}
      bottomActions={
        showCancelButton
          ? [
              {
                text: 'Cancel DDO',
                onClick: () => {
                  if (!checkValidation.dispatchDeliveryOrder(expandedItem)) {
                    return showModalWindow({
                      width: 320,
                      title: 'DDO is not valid',
                      content: 'Fill in all the fields correctly to save the DDO'
                    })
                  }

                  showModalWindow({
                    width: 365,
                    title: 'Are you sure you want to Cancel DDO?',
                    buttons: [
                      {
                        label: 'No'
                      },
                      {
                        label: 'Yes',
                        onClick: () => {
                          cancelDispatchDeliveryOrder()
                          saveCanceledDdo()
                        }
                      }
                    ]
                  })
                },
                isCloseButton: true,
                style: { backgroundColor: '#ff5e5e', borderColor: '#ff5e5e' }
              }
            ]
          : true
      }
      RenderPreview={Preview}
      RenderDetails={Popover}
      previewProps={{
        windowProps,
        currentActivityStatus: oc(currentActivity).status()
      }}
      detailsProps={{ windowProps, currentActivity }}
    />
  )
}

export default Activity
