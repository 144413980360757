import { ILocationGroup, LocationGroupType } from '../../../components/common/locationGroup/interfaces'
import { createId } from '../../utils'

export const generateLocationGroup = (props?: Partial<ILocationGroup>): ILocationGroup => {
  return {
    id: createId(),
    name: '',
    type: LocationGroupType.POSTAL_CODE,
    ids: [],
    ...(props || {})
  }
}
