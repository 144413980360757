import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { CustomerQuoteDTO } from '../../../../../../../../api/origin/qmp-service'
import {
  DeliveryOrderViewDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../../../../../../../../api/origin/business-logic'
import { AddButton, MakeShuttleButton, Title } from './styles'
import { TransportationRow } from './Row/TransportationActivityRow'
import { DocumentationRow } from './Row/DocumentationActivityRow'
import {
  doActionAndMakeGeneralActivityList,
  DocumentationActivityRow,
  getActivityListData,
  makeShuttle,
  pushNewActivityGroupInStage,
  TransportationActivityRow
} from '../../../../../../../../services/DTO/activity/controller'
import { activityDirectory } from '../../../../../../../../services/DTO/activity/directory'
import {
  checkDates,
  CorrectActivityData,
  findActivityWithWrongOrderOfPlannedDate
} from '../../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { checkTransportationActivities } from './Row/Column/functions'
import { SelectShuttlePopup } from './SelectShuttlePopup'
import { TLocation } from '../../../../../../location/interfaces'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../../../../../services/debug'
import { ActivityGroup } from '../../../../../../../../services/DTO/activity/interfaces'
import { getAllChannelDocumentMessages } from '../../../../../../../CommunicationHub/epics'
// tslint:disable:max-line-length
import { TransportationActivityRowProvider } from '../../../../../../../../providers/TransportationActivityRowProvider'
import { isDDOStatusCancelled } from '../../../../../../../../services/functions/test/isDDOStatusCancelled'
import { useExpandedItem } from '../../../../../../../../hooks/useExpandedItem'
import { TabExpandedItemDataProp } from '../../../../../../../../store/reducers/tabs/interfaces'
import { getRelatedActivityType } from '../../../../../../../../services/functions/get/getRelatedActivityType'
import { useGridItemDDO } from '../../../../../../../../hooks/useGridItemDDO'
import { getNeededActivityTypesByStages } from '../../../../../../../../services/functions/get/getNeededActivityTypesByStages'

type Props = {
  allowModifying: boolean
  customerQuote?: CustomerQuoteDTO
}

export const DDOActivityTableBody = (props: Props) => {
  const [stageShuttle, setStageShuttle] = React.useState(null)
  let stagesInCorrectOrder = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]
  const { customerQuote, allowModifying } = props
  const {
    dispatchDeliveryOrder,
    deliveryOrder,
    deliveryOrderType,
    quoteAmounts,
    activities,
    activityGroups,
    pickupLocation,
    deliveryLocation,
    returnLocation,
    modifyDispatchDeliveryOrder,
    modifyActivityData
  } = useGridItemDDO()
  const { setFetching, isModified, data, mergeDataProps } = useExpandedItem()
  const activityGroupListData = getActivityListData({ dispatchDeliveryOrder, deliveryOrder, activityGroups })
  const isExport = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const relatedTemplateActivity = isExport
    ? getRelatedActivityType({ transportationActivities: activities.transportationActivities, phaseType: 'return' })
    : getRelatedActivityType({ transportationActivities: activities.transportationActivities, phaseType: 'pickup' })
  const activityDocumentPopup = oc(data)[TabExpandedItemDataProp.activityDocumentPopup]()
  const activityDocumentMessages = oc(data)[TabExpandedItemDataProp.activityDocumentMessages]()
  const checkedDates = checkDates({ dispatchDeliveryOrder, deliveryOrder })

  if (isRepo) {
    stagesInCorrectOrder = stagesInCorrectOrder.filter(
      stage => stage !== TransportationActivityViewDTO.StageEnum.DELIVERY
    )
  }

  const handleRowClick = React.useCallback(
    (activityRow: ActivityGroup) => (event: React.MouseEvent) => {
      if (isShowDetailsOnClick(event)) {
        showDetailsOnClick({
          title: 'Activity Group',
          data: activityRow,
          blink: { class: 'activity-group-row', event }
        })
      }
    },
    []
  )

  const activityWithWrongOrderOfPlannedDates = findActivityWithWrongOrderOfPlannedDate(
    activities.transportationActivities
  )

  const nededActivityTypesByStages = getNeededActivityTypesByStages({
    dispatchDeliveryOrder,
    deliveryOrderType,
    activityGroups,
    strict: true
  })

  React.useEffect(() => {
    let isExpiredData = false

    getAllChannelDocumentMessages(dispatchDeliveryOrder.id)
      .then(updatedData => {
        if (!isExpiredData) {
          mergeDataProps({ activityDocumentMessages: updatedData })
        }
      })
      .catch(() => {})

    return () => {
      isExpiredData = true
    }
  }, [])

  return (
    <Container data-tip={true} data-for={'canceled-ddo'}>
      {stagesInCorrectOrder.map(stage => {
        const isStageExtendable = activityGroupListData.stages[stage].permissions.extendable
        const needTemplateActivityTypesToCreate: TransportationActivityViewDTO.TypeEnum[] =
          allowModifying && isStageExtendable ? nededActivityTypesByStages[stage] : []

        return (
          <React.Fragment key={stage}>
            <Title>
              {activityDirectory.stage[stage]}
              {allowModifying && (
                <>
                  {isStageExtendable && (
                    <AddButton
                      className={`mdi mdi-plus-circle${needTemplateActivityTypesToCreate.length ? '' : '-outline'}`}
                      onClick={() =>
                        modifyActivityData(
                          pushNewActivityGroupInStage(activityGroupListData, stage, {
                            neededTypes: needTemplateActivityTypesToCreate,
                            dispatchDeliveryOrder,
                            pickupLocation,
                            deliveryLocation,
                            returnLocation
                          })
                        )
                      }
                    />
                  )}

                  {activityGroupListData.stages[stage].permissions.shuttleAbility && (
                    <MakeShuttleButton onClick={() => setStageShuttle(stage)}>Shuttle</MakeShuttleButton>
                  )}
                </>
              )}
            </Title>
            {activityGroupListData.stages[stage].activityRows.map((activityRow, index, stageActivityRows) => {
              let transportationRow: TransportationActivityRow
              let documentationRow: DocumentationActivityRow

              if ((activityRow as TransportationActivityRow).activityGroup.gotoActivity) {
                transportationRow = activityRow as TransportationActivityRow
              } else if ((activityRow as DocumentationActivityRow).activityGroup.documentationActivity) {
                documentationRow = activityRow as DocumentationActivityRow
              }

              return transportationRow ? (
                <TransportationActivityRowProvider
                  key={transportationRow.id}
                  rowNumber={index + 1}
                  dispatchDeliveryOrder={dispatchDeliveryOrder}
                  transportationRow={transportationRow}
                  isModifiedMode={isModified}
                  allowModifying={allowModifying}
                  activityDocumentPopup={activityDocumentPopup}
                  activityDocumentMessages={activityDocumentMessages}
                  rows={stagesInCorrectOrder.reduce((acc, currStage) => {
                    return acc.concat(activityGroupListData.stages[currStage].activityRows)
                  }, [])}
                  usefulRows={activityGroupListData.usefulRows}
                  stageActivityRows={stageActivityRows}
                  lastUnsuccessfulActivityGroup={activityGroupListData.lastUnsuccessfulActivityGroup}
                  currentActivityGroup={activityGroupListData.currentActivityGroup}
                  importDatesConfused={
                    relatedTemplateActivity &&
                    relatedTemplateActivity.id === transportationRow.activityGroup.businessActivity.id &&
                    !isExport &&
                    checkedDates
                  }
                  exportDatesConfused={
                    relatedTemplateActivity &&
                    relatedTemplateActivity.id === transportationRow.activityGroup.businessActivity.id &&
                    isExport &&
                    checkedDates
                  }
                  activityWithWrongOrderOfPlannedDates={activityWithWrongOrderOfPlannedDates}
                  modifyDispatchDeliveryOrder={modifyDispatchDeliveryOrder}
                  // @ts-ignore
                  updateActivityRow={(updatedActivityGroup, action) => {
                    const arrayToCheck = Array.isArray(updatedActivityGroup)
                      ? updatedActivityGroup
                      : [updatedActivityGroup]
                    arrayToCheck.forEach(group => checkTransportationActivities(group.activityGroup))

                    return Array.isArray(updatedActivityGroup)
                      ? modifyActivityData(
                          doActionAndMakeGeneralActivityList({
                            activities,
                            dispatchDeliveryOrder,
                            deliveryOrderType,
                            activityGroupListData,
                            updatedActivityGroups: updatedActivityGroup
                          })(action)
                        )
                      : modifyActivityData(
                          doActionAndMakeGeneralActivityList({
                            activities,
                            dispatchDeliveryOrder,
                            deliveryOrderType,
                            activityGroupListData,
                            updatedActivityGroup
                          })(action)
                        )
                  }}
                >
                  <TransportationRow
                    handleRowClick={handleRowClick(transportationRow.activityGroup)}
                    customerQuote={customerQuote}
                    buySideQuotesAmount={quoteAmounts.buySideQuotesAmount}
                    setFetching={setFetching}
                  />
                </TransportationActivityRowProvider>
              ) : documentationRow ? (
                <DocumentationRow
                  key={documentationRow.id}
                  handleRowClick={handleRowClick(documentationRow.activityGroup)}
                  dispatchDeliveryOrder={dispatchDeliveryOrder}
                  documentationRow={documentationRow}
                />
              ) : null
            })}
          </React.Fragment>
        )
      })}
      {stageShuttle && (
        <SelectShuttlePopup
          clearState={() => setStageShuttle(null)}
          shuttleAction={(destination: TLocation) =>
            modifyActivityData(
              makeShuttle({
                ddoLocationIds: {
                  pickupStage: oc(dispatchDeliveryOrder).pickupStage.locationId(),
                  deliveryStage: oc(dispatchDeliveryOrder).deliveryStage.locationId(),
                  returnStage: oc(dispatchDeliveryOrder).returnStage.locationId()
                },
                deliveryOrderType,
                activityGroupListData,
                destination,
                stage: stageShuttle,
                loadType: dispatchDeliveryOrder.loadType
              })
            )
          }
        />
      )}
      {isDDOStatusCancelled(dispatchDeliveryOrder.status) && (
        <ReactTooltip backgroundColor={'#8CA2AB'} className={'react-tooltip'} id={'canceled-ddo'}>
          <span>You need to return DDO to active status</span>
        </ReactTooltip>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  min-width: 100%;
  flex-direction: column;
  margin-top: -20px;
`
