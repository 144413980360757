import * as React from 'react'
import { TextLine } from '../PreviewPhase'
import theme from '../../../../../../../../styles/theme'
import { ActivityNotesButton } from './ActivityNotesButton'

type Props = {
  driverName: string
  activityStatusText: JSX.Element | string
  isExtraActivity: boolean
  hasActivityNotes: boolean
  isUnsuccessful: boolean
  isActive: boolean
  isPending: boolean
  isAssigned: boolean
}

export const TwoActivitiesView = React.memo((props: Props) => {
  const {
    driverName,
    isExtraActivity,
    hasActivityNotes,
    activityStatusText,
    isUnsuccessful,
    isActive,
    isPending,
    isAssigned
  } = props
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        fontSize: 11,
        fontWeight: isExtraActivity ? 500 : undefined
      }}
    >
      <TextLine style={{ alignSelf: 'center' }}>{driverName}</TextLine>
      {isExtraActivity && (
        <TextLine
          style={{
            color: isUnsuccessful ? theme.colors.defaultRed : undefined,
            minWidth: 55,
            paddingLeft: 5,
            marginLeft: 'auto'
          }}
        >
          {activityStatusText}
        </TextLine>
      )}
      {hasActivityNotes && <ActivityNotesButton active={isActive || isPending || isAssigned} />}
    </div>
  )
})
