import * as React from 'react'
import { Grid } from './pages/Grid'
import { SchedulerPage } from './pages/SchedulerPage'
import { DeliveryOrderPage } from './pages/DeliveryOrderPage'
import { QMPPage } from './pages/QMPPage'
import { TabDTO } from '../store/reducers/tabs/interfaces'
import { useAppSelector } from '../hooks/useAppSelector'
import { LocationGroupPage } from './pages/LocationGroupPage'
import { selectActiveApplicationTabId, selectActiveApplicationTabType } from '../store/select/applicationTabSelect'

export const Router = React.memo(() => {
  const tabId = useAppSelector(selectActiveApplicationTabId)
  const tabType = useAppSelector(selectActiveApplicationTabType)

  if (!tabId) {
    return null
  }

  switch (tabType) {
    case TabDTO.Type.scheduler:
      return <SchedulerPage key={tabId} />
    case TabDTO.Type.QMP:
      return <QMPPage key={tabId} />
    case TabDTO.Type.deliveryOrder:
      return <DeliveryOrderPage key={tabId} />
    case TabDTO.Type.locationGroup:
      return <LocationGroupPage key={tabId} />
    default:
      return <Grid key={tabId} />
  }
})
