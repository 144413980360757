import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderSearch } from '../../../../../UI/DataFields/SearchRequest'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { SchedulerFilter } from '../../../interfaces'
import { getStore } from '../../../../../../store/configureStore'
import { ITab } from '../../../../../../store/reducers/tabs/interfaces'
import { updateTab } from '../../../../../../store/reducers/tabs/functions'

type OwnProps = {
  tab: ITab
  dispatchDeliveryOrderNumber: number
  dispatchDeliveryOrderId: string
}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const SelectDispatchDeliveryOrder = React.memo((props: Props) => {
  const { tab, dispatchDeliveryOrderNumber, dispatchDeliveryOrderId } = props

  return (
    <FieldContainer style={{ width: 150, marginRight: 4 }} title={'Search'}>
      <DispatchDeliveryOrderSearch
        placeholder={'DDO#'}
        value={{ id: dispatchDeliveryOrderId, number: dispatchDeliveryOrderNumber }}
        onChange={(ddoParams: { id: string; number: string }) => {
          const changed = dispatchDeliveryOrderId !== oc(ddoParams).id()

          if (!changed) {
            return
          }

          const { dispatch } = getStore()
          let updatedFilter = oc(tab)
            .uiSettings.filter([])
            .filter(
              _ =>
                _.column !== SchedulerFilter.dispatchDeliveryOrderNumber &&
                _.column !== SchedulerFilter.dispatchDeliveryOrderId
            )

          if (ddoParams) {
            updatedFilter = updatedFilter.concat([
              {
                column: SchedulerFilter.dispatchDeliveryOrderNumber,
                value: Number(ddoParams.number)
              },
              {
                column: SchedulerFilter.dispatchDeliveryOrderId,
                value: ddoParams.id
              }
            ])
          }

          updateTab(tab.id, {
            data: {
              showMissed: Boolean(ddoParams),
              ...(ddoParams ? { showNow: true } : {})
            },
            uiSettings: {
              filter: updatedFilter
            }
          })
        }}
      />
    </FieldContainer>
  )
})
