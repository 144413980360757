import * as React from 'react'
import { oc } from 'ts-optchain'
import { Container, GridColumn } from '../../../../UI/GridSystem'
import { Row } from '../styledComponents'
import { Delete } from '../../../../UI/styledComponents'
import { InputField, Input } from '../../../../UI/DataFields/Input'
import { Checkbox } from '../../../../UI/Checkbox'
import { ContactDTO } from '../../../../../api/api'
import { useContactsTab } from '../../../../../hooks/useContactsTab'

type Props = {
  contact: ContactDTO
}

export const Contact = React.memo((props: Props) => {
  const { contact } = props
  const { updateContactField, deleteContactById } = useContactsTab()

  return (
    <Row rows={9} margin={{ bottom: 10 }}>
      <Container columns={3} isFixed={true}>
        <GridColumn columns={2} isFixed={true} justifyCenter={true}>
          <Checkbox
            checked={oc(contact).primary(false)}
            onChange={() => updateContactField(contact)('primary')(!oc(contact).primary(false))}
          />
        </GridColumn>
      </Container>
      <Container columns={11} isFixed={true}>
        <Input
          placeholder={'First Name'}
          value={oc(contact).firstName('')}
          onChange={updateContactField(contact)('firstName')}
        />
      </Container>
      <Container columns={11} isFixed={true}>
        <Input
          placeholder={'Last Name'}
          value={oc(contact).lastName('')}
          onChange={updateContactField(contact)('lastName')}
        />
      </Container>
      <Container columns={9} isFixed={true}>
        <InputField.PhoneNumber
          placeholder={'Phone'}
          value={oc(contact).phone('')}
          onChange={updateContactField(contact)('phone')}
        />
      </Container>
      <Container columns={9} isFixed={true}>
        <InputField.PhoneNumber
          placeholder={'Phone2'}
          value={oc(contact).phone2('')}
          onChange={updateContactField(contact)('phone2')}
        />
      </Container>
      <Container columns={11} isFixed={true}>
        <InputField.Email
          placeholder={'Email'}
          value={oc(contact).email('')}
          onChange={updateContactField(contact)('email')}
        />
      </Container>
      <Container columns={9} isFixed={true}>
        <InputField.PhoneNumber
          placeholder={'Fax'}
          value={oc(contact).fax('')}
          onChange={updateContactField(contact)('fax')}
        />
      </Container>
      <Container columns={9}>{oc(contact).notes('')}</Container>
      <Delete className="mdi mdi-delete" columns={2} isFixed={true} onClick={() => deleteContactById(contact.id)} />
    </Row>
  )
})
