import { RootState } from '..'
import { IDriverPositionDTO } from '../../services/DTO/driverPosition/interfaces'

// SELECT ONE
export const selectDriverPosition = (vendorUserId: string) => (state: RootState): IDriverPositionDTO | undefined => {
  const {
    lists: { driverPosition, driverIdByAuthId }
  } = state

  if (driverPosition[vendorUserId]) {
    return driverPosition[vendorUserId]
  }

  const driverId = driverIdByAuthId[vendorUserId]

  return driverId && driverPosition[driverId]
}
