import { oc } from 'ts-optchain'
import { NotificationDTO } from '../../../api/origin/communication-hub-service'
import { getDispatch, getNewStoreState } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { ActiveChatChannelIdSpecialTypes } from '../../../store/reducers/communicationHub/interfaces'
import { WebsocketEvent } from '../../../services/websocket/interfaces'

export const handleReceivedNotification = (notification: NotificationDTO, websocketEvent: WebsocketEvent) => {
  const {
    communicationHub: { activeChannel },
    user
  } = getNewStoreState()
  const isStatusCreated = websocketEvent === WebsocketEvent.CREATED

  if (
    oc(activeChannel).id() !== ActiveChatChannelIdSpecialTypes.NOTIFICATIONS ||
    (user.id === notification.senderUserId && isStatusCreated)
  ) {
    return
  }

  getDispatch()(communicationHubActions.pushNotificationToStorage(notification))
}
