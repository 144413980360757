import { getItemFromStorage, StorageKeys } from '../services/storageService/storageService'

export const getDefaultHeaders = () => {
  let headers = {}
  const accessToken = getItemFromStorage(StorageKeys.AccessToken)
  if (accessToken) {
    headers = { Authorization: `Bearer ${accessToken}`, ...headers }
  }
  return headers
}
