import { oc } from 'ts-optchain'
import { IMoveDTO, IMoveItemDTO, MoveStatus } from '../../store/reducers/communicationHub/interfaces'
import { filterLatestObjects } from './functions'
import { dateService } from '../timeService'
import { requestDispatchDeliveryOrdersByIds } from '../../components/common/dispatchDeliveryOrder/epics'
import {
  getRouteBuilderMoveDate,
  isRouteBuilderNextDayMove,
  isRouteBuilderPrevDayMove
} from '../../hooks/useRouteBuilderNextToDayMove'
import { getCommunicationHubState, getDispatch, getListsState, getNewStoreState } from '../../store'
import { communicationHubActions } from '../../store/reducers/communicationHub'
import { EntityType } from '../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../store/reducers/lists/functions/pushListItemsToStore'
import { isFullObject } from '../functions/test/isFullObject'

export const movesAction = async ({
  addItems,
  updateItems,
  deleteItems
}: {
  addItems: IMoveDTO[]
  updateItems: IMoveDTO[]
  deleteItems: string[]
}) => {
  try {
    const {
      lists,
      communicationHub: { activeRouteBuilder }
    } = getNewStoreState()
    const { dispatchDeliveryOrder } = getListsState()

    const routeBuilderVendorId = oc(activeRouteBuilder).vendorId()
    const allMoves: IMoveDTO[] = addItems.concat(updateItems)
    const listMovesToPush: IMoveDTO[] = []
    const listMovesToDelete: string[] = deleteItems.filter(id => lists.move[id])

    allMoves.forEach(move => {
      if (!move.vendorId || move.status === MoveStatus.COMPLETED || move.status === MoveStatus.UNSUCCESSFUL) {
        if (lists.move[move.id]) {
          listMovesToDelete.push(move.id)
        }
      } else {
        // @ts-ignore
        listMovesToPush.push({ ...move, forceUpdate: true })
      }
    })

    if (listMovesToPush.length || listMovesToDelete.length) {
      await pushListItemsToStore({
        update: { [EntityType.move]: listMovesToPush },
        delete: { [EntityType.move]: listMovesToDelete }
      })
    }

    // Route Builder Moves
    if (!routeBuilderVendorId) {
      return
    }

    const activeTab = activeRouteBuilder.tabs.find(tab => tab.isActive)
    const activeTabDay = dateService.getStringDay(oc(activeTab).date())

    if (!activeTabDay) {
      return
    }

    const activeTabMoves = activeTab.moves
    const activeTabMoveIds = activeTabMoves.map(({ id }) => id)
    const deleteIds = [...deleteItems]
    const filteredMoves = filterLatestObjects(allMoves)
    const pushCurrentTabMoves = filteredMoves.filter(move => {
      if (oc(move).vendorId() !== routeBuilderVendorId) {
        return
      }

      const moveDate = getRouteBuilderMoveDate(move)
      const moveDay = dateService.getStringDay(moveDate)

      if (
        activeTabDay === moveDay ||
        (move.lastMoveOfDay && isRouteBuilderPrevDayMove({ activeTabDate: activeTab.date, moveDate })) ||
        (move.firstMoveOfDay && isRouteBuilderNextDayMove({ activeTabDate: activeTab.date, moveDate }))
      ) {
        return true
      } else if (activeTabMoveIds.includes(move.id)) {
        deleteIds.push(move.id)
      }
    })

    if (pushCurrentTabMoves.length) {
      const requestDDOIds: string[] = pushCurrentTabMoves.reduce((acc, currMove: IMoveDTO) => {
        const storeDDO = dispatchDeliveryOrder[currMove.dispatchDeliveryOrderId]

        if (!isFullObject(storeDDO)) {
          acc.push(currMove.dispatchDeliveryOrderId)
        }

        return acc
      }, [])

      if (requestDDOIds.length) {
        await requestDispatchDeliveryOrdersByIds(requestDDOIds)
      }

      getDispatch()(
        communicationHubActions.pushActiveRouteBuilderMoves({
          date: activeTab.date,
          moves: pushCurrentTabMoves,
          deleteIds
        })
      )
    } else if (deleteIds.length && activeTabMoveIds.some(id => deleteIds.includes(id))) {
      getDispatch()(communicationHubActions.deleteActiveRouteBuilderMoveIds(deleteIds))
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
}

export const moveItemsAction = ({
  addItems,
  updateItems,
  deleteItems
}: {
  addItems: IMoveItemDTO[]
  updateItems: IMoveItemDTO[]
  deleteItems: string[]
}) => {
  try {
    const { activeRouteBuilder } = getCommunicationHubState()
    const { vendorId } = activeRouteBuilder || {}

    // getDispatch()(
    //   listsActions.update({
    //     update: { [EntityType.move]: [...addItems, ...updateItems] },
    //     delete: { [EntityType.move]: deleteItems }
    //   })
    // )

    if (!vendorId) {
      return
    }

    const activeTab = activeRouteBuilder.tabs.find(tab => tab.isActive)

    if (!(activeTab && activeTab.date)) {
      return
    }

    const activeTabMoveIds: string[] = []
    const activeTabMoveItemIds: string[] = []
    activeTab.moves.forEach(({ id, items }) => {
      activeTabMoveIds.push(id)
      items.forEach(item => {
        activeTabMoveItemIds.push(item.id)
      })
    })
    const filteredMoveItems = filterLatestObjects(addItems.concat(updateItems))
    const pushCurrentTabMoveItems = filteredMoveItems.filter(({ moveId }) => activeTabMoveIds.includes(moveId))

    if (
      pushCurrentTabMoveItems.length ||
      (deleteItems.length && activeTabMoveItemIds.some(id => deleteItems.includes(id)))
    ) {
      getDispatch()(
        communicationHubActions.pushActiveRouteBuilderMoveItems({
          date: activeTab.date,
          deleteIds: deleteItems,
          moveItems: pushCurrentTabMoveItems
        })
      )
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
}
