import { BuySideQuoteDTO } from '../api/api'

export const buySideQuoteTypeGroups = {
  delivery: [
    BuySideQuoteDTO.TypeEnum.LIVELOAD,
    BuySideQuoteDTO.TypeEnum.LIVEUNLOAD,
    BuySideQuoteDTO.TypeEnum.PICKFULL,
    BuySideQuoteDTO.TypeEnum.DROPFULL,
    BuySideQuoteDTO.TypeEnum.PICKEMPTY,
    BuySideQuoteDTO.TypeEnum.DROPEMPTY
  ],
  shuttle: [
    BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPEMPTY,
    BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPFULL,
    BuySideQuoteDTO.TypeEnum.SHUTTLERETURNEMPTY,
    BuySideQuoteDTO.TypeEnum.SHUTTLERETURNFULL
  ],
  live: [BuySideQuoteDTO.TypeEnum.LIVELOAD, BuySideQuoteDTO.TypeEnum.LIVEUNLOAD]
}
