import * as React from 'react'
import { ButtonWithIcon } from './styledComponents'
import styled from '../../../styles/styledComponents'
import { Popover } from '../../UI/Dropdowns/Popover'
import { createDeliveryOrderAndTab } from '../deliveryOrder/epics'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { addNewGridItem, createTab, setActiveTabId } from '../../../store/reducers/tabs/functions'
import { tabDirectory } from '../../../store/reducers/tabs/directory'
import { selectApplicationTabs } from '../../../store/select/applicationTabSelect'
import { getActiveApplicationTabState } from '../../../store'

type Props = {}

export const CreateEntity = (props: Props) => {
  const tabs = useAppSelector(selectApplicationTabs)
  const [dropdownState, setDropdownState] = React.useState(false)
  const list = [
    TabDTO.Type.powerUnit,
    TabDTO.Type.equipment,
    TabDTO.Type.container,
    TabDTO.Type.location,
    TabDTO.Type.driver,
    TabDTO.Type.customer,
    TabDTO.Type.steamShipLine,
    TabDTO.Type.subClient,
    TabDTO.Type.deliveryOrder
  ]

  const onEntitySelect = (type: TabDTO.Type) => {
    switch (type) {
      case TabDTO.Type.deliveryOrder:
        return createDeliveryOrderAndTab()
      case TabDTO.Type.powerUnit:
      case TabDTO.Type.equipment:
      case TabDTO.Type.container:
      case TabDTO.Type.location:
      case TabDTO.Type.driver:
      case TabDTO.Type.customer:
      case TabDTO.Type.subClient:
      case TabDTO.Type.steamShipLine:
        const activeTab = tabs.find(tab => tab.active)
        let tabId: string = undefined
        const suitableTab =
          activeTab.type === type && !activeTab.expandedItem.id
            ? activeTab
            : tabs.find(tab => tab.type === type && !tab.expandedItem.id)

        if (suitableTab) {
          tabId = suitableTab.id
          setActiveTabId(tabId)
        } else {
          createTab(type)
          tabId = getActiveApplicationTabState().id
        }

        addNewGridItem({ tabId, type })

        return setDropdownState(false)
      default:
    }
  }

  return (
    <StyledAddButton>
      <Popover
        side={'right'}
        isOpen={dropdownState}
        byHover={true}
        lifetime={1}
        changeState={setDropdownState}
        button={<ButtonWithIcon className={'mdi mdi-plus-circle-outline'} />}
      >
        {list.map(type => {
          let label = tabDirectory.tabLabel[type]

          if (label[label.length - 1] === 's') {
            label = label.substring(0, label.length - 1)
          }

          return (
            <div key={type} onClick={() => onEntitySelect(type)}>
              {label}
            </div>
          )
        })}
      </Popover>
    </StyledAddButton>
  )
}

const StyledAddButton = styled.div`
  margin: 0 1px;
`
