import * as R from 'remeda'
import { equipmentAPI, callAPI, callAPIWithErrorMessage, EquipmentDTO } from '../../../../api/api'
import { catchListErrors } from '../../../../services/tabs/functions'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewObject } from '../../../../services/DTO'

export const requestEquipmentById = (id: string): Promise<EquipmentDTO> =>
  callAPI(equipmentAPI.findById, id)
    .toPromise()
    .then(async equipment => {
      await pushListItemsToStore({ update: { [EntityType.equipment]: [equipment] } })

      return equipment
    })

export const requestEquipments = async ({ sort, filter }: any): Promise<EquipmentDTO[]> => {
  return callAPI(equipmentAPI.findAll, filter, sort)
    .toPromise()
    .then(async equipments => {
      await pushListItemsToStore({ update: { [EntityType.equipment]: equipments } })

      return equipments
    })
    .catch(catchListErrors)
}

export const requestCreateEquipment = (equipment: EquipmentDTO): Promise<EquipmentDTO> => {
  return callAPIWithErrorMessage(equipmentAPI.create, R.omit(equipment, ['id'])).then(async requestedEquipment => {
    await pushListItemsToStore({ update: { [EntityType.equipment]: [requestedEquipment] } })

    return requestedEquipment
  })
}

export const requestUpdateEquipment = (equipment: EquipmentDTO): Promise<EquipmentDTO> => {
  if (isNewObject(equipment)) {
    return requestCreateEquipment(equipment)
  }

  return callAPIWithErrorMessage(equipmentAPI.update, equipment.id, equipment).then(async requestedEquipment => {
    await pushListItemsToStore({ update: { [EntityType.equipment]: [requestedEquipment] } })

    return requestedEquipment
  })
}
