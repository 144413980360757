import * as React from 'react'

export const IconMoveRowProps = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // tslint:disable:max-line-length
      d="M20 6C20 6.53043 19.7893 7.03914 19.4142 7.41421C19.0391 7.78929 18.5304 8 18 8H2C1.46957 8 0.960859 7.78929 0.585786 7.41421C0.210714 7.03914 0 6.53043 0 6V2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585787C19.7893 0.96086 20 1.46957 20 2V6ZM2 6H6V2H2V6ZM8 6H12V2H8V6ZM14 6H18V2H14V6Z"
      fill="#4F4F4F"
    />
    <path d="M11 16L11 14L6 14L6 12L11 12L11 10L14 13L11 16Z" fill="#4F4F4F" />
  </svg>
)
