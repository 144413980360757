import * as React from 'react'
import { TProps } from './simple'
import { TextMask } from './textMask'
import { transformAmount } from '../../../../../services/functions/transform/transformAmount'

export const Percentage = (props: TProps & { allowZero?: boolean }) => {
  const { onChange, value, allowZero } = props

  const handleChange = (input: string | null) => {
    if (input === null) {
      return onChange(null)
    }

    const cleanValue = input.replace('% ', '')
    const result = transformAmount(cleanValue)
    const regex = /^\d+([.]\d{1,2})?$/

    if (allowZero && (cleanValue === '0' || cleanValue === '-0')) {
      return onChange(Number(result))
    }

    if (!input) {
      return onChange(null)
    }

    if (regex.test(result) && Number(result) !== value) {
      if (value !== Number(result)) {
        return onChange(Number(result))
      }
    }
  }
  return (
    <TextMask
      {...props}
      placeholder={props.placeholder || 'N/A'}
      textMask={['%', ' ', /[0-9]/, /[0-9\.]/, /[0-9\.]/, /[0-9]/]}
      onChange={handleChange}
    />
  )
}
