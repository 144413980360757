import * as React from 'react'
import Input from '../'
import { regExpEmail } from '../../../../services/functions/regExp'

type Props = {
  noBorder?: boolean
  value?: string
  tabIndex?: number
  height?: number
  placeholder?: string
  disabled?: boolean
  isColumn?: boolean
  darkBackground?: boolean
  title?: string
  required?: boolean
  onChange(value: string): void
}

export const isValidEmail = (value: string) => {
  // const regExp = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$/
  // tslint:disable:max-line-length

  if (value === '' || value === null || value === undefined) {
    return true
  }
  if (regExpEmail.test(value)) {
    return true
  }
  return false
}

const Email = (props: Props) => <Input {...props} valid={isValidEmail(props.value)} />

export default Email
