import { oc } from 'ts-optchain'
import { BuySideQuoteDTO, TransportationActivityViewDTO } from '../../../api/api'

type Props = {
  buySideQuotes: BuySideQuoteDTO[]
  transportationActivities: TransportationActivityViewDTO[]
}

export const sortBsqItemsListByActivityType = (props: Props): BuySideQuoteDTO[] => {
  const { buySideQuotes, transportationActivities } = props

  if (!(buySideQuotes && buySideQuotes.length)) {
    return buySideQuotes
  }

  const activitiesNumberMapping: Record<string, number> = oc(transportationActivities)([]).reduce(
    (acc: Record<string, number>, curr: TransportationActivityViewDTO) => {
      acc[curr.id] = curr.number
      return acc
    },
    {}
  )

  return buySideQuotes
    .filter(bsq => typeof activitiesNumberMapping[bsq.activityId] === 'number')
    .sort((a, b) => activitiesNumberMapping[a.activityId] - activitiesNumberMapping[b.activityId])
}
