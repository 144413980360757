import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { ContainerViewDTO } from '../../../api/api'
import { saveContainer as saveContainerPromise } from '../../saveEntity/saveContainer'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { getListsState } from '../../../store'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'
import { isNewItem } from '../../utils'

export const saveContainer = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const container: ContainerViewDTO = parentItem
  const modifiedContainer = oc(modifiedLists)[EntityType.container][parentItemId]()
  const store = getListsState()
  const condition = testEntityVersion(store, EntityType.container)(modifiedContainer)

  const save = () => {
    return saveContainerPromise({ container: modifiedContainer }).then(
      handleSavedGridItem({ tabId, isNewItem: isNewItem(container) })
    )
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
