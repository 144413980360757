import { IMoveDTO } from '../interfaces'
import {
  isRouteBuilderPrevDayMove,
  getRouteBuilderMoveDate,
  isRouteBuilderNextDayMove
} from '../../../../hooks/useRouteBuilderNextToDayMove'

export const addMoveProperties = (
  move: IMoveDTO,
  data: {
    activeTabDate: string
  }
): IMoveDTO => {
  const { activeTabDate } = data

  return {
    ...move,
    _isPrevDayMove: isRouteBuilderPrevDayMove({
      activeTabDate,
      moveDate: getRouteBuilderMoveDate(move)
    }),
    _isNextDayMove: isRouteBuilderNextDayMove({
      activeTabDate,
      moveDate: getRouteBuilderMoveDate(move)
    })
  }
}
