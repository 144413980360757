import * as React from 'react'
import { TabDTO } from '../store/reducers/tabs/interfaces'

export type TGridContext = {
  tabId: string
  enableEditing: boolean
  tabData: any
  listRequestProps: any
  tabType: TabDTO.Type
  ignoreWS: boolean
  actionsType: TabDTO.Actions
  listRequestType: TabDTO.Request.List
  detailsRequestType: TabDTO.Type | TabDTO.Request.List
  selectedIds: string[]
  pinnedIds: string[]
  disabledSelectIds: string[]
  fetchGridItems(withoutSpinner?: boolean): Promise<any>
  setGridFetching(state: boolean): void
}

export const GridContext: React.Context<TGridContext> = React.createContext({
  tabId: null,
  enableEditing: null,
  tabData: null,
  listRequestProps: null,
  detailsRequestType: null,
  tabType: null,
  ignoreWS: null,
  actionsType: null,
  selectedIds: null,
  pinnedIds: null,
  disabledSelectIds: null,
  listRequestType: null,
  fetchGridItems: null,
  setGridFetching: null
})
