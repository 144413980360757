import styled from 'styled-components'

export const TerminalIntegrationTable = styled.div`
  min-width: 600px;
  margin: 0 -15px;

  > table {
    width: 100%;
  }
`
