import {
  QMPTab,
  IQMPTabData,
  CustomerQuoteFieldType,
  RateField,
  RulesFieldType
} from '../../../components/common/QMP/interfaces'
import { CustomerQuoteDTO, SellSideQuoteRateDTO } from '../../../api/origin/qmp-service'
// import { defaultContainerTypeIds } from '../containerType/functions'

export const defaultQMPTabData = (): IQMPTabData => ({
  activeTab: QMPTab.RATE,
  openedCustomerQuoteId: null,
  filters: {
    [QMPTab.RATE]: {
      [RateField.number]: null,
      [RateField.type]: null,
      [RateField.customerId]: null,
      [RateField.vendorId]: null,
      [RateField.containerTypeId]: null,
      [RateField.deliveryOrderType]: null,
      [RateField.loadType]: null,
      [RateField.pickupLocationId]: null,
      [RateField.deliveryLocation]: null,
      [RateField.returnLocationId]: null,
      [RateField.ruleIds]: null,
      [RateField.status]: [SellSideQuoteRateDTO.StatusEnum.NEW],
      [RateField.quoteDate]: null
    },
    [QMPTab.CUSTOMERQUOTE]: {
      [CustomerQuoteFieldType.number]: null,
      [CustomerQuoteFieldType.customerId]: null,
      [CustomerQuoteFieldType.vendorId]: null,
      [CustomerQuoteFieldType.pickupLocationId]: null,
      [CustomerQuoteFieldType.deliveryLocation]: null,
      [CustomerQuoteFieldType.returnLocationId]: null,
      [CustomerQuoteFieldType.deliveryOrderType]: [
        CustomerQuoteDTO.DeliveryOrderTypeEnum.IMPORT,
        CustomerQuoteDTO.DeliveryOrderTypeEnum.EXPORT
      ],
      [CustomerQuoteFieldType.containerTypeId]: null,
      [CustomerQuoteFieldType.loadType]: null,
      [CustomerQuoteFieldType.status]: [CustomerQuoteDTO.StatusEnum.NEW]
    },
    [QMPTab.RULES]: {
      [RulesFieldType.name]: null,
      [RulesFieldType.parameter]: null,
      [RulesFieldType.function]: null,
      [RulesFieldType.dateUpdated]: null,
      [RulesFieldType.createdBy]: null
    }
  },
  extraData: {
    [QMPTab.RATE]: {},
    [QMPTab.CUSTOMERQUOTE]: {},
    [QMPTab.RULES]: {}
  },
  ssqIds: [],
  bsqIds: [],
  customerQuoteIds: [],
  newSSQRates: [],
  newBSQRates: [],
  newCustomerQuotes: null,
  ratesTabVisited: false,
  customerQuotesTabVisited: false
})

export const defaultFiltersOnCustomerQuoteCreation = (): any => ({
  [CustomerQuoteFieldType.deliveryOrderType]: [
    CustomerQuoteDTO.DeliveryOrderTypeEnum.IMPORT,
    CustomerQuoteDTO.DeliveryOrderTypeEnum.EXPORT
  ]
})
