import * as React from 'react'
import { Container, Value, Disable, InputContainer, FindButton, DropDown, Item } from './styles'
import { StyledInput, ClearButton } from '../Input/styles'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import cn from 'classnames'
import { FieldContainer } from '../FieldContainer'
import { Whitespace } from '../../../../services/keyboardService/keys'
import { WindowPopover } from '../../windowPopover'
import { useRegExp } from '../../../../services/functions/regExp'

export type TDropDownItem = {
  disabled?: boolean
  before?: string
  after?: string
  className?: string
  value: any
  label: string
  componentBefore?: JSX.Element
  onClick?: () => void
}

export type TSearchByList = {
  toUpperCase?: boolean
  minWidth?: number
  maxLength?: number
  disabled?: boolean
  title?: string
  required?: boolean
  label: any
  dropDownList: TDropDownItem[]
  onChange: (value: any) => void
  isFetching?: boolean
  placeholder?: string
  symbolsNumberToFetch?: number
  handleInputChange?: (inputValue: string) => void
  onEnterPress?: (inputValue?: string) => void
  findAll?: () => void
  filtering?: boolean
  highlighted?: boolean
  focus?: boolean
  enterPressText?: string
  doRender?: () => void
  termRegExp?: RegExp
}

const transformValue = (value: string) => value.replace(/ /g, '').toUpperCase()

const doFilter = (inputValue: string, list: TDropDownItem[]): TDropDownItem[] =>
  inputValue && list.length
    ? list.filter(item => transformValue(item.label).indexOf(transformValue(inputValue)) !== -1)
    : list

export const SearchByList = (props: TSearchByList) => {
  const {
    toUpperCase,
    minWidth,
    maxLength,
    disabled,
    title,
    required,
    isFetching,
    symbolsNumberToFetch,
    label = '',
    dropDownList,
    onChange,
    onEnterPress,
    filtering = true,
    highlighted,
    focus,
    enterPressText = 'Press Enter to search',
    doRender,
    termRegExp
  } = props
  let selectedItem = false
  const labelRef = React.useRef(label)
  const [editState, setEditState] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(label)
  const { statePopover, togglePopover, wrapperRef } = useOutsideClick()
  const inputRef = React.useRef(null)
  const dropDownRef = React.useRef(null)
  labelRef.current = label
  const closeDropDown = () => (statePopover ? togglePopover() : null)
  const openDropDown = () => (statePopover ? null : togglePopover())
  const handleFindButtonClick = () => {
    if (!statePopover && props.findAll) {
      props.findAll()
    }

    const inputElement = inputRef.current.getElementsByTagName('INPUT')[0]
    if (inputElement) {
      inputElement.focus()
    }

    togglePopover()
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Whitespace.Enter) {
      e.preventDefault()
      e.stopPropagation()
      let value = ''

      if (inputValue) {
        value = String(inputValue).trim()
      }

      if (toUpperCase) {
        value = value.toUpperCase()
      }

      setInputValue(value)

      if (!value.length) {
        handleFindButtonClick()
      } else if (onEnterPress) {
        onEnterPress(value)
      }
    }
  }

  React.useEffect(() => {
    if (!label && inputValue) {
      setInputValue('')
    }
  }, [doRender])

  React.useEffect(() => {
    setInputValue(label || '')
    if (!label) {
      closeDropDown()
    }
  }, [label])

  React.useEffect(() => {
    if (!statePopover && !editState) {
      setInputValue(label || '')
    }
  }, [statePopover])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target

    if (toUpperCase) {
      value = value.toUpperCase()
    }

    const correctedValue = useRegExp(termRegExp, value)

    const update = () => {
      setInputValue(correctedValue)
      if (correctedValue.length) {
        if (props.handleInputChange) {
          props.handleInputChange(correctedValue)
        }
        openDropDown()
      } else {
        onChange(null)
        closeDropDown()
      }
    }

    if (maxLength !== undefined) {
      if (correctedValue.length <= maxLength) {
        update()
      }
    } else {
      update()
    }
  }

  const handleSelectItem = (item: any) => {
    selectedItem = true
    onChange(item)
    closeDropDown()
  }

  const handleClear = () => {
    setInputValue('')
    onChange(null)
    closeDropDown()
  }

  const handleFocus = () => {
    setEditState(true)
    // if (inputValue && inputValue.length) {
    //   openDropDown()
    // }
  }

  const handleBlur = () => {
    const trimValue = (inputValue ? String(inputValue) : '').trim()

    setTimeout(() => {
      if (!selectedItem) {
        if (labelRef.current && !trimValue) {
          onChange(null)
        } else if (!labelRef.current && trimValue) {
          setInputValue('')
        } else if (labelRef.current) {
          setInputValue(labelRef.current)
        }
      }

      setEditState(false)
      selectedItem = false
    }, 150)
  }

  const inputContainerClasses = cn('input-container', {
    disabled,
    active: statePopover && !isFetching,
    highlighted: required && !statePopover && !label && !editState
  })

  const findButtonClasses = cn('mdi', {
    disable: Boolean(inputValue),
    fetching: isFetching,
    'mdi-magnify': !isFetching
  })

  const filteredList = filtering ? doFilter(inputValue, dropDownList) : dropDownList

  return (
    <FieldContainer title={title} required={required} highlighted={highlighted} disabled={disabled}>
      <Container className={'search-by-list-container'} ref={wrapperRef}>
        <InputContainer ref={inputRef} className={inputContainerClasses} title={inputValue || ''}>
          {isFetching && <Disable />}
          {disabled ? (
            <Value className={'disabled-input'} data-copy-value-on-mousedown={true}>
              <span>{inputValue || ''}</span>
            </Value>
          ) : (
            <>
              <StyledInput
                type="text"
                autoFocus={Boolean(focus)}
                // @ts-ignore
                onKeyPress={handleKeyPress}
                placeholder={props.placeholder || 'Type here'}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={inputValue || ''}
                data-copy-value-on-mousedown={true}
                onChange={handleInputChange}
              />
              {inputValue && <ClearButton className={'mdi mdi-close-circle'} onClick={handleClear} />}
              <FindButton
                className={findButtonClasses}
                onClick={() => (!inputValue ? handleFindButtonClick() : null)}
              />
            </>
          )}
        </InputContainer>
        {statePopover && !isFetching && (
          <WindowPopover bindToRef={inputRef}>
            <DropDown ref={dropDownRef} style={{ minWidth }}>
              {filteredList.length ? (
                filteredList.map((item: TDropDownItem, i) => {
                  let onClick = () => handleSelectItem(item.value)

                  if (item.disabled) {
                    onClick = undefined
                  } else if (item.onClick) {
                    onClick = () => {
                      item.onClick()
                      closeDropDown()
                    }
                  }

                  return (
                    <Item key={item.label + i} className={item.className || null} onClick={onClick}>
                      {item.before && <span>{item.before}</span>}
                      {item.componentBefore}
                      <div>{item.label}</div>
                      {item.after && <span>{item.after}</span>}
                    </Item>
                  )
                })
              ) : (
                <Item>
                  {symbolsNumberToFetch && (inputValue.length < symbolsNumberToFetch && inputValue.length)
                    ? `Enter ${symbolsNumberToFetch} characters to start the search`
                    : onEnterPress
                    ? enterPressText
                    : 'Not found'}
                </Item>
              )}
            </DropDown>
          </WindowPopover>
        )}
      </Container>
    </FieldContainer>
  )
}
