import * as React from 'react'
import { General } from '../Details/General'
import { EquipmentCredentials } from '../Details/Credentials'
import { Repairs } from '../Details/Repairs'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Equipment

export const EquipmentPreview = {
  headings: [T.General, T.Credentials, T.Repairs],
  tabs: {
    [T.General]: <General />,
    [T.Credentials]: <EquipmentCredentials />,
    [T.Repairs]: <Repairs />
  },
  isValid: checkValidation.equipment
}
