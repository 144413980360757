export const downloadCsvContent = (content: string, fileName?: string): void => {
  try {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${fileName || 'file'}.csv`
    a.innerHTML = 'download csv file'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (e) {}
}
