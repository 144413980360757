import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { savePowerUnit as savePowerUnitPromise } from '../../saveEntity/savePowerUnit'
import { PowerUnitViewDTO } from '../../../api/api'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../utils'
import { getListsState } from '../../../store'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'

export const savePowerUnit = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const powerUnit: PowerUnitViewDTO = parentItem
  const modifiedPowerUnit = oc(modifiedLists)[EntityType.powerUnit][parentItemId]()
  const store = getListsState()
  const condition = testEntityVersion(store, EntityType.powerUnit)(modifiedPowerUnit)

  const save = () => {
    return savePowerUnitPromise({ powerUnit }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(powerUnit) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
