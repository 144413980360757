import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { DispatchDeliveryOrderTabContent } from '../styles'
import Input from '../../../../../UI/Input'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'

const extraStyles = {
  fontSize: 14,
  userSelect: 'none'
}

export const Description = () => {
  const { dispatchDeliveryOrder, enableEditing } = useGridItemDDO()
  const { modifyParentObjectField } = useExpandedItem()

  return (
    <DispatchDeliveryOrderTabContent style={extraStyles as any}>
      <Container rows={38} margin={{ top: 25, left: 60, right: 60 }}>
        <Input
          isTextArea={true}
          disabled={enableEditing === false}
          value={dispatchDeliveryOrder.description}
          onChange={modifyParentObjectField('description')}
        />
      </Container>
    </DispatchDeliveryOrderTabContent>
  )
}
