import * as R from 'remeda'
import { EntityType } from '../../interfaces'
import { LocationViewDTO, TransportationActivityViewDTO } from '../../../../../api/api'
import { ActivityViewDTO } from '../../../../../components/common/activity/interfaces'

interface IParsedProps {
  [EntityType.activity]: (ActivityViewDTO)[]
  [EntityType.location]: LocationViewDTO[]
}

export const parseActivities = async (activities: ActivityViewDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.activity]: [],
    [EntityType.location]: []
  }

  try {
    activities.forEach(activity => {
      if ((activity as TransportationActivityViewDTO).destination) {
        // @ts-ignore
        result[EntityType.location].push((activity as TransportationActivityViewDTO).destination)
      }

      // @ts-ignore
      result[EntityType.activity].push(R.omit(activity, ['destination', 'vendor']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
