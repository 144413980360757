import { NotificationDTO } from '../../../../api/origin/communication-hub-service'
import {
  ChannelType,
  CommunicationHubTab,
  IChannelBrowser,
  ICommunicationHub,
  NotificationFilter,
  TMessages
} from '../interfaces'

export const defaultMessages = (): TMessages => ({
  ids: [],
  mapping: {}
})

export const defaultChannelBrowser = (): IChannelBrowser => ({
  channelType: undefined,
  filters: undefined
})

export const generateInitialCommunicationHubState = (): ICommunicationHub => {
  return {
    activeTab: CommunicationHubTab.messenger,
    channelBrowser: undefined,
    activeChannel: undefined,
    channels: {
      idListByType: {
        [ChannelType.recentlyOpened]: [],
        [ChannelType.operator]: [],
        [ChannelType.dispatchDeliveryOrder]: [],
        [ChannelType.driver]: []
      },
      mapping: {}
    },
    notifications: {
      filters: {
        [NotificationFilter.status]: NotificationDTO.StatusEnum.INPROGRESS
      },
      sortedIds: [],
      itemsMapping: {},
      documentForms: []
    },
    messages: defaultMessages(),
    userMeta: {},
    channelsMeta: {},
    editMessageId: undefined,
    activeRouteBuilder: undefined
  }
}
