
// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";

import { Configuration } from "./configuration";

const BASE_PATH = "https://intermodal-gateway.dev.port24.com".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = configuration.basePath, ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    code?: ApiError.CodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    value?: string;
}

/**
 * @export
 * @namespace ApiError
 */
export namespace ApiError {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        UNKNOWN = <any> 'UNKNOWN',
        WRONGEFFECTIVEDATE = <any> 'WRONG_EFFECTIVE_DATE',
        DUPLICATE = <any> 'DUPLICATE',
        CONSTRAINTVIOLATION = <any> 'CONSTRAINT_VIOLATION',
        CONTROLLERERROR = <any> 'CONTROLLER_ERROR'
    }
}

/**
 * 
 * @export
 * @interface BsqRateListDTO
 */
export interface BsqRateListDTO extends Array<BuySideQuoteRateDTO> {
}

/**
 * 
 * @export
 * @interface BuySideQuoteRateDTO
 */
export interface BuySideQuoteRateDTO {
    /**
     * 
     * @type {number}
     * @memberof BuySideQuoteRateDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    calculationType: BuySideQuoteRateDTO.CalculationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    containerTypeId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof BuySideQuoteRateDTO
     */
    createdAt?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    deliveryCity?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    deliveryOrderType?: BuySideQuoteRateDTO.DeliveryOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    deliveryStateId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof BuySideQuoteRateDTO
     */
    effectiveDate: DateISOString;
    /**
     * 
     * @type {DateISOString}
     * @memberof BuySideQuoteRateDTO
     */
    expirationDate?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    loadType?: BuySideQuoteRateDTO.LoadTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BuySideQuoteRateDTO
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    pickupLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    returnLocationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuySideQuoteRateDTO
     */
    ruleIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    status?: BuySideQuoteRateDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    type: BuySideQuoteRateDTO.TypeEnum;
    /**
     * 
     * @type {DateISOString}
     * @memberof BuySideQuoteRateDTO
     */
    updatedAt?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof BuySideQuoteRateDTO
     */
    vendorId?: string;
    /**
     * 
     * @type {number}
     * @memberof BuySideQuoteRateDTO
     */
    version?: number;
}

/**
 * @export
 * @namespace BuySideQuoteRateDTO
 */
export namespace BuySideQuoteRateDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CalculationTypeEnum {
        FIXEDAMOUNT = <any> 'FIXED_AMOUNT',
        PERCENTAGE = <any> 'PERCENTAGE',
        PERMILE = <any> 'PER_MILE',
        PERHOUR = <any> 'PER_HOUR',
        PERDAY = <any> 'PER_DAY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryOrderTypeEnum {
        IMPORT = <any> 'IMPORT',
        EXPORT = <any> 'EXPORT',
        REPOSITION = <any> 'REPOSITION'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LoadTypeEnum {
        LIVELOAD = <any> 'LIVE_LOAD',
        DROP = <any> 'DROP',
        PICK = <any> 'PICK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NEW = <any> 'NEW',
        EXPIRED = <any> 'EXPIRED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ALLIN = <any> 'ALL_IN',
        BASE = <any> 'BASE',
        FUEL = <any> 'FUEL',
        CHASSIS = <any> 'CHASSIS',
        DEDUCTION = <any> 'DEDUCTION',
        DEDUCTIONREEFER = <any> 'DEDUCTION_REEFER',
        DEMURRAGE = <any> 'DEMURRAGE',
        DETENTION = <any> 'DETENTION',
        DROPANDPICKUP = <any> 'DROP_AND_PICKUP',
        DRYRUN = <any> 'DRY_RUN',
        HAZMAT = <any> 'HAZMAT',
        LAYOVER = <any> 'LAY_OVER',
        OVERWEIGHT = <any> 'OVERWEIGHT',
        REDELIVERY = <any> 'REDELIVERY',
        REEFER = <any> 'REEFER',
        PERDIEM = <any> 'PER_DIEM',
        PREPULL = <any> 'PREPULL',
        SCALE = <any> 'SCALE',
        SHUTTLE = <any> 'SHUTTLE',
        SHUTTLEHAZ = <any> 'SHUTTLE_HAZ',
        SHUTTLEOW = <any> 'SHUTTLE_OW',
        SHUTTLEREEFER = <any> 'SHUTTLE_REEFER',
        STORAGE = <any> 'STORAGE',
        TANKER = <any> 'TANKER',
        TOLLS = <any> 'TOLLS',
        CONGESTION = <any> 'CONGESTION',
        BOBTAIL = <any> 'BOBTAIL'
    }
}

/**
 * 
 * @export
 * @interface ConditionDTO
 */
export interface ConditionDTO {
    /**
     * 
     * @type {string}
     * @memberof ConditionDTO
     */
    conditionOperator?: ConditionDTO.ConditionOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionDTO
     */
    logicalOperator?: ConditionDTO.LogicalOperatorEnum;
    /**
     * 
     * @type {number}
     * @memberof ConditionDTO
     */
    number?: number;
    /**
     * 
     * @type {Array<ConditionPropertyValueDTO>}
     * @memberof ConditionDTO
     */
    values?: Array<ConditionPropertyValueDTO>;
    /**
     * 
     * @type {number}
     * @memberof ConditionDTO
     */
    version?: number;
}

/**
 * @export
 * @namespace ConditionDTO
 */
export namespace ConditionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum ConditionOperatorEnum {
        EQUAL = <any> 'EQUAL',
        NOTEQUAL = <any> 'NOT_EQUAL',
        IN = <any> 'IN',
        NOTIN = <any> 'NOT_IN',
        LESSTHAN = <any> 'LESS_THAN',
        LESSTHANEQUAL = <any> 'LESS_THAN_EQUAL',
        GREATERTHAN = <any> 'GREATER_THAN',
        GREATERTHANEQUAL = <any> 'GREATER_THAN_EQUAL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LogicalOperatorEnum {
        AND = <any> 'AND',
        OR = <any> 'OR'
    }
}

/**
 * 
 * @export
 * @interface ConditionPropertyValueDTO
 */
export interface ConditionPropertyValueDTO {
    /**
     * 
     * @type {string}
     * @memberof ConditionPropertyValueDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionPropertyValueDTO
     */
    propertyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionPropertyValueDTO
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface CustomerQuoteDTO
 */
export interface CustomerQuoteDTO {
    /**
     * 
     * @type {number}
     * @memberof CustomerQuoteDTO
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    containerTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    deliveryCity?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    deliveryOrderType: CustomerQuoteDTO.DeliveryOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    deliveryPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    deliveryStateId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof CustomerQuoteDTO
     */
    effectiveDate: DateISOString;
    /**
     * 
     * @type {DateISOString}
     * @memberof CustomerQuoteDTO
     */
    expirationDate?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    loadType: CustomerQuoteDTO.LoadTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerQuoteDTO
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    pickupLocationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerQuoteDTO
     */
    rateIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    returnLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    status?: CustomerQuoteDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    surcharges?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQuoteDTO
     */
    type: CustomerQuoteDTO.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerQuoteDTO
     */
    version?: number;
}

/**
 * @export
 * @namespace CustomerQuoteDTO
 */
export namespace CustomerQuoteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryOrderTypeEnum {
        IMPORT = <any> 'IMPORT',
        EXPORT = <any> 'EXPORT',
        REPOSITION = <any> 'REPOSITION'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LoadTypeEnum {
        LIVELOAD = <any> 'LIVE_LOAD',
        DROPANDPICK = <any> 'DROP_AND_PICK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NEW = <any> 'NEW',
        EXPIRED = <any> 'EXPIRED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ALLIN = <any> 'ALL_IN',
        BASEANDFUEL = <any> 'BASE_AND_FUEL'
    }
}

/**
 * 
 * @export
 * @interface CustomerQuoteListDTO
 */
export interface CustomerQuoteListDTO extends Array<CustomerQuoteDTO> {
}

/**
 * 
 * @export
 * @interface DuplicateError
 */
export interface DuplicateError {
    /**
     * 
     * @type {string}
     * @memberof DuplicateError
     */
    code?: DuplicateError.CodeEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateError
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DuplicateError
     */
    duplicateIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DuplicateError
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateError
     */
    message?: string;
}

/**
 * @export
 * @namespace DuplicateError
 */
export namespace DuplicateError {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        UNKNOWN = <any> 'UNKNOWN',
        WRONGEFFECTIVEDATE = <any> 'WRONG_EFFECTIVE_DATE',
        DUPLICATE = <any> 'DUPLICATE',
        CONSTRAINTVIOLATION = <any> 'CONSTRAINT_VIOLATION',
        CONTROLLERERROR = <any> 'CONTROLLER_ERROR'
    }
}

/**
 * 
 * @export
 * @interface DuplicateErrorList
 */
export interface DuplicateErrorList extends Array<DuplicateError> {
}

/**
 * 
 * @export
 * @interface PropertyDTO
 */
export interface PropertyDTO {
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    propertyType?: PropertyDTO.PropertyTypeEnum;
}

/**
 * @export
 * @namespace PropertyDTO
 */
export namespace PropertyDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum PropertyTypeEnum {
        BOOLEAN = <any> 'BOOLEAN',
        STRING = <any> 'STRING',
        INTEGER = <any> 'INTEGER',
        UUID = <any> 'UUID'
    }
}

/**
 * 
 * @export
 * @interface RuleDTO
 */
export interface RuleDTO {
    /**
     * 
     * @type {boolean}
     * @memberof RuleDTO
     */
    active?: boolean;
    /**
     * 
     * @type {Array<ConditionDTO>}
     * @memberof RuleDTO
     */
    conditions?: Array<ConditionDTO>;
    /**
     * 
     * @type {string}
     * @memberof RuleDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof RuleDTO
     */
    version?: number;
}

/**
 * 
 * @export
 * @interface RuleSearchDTO
 */
export interface RuleSearchDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleSearchDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSearchDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSearchDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleSearchDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof RuleSearchDTO
     */
    sort?: number;
}

/**
 * 
 * @export
 * @interface SellSideQuoteRateDTO
 */
export interface SellSideQuoteRateDTO {
    /**
     * 
     * @type {number}
     * @memberof SellSideQuoteRateDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    calculationType: SellSideQuoteRateDTO.CalculationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    containerTypeId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof SellSideQuoteRateDTO
     */
    createdAt?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    deliveryCity?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    deliveryOrderType?: SellSideQuoteRateDTO.DeliveryOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    deliveryStateId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof SellSideQuoteRateDTO
     */
    effectiveDate: DateISOString;
    /**
     * 
     * @type {DateISOString}
     * @memberof SellSideQuoteRateDTO
     */
    expirationDate?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    loadType?: SellSideQuoteRateDTO.LoadTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SellSideQuoteRateDTO
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    pickupLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    returnLocationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SellSideQuoteRateDTO
     */
    ruleIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    status?: SellSideQuoteRateDTO.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    type: SellSideQuoteRateDTO.TypeEnum;
    /**
     * 
     * @type {DateISOString}
     * @memberof SellSideQuoteRateDTO
     */
    updatedAt?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof SellSideQuoteRateDTO
     */
    updatedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof SellSideQuoteRateDTO
     */
    version?: number;
}

/**
 * @export
 * @namespace SellSideQuoteRateDTO
 */
export namespace SellSideQuoteRateDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CalculationTypeEnum {
        FIXEDAMOUNT = <any> 'FIXED_AMOUNT',
        PERCENTAGE = <any> 'PERCENTAGE',
        PERMILE = <any> 'PER_MILE',
        PERHOUR = <any> 'PER_HOUR',
        PERDAY = <any> 'PER_DAY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryOrderTypeEnum {
        IMPORT = <any> 'IMPORT',
        EXPORT = <any> 'EXPORT',
        REPOSITION = <any> 'REPOSITION'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LoadTypeEnum {
        LIVELOAD = <any> 'LIVE_LOAD',
        DROPANDPICK = <any> 'DROP_AND_PICK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NEW = <any> 'NEW',
        EXPIRED = <any> 'EXPIRED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ALLIN = <any> 'ALL_IN',
        BASE = <any> 'BASE',
        FUEL = <any> 'FUEL',
        CHASSIS = <any> 'CHASSIS',
        DEDUCTION = <any> 'DEDUCTION',
        DEDUCTIONREEFER = <any> 'DEDUCTION_REEFER',
        DEMURRAGE = <any> 'DEMURRAGE',
        DETENTION = <any> 'DETENTION',
        DROPANDPICKUP = <any> 'DROP_AND_PICKUP',
        DRYRUN = <any> 'DRY_RUN',
        HAZMAT = <any> 'HAZMAT',
        LAYOVER = <any> 'LAY_OVER',
        OVERWEIGHT = <any> 'OVERWEIGHT',
        REDELIVERY = <any> 'REDELIVERY',
        REEFER = <any> 'REEFER',
        PERDIEM = <any> 'PER_DIEM',
        PREPULL = <any> 'PREPULL',
        SCALE = <any> 'SCALE',
        SHUTTLE = <any> 'SHUTTLE',
        SHUTTLEHAZ = <any> 'SHUTTLE_HAZ',
        SHUTTLEOW = <any> 'SHUTTLE_OW',
        SHUTTLEREEFER = <any> 'SHUTTLE_REEFER',
        STORAGE = <any> 'STORAGE',
        TANKER = <any> 'TANKER',
        TOLLS = <any> 'TOLLS',
        CONGESTION = <any> 'CONGESTION',
        BOBTAIL = <any> 'BOBTAIL'
    }
}

/**
 * 
 * @export
 * @interface SsqRateGroupDTO
 */
export interface SsqRateGroupDTO {
    /**
     * 
     * @type {number}
     * @memberof SsqRateGroupDTO
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    calculationType?: SsqRateGroupDTO.CalculationTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SsqRateGroupDTO
     */
    containerTypeId?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    deliveryCity?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SsqRateGroupDTO
     */
    deliveryOrderType?: Array<SsqRateGroupDTO.DeliveryOrderTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    deliveryStateId?: string;
    /**
     * 
     * @type {DateISOString}
     * @memberof SsqRateGroupDTO
     */
    effectiveDate?: DateISOString;
    /**
     * 
     * @type {DateISOString}
     * @memberof SsqRateGroupDTO
     */
    expirationDate?: DateISOString;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    loadType?: SsqRateGroupDTO.LoadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    pickupLocationId?: string;
    /**
     * 
     * @type {Array<SellSideQuoteRateDTO>}
     * @memberof SsqRateGroupDTO
     */
    rates?: Array<SellSideQuoteRateDTO>;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    returnLocationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SsqRateGroupDTO
     */
    ruleIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SsqRateGroupDTO
     */
    type?: SsqRateGroupDTO.TypeEnum;
}

/**
 * @export
 * @namespace SsqRateGroupDTO
 */
export namespace SsqRateGroupDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CalculationTypeEnum {
        FIXEDAMOUNT = <any> 'FIXED_AMOUNT',
        PERCENTAGE = <any> 'PERCENTAGE',
        PERMILE = <any> 'PER_MILE',
        PERHOUR = <any> 'PER_HOUR',
        PERDAY = <any> 'PER_DAY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryOrderTypeEnum {
        IMPORT = <any> 'IMPORT',
        EXPORT = <any> 'EXPORT',
        REPOSITION = <any> 'REPOSITION'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LoadTypeEnum {
        LIVELOAD = <any> 'LIVE_LOAD',
        DROPANDPICK = <any> 'DROP_AND_PICK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ALLIN = <any> 'ALL_IN',
        BASE = <any> 'BASE',
        FUEL = <any> 'FUEL',
        CHASSIS = <any> 'CHASSIS',
        DEDUCTION = <any> 'DEDUCTION',
        DEDUCTIONREEFER = <any> 'DEDUCTION_REEFER',
        DEMURRAGE = <any> 'DEMURRAGE',
        DETENTION = <any> 'DETENTION',
        DROPANDPICKUP = <any> 'DROP_AND_PICKUP',
        DRYRUN = <any> 'DRY_RUN',
        HAZMAT = <any> 'HAZMAT',
        LAYOVER = <any> 'LAY_OVER',
        OVERWEIGHT = <any> 'OVERWEIGHT',
        REDELIVERY = <any> 'REDELIVERY',
        REEFER = <any> 'REEFER',
        PERDIEM = <any> 'PER_DIEM',
        PREPULL = <any> 'PREPULL',
        SCALE = <any> 'SCALE',
        SHUTTLE = <any> 'SHUTTLE',
        SHUTTLEHAZ = <any> 'SHUTTLE_HAZ',
        SHUTTLEOW = <any> 'SHUTTLE_OW',
        SHUTTLEREEFER = <any> 'SHUTTLE_REEFER',
        STORAGE = <any> 'STORAGE',
        TANKER = <any> 'TANKER',
        TOLLS = <any> 'TOLLS',
        CONGESTION = <any> 'CONGESTION',
        BOBTAIL = <any> 'BOBTAIL'
    }
}

/**
 * 
 * @export
 * @interface SsqRateListDTO
 */
export interface SsqRateListDTO extends Array<SellSideQuoteRateDTO> {
}


/**
 * BuySideQuoteRateControllerApi - fetch parameter creator
 * @export
 */
export const BuySideQuoteRateControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<BuySideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateBuySideQuoteRate(qMPOverwrite: boolean, body: Array<BuySideQuoteRateDTO>, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling batchCreateBuySideQuoteRate.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling batchCreateBuySideQuoteRate.');
            }
            const localVarPath = `/bsq-rates/batch/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"BuySideQuoteRateDTO[]" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {BuySideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuySideQuoteRate(qMPOverwrite: boolean, body: BuySideQuoteRateDTO, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling createBuySideQuoteRate.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBuySideQuoteRate.');
            }
            const localVarPath = `/bsq-rates/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"BuySideQuoteRateDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireBuySideQuote(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling expireBuySideQuote.');
            }
            const localVarPath = `/bsq-rates/{id}/expire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRate(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBuySideQuoteRate.');
            }
            const localVarPath = `/bsq-rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRates(filter?: string, sort?: string, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/bsq-rates/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuySideQuoteRateControllerApi - functional programming interface
 * @export
 */
export const BuySideQuoteRateControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<BuySideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateBuySideQuoteRate(qMPOverwrite: boolean, body: Array<BuySideQuoteRateDTO>, options?: any): (basePath?: string) => Promise<BsqRateListDTO> {
            const localVarFetchArgs = BuySideQuoteRateControllerApiFetchParamCreator(configuration).batchCreateBuySideQuoteRate(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {BuySideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuySideQuoteRate(qMPOverwrite: boolean, body: BuySideQuoteRateDTO, options?: any): (basePath?: string) => Promise<BuySideQuoteRateDTO> {
            const localVarFetchArgs = BuySideQuoteRateControllerApiFetchParamCreator(configuration).createBuySideQuoteRate(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireBuySideQuote(id: string, options?: any): (basePath?: string) => Promise<BuySideQuoteRateDTO> {
            const localVarFetchArgs = BuySideQuoteRateControllerApiFetchParamCreator(configuration).expireBuySideQuote(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRate(id: string, options?: any): (basePath?: string) => Promise<BuySideQuoteRateDTO> {
            const localVarFetchArgs = BuySideQuoteRateControllerApiFetchParamCreator(configuration).getBuySideQuoteRate(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any): (basePath?: string) => Promise<Array<BuySideQuoteRateDTO>> {
            const localVarFetchArgs = BuySideQuoteRateControllerApiFetchParamCreator(configuration).getBuySideQuoteRates(filter, sort, limit, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BuySideQuoteRateControllerApi - factory interface
 * @export
 */
export const BuySideQuoteRateControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<BuySideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateBuySideQuoteRate(qMPOverwrite: boolean, body: Array<BuySideQuoteRateDTO>, options?: any) {
            return BuySideQuoteRateControllerApiFp(configuration).batchCreateBuySideQuoteRate(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {BuySideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuySideQuoteRate(qMPOverwrite: boolean, body: BuySideQuoteRateDTO, options?: any) {
            return BuySideQuoteRateControllerApiFp(configuration).createBuySideQuoteRate(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireBuySideQuote(id: string, options?: any) {
            return BuySideQuoteRateControllerApiFp(configuration).expireBuySideQuote(id, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRate(id: string, options?: any) {
            return BuySideQuoteRateControllerApiFp(configuration).getBuySideQuoteRate(id, options)(basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuySideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any) {
            return BuySideQuoteRateControllerApiFp(configuration).getBuySideQuoteRates(filter, sort, limit, options)(basePath);
        },
    };
};

/**
 * BuySideQuoteRateControllerApi - object-oriented interface
 * @export
 * @class BuySideQuoteRateControllerApi
 * @extends {BaseAPI}
 */
export class BuySideQuoteRateControllerApi extends BaseAPI {
    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {Array<BuySideQuoteRateDTO>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuySideQuoteRateControllerApi
     */
    public batchCreateBuySideQuoteRate(qMPOverwrite: boolean, body: Array<BuySideQuoteRateDTO>, options?: any) {
        return BuySideQuoteRateControllerApiFp(this.configuration).batchCreateBuySideQuoteRate(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {BuySideQuoteRateDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuySideQuoteRateControllerApi
     */
    public createBuySideQuoteRate(qMPOverwrite: boolean, body: BuySideQuoteRateDTO, options?: any) {
        return BuySideQuoteRateControllerApiFp(this.configuration).createBuySideQuoteRate(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuySideQuoteRateControllerApi
     */
    public expireBuySideQuote(id: string, options?: any) {
        return BuySideQuoteRateControllerApiFp(this.configuration).expireBuySideQuote(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuySideQuoteRateControllerApi
     */
    public getBuySideQuoteRate(id: string, options?: any) {
        return BuySideQuoteRateControllerApiFp(this.configuration).getBuySideQuoteRate(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [sort] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuySideQuoteRateControllerApi
     */
    public getBuySideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any) {
        return BuySideQuoteRateControllerApiFp(this.configuration).getBuySideQuoteRates(filter, sort, limit, options)(this.basePath);
    }

}

/**
 * CustomerQuoteControllerApi - fetch parameter creator
 * @export
 */
export const CustomerQuoteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<CustomerQuoteDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateCustomerQuote(qMPOverwrite: boolean, body: Array<CustomerQuoteDTO>, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling batchCreateCustomerQuote.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling batchCreateCustomerQuote.');
            }
            const localVarPath = `/customer-quotes/batch/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"CustomerQuoteDTO[]" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {CustomerQuoteDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerQuote(qMPOverwrite: boolean, body: CustomerQuoteDTO, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling createCustomerQuote.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCustomerQuote.');
            }
            const localVarPath = `/customer-quotes/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"CustomerQuoteDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireCustomerQuotes(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling expireCustomerQuotes.');
            }
            const localVarPath = `/customer-quotes/{id}/expire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuote(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCustomerQuote.');
            }
            const localVarPath = `/customer-quotes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuotes(filter?: string, sort?: string, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/customer-quotes/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesOfCustomerQuote(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRatesOfCustomerQuote.');
            }
            const localVarPath = `/customer-quotes/{id}/rates/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerQuoteControllerApi - functional programming interface
 * @export
 */
export const CustomerQuoteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<CustomerQuoteDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateCustomerQuote(qMPOverwrite: boolean, body: Array<CustomerQuoteDTO>, options?: any): (basePath?: string) => Promise<CustomerQuoteListDTO> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).batchCreateCustomerQuote(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {CustomerQuoteDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerQuote(qMPOverwrite: boolean, body: CustomerQuoteDTO, options?: any): (basePath?: string) => Promise<CustomerQuoteDTO> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).createCustomerQuote(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireCustomerQuotes(id: string, options?: any): (basePath?: string) => Promise<CustomerQuoteDTO> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).expireCustomerQuotes(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuote(id: string, options?: any): (basePath?: string) => Promise<CustomerQuoteDTO> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).getCustomerQuote(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuotes(filter?: string, sort?: string, limit?: number, options?: any): (basePath?: string) => Promise<Array<CustomerQuoteDTO>> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).getCustomerQuotes(filter, sort, limit, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesOfCustomerQuote(id: string, options?: any): (basePath?: string) => Promise<Array<SellSideQuoteRateDTO>> {
            const localVarFetchArgs = CustomerQuoteControllerApiFetchParamCreator(configuration).getRatesOfCustomerQuote(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CustomerQuoteControllerApi - factory interface
 * @export
 */
export const CustomerQuoteControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<CustomerQuoteDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateCustomerQuote(qMPOverwrite: boolean, body: Array<CustomerQuoteDTO>, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).batchCreateCustomerQuote(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {CustomerQuoteDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerQuote(qMPOverwrite: boolean, body: CustomerQuoteDTO, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).createCustomerQuote(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireCustomerQuotes(id: string, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).expireCustomerQuotes(id, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuote(id: string, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).getCustomerQuote(id, options)(basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerQuotes(filter?: string, sort?: string, limit?: number, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).getCustomerQuotes(filter, sort, limit, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesOfCustomerQuote(id: string, options?: any) {
            return CustomerQuoteControllerApiFp(configuration).getRatesOfCustomerQuote(id, options)(basePath);
        },
    };
};

/**
 * CustomerQuoteControllerApi - object-oriented interface
 * @export
 * @class CustomerQuoteControllerApi
 * @extends {BaseAPI}
 */
export class CustomerQuoteControllerApi extends BaseAPI {
    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {Array<CustomerQuoteDTO>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public batchCreateCustomerQuote(qMPOverwrite: boolean, body: Array<CustomerQuoteDTO>, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).batchCreateCustomerQuote(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {CustomerQuoteDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public createCustomerQuote(qMPOverwrite: boolean, body: CustomerQuoteDTO, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).createCustomerQuote(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public expireCustomerQuotes(id: string, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).expireCustomerQuotes(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public getCustomerQuote(id: string, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).getCustomerQuote(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [sort] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public getCustomerQuotes(filter?: string, sort?: string, limit?: number, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).getCustomerQuotes(filter, sort, limit, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerQuoteControllerApi
     */
    public getRatesOfCustomerQuote(id: string, options?: any) {
        return CustomerQuoteControllerApiFp(this.configuration).getRatesOfCustomerQuote(id, options)(this.basePath);
    }

}

/**
 * IndexControllerApi - fetch parameter creator
 * @export
 */
export const IndexControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options: any = {}): FetchArgs {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndexControllerApi - functional programming interface
 * @export
 */
export const IndexControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): (basePath?: string) => Promise<any> {
            const localVarFetchArgs = IndexControllerApiFetchParamCreator(configuration).get(options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IndexControllerApi - factory interface
 * @export
 */
export const IndexControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any) {
            return IndexControllerApiFp(configuration).get(options)(basePath);
        },
    };
};

/**
 * IndexControllerApi - object-oriented interface
 * @export
 * @class IndexControllerApi
 * @extends {BaseAPI}
 */
export class IndexControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndexControllerApi
     */
    public get(options?: any) {
        return IndexControllerApiFp(this.configuration).get(options)(this.basePath);
    }

}

/**
 * PropertyControllerApi - fetch parameter creator
 * @export
 */
export const PropertyControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties(options: any = {}): FetchArgs {
            const localVarPath = `/properties/properties`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyControllerApi - functional programming interface
 * @export
 */
export const PropertyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties(options?: any): (basePath?: string) => Promise<Array<PropertyDTO>> {
            const localVarFetchArgs = PropertyControllerApiFetchParamCreator(configuration).getProperties(options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PropertyControllerApi - factory interface
 * @export
 */
export const PropertyControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties(options?: any) {
            return PropertyControllerApiFp(configuration).getProperties(options)(basePath);
        },
    };
};

/**
 * PropertyControllerApi - object-oriented interface
 * @export
 * @class PropertyControllerApi
 * @extends {BaseAPI}
 */
export class PropertyControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyControllerApi
     */
    public getProperties(options?: any) {
        return PropertyControllerApiFp(this.configuration).getProperties(options)(this.basePath);
    }

}

/**
 * RuleControllerApi - fetch parameter creator
 * @export
 */
export const RuleControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRule(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRule.');
            }
            const localVarPath = `/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRules(filter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/rules/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleControllerApi - functional programming interface
 * @export
 */
export const RuleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRule(id: string, options?: any): (basePath?: string) => Promise<RuleDTO> {
            const localVarFetchArgs = RuleControllerApiFetchParamCreator(configuration).getRule(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRules(filter?: string, options?: any): (basePath?: string) => Promise<Array<RuleSearchDTO>> {
            const localVarFetchArgs = RuleControllerApiFetchParamCreator(configuration).getRules(filter, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RuleControllerApi - factory interface
 * @export
 */
export const RuleControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRule(id: string, options?: any) {
            return RuleControllerApiFp(configuration).getRule(id, options)(basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRules(filter?: string, options?: any) {
            return RuleControllerApiFp(configuration).getRules(filter, options)(basePath);
        },
    };
};

/**
 * RuleControllerApi - object-oriented interface
 * @export
 * @class RuleControllerApi
 * @extends {BaseAPI}
 */
export class RuleControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleControllerApi
     */
    public getRule(id: string, options?: any) {
        return RuleControllerApiFp(this.configuration).getRule(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleControllerApi
     */
    public getRules(filter?: string, options?: any) {
        return RuleControllerApiFp(this.configuration).getRules(filter, options)(this.basePath);
    }

}

/**
 * SellSideQuoteRateControllerApi - fetch parameter creator
 * @export
 */
export const SellSideQuoteRateControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<SellSideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateSellSideQuoteRate(qMPOverwrite: boolean, body: Array<SellSideQuoteRateDTO>, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling batchCreateSellSideQuoteRate.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling batchCreateSellSideQuoteRate.');
            }
            const localVarPath = `/ssq-rates/batch/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"SellSideQuoteRateDTO[]" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {SellSideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellSideQuoteRate(qMPOverwrite: boolean, body: SellSideQuoteRateDTO, options: any = {}): FetchArgs {
            // verify required parameter 'qMPOverwrite' is not null or undefined
            if (qMPOverwrite === null || qMPOverwrite === undefined) {
                throw new RequiredError('qMPOverwrite','Required parameter qMPOverwrite was null or undefined when calling createSellSideQuoteRate.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSellSideQuoteRate.');
            }
            const localVarPath = `/ssq-rates/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (qMPOverwrite !== undefined && qMPOverwrite !== null) {
                localVarHeaderParameter['QMP-Overwrite'] = String(qMPOverwrite);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());
            const needsSerialization = (<any>"SellSideQuoteRateDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSellSideQuote(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling expireSellSideQuote.');
            }
            const localVarPath = `/ssq-rates/{id}/expire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRate(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSellSideQuoteRate.');
            }
            const localVarPath = `/ssq-rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRateGroups(filter?: string, sort?: string, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/ssq-rates/groups/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRates(filter?: string, sort?: string, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/ssq-rates/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellSideQuoteRateControllerApi - functional programming interface
 * @export
 */
export const SellSideQuoteRateControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<SellSideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateSellSideQuoteRate(qMPOverwrite: boolean, body: Array<SellSideQuoteRateDTO>, options?: any): (basePath?: string) => Promise<SsqRateListDTO> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).batchCreateSellSideQuoteRate(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {SellSideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellSideQuoteRate(qMPOverwrite: boolean, body: SellSideQuoteRateDTO, options?: any): (basePath?: string) => Promise<SellSideQuoteRateDTO> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).createSellSideQuoteRate(qMPOverwrite, body, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSellSideQuote(id: string, options?: any): (basePath?: string) => Promise<SellSideQuoteRateDTO> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).expireSellSideQuote(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRate(id: string, options?: any): (basePath?: string) => Promise<SellSideQuoteRateDTO> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).getSellSideQuoteRate(id, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRateGroups(filter?: string, sort?: string, limit?: number, options?: any): (basePath?: string) => Promise<Array<SsqRateGroupDTO>> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).getSellSideQuoteRateGroups(filter, sort, limit, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any): (basePath?: string) => Promise<Array<SellSideQuoteRateDTO>> {
            const localVarFetchArgs = SellSideQuoteRateControllerApiFetchParamCreator(configuration).getSellSideQuoteRates(filter, sort, limit, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SellSideQuoteRateControllerApi - factory interface
 * @export
 */
export const SellSideQuoteRateControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {Array<SellSideQuoteRateDTO>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreateSellSideQuoteRate(qMPOverwrite: boolean, body: Array<SellSideQuoteRateDTO>, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).batchCreateSellSideQuoteRate(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {boolean} qMPOverwrite 
         * @param {SellSideQuoteRateDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellSideQuoteRate(qMPOverwrite: boolean, body: SellSideQuoteRateDTO, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).createSellSideQuoteRate(qMPOverwrite, body, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSellSideQuote(id: string, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).expireSellSideQuote(id, options)(basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRate(id: string, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).getSellSideQuoteRate(id, options)(basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRateGroups(filter?: string, sort?: string, limit?: number, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).getSellSideQuoteRateGroups(filter, sort, limit, options)(basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sort] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellSideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any) {
            return SellSideQuoteRateControllerApiFp(configuration).getSellSideQuoteRates(filter, sort, limit, options)(basePath);
        },
    };
};

/**
 * SellSideQuoteRateControllerApi - object-oriented interface
 * @export
 * @class SellSideQuoteRateControllerApi
 * @extends {BaseAPI}
 */
export class SellSideQuoteRateControllerApi extends BaseAPI {
    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {Array<SellSideQuoteRateDTO>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public batchCreateSellSideQuoteRate(qMPOverwrite: boolean, body: Array<SellSideQuoteRateDTO>, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).batchCreateSellSideQuoteRate(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {boolean} qMPOverwrite 
     * @param {SellSideQuoteRateDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public createSellSideQuoteRate(qMPOverwrite: boolean, body: SellSideQuoteRateDTO, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).createSellSideQuoteRate(qMPOverwrite, body, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public expireSellSideQuote(id: string, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).expireSellSideQuote(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public getSellSideQuoteRate(id: string, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).getSellSideQuoteRate(id, options)(this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [sort] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public getSellSideQuoteRateGroups(filter?: string, sort?: string, limit?: number, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).getSellSideQuoteRateGroups(filter, sort, limit, options)(this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {string} [sort] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellSideQuoteRateControllerApi
     */
    public getSellSideQuoteRates(filter?: string, sort?: string, limit?: number, options?: any) {
        return SellSideQuoteRateControllerApiFp(this.configuration).getSellSideQuoteRates(filter, sort, limit, options)(this.basePath);
    }

}


export type DateISOString = string; // 2018-04-15T08:36:01.711Z