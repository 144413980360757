import * as React from 'react'
import { IModalWindow, ModalWindow } from '../store/reducers/modalWindow/interfaces'

export interface IModalWindowContext {
  modalWindow: IModalWindow
  setModalWindow: (state: IModalWindow) => void
  setModalWindowButtons: (buttons: ModalWindow.Button[]) => void
  closeModalWindow: () => void
}

export const ModalWindowContext: React.Context<IModalWindowContext> = React.createContext(null)
