export enum WebsocketEvent {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  UPDATED_FORCE = 'UPDATED_FORCE'
}

export type WSProps = {
  DTOType: string
  data: any
  eventType: WebsocketEvent
  service: WebsocketService
}

export enum WebsocketService {
  QMP = 'QMP',
  MOVE = 'MOVE',
  CHUB = 'CHUB',
  DOCUMENT = 'DOCUMENT',
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  USER = 'USER',
  ROUTE = 'ROUTE',
  DISPATCH_VENDOR = 'DISPATCH_VENDOR'
}

export interface IWebsocketData {
  service: WebsocketService
  eventType: WebsocketEvent
  type: string
  payload: any
}

export interface IWebsocketPingData {
  ping: number
}
