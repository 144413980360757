import { FileCategory } from '../services/filesService/interfaces'
import { ChannelType } from '../store/reducers/communicationHub/interfaces'

const fileCategoryMapping: Record<FileCategory, { label: string; iconClassName: string }> = {
  [FileCategory.IMAGE]: {
    label: 'Image',
    iconClassName: 'mdi mdi-image'
  },
  [FileCategory.WORKORDER]: {
    label: 'Work Order',
    iconClassName: 'mdi mdi-clipboard-text'
  },
  [FileCategory.SCALETICKET]: {
    label: 'Scale Ticket',
    iconClassName: 'mdi mdi-scale'
  },
  [FileCategory.POD]: {
    label: 'POD',
    iconClassName: 'mdi mdi-earth'
  },
  [FileCategory.TIR]: {
    label: 'TIR',
    iconClassName: 'mdi mdi-earth'
  },
  [FileCategory.TROUBLETICKET]: {
    label: 'Trouble Ticket',
    iconClassName: 'mdi mdi-alert-decagram'
  },
  [FileCategory.OTHER]: {
    label: 'Other',
    iconClassName: 'mdi mdi-file-document'
  }
}

export const CommunicationHubConstants = {
  navigationViewWidth: 300,
  channelViewWidth: 655,
  fileCategoryMapping,
  maxChannelsNumber: {
    [ChannelType.recentlyOpened]: 20,
    [ChannelType.dispatchDeliveryOrder]: 20,
    [ChannelType.operator]: 99,
    [ChannelType.driver]: 99
  }
}
