import * as React from 'react'
import { SellSideQuoteRateDTO, BuySideQuoteRateDTO, CustomerQuoteDTO } from '../../../api/origin/qmp-service'

// const surchargeTypes = {
//   [SellSideQuoteRateDTO.TypeEnum.CHASSIS]: 'Chassis',
//   // [SellSideQuoteSurchargeDTO.TypeEnum.DETENTION]: 'Detention',
//   [SellSideQuoteRateDTO.TypeEnum.DROPANDPICKUP]: 'Drop & Pick',
//   // [SellSideQuoteSurchargeDTO.TypeEnum.DRYRUN]: 'Dry run',
//   [SellSideQuoteRateDTO.TypeEnum.FUEL]: 'Fuel',
//   [SellSideQuoteRateDTO.TypeEnum.HAZMAT]: 'Hazmat',
//   [SellSideQuoteRateDTO.TypeEnum.LAYOVER]: 'Lay Over',
//   // [SellSideQuoteRateDTO.TypeEnum.MISC]: 'Misc',
//   [SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT]: 'Overweight',
//   [SellSideQuoteRateDTO.TypeEnum.PREPULL]: 'Pre-pull',
//   // [SellSideQuoteSurchargeDTO.TypeEnum.REDELIVERY]: 'Redelivery',
//   [SellSideQuoteRateDTO.TypeEnum.REEFER]: 'Reefer',
//   // [SellSideQuoteSurchargeDTO.TypeEnum.STORAGE]: 'Storage',
//   [SellSideQuoteRateDTO.TypeEnum.TANKER]: 'Tanker',
//   [SellSideQuoteRateDTO.TypeEnum.TOLLS]: 'Tolls',
//   [SellSideQuoteRateDTO.TypeEnum.PERDIEM]: 'Per Diem',
//   [SellSideQuoteRateDTO.TypeEnum.CONGESTION]: 'Congestion',
//   [SellSideQuoteRateDTO.TypeEnum.DEMURRAGE]: 'Demurrage'
// }

// const surchargeActivityTypes = {
//   [SellSideQuoteRateDTO.TypeEnum.TOLLS]: 'Tolls',
//   [SellSideQuoteRateDTO.TypeEnum.DETENTION]: 'Detention',
//   [SellSideQuoteRateDTO.TypeEnum.REDELIVERY]: 'Redelivery',
//   [SellSideQuoteRateDTO.TypeEnum.STORAGE]: 'Storage',
//   // [SellSideQuoteRateDTO.TypeEnum.MISC]: 'Misc',
//   [SellSideQuoteRateDTO.TypeEnum.LAYOVER]: 'Lay Over',
//   [SellSideQuoteRateDTO.TypeEnum.DRYRUN]: 'Dry run'
// }

const loadType = {
  [SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK]: 'D&P',
  [SellSideQuoteRateDTO.LoadTypeEnum.LIVELOAD]: 'Live'
}

const loadTypeAll = {
  [SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK]: 'D&P',
  [SellSideQuoteRateDTO.LoadTypeEnum.LIVELOAD]: 'Live',
  [BuySideQuoteRateDTO.LoadTypeEnum.DROP]: 'Drop',
  [BuySideQuoteRateDTO.LoadTypeEnum.PICK]: 'Pick'
}

// const baseRateTypes = {
//   [SellSideQuoteRateDTO.TypeEnum.ALLIN]: 'All-In',
//   [SellSideQuoteRateDTO.TypeEnum.BASE]: 'Base'
// }

const calculationType = {
  [SellSideQuoteRateDTO.CalculationTypeEnum.FIXEDAMOUNT]: 'Fixed',
  [SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE]: 'Percent',
  [SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY]: 'Per Day',
  [SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR]: 'Per Hour',
  [SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE]: 'Per Mile'
}

const accountingCategory = {
  // [SellSideQuoteSurchargeDTO.AccountingCategoryEnum.DELIVERYORDER]: 'DO',
  // [SellSideQuoteSurchargeDTO.AccountingCategoryEnum.PARTY]: 'PRT',
  // [SellSideQuoteSurchargeDTO.AccountingCategoryEnum.TARIFF]: 'TFF'
  DELIVERY_ORDER: 'DO',
  PARTY: 'PRT',
  TARIFF: 'TFF'
}
const typeOfRate = {
  [SellSideQuoteRateDTO.TypeEnum.BASE]: 'Base',
  [SellSideQuoteRateDTO.TypeEnum.ALLIN]: 'All-in',
  [SellSideQuoteRateDTO.TypeEnum.FUEL]: 'Fuel',
  [SellSideQuoteRateDTO.TypeEnum.CHASSIS]: 'Chassis',
  [SellSideQuoteRateDTO.TypeEnum.DROPANDPICKUP]: 'Drop & Pick',
  [SellSideQuoteRateDTO.TypeEnum.TOLLS]: 'Tolls',
  [SellSideQuoteRateDTO.TypeEnum.DETENTION]: 'Detention',
  [SellSideQuoteRateDTO.TypeEnum.PREPULL]: 'Pre-pull',
  [SellSideQuoteRateDTO.TypeEnum.REDELIVERY]: 'Redelivery',
  [SellSideQuoteRateDTO.TypeEnum.STORAGE]: 'Storage',
  [SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT]: 'Overweight',
  [SellSideQuoteRateDTO.TypeEnum.HAZMAT]: 'Hazmat',
  [SellSideQuoteRateDTO.TypeEnum.REEFER]: 'Reefer',
  [SellSideQuoteRateDTO.TypeEnum.LAYOVER]: 'Lay over',
  [SellSideQuoteRateDTO.TypeEnum.TANKER]: 'Tanker',
  [SellSideQuoteRateDTO.TypeEnum.DRYRUN]: 'Dry run',
  [SellSideQuoteRateDTO.TypeEnum.PERDIEM]: 'Per diem',
  [SellSideQuoteRateDTO.TypeEnum.DEMURRAGE]: 'Demurrage',
  [SellSideQuoteRateDTO.TypeEnum.SCALE]: 'Scale',
  [SellSideQuoteRateDTO.TypeEnum.CONGESTION]: 'Congestion',
  [BuySideQuoteRateDTO.TypeEnum.SHUTTLE]: 'Regular Shuttle',
  [BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER]: 'Reefer Shuttle',
  [BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW]: 'Overweight Shuttle',
  [BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ]: 'Hazmat Shuttle',
  [BuySideQuoteRateDTO.TypeEnum.DEDUCTION]: 'Deduction',
  [BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER]: 'Deduction Reefer',
  [BuySideQuoteRateDTO.TypeEnum.BOBTAIL]: 'Bobtail'
}

const customerQuoteType = {
  [CustomerQuoteDTO.TypeEnum.ALLIN]: 'All-In',
  [CustomerQuoteDTO.TypeEnum.BASEANDFUEL]: 'Base + Fuel'
}

export const quoteDirectory = {
  // surchargeTypes,
  // surchargeActivityTypes,
  loadType,
  // baseRateTypes,
  calculationType,
  accountingCategory,
  typeOfRate,
  loadTypeAll,
  customerQuoteType
}
