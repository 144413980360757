import * as React from 'react'
import * as R from 'remeda'
import styled from 'styled-components'
import cn from 'classnames'
import { EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import Editor from 'draft-js-plugins-editor'
import { fixCursorBug, linkifyEditorState } from '../../../CommunicationHub/functions/linkifyEditorState'
import { FieldContainer } from '../FieldContainer'
import theme from '../../../../styles/theme'

enum KeyCommand {
  enterPressed = 'enter-pressed',
  handled = 'handled',
  notHandled = 'not-handled'
}

type Props = {
  editorState: EditorState
  onEditorChange: (editorState: EditorState) => void
  placeholder?: string
  title?: string
  required?: boolean
  visibleLinesNumber?: number
  visibleExpandedLinesNumber?: number
  styleFieldContainer?: React.CSSProperties
}

export const RichTextArea = React.memo((props: Props) => {
  const { title, required, placeholder, visibleLinesNumber, visibleExpandedLinesNumber, styleFieldContainer } = props
  const editorRef = React.useRef(null)
  const editorState = props.editorState || EditorState.createEmpty()
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const handleEditorChange = React.useCallback(
    (state: EditorState) => {
      if (props.onEditorChange) {
        props.onEditorChange(state)
      }
    },
    [props.onEditorChange]
  )

  const onChange = React.useCallback(
    (state: EditorState) => handleEditorChange(fixCursorBug(editorState, linkifyEditorState(state))),
    [editorState, handleEditorChange]
  )

  const handleKeyCommand = React.useCallback(
    (command: any, newEditorState: any) => {
      if (command === KeyCommand.enterPressed) {
        return KeyCommand.handled
      }

      const newState = RichUtils.handleKeyCommand(newEditorState, command)

      if (newState) {
        handleEditorChange(newState)
        return KeyCommand.handled
      }

      return KeyCommand.notHandled
    },
    [props.onEditorChange]
  )

  // STYLED TEXT
  const { inlineToolbarPlugin, linkifyPlugin } = React.useMemo(() => {
    return {
      inlineToolbarPlugin: createInlineToolbarPlugin(),
      linkifyPlugin: createLinkifyPlugin({
        target: '_blank',
        // hot fix https://github.com/draft-js-plugins/draft-js-plugins/issues/1342
        component: (params: any) => <a {...R.omit(params, ['blockKey', 'start', 'end'])} />
      })
      // emojiPlugin: createEmojiPlugin({
      //   useNativeArt: true,
      //   selectButtonContent: <i className={'mdi mdi-emoticon'} />
      // }),
    }
  }, [])

  // HANDLE KEY COMMANDS
  // const keyBindingFn = React.useCallback((e: any) => {
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     return KeyCommand.enterPressed // name this whatever you want
  //   }
  //   // If this wasn't keys above, we return Draft's default key bindings
  //   return getDefaultKeyBinding(e as any)
  // }, [])

  const plugins = React.useMemo(
    () => [
      inlineToolbarPlugin,
      // emojiPlugin,
      linkifyPlugin
    ],
    []
  )

  const { InlineToolbar } = inlineToolbarPlugin as any

  return (
    <FieldContainer
      className={'RichTextArea__container'}
      title={title}
      required={required}
      style={{ position: 'relative', ...(styleFieldContainer || {}) }}
    >
      {Boolean(visibleExpandedLinesNumber) && (
        <ExpandButton
          className={cn('mdi mdi-arrow-expand', { active: expanded })}
          onClick={() => setExpanded(_ => !_)}
        />
      )}
      <Container
        className={'RichTextArea__editor-container'}
        style={
          expanded
            ? { height: richTextLineHeight * visibleExpandedLinesNumber + richTextOffset }
            : visibleLinesNumber
            ? { height: richTextLineHeight * visibleLinesNumber + richTextOffset }
            : undefined
        }
        onClick={() => editorRef.current.focus()}
      >
        <Editor
          ref={editorRef}
          placeholder={placeholder || 'Type here...'}
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          // keyBindingFn={keyBindingFn}
          // handleKeyCommand={handleKeyCommand}
        />
        <InlineToolbar />
      </Container>
    </FieldContainer>
  )
})

export const richTextLineHeight = 23
export const richTextPaddingOffset = 8
export const richTextBorderWidth = 1
export const richTextOffset = richTextBorderWidth * 2 + richTextBorderWidth * 2

const Container = styled.div`
  border: ${richTextBorderWidth}px solid rgba(229, 229, 229, 1);
  border-radius: 4px;
  padding: ${richTextPaddingOffset}px;
  font-size: 14px;
  overflow: auto;

  .public-DraftEditorPlaceholder-root {
    color: rgba(51, 51, 51, 0.3);
  }
`

const ExpandButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  color: #bdbdbd;
  cursor: pointer;

  &:hover,
  &.active {
    color: ${theme.colors.basicBlueColor};
  }
`
