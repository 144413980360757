import * as React from 'react'
import { Row as StyledRow, Column, ColumnData } from '../styled'
import { getStringConvertISODateToDateWithHoursMins } from '../../../../../../../services/timeService/dateUtils'
import { TAuditTrailRow } from '../interfaces'

export const Row = (props: TAuditTrailRow) => {
  const { updatedAt, fieldName, newValue, oldValue, updatedBy, activityNumber } = props
  return (
    <StyledRow>
      <Column>
        <ColumnData>{getStringConvertISODateToDateWithHoursMins(updatedAt, false, false)}</ColumnData>
      </Column>
      <Column>
        <ColumnData>{updatedBy}</ColumnData>
      </Column>
      <Column style={{ maxWidth: 180 }}>
        <ColumnData>{activityNumber || '–'}</ColumnData>
      </Column>
      <Column>
        <ColumnData>{fieldName}</ColumnData>
      </Column>
      <Column>
        <ColumnData>{newValue}</ColumnData>
      </Column>
      <Column>
        <ColumnData>{oldValue}</ColumnData>
      </Column>
    </StyledRow>
  )
}
