import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { File } from './File'
import ImageViewer from 'react-simple-image-viewer'
import { Portal } from 'react-portal'
import { stopPropagationEvent } from '../../../../services/functions/basic'
import { FileCategory, IAttachment } from '../../../../services/filesService/interfaces'
import { AttachmentAction, AttachmentActionType } from './AttachmentAction'
import { openFileLink } from '../../../../services/filesService'

type TFilesByTypes = {
  workOrders: IAttachment[]
  images: IAttachment[]
  other: IAttachment[]
}

type Props = {
  attachments: IAttachment[]
  numberOfElementsPerLine?: number
  style?: React.CSSProperties
}

export const Attachments = React.memo((props: Props) => {
  const { numberOfElementsPerLine, style } = props
  const attachments = props.attachments || []
  const [currentImage, setCurrentImage] = React.useState(0)
  const [isViewerOpen, setIsViewerOpen] = React.useState(false)

  const openImageViewer = React.useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = React.useCallback(() => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }, [])

  const files: TFilesByTypes = {
    workOrders: [],
    images: [],
    other: []
  }

  attachments.forEach(file => {
    if (file.category === FileCategory.WORKORDER) {
      files.workOrders.push(file)
    } else if (file.thumb) {
      files.images.push(file)
    } else {
      files.other.push(file)
    }
  })

  const imageURLs = React.useMemo(() => files.images.map(_ => _.url), [files.images])

  const adjustNumberOfElementsPerLine: string = React.useMemo(() => {
    if (!(typeof numberOfElementsPerLine === 'number' && numberOfElementsPerLine > 0)) {
      return
    }

    const columns: string[] = []

    for (let i = 1; i <= numberOfElementsPerLine; i++) {
      columns.push('1fr')
    }

    return columns.join(' ')
  }, [numberOfElementsPerLine])

  if (!attachments.length) {
    return null
  }

  return (
    <>
      <AttachmentsContainer
        // @ts-ignore
        style={style}
      >
        {attachments.length > 1 ? <AttachmentsCount>{attachments.length} files</AttachmentsCount> : null}
        <>
          {Boolean(files.workOrders.length) && (
            <FilesContainer
              style={{
                marginBottom: files.images.length ? 10 : undefined,
                gridTemplateColumns: adjustNumberOfElementsPerLine
              }}
            >
              {files.workOrders.map((file, index) => (
                <File key={file.id || file.name + index} file={file} />
              ))}
            </FilesContainer>
          )}
          {Boolean(files.other.length) && (
            <FilesContainer
              style={{
                marginBottom: files.images.length ? 10 : undefined,
                gridTemplateColumns: adjustNumberOfElementsPerLine
              }}
            >
              {files.other.map((file, index) => (
                <File key={file.id || file.name + index} file={file} />
              ))}
            </FilesContainer>
          )}
          {Boolean(files.images.length) && (
            <FilesContainer style={{ gridTemplateColumns: adjustNumberOfElementsPerLine }}>
              {files.images.map((file, index) => (
                <Image
                  key={file.id || file.name + index}
                  style={{
                    background: `rgba(0, 0, 0, 0.2) url(${oc(file).thumb()}) center/cover`
                  }}
                  onClick={() => openImageViewer(index)}
                >
                  <AttachmentAction
                    type={AttachmentActionType.download}
                    onClick={event => {
                      stopPropagationEvent(event)
                      openFileLink(file.url, { predownload: true, name: file.name })
                    }}
                  />
                </Image>
              ))}
            </FilesContainer>
          )}
        </>
      </AttachmentsContainer>
      {isViewerOpen && Boolean(imageURLs.length) && (
        <Portal>
          <ImageViewer
            src={imageURLs}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)'
            }}
            closeOnClickOutside={true}
          />
        </Portal>
      )}
    </>
  )
})

export const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`

export const AttachmentsContainer = styled.div`
  margin-top: 15px;
`
export const AttachmentsCount = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 1.2;
  margin-bottom: 4px;
`
const DownloadButton = styled.a`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
  color: white;
  opacity: 0;

  &:hover {
    transition: background 0.25s;
    background-color: rgba(0, 0, 0, 0.9);
  }
`
const Image = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  cursor: zoom-in;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:hover {
    ${DownloadButton} {
      transition: opacity 0.25s;
      opacity: 1;
    }
  }
`
