import { CustomerViewDTO } from '../../../api/api'
import { debuggingMode } from '../../debug'
import { schemaCustomer } from '../../yupScheme'

export const isCustomerValid = (customer: CustomerViewDTO): boolean => {
  if (debuggingMode.common) {
    schemaCustomer.validate(customer).catch(e => {
      //   getStore().dispatch(
      //     addServerMessage({
      //       type: 'error',
      //       message: e.message
      //     })
      //   )
      // tslint:disable-next-line:no-console
      console.log('checkCustomerValidation', e)
    })
  }

  return schemaCustomer.isValidSync(customer)
}
