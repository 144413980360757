import { DocumentDTO } from '../../../api/api'
import { PaymentMethod, VendorDTO } from '../../../api/origin/vendor-accounting-service'

export const testVendorAccounting = (vendorAccounting: VendorDTO): boolean => {
  if (!vendorAccounting) {
    return false
  }

  const { paymentMethod } = vendorAccounting

  const testVendorAccountingRoutingCode = (text: string): boolean => {
    const isValid = text && text.length === 9
    if (paymentMethod === PaymentMethod.ACH) {
      return isValid
    }

    return !text || isValid
  }

  const testVendorAccountingAccountNumber = (text: string): boolean => {
    const isValid = text && text.length >= 6 && text.length <= 17

    if (paymentMethod === PaymentMethod.ACH) {
      return isValid
    }

    return !text || isValid
  }

  const testCopyOfVoidedCheck = (document: DocumentDTO): boolean => {
    if (paymentMethod === PaymentMethod.ACH) {
      return document && typeof document === 'object'
    }

    return true
  }

  return (
    testVendorAccountingRoutingCode(vendorAccounting.routingCode) &&
    testVendorAccountingAccountNumber(vendorAccounting.accountNumber) &&
    testCopyOfVoidedCheck(vendorAccounting.voidedCheck)
  )
}
