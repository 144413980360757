import * as React from 'react'
// @ts-ignore
import { debounce } from 'debounce'
import { ApplicationSizeContext } from '../contexts/ApplicationSizeContext'
import { debuggingMode } from '../services/debug'
import { CommunicationHubConstants } from '../constants/CommunicationHubConstants'
import { useAppSelector } from '../hooks/useAppSelector'
import {
  selectCommunictaionHubIsExpanded,
  selectCommunictaionHubIsVisible
} from '../store/select/communicationHubSelect'

type OwnProps = {
  rerenderOnResize?: boolean
  children: any
}

type StateProps = {}

type Props = OwnProps & StateProps

export const ApplicationSizeProvider = React.memo((props: Props) => {
  const { children, rerenderOnResize } = props
  const isCommunicationHubExpanded = useAppSelector(selectCommunictaionHubIsExpanded)
  const isCommunicationHubVisible = useAppSelector(selectCommunictaionHubIsVisible)
  const [resize, updateResize] = React.useState(0)
  let communicationHubWidth = 0

  if (isCommunicationHubVisible) {
    communicationHubWidth += CommunicationHubConstants.navigationViewWidth

    if (isCommunicationHubExpanded) {
      communicationHubWidth += CommunicationHubConstants.channelViewWidth
    }
  }

  if (rerenderOnResize) {
    React.useEffect(() => {
      const updateResizeFn = debounce(() => updateResize(state => state + 1), 500)
      window.addEventListener('resize', updateResizeFn)

      return () => {
        window.removeEventListener('resize', updateResizeFn)
      }
    }, [])
  }

  return (
    <ApplicationSizeContext.Provider
      value={{
        resize,
        applicationWidth: window.innerWidth - communicationHubWidth,
        applicationHeight: window.innerHeight,
        communicationHubWidth: debuggingMode.hideCommunicationHub ? 0 : communicationHubWidth,
        isCommunicationHubExpanded: debuggingMode.hideCommunicationHub ? false : isCommunicationHubExpanded
      }}
    >
      {children}
    </ApplicationSizeContext.Provider>
  )
})
