import { WebsocketEvent } from './interfaces'
import { filterLatestObjects } from './functions'

type WebsocketCollector = {
  receivedObjectsAction(props: { addItems: any[]; updateItems: any[]; deleteItems: any[] }): void
  delay?: number
  doNotFilterLatestItems?: true
}

export const websocketCollector = ({
  delay = 1000,
  receivedObjectsAction,
  doNotFilterLatestItems
}: WebsocketCollector) => {
  let timer: any = null
  let addItems: any[] = []
  let updateItems: any[] = []
  let deleteItems: any[] = []

  const doWork = () => {
    if (!(addItems.length || updateItems.length || deleteItems.length)) {
      return
    }

    if (!doNotFilterLatestItems) {
      try {
        updateItems = filterLatestObjects(updateItems)
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e)
      }
    }

    receivedObjectsAction({ addItems, updateItems, deleteItems })

    addItems = []
    updateItems = []
    deleteItems = []
  }

  return (objectDTO: any, eventType: WebsocketEvent) => {
    switch (eventType) {
      case WebsocketEvent.CREATED: {
        addItems.push(objectDTO)
        break
      }
      case WebsocketEvent.UPDATED: {
        updateItems.push(objectDTO)
        break
      }
      case WebsocketEvent.UPDATED_FORCE: {
        updateItems.push({ ...objectDTO, forceUpdate: true })
        break
      }
      case WebsocketEvent.DELETED: {
        deleteItems.push(objectDTO)
        break
      }
      default:
        break
    }

    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(doWork, delay)
  }
}
