export enum LocationColumn {
  Name = 'Name',
  Type = 'Type',
  Code = 'Code',
  Company = 'Company',
  Address = 'Address',
  City = 'City',
  State = 'State',
  ZIP = 'ZIP',
  PCFirstName = 'PC First Name',
  PCLastName = 'PC Last Name',
  PCPhone = 'PC phone',
  PCEmail = 'PC email'
}

export const locationColumnSettings = {
  [LocationColumn.Name]: { width: 160, active: true },
  [LocationColumn.Type]: { width: 160, active: true },
  [LocationColumn.Code]: { width: 100, active: true },
  [LocationColumn.Company]: { width: 160, active: true },
  [LocationColumn.Address]: { width: 260, active: true },
  [LocationColumn.City]: { width: 120, active: true },
  [LocationColumn.State]: { width: 80, active: true },
  [LocationColumn.ZIP]: { width: 100, active: true },
  [LocationColumn.PCFirstName]: { width: 130, active: true },
  [LocationColumn.PCLastName]: { width: 130, active: true },
  [LocationColumn.PCPhone]: { width: 120, active: true },
  [LocationColumn.PCEmail]: { width: 150, active: true }
}
