import * as React from 'react'
import { oc } from 'ts-optchain'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useContact } from '../hooks/useContact'
import { GridItemSteamShipLineContext } from '../contexts/GridItemSteamShipLineContext'
import { useBusinessPartner } from '../hooks/useBusinessPartner'
import { useSteamShipLine } from '../hooks/useSteamShipLine'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemSteamShipLineProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const steamShipLine = useSteamShipLine({ id, modifiedLists })
  const businessPartner = useBusinessPartner({ id: steamShipLine.businessPartnerId, modifiedLists })
  const contact = useContact({ modifiedLists, id: oc(businessPartner).primaryContactId() })

  return (
    <GridItemSteamShipLineContext.Provider
      value={{ steamShipLine, isExpanded: Boolean(modifiedLists), businessPartner, primaryContact: contact }}
      children={children}
    />
  )
})
