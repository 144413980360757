import * as React from 'react'
import { SpecialColumn } from './SpecialCokumn'
import { IDriverPositionDTO } from '../../../../../../../../services/DTO/driverPosition/interfaces'
import { dateService } from '../../../../../../../../services/timeService'
import { getDriverPosition } from '../../../../../../../../services/DTO/driverPosition/epics'

type Props = {
  isActive: boolean
  isAssigned: boolean
  isExtraActivity: boolean
  isTwoActivitiesView: boolean
  hideETAMilesColumns: boolean
  isDriverOnline: boolean
  authUserId: string
  driverPosition: IDriverPositionDTO
  duration: number
}

export const ETAColumn = React.memo((props: Props) => {
  const {
    isActive,
    isAssigned,
    isExtraActivity,
    isTwoActivitiesView,
    hideETAMilesColumns,
    driverPosition,
    isDriverOnline,
    duration,
    authUserId
  } = props

  if (hideETAMilesColumns) {
    return <div />
  }

  return (
    <SpecialColumn
      title={'ETA'}
      value={duration ? dateService.makeTimerLabel(driverPosition.duration * 1000) : undefined}
      color={isDriverOnline === false && '#989ea6'}
      isTwoActivitiesView={isTwoActivitiesView}
      onRefrashButtonClick={
        authUserId && isDriverOnline && (isActive || isAssigned || isExtraActivity)
          ? () => getDriverPosition(authUserId)
          : undefined
      }
    />
  )
})
