import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { BusinessPartnerViewDTO, ContactDTO, CustomerViewDTO, SubClientViewDTO } from '../../../api/api'
import { getExpandedItemState, getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { getCurrentListItemState } from './getCurrentListItemState'

type Props = {
  customer: CustomerViewDTO
  modifiedLists: Partial<TListsState>
  storeLists?: TListsState
}

type ModifiedEntities = {
  [EntityType.customer]: CustomerViewDTO
  [EntityType.businessPartner]: BusinessPartnerViewDTO[]
  [EntityType.subClient]: SubClientViewDTO[]
  [EntityType.contact]: ContactDTO[]
}

export const getModifiedCustomerEntities = (props: Props): ModifiedEntities => {
  const { customer } = props
  const storeLists = props.storeLists || getListsState()
  const modifiedLists = props.modifiedLists || getExpandedItemState().modifiedLists
  const businessPartner: BusinessPartnerViewDTO = getCurrentListItemState({
    id: customer.businessPartnerId,
    entityType: EntityType.businessPartner,
    storeLists,
    modifiedLists
  })

  const subClients: SubClientViewDTO[] = oc(customer)
    .subClientIds([])
    .map(id => getCurrentListItemState({ id, entityType: EntityType.subClient, storeLists, modifiedLists }))
    .filter(Boolean)
  const businessPartnerIds: string[] = subClients
    .map(item => item.businessPartnerId)
    .concat(customer.businessPartnerId)
    .filter(Boolean)
  const businessPartners: BusinessPartnerViewDTO[] = businessPartnerIds
    .map(id => getCurrentListItemState({ id, entityType: EntityType.businessPartner, storeLists, modifiedLists }))
    .filter(Boolean)

  return {
    [EntityType.customer]: oc(modifiedLists)[EntityType.customer][customer.id](),
    [EntityType.businessPartner]: businessPartnerIds
      .map(id => oc(modifiedLists)[EntityType.businessPartner][id]())
      .filter(Boolean),
    [EntityType.subClient]: oc(customer)
      .subClientIds([])
      .map(id => oc(modifiedLists)[EntityType.subClient][id]())
      .filter(Boolean),
    [EntityType.contact]: R.uniq(
      businessPartners.map(item => item.primaryContactId).concat(oc(businessPartner).contactIds())
    )
      .map(id => oc(modifiedLists)[EntityType.contact][id]())
      .filter(Boolean)
  }
}
