import * as React from 'react'
import { TabDTO } from '../store/reducers/tabs/interfaces'

export interface ITabDataContext {
  tabId: string
  tabType: TabDTO.Type
  tabData: any
  setTabData: (data: any) => void
  modifyTabDataProps: (props: any) => void
}

export const TabDataContext = React.createContext<ITabDataContext>(undefined)
