import * as React from 'react'
import styled from 'styled-components'
import { UserAvatar, UserAvatarSize } from '../UserAvatar'
import { UserDTO, UserType } from '../../../../api/origin/user-service'
import theme from '../../../../styles/theme'
import { userMUIconMapping } from '../../../../store/reducers/user/directory'

type Props = {
  mention: UserDTO & { isAnotherMentionTypeStart?: boolean }
  searchValue: string
  isFocused: boolean
  parentProps: any
}

// mdi-headset

// tslint:disable:max-line-length
export const MentionEntry = (props: Props) => {
  const { mention, ...parentProps } = props

  return (
    <>
      {mention.isAnotherMentionTypeStart && <Separator />}
      <Container {...parentProps}>
        <UserAvatar size={UserAvatarSize.small} user={mention} />
        <Name>{mention.name}</Name>
        <Icons>
          {mention.type === UserType.DRIVER && !mention.mobileApp && (
            <SMSIcon>
              <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="24-basic-check-mark">
                  <rect
                    id="Rectangle 836"
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="14"
                    rx="2.5"
                    stroke="#031831"
                    strokeOpacity="0.5"
                  />
                  <g id="SMS" opacity="0.5">
                    <path
                      d="M5.93699 11.5C5.2993 11.5 4.81704 11.3333 4.49023 11C4.16341 10.6593 4 10.1741 4 9.54444V9.1H5.2435V9.63333C5.2435 10.137 5.47068 10.3889 5.92504 10.3889C6.14823 10.3889 6.31562 10.3296 6.42722 10.2111C6.54679 10.0852 6.60657 9.88519 6.60657 9.61111C6.60657 9.28519 6.52686 9 6.36744 8.75556C6.20801 8.5037 5.91308 8.2037 5.48264 7.85556C4.9406 7.41111 4.56197 7.01111 4.34675 6.65556C4.13152 6.29259 4.02391 5.88519 4.02391 5.43333C4.02391 4.81852 4.19131 4.34444 4.5261 4.01111C4.86089 3.67037 5.34713 3.5 5.98482 3.5C6.61454 3.5 7.08883 3.67037 7.40767 4.01111C7.73449 4.34444 7.8979 4.82593 7.8979 5.45556V5.77778H6.6544V5.37778C6.6544 5.11111 6.5986 4.91852 6.487 4.8C6.37541 4.67407 6.212 4.61111 5.99678 4.61111C5.55836 4.61111 5.33916 4.85926 5.33916 5.35556C5.33916 5.63704 5.41887 5.9 5.57829 6.14444C5.74569 6.38889 6.0446 6.68519 6.47505 7.03333C7.02506 7.47778 7.40369 7.88148 7.61094 8.24444C7.81819 8.60741 7.92181 9.03333 7.92181 9.52222C7.92181 10.1593 7.75043 10.6481 7.40767 10.9889C7.07289 11.3296 6.58266 11.5 5.93699 11.5Z"
                      fill="#031831"
                    />
                    <path
                      d="M9.27377 3.61111H11.151L11.988 9.17778H12.0119L12.8488 3.61111H14.726V11.3889H13.4825V5.5H13.4586L12.5021 11.3889H11.4021L10.4455 5.5H10.4216V11.3889H9.27377V3.61111Z"
                      fill="#031831"
                    />
                    <path
                      d="M18.0152 11.5C17.3775 11.5 16.8952 11.3333 16.5684 11C16.2416 10.6593 16.0782 10.1741 16.0782 9.54444V9.1H17.3217V9.63333C17.3217 10.137 17.5489 10.3889 18.0032 10.3889C18.2264 10.3889 18.3938 10.3296 18.5054 10.2111C18.625 10.0852 18.6848 9.88519 18.6848 9.61111C18.6848 9.28519 18.605 9 18.4456 8.75556C18.2862 8.5037 17.9913 8.2037 17.5608 7.85556C17.0188 7.41111 16.6402 7.01111 16.4249 6.65556C16.2097 6.29259 16.1021 5.88519 16.1021 5.43333C16.1021 4.81852 16.2695 4.34444 16.6043 4.01111C16.9391 3.67037 17.4253 3.5 18.063 3.5C18.6927 3.5 19.167 3.67037 19.4859 4.01111C19.8127 4.34444 19.9761 4.82593 19.9761 5.45556V5.77778H18.7326V5.37778C18.7326 5.11111 18.6768 4.91852 18.5652 4.8C18.4536 4.67407 18.2902 4.61111 18.075 4.61111C17.6365 4.61111 17.4173 4.85926 17.4173 5.35556C17.4173 5.63704 17.4971 5.9 17.6565 6.14444C17.8239 6.38889 18.1228 6.68519 18.5532 7.03333C19.1032 7.47778 19.4819 7.88148 19.6891 8.24444C19.8964 8.60741 20 9.03333 20 9.52222C20 10.1593 19.8286 10.6481 19.4859 10.9889C19.1511 11.3296 18.6608 11.5 18.0152 11.5Z"
                      fill="#031831"
                    />
                  </g>
                </g>
              </svg>
            </SMSIcon>
          )}
          <Type className={userMUIconMapping[mention.type]} />
        </Icons>
      </Container>
    </>
  )
}

const Separator = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px 14px;
`

const Container = styled.div`
  min-height: 30px;
  display: flex;
  padding: 3px 14px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.basicBlueColor}10;
  }
`
const Icons = styled.div`
  display: flex;
  margin-left: auto;

  > div {
    padding-left: 4px;
  }
`
const SMSIcon = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const Type = styled.div`
  line-height: 24px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
`
const Name = styled.div`
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
`
