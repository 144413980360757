import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../styles/theme'

type Props = {
  requestReports: () => void
}

export const DriverReportFetchError = (props: Props) => {
  const { requestReports } = props

  return (
    <Container>
      <ErrorMessage>MVR/PSP Report Service doesn't&nbsp;respond&nbsp;</ErrorMessage>
      <ReloadButton children={'Reload'} onClick={requestReports} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-top: 20px;
`
const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${theme.colors.defaultRed};
  line-height: 1.2;
  margin-bottom: 5px;
`
const ReloadButton = styled.div`
  height: max-content;
  line-height: 1.2;
  color: ${theme.colors.basicBlueColor};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`
