import * as React from 'react'
import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { ActionButton } from '../UI/Buttons/ActionButton'
import { requestDriversByTerm } from '../common/drivers/epics'
import { requestCustomersByTerm } from '../common/customer/epics'
import { SelecterWidget } from '../UI/DataFields/Select/SelecterWidget'
import { DateTypes } from '../UI/DatePicker/Interfaces'
import DateField from '../UI/DatePicker/DateField'
import { FieldContainer } from '../UI/DataFields/FieldContainer'
import { Portal } from 'react-portal'
import { accountingReportAPI, callAPI, DriverNameDTO } from '../../api/api'
import { downloadWorkOrderFile } from '../common/dispatchDeliveryOrder/views/Details/CustomHeader/DownloadWorkOrder'
import { showSimpleModal } from '../UI/Modal'
import { GridItemSpinner } from '../UI/Spinner/Spinner'
import { resetTimeToZero } from '../../services/timeService/dateUtils'

type Props = {
  closePopup: () => void
}

export const Accounting = ({ closePopup }: Props) => {
  const defaultDateRange = {
    from: resetTimeToZero(new Date(Date.now() - 1000 * 60 * 60 * 24 * 31).toISOString()),
    to: resetTimeToZero(new Date(Date.now()).toISOString(), true)
  }
  const [fetch, setFetch] = React.useState(undefined)
  const [drivers, setDrivers] = React.useState(undefined)
  const [customers, setCustomers] = React.useState(undefined)
  const [activityCompletionDate, setBSQActivityCompletionDate] = React.useState(defaultDateRange)
  const [ddoCompletionDate, setDDOCompletionDate] = React.useState(defaultDateRange)

  const ddoCompletionDateError = catchDateError(ddoCompletionDate)
  const bsqActivityCompletionDateError = catchDateError(activityCompletionDate)

  function catchDateError(range: { from: string; to: string }): string {
    const { from, to } = range

    if (!from || !to) {
      return
    }

    if (Date.parse(to) < Date.parse(from)) {
      return 'Incorrect dates'
    }

    if (Math.round((Date.parse(to) - Date.parse(from)) / (1000 * 60 * 60 * 24)) > 32) {
      return 'It is impossible to specify a timeframe more than 1 month'
    }
  }

  const getAccountingReport = () => {
    setFetch(true)
    callAPI(accountingReportAPI.generateReportUrl, {
      activityCompletionDateTimeFrom: activityCompletionDate.from || undefined,
      activityCompletionDateTimeTill: activityCompletionDate.to || undefined,
      ddoCompletionDateTimeFrom: ddoCompletionDate.from || undefined,
      ddoCompletionDateTimeTill: ddoCompletionDate.to || undefined,
      vendorIds: drivers ? drivers.map(({ value }: any) => value) : undefined,
      customerIds: customers ? customers.map(({ value }: any) => value) : undefined
    })
      .toPromise()
      .then(({ url, name }) => {
        downloadWorkOrderFile({ url, name })
      })
      .catch(err => {
        // tslint:disable-next-line:no-console
        console.error(err)

        if (err.status === 404) {
          showSimpleModal('No eligible orders for this date range')
        }

        if (err.status === 422) {
          showSimpleModal('Could not generate the accounting report')
        }
      })
      .finally(() => {
        setFetch(false)
      })
  }

  return (
    <Portal>
      <AccountingPopup>
        <StyledAccounting>
          {fetch ? <GridItemSpinner /> : <CloseButton className={'mdi mdi-close'} onClick={closePopup} />}
          <Content>
            <FiltersLine>
              <FilterItem>
                <SelecterWidget
                  title={'Customer'}
                  placeholder={'Customer'}
                  label={Array.isArray(customers) ? undefined : 'All Customers'}
                  selectedItems={Array.isArray(customers) ? customers : undefined}
                  staticList={[
                    {
                      label: 'All Customers',
                      selecterValue: null,
                      isSelected: customers === null
                    }
                  ]}
                  getAllItems={() => requestCustomersByTerm('')}
                  onSelect={setCustomers}
                />
              </FilterItem>
              <FilterItem>
                <SelecterWidget
                  title={'Driver'}
                  placeholder={'Driver'}
                  label={Array.isArray(drivers) ? undefined : 'All Drivers'}
                  selectedItems={Array.isArray(drivers) ? drivers : undefined}
                  staticList={[
                    {
                      label: 'All Drivers',
                      selecterValue: null,
                      isSelected: drivers === null
                    }
                  ]}
                  getAllItems={() =>
                    requestDriversByTerm('', _ => _.driverType !== DriverNameDTO.DriverTypeEnum.VENDOR)
                  }
                  onSelect={setDrivers}
                />
              </FilterItem>
            </FiltersLine>
            <FieldContainer title={'Delivery completion date'}>
              <FiltersLine>
                <FilterItem>
                  <DateField
                    highlight={Boolean(ddoCompletionDateError)}
                    title={'From'}
                    setDayStart={true}
                    date={ddoCompletionDate.from}
                    dateType={DateTypes.DateISOString}
                    placeholder={'MM/DD/YY'}
                    onUpdate={(from: any) => setDDOCompletionDate({ ...ddoCompletionDate, from })}
                  />
                </FilterItem>
                <FilterItem>
                  <DateField
                    highlight={Boolean(ddoCompletionDateError)}
                    title={'To'}
                    setDayEnd={true}
                    date={ddoCompletionDate.to}
                    dateType={DateTypes.DateISOString}
                    placeholder={'MM/DD/YY'}
                    onUpdate={(to: any) => setDDOCompletionDate({ ...ddoCompletionDate, to })}
                  />
                </FilterItem>
              </FiltersLine>
              {Boolean(ddoCompletionDateError) && <ErrorMessage>{ddoCompletionDateError}</ErrorMessage>}
            </FieldContainer>
            <FieldContainer title={'BSQ (driver) activity completion date'}>
              <FiltersLine>
                <FilterItem>
                  <DateField
                    highlight={Boolean(bsqActivityCompletionDateError)}
                    title={'From'}
                    setDayStart={true}
                    date={activityCompletionDate.from}
                    dateType={DateTypes.DateISOString}
                    placeholder={'MM/DD/YY'}
                    onUpdate={(from: any) => setBSQActivityCompletionDate({ ...activityCompletionDate, from })}
                  />
                </FilterItem>
                <FilterItem>
                  <DateField
                    highlight={Boolean(bsqActivityCompletionDateError)}
                    title={'To'}
                    setDayEnd={true}
                    date={activityCompletionDate.to}
                    dateType={DateTypes.DateISOString}
                    placeholder={'MM/DD/YY'}
                    onUpdate={(to: any) => setBSQActivityCompletionDate({ ...activityCompletionDate, to })}
                  />
                </FilterItem>
              </FiltersLine>
              {Boolean(bsqActivityCompletionDateError) && <ErrorMessage>{bsqActivityCompletionDateError}</ErrorMessage>}
            </FieldContainer>

            <ActionButton
              disabled={Boolean(
                ddoCompletionDateError ||
                  bsqActivityCompletionDateError ||
                  (!(ddoCompletionDate.from && ddoCompletionDate.to) &&
                    !(activityCompletionDate.from && activityCompletionDate.to)) ||
                  (!ddoCompletionDate.from && ddoCompletionDate.to) ||
                  (ddoCompletionDate.from && !ddoCompletionDate.to) ||
                  (!activityCompletionDate.from && activityCompletionDate.to) ||
                  (activityCompletionDate.from && !activityCompletionDate.to)
              )}
              className={'mdi mdi-download'}
              filled={true}
              onClick={getAccountingReport}
            >
              Download File
            </ActionButton>
          </Content>
        </StyledAccounting>
      </AccountingPopup>
    </Portal>
  )
}

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${theme.colors.defaultGray};
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`
const AccountingPopup = styled.div`
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`
const StyledAccounting = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
  background-color: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: default;
`
const Content = styled.div`
  padding: 35px;
`
const FiltersLine = styled.div`
  display: flex;
  margin-bottom: 30px;
`
const FilterItem = styled.div`
  width: 230px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`
const ErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  margin-bottom: 20px;
`
