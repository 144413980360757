import * as React from 'react'
// @ts-ignore
import { debounce } from 'debounce'
import { ApplicationSizeContext, IApplicationSizeContext } from '../contexts/ApplicationSizeContext'
import { useAppSelector } from './useAppSelector'
import {
  selectCommunictaionHubIsExpanded,
  selectCommunictaionHubIsVisible
} from '../store/select/communicationHubSelect'
import { CommunicationHubConstants } from '../constants/CommunicationHubConstants'
import { debuggingMode } from '../services/debug'

export const useApplicationSize = () => React.useContext(ApplicationSizeContext)

export const useApplicationSizeLocaly = (props: { rerenderOnResize?: boolean }): IApplicationSizeContext => {
  const { rerenderOnResize } = props
  const isCommunicationHubExpanded = useAppSelector(selectCommunictaionHubIsExpanded)
  const isCommunicationHubVisible = useAppSelector(selectCommunictaionHubIsVisible)
  const [resize, updateResize] = React.useState(0)
  let communicationHubWidth = 0

  if (isCommunicationHubVisible) {
    communicationHubWidth += CommunicationHubConstants.navigationViewWidth

    if (isCommunicationHubExpanded) {
      communicationHubWidth += CommunicationHubConstants.channelViewWidth
    }
  }

  if (rerenderOnResize) {
    React.useEffect(() => {
      const updateResizeFn = debounce(() => updateResize(state => state + 1), 500)
      window.addEventListener('resize', updateResizeFn)

      return () => {
        window.removeEventListener('resize', updateResizeFn)
      }
    }, [])
  }

  return {
    resize,
    applicationWidth: window.innerWidth - communicationHubWidth,
    applicationHeight: window.innerHeight,
    communicationHubWidth: debuggingMode.hideCommunicationHub ? 0 : communicationHubWidth,
    isCommunicationHubExpanded: debuggingMode.hideCommunicationHub ? false : isCommunicationHubExpanded
  }
}
