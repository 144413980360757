import * as React from 'react'
import { DeliveryOrderView } from '../common/deliveryOrder/views'
import { DeliveryOrderTabProvider } from '../../providers/DeliveryOrderTabProvider'
import { useExpandedItem } from '../../hooks/useExpandedItem'

type Props = {}

export const DeliveryOrderPage = (props: Props) => {
  const { parentItemId, modifiedLists } = useExpandedItem()

  return (
    <DeliveryOrderTabProvider id={parentItemId} modifiedLists={modifiedLists}>
      <DeliveryOrderView />
    </DeliveryOrderTabProvider>
  )
}
