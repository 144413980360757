import { createSelectListFromDirectory } from './index'
import { customerDirectory } from '../DTO/customer/directory'

const netTerms = createSelectListFromDirectory(customerDirectory.netTerms)
const netTermsUnit = createSelectListFromDirectory(customerDirectory.netTermsUnit)

export const customerLists = {
  netTerms,
  netTermsUnit
}
