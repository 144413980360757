import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { createId } from '../../../../services/utils'
import { ICustomerQuotesState } from '../interfaces/'
import { addCustomerQuotesToStore } from '../actions'
import { CustomerQuoteDTO } from '../../../../api/origin/qmp-service'
import { TNewCustomerQuotes } from '../interfaces'
import { maxCompleteness } from '../../../../constants/completeness'

type TCreateCustomerQuote = {
  customerId: any
  deliveryPostalCode: string
  deliveryStateId: string
  deliveryCity: string
  pickupLocationId: string
  returnLocationId: string
  containerTypeId: string
  deliveryOrderType: CustomerQuoteDTO.DeliveryOrderTypeEnum
  loadType: CustomerQuoteDTO.LoadTypeEnum
  effectiveDate?: string
  // containerTypeId: string[]
  // impExpIndicator: CustomerQuoteDTO.ImpExpIndicatorEnum[]
  // loadType: CustomerQuoteDTO.LoadTypeEnum[]
}
export const createCustomerQuote = (props: TCreateCustomerQuote): TNewCustomerQuotes => ({
  ...props,
  id: createId(),
  status: CustomerQuoteDTO.StatusEnum.NEW,
  number: undefined,
  type: null,
  effectiveDate: props.effectiveDate,
  expirationDate: null,
  surcharges: '',
  // @ts-ignore
  completeness: maxCompleteness,
  rateIds: [],
  amount: 0,
  selectedSSQGroupsOfRates: [],
  selectedBSQGroupsOfRates: [],
  bsqAmount: 0
})

const customerQuotesState: ICustomerQuotesState = {}

export const customerQuotesReducer = reducerWithInitialState<ICustomerQuotesState>(customerQuotesState)
  .case(addCustomerQuotesToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()
