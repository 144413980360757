import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../utils'

export const testEntityVersion = (store: TListsState, entityType: EntityType) => (testItem: any): boolean => {
  if (!(store && entityType && store[entityType] && testItem && (testItem.updatedAt || testItem.version))) {
    return true
  }

  if (isNewItem(testItem)) {
    return true
  }

  const storeItem: any = oc(store)[entityType][testItem.id]()

  if (!storeItem) {
    return true
  }

  if (typeof testItem.version === 'number') {
    return testItem.version >= oc(storeItem).version(-1)
  }

  return testItem.updatedAt >= oc(storeItem).updatedAt('')
}
