import { whatIsIt } from '../test/whatIsIt'

export const processObject = (object: any) => {
  if (!object) {
    return {
      clean: object,
      hardClean: object
    }
  }

  const omitEmptyValues = (obj: object, hard: boolean) => {
    return Object.keys(obj || {}).reduce((acc, key) => {
      let value = obj[key]

      if (value === null || value === undefined) {
        return acc
      }

      if (whatIsIt(value).isObject) {
        value = omitEmptyValues(value, hard)

        if (hard) {
          if (Object.keys(value).length) {
            acc[key] = value
          }
        } else {
          acc[key] = value
        }
      } else {
        acc[key] = value
      }

      return acc
    }, {})
  }

  return {
    get clean() {
      return omitEmptyValues(object, false)
    },
    get hardClean() {
      return omitEmptyValues(object, true)
    }
  }
}
