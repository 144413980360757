import { combineReducers } from 'redux'
import { IStore } from '../store.interface'
import modal from '../../components/UI/Modal/reducers'
import { applicationContextReducer } from '../../services/applicationContextService/reducers'
import {
  authorizationReducer,
  actionsToDispatchAfterSuccessfulAuthorizationReducer
} from '../../services/authService/reducers/authServiceReducers'
import { customerQuotesReducer } from '../../services/DTO/customerQuote/reducer'
import { streetTurnReducer } from '../../services/DTO/streetTurn/reducers'

export const rootReducer = () =>
  combineReducers<IStore>({
    applicationContext: applicationContextReducer,
    authorization: authorizationReducer,
    actionsToDispatchAfterSuccessfulAuthorization: actionsToDispatchAfterSuccessfulAuthorizationReducer,
    streetTurn: streetTurnReducer,
    customerQuotes: customerQuotesReducer,
    modal
  })
