import { debuggingMode } from '../../debug'
import { schemaSteamShipLineView } from '../../yupScheme'
import { SteamShipLineViewDTO } from '../../../api/api'

export const isSteamShipLineValid = (steamshipLine: SteamShipLineViewDTO): boolean => {
  if (debuggingMode.common) {
    schemaSteamShipLineView.validate(steamshipLine).catch(e => {
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkSSLValidation', e)
    })
  }

  return schemaSteamShipLineView.isValidSync(steamshipLine)
}
