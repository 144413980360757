import styled from 'styled-components'
import { theme } from '../../../../styles/theme'
import { withProps } from '../../../../styles/styledComponents'

export const ButtonClear = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: none;
  font-size: 18px;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.25s;
  padding: 0 2px;
  margin-left: auto;

  &:hover {
    color: #445366;
  }
`
export const ButtonToggle = withProps<{ active: boolean }, HTMLDivElement>(styled.div)`
  position: relative;
  width: 33px;
  height: 15px;
  border-radius: 7px;
  background: rgba(${({ active }) => (active ? '69, 85, 208' : '189, 189, 189')}, 0.4);
  transition: 0.25s;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: ${({ active }) => (active ? 'calc(100% - 20px)' : 0)};
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: 0.25s;
    background: rgb(${({ active }) => (active ? '69, 85, 208' : '189, 189, 189')});
  }
`
export const StyledSelectedPopover = styled.div`
  width: 325px;
  z-index: 99999;
  display: none;
  position: absolute;
  left: 0;
  bottom: calc(100% + 15px);
  cursor: default;

  > div {
    background-color: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid ${theme.colors.darkBorderColor};
    padding: 8px;
  }
`
export const SelectedPopoverItem = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;

  &:not(:last-child):after {
    content: ',';
  }
`
export const SingleValue = styled.div`
  max-height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const StyledSelect = styled.div`
  position: relative;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 4px;

  &.disabled {
    border: 1px solid rgba(229, 229, 229, 0.4);
  }

  &:not(.opened) {
    &.highlighted {
      box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
    }

    &:hover {
      ${StyledSelectedPopover} {
        display: flex;
      }

      &:not(.hide-popover).hasPopover {
        box-shadow: 0 0 0 1px ${theme.colors.basicBlueColor};

        & + label {
          color: ${theme.colors.basicBlueColor};
        }
      }
    }
  }
`

export const Selected = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 33px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 20px 0 10px;

  &:before {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 10px;
    color: #4f4f4f;
    font-size: 24px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
  }

  &.disabled {
    cursor: default;

    &:before {
      content: none;
    }
  }

  &.placeholder {
    color: rgba(51, 51, 51, 0.3);
  }
`
export const LabelBox = styled.div`
  color: white;
  background-color: ${theme.colors.basicBlueColor};
  display: inline-flex;
  border-radius: 4px;
  padding: 2px 4px;
`
export const LabelBoxClearButton = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  font-size: 15px;
  cursor: pointer;
  margin-left: 3px;
`
export const StyledDropdown = styled.div`
  z-index: 2;
  max-height: 350px;
  background-color: white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px 0;
  overflow: auto;
  user-select: none;
`
export const Group = styled.div`
  position: relative;

  &:not(:last-child):not(:empty):before {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    height: 1px;
    background-color: #e5e5e5;
  }
`
export const DropdownItem = styled.div`
  &:hover {
    background-color: #f5f6fa;
  }
`
export const DropdownItemValue = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #323e4e;
  padding: 5px 8px;
  cursor: pointer;

  &.disabled {
    color: rgba(50, 62, 78, 0.5);
    cursor: default;
  }
`
export const InputSearchContainer = styled(Group)`
  z-index: 1;
  position: sticky;
  top: 0;
  background: white;
  padding: 15px 8px;
`
export const InputSearch = styled.input`
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 14px;
  font-weight: 400 !important;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0 8px;

  &::placeholder {
    color: #bdbdbd;
  }
`
export const StyledMultiselectValue = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    line-height: 18px;
    background: ${theme.colors.basicBlueColor};
    font-weight: 500;
    font-size: 14px;
    color: #f5f6fa;
    border-radius: 2px;
    padding: 0 5px;
  }
`
