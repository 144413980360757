import * as React from 'react'
import { General } from '../Details/General'
import { ActivitiesTab } from '../Details/Activities'
import { DispatchDeliveryOrderDocuments } from '../Details/Documents'
import { Description } from '../Details/Description'
import { AuditTrail } from '../Details/AuditTrail'
import { TerminalNotes } from '../Details/TerminalNotes'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.DispatchDeliveryOrder

export const DispatchDeliveryOrderPreview = {
  headings: [T.General, T.Activities, T.Description, T.AuditTrail, T.TerminalNotes],
  tabs: {
    [T.General]: <General />,
    [T.Activities]: <ActivitiesTab />,
    [T.Documents]: <DispatchDeliveryOrderDocuments />,
    [T.Description]: <Description />,
    [T.AuditTrail]: <AuditTrail />,
    [T.TerminalNotes]: <TerminalNotes />
  },
  isValid: checkValidation.dispatchDeliveryOrder
}
