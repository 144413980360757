import * as React from 'react'
import { oc } from 'ts-optchain'
import { Window } from './preview'
import { Popover } from './popover'
import { Widget } from '../../'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

type OwnProps = {}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const EquipmentWidget = () => {
  const { isImport, isChassisNumberRequired, dispatchDeliveryOrder, equipment } = useGridItemDDO()

  return (
    <Widget
      title={'Equipment'}
      highlighted={
        (isImport
          ? !oc(dispatchDeliveryOrder).containerTypeId() || !oc(dispatchDeliveryOrder).containerId()
          : !oc(dispatchDeliveryOrder).containerTypeId()) ||
        (isChassisNumberRequired && !equipment)
      }
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={undefined}
      detailsProps={undefined}
    />
  )
}

export default EquipmentWidget
