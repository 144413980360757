import * as React from 'react'
import styled from 'styled-components'
import { DriverReportDTO } from '../../../../api/origin/document-service'
import { dateService } from '../../../../services/timeService'
import { StyledFileUploaderButton } from '../FileUploader/styles'
import { openFileLink } from '../../../../services/filesService'

type Props = {
  reportHistory: DriverReportDTO[]
}

export const DriverReportHistory = (props: Props) => {
  const { reportHistory } = props

  return (
    <Container>
      <Header>Report Date</Header>
      <Header>Report</Header>

      {Boolean(reportHistory.length) && (
        <>
          {reportHistory.map(
            report =>
              Boolean(report.url) && (
                <React.Fragment key={report.id}>
                  <DateColumn>{dateService.makeLabel(report.date)}</DateColumn>
                  <FileColumn>
                    <StyledFileUploaderButton
                      style={{ width: 70 }}
                      className={'mdi mdi-eye'}
                      onClick={() => openFileLink(report.url)}
                    >
                      View
                    </StyledFileUploaderButton>
                  </FileColumn>
                </React.Fragment>
              )
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 20px;
  margin-top: -10px;
`

const Header = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  color: #6c707d;
  font-size: 12px;
  user-select: none;

  &:first-child {
    position: relative;
    padding-left: 4px;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #e5e5e5;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -10px;
    }
  }
`

const DateColumn = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  color: #6c707d;
  font-size: 14px;
  border-radius: 4px;
  background: rgba(239, 240, 242, 0.8);
  padding: 8px;
`

const FileColumn = styled.div``
