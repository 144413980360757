import { GridColumn } from '../../../../../UI/GridSystem'
import theme, { IStyledComponentsProps } from '../../../../../../styles/theme'
import styled, { withProps } from '../../../../../../styles/styledComponents'

export const StyledGeneral = styled(GridColumn)`
  font-size: 14px;
  user-select: none;
`

export const Title = styled(GridColumn)`
  align-items: center;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  padding-left: 10px;
  margin-bottom: 20px;
`

export const Date = styled(GridColumn)`
  > div {
    width: 100%;
  }
`

export const AddContact = styled(GridColumn)`
  align-items: center;
  justify-content: center;
  height: 45px;
  max-height: 45px;
  min-height: 45px;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 5px;
  color: rgba(51, 51, 51, 0.5);
  cursor: pointer;

  &:hover {
    color: white;
    background: ${theme.colors.basicBlueColor};
    border-color: ${theme.colors.basicBlueColor};
  }
`
export const StyledAddressField = withProps<IStyledComponentsProps, HTMLDivElement>(styled(GridColumn))`
  &.open {
    > div {
      &:first-child {
        border-radius: 5px 5px 0 0;
        box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
      }

      &:last-child {
        z-index: 1;
        background: white;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
      }
    }
  }
`
