import * as React from 'react'
import { oc } from 'ts-optchain'
import { Container, ColumnRow } from '../../../../../UI/GridSystem'
import { Title } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { AddressDTO, CustomerViewDTO } from '../../../../../../api/origin/business-logic/api'
import AddressField from '../../../../../UI/AddressField'
import { CustomerSearch } from '../../../../../UI/DataFields/SearchRequest'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemSubClient } from '../../../../../../hooks/useGridItemSubClient'

type OwnProps = {}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const CustomerInfo = React.memo(() => {
  const { businessPartner, subClient } = useGridItemSubClient()
  const { modifyListItems, modifyParentObjectField } = useExpandedItem()

  return (
    <Container isGrid={true} margin={{ right: 40 }}>
      <Title>Customer Info</Title>
      <ColumnRow margin={{ bottom: 25 }}>
        <ColumnRow columns={20} flexBasis={'auto'} margin={{ right: 25 }}>
          <Input
            title={'Name'}
            required={true}
            value={businessPartner.legalName}
            onChange={(legalName: string) => {
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, legalName }] })
            }}
          />
        </ColumnRow>
        <ColumnRow columns={10} flexBasis={'auto'}>
          <CustomerSearch
            title={'Customer'}
            id={oc(subClient).customerId()}
            required={true}
            onChange={(_customer: CustomerViewDTO) => {
              modifyParentObjectField('customerId')(oc(_customer).id())
            }}
          />
        </ColumnRow>
      </ColumnRow>

      <ColumnRow>
        <ColumnRow columns={30} flexBasis={'auto'}>
          <AddressField
            title={'Address Search'}
            required={true}
            address={businessPartner.legalAddress}
            onChange={(legalAddress: AddressDTO) => {
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, legalAddress }] })
            }}
          />
        </ColumnRow>
      </ColumnRow>
    </Container>
  )
})
