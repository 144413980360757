import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderViewDTO } from '../../api/api'
import { requestUpdateDispatchDeliveryOrder } from '../../components/common/dispatchDeliveryOrder/epics'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO | undefined
}

export const saveDispatchDeliveryOrder = async (props: Props): Promise<DispatchDeliveryOrderViewDTO> => {
  const { dispatchDeliveryOrder } = props
  let savedDispatchDeliveryOrder = dispatchDeliveryOrder

  if (dispatchDeliveryOrder) {
    savedDispatchDeliveryOrder = await requestUpdateDispatchDeliveryOrder(dispatchDeliveryOrder)
  }

  return savedDispatchDeliveryOrder
}
