import * as React from 'react'
import cn from 'classnames'
import { StickiedLabel } from '../styledComponents'
import { DateGroupBody, DateGroupHeader, Group, GroupHeader } from './Group/styles'
import { OngoingActivityRow } from './Group/OngoingActivityRow/OngoingActivityRow'
import { OngoingActivityGroupDTO, TransportationActivityDTO } from '../../../../../api/origin/business-logic'
import { sortOngoingActivityGroups } from '../../functions/sortOngoingActivityGroups'
import { groupTitleWithoutDataMapping } from '../../functions/generateGroupTitleWithoutData'
import { SchedulerSortBy } from '../../interfaces'
import { dateService } from '../../../../../services/timeService'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectDrivers } from '../../../../../store/select/driverSelect'
import { selectUndefined } from '../../../../../store/select/undefinedSelect'

type OwnProps = {
  title: string
  activityGroups: OngoingActivityGroupDTO[]
  sortBy: SchedulerSortBy
  expanded: boolean
  onToggle: () => void
  labelBackgroundColor?: string
  withoutLabel?: boolean
  isMissed?: boolean
  isNowSection?: boolean
}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const CollapsibleGroup = React.memo((props: Props) => {
  const {
    title,
    labelBackgroundColor,
    onToggle,
    sortBy,
    expanded,
    activityGroups,
    withoutLabel,
    isMissed,
    isNowSection
  } = props
  const driverMapping = useAppSelector(sortBy === SchedulerSortBy.deliveryStageDriver ? selectDrivers : selectUndefined)
  let sortedListWithTargetData: Record<string, OngoingActivityGroupDTO[]> = {}
  let sortedListWithoutTargetData: OngoingActivityGroupDTO[] = []

  const RenderRow = React.useCallback(
    (missedActivity: OngoingActivityGroupDTO) => (
      <OngoingActivityRow
        key={missedActivity.id}
        isMissed={isMissed}
        isNowSection={isNowSection}
        activityGroup={missedActivity}
      />
    ),
    []
  )

  if (!(activityGroups && activityGroups.length)) {
    return null
  }

  if (expanded) {
    if (!withoutLabel) {
      if (sortBy !== SchedulerSortBy.date) {
        const sortedActivityGroups = sortOngoingActivityGroups(sortBy, driverMapping)(activityGroups)
        sortedListWithTargetData = sortedActivityGroups.sortedListWithTargetData
        sortedListWithoutTargetData = sortedActivityGroups.sortedListWithoutTargetData
      }
    }
  }

  return (
    <Group>
      <DateGroupHeader
        style={labelBackgroundColor ? { backgroundColor: labelBackgroundColor } : undefined}
        className={cn('expandable', { expanded })}
        onClick={onToggle}
      >
        <StickiedLabel className={'left'}>
          <i className={`mdi mdi-chevron-${expanded ? 'up' : 'down'}`} />
          {title + ': ' + activityGroups.length}
        </StickiedLabel>
      </DateGroupHeader>
      {expanded && (
        <DateGroupBody>
          {withoutLabel
            ? activityGroups.map(RenderRow)
            : (() => {
                switch (sortBy) {
                  case SchedulerSortBy.date:
                    return activityGroups
                      .sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
                      .map(ongoingActivityGroup => {
                        const isCurrentDay = dateService.isCurrentDay(ongoingActivityGroup.activityGroupDate)
                        let ongoingActivity = [
                          ongoingActivityGroup.pickupActivity,
                          ongoingActivityGroup.deliveryActivity,
                          ongoingActivityGroup.returnActivity
                        ]
                          .filter(Boolean)
                          .find(
                            activity =>
                              activity.status === TransportationActivityDTO.StatusEnum.INPROCESS &&
                              activity.startActualDate
                          )

                        if (!ongoingActivity) {
                          if (
                            ongoingActivityGroup.dispatchDeliveryOrderStatus ===
                            OngoingActivityGroupDTO.DispatchDeliveryOrderStatusEnum.INPROCESSPICKUP
                          ) {
                            ongoingActivity = ongoingActivityGroup.pickupActivity
                          } else if (
                            ongoingActivityGroup.dispatchDeliveryOrderStatus ===
                            OngoingActivityGroupDTO.DispatchDeliveryOrderStatusEnum.INPROCESSDELIVERY
                          ) {
                            ongoingActivity = ongoingActivityGroup.deliveryActivity
                          } else if (
                            ongoingActivityGroup.dispatchDeliveryOrderStatus ===
                            OngoingActivityGroupDTO.DispatchDeliveryOrderStatusEnum.INPROCESSRETURN
                          ) {
                            ongoingActivity = ongoingActivityGroup.returnActivity
                          }
                        }

                        // TODO INPROCESS || Complete ???
                        return ongoingActivity ? (
                          <React.Fragment key={ongoingActivityGroup.id}>
                            <GroupHeader>
                              <StickiedLabel className={'left'}>
                                {dateService.makeLabel(
                                  ongoingActivity.startActualDate,
                                  isCurrentDay
                                    ? { onlyTime: true }
                                    : {
                                        hideWeekday: true,
                                        hideYear: true,
                                        monthName: 'short'
                                      }
                                )}
                              </StickiedLabel>
                            </GroupHeader>
                            {RenderRow(ongoingActivityGroup)}
                          </React.Fragment>
                        ) : null
                      })
                  case SchedulerSortBy.deliveryStageDriver:
                  case SchedulerSortBy.deliveryLocationCity:
                    return Object.keys(sortedListWithTargetData)
                      .sort((a, b) => a.localeCompare(b))
                      .map((label, index) => (
                        <React.Fragment key={label}>
                          <GroupHeader className={index !== 0 ? 'extra-top-margin' : ''}>
                            <StickiedLabel className={'left'}>{label}</StickiedLabel>
                          </GroupHeader>
                          {sortedListWithTargetData[label].map(RenderRow)}
                        </React.Fragment>
                      ))
                  default:
                    return null
                }
              })()}
          {Boolean(sortedListWithoutTargetData.length) && (
            <>
              <GroupHeader className={'extra-top-margin'}>
                <StickiedLabel className={'left'}>{groupTitleWithoutDataMapping[sortBy] || 'Other'}</StickiedLabel>
              </GroupHeader>
              {sortedListWithoutTargetData.map(RenderRow)}
            </>
          )}
        </DateGroupBody>
      )}
    </Group>
  )
})
