import { ContactDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.contact]: ContactDTO[]
}

export const parseContacts = async (contacts: ContactDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.contact]: []
  }

  try {
    contacts.forEach(contact => {
      result[EntityType.contact].push(contact)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
