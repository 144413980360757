import { oc } from 'ts-optchain'
import { AddressDTO } from '../../../api/api'
import { getStateMapping } from '../../../store'

const getStateCode = (stateId: string): string => {
  let stateCode = undefined

  if (stateId) {
    const state = getStateMapping()[stateId]
    stateCode = oc(state).code()
  }

  return stateCode
}

export const generateAddressLabel = (address: AddressDTO): string => {
  if (!address) {
    return ''
  }

  const { stateId, postalCode, city, street } = address
  const stateCode = getStateCode(stateId)

  return [street, city, stateCode, postalCode].filter(Boolean).join(', ')
}

export const generateAddressMultilineLabel = (
  address: AddressDTO
): {
  street: string
  postalCode: string
} => {
  if (!address) {
    return {
      street: '',
      postalCode: ''
    }
  }

  const { stateId, postalCode, city, street } = address
  const stateCode = getStateCode(stateId)

  return {
    street: [street, city].filter(Boolean).join(', '),
    postalCode: [stateCode, postalCode].filter(Boolean).join(', ')
  }
}
