import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { convertFromRaw, RawDraftContentState, RawDraftEntity } from 'draft-js'
import HtmlReactParser, { domToReact } from 'html-react-parser'
import { convertToHTML } from 'draft-convert'
import * as DOMPurify from 'dompurify'
import { Element } from 'domhandler'
import { MentionLabel } from '../Editor/MentionLabel'
import { convertMentionsToEntityMap } from '../../functions/mentions'
import { TroubleTicketDTO } from '../../../../api/origin/business-logic'
import { MessageDTO, MessageType } from '../../../../api/origin/communication-hub-service'
import theme from '../../../../styles/theme'

type OwnProps = {
  type: MessageType
  text: string | RawDraftContentState
  mentionUserIds: string[]
  bottomSection?: JSX.Element | JSX.Element[]
  status?: MessageDTO.SmsStatusEnum | MessageDTO.StatusEnum
  alertDocumentType?: TroubleTicketDTO.TypeEnum
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const MessageBody = React.memo((props: Props) => {
  const { type, status, alertDocumentType, text, mentionUserIds, bottomSection } = props

  const messageContent = React.useMemo(() => {
    switch (true) {
      case !text:
        return ''
      case typeof text === 'string':
        return text
      case Boolean(oc(text as RawDraftContentState).blocks([]).length):
        const textToUse = convertMentionsToEntityMap(
          text as RawDraftContentState,
          mentionUserIds,
          type,
          alertDocumentType
        )
        const draftJSData = convertFromRaw(textToUse)

        if (draftJSData && draftJSData.hasText()) {
          return HtmlReactParser(
            DOMPurify.sanitize(convertToHTML(convertToHTMLOptions)(draftJSData as any)),
            convertFromHTMLOptions
          )
        }

        return ''
      default:
        return ''
    }
  }, [text])

  return (
    <Container className={cn(type, status)}>
      <>
        {messageContent}
        {bottomSection}
      </>
    </Container>
  )
})

const Container = styled.div`
  font-size: 14px;
  line-height: 1.75;

  * {
    line-height: inherit;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  code {
    font-family: monospace;
    overflow-wrap: break-word;
  }

  &.${MessageType.ALERT} {
    position: relative;
    font-size: 12px;
    padding: 8px 14px 12px 24px;
    border-radius: 5px;
    background: #f2f3f7;

    &:before {
      content: '';
      display: block;
      width: 2px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 1px;
      background-color: #6973f6;
    }

    &.${TroubleTicketDTO.StatusEnum.NEW} {
      background: #ff5e5e0f;

      &:before {
        background-color: ${theme.colors.defaultRed};
      }
    }

    strong {
      font-weight: 500;
    }
  }
`
//
// const convertFromHTMLOptions: any = {
//   replace: (node: Element) => {
//     if (node.name === 'a') {
//       return (
//         <a href={node.attribs.href} target="_blank">
//           {domToReact(node.children, convertFromHTMLOptions)}
//         </a>
//       )
//     }
//     if (node.name === 'span' && node.attribs.class && node.attribs.class.includes('mention-container')) {
//       const tooltipContent = (node.children as Element[]).find(
//         _ => _.attribs && _.attribs.class && _.attribs.class.includes('tooltip-content')
//       )
//
//       const ref = React.createRef<Hint>()
//       return (
//         <span onMouseEnter={() => ref.current!.show()} onMouseLeave={() => ref.current!.hide()}>
//           <Hint
//             ref={ref}
//             content={<>{domToReact([tooltipContent])}</>} // render with JSX
//             place={'top'}
//             targetMoves={true}
//             events={[]}
//           >
//             <span className={node.attribs.class}>{domToReact(node.children, convertFromHTMLOptions)}</span>
//           </Hint>
//         </span>
//       )
//     }
//   }
// }

const convertToHTMLOptions = {
  entityToHTML: (entity: RawDraftEntity, originalText: string) => {
    if (entity.type === 'mention') {
      return (
        <MentionLabel mention={entity.data.mention} fromMessage={true}>
          {originalText}
        </MentionLabel>
      )
    }
    if (entity.type === 'LINK') {
      return <a href={entity.data.href}>{originalText}</a>
    }
    return originalText
  }
}

const convertFromHTMLOptions: any = {
  replace: (node: Element) => {
    if (node.name === 'a') {
      return (
        <a href={node.attribs.href} target="_blank">
          {domToReact(node.children, convertFromHTMLOptions)}
        </a>
      )
    }
    if (node.name === 'span' && node.attribs.class && node.attribs.class.includes('mention-container')) {
      const tooltipContent = (node.children as Element[]).find(
        _ => _.attribs && _.attribs.class && _.attribs.class.includes('tooltip-content')
      )

      // const ref = React.createRef<Hint>()
      return (
        <span className={node.attribs.class}>{domToReact(node.children, convertFromHTMLOptions)}</span>
        // <span onMouseEnter={() => ref.current!.show()} onMouseLeave={() => ref.current!.hide()}>
        //   <Hint
        //     ref={ref}
        //     content={<>{domToReact([tooltipContent])}</>} // render with JSX
        //     place={'top'}
        //     targetMoves={true}
        //     events={[]}
        //   >
        //
        //   </Hint>
        //   <span className={node.attribs.class}>{domToReact(node.children, convertFromHTMLOptions)}</span>
        // </span>
      )
    }
  }
}
