import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { CargoDTO, HazmatDTO, DispatchDeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { Window } from './preview'
import { Popover } from './popover'
import { schemaCargo } from '../../../../../services/DTO/deliveryOrder'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

type Props = {}

export type CargoType = {
  description: string
  weight: React.ReactText
  weightUnit: string | DispatchDeliveryOrderViewDTO.WeightUnitEnum
  referenceNumber: string
  overweight: boolean
}

const Cargo = () => {
  const {
    enableEditing,
    dispatchDeliveryOrder,
    deliveryOrder,
    isReefer,
    modifyDeliveryOrderField,
    modifyDispatchDeliveryOrder,
    modifyDispatchDeliveryOrderField
  } = useGridItemDDO()
  const cargo = {
    description: oc(deliveryOrder).cargo.description(''),
    weight: dispatchDeliveryOrder.weight || '',
    weightUnit: dispatchDeliveryOrder.weightUnit || '',
    referenceNumber: oc(deliveryOrder).cargo.referenceNumber(''),
    overweight: dispatchDeliveryOrder.overweightIndicator
  }
  const disabled = enableEditing === false

  return (
    <Widget
      title={'Cargo'}
      highlighted={
        !schemaCargo.isValidSync(cargo) || (dispatchDeliveryOrder.hazmatIndicator && !dispatchDeliveryOrder.hazmatId)
      }
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{ cargo }}
      detailsProps={{
        disabled,
        reefer: isReefer,
        cargo,
        updateCargo: (key: keyof CargoDTO) => (value: any) => {
          modifyDeliveryOrderField('cargo')({ ...deliveryOrder.cargo, [key]: value })
        },
        dispatchDeliveryOrder,
        updateDispatchDeliveryOrder: modifyDispatchDeliveryOrderField,
        updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => {
          modifyDispatchDeliveryOrder({ ...dispatchDeliveryOrder, ...fields })
        },
        updateHazmat: (hazmat: HazmatDTO) => {
          modifyDispatchDeliveryOrder({ ...dispatchDeliveryOrder, hazmatId: oc(hazmat).id() })
        }
      }}
    />
  )
}

export default Cargo
