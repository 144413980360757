import styled from 'styled-components'
import { theme } from '../../../../styles/theme'

export const TableHeaderRow = styled.div`
  z-index: 350;
  position: relative;
  display: inline-flex;
  height: 30px;
  align-items: stretch;
  flex-shrink: 0;
  min-width: 100%;
  background-color: white;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.07);

  &.table__header_static {
  }

  &.table__header_highlighted {
    background-color: ${theme.colors.rowHoverColor};
  }
`
export const TableRowColumns = styled.div`
  height: 40px;
  align-items: stretch;
  display: inline-flex;
  flex-shrink: 0;
  min-width: 100%;
  background-color: #f5f6fa;
  cursor: pointer;

  &.highlight-red {
    background-color: #ffeaea !important;
    box-shadow: inset 0 0 0 1px ${theme.colors.defaultRed}, inset 3px 0 0 0 ${theme.colors.defaultRed} !important;
  }
`
export const TablePreview = styled.section`
  z-index: 1;
  border-bottom: 1px solid ${theme.colors.basicBorderColor};
`
export const TableHeaderCell = styled.div`
  position: relative;
  display: inline-flex;
  align-items: stretch;
  flex-shrink: 0;
  color: rgb(108, 112, 125);
  font-size: 10px;
  min-width: 80px;

  &:first-child {
    min-width: unset;
  }

  &.column-0 > div:before {
    content: none;
  }

  &.primary {
    z-index: 999999;
  }
`
export const IconCopy = styled.i`
  display: none;
  color: #b2b7c9;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:active {
    color: #27ae60;
  }

  &:hover {
    color: #4555d0;
  }
`
export const TableCell = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 80px;
  max-height: 100%;
  line-height: 1.2;
  padding: 3px 10px;
  overflow: hidden;

  &:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: rgb(242, 242, 242);
  }

  &:first-child {
    min-width: unset;
  }

  &:hover ${IconCopy} {
    display: block;
    opacity: 0;
    animation: appearElement 0.25s 0.1s forwards;
  }

  > div {
    width: 100%;
    max-height: 100%;
  }
`
export const TableRow = styled.div`
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: 100%;
  color: rgb(68, 83, 102);
  font-size: 12px;

  &.table__row_full-height {
    height: calc(100% - 30px);
    margin-top: -3px;

    .table__row-details {
      height: calc(100% - 40px);

      .grid-preview {
        height: 100%;
      }
    }
  }

  &.table__row_customer-quote.isPopup {
    z-index: 1;
    min-width: unset;
    position: fixed;
    top: 248px;
    left: 0;
    bottom: 0;
  }

  &.table__row_dark ${TableRowColumns} {
    background-color: rgb(252, 253, 255);
  }

  &.table__row_disabled {
    background: linear-gradient(black 40px, transparent 40px, transparent 100%);

    ${TableRowColumns} {
      cursor: default;
      opacity: 0.8;
    }
  }

  &.table__row_selected {
    ${TableRowColumns} {
      cursor: default;
      background: ${theme.colors.rowHoverColor};
    }

    ${TableCell} {
      cursor: default;
    }
  }

  &.table__row_opened {
    z-index: 1;

    ${TableRowColumns} {
      background: ${theme.colors.rowHoverColor};
    }
  }

  &.table__row_highlighted,
  &:hover {
    ${TableRowColumns} {
      background: ${theme.colors.rowHoverColor};
    }
  }
`

export const Table = styled.div`
  min-width: 100%;
  max-height: 100%;
  z-index: 0;
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  flex-direction: column;
  table-layout: fixed;
`

export const NoWrap = styled.div`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
