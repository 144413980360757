import { SubClientColumn } from '../../../components/common/grid/columnSettings/subClientColumnSettings'
import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'

export const subClientFilterSettings = {
  [SubClientColumn.Name]: {
    name: 'businessPartner.legalName',
    type: ColumnDTO.Type.string
  },
  [SubClientColumn.Address]: {
    name: 'businessPartner.legalAddress',
    type: ColumnDTO.Type.string
  },
  [SubClientColumn.Customer]: {
    name: 'customer.businessPartner.legalName',
    type: ColumnDTO.Type.string
  },
  [SubClientColumn.PrimaryContact]: {
    name: 'businessPartner.primaryContact.firstName',
    type: ColumnDTO.Type.string
  }
}
