import { DateISOString, DateTimeRangeDTO, ConfirmedLocalDateTimeRangeDTO } from '../../../api/origin/business-logic'

export type DateType = DateISOString | DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO

export enum DateTypes {
  DateISOString = 'DateISOString',
  DateTimeRangeDTO = 'DateTimeRangeDTO',
  ConfirmedDateTimeRange = 'ConfirmedDateTimeRange'
}

export interface IDateFieldProps {
  id?: string
  redColor?: boolean
  dateType: DateTypes
  label?: string
  labelIcon?: any
  date?: any
  title?: string
  icon?: any
  onUpdate?(date: DateType): void
  small?: boolean
  style?: object
  minDate?: any
  maxDate?: any
  highlight?: boolean
  disabled?: boolean
  textView?: boolean
  tabIndex?: number
  placeholder?: string
  showTime?: boolean
  required?: boolean
  isTable?: boolean
  setTimeNowOnEmpty?: boolean
  setTimeMorningOnEmpty?: boolean
  setDayStart?: boolean
  setDayEnd?: boolean
}

export interface IDateFieldState {
  value: any
  showLabel?: boolean
  date?: DateType
  timeFrom?: any
  timeTo?: any
  placeholder?: string
}
