import * as React from 'react'
import styled from 'styled-components'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import { openCommunicationHubChannelByDDOId } from '../../functions/openCommunicationHubChannelByDDOId'
import { openDDOinNewTab } from '../../../../services/DTO/dispatchDeliveryOrder'
import { RouteBuilderStreetTurnButton, RouteBuilderVendorStatusButton } from './RouteBuilderMoveButton'
import { RouteBuilderMoveActivity } from './RouteBuilderMoveActivity'
import { Icon, IconName } from '../Icon'
import { dateService } from '../../../../services/timeService'
import { DateTimePickerButton, TimesOfDay } from '../../../UI/DataFields/DateTimePicker/views/DateTimePickerButton'
import { deleteMove, setMovePlannedDate, setMovesActivitiesStatus } from '../../epics/routeBuilder'
import { deliveryOrderDirectory } from '../../../../services/DTO/deliveryOrder/directory'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { useRouteBuilder } from '../../../../hooks/useRouteBuilder'
import {
  DeliveryOrderDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../services/DTO/dispatchDeliveryOrder/directory'
import { convertErrorToMessage } from '../../../../services/DTO/saveDTO'
import { RouteBuilderToastMessage, RouteBuilderToastMessageType } from './RouteBuilderToastMessage'
import { checkWeatherContainerTypeIsReefer } from '../../../../services/DTO/containerType/functions'
import { isStageShuttle } from '../../../../services/functions/test/isStageShuttle'
import { isStreetTurnButtonsAvailible } from '../../functions/isStreetTurnButtonsAvailible'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../services/debug'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelHasNewMasseges } from '../../../../store/select/communicationHubSelect'
import {
  IMove,
  IMoveDTO,
  MoveStatus,
  RouteBuilderGridView
} from '../../../../store/reducers/communicationHub/interfaces'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { getFirstActualMoveItem } from '../../functions/getFirstActualMoveItem'
import { isMoveAvailableToChangeStatus } from '../../../../services/functions/test/isMoveAvailableToChangeStatus'
import { getMoveStatus } from '../../../../services/functions/get/getMoveStatus'
import { isMoveStatusChangeable } from '../../../../services/functions/test/isMoveStatusChangeable'
import { selectEquipment } from '../../../../store/select/equipmentSelect'
import { selectActivities } from '../../../../store/select/activitySelect'
import { selectDispatchDeliveryOrder } from '../../../../store/select/dispatchDeliveryOrderSelect'
import { useContainer } from '../../../../hooks/useContainer'
import { useContainerType } from '../../../../hooks/useContainerType'
import { useDeliveryOrder } from '../../../../hooks/useDeliveryOrder'
import { isDocumentationActivity } from '../../../../services/functions/test/isDocumentationActivity'

type Props = {
  move: IMoveDTO
  enableDateMovement: boolean
  disable?: boolean
}

export const RouteBuilderMove = React.memo((props: Props) => {
  const {
    move: { dispatchDeliveryOrderId },
    disable,
    enableDateMovement
  } = props
  const dispatch = useAppDispatch()
  const transportationActivityMapping = useAppSelector(selectActivities) as Record<
    string,
    TransportationActivityViewDTO
  >
  const dispatchDeliveryOrder = useAppSelector(
    selectDispatchDeliveryOrder(dispatchDeliveryOrderId)
  ) as DispatchDeliveryOrderViewDTO
  const deliveryOrder = useDeliveryOrder({ id: oc(dispatchDeliveryOrder).deliveryOrderId() })
  const containerType = useContainerType({ id: oc(dispatchDeliveryOrder).containerTypeId() })
  const container = useContainer({ id: oc(dispatchDeliveryOrder).containerId() })
  const hasNewMessages = useAppSelector(selectCommunictaionHubChannelHasNewMasseges(dispatchDeliveryOrderId))
  const {
    isDriverAssignable,
    setFetch,
    getCurrentTabDayMoves,
    vendorId,
    authUserId,
    activeTabDate,
    view,
    setMoveErrorMappingRef,
    setRouteBuiderToastMessage
  } = useRouteBuilder()
  const [toastErrorMessage, setToastErrorMessage] = React.useState<string>(null)
  const firstActualMoveItem = getFirstActualMoveItem({ move: props.move, transportationActivityMapping })
  setMoveErrorMappingRef.current[props.move.id] = setToastErrorMessage
  const equipment = useAppSelector(selectEquipment(oc(dispatchDeliveryOrder).equipmentId()))

  React.useEffect(() => {
    return () => {
      setMoveErrorMappingRef.current[props.move.id] = undefined
    }
  }, [])

  const move = React.useMemo((): IMove => {
    const result: IMove = {
      ...props.move,
      _status: undefined,
      deliveryOrderType: oc(deliveryOrder).type(),
      items: props.move.items
        .map(item => ({
          ...item,
          gotoActivity: transportationActivityMapping[item.gotoActivityId],
          businessActivity: transportationActivityMapping[item.businessActivityId]
        }))
        .sort((a, b) => oc(a).gotoActivity.number(0) - oc(b).gotoActivity.number(0)),
      dispatchDeliveryOrder: {
        number: oc(dispatchDeliveryOrder).number(),
        status: oc(dispatchDeliveryOrder).status(),
        autoIndicator: oc(dispatchDeliveryOrder).autoIndicator(),
        hazmatIndicator: oc(dispatchDeliveryOrder).hazmatIndicator(),
        overweightIndicator: oc(dispatchDeliveryOrder).overweightIndicator(),
        refrigeratorIndicator:
          containerType && checkWeatherContainerTypeIsReefer({ containerTypeName: containerType.name }),
        isConfirmedAppointmentDate: oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed(),
        deliveryOrderDocNumber: oc(deliveryOrder).bookingNumber(),
        containerTypeCode: oc(containerType).code(),
        containerNumber: oc(container).number(),
        equipmentChassisNumber: oc(equipment).number()
      }
    }

    result._status = getMoveStatus(result)

    return result
  }, [
    props.move,
    transportationActivityMapping,
    dispatchDeliveryOrder,
    deliveryOrder,
    container,
    containerType,
    equipment
  ])

  const {
    containerNumber,
    containerTypeCode,
    autoIndicator,
    hazmatIndicator,
    overweightIndicator,
    refrigeratorIndicator,
    deliveryOrderDocNumber,
    isConfirmedAppointmentDate
  } = move.dispatchDeliveryOrder || {}
  const alwaysShowDetails = ![MoveStatus.COMPLETED, MoveStatus.UNSUCCESSFUL].includes(move._status)
  const [showDetails, setShowDetails] = React.useState(alwaysShowDetails)
  const ddoNumber = oc(move).dispatchDeliveryOrder.number() || 'dispatchDeliveryOrder number'
  const { moveDay, moveTime, moveTimeLabel } = React.useMemo(() => {
    let date = move.completeDate || move.startDate
    const showNextToDate = !date && move._isPrevDayMove

    if (showNextToDate) {
      date = move.plannedDate
    }

    const isAnotherDay = date && !dateService.isTheSameDay(date, activeTabDate)
    const isAnotherYear = isAnotherDay && new Date(date).getFullYear() !== new Date(activeTabDate).getFullYear()

    return {
      moveDay: isAnotherDay ? dateService.makeLabel(date, { hideTime: true, hideYear: !isAnotherYear }) : undefined,
      moveTime: showNextToDate ? undefined : dateService.makeLabel(date, { onlyTime: true }),
      moveTimeLabel: move.completeDate ? 'Complete Time' : move.startDate ? 'Actual Start' : undefined
    }
  }, [move, activeTabDate])

  const { openChatChannel, openDDO } = React.useMemo(() => {
    if (!move.dispatchDeliveryOrderId) {
      return {}
    }

    return {
      openDDO: () =>
        openDDOinNewTab({
          ddoNumber,
          ddoId: move.dispatchDeliveryOrderId
        }),
      openChatChannel: () => openCommunicationHubChannelByDDOId(move.dispatchDeliveryOrderId)
    }
  }, [move.dispatchDeliveryOrderId, ddoNumber])

  const ToggleMoveDetailsButton = React.useCallback(() => {
    const buttonStyles = {
      display: 'flex',
      alignItems: 'center',
      marginLeft: view === RouteBuilderGridView.grid || !showDetails ? 'auto' : undefined
    }

    return [MoveStatus.COMPLETED, MoveStatus.UNSUCCESSFUL].includes(move._status) ? (
      <ToggleMoveButton
        className={cn('mdi', { 'mdi-chevron-down': !showDetails, 'mdi-chevron-up': showDetails })}
        style={buttonStyles}
        onClick={() => setShowDetails(_ => !_)}
      />
    ) : !disable &&
      !move.startDate &&
      move.items.every(
        item =>
          oc(item).gotoActivity.status() !== TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED &&
          oc(item).businessActivity.status() !== TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
      ) ? (
      <Icon
        icon={IconName.CLOSE}
        fontSize={20}
        style={buttonStyles}
        onClick={() => {
          showModalWindow({
            width: 320,
            title: 'Are you sure you want to delete this move?',
            buttons: [
              {
                label: 'No'
              },
              {
                label: 'Yes',
                onClick: async () => {
                  setFetch(true)
                  const deleted = await deleteMove(move)
                  if (deleted) {
                    dispatch(communicationHubActions.deleteActiveRouteBuilderMoveIds([move.id]))
                  }
                  setFetch(false)
                }
              }
            ]
          })
        }}
      />
    ) : (
      <ToggleMoveButton style={{ ...buttonStyles, cursor: 'default' }} />
    )
  }, [move, showDetails, setFetch, view, disable])

  const RenderMoveStreetTurnButtons = React.useCallback(
    () =>
      !disable &&
      isStreetTurnButtonsAvailible({ dispatchDeliveryOrder, move }) && (
        <RouteBuilderStreetTurnButton dispatchDeliveryOrderId={move.dispatchDeliveryOrderId} setFetching={setFetch} />
      ),
    [move, transportationActivityMapping, dispatchDeliveryOrder, disable]
  )

  const RenderTopMoveActionButtons = React.useCallback(() => {
    const isChangeablePlanned = isDriverAssignable && isMoveStatusChangeable(move)[MoveStatus.PLANNED]
    const isChangeableAssigned = isMoveStatusChangeable(move)[MoveStatus.ASSIGNED]
    const onStatusButtonClick =
      isChangeablePlanned || isChangeableAssigned
        ? async () => {
            const testResult = isMoveAvailableToChangeStatus({
              move,
              status: isChangeablePlanned ? MoveStatus.PLANNED : MoveStatus.ASSIGNED
            })

            if (!testResult.changeable) {
              return testResult.errorMessage ? setToastErrorMessage(testResult.errorMessage) : undefined
            }

            setFetch(true)
            await setMovesActivitiesStatus({
              vendorId,
              authUserId,
              moves: [move],
              searchStatus: isChangeablePlanned
                ? TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
                : TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
              newStatus: isChangeablePlanned
                ? TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
                : TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
            })
            setFetch(false)
          }
        : undefined
    return (
      <>
        <RouteBuilderVendorStatusButton
          status={move._status}
          onButtonClick={disable ? undefined : onStatusButtonClick}
          smallButton={view === RouteBuilderGridView.list}
        />
      </>
    )
  }, [move, getCurrentTabDayMoves, vendorId, authUserId, setFetch, isDriverAssignable])

  const isShuttle = React.useMemo((): boolean => {
    try {
      const uniqStages = R.uniq(
        oc(move)
          .items([])
          .map(({ gotoActivity }) => gotoActivity && gotoActivity.stage)
          .filter(Boolean)
      )

      if (uniqStages.length === 1 && uniqStages[0] !== TransportationActivityViewDTO.StageEnum.DELIVERY) {
        const transportationActivities =
          dispatchDeliveryOrder &&
          dispatchDeliveryOrder.activityIds &&
          dispatchDeliveryOrder.activityIds
            .map(id => transportationActivityMapping[id])
            .filter(Boolean)
            .filter(activity => !isDocumentationActivity(activity))

        if (transportationActivities && transportationActivities.length) {
          return isStageShuttle({
            stage: uniqStages[0],
            ddoPickupLocationId: oc(dispatchDeliveryOrder).pickupStage.locationId(),
            ddoReturnLocationId: oc(dispatchDeliveryOrder).returnStage.locationId(),
            deliveryOrderType: oc(deliveryOrder).type(),
            ddoStreetTurnId: oc(dispatchDeliveryOrder).troubleTicketId(),
            transportationActivities
          })
        }
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      return false
    }
  }, [move])

  const RenderTimeColumn = () => {
    if (moveDay || moveTime) {
      return (
        <Time className={view === RouteBuilderGridView.grid ? 'fixed' : ''}>
          {moveDay && <FullDate>{moveDay}</FullDate>}
          {moveTime}
          <TimeLabel>{moveTimeLabel}</TimeLabel>
        </Time>
      )
    } else if (enableDateMovement) {
      const datepicker = (
        <DateTimePickerButton
          date={undefined}
          calendarPosition={'right'}
          timesOfDay={TimesOfDay.startWorkDay}
          minDate={dateService.createDate.startWorkDay}
          excludeDates={[dateService.convertFromServer(activeTabDate)]}
          onChange={async plannedDate => {
            setFetch(true)
            setToastErrorMessage(null)

            try {
              await setMovePlannedDate({ moveId: move.id, plannedDate })
                .then(() => {
                  if (setRouteBuiderToastMessage) {
                    setRouteBuiderToastMessage({
                      message: plannedDate,
                      type: RouteBuilderToastMessageType.setMoveDay
                    })
                  }
                  getCurrentTabDayMoves()
                })
                .catch(error => {
                  const errorMessage = convertErrorToMessage(error.data)
                  if (typeof errorMessage === 'string') {
                    setToastErrorMessage(errorMessage)
                  }
                })
            } catch (e) {}
            setFetch(false)
          }}
        />
      )

      return view === RouteBuilderGridView.grid ? <MoveDatePicker>{datepicker}</MoveDatePicker> : datepicker
    }

    return null
  }

  if (
    oc(move)
      .items([])
      .every(
        ({ businessActivity }) =>
          businessActivity && businessActivity.status === TransportationActivityViewDTO.StatusEnum.NEW
      )
  ) {
    return null
  }

  if (view === RouteBuilderGridView.grid) {
    return (
      <GridViewMoveContainer
        className={'RouteBuilderMove'}
        onClick={(event: any) => {
          if (isShowDetailsOnClick(event)) {
            showDetailsOnClick({
              title: 'Route Builder Move Details',
              data: move,
              blink: { class: 'RouteBuilderMove', event }
            })
          }
        }}
      >
        <TimeColumn>
          <RenderTimeColumn />
        </TimeColumn>
        <GridViewMoveDetails>
          {toastErrorMessage && (
            <ToastMessageContainer>
              <RouteBuilderToastMessage
                key={toastErrorMessage}
                type={RouteBuilderToastMessageType.error}
                removeToastMessageAction={() => setToastErrorMessage(null)}
                meta={{ message: toastErrorMessage }}
              />
            </ToastMessageContainer>
          )}
          <MoveButtons className={'view-' + view}>
            {<RenderTopMoveActionButtons />}
            {<ToggleMoveDetailsButton />}
          </MoveButtons>
          {(alwaysShowDetails || showDetails) && (
            <MoveContainer>
              <Header>
                <HeaderDOType>
                  {isShuttle ? 'SH' : deliveryOrderDirectory.columnType[move.deliveryOrderType]}
                </HeaderDOType>
                <HeaderDDONumber onClick={openDDO}>{ddoNumber}</HeaderDDONumber>
                <HeaderMessengerButton
                  className={cn('mdi mdi-message-text', { hasNewMessages })}
                  style={{ height: '100%', padding: '0 8px' }}
                  onClick={openChatChannel}
                />
                <HeaderRightSide>
                  {containerTypeCode && <Text>{containerTypeCode}</Text>}
                  {(containerNumber || deliveryOrderDocNumber) && (
                    <Text>{containerNumber || deliveryOrderDocNumber}</Text>
                  )}
                  {refrigeratorIndicator && <HeaderIcon children={ddoDirectory.icons.refrigerator} />}
                  {hazmatIndicator && <HeaderIcon children={ddoDirectory.icons.hazmat} />}
                  {overweightIndicator && <HeaderIcon children={ddoDirectory.icons.overweight} />}
                  {autoIndicator && <HeaderIcon children={ddoDirectory.icons.auto} />}
                </HeaderRightSide>
              </Header>
              <Body>
                {move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.EXPORT && <RenderMoveStreetTurnButtons />}
                {(move.items || []).map(
                  moveItem =>
                    moveItem.gotoActivity &&
                    moveItem.businessActivity && (
                      <RouteBuilderMoveActivity
                        key={moveItem.id}
                        isFirstActualMoveItem={firstActualMoveItem && firstActualMoveItem.id === moveItem.id}
                        disable={disable}
                        activeTabDate={activeTabDate}
                        dateIsNotConfirmed={
                          isConfirmedAppointmentDate === false &&
                          moveItem.businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
                          moveItem.businessActivity.template
                        }
                        view={view}
                        moveItem={moveItem}
                        prevConnectionType={move.prevConnectionType}
                        nextConnectionType={move.nextConnectionType}
                      />
                    )
                )}
                {move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.IMPORT && <RenderMoveStreetTurnButtons />}
              </Body>
            </MoveContainer>
          )}
        </GridViewMoveDetails>
      </GridViewMoveContainer>
    )
  }
  // else if (view === RouteBuilderGridView.list) {
  //   return (
  //     <ListViewMoveContainer>
  //       <ListViewMoveHeader>
  //         <ListViewTimeColumn>
  //           <RenderTimeColumn />
  //         </ListViewTimeColumn>
  //         <MoveButtons className={'view-' + view}>{RenderMoveActionButtons}</MoveButtons>
  //         {showDetails && (
  //           <HeaderRightSide style={{ paddingRight: 20 }}>
  //             {containerTypeCode && <Text>{containerTypeCode}</Text>}
  //  {(containerNumber || deliveryOrderDocNumber) && <Text>{containerNumber || deliveryOrderDocNumber}</Text>}
  //             {refrigeratorIndicator && <HeaderIcon children={ddoDirectory.icons.refrigerator} />}
  //             {overweightIndicator && <HeaderIcon children={ddoDirectory.icons.overweight} />}
  //             {hazmatIndicator && <HeaderIcon children={ddoDirectory.icons.hazmat} />}
  //             {autoIndicator && <HeaderIcon children={ddoDirectory.icons.auto} />}
  //           </HeaderRightSide>
  //         )}
  //         {ToggleMoveDetailsButton}
  //       </ListViewMoveHeader>
  //       {showDetails && (
  //         <ListViewMoveColumns>
  //           <ListViewInfoColumn>
  //             <ListViewInfoColumnText style={{ fontWeight: 500 }}>EXP</ListViewInfoColumnText>
  //             <ListViewInfoColumnTextLink onClick={openDDO}>{ddoNumber}</ListViewInfoColumnTextLink>
  //             <HeaderMessengerButton
  //               className={cn('mdi mdi-message-text', { hasNewMessages })}
  //               style={{ marginTop: 4 }}
  //               onClick={openChatChannel}
  //             />
  //           </ListViewInfoColumn>
  //           {RenderActivities}
  //         </ListViewMoveColumns>
  //       )}
  //     </ListViewMoveContainer>
  //   )
  // }

  return null
})

// COMMON
const TimeColumn = styled.div`
  flex-shrink: 0;
  width: 120px;
  padding-left: 15px;
`
export const FullDate = styled.div`
  font-weight: 500;
  color: #697585;
`
export const Time = styled.div`
  color: ${theme.colors.gray};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  user-select: none;

  &.fixed {
    left: 55px;
    position: absolute;
  }
`
export const TimeLabel = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 7px;
  line-height: 10px;
  color: #98a4b4;
  white-space: nowrap;
`

// GRID VIEW
const GridViewMoveContainer = styled.div`
  flex-grow: 1;
  display: flex;
`
const GridViewMoveDetails = styled.div`
  width: 100%;
`
const ToggleMoveButton = styled.div`
  width: 24px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #9aa6b6;
  cursor: pointer;

  &:hover {
    color: #445366;
  }
`
const MoveButtons = styled.div`
  display: flex;
  margin-bottom: 15px;

  &.view-${RouteBuilderGridView.grid} {
  }

  &.view-${RouteBuilderGridView.list} {
  }
`
const MoveContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
const Header = styled.div`
  height: 42px;
  display: flex;
  align-items: stretch;
  text-transform: uppercase;
  padding-right: 6px;
`
const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  color: #445366;
  margin-left: auto;

  > div {
    padding: 0 5px;

    &:not(:first-child) {
      border-left: 1px solid #44536610;
    }
  }
`
const HeaderIcon = styled.div`
  height: 24px;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  padding: 0 3px;
`
const Text = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
const HeaderDOType = styled.div`
  width: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #9ba8b8;
  color: white;
  border-radius: 8px 0 0 0;
  font-weight: 500;
  font-size: 14px;
`
const HeaderDDONumber = styled.div`
  width: 97px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: rgba(201, 205, 221, 0.3);
  border-radius: 0 0 20px 0;
  color: #445366;
  padding-left: 12px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
    text-decoration: underline;
  }
`
const HeaderMessengerButton = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #c9cddd;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }

  &.hasNewMessages {
    color: ${theme.colors.basicBlueColor};
    position: relative;

    &:after {
      content: '';
      height: 7px;
      width: 7px;
      display: block;
      background: ${theme.colors.defaultRed};
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 13px);
      right: calc(50% - 13px);
    }
  }
`

const Body = styled.div`
  padding: 14px;

  > div:not(:last-child) {
    margin-bottom: 12px;
  }
`
const MoveDatePicker = styled.div`
  left: 70px;
  position: absolute;
`
// LIST VIEW
const ListViewMoveContainer = styled.div`
  width: 100%;
  padding-left: 12px;
`
const ListViewMoveHeader = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
const ListViewMoveColumns = styled.div`
  min-height: 50px;
  display: flex;
  padding-right: 24px;

  > div {
    flex-shrink: 0;
  }
`
const ListViewTimeColumn = styled.div`
  width: 75px;
  flex-shrink: 0;
`
const ListViewInfoColumn = styled.div`
  width: 60px;
  border-right: 1px solid #e3e8ea;
`
const ListViewInfoColumnText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #445366;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ListViewInfoColumnTextLink = styled(ListViewInfoColumnText)`
  font-weight: 500;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const ToastMessageContainer = styled.div`
  margin-bottom: 15px;
`
const ShowMoveButton = styled.div`
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-size: 8px;
  color: #516176;
  text-transform: uppercase;
  padding: 0 20px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 18px;
    position: absolute;
    right: 0;
    top: -6px;
  }
`

const ShowMoveButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-left: 115px;

  &.isDoubleMove {
    text-align: left;
    padding-left: 0;

    ${ShowMoveButton} {
      cursor: default;
    }
  }
`
