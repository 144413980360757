import { NewRateDTO, RateState } from '../../../components/common/QMP/interfaces'
import { createId } from '../../utils'

export const generateRate = (options?: Partial<NewRateDTO>): NewRateDTO => {
  return {
    id: createId(),
    state: RateState.new,
    duplicateIds: undefined,
    warningMessage: undefined,
    warningMessageDescription: undefined,
    customerId: undefined,
    vendorId: undefined,
    pickupLocationId: undefined,
    deliveryCity: undefined,
    deliveryPostalCode: undefined,
    deliveryStateId: undefined,
    returnLocationId: undefined,
    containerTypeId: undefined,
    deliveryOrderType: undefined,
    loadType: undefined,
    calculationType: undefined,
    type: undefined,
    effectiveDate: undefined,
    expirationDate: undefined,
    amount: undefined,
    ...(options || {})
  }
}
