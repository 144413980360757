import * as R from 'remeda'
import equal = require('fast-deep-equal')
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { processObject } from '../transform/processObject'

type Props = {
  entityType: EntityType
  modifiedEntity: any
  storeEntity: any
}

export const isEntityModified = (props: Props): boolean => {
  const { entityType, modifiedEntity, storeEntity } = props

  if (!modifiedEntity) {
    return false
  }

  if (!storeEntity) {
    return true
  }

  const cutEntityProps: string[] = []

  if (entityType === EntityType.activity) {
    const cutProps: (keyof TransportationActivityViewDTO)[] = ['destination']
    cutEntityProps.push(...cutProps)
  } else if (entityType === EntityType.dispatchDeliveryOrder) {
    const cutProps: (keyof DispatchDeliveryOrderViewDTO)[] = [
      'deliveryOrder',
      'activities',
      'activityIds',
      'buySideQuoteIds',
      'container',
      'equipment'
    ]
    cutEntityProps.push(...cutProps)
  }

  return !equal(
    processObject(R.omit(modifiedEntity, cutCommonProps.concat(cutEntityProps))).hardClean,
    processObject(R.omit(storeEntity, cutCommonProps.concat(cutEntityProps))).hardClean
  )
}

const cutDates = ['updatedAt', 'createdAt']
const cutAuth = ['updatedBy', 'createdBy']
const cutCommonProps = [...cutDates, ...cutAuth]
