import { TransportationActivityViewDTO } from '../../../../../../../../../../api/origin/business-logic'

type GroupProps = {
  gotoActivity: TransportationActivityViewDTO
  businessActivity: TransportationActivityViewDTO
}

export const checkTransportationActivities = ({ gotoActivity, businessActivity }: GroupProps) => {
  if (gotoActivity.bobtailFrom) {
    return
  }

  if (
    [TransportationActivityViewDTO.StatusEnum.INPROCESS, TransportationActivityViewDTO.StatusEnum.COMPLETED].includes(
      gotoActivity.status
    ) &&
    [
      TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
      TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
      TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
    ].includes(businessActivity.status)
  ) {
    businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
  }
}
