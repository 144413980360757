import * as React from 'react'
import styled from '../../../styles/styledComponents'
import { appVersion } from '../../App'

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 50px;
  color: white !important;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  user-select: none;

  &:hover {
    transition: background 0.25s ease;
    background: #475264;
  }
`

const Version = styled.span`
  font-size: 13px;
  font-weight: 300;
  margin-left: 5px;
`

export const Logo = React.memo(() => (
  <StyledLogo>
    <span>Intermodal</span>
    <Version>v{appVersion}</Version>
  </StyledLogo>
))
