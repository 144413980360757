import styled from 'styled-components'
import { GridColumn } from '../GridSystem'
import { theme } from '../../../styles/theme'

export const StyledRadioButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #cdcdcd;
  font-size: 20px;
  user-select: none;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.active,
  &:not(.disabled):hover {
    color: ${theme.colors.basicBlueColor};
  }
`

export const StyledRadio = styled(GridColumn)`
  font-size: 14px;
  color: #4f4f4f;
  user-select: none;
  cursor: pointer;

  span {
    font-size: 16px;
    color: #e3e6f1;
    transition: color 0.25s;
    margin-right: 6px;

    &:before {
      content: '\f130';
    }

    &.active {
      color: #4555d0;

      &:before {
        content: '\f43e';
      }
    }
  }

  &:hover {
    span {
      color: #4555d0;
    }
  }

  &.not-valid {
    cursor: not-allowed;
    &:hover span {
      color: #e3e6f1;
    }
  }

  &.disable {
    opacity: 0;
    cursor: default;
  }
`
export const StyledRadioGroup = styled(GridColumn)`
  ${StyledRadio}:last-child {
    margin: 0;
  }
`
