import { ContainerTypeDTO, HazmatDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.hazmat]: HazmatDTO[]
}

export const parseHazmats = async (hazmats: ContainerTypeDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.hazmat]: []
  }

  try {
    hazmats.forEach(hazmat => {
      result[EntityType.hazmat].push(hazmat)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
