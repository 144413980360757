import styled from 'styled-components'
import theme from '../../../../../../../../styles/theme'

export const Title = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 20px 0 5px;
  cursor: default;
  user-select: none;

  &:first-child {
    margin-top: 0;
  }
`
export const AddButton = styled.span`
  margin-left: 10px;
  color: ${theme.colors.basicBlueColor};
  font-size: 24px;
  transform: translateY(1px);
  cursor: pointer;
`
export const MakeShuttleButton = styled.span`
  border-radius: 10px;
  background-color: #4555d0;
  color: white;
  font-size: 14px;
  padding: 4px 10px;
  margin-left: 20px;
  cursor: pointer;
`
