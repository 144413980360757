import { LocationViewDTO } from '../../../api/api'
import { TLocation } from '../../../components/common/location/interfaces'
import { getListsState } from '../../../store'

export const getContainerYardLocations = (props: { active?: boolean; name?: string }): (TLocation)[] => {
  const locatons = Object.values(getListsState().location)

  return locatons.filter(
    location =>
      location.type === LocationViewDTO.TypeEnum.CONTAINERYARD &&
      (props && 'active' in props ? location.active === props.active : true) &&
      (props && 'name' in props
        ? location.name
            .toUpperCase()
            .trim()
            .includes(props.name.toUpperCase().trim())
        : true)
  )
}
