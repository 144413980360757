import * as React from 'react'

export const RefreshIcon = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // tslint:disable:max-line-length
      d="M1 1.69556V6.69556H1.58152M16.9381 8.69556C16.446 4.74924 13.0796 1.69556 9 1.69556C5.64262 1.69556 2.76829 3.76373 1.58152 6.69556M1.58152 6.69556H6M17 17.6956V12.6956H16.4185M16.4185 12.6956C15.2317 15.6274 12.3574 17.6956 9 17.6956C4.92038 17.6956 1.55399 14.6419 1.06189 10.6956M16.4185 12.6956H12"
      stroke="#CFA63E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
