import * as React from 'react'
import { CustomerViewDTO, DeliveryOrderViewDTO, LocationViewDTO, SteamShipLineViewDTO } from '../api/api'

export interface IDeliveryOrderTabContext {
  deliveryOrder: DeliveryOrderViewDTO
  pickupLocation: LocationViewDTO | undefined
  deliveryLocation: LocationViewDTO | undefined
  returnLocation: LocationViewDTO | undefined
  customer: CustomerViewDTO | undefined
  steamShipLine: SteamShipLineViewDTO | undefined
  isSteamShipLineRequired: boolean
}

export const DeliveryOrderTabContext = React.createContext<IDeliveryOrderTabContext>({
  deliveryOrder: null,
  pickupLocation: null,
  deliveryLocation: null,
  returnLocation: null,
  customer: null,
  steamShipLine: null,
  isSteamShipLineRequired: false
})
