import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { VendorDTO } from '../api/origin/vendor-accounting-service'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useVendorAccounting = (props: Props): VendorDTO => {
  const { id, modifiedLists } = props
  const vendorAccounting = useListItem({ id, type: EntityType.vendorAccounting, modifiedLists })

  return vendorAccounting
}
