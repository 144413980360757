import * as React from 'react'
import { StyledRulesColumn } from '../../../../Grid/styles'
import { RuleHeader } from './Header'
import { RuleBody } from './Body'

export const columnProps: Record<string, { styles: object }> = {
  ID: {
    styles: { flex: '0 0 40px' }
  },
  Name: { styles: { flex: '0 0 165px' } },
  Definition: { styles: { flex: '1 0 0' } },
  Updated: { styles: { flex: '0 0 90px' } },
  'Created by': { styles: { flex: '0 0 90px' } }
}
export const columns = Object.keys(columnProps)

type Props = {
  title: string
}

export const RuleBuilderColumn = (props: Props) => {
  const { title } = props
  return (
    <StyledRulesColumn>
      <RuleHeader title={title} onAddNewRule={() => {}} />
      <RuleBody ruleNumber={'005'} conditionList={testConditions} onTitleChange={() => {}} />
    </StyledRulesColumn>
  )
}

const testConditions = [
  {
    number: 1,
    propertyName: 'LOCATION',
    propertyType: 'ANY',
    operator: 'IS',
    value: 'MAHER',
    conditionType: 'AND'
  },
  {
    number: 2,
    propertyName: 'LOCATION',
    propertyType: 'ANY',
    operator: 'IS',
    value: 'MAHER',
    conditionType: 'AND'
  },
  {
    number: 3,
    propertyName: 'LOCATION',
    propertyType: 'ANY',
    operator: 'IS',
    value: 'MAHER',
    conditionType: 'AND'
  },
  {
    number: 4,
    propertyName: 'LOCATION',
    propertyType: 'ANY',
    operator: 'IS',
    value: 'MAHER',
    conditionType: 'AND'
  },
  {
    number: 5,
    propertyName: 'LOCATION',
    propertyType: 'ANY',
    operator: 'IS',
    value: 'MAHER',
    conditionType: 'AND'
  }
]
