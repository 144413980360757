import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { MiscSurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import { SsqBsqWidgetTableTitleWithLink } from './SsqBsqWidgetTableTitleWithLink'
import { SsqBsqWidgetMiscSurcharge } from './SsqBsqWidgetMiscSurcharge'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'

type Props = {
  miscSurcharges: MiscSurchargeDTO[]
  addMiscSurcharge: () => void
  deleteMiscSurchargeId: (miscSurchargeId: string) => void
  modifyMiscSurcharge: (miscSurcharge: MiscSurchargeDTO) => void
  hideActivityColumn?: boolean
}

export const SsqBsqWidgetAdditionalSurcharges = React.memo((props: Props) => {
  const { miscSurcharges, hideActivityColumn, addMiscSurcharge, deleteMiscSurchargeId, modifyMiscSurcharge } = props
  const { enableEditing } = useSsqBsqWidget()
  const disable = !enableEditing

  return (
    <>
      <SsqBsqWidgetTableTitleWithLink
        isBold={true}
        title={'Additional Surcharges'}
        link={
          disable
            ? null
            : {
                isAddButton: true,
                label: 'Add Misc',
                onClick: addMiscSurcharge
              }
        }
      />
      {Boolean((miscSurcharges || []).length) && (
        <Container>
          <Table className={cn({ hideActivityColumn })}>
            {miscSurcharges.map(miscSurcharge => (
              <SsqBsqWidgetMiscSurcharge
                key={miscSurcharge.id}
                miscSurcharge={miscSurcharge}
                disable={disable}
                hideActivityColumn={hideActivityColumn}
                modifyMiscSurcharge={modifyMiscSurcharge}
                deleteMiscSurcharge={() => deleteMiscSurchargeId(miscSurcharge.id)}
              />
            ))}
          </Table>
        </Container>
      )}
    </>
  )
})

const Container = styled.div`
  flex-shrink: 0;
  max-height: 230px;
  background-color: #fff;
  overflow-y: scroll;
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 180fr 275fr 305fr 120fr 42fr;

  &.hideActivityColumn {
    grid-template-columns: 275fr 305fr 120fr 42fr;
  }
`
const BasePlusFuelAmount = styled.div`
  text-align: right;
  padding: 10px 10px 20px;

  span {
    font-weight: 500;
    margin-left: 8px;
  }
`
