import * as React from 'react'
import { GridItemPowerUnitContext } from '../contexts/GridItemPowerUnitContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { usePowerUnit } from '../hooks/usePowerUnit'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemPowerUnitProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const powerUnit = usePowerUnit({ id, modifiedLists })

  return (
    <GridItemPowerUnitContext.Provider value={{ powerUnit, isExpanded: Boolean(modifiedLists) }} children={children} />
  )
})
