import { v4 } from 'uuid'
import { NotificationOptionDTO } from '../../../api/origin/communication-hub-service'

export const generateNotificationOption = (options?: Partial<NotificationOptionDTO>): NotificationOptionDTO => {
  return {
    id: v4(),
    text: '',
    ...(options || {})
  }
}
