import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridTable } from '../common/grid/views'
import { createFilterRequest } from '../../services/uiSettingsService/filter'
import { requestSteamShipLineById, requestSteamShipLines } from '../common/steamShipLine/epics'
import { requestCustomerById, requestCustomers } from '../common/customer/epics'
import { requestEquipmentById, requestEquipments } from '../common/equipment/epics'
import { requestPowerUnitById, requestPowerUnits } from '../common/powerUnit/epics'
import { requestContainers, requestContainerById } from '../common/containers/epics'
import { requestGridLocations, requestLocationById } from '../common/location/epics'
import { requestDriverById, requestDrivers } from '../common/drivers/epics'
import { requestSubClientById, requestSubClients } from '../common/subClient/epics'
import {
  findAllDroppedAtDeliveryLocation,
  requestGridDispatchDeliveryOrders,
  requestDispatchDeliveryOrderById,
  findAllDeliveryStageCompletedNoReturned,
  findAllDroppedAtYard,
  findAllPickedUpNoDelivery,
  findRecentDispatchDeliveryOrders
} from '../common/dispatchDeliveryOrder/epics'
import { requestStreetTurnDispatchDeliveryOrdersForDdoId } from '../../services/DTO/streetTurn/epics'
import { GridProvider } from '../../providers/GridProvider'
import { isNewId } from '../../services/DTO'
import { useAppSelector } from '../../hooks/useAppSelector'
import {
  selectActiveApplicationTabFetching,
  selectActiveApplicationTabRefrashOnTabEnter,
  selectActiveApplicationTabType,
  selectActiveApplicationTabUISettings,
  selectActiveApplicationTabVisiteState,
  selectExpandedTabItemId
} from '../../store/select/applicationTabSelect'
import { ITab, TabDTO } from '../../store/reducers/tabs/interfaces'
import { updateTab } from '../../store/reducers/tabs/functions'
import { getActiveApplicationTabState } from '../../store'

type Props = {}

export const Grid = React.memo((props: Props) => {
  // const tab = useAppSelector(selectActiveApplicationTab)
  const extandedItemId = useAppSelector(selectExpandedTabItemId)
  const visited = useAppSelector(selectActiveApplicationTabVisiteState)
  const uiSettings = useAppSelector(selectActiveApplicationTabUISettings)
  const tabType = useAppSelector(selectActiveApplicationTabType)
  const refrashOnTabEnter = useAppSelector(selectActiveApplicationTabRefrashOnTabEnter)
  const tabFetching = useAppSelector(selectActiveApplicationTabFetching)
  const mountedRef = React.useRef(false)
  const [isFetching, setFetching] = React.useState(!visited)

  React.useEffect(() => {
    if (!visited) {
      if (extandedItemId && !isNewId(extandedItemId)) {
        findGridItem(tabType)(extandedItemId)
      }
      getList(setFetching)
    } else if (mountedRef.current || refrashOnTabEnter) {
      // catch uiSettings changes
      getList(setFetching)
    }

    mountedRef.current = true
  }, [uiSettings.filter, uiSettings.sorting])

  const fetchGridItems = React.useCallback(
    async (withoutSpinner?: boolean) => {
      return getList(withoutSpinner ? () => {} : setFetching)
    },
    [setFetching]
  )

  return (
    <GridProvider fetchGridItems={fetchGridItems} setGridFetching={setFetching}>
      <GridTable
        isFetching={isFetching || tabFetching}
        extandedItemId={extandedItemId}
        columnsSettings={uiSettings.columns}
        fetchGridItems={fetchGridItems}
      />
    </GridProvider>
  )
})

const findGridItem = (type: TabDTO.Type) => {
  switch (type) {
    case TabDTO.Type.powerUnit:
      return requestPowerUnitById
    case TabDTO.Type.equipment:
      return requestEquipmentById
    case TabDTO.Type.container:
      return requestContainerById
    case TabDTO.Type.location:
      return requestLocationById
    case TabDTO.Type.driver:
      return requestDriverById
    case TabDTO.Type.customer:
      return requestCustomerById
    case TabDTO.Type.steamShipLine:
      return requestSteamShipLineById
    case TabDTO.Type.subClient:
      return requestSubClientById
    case TabDTO.Type.dispatchDeliveryOrder:
      return requestDispatchDeliveryOrderById
    default:
      return () => Promise.resolve(null)
  }
}

const listRequest = (currentTab: ITab) => {
  const { type, request, uiSettings } = currentTab
  const { listRequestType } = request || {}

  if (listRequestType) {
    switch (listRequestType) {
      case TabDTO.Request.List.streetTurn:
        return requestStreetTurnDispatchDeliveryOrdersForDdoId
      case TabDTO.Request.List.droppedAtDeliveryLocation:
        return findAllDroppedAtDeliveryLocation
      case TabDTO.Request.List.deliveryStageCompletedNoReturned:
        return findAllDeliveryStageCompletedNoReturned
      case TabDTO.Request.List.droppedAtYard:
        return findAllDroppedAtYard
      case TabDTO.Request.List.pickedUpNoDelivery:
        return findAllPickedUpNoDelivery
      case TabDTO.Request.List.recentDispatchDeliverOrders:
        return findRecentDispatchDeliveryOrders
      default:
        return () => Promise.resolve([])
    }
  }

  const returnEmptyList = () => Promise.resolve([])

  switch (type) {
    case TabDTO.Type.powerUnit:
      return requestPowerUnits
    case TabDTO.Type.equipment:
      return requestEquipments
    case TabDTO.Type.container:
      return oc(uiSettings).filter([]).length ? requestContainers : returnEmptyList
    case TabDTO.Type.location:
      return requestGridLocations
    case TabDTO.Type.driver:
      return requestDrivers
    case TabDTO.Type.customer:
      return requestCustomers
    case TabDTO.Type.steamShipLine:
      return requestSteamShipLines
    case TabDTO.Type.subClient:
      return requestSubClients
    case TabDTO.Type.dispatchDeliveryOrder:
      return requestGridDispatchDeliveryOrders
    default:
      return returnEmptyList
  }
}

const getList = (setFetching: (state: boolean) => void) => {
  const tab = getActiveApplicationTabState()
  const { sorting, filter } = tab.uiSettings
  let localFetchingState = true
  setFetching(true)

  const fetch = listRequest(tab)
  const fetchProps = oc(tab).request.propsToRequest() || { filter: createFilterRequest(filter), sort: sorting }

  return fetch(fetchProps)
    .then(list => {
      localFetchingState = false
      setFetching(false)
      updateTab(tab.id, { gridItemIds: list.map(({ id }) => id), visited: true })
    })
    .finally(() => {
      if (localFetchingState) {
        setFetching(false)
      }
    })
}
