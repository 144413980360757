import * as React from 'react'
import { EquipmentDTO } from '../api/api'

export interface IGridItemEquipmentContext {
  equipment: EquipmentDTO
  isExpanded: boolean
}

export const GridItemEquipmentContext = React.createContext<IGridItemEquipmentContext>({
  equipment: undefined,
  isExpanded: undefined
})
