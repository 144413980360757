import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { theme } from '../../styles/theme'
import { IConflictBlock } from '../functions/get/getDispatchDeliveryOrdersConflicts'

type Props = {
  conflicts: IConflictBlock[]
}

export const ConflictsOnSave = (props: Props): JSX.Element => {
  const { conflicts } = props

  if (!conflicts || !conflicts.length) {
    return null
  }

  return (
    <Container>
      {conflicts.map((conflict, index) => {
        const hasDetails = Boolean(conflict.conflicts && conflict.conflicts.length)

        return (
          <Block
            key={index}
            className={'active'}
            style={conflict.isCritical ? { background: 'rgba(255, 94, 94, 0.05)' } : undefined}
          >
            <TitleContainer className={(hasDetails ? '' : 'no-details') + ' active'}>
              <Title style={conflict.isCritical ? { color: theme.colors.defaultRed } : undefined}>
                {conflict.label}
                {hasDetails && <Expand className={`mdi mdi-chevron-up}-circle`} />}
              </Title>
              {conflict.isCritical && (
                <div style={{ marginTop: 8 }}>You can not save changes. Critical changes have been made</div>
              )}
            </TitleContainer>
            {hasDetails && (
              <Details className={'conflict_block' + ' active'}>
                <div>
                  {(conflict as IConflictBlock).conflicts.map((c, i) => (
                    <Detail key={i}>
                      <FieldName>{c.label}</FieldName>
                      <CompareValues>
                        <Value className={cn('right', { empty: !c.prevValue })}>
                          <div>{c.prevValue || 'Empty'}</div>
                        </Value>
                        <Action className={'mdi mdi-arrow-right-thick'} />
                        <Value className={cn('left', { empty: !c.nextValue })}>
                          <div>{c.nextValue || 'Empty'}</div>
                        </Value>
                      </CompareValues>
                    </Detail>
                  ))}
                </div>
              </Details>
            )}
          </Block>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  width: 700px;
  margin: 0 -15px;
`
const Block = styled.div`
  &:nth-child(odd) {
    background-color: rgba(245, 246, 250, 0.5);
  }

  &.active {
    background-color: #f5f6fa;
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
`
const TitleContainer = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${theme.colors.header};
  padding: 15px;
  cursor: pointer;

  &:hover ${Title} {
    opacity: 0.8;
  }

  &.no-details {
    cursor: default !important;

    ${Title} {
      opacity: 1 !important;
    }
  }

  &.active ${Title} {
    color: ${theme.colors.basicBlueColor};
  }
`
const Expand = styled.div`
  margin-left: 8px;
`
const Details = styled.div`
  overflow: hidden;
  transition: 0.15s;

  > div {
    padding: 0 0 10px 20px;
  }
`
const Detail = styled.div`
  width: 100%;
  display: flex;
`
const FieldName = styled.div`
  flex-shrink: 0;
  width: 200px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
`
const CompareValues = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`
const Value = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  white-space: pre-wrap;

  &.empty {
    color: ${theme.colors.defaultGray};
  }

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }
`
const Action = styled.div`
  font-size: 20px;
  padding: 0 15px;
`
