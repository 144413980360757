import * as React from 'react'
import styled from 'styled-components'

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  // tslint:disable:max-line-length
  // @ts-ignore
  <Icon {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path
      d="M4.5 14L13.5 5M4.5 5L13.5 14"
      stroke="#A6A9BA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

const Icon = styled.svg`
  cursor: pointer;

  &:hover path {
    stroke: #445366;
  }
`
