import * as React from 'react'
import { RuleListColumn } from './Columns/RuleList'
import { RuleBuilderColumn } from './Columns/RuleBuilder'
import { StyledBody, StyledRules } from '../../Grid/styles'
import { ActionsSaveCancel } from '../../../../../UI/Buttons/ActionsSaveCancel'

export const Rules = () => {
  return (
    <StyledBody>
      {/*<StyledRules className={'adapt'}>*/}
      <StyledRules>
        <RuleListColumn title={'Rules'} />
        <RuleBuilderColumn title={'Rule Builder'} />
      </StyledRules>
      <ActionsSaveCancel disableSave={false} showCancel={true} onSave={() => {}} onCancel={() => {}} />
    </StyledBody>
  )
}
