import * as React from 'react'
import { SsqBsqWidgetRateColumn, SsqBsqWidgetRateColumnType } from './SsqBsqWidgetRateColumn'
import { CombinedSurchargeRateDTO } from '../../../../../services/DTO/rate/interfaces'

type Props = {
  rate: CombinedSurchargeRateDTO
  baseRate: CombinedSurchargeRateDTO | undefined
  disable: boolean
}

export const SsqBsqWidgetMainRate = React.memo((props: Props) => {
  const { rate, baseRate, disable } = props

  return (
    <>
      {mainRateColumns.map(columnType => {
        return (
          <SsqBsqWidgetRateColumn
            key={columnType}
            columnType={columnType}
            rate={rate}
            baseRate={baseRate}
            disable={disable}
            addSurcharge={undefined}
            deleteSurcharge={undefined}
            modifySurcharge={undefined}
          />
        )
      })}
    </>
  )
})

export const mainRateColumns = [
  SsqBsqWidgetRateColumnType.ID,
  SsqBsqWidgetRateColumnType.Surcharges,
  SsqBsqWidgetRateColumnType.Type,
  SsqBsqWidgetRateColumnType.CalculationType,
  SsqBsqWidgetRateColumnType.Rule,
  SsqBsqWidgetRateColumnType.EffectiveDate,
  SsqBsqWidgetRateColumnType.ExpirationDate,
  SsqBsqWidgetRateColumnType.Amount
]
