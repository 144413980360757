import * as React from 'react'
import { ColumnRow, Container } from '../../../GridSystem'
import { Input } from '../../../DataFields/Input'
import { WidgetActions } from '../../styles'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { regExpOnlyNumbersAndLetters } from '../../../../../services/functions/regExp'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

export const Popover = () => {
  const { isImport, isExport, isRepo, deliveryOrder, modifyDeliveryOrderField, enableEditing } = useGridItemDDO()
  const { closePopover } = React.useContext(WidgetContext)

  return (
    <>
      <Container isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={enableEditing === false}
            title={'Booking #'}
            maxLength={64}
            highlighted={deliveryOrder.bookingNumber && deliveryOrder.bookingNumber.length < 4}
            regExp={regExpOnlyNumbersAndLetters}
            required={isExport || isRepo}
            value={deliveryOrder.bookingNumber}
            onChange={modifyDeliveryOrderField('bookingNumber')}
          />
        </ColumnRow>

        <ColumnRow>
          <Input
            disabled={enableEditing === false}
            title={'Bill of Lading #'}
            maxLength={64}
            highlighted={deliveryOrder.billOfLadingNumber && deliveryOrder.billOfLadingNumber.length < 4}
            regExp={regExpOnlyNumbersAndLetters}
            required={isImport}
            value={deliveryOrder.billOfLadingNumber}
            onChange={modifyDeliveryOrderField('billOfLadingNumber')}
          />
        </ColumnRow>
      </Container>
      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
