import * as React from 'react'
import { oc } from 'ts-optchain'
import { toast } from 'react-toastify'
import {
  IDocumentMessage,
  IMessage,
  NotificationDocumentForm
} from '../../../store/reducers/communicationHub/interfaces'
import {
  applicationFormAPI,
  callAPI,
  callAPIWithErrorMessage,
  communicationHubAlertAPI,
  communicationHubAppAPI,
  communicationHubChannelAPI,
  communicationHubCounterAPI,
  communicationHubMessageAPI,
  communicationHubWorkOrderAPI,
  dispatchDeliveryOrderAPI,
  DispatchDeliveryOrderSearchDTO,
  DispatchDeliveryOrderViewDTO,
  notificationAPI,
  officialFormAPI,
  TransportationActivityViewDTO,
  TroubleTicketDTO,
  userAPI
} from '../../../api/api'
import {
  CreateNotificationDTO,
  GetNotificationListDTO,
  NotificationDTO,
  WorkOrderDTO
} from '../../../api/origin/communication-hub-service'
import { constructMessageToDriver } from '../../../services/DTO/dispatchDeliveryOrder/constructMessageToDriver'
import { requestDispatchDeliveryOrderById } from '../../common/dispatchDeliveryOrder/epics'
import { handleReceivedChatMessage } from '../functions/handleReceivedChatMessage'
import communicationHubAxios from './communicationHubAxios'
import { isBusinessActivity } from '../../../services/functions/test/isBusinessActivity'
import { getDispatch } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { clearMessageBlockType } from '../functions/clearMessageBlockType'
import {
  ApplicationFormDTO,
  FileDTO,
  OfficialFormDTO,
  PreviewApplicationFormRequestDTO,
  PreviewOfficialFormRequestDTO,
  PreviewOfficialFormResponseDTO
} from '../../../api/origin/document-service'

export const requestFirebaseToken = (): Promise<{ firebaseToken: string } | undefined> => {
  // @ts-ignore
  return callAPI(communicationHubAppAPI.appControllerGetFirebaseToken)
    .toPromise()
    .then(data => {
      return data.json()
    })
}

// CHANNEL
export const setChatChannelLastOpen = (channelId: string) => {
  return callAPI(communicationHubChannelAPI.channelControllerOpen, channelId).toPromise()
}

export const markAsReadChatMessage = async (messageId: string) => {
  return callAPI(communicationHubMessageAPI.messageControllerRead, messageId)
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const markAsUnreadChatMessage = async (messageId: string) => {
  return callAPI(communicationHubMessageAPI.messageControllerUnread, messageId)
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const markAllMessagesRead = () => {
  return callAPI(communicationHubMessageAPI.messageControllerMarkAllRead).toPromise()
}

export const getGeneralChatCounter = () => {
  return callAPI(communicationHubCounterAPI.counterControllerGetGeneralCounters).toPromise()
}

export const getUserChatCounter = () => {
  return callAPI(communicationHubCounterAPI.counterControllerGetUserCounters).toPromise()
}

export const muteChannel = (channelId: string) => {
  getDispatch()(communicationHubActions.setChannelIdMuteState({ channelId, mute: true }))

  return callAPI(communicationHubChannelAPI.channelControllerMute, channelId)
    .toPromise()
    .catch(() => {
      getDispatch()(communicationHubActions.setChannelIdMuteState({ channelId, mute: false }))
    })
}

export const unmuteChannel = (channelId: string) => {
  getDispatch()(communicationHubActions.setChannelIdMuteState({ channelId, mute: false }))

  return callAPI(communicationHubChannelAPI.channelControllerUnmute, channelId)
    .toPromise()
    .catch(() => {
      getDispatch()(communicationHubActions.setChannelIdMuteState({ channelId, mute: true }))
    })
}

export const getDispatchDeliveryOrderChannels = (
  limit?: number,
  term?: string,
  ids?: string[]
): Promise<DispatchDeliveryOrderSearchDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.search, {
    limit: ids && ids.length ? ids.length : limit || 99,
    term,
    ids: ids && ids.length ? ids : undefined
  }).toPromise()
}

// MESSAGE
export const getChatMessages = (
  channelId: string,
  options?: {
    limit?: number
    page?: number
  }
) => {
  const { limit, page } = options || {}

  return callAPI(
    communicationHubMessageAPI.messageControllerGetMessages,
    channelId,
    limit || 999,
    page || 1
  ).toPromise()
}

export const getAllUnreadMessages = (): Promise<IMessage[]> => {
  return callAPI(communicationHubMessageAPI.messageControllerGetAllUnreadMessages).toPromise()
}

export const getAllChannelDocumentMessages = (channelId: string): Promise<IMessage[]> => {
  return callAPI(() => communicationHubAxios.get(`/document`, { params: { channelId } }))
    .toPromise()
    .then(data => {
      if (data && Array.isArray(data.data)) {
        return data.data
      }

      return []
    })
    .catch(() => {
      return []
    })
}

export const createChatDocumentMessage = (documentMessage: IDocumentMessage): Promise<IMessage> => {
  return callAPI(() => communicationHubAxios.post(`/document`, documentMessage))
    .toPromise()
    .then(data => {
      if (data && typeof data.data === 'object') {
        handleReceivedChatMessage(data.data)
        return data.data
      }
    })
}

export const createChatMessage = (message: IMessage) => {
  return callAPI(communicationHubMessageAPI.messageControllerCreate, {
    ...clearMessageBlockType(message),
    // @ts-ignore
    id: undefined
  })
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const updateChatMessage = (message: IMessage) => {
  return callAPI(communicationHubMessageAPI.messageControllerUpdate, message.id, clearMessageBlockType(message))
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const deleteChatMessage = (messageId: string) => {
  return callAPI(communicationHubMessageAPI.messageControllerRemove, messageId)
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const pinChatMessage = async (messageId: string, message?: IMessage) => {
  return callAPI(communicationHubMessageAPI.messageControllerPin, messageId)
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

export const unpinChatMessage = async (messageId: string, message?: IMessage) => {
  return callAPI(communicationHubMessageAPI.messageControllerUnpin, messageId)
    .toPromise()
    .then(data => {
      handleReceivedChatMessage(data)
      return data
    })
}

// ALERTS
export const getAlerts = async (props: { channelId?: string; status?: TroubleTicketDTO.StatusEnum }) => {
  const { channelId, status } = props
  return callAPI(communicationHubAlertAPI.alertControllerGetAlerts, channelId, status as any).toPromise()
}

// USER
export const getUsers = () => {
  return callAPI(userAPI.userControllerGetAll).toPromise()
}

// WORK ORDER
export const createWorkOrder = async (props: {
  ddoId: string
  fullDispatchDeliveryOrder?: DispatchDeliveryOrderViewDTO
  drivers: { vendorId: string; authUserId: string }[]
}) => {
  try {
    const { ddoId, drivers } = props
    const dispatchDeliveryOrder = props.fullDispatchDeliveryOrder || (await requestDispatchDeliveryOrderById(ddoId))
    const container = oc(dispatchDeliveryOrder).container()

    const workOrder: WorkOrderDTO = {
      ddoId,
      ddoNumber: oc(dispatchDeliveryOrder).number(),
      selectedDrivers: []
    }

    if (container && container.id && container.number) {
      workOrder.container = { id: container.id, number: container.number }
    }

    for (const driver of drivers) {
      const driverActivities = dispatchDeliveryOrder.activities.transportationActivities.filter(
        item =>
          item.vendorId === driver.vendorId && item.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
      )
      const filteredDriverActivities = driverActivities.filter(item => isBusinessActivity(item))

      workOrder.selectedDrivers.push({
        id: driver.vendorId,
        authUserId: driver.authUserId,
        // @ts-ignore
        activities: filteredDriverActivities,
        message: constructMessageToDriver({
          fullDispatchDeliveryOrder: dispatchDeliveryOrder,
          driverActivities
        })
      })
    }

    return callAPI(communicationHubWorkOrderAPI.workOrderControllerCreate, workOrder)
      .toPromise()
      .then(workOrderMessages => {
        const errorMessage = workOrderMessages.find(m => m.errorMessage)

        workOrderMessages.forEach(m => {
          if (m.id) {
            handleReceivedChatMessage(m)
          }
        })

        if (errorMessage) {
          // tslint:disable-next-line:no-console
          console.error(errorMessage)
          toast.error(
            <>
              <i className={'mdi mdi-close'} />
              Error on assigning driver
            </>
          )
        } else {
          toast.success(
            <>
              <i className={'mdi mdi-check'} />
              Driver assigned
            </>
          )
        }
        return workOrderMessages
      })
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error(err)
    toast.error(
      <>
        <i className={'mdi mdi-close'} />
        Error on assigning driver
      </>
    )

    return null
  }
}

export const requestApplicationForms = (lang?: ApplicationFormDTO.LangEnum): Promise<ApplicationFormDTO[]> => {
  // @ts-ignore
  return callAPI(applicationFormAPI.getAllApplicationForms, lang)
    .toPromise()
    .catch(() => [])
}

export const requestOfficialForms = (lang?: OfficialFormDTO.LangEnum): Promise<OfficialFormDTO[]> => {
  // @ts-ignore
  return callAPI(officialFormAPI.getAllOfficialForms, lang)
    .toPromise()
    .catch(() => [])
}

export const requestNotificationDocumentForms = async (): Promise<NotificationDocumentForm[]> => {
  const applicationForms = await requestApplicationForms()
  const officialForms = await requestOfficialForms()
  const documentForms: NotificationDocumentForm[] = [
    ...(applicationForms || []).map(item => ({ ...item, isApplicationForm: true })),
    ...(officialForms || []).map(item => ({ ...item, isOfficialForm: true }))
  ]

  getDispatch()(communicationHubActions.setDocumentForms(documentForms))

  return documentForms
}

export const requestApplicationFormPreview = (props: PreviewApplicationFormRequestDTO): Promise<FileDTO> => {
  if (props && props.documentType && props.driverType && props.lang) {
    return callAPI(applicationFormAPI.createAndRenderApplicationFormDocumentPreview, props)
      .toPromise()
      .catch(() => null)
  }
}

export const requestOfficialFormPreview = (
  props: PreviewOfficialFormRequestDTO
): Promise<PreviewOfficialFormResponseDTO> => {
  if (props && props.lang && props.type) {
    return callAPI(officialFormAPI.createAndGetOfficialFormDocumentPreview, props)
      .toPromise()
      .catch(() => null)
  }
}

export const requestNotificationCreate = (notification: CreateNotificationDTO): Promise<NotificationDTO> => {
  return callAPI(notificationAPI.notificationControllerCreate, notification)
    .toPromise()
    .catch(async errors => {
      const result = await (errors && errors.json ? errors.json() : errors)

      return Promise.reject(result)
    })
}

export const requestNotificationComplete = (notificationId: string): Promise<NotificationDTO> => {
  return callAPIWithErrorMessage(notificationAPI.notificationControllerComplete, notificationId)
}

export const requestNotificationDelete = (notificationId: string): Promise<NotificationDTO> => {
  return callAPIWithErrorMessage(notificationAPI.notificationControllerDelete, notificationId)
}

export const requestNotifications = (options: GetNotificationListDTO): Promise<NotificationDTO[]> => {
  return callAPIWithErrorMessage(notificationAPI.notificationControllerFindAll, options)
}

export const requestNotificationReplies = (notificationId: string): Promise<string> => {
  return callAPI(notificationAPI.notificationControllerGetReplies, notificationId)
    .toPromise()
    .then(data => data.text())
}
