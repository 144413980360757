import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { Column, DefaultColumn } from '../../../../../../../../../UI/GridSystem'
import { DriverSearch } from '../../../../../../../../../UI/DataFields/SearchByList/DriverSearch'
import {
  ActivityGroupAction,
  ActivityRow,
  TransportationActivityRow
} from '../../../../../../../../../../services/DTO/activity/controller'
import {
  TransportationActivityViewDTO,
  LocationViewDTO,
  DriverNameDTO,
  DriverDTO,
  TransportationActivityBobtailToDTO
} from '../../../../../../../../../../api/origin/business-logic'
import { LocationWidget } from '../../../../../../../../../UI/Widget/widgets/location/simple'
import { DriverLocationRow } from '../../../styles'
import { TransportationActivityGroup } from '../../../../../../../../../../services/DTO/activity/interfaces'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import { useAppSelector } from '../../../../../../../../../../hooks/useAppSelector'
import { selectDriver } from '../../../../../../../../../../store/select/driverSelect'
import {
  isDropBobtailGotoActivity,
  isPickBobtailGotoActivity
} from '../../../../../../../../../../services/functions/test/isBobtailGotoActivity'
import { getNextActivityRow } from '../../../../../../../../../../services/functions/get/getNextActivityRow'
import { isActualActivity } from '../../../../../../../../../../services/functions/test/isActualActivity'
import { isDropActivity } from '../../../../../../../../../../services/functions/test/isDropActivity'
// tslint:disable:max-line-length
import { getActivityDropBobtailGroup } from '../../../../../../../../../../services/functions/get/getActivityDropBobtailGroup'
import { TroubleTicket } from '../Popups/TroubleTicket/TroubleTicket'
import { useGridItemDDO } from '../../../../../../../../../../hooks/useGridItemDDO'
import { getListsState } from '../../../../../../../../../../store'
import { EntityType } from '../../../../../../../../../../store/reducers/lists/interfaces'

type Props = {}

export const VendorLocationActivityColumn = (props: Props) => {
  const {
    isBobtail,
    isPickBobtail,
    isDropBobtail,
    isUnsuccessful,
    allowModifying,
    isModifiedMode,
    transportationRow,
    updateActivityRow,
    stageActivityRows,
    rowNumber,
    stage,
    dispatchDeliveryOrder,
    anotherDOOActivityLink,
    troubleTicketId
  } = useTransportationActivityRow()
  const { activities } = useGridItemDDO()
  const activityGroup: TransportationActivityGroup = transportationRow.activityGroup
  const driver = useAppSelector(selectDriver(oc(activityGroup).gotoActivity.vendorId()))

  const isVendorAndLocationRequired =
    (isBobtail && isUnsuccessful) ||
    (activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED &&
      activityGroup.businessActivity.type !== TransportationActivityViewDTO.TypeEnum.EMPTY) ||
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED ||
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED ||
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
    activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED

  const generalDisableLocationEditing =
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
    activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
    activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL ||
    activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL

  const removeDropBobtailsGroupsIfLocationBecomesContainerYard = (
    activity: TransportationActivityViewDTO,
    updatedLocation: LocationViewDTO
  ) => {
    if (
      updatedLocation &&
      updatedLocation.type === LocationViewDTO.TypeEnum.CONTAINERYARD &&
      isDropActivity(activity)
    ) {
      const activityBobtails = getActivityDropBobtailGroup({
        activity,
        activities: activities.transportationActivities
      })

      if (activityBobtails.length) {
        const rowsToDelete = (stageActivityRows as TransportationActivityRow[])
          .filter(row => activityBobtails.some(group => oc(group).gotoActivity.id() === row.id))
          .filter(Boolean)

        if (rowsToDelete.length) {
          updateActivityRow(rowsToDelete, ActivityGroupAction.remove)
        }
      }
    }
  }

  return (
    <DriverLocationRow className={'driver-location-column'} style={{ border: 'none' }}>
      {Boolean(troubleTicketId) && <TroubleTicket key={dispatchDeliveryOrder.id} troubleTicketId={troubleTicketId} />}
      <Column
        data-id={[
          'go-to-activity-driver',
          'business-activity-driver',
          'vendorName-' + (driver || oc(driver).name()),
          'vendorId-' + oc(driver).id(),
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={'driver'}
      >
        <DriverSearch
          disabled={
            !allowModifying ||
            Boolean(anotherDOOActivityLink) ||
            isDropBobtail ||
            (generalDisableLocationEditing && Boolean(activityGroup.gotoActivity.vendorId))
          }
          selectedDriverId={oc(activityGroup).gotoActivity.vendorId()}
          highlighted={activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED}
          required={isVendorAndLocationRequired}
          filterListResultFunction={({ driverType }: DriverNameDTO) => driverType !== DriverDTO.DriverTypeEnum.VENDOR}
          onChange={vendor => {
            const modifiedActivityGroup: TransportationActivityGroup = R.clone(activityGroup)
            const { gotoActivity, businessActivity } = modifiedActivityGroup

            if (vendor) {
              if (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.NEW) {
                gotoActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
              }
              if (businessActivity.status === TransportationActivityViewDTO.StatusEnum.NEW) {
                businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
              }
              gotoActivity.vendorId = businessActivity.vendorId = vendor.id
            } else {
              gotoActivity.status = TransportationActivityViewDTO.StatusEnum.NEW
              businessActivity.status = TransportationActivityViewDTO.StatusEnum.NEW
              gotoActivity.vendorId = businessActivity.vendorId = undefined
              gotoActivity.startActualDate = businessActivity.startActualDate = undefined
              gotoActivity.completionActualDate = businessActivity.completionActualDate = undefined
            }

            const updatedActivityRow = { ...transportationRow, activityGroup: modifiedActivityGroup }
            const updatedBobtailActivityRow = linkUpdatesToBobtailActivityRow({
              updatedActivityRow,
              stageActivityRows,
              anotherDOOActivityLink
            })

            updateActivityRow(
              [updatedActivityRow, updatedBobtailActivityRow].filter(Boolean),
              ActivityGroupAction.severalUpdates
            )
          }}
        />
      </Column>
      <DefaultColumn
        data-id={[
          'go-to-activity-location',
          'business-activity-location',
          'locationId-' + oc(activityGroup).gotoActivity.destinationId(),
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
      >
        <LocationWidget
          required={isVendorAndLocationRequired}
          disableWidget={
            !allowModifying ||
            Boolean(isDropBobtail && anotherDOOActivityLink) ||
            (generalDisableLocationEditing && Boolean(activityGroup.gotoActivity.destinationId)) ||
            (anotherDOOActivityLink &&
              (activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
                activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED))
          }
          id={activityGroup.gotoActivity.destinationId}
          changeLocationId={locationId => {
            const location = oc(getListsState())[EntityType.location][locationId]() as LocationViewDTO
            const updatedGroups: TransportationActivityRow[] = []

            const updateActivityGroupRow = (row: TransportationActivityRow): void => {
              const modifiedActivityGroup: TransportationActivityGroup = R.clone(row.activityGroup)
              const { gotoActivity, businessActivity } = modifiedActivityGroup

              if (
                gotoActivity.vendorId &&
                gotoActivity.destinationId &&
                locationId !== gotoActivity.destinationId &&
                (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED ||
                  gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED ||
                  gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS)
              ) {
                gotoActivity.status = businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
                gotoActivity.startActualDate = businessActivity.startActualDate = undefined
                gotoActivity.completionActualDate = businessActivity.completionActualDate = undefined
              }

              gotoActivity.destinationId = businessActivity.destinationId = locationId

              if (isPickBobtail && location && location.type === LocationViewDTO.TypeEnum.CONTAINERYARD) {
                gotoActivity.type = TransportationActivityViewDTO.TypeEnum.GOTO
              }

              updatedGroups.push({ ...row, activityGroup: modifiedActivityGroup })
              removeDropBobtailsGroupsIfLocationBecomesContainerYard(businessActivity, location)
            }

            if (activityGroup.gotoActivity.template) {
              const groupsToUpdate = stageActivityRows.filter(row =>
                oc(row as TransportationActivityRow).activityGroup.gotoActivity.template()
              ) as TransportationActivityRow[]
              groupsToUpdate.forEach(updateActivityGroupRow)
            } else {
              updateActivityGroupRow(transportationRow)
            }

            updateActivityRow(updatedGroups, ActivityGroupAction.severalUpdates)
          }}
          // TODO
          // updateLocation={location => {
          //   const modifiedActivityGroup: TransportationActivityGroup = R.clone(activityGroup)
          //   const { gotoActivity, businessActivity } = modifiedActivityGroup

          //   if (gotoActivity.template) {
          //     const groupsToUpdate = stageActivityRows.filter(row =>
          //       oc(row as TransportationActivityRow).activityGroup.gotoActivity.template()
          //     ) as TransportationActivityRow[]

          //     updateActivityRow(
          //       groupsToUpdate.map(row => {
          //         return {
          //           ...row,
          //           activityGroup: {
          //             ...row.activityGroup,
          //             gotoActivity: {
          //               ...row.activityGroup.gotoActivity,
          //               destination: location || undefined,
          //               type:
          //                 isPickBobtailGotoActivity(row.activityGroup.gotoActivity) &&
          //                 location &&
          //                 location.type === LocationViewDTO.TypeEnum.CONTAINERYARD
          //                   ? TransportationActivityViewDTO.TypeEnum.GOTO
          //                   : row.activityGroup.gotoActivity.type
          //             },
          //             businessActivity: { ...row.activityGroup.businessActivity, destination: location || undefined }
          //           }
          //         }
          //       }),
          //       ActivityGroupAction.severalUpdates
          //     )
          //     removeDropBobtailsGroupsIfLocationBecomesContainerYard(businessActivity, location)
          //     return
          //   }

          //   gotoActivity.destination = businessActivity.destination = location || undefined

          //   if (isPickBobtail && location && location.type === LocationViewDTO.TypeEnum.CONTAINERYARD) {
          //     gotoActivity.type = TransportationActivityViewDTO.TypeEnum.GOTO
          //   }

          //   updateActivityRow(
          //     { ...transportationRow, activityGroup: modifiedActivityGroup },
          //     ActivityGroupAction.update
          //   )
          //   removeDropBobtailsGroupsIfLocationBecomesContainerYard(businessActivity, location)
          // }}
        />
      </DefaultColumn>
    </DriverLocationRow>
  )
}

const linkUpdatesToBobtailActivityRow = (props: {
  updatedActivityRow: TransportationActivityRow
  stageActivityRows: ActivityRow[]
  anotherDOOActivityLink: TransportationActivityBobtailToDTO
}): TransportationActivityRow | undefined => {
  const { updatedActivityRow, stageActivityRows, anotherDOOActivityLink } = props
  const { gotoActivity, businessActivity } = updatedActivityRow.activityGroup
  let result: TransportationActivityRow = undefined

  const link = (bobtailActivityRow: TransportationActivityRow) => {
    const modifiedBobtailActivityGroup: TransportationActivityGroup = R.clone(bobtailActivityRow.activityGroup)
    const driverHasBeenChanged =
      oc(modifiedBobtailActivityGroup).gotoActivity.vendorId() !== oc(gotoActivity).vendorId()

    modifiedBobtailActivityGroup.gotoActivity.vendorId = gotoActivity.vendorId
    modifiedBobtailActivityGroup.businessActivity.vendorId = businessActivity.vendorId

    if (driverHasBeenChanged && anotherDOOActivityLink) {
      modifiedBobtailActivityGroup.gotoActivity.status = modifiedBobtailActivityGroup.businessActivity.status =
        TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
    }

    if (!modifiedBobtailActivityGroup.gotoActivity.vendorId) {
      modifiedBobtailActivityGroup.gotoActivity.status = modifiedBobtailActivityGroup.businessActivity.status =
        TransportationActivityViewDTO.StatusEnum.NEW
    } else {
      modifiedBobtailActivityGroup.gotoActivity.status = modifiedBobtailActivityGroup.businessActivity.status =
        TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
    }

    result = { ...bobtailActivityRow, activityGroup: modifiedBobtailActivityGroup }
  }

  if (isDropActivity(businessActivity)) {
    let nextActivityRow = getNextActivityRow(stageActivityRows, businessActivity.id, {
      returnOnlyTransportation: true
    }) as TransportationActivityRow

    if (nextActivityRow) {
      if (isDropBobtailGotoActivity(nextActivityRow.activityGroup.gotoActivity)) {
        if (isActualActivity(nextActivityRow.activityGroup.gotoActivity)) {
          link(nextActivityRow)
        } else {
          nextActivityRow = getNextActivityRow(stageActivityRows, nextActivityRow.activityGroup.businessActivity.id, {
            returnOnlyTransportation: true
          }) as TransportationActivityRow

          if (
            nextActivityRow &&
            isDropBobtailGotoActivity(nextActivityRow.activityGroup.gotoActivity) &&
            isActualActivity(nextActivityRow.activityGroup.gotoActivity)
          ) {
            link(nextActivityRow)
          }
        }
      }
    }
  }

  return result
}
