import { ContainerDTO, ContainerViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateContainer = (props?: Partial<ContainerViewDTO>): ContainerViewDTO => {
  return {
    completeness: maxCompleteness,
    id: createId(),
    containerTypeId: undefined,
    number: '',
    active: true,
    unavailabilityReason: null,
    inactiveReason: null,
    availability: true,
    weightUnit: ContainerDTO.WeightUnitEnum.LBS,
    specs: '',
    ownership: ContainerDTO.OwnershipEnum.LINEOWNED,
    ...(props || {})
  }
}
