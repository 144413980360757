import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { ColumnRow } from '../../../../../UI/GridSystem'
import { ContainerViewDTO, EquipmentDTO } from '../../../../../../api/api'
import { ContainerSearch, EquipmentSearch } from '../../../../../UI/DataFields/SearchRequest'
import { compareDates, convertISODateToDateWithHoursMins } from '../../../../../../services/timeService/dateUtils'
import { isNewObject } from '../../../../../../services/DTO'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { StyledTopBlock } from './styledComponents'
import {
  alertOnChangingMainStreetTurnPropsOfDDO,
  isContainerRequiredForDDO
} from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'
import { SsqBsqWidget } from '../../../../../UI/Widget/widgets/SsqBsqWidget/SsqBsqWidget'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'

type OwnProps = {
  showDates?: boolean
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const ActivitiesTopSection = (props: Props) => {
  const { showDates } = props
  const {
    enableEditing,
    isChassisNumberRequired,
    container,
    equipment,
    dispatchDeliveryOrder,
    deliveryOrder,
    modifyDispatchDeliveryOrder,
    modifyDispatchDeliveryOrderField,
    modifyDeliveryOrderField
  } = useGridItemDDO()
  const { modifyListItems, modifyParentObjectField } = useExpandedItem()

  const updateEquipment = (_equipment: EquipmentDTO) => {
    if (isNewObject(_equipment)) {
      modifyListItems({ [EntityType.equipment]: [_equipment] })
    }

    modifyParentObjectField('equipmentId')(oc(_equipment).id())
  }

  const updateEquipmentDate = (field: string) => (value: any) => {
    updateEquipment({
      ...equipment,
      [field]: value
    } as EquipmentDTO)
  }

  const isNewChassis = isNewObject(equipment)

  const estimate = {
    calcDates:
      oc(equipment).pickupDate() && oc(equipment).returnDate()
        ? Math.ceil(Number(compareDates(oc(equipment).pickupDate(), oc(equipment).returnDate(), 'days')))
        : '',
    placeholder: isNewChassis ? 'MM/DD/YY' : ' '
  }

  return (
    <StyledTopBlock className={'activities__top_block'}>
      <ColumnRow data-id={'size'} columns={15} isFixed={true}>
        <FieldTemplate.ContainerTypeSearch
          id={dispatchDeliveryOrder.containerTypeId}
          disabled={enableEditing === false}
          title={'Size'}
          required={true}
          onChange={_containerType => {
            const containerTypeId = oc(_containerType).id()
            const updatedDDO = { ...dispatchDeliveryOrder, containerTypeId }

            if (container && container.containerTypeId !== containerTypeId) {
              updatedDDO.containerId = undefined
              updatedDDO.containerTypeId = undefined

              modifyDispatchDeliveryOrder(updatedDDO)
            } else {
              modifyDispatchDeliveryOrderField('containerTypeId')(containerTypeId)
            }

            alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
          }}
        />
      </ColumnRow>
      <ColumnRow data-id={'container'} columns={9} isFixed={true} margin={{ left: 10 }}>
        <ContainerSearch
          required={isContainerRequiredForDDO(deliveryOrder.type, dispatchDeliveryOrder.status)}
          disabled={enableEditing === false}
          allowCreate={true}
          title={'Container #'}
          placeholder={dispatchDeliveryOrder.containerNumberPlaceholder}
          onChange={(_container: ContainerViewDTO) => {
            const containerId = oc(_container).id()
            const containerTypeId = oc(_container).containerTypeId()
            const steamShipLineId = oc(_container).steamShipLineId()
            const updatedDDO = {
              ...dispatchDeliveryOrder,
              containerId,
              containerTypeId: containerTypeId || oc(dispatchDeliveryOrder).containerTypeId()
            }

            modifyDispatchDeliveryOrder(updatedDDO)

            if (!deliveryOrder.steamShipLineId) {
              modifyDeliveryOrderField('steamShipLineId')(steamShipLineId)
            }

            alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
          }}
          id={dispatchDeliveryOrder.containerId}
          steamShipLineId={oc(deliveryOrder).steamShipLineId()}
          containerTypeId={dispatchDeliveryOrder.containerTypeId}
        />
      </ColumnRow>
      <ColumnRow data-id={'chassisNumber'} columns={9} isFixed={true} margin={{ left: 10 }}>
        <EquipmentSearch
          disabled={enableEditing === false}
          required={isChassisNumberRequired}
          allowCreate={true}
          title={'Chassis #'}
          equipmentId={dispatchDeliveryOrder.equipmentId}
          onChange={_equipment => modifyDispatchDeliveryOrderField('equipmentId')(oc(_equipment).id())}
        />
      </ColumnRow>
      <ColumnRow data-id={'pickupDate'} columns={8} isFixed={true} margin={{ left: 10 }}>
        <DateTimePicker
          title={'Chassis Pickup'}
          disabled={true}
          placeholder={estimate.placeholder}
          date={oc(equipment).pickupDate()}
          onChange={() => {}}
        />
      </ColumnRow>
      <ColumnRow data-id={'returnDate'} columns={8} isFixed={true} margin={{ left: 10 }}>
        <DateTimePicker
          title={'Chassis Return'}
          disabled={true}
          placeholder={estimate.placeholder}
          date={oc(equipment).returnDate()}
          onChange={() => {}}
        />
      </ColumnRow>
      <ColumnRow data-id={'days'} columns={4} isFixed={true} margin={{ left: 10 }}>
        <FieldContainer title={'Chassis Days'} wrapChild={true} childrenStyle={{ height: 35, fontSize: 13 }}>
          {oc(equipment).days() || estimate.calcDates}
        </FieldContainer>
      </ColumnRow>

      <div style={extraWidgetContainerStyles}>
        <SsqBsqWidget />
      </div>

      {showDates && (
        <SUpdateInfo>
          <FieldContainer title={'Created'}>
            <Info style={{ marginBottom: 10 }}>
              {convertISODateToDateWithHoursMins(dispatchDeliveryOrder.createdAt)}
              <br />
              {dispatchDeliveryOrder.createdBy}
            </Info>
          </FieldContainer>

          <FieldContainer title={'Updated'}>
            <Info>
              {convertISODateToDateWithHoursMins(dispatchDeliveryOrder.updatedAt)}
              <br />
              {dispatchDeliveryOrder.updatedBy}
            </Info>
          </FieldContainer>
        </SUpdateInfo>
      )}
    </StyledTopBlock>
  )
}

const Info = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgb(108, 112, 125);
  margin-left: 10px;
`

const SUpdateInfo = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;

    label {
      font-size: 10px;
      color: rgb(69, 85, 208);
      margin: 0;
    }
  }
`

const extraWidgetContainerStyles = {
  minWidth: 280,
  marginLeft: 'auto',
  marginRight: 15
}
