import * as React from 'react'

// tslint:disable:max-line-length
export const TrashIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.6999 2.10001C7.39689 2.10001 7.11988 2.27121 6.98436 2.54224L6.40548 3.70001H3.6999C3.25807 3.70001 2.8999 4.05818 2.8999 4.50001C2.8999 4.94183 3.25807 5.30001 3.6999 5.30001L3.6999 13.3C3.6999 14.1837 4.41625 14.9 5.2999 14.9H11.6999C12.5836 14.9 13.2999 14.1837 13.2999 13.3V5.30001C13.7417 5.30001 14.0999 4.94183 14.0999 4.50001C14.0999 4.05818 13.7417 3.70001 13.2999 3.70001H10.5943L10.0154 2.54224C9.87993 2.27121 9.60292 2.10001 9.2999 2.10001H7.6999ZM6.0999 6.90001C6.0999 6.45818 6.45807 6.10001 6.8999 6.10001C7.34173 6.10001 7.6999 6.45818 7.6999 6.90001V11.7C7.6999 12.1418 7.34173 12.5 6.8999 12.5C6.45807 12.5 6.0999 12.1418 6.0999 11.7V6.90001ZM10.0999 6.10001C9.65807 6.10001 9.2999 6.45818 9.2999 6.90001V11.7C9.2999 12.1418 9.65807 12.5 10.0999 12.5C10.5417 12.5 10.8999 12.1418 10.8999 11.7V6.90001C10.8999 6.45818 10.5417 6.10001 10.0999 6.10001Z"
          fill="#4F4F4F"
        />
      </g>
    </svg>
  )
}
