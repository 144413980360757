import * as React from 'react'
import { oc } from 'ts-optchain'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TableCell } from '../../../grid/views/styles'
import { containerDirectory } from '../../../../../services/DTO/container/directory'
import { useGridItemContainer } from '../../../../../hooks/useGridItemContainer'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { ContainerColumn } from '../../../grid/columnSettings/containerColumnSettings'

const Column = (props: TGridColumn) => {
  const { className, columnName, style } = props
  const { container, containerType, steamShipLine } = useGridItemContainer()
  let text = columnName

  switch (columnName) {
    case ContainerColumn.ContainerNumber: {
      text = container.number
      break
    }
    case ContainerColumn.ActiveAvailability:
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className} data-copy-value-on-mousedown={true}>
          <AvailabilityColumn />
        </TableCell>
      )
    case ContainerColumn.Type: {
      text = oc(containerType).name()
      break
    }
    case ContainerColumn.MaxCargoWeight: {
      text = container.weight && container.weightUnit ? container.weight + ' ' + container.weightUnit : ''
      break
    }
    case ContainerColumn.Ownership: {
      text = container.ownership ? containerDirectory.ownership[container.ownership] : ''
      break
    }
    case ContainerColumn.SSL: {
      text = oc(steamShipLine).name()
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      {text}
    </TableCell>
  )
}

export default Column
