import * as React from 'react'
import cn from 'classnames'
import { StyledColumnValueWithPopover, StyledLabel, StyledValue, StyledValueAsNumber, StyledPopover } from './styles'
import { WindowPopover } from '../windowPopover'

type TColumnValuePopover = {
  styles?: any
  values: string[]
  showNumberAsValue?: boolean
  placeholder?: string
  separateRows?: boolean
  offset?: number
}
export const ColumnValueWithPopover = (props: TColumnValuePopover) => {
  const { styles, showNumberAsValue, values, placeholder = '–', separateRows, offset = 3 } = props
  const [showPopover, setShowPopover] = React.useState(false)
  // const containerRef = React.createRef<any>()
  const containerRef = React.useRef(null)

  if (!values || values.length === 0) {
    return <>{placeholder}</>
  }

  const onMouseEnter = () => setShowPopover(true)
  const onMouseLeave = () => setShowPopover(false)
  const makeAdditionalStyles = () => {
    if (!containerRef.current) {
      return
    }
    const container = containerRef.current.getBoundingClientRect()
    return {
      zIndex: 999,
      transform: `translate(calc(-50% + ${container.width / 2}px), calc(-100% - ${offset}px - ${container.height}px))`
    }
  }

  return (
    <StyledColumnValueWithPopover
      ref={containerRef}
      className={cn({ separateRows })}
      style={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Label values={values} showNumberAsValue={showNumberAsValue} />
      {showPopover && (
        <WindowPopover
          bindToRef={containerRef}
          isExternal={true}
          flexibleWidth={true}
          containerStyles={makeAdditionalStyles()}
        >
          <Popover values={values} separateRows={separateRows} />
        </WindowPopover>
      )}
    </StyledColumnValueWithPopover>
  )
}

type TLabel = {
  values: string[]
  showNumberAsValue?: boolean
}
const Label = ({ values, showNumberAsValue }: TLabel) => {
  return (
    <StyledLabel>
      {showNumberAsValue && values.length > 1 ? (
        <StyledValueAsNumber>
          <span>{values.length}</span>
        </StyledValueAsNumber>
      ) : (
        <StyledValue>
          {values.map(value => (
            <div key={value}>{value}</div>
          ))}
        </StyledValue>
      )}
    </StyledLabel>
  )
}

type TPopover = {
  values: string[]
  separateRows: boolean
}
const Popover = ({ values, separateRows }: TPopover) => {
  return (
    <StyledPopover>
      <ListContainer separateRows={separateRows}>
        {values.map(value => (
          <ListItem key={value} separateRows={separateRows}>
            {value}
          </ListItem>
        ))}
      </ListContainer>
    </StyledPopover>
  )
}

const ListContainer = ({ children, separateRows }: { children: any; separateRows: boolean }) =>
  separateRows ? <ul>{children}</ul> : <div>{children}</div>

const ListItem = ({ children, separateRows }: { children: any; separateRows: boolean }) =>
  separateRows ? <li>{children}</li> : <span>{children}</span>

// import * as React from 'react'
// import { StyledColumnValueWithPopover, StyledLabel, StyledValue, StyledValueAsNumber, StyledPopover } from './styles'
// import cn from 'classnames'
// import Hint from 'react-portal-hint'
//
// type TColumnValuePopover = {
//   styles?: any
//   values: string[]
//   showNumberAsValue?: boolean
//   placeholder?: string
//   separateRows?: boolean
// }
// export const ColumnValueWithPopover = (props: TColumnValuePopover) => {
//   const { styles, showNumberAsValue, values, placeholder = '–', separateRows } = props
//
//   if (!values || values.length === 0) {
//     return placeholder
//   }
//
//   const hintRef = React.createRef<Hint>()
//   return (
//     <StyledColumnValueWithPopover className={cn({ separateRows })} style={styles}>
//       <Hint
//         ref={hintRef}
//         content={<Popover values={values} separateRows={separateRows} />}
//         place={'top'}
//         targetMoves={true}
//       >
//         <div>
//           <Label values={values} showNumberAsValue={showNumberAsValue} />
//         </div>
//       </Hint>
//     </StyledColumnValueWithPopover>
//   )
// }
//
// type TLabel = {
//   values: string[]
//   showNumberAsValue?: boolean
// }
// const Label = ({ values, showNumberAsValue }: TLabel) => {
//   return (
//     <StyledLabel>
//       {showNumberAsValue && values.length > 1 ? (
//         <StyledValueAsNumber>
//           <span>{values.length}</span>
//         </StyledValueAsNumber>
//       ) : (
//         <StyledValue>
//           {values.map(value => (
//             <div key={value}>{value}</div>
//           ))}
//         </StyledValue>
//       )}
//     </StyledLabel>
//   )
// }
//
// type TPopover = {
//   values: string[]
//   separateRows: boolean
// }
// const Popover = ({ values, separateRows }: TPopover) => {
//   return (
//     <StyledPopover>
//       <ListContainer separateRows={separateRows}>
//         {values.map(value => (
//           <ListItem key={value} separateRows={separateRows}>
//             {value}
//           </ListItem>
//         ))}
//       </ListContainer>
//     </StyledPopover>
//   )
// }
//
// const ListContainer = ({ children, separateRows }: { children: any; separateRows: boolean }) =>
//   separateRows ? <ul>{children}</ul> : <div>{children}</div>
//
// const ListItem = ({ children, separateRows }: { children: any; separateRows: boolean }) =>
//   separateRows ? <li>{children}</li> : <span>{children}</span>
