import * as React from 'react'
import { SWidget, Search, Options, DynamicOptions, StaticOption, Option } from './styles'
import { Checkbox } from '../../../Radio'
import { WindowPopover } from '../../../windowPopover'
import { TSelecterWidgetListItem, TSelecterWidgetStatisItem } from './index'
import { ClearButton, Container, StyledInput } from '../../Input/styles'
import { GridSpinner } from '../../../Spinner/Spinner'

type Props = {
  wrapperRef: any
  staticList: TSelecterWidgetStatisItem[]
  selectedItems: TSelecterWidgetListItem[]
  getAllItems: () => Promise<any[]>
  togglePopover: () => void
  onSelect: (value: string | string[] | any) => void
}

export const Widget = (props: Props) => {
  const { wrapperRef, staticList, selectedItems, getAllItems, togglePopover, onSelect } = props
  const [isFetching, setIsFetching] = React.useState(true)
  const [inputValue, setInputValue] = React.useState(null)
  const [searchedItems, setSearchedItems] = React.useState([])

  React.useEffect(() => {
    getAllItems().then(items => {
      setIsFetching(false)
      setSearchedItems(
        items.map(item => ({ label: item.name || item.firstName + ' ' + item.lastName, value: item.id }))
      )
    })
  }, [])

  const selectDynamicOption = (item: TSelecterWidgetListItem, needToAdd: boolean) => {
    if (needToAdd) {
      onSelect([...(selectedItems || []), item])
    } else {
      const filteredSelectedList = selectedItems.filter(selectedItem => selectedItem.value !== item.value)
      onSelect(filteredSelectedList.length ? filteredSelectedList : null)
    }
  }

  return (
    <WindowPopover bindToRef={wrapperRef} containerStyles={{ zIndex: 999 }}>
      <SWidget>
        <Options>
          {Boolean(staticList && staticList.length) &&
            staticList.map(item =>
              item.isSelected ? null : (
                <StaticOption
                  key={item.label}
                  onClick={() => {
                    togglePopover()
                    onSelect(item.selecterValue)
                  }}
                >
                  {item.label}
                </StaticOption>
              )
            )}
        </Options>
        <Search>
          <Container style={{ width: '100%' }} className={'input-container'}>
            <StyledInput
              type="text"
              value={inputValue || ''}
              placeholder={'Search'}
              onChange={event => setInputValue(event.target.value)}
            />
            {inputValue && <ClearButton className={'mdi mdi-close-circle'} onClick={() => setInputValue(null)} />}
          </Container>
        </Search>
        <Options>
          {Boolean(selectedItems && selectedItems.length) &&
            selectedItems.map(item => (
              <Option key={item.label + item.value} onClick={() => selectDynamicOption(item, false)}>
                <Checkbox style={{ marginRight: 8, fontSize: 22 }} active={true} />
                {item.label}
              </Option>
            ))}
        </Options>
        <DynamicOptions>
          {isFetching && <GridSpinner />}
          {Boolean(searchedItems.length) &&
            searchedItems.map((item: TSelecterWidgetListItem) => {
              if (inputValue && !item.label.toUpperCase().includes(inputValue.trim().toUpperCase())) {
                return null
              }

              if (
                selectedItems &&
                selectedItems.length &&
                selectedItems.find(selectedItem => selectedItem.value === item.value)
              ) {
                return null
              }
              return (
                <Option key={item.label + item.value} onClick={() => selectDynamicOption(item, true)}>
                  <Checkbox style={{ marginRight: 8, fontSize: 22 }} active={false} />
                  {item.label}
                </Option>
              )
            })}
        </DynamicOptions>
      </SWidget>
    </WindowPopover>
  )
}
