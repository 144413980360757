import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const vaAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('VA/ASYNC');
import { FetchAPI, FetchArgs, CreateDeductionDTO, CreateDeductionTransactionDTO, CreateVendorDeductionTransactionsDTO, DeductionDTO, DeductionTransactionDTO, DocumentFileDTO, DownloadDTO, FileDTO, PaymentCheckDTO, PaymentCheckReportDTO, PaymentCheckReportParametersDTO, UpdateDeductionDTO, UpdateDocumentFileDTO, UpdateVendorDTO, VendorDTO, DateISOString } from './api';
export interface IBankingDetailsBankingDetailsControllerGetRequest { vendorId: string, options?: any }
export type IBankingDetailsBankingDetailsControllerGetSuccess = VendorDTO;
export type IBankingDetailsBankingDetailsControllerGetError = any;
export const bankingDetailsBankingDetailsControllerGetAction = vaAsyncActionCreator.async<
  IBankingDetailsBankingDetailsControllerGetRequest, IBankingDetailsBankingDetailsControllerGetSuccess, IBankingDetailsBankingDetailsControllerGetError
>('BANKINGDETAILS_BANKINGDETAILSCONTROLLERGET');

export interface IBsqBsqControllerDownloadRequest { body: DownloadDTO, options?: any }
export type IBsqBsqControllerDownloadSuccess = string;
export type IBsqBsqControllerDownloadError = any;
export const bsqBsqControllerDownloadAction = vaAsyncActionCreator.async<
  IBsqBsqControllerDownloadRequest, IBsqBsqControllerDownloadSuccess, IBsqBsqControllerDownloadError
>('BSQ_BSQCONTROLLERDOWNLOAD');

export interface IDeductionDeductionControllerCloseRequest { id: string, options?: any }
export type IDeductionDeductionControllerCloseSuccess = DeductionDTO;
export type IDeductionDeductionControllerCloseError = any;
export const deductionDeductionControllerCloseAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerCloseRequest, IDeductionDeductionControllerCloseSuccess, IDeductionDeductionControllerCloseError
>('DEDUCTION_DEDUCTIONCONTROLLERCLOSE');

export interface IDeductionDeductionControllerCreateRequest { body: CreateDeductionDTO, options?: any }
export type IDeductionDeductionControllerCreateSuccess = DeductionDTO;
export type IDeductionDeductionControllerCreateError = any;
export const deductionDeductionControllerCreateAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerCreateRequest, IDeductionDeductionControllerCreateSuccess, IDeductionDeductionControllerCreateError
>('DEDUCTION_DEDUCTIONCONTROLLERCREATE');

export interface IDeductionDeductionControllerGetDeductionRequest { deductionId: string, options?: any }
export type IDeductionDeductionControllerGetDeductionSuccess = DeductionDTO;
export type IDeductionDeductionControllerGetDeductionError = any;
export const deductionDeductionControllerGetDeductionAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerGetDeductionRequest, IDeductionDeductionControllerGetDeductionSuccess, IDeductionDeductionControllerGetDeductionError
>('DEDUCTION_DEDUCTIONCONTROLLERGETDEDUCTION');

export interface IDeductionDeductionControllerGetDeductionsByVendorIdRequest { vendorId: string, options?: any }
export type IDeductionDeductionControllerGetDeductionsByVendorIdSuccess = DeductionDTO[];
export type IDeductionDeductionControllerGetDeductionsByVendorIdError = any;
export const deductionDeductionControllerGetDeductionsByVendorIdAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerGetDeductionsByVendorIdRequest, IDeductionDeductionControllerGetDeductionsByVendorIdSuccess, IDeductionDeductionControllerGetDeductionsByVendorIdError
>('DEDUCTION_DEDUCTIONCONTROLLERGETDEDUCTIONSBYVENDORID');

export interface IDeductionDeductionControllerRemoveRequest { id: string, options?: any }
export type IDeductionDeductionControllerRemoveSuccess = DeductionDTO;
export type IDeductionDeductionControllerRemoveError = any;
export const deductionDeductionControllerRemoveAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerRemoveRequest, IDeductionDeductionControllerRemoveSuccess, IDeductionDeductionControllerRemoveError
>('DEDUCTION_DEDUCTIONCONTROLLERREMOVE');

export interface IDeductionDeductionControllerUpdateRequest { id: string, body: UpdateDeductionDTO, options?: any }
export type IDeductionDeductionControllerUpdateSuccess = DeductionDTO;
export type IDeductionDeductionControllerUpdateError = any;
export const deductionDeductionControllerUpdateAction = vaAsyncActionCreator.async<
  IDeductionDeductionControllerUpdateRequest, IDeductionDeductionControllerUpdateSuccess, IDeductionDeductionControllerUpdateError
>('DEDUCTION_DEDUCTIONCONTROLLERUPDATE');

export interface IDeductionTransactionDeductionTransactionControllerCreateRequest { body: CreateDeductionTransactionDTO, options?: any }
export type IDeductionTransactionDeductionTransactionControllerCreateSuccess = DeductionTransactionDTO;
export type IDeductionTransactionDeductionTransactionControllerCreateError = any;
export const deductionTransactionDeductionTransactionControllerCreateAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerCreateRequest, IDeductionTransactionDeductionTransactionControllerCreateSuccess, IDeductionTransactionDeductionTransactionControllerCreateError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERCREATE');

export interface IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdRequest { deductionId: string, options?: any }
export type IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdSuccess = DeductionTransactionDTO[];
export type IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdError = any;
export const deductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdRequest, IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdSuccess, IDeductionTransactionDeductionTransactionControllerGetDeductionTransactionsByDeductionIdError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERGETDEDUCTIONTRANSACTIONSBYDEDUCTIONID');

export interface IDeductionTransactionDeductionTransactionControllerGetReportRequest { deductionId: string, options?: any }
export type IDeductionTransactionDeductionTransactionControllerGetReportSuccess = Response;
export type IDeductionTransactionDeductionTransactionControllerGetReportError = any;
export const deductionTransactionDeductionTransactionControllerGetReportAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerGetReportRequest, IDeductionTransactionDeductionTransactionControllerGetReportSuccess, IDeductionTransactionDeductionTransactionControllerGetReportError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERGETREPORT');

export interface IDeductionTransactionDeductionTransactionControllerPublishRequest { id: string, options?: any }
export type IDeductionTransactionDeductionTransactionControllerPublishSuccess = DeductionTransactionDTO;
export type IDeductionTransactionDeductionTransactionControllerPublishError = any;
export const deductionTransactionDeductionTransactionControllerPublishAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerPublishRequest, IDeductionTransactionDeductionTransactionControllerPublishSuccess, IDeductionTransactionDeductionTransactionControllerPublishError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERPUBLISH');

export interface IDeductionTransactionDeductionTransactionControllerRemoveRequest { id: string, options?: any }
export type IDeductionTransactionDeductionTransactionControllerRemoveSuccess = DeductionTransactionDTO;
export type IDeductionTransactionDeductionTransactionControllerRemoveError = any;
export const deductionTransactionDeductionTransactionControllerRemoveAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerRemoveRequest, IDeductionTransactionDeductionTransactionControllerRemoveSuccess, IDeductionTransactionDeductionTransactionControllerRemoveError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERREMOVE');

export interface IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdRequest { id: string, options?: any }
export type IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdSuccess = DeductionTransactionDTO;
export type IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdError = any;
export const deductionTransactionDeductionTransactionControllerRemoveByVendorIdAction = vaAsyncActionCreator.async<
  IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdRequest, IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdSuccess, IDeductionTransactionDeductionTransactionControllerRemoveByVendorIdError
>('DEDUCTIONTRANSACTION_DEDUCTIONTRANSACTIONCONTROLLERREMOVEBYVENDORID');

export interface IFuelTransactionFuelTransactionControllerDownloadRequest { body: DownloadDTO, options?: any }
export type IFuelTransactionFuelTransactionControllerDownloadSuccess = string;
export type IFuelTransactionFuelTransactionControllerDownloadError = any;
export const fuelTransactionFuelTransactionControllerDownloadAction = vaAsyncActionCreator.async<
  IFuelTransactionFuelTransactionControllerDownloadRequest, IFuelTransactionFuelTransactionControllerDownloadSuccess, IFuelTransactionFuelTransactionControllerDownloadError
>('FUELTRANSACTION_FUELTRANSACTIONCONTROLLERDOWNLOAD');

export interface IPaymentCheckPaymentCheckControllerCreateAccountingReportRequest { id: string, options?: any }
export type IPaymentCheckPaymentCheckControllerCreateAccountingReportSuccess = FileDTO;
export type IPaymentCheckPaymentCheckControllerCreateAccountingReportError = any;
export const paymentCheckPaymentCheckControllerCreateAccountingReportAction = vaAsyncActionCreator.async<
  IPaymentCheckPaymentCheckControllerCreateAccountingReportRequest, IPaymentCheckPaymentCheckControllerCreateAccountingReportSuccess, IPaymentCheckPaymentCheckControllerCreateAccountingReportError
>('PAYMENTCHECK_PAYMENTCHECKCONTROLLERCREATEACCOUNTINGREPORT');

export interface IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportRequest { body: PaymentCheckReportParametersDTO, options?: any }
export type IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportSuccess = PaymentCheckReportDTO;
export type IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportError = any;
export const paymentCheckPaymentCheckControllerCreatePaymentCheckReportAction = vaAsyncActionCreator.async<
  IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportRequest, IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportSuccess, IPaymentCheckPaymentCheckControllerCreatePaymentCheckReportError
>('PAYMENTCHECK_PAYMENTCHECKCONTROLLERCREATEPAYMENTCHECKREPORT');

export interface IPaymentCheckPaymentCheckControllerGetPaymentCheckReportRequest { id: string, options?: any }
export type IPaymentCheckPaymentCheckControllerGetPaymentCheckReportSuccess = PaymentCheckReportDTO;
export type IPaymentCheckPaymentCheckControllerGetPaymentCheckReportError = any;
export const paymentCheckPaymentCheckControllerGetPaymentCheckReportAction = vaAsyncActionCreator.async<
  IPaymentCheckPaymentCheckControllerGetPaymentCheckReportRequest, IPaymentCheckPaymentCheckControllerGetPaymentCheckReportSuccess, IPaymentCheckPaymentCheckControllerGetPaymentCheckReportError
>('PAYMENTCHECK_PAYMENTCHECKCONTROLLERGETPAYMENTCHECKREPORT');

export interface IPaymentCheckPaymentCheckControllerGetPaymentChecksRequest { pageNo?: number, pageSize?: number, options?: any }
export type IPaymentCheckPaymentCheckControllerGetPaymentChecksSuccess = PaymentCheckDTO[];
export type IPaymentCheckPaymentCheckControllerGetPaymentChecksError = any;
export const paymentCheckPaymentCheckControllerGetPaymentChecksAction = vaAsyncActionCreator.async<
  IPaymentCheckPaymentCheckControllerGetPaymentChecksRequest, IPaymentCheckPaymentCheckControllerGetPaymentChecksSuccess, IPaymentCheckPaymentCheckControllerGetPaymentChecksError
>('PAYMENTCHECK_PAYMENTCHECKCONTROLLERGETPAYMENTCHECKS');

export interface ITollTransactionTollTransactionControllerDownloadRequest { body: DownloadDTO, options?: any }
export type ITollTransactionTollTransactionControllerDownloadSuccess = string;
export type ITollTransactionTollTransactionControllerDownloadError = any;
export const tollTransactionTollTransactionControllerDownloadAction = vaAsyncActionCreator.async<
  ITollTransactionTollTransactionControllerDownloadRequest, ITollTransactionTollTransactionControllerDownloadSuccess, ITollTransactionTollTransactionControllerDownloadError
>('TOLLTRANSACTION_TOLLTRANSACTIONCONTROLLERDOWNLOAD');

export interface IVendorVendorControllerCreateVendorDeductionTransactionsRequest { id: string, body: CreateVendorDeductionTransactionsDTO, options?: any }
export type IVendorVendorControllerCreateVendorDeductionTransactionsSuccess = string;
export type IVendorVendorControllerCreateVendorDeductionTransactionsError = any;
export const vendorVendorControllerCreateVendorDeductionTransactionsAction = vaAsyncActionCreator.async<
  IVendorVendorControllerCreateVendorDeductionTransactionsRequest, IVendorVendorControllerCreateVendorDeductionTransactionsSuccess, IVendorVendorControllerCreateVendorDeductionTransactionsError
>('VENDOR_VENDORCONTROLLERCREATEVENDORDEDUCTIONTRANSACTIONS');

export interface IVendorVendorControllerGetRequest { vendorId: string, options?: any }
export type IVendorVendorControllerGetSuccess = VendorDTO;
export type IVendorVendorControllerGetError = any;
export const vendorVendorControllerGetAction = vaAsyncActionCreator.async<
  IVendorVendorControllerGetRequest, IVendorVendorControllerGetSuccess, IVendorVendorControllerGetError
>('VENDOR_VENDORCONTROLLERGET');

export interface IVendorVendorControllerUpdateRequest { id: string, body: UpdateVendorDTO, options?: any }
export type IVendorVendorControllerUpdateSuccess = VendorDTO;
export type IVendorVendorControllerUpdateError = any;
export const vendorVendorControllerUpdateAction = vaAsyncActionCreator.async<
  IVendorVendorControllerUpdateRequest, IVendorVendorControllerUpdateSuccess, IVendorVendorControllerUpdateError
>('VENDOR_VENDORCONTROLLERUPDATE');


