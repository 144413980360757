import * as React from 'react'
import { StyledRadioButton } from './styles'
import cn from 'classnames'

const icons = {
  radio: {
    active: 'mdi mdi-radiobox-marked',
    deactivated: 'mdi mdi-radiobox-blank'
  },
  checkbox: {
    active: 'mdi mdi-checkbox-marked',
    deactivated: 'mdi mdi-checkbox-blank-outline'
  }
}

type Props = {
  icon?: {
    active: string
    deactivated: string
  }
  active: boolean
  onChange?: (state: boolean) => void
  onClick?: (event: any) => void
  style?: object
  disabled?: boolean
}

export const IconButton = (props: Props) => {
  const { icon = icons.radio, active, onClick, onChange, style, disabled } = props

  let onClickAction = undefined
  if (!disabled) {
    if (onClick) {
      onClickAction = onClick
    }
    if (onChange) {
      onClickAction = () => onChange(!active)
    }
  }

  return (
    <StyledRadioButton
      className={cn({
        disabled,
        active,
        [icon.active]: active,
        [icon.deactivated]: !active
      })}
      style={style}
      onClick={onClickAction}
    />
  )
}

export const Checkbox = (props: Props) => <IconButton icon={icons.checkbox} {...props} />
export const Radio = (props: Props) => <IconButton icon={icons.radio} {...props} />

export namespace Selecter {
  export const checkbox = Checkbox
  export const radio = Radio
}
