import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { IMove, MoveStatus } from '../../../store/reducers/communicationHub/interfaces'
import { dispatchDeliveryOrderStatusIndex } from '../../DTO/dispatchDeliveryOrder/functions'
import { isDDOStatusCancelled } from './isDDOStatusCancelled'

export const isMoveStatusChangeable = (move: IMove) => {
  const { items, dispatchDeliveryOrder } = move
  const checkMoves = items.filter(
    ({ gotoActivity }) => oc(gotoActivity).status() !== TransportationActivityViewDTO.StatusEnum.NEW
  )
  const isDDOStatusAvailable =
    dispatchDeliveryOrderStatusIndex[dispatchDeliveryOrder.status] >=
      dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH] &&
    !isDDOStatusCancelled(dispatchDeliveryOrder.status)

  return {
    get [MoveStatus.PLANNED]() {
      return (
        isDDOStatusAvailable &&
        checkMoves.some(
          ({ gotoActivity }) => oc(gotoActivity).status() === TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
        ) &&
        !checkMoves.some(
          ({ gotoActivity }) => oc(gotoActivity).status() === TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
        )
      )
    },
    get [MoveStatus.ASSIGNED]() {
      return (
        isDDOStatusAvailable &&
        checkMoves.some(
          ({ gotoActivity }) => oc(gotoActivity).status() === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
        )
      )
    }
  }
}
