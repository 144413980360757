import * as React from 'react'
import cn from 'classnames'
import { NotificationsContainer, NotificationList, NotificationsButton } from './styles'
import { Notification } from './Notification'
import { useApplicationSize } from '../../../../hooks/useApplicationSize'
import { getAlerts } from '../../../CommunicationHub/epics'
import { TroubleTicketDTO } from '../../../../api/api'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectAppNotifications } from '../../../../store/select/appNotificationSelect'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { appNotificationsActions } from '../../../../store/reducers/appNotifications'
import { AppNotificationType } from '../../../../store/reducers/appNotifications/interfaces'

export const Notifications = React.memo(() => {
  const dispatch = useAppDispatch()
  const appNotifications = useAppSelector(selectAppNotifications)
  const { communicationHubWidth } = useApplicationSize()
  const [opened, setOpened] = React.useState(true)

  React.useEffect(() => {
    setOpened(true)
  }, [appNotifications])

  React.useEffect(() => {
    getAlerts({ status: TroubleTicketDTO.StatusEnum.NEW })
      .then(alertMessages => {
        if (alertMessages && alertMessages.length) {
          dispatch(
            appNotificationsActions.set(
              alertMessages.map(alertMessage => ({
                id: alertMessage.id,
                type: AppNotificationType.alert,
                data: alertMessage
              }))
            )
          )
        }
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.error(error)
      })
  }, [])

  const toggle = React.useCallback(() => setOpened(_ => !_), [])

  const RenderContent = React.useMemo(
    () => (
      <>
        <NotificationsButton
          data-id={'alert-notifications-open-button'}
          className={cn('mdi', 'mdi-alert-decagram', { opened })}
          onClick={toggle}
        />
        {opened && (
          <NotificationList data-id={'alert-notifications-list'}>
            {appNotifications.map(notification => (
              <Notification
                key={notification.id}
                notification={notification}
                removeNotification={() => dispatch(appNotificationsActions.delete({ id: notification.id }))}
              />
            ))}
          </NotificationList>
        )}
      </>
    ),
    [opened, appNotifications]
  )

  if (!appNotifications || appNotifications.length === 0) {
    return null
  }

  return (
    <NotificationsContainer data-id={'alert-notifications'} style={{ right: communicationHubWidth }}>
      {RenderContent}
    </NotificationsContainer>
  )
})
