import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { DispatchDeliveryOrderStreetTurnDTO } from '../api/api'
import { useListItem } from './useListItem'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useStreetTurn = (props: Props): DispatchDeliveryOrderStreetTurnDTO => {
  const { id, modifiedLists } = props
  const streetTurn = useListItem({ id, type: EntityType.streetTurn, modifiedLists })

  return streetTurn
}
