import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectCommunictaionHubChannelsMeta,
  selectCommunictaionHubHasUnreadMentionedMessages,
  selectCommunictaionHubTab
} from '../../../../store/select/communicationHubSelect'
import { CommunicationHubTab } from '../../../../store/reducers/communicationHub/interfaces'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import theme from '../../../../styles/theme'

const tabIconMapping = {
  [CommunicationHubTab.messenger]: 'mdi mdi-forum',
  [CommunicationHubTab.routeBuilder]: 'mdi mdi-transit-connection-variant'
}

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NavigationHeader = (props: Props) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectCommunictaionHubTab)
  const channelsMeta = useAppSelector(selectCommunictaionHubChannelsMeta)
  const hasNewMentionedMessages = useAppSelector(selectCommunictaionHubHasUnreadMentionedMessages)
  const [hasNewMessages, setHasNewMessages] = React.useState(false)
  const tabs = React.useMemo(() => [CommunicationHubTab.messenger, CommunicationHubTab.routeBuilder], [])

  React.useEffect(() => {
    if (activeTab !== CommunicationHubTab.messenger) {
      setHasNewMessages(true)
    }
  }, [channelsMeta])

  React.useEffect(() => {
    setHasNewMessages(false)
  }, [activeTab])

  return (
    <Container>
      {tabs.map(tab => {
        const isActiveTab = activeTab === tab
        return (
          <Tab
            key={tab}
            className={cn('c-hub-tab', tabIconMapping[tab], {
              active: isActiveTab,
              ...(tab === CommunicationHubTab.messenger
                ? {
                    hasNewMessages,
                    hasNewMentionedMessages
                  }
                : {})
            })}
            children={tab}
            onClick={
              isActiveTab
                ? undefined
                : () => {
                    leaveChatChannelIfNoEditing(() =>
                      dispatch(communicationHubActions.setActiveTab({ activeTab: tab }))
                    )
                  }
            }
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  flex-shrink: 0;
  height: 48px;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
const Tab = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  color: #445366;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    justify-content: flex-end;
    padding-right: 20px;
  }

  &:before {
    font-size: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  &.active {
    color: white;
    cursor: default;
  }

  &.hasNewMessages:not(.hasNewMentionedMessages):after,
  &.hasNewMentionedMessages:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    order: -1;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    transform: translate(15px, -8px);
  }

  &.hasNewMentionedMessages:after {
    background-color: ${theme.colors.defaultRed};
  }
`
