import * as React from 'react'
import { DriverViewDTO } from '../api/api'

export interface IGridItemDriverContext {
  driver: DriverViewDTO
  isExpanded: boolean
}

export const GridItemDriverContext = React.createContext<IGridItemDriverContext>({
  driver: null,
  isExpanded: null
})
