import { oc } from 'ts-optchain'
import { ActivitiesViewDTO, DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { ActivityGroup, DocumentationActivityGroup, TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { isGotoActivity } from '../test/isGotoActivity'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'

export const groupActivities = (activities: ActivitiesViewDTO): ActivityGroup[] => {
  let error = ''
  const throwError = () => {
    return [] as ActivityGroup[]
  }

  if (activities.transportationActivities.length % 2 !== 0) {
    error = 'Incorrect Transportation Activity count'
    return throwError()
  }

  try {
    const activityByGroups: {
      [groupId: string]: TransportationActivityViewDTO[]
    } = oc(activities)
      .transportationActivities([])
      .reduce((acc, activity) => {
        if (acc[activity.groupId]) {
          if (isGotoActivity(activity)) {
            acc[activity.groupId].unshift(activity)
          } else {
            acc[activity.groupId].push(activity)
          }
        } else {
          acc[activity.groupId] = [activity]
        }

        if (!activity.groupId) {
          error = `Activity must have a groupId: DDO ID: ${activity.dispatchDeliveryOrderId}`
        }

        return acc
      }, {})

    if (error) {
      return throwError()
    }

    const activityGroupArray = Object.values(activityByGroups)
    const allActivityGroupArray: ActivityViewDTO[][] = activityGroupArray.concat(
      oc(activities)
        .documentationActivities([])
        .map(documentationActivity => [documentationActivity as any])
    )
    const sortedAllActivityGroupArray: ActivityViewDTO[][] = allActivityGroupArray.sort(
      (a, b) => a[0].number - b[0].number
    )

    let activityNumber = 0
    const transportationActivities: TransportationActivityViewDTO[] = []
    const documentationActivities: DocumentationActivityDTO[] = []
    const updatedActivityGroups: ActivityGroup[] = []

    const sortedActivityGroupsByStage: Record<TransportationActivityViewDTO.StageEnum, ActivityViewDTO[][]> = {
      [TransportationActivityViewDTO.StageEnum.PICKUP]: sortedAllActivityGroupArray.filter(
        group => group[0].stage === TransportationActivityViewDTO.StageEnum.PICKUP
      ),
      [TransportationActivityViewDTO.StageEnum.DELIVERY]: sortedAllActivityGroupArray.filter(
        group => group[0].stage === TransportationActivityViewDTO.StageEnum.DELIVERY
      ),
      [TransportationActivityViewDTO.StageEnum.RETURN]: sortedAllActivityGroupArray.filter(
        group => group[0].stage === TransportationActivityViewDTO.StageEnum.RETURN
      )
    }

    sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.PICKUP]
      .concat(
        sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.DELIVERY],
        sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.RETURN]
      )
      .forEach(group => {
        switch (group.length) {
          case 1: {
            // Documentation Activity
            const documentationActivity = { ...group[0], number: activityNumber }
            activityNumber++

            documentationActivities.push(documentationActivity as DocumentationActivityDTO)
            updatedActivityGroups.push({ documentationActivity } as DocumentationActivityGroup)
            break
          }
          case 2: {
            // GOTO + Business Activity
            const gotoActivity = { ...group[0], number: activityNumber }
            activityNumber++
            const businessActivity = { ...group[1], number: activityNumber }
            activityNumber++

            transportationActivities.push(
              gotoActivity as TransportationActivityViewDTO,
              businessActivity as TransportationActivityViewDTO
            )
            updatedActivityGroups.push({ gotoActivity, businessActivity } as TransportationActivityGroup)
            break
          }
          default: {
            return
          }
        }
      })

    return updatedActivityGroups
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)

    return throwError()
  }
}
