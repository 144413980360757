import { ICommunicationHub } from '../interfaces'
import { generateInitialCommunicationHubState } from './generateInitialCommunicationHubState'

export const communicationHubLocalStorageService = {
  get initialState(): ICommunicationHub {
    const state = sessionStorage.getItem('communicationHub')
    return state ? (JSON.parse(state) as ICommunicationHub) : generateInitialCommunicationHubState()
  },
  set initialState(state: ICommunicationHub) {
    sessionStorage.setItem('communicationHub', JSON.stringify(state))
  }
}
