import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

type OwnProps = {
  title: string
  previewData: {
    label: string
    value: string | number
  }[]
  popoverData?: {
    tabTitle: string
    tabList: {
      label: string
      value?: string | number
    }[]
    headers?: {
      label: string
      value?: string
    }
  }[]
  style?: any
}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Widget = (props: Props) => {
  const { title, previewData = [], popoverData, style } = props
  const [showPopover, setShowPopover] = React.useState(false)
  const [activePopoverTabIndex, setActivePopoverTabIndex] = React.useState(0)
  const hasPopover = Boolean(popoverData)

  return (
    <StyledWidget
      style={style}
      onMouseEnter={hasPopover ? () => setShowPopover(true) : undefined}
      onMouseLeave={hasPopover ? () => setShowPopover(false) : undefined}
    >
      <Preview>
        <PreviewTitle>
          {title}
          {hasPopover && <InformationIcon className={cn('mdi mdi-information', { active: showPopover })} />}
        </PreviewTitle>
        <PreviewData>
          {previewData.map(({ label, value }) => (
            <PreviewItem key={label}>
              {label}
              <PreviewItemNumber>{value}</PreviewItemNumber>
            </PreviewItem>
          ))}
        </PreviewData>
      </Preview>

      {showPopover && (
        <Popover>
          <PopoverTitles>
            {popoverData.map(({ tabTitle }, index) => (
              <PopoverTitle
                key={tabTitle}
                className={cn({ active: activePopoverTabIndex === index })}
                onClick={() => setActivePopoverTabIndex(index)}
              >
                {tabTitle}
              </PopoverTitle>
            ))}
          </PopoverTitles>

          {Boolean(popoverData[activePopoverTabIndex].headers) && (
            <PopoverListHeader>
              <div>{popoverData[activePopoverTabIndex].headers.label}</div>
              <div>{popoverData[activePopoverTabIndex].headers.value}</div>
            </PopoverListHeader>
          )}
          <PopoverList key={activePopoverTabIndex}>
            {popoverData[activePopoverTabIndex].tabList.map(item => (
              <PopoverListItem key={item.label}>
                <div>{item.label}</div>
                <div>{item.value}</div>
              </PopoverListItem>
            ))}
          </PopoverList>
        </Popover>
      )}
    </StyledWidget>
  )
}

const StyledWidget = styled.div`
  position: relative;
`
const Preview = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 8px 8px;
  cursor: default;
`
const PreviewTitle = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #697585;
  margin-bottom: 6px;
`
const InformationIcon = styled.span`
  font-size: 18px;
  color: #bdbdbd;
  position: absolute;
  top: 5px;
  right: 8px;

  &.active {
    color: #4555d0;
  }
`
const PreviewData = styled.ul`
  display: flex;
`
const PreviewItem = styled.li`
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  color: #4f4f4f;

  &:not(:first-child) {
    margin-left: auto;
  }
`
const PreviewItemNumber = styled.span`
  display: inline-block;
  min-width: 20px;
  font-weight: 700;
  margin-left: 4px;
`

const Popover = styled.div`
  z-index: 1;
  width: 360px;
  position: absolute;
  top: 100%;
  right: 0;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`
const PopoverTitles = styled.ul`
  display: flex;
  padding: 16px;
`
const PopoverTitle = styled.li`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.3px;
  flex-basis: 100%;
  text-transform: uppercase;
  color: #333333;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 11px;

  &.active {
    color: #4555d0;
    border-color: #4555d0;
  }
`
const PopoverList = styled.ul`
  max-height: 250px;
  overflow: auto;
`
const PopoverListItem = styled.li`
  display: flex;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 17px 16px;

  > *:first-child {
    color: rgba(79, 79, 79, 0.8);
  }

  > *:nth-child(2) {
    min-width: 50px;
    margin-left: auto;
  }
`
const PopoverListHeader = styled(PopoverListItem)`
  background-color: #eef0f6;
  padding: 22px 16px;

  > * {
    color: #4f4f4f !important;
  }
`
