import * as CryptoJS from 'crypto-js'
import { SecretKey } from './interfaces'
import { requestSecretKey } from './requestSecretKey'

export const encrypt = async (text: string): Promise<string> => {
  if (!text) {
    return
  }

  const secretKey = await requestSecretKey(SecretKey.PUBLIC_ENCRYPTION_KEY)

  if (!secretKey) {
    return
  }

  const iv = CryptoJS.lib.WordArray.random(16)
  const encryptValue = CryptoJS.enc.Utf8.parse(text)
  const base64Key = CryptoJS.enc.Base64.parse(secretKey)
  const encrypted = CryptoJS.AES.encrypt(encryptValue, base64Key, {
    iv
  })
  const cipherText = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64)

  return cipherText
}
