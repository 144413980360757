import * as React from 'react'
import styled from 'styled-components'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { Column, GridColumn, ColumnRow, Container } from '../../../UI/GridSystem'
import { DeliveryOrderViewDTO } from '../../../../api/api'
import { Select } from '../../../UI/DataFields/Select'
import { LocationWidget } from '../../../UI/Widget/widgets/location/simple'
import { FieldContainer } from '../../../UI/DataFields/FieldContainer'
import { dispatchDeliveryOrderLists } from '../../../../services/select/dispatchDeliveryOrderLists'
import { DateOnFocus, DateTimePicker } from '../../../UI/DataFields/DateTimePicker/views'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { assembleEntity } from '../../../../services/functions/assembleListEntity/assembleEntity'
import { useDeliveryOrderTab } from '../../../../hooks/useDeliveryOrderTab'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'

type Props = {}

export const RightSide = (props: Props) => {
  const { modifyListItems, modifyParentObject, modifyParentObjectField } = useExpandedItem()
  const { deliveryOrder } = useDeliveryOrderTab()
  const isImport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION

  const changeLocation = (propName: string) => (locationId: string) => {
    modifyParentObjectField(propName)(locationId)
  }

  return (
    <DORightSide style={{ width: '100%' }} isGrid={true} flexClear={true} margin={{ left: 70 }}>
      <ColumnRow margin={{ bottom: 15 }}>
        <Column id={'do-pickupLocation'}>
          <FieldContainer title={'Pickup'}>
            <LocationWidget
              isExternal={true}
              isShortName={false}
              id={deliveryOrder.pickupLocationId}
              changeLocationId={changeLocation('pickupLocationId')}
            />
          </FieldContainer>
        </Column>
      </ColumnRow>
      <ColumnRow margin={{ bottom: 35 }}>
        <Column margin={{ right: 15 }} id={'do-equipmentFirstPickupDate'}>
          <DateTimePicker
            title={'Availability'}
            date={deliveryOrder.equipmentFirstPickupDate}
            onChange={modifyParentObjectField('equipmentFirstPickupDate')}
          />
        </Column>

        <Column margin={{ right: 15 }} id={'do-vesselArrivalDate'}>
          {isImport && (
            <DateTimePicker
              title={'Vessel Arrival'}
              date={deliveryOrder.vesselArrivalDate}
              onChange={modifyParentObjectField('vesselArrivalDate')}
            />
          )}
        </Column>

        <Column id={'do-lastFreeDateDemurrage'}>
          {isImport && (
            <DateTimePicker
              title={'Last Free'}
              date={deliveryOrder.lastFreeDateDemurrage}
              onChange={modifyParentObjectField('lastFreeDateDemurrage')}
            />
          )}
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 25 }}>
        <Column id={'do-deliveryLocation'}>
          <FieldContainer title={'Delivery'} disabled={isRepo}>
            <LocationWidget
              disableWidget={isRepo}
              isExternal={true}
              isShortName={false}
              id={deliveryOrder.deliveryLocationId}
              changeLocationId={changeLocation('deliveryLocationId')}
            />
          </FieldContainer>
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 45 }}>
        <Column margin={{ right: 15 }} id={'do-appointmentDateTimeRange'}>
          <DateTimePicker
            disabled={isRepo}
            title={'Appointment Date'}
            dateOnFocus={DateOnFocus.startWorkDay}
            date={deliveryOrder.appointmentDateTimeRange}
            isRange={true}
            onChange={value => {
              const isHaveDate = (date: any) => Boolean(date) || !R.equals(date, { to: null, from: null })

              modifyParentObject({ ...deliveryOrder, appointmentDateTimeRange: value })
              modifyListItems({
                [EntityType.dispatchDeliveryOrder]: oc(deliveryOrder)
                  .dispatchDeliveryOrderIds([])
                  .map(id => {
                    const ddo = assembleEntity({ entityType: EntityType.dispatchDeliveryOrder, entityId: id })

                    if (
                      !(oc(ddo).deliveryStage() || isHaveDate(oc(ddo).deliveryStage.plannedAppointmentDateTimeRange()))
                    ) {
                      return
                    }

                    return {
                      ...ddo,
                      deliveryStage: {
                        ...oc(ddo).deliveryStage({}),
                        plannedAppointmentDateTimeRange: value
                      }
                    }
                  })
                  .filter(Boolean)
              })
            }}
          />
        </Column>
        <Column margin={{ right: 15 }} id={'do-loadType'}>
          <Container columns={4} isFixed={true}>
            <Select
              disabled={isRepo}
              title={'Load Type'}
              required={!isRepo}
              placeholder={''}
              selectedValue={deliveryOrder.loadType}
              list={dispatchDeliveryOrderLists.loadType}
              onSelect={modifyParentObjectField('loadType')}
            />
          </Container>
        </Column>
        <Column />
      </ColumnRow>

      <ColumnRow margin={{ bottom: 15 }} id={'do-returnLocation'}>
        <FieldContainer title={'Return'}>
          <LocationWidget
            isExternal={true}
            isShortName={false}
            id={deliveryOrder.returnLocationId}
            changeLocationId={changeLocation('returnLocationId')}
          />
        </FieldContainer>
      </ColumnRow>

      <ColumnRow>
        {!isRepo && (
          <Column margin={{ right: 15 }} id={'do-lastFreeDatePerDiem'}>
            <DateTimePicker
              title={'Per Diem Free by'}
              date={deliveryOrder.lastFreeDatePerDiem}
              onChange={modifyParentObjectField('lastFreeDatePerDiem')}
            />
          </Column>
        )}
        <Column margin={{ right: 15 }} id={'do-firstReceivingDate'}>
          {(isExport || isRepo) && (
            <DateTimePicker
              title={'First Receiving'}
              date={deliveryOrder.firstReceivingDate}
              onChange={modifyParentObjectField('firstReceivingDate')}
            />
          )}
        </Column>
        <Column margin={isRepo ? { right: 15 } : undefined} id={'do-generalCutoffDate'}>
          {isExport && (
            <DateTimePicker
              title={'Cutoff'}
              date={deliveryOrder.generalCutoffDate}
              onChange={modifyParentObjectField('generalCutoffDate')}
            />
          )}
        </Column>
        {isRepo && <Column />}
      </ColumnRow>
    </DORightSide>
  )
}

const DORightSide = styled(GridColumn)`
  .search-location {
    input,
    .mdi > div,
    .mdi > span {
      font-size: 18px;
    }
  }
`
