import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { saveSubClient as saveSubClientPromise } from '../../saveEntity/saveSubClient'
import { SubClientViewDTO } from '../../../api/api'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { getListsState } from '../../../store'
import { isNewItem } from '../../utils'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { filterModifiedContacts } from '../../functions/filter/filterModifiedContacts'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'

export const saveSubClient = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const store = getListsState()
  const subClient: SubClientViewDTO = parentItem
  const modifiedSubClient = oc(modifiedLists)[EntityType.subClient][parentItemId]()
  const storeBusinessPartner = store[EntityType.businessPartner][subClient.businessPartnerId]
  const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][subClient.businessPartnerId]()
  const contactIds = (modifiedBusinessPartner || storeBusinessPartner).contactIds
  const updatedContacts = filterModifiedContacts({ contactIds, modifiedLists })

  const condition =
    testEntityVersion(store, EntityType.subClient)(modifiedSubClient) &&
    testEntityVersion(store, EntityType.businessPartner)(modifiedBusinessPartner) &&
    updatedContacts.every(testEntityVersion(store, EntityType.contact))

  const save = () => {
    return saveSubClientPromise({
      subClient: modifiedSubClient,
      businessPartner: modifiedBusinessPartner,
      contacts: updatedContacts
    }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(subClient) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
