import { oc } from 'ts-optchain'
import { OngoingActivityDTO, TransportationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'

const dropTypes = [
  TransportationActivityViewDTO.TypeEnum.DROPCHASSIS,
  TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
  TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
  TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
  TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.DROPCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.DROPEMPTYWITHCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.DROPEMPTYWITHOUTCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.DROPFULLWITHCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.DROPFULLWITHOUTCHASSIS
]

export const isDropActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  // @ts-ignore
  return isDropActivityType(oc(activity).comboType())
}

export const isDropActivityType = (
  activityType: TransportationActivityViewDTO.TypeEnum | TransportationActivityViewDTO.ComboTypeEnum
): boolean => {
  return dropTypes.includes(activityType)
}
