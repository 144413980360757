import { NotificationDTO } from '../../../api/origin/communication-hub-service'

const type = {
  [NotificationDTO.TypeEnum.POLL]: 'Poll',
  [NotificationDTO.TypeEnum.ACKNOWLEDGMENT]: 'Notification with Acknowledgement',
  [NotificationDTO.TypeEnum.RESIGNDOCUMENT]: 'Resign Document',
  [NotificationDTO.TypeEnum.SIMPLE]: 'Simple Notification'
}

const status = {
  [NotificationDTO.StatusEnum.INPROGRESS]: 'In-progress',
  [NotificationDTO.StatusEnum.COMPLETED]: 'Completed',
  [NotificationDTO.StatusEnum.DELETED]: 'Deleted'
}

const statusColor = {
  [NotificationDTO.StatusEnum.INPROGRESS]: '#f2f3f5',
  [NotificationDTO.StatusEnum.COMPLETED]: '#edf9f0',
  [NotificationDTO.StatusEnum.DELETED]: '#fcf5f7'
}

export const notificationDirectory = { type, status, statusColor }
