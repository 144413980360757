import { schemaContainerView } from '../../yupScheme'
import { debuggingMode } from '../../debug'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { ContainerViewDTO } from '../../../api/api'

export const checkContainerValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { parentItem } = expandedItemContext
  const container = parentItem as ContainerViewDTO

  if (debuggingMode.common) {
    schemaContainerView.validate(container).catch(e =>
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkContainerValidation', e)
    )
  }
  return schemaContainerView.isValidSync(container)
}
