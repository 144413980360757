import { oc } from 'ts-optchain'
import { OngoingActivityDTO, TransportationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'

const pickTypes = [
  TransportationActivityViewDTO.TypeEnum.PICKCHASSIS,
  TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY,
  TransportationActivityViewDTO.TypeEnum.PICKUPFULL,
  TransportationActivityViewDTO.ComboTypeEnum.PICKCHASSIS,
  TransportationActivityViewDTO.ComboTypeEnum.PICKUPEMPTY,
  TransportationActivityViewDTO.ComboTypeEnum.PICKUPFULL
]

export const isPickActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  // @ts-ignore
  return isPickActivityType(oc(activity).comboType())
}

export const isPickActivityType = (
  activityType: TransportationActivityViewDTO.TypeEnum | TransportationActivityViewDTO.ComboTypeEnum
): boolean => {
  return pickTypes.includes(activityType)
}
