import * as React from 'react'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { Icon, IconMode, IconName } from '../Icon'
import styled from 'styled-components'
import { Portal } from 'react-portal'

type Props = {
  pinned: boolean
  actionPin: () => void
  actionDelete: () => void
  actionEdit: () => void
}

export const MessageActions = React.memo((props: Props) => {
  const { pinned, actionPin, actionEdit, actionDelete } = props
  const popoverRef = React.useRef(null)
  const { statePopover, wrapperRef, togglePopover, setPopoverState } = useOutsideClick({
    capture: false,
    hideOnScroll: true
  })

  React.useEffect(() => {
    if (statePopover && popoverRef.current) {
      const buttonProps = wrapperRef.current.getBoundingClientRect()

      popoverRef.current.style.top = buttonProps.top + 'px'
    }
  }, [statePopover])

  const doAction = (action: () => void) => () => {
    action()
    setPopoverState(false)
  }

  return (
    <StyledActions ref={wrapperRef} onClick={togglePopover}>
      <Icon active={statePopover} icon={IconName.DOTS} mode={IconMode.DARK} onClick={() => {}} />

      {statePopover && (
        <Portal>
          <Popover ref={popoverRef}>
            <Title>Actions with message</Title>
            {Boolean(actionEdit) && <Action onClick={doAction(actionEdit)}>Edit</Action>}
            <Action onClick={doAction(actionPin)}>{pinned ? 'Unpin from Notes' : 'Pin to Notes'}</Action>
            {Boolean(actionDelete) && <Action onClick={doAction(actionDelete)}>Delete</Action>}
          </Popover>
        </Portal>
      )}
    </StyledActions>
  )
})

const StyledActions = styled.div`
  z-index: 11;
  position: absolute;
  top: 2px;
  right: 3px;
`
const Popover = styled.div`
  z-index: 1;
  width: 130px;
  position: fixed;
  top: 0;
  right: 30px;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 0;
  user-select: none;
`
const Title = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #bdbdbd;
  padding: 5px 10px 4px;
`
const Action = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #323e4e;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(229, 231, 239, 0.22);
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`
