import { schemaEquipment } from '../../yupScheme'
import { debuggingMode } from '../../debug'
import { EquipmentDTO } from '../../../api/api'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'

export const equipmentNumberRegex = /^[a-zA-Z]{4}\d{6,7}$/

export const checkEquipmentNumberValidation = (equipmentNumber: string) => {
  return equipmentNumberRegex.test(equipmentNumber)
}

export const checkEquipmentValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { parentItem } = expandedItemContext
  const equipment = parentItem as EquipmentDTO

  if (debuggingMode.common) {
    schemaEquipment.validate(equipment).catch(e =>
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkEquipmentValidation', e)
    )
  }
  return schemaEquipment.isValidSync(equipment)
}
