import styled from 'styled-components'

export const SWidget = styled.div`
  min-width: 200px;
  background-color: white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px 0;
`
export const Search = styled.div`
  padding: 10px;
`
export const Options = styled.div`
  max-height: 160px;
  overflow: auto;
`
export const DynamicOptions = styled(Options)`
  position: relative;
  min-height: 160px;
  border-top: 1px solid #e5e5e5;
`
export const Option = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #323e4e;
  padding: 5px 8px;
  cursor: pointer;

  &:hover {
    background-color: #f5f6fa;
  }
`
export const StaticOption = styled(Option)`
  font-size: 14px;
  padding: 5px 10px;
`
