import * as React from 'react'
import styled from 'styled-components'
import { Title } from '../styles'

type Props = {
  label: string
  placeholder: string
  textareaHeight: number
  offset?: {
    top?: number
    bottom?: number
  }
  text: string
  onTextUpdate: (text: string) => void
}

export const ActivityDocumentTextArea = (props: Props) => {
  const { label, placeholder, textareaHeight, text, offset, onTextUpdate } = props

  return (
    <>
      <Title style={offset ? { marginTop: offset.top, marginBottom: offset.bottom } : undefined}>{label}</Title>
      <TextArea
        placeholder={placeholder}
        style={{ height: textareaHeight }}
        onBlur={event => onTextUpdate(event.target.value)}
      >
        {text}
      </TextArea>
    </>
  )
}

const TextArea = styled.textarea`
  height: 100px;
  background: #fafbfd;
  border: 1px solid #e3e8ea;
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.2;
  padding: 15px;

  &::placeholder {
    color: #b2b2b2;
  }
`
