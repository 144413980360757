import { DeliveryOrderViewDTO, DispatchDeliveryOrderDTO, DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateDispatchDeliveryOrderByDeliveryOrder = (
  deliveryOrder: DeliveryOrderViewDTO,
  props?: Partial<DispatchDeliveryOrderViewDTO>
): DispatchDeliveryOrderViewDTO => {
  const {
    type,
    pickupLocationId,
    deliveryLocationId,
    returnLocationId,
    loadType,
    appointmentDateTimeRange
  } = deliveryOrder

  return {
    id: createId(),
    completeness: maxCompleteness,
    activityIds: [],
    buySideQuoteIds: [],
    containerId: undefined,
    weight: undefined,
    weightUnit: DispatchDeliveryOrderDTO.WeightUnitEnum.LBS,
    status: DispatchDeliveryOrderDTO.StatusEnum.NEW,
    overweightIndicator: false,
    deliveryOrderId: deliveryOrder.id,
    containerTypeId: undefined,
    loadType: type === DeliveryOrderViewDTO.TypeEnum.REPOSITION ? undefined : loadType,
    pickupStage: {
      locationId: pickupLocationId
    },
    deliveryStage:
      type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? {}
        : {
            locationId: deliveryLocationId,
            plannedAppointmentDateTimeRange:
              appointmentDateTimeRange && appointmentDateTimeRange.from
                ? { ...appointmentDateTimeRange, confirmed: true }
                : appointmentDateTimeRange
          },
    returnStage: {
      locationId: returnLocationId
    },
    ...(props || {})
  }
}
