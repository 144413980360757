import * as React from 'react'
import { oc } from 'ts-optchain'
import { SsqBsqWidgetContext } from '../contexts/SsqBsqWidgetContext'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { requestSellSideQuoteById } from '../services/DTO/sellSideQuote/epics'
import { requestBuySideQuoteById, requestBuySideQuotesByDdoId } from '../services/DTO/buySideQuote/epics'
import { useGridItemDDO } from '../hooks/useGridItemDDO'
import { useAppSelector } from '../hooks/useAppSelector'
import { selectIsRulesLoaded } from '../store/select/ruleSelect'
import { requestRules } from '../components/common/QMP/epics/rules'
import { BuySideQuoteRateDTO, CustomerQuoteDTO, SellSideQuoteRateDTO } from '../api/origin/qmp-service'
import { useLocation } from '../hooks/useLocation'
import { BuySideQuoteDTO, DateISOString, SellSideQuoteDTO, TransportationActivityViewDTO } from '../api/api'
import { buySideQuoteTypeGroups } from '../constants/buySideQuoteTypeGroups'
import { isBusinessActivity } from '../services/functions/test/isBusinessActivity'
import { isBobtailGotoActivity } from '../services/functions/test/isBobtailGotoActivity'
import { MiscSurchargeDTO, SurchargeDTO } from '../services/DTO/rate/interfaces'
import { EntityType } from '../store/reducers/lists/interfaces'
import { generateMiscSurcharge } from '../services/functions/generate/generateMiscSurcharge'
import { openQMPRatesTab } from '../services/functions/openTab/openQMPRatesTab'
import { dateService } from '../services/timeService'
import { useContainerType } from '../hooks/useContainerType'

type Props = {
  children?: any
}

export const SsqBsqWidgetProvider = React.memo((props: Props) => {
  const { children } = props
  const { modifiedLists, modifyListItems } = useExpandedItem()
  const {
    sellSideQuote,
    buySideQuotes,
    quoteAmounts,
    customer,
    customerBusinessPartner,
    dispatchDeliveryOrder,
    deliveryOrder,
    deliveryOrderType,
    activities,
    isImport,
    isExport,
    isReefer,
    isQuotesModified,
    enableEditing,
    cancelQuotesModifies
  } = useGridItemDDO()
  const containerType = useContainerType({ id: oc(dispatchDeliveryOrder).containerTypeId() })
  const sellSideQuoteId = oc(dispatchDeliveryOrder).sellSideQuoteId()
  const buySideQuoteIds = oc(dispatchDeliveryOrder).buySideQuoteIds()
  const isRulesLoaded = useAppSelector(selectIsRulesLoaded)
  const isModified = isQuotesModified
  const deliveryLocation = useLocation({ id: oc(dispatchDeliveryOrder).deliveryStage.locationId(), modifiedLists })
  const businessActivities = activities.transportationActivities.filter(activity => isBusinessActivity(activity))
  const bobtailActivities = activities.transportationActivities.filter(activity => isBobtailGotoActivity(activity))
  const requestFilters = {
    deliveryOrderType: [deliveryOrderType],
    pickupLocationId: dispatchDeliveryOrder.pickupStage.locationId,
    deliveryLocation: deliveryLocation
      ? {
          city: oc(deliveryLocation).address.city(''),
          countryCode: '',
          postalCode: oc(deliveryLocation).address.postalCode(''),
          stateId: oc(deliveryLocation).address.stateId('')
        }
      : undefined,
    returnLocationId: dispatchDeliveryOrder.returnStage.locationId,
    customerId: deliveryOrder.customerId,
    containerTypeId: dispatchDeliveryOrder.containerTypeId ? [dispatchDeliveryOrder.containerTypeId] : undefined,
    loadType: dispatchDeliveryOrder.loadType ? [dispatchDeliveryOrder.loadType] : undefined,
    status: [CustomerQuoteDTO.StatusEnum.NEW, CustomerQuoteDTO.StatusEnum.EXPIRED]
  }
  const isNotReefer = oc(containerType).name() && !isReefer
  const unsuitableSurchargeTypes = [
    SellSideQuoteRateDTO.TypeEnum.BASE,
    SellSideQuoteRateDTO.TypeEnum.ALLIN,
    SellSideQuoteRateDTO.TypeEnum.FUEL
  ]

  if (
    activities.transportationActivities.every(
      activity => activity.type !== TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO
    )
  ) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.BOBTAIL)
  }
  if (!dispatchDeliveryOrder.hazmatIndicator) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.HAZMAT)
  }
  if (!dispatchDeliveryOrder.overweightIndicator) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT)
  }
  if (!(dispatchDeliveryOrder.pickupStage.locationId || dispatchDeliveryOrder.returnStage.locationId)) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.TOLLS)
  }
  if (
    !(
      (dispatchDeliveryOrder.pickupStage.locationId && isImport) ||
      (dispatchDeliveryOrder.returnStage.locationId && isExport)
    ) &&
    buySideQuotes.some(quote => buySideQuoteTypeGroups.shuttle.includes(quote.type))
  ) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.PREPULL)
  }
  if (!isReefer) {
    unsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.REEFER)
  }
  if (isReefer) {
    unsuitableSurchargeTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTION)
  }
  if (isNotReefer) {
    unsuitableSurchargeTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER)
  }

  const modifyQuote = (
    quoteType: EntityType.sellSideQuote | EntityType.buySideQuote,
    quote: SellSideQuoteDTO | BuySideQuoteDTO
  ) => {
    return {
      addMiscSurcharge: () => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            // @ts-ignore
            { ...quote, miscSurcharges: [...oc(quote).miscSurcharges([]), generateMiscSurcharge()] }
          ]
        })
      },
      modifyMiscSurcharge: (miscSurcharge: MiscSurchargeDTO) => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            {
              ...quote,
              miscSurcharges: oc(quote)
                // @ts-ignore
                .miscSurcharges([])
                // @ts-ignore
                .map(s => (s.id === miscSurcharge.id ? miscSurcharge : s))
            }
          ]
        })
      },
      deleteMiscSurchargeId: (miscSurchargeId: string) => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            {
              ...quote,
              miscSurcharges: oc(quote)
                // @ts-ignore
                .miscSurcharges([])
                // @ts-ignore
                .filter(s => s.id !== miscSurchargeId)
            }
          ]
        })
      },
      addSurcharge: (surcharge: SurchargeDTO) => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            {
              ...quote,
              // @ts-ignore
              surcharges: [...oc(quote).surcharges([]), surcharge]
            }
          ]
        })
      },
      modifySurcharge: (surcharge: SurchargeDTO) => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            {
              ...quote,
              surcharges: oc(quote)
                // @ts-ignore
                .surcharges([])
                // @ts-ignore
                .map(s => (s.id === surcharge.id ? surcharge : s))
            }
          ]
        })
      },
      deleteSurchargeId: (surchargeId: string) => {
        if (!enableEditing) {
          return
        }
        modifyListItems({
          [quoteType]: [
            {
              ...quote,
              surcharges: oc(quote)
                // @ts-ignore
                .surcharges([])
                // @ts-ignore
                .filter(s => s.id !== surchargeId)
            }
          ]
        })
      }
    }
  }

  const onOpenRatesClick = (date: DateISOString) => (_vendor?: { label: string; value: string }) => {
    const vendor = _vendor && _vendor.label && _vendor.value ? _vendor : undefined
    const _requestFilters = {
      ...requestFilters,
      customerId: deliveryOrder.customerId
        ? [
            {
              label: oc(customerBusinessPartner).legalName('') || oc(customer).name('name'),
              value: deliveryOrder.customerId
            }
          ]
        : undefined
    }

    openQMPRatesTab({
      filledFilters: {
        ..._requestFilters,
        status: [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED],
        vendorId: vendor ? [vendor] : undefined,
        loadType: oc(_requestFilters)
          .loadType([])
          .includes(SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK)
          ? [..._requestFilters.loadType, BuySideQuoteRateDTO.LoadTypeEnum.DROP, BuySideQuoteRateDTO.LoadTypeEnum.PICK]
          : _requestFilters.loadType
      },
      extraData: {
        effectiveDate: dateService.convertStringDate(date).startDay
      }
    })
  }

  React.useEffect(() => {
    const missingBsqIds = buySideQuoteIds.filter(id => buySideQuotes.every(item => item.id !== id))

    if (!sellSideQuote) {
      requestSellSideQuoteById(sellSideQuoteId)
    }

    if (missingBsqIds.length) {
      requestBuySideQuotesByDdoId(oc(dispatchDeliveryOrder).id())
    }

    if (!isRulesLoaded) {
      requestRules()
    }
  }, [oc(dispatchDeliveryOrder).buySideQuoteIds()])

  return (
    <SsqBsqWidgetContext.Provider
      value={{
        enableEditing,
        isModified,
        sellSideQuote,
        buySideQuotes,
        quoteAmounts,
        requestFilters,
        unsuitableSurchargeTypes,
        businessActivities,
        bobtailActivities,
        onOpenRatesClick,
        modifyQuote,
        cancelModifies: cancelQuotesModifies
      }}
      children={children}
    />
  )
})
