import * as React from 'react'
import { TProps } from './simple'
import { TextMask } from './textMask'

export const Integer = (props: TProps) => (
  <TextMask
    {...props}
    onChange={value => props.onChange(value ? (Number(value) < 32767 ? Number(value) : 32767) : null)}
    textMask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
  />
)
