import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { Window } from './preview'
import { Popover } from './popover'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useDispatchDeliveryOrders } from '../../../../../hooks/useDispatchDeliveryOrders'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
// tslint:disable:max-line-length
import { isDeliveryOrderSteamShipLineRequired } from '../../../../../services/functions/test/isDeliveryOrderSteamShipLineRequired'

const SteamShipLine = React.memo(() => {
  const { deliveryOrder } = useGridItemDDO()
  const { modifiedLists } = useExpandedItem()
  const dispatchDeliveryOrders = useDispatchDeliveryOrders({
    dispatchDeliveryOrderIds: oc(deliveryOrder).dispatchDeliveryOrderIds([]),
    modifiedLists
  })
  const isSteamShipLineRequired = isDeliveryOrderSteamShipLineRequired({ deliveryOrder, dispatchDeliveryOrders })

  return (
    <Widget
      title={'SSL'}
      highlighted={!deliveryOrder.steamShipLineId && isSteamShipLineRequired}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={undefined}
      detailsProps={{ isSteamShipLineRequired }}
    />
  )
})

export default SteamShipLine
