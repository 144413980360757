import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { DetailsViewHeader } from '../DetailsView/DetailsViewHeader'
import theme from '../../../../styles/theme'
import { ChannelSorting, ISearchChannelFilters } from '../../../../store/reducers/communicationHub/interfaces'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'

type OwnProps = {
  fetching: boolean
  searchResultsCount: number
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  searchFilters: ISearchChannelFilters
  setSearchFilters: React.Dispatch<React.SetStateAction<ISearchChannelFilters>>
  sorting: ChannelSorting
  toggleSorting(): void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ChannelBrowserHeader = React.memo((props: Props) => {
  const { fetching, searchValue, setSearchValue, searchResultsCount, sorting, toggleSorting } = props
  const dispatch = useAppDispatch()

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [setSearchValue]
  )

  // const SearchIconStyles: React.CSSProperties = React.useMemo(
  //   () => ({
  //     position: 'absolute',
  //     top: 0,
  //     bottom: 0,
  //     right: 5,
  //     display: 'flex',
  //     alignItems: 'center',
  //     opacity: 0.5
  //   }),
  //   []
  // )

  const RenderSearchChannelBlock = (
    <SearchContainer>
      <SearchInputContainer>
        {/*<Icon icon={IconName.SEARCH} fontSize={24} style={SearchIconStyles} mode={IconMode.DARK} />*/}
        <SearchInput
          data-id={'ChannelBrowser-SearchInput'}
          type={'text'}
          value={searchValue}
          onChange={handleInputChange}
          placeholder={'Search DDO...'}
          autoFocus={true}
        />
      </SearchInputContainer>
      <SearchFiltersContainer>
        {!fetching && (
          <SearchResultsCount>
            {searchResultsCount} channel{searchResultsCount !== 1 ? 's' : ''}
          </SearchResultsCount>
        )}
        <SearchSorting
          className={cn('mdi', {
            'mdi-sort-variant': sorting === ChannelSorting.recentlyUpdatedFirst,
            'mdi-sort-reverse-variant': sorting === ChannelSorting.recentlyUpdatedLast
          })}
          onClick={toggleSorting}
        >
          {sorting}
        </SearchSorting>
        {/*<SearchFilters>SearchFilters</SearchFilters>*/}
      </SearchFiltersContainer>
    </SearchContainer>
  )

  return (
    <>
      <DetailsViewHeader
        title={'Dispatch Delivery Order channel browser'}
        onCloseButtonClick={() => dispatch(communicationHubActions.closeChannelBrowser())}
        closeButtonId={'DDO Browser'}
        extraBottomBlock={RenderSearchChannelBlock}
      />
    </>
  )
})

const SearchContainer = styled.div``

const SearchInputContainer = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  position: relative;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(198, 204, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 14px;
  color: #000;
  background: none;
  padding: 0 35px 0 15px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    user-select: none;
  }
`

const SearchFiltersContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
`

const SearchResultsCount = styled.div`
  color: #000;
  font-weight: 500;
  font-size: 12px;
  user-select: none;
`

const SearchSorting = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.basicBlueColor};
  margin-left: auto;
  user-select: none;
  cursor: pointer;

  &:before {
    font-size: 14px;
    margin-right: 5px;
  }
`

const SearchFilters = styled.div``
