import * as React from 'react'
import * as R from 'remeda'
import { Column } from './Column/'
import { StyledBody, FullHeightContainer } from '../../Grid/styles'
import { GridItemSpinner } from '../../../../../UI/Spinner/Spinner'
import { ColumnSize, QMPTab, IRateFiltersState, RateType, NewRateDTO, IQMPFilters } from '../../../interfaces'
import { getSellSideQuoteRates, getBuySideQuoteRates } from '../../../../../../services/DTO/rate/epics'
// @ts-ignore
import { debounce } from 'debounce'
import { useQMP } from '../../../../../../hooks/useQMP'
import { getListsState } from '../../../../../../store'
import { requestLocationIds } from '../../../../location/epics'
import { requestCustomerIds } from '../../../../customer/epics'

type Props = {
  filters: IQMPFilters
  visited: boolean
  extraData: any
  ssqIds: string[]
  bsqIds: string[]
  newSSQRates: NewRateDTO[]
  newBSQRates: NewRateDTO[]
}

export const Rate = (props: Props) => {
  const { visited, extraData, filters, ssqIds, bsqIds, newSSQRates, newBSQRates } = props
  const mounted = React.useRef(false)
  const [columnSize, setColumnSize] = React.useState(ColumnSize.default)
  const { setRateIds } = useQMP()
  const [fetching, setFetching] = React.useState(false)

  const appliedFilters = React.useMemo(() => filters[QMPTab.RATE], [filters])

  const handleResizeClick = React.useCallback(
    (rateType: RateType) => {
      switch (columnSize) {
        case ColumnSize.default:
          return setColumnSize(rateType === RateType.ssq ? ColumnSize.wideSSQ : ColumnSize.wideBSQ)
        case ColumnSize.wideSSQ:
          return setColumnSize(rateType === RateType.ssq ? ColumnSize.default : ColumnSize.wideBSQ)
        case ColumnSize.wideBSQ:
          return setColumnSize(rateType === RateType.bsq ? ColumnSize.default : ColumnSize.wideSSQ)
        default:
      }
    },
    [columnSize]
  )

  React.useEffect(() => {
    const isFirstMountButNotFirstTabVisit = visited && !mounted.current
    mounted.current = true

    if (isFirstMountButNotFirstTabVisit) {
      return
    }

    setFetching(true)

    getRatesRequest(appliedFilters, (_props: { ssqIds: string[]; bsqIds: string[] }) => {
      setFetching(false)
      setRateIds(_props)
    })
  }, [appliedFilters])

  return (
    <StyledBody>
      <FullHeightContainer>
        {fetching && <GridItemSpinner />}
        <Column
          rateType={RateType.ssq}
          filters={filters}
          extraData={extraData}
          tabType={QMPTab.RATE}
          rateIds={ssqIds}
          newRates={newSSQRates}
          oppositeNewRates={newBSQRates}
          columnSize={columnSize}
          onResizeClick={handleResizeClick}
        />
        <Column
          rateType={RateType.bsq}
          filters={filters}
          extraData={extraData}
          tabType={QMPTab.RATE}
          rateIds={bsqIds}
          newRates={newBSQRates}
          oppositeNewRates={newSSQRates}
          columnSize={columnSize}
          onResizeClick={handleResizeClick}
        />
      </FullHeightContainer>
    </StyledBody>
  )
}

const getRatesRequest = debounce(
  (appliedFilters: IRateFiltersState, callback: (props: { ssqIds: string[]; bsqIds: string[] }) => void) => {
    return Promise.all([getSellSideQuoteRates(appliedFilters), getBuySideQuoteRates(appliedFilters)])
      .then(async ([ssqList, bsqList]) => {
        const _rates = ssqList.concat(bsqList as any)
        const storeLists = getListsState()
        const storeLocationMapping = storeLists.location
        const storeCustomerMapping = storeLists.customer

        let fetchLocationIds: string[] = []
        let fetchCustomerIds: string[] = []

        fetchLocationIds.push(appliedFilters.pickupLocationId)
        fetchLocationIds.push(appliedFilters.returnLocationId)
        fetchCustomerIds.push(
          ...(Array.isArray(appliedFilters.customerId) ? appliedFilters.customerId : []).map(item => item.value)
        )

        _rates.forEach((rate: any) => {
          fetchLocationIds.push(rate.pickupLocationId, rate.returnLocationId)
          fetchCustomerIds.push(rate.customerId)
        })

        fetchLocationIds = R.uniq(fetchLocationIds.filter(Boolean).filter(id => !storeLocationMapping[id]))
        fetchCustomerIds = R.uniq(fetchCustomerIds.filter(Boolean).filter(id => !storeCustomerMapping[id]))

        requestLocationIds(fetchLocationIds)
        requestCustomerIds(fetchCustomerIds)
        callback({ ssqIds: ssqList.map(({ id }) => id), bsqIds: bsqList.map(({ id }) => id) })
      })
      .catch(() => {
        callback({ ssqIds: [], bsqIds: [] })
      })
  },
  1000
)
