import styled from 'styled-components'
import theme from '../../../../styles/theme'

export const NotificationsContainer = styled.div`
  position: relative;
`
export const NotificationList = styled.div`
  position: absolute;
  top: 100%;
  right: -100px;
  padding-right: 8px;
`
export const NotificationsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.defaultRed};
  height: 50px;
  width: 50px;
  font-size: 18px;
  color: white;
  cursor: pointer;

  &.opened {
    background: ${theme.colors.defaultRed}90;
  }

  &:hover {
    transition: background 0.25s ease;
    background: ${theme.colors.defaultRed} !important;
  }
`
