import { EquipmentDTO } from '../../../api/origin/business-logic'
import { powerUnitDirectory } from '../powerUnit/directory'

const equipmentType = {
  [EquipmentDTO.EquipmentTypeEnum.CONESTOGATRAILER]: 'Conestoga Trailer',
  [EquipmentDTO.EquipmentTypeEnum.DRYVANS]: 'Dry Vans',
  [EquipmentDTO.EquipmentTypeEnum.EXTENDABLEDOUBLEDROPTRAILER]: 'Extendable Double Drop Trailer',
  [EquipmentDTO.EquipmentTypeEnum.EXTENDABLEFLATBEDTRAILER]: 'Extendable Flatbed Trailer',
  [EquipmentDTO.EquipmentTypeEnum.FLATBEDTRAILER]: 'Flatbed Trailer',
  [EquipmentDTO.EquipmentTypeEnum.LOWBOYTRAILER]: 'Lowboy Trailer',
  [EquipmentDTO.EquipmentTypeEnum.MULTICARTRAILER]: 'Multi Car Trailer',
  [EquipmentDTO.EquipmentTypeEnum.OCEANCONTAINERCHASSIS]: 'Ocean Container Chassis',
  [EquipmentDTO.EquipmentTypeEnum.POWERONLYTRAILER]: 'Power Only Trailer',
  [EquipmentDTO.EquipmentTypeEnum.REFRIGERATEDTRAILER]: 'Refrigerated Trailer',
  [EquipmentDTO.EquipmentTypeEnum.REMOVABLEGOOSENECKTRAILER]: 'Removable Gooseneck Trailer',
  [EquipmentDTO.EquipmentTypeEnum.SIDEKITTRAILER]: 'Sidekit Trailer',
  [EquipmentDTO.EquipmentTypeEnum.SPECIALIZEDTRAILER]: 'Specialized Trailer',
  [EquipmentDTO.EquipmentTypeEnum.SPECIALTYTRAILER]: 'Specialty Trailer',
  [EquipmentDTO.EquipmentTypeEnum.STEPDECKTRAILER]: 'Step Deck Trailer',
  [EquipmentDTO.EquipmentTypeEnum.STRETCHRNGTRAILER]: 'Stretch RGN Trailer',
  [EquipmentDTO.EquipmentTypeEnum.STRETCHSINGLEDROPDECKTRAILER]: 'Stretch Single Drop Deck Trailer'
}

export const equipmentDirectory = {
  equipmentType,
  ownership: powerUnitDirectory.ownership,
  restrictions: powerUnitDirectory.restrictions,
  inactiveReason: powerUnitDirectory.inactiveReason,
  insuranceType: powerUnitDirectory.insuranceType,
  maintenanceInspectionFrequency: powerUnitDirectory.maintenanceInspectionFrequency,
  repairType: powerUnitDirectory.repairType
}
