import { CreateNotificationDTO, NotificationDTO } from '../../../api/origin/communication-hub-service'

export const generateNotification = (options?: Partial<CreateNotificationDTO>): CreateNotificationDTO => {
  return {
    type: NotificationDTO.TypeEnum.SIMPLE,
    text: undefined,
    subject: undefined,
    options: undefined,
    driverStatuses: [],
    documentType: undefined,
    ...(options || {})
  }
}
