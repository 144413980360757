import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  entityType: EntityType
  modifiedLists: Partial<TListsState>
}

export const isModifiedGridItem = (props: Props): boolean => {
  const { entityType, modifiedLists } = props

  if (modifiedLists) {
    switch (entityType) {
      case EntityType.dispatchDeliveryOrder: {
        const omitKeys = [
          EntityType.buySideQuote,
          EntityType.sellSideQuote,
          EntityType.location,
          EntityType.customer,
          EntityType.businessPartner,
          EntityType.contact
        ]
        const keys = (Object.keys(modifiedLists) as EntityType[]).filter(key => !omitKeys.includes(key))

        return keys.some(key => modifiedLists[key] && Object.keys(modifiedLists[key]).length)
      }
      case EntityType.deliveryOrder: {
        const omitKeys = [EntityType.location, EntityType.customer, EntityType.contact, EntityType.businessPartner]
        const keys = (Object.keys(modifiedLists) as EntityType[]).filter(key => !omitKeys.includes(key))

        return keys.some(key => modifiedLists[key] && Object.keys(modifiedLists[key]).length)
      }
      default:
        return Object.values(modifiedLists).some(mapping => Object.keys(mapping).length)
    }
  }

  return false
}
