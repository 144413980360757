import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { RepairRow } from './Row'
import { newRepair } from '../../../../../../services/repairService'
import { ColumnTitles, AddRow } from '../../../../contact/views/styledComponents'
import { EquipmentDTO } from '../../../../../../api/api'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'

type Props = {}

const extraContainerStyles = { overflow: 'auto scroll' }

export const Repairs = (props: Props) => {
  const { parentItem, modifyParentObjectField } = useExpandedItem()
  const equipment: EquipmentDTO = parentItem
  const repairs = equipment.repairs || []

  return (
    <Container
      style={extraContainerStyles}
      isGrid={true}
      rows={70}
      background={'#f9f9f9'}
      padding={{ top: 20, left: 20, right: 20 }}
    >
      {Boolean(repairs.length) && (
        <ColumnTitles rows={4} padding={{ left: 20, right: 40 }} margin={{ bottom: 10 }}>
          <Container className={'required'} columns={8}>
            Start DateTime
          </Container>
          <Container className={'required'} columns={8}>
            Finish DateTime
          </Container>
          <Container columns={6} className={'required'}>
            Type
          </Container>
          <Container className={'required'} columns={6}>
            Description
          </Container>
          <Container columns={6}>Shop Name</Container>
          <Container columns={5}>Cost Estimate, $</Container>
          <Container columns={5}>Bill Actual, $</Container>
          <Container columns={5}>Equipment Hours</Container>
          <Container columns={6}>Image Of Receipt</Container>
        </ColumnTitles>
      )}

      {repairs.map(repair => (
        <RepairRow
          key={repair.id}
          repair={repair}
          changeRepair={(fieldName: any) => (value: any) =>
            modifyParentObjectField('repairs')(
              repairs.map(item => (item.id === repair.id ? { ...item, [fieldName]: value } : item))
            )}
          deleteRepair={id => modifyParentObjectField('repairs')(repairs.filter(item => item.id !== id))}
        />
      ))}

      <AddRow
        rows={9}
        alignCenter={true}
        justifyCenter={true}
        margin={{ bottom: 20 }}
        onClick={() => modifyParentObjectField('repairs')([...repairs, newRepair()])}
      >
        Add Repair
      </AddRow>
    </Container>
  )
}
