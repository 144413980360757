import styled, { withProps } from '../../styles/styledComponents'
import theme, { IStyledComponentsProps } from '../../styles/theme'
import { GridColumn } from './GridSystem'

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 12px;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;
  user-select: none;

  i {
    font-size: 18px;
    margin-right: 3px;
  }
`
export const Input = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  background-color: ${theme.colors.basicBackgroundColor};
  color: #333333;
  cursor: pointer;
  margin-bottom: 15px;
  overflow: hidden;

  input {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    border: none;
    background: none;
    outline: none;
    font-size: 12px;
    color: #333333;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.basicBackgroundColor} inset;
    }
  }
`

export const Close = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  z-index: ${props => (props.zIndex ? props.zIndex : 15)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${theme.colors.defaultGray};
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 25px;
  top: ${props => (props.top ? props.top : 0)}px;
  bottom: ${props => (props.bottom ? props.bottom : 0)}px;
  right: ${props => (props.right ? props.right : 0)}px;
  cursor: pointer;

  &:before {
    content: '\f156';
    display: inline-block;
    font: normal normal normal 24px/1 'Material Design Icons';
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    color: black;
  }
`
export const StyledInput = withProps<IStyledComponentsProps, HTMLInputElement>(styled.input)`
  width: 100%;
  font-size: ${props => props.fontSize || 14}px;
  height: ${props => props.height || 35}px;
  line-height: ${props => props.height || 35}px;
  border: none;
  color: #333;
  background: ${props => (props.disabled ? 'none' : theme.colors.basicBackgroundColor)};
  border-radius: 5px;
  padding: 0 10px;

  &::placeholder{
    color: rgba(51, 51, 51, 0.3);
  }
`

export const Container = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  position: relative;
  width: ${props => (props.width ? props.width + 'px' : null)};
  margin: ${props =>
    props.margin
      ? `${props.margin.top || 0}px ${props.margin.right || 0}px ${props.margin.bottom || 0}px ${props.margin.left ||
          0}px`
      : null};
  padding: ${props =>
    props.padding
      ? `${props.padding.top || 0}px ${props.padding.right || 0}px ${props.padding.bottom || 0}px ${props.padding
          .left || 0}px`
      : null};
  background: ${props => props.background};
`
export const Title = withProps<IStyledComponentsProps, HTMLLabelElement>(styled.label)`
  display: block;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: rgba(108, 112, 125, 1);
  margin: ${props =>
    props.margin
      ? `${props.margin.top || 0}px ${props.margin.right || 0}px ${props.margin.bottom || 5}px ${props.margin.left ||
          0}px`
      : null};
  user-select: none;
  
  &.disabled {
    color: rgba(108, 112, 125, 0.6);
  }

  &:after{
    content: ${props => (props.isRequired ? `' *'` : null)};
    color: ${theme.colors.defaultRed};
    font-size: 14px;
    line-height: 14px;
  }
`

export const Delete = styled(GridColumn)`
  background: ${theme.colors.basicBorderColor};
  border-radius: 0 5px 5px 0;
  font-size: 18px;
  color: #4f4f4f;
  cursor: pointer;

  &.disabled {
    opacity: 0.5 !important;
    cursor: default !important;
  }
`
