import * as React from 'react'
import { SearchByList } from '../SearchByList'
import { StateDTO } from '../../../../api/origin/business-logic'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectStates } from '../../../../store/select/stateSelect'

type Props = {
  title?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  stateId: string | null | undefined
  onChange: (stateId: string) => void
  showCode?: boolean
}

export const StateSearch = ({ title, required, stateId, onChange, disabled, showCode, placeholder }: Props) => {
  const states = useAppSelector(selectStates)

  const createStateLabel = React.useCallback(
    ({ id, state, onlyCode }: { id?: string; state?: StateDTO; onlyCode?: boolean }): string => {
      const { code, name } = state || states[id] || { code: 'Code', name: 'Name' }
      return onlyCode ? code : `${code} – ${name}`
    },
    []
  )
  const stateList = React.useMemo(() => {
    return Object.values(states).reduce(
      (acc, curr) => [...acc, { label: createStateLabel({ state: curr }), value: curr.id }],
      []
    )
  }, [states])

  return (
    <SearchByList
      title={title}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      label={stateId ? createStateLabel({ id: stateId, onlyCode: showCode }) : null}
      dropDownList={stateList}
      onChange={onChange}
    />
  )
}
