import * as React from 'react'
import cn from 'classnames'
import theme from '../../../../../../../../../../../styles/theme'
import { ActivityDocumentPopupView } from '../../../../../../../../interfaces'
import { CloseButton, Header } from '../styles'
import { TroubleTicketDTO } from '../../../../../../../../../../../api/api'
import { FileCategory } from '../../../../../../../../../../../services/filesService/interfaces'

type Props = {
  isTroubleTicket: boolean
  view: ActivityDocumentPopupView
  onClosePopupClick: () => void
  documentType?: TroubleTicketDTO.TypeEnum | FileCategory.TIR | FileCategory.POD
}

export const ActivityDocumentHeader = React.memo((props: Props) => {
  const { isTroubleTicket, view, documentType, onClosePopupClick } = props
  const isResolvingTroubleTicketView =
    isTroubleTicket &&
    (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED ||
      view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL ||
      view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE)
  let title = undefined

  if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL) {
    title = 'Unsuccessful'
  } else if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED) {
    title = 'Proceed'
  } else if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) {
    title = 'Validation'
  } else if (isTroubleTicket) {
    title = 'Trouble Ticket'

    if (documentType === TroubleTicketDTO.TypeEnum.POD || documentType === TroubleTicketDTO.TypeEnum.TIR) {
      title = `${documentType} Trouble Ticket`
    }
  } else if (documentType) {
    title = documentType
  }

  return (
    <>
      <CloseButton className={'mdi mdi-close'} onClick={onClosePopupClick} />
      <Header>
        <i
          style={{
            color: isTroubleTicket && !isResolvingTroubleTicketView ? theme.colors.defaultRed : '#6B73EE'
          }}
          className={cn('mdi', {
            'mdi mdi-file-upload': !isTroubleTicket && documentType,
            'mdi-checkbox-marked-circle': isResolvingTroubleTicketView,
            'mdi-alert-decagram': isTroubleTicket && !isResolvingTroubleTicketView
          })}
        />
        {title}
      </Header>
    </>
  )
})
