import styled from 'styled-components'

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
export const DateGroupHeader = styled.div`
  z-index: 2;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  position: sticky;
  top: 0;
  background-color: #333333;
  border-radius: 4px 4px 0 0;
  padding: 0 16px;

  &.expandable {
    border-radius: 4px;
    cursor: pointer;
  }

  &.expanded {
    border-radius: 4px 4px 0 0;
  }

  .mdi {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 6px;
  }
`
export const DateGroupBody = styled.div`
  display: inline-flex;
  flex-direction: column;
  //background-color: #fff;
  background: rgb(227, 232, 234);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 4px 4px;
  padding: 16px 8px;
  overflow: hidden;

  &:empty {
    padding: 36px 20px;
    align-items: center;

    &:before {
      content: 'No  Planned DDO for this Day';
      color: #b2b2b2;
    }
  }
`
export const GroupBlock = styled.div`
  padding: 8px 0;

  &:not(:first-child) {
    border-top: 1px solid #e5e5e5;
  }

  &:last-child {
    padding-bottom: 0;
  }
`
export const GroupHeader = styled.div`
  display: flex;
  //background-color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #445366;
  margin-bottom: 5px;

  &.extra-top-margin {
    margin-top: 20px;
  }
`
