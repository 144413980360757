import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { dateService } from '../../../../../services/timeService'
import { UserDTO } from '../../../../../api/origin/user-service'
import { NotificationTagHintId } from '../../../../Hints/NotificationTagHint'

type OwnProps = {
  sender: UserDTO
  date: string
  tags: { text: string; style?: React.CSSProperties }[]
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NotificationHeader = (props: Props) => {
  const { sender, date, tags } = props
  const senderId = oc(sender).id()
  const time = React.useMemo(() => dateService.makeLabel(date, { onlyTime: true }), [date])
  const hintData = React.useMemo(
    () =>
      senderId
        ? {
            'data-for': 'user-details-hint',
            'data-tip': senderId
          }
        : {},
    [senderId]
  )

  return (
    <Container>
      <UserName {...hintData}>{oc(sender).name('User Name')}</UserName>
      <Time>{time}</Time>
      {(tags || []).map((tag, index) => (
        <Tag key={index} data-tip={tag.text} data-for={NotificationTagHintId} style={tag.style} children={tag.text} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const Time = styled.div`
  white-space: nowrap;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 8px 0 4px;
`
const Tag = styled.div`
  border-radius: 10px;
  border: 1px solid #475364;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 500;
  color: #475364;
  padding: 3px 10px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

const TextContainer = styled.div`
  font-size: 12px;
  line-height: 1.2;
`
