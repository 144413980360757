import * as React from 'react'
import styled from 'styled-components'
import { LocationGroupListItem } from './LocationGroupListItem'
import { ILocationGroup } from '../../interfaces'

type Props = {
  list: ILocationGroup[]
  onClick: (group: ILocationGroup) => void
}

export const LocationGroupList = (props: Props) => {
  const { list, onClick } = props

  return (
    <Container>
      {list.map(group => (
        <LocationGroupListItem key={group.id} group={group} onClick={() => onClick(group)} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
`

const Name = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
`

const Details = styled.div`
  color: #333;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.5;
`
