import { oc } from 'ts-optchain'
import { DeliveryOrderGridItemDTO } from '../../../api/api'
import { ChannelNamePlaceholder, ChannelType } from '../../../store/reducers/communicationHub/interfaces'
import { deliveryOrderDirectory } from '../../DTO/deliveryOrder/directory'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { getListsState } from '../../../store'

type Props = {
  channelType: ChannelType
  data: any
}

export const generateChannelDataByEntity = (
  props: Props
): {
  id: string
  name: string
  subName: string
  channelViewName: string
} => {
  const { channelType, data } = props
  const result = {
    id: '',
    name: '',
    subName: '',
    channelViewName: ''
  }

  switch (channelType) {
    case ChannelType.dispatchDeliveryOrder: {
      result.id = oc(data).id()
      const deliveryOrderNumber = oc(data).number('DDO#')
      let deliveryOrderType = oc(data).deliveryOrder.type()

      if (!deliveryOrderType && oc(data).deliveryOrderId()) {
        deliveryOrderType = oc(getListsState())[EntityType.deliveryOrder][data.deliveryOrderId].type()
      }

      const deliveryOrderLocation =
        deliveryOrderType === DeliveryOrderGridItemDTO.TypeEnum.REPOSITION
          ? oc(data).returnStage.location.shortName()
          : oc(data).deliveryStage.location.shortName()

      result.name = deliveryOrderNumber

      if (deliveryOrderType) {
        deliveryOrderType = deliveryOrderDirectory.columnType[deliveryOrderType].toUpperCase()
        result.name += ' ' + deliveryOrderType
      }

      result.subName = deliveryOrderLocation || ChannelNamePlaceholder.placeholderLongLine
      result.channelViewName = [deliveryOrderNumber, deliveryOrderType, deliveryOrderLocation]
        .filter(Boolean)
        .join(' • ')

      return result
    }
    default:
      return result
  }
}
