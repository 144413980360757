import { SellSideQuoteMiscSurchargeDTO } from '../../../api/api'
import { MiscSurchargeDTO } from '../../DTO/rate/interfaces'
import { createId } from '../../utils'

export const generateMiscSurcharge = (): MiscSurchargeDTO => ({
  id: createId(),
  calculationType: SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.FIXEDAMOUNT,
  quantity: 0,
  amount: 0,
  description: '',
  type: SellSideQuoteMiscSurchargeDTO.TypeEnum.GENERAL
})
