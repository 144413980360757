import * as React from 'react'
import styled from '../../styles/styledComponents'
import theme from '../../styles/theme'
import { preventEvent } from '../../services/functions/basic'

const StyledContextMenu = styled.div`
  z-index: 70;
  width: 165px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  padding: 3px 14px 0 14px;
  font-size: 12px;
  color: #333;

  > div:last-child {
    border: 0;
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  border-bottom: solid 1px ${theme.colors.basicBorderColor};
  cursor: pointer;
`

interface IContextMenuAction {
  title: string
  callToAction(): void
}

type Props = {
  position: any
  closeContextMenu: () => void
  actions: IContextMenuAction[]
}

export const ContextMenu = (props: Props) => {
  const { position, actions, closeContextMenu } = props

  React.useEffect(() => {
    const handleScroll = () => {
      closeContextMenu()
    }

    const handleKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        closeContextMenu()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledContextMenu style={position} onMouseLeave={() => closeContextMenu()}>
      {actions.map(action => {
        return (
          <Action
            key={action.title}
            onMouseDown={(event: React.MouseEvent) => {
              preventEvent(event)
              action.callToAction()
              closeContextMenu()
            }}
          >
            {action.title}
          </Action>
        )
      })}
    </StyledContextMenu>
  )
}

export default ContextMenu
