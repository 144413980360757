import { oc } from 'ts-optchain'
import {
  callAPIWithErrorMessage,
  TroubleTicketDTO,
  troubleTicketDocumentAPI,
  dispatchDeliveryOrderAPI,
  ActivitiesViewDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../api/api'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../../utils'
import { isDDOStatusCancelled } from '../../../functions/test/isDDOStatusCancelled'
import { getAssignedDrivers, TAssignedDrivers } from '../functions'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { createWorkOrder } from '../../../../components/CommunicationHub/epics'
import { getRelatedActivityType } from '../../../functions/get/getRelatedActivityType'

const receiveActivities = async (activities: ActivitiesViewDTO) => {
  const activityList = [...oc(activities).transportationActivities([]), ...oc(activities).documentationActivities([])]

  if (activityList.length) {
    await pushListItemsToStore({ update: { [EntityType.activity]: activityList } })
  }

  return activities
}

export const createTroubleTicketByActivityId = (activityId: string): Promise<TroubleTicketDTO> => {
  return callAPIWithErrorMessage(troubleTicketDocumentAPI.create, activityId)
}

export const requestDeleteActivities = (_activities: ActivitiesViewDTO, ddoId: string) => {
  const activities = { ..._activities }

  if (activities && activities.transportationActivities && activities.transportationActivities.length) {
    activities.transportationActivities = activities.transportationActivities
      .map(activity => (isNewItem(activity) ? undefined : activity))
      .filter(Boolean)
  }
  if (activities && activities.documentationActivities && activities.documentationActivities.length) {
    activities.documentationActivities = activities.documentationActivities
      .map(activity => (isNewItem(activity) ? undefined : activity))
      .filter(Boolean)
  }

  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.deleteActivities, ddoId, activities).then(receiveActivities)
}

export const requestUpdateActivities = (_activities: ActivitiesViewDTO, ddoId: string) => {
  const activities = { ..._activities }

  if (activities && activities.transportationActivities && activities.transportationActivities.length) {
    activities.transportationActivities = activities.transportationActivities.map(activity =>
      isNewItem(activity) ? { ...activity, id: undefined } : activity
    )
  }
  if (activities && activities.documentationActivities && activities.documentationActivities.length) {
    activities.documentationActivities = activities.documentationActivities.map(activity =>
      isNewItem(activity) ? { ...activity, id: undefined } : activity
    )
  }

  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.updateActivities, ddoId, activities).then(receiveActivities)
}

export const requestAssignDriver = async (props: {
  fullPrevDDO: DispatchDeliveryOrderViewDTO
  fullUpdatedDDO: DispatchDeliveryOrderViewDTO
}) => {
  const { fullPrevDDO, fullUpdatedDDO } = props

  if (isDDOStatusCancelled(fullUpdatedDDO.status)) {
    return Promise.resolve()
  }

  const assignDrivers: TAssignedDrivers = getAssignedDrivers(
    oc(fullPrevDDO).activities.transportationActivities([]),
    oc(fullUpdatedDDO).activities.transportationActivities([])
  )

  if (!assignDrivers.length) {
    return Promise.resolve()
  }

  if (
    oc(fullUpdatedDDO).deliveryStage.plannedAppointmentDateTimeRange.confirmed() === false &&
    getRelatedActivityType({
      transportationActivities: oc(fullUpdatedDDO).activities.transportationActivities([]),
      phaseType: 'delivery'
    })
  ) {
    showModalWindow({
      width: 260,
      title: 'Appointment date is Not Confirmed',
      buttons: [{ label: 'Ok' }]
    })
  }

  return createWorkOrder({
    ddoId: fullUpdatedDDO.id,
    fullDispatchDeliveryOrder: fullUpdatedDDO,
    drivers: assignDrivers
  })
}
