import * as React from 'react'
import { Container } from './Grid/styles'
import { Body, Header } from './Grid'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { QMPProvider } from '../../../../providers/QMPProvider'
import { IQMPTabData } from '../interfaces'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectIsRulesLoaded } from '../../../../store/select/ruleSelect'
import { requestRules } from '../epics/rules'
import { selectActiveApplicationTab } from '../../../../store/select/applicationTabSelect'

const TabContainer = styled(Container)`
  width: 100%;
  position: relative;
  z-index: 1;
`

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & OwnProps & DispatchProps

export const QMP = React.memo((props: Props) => {
  const tab = useAppSelector(selectActiveApplicationTab)
  const tabData = tab.data as IQMPTabData
  const isModifyMode = Boolean(tabData.newSSQRates.length || tabData.newBSQRates.length || tabData.newCustomerQuotes)
  const filters = React.useMemo(() => tabData.filters, [tabData.filters])
  const isRulesLoaded = useAppSelector(selectIsRulesLoaded)

  React.useEffect(() => {
    if (!isRulesLoaded) {
      requestRules()
    }
  }, [])

  return (
    <TabContainer>
      <QMPProvider tabId={tab.id}>
        <Header isModifyMode={isModifyMode} activeTab={tabData.activeTab} filters={filters} />
        <Body filters={filters} tabData={tabData} />
      </QMPProvider>
      <ReactTooltip className={'react-tooltip pre-line'} id={'rules'} />
    </TabContainer>
  )
})
