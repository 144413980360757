import * as React from 'react'
import { GridContext } from '../contexts/GridContext'
import { useAppSelector } from '../hooks/useAppSelector'
import {
  selectActiveApplicationTabActionsType,
  selectActiveApplicationTabData,
  selectActiveApplicationTabDisabledSelectIds,
  selectActiveApplicationTabEnableEditing,
  selectActiveApplicationTabGridItemDetailsRequestType,
  selectActiveApplicationTabId,
  selectActiveApplicationTabListRequestType,
  selectActiveApplicationTabPermissionsWebsoketsUpdate,
  selectActiveApplicationTabPinnedIds,
  selectActiveApplicationTabPropsToRequest,
  selectActiveApplicationTabSelectedIds,
  selectActiveApplicationTabType
} from '../store/select/applicationTabSelect'

type OwnProps = {
  fetchGridItems(withoutSpinner?: boolean): Promise<any>
  setGridFetching(state: boolean): void
  children: any
}

type StateProps = {}

type Props = OwnProps & StateProps

export const GridProvider = React.memo((props: Props) => {
  const { fetchGridItems, setGridFetching, children } = props
  const tabId = useAppSelector(selectActiveApplicationTabId)
  const tabType = useAppSelector(selectActiveApplicationTabType)
  const websoketsUpdate = useAppSelector(selectActiveApplicationTabPermissionsWebsoketsUpdate)
  const tabData = useAppSelector(selectActiveApplicationTabData)
  const listRequestType = useAppSelector(selectActiveApplicationTabListRequestType)
  const detailsRequestType = useAppSelector(selectActiveApplicationTabGridItemDetailsRequestType)
  const actionsType = useAppSelector(selectActiveApplicationTabActionsType)
  const selectedIds = useAppSelector(selectActiveApplicationTabSelectedIds)
  const pinnedIds = useAppSelector(selectActiveApplicationTabPinnedIds)
  const disabledSelectIds = useAppSelector(selectActiveApplicationTabDisabledSelectIds)
  const enableEditing = useAppSelector(selectActiveApplicationTabEnableEditing)
  const listRequestProps = useAppSelector(selectActiveApplicationTabPropsToRequest)

  return (
    <GridContext.Provider
      value={{
        fetchGridItems,
        setGridFetching,
        tabId,
        tabType,
        ignoreWS: !websoketsUpdate,
        tabData,
        listRequestType,
        actionsType,
        selectedIds: selectedIds || defaultIdsArray,
        pinnedIds: pinnedIds || defaultIdsArray,
        disabledSelectIds: disabledSelectIds || defaultIdsArray,
        detailsRequestType,
        enableEditing,
        listRequestProps
      }}
    >
      {children}
    </GridContext.Provider>
  )
})

const defaultIdsArray: string[] = []
