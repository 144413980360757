import { oc } from 'ts-optchain'
import {
  ContainerTypeDTO,
  ContainerViewDTO,
  CustomerViewDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  EquipmentDTO,
  HazmatDTO,
  LocationViewDTO,
  SteamShipLineViewDTO
} from '../../../api/api'
import { getObjectsDifference } from './getObjectsDifference'
import { getListsState } from '../../../store'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { IGridItemDDOContext } from '../../../contexts/GridItemDDOContext'
import { dateService } from '../../timeService'
import { ddoDirectory } from '../../DTO/dispatchDeliveryOrder/directory'

type Props = {
  expandedItemContext: TExpandedItemContext
  gridItemDDOProviderData: IGridItemDDOContext
}

export interface IConflictBlock {
  label: string
  isCritical?: boolean
  conflicts: IConflict[]
}

export interface IConflict {
  label: string
  prevValue: string
  nextValue: string
}

export const getDispatchDeliveryOrdersConflicts = (props: Props): (IConflictBlock)[] => {
  try {
    const { expandedItemContext, gridItemDDOProviderData } = props
    const { initialLists } = expandedItemContext
    const { dispatchDeliveryOrder } = gridItemDDOProviderData
    const storeLists = getListsState()

    const prevDDO = oc(initialLists).dispatchDeliveryOrder[dispatchDeliveryOrder.id]()
    const prevDO = oc(initialLists).deliveryOrder[dispatchDeliveryOrder.deliveryOrderId]()

    const nextDDO = prevDDO ? oc(storeLists).dispatchDeliveryOrder[dispatchDeliveryOrder.id]() : undefined
    const nextDO = prevDO ? oc(storeLists).deliveryOrder[dispatchDeliveryOrder.deliveryOrderId]() : undefined

    const blocks: string[] = ['General', 'Equipment', 'Cargo', 'Documents', 'SSL']
    const difference = getObjectsDifference({
      prevObject: pathMapping({
        // @ts-ignore
        dispatchDeliveryOrder: prevDDO,
        deliveryOrder: prevDO,
        hazmat: storeLists.hazmat[oc(prevDDO).hazmatId()],
        equipment: storeLists.equipment[oc(prevDDO).equipmentId()],
        container: storeLists.container[oc(prevDDO).containerId()],
        containerType: storeLists.containerType[oc(prevDDO).containerTypeId()],
        customer: storeLists.customer[oc(prevDO).customerId()],
        steamShipLine: storeLists.steamShipLine[oc(prevDO).steamShipLineId()],
        // @ts-ignore
        pickupLocation: storeLists.location[oc(prevDDO).pickupStage.locationId()],
        // @ts-ignore
        deliveryLocation: storeLists.location[oc(prevDDO).deliveryStage.locationId()],
        // @ts-ignore
        returnLocation: storeLists.location[oc(prevDDO).returnStage.locationId()]
      }),
      nextObject: pathMapping({
        // @ts-ignore
        dispatchDeliveryOrder: nextDDO,
        deliveryOrder: nextDO,
        hazmat: storeLists.hazmat[oc(nextDDO).hazmatId()],
        equipment: storeLists.equipment[oc(nextDDO).equipmentId()],
        container: storeLists.container[oc(nextDDO).containerId()],
        containerType: storeLists.containerType[oc(nextDDO).containerTypeId()],
        customer: storeLists.customer[oc(nextDO).customerId()],
        steamShipLine: storeLists.steamShipLine[oc(nextDO).steamShipLineId()],
        // @ts-ignore
        pickupLocation: storeLists.location[oc(nextDDO).pickupStage.locationId()],
        // @ts-ignore
        deliveryLocation: storeLists.location[oc(nextDDO).deliveryStage.locationId()],
        // @ts-ignore
        returnLocation: storeLists.location[oc(nextDDO).returnStage.locationId()]
      })
    })

    const getConflictDetails = (mapping: any): (IConflict)[] => {
      return Object.keys(mapping).map(key => {
        let label = key
        let { before, after } = mapping[key]

        if (key.includes('DateTimeRange')) {
          label = label.replace(/DateTimeRange/g, '')
          before = [
            dateService.makeLabel(before),
            oc(before).confirmed() === true && 'Confirmed',
            oc(before).confirmed() === false && 'Not Confirmed'
          ]
            .filter(Boolean)
            .join(' ')
          after = [
            dateService.makeLabel(after),
            oc(after).confirmed() === true && 'Confirmed',
            oc(after).confirmed() === false && 'Not Confirmed'
          ]
            .filter(Boolean)
            .join(' ')
        } else if (key.includes('Date')) {
          label = label.replace(/Date/g, '')
          before = dateService.makeLabel(before)
          after = dateService.makeLabel(after)
        }

        return {
          label: label.replace(/([a-z])([A-Z])/g, '$1 $2'),
          prevValue: before,
          nextValue: after
        } as IConflict
      })
    }

    return blocks
      .map(blockName =>
        difference[blockName]
          ? {
              label: blockName,
              conflicts: getConflictDetails(difference[blockName])
            }
          : undefined
      )
      .filter(Boolean)
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
    return []
  }
}

const pathMapping = (props: {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  hazmat: HazmatDTO
  equipment: EquipmentDTO
  container: ContainerViewDTO
  containerType: ContainerTypeDTO
  customer: CustomerViewDTO
  steamShipLine: SteamShipLineViewDTO
  pickupLocation: LocationViewDTO
  deliveryLocation: LocationViewDTO
  returnLocation: LocationViewDTO
}) => {
  const {
    dispatchDeliveryOrder,
    deliveryOrder,
    hazmat,
    equipment,
    container,
    containerType,
    customer,
    steamShipLine,
    pickupLocation,
    deliveryLocation,
    returnLocation
  } = props

  const isExport = oc(deliveryOrder).type() === DeliveryOrderViewDTO.TypeEnum.EXPORT

  const uppercaseBooleanValue = (value: true | false) => {
    if (value !== true && value !== false) {
      return
    }

    return String(value).toUpperCase()
  }

  return {
    General: {
      Status: ddoDirectory.status[oc(dispatchDeliveryOrder).status()],
      'Availability Date': oc(deliveryOrder).equipmentFirstPickupDate(),
      'Planned Appointment DateDateTimeRange': oc(
        dispatchDeliveryOrder
      ).deliveryStage.plannedAppointmentDateTimeRange(),
      'Actual Appointment Date': oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
      'Per Diem Free By Date': oc(deliveryOrder).lastFreeDatePerDiem(),
      'Planned Pickup DateDateTimeRange': oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange(),
      'Actual Pickup Date': oc(dispatchDeliveryOrder).pickupStage.actualAppointmentDate(),
      'Load Type': ddoDirectory.loadType[oc(dispatchDeliveryOrder).loadType()],
      'Planned Return DateDateTimeRange': oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange(),
      'Actual Return Date': oc(dispatchDeliveryOrder).returnStage.actualAppointmentDate(),
      'Cutoff Date': oc(deliveryOrder).generalCutoffDate(),
      'Planned Pick DateDateTimeRange': oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
      'Actual Pick Date': oc(dispatchDeliveryOrder).deliveryStage.actualPickDate(),
      'First Receiving Date': oc(deliveryOrder).firstReceivingDate(),
      'Hazmat Cutoff Date': oc(deliveryOrder).hazmatCutoffDate(),
      'Last Free Date': oc(deliveryOrder).lastFreeDateDemurrage(),
      'Auto Cutoff Date': oc(deliveryOrder).autoCutoffDate(),
      'Reefer Cutoff Date': oc(deliveryOrder).reeferCutoffDate(),
      'Spent (hours)': oc(dispatchDeliveryOrder).deliveryStage.spentTimeSpan(),
      'Pickup Location': oc(pickupLocation).name(),
      'Delivery Location': oc(deliveryLocation).name(),
      'Return Location': oc(returnLocation).name(),
      Description: oc(dispatchDeliveryOrder).description(),
      Customer: oc(customer).name()
    },
    Equipment: {
      'Container Type': oc(containerType).name(),
      'Container #': oc(container).number(),
      Seal: oc(dispatchDeliveryOrder).sealNumber(),
      'Chassis #': oc(equipment).chassisNumber(),
      'Chassis Pickup Date': oc(equipment).pickupDate(),
      'Chassis Return Date': oc(equipment).returnDate()
    },
    Cargo: {
      'Reference #': oc(deliveryOrder).cargo.referenceNumber(),
      Weight: oc(dispatchDeliveryOrder).weight(),
      'Weight Unit': oc(dispatchDeliveryOrder).weightUnit(),
      Overweight: oc(dispatchDeliveryOrder).overweightIndicator(),
      Miles: oc(dispatchDeliveryOrder).mileage(),
      Description: oc(deliveryOrder).cargo.description(),
      'Auto Indicator': uppercaseBooleanValue(oc(dispatchDeliveryOrder).autoIndicator()),
      'Hazmat Indicator': uppercaseBooleanValue(oc(dispatchDeliveryOrder).hazmatIndicator()),
      'Hazmat Description': hazmat
        ? [oc(hazmat).code(), oc(hazmat).description()].filter(Boolean).join(', ')
        : undefined
    },
    Documents: {
      'Booking #': oc(deliveryOrder).bookingNumber(),
      'Bill of Landing #': oc(deliveryOrder).billOfLadingNumber()
    },
    SSL: {
      'SSL Name': oc(steamShipLine).name(),
      'Vessel Name': oc(deliveryOrder).vesselName(),
      'Voyage #': oc(deliveryOrder).voyageNumber(),
      'Vessel Departure (ETD)Date': isExport
        ? oc(deliveryOrder).vesselDepartureDate()
        : oc(deliveryOrder).vesselArrivalDate()
    }
  }
}
