import * as React from 'react'
import { General } from '../Details/General'
import { Contacts } from '../Details/Contacts'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Location

export const LocationPreview = {
  headings: [T.General, T.Contacts],
  tabs: {
    [T.General]: <General />,
    [T.Contacts]: <Contacts />
  },
  isValid: checkValidation.location
}
