import * as React from 'react'
import { TabDataContext } from '../contexts/TabDataContext'
import { useAppSelector } from '../hooks/useAppSelector'
import {
  selectActiveApplicationTabData,
  selectActiveApplicationTabId,
  selectActiveApplicationTabType
} from '../store/select/applicationTabSelect'
import { tabActions } from '../store/reducers/tabs'

type Props = {
  children?: any
}

export const TabDataProvider = React.memo((props: Props) => {
  const { children } = props
  const tabId = useAppSelector(selectActiveApplicationTabId)
  const tabType = useAppSelector(selectActiveApplicationTabType)
  const tabData = useAppSelector(selectActiveApplicationTabData)

  const setTabData = React.useCallback(
    (data: any) => {
      tabActions.setTabData({ tabId, data })
    },
    [tabId]
  )
  const modifyTabDataProps = React.useCallback(
    (_props: any) => {
      tabActions.updateTabData({ tabId, options: _props })
    },
    [tabId]
  )

  return (
    <TabDataContext.Provider
      value={{
        tabId,
        tabType,
        tabData,
        setTabData,
        modifyTabDataProps
      }}
      children={children}
    />
  )
})
