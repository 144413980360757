import * as React from 'react'
import { oc } from 'ts-optchain'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TGridColumn } from '../../../grid/views/interfaces'
import { useGridItemEquipment } from '../../../../../hooks/useGridItemEquipment'
import { EquipmentColumn } from '../../../grid/columnSettings/equipmentColumnSettings'
import { equipmentDirectory } from '../../../../../services/DTO/equipment/directory'
import { getDateFromISOString } from '../../../../../services/timeService/dateUtils'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'
import { TableCell } from '../../../grid/views/styles'

const Column = React.memo((props: TGridColumn) => {
  const { className, columnName, style } = props
  const { equipment } = useGridItemEquipment()
  let text = columnName

  switch (columnName) {
    case EquipmentColumn.ActiveAvailability:
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className} data-copy-value-on-mousedown={true}>
          <AvailabilityColumn />
        </TableCell>
      )
    case EquipmentColumn.EquipmentNumber: {
      text = oc(equipment).chassisNumber()
      break
    }
    case EquipmentColumn.Type: {
      text = equipmentDirectory.equipmentType[equipment.equipmentType]
      break
    }
    case EquipmentColumn.Year: {
      text = oc(equipment).year() && String(equipment.year)
      break
    }
    case EquipmentColumn.Make: {
      text = oc(equipment).make()
      break
    }
    case EquipmentColumn.Model: {
      text = oc(equipment).model()
      break
    }
    case EquipmentColumn.MaxCargoWeight: {
      text =
        oc(equipment).weight() && oc(equipment).weightUnit() ? equipment.weight + ' ' + equipment.weightUnit : undefined
      break
    }
    case EquipmentColumn.Ownership: {
      text = equipmentDirectory.ownership[equipment.ownership]
      break
    }
    case EquipmentColumn.LicensePlateNumber: {
      text = oc(equipment).licensePlate()
      break
    }
    case EquipmentColumn.RegistrationState: {
      text = getAddressState({ stateId: oc(equipment).registration.stateId() }).name
      break
    }
    case EquipmentColumn.RegistrationDate: {
      text = getDateFromISOString(oc(equipment).registration.registeredDate())
      break
    }
    case EquipmentColumn.RegistrationExpDate: {
      text = getDateFromISOString(oc(equipment).registration.expirationDate())
      break
    }
    case EquipmentColumn.DOTSafetyInspectionDate: {
      text = getDateFromISOString(oc(equipment).dotSafetyInspection.performedDate())
      break
    }
    case EquipmentColumn.DOTSafetyInspectionAnnualExpDate: {
      text = getDateFromISOString(oc(equipment).dotSafetyInspection.expirationDate())
      break
    }
    case EquipmentColumn.MaintenanceInspectionFrequency: {
      text = equipmentDirectory.maintenanceInspectionFrequency[oc(equipment).maintenanceInspectionFrequency()]
      break
    }
    case EquipmentColumn.InsuranceType: {
      text = equipmentDirectory.insuranceType[oc(equipment).insurance.insuranceType()]
      break
    }
    case EquipmentColumn.InsuranceCarrier: {
      text = oc(equipment).insurance.carrier()
      break
    }
    case EquipmentColumn.InsuranceExpDate: {
      text = getDateFromISOString(oc(equipment).insurance.expirationDate())
      break
    }
    case EquipmentColumn.InsurancePolicyNumber: {
      text = oc(equipment).insurance.policyNumber()
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
})

export default Column
