import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, LocationViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { getListsState } from '../../../store'

type Props = {
  stage: TransportationActivityViewDTO.StageEnum
  usefulTransportationActivityGroups: TransportationActivityGroup[]
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  ddoPickupLocationId: string
  ddoReturnLocationId: string
}

export const isShuttleStageActivityGroup = (props: Props): boolean => {
  const {
    stage,
    usefulTransportationActivityGroups,
    deliveryOrderType,
    ddoPickupLocationId,
    ddoReturnLocationId
  } = props
  const getMainGroupIndex = (type: TransportationActivityViewDTO.TypeEnum, destinationId: string) => {
    return usefulTransportationActivityGroups.findIndex(
      _ => _.businessActivity.type === type && _.businessActivity.destinationId === destinationId
    )
  }

  const getRelativeGroup = (types: TransportationActivityViewDTO.TypeEnum[], position: number) => {
    const storeLocations = getListsState().location

    return usefulTransportationActivityGroups.find((group, index) => {
      const typeIsGood = types.includes(group.businessActivity.type)
      const destinationId = oc(group).businessActivity.destinationId()
      const destinationType = oc(storeLocations)[destinationId].type()

      return typeIsGood && index === position && destinationType === LocationViewDTO.TypeEnum.CONTAINERYARD
    })
  }

  switch (stage) {
    case TransportationActivityViewDTO.StageEnum.PICKUP: {
      if (!ddoPickupLocationId) {
        return false
      }

      if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT) {
        const mainGroupIndex = getMainGroupIndex(TransportationActivityViewDTO.TypeEnum.PICKUPFULL, ddoPickupLocationId)

        return Boolean(
          mainGroupIndex !== -1 &&
            getRelativeGroup(
              [
                TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS,
                TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS
              ],
              mainGroupIndex + 1
            )
        )
      }
      if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
        const mainGroupIndex = getMainGroupIndex(
          TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY,
          ddoPickupLocationId
        )

        return Boolean(
          mainGroupIndex !== -1 &&
            getRelativeGroup(
              [
                TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
                TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS
              ],
              mainGroupIndex + 1
            )
        )
      }
      return false
    }
    case TransportationActivityViewDTO.StageEnum.DELIVERY:
    case TransportationActivityViewDTO.StageEnum.RETURN: {
      if (!ddoReturnLocationId) {
        return false
      }

      if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT) {
        const mainGroupIndex = getMainGroupIndex(
          TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
          ddoReturnLocationId
        )

        return Boolean(
          mainGroupIndex !== -1 &&
            getRelativeGroup([TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY], mainGroupIndex - 1)
        )
      }
      if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
        const mainGroupIndex = getMainGroupIndex(
          TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS,
          ddoReturnLocationId
        )

        return Boolean(
          mainGroupIndex !== -1 &&
            getRelativeGroup([TransportationActivityViewDTO.TypeEnum.PICKUPFULL], mainGroupIndex - 1)
        )
      }
      return false
    }
    default:
      return false
  }
}
