import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { IMoveDTO, RouteBuilderGridView } from '../../../../store/reducers/communicationHub/interfaces'
import { getVendorIdMoves, updateMoveSequence } from '../../epics/routeBuilder'
import { RouteBuilderDashboard } from './RouteBuilderDashboard'
import { RouteBuilderMoves } from './RouteBuilderMoves'
import { RouteBuilderProvider } from '../../../../providers/RouteBuilderProvider'
import { filterCurrentDayMoves } from '../../functions/filterCurrentDayMoves'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectRouteBuilder } from '../../../../store/select/routeBuilderSelect'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { runBlinkAnimation } from '../../../../services/functions/animation/runBlinkAnimation'
import { scrollToElement } from '../../../../services/functions/animation/scrollToElement'
import { RouteBuilderToastMessage, RouteBuilderToastMessageType } from './RouteBuilderToastMessage'
import { selectDriver } from '../../../../store/select/driverSelect'
import { DriverViewDTO } from '../../../../api/api'
import { testDriverStatus } from '../../../../services/functions/test/testDriverStatus'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const RouteBuilderBody = (props: Props) => {
  const dispatch = useAppDispatch()
  const activeRouteBuilder = useAppSelector(selectRouteBuilder)
  const { vendorId, authUserId, tabs = [], view } = activeRouteBuilder || {}
  const driver = useAppSelector(selectDriver(vendorId))
  const currentTabsRef = React.useRef([])
  const prevTabsRef = React.useRef([])
  const [notActiveDriverToastMessage, setNotActiveDriverToastMessage] = React.useState<boolean>(false)
  const [routeBuiderToastMessage, setRouteBuiderToastMessage] = React.useState<{
    type: RouteBuilderToastMessageType
    message: string
  }>(null)
  const [fetch, setFetch] = React.useState(true)
  currentTabsRef.current = tabs

  if (!authUserId) {
    return <Container children={<EmptyDataLabel>No selected driver</EmptyDataLabel>} />
  }

  React.useEffect(() => {
    if (vendorId && tabs.length) {
      const getMoves = async () => {
        setFetch(true)

        try {
          for (let i = 0; i < tabs.length; i++) {
            const { date } = tabs[i]
            if (date !== oc(prevTabsRef.current)[i].day()) {
              const _moves = await getVendorIdMoves({ vendorId, date })
              dispatch(communicationHubActions.setActiveRouteBuilderMoves({ date, moves: _moves }))
            }
          }
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error(e)
          setRouteBuiderToastMessage({
            type: RouteBuilderToastMessageType.error,
            message: oc(e as any).data.message('')
          })
        }

        setFetch(false)
        prevTabsRef.current = tabs
      }

      getMoves()
    }
  }, [vendorId, ...tabs.map(tab => tab.date)])

  React.useEffect(() => {
    if (testDriverStatus(driver.status).isActive) {
      setNotActiveDriverToastMessage(false)
    } else {
      setNotActiveDriverToastMessage(true)
    }
  }, [vendorId, driver.status])

  const activeTab = React.useMemo(() => tabs.find(tab => tab.isActive), [tabs])
  const getCurrentTabDayMoves = React.useCallback(async () => {
    if (!vendorId) {
      return []
    }

    try {
      const _moves = await getVendorIdMoves({ vendorId, date: activeTab.date })
      dispatch(communicationHubActions.setActiveRouteBuilderMoves({ date: activeTab.date, moves: _moves }))

      return _moves
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      setRouteBuiderToastMessage({
        type: RouteBuilderToastMessageType.error,
        message: oc(e as any).data.message('')
      })
    }

    return []
  }, [activeTab.date, vendorId])

  React.useEffect(() => {
    if (activeRouteBuilder.higlightActivityId && activeTab.moves && activeTab.moves.length) {
      scrollToElement({ className: 'rb-item-' + activeRouteBuilder.higlightActivityId })
      runBlinkAnimation({
        elementClassName: 'rb-item-' + activeRouteBuilder.higlightActivityId,
        extraStyles: {
          borderRadius: '0'
        }
      }).then(() => {
        dispatch(communicationHubActions.removeActivityIdHiglight())
      })
    }
  }, [activeRouteBuilder.higlightActivityId, activeTab.moves])

  const onReplaceGroupPositions = React.useCallback(
    async (updatedMoves: IMoveDTO[]) => {
      setFetch(true)

      try {
        const updatedMovesWithCorrectSequenceNumber = updatedMoves.map((move, index) => ({
          ...move,
          sequenceNumber: activeTab.moves[index].sequenceNumber
        }))
        dispatch(
          communicationHubActions.setActiveRouteBuilderMoves({
            date: activeTab.date,
            moves: updatedMovesWithCorrectSequenceNumber
          })
        )
        await updateMoveSequence(
          updatedMovesWithCorrectSequenceNumber.filter((move, index) => move.id !== activeTab.moves[index].id)
        )
        const latestDayMoves = await getVendorIdMoves({ vendorId, date: activeTab.date })
        dispatch(communicationHubActions.setActiveRouteBuilderMoves({ date: activeTab.date, moves: latestDayMoves }))
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e)
        setRouteBuiderToastMessage({
          type: RouteBuilderToastMessageType.error,
          message: oc(e as any).data.message('')
        })
      }

      setFetch(false)
    },
    [activeTab.moves, vendorId]
  )

  const currentDayMoves = filterCurrentDayMoves(activeTab.date, activeTab.moves)

  return (
    <RouteBuilderProvider
      view={view}
      activeTabDate={activeTab.date}
      vendorId={vendorId}
      authUserId={authUserId}
      driver={driver}
      getCurrentTabDayMoves={getCurrentTabDayMoves}
      setRouteBuiderToastMessage={setRouteBuiderToastMessage}
      setFetch={setFetch}
    >
      <Container key={activeTab.date + vendorId}>
        <RouteBuilderDashboard tabs={tabs} view={view} currentDayMoves={currentDayMoves} />

        {(notActiveDriverToastMessage || routeBuiderToastMessage) && (
          <NotificationsContainer>
            {notActiveDriverToastMessage && (
              <RouteBuilderToastMessage
                type={RouteBuilderToastMessageType.notification}
                noTimer={true}
                meta={{
                  message:
                    driver.status === DriverViewDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS
                      ? 'Driver is off duty'
                      : 'Driver is inactive'
                }}
                removeToastMessageAction={() => setNotActiveDriverToastMessage(false)}
              />
            )}
            {routeBuiderToastMessage && (
              <RouteBuilderToastMessage
                key={routeBuiderToastMessage.message}
                type={routeBuiderToastMessage.type}
                meta={{ message: routeBuiderToastMessage.message }}
                removeToastMessageAction={() => setRouteBuiderToastMessage(null)}
              />
            )}
          </NotificationsContainer>
        )}
        {view === RouteBuilderGridView.grid && (
          <MovesColumnTitles>
            <MovesColumnTitle style={{ width: 145 }}>Timeline</MovesColumnTitle>
            <MovesColumnTitle>Moves</MovesColumnTitle>
          </MovesColumnTitles>
        )}
        <RouteBuilderMoves
          moves={currentDayMoves.length ? activeTab.moves : []}
          view={view}
          onReplaceGroupPositions={onReplaceGroupPositions}
        />
        {!currentDayMoves.length && !fetch && <EmptyDataLabel>No moves yet</EmptyDataLabel>}
        {fetch && <GridSpinner />}
      </Container>
    </RouteBuilderProvider>
  )
}

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const MovesColumnTitles = styled.div`
  flex-shrink: 0;
  display: flex;
  padding: 16px 15px 0 15px;
  user-select: none;
`
const MovesColumnTitle = styled.div`
  text-transform: uppercase;
  color: #b2b2b2;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`
const EmptyDataLabel = styled.div`
  font-size: 14px;
  color: #d8d8d8;
  margin: auto;
  user-select: none;
`
const NotificationsContainer = styled.div`
  padding: 15px 10px 15px 24px;

  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`
