import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { DeductionDTO } from '../api/origin/vendor-accounting-service'
import { useAppSelector } from './useAppSelector'
import { selectDeductions } from '../store/select/deductionSelect'

type Props = {
  deductionIds: string[]
  modifiedLists?: Partial<TListsState>
}

export const useDeductions = (props: Props): DeductionDTO[] => {
  const { deductionIds, modifiedLists } = props
  const storeDeductionsMapping = useAppSelector(selectDeductions)
  const modifiedDeductionsMapping = oc(modifiedLists)[EntityType.deduction]({})

  const deductions = React.useMemo(() => {
    return (deductionIds || []).map(id => modifiedDeductionsMapping[id] || storeDeductionsMapping[id]).filter(Boolean)
  }, [deductionIds, modifiedDeductionsMapping, storeDeductionsMapping])

  return deductions
}
