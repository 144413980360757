import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { Window } from './preview'
import { Popover } from './popover'
import { DeliveryOrderViewDTO, LocationViewDTO } from '../../../../../api/origin/business-logic'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

type Props = {}

export const TerminalIntegrationWidget = () => {
  const { dispatchDeliveryOrder, pickupLocation, returnLocation, deliveryOrderType } = useGridItemDDO()
  const terminalCode = getTerminalCode({ pickupLocation, returnLocation, deliveryOrderType }).toUpperCase()

  return (
    <Widget
      isTableWidget={true}
      title={'Mismatches'}
      bottomActions={true}
      disable={!dispatchDeliveryOrder.terminalMismatchIndicator}
      extraPreviewStyle={extraPreviewStyle}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{ terminalCode }}
      detailsProps={{ terminalCode, dispatchDeliveryOrder }}
    />
  )
}

const getTerminalCode = (props: {
  pickupLocation: LocationViewDTO
  returnLocation: LocationViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
}): string => {
  const { deliveryOrderType, pickupLocation, returnLocation } = props

  switch (deliveryOrderType) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return oc(pickupLocation).terminalIntegration.code('')
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
      return oc(returnLocation).terminalIntegration.code('')
    default:
      return ''
  }
}

const extraPreviewStyle = { height: 32, flex: '0 0 152px', padding: 0 }
