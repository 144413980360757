import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const NotificationTagHintId = 'NotificationTagHintId'

export const NotificationTagHint = () => {
  return (
    <ReactTooltip
      // className={'react-tooltip'}
      id={NotificationTagHintId}
      className={'react-tooltip pre-line react-tooltip_no-opacity'}
      place={'top'}
      effect={'solid'}
      backgroundColor={'white'}
      textColor={'rgb(50, 62, 78)'}
      borderColor={'#e5e5e5'}
      border={true}
      getContent={(text: string) => <TextContainer>{text}</TextContainer>}
    />
  )
}

const TextContainer = styled.div`
  font-size: 12px;
  line-height: 1.2;
`
