import * as React from 'react'
import styled from '../../../styles/styledComponents'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { CommunicationHubConstants } from '../../../constants/CommunicationHubConstants'

type OwnProps = {
  unitType: TabDTO.Type
  itemsNumber?: number
}

type Props = OwnProps

export const FloatingGridItemsCounter = ({ unitType, itemsNumber }: Props) => {
  switch (unitType) {
    case TabDTO.Type.dispatchDeliveryOrder:
    case TabDTO.Type.scheduler:
      return (
        <Counter>
          <span>Number of filtered items:</span> {itemsNumber || 0}
        </Counter>
      )
    default:
      return null
  }
}

const Counter = styled.div`
  position: fixed;
  bottom: 50px;
  right: calc(${CommunicationHubConstants.navigationViewWidth}px + 50px);
  color: #fff;
  transition: 0.25s;
  border-radius: 4px;
  background: #475264;
  padding: 5px;

  span {
    font-weight: 300;
  }

  .communicationHubExpanded & {
    right: calc(${CommunicationHubConstants.navigationViewWidth + CommunicationHubConstants.channelViewWidth}px + 50px);
  }

  .communicationHubHidden & {
    right: 50px;
  }
`
