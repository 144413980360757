import { oc } from 'ts-optchain'
import { ChannelType, IChannelMeta, IMessage } from '../../../store/reducers/communicationHub/interfaces'
import { TroubleTicketDTO } from '../../../api/origin/business-logic'
import { setChatChannelLastOpen } from '../epics'
import { MessageType } from '../../../api/origin/communication-hub-service'
import { UserType } from '../../../api/origin/user-service'
import { createOrUpdateChatChannel } from './createOrUpdateChatChannel'
import { checkIfNewMessageIncreasesChannelCounter } from './checkIfNewMessageIncreasesChannelCounter'
import { getActiveApplicationTabState, getDispatch, getNewStoreState } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { tabActions } from '../../../store/reducers/tabs'
import { appNotificationsActions } from '../../../store/reducers/appNotifications'
import { AppNotificationType } from '../../../store/reducers/appNotifications/interfaces'

export const handleReceivedChatMessage = (message: IMessage): void => {
  if (!message.id || !message.channelId) {
    return
  }

  const dispatch = getDispatch()
  const { communicationHub, user, appNotifications } = getNewStoreState()
  const activeChannelId = oc(communicationHub).activeChannel.id()
  const tab = getActiveApplicationTabState()
  const { expandedItem } = tab
  const tabId = tab.id
  const { pinnedMsgIds, mentionMsgIds } = oc(communicationHub).channelsMeta[message.channelId]({} as IChannelMeta)
  const isMyMessages = user.id === message.senderId
  const isChannelRequired = checkIfNewMessageIncreasesChannelCounter(message)
  const needToIncrementUnreadCount = isChannelRequired && !isMyMessages
  const isMessageCreated = message.createdAt === message.updatedAt

  if (
    user.type === UserType.DRIVER &&
    (!message.mentionUserIds || !message.mentionUserIds.includes(user.id)) &&
    !(mentionMsgIds || []).includes(message.id)
  ) {
    return
  }

  message.externalData = undefined

  if (
    message.type === MessageType.DOCUMENT &&
    message.attachments &&
    message.attachments.length &&
    expandedItem &&
    (expandedItem.id === message.channelId || expandedItem.data === message.channelId) &&
    oc(expandedItem).data.activityDocumentMessages()
  ) {
    dispatch(
      tabActions.mergeExpandedItemData({
        tabId,
        mergeProps: {
          activityDocumentMessages: [...expandedItem.data.activityDocumentMessages, message]
        }
      })
    )
  }

  // ANALYZE THE ACTION
  if (message.isPinned && (!pinnedMsgIds || !pinnedMsgIds.includes(message.id))) {
    dispatch(
      communicationHubActions.addPinnedMessageIdForChannelId({ messageId: message.id, channelId: message.channelId })
    )
  } else if (!message.isPinned && pinnedMsgIds && pinnedMsgIds.includes(message.id)) {
    dispatch(
      communicationHubActions.removePinnedMessageIdForChannelId({ messageId: message.id, channelId: message.channelId })
    )
  } else if (message.type === MessageType.ALERT) {
    dispatch(
      communicationHubActions.addAlertMessageIdForChannelId({ messageId: message.id, channelId: message.channelId })
    )

    if (message.isDeleted) {
      dispatch(appNotificationsActions.delete({ id: message.id }))
    } else if (message.status === TroubleTicketDTO.StatusEnum.NEW && isMessageCreated) {
      dispatch(appNotificationsActions.push({ id: message.id, type: AppNotificationType.alert, data: message }))
    } else if (
      (message.status === TroubleTicketDTO.StatusEnum.UNSUCCESSFUL ||
        message.status === TroubleTicketDTO.StatusEnum.PROCEED) &&
      (appNotifications || []).find(item => oc(item).id() === message.id)
    ) {
      dispatch(appNotificationsActions.push({ id: message.id, type: AppNotificationType.alert, data: message }))
    }
  }

  // Update channel position (and create channel if needed)
  if (isChannelRequired) {
    createOrUpdateChatChannel(message.channelId, ChannelType.dispatchDeliveryOrder, message.updatedAt)
    // const existedChannelIds = communicationHub.channels.idListByType[ChannelType.dispatchDeliveryOrder]
    // const needToAddChatChannel = message.channelId && !existedChannelIds.includes(message.channelId)
    //
    // if (needToAddChatChannel) {
    //   createOrUpdateChatChannel(message.channelId, ChannelType.dispatchDeliveryOrder, message.updatedAt)
    // }
  }

  // Increase Unread Messages Count for Not opened channel OR opened channel with active scrolling
  if (message.channelId !== activeChannelId || oc(communicationHub).activeChannel.updateUnreadCount()) {
    // increment mention
    if (
      message.mentionUserIds &&
      message.mentionUserIds.includes(user.id) &&
      (!mentionMsgIds || !mentionMsgIds.includes(message.id))
    ) {
      dispatch(
        communicationHubActions.addMentionMessageIdForChannelId({ channelId: message.channelId, messageId: message.id })
      )
    }

    // decrement mention
    if (
      (message.isDeleted || !message.mentionUserIds || !message.mentionUserIds.includes(user.id)) &&
      mentionMsgIds &&
      mentionMsgIds.includes(message.id)
    ) {
      dispatch(
        communicationHubActions.removeMentionMessageIdForChannelId({
          channelId: message.channelId,
          messageId: message.id
        })
      )
    }

    // increment channel unread count
    if (needToIncrementUnreadCount) {
      dispatch(communicationHubActions.incrementChannelIdUnreadCount(message))
    }

    // stop function
    if (message.channelId !== activeChannelId) {
      return
    }
  }

  // OPENED CHANNEL UPDATES
  // highlight as read opened channel
  if (!isMyMessages && !oc(communicationHub).activeChannel.updateUnreadCount()) {
    setChatChannelLastOpen(message.channelId)
  }

  // add message to storage
  if (message.isDeleted) {
    dispatch(communicationHubActions.removeMessageFromStorage(message))
  } else {
    dispatch(
      communicationHubActions.pushMessageToStorage({
        message: {
          ...message,
          readUserIds: oc(message)
            .readUserIds([])
            .filter(id => id !== user.id)
            .concat(user.id)
        }
        // removeLocalMessageId: isMyMessages && externalData.localMessageId
      })
    )
  }
}
