import * as React from 'react'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'
import cn from 'classnames'

export const StyledButton = styled.span`
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  border: 1px solid ${theme.colors.basicGreyColor};
  color: ${theme.colors.basicGreyColor};
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;

  &.red {
    border-color: ${theme.colors.defaultRed};
  }

  &:hover {
    opacity: 0.9;
  }

  &:before {
    font-size: 20px;
    margin-left: 5px;
  }

  span {
    text-align: center;
    flex-grow: 1;
    padding: 3px 12px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.round {
    border-radius: 15px;
    min-width: 75px;
    min-height: 24px;
  }

  &.outline {
    border-color: ${theme.colors.basicBlueColor};
    color: ${theme.colors.basicBlueColor};

    &.red {
      border-color: ${theme.colors.defaultRed};
    }
  }

  &.onlyText {
    border: none;
  }

  &.filled,
  &:not(.disabled):not(.onlyText):hover {
    background-color: ${theme.colors.basicBlueColor};
    border-color: ${theme.colors.basicBlueColor};
    color: white;

    &.red {
      background-color: ${theme.colors.defaultRed};
      border-color: ${theme.colors.defaultRed};
    }
  }
`

type Props = {
  id?: string
  disabled?: boolean
  round?: boolean
  filled?: boolean
  red?: boolean
  onlyText?: boolean
  outline?: boolean
  onClick: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  styles?: React.CSSProperties
  innerBlockStyles?: any
  className?: any
  options?: Record<string, any>
  children: any
}

export const ActionButton = (props: Props) => {
  const {
    id,
    red,
    onClick,
    styles,
    innerBlockStyles,
    className,
    filled,
    onlyText,
    round,
    disabled,
    outline,
    children,
    onMouseOver,
    onMouseOut,
    options
  } = props
  return (
    <StyledButton
      id={id}
      className={cn(className, { filled, disabled, outline, round, onlyText, red })}
      style={styles}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={disabled ? undefined : onClick}
      {...options || {}}
    >
      <span style={innerBlockStyles}>{children}</span>
    </StyledButton>
  )
}
