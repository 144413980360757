import * as React from 'react'

export const NotificationIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // tslint:disable-next-line:max-line-length
        d="M7.90847 1.71849C8.826 0.0873266 11.1745 0.0873257 12.092 1.71849L18.7884 13.6231C19.6883 15.223 18.5322 17.1998 16.6966 17.1998H3.30388C1.4683 17.1998 0.312188 15.223 1.2121 13.6231L7.90847 1.71849ZM11.2001 13.5999C11.2001 14.2626 10.6629 14.7999 10.0001 14.7999C9.3374 14.7999 8.80015 14.2626 8.80015 13.5999C8.80015 12.9372 9.3374 12.3999 10.0001 12.3999C10.6629 12.3999 11.2001 12.9372 11.2001 13.5999ZM10.0001 3.9999C9.3374 3.9999 8.80015 4.53716 8.80015 5.1999V8.7999C8.80015 9.46264 9.3374 9.9999 10.0001 9.9999C10.6629 9.9999 11.2001 9.46264 11.2001 8.7999V5.1999C11.2001 4.53716 10.6629 3.9999 10.0001 3.9999Z"
        fill="#F5A85F"
      />
    </svg>
  )
}
