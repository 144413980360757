import { CustomerViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateCustomer = (props?: Partial<CustomerViewDTO>): CustomerViewDTO => {
  return {
    completeness: maxCompleteness,
    id: createId(),
    businessPartnerId: undefined,
    netTerms: null,
    netTermsUnit: null,
    subClientIds: [],
    subClients: [],
    ...(props || {})
  }
}
