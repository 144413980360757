import * as React from 'react'
import {
  BuySideQuoteDTO,
  DateISOString,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  SellSideQuoteDTO,
  TransportationActivityViewDTO
} from '../api/api'
import { TCalculateQuotes } from '../services/functions/calculateDDOQuotes'
import { BuySideQuoteRateDTO, CustomerQuoteDTO, SellSideQuoteRateDTO } from '../api/origin/qmp-service'
import { MiscSurchargeDTO, SurchargeDTO } from '../services/DTO/rate/interfaces'
import { EntityType } from '../store/reducers/lists/interfaces'

export interface ISsqBsqWidgetContext {
  enableEditing: boolean
  isModified: boolean
  sellSideQuote: SellSideQuoteDTO
  buySideQuotes: BuySideQuoteDTO[]
  quoteAmounts: TCalculateQuotes
  unsuitableSurchargeTypes: (SellSideQuoteRateDTO.TypeEnum | BuySideQuoteRateDTO.TypeEnum)[]
  businessActivities: TransportationActivityViewDTO[]
  bobtailActivities: TransportationActivityViewDTO[]
  requestFilters: {
    deliveryOrderType: DeliveryOrderViewDTO.TypeEnum[]
    pickupLocationId: string
    deliveryLocation: ({ city: string; countryCode: string; postalCode: string; stateId: string }) | undefined
    returnLocationId: string
    customerId: string
    containerTypeId: string[] | undefined
    loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum[] | undefined
    status: CustomerQuoteDTO.StatusEnum[]
  }
  onOpenRatesClick: (date: DateISOString) => (vendor?: { label: string; value: string }) => void

  modifyQuote: (
    quoteType: EntityType.sellSideQuote | EntityType.buySideQuote,
    quote: SellSideQuoteDTO | BuySideQuoteDTO
  ) => {
    addMiscSurcharge: () => void
    modifyMiscSurcharge: (miscSurcharge: MiscSurchargeDTO) => void
    deleteMiscSurchargeId: (miscSurchargeId: string) => void
    addSurcharge: (surcharge: SurchargeDTO) => void
    modifySurcharge: (surcharge: SurchargeDTO) => void
    deleteSurchargeId: (surchargeId: string) => void
  }

  cancelModifies: () => void
}

export const SsqBsqWidgetContext = React.createContext<ISsqBsqWidgetContext>(undefined)
