import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'

export const WrapperSortTabs = styled.div`
  width: calc(100% - 35px);
  display: flex;
`

export const StyledTabs = styled.div`
  z-index: 2;
  position: relative;
  user-select: none;
  background: #445366;
`
export const TabsList = styled.div`
  display: flex;
`
export const StyledAddNewTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: ${theme.colors.spaceGray};
  font-size: 18px;
  text-align: center;

  &:hover {
    color: white;
  }
`
export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.defaultGray};
  font-size: 14px;
  position: absolute;
  width: 25px;
  top: 0;
  bottom: 0;
  right: 0;

  &:hover {
    color: white;
  }
`
export const TextBlock = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  right: 25px;
  bottom: 0;
  left: 25px;
`
export const Img = styled.div`
  width: 25px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: ${theme.colors.spaceGray};
`
export const StyledTab = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 80px;
  height: 35px;
  padding: 0 25px;
  border-bottom: none;
  background: #516176;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: ${theme.colors.spaceGray};
  cursor: pointer;
  margin-left: 1px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background: #fff;
    border-color: #fff;
    color: black;
    font-weight: 500;

    ${Img} {
      color: ${theme.colors.darkGreyColor};
    }

    ${Close}:hover {
      color: black;
    }
  }
`
export const Text = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  background: none;
  letter-spacing: -0.2px !important;
`

export const StyledDummyCap = styled.div`
  width: 50px;
`
