import * as R from 'remeda'
import { callAPI, callAPIWithErrorMessage, LocationViewDTO, locationAPI, LocationDTO } from '../../../../api/api'
import { catchListErrors } from '../../../../services/tabs/functions'
import { createFilterRequest } from '../../../../services/uiSettingsService/filter'
import { isNewObject } from '../../../../services/DTO'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'

export const requestLocationById = (id: string): Promise<LocationViewDTO> =>
  callAPI(locationAPI.findById, id)
    .toPromise()
    .then(async location => {
      await pushListItemsToStore({ update: { [EntityType.location]: [location] } })

      return location
    })

export const requestLocationIds = async (locationIds: string[]): Promise<LocationViewDTO[]> => {
  if (!(locationIds && locationIds.length)) {
    return []
  }

  const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(locationIds)
  const requestedLocations: LocationViewDTO[] = []

  if (!arrayOfRequestedIdsArrays.length) {
    return []
  }

  await Promise.all(
    arrayOfRequestedIdsArrays.map(ids =>
      requestLocations({ filter: 'id%%' + ids.join(',') }).then(locations => {
        requestedLocations.push(...locations)
      })
    )
  ).catch(() => {})

  if (requestedLocations.length) {
    await pushListItemsToStore({ update: { [EntityType.location]: requestedLocations } })
  }

  return requestedLocations
}

export const requestInitialLocations = async () => {
  return requestLocations({
    filter: `type%%${LocationDTO.TypeEnum.CONTAINERYARD},${LocationDTO.TypeEnum.TERMINAL}`
  })
}

export const requestLocations = async ({ sort, filter }: any): Promise<LocationViewDTO[]> => {
  return callAPI(locationAPI.findAll, filter, sort)
    .toPromise()
    .then(async locations => {
      await pushListItemsToStore({ update: { [EntityType.location]: locations } })

      return locations as LocationViewDTO[]
    })
    .catch(catchListErrors)
}

export const requestGridLocations = async ({ sort, filter }: any): Promise<LocationViewDTO[]> => {
  return callAPI(locationAPI.getGridLocations, filter, sort)
    .toPromise()
    .then(async locations => {
      await pushListItemsToStore({ update: { [EntityType.location]: locations } })

      return locations as LocationViewDTO[]
    })
    .catch(catchListErrors)
}

const omitLocationProps: (keyof LocationViewDTO)[] = ['primaryContact']

export const requestCreateLocation = (location: LocationViewDTO): Promise<LocationViewDTO> => {
  return callAPIWithErrorMessage(locationAPI.create, R.omit(
    location,
    omitLocationProps.concat('id')
  ) as LocationViewDTO).then(async requestedLocation => {
    await pushListItemsToStore({ update: { [EntityType.location]: [requestedLocation] } })

    return requestedLocation
  })
}

export const requestUpdateLocation = (location: LocationViewDTO): Promise<LocationViewDTO> => {
  if (isNewObject(location)) {
    return requestCreateLocation(location)
  }

  return callAPIWithErrorMessage(locationAPI.update, location.id, R.omit(
    location,
    omitLocationProps
  ) as LocationViewDTO).then(async requestedLocation => {
    await pushListItemsToStore({ update: { [EntityType.location]: [requestedLocation] } })

    return requestedLocation
  })
}
