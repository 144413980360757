import { generatePowerUnit } from '../functions/generate/generatePowerUnit'
import { generateDriver } from '../functions/generate/generateDriver'
import { generateEquipment } from '../functions/generate/generateEquipment'
import { generateLocation } from '../functions/generate/generateLocation'
import { generateSubClient } from '../functions/generate/generateSubClient'
import { generateSteamShipLine } from '../functions/generate/generateSteamShipLine'
import { generateCustomer } from '../functions/generate/generateCustomer'
import { generateContainer } from '../functions/generate/generateContainer'
import { TabDTO } from '../../store/reducers/tabs/interfaces'

export const createEntity = (type: TabDTO.Type) => {
  switch (type) {
    case TabDTO.Type.location:
      return generateLocation()
    case TabDTO.Type.customer:
      return generateCustomer()
    case TabDTO.Type.driver:
      return generateDriver()
    case TabDTO.Type.steamShipLine:
      return generateSteamShipLine()
    case TabDTO.Type.subClient:
      return generateSubClient()
    case TabDTO.Type.container:
      return generateContainer()
    case TabDTO.Type.powerUnit:
      return generatePowerUnit()
    case TabDTO.Type.equipment:
      return generateEquipment()
    default:
      return null
  }
}
