import { MaintenanceInspectionDTO } from '../../../api/api'
import { createId } from '../../utils'

export const generateMaintenanceInspection = (props?: Partial<MaintenanceInspectionDTO>): MaintenanceInspectionDTO => {
  return {
    id: createId(),
    performedDate: null,
    expirationDate: null,
    document: null,
    ...(props || {})
  }
}
