import {
  ActivitiesViewDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  LocationViewDTO,
  TransportationActivityViewDTO,
  VendorNameDTO
} from '../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { ActivityGroup, DocumentationActivityGroup, TransportationActivityGroup } from '../interfaces'
import {
  CorrectActivityData,
  getCurrentActivityGroup,
  getDDOWorkingStatus,
  updateActivityGroupsNumeration
} from '../../dispatchDeliveryOrder/functions'
import { isInProcessOrCompletedGroup } from '../functions'
import { createISODateNow } from '../../../../components/UI/DatePicker/Functions'
import { isDropBobtailGotoActivity } from '../../../functions/test/isBobtailGotoActivity'
import { isDropActivity } from '../../../functions/test/isDropActivity'
import { isUnsuccessfulActivityGroup } from '../../../functions/test/isUnsuccessfulActivity'
import { getActivityDropBobtailGroup } from '../../../functions/get/getActivityDropBobtailGroup'
import { generateBobtailActivityRow } from '../../../functions/generate/generateBobtailActivityRow'
import { generateActivityRow } from '../../../functions/generate/generateActivityRow'
import { generateTransportationActivity } from '../../../functions/generate/generateTransportationActivity'
import { TLocation } from '../../../../components/common/location/interfaces'
import { getNeededActivityTypesByStages } from '../../../functions/get/getNeededActivityTypesByStages'

const makeShuttleActivityGroupTypes: Record<
  DeliveryOrderViewDTO.TypeEnum,
  Record<TransportationActivityViewDTO.StageEnum, TransportationActivityViewDTO.TypeEnum[]>
> = {
  [DeliveryOrderViewDTO.TypeEnum.EXPORT]: {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: [
      TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
    ],
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: [
      TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPFULL
    ],
    [TransportationActivityViewDTO.StageEnum.RETURN]: [
      TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPFULL
    ]
  },
  [DeliveryOrderViewDTO.TypeEnum.IMPORT]: {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: [
      TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPFULL
    ],
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: [
      TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
    ],
    [TransportationActivityViewDTO.StageEnum.RETURN]: [
      TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
      TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
    ]
  }
}

export enum DDOWorkingStatus {
  none = 'none',
  planning = 'planning',
  working = 'working'
}

type RowDTO = {
  id: string
  stage: TransportationActivityViewDTO.StageEnum
  permissions: {
    ddoWorkingStatus: DDOWorkingStatus
    moveUp: boolean
    moveDown: boolean
    deletable: boolean
    writable: boolean
    fullAccess: boolean
  }
}

export type TransportationActivityRow = RowDTO & {
  activityGroup: TransportationActivityGroup
}

export type DocumentationActivityRow = RowDTO & {
  activityGroup: DocumentationActivityGroup
}

export type ActivityRow = TransportationActivityRow | DocumentationActivityRow

export type Stages = {
  [stage in TransportationActivityViewDTO.StageEnum]: {
    activityRows: ActivityRow[]
    permissions: {
      extendable: boolean
      shuttleAbility: boolean
    }
  }
}

export type ActivityGroupListData = {
  stages: Stages
  usefulRows: TransportationActivityRow[]
  currentActivityGroup?: TransportationActivityGroup
  lastUnsuccessfulActivityGroup?: TransportationActivityGroup
}

export const getActivityListData = (props: {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  activityGroups: ActivityGroup[]
}): ActivityGroupListData => {
  const { dispatchDeliveryOrder, deliveryOrder, activityGroups } = props
  const ddoWorkingStatus: DDOWorkingStatus = getDDOWorkingStatus(dispatchDeliveryOrder.status)

  const stages: Stages = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: {
      activityRows: [],
      permissions: {
        shuttleAbility: false,
        extendable: ddoWorkingStatus !== DDOWorkingStatus.none
      }
    },
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: {
      activityRows: [],
      permissions: {
        shuttleAbility: false,
        extendable: ddoWorkingStatus !== DDOWorkingStatus.none
      }
    },
    [TransportationActivityViewDTO.StageEnum.RETURN]: {
      activityRows: [],
      permissions: {
        shuttleAbility: false,
        extendable: ddoWorkingStatus !== DDOWorkingStatus.none
      }
    }
  }

  const {
    currentActivityGroup,
    currentActivityGroupIndex,
    lastUnsuccessfulActivityGroup,
    lastUnsuccessfulActivityGroupIndex
  } = getCurrentActivityGroup(activityGroups)
  const lastUnsuccessfulActivityGroupStage = oc(lastUnsuccessfulActivityGroup).businessActivity.stage()
  const currentGotoActivityNumber = oc(currentActivityGroup).gotoActivity.number(-1)
  const currentBusinessActivityNumber = oc(currentActivityGroup).businessActivity.number(-1)
  const currentActivityGroupStage = oc(currentActivityGroup).businessActivity.stage()
  const isCurrentActivityGroupCompleted =
    oc(currentActivityGroup).businessActivity.status() === TransportationActivityViewDTO.StatusEnum.COMPLETED

  const usefulRows: TransportationActivityRow[] = [] // activity groups w/o unsuccessful status
  let hasSubmittedStreetTurnAbove = false

  activityGroups.forEach((activityGroup, groupIndex) => {
    const { gotoActivity, businessActivity, documentationActivity } = activityGroup as {
      gotoActivity?: TransportationActivityViewDTO
      businessActivity?: TransportationActivityViewDTO
      documentationActivity?: DocumentationActivityDTO
    }

    if (documentationActivity) {
      // add activity group to stage
      stages[documentationActivity.stage].activityRows.push({
        id: documentationActivity.id,
        activityGroup,
        stage: documentationActivity.stage,
        permissions: {
          moveUp: false,
          moveDown: false,
          deletable: false,
          writable: false,
          fullAccess: false // all statuses are enabled and Actual Dates are writable
        }
      } as DocumentationActivityRow)

      if (documentationActivity.status === DocumentationActivityDTO.StatusEnum.SUBMITTED) {
        hasSubmittedStreetTurnAbove = true
      }
      return
    }

    const isUsefulActivityGroup: boolean = !isUnsuccessfulActivityGroup(activityGroup as any)

    const activityRow = {
      id: gotoActivity.id,
      activityGroup,
      stage: gotoActivity.stage,
      permissions: {
        ddoWorkingStatus,
        moveUp: false,
        moveDown: false,
        deletable: false,
        writable:
          ddoWorkingStatus !== DDOWorkingStatus.none &&
          gotoActivity.number >= currentGotoActivityNumber &&
          groupIndex >= lastUnsuccessfulActivityGroupIndex,
        fullAccess: false // all statuses are enabled and Actual Dates are writable
      }
    } as TransportationActivityRow
    const currentStageArray = stages[gotoActivity.stage].activityRows

    if (ddoWorkingStatus !== DDOWorkingStatus.none) {
      // >>> ACTIVITY GROUP PERMISSIONS
      if (
        isUsefulActivityGroup &&
        !(gotoActivity.template && gotoActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP) &&
        gotoActivity.number !== currentGotoActivityNumber
      ) {
        // deletable
        if (gotoActivity.number > currentBusinessActivityNumber) {
          activityRow.permissions.deletable = true
        }

        // move up
        if (
          groupIndex !== 0 &&
          (groupIndex > currentActivityGroupIndex + 1 ||
            (groupIndex === currentActivityGroupIndex + 1 && gotoActivity.stage !== currentActivityGroupStage)) &&
          (groupIndex > lastUnsuccessfulActivityGroupIndex + 1 ||
            (groupIndex === lastUnsuccessfulActivityGroupIndex + 1 &&
              gotoActivity.stage !== lastUnsuccessfulActivityGroupStage))
        ) {
          activityRow.permissions.moveUp = true
        }

        // move down
        if (
          groupIndex > currentActivityGroupIndex &&
          (groupIndex !== activityGroups.length - 1 ||
            activityRow.stage !== TransportationActivityViewDTO.StageEnum.RETURN)
        ) {
          activityRow.permissions.moveDown = true
        }
      }

      // full access
      if (!hasSubmittedStreetTurnAbove) {
        if (currentActivityGroup) {
          activityRow.permissions.fullAccess = isCurrentActivityGroupCompleted
            ? // is prev Current Activity Group
              Boolean(usefulRows.length) &&
              usefulRows[usefulRows.length - 1].id === currentActivityGroup.gotoActivity.id
            : // is Current Activity Group
              (activityRow.permissions.fullAccess = currentActivityGroup.gotoActivity.id === gotoActivity.id)
        } else if (usefulRows.length === 0) {
          // is not UNSUCCESSFUL and ready to be Current Activity Group
          activityRow.permissions.fullAccess = true
        }
      }
    }
    // <<<

    // add to useful group
    if (isUsefulActivityGroup) {
      usefulRows.push(activityRow)
    }

    // add activity group to stage
    currentStageArray.push(activityRow)
  })

  // STAGE PERMISSIONS
  if (currentActivityGroup) {
    if (currentActivityGroup.gotoActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
      stages[TransportationActivityViewDTO.StageEnum.PICKUP].permissions.extendable = false
    }
    if (currentActivityGroup.gotoActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN) {
      stages[TransportationActivityViewDTO.StageEnum.PICKUP].permissions.extendable = false
      stages[TransportationActivityViewDTO.StageEnum.DELIVERY].permissions.extendable = false
    }
  }

  if (lastUnsuccessfulActivityGroup) {
    if (lastUnsuccessfulActivityGroupStage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
      stages[TransportationActivityViewDTO.StageEnum.PICKUP].permissions.extendable = false
    }

    if (lastUnsuccessfulActivityGroupStage === TransportationActivityViewDTO.StageEnum.RETURN) {
      stages[TransportationActivityViewDTO.StageEnum.DELIVERY].permissions.extendable = false
    }
  }

  // SHUTTLE ABILITY
  const calculatedShuttleAbility = calcShuttleAbility({
    dispatchDeliveryOrder,
    deliveryOrder,
    usefulRows
  })
  stages[TransportationActivityViewDTO.StageEnum.PICKUP].permissions.shuttleAbility =
    calculatedShuttleAbility[TransportationActivityViewDTO.StageEnum.PICKUP]
  stages[TransportationActivityViewDTO.StageEnum.RETURN].permissions.shuttleAbility =
    calculatedShuttleAbility[TransportationActivityViewDTO.StageEnum.RETURN]

  return {
    stages,
    usefulRows,
    currentActivityGroup,
    lastUnsuccessfulActivityGroup
  }
}

export enum ActivityGroupAction {
  severalUpdates = 'severalUpdates',
  update = 'update',
  remove = 'remove',
  moveUp = 'moveUp',
  moveDown = 'moveDown',
  duplicate = 'duplicate',
  createDropActivityBobtail = 'createDropActivityBobtail'
  // createPickActivityBobtail = 'createPickActivityBobtail'
}

type Props = {
  activities: ActivitiesViewDTO
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroupListData: ActivityGroupListData
  updatedActivityGroup?: ActivityRow
  updatedActivityGroups?: ActivityRow[]
}

export const doActionAndMakeGeneralActivityList = (props: Props) => (
  action: ActivityGroupAction
): CorrectActivityData => {
  const {
    dispatchDeliveryOrder,
    activities,
    deliveryOrderType,
    activityGroupListData,
    updatedActivityGroup,
    updatedActivityGroups
  } = props
  const isImport = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const initialGroups: ActivityRow[] = [
    ...activityGroupListData.stages[TransportationActivityViewDTO.StageEnum.PICKUP].activityRows,
    ...activityGroupListData.stages[TransportationActivityViewDTO.StageEnum.DELIVERY].activityRows,
    ...activityGroupListData.stages[TransportationActivityViewDTO.StageEnum.RETURN].activityRows
  ]
  let resultedRows: ActivityRow[] = R.clone(initialGroups)

  switch (action) {
    case ActivityGroupAction.severalUpdates: {
      const updatedActivityGroupsMap = updatedActivityGroups.reduce((acc, curr) => {
        // @ts-ignore
        acc[curr.id] = curr
        return acc
      }, {})
      // @ts-ignore
      resultedRows = initialGroups.map(group => updatedActivityGroupsMap[group.id] || group)
      break
    }
    case ActivityGroupAction.update: {
      resultedRows = initialGroups.map(group => (group.id === updatedActivityGroup.id ? updatedActivityGroup : group))
      break
    }
    case ActivityGroupAction.remove: {
      const groupsToRemove = updatedActivityGroups || [updatedActivityGroup]
      const removeIds = groupsToRemove.map(group => group.id)

      const checkToRemoveBobtailRows = (group: ActivityRow) => {
        if ('gotoActivity' in group.activityGroup && isDropActivity(group.activityGroup.gotoActivity)) {
          const currentActivityRowIndex = initialGroups.findIndex(row => row.id === group.id)
          let nextActivityRowIndex = currentActivityRowIndex + 1
          let nextActivityRow = initialGroups[nextActivityRowIndex]

          while (
            nextActivityRow &&
            'gotoActivity' in nextActivityRow.activityGroup &&
            isDropBobtailGotoActivity(nextActivityRow.activityGroup.gotoActivity)
          ) {
            removeIds.push(nextActivityRow.id)
            nextActivityRowIndex++
            nextActivityRow = initialGroups[nextActivityRowIndex]
          }
        }
      }

      groupsToRemove.forEach(checkToRemoveBobtailRows)

      resultedRows = initialGroups.filter(group => !removeIds.includes(group.id))
      break
    }
    case ActivityGroupAction.moveUp: {
      const index = initialGroups.findIndex(group => group.id === updatedActivityGroup.id)

      if (index !== 0) {
        const prevGroup = resultedRows[index - 1]

        if (prevGroup.stage !== updatedActivityGroup.stage) {
          let newStagePosition = prevGroup.stage

          if (isImport || isExport) {
            switch (updatedActivityGroup.stage) {
              case TransportationActivityViewDTO.StageEnum.RETURN:
                newStagePosition = TransportationActivityViewDTO.StageEnum.DELIVERY
                break
              case TransportationActivityViewDTO.StageEnum.DELIVERY:
                newStagePosition = TransportationActivityViewDTO.StageEnum.PICKUP
                break
              default:
            }
          }

          resultedRows[index] = changeActivityGroupStage(updatedActivityGroup, newStagePosition)
        } else {
          let insertFromIndex = index
          const insertItems = []
          const prevBobtailGroup = getActivityDropBobtailGroup({
            activity: oc(prevGroup as TransportationActivityRow).activityGroup.gotoActivity(),
            activities: oc(activities).transportationActivities([]),
            includeParentActivityGroup: true
          })
          const bobtailGroup = getActivityDropBobtailGroup({
            activity: oc(updatedActivityGroup as TransportationActivityRow).activityGroup.gotoActivity(),
            activities: oc(activities).transportationActivities([]),
            includeParentActivityGroup: true
          })

          if (bobtailGroup && bobtailGroup.length > 1) {
            insertItems.unshift(
              ...initialGroups
                .map(group =>
                  bobtailGroup.some(({ gotoActivity }) => gotoActivity.id === group.id) ? group : undefined
                )
                .filter(Boolean)
            )
          } else {
            insertItems.unshift(updatedActivityGroup)
          }

          if (prevBobtailGroup && prevBobtailGroup.length > 1) {
            insertItems.push(
              ...initialGroups
                .map(group =>
                  prevBobtailGroup.some(({ gotoActivity }) => gotoActivity.id === group.id) ? group : undefined
                )
                .filter(Boolean)
            )
            insertFromIndex = insertFromIndex - prevBobtailGroup.length
          } else {
            insertItems.push(prevGroup)
            insertFromIndex = insertFromIndex - 1
          }

          resultedRows.splice(insertFromIndex, insertItems.length, ...insertItems)
        }
      }
      break
    }
    case ActivityGroupAction.moveDown: {
      const index = initialGroups.findIndex(group => group.id === updatedActivityGroup.id)

      if (index !== initialGroups.length - 1) {
        const nextGroup = resultedRows[index + 1]

        if (nextGroup.stage !== updatedActivityGroup.stage) {
          let newStagePosition = nextGroup.stage

          if (isImport || isExport) {
            switch (updatedActivityGroup.stage) {
              case TransportationActivityViewDTO.StageEnum.PICKUP:
                newStagePosition = TransportationActivityViewDTO.StageEnum.DELIVERY
                break
              case TransportationActivityViewDTO.StageEnum.DELIVERY:
                newStagePosition = TransportationActivityViewDTO.StageEnum.RETURN
                break
              default:
            }
          }

          resultedRows[index] = changeActivityGroupStage(updatedActivityGroup, newStagePosition)
        } else {
          let insertFromIndex = index
          const insertItems = []
          const bobtailGroup = getActivityDropBobtailGroup({
            activity: oc(updatedActivityGroup as TransportationActivityRow).activityGroup.gotoActivity(),
            activities: oc(activities).transportationActivities([]),
            includeParentActivityGroup: true
          })
          const nextBobtailGroup = getActivityDropBobtailGroup({
            activity: oc(nextGroup as TransportationActivityRow).activityGroup.gotoActivity(),
            activities: oc(activities).transportationActivities([]),
            includeParentActivityGroup: true
          })

          if (bobtailGroup && bobtailGroup.length > 1) {
            insertFromIndex = initialGroups.findIndex(group => group.id === bobtailGroup[0].gotoActivity.id)
            insertItems.push(
              ...initialGroups
                .map(group =>
                  bobtailGroup.some(({ gotoActivity }) => gotoActivity.id === group.id) ? group : undefined
                )
                .filter(Boolean)
            )
          } else {
            insertItems.push(updatedActivityGroup)
          }

          if (nextBobtailGroup && nextBobtailGroup.length > 1) {
            insertItems.unshift(
              ...initialGroups
                .map(group =>
                  nextBobtailGroup.some(({ gotoActivity }) => gotoActivity.id === group.id) ? group : undefined
                )
                .filter(Boolean)
            )
          } else {
            insertItems.unshift(nextGroup)
          }

          resultedRows.splice(insertFromIndex, insertItems.length, ...insertItems)
        }
      } else if (updatedActivityGroup.stage !== TransportationActivityViewDTO.StageEnum.RETURN) {
        switch (updatedActivityGroup.stage) {
          case TransportationActivityViewDTO.StageEnum.PICKUP:
            resultedRows[index] = changeActivityGroupStage(
              updatedActivityGroup,
              isImport || isExport
                ? TransportationActivityViewDTO.StageEnum.DELIVERY
                : TransportationActivityViewDTO.StageEnum.RETURN
            )
            break
          case TransportationActivityViewDTO.StageEnum.DELIVERY:
            resultedRows[index] = changeActivityGroupStage(
              updatedActivityGroup,
              TransportationActivityViewDTO.StageEnum.RETURN
            )
            break
          default:
        }
      }
      break
    }
    case ActivityGroupAction.duplicate: {
      initialGroups.forEach((group, index) => {
        if (group.id === updatedActivityGroup.id) {
          const updatedGroup = updatedActivityGroup as TransportationActivityRow
          const isTemplate = oc(updatedGroup).activityGroup.gotoActivity.template(false)
          const newStatusIsUnsuccessful =
            oc(updatedGroup).activityGroup.gotoActivity.status() ===
              TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL ||
            oc(updatedGroup).activityGroup.businessActivity.status() ===
              TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL

          const duplicatedActivityGroup = generateActivityRow(updatedGroup)

          if (isTemplate && newStatusIsUnsuccessful) {
            const prevTemplateActivityGroup = swapTemplateActivityGroupProps(updatedGroup, duplicatedActivityGroup)
            const newTemplateActivityGroup = swapTemplateActivityGroupProps(duplicatedActivityGroup, updatedGroup)

            resultedRows.splice(index, 1, prevTemplateActivityGroup, newTemplateActivityGroup)
          } else {
            resultedRows.splice(index, 1, updatedGroup, duplicatedActivityGroup)
          }
        }
      })
      break
    }
    case ActivityGroupAction.createDropActivityBobtail: {
      initialGroups.forEach((group, index) => {
        if (group.id === updatedActivityGroup.id) {
          const dropActivityRow = group as TransportationActivityRow
          const nextActivityRow = initialGroups[index + 1] as TransportationActivityRow
          const bobtail = generateBobtailActivityRow(dropActivityRow, 'drop')
          let bobtailRowIndex = index + 1

          if (nextActivityRow && isDropBobtailGotoActivity(nextActivityRow.activityGroup.gotoActivity)) {
            bobtailRowIndex++
          }

          resultedRows.splice(bobtailRowIndex, 0, bobtail)
        }
      })
      break
    }
    // case ActivityGroupAction.createPickActivityBobtail: {
    //   initialGroups.forEach((group, index) => {
    //     if (group.id === updatedActivityGroup.id) {
    //       const dropActivityRow = group as TransportationActivityRow
    //       const bobtail = createBobtailActivityGroup(dropActivityRow, 'pick')
    //       let bobtailRowIndex = index

    //       resultedRows.splice(bobtailRowIndex, 0, bobtail)
    //     }
    //   })
    //   break
    // }
    default: {
      break
    }
  }

  const resultedActivityGroups = resultedRows.map(_ => _.activityGroup)
  const neededActivityTypesByStages = getNeededActivityTypesByStages({
    dispatchDeliveryOrder,
    deliveryOrderType,
    activityGroups: resultedActivityGroups
  })

  // set templates for Stages
  const stages = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]
  stages.forEach(stage => {
    const neededStageTypes = neededActivityTypesByStages[stage]

    if (!neededActivityTypesByStages[stage].length) {
      return
    }

    resultedRows = resultedRows.map(row => {
      if (!('businessActivity' in row.activityGroup)) {
        return row
      }

      const { businessActivity } = row.activityGroup

      let dispatchDeliveryLocationId: string = undefined

      switch (stage) {
        case TransportationActivityViewDTO.StageEnum.PICKUP:
          dispatchDeliveryLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.DELIVERY:
          dispatchDeliveryLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.RETURN:
          dispatchDeliveryLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
          break
        default:
      }

      if (
        neededStageTypes.includes(businessActivity.type) &&
        dispatchDeliveryLocationId === businessActivity.destinationId &&
        !isUnsuccessfulActivityGroup(row.activityGroup)
      ) {
        let orderIsCorrect = true

        if (neededStageTypes.length === 2) {
          const firstGroupIndex = resultedActivityGroups.findIndex(
            (group: any) =>
              'businessActivity' in group &&
              neededStageTypes[0] === group.businessActivity.type &&
              dispatchDeliveryLocationId === group.businessActivity.destinationId &&
              !isUnsuccessfulActivityGroup(group)
          )
          const secondGroupIndex = resultedActivityGroups.findIndex(
            (group: any) =>
              'businessActivity' in group &&
              neededStageTypes[1] === group.businessActivity.type &&
              dispatchDeliveryLocationId === group.businessActivity.destinationId &&
              !isUnsuccessfulActivityGroup(group)
          )

          if (firstGroupIndex === -1) {
            orderIsCorrect = false
          }

          if (firstGroupIndex !== -1 && secondGroupIndex !== -1) {
            orderIsCorrect = firstGroupIndex < secondGroupIndex
          }
        }

        if (orderIsCorrect) {
          const rowCopy = R.clone(row)
          rowCopy.activityGroup.gotoActivity.template = true
          rowCopy.activityGroup.businessActivity.template = true

          switch (stage) {
            case TransportationActivityViewDTO.StageEnum.PICKUP:
              rowCopy.activityGroup.businessActivity.startPlannedDateTimeRange = oc(
                dispatchDeliveryOrder
              ).pickupStage.plannedAppointmentDateTimeRange()
              break
            case TransportationActivityViewDTO.StageEnum.DELIVERY:
              const plannedDate = String(businessActivity.type).includes('PICK')
                ? oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange()
                : oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()

              rowCopy.activityGroup.businessActivity.startPlannedDateTimeRange = plannedDate
              break
            case TransportationActivityViewDTO.StageEnum.RETURN:
              rowCopy.activityGroup.businessActivity.startPlannedDateTimeRange = oc(
                dispatchDeliveryOrder
              ).returnStage.plannedAppointmentDateTimeRange()
              break
            default:
          }

          return rowCopy
        }
      }

      return row
    })
  })

  return updateActivityGroupsNumeration({ activityGroups: resultedRows.map(({ activityGroup }) => activityGroup) })
}

const swapTemplateActivityGroupProps = (
  transportationGroup: TransportationActivityRow,
  usePropsFromGroup: TransportationActivityRow
): TransportationActivityRow => {
  const group: TransportationActivityRow = R.clone(transportationGroup)

  const template = usePropsFromGroup.activityGroup.gotoActivity.template
  const groupId = usePropsFromGroup.activityGroup.gotoActivity.groupId

  group.id = usePropsFromGroup.id
  group.activityGroup.gotoActivity.id = usePropsFromGroup.activityGroup.gotoActivity.id
  group.activityGroup.businessActivity.id = usePropsFromGroup.activityGroup.businessActivity.id
  group.activityGroup.gotoActivity.number = usePropsFromGroup.activityGroup.gotoActivity.number
  group.activityGroup.businessActivity.number = usePropsFromGroup.activityGroup.businessActivity.number
  group.activityGroup.gotoActivity.template = template
  group.activityGroup.businessActivity.template = template
  group.activityGroup.gotoActivity.groupId = groupId
  group.activityGroup.businessActivity.groupId = groupId

  return group
}

const changeActivityGroupStage = (
  activityGroupRow: ActivityRow,
  stage: TransportationActivityViewDTO.StageEnum
): ActivityRow => {
  const activityGroupRowCopy: ActivityRow = R.clone(activityGroupRow)

  activityGroupRowCopy.stage = stage
  Object.values(activityGroupRowCopy.activityGroup).forEach((item: any) => {
    item.stage = stage
  })

  return activityGroupRowCopy
}

export const pushNewActivityGroupInStage = (
  activityGroupListData: ActivityGroupListData,
  stage: TransportationActivityViewDTO.StageEnum,
  createTemplateActivitiesData: {
    neededTypes: TransportationActivityViewDTO.TypeEnum[]
    dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
    pickupLocation: LocationViewDTO
    deliveryLocation: LocationViewDTO
    returnLocation: LocationViewDTO
  }
): CorrectActivityData => {
  const {
    neededTypes,
    dispatchDeliveryOrder,
    pickupLocation,
    deliveryLocation,
    returnLocation
  } = createTemplateActivitiesData
  const stagesInCorrectOrder = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]

  const updatedActivityGroups = stagesInCorrectOrder.reduce((acc, currStage) => {
    acc.push(...activityGroupListData.stages[currStage].activityRows.map(({ activityGroup }) => activityGroup))
    if (stage === currStage) {
      const dispatchDeliveryOrderId = dispatchDeliveryOrder.id

      if ((neededTypes || []).length) {
        let destination: any = undefined

        switch (stage) {
          case TransportationActivityViewDTO.StageEnum.PICKUP:
            destination = pickupLocation
            break
          case TransportationActivityViewDTO.StageEnum.DELIVERY:
            destination = deliveryLocation
            break
          case TransportationActivityViewDTO.StageEnum.RETURN:
            destination = returnLocation
            break
          default:
        }

        neededTypes.forEach(type => {
          const gotoActivity = generateTransportationActivity({
            dispatchDeliveryOrderId,
            stage: currStage,
            options: {
              type: TransportationActivityViewDTO.TypeEnum.GOTO,
              template: true,
              comboType: type as any,
              destination,
              destinationId: oc(destination).id()
            }
          })

          const businessActivity = generateTransportationActivity({
            dispatchDeliveryOrderId,
            stage: currStage,
            options: {
              type,
              template: true,
              comboType: type as any,
              destination,
              destinationId: oc(destination).id(),
              groupId: gotoActivity.groupId
            }
          })

          switch (stage) {
            case TransportationActivityViewDTO.StageEnum.PICKUP:
              businessActivity.startPlannedDateTimeRange = oc(
                dispatchDeliveryOrder
              ).pickupStage.plannedAppointmentDateTimeRange()
              break
            case TransportationActivityViewDTO.StageEnum.DELIVERY:
              const plannedDate = String(businessActivity.type).includes('PICK')
                ? oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange()
                : oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()

              businessActivity.startPlannedDateTimeRange = plannedDate
              break
            case TransportationActivityViewDTO.StageEnum.RETURN:
              businessActivity.startPlannedDateTimeRange = oc(
                dispatchDeliveryOrder
              ).returnStage.plannedAppointmentDateTimeRange()
              break
            default:
          }

          const newGroup = {
            gotoActivity,
            businessActivity
          }

          if (
            stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
            neededTypes.length === 1 &&
            (neededTypes.includes(TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS) ||
              neededTypes.includes(TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS))
          ) {
            // Incorrect Order
            const relatedType = neededTypes.includes(TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS)
              ? TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
              : TransportationActivityViewDTO.TypeEnum.PICKUPFULL

            let relatedGroup: TransportationActivityGroup = undefined
            let relatedGroupIndex = undefined
            acc.forEach((group, index) => {
              if (oc(group).businessActivity.template() && oc(group).businessActivity.type() === relatedType) {
                relatedGroup = group
                relatedGroupIndex = index
              }
            })

            if (relatedGroup) {
              if (isInProcessOrCompletedGroup(relatedGroup)) {
                const dateTimeNow = createISODateNow()
                newGroup.gotoActivity.destinationId = relatedGroup.gotoActivity.destinationId
                newGroup.businessActivity.destinationId = relatedGroup.businessActivity.destinationId
                newGroup.gotoActivity.vendorId = relatedGroup.gotoActivity.vendorId
                newGroup.businessActivity.vendorId = relatedGroup.businessActivity.vendorId
                newGroup.gotoActivity.status = TransportationActivityViewDTO.StatusEnum.COMPLETED
                newGroup.businessActivity.status = TransportationActivityViewDTO.StatusEnum.COMPLETED
                newGroup.gotoActivity.startActualDate = dateTimeNow
                newGroup.businessActivity.startActualDate = dateTimeNow
                newGroup.gotoActivity.completionActualDate = dateTimeNow
                newGroup.businessActivity.completionActualDate = dateTimeNow
              }
              acc.splice(relatedGroupIndex, 0, newGroup)
            } else {
              acc.push(newGroup)
            }
          } else {
            // Correct Order
            acc.push(newGroup)
          }
        })
      } else {
        const gotoActivity = generateTransportationActivity({
          dispatchDeliveryOrderId,
          stage: currStage,
          options: {
            type: TransportationActivityViewDTO.TypeEnum.GOTO
          }
        })

        const businessActivity = generateTransportationActivity({
          dispatchDeliveryOrderId,
          stage: currStage,
          options: {
            groupId: gotoActivity.groupId
          }
        })

        acc.push({
          gotoActivity,
          businessActivity
        })
      }
    }

    return acc
  }, [])

  return updateActivityGroupsNumeration({ activityGroups: updatedActivityGroups })
}

export const makeShuttle = (props: {
  ddoLocationIds: {
    pickupStage: string
    deliveryStage: string
    returnStage: string
  }
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroupListData: ActivityGroupListData
  stage: TransportationActivityViewDTO.StageEnum
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  destination: TLocation
}): CorrectActivityData => {
  const { ddoLocationIds, destination, stage, deliveryOrderType, activityGroupListData, loadType } = props
  const stagesInCorrectOrder = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]
  const transportationActivityGroups = activityGroupListData.usefulRows
    .filter(_ => 'businessActivity' in _.activityGroup)
    .map(_ => _.activityGroup)

  const activityGroupsByStages: Record<
    TransportationActivityViewDTO.StageEnum,
    ActivityGroup[]
  > = stagesInCorrectOrder.reduce((acc, currStage) => {
    // @ts-ignore
    acc[currStage] = activityGroupListData.stages[currStage].activityRows.map(({ activityGroup }) => activityGroup)
    return acc
  }, {})

  const createGroup = ({
    dispatchDeliveryOrderId,
    vendorId,
    status,
    type,
    activityStage
  }: {
    dispatchDeliveryOrderId: string
    vendorId: string
    status: TransportationActivityViewDTO.StatusEnum
    type: TransportationActivityViewDTO.TypeEnum | TransportationActivityViewDTO.ComboTypeEnum
    activityStage: TransportationActivityViewDTO.StageEnum
  }) => {
    const gotoActivity = generateTransportationActivity({
      dispatchDeliveryOrderId,
      stage: activityStage,
      options: {
        type: TransportationActivityViewDTO.TypeEnum.GOTO,
        comboType: type as TransportationActivityViewDTO.ComboTypeEnum,
        status,
        destination,
        destinationId: oc(destination).id(),
        vendorId
      }
    })

    const businessActivity = generateTransportationActivity({
      dispatchDeliveryOrderId,
      stage: activityStage,
      options: {
        type: type as TransportationActivityViewDTO.TypeEnum,
        comboType: type as TransportationActivityViewDTO.ComboTypeEnum,
        status,
        groupId: gotoActivity.groupId,
        destination,
        destinationId: oc(destination).id(),
        vendorId
      }
    })

    return {
      gotoActivity,
      businessActivity
    }
  }

  switch (stage) {
    case TransportationActivityViewDTO.StageEnum.PICKUP:
      {
        const mainActivityGroup = getTemplateBusinessActivityByGroups({
          destinationId: ddoLocationIds.pickupStage,
          transportationActivityGroups,
          loadType,
          stage,
          deliveryOrderType
        })

        const firstTemplateDeliveryActivity = (transportationActivityGroups || [])
          .map(group => group.businessActivity)
          .find(
            businessActivity =>
              businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
              businessActivity.template &&
              businessActivity.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
          )

        if (mainActivityGroup) {
          const { dispatchDeliveryOrderId, vendorId, status } = mainActivityGroup.gotoActivity
          activityGroupsByStages[TransportationActivityViewDTO.StageEnum.PICKUP].push(
            createGroup({
              dispatchDeliveryOrderId,
              vendorId,
              status: [
                TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
                TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
                TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
              ].includes(status)
                ? status
                : TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED,
              type: makeShuttleActivityGroupTypes[deliveryOrderType][stage][0],
              activityStage: TransportationActivityViewDTO.StageEnum.PICKUP
            })
          )
          activityGroupsByStages[TransportationActivityViewDTO.StageEnum.DELIVERY].unshift(
            createGroup({
              dispatchDeliveryOrderId,
              vendorId: oc(firstTemplateDeliveryActivity).vendorId(),
              status: oc(firstTemplateDeliveryActivity).status(TransportationActivityViewDTO.StatusEnum.NEW),
              type: makeShuttleActivityGroupTypes[deliveryOrderType][stage][1],
              activityStage: TransportationActivityViewDTO.StageEnum.DELIVERY
            })
          )
        }
      }
      break
    case TransportationActivityViewDTO.StageEnum.DELIVERY:
    case TransportationActivityViewDTO.StageEnum.RETURN: {
      const mainDeliveryActivityGroup = getTemplateBusinessActivityByGroups({
        destinationId: ddoLocationIds.deliveryStage,
        transportationActivityGroups: activityGroupListData.stages[
          TransportationActivityViewDTO.StageEnum.DELIVERY
        ].activityRows
          .filter(_ => 'businessActivity' in _.activityGroup)
          .map(_ => _.activityGroup) as TransportationActivityGroup[],
        loadType,
        stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
        deliveryOrderType,
        enableUnsuccessful: true
      })

      const mainReturnActivityGroup = getTemplateBusinessActivityByGroups({
        destinationId: ddoLocationIds.returnStage,
        transportationActivityGroups,
        loadType,
        stage: TransportationActivityViewDTO.StageEnum.RETURN,
        deliveryOrderType
      })

      if (mainDeliveryActivityGroup && mainReturnActivityGroup) {
        const { dispatchDeliveryOrderId, vendorId, status } = mainDeliveryActivityGroup.gotoActivity
        activityGroupsByStages[TransportationActivityViewDTO.StageEnum.DELIVERY].push(
          createGroup({
            dispatchDeliveryOrderId,
            vendorId,
            status: [
              TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
              TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
              TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
            ].includes(status)
              ? status
              : TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED,
            type: makeShuttleActivityGroupTypes[deliveryOrderType][stage][0],
            activityStage: TransportationActivityViewDTO.StageEnum.DELIVERY
          })
        )
        activityGroupsByStages[TransportationActivityViewDTO.StageEnum.RETURN].unshift(
          createGroup({
            dispatchDeliveryOrderId,
            vendorId: oc(mainReturnActivityGroup).businessActivity.vendorId(),
            status: oc(mainReturnActivityGroup).businessActivity.status(TransportationActivityViewDTO.StatusEnum.NEW),
            type: makeShuttleActivityGroupTypes[deliveryOrderType][stage][1],
            activityStage: TransportationActivityViewDTO.StageEnum.RETURN
          })
        )
      } else if (
        mainReturnActivityGroup &&
        transportationActivityGroups.filter(
          _ => _.gotoActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY
        ).length === 0
      ) {
        const { dispatchDeliveryOrderId } = mainReturnActivityGroup.gotoActivity
        activityGroupsByStages[TransportationActivityViewDTO.StageEnum.RETURN].unshift(
          createGroup({
            dispatchDeliveryOrderId,
            vendorId: oc(mainReturnActivityGroup).businessActivity.vendorId(),
            status: oc(mainReturnActivityGroup).businessActivity.status(TransportationActivityViewDTO.StatusEnum.NEW),
            type: makeShuttleActivityGroupTypes[deliveryOrderType][stage][1],
            activityStage: TransportationActivityViewDTO.StageEnum.RETURN
          })
        )
      }
      break
    }
    default:
  }

  const activityGroups = stagesInCorrectOrder.reduce((acc, currStage) => {
    acc.push(...activityGroupsByStages[currStage])
    return acc
  }, [])

  return updateActivityGroupsNumeration({ activityGroups })
}

type TCalcShuttleAbility = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  usefulRows: TransportationActivityRow[]
}

const calcShuttleAbility = (props: TCalcShuttleAbility): Record<TransportationActivityViewDTO.StageEnum, boolean> => {
  const { usefulRows, dispatchDeliveryOrder, deliveryOrder } = props
  const deliveryOrderType = deliveryOrder.type
  const loadType = dispatchDeliveryOrder.loadType
  const pickupLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
  const deliveryLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
  const returnLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
  const transportationActivityGroups = usefulRows
    .filter(_ => 'businessActivity' in _.activityGroup)
    .map(_ => _.activityGroup)

  const result = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: false,
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: false,
    [TransportationActivityViewDTO.StageEnum.RETURN]: false
  }

  if (
    deliveryOrderType !== DeliveryOrderViewDTO.TypeEnum.IMPORT &&
    deliveryOrderType !== DeliveryOrderViewDTO.TypeEnum.EXPORT
  ) {
    return result
  }

  const hasStreetTurnStage = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP: {
        return deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT && Boolean(dispatchDeliveryOrder.streetTurnId)
      }
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
      case TransportationActivityViewDTO.StageEnum.RETURN: {
        return deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT && Boolean(dispatchDeliveryOrder.streetTurnId)
      }
      default:
        return false
    }
  }

  const isGoodForShuttleTemplate = (scanStage: TransportationActivityViewDTO.StageEnum): boolean => {
    let shuttleAvailableStatuses: TransportationActivityViewDTO.StatusEnum[] = []
    let stageIsAvailable = true
    let vendorIsRequired = true

    let mainActivityGroup: TransportationActivityGroup = undefined

    switch (scanStage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        shuttleAvailableStatuses = [
          TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
          TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
          TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
        ]

        mainActivityGroup = getTemplateBusinessActivityByGroups({
          deliveryOrderType,
          stage: TransportationActivityViewDTO.StageEnum.PICKUP,
          loadType,
          destinationId: pickupLocationId,
          transportationActivityGroups
        })
        break
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
      case TransportationActivityViewDTO.StageEnum.RETURN:
        if (!returnLocationId) {
          break
        }

        const returnStageTransportationActivityGroups = transportationActivityGroups.filter(
          _ => _.businessActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN
        )

        if (returnStageTransportationActivityGroups.length === 0) {
          break
        }

        const returnMainActivityGroup = getTemplateBusinessActivityByGroups({
          deliveryOrderType,
          stage: TransportationActivityViewDTO.StageEnum.RETURN,
          loadType,
          destinationId: returnLocationId,
          transportationActivityGroups
        })

        if (!returnMainActivityGroup) {
          break
        }

        stageIsAvailable = !returnStageTransportationActivityGroups.some(_ =>
          [
            TransportationActivityViewDTO.StatusEnum.INPROCESS,
            TransportationActivityViewDTO.StatusEnum.COMPLETED
          ].includes(_.gotoActivity.status)
        )

        shuttleAvailableStatuses = Object.keys(TransportationActivityViewDTO.StatusEnum) as any

        if (
          transportationActivityGroups.filter(
            _ => _.businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY
          ).length
        ) {
          mainActivityGroup = getTemplateBusinessActivityByGroups({
            deliveryOrderType,
            stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
            loadType,
            destinationId: deliveryLocationId,
            transportationActivityGroups
          })
        } else {
          vendorIsRequired = false
          mainActivityGroup = returnMainActivityGroup
        }
        break
      default:
    }

    if (!mainActivityGroup) {
      return false
    }

    const { businessActivity, gotoActivity } = mainActivityGroup

    return (
      stageIsAvailable &&
      (!vendorIsRequired || businessActivity.vendorId) &&
      businessActivity.destinationId &&
      shuttleAvailableStatuses.includes(gotoActivity.status) &&
      shuttleAvailableStatuses.includes(businessActivity.status)
    )
  }

  const stages = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]

  stages.forEach(stage => {
    result[stage] =
      !hasStreetTurnStage(stage) &&
      isGoodForShuttleTemplate(stage) &&
      (() => {
        switch (stage) {
          case TransportationActivityViewDTO.StageEnum.PICKUP:
            return !dispatchDeliveryOrder.pickupStage.shuttle
          case TransportationActivityViewDTO.StageEnum.DELIVERY:
            return false
          case TransportationActivityViewDTO.StageEnum.RETURN:
            return !dispatchDeliveryOrder.returnStage.shuttle
          default:
            return false
        }
      })() // && isGoodForShuttleActivityGroups(stage)
  })

  return result
}

export const getTemplateBusinessActivityByGroups = (props: {
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  stage: TransportationActivityViewDTO.StageEnum
  transportationActivityGroups: TransportationActivityGroup[]
  destinationId: string
  enableUnsuccessful?: boolean
}): TransportationActivityGroup => {
  const { deliveryOrderType, stage, loadType, destinationId, transportationActivityGroups, enableUnsuccessful } = props

  if (!destinationId) {
    return undefined
  }

  const templateTransportationActivity = getTemplateBusinessActivityByActivities({
    deliveryOrderType,
    stage,
    loadType,
    destinationId,
    transportationActivities: (transportationActivityGroups || []).map(group => group.businessActivity),
    enableUnsuccessful
  })

  return templateTransportationActivity
    ? transportationActivityGroups.find(
        group => group.businessActivity.groupId === templateTransportationActivity.groupId
      )
    : undefined
}

export const getTemplateBusinessActivityByActivities = (props: {
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  stage: TransportationActivityViewDTO.StageEnum
  transportationActivities: TransportationActivityViewDTO[]
  destinationId: string
  enableUnsuccessful?: boolean
}): TransportationActivityViewDTO => {
  const { destinationId, loadType, stage, deliveryOrderType, transportationActivities, enableUnsuccessful } = props

  if (!destinationId) {
    return undefined
  }

  const search = (searchByAnotherType?: boolean) => {
    const searchSuitableForTemplate = (mustBeTemplate: boolean) => (
      activity: TransportationActivityViewDTO
    ): boolean => {
      return (
        (!mustBeTemplate || activity.template) &&
        activity.type === templateType &&
        activity.destinationId === destinationId
      )
    }

    const templateType = getMainTypeForShuttle({ loadType, stage, deliveryOrderType, anotherType: searchByAnotherType })
    const activities = transportationActivities || []

    const unUsefulGroupIds = activities.reduce((acc, curr) => {
      if (curr.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL) {
        acc.push(curr.groupId)
      }
      return acc
    }, [])
    const usefulActivities = activities.filter(activity => !unUsefulGroupIds.includes(activity.groupId))

    let result = usefulActivities.find(searchSuitableForTemplate(true))

    if (!result && enableUnsuccessful) {
      result = activities
        .slice()
        .reverse()
        .find(searchSuitableForTemplate(false))
    }

    return result
  }

  let searchResult = search()

  if (!searchResult && stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
    searchResult = search(true)
  }

  return searchResult
}

const getMainTypeForShuttle = (props: {
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  loadType: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  stage: TransportationActivityViewDTO.StageEnum
  anotherType?: boolean
}): TransportationActivityViewDTO.TypeEnum => {
  const { deliveryOrderType, stage, loadType, anotherType } = props

  if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT) {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return TransportationActivityViewDTO.TypeEnum.PICKUPFULL
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        return loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD
          ? TransportationActivityViewDTO.TypeEnum.GETUNLOADED
          : anotherType
          ? TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS
          : TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS
      default:
        return
    }
  }

  if (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
    switch (stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        return TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        return loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD
          ? TransportationActivityViewDTO.TypeEnum.GETLOADED
          : anotherType
          ? TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS
          : TransportationActivityViewDTO.TypeEnum.PICKUPFULL
      case TransportationActivityViewDTO.StageEnum.RETURN:
        return TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
      default:
        return
    }
  }
}
