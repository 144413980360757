import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { getListsState } from '../../../store'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { isEntityModified } from '../../functions/test/isEntityModified'
import { requestUpdateSellSideQuote } from './epics'
import { requestUpdateBuySideQuote } from '../buySideQuote/epics'

export const saveSsqBsqQuotes = (expandedItemContext: TExpandedItemContext) => {
  const { parentItem, modifiedLists, setFetching, deleteModifiedListItems } = expandedItemContext
  const store = getListsState()
  const dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO = parentItem
  const sellSideQuoteId = oc(dispatchDeliveryOrder).sellSideQuoteId()
  const modifiedSellSideQuote = oc(modifiedLists)[EntityType.sellSideQuote][sellSideQuoteId]()
  const modifiedBuySideQuotes = oc(dispatchDeliveryOrder)
    .buySideQuoteIds([])
    .map(id => {
      return oc(modifiedLists)[EntityType.buySideQuote][id]()
    })
    .filter(Boolean)
    .filter(bsq =>
      isEntityModified({
        entityType: EntityType.buySideQuote,
        modifiedEntity: bsq,
        storeEntity: oc(store)[EntityType.buySideQuote][bsq.id]()
      })
    )
  const condition =
    testEntityVersion(store, EntityType.sellSideQuote)(modifiedSellSideQuote) &&
    modifiedBuySideQuotes.every(testEntityVersion(store, EntityType.buySideQuote))

  const save = async () => {
    try {
      if (
        modifiedSellSideQuote &&
        isEntityModified({
          entityType: EntityType.sellSideQuote,
          modifiedEntity: modifiedSellSideQuote,
          storeEntity: oc(store)[EntityType.sellSideQuote][sellSideQuoteId]()
        })
      ) {
        await requestUpdateSellSideQuote(modifiedSellSideQuote)
        deleteModifiedListItems({ [EntityType.sellSideQuote]: [modifiedSellSideQuote.id] })
      }

      if (modifiedBuySideQuotes.length) {
        for (const modifiedBuySideQuote of modifiedBuySideQuotes) {
          const savedBuySideQuote = await requestUpdateBuySideQuote(modifiedBuySideQuote)
          deleteModifiedListItems({ [EntityType.buySideQuote]: [modifiedBuySideQuote.id] })
        }
      }

      deleteModifiedListItems({
        [EntityType.buySideQuote]: oc(dispatchDeliveryOrder).buySideQuoteIds([]),
        [EntityType.sellSideQuote]: [sellSideQuoteId]
      })
    } catch (e) {}
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: () => {}
  })
}
