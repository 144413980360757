import * as React from 'react'
import { ContactsTab } from '../../../../contact/views/ContactsTab'
import { useGridItemLocation } from '../../../../../../hooks/useGridItemLocation'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

type Props = {}

export const Contacts = (props: Props) => {
  const { location } = useGridItemLocation()

  return <ContactsTab parentEntity={location} entityType={EntityType.location} />
}
