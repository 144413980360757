import * as React from 'react'
import { UserDTO } from '../api/origin/user-service'

export interface ICommunicationHubSuggestionsContext {
  contacts: UserDTO[]
}

export const CommunicationHubSuggestionsContext = React.createContext<ICommunicationHubSuggestionsContext>({
  contacts: null
})
