import * as React from 'react'
import { IAttachment } from '../services/filesService/interfaces'

export interface IUploadingFiles {
  [steamId: string]: {
    [fieldId: string]: IAttachment | IAttachment[]
  }
}

export interface IUploadingFilesContext {
  uploadingFiles: IUploadingFiles
  setUploadingFiles(steamId: string, fieldId: string, uploadingFiles: IAttachment | IAttachment[]): void
  clearUploadingFiles(steamId: string, fieldId: string): void
}

export const UploadingFilesContext = React.createContext<IUploadingFilesContext>({
  uploadingFiles: undefined,
  setUploadingFiles: undefined,
  clearUploadingFiles: undefined
})
