import { DOCUMENT_SERVICE_API_BASE_PATH } from '../../api/document-service'
import { getDefaultHeaders } from '../../api/defaultHeaders'
import axios from 'axios'

const axiosIstance = axios.create({
  baseURL: DOCUMENT_SERVICE_API_BASE_PATH
})

axiosIstance.interceptors.request.use(
  _config => {
    if (_config && _config.headers) {
      const defaultHeaders = getDefaultHeaders()
      Object.keys(defaultHeaders).map(key => {
        _config.headers[key] = defaultHeaders[key]
      })
    }

    return _config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosIstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // Do something with response error
    return Promise.reject(error.response)
  }
)

export default axiosIstance
