import * as React from 'react'
import cn from 'classnames'
import { DropdownItem, DropdownItemValue } from './styles'
import { Checkbox } from '../../Radio'
import { IDropdownItem } from './Dropdown'

type Props = IDropdownItem & {
  multiselect?: boolean
  isSelected?: boolean
  onClick: () => void
}

export const ListItem = (props: Props) => {
  const { label, htmlLabel, hint, onClick, multiselect, isSelected, disabled, onMouseOver, onMouseOut } = props
  const hintProps = React.useMemo(
    () =>
      hint
        ? {
            'data-for': 'select-hint',
            'data-tip': hint
          }
        : {},
    [hint]
  )

  const RenderListItem = () => (
    <DropdownItem onMouseOver={onMouseOver} onMouseOut={onMouseOut} {...hintProps}>
      <DropdownItemValue className={cn({ disabled })} onClick={!disabled ? onClick : undefined}>
        {multiselect && <Checkbox style={{ marginRight: 8, fontSize: 22 }} active={isSelected} />}
        {htmlLabel || label}
      </DropdownItemValue>
    </DropdownItem>
  )

  return <RenderListItem />
}
