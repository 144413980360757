import { RootState } from '..'
import { IMoveDTO } from '../reducers/communicationHub/interfaces'

// SELECT ONE
export const selectMove = (id: string) => (state: RootState): IMoveDTO | undefined => {
  return state.lists.move[id]
}

// SELECT MANY
export const selectMoves = (state: RootState): Record<string, IMoveDTO> => {
  return state.lists.move
}
