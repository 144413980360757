import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { SsqBsqWidgetColumnHeader } from './SsqBsqWidgetColumnHeader'
import { SsqBsqWidgetMainRates } from './SsqBsqWidgetMainRates'
import { requestSellSideQuoteRates } from '../../../../../services/DTO/rate/epics'
import { SsqBsqWidgetAdditionalSurcharges } from './SsqBsqWidgetAdditionalSurcharges'
import { SsqBsqWidgetApplicableRates } from './SsqBsqWidgetApplicableRates'
import { RateDTO } from '../../../../../services/DTO/rate/interfaces'
import { sortSurchargesByActivityNumber } from '../../../../../services/functions/sort/sortSurchargesByActivityNumber'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'

export const SsqWidgetColumn = React.memo(() => {
  const {
    quoteAmounts: { profit, sellSideQuoteTotalAmount },
    sellSideQuote,
    requestFilters,
    unsuitableSurchargeTypes,
    businessActivities,
    onOpenRatesClick,
    modifyQuote
  } = useSsqBsqWidget()
  const [applicableRates, setApplicableRates] = React.useState<RateDTO[]>([])

  React.useEffect(() => {
    if (sellSideQuote.surcharges.length) {
      requestSellSideQuoteRates({ filters: { id: sellSideQuote.surcharges.map(({ rateId }) => rateId) } })
    }

    requestSellSideQuoteRates({
      filters: { ...requestFilters, quoteDate: sellSideQuote.date },
      omitRateTypes: unsuitableSurchargeTypes
    }).then(items => {
      if (items.length) {
        setApplicableRates(items)
      }
    })
  }, [])
  const SsqActions = modifyQuote(EntityType.sellSideQuote, sellSideQuote)

  return (
    <Container>
      <SsqBsqWidgetColumnHeader quoteType={'SSQ'} profit={profit} total={sellSideQuoteTotalAmount} />
      <SsqBsqWidgetMainRates surcharges={sellSideQuote.surcharges} />
      <SsqBsqWidgetAdditionalSurcharges
        miscSurcharges={sortSurchargesByActivityNumber(sellSideQuote.miscSurcharges, businessActivities)}
        addMiscSurcharge={SsqActions.addMiscSurcharge}
        deleteMiscSurchargeId={SsqActions.deleteMiscSurchargeId}
        modifyMiscSurcharge={SsqActions.modifyMiscSurcharge}
      />
      <SsqBsqWidgetApplicableRates
        onOpenRatesClick={onOpenRatesClick(oc(sellSideQuote).date())}
        surcharges={sortSurchargesByActivityNumber(sellSideQuote.surcharges, businessActivities)}
        applicableRates={applicableRates}
        onlyOneSurchargeForRate={false}
        addSurcharge={SsqActions.addSurcharge}
        deleteSurchargeId={SsqActions.deleteSurchargeId}
        modifySurcharge={SsqActions.modifySurcharge}
      />
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
