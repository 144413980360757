import styled from 'styled-components'
import { theme } from '../../../../../../../../../../styles/theme'

export const StyledColumnInfo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 52px;
  border-top: 1px solid ${theme.colors.darkBorderColor};
  padding: 0 40px 0 8px;
`
export const ColumnInfoAmount = styled.div`
  min-width: 52px;
  font-weight: 500;
  font-size: 14px;
  color: #445366;
  margin-left: 40px;
`
export const ColumnInfoDate = styled.div`
  font-size: 14px;
  color: #445366;
`
