import { oc } from 'ts-optchain'
import { TransportationActivityViewDTO } from '../../../api/api'
import {
  isDropBobtailGotoActivity,
  isPickBobtailGotoActivity,
  isBobtailGotoActivity
} from '../test/isBobtailGotoActivity'
import { getGroupedActivitiesByGroupId } from './getGroupedActivitiesByGroupId'
import { isDropActivity } from '../test/isDropActivity'
import { isPickActivity } from '../test/isPickActivity'
import { TransportationActivityGroup } from '../../DTO/activity/interfaces'

type Props = {
  activity: TransportationActivityViewDTO
  activities: TransportationActivityViewDTO[]
  includeParentActivityGroup?: boolean
}

export const getActivityDropBobtailGroup = (props: Props): TransportationActivityGroup[] => {
  const { activity, activities, includeParentActivityGroup } = props

  if (!(activity && Array.isArray(activities))) {
    return []
  }

  let bobtailGroupType: 'drop' | 'pick' = 'drop'
  let parentActivityGroup: TransportationActivityGroup = undefined
  const activityBobtails: TransportationActivityGroup[] = []
  const groupedActivitiesByGroupId = getGroupedActivitiesByGroupId(activities) as TransportationActivityGroup[]
  const targetActivityGroupIndex = groupedActivitiesByGroupId.findIndex(_group => {
    return oc(_group).gotoActivity.id() === activity.id || oc(_group).businessActivity.id() === activity.id
  })
  const targetActivityGroup =
    targetActivityGroupIndex !== -1 ? groupedActivitiesByGroupId[targetActivityGroupIndex] : undefined

  if (!targetActivityGroup) {
    return []
  }

  if (isDropBobtailGotoActivity(targetActivityGroup.gotoActivity)) {
    bobtailGroupType = 'drop'
    let index = targetActivityGroupIndex

    while (!parentActivityGroup && index >= 0) {
      const prevGroup = groupedActivitiesByGroupId[index - 1]

      if (prevGroup && isDropActivity(prevGroup.gotoActivity)) {
        parentActivityGroup = prevGroup
      }

      index--
    }
  } else if (isDropActivity(targetActivityGroup.gotoActivity)) {
    bobtailGroupType = 'drop'
    parentActivityGroup = targetActivityGroup
  }

  if (!(parentActivityGroup && bobtailGroupType)) {
    return []
  }

  const isBobtailActivity =
    bobtailGroupType === 'drop'
      ? isDropBobtailGotoActivity
      : bobtailGroupType === 'pick'
      ? isPickBobtailGotoActivity
      : isBobtailGotoActivity
  let groupIndex = 0
  let group = groupedActivitiesByGroupId[groupIndex]

  const getNextGroup = () => {
    groupIndex =
      bobtailGroupType === 'drop' ? groupIndex + 1 : bobtailGroupType === 'pick' ? groupIndex - 1 : groupIndex
    group = groupedActivitiesByGroupId[groupIndex]
  }

  groupIndex = groupedActivitiesByGroupId.findIndex(_group => {
    if (
      oc(_group).gotoActivity.id() === parentActivityGroup.gotoActivity.id ||
      oc(_group).businessActivity.id() === parentActivityGroup.businessActivity.id
    ) {
      return true
    }
  })

  getNextGroup()

  while (group && 'gotoActivity' in group && isBobtailActivity(group.gotoActivity)) {
    activityBobtails.push({ gotoActivity: group.gotoActivity, businessActivity: group.businessActivity })
    getNextGroup()
  }

  if (parentActivityGroup && includeParentActivityGroup) {
    if (bobtailGroupType === 'drop') {
      activityBobtails.unshift(parentActivityGroup)
    } else if (bobtailGroupType === 'pick') {
      activityBobtails.push(parentActivityGroup)
    }
  }

  return activityBobtails
}
