import { oc } from 'ts-optchain'
import {
  DocumentationActivityDTO,
  OngoingActivityDTO,
  TransportationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/api'

export const isGotoActivity = (
  activity: DocumentationActivityDTO | TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  // @ts-ignore
  return isGotoActivityType(oc(activity).type())
}

export const isGotoActivityType = (type: TransportationActivityViewDTO.TypeEnum): boolean => {
  return [TransportationActivityViewDTO.TypeEnum.GOTO, TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO].includes(
    type
  )
}
