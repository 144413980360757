import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { EquipmentDTO } from '../../api/api'
import { isNewObject } from '../DTO'
import { requestUpdateEquipment } from '../../components/common/equipment/epics'

type Props = {
  equipment: EquipmentDTO
}

export const saveEquipment = async (props: Props): Promise<EquipmentDTO> => {
  const { equipment } = props
  let savedEqupment: EquipmentDTO = equipment

  if (equipment) {
    savedEqupment = await requestUpdateEquipment({
      ...equipment,
      maintenanceInspections: oc(equipment)
        .maintenanceInspections([])
        .map(item => (isNewObject(item) ? R.omit(item, ['id']) : item)),
      repairs: oc(equipment)
        .repairs([])
        .map(item => (isNewObject(item) ? R.omit(item, ['id']) : item))
    })
  }

  return savedEqupment
}
