import * as React from 'react'
import { ContactsPreviewTab } from './ContactsPreviewTab'
import { ContactsTabProvider } from '../../../../providers/ContactsTabProvider'
import { BusinessPartnerViewDTO, LocationViewDTO } from '../../../../api/api'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

type Props = {
  parentEntity: LocationViewDTO | BusinessPartnerViewDTO
  entityType: EntityType.businessPartner | EntityType.location
}

export const ContactsTab = (props: Props) => {
  const { parentEntity, entityType } = props

  return (
    <ContactsTabProvider parentEntity={parentEntity} entityType={entityType}>
      <ContactsPreviewTab />
    </ContactsTabProvider>
  )
}
