import { oc } from 'ts-optchain'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { TransportationActivityViewDTO } from '../../../api/api'

export const sortSurchargesByActivityNumber = (
  surcharges: any[],
  transportationActivities: TransportationActivityViewDTO[]
): any[] => {
  if (!surcharges || !surcharges.length) {
    return surcharges
  }

  const activitiesNumberMapping: Record<string, number> = oc(transportationActivities)([]).reduce(
    (acc: Record<string, number>, curr: TransportationActivityViewDTO) => {
      acc[curr.id] = curr.number
      return acc
    },
    {}
  )

  return surcharges.slice().sort((a, b) => {
    const numA = activitiesNumberMapping[a.activityId] || 999
    const numB = activitiesNumberMapping[b.activityId] || 999

    return numA - numB
  })
}
