/**
 * Created by
 * User: Sameer Shemna
 * Website: www.sameershemna.com
 * Email: sameersemna@gmail.com
 * Date: 16/9/14
 * Time: 6:39 PM
 * Ref for similar PHP Class: https://github.com/stormpat/Container-validator
 */

export class ContainerValidator {
  /**
   * @functions list
   *
   * isValid()
   * validate()
   * getErrorMessages()
   * getOwnerCode()
   * getProductGroupCode()
   * getRegistrationDigit()
   * getCheckDigit()
   * generate()
   * createCheckDigit()
   * clearErrors()
   * buildCheckDigit()
   * identify()
   *
   */

  // SHS Constants addition
  STR_PAD_LEFT = 'STR_PAD_LEFT'

  alphabetNumerical = {
    A: 10,
    B: 12,
    C: 13,
    D: 14,
    E: 15,
    F: 16,
    G: 17,
    H: 18,
    I: 19,
    J: 20,
    K: 21,
    L: 23,
    M: 24,
    N: 25,
    O: 26,
    P: 27,
    Q: 28,
    R: 29,
    S: 30,
    T: 31,
    U: 32,
    V: 34,
    W: 35,
    X: 36,
    Y: 37,
    Z: 38
  }
  pattern = /^([A-Z]{3})(U|J|Z)(\d{6})(\d)$/
  patternWithoutCheckDigit = /^([A-Z]{3})(U|J|Z)(\d{6})$/
  errorMessages: any = []
  ownerCode: any = []
  productGroupCode: any = null
  registrationDigit: any = []
  checkDigit: number = null
  containerNumber: string = null

  /**
   * Check if the container has a valid container code
   *
   * @return boolean
   */
  isValid(containerNumber: any) {
    this.validate(containerNumber)
    if (!this.errorMessages || !this.errorMessages.length) {
      return true
    }
    return false
  }

  validate(containerNumber: any) {
    let matches: any = []

    if (!this.empty(containerNumber) && this.is_string(containerNumber)) {
      matches = this.identify(containerNumber)

      if (this.count(matches) !== 5) {
        this.errorMessages.push('The container number is invalid')
      } else {
        const checkDigit = this.buildCheckDigit(matches)
        if (Number(this.checkDigit) !== checkDigit) {
          this.errorMessages.push('The check digit does not match')
          matches = []
        }
      }
    } else {
      this.errorMessages = { 0: 'The container number must be a string' }
    }
    return matches
  }

  getErrorMessages = () => this.errorMessages

  getOwnerCode() {
    if (this.empty(this.ownerCode)) {
      this.errorMessages.push('You must call validate or isValid first')
    }
    return this.ownerCode
  }

  getProductGroupCode() {
    if (this.empty(this.productGroupCode)) {
      this.errorMessages.push('You must call validate or isValid first')
    }
    return this.productGroupCode
  }

  getRegistrationDigit() {
    if (this.empty(this.registrationDigit)) {
      this.errorMessages.push('You must call validate or isValid first')
    }
    return this.registrationDigit
  }

  getCheckDigit() {
    if (this.empty(this.checkDigit)) {
      this.errorMessages.push('You must call validate or isValid first')
    }
    return this.checkDigit
  }

  generate(ownerCode: any, productGroupCode: any, from: any, to: any) {
    // SHS set default values for params
    from = typeof from !== 'undefined' ? from : 0
    to = typeof to !== 'undefined' ? to : 999999

    const alphabetCode = this.strtoupper(ownerCode + productGroupCode)
    let containers_no = []

    if (this.is_string(alphabetCode) && this.strlen(ownerCode) === 3 && this.strlen(productGroupCode) === 1) {
      containers_no = []
      let current_container_no = ''
      let current_container_check_digit = null

      if (from >= 0 && to < 1000000 && to - from > 0) {
        for (let i = from; i <= to; i++) {
          current_container_no = alphabetCode + this.str_pad(i, 6, '0', this.STR_PAD_LEFT)
          current_container_check_digit = this.createCheckDigit(current_container_no)

          if (current_container_check_digit < 0) {
            this.errorMessages.push('Error generating container number at number ' + i)
            return containers_no
          }

          containers_no[i] = current_container_no + current_container_check_digit
        }
      } else {
        this.errorMessages.push('Invalid number to generate, minimal is 0 and maximal is 999999')
      }
    } else {
      this.errorMessages.push('Invalid owner code or product group code')
    }

    return containers_no
  }

  createCheckDigit(containerNumber: any) {
    let checkDigit = -1
    if (!this.empty(containerNumber) && this.is_string(containerNumber)) {
      const matches = this.identify(containerNumber, true)

      if (this.count(matches) !== 4 || matches[4]) {
        this.errorMessages.push('Invalid container number')
      } else {
        checkDigit = this.buildCheckDigit(matches)
        if (checkDigit < 0) {
          this.errorMessages.push('Invalid container number')
        }
      }
    } else {
      this.errorMessages.push('Container number must be a string')
    }
    return checkDigit
  }

  clearErrors() {
    this.errorMessages = []
  }

  buildCheckDigit = (matches: any) => {
    if (matches[1]) {
      this.ownerCode = this.str_split(matches[1])
    }
    if (matches[2]) {
      this.productGroupCode = matches[2]
    }
    if (matches[3]) {
      this.registrationDigit = this.str_split(matches[3])
    }
    if (matches[4]) {
      this.checkDigit = matches[4]
    }

    // convert owner code + product group code to its numerical value
    const numericalOwnerCode = []
    for (let i = 0; i < this.count(this.ownerCode); i++) {
      numericalOwnerCode[i] = this.alphabetNumerical[this.ownerCode[i]]
    }
    numericalOwnerCode.push(this.alphabetNumerical[this.productGroupCode])

    // merge numerical owner code with registration digit
    const numericalCode = this.array_merge(numericalOwnerCode, this.registrationDigit)
    let sumDigit = 0

    // check six-digit registration number and last check digit
    for (let i = 0; i < this.count(numericalCode); i++) {
      sumDigit += numericalCode[i] * Math.pow(2, i)
    }

    const sumDigitDiff = Math.floor(sumDigit / 11) * 11
    const checkDigit = sumDigit - sumDigitDiff
    return checkDigit === 10 ? 0 : checkDigit
  }

  identify(containerNumber: any, withoutCheckDigit?: any) {
    // SHS set default values for params
    withoutCheckDigit = typeof withoutCheckDigit !== 'undefined' ? withoutCheckDigit : false

    this.clearErrors()
    return withoutCheckDigit
      ? this.preg_match(this.patternWithoutCheckDigit, this.strtoupper(containerNumber))
      : this.preg_match(this.pattern, this.strtoupper(containerNumber))
  }

  // SHS Helper functions
  is_string = (param: any) => (typeof param === 'string' ? true : false)

  preg_match = (pattern: any, el: any) => new RegExp(pattern).exec(el)

  strtoupper = (el: any) => el.toUpperCase()

  count = (array: any) => {
    if (array == null) {
      return 0
    } else {
      return array.length
    }
  }

  strlen = (el: any) => el.length

  // PHPJS Helper functions    Ref: http://phpjs.org          Remove if using PHPJS
  str_split = (el: any, split_length?: any) => {
    //  discuss at: http://phpjs.org/functions/str_split/
    // original by: Martijn Wieringa
    // improved by: Brett Zamir (http://brett-zamir.me)
    // bugfixed by: Onno Marsman
    //  revised by: Theriault
    //  revised by: Rafal Kukawski (http://blog.kukawski.pl/)
    //    input by: Bjorn Roesbeke (http://www.bjornroesbeke.be/)
    //   example 1: str_split('Hello Friend', 3);
    //   returns 1: ['Hel', 'lo ', 'Fri', 'end']

    if (split_length == null) {
      split_length = 1
    }
    if (el == null || split_length < 1) {
      return false
    }
    el += ''
    const chunks = [],
      len = el.length
    let pos = 0
    while (pos < len) {
      chunks.push(el.slice(pos, (pos += split_length)))
    }

    return chunks
  }

  str_pad = (input: any, pad_length: any, pad_string: any, pad_type: any) => {
    //  discuss at: http://phpjs.org/functions/str_pad/
    // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // improved by: Michael White (http://getsprink.com)
    //    input by: Marco van Oort
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT');
    //   returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
    //   example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH');
    //   returns 2: '------Kevin van Zonneveld-----'

    let half = '',
      pad_to_go

    const str_pad_repeater = (s: any, len: any) => {
      let collect = ''

      while (collect.length < len) {
        collect += s
      }
      collect = collect.substr(0, len)

      return collect
    }

    input += ''
    pad_string = pad_string !== undefined ? pad_string : ' '

    if (pad_type !== 'STR_PAD_LEFT' && pad_type !== 'STR_PAD_RIGHT' && pad_type !== 'STR_PAD_BOTH') {
      pad_type = 'STR_PAD_RIGHT'
    }
    pad_to_go = pad_length - input.length
    if (pad_to_go > 0) {
      if (pad_type === 'STR_PAD_LEFT') {
        input = str_pad_repeater(pad_string, pad_to_go) + input
      } else if (pad_type === 'STR_PAD_RIGHT') {
        input = input + str_pad_repeater(pad_string, pad_to_go)
      } else if (pad_type === 'STR_PAD_BOTH') {
        half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2))
        input = half + input + half
        input = input.substr(0, pad_length)
      }
    }

    return input
  }

  array_merge(a: any, b: any) {
    //  discuss at: http://phpjs.org/functions/array_merge/
    // original by: Brett Zamir (http://brett-zamir.me)
    // bugfixed by: Nate
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //    input by: josh
    //   example 1: arr1 = {"color": "red", 0: 2, 1: 4}
    //   example 1: arr2 = {0: "a", 1: "b", "color": "green", "shape": "trapezoid", 2: 4}
    //   example 1: array_merge(arr1, arr2)
    //   returns 1: {"color": "green", 0: 2, 1: 4, 2: "a", 3: "b", "shape": "trapezoid", 4: 4}
    //   example 2: arr1 = []
    //   example 2: arr2 = {1: "data"}
    //   example 2: array_merge(arr1, arr2)
    //   returns 2: {0: "data"}

    const args = Array.prototype.slice.call(arguments),
      argl = args.length,
      retObj = {},
      toStr = Object.prototype.toString

    let arg,
      retArr: any = true

    for (let i = 0; i < argl; i++) {
      if (toStr.call(args[i]) !== '[object Array]') {
        retArr = false
        break
      }
    }

    if (retArr) {
      retArr = []
      for (let i = 0; i < argl; i++) {
        retArr = retArr.concat(args[i])
      }
      return retArr
    }

    for (let i = 0, ct = 0; i < argl; i++) {
      arg = args[i]
      if (toStr.call(arg) === '[object Array]') {
        for (let j = 0, argil = arg.length; j < argil; j++) {
          retObj[ct++] = arg[j]
        }
      } else {
        for (const k in arg) {
          if (arg.hasOwnProperty(k)) {
            if (parseInt(k, 10) + '' === k) {
              retObj[ct++] = arg[k]
            } else {
              retObj[k] = arg[k]
            }
          }
        }
      }
    }
    return retObj
  }

  empty = (mixed_var: string[] | number) => {
    //  discuss at: http://phpjs.org/functions/empty/
    // original by: Philippe Baumann
    //    input by: Onno Marsman
    //    input by: LH
    //    input by: Stoyan Kyosev (http://www.svest.org/)
    // bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // improved by: Onno Marsman
    // improved by: Francesco
    // improved by: Marc Jansen
    // improved by: Rafal Kukawski
    //   example 1: empty(null);
    //   returns 1: true
    //   example 2: empty(undefined);
    //   returns 2: true
    //   example 3: empty([]);
    //   returns 3: true
    //   example 4: empty({});
    //   returns 4: true
    //   example 5: empty({'aFunc' : function () { alert('humpty'); } });
    //   returns 5: false
    const undef: any = null
    const emptyValues = [undef, null, false, 0, '', '0']

    for (let i = 0, len = emptyValues.length; i < len; i++) {
      if (mixed_var === emptyValues[i]) {
        return true
      }
    }

    if (typeof mixed_var === 'object') {
      // for (const key in mixed_var) {
      // if (mixed_var.hasOwnProperty(key)) {
      return false
      // }
      // }
      // return true
    }

    return false
  }
}
