import { RootState } from '..'
import { DriverViewDTO } from '../../api/api'

// SELECT ONE
export const selectDriver = (userAuthOrDriverId: string) => (state: RootState): DriverViewDTO | undefined => {
  const {
    lists: { driver, driverIdByAuthId }
  } = state

  if (driver[userAuthOrDriverId]) {
    return driver[userAuthOrDriverId]
  }

  const driverId = driverIdByAuthId[userAuthOrDriverId]

  return driverId && driver[driverId]
}

export const selectDriverName = (userAuthOrDriverId: string) => (state: RootState): string | undefined => {
  const {
    lists: { driver, driverIdByAuthId }
  } = state

  if (driver[userAuthOrDriverId]) {
    return driver[userAuthOrDriverId].name
  }

  const driverId = driverIdByAuthId[userAuthOrDriverId]

  return driverId && driver[driverId] && driver[driverId].name
}

// SELECT MANY
export const selectDrivers = (state: RootState): Record<string, DriverViewDTO> => {
  return state.lists.driver
}
