import * as React from 'react'
import { StyledFilter, StyledFieldContainer, StyledToggle, ExtraFilterFieldTitle } from '../../Grid/styles'
import { LocationSearch } from '../../../../../UI/DataFields/SearchRequest/location'
import { Select } from '../../../../../UI/DataFields/Select'
import { CustomerQuoteFieldType, ICustomerQuoterFilterState, RateField } from '../../../interfaces'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import styled from 'styled-components'
import { deliveryOrderLists } from '../../../../../../services/select/deliveryOrderLists'
import { quoteLists } from '../../../../../../services/select/quoteLists'
import { containerLists } from '../../../../../../services/select/containerLists'
// tslint:disable:max-line-length
import { LocationSearch as DeliveryLocationSearch } from '../../../../../UI/DataFields/SearchRequest/location/zip'
import { connect } from 'react-redux'
import { IStore } from '../../../../../../store/store.interface'
import { highlightRequiredFieldsToCreateCustomerQuote } from './functions'
import { createCustomerQuote } from '../../../../../../services/DTO/customerQuote/reducer'
import { Toggle } from '../../../../../UI/Toggle'
import { removeExtraZero } from '../../functions'
import { defaultFiltersOnCustomerQuoteCreation } from '../../../../../../services/DTO/qmp/defaultTabData'
import { ResetButton, resetObjectValues } from '../../Grid/Filter/resetButton'
import { InputField } from '../../../../../UI/DataFields/Input'
import { oc } from 'ts-optchain'
import { requestCustomersByTerm } from '../../../../customer/epics'
import { SelecterWidget } from '../../../../../UI/DataFields/Select/SelecterWidget'
import { requestDriversByTerm } from '../../../../drivers/epics'
import { CustomerQuoteDTO, SellSideQuoteRateDTO } from '../../../../../../api/origin/qmp-service'
import { DriverNameDTO } from '../../../../../../api/origin/business-logic'
import { useQMP } from '../../../../../../hooks/useQMP'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectLocation } from '../../../../../../store/select/locationSelect'

type OwnProps = {
  isModifyMode: boolean
  filterState: ICustomerQuoterFilterState
  setFiltersState: (filters: ICustomerQuoterFilterState) => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Filter = (props: Props) => {
  const { isModifyMode, filterState, setFiltersState } = props
  const { cancelCustomerQuoteEditing, setNewCustomerQuotes } = useQMP()
  const pickupLocation = useAppSelector(selectLocation(filterState.pickupLocationId))
  const returnLocation = useAppSelector(selectLocation(filterState.returnLocationId))
  const setFieldValue = (field: CustomerQuoteFieldType) => (value: any) =>
    setFiltersState({ ...filterState, [RateField.number]: null, [field]: (value && value.id) || value })
  const setNumberField = (value: string) => {
    const val = removeExtraZero(value)
    if (val !== '') {
      setFiltersState({
        ...resetObjectValues(filterState),
        [CustomerQuoteFieldType.number]: value,
        status: [CustomerQuoteDTO.StatusEnum.NEW, CustomerQuoteDTO.StatusEnum.EXPIRED]
      })
    }
  }
  const { onMouseOver, onMouseOut } = highlightRequiredFieldsToCreateCustomerQuote(filterState)
  const customer = filterState[CustomerQuoteFieldType.customerId]
  const vendor = filterState[CustomerQuoteFieldType.vendorId]
  const ddoTypeIsRepo = oc(filterState)
    .deliveryOrderType([])
    .includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION)

  return (
    <StyledFilter className={'special-fields-container'}>
      <StyledFieldContainer id={'number'} style={{ width: 100 }}>
        <InputField.Numeric
          title={filterState.number ? 'Number' : null}
          value={filterState.number}
          onChange={setNumberField}
          placeholder={'Number'}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'customerId'} style={{ width: 220 }}>
        <SelecterWidget
          title={'Customer'}
          placeholder={'Customer'}
          label={customer ? undefined : 'All Customers'}
          selectedItems={Array.isArray(customer) ? customer : null}
          staticList={[
            {
              label: 'All Customers',
              selecterValue: null,
              isSelected: customer === null
            }
          ]}
          getAllItems={() => requestCustomersByTerm('')}
          onSelect={setFieldValue(CustomerQuoteFieldType.customerId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 150, zIndex: 5 }}>
        <SelecterWidget
          title={'Driver'}
          placeholder={'Driver'}
          label={Array.isArray(vendor) ? undefined : vendor === '%%' ? 'TFF' : 'All Drivers'}
          selectedItems={Array.isArray(vendor) ? vendor : null}
          staticList={[
            {
              label: 'All Drivers',
              selecterValue: null,
              isSelected: vendor === null
            },
            {
              label: 'TFF',
              selecterValue: '%%',
              isSelected: vendor === '%%'
            }
          ]}
          getAllItems={() => requestDriversByTerm('', _ => _.driverType !== DriverNameDTO.DriverTypeEnum.VENDOR)}
          onSelect={setFieldValue(CustomerQuoteFieldType.vendorId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'deliveryOrderType'} style={{ width: 130 }}>
        <Select
          title={filterState.deliveryOrderType ? 'IMP/EXP/REPO' : null}
          placeholder={'IMP/EXP/REPO'}
          buttonClear={true}
          multiselect={true}
          mutuallyExclusive={[
            [
              CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION.toString(),
              CustomerQuoteDTO.DeliveryOrderTypeEnum.IMPORT.toString()
            ],
            [
              CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION.toString(),
              CustomerQuoteDTO.DeliveryOrderTypeEnum.EXPORT.toString()
            ]
          ]}
          selectedValue={filterState.deliveryOrderType}
          list={deliveryOrderLists.type}
          onSelect={deliveryOrderType => {
            if (oc(deliveryOrderType)([]).includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION)) {
              return setFiltersState({
                ...filterState,
                [RateField.number]: null,
                deliveryOrderType,
                deliveryLocation: null,
                loadType: null
              })
            }

            setFieldValue(CustomerQuoteFieldType.deliveryOrderType)(deliveryOrderType)
          }}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'pickupLocationId'} style={{ width: 220 }}>
        <LocationSearch
          title={pickupLocation ? 'Pickup' : null}
          placeholder={'Pickup'}
          isShortName={true}
          value={pickupLocation}
          onChange={setFieldValue(CustomerQuoteFieldType.pickupLocationId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'deliveryLocation'} style={{ width: 150 }}>
        <DeliveryLocationSearch
          disabled={ddoTypeIsRepo}
          title={filterState.deliveryLocation || ddoTypeIsRepo ? 'Delivery' : null}
          placeholder={'Delivery'}
          value={filterState.deliveryLocation}
          onChange={setFieldValue(CustomerQuoteFieldType.deliveryLocation)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'returnLocation'} style={{ width: 220 }}>
        <LocationSearch
          title={returnLocation ? 'Return' : null}
          placeholder={'Return'}
          isShortName={true}
          value={returnLocation}
          onChange={setFieldValue(CustomerQuoteFieldType.returnLocationId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'containerTypeId'} style={{ width: 150 }}>
        <Select
          title={filterState.containerTypeId ? 'Container type' : null}
          placeholder={'Container type'}
          buttonClear={true}
          multiselect={true}
          dropdownStyle={{ minWidth: 260 }}
          selectedValue={filterState.containerTypeId}
          list={containerLists.containerTypeWithDescription}
          onSelect={setFieldValue(CustomerQuoteFieldType.containerTypeId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'loadType'} style={{ width: 130 }}>
        <Select
          disabled={ddoTypeIsRepo}
          title={filterState.loadType ? 'Load Type' : null}
          placeholder={'Load Type'}
          buttonClear={true}
          selectedValue={oc(filterState).loadType[0](null)}
          list={quoteLists.loadType}
          onSelect={value => {
            return setFieldValue(CustomerQuoteFieldType.loadType)(value ? [value] : null)
          }}
        />
      </StyledFieldContainer>
      <StyledToggle>
        <span>Expired quotes</span>
        <Toggle
          checked={filterState.status.includes(SellSideQuoteRateDTO.StatusEnum.EXPIRED)}
          onChange={(state: boolean) =>
            setFieldValue(CustomerQuoteFieldType.status)(
              state
                ? [CustomerQuoteDTO.StatusEnum.NEW, CustomerQuoteDTO.StatusEnum.EXPIRED]
                : [CustomerQuoteDTO.StatusEnum.NEW]
            )
          }
        />
      </StyledToggle>

      <ResetButton filters={filterState} changeFilters={setFiltersState} />

      <ButtonContainer>
        {isModifyMode ? (
          <ActionButton
            styles={{ zIndex: 3, background: '#FF5E5E', color: 'white', borderColor: '#FF5E5E' }}
            className={'mdi mdi-close-circle'}
            onClick={cancelCustomerQuoteEditing}
          >
            Cancel
          </ActionButton>
        ) : (
          <ActionButton
            disabled={Boolean(onMouseOver)}
            filled={true}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            className={'mdi mdi-plus-circle-outline'}
            onClick={() => {
              let filterProps = filterState

              if (!filterState.deliveryOrderType) {
                const defaultFilters = defaultFiltersOnCustomerQuoteCreation()
                filterProps = {
                  ...filterState,
                  deliveryOrderType: defaultFilters.deliveryOrderType
                }
                setFiltersState(filterProps)
              }

              setNewCustomerQuotes(
                createCustomerQuote({
                  customerId: filterProps.customerId,
                  pickupLocationId: filterProps.pickupLocationId,
                  returnLocationId: filterProps.returnLocationId,
                  deliveryPostalCode: oc(filterProps).deliveryLocation.postalCode(),
                  deliveryStateId: oc(filterProps).deliveryLocation.stateId(),
                  deliveryCity: oc(filterProps).deliveryLocation.city(),
                  deliveryOrderType: filterProps.deliveryOrderType as any,
                  containerTypeId: filterProps.containerTypeId as any,
                  loadType: oc(filterProps)
                    .deliveryOrderType([])
                    .includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION)
                    ? undefined
                    : (filterProps.loadType as any)
                })
              )
            }}
          >
            Add new
          </ActionButton>
        )}
        {customer && customer.length > 1 && (
          <ExtraFilterFieldTitle>Only one customer should be selected to create Customer Quote</ExtraFilterFieldTitle>
        )}
      </ButtonContainer>
    </StyledFilter>
  )
}

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-left: 1px solid #e5e5e5;
  padding-left: 25px;
  margin-left: 25px !important;
`
