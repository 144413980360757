import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { ExpandedItemContext } from '../contexts/ExpandedItemContext'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { tabActions } from '../store/reducers/tabs'
import { EntityType, TListsStateUpdate } from '../store/reducers/lists/interfaces'
import { getListsState } from '../store'
import { useListItem } from '../hooks/useListItem'
import { useAppSelector } from '../hooks/useAppSelector'
import { selectActiveApplicationTab, selectActiveApplicationTabType } from '../store/select/applicationTabSelect'
import { generateTabExpandedItem } from '../services/functions/generate/generateTabExpandedItem'
import { saveObject } from '../services/DTO/saveDTO'
import { entityTypeByTabTypeMapping } from '../constants/entityTypeByTabTypeMapping'
import { isModifiedGridItem } from '../services/functions/test/isModifiedGridItem'

type Props = {
  children?: any
}

export const ExpandedItemProvider = React.memo((props: Props) => {
  const { children } = props
  const mounted = React.useRef(false)
  const dispatch = useAppDispatch()
  const tab = useAppSelector(selectActiveApplicationTab)
  const tabType = useAppSelector(selectActiveApplicationTabType)
  const expandedItem = oc(tab).expandedItem(generateTabExpandedItem())
  const tabId = oc(tab).id()
  const parentItemId = expandedItem.id
  const { initialLists, data, modifiedLists, activeTab } = expandedItem
  const entityType = expandedItem.entityType || entityTypeByTabTypeMapping[tabType]
  const [fetching, _setFetching] = React.useState<string | boolean>(false)
  const parentItem = useListItem({ id: parentItemId, type: entityType, modifiedLists })
  const initialParentItemState = oc(initialLists)[entityType][parentItemId]()
  const enableEditing = oc(tab).permissions.edit()
  const isModified = React.useMemo((): boolean => isModifiedGridItem({ modifiedLists, entityType }), [
    modifiedLists,
    entityType
  ])

  React.useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const setFetching = React.useCallback(
    (state: string | boolean) => {
      if (mounted.current) {
        _setFetching(state)
      }
    },
    [_setFetching]
  )

  const setActiveTab = (changedActiveTab: string) => {
    if (changedActiveTab || !isModified) {
      dispatch(tabActions.setExpandedItemActiveTab({ tabId, activeTab: changedActiveTab }))
    }
  }

  const modifyListItems = (updateList: TListsStateUpdate) => {
    if (enableEditing === false) {
      return
    }

    try {
      const types = Object.keys(updateList) as EntityType[]
      const listState = getListsState()
      const updateItems: {
        entityType: EntityType
        modifiedState: any
        initialState?: any
      }[] = []

      types.forEach(type => {
        const updateTypeItems: any[] = updateList[type]

        if (updateTypeItems && updateTypeItems.length) {
          updateTypeItems.forEach(item => {
            const entityId = item.id
            const hasInitialState = oc(initialLists)[type][entityId]()
            const updatedItem: {
              entityType: EntityType
              modifiedState: any
              initialState?: any
            } = {
              // @ts-ignore
              entityType: type,
              modifiedState: item
            }

            if (!hasInitialState) {
              const initialState = oc(listState)[type][entityId]()

              if (initialState) {
                updatedItem.initialState = R.clone(initialState)
              }
            }

            updateItems.push(updatedItem)
          })
        }
      })

      if (updateItems.length) {
        dispatch(
          tabActions.modifyListItems({
            tabId,
            items: updateItems
          })
        )
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
    }
  }

  const deleteModifiedListItems = (deleteProps: Partial<Record<EntityType, string[]>>) => {
    if (enableEditing === false) {
      return
    }

    const deletePropsKeys = Object.keys(deleteProps) as EntityType[]
    const deleteItems: {
      entityType: EntityType
      id: string
    }[] = []

    deletePropsKeys.forEach(type => {
      const itemIds: string[] = deleteProps[type]

      if (itemIds && itemIds.length) {
        deleteItems.push(
          // @ts-ignore
          ...itemIds.map(id => ({
            entityType: type,
            id
          }))
        )
      }
    })

    if (deleteItems.length) {
      dispatch(
        tabActions.deleteModifiedListItems({
          tabId,
          items: deleteItems
        })
      )
    }
  }

  const modifyParentObject = (modifiedState: any) => {
    modifyListItems({ [entityType]: [modifiedState] })
  }

  const modifyParentObjectField = (field: string) => (value: any) => {
    const modifiedState = { ...parentItem, [field]: value }

    modifyListItems({ [entityType]: [modifiedState] })
  }

  const setData = (updatedData: any) => {
    dispatch(tabActions.setExpandedItemData({ tabId, data: updatedData }))
  }

  const mergeDataProps = (mergeProps: any) => {
    dispatch(tabActions.mergeExpandedItemData({ tabId, mergeProps }))
  }

  const clearExpandedItem = () => {
    dispatch(tabActions.closeExpandedItem({ tabId }))
  }

  const clearExpandedItemModifies = () => {
    dispatch(tabActions.clearExpandedItemModifies({ tabId }))
  }

  const saveUpdatedEntities = (extraSaveData?: any) => {
    if (!enableEditing) {
      return
    }

    setFetching(true)
    setTimeout(() => saveObject[entityType](providerValue, extraSaveData))
  }

  const providerValue = {
    tabId,
    entityType,
    parentItemId,
    parentItem,
    isModified,
    enableEditing,
    initialParentItemState,
    isFetching: Boolean(fetching),
    setFetching,
    data,
    activeTab: activeTab || 'General',
    setActiveTab,
    initialLists,
    modifiedLists,
    modifyListItems,
    deleteModifiedListItems,
    modifyParentObject,
    modifyParentObjectField,
    clearExpandedItem,
    clearExpandedItemModifies,
    setData,
    mergeDataProps,
    saveUpdatedEntities
  }

  return <ExpandedItemContext.Provider value={providerValue} children={children} />
})
