import { locationControllerApi } from '../../../../../api/location'
import { showModal, TMsgType } from '../../../Modal/actions'
import { AlertButtonColor } from '../../../Modal'
import { getStore } from '../../../../../store/configureStore'
import { combineLocations } from '../../../../../services/DTO/location/functions'
import { generateLocation } from '../../../../../services/functions/generate/generateLocation'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'

export const googleSearchLocation = async (term: string, createLocationOnEmptyResult?: boolean): Promise<any[]> => {
  let result = []
  if (term && term.length > 2) {
    result = await locationControllerApi
      .searchLocation(term)
      .then((items: any) => (items ? items.map((_: any) => ({ ..._, googleResult: true })) : items))
      .catch(() => (createLocationOnEmptyResult ? [{ create: true, description: term }] : []))
  }

  return result
}

export const getOwnDatabasePostalCodes = async (term: string) => {
  let result = []
  if (term && term.length > 2) {
    result = await locationControllerApi
      .searchPostalCode(term)
      .then((items: any) =>
        items
          ? items.map(({ name, address: { city, countryCode, postalCode, stateCode } }: any) => ({
              name,
              city,
              countryCode,
              postalCode,
              stateId: stateCode && countryCode ? getAddressState({ codes: { stateCode, countryCode } }).id : null
            }))
          : items
      )
      .catch(() => [])
  }

  return result
}

export const getGoogleSearchLocationDetails = async (
  placeId: string,
  transformToPostalCode?: boolean
): Promise<any> => {
  return locationControllerApi.locationByPlaceId(placeId).then(location => {
    if (transformToPostalCode) {
      const {
        address: { city = null, countryCode = null, postalCode = null, stateCode = null }
      } = location

      if (!postalCode) {
        getStore().dispatch(
          showModal({
            msgType: TMsgType.info,
            buttonSettings: {
              button1: {
                color: AlertButtonColor.blue,
                label: 'Ok',
                action: () => {}
              }
            },
            message: "This location hasn't ZIP"
          })
        )

        return null
      }

      return {
        city,
        countryCode,
        postalCode,
        stateId: stateCode && countryCode ? getAddressState({ codes: { stateCode, countryCode } }).id : null
      }
    } else {
      return combineLocations(generateLocation(), location)
    }
  })
}

export const getPostalCodeDetails = async (
  item: any
): Promise<{
  city: string
  countryCode: string
  postalCode: string
  stateId: string
}> => {
  return item.googleResult ? getGoogleSearchLocationDetails(item.placeId, true) : item
}
