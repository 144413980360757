type Props = {
  elementClassName: string
  extraStyles?: Record<string, string>
  // selectedElement?: Element
}

export const runBlinkAnimation = async (props: Props): Promise<boolean> => {
  const { elementClassName, extraStyles } = props
  const fadeInTime = 300
  const delayTime = 1000
  const fadeOutTime = 1000
  const element = document.getElementsByClassName(elementClassName)[0] as HTMLElement

  if (!element) {
    return false
  }

  element.style.transition = `background ${fadeInTime / 1000}s`
  element.style.background = 'none'
  element.style.backgroundColor = '#dcf7ff'

  if (extraStyles) {
    Object.keys(extraStyles).forEach(key => {
      element.style[key] = extraStyles[key]
    })
  }

  await new Promise(resolve => {
    setTimeout(() => {
      if (element) {
        element.style.backgroundColor = ''
        element.style.transition = `background ${fadeOutTime / 1000}s`
      }

      resolve(true)
    }, fadeInTime + delayTime)
  })

  if (!element) {
    return false
  }

  await new Promise(resolve => {
    setTimeout(() => {
      if (element) {
        element.style.transition = ''
        element.style.background = ''

        if (extraStyles) {
          Object.keys(extraStyles).forEach(key => {
            element.style[key] = ''
          })
        }
      }

      resolve(true)
    }, fadeOutTime)
  })

  return true
}
