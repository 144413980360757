import * as React from 'react'
import { Container, ColumnRow, Column, ColumnRowMini } from '../../../GridSystem'
import { WidgetActions } from '../../styles'
import { CargoDTO, DispatchDeliveryOrderViewDTO, HazmatDTO } from '../../../../../api/origin/business-logic'
import { Input, InputField } from '../../../DataFields/Input'
import { Checkbox } from '../../../Checkbox'
import { Hazmat } from '../../../../common/dispatchDeliveryOrder/views/Details/General/Hazmat'
import WeightUnit from '../../../WeightUnit'
import { CargoType } from './index'
import { createValidator } from '../../../../../services/DTO/deliveryOrder'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { regExpOnlyNumbersAndLetters } from '../../../../../services/functions/regExp'

export const isValidReferenceNumber = createValidator(4, 64)
export const isValidDescription = createValidator(2, 1000)

type Props = {
  disabled: boolean
  reefer: boolean
  cargo: CargoType
  updateCargo: (fieldName: keyof CargoDTO) => (value: string) => void
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  updateDispatchDeliveryOrder: (fieldName: keyof DispatchDeliveryOrderViewDTO) => (value: any) => void
  updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => void
  updateHazmat: (hazmat: HazmatDTO) => void
}

export const Popover = (props: Props) => {
  const {
    disabled,
    reefer,
    cargo,
    updateCargo,
    dispatchDeliveryOrder,
    updateDispatchDeliveryOrder,
    updateDispatchDeliveryOrderMultipleFields,
    updateHazmat
  } = props
  const { closePopover } = React.useContext(WidgetContext)

  return (
    <>
      <Container
        style={{ width: 600 }}
        flexClear={true}
        isGrid={true}
        padding={{ right: 10, left: 10, top: 10, bottom: 20 }}
      >
        <ColumnRow margin={{ bottom: 20 }}>
          <Column columns={7} margin={{ right: 20 }}>
            <Input
              disabled={disabled}
              required={true}
              title={'Reference #'}
              regExp={regExpOnlyNumbersAndLetters}
              valid={isValidReferenceNumber(cargo.referenceNumber)}
              maxLength={64}
              value={cargo.referenceNumber}
              onChange={updateCargo('referenceNumber')}
            />
          </Column>
          <Column columns={5} margin={{ right: 20 }}>
            <WeightUnit
              disabled={disabled}
              title={'Weight'}
              weightUnit={dispatchDeliveryOrder.weightUnit}
              weight={cargo.weight}
              onChange={updateDispatchDeliveryOrderMultipleFields}
            />
          </Column>
          <Column columns={5} alignCenter={true} isFixed={true} padding={{ top: 20 }} margin={{ right: 20 }}>
            <Checkbox
              disabled={disabled}
              checked={cargo.overweight}
              onChange={value => {
                updateDispatchDeliveryOrderMultipleFields({ overweightIndicator: value })
              }}
            />
            <Column disableUserSelect={true} margin={{ left: 5 }}>
              Overweight
            </Column>
          </Column>
          <Column columns={5} isFixed={true}>
            <InputField.Integer
              disabled={disabled}
              title={'Miles'}
              value={dispatchDeliveryOrder.mileage}
              onChange={updateDispatchDeliveryOrder('mileage')}
            />
          </Column>
        </ColumnRow>

        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            required={true}
            title={'Description'}
            valid={isValidDescription(cargo.description)}
            maxLength={1000}
            value={cargo.description}
            onChange={updateCargo('description')}
          />
        </ColumnRow>

        <ColumnRowMini index={1} columns={7} isFixed={true} background={'white'}>
          <Checkbox
            disabled={disabled}
            checked={dispatchDeliveryOrder.autoIndicator}
            onChange={updateDispatchDeliveryOrder('autoIndicator')}
          />
          <Column disableUserSelect={true} margin={{ left: 5 }}>
            Auto
          </Column>
        </ColumnRowMini>

        <ColumnRowMini index={1} columns={7} isFixed={true} background={'white'}>
          <Checkbox
            disabled={disabled}
            checked={dispatchDeliveryOrder.hazmatIndicator}
            onChange={state =>
              updateDispatchDeliveryOrderMultipleFields({
                hazmatIndicator: state,
                hazmatId: undefined
              })
            }
          />
          <Column disableUserSelect={true} margin={{ left: 5 }}>
            Hazmat
          </Column>
        </ColumnRowMini>

        {dispatchDeliveryOrder.hazmatIndicator && (
          <ColumnRowMini>
            <Hazmat disabled={disabled} hazmatId={dispatchDeliveryOrder.hazmatId} updateHazmat={updateHazmat} />
          </ColumnRowMini>
        )}

        {reefer && (
          <Column>
            <Input
              disabled={disabled}
              title={'Reefer description'}
              maxLength={100}
              value={dispatchDeliveryOrder.temperatureControl}
              onChange={updateDispatchDeliveryOrder('temperatureControl')}
            />
          </Column>
        )}
      </Container>
      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
