import styled from 'styled-components'
import { theme } from '../../../../../../styles/theme'

export const TableContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Table = styled.div`
  flex-grow: 1;
  min-height: 375px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  overflow-y: scroll;
`
export const Header = styled.div`
  display: flex;
  align-items: stretch;
  height: 50px;
  font-weight: 500;
  border-bottom: 1px solid ${theme.colors.darkBorderColor};
  background-color: white;
  border-bottom: 1px solid #cdcdcd;
  padding-right: 6px;

  .input-container,
  .date-field {
    border: none;
    background: none !important;
    padding: 0 !important;
  }

  input {
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer;

    &::placeholder {
      font-weight: 500 !important;
      color: #445366 !important;
    }
  }
`
export const Row = styled.div`
  display: flex;
  align-items: stretch;
  height: 50px;

  &:nth-child(2n + 1) {
    background-color: #f5f6fa;
  }
`
export const Column = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 310px;
  font-size: 14px;
  overflow: hidden;
  color: #445366;
  padding: 0 32px;

  &.active {
    color: #4555d0;

    input {
      color: #4555d0 !important;
    }
  }

  &:hover {
    color: #4555d0;

    input::placeholder {
      color: #4555d0 !important;
    }
  }

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }
`
export const ColumnData = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Spinner = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
