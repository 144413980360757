import * as React from 'react'
import { ActivitiesTab } from '../../../../../dispatchDeliveryOrder/views/Details/Activities'
import ActionsSaveCancel from '../../../../../../UI/Buttons/ActionsSaveCancel'
import { GridItemSpinner } from '../../../../../../UI/Spinner/Spinner'
import { ActivitiesContainer } from '../../../styledComponents'
import { checkValidation } from '../../../../../../../services/DTO/validation'
import { DetailsButtonSaveContainer } from './styles'
import { localStorageService } from '../../../../../../../services/storageService/localStorage/LocalStorage'
import { TabDTO } from '../../../../../../../store/reducers/tabs/interfaces'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../../../hooks/useGridItemDDO'
import { requestDispatchDeliveryOrderById } from '../../../../../dispatchDeliveryOrder/epics'
import { isFullObject } from '../../../../../../../services/functions/test/isFullObject'

type Props = {}

export const Details = (props: Props) => {
  const expandedItem = useExpandedItem()
  const gridItemDDOProviderData = useGridItemDDO()
  const {
    isModified,
    parentItemId,
    isFetching,
    setFetching,
    saveUpdatedEntities,
    clearExpandedItemModifies
  } = expandedItem
  const { dispatchDeliveryOrder } = gridItemDDOProviderData
  const onSaveClickRef = React.useRef(null)
  const onSaveClick = (options?: any) => {
    saveUpdatedEntities({ gridItemDDOProviderData, retrySave: onSaveClickRef.current, options: options || {} })
  }
  onSaveClickRef.current = onSaveClick

  React.useEffect(() => {
    if (parentItemId) {
      localStorageService.pushRecentForTabType(TabDTO.Type.dispatchDeliveryOrder, parentItemId)
    }

    if (!isFullObject(dispatchDeliveryOrder)) {
      setFetching(true)
      requestDispatchDeliveryOrderById(parentItemId)
        .catch(() => {})
        .finally(() => setFetching(false))
    }
  }, [])

  if (isFetching && !dispatchDeliveryOrder) {
    return <GridItemSpinner />
  }

  if (!dispatchDeliveryOrder) {
    return null
  }

  return (
    <>
      <ActivitiesContainer>
        {isFetching && <GridItemSpinner />}
        <ActivitiesTab showUserInfo={true} unlimitedHeight={true} />
      </ActivitiesContainer>
      <DetailsButtonSaveContainer>
        {isModified && (
          <ActionsSaveCancel
            styles={{ height: 55, right: 20 }}
            disableSave={!checkValidation.dispatchDeliveryOrder(expandedItem)}
            onSave={onSaveClick}
            onCancel={clearExpandedItemModifies}
          />
        )}
      </DetailsButtonSaveContainer>
    </>
  )
}
