import * as React from 'react'
import theme from '../../../styles/theme'

export const ErrorIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // tslint:disable-next-line:max-line-length
      d="M19.6004 10.0004C19.6004 15.3023 15.3023 19.6004 10.0004 19.6004C4.69846 19.6004 0.400391 15.3023 0.400391 10.0004C0.400391 4.69846 4.69846 0.400391 10.0004 0.400391C15.3023 0.400391 19.6004 4.69846 19.6004 10.0004ZM11.2004 14.8004C11.2004 15.4631 10.6631 16.0004 10.0004 16.0004C9.33765 16.0004 8.80039 15.4631 8.80039 14.8004C8.80039 14.1376 9.33765 13.6004 10.0004 13.6004C10.6631 13.6004 11.2004 14.1376 11.2004 14.8004ZM10.0004 4.00039C9.33765 4.00039 8.80039 4.53765 8.80039 5.20039V10.0004C8.80039 10.6631 9.33765 11.2004 10.0004 11.2004C10.6631 11.2004 11.2004 10.6631 11.2004 10.0004V5.20039C11.2004 4.53765 10.6631 4.00039 10.0004 4.00039Z"
      fill={theme.colors.defaultRed}
    />
  </svg>
)
