import * as React from 'react'
import { ModalWindowContext } from '../contexts/ModalWindowContext'
import { IModalWindow, ModalWindow } from '../store/reducers/modalWindow/interfaces'
import { oc } from 'ts-optchain'

type Props = {
  children?: any
}

export const modalWindowActions: {
  setModalWindow: React.Dispatch<React.SetStateAction<IModalWindow>>
} = {
  setModalWindow: () => {}
}

export const ModalWindowProvider = React.memo((props: Props) => {
  const { children } = props
  const [modalWindow, setModalWindow] = React.useState<IModalWindow>(null)
  modalWindowActions.setModalWindow = setModalWindow

  const setModalWindowButtons = React.useCallback((buttons: ModalWindow.Button[]) => {
    setModalWindow((mw: IModalWindow) => (mw ? { ...mw, buttons } : mw))
  }, [])

  const closeModalWindow = React.useCallback(() => {
    setModalWindow(null)
  }, [])

  return (
    <ModalWindowContext.Provider
      value={{ modalWindow, setModalWindow, setModalWindowButtons, closeModalWindow }}
      children={children}
    />
  )
})
