import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { ColumnBoldText, ColumnThinText } from './MoveRowSpecs'
import { availibleActivityStatuses } from './MoveRowActivity'
import { transformMoney } from '../../../../../../../services/functions/transform/transformMoney'
import { useActivity } from '../../../../../../../hooks/useActivity'
import { TransportationActivityViewDTO } from '../../../../../../../api/api'

type Props = {
  gotoActivityId: string
  businessActivityId: string
}

export const MoveRowQuoteColumn = React.memo((props: Props) => {
  const { gotoActivityId, businessActivityId } = props
  const gotoActivity = useActivity({ id: gotoActivityId }) as TransportationActivityViewDTO
  const businessActivity = useActivity({ id: businessActivityId }) as TransportationActivityViewDTO
  const businessActivityStatus = oc(businessActivity).status() as TransportationActivityViewDTO.StatusEnum
  const gotoActivityBSQAmount = oc(gotoActivity).buySideQuoteAmount()
  const businessActivityBSQAmount = oc(businessActivity).buySideQuoteAmount()

  if (!availibleActivityStatuses.includes(businessActivityStatus)) {
    return null
  }

  let bsqAmount: number = undefined

  if (typeof gotoActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + gotoActivityBSQAmount
  }
  if (typeof businessActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + businessActivityBSQAmount
  }

  return (
    <QuotesColumn data-copy-value-on-mousedown={true}>
      <ColumnBoldText>{transformMoney(bsqAmount) || '–'}</ColumnBoldText>
      <ColumnThinText>BSQ</ColumnThinText>
    </QuotesColumn>
  )
})

export const QuotesColumn = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 6px;
`
