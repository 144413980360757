import * as React from 'react'
import styled from 'styled-components'

type Props = {
  styles?: any
  isBold?: boolean
  title: string
  link?: {
    isAddButton?: boolean
    label: string
    onClick: () => void
  }
}

export const SsqBsqWidgetTableTitleWithLink = ({ isBold, title, link, styles }: Props) => {
  const StyledTitle = isBold ? BoldTitle : Title

  return (
    <Container style={styles}>
      <StyledTitle>{title}</StyledTitle>
      {link ? (
        <>
          {link.isAddButton ? (
            <AddButton className={'mdi mdi-plus-circle-outline'} onClick={link.onClick}>
              {link.label}
            </AddButton>
          ) : (
            <StyledLink onClick={link.onClick}>{link.label}</StyledLink>
          )}
        </>
      ) : (
        <div />
      )}
    </Container>
  )
}

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 8px 0 16px;
`

const Title = styled.div`
  font-size: 14px;
  color: #445366;
`

const BoldTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #445366;
`

const StyledLink = styled.div`
  font-size: 14px;
  text-decoration-line: underline;
  color: #4555d0;
  cursor: pointer;
  user-select: none;
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-weight: 500;
  font-size: 14px;
  color: #4555d0;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 22px;
    margin-left: 5px;
  }
`
