import 'rxjs'
import { createStore, applyMiddleware, Store } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { rootReducer } from './reducers'
import rootEpic from './epics'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { IStore } from './store.interface'

let store: Store<IStore>
const epicMiddleware = createEpicMiddleware()

export const configureStoreProd = () => {
  const middlewares = [epicMiddleware]

  const enhancers = composeWithDevTools(applyMiddleware(...middlewares))
  store = createStore(rootReducer(), enhancers)
  // @ts-ignore
  epicMiddleware.run(rootEpic)

  return store
}

export const configureStoreDev = () => {
  const middlewares = [reduxImmutableStateInvariant(), epicMiddleware]

  const enhancers = composeWithDevTools(applyMiddleware(...middlewares))
  store = createStore(rootReducer(), enhancers)
  // @ts-ignore
  epicMiddleware.run(rootEpic)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export const getStore = (): Store<IStore> => store

export default configureStore
