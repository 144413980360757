import * as React from 'react'
import { TProps } from './simple'
import { TextMask } from './textMask'

export const PhoneNumber = (props: TProps) => (
  <TextMask
    {...props}
    highlighted={props.value && String(props.value).length < 10}
    onChange={value => props.onChange(value ? value.replace(/\D/g, '') || null : null)}
    placeholder={'(000) 000-0000'}
    textMask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
  />
)
