import * as React from 'react'

export const ArrowIcon = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // tslint:disable-next-line:max-line-length
      d="M2.23408 9.58711L5.61786 5.98011C6.12738 5.43697 6.12738 4.55959 5.61786 4.01646L2.23408 0.409459C1.411 -0.467918 0 0.15878 0 1.39825L0 8.61224C0 9.85171 1.411 10.4645 2.23408 9.58711Z"
      fill="#06182F"
    />
  </svg>
)
