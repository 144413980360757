import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderGridItemDTO, DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { isDDOStatusNew } from './isDDOStatusNew'
import { isDDOStatusCancelled } from './isDDOStatusCancelled'

type Props = {
  deliveryOrder: DeliveryOrderViewDTO
  dispatchDeliveryOrders: (DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[]
}

export const isDeliveryOrderSteamShipLineRequired = (props: Props): boolean => {
  const { deliveryOrder, dispatchDeliveryOrders } = props

  if (!(dispatchDeliveryOrders || []).length) {
    return false
  }

  const { steamShipLineId, equipmentFirstPickupDate } = deliveryOrder

  const isSSLRequeredForDdo = (
    dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO
  ): boolean => {
    if (isDDOStatusCancelled(dispatchDeliveryOrder.status) && !steamShipLineId) {
      return false
    }

    if (isDDOStatusNew(dispatchDeliveryOrder.status)) {
      return deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? Boolean(
            equipmentFirstPickupDate &&
              oc(dispatchDeliveryOrder).pickupStage.locationId() &&
              oc(dispatchDeliveryOrder).returnStage.locationId()
          )
        : false
    }

    return true
  }

  return dispatchDeliveryOrders.some(isSSLRequeredForDdo)
}
