import * as React from 'react'
import { Popover } from '../../../../../../components/UI/Dropdowns/Popover'
import { StyledColumnVisibility, Button, StyledList } from './styledComponents'
import Multiselector from '../../../../../UI/Multiselector'
import { TGridColumns } from '../../../../../../store/reducers/tabs/interfaces'
type OwnProps = {
  columns: TGridColumns
  updateColumns: (columns: TGridColumns) => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ColumnVisibilitySettings = (props: Props) => {
  const { columns, updateColumns } = props
  const [open, setOpen] = React.useState(false)

  if (!columns) {
    return null
  }

  const toggleColumnVisibility = (columnName: string) => {
    const column = columns[columnName]
    updateColumns({ ...columns, [columnName]: { ...column, active: !column.active } })
  }

  const togglePopover = React.useCallback(
    (state = !open) =>
      setOpen(_state => {
        if (state !== false && state !== true) {
          return !_state
        }

        return state
      }),
    []
  )

  return (
    <>
      <StyledColumnVisibility>
        <Popover
          width={200}
          isOpen={open}
          changeState={togglePopover}
          button={
            // tslint:disable-next-line:jsx-wrap-multiline
            <Button onClick={() => togglePopover()}>
              <i className={'mdi mdi-tune' + (open ? ' open' : '')} />
            </Button>
          }
        >
          <StyledList>
            {Object.keys(columns).map((column: string) => (
              <Multiselector
                key={column}
                name={column}
                active={columns[column].active}
                onChange={toggleColumnVisibility}
              />
            ))}
          </StyledList>
        </Popover>
      </StyledColumnVisibility>
    </>
  )
}
