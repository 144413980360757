import * as React from 'react'
import { PowerUnitViewDTO } from '../api/api'

export interface IGridItemPowerUnitContext {
  powerUnit: PowerUnitViewDTO
  isExpanded: boolean
}

export const GridItemPowerUnitContext = React.createContext<IGridItemPowerUnitContext>({
  powerUnit: undefined,
  isExpanded: undefined
})
