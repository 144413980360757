import { oc } from 'ts-optchain'
import { getContainerTypeNameWithCode, getContainerTypesDropDownList } from '../../../../services/DTO/containerType'
import { SearchByList } from '../SearchByList'
import * as React from 'react'
import { ContainerTypeDTO } from '../../../../api/origin/business-logic'
import { containerLists } from '../../../../services/select/containerLists'
import { useContainerType } from '../../../../hooks/useContainerType'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'

type Props = {
  id: string
  disabled?: boolean
  title?: string
  onlyCode?: boolean
  required?: boolean
  placeholder?: string
  onChange: (container: ContainerTypeDTO) => void
}

export const ContainerTypeSearch = ({ id, disabled, title, placeholder, onlyCode, required, onChange }: Props) => {
  const { modifiedLists } = useExpandedItem()
  const containerType = useContainerType({ id, modifiedLists })

  return (
    <SearchByList
      disabled={disabled}
      title={title}
      required={required}
      placeholder={placeholder}
      label={onlyCode ? oc(containerType).code('') : getContainerTypeNameWithCode(containerType)}
      dropDownList={getContainerTypesDropDownList(containerLists.containerTypes, true)}
      onChange={onChange}
    />
  )
}
