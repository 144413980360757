import {
  BusinessPartnerViewDTO,
  BuySideQuoteDTO,
  ContactDTO,
  ContainerTypeDTO,
  ContainerViewDTO,
  CustomerViewDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  DriverViewDTO,
  EquipmentDTO,
  HazmatDTO,
  OngoingActivityGroupDTO,
  PowerUnitDTO,
  SellSideQuoteDTO,
  StateDTO,
  SteamShipLineViewDTO,
  SubClientViewDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { IDriverPositionDTO } from '../../../services/DTO/driverPosition/interfaces'
import { UserDTO } from '../../../api/origin/user-service'
import { IMoveDTO } from '../communicationHub/interfaces'
import { TLocation } from '../../../components/common/location/interfaces'
import { IDriverActivityDTO } from '../../../services/DTO/driverActivity/interfaces'
import { BuySideQuoteRateDTO, RuleSearchDTO, SellSideQuoteRateDTO } from '../../../api/origin/qmp-service'
import { ILocationGroup } from '../../../components/common/locationGroup/interfaces'
import { DeductionDTO, VendorDTO } from '../../../api/origin/vendor-accounting-service'

export enum EntityType {
  ongoingActivityDateStage = 'ongoingActivityDateStage',
  ongoingActivityDateStageNow = 'ongoingActivityDateStageNow',
  ongoingActivityDateDriver = 'ongoingActivityDateDriver',
  driverPosition = 'driverPosition',
  move = 'move',
  driver = 'driver',
  driverIdByAuthId = 'driverIdByAuthId',
  user = 'user',
  powerUnit = 'powerUnit',
  equipment = 'equipment',
  location = 'location',
  customer = 'customer',
  container = 'container',
  containerType = 'containerType',
  steamShipLine = 'steamShipLine',
  subClient = 'subClient',
  businessPartner = 'businessPartner',
  contact = 'contact',
  driverActivity = 'driverActivity',
  rule = 'rule',
  state = 'state',
  deduction = 'deduction',
  vendorAccounting = 'vendorAccounting',
  dispatchDeliveryOrder = 'dispatchDeliveryOrder',
  deliveryOrder = 'deliveryOrder',
  activity = 'activity',
  locationGroup = 'locationGroup',
  streetTurn = 'streetTurn',
  sellSideQuote = 'sellSideQuote',
  buySideQuote = 'buySideQuote',
  rate = 'rate',
  hazmat = 'hazmat'
}

interface IListEntity {
  [EntityType.ongoingActivityDateStage]: OngoingActivityGroupDTO
  [EntityType.ongoingActivityDateStageNow]: OngoingActivityGroupDTO
  [EntityType.ongoingActivityDateDriver]: OngoingActivityGroupDTO
  [EntityType.driverPosition]: IDriverPositionDTO
  [EntityType.move]: IMoveDTO
  [EntityType.driver]: DriverViewDTO
  [EntityType.driverIdByAuthId]: string // { id: string; value: string }
  [EntityType.contact]: ContactDTO
  [EntityType.driverActivity]: IDriverActivityDTO
  [EntityType.rule]: RuleSearchDTO
  [EntityType.state]: StateDTO
  [EntityType.user]: UserDTO
  [EntityType.powerUnit]: PowerUnitDTO
  [EntityType.equipment]: EquipmentDTO
  [EntityType.location]: TLocation
  [EntityType.customer]: CustomerViewDTO
  [EntityType.containerType]: ContainerTypeDTO
  [EntityType.container]: ContainerViewDTO
  [EntityType.steamShipLine]: SteamShipLineViewDTO
  [EntityType.subClient]: SubClientViewDTO
  [EntityType.businessPartner]: BusinessPartnerViewDTO
  [EntityType.deliveryOrder]: DeliveryOrderViewDTO
  [EntityType.dispatchDeliveryOrder]: DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO
  [EntityType.activity]: TransportationActivityViewDTO | DocumentationActivityDTO
  [EntityType.locationGroup]: ILocationGroup
  [EntityType.streetTurn]: DispatchDeliveryOrderStreetTurnDTO
  [EntityType.sellSideQuote]: SellSideQuoteDTO
  [EntityType.buySideQuote]: BuySideQuoteDTO
  [EntityType.deduction]: DeductionDTO
  [EntityType.vendorAccounting]: VendorDTO
  [EntityType.rate]: SellSideQuoteRateDTO | BuySideQuoteRateDTO
  [EntityType.hazmat]: HazmatDTO
}

export type TListsState = { [K in keyof IListEntity]: Record<string, IListEntity[K]> }

export type TListsStateUpdate = { [K in keyof IListEntity]?: IListEntity[K][] }
export type TListsStateDelete = { [K in keyof IListEntity]?: string[] }
export interface IListsStateUpdate {
  update?: TListsStateUpdate
  delete?: TListsStateDelete
}
