import * as React from 'react'
import { oc } from 'ts-optchain'
import { Container, ColumnRow, Column } from '../../../GridSystem'
import { EditModeButton, WidgetActions } from '../../styles'
import { Select } from '../../../DataFields/Select'
import { AddressDTO, BusinessPartnerDTO, CustomerViewDTO } from '../../../../../api/origin/business-logic'
import { InputField, Input } from '../../../DataFields/Input'
import { CustomerSearch } from '../../../DataFields/SearchRequest'
import { updateAddressObject } from '../location/functions'
import { FieldTemplate } from '../../../DataFields/Templates'
import { customerLists } from '../../../../../services/select/customerLists'
import { GridSpinner } from '../../../Spinner/Spinner'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { isCustomerValid } from '../../../../../services/functions/test/isCustomerValid'
import { requestCustomerById } from '../../../../common/customer/epics'
import { isBusinessPartnerValid } from '../../../../../services/functions/test/isBusinessPartnerValid'
import { usePrimaryContact } from '../../../../../hooks/usePrimaryContact'
import { saveCustomer } from '../../../../../services/saveEntity/saveCustomer'
import { isContactValid } from '../../../../../services/functions/test/isContactValid'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'
import { testEntityVersion } from '../../../../../services/functions/test/testEntityVersion'
import { getListsState } from '../../../../../store'
import { showWarningModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { tryToSave } from '../../../../../services/DTO/saveDTO'

type Props = {
  disabled?: boolean
  isModifiedMode?: boolean
  customer: CustomerViewDTO
  businessPartner: BusinessPartnerDTO
  changeCustomerId: (customerId: string | undefined) => void
}

export const Popover = (props: Props) => {
  const { isModifiedMode, disabled, customer, businessPartner, changeCustomerId } = props
  const { modifiedLists, modifyListItems, deleteModifiedListItems } = useExpandedItem()
  const { primaryContact, modifyPrimaryContactField } = usePrimaryContact({
    parentEntity: businessPartner,
    entityType: EntityType.businessPartner
  })
  const isModifiedModeRef = React.useRef(isModifiedMode)
  const customerIdRef = React.useRef(oc(customer).id())
  const businessPartnerIdRef = React.useRef(oc(businessPartner).id())
  const primaryContactIdRef = React.useRef(oc(primaryContact).id())
  const { closePopover } = React.useContext(WidgetContext)
  const [edit, setEdit] = React.useState(isModifiedMode)
  const [fetching, setFetching] = React.useState(customer && !isFullObject(customer))
  const address = oc(businessPartner).legalAddress()
  isModifiedModeRef.current = isModifiedMode
  customerIdRef.current = oc(customer).id()
  businessPartnerIdRef.current = oc(businessPartner).id()
  primaryContactIdRef.current = oc(primaryContact).id()

  const deleteModifies = () => {
    if (customerIdRef.current || businessPartnerIdRef.current || primaryContactIdRef.current) {
      const deleteIds: Partial<Record<EntityType, string[]>> = {}

      if (customerIdRef.current) {
        deleteIds[EntityType.customer] = [customerIdRef.current]
      }
      if (businessPartnerIdRef.current) {
        deleteIds[EntityType.businessPartner] = [businessPartnerIdRef.current]
      }
      if (primaryContactIdRef.current) {
        deleteIds[EntityType.contact] = [primaryContactIdRef.current]
      }

      deleteModifiedListItems(deleteIds)
    }
  }

  React.useEffect(() => {
    if (customer && !isFullObject(customer)) {
      requestCustomerById(customer.id).finally(() => {
        setFetching(false)
      })
    }

    return () => {
      if (isModifiedModeRef.current) {
        deleteModifies()
      }
    }
  }, [])

  const modifyCustomerField = (fieldName: keyof CustomerViewDTO) => (value: any) => {
    modifyListItems({ [EntityType.customer]: [{ ...customer, [fieldName]: value }] })
  }

  const modifyBusinessPartnerField = (fieldName: keyof BusinessPartnerDTO) => (value: any) => {
    modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, [fieldName]: value }] })
  }

  const modifyAddressField = (fieldName: keyof AddressDTO) => (value: any) => {
    modifyBusinessPartnerField('legalAddress')(updateAddressObject(address)(fieldName)(value))
  }

  const cancelModifies = () => {
    deleteModifies()
    setEdit(false)
  }

  return (
    <>
      {fetching && <GridSpinner />}
      <Container columns={32} flexClear={true} isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <CustomerSearch
            disabled={disabled}
            title={'Find Customer'}
            required={true}
            id={oc(customer).id()}
            onChange={_customer => changeCustomerId(oc(_customer).id())}
          />
        </ColumnRow>

        {isFullObject(customer) && (
          <>
            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  required={true}
                  title={'Name'}
                  maxLength={200}
                  value={oc(businessPartner).legalName()}
                  onChange={modifyBusinessPartnerField('legalName')}
                />
              </Column>
              <Column>
                <Input
                  required={true}
                  disabled={!edit}
                  title={'Address'}
                  value={oc(address).street()}
                  onChange={modifyAddressField('street')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  title={'Company'}
                  maxLength={200}
                  value={oc(businessPartner).dbaName()}
                  onChange={modifyBusinessPartnerField('dbaName')}
                />
              </Column>
              <Column>
                <Column columns={5} margin={{ right: 10 }}>
                  <Input
                    disabled={!edit}
                    required={true}
                    title={'City'}
                    value={oc(address).city()}
                    onChange={modifyAddressField('city')}
                  />
                </Column>
                <Column style={{ fontSize: 14 }} columns={4} isFixed={true} margin={{ right: 10 }}>
                  <FieldTemplate.StateSearch
                    disabled={!edit}
                    required={true}
                    showCode={true}
                    title={'State'}
                    stateId={oc(address).stateId(null)}
                    onChange={modifyAddressField('stateId')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <InputField.Zip
                    disabled={!edit}
                    required={true}
                    title={'ZIP'}
                    value={oc(address).postalCode()}
                    onChange={modifyAddressField('postalCode')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <Column rows={0.2} background={'#eff2f6'} margin={{ bottom: 15 }} />

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Select
                  disabled={!edit}
                  required={true}
                  title={'Net Terms Unit'}
                  selectedValue={oc(customer).netTermsUnit(null)}
                  list={customerLists.netTermsUnit}
                  onSelect={modifyCustomerField('netTermsUnit')}
                />
              </Column>
              <Column>
                <Column margin={{ right: 10 }}>
                  <Input
                    disabled={!edit}
                    title={'First Name'}
                    value={oc(primaryContact).firstName()}
                    onChange={modifyPrimaryContactField('firstName')}
                  />
                </Column>
                <Column>
                  <Input
                    disabled={!edit}
                    title={'Last Name'}
                    value={oc(primaryContact).lastName()}
                    onChange={modifyPrimaryContactField('lastName')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Select
                  disabled={!edit}
                  required={true}
                  title={'Net Terms'}
                  selectedValue={oc(customer).netTerms(null)}
                  list={customerLists.netTerms}
                  onSelect={modifyCustomerField('netTerms')}
                />
              </Column>
              <Column>
                <InputField.Email
                  disabled={!edit}
                  title={'E-mail'}
                  value={oc(primaryContact).email()}
                  onChange={modifyPrimaryContactField('email')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  title={'Note'}
                  placeholder={'Type note here...'}
                  value={oc(businessPartner).notes()}
                  onChange={modifyBusinessPartnerField('notes')}
                />
              </Column>
              <Column>
                <Column margin={{ right: 10 }}>
                  <InputField.PhoneNumber
                    disabled={!edit}
                    title={'Phone'}
                    value={oc(primaryContact).phone()}
                    onChange={modifyPrimaryContactField('phone')}
                  />
                </Column>
                <Column>
                  <InputField.PhoneNumber
                    disabled={!edit}
                    title={'Fax'}
                    value={oc(primaryContact).fax()}
                    onChange={modifyPrimaryContactField('fax')}
                  />
                </Column>
              </Column>
            </ColumnRow>
          </>
        )}
      </Container>

      <WidgetActions>
        {edit ? (
          <>
            <ActionButton onlyText={true} round={true} onClick={cancelModifies} children={'Cancel'} />
            <ActionButton
              filled={true}
              round={true}
              disabled={
                !(
                  isCustomerValid(customer) &&
                  isBusinessPartnerValid(businessPartner) &&
                  (primaryContact ? isContactValid(primaryContact) : true)
                )
              }
              onClick={() => {
                const storeLists = getListsState()
                const modifiedCustomer = oc(modifiedLists)[EntityType.customer][customer.id]()
                const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][
                  customer.businessPartnerId
                ]()
                const modifiedContacts = [
                  oc(modifiedLists)[EntityType.contact][oc(businessPartner).primaryContactId()]()
                ].filter(Boolean)
                const condition =
                  testEntityVersion(storeLists, EntityType.customer)(modifiedCustomer) &&
                  testEntityVersion(storeLists, EntityType.businessPartner)(modifiedBusinessPartner) &&
                  modifiedContacts.every(testEntityVersion(storeLists, EntityType.contact))

                const save = () => {
                  return saveCustomer({
                    subClients: undefined,
                    customer: modifiedCustomer,
                    businessPartner: modifiedBusinessPartner,
                    contacts: modifiedContacts
                  }).then(() => {
                    deleteModifies()
                    setEdit(false)
                  })
                }

                tryToSave({
                  condition,
                  save: () => {
                    setFetching(true)
                    save().finally(() => setFetching(false))
                  },
                  hideSpinner: () => setFetching(false),
                  cancel: () => {},
                  discardChanges: deleteModifies
                })
              }}
              children={'Save Customer'}
            />
          </>
        ) : (
          <>
            {customer && !edit && !disabled && (
              <EditModeButton onClick={() => setEdit(true)} children={'Edit Customer'} />
            )}
            <ActionButton filled={true} round={true} onClick={closePopover} children={'Ok'} />
          </>
        )}
      </WidgetActions>
    </>
  )
}
