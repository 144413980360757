import * as React from 'react'
import { oc } from 'ts-optchain'
import { toast } from 'react-toastify'
import { IconWithText, DarkButton, BlueButton, GreenButton } from './styles'
import {
  streetTurnRequests,
  // getStreetTurnDispatchDeliveryOrderById,
  requestStreetTurnDispatchDeliveryOrdersForDdoId
} from '../../../services/DTO/streetTurn/epics'
import {
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderStreetTurnDTO
} from '../../../api/origin/business-logic'
import { requestDispatchDeliveryOrderById } from '../../common/dispatchDeliveryOrder/epics'
import { useGrid } from '../../../hooks/useGrid'
import { deleteTabById, updateTab, updateTabData } from '../../../store/reducers/tabs/functions'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'
import { useDispatchDeliveryOrder } from '../../../hooks/useDispatchDeliveryOrder'
import { useExpandedItem } from '../../../hooks/useExpandedItem'

type Props = {
  fetchGridItems: (withoutSpinner?: boolean) => Promise<DispatchDeliveryOrderGridItemDTO[]>
}

export const StreetTurn = (props: Props) => {
  const { fetchGridItems } = props
  const { parentItemId } = useExpandedItem()
  const { tabId, tabData, listRequestProps, setGridFetching } = useGrid()
  const selectedIds: string[] = oc(tabData).selectedIds([])
  const hideUnselectedGridItems = oc(tabData).hideUnselectedGridItems(false)
  const streetTurnMappingByDdoId: Record<string, DispatchDeliveryOrderStreetTurnDTO> = oc(
    tabData
  ).streetTurnMappingByDdoId({})
  const streetTurnRequested = tabData.streetTurnRequested
  const streetTurnConfirmed = tabData.streetTurnConfirmed
  const requestFromDDOId = listRequestProps.dispatchDeliveryOrderId
  const requestToDDOId = selectedIds.filter(id => id !== requestFromDDOId)[0]
  const ddoForStreetTurnRequest = useDispatchDeliveryOrder({ id: requestToDDOId })

  React.useEffect(() => {
    if (hideUnselectedGridItems && oc(selectedIds).length() === 1) {
      updateTabData(tabId, { hideUnselectedGridItems: false })
    }
  }, [oc(selectedIds).length()])

  const getDispatchDeliveryOrdersById = async () => {
    return Promise.all(
      [requestFromDDOId, requestToDDOId].filter(Boolean).map(id => requestDispatchDeliveryOrderById(id))
    )
  }

  const getMainDispatchDeliveryOrdersById = async () => {
    return requestDispatchDeliveryOrderById(requestFromDDOId)
  }

  const requestStreetTurn = () => {
    const streetTurnId = oc(streetTurnMappingByDdoId)[requestToDDOId].id()

    if (!streetTurnId) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not request Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    streetTurnRequests
      .submit({ streetTurnId })
      .then(getDispatchDeliveryOrdersById)
      .then(() => {
        updateTab(tabId, {
          refrashOnTabEnter: false,
          gridItemIds: [],
          data: { disabledSelectIds: selectedIds, streetTurnRequested: true }
        })

        toast.success(
          <>
            <i className={'mdi mdi-check'} />
            Street Turn Requested
          </>
        )
      })
      .catch(() => {
        setGridFetching(false)
        const closeTab = () => deleteTabById(tabId)
        const refreshTab = () => {
          updateTabData(tabId, {
            selectedIds: [requestFromDDOId],
            pinnedIds: [requestFromDDOId],
            disabledSelectIds: [requestFromDDOId],
            streetTurnRequested: false
          })
          fetchGridItems()
        }

        showErrorModalWindow({
          title: 'Street Turn Request Error',
          buttons: [{ label: 'Close Tab', onClick: closeTab }, { label: 'Refresh Tab', onClick: refreshTab }]
        })
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  const confirmStreetTurnRequest = () => {
    const streetTurnIdToApprove = ddoForStreetTurnRequest.streetTurnId

    if (!streetTurnIdToApprove) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not confirm Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    streetTurnRequests
      .approve({ streetTurnId: streetTurnIdToApprove })
      .then(getDispatchDeliveryOrdersById)
      .then(() => {
        updateTab(tabId, {
          refrashOnTabEnter: false,
          data: { streetTurnRequested: false, streetTurnConfirmed: true },
          gridItemIds: []
        })

        toast.success(
          <>
            <i className={'mdi mdi-check'} />
            Street Turn Confirmed
          </>
        )
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.log(error)
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  const cancelStreetTurnRequest = () => {
    const streetTurnIdToCancel = ddoForStreetTurnRequest.streetTurnId

    if (!streetTurnIdToCancel) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not cancel Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    let requestedGridItemIds: string[] = []

    streetTurnRequests
      .cancel({ streetTurnId: streetTurnIdToCancel })
      .then(() => requestStreetTurnDispatchDeliveryOrdersForDdoId({ dispatchDeliveryOrderId: requestFromDDOId }))
      .then(ddoList => (requestedGridItemIds = ddoList.map(({ id }) => id)))
      .then(() =>
        Promise.all([
          getMainDispatchDeliveryOrdersById(),
          parentItemId && parentItemId !== requestFromDDOId ? requestDispatchDeliveryOrderById(parentItemId) : undefined
        ])
      )
      .then(() => {
        updateTab(tabId, {
          refrashOnTabEnter: true,
          gridItemIds: requestedGridItemIds,
          data: {
            selectedIds: [requestFromDDOId],
            pinnedIds: [requestFromDDOId],
            disabledSelectIds: [requestFromDDOId],
            streetTurnRequested: false,
            streetTurnConfirmed: false
          }
        })
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.log(error)
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  return (
    <>
      {!ddoForStreetTurnRequest && (
        <IconWithText className={'mdi mdi-information'}>Select one of possible matches</IconWithText>
      )}
      {ddoForStreetTurnRequest && !streetTurnRequested && !streetTurnConfirmed && (
        <BlueButton className={'mdi mdi-compare-horizontal'} onClick={requestStreetTurn}>
          Request Street Turn
        </BlueButton>
      )}
      {streetTurnRequested && (
        <GreenButton className={'mdi mdi-check-circle'} onClick={confirmStreetTurnRequest}>
          Confirm Street Turn
        </GreenButton>
      )}
      {streetTurnRequested && (
        <DarkButton className={'mdi mdi-close-circle'} onClick={cancelStreetTurnRequest}>
          Cancel Request
        </DarkButton>
      )}
      {streetTurnConfirmed && (
        <DarkButton className={'mdi mdi-close-circle'} onClick={cancelStreetTurnRequest}>
          Cancel Street Turn
        </DarkButton>
      )}
    </>
  )
}
