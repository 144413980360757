type Props = {
  className?: string
  id?: string
  scrollLogicalPosition?: ScrollLogicalPosition
  scrollBehavior?: ScrollBehavior
}

export const scrollToElement = (props: Props) => {
  const { className, id, scrollLogicalPosition, scrollBehavior } = props
  let element: HTMLElement = undefined

  if (className) {
    element = document.getElementsByClassName(className)[0] as HTMLElement
  }

  if (id) {
    element = document.getElementById(id) as HTMLElement
  }

  if (!element) {
    return
  }

  element.scrollIntoView({ behavior: scrollBehavior || 'smooth', block: scrollLogicalPosition || 'center' })
}
