import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import {
  DateISOString,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  EquipmentDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../api/api'
import {
  IActivityDocumentPopup,
  ActivityDocumentPopupView
} from '../components/common/dispatchDeliveryOrder/interfaces'
// tslint:disable:max-line-length
import { completionActualDayNeedStatuses } from '../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/CompletionActualDateActivityColumn'
import { startActualDayNeedStatuses } from '../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/StartActualDateActivityColumn'
import { TransportationActivityRowContext } from '../contexts/TransportationActivityRowContext'
import {
  ActivityGroupAction,
  ActivityRow,
  DDOWorkingStatus,
  TransportationActivityRow
} from '../services/DTO/activity/controller'
import { TransportationActivityGroup } from '../services/DTO/activity/interfaces'
import { relatedActivityTypes } from '../constants/relatedActivityTypes'
import { FileCategory, IAttachment } from '../services/filesService/interfaces'
import { showModalWindow } from '../store/reducers/modalWindow/functions'
import { IMessage } from '../store/reducers/communicationHub/interfaces'
import { isDropBobtailGotoActivity, isPickBobtailGotoActivity } from '../services/functions/test/isBobtailGotoActivity'
import { getNextActivityRow } from '../services/functions/get/getNextActivityRow'
import { getPreviousActivityRow } from '../services/functions/get/getPreviousActivityRow'
import { isDropActivity } from '../services/functions/test/isDropActivity'
import { isPickActivity } from '../services/functions/test/isPickActivity'
import { isUnsuccessfulActivityGroup } from '../services/functions/test/isUnsuccessfulActivity'
import { getBobtailRefDispatchDeliveryOrderInfo } from '../services/functions/get/getBobtailRefDispatchDeliveryOrderInfo'
import { requestDispatchDeliveryOrderById } from '../components/common/dispatchDeliveryOrder/epics'
import { isGotoActivityType } from '../services/functions/test/isGotoActivity'
import { getActivityDropBobtailGroup } from '../services/functions/get/getActivityDropBobtailGroup'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { tabActions } from '../store/reducers/tabs'
import { getListsState } from '../store'
import { useGridItemDDO } from '../hooks/useGridItemDDO'
import { isFullObject } from '../services/functions/test/isFullObject'
import { useLocation } from '../hooks/useLocation'

type Props = {
  rowNumber: number
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  transportationRow: TransportationActivityRow
  isModifiedMode: boolean
  allowModifying: boolean
  importDatesConfused: boolean
  exportDatesConfused: boolean
  activityDocumentPopup: IActivityDocumentPopup
  activityDocumentMessages: IMessage[]
  activityWithWrongOrderOfPlannedDates: TransportationActivityViewDTO
  rows: ActivityRow[]
  usefulRows: TransportationActivityRow[]
  stageActivityRows: ActivityRow[]
  updateActivityRow: (
    updatedActivityGroup: TransportationActivityRow | TransportationActivityRow[],
    action: ActivityGroupAction,
    updateLocationForInitialObjectState?: LocationViewDTO
  ) => void
  modifyDispatchDeliveryOrder: (state: DispatchDeliveryOrderViewDTO) => void
  lastUnsuccessfulActivityGroup?: TransportationActivityGroup
  currentActivityGroup?: TransportationActivityGroup
  children?: any
}

export const TransportationActivityRowProvider = React.memo((props: Props) => {
  const {
    rowNumber,
    dispatchDeliveryOrder,
    transportationRow,
    isModifiedMode,
    allowModifying,
    activityWithWrongOrderOfPlannedDates,
    importDatesConfused,
    exportDatesConfused,
    rows,
    usefulRows,
    stageActivityRows,
    modifyDispatchDeliveryOrder,
    lastUnsuccessfulActivityGroup,
    currentActivityGroup,
    activityDocumentPopup,
    activityDocumentMessages,
    children
  } = props
  const dispatch = useAppDispatch()
  const { activities, deliveryOrderType, equipment } = useGridItemDDO()
  const updateActivityRowRef = React.useRef(props.updateActivityRow)
  updateActivityRowRef.current = props.updateActivityRow
  const { gotoActivity, businessActivity } = transportationRow.activityGroup as TransportationActivityGroup
  const destination = useLocation({ id: oc(businessActivity).destinationId() })
  const storeLists = getListsState()
  const isDrop = isDropActivity(businessActivity)
  const isPick = isPickActivity(businessActivity)
  const isDropBobtail = isDropBobtailGotoActivity(gotoActivity)
  const isPickBobtail = isPickBobtailGotoActivity(gotoActivity)
  const isBobtail = isDropBobtail || isPickBobtail
  let bobtailGroup: TransportationActivityGroup[] = []
  const anotherDOOActivityLink = getBobtailRefDispatchDeliveryOrderInfo(gotoActivity)
  const isLinkedActivityToBobtail = Boolean(anotherDOOActivityLink && !isBobtail)
  const loadType = dispatchDeliveryOrder.loadType
  const isUnsuccessful = isUnsuccessfulActivityGroup(transportationRow.activityGroup)
  let appDateIsNotConfirmed: boolean
  let disableActualDates = false
  let tirDocument: IAttachment
  let podDocument: IAttachment
  let tirDocumentMessage: IMessage
  let podDocumentMessage: IMessage
  let enableTIRDocumnet = false
  let enablePODDocumnet = false
  let dropTypeActivityRowToCreateBobtail = undefined
  let pickTypeActivityRowToCreateBobtail = undefined
  let isDropBobtailValidationError = false
  const prevTransportationRowOfStage = getPreviousActivityRow(stageActivityRows, businessActivity.id, {
    returnOnlyTransportation: true
  }) as TransportationActivityRow
  const nextTransportationRowOfStage = getNextActivityRow(stageActivityRows, businessActivity.id, {
    returnOnlyTransportation: true
  }) as TransportationActivityRow
  const troubleTicketId = gotoActivity.troubleTicketId || businessActivity.troubleTicketId
  const permissions = transportationRow.permissions
  const gotoActivityStartDateRequired = startActualDayNeedStatuses.includes(gotoActivity.status)
  const businessActivityStartDateRequired = startActualDayNeedStatuses.includes(businessActivity.status)
  const gotoActivityCompleteDateRequired = completionActualDayNeedStatuses.includes(gotoActivity.status)
  const businessActivityCompleteDateRequired = completionActualDayNeedStatuses.includes(businessActivity.status)
  // @ts-ignore
  const linkedGotoActivity: TransportationActivityViewDTO =
    anotherDOOActivityLink &&
    anotherDOOActivityLink.activityId &&
    storeLists.activity[anotherDOOActivityLink.activityId]
  let linkedBusinessActivity: TransportationActivityViewDTO = undefined

  if (linkedGotoActivity) {
    const linkedDispachDeliveryOrder = storeLists.dispatchDeliveryOrder[linkedGotoActivity.dispatchDeliveryOrderId]

    if (isFullObject(linkedDispachDeliveryOrder)) {
      // @ts-ignore
      linkedBusinessActivity = linkedDispachDeliveryOrder.activityIds
        .map((id: string) => storeLists.activity[id])
        .filter(Boolean)
        .find((activity: TransportationActivityViewDTO) => {
          return activity.groupId === linkedGotoActivity.groupId && !isGotoActivityType(activity.type)
        }) as TransportationActivityViewDTO
    }
  }

  React.useEffect(() => {
    if (anotherDOOActivityLink && anotherDOOActivityLink.activityId && !linkedGotoActivity) {
      requestDispatchDeliveryOrderById(anotherDOOActivityLink.dispatchDeliveryOrderId)
    }
  }, [anotherDOOActivityLink && anotherDOOActivityLink.activityId])

  // @ts-ignore
  const updateActivityRow = React.useCallback((...args) => {
    // @ts-ignore
    updateActivityRowRef.current(...args)
  }, [])

  if (
    (isDropBobtail &&
      transportationRow.activityGroup.gotoActivity.comboType === TransportationActivityViewDTO.ComboTypeEnum.EMPTY &&
      !transportationRow.activityGroup.gotoActivity.destinationId) ||
    transportationRow.permissions.ddoWorkingStatus !== DDOWorkingStatus.working ||
    !allowModifying ||
    (oc(currentActivityGroup).gotoActivity.id() !== transportationRow.id &&
      (!permissions.writable || !permissions.fullAccess)) ||
    (lastUnsuccessfulActivityGroup &&
      lastUnsuccessfulActivityGroup.gotoActivity.id !== gotoActivity.id &&
      lastUnsuccessfulActivityGroup.businessActivity.number > gotoActivity.number)
  ) {
    disableActualDates = true
  }

  const gotoActivityStartDateDisabled =
    isLinkedActivityToBobtail ||
    (disableActualDates && !(gotoActivityStartDateRequired && !gotoActivity.startActualDate))
  const gotoActivityCompleteDateDisabled =
    isLinkedActivityToBobtail ||
    (disableActualDates && !(gotoActivityCompleteDateRequired && !gotoActivity.completionActualDate))
  const businessActivityStartDateDisabled =
    Boolean(isLinkedActivityToBobtail && linkedGotoActivity && !linkedGotoActivity.completionActualDate) ||
    (disableActualDates && !(businessActivityStartDateRequired && !businessActivity.startActualDate))
  const businessActivityCompleteDateDisabled =
    Boolean(isLinkedActivityToBobtail && linkedGotoActivity && !linkedGotoActivity.completionActualDate) ||
    (disableActualDates && !(businessActivityCompleteDateRequired && !businessActivity.completionActualDate))

  if (
    // PICKUP
    (businessActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP &&
      [
        // @ts-ignore
        oc(equipment).ownership() === EquipmentDTO.OwnershipEnum.RENTED
          ? TransportationActivityViewDTO.TypeEnum.PICKCHASSIS
          : undefined,
        TransportationActivityViewDTO.TypeEnum.PICKUPFULL,
        TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
      ]
        .filter(Boolean)
        .includes(businessActivity.type)) ||
    // RETURN
    (businessActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN &&
      [
        // @ts-ignore
        oc(equipment).ownership() === EquipmentDTO.OwnershipEnum.RENTED
          ? TransportationActivityViewDTO.TypeEnum.DROPCHASSIS
          : undefined,
        TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
        TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
        TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
        TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
      ]
        .filter(Boolean)
        .includes(businessActivity.type))
  ) {
    enableTIRDocumnet = true
  }

  if (
    // DELIVERY
    businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
    // IMP
    ((deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT &&
      // D&P
      ((loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK &&
        [
          TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
          TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
        ].includes(businessActivity.type) &&
        oc(destination).type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) ||
        // Live load
        (loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD &&
          [TransportationActivityViewDTO.TypeEnum.GETUNLOADED].includes(businessActivity.type)))) ||
      // EXP
      (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT &&
        // D&P
        ((loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK &&
          [TransportationActivityViewDTO.TypeEnum.PICKUPFULL].includes(businessActivity.type) &&
          oc(destination).type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) ||
          // Live load
          (loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD &&
            [TransportationActivityViewDTO.TypeEnum.GETLOADED].includes(businessActivity.type)))))
  ) {
    enablePODDocumnet = true
  }

  const emptyRequiredFieldsForDocument: string = [
    enableTIRDocumnet &&
      !dispatchDeliveryOrder.containerId &&
      !(
        (businessActivity.type === TransportationActivityViewDTO.TypeEnum.PICKCHASSIS &&
          businessActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP) ||
        (businessActivity.type === TransportationActivityViewDTO.TypeEnum.DROPCHASSIS &&
          businessActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN)
      ) &&
      'Container #',
    !businessActivity.vendorId && 'Driver',
    !businessActivity.destinationId && 'Location'
  ]
    .filter(Boolean)
    .join(', ')

  if ((enableTIRDocumnet || enablePODDocumnet) && activityDocumentMessages) {
    activityDocumentMessages
      .slice()
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      .forEach(documentMessage => {
        if (
          documentMessage.metadata &&
          oc(documentMessage).metadata.activities[0].id() === businessActivity.id &&
          documentMessage.attachments
        ) {
          if (!tirDocument) {
            tirDocument = documentMessage.attachments.find(document => document.category === FileCategory.TIR)

            if (tirDocument) {
              tirDocumentMessage = documentMessage
            }
          }
          if (!podDocument) {
            podDocument = documentMessage.attachments.find(document => document.category === FileCategory.POD)

            if (podDocument) {
              podDocumentMessage = documentMessage
            }
          }
        }
      })
  }

  if (
    businessActivity.template &&
    businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
    relatedActivityTypes[TransportationActivityViewDTO.StageEnum.DELIVERY].includes(businessActivity.type)
  ) {
    appDateIsNotConfirmed =
      oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed() === false
  }

  const openActivityDocumentPopup = (view: ActivityDocumentPopupView, ignoreEmptyRequiredFields: boolean) => {
    if (!emptyRequiredFieldsForDocument || ignoreEmptyRequiredFields) {
      dispatch(
        tabActions.mergeExpandedItemData({
          mergeProps: {
            activityDocumentPopup: {
              view,
              activityId: businessActivity.id,
              completionActualDate: businessActivity.completionActualDate,
              troubleTicketId: gotoActivity.troubleTicketId || businessActivity.troubleTicketId,
              validatedManually:
                !gotoActivity.troubleTicketId && !businessActivity.troubleTicketId
                  ? enableTIRDocumnet
                    ? // @ts-ignore
                      oc(tirDocumentMessage).metadata.validation.validatedManually()
                    : enablePODDocumnet
                    ? // @ts-ignore
                      oc(podDocumentMessage).metadata.validation.validatedManually()
                    : undefined
                  : undefined
            }
          }
        })
      )
    } else {
      showModalWindow({
        width: 480,
        title: 'Required fields are empty',
        content: `To Upload or Edit Documents you need to fill required fields (${emptyRequiredFieldsForDocument})`,
        buttons: [{ label: 'Ok' }]
      })
    }
  }

  const setActivityDocumentPopup = (state: IActivityDocumentPopup) => {
    dispatch(
      tabActions.mergeExpandedItemData({
        mergeProps: { activityDocumentPopup: state }
      })
    )
  }

  const updateCompletionActualDate = ({ date, isGotoActivity }: { date?: DateISOString; isGotoActivity: boolean }) => {
    // oc(currentActivityGroup).businessActivity.type() === TransportationActivityViewDTO.TypeEnum.EMPTY
    const modifiedActivityGroup: TransportationActivityGroup = R.clone(transportationRow.activityGroup)

    if (isUnsuccessful) {
      if (isGotoActivity) {
        modifiedActivityGroup.gotoActivity.completionActualDate = date
      } else {
        modifiedActivityGroup.businessActivity.completionActualDate = date
      }
    } else if (isGotoActivity) {
      // GOTO ACTIVITY
      if (date) {
        // Date has been changed
        modifiedActivityGroup.gotoActivity.status = TransportationActivityViewDTO.StatusEnum.COMPLETED

        if (!modifiedActivityGroup.gotoActivity.startActualDate) {
          modifiedActivityGroup.gotoActivity.startActualDate = date
        }

        if (isDropBobtail) {
          modifiedActivityGroup.businessActivity.status = modifiedActivityGroup.gotoActivity.status =
            TransportationActivityViewDTO.StatusEnum.COMPLETED

          if (!modifiedActivityGroup.businessActivity.startActualDate) {
            modifiedActivityGroup.businessActivity.startActualDate = date
          }

          if (!modifiedActivityGroup.businessActivity.completionActualDate) {
            modifiedActivityGroup.businessActivity.completionActualDate = date
          }
        }
      } else {
        // Date has been removed
        if (modifiedActivityGroup.gotoActivity.completionActualDate) {
          modifiedActivityGroup.gotoActivity.status = TransportationActivityViewDTO.StatusEnum.INPROCESS
          modifiedActivityGroup.businessActivity.startActualDate = modifiedActivityGroup.businessActivity.completionActualDate = null
          modifiedActivityGroup.businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
        }
      }

      modifiedActivityGroup.gotoActivity.completionActualDate = date
    } else {
      // BUSINESS ACTIVITY
      if (date) {
        // Date has been changed
        modifiedActivityGroup.businessActivity.status = modifiedActivityGroup.gotoActivity.status =
          TransportationActivityViewDTO.StatusEnum.COMPLETED

        if (!modifiedActivityGroup.gotoActivity.startActualDate) {
          modifiedActivityGroup.gotoActivity.startActualDate = date
        }

        if (!modifiedActivityGroup.gotoActivity.completionActualDate) {
          modifiedActivityGroup.gotoActivity.completionActualDate = date
        }

        if (!modifiedActivityGroup.businessActivity.startActualDate) {
          modifiedActivityGroup.businessActivity.startActualDate = date
        }
      } else {
        // Date has been removed
        if (modifiedActivityGroup.businessActivity.completionActualDate) {
          modifiedActivityGroup.businessActivity.status = TransportationActivityViewDTO.StatusEnum.INPROCESS
        }
      }

      modifiedActivityGroup.businessActivity.completionActualDate = date
    }

    updateActivityRow({ ...transportationRow, activityGroup: modifiedActivityGroup }, ActivityGroupAction.update)
  }

  if (transportationRow.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
    if (isDrop || isDropBobtail) {
      bobtailGroup = getActivityDropBobtailGroup({
        activity: gotoActivity,
        activities: oc(activities).transportationActivities([]),
        includeParentActivityGroup: true
      })

      bobtailGroup = bobtailGroup.length > 1 ? bobtailGroup : undefined
    }

    if (oc(destination).type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) {
      if (isDropActivity(businessActivity) && (!bobtailGroup || bobtailGroup.length <= 3)) {
        dropTypeActivityRowToCreateBobtail = transportationRow
      }

      if (isPickActivity(businessActivity)) {
        pickTypeActivityRowToCreateBobtail = transportationRow
      }

      isDropBobtailValidationError =
        isDropBobtail &&
        !isUnsuccessful &&
        isDropBobtailGotoActivity(
          prevTransportationRowOfStage && prevTransportationRowOfStage.activityGroup.gotoActivity
        ) &&
        !isUnsuccessfulActivityGroup(prevTransportationRowOfStage && prevTransportationRowOfStage.activityGroup)
    }
  }

  return (
    <TransportationActivityRowContext.Provider
      value={{
        isBobtail,
        isDropBobtail,
        isPickBobtail,
        activityDocumentPopup,
        rowNumber,
        gotoActivity,
        businessActivity,
        linkedGotoActivity,
        linkedBusinessActivity,
        stage: transportationRow.stage,
        disabled: !allowModifying || !permissions.writable,
        isUnsuccessful,
        isModifiedMode,
        disableActualDates,
        activityWithWrongOrderOfPlannedDates,
        importDatesConfused,
        exportDatesConfused,
        dispatchDeliveryOrder,
        anotherDOOActivityLink,
        dropTypeActivityRowToCreateBobtail,
        pickTypeActivityRowToCreateBobtail,
        isDropBobtailValidationError,
        isLinkedActivityToBobtail,
        isDropActivity: isDrop,
        isPickActivity: isPick,
        allowModifying,
        transportationRow,
        prevTransportationRowOfStage,
        nextTransportationRowOfStage,
        stageActivityRows,
        rows,
        bobtailGroup,
        usefulRows,
        currentActivityGroup,
        updateActivityRow,
        tirDocument,
        podDocument,
        enablePODDocumnet,
        enableTIRDocumnet,
        disableEditDocument: Boolean(emptyRequiredFieldsForDocument),
        tirDocumentMessage,
        podDocumentMessage,
        troubleTicketId,
        gotoActivityStartDateRequired,
        gotoActivityStartDateDisabled,
        businessActivityStartDateRequired,
        businessActivityStartDateDisabled,
        gotoActivityCompleteDateRequired,
        gotoActivityCompleteDateDisabled,
        businessActivityCompleteDateRequired,
        businessActivityCompleteDateDisabled,
        appDateIsNotConfirmed,
        updateCompletionActualDate,
        modifyDispatchDeliveryOrder,
        openActivityDocumentPopup,
        setActivityDocumentPopup
      }}
      children={children}
    />
  )
})
