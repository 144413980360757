import { oc } from 'ts-optchain'
import { TransportationActivityViewDTO } from '../../../api/api'
import { getStageActivities } from '../get/getStageActivities'

export const isEdgeActivityGroupIdOfStage = (props: {
  edge: 'first' | 'last'
  groupId: string
  stage: TransportationActivityViewDTO.StageEnum
  activities: TransportationActivityViewDTO[]
}): boolean | undefined => {
  const { edge, groupId, stage, activities } = props

  if (!(groupId && stage && Array.isArray(activities) && activities.length)) {
    return undefined
  }

  const sortedStageActivities = getStageActivities(stage, activities)
  const index = edge === 'first' ? 0 : sortedStageActivities.length - 1

  return oc(sortedStageActivities[index]).groupId() === groupId
}
