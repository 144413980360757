import * as React from 'react'
import { SpecialColumn } from './SpecialCokumn'

type Props = {
  isDriverOnline: boolean
  hideETAMilesColumns: boolean
  isTwoActivitiesView: boolean
  distance: number
}

export const MilesColumn = React.memo((props: Props) => {
  const { isDriverOnline, hideETAMilesColumns, isTwoActivitiesView, distance } = props

  if (hideETAMilesColumns) {
    return <div />
  }

  return (
    <SpecialColumn
      title={'MILES'}
      color={isDriverOnline === false && '#989ea6'}
      value={distance ? String(distance.toFixed(2)) : undefined}
      isTwoActivitiesView={isTwoActivitiesView}
    />
  )
})
