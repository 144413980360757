import * as React from 'react'
import * as yup from 'yup'
import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { schemaCustomerLazy, schemaDeliveryStage, schemaDispatchDeliveryOrderGridItem } from '../../yupScheme'
import { schemaDeliveryOrderGridItem } from '../deliveryOrder'
import { checkActivity } from '../activity'
import {
  findActivityWithWrongOrderOfPlannedDate,
  isChassisNumberRequired as isChassisNumberRequiredFn,
  isContainerRequiredForDDO
} from './functions'
import { debuggingMode } from '../../debug'
import { isBobtailGotoActivity } from '../../functions/test/isBobtailGotoActivity'
import { isUnsuccessfulActivity } from '../../functions/test/isUnsuccessfulActivity'
import { isLoadTypeActitiesValid } from '../../functions/test/isLoadTypeActitiesValid'
import { getListsState } from '../../../store'
import { createTab } from '../../../store/reducers/tabs/functions'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { generateTabExpandedItem } from '../../functions/generate/generateTabExpandedItem'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { assembleEntity } from '../../functions/assembleListEntity/assembleEntity'
import { groupActivities } from '../../functions/group/groupActivities'
import { isDeliveryOrderSteamShipLineRequired } from '../../functions/test/isDeliveryOrderSteamShipLineRequired'

const businessLogicValidationDispatchDeliveryOrder = schemaDispatchDeliveryOrderGridItem.shape({
  deliveryStage: yup
    .object()
    .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
      deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? schema.nullable(true).notRequired()
        : schemaDeliveryStage
    ),
  containerTypeId: yup.string().required(),
  hazmatId: yup
    .string()
    .when('$isCargoTypeHazmat', (isCargoTypeHazmat: boolean, schema: yup.StringSchema) =>
      isCargoTypeHazmat ? schema.required() : schema.notRequired().nullable(true)
    ),
  containerId: yup
    .string()
    .when(
      ['$deliveryOrderType', '$dispatchDeliveryOrderStatus'],
      (
        deliveryOrderType: DeliveryOrderViewDTO.TypeEnum,
        dispatchDeliveryOrderStatus: DispatchDeliveryOrderViewDTO.StatusEnum,
        schema: yup.StringSchema
      ) =>
        isContainerRequiredForDDO(deliveryOrderType, dispatchDeliveryOrderStatus)
          ? schema.required()
          : schema.notRequired().nullable(true)
    ),
  deliveryOrder: schemaDeliveryOrderGridItem.shape({
    billOfLadingNumber: yup
      .string()
      .min(4)
      .max(64)
      .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT
          ? schema.required()
          : schema.nullable(true).notRequired()
      ),
    bookingNumber: yup
      .string()
      .min(4)
      .max(64)
      .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT ||
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
          ? schema.required()
          : schema.nullable(true).notRequired()
      ),
    customer: schemaCustomerLazy,
    generalCutoffDate: yup
      .string()
      .nullable(true)
      .notRequired()
  })
})

export const checkDispatchDeliveryOrderValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { parentItemId, modifiedLists } = expandedItemContext
  const fullDispatchDeliveryOrder: DispatchDeliveryOrderViewDTO = assembleEntity({
    deep: true,
    entityId: parentItemId,
    entityType: EntityType.dispatchDeliveryOrder,
    modifiedLists
  })
  const deliveryOrder: DeliveryOrderViewDTO = assembleEntity({
    entityId: oc(fullDispatchDeliveryOrder).deliveryOrderId(),
    entityType: EntityType.deliveryOrder,
    modifiedLists
  })
  const context = {
    dispatchDeliveryOrderStatus: fullDispatchDeliveryOrder.status,
    deliveryOrderType: deliveryOrder.type,
    isCargoTypeHazmat: fullDispatchDeliveryOrder.hazmatIndicator
  }

  const dispatchDeliveryOrders = oc(deliveryOrder)
    .dispatchDeliveryOrderIds([])
    .map(id => assembleEntity({ entityId: id, entityType: EntityType.dispatchDeliveryOrder, modifiedLists }))
    .filter(Boolean)
  const isSteamShipLineRequired = isDeliveryOrderSteamShipLineRequired({ deliveryOrder, dispatchDeliveryOrders })
  const isChassisNumberRequired = isChassisNumberRequiredFn(fullDispatchDeliveryOrder.activities)

  const steamShipLineValueIsCorrect = (): boolean => {
    return Boolean(oc(deliveryOrder).steamShipLineId()) || !isSteamShipLineRequired
  }

  const validateTransportationActivities = (): boolean => {
    return Boolean(fullDispatchDeliveryOrder.activities.transportationActivities.every(checkActivity))
  }

  const validateLoadType = (): boolean => {
    const prevDispatchDeliveryOrderState = getListsState()[EntityType.dispatchDeliveryOrder][
      fullDispatchDeliveryOrder.id
    ]

    return (
      fullDispatchDeliveryOrder.loadType !== oc(prevDispatchDeliveryOrderState).loadType() ||
      isLoadTypeActitiesValid({
        dispatchDeliveryOrder: fullDispatchDeliveryOrder,
        deliveryOrderType: oc(deliveryOrder).type(),
        activityGroups: groupActivities(fullDispatchDeliveryOrder.activities)
      })
    )
  }

  if (debuggingMode.common) {
    let validateDDO: any = true

    businessLogicValidationDispatchDeliveryOrder
      .validate(fullDispatchDeliveryOrder, { context })
      .catch(e => {
        // getStore().dispatch(
        //   addServerMessage({
        //     type: 'error',
        //     message: e.message
        //   })
        // )

        validateDDO = e
      })
      .finally(() => {
        let validationResult: any = {
          validateDDO,
          validateTransportationActivities: validateTransportationActivities(),
          validateLoadType: validateLoadType(),
          steamShipLineValueIsCorrect: steamShipLineValueIsCorrect(),
          isChassisNumberRequired
        }

        validationResult = Object.keys(validationResult).reduce((acc, currKey) => {
          if (validationResult[currKey] !== true) {
            // @ts-ignore
            acc[currKey] = validationResult[currKey]
          }
          return acc
        }, {})

        if (Object.keys(validationResult).length) {
          // tslint:disable-next-line:no-console
          console.error(`DDO#${fullDispatchDeliveryOrder.number || 'NEW'} validation error`, validationResult)
        }
      })
  }

  return (
    businessLogicValidationDispatchDeliveryOrder.isValidSync(fullDispatchDeliveryOrder, { context }) &&
    (!isChassisNumberRequired || fullDispatchDeliveryOrder.equipmentId) &&
    validateTransportationActivities() &&
    steamShipLineValueIsCorrect() &&
    !findActivityWithWrongOrderOfPlannedDate(fullDispatchDeliveryOrder.activities.transportationActivities) &&
    fullDispatchDeliveryOrder.activities.transportationActivities.every(activity =>
      (isBobtailGotoActivity(activity) && isUnsuccessfulActivity(activity)) ||
      (activity.type === TransportationActivityViewDTO.TypeEnum.OFFDUTY && !isUnsuccessfulActivity(activity))
        ? activity.destinationId
        : true
    )
  )
}

export const openDDOinNewTab = ({
  ddoId,
  tabName,
  ddoNumber,
  event
}: {
  ddoId: string
  ddoNumber?: number | string
  tabName?: string
  event?: React.MouseEvent<HTMLSpanElement>
}) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const filter = []

  switch (true) {
    case Boolean(ddoNumber):
      filter.push({ column: 'number', value: String(ddoNumber) })
      break
    case Boolean(ddoId):
      filter.push({ column: 'id', value: ddoId })
      break
    default:
  }

  createTab(TabDTO.Type.dispatchDeliveryOrder, {
    label: tabName || 'DDO #' + ddoNumber,
    permissions: {
      sort: false,
      filter: false
    },
    uiSettings: {
      filter
    },
    expandedItem: generateTabExpandedItem({ id: ddoId, entityType: EntityType.dispatchDeliveryOrder })
  })
}
