import * as React from 'react'
import styled from 'styled-components'
import { Icon, IconName } from '../Icon'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'

type Action = { label: string; onClick: () => void; icon?: JSX.Element; iconClassName?: string }

type Props = {
  title: string | JSX.Element
  onCloseButtonClick: () => void
  closeButtonId: string
  extraLeftBlock?: JSX.Element
  extraRightBlock?: JSX.Element
  extraBottomBlock?: JSX.Element
  actions?: Action[]
  closeButtonIcon?: IconName
  hideCloseButton?: boolean
}

export const DetailsViewHeader = React.memo((props: Props) => {
  const {
    title,
    extraLeftBlock,
    extraRightBlock,
    actions,
    onCloseButtonClick,
    extraBottomBlock,
    closeButtonId,
    closeButtonIcon,
    hideCloseButton
  } = props
  const { statePopover, wrapperRef, setPopoverState } = useOutsideClick()
  const hasExtraBlock = Boolean(extraLeftBlock || extraRightBlock)

  return (
    <DetailsViewHeaderContainer>
      <DetailsViewHeaderTopBlockContainer>
        {typeof title === 'string' ? <DetailsViewHeaderName>{title}</DetailsViewHeaderName> : title}
        {Boolean(extraLeftBlock) && <LeftBlock>{extraLeftBlock}</LeftBlock>}
        {Boolean(extraRightBlock) && <RightBlock>{extraRightBlock}</RightBlock>}
        <Actions style={hasExtraBlock ? undefined : { marginLeft: 'auto' }}>
          {Boolean(actions && actions.length) && (
            <DotsActions ref={wrapperRef}>
              <Icon icon={IconName.DOTS} fontSize={20} onClick={() => setPopoverState(_ => !_)} />
              {statePopover && (
                <DotsActionsPopover>
                  {actions.map(({ label, iconClassName, icon, onClick }) => (
                    <DotsAction
                      key={label}
                      onClick={() => {
                        setPopoverState(false)
                        onClick()
                      }}
                    >
                      {icon}
                      {iconClassName && <DotsActionIcon className={iconClassName} />}
                      {label}
                    </DotsAction>
                  ))}
                </DotsActionsPopover>
              )}
            </DotsActions>
          )}
          {!hideCloseButton && (
            <Icon
              icon={closeButtonIcon || IconName.CLOSE}
              fontSize={20}
              onClick={onCloseButtonClick}
              options={{ ['data-id']: `closeChatChannel-${closeButtonId}` }}
            />
          )}
        </Actions>
      </DetailsViewHeaderTopBlockContainer>
      {extraBottomBlock}
    </DetailsViewHeaderContainer>
  )
})

export const DetailsViewHeaderContainer = styled.div`
  flex-shrink: 0;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 15px;
`
export const DetailsViewHeaderTopBlockContainer = styled.div`
  height: 47px;
  display: flex;
  align-items: center;
`

export const DetailsViewHeaderName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-right: 10px;
  user-select: none;
`
const LeftBlock = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: auto;
`
const RightBlock = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: auto;
`
const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

const DotsActions = styled.div`
  flex-shrink: 0;
  position: relative;
`
const DotsActionsPopover = styled.div`
  z-index: 1;
  min-width: 150px;
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(68, 83, 102, 0.3);
  padding: 4px 0;
  user-select: none;
`
const DotsAction = styled.div`
  min-height: 18px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`
const DotsActionIcon = styled.div`
  font-size: 18px;
  margin: 0 7px;
`
