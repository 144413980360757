import * as React from 'react'
import styled from 'styled-components'
import { AllUnreadHeader } from './AllUnreadHeader'
import { AllUnreadBody } from './AllUnreadBody'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelsMeta } from '../../../../store/select/communicationHubSelect'
import { IChannelMeta } from '../../../../store/reducers/communicationHub/interfaces'
import { ApplicationSizeProvider } from '../../../../providers/ApplicationSizeProvider'
import { number } from 'yup'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const AllUnread = React.memo((props: Props) => {
  const channelsMeta = useAppSelector(selectCommunictaionHubChannelsMeta)
  const { unreadMessagesCount, channelMetaListWithNewMessages } = React.useMemo(() => {
    const result = {
      unreadMessagesCount: 0,
      channelMetaListWithNewMessages: [] as IChannelMeta[]
    }

    if (channelsMeta) {
      Object.values(channelsMeta).forEach(channelMeta => {
        if (channelMeta) {
          const hasNewMessages = !channelMeta.mute && channelMeta.count
          const hasNewMentionedMessages = channelMeta.mentionMsgIds && channelMeta.mentionMsgIds.length

          if (hasNewMessages) {
            result.unreadMessagesCount += channelMeta.count
          }

          if (hasNewMessages || hasNewMentionedMessages) {
            result.channelMetaListWithNewMessages.push(channelMeta)
          }
        }
      })
    }

    return result
  }, [channelsMeta])

  return (
    <>
      <AllUnreadHeader hasUnreadMessages={Boolean(unreadMessagesCount)} />
      {unreadMessagesCount ? (
        <ApplicationSizeProvider rerenderOnResize={true}>
          <AllUnreadBody channelMetaListWithNewMessages={channelMetaListWithNewMessages} />
        </ApplicationSizeProvider>
      ) : (
        <Placeholder>No Unread Messages</Placeholder>
      )}
    </>
  )
})

const Placeholder = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d8d8d8;
  font-size: 14px;
  user-select: none;
`
