import * as React from 'react'
import { IQMPFilters, IQMPTabData, QMPTab } from '../../interfaces'
import { Content } from './styles'
import { Rate } from '../Tabs/Rate'
import { CustomerQuote } from '../Tabs/CustomerQuote'
import { Rules } from '../Tabs/Rules'

type Props = {
  tabData: IQMPTabData
  filters: IQMPFilters
}

export const Body = React.memo((props: Props) => {
  const {
    tabData: {
      activeTab,
      ratesTabVisited,
      customerQuotesTabVisited,
      extraData,
      ssqIds,
      bsqIds,
      newSSQRates,
      newBSQRates,
      newCustomerQuotes
    },
    filters
  } = props

  return (
    <Content>
      {activeTab === QMPTab.RATE && (
        <Rate
          visited={ratesTabVisited}
          extraData={extraData[activeTab]}
          filters={filters}
          ssqIds={ssqIds}
          bsqIds={bsqIds}
          newSSQRates={newSSQRates}
          newBSQRates={newBSQRates}
        />
      )}
      {activeTab === QMPTab.CUSTOMERQUOTE && (
        <CustomerQuote
          visited={customerQuotesTabVisited}
          extraData={extraData[activeTab]}
          filters={filters}
          newCustomerQuotes={newCustomerQuotes}
        />
      )}
      {activeTab === QMPTab.RULES && <Rules />}
    </Content>
  )
})
