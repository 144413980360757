import { DriverDTO, DriverViewDTO } from '../../../api/api'
import { groupedDriverStatuses } from '../../../constants/driverStatuses'

type DriverFieldRequired = {
  licenseDocumentFile: boolean
  medicalExamCardDocumentFile: boolean
  w9DocumentFile: boolean
  i9DocumentFile: boolean
  applicationDocumentFile: boolean
  leaseDocumentFile: boolean
  twicDocumentFile: boolean
  portCredentialsDocumentFile: boolean
  licenseBackDocumentFile: boolean
  email: boolean
  ssn: boolean
  birthday: boolean
  licenseAddress: boolean
  twicExpDate: boolean
  portCredentialsExpDate: boolean
  medicalExamCardExpDate: boolean
  powerUnitId: boolean
  bankingDetails: boolean
}

export const isDriverFieldRequired = (driver: DriverViewDTO): DriverFieldRequired => {
  if (!driver) {
    return {} as DriverFieldRequired
  }

  const isDriver =
    driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR ||
    driver.driverType === DriverViewDTO.DriverTypeEnum.COMPANY

  const isDriverStatusReady = [
    ...groupedDriverStatuses.Active,
    ...groupedDriverStatuses.Inactive.filter(s => s !== DriverDTO.StatusEnum.TERMINATED),
    DriverDTO.StatusEnum.CANDIDATEAPPROVED
  ].includes(driver.status)

  return {
    get licenseDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get medicalExamCardDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get w9DocumentFile() {
      return (
        (driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR ||
          (driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && !Boolean(driver.parentId))) &&
        isDriverStatusReady
      )
    },
    get i9DocumentFile() {
      return driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && isDriverStatusReady
    },
    get applicationDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get leaseDocumentFile() {
      return driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && isDriverStatusReady
    },
    get licenseBackDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get twicDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get portCredentialsDocumentFile() {
      return isDriver && isDriverStatusReady
    },
    get email() {
      return isDriver && isDriverStatusReady
    },
    get ssn() {
      return isDriver && isDriverStatusReady
    },
    get birthday() {
      return isDriver
    },
    get licenseAddress() {
      return isDriver && isDriverStatusReady
    },
    get twicExpDate() {
      return isDriver && isDriverStatusReady
    },
    get portCredentialsExpDate() {
      return isDriver && isDriverStatusReady
    },
    get medicalExamCardExpDate() {
      return isDriver && isDriverStatusReady
    },
    get powerUnitId() {
      return driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && isDriverStatusReady
    },
    get bankingDetails() {
      return isDriver && isDriverStatusReady
    }
  }
}
