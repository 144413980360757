import * as React from 'react'
import { oc } from 'ts-optchain'
import { DeliveryOrderTabContext } from '../contexts/DeliveryOrderTabContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useDeliveryOrder } from '../hooks/useDeliveryOrder'
import { useLocation } from '../hooks/useLocation'
import { useCustomer } from '../hooks/useCustomer'
import { useSteamShipLine } from '../hooks/useSteamShipLine'
import { useDispatchDeliveryOrders } from '../hooks/useDispatchDeliveryOrders'
import { isDeliveryOrderSteamShipLineRequired } from '../services/functions/test/isDeliveryOrderSteamShipLineRequired'
import { requestDeliveryOrderById } from '../components/common/deliveryOrder/epics'
import { isFullObject } from '../services/functions/test/isFullObject'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const DeliveryOrderTabProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const deliveryOrder = useDeliveryOrder({ id, modifiedLists })
  const pickupLocation = useLocation({ id: oc(deliveryOrder).pickupLocationId(), modifiedLists })
  const deliveryLocation = useLocation({ id: oc(deliveryOrder).deliveryLocationId(), modifiedLists })
  const returnLocation = useLocation({ id: oc(deliveryOrder).returnLocationId(), modifiedLists })
  const customer = useCustomer({ id: oc(deliveryOrder).customerId(), modifiedLists })
  const steamShipLine = useSteamShipLine({ id: oc(deliveryOrder).steamShipLineId(), modifiedLists })
  const dispatchDeliveryOrders = useDispatchDeliveryOrders({
    dispatchDeliveryOrderIds: oc(deliveryOrder).dispatchDeliveryOrderIds([]),
    modifiedLists
  })
  const isSteamShipLineRequired = isDeliveryOrderSteamShipLineRequired({ deliveryOrder, dispatchDeliveryOrders })

  React.useEffect(() => {
    if (!isFullObject(deliveryOrder)) {
      requestDeliveryOrderById(id)
    }
  }, [id])

  return (
    <DeliveryOrderTabContext.Provider
      value={{
        deliveryOrder,
        pickupLocation,
        deliveryLocation,
        returnLocation,
        customer,
        steamShipLine,
        isSteamShipLineRequired
      }}
      children={children}
    />
  )
})
