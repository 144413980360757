import * as R from 'remeda'
import { callAPI, customerAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { CustomerViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { isNewObject } from '../../../../services/DTO'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'

export const requestCustomerById = (id: string): Promise<CustomerViewDTO> =>
  callAPI(customerAPI.findById, id)
    .toPromise()
    .then(async customer => {
      await pushListItemsToStore({ update: { [EntityType.customer]: [customer] } })

      return customer
    })

export const requestCustomerIds = async (customerIds: string[]): Promise<CustomerViewDTO[]> => {
  if (!(customerIds && customerIds.length)) {
    return []
  }

  const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(customerIds)
  const requestedCustomers: CustomerViewDTO[] = []

  if (!arrayOfRequestedIdsArrays.length) {
    return []
  }

  await Promise.all(
    arrayOfRequestedIdsArrays.map(ids =>
      requestCustomers({ filter: 'id%%' + ids.join(',') }).then(customers => {
        requestedCustomers.push(...customers)
      })
    )
  ).catch(() => {})

  if (requestedCustomers.length) {
    await pushListItemsToStore({ update: { [EntityType.customer]: requestedCustomers } })
  }

  return requestedCustomers
}

export const requestCustomers = ({ sort, filter }: any): Promise<CustomerViewDTO[]> => {
  return callAPI(customerAPI.findAll, filter, sort)
    .toPromise()
    .then(async customers => {
      if (customers.length) {
        await pushListItemsToStore({ update: { [EntityType.customer]: customers } })
      }

      return customers
    })
    .catch(catchListErrors)
}

export const requestCustomersByTerm = (term: string) => {
  return callAPI(customerAPI.search, null, term.trim()).toPromise()
}

const omitCustomerProps: (keyof CustomerViewDTO)[] = ['subClients', 'businessPartner']

export const requestCreateCustomer = (customer: CustomerViewDTO): Promise<CustomerViewDTO> => {
  return callAPIWithErrorMessage(customerAPI.create, R.omit(
    customer,
    omitCustomerProps.concat('id')
  ) as CustomerViewDTO).then(async requestedCustomer => {
    await pushListItemsToStore({ update: { [EntityType.customer]: [requestedCustomer] } })

    return requestedCustomer
  })
}

export const requestUpdateCustomer = (customer: CustomerViewDTO): Promise<CustomerViewDTO> => {
  if (isNewObject(customer)) {
    return requestCreateCustomer(customer)
  }

  return callAPIWithErrorMessage(customerAPI.update, customer.id, R.omit(
    customer,
    omitCustomerProps
  ) as CustomerViewDTO).then(async requestedCustomer => {
    await pushListItemsToStore({ update: { [EntityType.customer]: [requestedCustomer] } })

    return requestedCustomer
  })
}
