import * as React from 'react'
import { IChannelMeta } from '../../../../store/reducers/communicationHub/interfaces'
import { AllUnreadChannelHeader } from './AllUnreadChannelHeader'

type Props = {
  channelMeta: IChannelMeta
  isExpanded: boolean
  setExpandedChannelId: React.Dispatch<React.SetStateAction<string>>
  markReadSecondsLeft: number
  setMarkRead: () => void
  clearMarkRead: () => void
}

export const AllUnreadChannel = React.memo((props: Props) => {
  const { channelMeta, isExpanded, setExpandedChannelId, markReadSecondsLeft, setMarkRead, clearMarkRead } = props

  return (
    <AllUnreadChannelHeader
      isExpanded={isExpanded}
      setExpandedChannelId={setExpandedChannelId}
      channelMeta={channelMeta}
      markReadSecondsLeft={markReadSecondsLeft}
      setMarkRead={setMarkRead}
      clearMarkRead={clearMarkRead}
    />
  )
})
