import { OngoingActivityGroupDTO } from '../../../../api/api'
import { dateService } from '../../../../services/timeService'
import { sortOngoingActivitiesByGroupDate } from './sortOngoingActivitiesByGroupDate'

type TGroupOngoingActivitiesByDate = {
  [date: string]: OngoingActivityGroupDTO[]
}

type TGroupOngoingActivitiesByDateWithSeparateMissed = {
  ongoingActivityDateGroups: TGroupOngoingActivitiesByDate
  missedActivities: OngoingActivityGroupDTO[]
}

export const groupOngoingActivitiesByDate = (
  ongoingActivities: OngoingActivityGroupDTO[],
  separateMissedActivities?: boolean
): TGroupOngoingActivitiesByDateWithSeparateMissed => {
  const sortedOngoingActivitiesByGroupDate = sortOngoingActivitiesByGroupDate(ongoingActivities)
  const today = dateService.createStringDate.startDay

  return sortedOngoingActivitiesByGroupDate.reduce(
    (acc, ongoingActivity) => {
      if (separateMissedActivities && today > ongoingActivity.activityGroupDate) {
        if (
          !acc.missedActivities.some(
            missedActivity => missedActivity.dispatchDeliveryOrderId === ongoingActivity.dispatchDeliveryOrderId
          )
        ) {
          acc.missedActivities.push(ongoingActivity)
        }
      } else {
        const groupDay = dateService.getStringDay(ongoingActivity.activityGroupDate)

        if (!acc.ongoingActivityDateGroups[groupDay]) {
          acc.ongoingActivityDateGroups[groupDay] = []
        }

        acc.ongoingActivityDateGroups[groupDay].push(ongoingActivity)
      }

      return acc
    },
    {
      ongoingActivityDateGroups: {},
      missedActivities: []
    }
  )
}
