import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { ActivityGroup, TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { filterUsefulActivityGroups } from '../filter/filterUsefulActivityGroups'
import { isStageStreetTurn } from '../test/isStageStreetTurn'
import { isShuttleStageActivityGroup } from '../test/isShuttleStageActivityGroups'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroups: ActivityGroup[]
}

export const getShuttleByStages = (props: Props): Record<TransportationActivityViewDTO.StageEnum, boolean> => {
  const { dispatchDeliveryOrder, deliveryOrderType, activityGroups } = props
  const result = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: false,
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: false,
    [TransportationActivityViewDTO.StageEnum.RETURN]: false
  }

  if (![DeliveryOrderViewDTO.TypeEnum.IMPORT, DeliveryOrderViewDTO.TypeEnum.EXPORT].includes(deliveryOrderType)) {
    return result
  }

  const usefulTransportationActivityGroups: TransportationActivityGroup[] = filterUsefulActivityGroups(
    activityGroups,
    true
  ) as TransportationActivityGroup[]
  const ddoPickupLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
  const ddoReturnLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
  const checkStages = [TransportationActivityViewDTO.StageEnum.PICKUP, TransportationActivityViewDTO.StageEnum.RETURN]

  checkStages.forEach((stage: any) => {
    result[stage] =
      !isStageStreetTurn({ stage, deliveryOrderType, ddoStreetTurnId: dispatchDeliveryOrder.streetTurnId }) &&
      isShuttleStageActivityGroup({
        stage,
        deliveryOrderType,
        ddoReturnLocationId,
        ddoPickupLocationId,
        usefulTransportationActivityGroups
      })
  })

  return result
}
