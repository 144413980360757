import styled from 'styled-components'
import theme from '../../../../../../../../../../../styles/theme'

export const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 25px;
`
export const TroubleTicketButton = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: white;
  background-color: ${theme.colors.defaultRed};
  border-radius: 100px;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  span {
    font-size: 18px;
    margin-right: 5px;
  }
`

// POPUP
export const Attachments = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin: 16px 0;
`
export const Image = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`
export const Line = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-top: auto;
`
export const Details = styled.div`
  min-height: 202px;
  display: flex;
  flex-direction: column;
`
export const Note = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 202px;

  .editor-container {
    box-shadow: none;
  }

  .public-DraftEditor-content {
    height: 92px;
  }

  .files-container {
    grid-template-columns: 1fr 1fr;
  }
`
export const ReportInfo = styled.div`
  padding-top: 12px;
`
export const User = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;

  &:before {
    font-size: 24px;
    color: #727272;
  }
`
export const UserName = styled.div`
  margin: 0 6px;
`
export const CreatedAt = styled.div`
  opacity: 0.7;
`
// ACTIONS
export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-top: auto;
  user-select: none;
`
export const Back = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:before {
    font-size: 24px;
  }
`
export const ButtonClose = styled.div`
  color: ${theme.colors.basicBlueColor};
  padding: 6px;
  margin: 0 10px 0 auto;
  cursor: pointer;
`
export const LabelResolve = styled.span`
  height: 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-left: 5px !important;
  padding-right: 0 !important;
  overflow: hidden;

  &:before {
    font-size: 18px;
    margin: 0 0 0 5px;
  }
`
export const NoteTextarea = styled.textarea`
  flex-grow: 1;
  width: 360px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 25px;
  color: #6c707d;

  ::placeholder {
    color: #333333;
    opacity: 0.3;
  }
`
