import * as crypto from 'crypto'
import {
  getItemFromStorage,
  removeItemFromStorage,
  saveItemToStorage,
  StorageKeys
} from '../storageService/storageService'
import { AUTH_API_BASE_PATH } from '../../api/authApi'

const hashSha256 = (str: string) =>
  crypto
    .createHash('sha256')
    .update(str)
    .digest()

const base64URLEncode = (buffer: Buffer) =>
  buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')

const encodeJson = (obj: any) =>
  Object.keys(obj)
    .filter(k => obj.hasOwnProperty(k))
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&')

export const AuthConfig = {
  client_id: process.env.AUTH_CLIENT_ID,
  redirect_uri: process.env.BASE_URL,
  audience: process.env.AUTH_AUDIENCE
}

interface IAuthorizationHeaders {
  readonly 'content-type': string
}

export const AUTHORIZATION_HEADERS: IAuthorizationHeaders = {
  'content-type': `application/x-www-form-urlencoded`
}

export enum AuthorizationGrantTypes {
  Password = 'password',
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code'
}

export const AuthCreateChallenge = () => {
  const AuthVerifier = base64URLEncode(crypto.randomBytes(32))
  saveItemToStorage(StorageKeys.AuthVerifier, AuthVerifier)
  return base64URLEncode(hashSha256(AuthVerifier))
}

export const AuthGetVerifier = () => getItemFromStorage(StorageKeys.AuthVerifier)

const AutoCreateState = () => {
  const state = base64URLEncode(crypto.randomBytes(20))
  saveItemToStorage(StorageKeys.AuthState, state)
  return state
}

const clearStorageAuthToken = () => {
  removeItemFromStorage(StorageKeys.RefreshToken)
  removeItemFromStorage(StorageKeys.AccessToken)
  removeItemFromStorage(StorageKeys.IdToken)
}

export const authRedirect = () => {
  clearStorageAuthToken()

  window.location.replace(
    `${AUTH_API_BASE_PATH}/realms/${AuthConfig.audience}/protocol/openid-connect/auth/?${encodeJson({
      response_type: 'code',
      code_challenge: AuthCreateChallenge(),
      code_challenge_method: 'S256',
      client_id: AuthConfig.client_id,
      redirect_uri: AuthConfig.redirect_uri,
      state: AutoCreateState(),
      scope: 'openid profile email offline_access'
    })}`
  )
}

export const authLogout = () => {
  clearStorageAuthToken()

  window.location.replace(
    `${AUTH_API_BASE_PATH}/realms/${AuthConfig.audience}/protocol/openid-connect/logout?${encodeJson({
      redirect_uri: AuthConfig.redirect_uri
    })}`
  )
}
