import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { Window } from './preview'
import { Popover } from './popover'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { useCustomer } from '../../../../../hooks/useCustomer'
import { isCustomerValid } from '../../../../../services/functions/test/isCustomerValid'
import { useBusinessPartner } from '../../../../../hooks/useBusinessPartner'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { useContact } from '../../../../../hooks/useContact'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'

type OwnProps = {
  id: string
  changeCustomerId: (customerId: string | undefined) => void
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const CustomerWidget = (props: Props) => {
  const { id, changeCustomerId } = props
  const { enableEditing } = useGridItemDDO()
  const { modifiedLists } = useExpandedItem()
  const customer = useCustomer({ id, modifiedLists })
  const businessPartner = useBusinessPartner({ id: oc(customer).businessPartnerId(), modifiedLists })
  const primaryContact = useContact({ id: oc(businessPartner).primaryContactId(), modifiedLists })
  const isModifiedMode = Boolean(
    oc(modifiedLists)[EntityType.customer][id]() ||
      oc(modifiedLists)[EntityType.businessPartner][oc(businessPartner).id()]() ||
      oc(modifiedLists)[EntityType.contact][oc(primaryContact).id()]()
  )

  return (
    <Widget
      title={'Customer'}
      highlighted={!customer || (isFullObject(customer) && !isCustomerValid(customer))}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        title: 'Customer',
        value: oc(businessPartner).legalName('') || oc(customer).name('')
      }}
      detailsProps={{
        disabled: enableEditing === false,
        customer,
        businessPartner,
        primaryContact,
        changeCustomerId,
        isModifiedMode
      }}
    />
  )
}

export default CustomerWidget
