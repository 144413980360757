import { boolean } from 'yup'

interface IThemeFontFamily {
  [blockName: string]: 'Rubik'
}

export interface IStyledComponentsProps {
  loading?: boolean
  valid?: boolean
  fixedWidth?: boolean
  lessZero?: boolean
  noValue?: boolean
  disabled?: boolean
  isColumn?: boolean
  icon?: string
  addString?: string
  money?: boolean
  cancel?: boolean
  active?: boolean
  open?: boolean
  styled?: string
  isRequired?: boolean
  isTable?: boolean
  number?: number
  zIndex?: number
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  maxHeight?: number
  width?: number
  background?: string
  activeInput?: boolean
  right?: number
  left?: number
  top?: number
  bottom?: number
  padding?: {
    top?: number
    left?: number
    bottom?: number
    right?: number
  }
  margin?: {
    top?: number
    left?: number
    bottom?: number
    right?: number
  }
  fontSize?: number
  fontWeight?: number
  maxVisibleItems?: number
  edit?: boolean
}

export interface ITheme {
  fontFamily: IThemeFontFamily
  fontSize: {
    base: number
    header: number
  }
  colors: {
    offline: string
    doubleMove: string
    gray: string
    white: string
    lightgrey: string
    tableBorder: string
    grey: string
    black: string
    blue: string
    titleColor: string
    blueColor: string
    borderColor: string
    green: string
    lightGrey: string
    violet: string
    basicBlueColor: string
    basicGreyColor: string
    basicBorderColor: string
    darkBorderColor: string
    darkGreyColor: string
    basicBackgroundColor: string
    lightGrayBackground: string
    spaceGray: string
    defaultGray: string
    header: string
    adminHeader: string
    defaultRed: string
    rowBorder: string
    charade: string
    oxfordBlue: string
    orange: string
    generalBorder: string
    rowHoverColor: string
  }
  screen: {
    sm: string
    md: string
    lg: string
    xlg: string
  }
  zIndex: {
    header: number
    popover: number
    communication: number
  }
  elemSize: {
    gridHeight: string
    headerHeight: string
    tabHeight: string
    communication: {
      sectionWidth: string
      headerHeight: string
      sectionContentWidth: string
    }
    orderDetailsHeight: string
  }
  orderStatus: {
    new: string
    pending: string
    orderColor: string
    'ready-for-posting': string
    posted: string
    'hot-load': string
    'bids-received': string
    'assigned-to-carrier': string
    'signed-by-carrier': string
    'pick-up-scheduled': string
    'pick-up-confirmed': string
    'delivery-scheduled': string
    delivered: string
    'pod-received': string
    'client-claim': string
    'pending-resolution': string
    canceled: string
    archived: string
  }
}

const baseFontSize = 16
const headerFontSize = 16

export const theme: ITheme = {
  fontFamily: {
    communication: 'Rubik'
  },
  fontSize: {
    base: baseFontSize,
    header: headerFontSize
  },
  colors: {
    offline: '#F29640',
    doubleMove: '#4CA36F',
    gray: '#9BA8B8',
    white: '#fff',
    lightgrey: '#f8f8f8',
    grey: '#797a7e',
    black: '#000',
    blue: '#6973f6',
    titleColor: '#2e4a82',
    blueColor: '#4b83f0',
    borderColor: '#d2d5d9',
    green: '#93BA7B',
    lightGrey: '#dfe2e6',
    violet: '#5b37a7',
    tableBorder: '#e3e6f1',
    basicBlueColor: '#4555d0',
    basicGreyColor: '#999999',
    basicBorderColor: '#f2f2f2',
    darkBorderColor: '#e5e5e5',
    darkGreyColor: '#6c707d',
    basicBackgroundColor: '#f5f6fa',
    lightGrayBackground: '#fafbfd',
    spaceGray: '#d7dae6',
    defaultGray: '#a7abbc',
    header: '#323e4e',
    defaultRed: '#ff5e5e',
    rowBorder: '#eaeaea',
    charade: '#242a32',
    oxfordBlue: '#3b4756',
    adminHeader: '#212e4e',
    generalBorder: '#e3e6f1',
    rowHoverColor: 'rgb(227, 237, 253)',
    orange: '#E26D3A'
  },
  screen: {
    sm: 576 / baseFontSize + 'em',
    md: 868 / baseFontSize + 'em',
    lg: 992 / baseFontSize + 'em',
    xlg: 1200 / baseFontSize + 'em'
  },
  zIndex: {
    communication: 20,
    header: 20,
    popover: 10
  },
  elemSize: {
    gridHeight: 'calc(100vh - 85px)',
    headerHeight: 50 + 'px',
    tabHeight: 35 + 'px',
    communication: {
      headerHeight: 61 + 'px',
      sectionWidth: 215 + 'px',
      sectionContentWidth: 490 + 'px'
    },
    orderDetailsHeight: 353 + 'px'
  },
  orderStatus: {
    new: '#B8CCE8',
    pending: '#8FACD5',
    orderColor: '#B8CCE8',
    'ready-for-posting': '#668BBF',
    posted: '#4C71A6',
    'hot-load': '#2C4B76',
    'bids-received': '#F4DD74',
    'assigned-to-carrier': '#CAAF30',
    'signed-by-carrier': '#947F1E',
    'pick-up-scheduled': '#D3B0EA',
    'pick-up-confirmed': '#AF8AC8',
    'delivery-scheduled': '#8232B7',
    delivered: '#4A1F66',
    'pod-received': '#B7D9A2',
    'client-claim': '#93BA7B',
    'pending-resolution': '#527C38',
    canceled: '#2E5714',
    archived: '#193905'
  }
}

export default theme
