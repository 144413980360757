import { RootState } from '..'
import { RuleSearchDTO } from '../../api/origin/qmp-service'

// SELECT ONE
export const selectRule = (id: string) => (state: RootState): RuleSearchDTO | undefined => {
  return state.lists.rule[id]
}

export const selectIsRulesLoaded = (state: RootState): boolean => {
  return Boolean(Object.keys(state.lists.rule).length)
}

// SELECT MANY
export const selectRules = (state: RootState): Record<string, RuleSearchDTO> => {
  return state.lists.rule
}
