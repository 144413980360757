import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrder } from './dispatchDeliveryOrder'
import { Container, Column } from '../../../../UI/GridSystem'
import { AddRow } from '../../../dispatchDeliveryOrder/views/Details/Activities/styledComponents'
import { DeliveryOrderViewDTO } from '../../../../../api/api'
import { Columns } from '../../../dispatchDeliveryOrder/views/Details/Activities/Table/styles'
import { Title } from './styles'
import { isNewObject } from '../../../../../services/DTO'
// tslint:disable:max-line-length
import { generateDispatchDeliveryOrderByDeliveryOrder } from '../../../../../services/functions/generate/generateDispatchDeliveryOrder'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { useDeliveryOrderTab } from '../../../../../hooks/useDeliveryOrderTab'
import { requestGridDispatchDeliveryOrders } from '../../../dispatchDeliveryOrder/epics'

type Props = {}

export const DispatchDeliveryOrders = React.memo(() => {
  const { modifyListItems } = useExpandedItem()
  const { deliveryOrder } = useDeliveryOrderTab()
  const [fetching, setFetching] = React.useState(true)
  const [showHazmatColumn, setShowHazmatColumn] = React.useState(false)
  const dispatchDeliveryOrderIds = oc(deliveryOrder).dispatchDeliveryOrderIds([])
  const isImport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION

  React.useEffect(() => {
    if (!isNewObject(deliveryOrder) && dispatchDeliveryOrderIds.length) {
      requestGridDispatchDeliveryOrders({ filter: 'deliveryOrder.id%%' + deliveryOrder.id }, true).finally(() => {
        setFetching(false)
      })
    } else {
      setFetching(false)
    }
  }, [deliveryOrder.id])

  return (
    <>
      <Title>{`Dispatch Delivery Orders ${dispatchDeliveryOrderIds.length}`}</Title>
      <Container isGrid={true} flexClear={true} padding={{ top: 20, left: 20, right: 20, bottom: 200 }}>
        {Boolean(dispatchDeliveryOrderIds.length) && (
          <>
            <Columns className={'darkBg'} style={{ paddingRight: 40, marginBottom: 10 }}>
              <Column style={{ paddingLeft: 10 }} columns={3} isFixed={true}>
                #
              </Column>
              <Column columns={10} className={'required'} isFixed={true}>
                {'Equipment'}
              </Column>
              {(isImport || isRepo) && (
                <Column className={isImport ? 'required' : ''} columns={9} isFixed={true}>
                  Container
                </Column>
              )}
              {isImport && <Column columns={8}>Seal</Column>}
              <Column columns={isExport ? 10 : 8}>Pickup</Column>
              {!isRepo && <Column columns={isExport ? 10 : 8}>Delivery</Column>}
              <Column columns={isExport ? 10 : 8}>Return</Column>
              {!isRepo && (
                <Column columns={11} isFixed={true}>
                  Appointment Date
                </Column>
              )}
              <Column columns={6} isFixed={true}>
                Cargo
              </Column>
              {showHazmatColumn && (
                <Column className={'required'} columns={10} isFixed={true}>
                  Hazmat
                </Column>
              )}
              <Column columns={12} isFixed={true}>
                Weight
              </Column>
            </Columns>

            <div id={'dispatch-delivery-order-list'}>
              {dispatchDeliveryOrderIds.map(dispatchDeliveryOrderId => (
                <DispatchDeliveryOrder
                  key={dispatchDeliveryOrderId}
                  dispatchDeliveryOrderId={dispatchDeliveryOrderId}
                  showHazmatColumn={showHazmatColumn}
                  setShowHazmatColumn={setShowHazmatColumn}
                />
              ))}
            </div>
          </>
        )}

        {deliveryOrder.type && (
          <AddRow
            id={'add-dispatch-delivery-order'}
            className={!dispatchDeliveryOrderIds.length && !fetching ? 'red' : 'darkBg'}
            rows={9}
            alignCenter={true}
            justifyCenter={true}
            margin={{ top: 20, bottom: 20 }}
            onClick={() => {
              const newDispatchDeliveryOrder = generateDispatchDeliveryOrderByDeliveryOrder(deliveryOrder)

              modifyListItems({
                [EntityType.deliveryOrder]: [
                  {
                    ...deliveryOrder,
                    dispatchDeliveryOrderIds: [
                      ...oc(deliveryOrder).dispatchDeliveryOrderIds([]),
                      newDispatchDeliveryOrder.id
                    ]
                  }
                ],
                [EntityType.dispatchDeliveryOrder]: [newDispatchDeliveryOrder]
              })
            }}
          >
            Add Dispatch Delivery Order
          </AddRow>
        )}
      </Container>
    </>
  )
})
