import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { DeliveryOrderViewDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useDeliveryOrder = (props: Props): DeliveryOrderViewDTO => {
  const { id, modifiedLists } = props
  const deliveryOrder = useListItem({ id, type: EntityType.deliveryOrder, modifiedLists })

  return deliveryOrder
}
