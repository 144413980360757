import { listsActions } from '..'
import { getDispatch } from '../../..'
import { EntityType, IListsStateUpdate, TListsStateUpdate } from '../interfaces'
import { parseListItems } from './parseListItems'
import { mergeParsedLists } from './parseListItems/mergeParsedLists'

export const pushListItemsToStore = async (props: IListsStateUpdate): Promise<any> => {
  // console.log('pushListItemsToStore ' + Object.keys(props.update || {}).join(', '), props)
  try {
    const updateEntitiesMapping = props.update || {}
    const updateListTypes = Object.keys(updateEntitiesMapping)
    const payloadToUpdate = Object.keys(EntityType).reduce(
      (acc, key) => {
        acc[key] = []
        return acc
      },
      {} as TListsStateUpdate
    )

    for (const listType of updateListTypes) {
      try {
        const parseList = parseListItems[listType]
        const items = updateEntitiesMapping[listType]

        if (items && items.length) {
          if (parseList) {
            await parseList(items).then(mergeParsedLists(payloadToUpdate))
          } else {
            mergeParsedLists(payloadToUpdate)({ [listType]: items })
          }
        }
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e)
      }
    }

    Object.keys(EntityType).map(key => {
      if (!payloadToUpdate[key].length) {
        delete payloadToUpdate[key]
      }
    })

    getDispatch()(
      listsActions.update({
        update: payloadToUpdate,
        delete: props.delete
      })
    )
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
}
