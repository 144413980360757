import * as React from 'react'
import { PowerUnitDTO, PowerUnitViewDTO } from '../../../../../api/origin/business-logic'
import { AvailabilityColumnSelecter } from '../../../../UI/oldAvailabilitySelecter'
import { useGridItemPowerUnit } from '../../../../../hooks/useGridItemPowerUnit'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'

const dropDownItems = {
  Active: ['Available', 'Not Available (Assigned)', 'Not Available (In-Use)'],
  Inactive: [
    'Expired Registration',
    'Expired Safety Inspection',
    'Expired Maintenance Inspection',
    'Maintenance',
    'Terminated',
    'Other Inactive Reason'
  ]
}

type Props = {}

export const AvailabilityColumn = (props: Props) => {
  const { powerUnit, isExpanded } = useGridItemPowerUnit()
  // @ts-ignore
  const { modifyParentObject } = isExpanded ? useExpandedItem() : {}
  let selectedItem: string

  const selectedValue = () => {
    if (powerUnit.active) {
      powerUnit.availability
        ? (selectedItem = 'Available')
        : powerUnit.unavailabilityReason === PowerUnitDTO.UnavailabilityReasonEnum.ASSIGNED
        ? (selectedItem = 'Not Available (Assigned)')
        : (selectedItem = 'Not Available (In-Use)')
    } else {
      switch (powerUnit.inactiveReason) {
        case PowerUnitDTO.InactiveReasonEnum.EXPIREDREGISTRATION:
          selectedItem = 'Expired Registration'
          break
        case PowerUnitDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION:
          selectedItem = 'Expired Safety Inspection'
          break
        case PowerUnitDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION:
          selectedItem = 'Expired Maintenance Inspection'
          break
        case PowerUnitDTO.InactiveReasonEnum.MAINTENANCE:
          selectedItem = 'Maintenance'
          break
        case PowerUnitDTO.InactiveReasonEnum.TERMINATED:
          selectedItem = 'Terminated'
          break
        case PowerUnitDTO.InactiveReasonEnum.OTHER:
          selectedItem = 'Other Inactive Reason'
          break
        default:
          break
      }
    }

    return selectedItem
  }

  const currentSelectedItem: string = selectedValue()

  return (
    <AvailabilityColumnSelecter
      handleSelect={modifyParentObject ? handleSelect(powerUnit, modifyParentObject, currentSelectedItem) : undefined}
      activeList={dropDownItems.Active}
      inactiveList={dropDownItems.Inactive}
      selectedItem={currentSelectedItem}
      stateActive={powerUnit.active}
    />
  )
}

const handleSelect = (
  powerUnit: PowerUnitViewDTO,
  modifyParentObject: (item: PowerUnitViewDTO) => void,
  selectedItem: any
) => (item: string) => {
  if (item !== selectedItem) {
    let updatedPowerUnit: PowerUnitViewDTO

    switch (item) {
      case 'Available':
        updatedPowerUnit = {
          ...powerUnit,
          active: true,
          inactiveReason: null,
          availability: true,
          unavailabilityReason: null
        }
        break
      case 'Not Available (Assigned)':
        updatedPowerUnit = {
          ...powerUnit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.ASSIGNED
        }
        break
      case 'Not Available (In-Use)':
        updatedPowerUnit = {
          ...powerUnit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INUSE
        }
        break
      case 'Expired Registration':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.EXPIREDREGISTRATION,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Safety Inspection':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Maintenance Inspection':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Maintenance':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.MAINTENANCE,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Terminated':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.TERMINATED,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Other Inactive Reason':
        updatedPowerUnit = {
          ...powerUnit,
          active: false,
          inactiveReason: PowerUnitDTO.InactiveReasonEnum.OTHER,
          availability: false,
          unavailabilityReason: PowerUnitDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      default:
    }

    if (updatedPowerUnit) {
      modifyParentObject(updatedPowerUnit)
    }
  }
}
