import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import equal = require('fast-deep-equal')
import {
  NewRateDTO,
  RateField,
  RateState,
  SellSideQuoteRateGroupDTO,
  RateType,
  IRateFiltersState,
  ICustomerQuoterFilterState
} from '../../../interfaces'
import { BuySideQuoteRateDTO, CustomerQuoteDTO, SellSideQuoteRateDTO } from '../../../../../../api/origin/qmp-service'
import { createFilterRequest } from '../../../../../../services/uiSettingsService/filter'
import { quoteLists } from '../../../../../../services/select/quoteLists'
import { buySideQuoteRateAPI, callAPI, sellSideQuoteRateAPI } from '../../../../../../api/api'

import {
  doBatchRatesSaving,
  getBuySideQuoteRateIds,
  getSellSideQuoteRateIds
} from '../../../../../../services/DTO/rate/epics'
import { RateDTO } from '../../../../../../services/DTO/rate/interfaces'
import { qmpLists } from '../../../../../../services/select/qmpLists'
import { pushListItemsToStore } from '../../../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

type TRateType = SellSideQuoteRateDTO.TypeEnum | BuySideQuoteRateDTO.TypeEnum
type TLoadTypes = SellSideQuoteRateDTO.LoadTypeEnum | BuySideQuoteRateDTO.LoadTypeEnum

export const correctLoadTypeFilter = (rateType: RateType, loadTypes: TLoadTypes[]): TLoadTypes[] => {
  if (!loadTypes) {
    return loadTypes
  }

  const result = loadTypes.filter((loadType: any) => {
    if (rateType === RateType.ssq) {
      return loadType !== BuySideQuoteRateDTO.LoadTypeEnum.DROP && loadType !== BuySideQuoteRateDTO.LoadTypeEnum.PICK
    }

    if (rateType === RateType.bsq) {
      return loadType !== SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK
    }
  })

  return result.length ? result : null
}

export const suitableLoadTypes = (rateType: RateType, loadTypes: TLoadTypes[]): TLoadTypes[] => {
  if (!loadTypes) {
    return loadTypes
  }

  let result = correctLoadTypeFilter(rateType, loadTypes) || []

  if (rateType === RateType.ssq) {
    if (
      loadTypes.includes(BuySideQuoteRateDTO.LoadTypeEnum.DROP) &&
      loadTypes.includes(BuySideQuoteRateDTO.LoadTypeEnum.PICK)
    ) {
      result.push(SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK)
    }
  }

  if (rateType === RateType.bsq) {
    if (loadTypes.includes(SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK)) {
      result.push(BuySideQuoteRateDTO.LoadTypeEnum.DROP, BuySideQuoteRateDTO.LoadTypeEnum.PICK)
    }
  }

  result = R.uniq(result)
  return result.length ? result : null
}

export const validateRate = (
  appliedFilters: IRateFiltersState | ICustomerQuoterFilterState,
  customerQuote: NewRateDTO | CustomerQuoteDTO,
  rateType: RateType
) => (rate: NewRateDTO): boolean => {
  const isCopy = rate.id.indexOf('copied') !== -1

  switch (rate.type) {
    case SellSideQuoteRateDTO.TypeEnum.ALLIN:
    case SellSideQuoteRateDTO.TypeEnum.BASE:
    case SellSideQuoteRateDTO.TypeEnum.TOLLS:
    case SellSideQuoteRateDTO.TypeEnum.PREPULL:
      return Boolean(
        rate.type &&
          rate.effectiveDate &&
          rate.calculationType &&
          rate.amount &&
          (customerQuote ||
            isCopy ||
            getErrorsOfRequiredFilterFieldsForRateType({
              type: rate.type,
              noLoadTypeCorrection: false,
              appliedFilters,
              rateType
            }).length === 0)
      )
    case BuySideQuoteRateDTO.TypeEnum.DEDUCTION:
    case BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER:
      return Boolean(rate.type && rate.calculationType && rate.effectiveDate && (rate.amount || rate.amount === 0))
    default:
      return Boolean(rate.type && rate.calculationType && rate.effectiveDate && rate.amount)
  }
}

export const createRateFilters = (
  rateType: RateType,
  appliedFilters: IRateFiltersState | ICustomerQuoterFilterState,
  isCustomerQuoteParent?: boolean
): string => {
  let extraRequestString = ''
  const template = (column: string, value: any) => (value ? { column, value: value.toString() } : null)

  const normalizeFilterValues = Object.keys(appliedFilters).reduce((acc, filterField) => {
    switch (filterField) {
      case 'id':
      case RateField.ruleIds:
      case RateField.status:
      case RateField.deliveryOrderType:
      case RateField.type: {
        // @ts-ignore
        const list = appliedFilters[filterField]
        if (list && list.length) {
          return [...acc, template(filterField, list.join(','))]
        }
        return acc
      }
      case RateField.loadType: {
        const list = isCustomerQuoteParent
          ? suitableLoadTypes(rateType, appliedFilters[filterField])
          : correctLoadTypeFilter(rateType, appliedFilters[filterField])

        if (list && list.length) {
          return [...acc, template(filterField, list.join(','))]
        }
        return acc
      }
      case RateField.containerTypeId: {
        const val = appliedFilters[filterField]
        return val ? [...acc, template(filterField, Array.isArray(val) ? val.join(',') : val)] : acc
      }
      case RateField.number:
      case RateField.pickupLocationId: {
        const val = appliedFilters[filterField]
        return val ? [...acc, template(filterField, val)] : acc
      }
      case RateField.returnLocationId: {
        const val = appliedFilters[filterField]
        if (isCustomerQuoteParent) {
          return [...acc, template(filterField, val || '%%')]
        }
        return val ? [...acc, template(filterField, val)] : acc
      }
      case RateField.deliveryLocation: {
        const location = appliedFilters[filterField]
        return location ? [...acc, template('deliveryPostalCode', location.postalCode)] : acc
      }
      case RateField.vendorId: {
        const val = appliedFilters[filterField]
        return rateType === RateType.bsq && val
          ? [...acc, template(filterField, Array.isArray(val) ? val.map(item => item.value).join(',') : val)]
          : acc
      }
      case RateField.customerId: {
        const val = appliedFilters[filterField]
        return rateType === RateType.ssq && val
          ? [...acc, template(filterField, Array.isArray(val) ? val.map(item => item.value).join(',') : val)]
          : acc
      }
      case RateField.quoteDate: {
        // @ts-ignore
        const val = appliedFilters[filterField]
        if (val) {
          extraRequestString += ';effectiveDate<' + val + ';expirationDate>' + val
        }
        return acc
      }
      default:
        return acc
    }
  }, [])

  return createFilterRequest(normalizeFilterValues.filter(Boolean)) + extraRequestString
}

enum FilterFieldQuerySelector {
  PickupLocation = '#pickupLocationId .input-container',
  DeliveryLocation = '#deliveryLocation .input-container',
  ReturnLocation = '#returnLocationId .input-container',
  DeliveryOrderType = '#deliveryOrderType .select-container',
  LoadType = '#loadType .select-container'
}

enum FilterFieldMessage {
  PickupLocation = 'Pickup Location filter must be set',
  DeliveryLocation = 'Delivery Location filter must be set for Import and Export',
  DeliveryLocationZIP = 'You must set ZIP code for Delivery location',
  ReturnLocation = 'Return Location filter must be set',
  DeliveryOrderType = 'Import/Export/Reposition filter must be set',
  LoadType = 'Load Type must be set (or set correctly) for Import and Export'
}

type TErrorFields = { querySelector: string; message?: string }

export const getErrorsOfRequiredFilterFieldsForRateType = ({
  rateType,
  type,
  appliedFilters,
  noLoadTypeCorrection
}: {
  noLoadTypeCorrection: boolean
  rateType: RateType
  type: TRateType
  appliedFilters: IRateFiltersState | ICustomerQuoterFilterState
}): TErrorFields[] => {
  const deliveryOrderType = oc(appliedFilters).deliveryOrderType([])
  const isDOTypeEmpty = deliveryOrderType.length === 0
  const isImport = deliveryOrderType.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.IMPORT)
  const isExport = deliveryOrderType.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.EXPORT)
  const isRepo = deliveryOrderType.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION)

  switch (type) {
    case SellSideQuoteRateDTO.TypeEnum.BASE:
    case SellSideQuoteRateDTO.TypeEnum.ALLIN: {
      return [
        // IMP/EXP/REPO is not set && Pickup Location || Return Location is not set
        !deliveryOrderType.length && !appliedFilters.pickupLocationId && !appliedFilters.returnLocationId
          ? {
              querySelector: FilterFieldQuerySelector.PickupLocation + ',' + FilterFieldQuerySelector.ReturnLocation,
              message: 'Pickup Location and Return Location filter must be set for Reposition.'
            }
          : undefined,
        // Pickup Location for Reposition is not set
        isRepo && !appliedFilters.pickupLocationId
          ? { querySelector: FilterFieldQuerySelector.PickupLocation, message: FilterFieldMessage.PickupLocation }
          : undefined,
        // Return Location for Reposition is not set
        isRepo && !appliedFilters.returnLocationId
          ? { querySelector: FilterFieldQuerySelector.ReturnLocation, message: FilterFieldMessage.ReturnLocation }
          : undefined,
        // Delivery Location ZIP is not set
        appliedFilters.deliveryLocation && !appliedFilters.deliveryLocation.postalCode
          ? {
              querySelector: FilterFieldQuerySelector.DeliveryLocation,
              message: FilterFieldMessage.DeliveryLocationZIP
            }
          : undefined,
        // Delivery Location is not set for Import/Export
        (!deliveryOrderType.length || isImport || isExport) && !appliedFilters.deliveryLocation
          ? { querySelector: FilterFieldQuerySelector.DeliveryLocation, message: FilterFieldMessage.DeliveryLocation }
          : undefined,
        // IMP/EXP/REPO is not set
        !deliveryOrderType.length
          ? { querySelector: FilterFieldQuerySelector.DeliveryOrderType, message: FilterFieldMessage.DeliveryOrderType }
          : undefined,
        // Load Type is not set (or is not set correctly) for IMP/EXP/REPO
        (isDOTypeEmpty || isImport || isExport) &&
        !Boolean(
          noLoadTypeCorrection
            ? appliedFilters.loadType && appliedFilters.loadType.length
            : correctLoadTypeFilter(rateType, appliedFilters.loadType)
        )
          ? { querySelector: FilterFieldQuerySelector.LoadType, message: FilterFieldMessage.LoadType }
          : undefined
      ].filter(Boolean)
    }
    case SellSideQuoteRateDTO.TypeEnum.TOLLS: {
      return [
        // Pickup or Return Location must be set
        !appliedFilters.pickupLocationId && !appliedFilters.returnLocationId
          ? {
              querySelector: FilterFieldQuerySelector.PickupLocation + ',' + FilterFieldQuerySelector.ReturnLocation,
              message: 'Pickup Location or Return Location filter must be set'
            }
          : undefined
      ].filter(Boolean)
    }
    case SellSideQuoteRateDTO.TypeEnum.PREPULL: {
      return [
        // Pickup or Return Location must be set
        !appliedFilters.pickupLocationId && !appliedFilters.returnLocationId
          ? {
              querySelector: FilterFieldQuerySelector.PickupLocation + ',' + FilterFieldQuerySelector.ReturnLocation,
              message: 'Pickup Location or Return Location filter must be set'
            }
          : undefined
      ].filter(Boolean)
    }
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLE:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEREEFER:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEOW:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEHAZ: {
      return [
        // IMP/EXP/REPO is not set
        !deliveryOrderType.length
          ? { querySelector: FilterFieldQuerySelector.DeliveryOrderType, message: 'Import/Export filter must be set' }
          : undefined
      ].filter(Boolean)
    }
    case SellSideQuoteRateDTO.TypeEnum.CONGESTION:
      return [
        // Delivery Location ZIP is not set
        appliedFilters.deliveryLocation && !appliedFilters.deliveryLocation.postalCode
          ? {
              querySelector: FilterFieldQuerySelector.DeliveryLocation,
              message: FilterFieldMessage.DeliveryLocationZIP
            }
          : undefined,
        // At least one location must be set
        !appliedFilters.pickupLocationId && !appliedFilters.returnLocationId && !appliedFilters.deliveryLocation
          ? {
              querySelector: [
                FilterFieldQuerySelector.PickupLocation,
                FilterFieldQuerySelector.DeliveryLocation,
                FilterFieldQuerySelector.ReturnLocation
              ].join(','),
              message: 'At least one location must be set'
            }
          : undefined
      ].filter(Boolean)
    default:
      return []
  }
}

export const makeRuleList = (typeOfRate: SellSideQuoteRateDTO.TypeEnum | BuySideQuoteRateDTO.TypeEnum) => {
  switch (typeOfRate) {
    case SellSideQuoteRateDTO.TypeEnum.FUEL:
      return []
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLE:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEREEFER:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEHAZ:
    case SellSideQuoteRateDTO.TypeEnum.SHUTTLEOW:
      return qmpLists.rules.filter(
        (rule: any) =>
          ![
            SellSideQuoteRateDTO.TypeEnum.HAZMAT,
            SellSideQuoteRateDTO.TypeEnum.REEFER,
            SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT,
            'DELIVERY'
          ].includes(rule.label.toUpperCase())
      )
    case SellSideQuoteRateDTO.TypeEnum.HAZMAT:
      return qmpLists.rules.filter((rule: any) => rule.label.toUpperCase() !== SellSideQuoteRateDTO.TypeEnum.HAZMAT)
    case SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT:
      return qmpLists.rules.filter((rule: any) => rule.label.toUpperCase() !== SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT)
    case SellSideQuoteRateDTO.TypeEnum.REEFER:
    case SellSideQuoteRateDTO.TypeEnum.DEDUCTION:
    case SellSideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER:
      return qmpLists.rules.filter((rule: any) => rule.label.toUpperCase() !== SellSideQuoteRateDTO.TypeEnum.REEFER)
    case SellSideQuoteRateDTO.TypeEnum.BOBTAIL:
      // miles only
      return qmpLists.rules.filter((rule: any) =>
        [
          'dcdb3da2-149f-4042-85c3-9e57b8462642',
          '8f4cdca7-a67a-4254-842c-0cded2fd1b9d',
          'c0013165-81e4-4344-bb5b-36307fbe0802'
        ].includes(rule.value)
      )
    default:
      return qmpLists.rules
  }
}

export const makeRateTypesList = (
  appliedFilters: IRateFiltersState | ICustomerQuoterFilterState,
  rateType: RateType,
  isCustomerQuoteParent: boolean
) => {
  const deliveryOrderTypes = oc(appliedFilters).deliveryOrderType([])
  // const isImport = deliveryOrderTypes.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.IMPORT)
  // const isExport = deliveryOrderTypes.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.EXPORT)
  const isRepo = deliveryOrderTypes.includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION)
  let list: any[] = []

  switch (rateType) {
    case RateType.ssq:
      list = quoteLists.ssqTypeOfRate
      break
    case RateType.bsq:
      list = quoteLists.bsqTypeOfRate
      break
    default:
      break
  }

  if (isRepo) {
    const availableRepositionTypes = [
      'BASE',
      'ALL_IN',
      'FUEL',
      'CHASSIS',
      'TOLLS',
      'DETENTION',
      'REDELIVERY',
      'STORAGE',
      'OVERWEIGHT',
      'HAZMAT',
      'REEFER',
      'TANKER',
      'DRY_RUN',
      'PER_DIEM',
      'DEMURRAGE',
      'SCALE',
      'CONGESTION'
    ]
    list = list.filter(({ value }) => availableRepositionTypes.includes(value))
  }

  return list.map(item => {
    const errors = getErrorsOfRequiredFilterFieldsForRateType({
      rateType,
      appliedFilters: appliedFilters as IRateFiltersState,
      type: item.value,
      noLoadTypeCorrection: isCustomerQuoteParent
    })

    if (!errors.length) {
      return item
    }

    const selector = errors.map(({ querySelector }) => querySelector).join(',')
    const hint = errors
      .filter(({ message }) => message)
      .map(({ message }) => message)
      .join('\n')

    return {
      ...item,
      disabled: true,
      hint,
      onMouseOver() {
        document.querySelectorAll(selector).forEach(_ => _.classList.add('highlighted'))
      },
      onMouseOut() {
        document.querySelectorAll(selector).forEach(_ => _.classList.remove('highlighted'))
      }
    }
  })
}

export const updateRateFiltersOnSave = (
  appliedFilters: IRateFiltersState,
  resolvedRates: SellSideQuoteRateDTO[]
): IRateFiltersState => {
  let doUpdate = false
  const fields = ['type', 'deliveryOrderType', 'containerTypeId', 'loadType']
  const necessaryFiltersProps = fields.reduce(
    (acc, curr) =>
      // @ts-ignore
      !appliedFilters[curr] || (typeof appliedFilters[curr] === 'object' && !appliedFilters[curr].length)
        ? acc
        : { ...acc, [curr]: [] },
    {}
  )

  resolvedRates.forEach(rate => {
    fields.forEach(field => {
      // @ts-ignore
      if (necessaryFiltersProps[field] && rate[field]) {
        // @ts-ignore
        necessaryFiltersProps[field].push(rate[field])
      }
    })
  })

  Object.keys(necessaryFiltersProps).forEach(key => {
    // @ts-ignore
    if (necessaryFiltersProps[key].length === 0) {
      // @ts-ignore
      delete necessaryFiltersProps[key]
    }
  })

  const resultFilters = Object.keys(necessaryFiltersProps).reduce(
    // @ts-ignore
    (acc, curr) => ({ ...acc, [curr]: R.uniq([...acc[curr], ...necessaryFiltersProps[curr]]) }),
    { ...appliedFilters }
  )

  Object.keys(necessaryFiltersProps).forEach(key => {
    // @ts-ignore
    if (!equal(necessaryFiltersProps[key], appliedFilters[key])) {
      doUpdate = true
    }
  })

  return doUpdate ? resultFilters : null
}

export const saveRates = (
  rateType: RateType,
  newRateList: NewRateDTO[]
): Promise<{ rejectedRates: NewRateDTO[]; resolvedRates: any[] }> => {
  const resolvedRates: any = []
  const rejectedRates: NewRateDTO[] = []
  const callAPIRequest =
    rateType === RateType.ssq
      ? sellSideQuoteRateAPI.createSellSideQuoteRate
      : buySideQuoteRateAPI.createBuySideQuoteRate

  return newRateList
    .reduce(
      (promises: Promise<any>, rate) =>
        promises.then(async () => {
          await callAPI(
            callAPIRequest as any,
            false,
            R.omit(rate, ['id', 'state', 'duplicateIds', 'warningMessage', 'warningMessageDescription'])
          )
            .toPromise()
            .then(data => {
              resolvedRates.push(data)
            })
            .catch(async errors => {
              if (errors && errors.json) {
                await errors.json().then(async (error: any) => {
                  if (errors.status === 406) {
                    const duplicateIds = oc(error).duplicateIds()

                    if (duplicateIds && duplicateIds.length) {
                      const getRatesRequest =
                        rateType === RateType.ssq ? getSellSideQuoteRateIds : getBuySideQuoteRateIds
                      await getRatesRequest(duplicateIds)
                    }

                    rejectedRates.push({
                      ...rate,
                      state: RateState.warning,
                      duplicateIds,
                      warningMessage: oc(error).message(),
                      warningMessageDescription: oc(error).description()
                    })
                  } else {
                    const message = error.length
                      ? oc(error as any[])([]).reduce((acc, curr) => {
                          return acc + `\n` + curr.message
                        }, '')
                      : oc(error).message(null)

                    rejectedRates.push({
                      ...rate,
                      state: RateState.warning,
                      duplicateIds: undefined,
                      warningMessage: message,
                      warningMessageDescription: oc(error).description()
                    })
                  }
                })
              } else {
                rejectedRates.push({ ...rate, state: RateState.warning })
              }
            })
        }),
      Promise.resolve()
    )
    .then(async () => {
      if (resolvedRates.length) {
        await pushListItemsToStore({ update: { [EntityType.rate]: resolvedRates } })
      }
      return { resolvedRates, rejectedRates }
    })
}

export const saveGroupsOfRates = (
  rateType: RateType,
  groupsOfRates: SellSideQuoteRateGroupDTO[],
  forceSaving?: boolean
): Promise<{ rejectedRates: NewRateDTO[]; resolvedRates: RateDTO[]; duplicateRates?: RateDTO[]; isError: boolean }> => {
  let rates: NewRateDTO[] = []

  if (groupsOfRates && groupsOfRates.length) {
    rates = groupsOfRates.reduce((acc, groupOfRates) => {
      const commonProps = R.omit(groupOfRates, ['rates', 'id', 'loadType', 'deliveryOrderType', 'containerTypeId'])
      acc.push(...groupOfRates.rates.map(rate => ({ ...rate, ...commonProps })))
      return acc
    }, [])
  }

  return doBatchRatesSaving(rateType, rates, forceSaving)
}
