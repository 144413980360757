import * as React from 'react'
import { oc } from 'ts-optchain'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectUser } from '../../../../../store/select/userSelect'

type Props = {
  salesRepUserId: string
}

export const SalesRepColumn = (props: Props) => {
  const { salesRepUserId } = props
  const user = useAppSelector(selectUser(salesRepUserId))

  return <span>{oc(user).name()}</span>
}
