import * as React from 'react'
import { ModalWindow } from '../../../store/reducers/modalWindow/interfaces'

// tslint:disable:max-line-length

export const ModalWindowIconMapping = {
  [ModalWindow.Type.SUCCESS]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g clipPath="url(#clip0_4674_275047)">
        <path
          d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25ZM19.85 10.3625L12.5 17.7125L10.15 15.3625C9.6625 14.875 8.875 14.875 8.3875 15.3625C7.9 15.85 7.9 16.6375 8.3875 17.125L11.625 20.3625C12.1125 20.85 12.9 20.85 13.3875 20.3625L21.625 12.125C22.1125 11.6375 22.1125 10.85 21.625 10.3625C21.1375 9.875 20.3375 9.875 19.85 10.3625Z"
          fill="#65D16C"
        />
      </g>
      <defs>
        <clipPath id="clip0_4674_275047">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  [ModalWindow.Type.NOTIFICATION]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M16.25 20H15V15H13.75M15 10H15.0125M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
        stroke="#5C6FFF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [ModalWindow.Type.WARNING]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0519 19.9415L19.2952 4.97202C18.4161 3.448 16.7776 2.50208 15.0055 2.5C13.2333 2.49793 11.5942 3.43994 10.7042 4.97209L1.94425 19.947C1.03591 21.4743 1.01536 23.3882 1.89519 24.949C2.77575 26.5111 4.42454 27.4833 6.21797 27.4978L23.7653 27.4979C25.5774 27.4803 27.223 26.5111 28.1035 24.9504C28.9828 23.3915 28.9632 21.4819 28.0519 19.9415ZM4.09757 21.2171L12.864 6.23113C13.3079 5.46692 14.1222 4.99897 15.0026 5C15.883 5.00103 16.6969 5.47092 17.1335 6.22778L25.8971 21.2091C26.3565 21.9856 26.3663 22.9416 25.926 23.7221C25.4851 24.5037 24.6608 24.9891 23.7531 24.998L6.22813 24.9979C5.33988 24.9906 4.514 24.5037 4.07302 23.7214C3.63251 22.9399 3.6428 21.9818 4.09757 21.2171ZM15.0004 22.4979C15.691 22.4979 16.2508 21.9383 16.2508 21.2479C16.2508 20.5576 15.691 19.9979 15.0004 19.9979C14.3098 19.9979 13.75 20.5576 13.75 21.2479C13.75 21.9383 14.3098 22.4979 15.0004 22.4979ZM16.2545 9.99793H13.7537V18.7479H16.2545V9.99793Z"
        fill="#CFA63E"
      />
    </svg>
  ),
  [ModalWindow.Type.ALERT]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15 10V15M15 20H15.0125M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
        stroke="#FF5E5E"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
