import * as R from 'remeda'
import { oc } from 'ts-optchain'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  DriverViewDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../../../../../api/api'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { mergeParsedLists } from './mergeParsedLists'
import { parseLocations } from './parseLocations'
import { parseDrivers } from './parseDrivers'
import { parseActivities } from './parseActivities'
import { parseDeliveryOrders } from './parseDeliveryOrders'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'

export const parseDispatchDeliveryOrders = async (
  dispatchDeliveryOrders: DispatchDeliveryOrderViewDTO[]
): Promise<TListsStateUpdate> => {
  const activitiesToParse: TransportationActivityViewDTO[] = []
  const locationsToParse: LocationViewDTO[] = []
  const driversToParse: DriverViewDTO[] = []
  const deliveryOrdersToParse: DeliveryOrderViewDTO[] = []
  const result = generateEmptyListsState()

  try {
    dispatchDeliveryOrders.forEach(dispatchDeliveryOrder => {
      const deliveryOrder = oc(dispatchDeliveryOrder).deliveryOrder()
      const activities = oc(dispatchDeliveryOrder).activities()
      const streetTurn = oc(dispatchDeliveryOrder).streetTurn()
      const container = oc(dispatchDeliveryOrder).container()
      const equipment = oc(dispatchDeliveryOrder).equipment()
      const pickupLocation = oc(dispatchDeliveryOrder).pickupStage.location()
      const deliveryLocation = oc(dispatchDeliveryOrder).deliveryStage.location()
      const returnLocation = oc(dispatchDeliveryOrder).returnStage.location()

      if (deliveryOrder) {
        deliveryOrdersToParse.push(deliveryOrder)
      }

      if (container) {
        result[EntityType.container].push(container)
      }

      if (equipment) {
        // @ts-ignore
        result[EntityType.equipment].push(equipment)
      }

      if (streetTurn) {
        result[EntityType.streetTurn].push(streetTurn)
      }

      activitiesToParse.push(
        ...oc(activities)
          .transportationActivities([])
          // @ts-ignore
          .concat(oc(activities).documentationActivities([]))
          .filter(Boolean)
      )

      locationsToParse.push(
        // @ts-ignore
        ...[pickupLocation, deliveryLocation, returnLocation].filter(Boolean)
      )

      result[EntityType.dispatchDeliveryOrder].push(
        R.omit(dispatchDeliveryOrder, ['deliveryOrder', 'container', 'equipment', 'activities'])
      )
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (locationsToParse.length) {
    await parseLocations(locationsToParse).then(mergeParsedLists(result))
  }
  if (driversToParse.length) {
    await parseDrivers(driversToParse).then(mergeParsedLists(result))
  }
  if (activitiesToParse.length) {
    await parseActivities(activitiesToParse).then(mergeParsedLists(result))
  }
  if (deliveryOrdersToParse.length) {
    await parseDeliveryOrders(deliveryOrdersToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}
