import { ColumnSize, RateState, NewRateDTO, RateType, RateColumn } from '../../../../interfaces'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../../../api/origin/qmp-service'
import { createId } from '../../../../../../../services/utils'

export const columnsSettings: Record<string, { styles: object; visibility: ColumnSize[] }> = {
  ID: {
    styles: { flex: '0 0 auto', width: 55 },
    visibility: null // always
  },
  'Type of rate': { styles: { flex: '0 0 auto', width: 100 }, visibility: null },
  Type: { styles: { flex: '0 0 auto', width: 85 }, visibility: null },
  Pickup: {
    styles: { flex: '1 0 auto', width: 70 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ]
  },
  Delivery: {
    styles: { flex: '1 0 auto', width: 70 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ]
  },
  Return: {
    styles: { flex: '1 0 auto', width: 70 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ]
  },
  'DO Type': { styles: { flex: '0 0 auto', width: 60 }, visibility: [ColumnSize.wideSSQ, ColumnSize.wideBSQ] },
  'Container Type': { styles: { flex: '0 0 auto', width: 90 }, visibility: [ColumnSize.wideSSQ, ColumnSize.wideBSQ] },
  'Load Type': {
    styles: { flex: '0 0 auto', width: 80 },
    visibility: [ColumnSize.wideSSQ, ColumnSize.wideBSQ, ColumnSize.customerQuote]
  },
  'Calculation Type': {
    styles: { flex: '0 0 auto', width: 90 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ, ColumnSize.customerQuote]
  },
  Rule: {
    styles: { flex: '1 0 auto', width: 80 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ, ColumnSize.customerQuote]
  },
  'Effective Date': {
    styles: { flex: '0 0 auto', width: 85 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ, ColumnSize.customerQuote]
  },
  'Expiration Date': {
    styles: { flex: '0 0 auto', width: 85 },
    visibility: [ColumnSize.default, ColumnSize.wideSSQ, ColumnSize.wideBSQ, ColumnSize.customerQuote]
  },
  Amount: { styles: { flex: '0 0 auto', width: 80, justifyContent: 'flex-end', paddingRight: 8 }, visibility: null }
}
export const columns = Object.keys(columnsSettings) as RateColumn[]

export const isAllowCopyRate = (
  rateType: RateType,
  propertyType: SellSideQuoteRateDTO.TypeEnum | BuySideQuoteRateDTO.TypeEnum
): boolean => {
  const deprecatedRateTypesToCopy = {
    [RateType.ssq]: [SellSideQuoteRateDTO.TypeEnum.PREPULL, SellSideQuoteRateDTO.TypeEnum.REDELIVERY],
    [RateType.bsq]: [
      SellSideQuoteRateDTO.TypeEnum.SHUTTLE,
      SellSideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
      SellSideQuoteRateDTO.TypeEnum.SHUTTLEOW,
      SellSideQuoteRateDTO.TypeEnum.SHUTTLEHAZ,
      SellSideQuoteRateDTO.TypeEnum.DEDUCTION,
      SellSideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER
    ]
  }

  return !deprecatedRateTypesToCopy[rateType].includes(propertyType)
}
