import * as React from 'react'
import { ContactsTab } from '../../../../contact/views/ContactsTab'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemSubClient } from '../../../../../../hooks/useGridItemSubClient'

type Props = {}

export const Contacts = React.memo((props: Props) => {
  const { businessPartner } = useGridItemSubClient()

  return <ContactsTab parentEntity={businessPartner} entityType={EntityType.businessPartner} />
})
