import * as React from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import { CheckType, CreateDeductionTransactionDTO } from '../../../../../../api/origin/vendor-accounting-service'
import {
  BottomSection,
  MiddleSection,
  StyledModalWindow,
  TopSection,
  WindowOverlay
} from '../../../../../UI/ModalWindow/views/styles'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { Select } from '../../../../../UI/DataFields/Select'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { ToggleButton } from '../../../../../UI/Toggle/ToggleButton'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { regExpOnlyNumbersAndLetters } from '../../../../../../services/functions/regExp'
import { deductionLists } from '../../../../../../services/select/deductionLists'
import {
  requestCreateDeductionTransaction,
  requestCloseDeductionId
} from '../../../../../../services/DTO/deduction/fetch'
import { GridSpinner } from '../../../../../UI/Spinner/Spinner'
import { showSuccessModalWindow } from '../../../../../../store/reducers/modalWindow/functions'
import { dateService } from '../../../../../../services/timeService'
import { deductionDirectory } from '../../../../../../services/DTO/deduction/directory'
import { CloseIcon } from '../../../../../UI/icons/CloseIcon'
import { transformMoney } from '../../../../../../services/functions/transform/transformMoney'

type Props = {}

export const RefundEscrowDeductionPopup = (props: Props) => {
  const {
    refundEscrowDeduction,
    closeEscrowDeduction,
    setRefundEscrowDeduction,
    setCloseEscrowDeduction,
    requestDriverDeductionsWithSpinner
  } = useDriverDeductions()
  const isCloseEscrowDeductionPopup = Boolean(closeEscrowDeduction)
  const deduction = isCloseEscrowDeductionPopup ? closeEscrowDeduction : refundEscrowDeduction
  const closePopup = () =>
    isCloseEscrowDeductionPopup ? setCloseEscrowDeduction(null) : setRefundEscrowDeduction(null)
  const [fetching, setFetching] = React.useState(false)
  const [closeEscrow, setCloseEscrow] = React.useState(false)
  const [transaction, setTransaction] = React.useState<CreateDeductionTransactionDTO>({
    deductionId: deduction.id,
    date: undefined,
    checkType: isCloseEscrowDeductionPopup ? undefined : CheckType.CHECK,
    checkNumber: undefined,
    amount: undefined,
    description: undefined
  })
  const currentBalance = deduction.currentBalance || 0
  const isAmountValid = currentBalance !== 0 && (transaction.amount || 0) <= currentBalance
  const isRefundEnable =
    transaction.date &&
    (isCloseEscrowDeductionPopup
      ? (!transaction.checkType && !transaction.checkNumber) || (transaction.checkType && transaction.checkNumber)
      : transaction.checkType && transaction.checkNumber) &&
    transaction.deductionId &&
    typeof transaction.amount === 'number' &&
    isAmountValid
  const isCloseToggleEnable = typeof transaction.amount === 'number' && transaction.amount === currentBalance
  const labelMapping = {
    title: isCloseEscrowDeductionPopup ? 'Close Escrow' : 'Refund Escrow',
    date: isCloseEscrowDeductionPopup ? 'Date' : 'Refund Date',
    confirmButton: isCloseEscrowDeductionPopup ? 'Confirm' : 'Refund'
  }

  const changePropValue = (prop: keyof CreateDeductionTransactionDTO) => (value: any) => {
    setTransaction(item => ({ ...item, [prop]: value }))

    if (prop === 'amount') {
      setCloseEscrow(value === currentBalance)
    }
  }

  const requestRefundTransaction = () => {
    setFetching(true)
    requestCreateDeductionTransaction(transaction)
      .then(async requestedRefundTransaction => {
        showSuccessModalWindow({
          title: labelMapping.title,
          content: (
            <>
              <RefundRow>
                <RefundLabel>Date:</RefundLabel>
                {dateService.makeLabel(requestedRefundTransaction.date, { hideTime: true })}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Check Type:</RefundLabel>
                {deductionDirectory.checkType[requestedRefundTransaction.checkType]}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Check #:</RefundLabel>
                {requestedRefundTransaction.checkNumber}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Sum:</RefundLabel>
                {transformMoney(requestedRefundTransaction.amount)}
              </RefundRow>
              {requestedRefundTransaction.description && (
                <RefundRow>
                  <RefundLabel style={{ alignSelf: 'flex-start' }}>Description:</RefundLabel>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{requestedRefundTransaction.description}</p>
                </RefundRow>
              )}
            </>
          )
        })
        closePopup()

        if (closeEscrow) {
          await requestCloseDeductionId(deduction.id)
        }

        requestDriverDeductionsWithSpinner()
      })
      .catch(() => setFetching(false))
  }

  React.useEffect(() => {
    if (!isCloseToggleEnable && closeEscrow) {
      setCloseEscrow(false)
    }
  }, [isCloseToggleEnable, closeEscrow])

  return (
    <Portal>
      <WindowOverlay>
        <StyledModalWindow style={{ minWidth: 600, maxWidth: 600, position: 'relative' }}>
          {fetching && <GridSpinner />}
          <TopSection>
            {labelMapping.title} <CloseIcon style={{ marginLeft: 'auto' }} onClick={closePopup} />
          </TopSection>
          <MiddleSection>
            <InputsContainer>
              <DateTimePicker
                title={labelMapping.date}
                required={true}
                hideTimeInput={true}
                date={transaction.date}
                onChange={changePropValue('date')}
              />
              <Select
                title={'Check Type'}
                required={!isCloseEscrowDeductionPopup}
                selectedValue={transaction.checkType}
                list={
                  isCloseEscrowDeductionPopup
                    ? [{ label: '–', value: undefined }].concat(deductionLists.checkType)
                    : deductionLists.checkType
                }
                onSelect={changePropValue('checkType')}
              />
              <Input
                title={'Check #'}
                required={!isCloseEscrowDeductionPopup}
                maxLength={8}
                regExp={regExpOnlyNumbersAndLetters}
                value={transaction.checkNumber}
                placeholder={'#'}
                onChange={changePropValue('checkNumber')}
              />
              <InputField.Money
                title={'Sum'}
                required={true}
                highlighted={!isAmountValid}
                isPositiveOrNegative={true}
                errorMessage={
                  typeof transaction.amount === 'number' &&
                  !isAmountValid && (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {transaction.amount === 0
                        ? "Sum can't be $0"
                        : `Sum can't be bigger than Current Balance ($${currentBalance})`}
                    </span>
                  )
                }
                value={transaction.amount}
                onChange={changePropValue('amount')}
              />
            </InputsContainer>
            <TextareaContainer>
              <FieldContainer title={'Description (optional)'}>
                <Textarea
                  value={transaction.description}
                  maxLength={35}
                  placeholder={'Type your note here...'}
                  onChange={event => changePropValue('description')(event.target.value)}
                />
              </FieldContainer>
              <ShowClosedButton>
                <Label>Close Escrow</Label>
                <ToggleButton active={closeEscrow} />
              </ShowClosedButton>
            </TextareaContainer>
          </MiddleSection>
          <BottomSection style={{ justifyContent: 'space-between' }}>
            <ActionButton round={true} onClick={closePopup}>
              Cancel
            </ActionButton>
            <ActionButton
              filled={true}
              round={true}
              disabled={!isRefundEnable}
              onClick={isRefundEnable ? requestRefundTransaction : undefined}
            >
              {labelMapping.confirmButton}
            </ActionButton>
          </BottomSection>
        </StyledModalWindow>
      </WindowOverlay>
    </Portal>
  )
}

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 12px));
  grid-gap: 16px;
  margin-bottom: 24px;
`

const TextareaContainer = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr auto;
  grid-gap: 50px;
`

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  padding: 10px 12px;

  &::placeholder {
    color: #d8d8d8;
  }
`

const Label = styled.div`
  font-size: 12px;
  color: black;
  user-select: none;
  margin-right: 4px;
`

const ShowClosedButton = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  cursor: default;

  * {
    cursor: default;
  }
`

const RefundRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`
const RefundLabel = styled.div`
  width: 100px;
  font-weight: 500;
  margin-right: 20px;
`
