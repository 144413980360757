import * as React from 'react'
import styled from 'styled-components'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'
import { WindowPopover } from '../../../../UI/windowPopover'
import { GridHeaderColumnStatusLabel } from './GridHeaderColumnStatusLabel'
import { GridHeaderColumnStatusDropdown } from './GridHeaderColumnStatusDropdown'
import { ColumnDTO } from '../../../../../store/reducers/tabs/interfaces'
import { DriverDTO } from '../../../../../api/api'

type Props = {
  disabled?: boolean
  placeholder: string
  filterItem: ColumnDTO.Filter
  onStatusChange: (values: string[], notEqualValue?: boolean) => void
}

export const DriverGridHeaderStatus = (props: Props) => {
  const { filterItem, placeholder, onStatusChange, disabled } = props
  const { wrapperRef, statePopover, togglePopover } = useOutsideClick({ capture: false })
  const filterItemValue = filterItem && (filterItem.value as string[])
  // @ts-ignore
  const selectedValues: DriverDTO.StatusEnum[] = filterItemValue || []
  const [localSelectedValues, setLocalSelectedValues] = React.useState<DriverDTO.StatusEnum[]>(selectedValues)
  const isChanged = !(
    selectedValues.every(item => localSelectedValues.includes(item)) &&
    localSelectedValues.every(item => selectedValues.includes(item))
  )

  return (
    <StyledSelect className={'column-value'} ref={wrapperRef} onClick={togglePopover}>
      <GridHeaderColumnStatusLabel
        disabled={disabled}
        placeholder={placeholder}
        selectedValues={selectedValues}
        onClearButtonClick={() => onStatusChange([])}
      />
      {!disabled && statePopover && (
        <WindowPopover bindToRef={wrapperRef} isExternal={true}>
          <GridHeaderColumnStatusDropdown
            // @ts-ignore
            selectedValues={localSelectedValues}
            onStatusChange={setLocalSelectedValues}
            onApplyClick={isChanged ? () => onStatusChange(localSelectedValues.map(String)) : undefined}
          />
        </WindowPopover>
      )}
    </StyledSelect>
  )
}

const StyledSelect = styled.div`
  height: 100%;
`
