import * as React from 'react'
import {
  ActivityDocumentPopupView,
  IActivityDocumentPopup
} from '../components/common/dispatchDeliveryOrder/interfaces'
import { ActivityRow, ActivityGroupAction, TransportationActivityRow } from '../services/DTO/activity/controller'
import { TransportationActivityGroup } from '../services/DTO/activity/interfaces'
import {
  DateISOString,
  DispatchDeliveryOrderViewDTO,
  LocationViewDTO,
  TransportationActivityBobtailToDTO,
  TransportationActivityViewDTO
} from '../api/origin/business-logic'
import { IAttachment } from '../services/filesService/interfaces'
import { IMessage } from '../store/reducers/communicationHub/interfaces'

export interface ITransportationActivityRowContext {
  rowNumber: number
  stage: TransportationActivityViewDTO.StageEnum
  disabled: boolean
  isUnsuccessful: boolean
  isModifiedMode: boolean
  disableActualDates: boolean
  activityWithWrongOrderOfPlannedDates: TransportationActivityViewDTO
  exportDatesConfused: boolean
  importDatesConfused: boolean
  gotoActivityStartDateRequired: boolean
  gotoActivityCompleteDateRequired: boolean
  gotoActivityStartDateDisabled: boolean
  gotoActivityCompleteDateDisabled: boolean
  businessActivityStartDateRequired: boolean
  businessActivityCompleteDateRequired: boolean
  businessActivityStartDateDisabled: boolean
  businessActivityCompleteDateDisabled: boolean
  appDateIsNotConfirmed: boolean
  enablePODDocumnet: boolean
  enableTIRDocumnet: boolean
  disableEditDocument: boolean
  isDropActivity: boolean
  isPickActivity: boolean
  isBobtail: boolean
  isDropBobtail: boolean
  isPickBobtail: boolean
  isDropBobtailValidationError: boolean
  isLinkedActivityToBobtail: boolean
  allowModifying: boolean
  troubleTicketId: string
  tirDocumentMessage: IMessage
  podDocumentMessage: IMessage
  tirDocument: IAttachment
  podDocument: IAttachment
  anotherDOOActivityLink: TransportationActivityBobtailToDTO | undefined
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  transportationRow: TransportationActivityRow
  prevTransportationRowOfStage: TransportationActivityRow
  nextTransportationRowOfStage: TransportationActivityRow
  dropTypeActivityRowToCreateBobtail: TransportationActivityRow
  pickTypeActivityRowToCreateBobtail: TransportationActivityRow
  gotoActivity: TransportationActivityViewDTO
  businessActivity: TransportationActivityViewDTO
  linkedGotoActivity: TransportationActivityViewDTO | undefined
  linkedBusinessActivity: TransportationActivityViewDTO | undefined
  stageActivityRows: ActivityRow[]
  rows: ActivityRow[]
  usefulRows: TransportationActivityRow[]
  bobtailGroup: TransportationActivityGroup[] | undefined
  activityDocumentPopup: IActivityDocumentPopup
  setActivityDocumentPopup: (state: IActivityDocumentPopup) => void
  openActivityDocumentPopup: (view: ActivityDocumentPopupView, ignoreEmptyRequiredFields: boolean) => void
  updateCompletionActualDate: (props: { date?: DateISOString; isGotoActivity: boolean }) => void
  updateActivityRow: (
    updatedActivityGroupRow: TransportationActivityRow | TransportationActivityRow[],
    action: ActivityGroupAction,
    updateLocationForInitialObjectState?: LocationViewDTO
  ) => void
  modifyDispatchDeliveryOrder: (state: DispatchDeliveryOrderViewDTO) => void
  currentActivityGroup?: TransportationActivityGroup
}

export const TransportationActivityRowContext: React.Context<ITransportationActivityRowContext> = React.createContext(
  null
)
