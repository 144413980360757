import { oc } from 'ts-optchain'
import { BuySideQuoteDTO, SellSideQuoteDTO } from '../../api/api'
import { getQuoteAmount } from './get/getQuoteAmount'

export type TCalculateQuotes = {
  profit: number
  sellSideQuoteTotalAmount: number
  buySideQuoteTotalAmount: number
  buySideQuotesAmount: Record<string, number>
}

export const calculateDDOQuotes = (props: {
  sellSideQuote: SellSideQuoteDTO
  buySideQuotes: BuySideQuoteDTO[]
}): TCalculateQuotes => {
  const { sellSideQuote, buySideQuotes } = props
  const allSSQSurcharges = [...oc(sellSideQuote).surcharges([]), ...oc(sellSideQuote).miscSurcharges([])]
  let sellSideQuoteTotalAmount = allSSQSurcharges.length ? oc(sellSideQuote).amount() : undefined
  let buySideQuoteTotalAmount = buySideQuotes && buySideQuotes.length ? 0 : undefined
  const buySideQuotesAmount: Record<string, number> = {}
  let profit = sellSideQuoteTotalAmount !== undefined ? 0 : undefined

  const calculateBuySideQuote = (buySideQuote: BuySideQuoteDTO): number => {
    return getQuoteAmount({ rates: [...buySideQuote.miscSurcharges, ...buySideQuote.surcharges] })
  }

  if (buySideQuotes && buySideQuotes.length) {
    buySideQuotes.filter(Boolean).forEach(buySideQuote => {
      const amount = calculateBuySideQuote(buySideQuote)

      buySideQuotesAmount[buySideQuote.id] = amount
      buySideQuoteTotalAmount += amount
    })
  }

  if (allSSQSurcharges.length) {
    sellSideQuoteTotalAmount = getQuoteAmount({ rates: allSSQSurcharges })
    profit = (sellSideQuoteTotalAmount || 0) - (buySideQuoteTotalAmount || 0)
  }

  return {
    sellSideQuoteTotalAmount,
    buySideQuoteTotalAmount,
    buySideQuotesAmount,
    profit
  }
}
