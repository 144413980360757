import { oc } from 'ts-optchain'
import { ActivityRow, DocumentationActivityRow, TransportationActivityRow } from '../../DTO/activity/controller'
import { isUnsuccessfulActivityGroup } from '../test/isUnsuccessfulActivity'

export const getNextActivityRow = (
  rows: ActivityRow[],
  currentActivityId: string,
  _options?: {
    omitUnsuccessful?: boolean
    omitDocumentation?: boolean
    returnOnlyTransportation?: boolean
  }
): ActivityRow | TransportationActivityRow | undefined => {
  if (!Array.isArray(rows)) {
    return undefined
  }

  const options = _options || {}
  const filteredRows = rows.filter(row => {
    if (options.omitUnsuccessful && isUnsuccessfulActivityGroup(row.activityGroup)) {
      return false
    }

    if (options.omitDocumentation && 'documentationActivity' in row.activityGroup) {
      return false
    }

    return true
  })
  const currentRowIndex = filteredRows.findIndex(
    row =>
      oc(row as TransportationActivityRow).activityGroup.gotoActivity.id() === currentActivityId ||
      oc(row as TransportationActivityRow).activityGroup.businessActivity.id() === currentActivityId ||
      oc(row as DocumentationActivityRow).activityGroup.documentationActivity.id() === currentActivityId
  )

  if (currentRowIndex >= 0) {
    const result = filteredRows[currentRowIndex + 1]

    if (options.returnOnlyTransportation) {
      return (result && 'gotoActivity' in result.activityGroup ? result : undefined) as TransportationActivityRow
    }

    return result
  }

  return undefined
}
