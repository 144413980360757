import * as React from 'react'
import { oc } from 'ts-optchain'
import { TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { useGridItemSubClient } from '../../../../../hooks/useGridItemSubClient'
import { SubClientColumn } from '../../../grid/columnSettings/subClientColumnSettings'
import { generateContactNameLabel } from '../../../../../services/functions/generate/generateContactNameLabel'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'

const Column = React.memo((props: TGridColumn) => {
  const { className, columnName, style } = props
  const { businessPartner, customerBusinessPartner, primaryContact } = useGridItemSubClient()
  let text = columnName

  switch (columnName) {
    case SubClientColumn.Name:
      text = oc(businessPartner).legalName()
      break
    case SubClientColumn.Address:
      text = generateAddressLabel(oc(businessPartner).legalAddress())
      break
    case SubClientColumn.Customer:
      text = oc(customerBusinessPartner).legalName()

      break
    case SubClientColumn.PrimaryContact:
      text = generateContactNameLabel(primaryContact)
      break
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
})

export default Column
