import * as React from 'react'
import styled from 'styled-components'
import { GridItemSpinner } from '../../../../../../UI/Spinner/Spinner'
import { ActivitiesTab } from '../../../../../dispatchDeliveryOrder/views/Details/Activities'
import ActionsSaveCancel from '../../../../../../UI/Buttons/ActionsSaveCancel'
import { Window } from '../../../../../../UI/Window'
import { MoveRowSpecs } from '../MoveRow/MoveRowSpecs'
import { requestDispatchDeliveryOrderById } from '../../../../../dispatchDeliveryOrder/epics'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../../../hooks/useGridItemDDO'
import { checkDispatchDeliveryOrderValidation } from '../../../../../../../services/DTO/dispatchDeliveryOrder'
import { isFullObject } from '../../../../../../../services/functions/test/isFullObject'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DispatchDeliveryOrderPopupDetails = (props: Props) => {
  const expandedItemContext = useExpandedItem()
  const gridItemDDOProviderData = useGridItemDDO()
  const { dispatchDeliveryOrder } = gridItemDDOProviderData
  const {
    parentItemId,
    isModified,
    isFetching,
    setFetching,
    clearExpandedItem,
    clearExpandedItemModifies,
    saveUpdatedEntities
  } = expandedItemContext
  const onSaveClickRef = React.useRef(null)
  const onSaveClick = (options?: any) => {
    saveUpdatedEntities({ gridItemDDOProviderData, retrySave: onSaveClickRef.current, options: options || {} })
  }
  onSaveClickRef.current = onSaveClick

  React.useEffect(() => {
    if (!isFullObject(dispatchDeliveryOrder)) {
      setFetching(true)
      requestDispatchDeliveryOrderById(parentItemId).finally(() => {
        setFetching(false)
      })
    }
  }, [])

  if (isFetching && !dispatchDeliveryOrder) {
    return (
      <Window
        // startBelowTabs={true}
        isCommunicationHubVisible={true}
        onButtonCloseClick={isModified ? undefined : clearExpandedItem}
        windowStyles={{ width: '100%', height: '100%', paddingBottom: 65 }}
      >
        <ActivitiesContainer>
          <GridItemSpinner />
        </ActivitiesContainer>
      </Window>
    )
  }

  if (!dispatchDeliveryOrder) {
    return null
  }

  return (
    <Window
      // startBelowTabs={true}
      isCommunicationHubVisible={true}
      onButtonCloseClick={isModified ? undefined : clearExpandedItem}
      windowStyles={{ width: '100%', height: '100%', paddingBottom: 65 }}
    >
      <ActivitiesContainer>
        {isFetching && <GridItemSpinner />}
        <DDODetails>
          <MoveRowSpecs dispatchDeliveryOrder={dispatchDeliveryOrder} />
        </DDODetails>
        <ActivitiesTab showUserInfo={true} unlimitedHeight={true} />
      </ActivitiesContainer>
      <DetailsButtonSaveContainer>
        {isModified && (
          <ActionsSaveCancel
            styles={{ height: 55, right: 20 }}
            disableSave={!checkDispatchDeliveryOrderValidation(expandedItemContext)}
            onSave={onSaveClick}
            onCancel={clearExpandedItemModifies}
          />
        )}
      </DetailsButtonSaveContainer>
    </Window>
  )
}

const ActivitiesContainer = styled.div`
  background-color: #fff;
`
const DDODetails = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  padding-top: 1px;

  .MoveRowSpecs > div {
    border-left-width: 1px;
  }
`
const DetailsButtonSaveContainer = styled.div`
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
