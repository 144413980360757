import { DeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { initialActivitiesCalculation } from '../../DTO/dispatchDeliveryOrder/functions'
import { filterUsefulActivityGroups } from '../filter/filterUsefulActivityGroups'
import { isShuttleStageActivityGroup } from './isShuttleStageActivityGroups'
import { isStageStreetTurn } from './isStageStreetTurn'

type Props = {
  stage: TransportationActivityViewDTO.StageEnum
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  ddoStreetTurnId: string
  ddoPickupLocationId: string
  ddoReturnLocationId: string
  transportationActivities: TransportationActivityViewDTO[]
}

export const isStageShuttle = (props: Props): boolean => {
  const {
    stage,
    ddoPickupLocationId,
    ddoReturnLocationId,
    ddoStreetTurnId,
    deliveryOrderType,
    transportationActivities
  } = props
  if (![DeliveryOrderViewDTO.TypeEnum.IMPORT, DeliveryOrderViewDTO.TypeEnum.EXPORT].includes(deliveryOrderType)) {
    return false
  }

  let usefulTransportationActivityGroups: TransportationActivityGroup[] = []

  try {
    usefulTransportationActivityGroups = filterUsefulActivityGroups(
      initialActivitiesCalculation({
        documentationActivities: [],
        transportationActivities
      }).activityGroups
    ) as TransportationActivityGroup[]

    return (
      !isStageStreetTurn({ stage, deliveryOrderType, ddoStreetTurnId }) &&
      isShuttleStageActivityGroup({
        stage,
        deliveryOrderType,
        ddoReturnLocationId,
        ddoPickupLocationId,
        usefulTransportationActivityGroups
      })
    )
  } catch (e) {
    return false
  }
}
