import { createSelectListFromDirectory } from './index'
import { driverDirectory } from '../DTO/driver/directory'

const type = createSelectListFromDirectory(driverDirectory.type)
const operationalPreference = createSelectListFromDirectory(driverDirectory.operationalPreference)
const millageRestriction = createSelectListFromDirectory(driverDirectory.millageRestriction)
const endorsement = createSelectListFromDirectory(driverDirectory.endorsement)

export const driverLists = {
  type,
  operationalPreference,
  millageRestriction,
  endorsement
}
