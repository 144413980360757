import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import {
  DispatchDeliveryOrderViewDTO,
  ContainerViewDTO,
  ConfirmedLocalDateTimeRangeDTO,
  DeliveryOrderViewDTO
} from '../../../../../api/api'
import { Column, Container, GridColumn } from '../../../../UI/GridSystem'
import { Input } from '../../../../UI/DataFields/Input'
import { ContainerSearch, HazmatSearch } from '../../../../UI/DataFields/SearchRequest'
import { FieldTemplate } from '../../../../UI/DataFields/Templates'
import { Checkbox } from '../../../../UI/Checkbox'
import WeightUnit from '../../../../UI/WeightUnit'
import { DeleteRow, DOStyledRow } from '../../../dispatchDeliveryOrder/views/Details/Activities/Table/styles'
import { isNewObject } from '../../../../../services/DTO'
import { LocationWidget } from '../../../../UI/Widget/widgets/location/simple'
import { Select } from '../../../../UI/DataFields/Select'
import { alertOnChangingMainStreetTurnPropsOfDDO } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DateTimePicker } from '../../../../UI/DataFields/DateTimePicker/views'
import { isDDOStatusCancelled } from '../../../../../services/functions/test/isDDOStatusCancelled'
import { useDispatchDeliveryOrder } from '../../../../../hooks/useDispatchDeliveryOrder'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { useDeliveryOrderTab } from '../../../../../hooks/useDeliveryOrderTab'
import { useContainer } from '../../../../../hooks/useContainer'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'

type OwnProps = {
  dispatchDeliveryOrderId: string
  showHazmatColumn: boolean
  setShowHazmatColumn: (state: boolean) => void
}

type Props = OwnProps

export const DispatchDeliveryOrder = (props: Props) => {
  const { dispatchDeliveryOrderId, showHazmatColumn, setShowHazmatColumn } = props
  const { deliveryOrder } = useDeliveryOrderTab()
  const { modifiedLists, modifyListItems, modifyParentObjectField, deleteModifiedListItems } = useExpandedItem()
  const dispatchDeliveryOrder = useDispatchDeliveryOrder({
    id: dispatchDeliveryOrderId,
    modifiedLists: modifiedLists
  })
  const container = useContainer({ id: oc(dispatchDeliveryOrder).containerId() })
  const isImport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const hazmatIndicator = oc(dispatchDeliveryOrder).hazmatIndicator()
  const disallowRemoveRow = !isNewObject(dispatchDeliveryOrder)
  const disabled = isDDOStatusCancelled(oc(dispatchDeliveryOrder).status())

  React.useEffect(() => {
    if (hazmatIndicator) {
      setShowHazmatColumn(true)
    }
  }, [hazmatIndicator, setShowHazmatColumn])

  if (!dispatchDeliveryOrder) {
    return null
  }

  const updateDispatchDeliveryOrder = (modifiedState: DispatchDeliveryOrderViewDTO) => {
    modifyListItems({ [EntityType.dispatchDeliveryOrder]: [modifiedState] })
  }

  const updateDispatchDeliveryOrderField = (field: string) => (value: any) => {
    modifyListItems({ [EntityType.dispatchDeliveryOrder]: [{ ...dispatchDeliveryOrder, [field]: value }] })
  }

  const updateDispatchDeliveryOrderFields = (updateProps: Record<string, any>) => {
    modifyListItems({ [EntityType.dispatchDeliveryOrder]: [{ ...dispatchDeliveryOrder, ...updateProps }] })
  }

  const changeLocation = (stage: 'pickupStage' | 'deliveryStage' | 'returnStage') => (locationId: string) => {
    updateDispatchDeliveryOrderField(stage)({ ...oc(dispatchDeliveryOrder[stage])({}), locationId })
  }

  const changeContainer = (_container: ContainerViewDTO) => {
    if (_container && !deliveryOrder.steamShipLineId) {
      modifyParentObjectField('steamShipLineId')(oc(_container).steamShipLineId())
    }

    let updatedDDO = {
      ...dispatchDeliveryOrder,
      containerId: oc(_container).id()
    }

    if (!isNewObject(_container)) {
      updatedDDO = {
        ...updatedDDO,
        containerTypeId: oc(_container).containerTypeId(updatedDDO.containerTypeId)
      }
    }

    updateDispatchDeliveryOrder(updatedDDO)
    alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
  }

  return (
    <DOStyledRow data-id={'dispatch-delivery-order'} className={disabled ? 'canceled' : ''}>
      {/* Number # */}
      <Column columns={3} alignCenter={true} isFixed={true}>
        {oc(dispatchDeliveryOrder).number() ? (
          <Link
            data-id={'ddo-number'}
            onClick={event => {
              openDDOinNewTab({
                event,
                ddoId: dispatchDeliveryOrder.id,
                ddoNumber: dispatchDeliveryOrder.number
              })
            }}
            children={dispatchDeliveryOrder.number}
          />
        ) : (
          <div data-id={'ddo-number'} />
        )}
      </Column>
      {/*Equipment Type*/}
      <GridColumn columns={10} isFixed={true} data-id={'ddo-containerType'}>
        <FieldTemplate.ContainerTypeSearch
          disabled={disabled}
          required={true}
          id={dispatchDeliveryOrder.containerTypeId}
          onChange={containerType => {
            const updatedDDO = {
              ...dispatchDeliveryOrder,
              containerTypeId: oc(containerType).id()
            }

            if (container && oc(container).containerTypeId() !== oc(containerType).id()) {
              updatedDDO.containerId = undefined
            }

            alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
            updateDispatchDeliveryOrder(updatedDDO)
          }}
        />
      </GridColumn>
      {(isRepo || isImport) && (
        <>
          {/* Container */}
          <GridColumn columns={9} isFixed={true} data-id={'ddo-container'}>
            <ContainerSearch
              disabled={disabled}
              allowCreate={true}
              required={isImport}
              id={dispatchDeliveryOrder.containerId}
              steamShipLineId={oc(deliveryOrder).steamShipLineId()}
              containerTypeId={dispatchDeliveryOrder.containerTypeId}
              onChange={changeContainer}
            />
          </GridColumn>
        </>
      )}
      {isImport && (
        <>
          {/* Seal */}
          <GridColumn columns={8} data-id={'ddo-sealNumber'}>
            <Input
              disabled={disabled}
              maxLength={20}
              value={dispatchDeliveryOrder.sealNumber}
              onChange={updateDispatchDeliveryOrderField('sealNumber')}
            />
          </GridColumn>
        </>
      )}
      {/*Pickup*/}
      <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-pickupStage-location'}>
        <LocationWidget
          isExternal={true}
          isShortName={true}
          disabled={disabled}
          id={oc(dispatchDeliveryOrder).pickupStage.locationId()}
          changeLocationId={changeLocation('pickupStage')}
        />
      </GridColumn>
      {/*Delivery*/}
      {(isImport || isExport) && (
        <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-deliveryStage-location'}>
          <LocationWidget
            id={oc(dispatchDeliveryOrder).deliveryStage.locationId()}
            isExternal={true}
            isShortName={true}
            disabled={disabled}
            changeLocationId={changeLocation('deliveryStage')}
          />
        </GridColumn>
      )}
      {/*Return*/}
      <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-returnStage-location'}>
        <LocationWidget
          isExternal={true}
          isShortName={true}
          disabled={disabled}
          id={oc(dispatchDeliveryOrder).returnStage.locationId()}
          changeLocationId={changeLocation('returnStage')}
        />
      </GridColumn>
      {/*Appointment Date*/}
      {(isImport || isExport) && (
        <GridColumn columns={11} isFixed={true} data-id={'ddo-deliveryStage-plannedAppointmentDateTimeRange'}>
          <DateTimePicker
            disabled={disabled}
            date={oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()}
            isRange={true}
            onChange={plannedAppointmentDateTimeRange => {
              const date = plannedAppointmentDateTimeRange as ConfirmedLocalDateTimeRangeDTO

              if (date && !oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()) {
                date.confirmed = true
              }

              return updateDispatchDeliveryOrder({
                ...dispatchDeliveryOrder,
                deliveryStage: {
                  ...oc(dispatchDeliveryOrder).deliveryStage({}),
                  plannedAppointmentDateTimeRange: date
                }
              })
            }}
          />
        </GridColumn>
      )}
      {/*Cargo*/}
      <GridColumn columns={6} isFixed={true} data-id={'ddo-cargo'}>
        <Select
          multiselect={true}
          selectedValue={[
            dispatchDeliveryOrder.autoIndicator ? 'autoIndicator' : null,
            dispatchDeliveryOrder.hazmatIndicator ? 'hazmatIndicator' : null
          ].filter(Boolean)}
          list={[
            {
              label: 'Auto',
              value: 'autoIndicator'
            },
            {
              label: 'Hazmat',
              value: 'hazmatIndicator'
            }
          ]}
          onSelect={values => {
            const isAutoSelected = oc(values)([]).includes('autoIndicator')
            const isHazmatSelected = oc(values)([]).includes('hazmatIndicator')

            updateDispatchDeliveryOrder({
              ...dispatchDeliveryOrder,
              autoIndicator: isAutoSelected,
              hazmatIndicator: isHazmatSelected,
              hazmatId: isHazmatSelected ? dispatchDeliveryOrder.hazmatId : undefined
            })
          }}
        />
      </GridColumn>
      {/*Hazmat*/}
      {showHazmatColumn && (
        <GridColumn className={'hazmat-column'} columns={10} isFixed={true} data-id={'ddo-hazmat'}>
          {dispatchDeliveryOrder.hazmatIndicator && (
            <HazmatSearch
              disabled={disabled}
              required={true}
              title="Hazmat Description"
              placeholder="Search by UN Code, Class of Description.."
              id={dispatchDeliveryOrder.hazmatId}
              onChange={_hazmat => updateDispatchDeliveryOrderField('hazmatId')(oc(_hazmat).id())}
            />
          )}
        </GridColumn>
      )}
      {/*Weight*/}
      <GridColumn columns={9} isFixed={true} data-id={'ddo-weight'}>
        <Container rows={7}>
          <WeightUnit
            disabled={disabled}
            weightUnit={dispatchDeliveryOrder.weightUnit}
            weight={dispatchDeliveryOrder.weight}
            onChange={updateDispatchDeliveryOrderFields}
          />
        </Container>
      </GridColumn>
      {/* OW */}
      <GridColumn
        style={{ borderColor: 'transparent', paddingLeft: 0 }}
        columns={3}
        isFixed={true}
        alignCenter={true}
        data-id={'ddo-overweightIndicator'}
      >
        <Checkbox
          checked={dispatchDeliveryOrder.overweightIndicator}
          onChange={updateDispatchDeliveryOrderField('overweightIndicator')}
        />
        OW
      </GridColumn>

      <DeleteRow
        data-id={'ddo-button-delete'}
        className={cn('mdi mdi-delete', { disabled: disallowRemoveRow })}
        onClick={() => {
          if (disallowRemoveRow) {
            return
          }

          modifyParentObjectField('dispatchDeliveryOrderIds')(
            oc(deliveryOrder)
              .dispatchDeliveryOrderIds([])
              .filter(id => id !== dispatchDeliveryOrderId)
          )
          deleteModifiedListItems({ [EntityType.dispatchDeliveryOrder]: [dispatchDeliveryOrderId] })
        }}
      />
    </DOStyledRow>
  )
}

const Link = styled.div`
  width: unset !important;
  color: ${theme.colors.basicBlueColor};
  padding: 10px 0;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`
