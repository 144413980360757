import styled from 'styled-components'
import theme from '../../../../styles/theme'
import { GridColumn } from '../../../UI/GridSystem'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'

export const ModifiedModeOverlay = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: default;
`

export const StyledGrid = styled.div`
  z-index: 1;
  position: relative;
  height: calc(100% - 85px);
  background-color: #f5f6fa;
  overflow: hidden;
  width: 100%;

  &.emptyGrid {
    overflow: auto;
  }

  &.scrollable {
    overflow: auto;
  }
`

export const StatusLine = styled.div`
  z-index: 3;
  width: 3px;
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  border-radius: 5px 0 0 5px;

  &:before {
    content: unset !important;
  }
`
export const PreviewNavigation = styled.div`
  display: flex;
  align-items: stretch;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  user-select: none;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      color: ${theme.colors.basicBlueColor};
    }

    &.active {
      color: ${theme.colors.basicBlueColor};

      &:after {
        content: '';
        display: block;
        height: 2px;
        background: ${theme.colors.basicBlueColor};
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
      }
    }
  }
`
export const StyledColumn = styled(GridColumn)`
  background: #fcfdff;

  &:not(.left):not(.oneLineOfText):not(.wrap):not(.firstLetter):not(.capitalize) {
    text-align: center;
  }

  &.wrap {
    white-space: pre-wrap;
  }

  &.oneLineOfText > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &.capitalize > span {
    text-transform: capitalize;
  }
  &.firstLetter > span:first-letter {
    text-transform: uppercase;
  }

  .textLine {
    width: 100%;
    text-align: left;

    span:after {
      content: ',';
    }

    &:last-child {
      span:after {
        content: '';
      }
    }
  }

  &.bold {
    font-weight: 500;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &.font-size-14 {
    font-size: 14px;
  }

  &.font-size-12 {
    font-size: 12px;

    * {
      font-size: 12px;
    }
  }

  .selected {
    background: none !important;
  }
`
export const LocationColumn1 = styled(StyledColumn)`
  padding: 20px 10px 0 10px;

  p {
    font-weight: 300;
    font-size: 10px;
  }

  > div {
    display: flex;
    width: 100%;

    &:before {
      font-size: 18px !important;
      margin-right: 5px;
      flex-shrink: 0;
      transform: translateY(-2px);
    }

    > div {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 3px;

      span:after {
        content: none !important;
      }
    }
  }

  &.oneLine {
    > div {
      > div {
        width: unset;
        margin: 0;

        span {
          line-height: 14px;
        }
      }
    }
  }
`
// TODO Delete
export const DDO = styled(GridColumn)`
  div {
    width: 100%;
  }

  span {
    font-size: 12px;
    letter-spacing: 1px;

    &:first-child {
      font-weight: 500;
      color: #4555d0;
    }

    &:nth-child(2):before {
      content: '–';
      color: rgba(0, 0, 0, 0.2);
      margin: 0 5px;
    }
  }

  p {
    display: block;
    line-height: 12px;
    font-size: 10px;
    color: #445366;
    font-weight: 300;
    margin-top: 5px;
  }
`
export const PreviewContent = styled(GridColumn)`
  flex-direction: column;
  width: 100vw;
  background: white;
  border-radius: 0 0 5px 5px;
  border-top: none;
  flex: none;
  position: sticky;
  left: 0;
  min-height: auto;

  ${StatusLine} {
    border-radius: 0 0 0 5px !important;
  }
`

export const ColumnTitle = styled(GridColumn)`
  font-size: 10px;
  color: ${theme.colors.darkGreyColor};
  user-select: none;
  cursor: default;

  &:before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    background-color: ${theme.colors.basicBorderColor};
  }

  &.filtered {
    color: ${theme.colors.basicBlueColor};
  }
`
export const Container = styled(GridColumn)`
  &.emptyGrid {
    flex-direction: column;
    overflow: auto;
    background: none;
  }
`
export const EmptyGrid = styled.div`
  z-index: -2 !important;
  position: fixed;
  top: calc(50vh + 85px + 12px);
  left: calc(50vw - 90px - ${CommunicationHubConstants.navigationViewWidth / 2}px);
  font-size: 24px;
  color: #6c707d;
  transition: 0.25s;
  background-color: #f5f6fa;

  .communicationHubExpanded & {
    left: calc(
      50vw - 90px -
        ${(CommunicationHubConstants.navigationViewWidth + CommunicationHubConstants.channelViewWidth) / 2}px
    );
  }

  .communicationHubHidden & {
    left: calc(50vw - 90px);
  }
`

export const PreviewHeader = styled.div`
  height: 45px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e3e6f1;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0 0 5px 5px;
  background-color: #fafbfd;
  padding: 0 15px;
`
