import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { SearchRequest } from '../searchRequest'
import { callAPI, equipmentAPI, EquipmentDTO } from '../../../../../api/api'
import { TSearchField } from '../'
import { isNewObject } from '../../../../../services/DTO'
import { checkEquipmentNumberValidation } from '../../../../../services/DTO/equipment'
import { generateEquipment } from '../../../../../services/functions/generate/generateEquipment'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../../../store/reducers/lists/functions/pushListItemsToStore'
import { useEquipment } from '../../../../../hooks/useEquipment'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { requestCreateEquipment } from '../../../../common/equipment/epics'
import { dateService } from '../../../../../services/timeService'

export const EquipmentSearch = (props: TSearchField & { equipmentId: string }) => {
  const { modifiedLists, setFetching } = useExpandedItem()
  const equipment = useEquipment({ id: props.equipmentId, modifiedLists })
  const [temporaryNewEquipment, setTemporaryNewEquipment] = React.useState<EquipmentDTO>(null)
  const getListRequest = (term: string) => callAPI(equipmentAPI.search, null, term).toPromise()
  let getList = getListRequest

  if (props.allowCreate) {
    getList = (term: string) =>
      getListRequest(term).then(list => {
        if (!list.length) {
          return term.length
            ? [
                {
                  ...R.omit(
                    generateEquipment({
                      equipmentType: EquipmentDTO.EquipmentTypeEnum.OCEANCONTAINERCHASSIS,
                      ownership: EquipmentDTO.OwnershipEnum.RENTED,
                      pickupDate: dateService.createStringDate.now,
                      availability: false,
                      unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
                    }),
                    ['model', 'make', 'insurance', 'vin', 'licensePlate', 'maintenanceInspections']
                  ),
                  chassisNumber: term
                }
              ]
            : []
        }
        return list
      })
  }

  return (
    <SearchRequest
      {...props}
      maxLength={11}
      toUpperCase={true}
      label={oc(temporaryNewEquipment).chassisNumber() || oc(equipment).chassisNumber()}
      getList={getList}
      getDetails={value =>
        isNewObject(value) ? Promise.resolve(value) : callAPI(equipmentAPI.findById, value.id).toPromise()
      }
      addToStore={(item: EquipmentDTO) => pushListItemsToStore({ update: { [EntityType.equipment]: [item] } })}
      makeDropDownItem={_equipment => {
        if (isNewObject(_equipment)) {
          const validation = [
            {
              valid: checkEquipmentNumberValidation(_equipment.chassisNumber),
              errorMessage: '• Invalid number. 4 letters and 6 or 7 numbers allowed'
            }
          ]
            .filter(item => !item.valid)
            .map(item => item.errorMessage)
          const errorMessages = validation.join(`\n`)
          const disabled = Boolean(errorMessages)

          return {
            disabled,
            className: 'create validation' + (errorMessages ? ' invalid' : ''),
            before: 'Create',
            after: errorMessages,
            label: _equipment.chassisNumber,
            value: _equipment,
            onClick: disabled
              ? undefined
              : () => {
                  setTemporaryNewEquipment(_equipment)
                  setFetching(true)

                  requestCreateEquipment(_equipment)
                    .then(savedEquipment => {
                      props.onChange(savedEquipment)
                    })
                    .finally(() => {
                      setTemporaryNewEquipment(null)
                      setFetching(false)
                    })
                }
          }
        } else {
          return { label: _equipment.chassisNumber, value: _equipment }
        }
      }}
    />
  )
}
