import * as React from 'react'
import { ITableColumnSetting, ITableRowActionButton, TableContext } from '../contexts/TableContext'

type Props = {
  listRef: React.MutableRefObject<any>
  settings: ITableColumnSetting[]
  list: any[]
  styleContainer: React.CSSProperties
  styleHeader: React.CSSProperties
  styleList: React.CSSProperties
  styleRow: React.CSSProperties
  styleButtonAdd: React.CSSProperties
  styleButtonRemove: React.CSSProperties | ((rowItem: any) => React.CSSProperties)
  getRowClassName: (rowItem: any) => string
  onButtonAddClick: () => void
  rowActionButton?: (rowItem: any) => ITableRowActionButton
  children?: any
}

export const TableProvider = React.memo((props: Props) => {
  const { settings, list, children, rowActionButton } = props
  let CSSRuleGridTemplateColumns = settings
    .map(setting => {
      const width = typeof setting.width === 'number' ? setting.width + 'px' : setting.width
      const maxWidth = setting.maxWidth
        ? typeof setting.maxWidth === 'number'
          ? setting.maxWidth + 'px'
          : setting.maxWidth
        : width
      return `minmax(${width}, ${maxWidth})`
    })
    .join(' ')

  if (rowActionButton) {
    CSSRuleGridTemplateColumns += ' 40px'
  }

  return (
    <TableContext.Provider
      value={{
        settings,
        list,
        CSSRuleGridTemplateColumns,
        styleContainer: props.styleContainer,
        styleHeader: props.styleHeader,
        styleList: props.styleList,
        styleRow: props.styleRow,
        styleButtonAdd: props.styleButtonAdd,
        styleButtonRemove: props.styleButtonRemove,
        getRowClassName: props.getRowClassName,
        onButtonAddClick: props.onButtonAddClick,
        rowActionButton: props.rowActionButton,
        listRef: props.listRef
      }}
      children={children}
    />
  )
})
