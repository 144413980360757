import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { NavigationChannelContainer, UnreadMentionMessagesCount } from './NavigationChannel'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectCommunictaionHubChannelsMeta,
  selectCommunictaionHubIsAllUnreadView
} from '../../../../store/select/communicationHubSelect'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NavigationAllUnread = (props: Props) => {
  const dispatch = useAppDispatch()
  const channelsMeta = useAppSelector(selectCommunictaionHubChannelsMeta)
  const isAllUnreadView = useAppSelector(selectCommunictaionHubIsAllUnreadView)
  const { mentionsCount, unreadMessagesCount } = React.useMemo(() => {
    let _unreadMessagesCount = 0
    let _mentionsCount = 0

    if (channelsMeta) {
      Object.values(channelsMeta).forEach(channelMeta => {
        if (channelMeta) {
          if (!channelMeta.mute && channelMeta.count) {
            _unreadMessagesCount += channelMeta.count
          }

          if (channelMeta.mentionMsgIds && channelMeta.mentionMsgIds.length) {
            _mentionsCount += channelMeta.mentionMsgIds.length
          }
        }
      })
    }

    return { mentionsCount: _mentionsCount, unreadMessagesCount: _unreadMessagesCount }
  }, [channelsMeta])

  return (
    <Container
      className={cn({ active: isAllUnreadView, 'has-unread-messages': mentionsCount })}
      onClick={() => leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.openAllUnread()))}
    >
      <AllUnreadIcon color={isAllUnreadView ? '#06182f' : 'white'} />
      All unread
      {Boolean(unreadMessagesCount || mentionsCount) && (
        <RightSide>
          {Boolean(unreadMessagesCount || mentionsCount) && <NewMessagesLabel>New messages</NewMessagesLabel>}
          {Boolean(mentionsCount) && (
            <UnreadMentionMessagesCount style={{ marginLeft: 8 }}>{mentionsCount}</UnreadMentionMessagesCount>
          )}
        </RightSide>
      )}
    </Container>
  )
}

const Container = styled(NavigationChannelContainer)`
  margin-top: 5px;

  svg {
    margin: 0 8px 0 2px;
  }
`
const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
const NewMessagesLabel = styled.div`
  font-weight: 400 !important;
  font-size: 10px;
`

export const AllUnreadIcon = ({ color }: { color?: string }) => {
  return (
    // tslint:disable:max-line-length
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="scale(1.1)">
        <path
          fill={color}
          d={
            'M15.5 5.235V12C15.5 12.825 14.825 13.5 14 13.5H3.5L0.5 16.5V3C0.5 2.175 1.175 1.5 2 1.5H9.575C9.53 1.74 9.5 1.995 9.5 2.25C9.5 4.32 11.18 6 13.25 6C14.0975 6 14.87 5.7075 15.5 5.235ZM11 2.25C11 3.495 12.005 4.5 13.25 4.5C14.495 4.5 15.5 3.495 15.5 2.25C15.5 1.005 14.495 0 13.25 0C12.005 0 11 1.005 11 2.25Z'
          }
        />
      </g>
    </svg>
  )
}
