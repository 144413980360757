import * as R from 'remeda'
import { BusinessPartnerViewDTO, businessPartnerAPI, callAPI, callAPIWithErrorMessage } from '../../../api/api'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { isNewObject } from '..'

export const createBusinessPartner = (businessPartner: BusinessPartnerViewDTO): Promise<BusinessPartnerViewDTO> => {
  return callAPIWithErrorMessage(businessPartnerAPI.create, R.omit(businessPartner, ['primaryContact', 'id'])).then(
    async requestedBusinessPartner => {
      await pushListItemsToStore({ update: { [EntityType.businessPartner]: [requestedBusinessPartner] } })

      return requestedBusinessPartner
    }
  )
}

export const updateBusinessPartner = (businessPartner: BusinessPartnerViewDTO): Promise<BusinessPartnerViewDTO> => {
  if (isNewObject(businessPartner)) {
    return createBusinessPartner(businessPartner)
  }

  return callAPIWithErrorMessage(
    businessPartnerAPI.update,
    businessPartner.id,
    R.omit(businessPartner, ['primaryContact'])
  ).then(async requestedBusinessPartner => {
    await pushListItemsToStore({ update: { [EntityType.businessPartner]: [requestedBusinessPartner] } })

    return requestedBusinessPartner
  })
}
