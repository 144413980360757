import * as React from 'react'
import { StyledGeneral } from './styledComponents'
import { PersonalInfo } from './personalInfo/'
import { Address } from './address/'
import { TruckInfo } from './TruckInfo'

type Props = {}

export const General = () => {
  return (
    <StyledGeneral minRows={81} padding={{ top: 30, left: 20, right: 20 }}>
      <PersonalInfo />
      <Address />
      <TruckInfo />
    </StyledGeneral>
  )
}
