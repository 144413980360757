import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { Container, GridColumn, Column, ColumnRow } from '../../../UI/GridSystem'
import { InputField, Input } from '../../../UI/DataFields/Input'
import { BusinessPartnerViewDTO, LocationViewDTO } from '../../../../api/api'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { usePrimaryContact } from '../../../../hooks/usePrimaryContact'

type OwnProps = {
  parentEntity: LocationViewDTO | BusinessPartnerViewDTO
  entityType: EntityType.businessPartner | EntityType.location
  columns?: 2 | 3
  padding?: {
    top?: number
    left?: number
    right?: number
    bottom?: number
  }
  titleStyles?: any | null
}

type State = {}

type DispatchProps = {}

type Props = OwnProps & State & DispatchProps

export const PrimaryContact = (props: Props) => {
  const { columns, parentEntity, entityType, padding = null, titleStyles = null } = props
  const { primaryContact, modifyPrimaryContactField } = usePrimaryContact({ parentEntity, entityType })

  const fields = {
    firstName: (
      <ColumnRow margin={{ bottom: 25 }}>
        <Input
          title={'First Name'}
          value={oc(primaryContact).firstName()}
          onChange={modifyPrimaryContactField('firstName')}
        />
      </ColumnRow>
    ),
    phone: (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber
          title={'Phone'}
          value={oc(primaryContact).phone()}
          onChange={modifyPrimaryContactField('phone')}
        />
      </ColumnRow>
    ),
    email: (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.Email
          title={'Email'}
          value={oc(primaryContact).email()}
          onChange={modifyPrimaryContactField('email')}
        />
      </ColumnRow>
    ),
    lastName: (
      <ColumnRow margin={{ bottom: 25 }}>
        <Input
          title={'Last Name'}
          value={oc(primaryContact).lastName()}
          onChange={modifyPrimaryContactField('lastName')}
        />
      </ColumnRow>
    ),
    phone2: (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber
          title={'Phone 2'}
          value={oc(primaryContact).phone2()}
          onChange={modifyPrimaryContactField('phone2')}
        />
      </ColumnRow>
    ),
    fax: (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber
          title={'Fax'}
          value={oc(primaryContact).fax()}
          onChange={modifyPrimaryContactField('fax')}
        />
      </ColumnRow>
    )
  }

  return (
    <Column columns={17} isGrid={true} padding={padding}>
      <Title style={titleStyles} rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
        Primary Contact
      </Title>

      {columns === 3 ? (
        <Container>
          <GridColumn isGrid={true} padding={{ right: 10 }}>
            {fields.firstName}
            {fields.phone2}
          </GridColumn>
          <GridColumn isGrid={true} padding={{ left: 10, right: 10 }}>
            {fields.lastName}
            {fields.email}
          </GridColumn>
          <GridColumn isGrid={true} padding={{ left: 10 }}>
            {fields.phone}
            {fields.fax}
          </GridColumn>
        </Container>
      ) : (
        <Container>
          <GridColumn columns={8} isGrid={true} padding={{ right: 10 }}>
            {fields.firstName}
            {fields.phone}
            {fields.email}
          </GridColumn>
          <GridColumn columns={8} isGrid={true} padding={{ left: 10 }}>
            {fields.lastName}
            {fields.phone2}
            {fields.fax}
          </GridColumn>
        </Container>
      )}
    </Column>
  )
}

const Title = styled(Container)``
