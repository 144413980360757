import * as React from 'react'
import styled from 'styled-components'
import { StyledInput, ClearButton } from '../Input/styles'
import theme from '../../../../styles/theme'

export const Container = styled.div`
  position: relative;
  height: 35px;
  width: 100%;
`

export const FindButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  color: #e0e0e0;
  font-size: 18px;
  width: 24px;
  overflow: hidden;
  padding-right: 8px;
  cursor: pointer;

  &:hover {
    color: #445366;
  }

  &.disable {
    color: #e0e0e0 !important;
    cursor: default;
  }

  &.fetching:after {
    content: '';
    display: block;
    padding: calc(100% - 5px) calc(100% - 5px) 0 0;
    border-radius: 50%;
    border: 2px solid;
    border-color: ${theme.colors.basicBlueColor} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  border: 1px solid rgba(229, 229, 229, 1);
  height: 35px;
  width: 100%;
  border-radius: 5px;

  &.disabled {
    border: 1px solid rgba(229, 229, 229, 0.4);
  }

  &.active {
    border-radius: 5px 5px 0 0;

    ${FindButton} {
      color: ${theme.colors.basicBlueColor} !important;
    }
  }

  &.top {
    border-radius: 0 0 5px 5px;
  }

  &.highlighted {
    box-shadow: 0 0 0 1px #ff5e5e !important;
  }

  ${ClearButton} {
    padding-right: 3px !important;
  }
`

export const Value = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-grow: 1;
  padding: 0 10px;
  cursor: default;

  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }
`

export const Disable = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
`

export const DropDown = styled.ul`
  z-index: 2;
  min-width: 150px;
  max-height: 180px;
  font-size: 12px;
  background: white;
  border-radius: 0 0 5px 5px;
  border-top: 2px solid ${theme.colors.basicBlueColor};
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  overflow: auto;

  &.top {
    top: unset;
    bottom: 100%;
    border-top: none;
    border-bottom: 2px solid ${theme.colors.basicBlueColor};
    border-radius: 5px 5px 0 0;
    box-shadow: 0 -23px 45px 0 rgba(0, 0, 0, 0.28);
  }
`

const StyledItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 10px;
  cursor: pointer;

  > div {
    line-height: 1.2;
  }

  &:not(:first-child):before {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    background-color: ${theme.colors.basicBorderColor};
  }

  &:hover {
    background-color: rgba(69, 85, 208, 0.05);

    &:before,
    & + *:before {
      content: none;
    }
  }

  // DYNAMIC CLASSES
  &.create {
    span:first-child {
      color: ${theme.colors.basicBlueColor};
      margin-right: 10px;
      user-select: none;
    }
  }

  &.google:after {
    content: 'Google';
    position: absolute;
    left: 4px;
    top: 3px;
    font-size: 8px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  &.validation {
    &.invalid {
      flex-wrap: wrap;
      cursor: default;

      span:first-child {
        opacity: 0.3;
      }
    }

    span:last-child {
      width: 100%;
      flex-shrink: 0;
      white-space: pre-wrap;
      color: ${theme.colors.defaultRed};
      margin-top: 3px;
      user-select: none;
    }
  }
`

export const Item = (props: any) => (
  <li style={{ display: 'inline-block', minWidth: '100%' }}>
    <StyledItem {...props}>{props.children}</StyledItem>
  </li>
)
