import { oc } from 'ts-optchain'
import { ActivityGroup, TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { TransportationActivityViewDTO } from '../../../api/api'
import { filterTrasportationGroups } from './filterTrasportationGroups'

export const filterUsefulActivityGroups = (
  activityGroups: ActivityGroup[],
  omitDocumentationType?: boolean
): ActivityGroup[] => {
  const activityGroupList = omitDocumentationType
    ? filterTrasportationGroups(activityGroups)
    : (activityGroups as ActivityGroup[])

  return activityGroupList.filter(
    activityGroup =>
      oc(activityGroup as TransportationActivityGroup).gotoActivity.status() !==
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
      oc(activityGroup as TransportationActivityGroup).businessActivity.status() !==
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
  )
}
