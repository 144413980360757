import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { DispatchDeliveryOrderViewDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useDispatchDeliveryOrder = (props: Props): DispatchDeliveryOrderViewDTO => {
  const { id, modifiedLists } = props
  const dispatchDeliveryOrder = useListItem({
    id,
    type: EntityType.dispatchDeliveryOrder,
    modifiedLists
  })

  return dispatchDeliveryOrder
}
