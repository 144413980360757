import * as React from 'react'
import styled from 'styled-components'
import { FieldContainer } from '../../UI/DataFields/FieldContainer'
import { DateOnFocus, DateTimePicker } from '../../UI/DataFields/DateTimePicker/views'
import { dateService } from '../../../services/timeService'

type Props = {
  dateFrom: string
  dateTo: string
  onChangeDateFrom: (dateFrom: string) => void
  onChangeDateTo: (dateTo: string) => void
  title?: string
  containerStyle?: React.CSSProperties
}

export const DateRange = (props: Props) => {
  const { title, dateFrom, dateTo, containerStyle, onChangeDateFrom, onChangeDateTo } = props

  return (
    <FieldContainer title={title} style={containerStyle}>
      <Container>
        <DateTimePicker
          hideTimeInput={true}
          maxDate={dateTo}
          date={dateFrom}
          dateOnFocus={DateOnFocus.startDay}
          onChange={date => onChangeDateFrom(date as string)}
        />
        <Separator />
        <DateTimePicker
          hideTimeInput={true}
          minDate={dateFrom}
          date={dateTo}
          dateOnFocus={DateOnFocus.endDay}
          onChange={date => {
            onChangeDateTo(dateService.convertStringDate(date as string).endDay)
          }}
        />
      </Container>
    </FieldContainer>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 93px 10px 93px;
  grid-column-gap: 10px;

  .date-time-picker {
    padding: unset;
  }
`

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '–';
  }
`
