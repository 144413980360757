import * as React from 'react'
import { oc } from 'ts-optchain'
import { TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { LocationColumn } from '../../../grid/columnSettings/locationColumnSettings'
import { useGridItemLocation } from '../../../../../hooks/useGridItemLocation'
import { locationDirectory } from '../../../../../services/DTO/location/directory'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'

const Column = React.memo((props: TGridColumn) => {
  const { className, columnName, style } = props
  const { location, primaryContact } = useGridItemLocation()
  let text = columnName

  switch (columnName) {
    case LocationColumn.Name: {
      text = oc(location).name()
      break
    }
    case LocationColumn.Type: {
      text = locationDirectory.type[location.type]
      break
    }
    case LocationColumn.Code: {
      text = oc(location).code()
      break
    }
    case LocationColumn.Company: {
      text = oc(location).careOf('')
      break
    }
    case LocationColumn.Address: {
      text = [oc(location).address.street(), oc(location).address.street2()].filter(Boolean).join(', \n')
      break
    }
    case LocationColumn.City: {
      text = oc(location).address.city()
      break
    }
    case LocationColumn.State: {
      text = getAddressState({ stateId: oc(location).address.stateId() }).code
      break
    }
    case LocationColumn.ZIP: {
      text = oc(location).address.postalCode()
      break
    }
    case LocationColumn.PCFirstName: {
      text = oc(primaryContact).firstName()
      break
    }
    case LocationColumn.PCLastName: {
      text = oc(primaryContact).lastName()
      break
    }
    case LocationColumn.PCPhone: {
      text = [oc(primaryContact).phone(), oc(primaryContact).phone2()].filter(Boolean).join('\n')
      break
    }
    case LocationColumn.PCEmail: {
      text = oc(primaryContact).email()
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
})

export default Column
