import * as React from 'react'
import { oc } from 'ts-optchain'
import { DefaultColumn } from '../../../../../UI/GridSystem'
import { StyledRight, Top } from './styles'
import Cargo from '../../../../../UI/Widget/widgets/cargo/'
import Document from '../../../../../UI/Widget/widgets/document'
import SteamShipLine from '../../../../../UI/Widget/widgets/steamShipLine'
import { TerminalIntegrationWidget } from '../../../../../UI/Widget/widgets/terminalIntegration'
import { isDDOActive, isDDOHasLocationsWithActiveTerminalIntegration } from '../../../../../../services/DTO/activity'
import { isDDOStatusNew } from '../../../../../../services/functions/test/isDDOStatusNew'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'

const Right = () => {
  const {
    isRepo,
    dispatchDeliveryOrder,
    deliveryOrder,
    deliveryOrderType,
    pickupLocation,
    returnLocation
  } = useGridItemDDO()

  return (
    <StyledRight>
      <Top>
        {!isRepo &&
          (!isDDOStatusNew(dispatchDeliveryOrder.status) || Boolean(oc(deliveryOrder).steamShipLineId())) &&
          isDDOActive(dispatchDeliveryOrder) &&
          isDDOHasLocationsWithActiveTerminalIntegration({ pickupLocation, returnLocation, deliveryOrderType }) && (
            <TerminalIntegrationWidget />
          )}
      </Top>
      <DefaultColumn
        isGrid={true}
        flexClear={true}
        padding={{
          top: 15,
          right: 16,
          left: 16
        }}
      >
        <DefaultColumn margin={{ bottom: 15 }}>
          <Cargo />
        </DefaultColumn>
        <DefaultColumn margin={{ bottom: 15 }}>
          <Document />
        </DefaultColumn>
        <DefaultColumn margin={{ bottom: 25 }}>
          <SteamShipLine />
        </DefaultColumn>
      </DefaultColumn>
    </StyledRight>
  )
}

export default Right
