import { oc } from 'ts-optchain'
import { BuySideQuoteDTO } from '../../../../api/origin/business-logic'
import { buySideQuoteAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewObject } from '../..'

// export const requestBuySideQuotesByDDOId = (ddoId: string): Promise<BuySideQuoteDTO[]> => {
//   return callAPIWithErrorMessage(buySideQuoteAPI.findById, ddoId).then(async requestedBSQs => {
//     await pushListItemsToStore({ update: { [EntityType.buySideQuote]: requestedBSQs } })

//     return requestedBSQs
//   })
// }

export const requestBuySideQuotesByDdoId = (ddoId: string): Promise<BuySideQuoteDTO[]> => {
  return callAPIWithErrorMessage(buySideQuoteAPI.getBuySideQuoteByDispatchDeliveryOrderId, ddoId).then(
    async requestedBSQs => {
      await pushListItemsToStore({ update: { [EntityType.buySideQuote]: requestedBSQs } })

      return requestedBSQs
    }
  )
}

export const requestBuySideQuoteById = (id: string): Promise<BuySideQuoteDTO> => {
  return callAPIWithErrorMessage(buySideQuoteAPI.findById, id).then(async requestedBSQ => {
    await pushListItemsToStore({ update: { [EntityType.buySideQuote]: [requestedBSQ] } })

    return requestedBSQ
  })
}

export const requestCreateBuySideQuote = (buySideQuote: BuySideQuoteDTO): Promise<BuySideQuoteDTO> => {
  return callAPIWithErrorMessage(
    buySideQuoteAPI.createBuySideQuote,
    transformBSQForSaving({ ...buySideQuote, id: undefined })
  ).then(async requestedBSQ => {
    await pushListItemsToStore({ update: { [EntityType.buySideQuote]: [requestedBSQ] } })

    return requestedBSQ
  })
}

export const requestUpdateBuySideQuote = (buySideQuote: BuySideQuoteDTO): Promise<BuySideQuoteDTO> => {
  if (isNewObject(buySideQuote)) {
    return requestCreateBuySideQuote(buySideQuote)
  }

  return callAPIWithErrorMessage(buySideQuoteAPI.update, buySideQuote.id, transformBSQForSaving(buySideQuote)).then(
    async requestedBSQ => {
      await pushListItemsToStore({ update: { [EntityType.buySideQuote]: [requestedBSQ] } })

      return requestedBSQ
    }
  )
}

export const requestDeleteBuySideQuoteById = (id: string) => {
  return callAPIWithErrorMessage(buySideQuoteAPI.deleteBuySideQuote, id).then(async () => {
    await pushListItemsToStore({ delete: { [EntityType.buySideQuote]: [id] } })

    return id
  })
}

const transformBSQForSaving = (buySideQuote: BuySideQuoteDTO): BuySideQuoteDTO => {
  return {
    ...buySideQuote,
    surcharges: oc(buySideQuote)
      .surcharges([])
      .map(surcharge => (isNewObject(surcharge) ? { ...surcharge, id: undefined } : surcharge)),
    miscSurcharges: oc(buySideQuote)
      .miscSurcharges([])
      .map(miscSurcharge => (isNewObject(miscSurcharge) ? { ...miscSurcharge, id: undefined } : miscSurcharge))
  }
}
