import * as React from 'react'
import { ITabExpandedItemData } from '../store/reducers/tabs/interfaces'
import { EntityType, TListsState, TListsStateUpdate } from '../store/reducers/lists/interfaces'

export type TExpandedItemContext = {
  tabId: string
  entityType: EntityType
  parentItemId: string
  parentItem: any | undefined
  initialParentItemState: any
  enableEditing: boolean

  isModified: boolean

  isFetching: boolean | string
  setFetching(fetchingState: boolean | string): void

  activeTab: string
  setActiveTab(tab: string): void

  modifiedLists: Partial<TListsState>
  initialLists: Partial<TListsState>
  modifyListItems(updateList: TListsStateUpdate): void
  deleteModifiedListItems(props: Partial<Record<EntityType, string[]>>): void
  modifyParentObject(item: any): void
  modifyParentObjectField(field: string): (value: any) => void

  data: ITabExpandedItemData | undefined
  setData(data: ITabExpandedItemData): void
  mergeDataProps(dataProps: Partial<ITabExpandedItemData>): void

  clearExpandedItem(): void
  clearExpandedItemModifies(): void
  saveUpdatedEntities(extraSaveData?: any): void
}

export const ExpandedItemContext: React.Context<TExpandedItemContext> = React.createContext(null)
