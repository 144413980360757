import * as React from 'react'
import styled from 'styled-components'
import { TransportationActivityViewDTO } from '../../../../../../api/api'
import { IMoveDTO } from '../../../../../../store/reducers/communicationHub/interfaces'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectActivities } from '../../../../../../store/select/activitySelect'

type Props = {
  vendorName: string
  dateMoves: { date: string; moves: IMoveDTO[] }[]
}

export const MovesGroupHeader = React.memo((props: Props) => {
  const { vendorName, dateMoves } = props
  const transportationActivityMapping = useAppSelector(selectActivities)
  let planned = 0
  let assigned = 0
  let confirmed = 0

  dateMoves.forEach(({ moves }) =>
    moves.forEach(({ items }) =>
      items.forEach(({ businessActivityId }) => {
        const storeBusinessActivity = transportationActivityMapping[businessActivityId]

        if (storeBusinessActivity) {
          if (storeBusinessActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED) {
            planned++
          } else if (storeBusinessActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED) {
            assigned++
          } else if (storeBusinessActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED) {
            confirmed++
          }
        }
      })
    )
  )

  return (
    <Header>
      <HeaderVendorName>{vendorName}</HeaderVendorName>
      <HeaderStatistic>
        Planned
        <HeaderStatisticCount className={'planned'} style={{ background: '#EBCD33' }} children={planned} />
      </HeaderStatistic>
      <HeaderStatistic>
        Assigned
        <HeaderStatisticCount className={'assigned'} style={{ background: '#7B61FF' }} children={assigned} />
      </HeaderStatistic>
      <HeaderStatistic>
        Confirmed
        <HeaderStatisticCount className={'confirmed'} style={{ background: '#7CCF81' }} children={confirmed} />
      </HeaderStatistic>
    </Header>
  )
})

const Header = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  color: #445366;
  font-size: 12px;
  margin-bottom: 6px;
`
export const HeaderVendorName = styled.div`
  width: 220px;
  font-weight: 500;
  font-size: 16px;
  color: #445366;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
`
const HeaderStatistic = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  user-select: none;
`
const HeaderStatisticCount = styled.span`
  height: 18px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 500;
  color: white;
  padding: 0 8px;
  margin-left: 5px;
`
