import * as React from 'react'
import { General } from '../Details/General'
import { Credentials } from '../../../drivers/views/Details/Credentials'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'
import { DriverViewDTO } from '../../../../../api/origin/business-logic'
import { AccountingTab } from '../Details/Accounting/AccountingTab'
import { DriverDeductionsProvider } from '../../../../../providers/DriverDeductionsProvider'
import { isNewObject } from '../../../../../services/DTO'
import { VendorAccountingReportsProvider } from '../../../../../providers/VendorAccountingReportsProvider'
import { DocumentsTab } from '../Details/Documents/DocumentsTab'

export const DriverPreview = {
  headings(driver: DriverViewDTO) {
    const driverType = driver.driverType
    const tabs = [GridItemTab.Driver.General]

    if ([DriverViewDTO.DriverTypeEnum.OWNEROPERATOR, DriverViewDTO.DriverTypeEnum.COMPANY].includes(driverType)) {
      tabs.push(GridItemTab.Driver.Credentials)
    }

    if (
      !isNewObject(driver) &&
      [DriverViewDTO.DriverTypeEnum.OWNEROPERATOR, DriverViewDTO.DriverTypeEnum.VENDOR].includes(driverType)
    ) {
      tabs.push(GridItemTab.Driver.Accounting)
    }

    if (
      [
        DriverViewDTO.DriverTypeEnum.OWNEROPERATOR,
        DriverViewDTO.DriverTypeEnum.COMPANY,
        DriverViewDTO.DriverTypeEnum.VENDOR
      ].includes(driverType)
    ) {
      tabs.push(GridItemTab.Driver.Documents)
    }

    return tabs
  },
  tabs: {
    [GridItemTab.Driver.General]: <General />,
    [GridItemTab.Driver.Credentials]: (
      <VendorAccountingReportsProvider>
        <Credentials />
      </VendorAccountingReportsProvider>
    ),
    [GridItemTab.Driver.Accounting]: (
      <DriverDeductionsProvider>
        <AccountingTab />
      </DriverDeductionsProvider>
    ),
    [GridItemTab.Driver.Documents]: <DocumentsTab />
  },
  isValid: checkValidation.driver
}
