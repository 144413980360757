import { checkLocationValidation } from './location'
import { checkDriverValidation } from './driver'
import { checkPowerUnitValidation } from './powerUnit'
import { checkEquipmentValidation } from './equipment'
import { checkSteamShipLineValidation } from './steamShipLine'
import { checkContainerValidation } from './container'
import { checkCustomerValidation } from './customer'
import { checkSubClientValidation } from './subClient'
import { checkDispatchDeliveryOrderValidation } from './dispatchDeliveryOrder'
import { isContactValid } from '../functions/test/isContactValid'
import { isAddressValid } from '../functions/test/isAddressValid'
import { isBusinessPartnerValid } from '../functions/test/isBusinessPartnerValid'
import { checkQuotesValidation } from './sellSideQuote'

export const checkValidation = {
  address: isAddressValid,
  businessPartner: isBusinessPartnerValid,
  contact: isContactValid,
  location: checkLocationValidation,
  driver: checkDriverValidation,
  powerUnit: checkPowerUnitValidation,
  equipment: checkEquipmentValidation,
  steamShipLine: checkSteamShipLineValidation,
  container: checkContainerValidation,
  customer: checkCustomerValidation,
  subClient: checkSubClientValidation,
  dispatchDeliveryOrder: checkDispatchDeliveryOrderValidation,
  quotes: checkQuotesValidation
}
