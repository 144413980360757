import * as React from 'react'
import { Container, StyledInput, ClearButton } from '../styles'
import { isHighlighted, handleKeyPress, getStringValue } from '../functions'
import { FieldContainer } from '../../FieldContainer'
import cn from 'classnames'
import { useRegExp } from '../../../../../services/functions/regExp'

export type TProps = {
  id?: string
  dataId?: string
  disabled?: boolean
  title?: string
  value: any
  onChange: (value: any) => void
  transformOnInputChange?: (value: string) => string | number
  transformInputLabel?: (value: string) => string | number
  required?: boolean
  requiredMark?: boolean
  valid?: boolean
  highlighted?: boolean
  placeholder?: string
  maxLength?: number
  minLength?: number
  updateOnTyping?: boolean
  minTypeToChange?: number
  setFocusOnInit?: boolean
  // inputContainerStyle?: React.CSSProperties
  inputContainerStyle?: any
  regExp?: RegExp
  errorMessage?: JSX.Element | string
  styleFieldContainer?: React.CSSProperties
}

export type TStateProps = {
  editState: boolean
  inputRef: React.RefObject<HTMLInputElement>
  value: any
}

export const Input = (props: TProps) => {
  const {
    required,
    requiredMark,
    title,
    maxLength,
    minLength,
    disabled,
    transformOnInputChange,
    transformInputLabel,
    id,
    dataId,
    regExp,
    styleFieldContainer
  } = props
  const inputRef = React.useRef(null)
  const [editState, setEditState] = React.useState(false)
  const [value, setValue] = React.useState(getStringValue(props.value) || '')
  const isEmptyValue = value === undefined || value === null || value === ''
  const state: TStateProps = {
    editState,
    inputRef,
    value: value
  }

  React.useEffect(() => {
    setValue(getStringValue(props.value) || '')
  }, [props])

  React.useEffect(() => {
    if (props.setFocusOnInit && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const onInputChange = (e: any) => {
    const currentValue = useRegExp(regExp, e.target.value)
    const updatedValue = String(transformOnInputChange ? transformOnInputChange(currentValue) : currentValue)

    if (updatedValue && props.updateOnTyping && updatedValue.length >= props.minTypeToChange) {
      return props.onChange(updatedValue)
    }
    setValue(updatedValue)
  }

  const handleFocus = () => {
    setEditState(true)
  }

  const handleBlur = () => {
    const prevValueState = getStringValue(props.value)
    if ((value || prevValueState) && value !== prevValueState) {
      props.onChange(value ? value.trim() : null)
    } else if (props.updateOnTyping && !value) {
      props.onChange(null)
    }
    setEditState(false)
  }

  const inputValue = transformInputLabel ? transformInputLabel(value) : value

  return (
    <FieldContainer title={title} required={required || requiredMark} disabled={disabled} style={styleFieldContainer}>
      <Container
        style={{ width: '100%', ...props.inputContainerStyle } as any}
        className={cn('input-container', {
          'extra-padding': isEmptyValue || disabled,
          highlighted: isHighlighted(props, state),
          disabled
        })}
      >
        <StyledInput
          id={id}
          data-id={dataId}
          disabled={disabled}
          ref={inputRef}
          type="text"
          // @ts-ignore
          onKeyPress={handleKeyPress(props, state)}
          placeholder={props.placeholder || 'Type here'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={inputValue}
          maxLength={maxLength}
          onChange={onInputChange}
          data-copy-value-on-mousedown={true}
        />
        {!isEmptyValue && !disabled && (
          <ClearButton className={'mdi mdi-close-circle'} onClick={() => props.onChange(null)} />
        )}
      </Container>
    </FieldContainer>
  )
}
