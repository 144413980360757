import * as React from 'react'
import { WidgetTable, RowTitleColumn } from '../../../../../popups/Table'
import { StyledTable } from './styles'

type Props = {
  rows: {
    [title: string]: string
  }
}

export const Table = ({ rows }: Props) => {
  const body = Object.keys(rows).reduce(
    (acc, curr) => [...acc, [<RowTitleColumn key={curr}>{curr}</RowTitleColumn>, rows[curr]]],
    []
  )

  return (
    <StyledTable>
      <WidgetTable body={body} />
    </StyledTable>
  )
}
