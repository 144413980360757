import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { BoldLine, Text, Title } from '../../styles'
import { getStreetTurnStatus } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

export const Window = () => {
  const { isExport, streetTurn, dispatchDeliveryOrder, equipment, containerType, container } = useGridItemDDO()
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn,
    streetTurnCount: dispatchDeliveryOrder && dispatchDeliveryOrder.streetTurnCount
  })
  const streetTurnStatusColor = ddoDirectory.streetTurnStatusColor[streetTurnStatus]

  return (
    <>
      <Title rows={2} margin={{ bottom: 10 }}>
        Equipment
      </Title>
      <BoldLine className={'letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
        {[oc(containerType).code(), oc(container).number()].filter(Boolean).join(': ')}
      </BoldLine>
      <Text className={'opacity'} rows={3} alignCenter={true}>
        CHASSIS: {oc(equipment).chassisNumber()}
      </Text>
      {Boolean(streetTurnStatus) && (
        <StyledStreetTurnLabel style={{ backgroundColor: streetTurnStatusColor }}>
          ST {ddoDirectory.streetTurnStatusLabel[streetTurnStatus]}
          {(streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED ||
            streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED) && (
            <StreetTurnDDOLink
              style={{ marginTop: 3 }}
              onClick={event =>
                openDDOinNewTab({
                  // @ts-ignore
                  event,
                  ddoNumber: isExport ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber,
                  ddoId: isExport ? streetTurn.ddoImportId : streetTurn.ddoExportId
                })
              }
            >
              DDO# {isExport ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
            </StreetTurnDDOLink>
          )}
        </StyledStreetTurnLabel>
      )}
    </>
  )
}

const StyledStreetTurnLabel = styled.div`
  min-height: 20px;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
`

const StreetTurnDDOLink = styled.div`
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    color: #5599ff;
  }
`
