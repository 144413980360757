import { oc } from 'ts-optchain'
import { TransportationActivityViewDTO } from '../../../api/api'

export const isCompletedActivity = (activity: TransportationActivityViewDTO): boolean => {
  return isCompletedActivityStatus(oc(activity).status())
}

export const isCompletedActivityStatus = (status: TransportationActivityViewDTO.StatusEnum): boolean => {
  return status === TransportationActivityViewDTO.StatusEnum.COMPLETED
}
