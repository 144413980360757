import * as React from 'react'
import { General } from '../Details/General'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'
import { Contacts } from '../Details/Contacts'
import { SubClients } from '../Details/General/SubClients'

const T = GridItemTab.Customer

export const CustomerPreview = {
  headings: [T.General, T.Contacts, T.SubClients],
  tabs: {
    [T.General]: <General />,
    [T.Contacts]: <Contacts />,
    [T.SubClients]: <SubClients />
  },
  isValid: checkValidation.customer
}
