import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { dateService } from '../../../../../../services/timeService'
import { getDispatch, getNewStoreState } from '../../../../../../store'
import { communicationHubActions } from '../../../../../../store/reducers/communicationHub'
import { IMoveDTO } from '../../../../../../store/reducers/communicationHub/interfaces'
import theme from '../../../../../../styles/theme'
import { MoveRow } from './MoveRow/MoveRow'
import { MovesGroupHeader } from './MovesGroupHeader'

type Props = {
  currentTabId: string
  vendorId: string
  label: string
  dateMoves: { date: string; moves: IMoveDTO[] }[]
}

export const MovesGroup = React.memo((props: Props) => {
  const { currentTabId, vendorId, label, dateMoves } = props
  let moveCounter = 0

  return (
    <Container style={{ counterReset: 'planned assigned confirmed' }}>
      <MovesGroupHeader vendorName={label} dateMoves={dateMoves} />
      <MovesContainer>
        {dateMoves.map(({ date, moves }) => (
          <DayMoves className={'day-moves'} key={date}>
            <DayMovesDateLink
              onClick={() => {
                const {
                  communicationHub: { activeRouteBuilder },
                  lists: { driver }
                } = getNewStoreState()
                const dispatch = getDispatch()

                if (
                  activeRouteBuilder &&
                  activeRouteBuilder.vendorId === vendorId &&
                  dateService.isTheSameDay(oc(activeRouteBuilder.tabs[0]).date(), date)
                ) {
                  return
                }

                dispatch(
                  communicationHubActions.setActiveRouteBuilderVendor({
                    vendorId,
                    authUserId: oc(driver[vendorId]).authUserId(),
                    forceDate: date
                  })
                )
              }}
            >
              {dateService.makeLabel(date, { long: true, fullYear: true })}
            </DayMovesDateLink>
            {moves.map(move => {
              moveCounter++
              return <MoveRow key={move.id} currentTabId={currentTabId} move={move} />
            })}
          </DayMoves>
        ))}
      </MovesContainer>
    </Container>
  )
})

const Container = styled.div`
  display: none;
  padding: 20px 9px 0 9px;

  &:has(.move-activities:not(:empty)) {
    display: unset;

    & ~ section {
      display: none;
    }
  }
`
const MovesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`
const DayMoves = styled.div`
  display: none;

  &:has(.move-activities:not(:empty)) {
    display: unset;

    .move:has(.move-activities:not(:empty)) ~ .move .move-row-activity:first-child,
    ~ .day-moves .move-row-activity:first-child {
      background-color: #f5f7f7 !important;
      color: rgb(114, 114, 114) !important;
      outline: 1px solid #dce6f5 !important;

      .ActivityIcon {
        background: #b2b2b2 !important;
      }
    }
  }
`
const DayMovesDateLink = styled.div`
  font-size: 12px;
  text-decoration-line: underline;
  color: ${theme.colors.basicBlueColor};
  padding-bottom: 6px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
`
