import { Action, Failure, Success } from 'typescript-fsa'
import * as Statuses from 'http-status-codes'
import { IStore, AuthorizationState } from '../../../store/store.interface'
import { authLoginWithRedirect, dispatchActionAfterAuthorization } from '../../authService/actions/authServiceActions'
import { Observable, concat, from, of, interval } from 'rxjs'
import { catchError, mergeMap, take, delay } from 'rxjs/operators'
import { StateObservable } from 'redux-observable'
import { NOTHING } from '../actions'
import { getItemFromStorage, StorageKeys } from '../../storageService/storageService'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'

interface IServerError {
  error: string
  exception: string
  message: string
  path: string
  status: number
  timestamp: string
}

const convertErrorToMessage = (error: any) => {
  if (typeof error === 'string') {
    return error
  }
  if (error.message) {
    return error.message
  }
  return 'Unknown error'
}

const apiRequest = <Res, Req>(
  store$: StateObservable<IStore>,
  requestTrigger: (action: Action<Req>) => Promise<Res>,
  successCallback?: (
    res: Res,
    req: Req
  ) => Action<Success<Req, Res>> | Action<Req> | Observable<any> | Observable<any>[] | Action<any>[],
  errorCallback?: (res: Response, req: Req) => Action<Failure<Req, Res>> | Action<Req>,
  withoutSpinner = false
) => (source: Observable<Action<Req>>): Observable<any> =>
  source.pipe(
    delay(1),
    mergeMap((action: Action<Req>) => {
      const { authorization } = store$.value

      if (authorization.state === AuthorizationState.Authorizing) {
        return of(dispatchActionAfterAuthorization(action))
      }

      const requestPromise = requestTrigger(action)

      const requestStream$ = from(requestPromise).pipe(
        mergeMap((response: Res) => {
          if (successCallback) {
            const result = successCallback(response, action.payload)
            return Array.isArray(result) ? of(...result) : of(result)
          }
          return of(NOTHING())
        }),
        catchError(
          (response: Response): Observable<any> => {
            const authState = store$.value.authorization
            const accessToken = getItemFromStorage(StorageKeys.AccessToken)
            const refreshToken = getItemFromStorage(StorageKeys.RefreshToken)

            // Response can be different. It can be interface Response from lib.dom, or some string, or nothing
            // in case of CORS issues, so we check everything
            const responseErrorText = response
              ? response.statusText !== ''
                ? response.statusText
                : response.status
              : response

            // tslint:disable:no-console
            console.warn(
              responseErrorText,
              'action',
              action,
              `AccessToken: ${accessToken}`,
              `Auth state: ${authState && authState.state}`
            )
            // tslint:enable:no-console

            if (refreshToken === null) {
              // We do not have refresh token, force to login with redirect
              return of(authLoginWithRedirect())
            } else {
              switch (response.status) {
                case Statuses.UNAUTHORIZED:
                  // Server side error occurs.
                  if (authState.state === AuthorizationState.Authorizing) {
                    // Add current action to the queue `actionsToDispatchAfterSuccessfulAuthorization` (IStore)
                    return of(dispatchActionAfterAuthorization(action))
                  } else {
                    // 1. We're not authorized, wait 1 second before next action takes place (interval, take)
                    // 2. While not authorized put needed actions to the store (dispatchActionAfterAuthorization)
                    // 3. Hide spinner (we do not need it at this point)
                    // 4. Try to obtain new AccessToken with `loginWithRefreshToken.started`, see epics
                    // 5. If there is a bug on backend:
                    //    at this point we're Authorized as we have "valid" AccessToken since we use latest
                    //    token from backend, but sometimes backend rejects newly acquired token.
                    //    In this case we can't do nothing.
                    //    We can just ensure we're not hanging the browser with excessive requests by using interval:
                    return interval(1000).pipe(
                      take(1),
                      mergeMap(() => {
                        return concat(
                          of(dispatchActionAfterAuthorization(action))
                          // of(loginWithRefreshToken.started({ refreshToken }))
                        )
                      })
                    )
                  }
                case Statuses.NOT_FOUND:
                  // Usually if we receive 404, we should provide errorCallback inside epic
                  // If we won't, in some cases we will watch forever spinning spinner
                  return errorCallback ? of(errorCallback(response, action.payload)) : of(NOTHING())
                default:
                  if (errorCallback) {
                    return of(errorCallback(response, action.payload))
                  } else {
                    // Response can be different, depends on what was the initiator: fetch, offline network etc.
                    // In this case it's probably just a string
                    if (!response.json) {
                      // tslint:disable-next-line
                      console.error(response)
                      showErrorModalWindow({ content: 'Unknown error' })

                      return of(NOTHING())
                    }

                    return from(response.json()).pipe(
                      mergeMap((error: IServerError) => {
                        showErrorModalWindow({
                          content: convertErrorToMessage(error)
                        })

                        return of(NOTHING())
                      })
                    )
                  }
              }
            }
          }
        )
      )

      // All good. Show spinner if needed (see above), and dispatch action
      return requestStream$
    })
  )

export default apiRequest
