import { callAPI } from '../../../api/api'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import driverActivityAxios from './driverActivityAxios'
import { IDriverActivityDTO } from './interfaces'

export const requestDriversActvity = (): Promise<IDriverActivityDTO[]> => {
  return callAPI(() => driverActivityAxios.get(`/driver-activeness/`))
    .toPromise()
    .then(async data => {
      if (data && Array.isArray(data.data) && data.data.length) {
        await pushListItemsToStore({
          update: {
            [EntityType.driverActivity]: data.data
          }
        })

        return data.data
      }

      return []
    })
}
