import { StateDTO } from '../../../api/api'
import { getStateMapping } from '../../../store'
import { generateAddressState } from '../generate/generateAddressState'

export const getAddressState = (props?: {
  stateId?: string
  codes?: { stateCode: string; countryCode: string }
}): StateDTO => {
  const { stateId, codes } = props || {}

  if (stateId || codes) {
    const stateMapping = getStateMapping()

    if (stateId) {
      return stateMapping[stateId] || generateAddressState()
    } else if (codes) {
      const { stateCode, countryCode } = codes
      const states = Object.values(stateMapping)

      return (
        states.find(state => state.code === stateCode && state.countryCode === countryCode) || generateAddressState()
      )
    }
  }

  return generateAddressState()
}
