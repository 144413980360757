import * as R from 'remeda'
import { PowerUnitViewDTO } from '../../api/api'
import { updatePowerUnit } from '../../components/common/powerUnit/epics'
import { isNewObject } from '../DTO'

type Props = {
  powerUnit: PowerUnitViewDTO
}

export const savePowerUnit = async (props: Props): Promise<PowerUnitViewDTO> => {
  const { powerUnit } = props
  let savedPowerUnit: PowerUnitViewDTO = powerUnit

  if (powerUnit) {
    savedPowerUnit = await updatePowerUnit({
      ...powerUnit,
      maintenanceInspections: powerUnit.maintenanceInspections.map(item =>
        isNewObject(item) ? R.omit(item, ['id']) : item
      ),
      repairs: powerUnit.repairs.map(item => (isNewObject(item) ? R.omit(item, ['id']) : item))
    })
  }

  return savedPowerUnit
}
