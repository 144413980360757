import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO } from '../../api/api'
import { requestUpdateDeliveryOrder } from '../../components/common/deliveryOrder/epics'

type Props = {
  deliveryOrder: DeliveryOrderViewDTO | undefined
}

export const saveDeliveryOrder = async (props: Props): Promise<DeliveryOrderViewDTO> => {
  const { deliveryOrder } = props
  let savedDeliveryOrder = deliveryOrder

  if (deliveryOrder) {
    savedDeliveryOrder = await requestUpdateDeliveryOrder(deliveryOrder)
  }

  return savedDeliveryOrder
}
