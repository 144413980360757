import { oc } from 'ts-optchain'

export const whatIsIt = (item: any) => {
  return {
    get isString(): boolean {
      return typeof item === 'string'
    },
    get isFunction(): boolean {
      return Object.prototype.toString.call(item) === '[object Function]'
    },
    get isArray(): boolean {
      return Object.prototype.toString.call(item) === '[object Array]'
    },
    get isDate(): boolean {
      return Object.prototype.toString.call(item) === '[object Date]'
    },
    get isObject(): boolean {
      return Object.prototype.toString.call(item) === '[object Object]'
    },
    get isNewObject(): boolean {
      const id = oc(item).id('')
      return id.includes('new') || id.includes('fake')
    },
    get isNewId(): boolean {
      const id = item && typeof item === 'string' ? item : ''
      return id.includes('new') || id.includes('fake')
    }
  }
}
