import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'
import { customerLists } from '../../select/customerLists'
import { CustomerColumn } from '../../../components/common/grid/columnSettings/customerColumnSettings'

export const customerFilterSettings = {
  [CustomerColumn.DateCreated]: {
    name: 'createdAt',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  Name: {
    name: 'businessPartner.legalName',
    type: ColumnDTO.Type.string
  },
  [CustomerColumn.Address]: {
    name: 'businessPartner.legalAddress',
    type: ColumnDTO.Type.string
  },
  [CustomerColumn.SalesRep]: {
    name: 'salesRepUserId',
    type: ColumnDTO.Type.multiSelectList,
    disableSorting: true
  },
  [CustomerColumn.NetTermsUnit]: {
    name: 'netTermsUnit',
    type: ColumnDTO.Type.list,
    items: customerLists.netTermsUnit
  },
  [CustomerColumn.NetTerms]: {
    name: 'netTerms',
    type: ColumnDTO.Type.list,
    items: customerLists.netTerms
  },
  [CustomerColumn.PrimaryContact]: {
    name: 'businessPartner.primaryContact.firstName',
    type: ColumnDTO.Type.string
  }
}
