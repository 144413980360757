import { DriverDTO } from '../../../api/origin/business-logic'
import theme from '../../../styles/theme'

const type = {
  [DriverDTO.DriverTypeEnum.COMPANY]: 'Company Driver',
  [DriverDTO.DriverTypeEnum.OWNEROPERATOR]: 'Owner-Operator',
  [DriverDTO.DriverTypeEnum.VENDOR]: 'Vendor',
  [DriverDTO.DriverTypeEnum.OUTSOURCE]: 'Outsource'
}

const operationalPreference = {
  [DriverDTO.OperationalPreferenceEnum.TEAM]: 'Team',
  [DriverDTO.OperationalPreferenceEnum.SINGLE]: 'Single'
}

const millageRestriction = {
  [DriverDTO.MillageRestrictionEnum.LESSTHEN100MILES]: '<= 100 Miles',
  [DriverDTO.MillageRestrictionEnum.BETWEEN100AND150MILES]: '> 100 ~ 150 Local Roads Miles',
  [DriverDTO.MillageRestrictionEnum.BETWEEN150AND300MILES]: '> 150 ~ 300 Miles',
  [DriverDTO.MillageRestrictionEnum.MORETHEN300MILES]: '> 300 Miles'
}

export const endorsement = {
  [DriverDTO.EndorsementEnum.HAZARDOUSMATERIALS]: 'Hazardous Materials',
  [DriverDTO.EndorsementEnum.TANKERWITHHAZARDOUSMATERIALS]: 'Tanker with Hazardous Materials',
  [DriverDTO.EndorsementEnum.TANKER]: 'Tanker',
  [DriverDTO.EndorsementEnum.DOUBLESTRIPLES]: 'Doubles/Triples'
}

export const status = {
  [DriverDTO.StatusEnum.AVAILABLE]: 'Available',
  [DriverDTO.StatusEnum.NOTAVAILABLEASSIGNED]: 'Not Available (Assigned)',
  [DriverDTO.StatusEnum.NOTAVAILABLEINUSE]: 'Not Available (In-Use)',
  [DriverDTO.StatusEnum.INACTIVEEXPIREDCREDENTIALS]: 'Expired Credentials',
  [DriverDTO.StatusEnum.INACTIVENODRUGTEST]: 'No Drug Test',
  [DriverDTO.StatusEnum.INACTIVEDOTSTOP]: 'DOT Stop',
  [DriverDTO.StatusEnum.INACTIVENOBIINSPECTION]: 'No BI Inspection',
  [DriverDTO.StatusEnum.INACTIVENOQUARTERLYINSPECTION]: 'No Quarterly Inspection',
  [DriverDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS]: 'Off Duty',
  [DriverDTO.StatusEnum.INACTIVEHOSVIOLATION]: 'HOS Violations',
  [DriverDTO.StatusEnum.INACTIVENOPAPERLOG]: 'No Paper Log',
  [DriverDTO.StatusEnum.POWERUNITISINACTIVE]: 'Power Unit is Inactive',
  [DriverDTO.StatusEnum.TERMINATED]: 'Terminated',
  [DriverDTO.StatusEnum.CANDIDATEREJECTED]: 'Candidate Rejected',
  [DriverDTO.StatusEnum.CANDIDATE]: 'Candidate',
  [DriverDTO.StatusEnum.CANDIDATEREVIEW]: 'Candidate Review',
  [DriverDTO.StatusEnum.CANDIDATEAPPROVED]: 'Candidate Approved'
}

export const statusColor: Record<DriverDTO.StatusEnum, string> = {
  [DriverDTO.StatusEnum.CANDIDATEREJECTED]: theme.colors.defaultRed,
  [DriverDTO.StatusEnum.CANDIDATE]: '#EF8D5D',
  [DriverDTO.StatusEnum.CANDIDATEREVIEW]: '#98A4B4',
  [DriverDTO.StatusEnum.CANDIDATEAPPROVED]: '#6877EE'
}

export const sortedCandidateStatuses = [
  DriverDTO.StatusEnum.CANDIDATEREJECTED,
  DriverDTO.StatusEnum.CANDIDATE,
  DriverDTO.StatusEnum.CANDIDATEREVIEW,
  DriverDTO.StatusEnum.CANDIDATEAPPROVED
]

export const driverDirectory = {
  type,
  status,
  statusColor,
  operationalPreference,
  millageRestriction,
  endorsement,
  sortedCandidateStatuses
}
