import { oc } from 'ts-optchain'
import { IMove, IMoveItem, MoveStatus } from '../../../store/reducers/communicationHub/interfaces'
import { TransportationActivityViewDTO } from '../../../api/api'

export const testMoveStatus = (move: IMove) => {
  const { items, startDate } = move
  const moveItemsToCheck = items.filter(({ gotoActivity }) => {
    return oc(gotoActivity).status() !== TransportationActivityViewDTO.StatusEnum.NEW
  })
  const getItemStatus = (item: IMoveItem) => {
    if (
      oc(item).gotoActivity.bobtailFrom() &&
      oc(item).gotoActivity.status() === TransportationActivityViewDTO.StatusEnum.INPROCESS
    ) {
      return oc(item).businessActivity.status()
    }

    return oc(item).gotoActivity.status()
  }

  return {
    get [MoveStatus.PLANNED]() {
      return (
        moveItemsToCheck.some(item => getItemStatus(item) === TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED) &&
        moveItemsToCheck.every(item => getItemStatus(item) !== TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED)
      )
    },
    get [MoveStatus.ASSIGNED]() {
      return moveItemsToCheck.every(
        item => getItemStatus(item) === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
      )
    },
    get [MoveStatus.CONFIRMED]() {
      return (
        !startDate &&
        moveItemsToCheck.every(item => getItemStatus(item) === TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED)
      )
    },
    get [MoveStatus.REFUSED]() {
      return moveItemsToCheck.some(
        item => getItemStatus(item) === TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
      )
    },
    get [MoveStatus.INPROCESS]() {
      return (
        startDate &&
        moveItemsToCheck.every(item => getItemStatus(item) !== TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED)
      )
    }
  }
}

export const getMoveStatus = (move: IMove): MoveStatus => {
  if (move.status === MoveStatus.COMPLETED || move.status === MoveStatus.UNSUCCESSFUL) {
    return move.status
  }

  const statusList = [
    MoveStatus.REFUSED,
    MoveStatus.ASSIGNED,
    MoveStatus.PLANNED,
    MoveStatus.CONFIRMED,
    MoveStatus.INPROCESS
  ]

  return statusList.find(_status => testMoveStatus(move)[_status]) || move.status
}
