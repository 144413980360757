import * as React from 'react'
import { ChannelSorting, ISearchChannelFilters } from '../../../../store/reducers/communicationHub/interfaces'
import { ChannelBrowserHeader } from './ChannelBrowserHeader'
import { getDispatchDeliveryOrderChannels, getGeneralChatCounter } from '../../epics'
// @ts-ignore
import { debounce } from 'debounce'
import { DispatchDeliveryOrderSearchDTO } from '../../../../api/origin/business-logic'
import { ChannelBrowserBody } from './ChannelBrowserBody'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ChannelBrowser = (props: Props) => {
  const [fetching, setFetching] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [sorting, setSorting] = React.useState<ChannelSorting>(ChannelSorting.recentlyUpdatedFirst)
  const [searchFilters, setSearchFilters] = React.useState<ISearchChannelFilters>({})
  const [searchResults, setSearchResults] = React.useState<DispatchDeliveryOrderSearchDTO[]>([])

  const searchChannelRequest = React.useCallback(
    debounce(async (term: string) => {
      setFetching(true)

      const ddoChannelsLimit = 100
      const ddoIds: string[] = []

      if (!term) {
        await getGeneralChatCounter().then(generalChannelCounters => {
          for (let i = 0; i < ddoChannelsLimit; i++) {
            const ddoId = generalChannelCounters[i] ? generalChannelCounters[i].id : undefined
            if (ddoId) {
              ddoIds.push(ddoId)
            }
          }
        })
      }

      getDispatchDeliveryOrderChannels(ddoChannelsLimit, term, ddoIds)
        .then(setSearchResults)
        .finally(() => setFetching(false))
    }, 500),
    [setSearchValue]
  )

  React.useEffect(() => {
    setFetching(true)
    searchChannelRequest(searchValue)
  }, [searchValue, searchChannelRequest])

  const toggleSorting = React.useCallback(() => {
    setSorting(_sorting =>
      _sorting === ChannelSorting.recentlyUpdatedFirst
        ? ChannelSorting.recentlyUpdatedLast
        : ChannelSorting.recentlyUpdatedFirst
    )
  }, [])

  return (
    <>
      <ChannelBrowserHeader
        fetching={fetching}
        searchResultsCount={searchResults.length}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sorting={sorting}
        toggleSorting={toggleSorting}
      />
      <ChannelBrowserBody
        fetching={fetching}
        searchResults={searchResults}
        searchValue={searchValue}
        sorting={sorting}
      />
    </>
  )
}
