import * as React from 'react'
import { InputField } from '../../../../../UI/DataFields/Input'
import { GridColumn, Container } from '../../../../../UI/GridSystem'
import theme from '../../../../../../styles/theme'
import { Delete } from '../../../../../UI/styledComponents'
import { FeeScheduleDTO } from '../../../../../../api/origin/business-logic/api'
import { Title, Date, ColumnTitles, Row, AddRow } from './styledComponents'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemSteamShipLine } from '../../../../../../hooks/useGridItemSteamShipLine'
// tslint:disable-next-line:max-line-length
import { generateContainerDetentionPerDiemFeeSchedule } from '../../../../../../services/functions/generate/generateContainerDetentionPerDiemFeeSchedule'

type Props = {}

export const ContainerDetentionPerDiemFeeSchedule = (props: Props) => {
  const { steamShipLine } = useGridItemSteamShipLine()
  const { modifyParentObjectField } = useExpandedItem()

  const createItem = () => {
    modifyParentObjectField('containerDetentionPerDiemFeeSchedule')([
      ...(steamShipLine.containerDetentionPerDiemFeeSchedule || []),
      generateContainerDetentionPerDiemFeeSchedule()
    ])
  }

  const deleteItemById = (id: string) => {
    modifyParentObjectField('containerDetentionPerDiemFeeSchedule')(
      steamShipLine.containerDetentionPerDiemFeeSchedule.filter(item => item.id !== id)
    )
  }

  const updateItemFieldById = (id: string) => (field: keyof FeeScheduleDTO) => (value: any) => {
    modifyParentObjectField('containerDetentionPerDiemFeeSchedule')(
      steamShipLine.containerDetentionPerDiemFeeSchedule.map(item => {
        if (item.id === id) {
          return { ...item, [field]: value }
        }

        return item
      })
    )
  }

  return (
    <Container
      columns={28}
      maxColumns={40}
      flexBasis={'auto'}
      isGrid={true}
      background={theme.colors.lightGrayBackground}
      padding={{ top: 20, left: 20, right: 20 }}
    >
      <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
        Container Detention Per Diem Fee Schedule
      </Title>

      {Boolean(
        steamShipLine.containerDetentionPerDiemFeeSchedule && steamShipLine.containerDetentionPerDiemFeeSchedule.length
      ) && (
        <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 20 }}>
          <Container columns={7}>From Day</Container>
          <Container columns={7}>To Day</Container>
          <Container columns={6}>Per Diem Cost. $</Container>
        </ColumnTitles>
      )}

      {steamShipLine.containerDetentionPerDiemFeeSchedule.map(containerDetentionPerDiemFeeSchedule => {
        const deleteCurrentItem = () => deleteItemById(containerDetentionPerDiemFeeSchedule.id)
        const updateCurrentItem = (field: keyof FeeScheduleDTO) => (value: any) => {
          return updateItemFieldById(containerDetentionPerDiemFeeSchedule.id)(field)(value)
        }

        return (
          <Row rows={9} margin={{ bottom: 10 }} key={containerDetentionPerDiemFeeSchedule.id}>
            <Date columns={7}>
              <InputField.Numeric
                value={
                  containerDetentionPerDiemFeeSchedule.fromDay === null ||
                  containerDetentionPerDiemFeeSchedule.fromDay === undefined
                    ? ''
                    : String(containerDetentionPerDiemFeeSchedule.fromDay)
                }
                onChange={updateCurrentItem('fromDay')}
              />
            </Date>
            <Date columns={7}>
              <InputField.Numeric
                value={
                  containerDetentionPerDiemFeeSchedule.toDay === null ||
                  containerDetentionPerDiemFeeSchedule.toDay === undefined
                    ? ''
                    : String(containerDetentionPerDiemFeeSchedule.toDay)
                }
                onChange={updateCurrentItem('toDay')}
              />
            </Date>
            <GridColumn columns={6}>
              <InputField.Money
                value={containerDetentionPerDiemFeeSchedule.cost}
                onChange={updateCurrentItem('cost')}
              />
            </GridColumn>
            <Delete className="mdi mdi-delete" columns={2} isFixed={true} onClick={deleteCurrentItem} />
          </Row>
        )
      })}

      <AddRow rows={9} alignCenter={true} justifyCenter={true} margin={{ bottom: 20 }} onClick={createItem}>
        Add Fee
      </AddRow>
    </Container>
  )
}
