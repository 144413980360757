// tslint:disable:max-line-length
import { v4 } from 'uuid'
import { tabDirectory } from '../../../store/reducers/tabs/directory'
import { ITab, ITabUpdate, TabDTO } from '../../../store/reducers/tabs/interfaces'
import { SchedulerFilter, SchedulerSortBy } from '../../../components/common/scheduler/interfaces'
import {
  DispatchDeliveryOrderViewDTO,
  LocationViewDTO,
  OngoingActivityGroupDTO
} from '../../../api/origin/business-logic'
import { dateService } from '../../timeService'
import { defaultQMPTabData } from '../../DTO/qmp/defaultTabData'
import { defaultSchedulerTabData } from '../../DTO/activity/defaultTabData'
import { ddoDirectory } from '../../DTO/dispatchDeliveryOrder/directory'
import { locationColumnSettings } from '../../../components/common/grid/columnSettings/locationColumnSettings'
import { containerColumnSettings } from '../../../components/common/grid/columnSettings/containerColumnSettings'
import { powerUnitColumnSettings } from '../../../components/common/grid/columnSettings/powerUnitColumnSettings'
import { equipmentColumnSettings } from '../../../components/common/grid/columnSettings/equipmentColumnSettings'
import { driverColumnSettings } from '../../../components/common/grid/columnSettings/driverColumnSettings'
import { customerColumnSettings } from '../../../components/common/grid/columnSettings/customerColumnSettings'
import { steamShipLineColumnSettings } from '../../../components/common/grid/columnSettings/steamShipLineColumnSettings'
import { subClientColumnSettings } from '../../../components/common/grid/columnSettings/subClientColumnSettings'
import { dispatchDeliveryOrderColumnSettings } from '../../../components/common/grid/columnSettings/dispatchDeliveryOrderColumnSettings'
import { groupedDriverStatuses } from '../../../constants/driverStatuses'
import { generateTabExpandedItem } from './generateTabExpandedItem'

export const generateTab = (type: TabDTO.Type, _options?: Partial<ITabUpdate>, tabTypeCount?: number): ITab => {
  const options = _options || {}
  let label = `${tabDirectory.tabLabel[type]}`

  if (type !== TabDTO.Type.scheduler) {
    label += ` List` + (tabTypeCount ? ` ${tabTypeCount}` : '')
  }

  const tab: ITab = {
    id: v4(),
    label,
    selectedIds: [],
    pinnedIds: [],
    disabledSelectIds: [],
    hideUnselectedGridItemIds: [],
    // @ts-ignore
    uiSettings: undefined,
    visited: false,
    active: false,
    fetching: false,
    refrashOnTabEnter: false,
    type,
    actionsType: undefined,
    request: undefined,
    gridItemIds: undefined,
    data: undefined,
    // @ts-ignore
    permissions: undefined,
    expandedItem: generateTabExpandedItem(),
    ...options
  }

  tab.permissions = {
    localStorage: true,
    websoketsUpdate: true,
    edit: true,
    sort: sortingPermission(type),
    filter: filterPermission(type),
    ...(options.permissions || {})
  }

  tab.uiSettings = {
    ...(uiSettings[type] || { sorting: '', columns: {}, filter: [] }),
    ...(options.uiSettings || {})
  }

  tab.data = {
    // @ts-ignore
    ...(defaultTabData[type] || {}),
    ...(options.data || {})
  }

  return tab
}

export const filterPermission = (type: TabDTO.Type): boolean => {
  switch (type) {
    case TabDTO.Type.dispatchDeliveryOrder:
    case TabDTO.Type.container:
    case TabDTO.Type.location:
    case TabDTO.Type.customer:
    case TabDTO.Type.steamShipLine:
    case TabDTO.Type.driver:
      return true
    default:
      return false
  }
}

const sortingPermission = (type: TabDTO.Type): boolean => {
  switch (type) {
    case TabDTO.Type.dispatchDeliveryOrder:
    case TabDTO.Type.container:
    case TabDTO.Type.location:
    case TabDTO.Type.customer:
    case TabDTO.Type.steamShipLine:
      return true
    default:
      return false
  }
}

const uiSettings: Record<string, TabDTO.UISettings> = {
  [TabDTO.Type.powerUnit]: {
    columns: powerUnitColumnSettings,
    sorting: '-updatedAt'
  },
  [TabDTO.Type.equipment]: {
    columns: equipmentColumnSettings,
    sorting: '-updatedAt'
  },
  [TabDTO.Type.container]: {
    columns: containerColumnSettings,
    sorting: '-updatedAt'
  },
  [TabDTO.Type.location]: {
    columns: locationColumnSettings,
    filter: [{ column: 'status', value: LocationViewDTO.StatusEnum.FULL.toString() }],
    sorting: '-updatedAt'
  },
  [TabDTO.Type.driver]: {
    columns: driverColumnSettings,
    sorting: '-updatedAt',
    filter: [
      { column: 'status', value: groupedDriverStatuses.Active.concat(groupedDriverStatuses.Inactive).map(String) }
    ]
  },
  [TabDTO.Type.customer]: {
    columns: customerColumnSettings,
    sorting: '-createdAt'
  },
  [TabDTO.Type.steamShipLine]: {
    columns: steamShipLineColumnSettings,
    sorting: '-updatedAt'
  },
  [TabDTO.Type.subClient]: {
    columns: subClientColumnSettings,
    sorting: '-updatedAt'
  },
  [TabDTO.Type.dispatchDeliveryOrder]: {
    columns: dispatchDeliveryOrderColumnSettings,
    sorting: '-number',
    filter: [
      {
        column: 'status',
        value: Object.keys(ddoDirectory.status).filter(
          status =>
            ![
              DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED.toString(),
              DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED.toString(),
              DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED.toString()
            ].includes(status)
        )
      },
      {
        column: 'archived',
        value: 'false'
      }
      // {
      //   column: 'deliveryStage.plannedAppointmentDateTimeRange.confirmed',
      //   value: 'true'
      // }
    ]
  },
  [TabDTO.Type.deliveryOrder]: {
    columns: {},
    sorting: ''
  },
  [TabDTO.Type.scheduler]: {
    sorting: SchedulerSortBy.date,
    filter: [
      {
        column: SchedulerFilter.grouping,
        value: OngoingActivityGroupDTO.GroupingEnum.STAGE.toString()
      },
      {
        column: SchedulerFilter.specificDate,
        value: dateService.createStringDate.startDay
      },
      {
        column: SchedulerFilter.activityGroupDate,
        value: { from: dateService.createStringDate.startDay, to: dateService.createStringDate.endDay }
      }
    ],
    columns: {}
  },
  [TabDTO.Type.locationGroup]: {
    sorting: '',
    filter: [],
    columns: {}
  }
}

const defaultTabData = {
  [TabDTO.Type.QMP]: defaultQMPTabData(),
  [TabDTO.Type.scheduler]: defaultSchedulerTabData(),
  [TabDTO.Type.locationGroup]: {}
}
