import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import theme from '../../../../../styles/theme'
import styled from '../../../../../styles/styledComponents'
import { Column, ColumnRow, Container, GridColumn } from '../../../GridSystem'
import { EditModeButton, WidgetActions } from '../../styles'
import { Select } from '../../../DataFields/Select'
import { AddressDTO, LocationViewDTO } from '../../../../../api/origin/business-logic'
import Textarea from '../../../Input'
import { Input, InputField } from '../../../DataFields/Input'
import { LocationSearchWithGoogle } from '../../../DataFields/SearchRequest/location/withGoogle'
import { updateAddressObject } from './functions'
import { isNewObject } from '../../../../../services/DTO'
import { GridSpinner } from '../../../Spinner/Spinner'
import { FieldTemplate } from '../../../DataFields/Templates'
import { locationLists } from '../../../../../services/select/locationLists'
import { requestLocationById, requestUpdateLocation } from '../../../../common/location/epics'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { showModal, TMsgType } from '../../../Modal/actions'
import { getStore } from '../../../../../store/configureStore'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { locationControllerApi } from '../../../../../api/location'
import {
  showErrorModalWindow,
  showModalWindow,
  showSuccessModalWindow
} from '../../../../../store/reducers/modalWindow/functions'
import { SelectLocation } from '../../../../../services/DTO/location/SelectLocation'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'
import { isLocationValid } from '../../../../../services/functions/test/isLocationValid'
import { saveWidgetLocation } from './saveWidgetLocation'
import { usePrimaryContact } from '../../../../../hooks/usePrimaryContact'
import { isContactValid } from '../../../../../services/functions/test/isContactValid'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'

type Props = {
  disabled?: boolean
  disableEditing?: boolean
  isModifiedMode: boolean
  location: LocationViewDTO
  changeLocationId: (locationId: string) => void
}

export const Popover = (props: Props) => {
  const { disabled, disableEditing, isModifiedMode, location, changeLocationId } = props
  const { primaryContact, modifyPrimaryContactField } = usePrimaryContact({
    parentEntity: location,
    entityType: EntityType.location
  })
  const isModifiedModeRef = React.useRef(isModifiedMode)
  const locationIdRef = React.useRef(oc(location).id())
  const { closePopover } = React.useContext(WidgetContext)
  const expandedItemContext = useExpandedItem()
  const { modifiedLists, modifyListItems, deleteModifiedListItems, initialLists } = expandedItemContext
  const [edit, setEdit] = React.useState(isModifiedMode)
  const [fetching, setFetching] = React.useState(location && !isFullObject(location))
  const initialLocation = oc(initialLists)[EntityType.location][oc(location).id()]()
  const isInvalid = isLocationGEOValid(location) === false
  const isPartial = oc(location).status() === LocationViewDTO.StatusEnum.PARTIAL
  const isLocationEditable = Boolean(!disabled && !disableEditing && edit)
  isModifiedModeRef.current = isModifiedMode
  locationIdRef.current = oc(location).id()

  React.useEffect(() => {
    if (location && !isFullObject(location)) {
      requestLocationById(location.id).finally(() => {
        setFetching(false)
      })
    }

    return () => {
      if (isModifiedModeRef.current) {
        if (locationIdRef.current) {
          deleteModifiedListItems({ [EntityType.location]: [locationIdRef.current] })
        }
      }
    }
  }, [])

  const address = oc(location).address(null)
  const locationObject = {
    id: oc(location).id(''),
    status: oc(location).status(LocationViewDTO.StatusEnum.FULL),
    name: oc(location).name(''),
    code: oc(location).code(''),
    longName: oc(location).longName(''),
    street: oc(address).street(''),
    type: oc(location).type(null),
    city: oc(address).city(''),
    stateId: oc(address).stateId(''),
    zip: oc(address).postalCode(''),
    notes: oc(location).notes(''),
    company: oc(location).careOf('')
  }

  const modifyLocation = (_location: LocationViewDTO) => {
    modifyListItems({ [EntityType.location]: [_location] })
  }

  const modifyLocationField = (fieldName: keyof LocationViewDTO) => (value: any) => {
    modifyListItems({ [EntityType.location]: [{ ...location, [fieldName]: value }] })
  }

  const updateAddressField = (fieldName: keyof AddressDTO) => (value: any) => {
    modifyLocationField('address')(updateAddressObject(address)(fieldName)(value))
  }

  const cancelModifies = () => {
    if (location && location.id) {
      deleteModifiedListItems({
        [EntityType.location]: [location.id],
        [EntityType.contact]: [oc(primaryContact).id()].filter(Boolean)
      })
    }

    setEdit(false)
  }

  const onCheckLocationClick = async () => {
    const addressString = generateAddressLabel(location.address)
    const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

    if (searchResults.length) {
      return showModalWindow({
        width: 445,
        title: 'Select location',
        content: (
          <SelectLocation
            selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
            locations={searchResults}
            onSelect={selectedPlaceId => {
              if (selectedPlaceId) {
                setFetching(true)

                locationControllerApi
                  .locationByPlaceId(selectedPlaceId)
                  .then(async requestedLocation => {
                    const { latitude, longitude } = requestedLocation
                    const requestedAddress = oc(requestedLocation).address()
                    const updatedLocation = { ...location, placeId: selectedPlaceId, latitude, longitude }

                    if (requestedAddress) {
                      if (requestedAddress.city) {
                        updatedLocation.address = {
                          ...updatedLocation.address,
                          street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                          city: requestedAddress.city || location.address.city,
                          postalCode: requestedAddress.postalCode || location.address.postalCode,
                          stateId:
                            requestedAddress.stateCode && requestedAddress.countryCode
                              ? getAddressState({
                                  codes: {
                                    stateCode: requestedAddress.stateCode,
                                    countryCode: requestedAddress.countryCode
                                  }
                                }).id
                              : location.address.stateId
                        }
                      }
                    }

                    const resultedLocation = await requestUpdateLocation(updatedLocation)
                      .then(() => {
                        showSuccessModalWindow({ title: 'Validation Success' })
                      })
                      .catch(() => null)

                    if (resultedLocation) {
                      deleteModifiedListItems({ [EntityType.location]: [resultedLocation.id] })
                    }

                    setFetching(false)
                  })
                  .catch(() => {
                    setFetching(false)
                    showErrorModalWindow({
                      title: 'Location search error'
                    })
                  })
              }
            }}
          />
        ),
        buttons: [
          {
            label: 'Cancel'
          },
          {
            label: 'Apply',
            disabled: true
          }
        ]
      })
    } else {
      setFetching(false)
      return showErrorModalWindow({
        content: 'Location with the entered address is not found in Google'
      })
    }
  }

  const onValidateLocationClick = async () => {
    if (!isInvalid) {
      return
    }

    const addressString = generateAddressLabel(location.address)
    const placeId = oc(location).placeId()
    const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

    if (searchResults.length) {
      // const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId
      const isPlaceIdCorrect = false // always show confirm popup

      if (!(isPlaceIdCorrect && oc(location).longitude() && oc(location).latitude())) {
        return showModalWindow({
          width: 445,
          title: 'Select location',
          content: (
            <SelectLocation
              selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
              locations={searchResults}
              onSelect={selectedPlaceId => {
                if (selectedPlaceId) {
                  setFetching(true)

                  locationControllerApi
                    .locationByPlaceId(selectedPlaceId)
                    .then(async requestedLocation => {
                      const { latitude, longitude } = requestedLocation
                      const requestedAddress = oc(requestedLocation).address()
                      const updatedLocation = { ...location, placeId: selectedPlaceId, latitude, longitude }

                      if (requestedAddress) {
                        if (requestedAddress.city) {
                          updatedLocation.address = {
                            ...updatedLocation.address,
                            street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                            city: requestedAddress.city || location.address.city,
                            postalCode: requestedAddress.postalCode || location.address.postalCode,
                            stateId:
                              requestedAddress.stateCode && requestedAddress.countryCode
                                ? getAddressState({
                                    codes: {
                                      stateCode: requestedAddress.stateCode,
                                      countryCode: requestedAddress.countryCode
                                    }
                                  }).id
                                : location.address.stateId
                          }
                        }
                      }

                      const resultedLocation = await requestUpdateLocation(updatedLocation)
                        .then(() => {
                          showSuccessModalWindow({ title: 'Validation Success' })
                        })
                        .catch(() => null)

                      if (resultedLocation) {
                        deleteModifiedListItems({ [EntityType.location]: [resultedLocation.id] })
                      }

                      setFetching(false)
                    })
                    .catch(() => {
                      setFetching(false)
                      showErrorModalWindow({
                        title: 'Location search error'
                      })
                    })
                }
              }}
            />
          ),
          buttons: [
            {
              label: 'Cancel'
            },
            {
              label: 'Apply',
              disabled: true
            }
          ]
        })
      }
    } else {
      setFetching(false)
      return showErrorModalWindow({
        content: 'Location with the entered address is not found in Google'
      })
    }
  }

  return (
    <>
      {fetching && <GridSpinner />}
      {(isInvalid || isPartial) && (
        <LocationWidgetLabelsBlock>
          {isInvalid && <PopoverInvalidLabel />}
          {isPartial && <PopoverPartialLabel />}
        </LocationWidgetLabelsBlock>
      )}
      <Container columns={32} flexClear={true} isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <LocationSearchWithGoogle
            disabled={disableEditing}
            focus={!oc(location).id()}
            title={'Find Location'}
            id={oc(location).id()}
            onChange={(updatedLocation: LocationViewDTO) => {
              const doUpdate = () => {
                changeLocationId(oc(updatedLocation).id())

                if (updatedLocation && isNewObject(updatedLocation)) {
                  modifyListItems({ [EntityType.location]: [updatedLocation] })
                  setEdit(true)
                }
              }

              if (oc(location).status() === LocationViewDTO.StatusEnum.PARTIAL) {
                getStore().dispatch(
                  showModal({
                    msgType: TMsgType.delete,
                    message: 'Are you sure you want to remove partial location?',
                    onConfirm: doUpdate,
                    onCancel: () => {}
                  })
                )
              } else {
                doUpdate()
              }
            }}
          />
        </ColumnRow>

        {isFullObject(location) && (
          <>
            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!isLocationEditable}
                  required={true}
                  title={'Name'}
                  value={locationObject.name}
                  onChange={modifyLocationField('name')}
                />
              </Column>
              <Column>
                <Input
                  disabled={!isLocationEditable}
                  required={true}
                  title={'Address'}
                  value={locationObject.street}
                  onChange={updateAddressField('street')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Column columns={4} margin={{ right: 10 }}>
                  <Select
                    disabled={!isLocationEditable}
                    required={true}
                    title={'Type'}
                    label={oc(locationLists.typeWithIcons.find(_ => _.value === locationObject.type)).htmlLabel(null)}
                    selectedValue={locationObject.type}
                    list={locationLists.typeWithIcons}
                    onSelect={modifyLocationField('type')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <Input
                    title={'Code'}
                    maxLength={6}
                    disabled={!isLocationEditable}
                    value={locationObject.code}
                    onChange={modifyLocationField('code')}
                  />
                </Column>
              </Column>
              <Column>
                <Column columns={5} margin={{ right: 10 }}>
                  <Input
                    disabled={!isLocationEditable}
                    required={true}
                    title={'City'}
                    value={locationObject.city}
                    onChange={updateAddressField('city')}
                  />
                </Column>
                <Column style={{ fontSize: 14 }} columns={4} isFixed={true} margin={{ right: 10 }}>
                  <FieldTemplate.StateSearch
                    disabled={!isLocationEditable}
                    required={true}
                    showCode={true}
                    title={'State'}
                    stateId={locationObject.stateId}
                    onChange={updateAddressField('stateId')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <InputField.Zip
                    disabled={!isLocationEditable}
                    required={true}
                    title={'ZIP'}
                    value={locationObject.zip}
                    onChange={updateAddressField('postalCode')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <Column rows={0.2} background={'#eff2f6'} margin={{ bottom: 15 }} />

            <GridColumn>
              <Column isGrid={true} margin={{ right: 16 }}>
                <ColumnRow margin={{ bottom: 20 }}>
                  <Input
                    disabled={!isLocationEditable}
                    title={'Company'}
                    value={locationObject.company}
                    onChange={modifyLocationField('careOf')}
                  />
                </ColumnRow>
                <Column>
                  <Textarea
                    disabled={!isLocationEditable}
                    title={'Note'}
                    isTextArea={true}
                    value={locationObject.notes}
                    onChange={modifyLocationField('notes')}
                  />
                </Column>
              </Column>

              <Column isGrid={true}>
                <ColumnRow margin={{ bottom: 20 }}>
                  <Column margin={{ right: 10 }}>
                    <Input
                      disabled={!isLocationEditable}
                      title={'First Name'}
                      value={oc(primaryContact).firstName()}
                      onChange={modifyPrimaryContactField('firstName')}
                    />
                  </Column>
                  <Column>
                    <Input
                      disabled={!isLocationEditable}
                      title={'Last Name'}
                      value={oc(primaryContact).lastName()}
                      onChange={modifyPrimaryContactField('lastName')}
                    />
                  </Column>
                </ColumnRow>

                <ColumnRow margin={{ bottom: 20 }}>
                  <InputField.Email
                    disabled={!isLocationEditable}
                    title={'E-mail'}
                    value={oc(primaryContact).email()}
                    onChange={modifyPrimaryContactField('email')}
                  />
                </ColumnRow>

                <ColumnRow>
                  <Column margin={{ right: 10 }}>
                    <InputField.PhoneNumber
                      disabled={!isLocationEditable}
                      title={'Phone'}
                      value={oc(primaryContact).phone()}
                      onChange={modifyPrimaryContactField('phone')}
                    />
                  </Column>
                  <Column>
                    <InputField.PhoneNumber
                      disabled={!isLocationEditable}
                      title={'Fax'}
                      value={oc(primaryContact).fax()}
                      onChange={modifyPrimaryContactField('fax')}
                    />
                  </Column>
                </ColumnRow>
              </Column>
            </GridColumn>
            {oc(location).terminalIntegration() && (
              <>
                {location.terminalIntegration.active ? (
                  <TerminalIntegrationContainer>
                    <i className={'mdi mdi-checkbox-marked-circle'} />
                    Integrated with&nbsp;
                    <a href={`https://${location.terminalIntegration.webSite}`} target="_blank">
                      {location.terminalIntegration.webSite}
                    </a>
                    {location.terminalIntegration.fault && (
                      <TerminalAlert>
                        <i className={'mdi mdi-alert-circle'} /> Terminal Alert
                      </TerminalAlert>
                    )}
                  </TerminalIntegrationContainer>
                ) : (
                  <TerminalIntegrationContainer>Terminal Intergation turned off</TerminalIntegrationContainer>
                )}
              </>
            )}
          </>
        )}
      </Container>
      <WidgetActions>
        {edit ? (
          <>
            <ActionButton onlyText={true} round={true} onClick={cancelModifies} children={'Cancel'} />
            <ActionButton
              filled={true}
              round={true}
              disabled={!(isLocationValid(location) && (primaryContact ? isContactValid(primaryContact) : true))}
              onClick={() => {
                saveWidgetLocation({
                  modifiedLocation: location,
                  modifyLocation: modifyLocation,
                  modifiedPrimaryContact: oc(modifiedLists)[EntityType.contact][oc(location).primaryContactId()](),
                  // @ts-ignore
                  initialLocation,
                  setFetching,
                  cancelModifies,
                  onLocationSaveComplete: (savedLocation: LocationViewDTO) => {
                    if (oc(savedLocation).id() && oc(savedLocation).id() !== oc(location).id()) {
                      changeLocationId(oc(savedLocation).id())
                    }

                    cancelModifies()
                  }
                })
              }}
              children={'Save Location'}
            />
          </>
        ) : (
          <>
            {location && (
              <>
                <ActionButton
                  round={true}
                  filled={true}
                  red={Boolean(isInvalid)}
                  onClick={isInvalid ? onValidateLocationClick : onCheckLocationClick}
                  children={'Validate'}
                />
                {!disabled && !disableEditing && (
                  <EditModeButton onClick={() => setEdit(true)} children={'Edit Location'} />
                )}
              </>
            )}
            <ActionButton filled={true} round={true} onClick={closePopover} children={'Ok'} />
          </>
        )}
      </WidgetActions>
    </>
  )
}

export const LocationWidgetLabelsBlock = styled.div`
  display: flex;
  position: absolute;
  left: 170px;
  top: 21px;

  > * {
    margin-right: 5px;
  }
`

export const LocationWidgetLabel = styled.div`
  background: ${theme.colors.basicBlueColor};
  color: white;
  font-weight: 500;
  border-radius: 3px;
  font-size: 10px;
  letter-spacing: 0.3px;
  padding: 2px 3px;
`

export const PopoverPartialLabel = styled(LocationWidgetLabel)`
  &:before {
    content: 'Partial';
  }
`

export const PopoverInvalidLabel = styled(PopoverPartialLabel)`
  background: ${theme.colors.defaultRed};

  &:before {
    content: 'Invalid';
  }
`

const TerminalIntegrationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  border-top: 1px solid rgba(214, 223, 232, 0.4);
  border-bottom: 1px solid rgba(214, 223, 232, 0.4);
  font-size: 14px;
  color: #445366;
  margin: 16px 0 0;

  i.mdi {
    font-size: 24px;
    margin-right: 7px;
  }

  .mdi-checkbox-marked-circle {
    color: #4555d0;
  }

  a {
    color: #4555d0;
    line-height: 56px;
    user-select: none;
    cursor: pointer;
  }
`

const TerminalAlert = styled.div`
  display: flex;
  align-items: center;
  color: #ff5e5e;
  margin-left: 20px;

  .mdi {
    color: inherit;
  }
`
