import { SearchRequest } from './searchRequest'
import * as React from 'react'
import { TSearchField } from '../../index'
import { getOwnDatabasePostalCodes } from '../functions'

export const LocationSearch = (props: TSearchField) => {
  return (
    <SearchRequest
      {...props}
      filtering={false}
      label={createLabel(props.value)}
      getList={getOwnDatabasePostalCodes}
      makeDropDownItem={response =>
        response.create
          ? {
              disabled: response.create,
              className: 'create',
              before: '',
              label: response.description,
              value: response
            }
          : { label: response.name, value: response }
      }
    />
  )
}

const createLabel = (location: any) => {
  if (location) {
    const { city, postalCode } = location
    return (city ? city + ',' : '') + ' ' + (postalCode || '')
  }

  return null
}
