import { ContainerTypeDTO } from '../../../api/origin/business-logic'
import { getContainerTypeMapping } from '../../../store'

export const checkWeatherContainerTypeIsReefer = (props: {
  containerTypeCode?: string
  containerTypeName?: string
  containerTypeId?: string
}): boolean => {
  const { containerTypeCode, containerTypeId, containerTypeName } = props

  const includesReefer = (name?: string): boolean => (name ? name.toLowerCase().includes('refriger') : false)
  const checkWeatherReefer = (containerType?: ContainerTypeDTO): boolean => {
    return includesReefer(containerType && containerType.name)
  }

  switch (true) {
    case Boolean(containerTypeName):
      return includesReefer(containerTypeName)
    case Boolean(containerTypeId):
      return checkWeatherReefer(getContainerTypeMapping[containerTypeId])
    case Boolean(containerTypeCode):
      return checkWeatherReefer(Object.values(getContainerTypeMapping()).find(_ => _.code === containerTypeCode))
    default:
      return false
  }
}
