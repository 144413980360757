import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { FieldContainer } from '../FieldContainer'
import { axiosCallAPIWithErrorMessage } from '../../../../api/api'
import { fileServiceAPI } from '../../../../services/filesService'
import { FileDTO } from '../../../../api/origin/document-service'
import Styled from './styles'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { useUploadFilesStore } from '../../../../hooks/useUploadFilesStore'
import { generateFileTemplate } from '../../../../services/filesService/generateFileTemplate'
import { IAttachment } from '../../../../services/filesService/interfaces'

type OwnProps = {
  uploadingFileId: string
  file: FileDTO | undefined
  onChange: (file: FileDTO | undefined) => void
  activeTabId?: string
  title?: string
  required?: boolean
  disabled?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const FileUploader = React.memo((props: Props) => {
  const { title, required, disabled, file, uploadingFileId } = props
  const { uploadingFiles, setUploadingFiles, clearUploadingFiles } = useUploadFilesStore()
  const activeTabId = React.useMemo(() => props.activeTabId || 'general', [props.activeTabId])
  const uploadingFile = oc(uploadingFiles)[activeTabId][uploadingFileId]() as IAttachment
  // const [errorMessage, setErrorMessage] = React.useState<string>(undefined)
  const onChangeRef = React.useRef(props.onChange)
  onChangeRef.current = props.onChange

  const onFileChange = React.useCallback((e: any) => {
    const files: File[] = oc(e).target.files()
    // setErrorMessage(undefined)

    if (files && files[0]) {
      const temporaryAttachment = generateFileTemplate(files[0], {
        uploadingProgress: 0
      }) as IAttachment

      onChangeRef.current(undefined)
      setUploadingFiles(activeTabId, uploadingFileId, temporaryAttachment)

      try {
        axiosCallAPIWithErrorMessage(fileServiceAPI.uploadFile, {
          file: files[0],
          progressCallback: (uploadingProgress: number) => {
            setUploadingFiles(activeTabId, uploadingFileId, { ...temporaryAttachment, uploadingProgress })
          }
        })
          .then(data => {
            onChangeRef.current(R.omit(data, ['id']))
            clearUploadingFiles(activeTabId, uploadingFileId)
          })
          .catch((error: any) => {
            clearUploadingFiles(activeTabId, uploadingFileId)

            // if (error && error.data) {
            //   setErrorMessage(convertErrorToMessage(error.data))
            // }
          })
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('FileUploader error', e)
        clearUploadingFiles(activeTabId, uploadingFileId)
      }
    }
  }, [])

  const RenderContent = React.useMemo(() => {
    switch (true) {
      case Boolean(uploadingFile):
        return (
          <Styled.Button style={{ cursor: 'default' }}>
            {makeUploadingLabel(uploadingFile.uploadingProgress)}
          </Styled.Button>
        )
      case Boolean(file):
        return (
          <>
            <a href={file.url} download={true} type={file.contentType} target={'_blank'}>
              <Styled.Button className={'mdi mdi-eye'}>View</Styled.Button>
            </a>
            <Styled.ButtonDelete
              className={'mdi mdi-close-circle'}
              onClick={() => {
                showModalWindow({
                  width: 320,
                  title: `Delete the file?`,
                  content: oc(file).name() ? (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <span>{file.name}</span>
                    </div>
                  ) : (
                    undefined
                  ),
                  buttons: [
                    {
                      label: 'No'
                    },
                    {
                      label: 'Yes',
                      onClick: () => onChangeRef.current(undefined)
                    }
                  ]
                })
              }}
            >
              Delete
            </Styled.ButtonDelete>
          </>
        )
      default:
        return (
          <label>
            <input type={'file'} onChange={onFileChange} hidden={true} />
            <Styled.Button className={'mdi mdi-cloud-upload'}>
              Upload file
              {/*{localFile === undefined ? 'Retry' : 'Upload file'}*/}
            </Styled.Button>
          </label>
        )
    }
  }, [uploadingFile, file, uploadingFileId, onFileChange])

  return (
    <FieldContainer title={title} required={required} disabled={disabled}>
      <Styled.Container>{RenderContent}</Styled.Container>
    </FieldContainer>
  )
})

const makeUploadingLabel = (progress: number, title?: string): string => {
  return `Uploading${title ? ' ' + title : ''}... ${typeof progress === 'number' ? '(' + progress + ')%' : ''}`
}
