import * as React from 'react'
import styled from 'styled-components'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
import { showModalWindow } from '../../../../../../store/reducers/modalWindow/functions'
import { isDDOStatusCancelled } from '../../../../../../services/functions/test/isDDOStatusCancelled'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { saveDispatchDeliveryOrder } from '../../../../../../services/saveEntity/saveDispatchDeliveryOrder'

export const UncancelDDO = () => {
  const { parentItem, isModified, setFetching } = useExpandedItem()
  const dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO = parentItem

  if (isModified || !isDDOStatusCancelled(dispatchDeliveryOrder.status)) {
    return null
  }

  let title = 'Title'
  let buttonLabel = 'Button'
  let status = DispatchDeliveryOrderViewDTO.StatusEnum.NEW

  if (dispatchDeliveryOrder.status === DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED) {
    title = 'Uncancel DDO?'
    buttonLabel = 'Uncancel DDO'
    status = DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH
  } else if (dispatchDeliveryOrder.status === DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED) {
    title = 'Unreject DDO?'
    buttonLabel = 'Unreject DDO'
    status = DispatchDeliveryOrderViewDTO.StatusEnum.NEW
  }

  return (
    <Container>
      <Button
        className={'mdi mdi-restore'}
        children={buttonLabel}
        onClick={() => {
          showModalWindow({
            title,
            buttons: [
              {
                label: 'No'
              },
              {
                label: 'Yes',
                onClick: () => {
                  setFetching(true)
                  saveDispatchDeliveryOrder({ dispatchDeliveryOrder: { ...dispatchDeliveryOrder, status } }).finally(
                    () => setFetching(false)
                  )
                }
              }
            ]
          })
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 0 15px;
  margin: 0 15px;
  user-select: none;
`

const Button = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  background-color: #4555d0;
  color: white;
  padding: 0 9px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }
`
