import * as React from 'react'
import { oc } from 'ts-optchain'
import { TGridColumn } from '../../../grid/views/interfaces'
import { TableCell } from '../../../grid/views/styles'
import { CustomerColumn } from '../../../grid/columnSettings/customerColumnSettings'
import { customerDirectory } from '../../../../../services/DTO/customer/directory'
import { useGridItemCustomer } from '../../../../../hooks/useGridItemCustomer'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'
import { SalesRepColumn } from './SalesRepColumn'
import { dateService } from '../../../../../services/timeService'

const Column = (props: TGridColumn) => {
  const { className, style, columnName } = props
  const { customer, businessPartner, primaryContact } = useGridItemCustomer()
  let text = columnName

  switch (columnName) {
    case CustomerColumn.DateCreated: {
      text = dateService.makeLabel(oc(customer).createdAt())
      break
    }
    case CustomerColumn.Name: {
      text = oc(businessPartner).legalName()
      break
    }
    case CustomerColumn.Address: {
      text = generateAddressLabel(oc(businessPartner).legalAddress())
      break
    }
    case CustomerColumn.SalesRep: {
      return (
        <TableCell className={className} style={style} data-copy-value-on-mousedown={true}>
          <SalesRepColumn salesRepUserId={oc(customer).salesRepUserId()} />
        </TableCell>
      )
    }
    case CustomerColumn.NetTermsUnit: {
      text = customer
        ? customer.netTermsUnit
          ? customerDirectory.netTermsUnit[customer.netTermsUnit]
          : undefined
        : undefined
      break
    }
    case CustomerColumn.NetTerms: {
      text = customer ? (customer.netTermsUnit ? customerDirectory.netTerms[customer.netTerms] : undefined) : undefined
      break
    }
    case CustomerColumn.PrimaryContact: {
      text = [oc(primaryContact).firstName(), oc(primaryContact).lastName()].filter(Boolean).join(' ')
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      {text}
    </TableCell>
  )
}

export default Column
