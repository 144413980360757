import * as React from 'react'
import { EditorState } from 'draft-js'
import { IAttachment } from '../services/filesService/interfaces'
import { IDraftMessage, IDraftMessages, IEditMessage } from '../store/reducers/communicationHub/interfaces'

export interface ICommunicationHubDraftMessageContext {
  // Draft Messages
  draftMessages: IDraftMessages
  setDraftMessageState(props: { channelId: string; draftMessage: IDraftMessage }): void
  setDraftMessageEditor(props: { channelId: string; editor: EditorState }): void
  setDraftMessageAttachment(props: { channelId: string; attachment: IAttachment }): void
  removeDraftMessageAttachmentId(props: { channelId: string; attachmentId: string }): void
  finishDraftMessageAttachmentUploading(props: {
    channelId: string
    temporaryAttachmentId: string
    attachment: IAttachment
  }): void
  // Edit Message
  editMessage?: IEditMessage
  setEditMessageState(props: { state: IEditMessage }): void
  setEditMessageEditor(props: { editor: EditorState }): void
  setEditMessageAttachment(props: { attachment: IAttachment }): void
  removeEditMessageAttachmentId(props: { attachmentId: string }): void
  finishEditMessageAttachmentUploading(props: { temporaryAttachmentId: string; attachment: IAttachment }): void
}

export const CommunicationHubDraftMessageContext = React.createContext<ICommunicationHubDraftMessageContext>({
  draftMessages: undefined,
  setDraftMessageState: undefined,
  setDraftMessageEditor: undefined,
  setDraftMessageAttachment: undefined,
  removeDraftMessageAttachmentId: undefined,
  finishDraftMessageAttachmentUploading: undefined,
  editMessage: undefined,
  setEditMessageState: undefined,
  setEditMessageEditor: undefined,
  setEditMessageAttachment: undefined,
  removeEditMessageAttachmentId: undefined,
  finishEditMessageAttachmentUploading: undefined
})
