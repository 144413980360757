import { oc } from 'ts-optchain'
import { DriverViewDTO } from '../../../api/api'
import {
  DriverActivenessStatus,
  ICalculatedDriverActivity,
  IDriverActivityDTO
} from '../../DTO/driverActivity/interfaces'
import { generateLastDriverActivityDate } from '../generate/generateLastDriverActivityDate'
import { testDriverStatus } from '../test/testDriverStatus'

export const getCalculatedDriverActivity = (props: {
  driver: DriverViewDTO | undefined
  driverActivity: IDriverActivityDTO | undefined
}): ICalculatedDriverActivity => {
  const { driver, driverActivity } = props
  const { isActive, isInactive } = testDriverStatus(oc(driver).status())

  if (
    !(
      isActive &&
      [DriverViewDTO.DriverTypeEnum.COMPANY, DriverViewDTO.DriverTypeEnum.OWNEROPERATOR].includes(driver.driverType)
    )
  ) {
    return {
      inactive: isInactive,
      showOffline: false,
      showOnline: false,
      showLastActiveDate: undefined,
      showGeneratedLastActiveDate: undefined
    }
  }

  const { status, lastActiveDate } = driverActivity || {}
  const showDriverActivityIndicator = isActive
  const showOffline = showDriverActivityIndicator && status === DriverActivenessStatus.NOT_ACTIVE
  const showOnline = showDriverActivityIndicator && status === DriverActivenessStatus.ACTIVE
  const showLastActiveDate = showOffline && lastActiveDate ? lastActiveDate : undefined
  const showGeneratedLastActiveDate = showOffline ? generateLastDriverActivityDate(lastActiveDate) : undefined

  return {
    inactive: isInactive,
    showOffline,
    showOnline,
    showLastActiveDate,
    showGeneratedLastActiveDate
  }
}
