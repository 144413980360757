import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'

const Button = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  color: ${theme.colors.defaultGray};
  font-size: 14px;

  &:hover {
    color: black;
  }
`

const Close = (props: { style?: object; onClick(): void }) => <Button {...props} className={'mdi mdi-close'} />

export default Close
