
// tslint:disable
/**
 * Intermodal Location Service API
 * The API provides searching places, geocoding and routing
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";

import { Configuration } from "./configuration";

const BASE_PATH = "https://intermodal-gateway.dev.port24.com/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = configuration.basePath, ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    stateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    stateName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street2?: string;
}

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phone2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contact
     */
    primary?: boolean;
}

/**
 * 
 * @export
 * @interface LocationDetailsDto
 */
export interface LocationDetailsDto {
    /**
     * 
     * @type {Address}
     * @memberof LocationDetailsDto
     */
    address?: Address;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof LocationDetailsDto
     */
    contacts?: Array<Contact>;
    /**
     * 
     * @type {number}
     * @memberof LocationDetailsDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDetailsDto
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    placeId?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    rating?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    webSite?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailsDto
     */
    workingHours?: string;
}

/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    placeId?: string;
}


/**
 * LocationControllerApi - fetch parameter creator
 * @export
 */
export const LocationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary locationByPlaceId
         * @param {string} placeId placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPlaceId(placeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'placeId' is not null or undefined
            if (placeId === null || placeId === undefined) {
                throw new RequiredError('placeId','Required parameter placeId was null or undefined when calling locationByPlaceId.');
            }
            const localVarPath = `/ls-location/{placeId}`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary locationByPostalCode
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPostalCode(postalCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'postalCode' is not null or undefined
            if (postalCode === null || postalCode === undefined) {
                throw new RequiredError('postalCode','Required parameter postalCode was null or undefined when calling locationByPostalCode.');
            }
            const localVarPath = `/ls-location/postalCode/{postalCode}`
                .replace(`{${"postalCode"}}`, encodeURIComponent(String(postalCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchLocation
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLocation(term: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling searchLocation.');
            }
            const localVarPath = `/ls-location/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchPostalCode
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPostalCode(term: string, options: any = {}): FetchArgs {
            // verify required parameter 'term' is not null or undefined
            if (term === null || term === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling searchPostalCode.');
            }
            const localVarPath = `/ls-location/searchPostalCode/{term}`
                .replace(`{${"term"}}`, encodeURIComponent(String(term)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationControllerApi - functional programming interface
 * @export
 */
export const LocationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary locationByPlaceId
         * @param {string} placeId placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPlaceId(placeId: string, options?: any): (basePath?: string) => Promise<LocationDetailsDto> {
            const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).locationByPlaceId(placeId, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary locationByPostalCode
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPostalCode(postalCode: string, options?: any): (basePath?: string) => Promise<LocationDetailsDto> {
            const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).locationByPostalCode(postalCode, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary searchLocation
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLocation(term: string, options?: any): (basePath?: string) => Promise<Array<LocationDto>> {
            const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).searchLocation(term, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary searchPostalCode
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPostalCode(term: string, options?: any): (basePath?: string) => Promise<Array<LocationDetailsDto>> {
            const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).searchPostalCode(term, options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LocationControllerApi - factory interface
 * @export
 */
export const LocationControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary locationByPlaceId
         * @param {string} placeId placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPlaceId(placeId: string, options?: any) {
            return LocationControllerApiFp(configuration).locationByPlaceId(placeId, options)(basePath);
        },
        /**
         * 
         * @summary locationByPostalCode
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationByPostalCode(postalCode: string, options?: any) {
            return LocationControllerApiFp(configuration).locationByPostalCode(postalCode, options)(basePath);
        },
        /**
         * 
         * @summary searchLocation
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLocation(term: string, options?: any) {
            return LocationControllerApiFp(configuration).searchLocation(term, options)(basePath);
        },
        /**
         * 
         * @summary searchPostalCode
         * @param {string} term term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPostalCode(term: string, options?: any) {
            return LocationControllerApiFp(configuration).searchPostalCode(term, options)(basePath);
        },
    };
};

/**
 * LocationControllerApi - object-oriented interface
 * @export
 * @class LocationControllerApi
 * @extends {BaseAPI}
 */
export class LocationControllerApi extends BaseAPI {
    /**
     * 
     * @summary locationByPlaceId
     * @param {string} placeId placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public locationByPlaceId(placeId: string, options?: any) {
        return LocationControllerApiFp(this.configuration).locationByPlaceId(placeId, options)(this.basePath);
    }

    /**
     * 
     * @summary locationByPostalCode
     * @param {string} postalCode postalCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public locationByPostalCode(postalCode: string, options?: any) {
        return LocationControllerApiFp(this.configuration).locationByPostalCode(postalCode, options)(this.basePath);
    }

    /**
     * 
     * @summary searchLocation
     * @param {string} term term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public searchLocation(term: string, options?: any) {
        return LocationControllerApiFp(this.configuration).searchLocation(term, options)(this.basePath);
    }

    /**
     * 
     * @summary searchPostalCode
     * @param {string} term term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public searchPostalCode(term: string, options?: any) {
        return LocationControllerApiFp(this.configuration).searchPostalCode(term, options)(this.basePath);
    }

}

/**
 * RootControllerApi - fetch parameter creator
 * @export
 */
export const RootControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getServerStartInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStartInfo(options: any = {}): FetchArgs {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers, configuration.getDefaultHeaders());

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootControllerApi - functional programming interface
 * @export
 */
export const RootControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getServerStartInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStartInfo(options?: any): (basePath?: string) => Promise<string> {
            const localVarFetchArgs = RootControllerApiFetchParamCreator(configuration).getServerStartInfo(options);
            return (basePath: string = configuration.basePath) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RootControllerApi - factory interface
 * @export
 */
export const RootControllerApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary getServerStartInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStartInfo(options?: any) {
            return RootControllerApiFp(configuration).getServerStartInfo(options)(basePath);
        },
    };
};

/**
 * RootControllerApi - object-oriented interface
 * @export
 * @class RootControllerApi
 * @extends {BaseAPI}
 */
export class RootControllerApi extends BaseAPI {
    /**
     * 
     * @summary getServerStartInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootControllerApi
     */
    public getServerStartInfo(options?: any) {
        return RootControllerApiFp(this.configuration).getServerStartInfo(options)(this.basePath);
    }

}


export type DateISOString = string; // 2018-04-15T08:36:01.711Z