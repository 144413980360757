import { FileDTO } from '../../api/origin/document-service'

export enum FileCategory {
  IMAGE = 'IMAGE',
  WORKORDER = 'WORK_ORDER',
  SCALETICKET = 'SCALE_TICKET',
  POD = 'POD',
  TIR = 'TIR',
  TROUBLETICKET = 'TROUBLE_TICKET',
  OTHER = 'OTHER'
}

interface ILocalAttachmentMeta {
  uploadingProgress?: number
  senderName?: string
  massageCreatedAt?: string
  messageMetadata?: any
}

export interface IAttachment extends FileDTO, ILocalAttachmentMeta {
  category?: FileCategory
}
