import {
  QMPTab,
  CustomerQuoteFieldType,
  NewRateDTO,
  SellSideQuoteRateGroupDTO,
  AnyRateDTO,
  RateType,
  ICustomerQuoterFilterState
} from '../../../interfaces'
import { createFilterRequest } from '../../../../../../services/uiSettingsService/filter'
import { CustomerQuoteDTO, SellSideQuoteRateDTO } from '../../../../../../api/origin/qmp-service'
import { TNewCustomerQuotes } from '../../../../../../services/DTO/customerQuote/interfaces'
import { createId } from '../../../../../../services/utils'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { showModal, TMsgType } from '../../../../../UI/Modal/actions'
import { AlertButtonColor } from '../../../../../UI/Modal'
import { getStore } from '../../../../../../store/configureStore'
import { quoteDirectory } from '../../../../../../services/DTO/sellSideQuote/directory'
import { calcCustomerQuoteRatesAmount } from '../../functions'
import { defaultFiltersOnCustomerQuoteCreation } from '../../../../../../services/DTO/qmp/defaultTabData'

export const customerQuoteSelectedRatesFieldName = {
  ssq: 'selectedSSQGroupsOfRates',
  bsq: 'selectedBSQGroupsOfRates'
}

export const createCustomerQuoteFilters = (appliedFilters: ICustomerQuoterFilterState): string => {
  const template = (column: string, value: any) => (value ? { column, value: value.toString() } : null)

  const normalizeFilterValues = Object.keys(appliedFilters).reduce((acc, filterField) => {
    switch (filterField) {
      case CustomerQuoteFieldType.status:
      case CustomerQuoteFieldType.deliveryOrderType:
      case CustomerQuoteFieldType.containerTypeId:
      case CustomerQuoteFieldType.loadType:
      case CustomerQuoteFieldType.number:
      case CustomerQuoteFieldType.pickupLocationId:
      case CustomerQuoteFieldType.returnLocationId:
      case CustomerQuoteFieldType.customerId: {
        const val: any = appliedFilters[filterField]
        return val
          ? [...acc, template(filterField, Array.isArray(val) ? val.map(item => item.value || item).join(',') : val)]
          : acc
      }
      case CustomerQuoteFieldType.deliveryLocation: {
        const location = appliedFilters[filterField]
        return location ? [...acc, template('deliveryPostalCode', location.postalCode)] : acc
      }
      default:
        return acc
    }
  }, [])

  return createFilterRequest(normalizeFilterValues.filter(Boolean))
}

// export const validateFiltersForCreatingCQ = (filterState: FiltersState[QMPTab.CUSTOMERQUOTE]): boolean => {
//   return Boolean(
//     Array.isArray(filterState.customerId) &&
//       filterState.customerId.length === 1 &&
//       filterState.pickupLocationId &&
//       filterState.deliveryLocation &&
//       filterState.deliveryLocation.postalCode &&
//       filterState.loadType
//   )
// }

export const highlightRequiredFieldsToCreateCustomerQuote = (
  filterState: ICustomerQuoterFilterState
): {
  onMouseOver: () => void
  onMouseOut: () => void
} => {
  const defaultFilters = defaultFiltersOnCustomerQuoteCreation()

  const deliveryOrderType = oc(filterState).deliveryOrderType([]).length
    ? filterState.deliveryOrderType
    : defaultFilters.deliveryOrderType

  const isImport = deliveryOrderType.includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.IMPORT)
  const isExport = deliveryOrderType.includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.EXPORT)
  const isRepo = deliveryOrderType.includes(CustomerQuoteDTO.DeliveryOrderTypeEnum.REPOSITION)

  const fields: { querySelector: string; isCorrect: boolean }[] = [
    {
      querySelector: '#customerId .select-container.selected',
      isCorrect: Boolean(oc(filterState).customerId([]).length === 1)
    },
    {
      querySelector: '#pickupLocationId .input-container',
      isCorrect: isRepo ? Boolean(filterState.pickupLocationId) : true
    },
    {
      querySelector: '#deliveryLocation .input-container',
      isCorrect: isRepo || Boolean(filterState.deliveryLocation && filterState.deliveryLocation.postalCode)
    },
    {
      querySelector: '#returnLocation .input-container',
      isCorrect: isRepo ? Boolean(filterState.returnLocationId) : true
    },
    { querySelector: '#loadType .select-container', isCorrect: isRepo || Boolean(filterState.loadType) }
  ]
  if (fields.every(({ isCorrect }) => isCorrect)) {
    return {
      onMouseOver: undefined,
      onMouseOut: undefined
    }
  }

  const selector = fields
    .filter(({ isCorrect }) => !isCorrect)
    .map(({ querySelector }) => querySelector)
    .join(',')

  return {
    onMouseOver: () => {
      document.querySelectorAll(selector).forEach(_ => _.classList.add('highlighted'))
    },
    onMouseOut: () => {
      document.querySelectorAll(selector).forEach(_ => _.classList.remove('highlighted'))
    }
  }
}

export const matchCustomerQuotesToSelectedRates = (newCustomerQuotes: TNewCustomerQuotes): CustomerQuoteDTO[] => {
  const correctedNewCustomerQuotes = {
    ...newCustomerQuotes,
    customerId: Array.isArray(newCustomerQuotes.customerId)
      ? newCustomerQuotes.customerId[0].value
      : newCustomerQuotes.customerId
  }
  const multiSelectFields = ['containerTypeId', 'deliveryOrderType', 'loadType']
  const selectedGroupsOfRates: SellSideQuoteRateGroupDTO[] = R.clone(
    correctedNewCustomerQuotes.selectedSSQGroupsOfRates
  )

  return multiSelectFields
    .reduce(
      (acc: CustomerQuoteDTO[], filterName: string) => {
        const filterFieldValue = correctedNewCustomerQuotes[filterName]

        if (!filterFieldValue) {
          return acc
        }

        const array: CustomerQuoteDTO[] = []

        filterFieldValue.forEach((filterValue: string) => {
          array.push(
            ...acc.map(customerQuote => {
              const id = createId()
              return {
                ...customerQuote,
                id,
                externalId: id,
                [filterName]: filterValue,
                selectedSSQGroupsOfRates: undefined,
                selectedBSQGroupsOfRates: undefined
              }
            })
          )
        })
        return array
      },
      [correctedNewCustomerQuotes as any]
    )
    .map(customerQuote => ({
      ...customerQuote,
      rateIds: selectedGroupsOfRates.map(({ rates }) => {
        const matchedRate: SellSideQuoteRateDTO = rates.find(
          rate =>
            (!rate.deliveryOrderType || rate.deliveryOrderType === customerQuote.deliveryOrderType) &&
            (!rate.containerTypeId || rate.containerTypeId === customerQuote.containerTypeId) &&
            (!rate.loadType || rate.loadType === customerQuote.loadType)
        )
        return oc(matchedRate).id()
      })
    }))
}

export const isDatesOfSelectedRatesValid = (customerQuote: TNewCustomerQuotes): boolean => {
  let valid = true
  const conflictedRateTypes: string[] = []
  const customerQuoteStartDayTime = Date.parse(customerQuote.effectiveDate)

  customerQuote.selectedSSQGroupsOfRates.forEach((rate: NewRateDTO) => {
    if (customerQuoteStartDayTime < Date.parse(rate.effectiveDate)) {
      conflictedRateTypes.push(quoteDirectory.typeOfRate[rate.type])
      valid = false
    }
  })

  if (!valid) {
    getStore().dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.blue,
            label: 'Ok',
            action: () => {}
          }
        },
        message: `The effective date of Rate (${conflictedRateTypes.join(', ')}) must match the Customer Quote`
      })
    )
    return false
  }
  return true
}

export const calculateCustomerQuoteProps = (
  customerQuote: TNewCustomerQuotes,
  rateType?: RateType,
  selectedRates?: AnyRateDTO[]
): TNewCustomerQuotes => {
  let customerQuoteType = customerQuote.type
  let selectedSSQGroupsOfRates = []
  let selectedBSQGroupsOfRates = []

  switch (rateType) {
    case RateType.ssq: {
      selectedSSQGroupsOfRates = selectedRates
      selectedBSQGroupsOfRates = customerQuote.selectedBSQGroupsOfRates
      break
    }
    case RateType.bsq: {
      selectedSSQGroupsOfRates = customerQuote.selectedSSQGroupsOfRates
      selectedBSQGroupsOfRates = selectedRates
      break
    }
    default: {
      selectedSSQGroupsOfRates = customerQuote.selectedSSQGroupsOfRates
      selectedBSQGroupsOfRates = customerQuote.selectedBSQGroupsOfRates
      break
    }
  }

  if (rateType === RateType.ssq || rateType === undefined) {
    // set customerQuote.surcharges and customerQuote.type
    customerQuoteType = undefined
    const hasRateType = {
      base: false,
      fuel: false,
      allIn: false
    }

    selectedSSQGroupsOfRates.forEach(r => {
      if (r.type === SellSideQuoteRateDTO.TypeEnum.BASE) {
        hasRateType.base = true
        return
      } else if (r.type === SellSideQuoteRateDTO.TypeEnum.ALLIN) {
        hasRateType.allIn = true
        return
      } else if (r.type === SellSideQuoteRateDTO.TypeEnum.FUEL) {
        hasRateType.fuel = true
        return
      }
    })

    if (hasRateType.allIn) {
      customerQuoteType = CustomerQuoteDTO.TypeEnum.ALLIN
    } else if (hasRateType.base && hasRateType.fuel) {
      customerQuoteType = CustomerQuoteDTO.TypeEnum.BASEANDFUEL
    }
  }

  return {
    ...customerQuote,
    selectedSSQGroupsOfRates: selectedSSQGroupsOfRates as any,
    selectedBSQGroupsOfRates: selectedBSQGroupsOfRates as any,
    type: customerQuoteType,
    amount:
      rateType === RateType.bsq ? customerQuote.amount : calcCustomerQuoteRatesAmount(selectedSSQGroupsOfRates, 0),
    bsqAmount: calcCustomerQuoteRatesAmount(selectedBSQGroupsOfRates, 0)
  }
}
