import * as React from 'react'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
import { useOutsideClick } from '../../../../../../hooks/useOutsideClick'
import { WindowPopover } from '../../../../../UI/windowPopover'
import { Label } from './Label'
import { DropDown } from './Dropdown'
import styled from 'styled-components'

type Props = {
  disabled?: boolean
  placeholder: string
  selectedValues: DispatchDeliveryOrderViewDTO.StatusEnum[]
  onStatusChange: (values: DispatchDeliveryOrderViewDTO.StatusEnum[]) => void
  archivedState: boolean
  toggleArchived: (state: boolean) => void
}

export const DDOGridHeaderStatus = (props: Props) => {
  const { placeholder, selectedValues, onStatusChange, archivedState, toggleArchived, disabled } = props
  const { wrapperRef, statePopover, togglePopover } = useOutsideClick({
    capture: false
  })

  return (
    <StyledSelect className={'column-value'} ref={wrapperRef} onClick={togglePopover}>
      <Label
        disabled={disabled}
        placeholder={placeholder}
        selectedValues={selectedValues || []}
        clearStatuses={() => onStatusChange(undefined)}
        archivedState={archivedState}
        toggleArchived={toggleArchived}
      />
      {!disabled && statePopover && (
        <WindowPopover bindToRef={wrapperRef} isExternal={true}>
          <DropDown
            selectedValues={selectedValues || []}
            onApplyClick={(statuses: DispatchDeliveryOrderViewDTO.StatusEnum[]) => onStatusChange(statuses)}
          />
        </WindowPopover>
      )}
    </StyledSelect>
  )
}

const StyledSelect = styled.div`
  height: 100%;
`
