import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import styled from 'styled-components'
import { activityIcons } from '../../../../../../../../../../constants/activityIcons'
import { ActivityColumn, activityStageColor } from '../../../styles'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import { ToggleButton } from '../../../../../../../../../UI/Toggle/ToggleButton'
import { TransportationActivityViewDTO } from '../../../../../../../../../../api/api'
// tslint:disable:max-line-length
import { getContainerYardLocations } from '../../../../../../../../../../services/functions/get/getContainerYardLocations'
import { ActivityGroupAction } from '../../../../../../../../../../services/DTO/activity/controller'
import { activityDirectory } from '../../../../../../../../../../services/DTO/activity/directory'
import { isNewItem } from '../../../../../../../../../../services/utils'
import { getDispatch, getNewStoreState } from '../../../../../../../../../../store'
import { communicationHubActions } from '../../../../../../../../../../store/reducers/communicationHub'
import { requestMoveDateByActivityId } from '../../../../../../../../../CommunicationHub/epics/routeBuilder'
import { useExpandedItem } from '../../../../../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../../../../../store/reducers/lists/interfaces'

type Props = {}

export const IconsActivityColumn = (props: Props) => {
  const {
    anotherDOOActivityLink,
    linkedGotoActivity,
    transportationRow,
    isDropBobtail,
    updateActivityRow,
    disabled,
    isUnsuccessful
  } = useTransportationActivityRow()
  const { setFetching, modifiedLists } = useExpandedItem()
  const isModifiedActivityGroup = Boolean(
    oc(modifiedLists)[EntityType.activity][oc(transportationRow).activityGroup.gotoActivity.id()]() ||
      oc(modifiedLists)[EntityType.activity][oc(transportationRow).activityGroup.gotoActivity.id()]()
  )
  const vendorId = transportationRow.activityGroup.gotoActivity.vendorId
  const isAvailableRouteBuilderLink =
    !isNewItem(transportationRow.activityGroup.gotoActivity) && vendorId && !isModifiedActivityGroup

  const onRouteBuilderLinkClick = !isAvailableRouteBuilderLink
    ? undefined
    : async () => {
        setFetching(true)

        try {
          const {
            lists: { driver }
          } = getNewStoreState()
          const dispatch = getDispatch()
          let date = await requestMoveDateByActivityId(transportationRow.activityGroup.gotoActivity.id)
          date = date ? date + 'T08:00:00.000' : date

          if (date) {
            dispatch(
              communicationHubActions.setActiveRouteBuilderVendor({
                vendorId,
                authUserId: oc(driver[vendorId]).authUserId(),
                higlightActivityId: transportationRow.activityGroup.gotoActivity.id,
                forceDate: date
              })
            )
          }
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.log(e)
        }

        setFetching(false)
      }

  const RenderBusinessActivityIcon = () => {
    if (isDropBobtail && !disabled) {
      if (!anotherDOOActivityLink) {
        return (
          <ActivityColumn style={{ alignItems: 'center', justifyContent: 'center' }}>
            {!(isUnsuccessful && transportationRow.activityGroup.gotoActivity.referenceId) && (
              <ToggleButton
                active={
                  transportationRow.activityGroup.businessActivity.type ===
                  TransportationActivityViewDTO.TypeEnum.OFFDUTY
                }
                backgroundColor={activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY]}
                onClick={() => {
                  const updatedRow = R.clone(transportationRow)
                  const { gotoActivity, businessActivity } = updatedRow.activityGroup
                  const type =
                    businessActivity.type === TransportationActivityViewDTO.TypeEnum.EMPTY
                      ? TransportationActivityViewDTO.TypeEnum.OFFDUTY
                      : TransportationActivityViewDTO.TypeEnum.EMPTY

                  gotoActivity.comboType = type
                  businessActivity.type = type
                  businessActivity.comboType = type

                  if (type === TransportationActivityViewDTO.TypeEnum.OFFDUTY) {
                    if (!gotoActivity.destinationId) {
                      const containerYardLocations = getContainerYardLocations({ name: '635' })

                      if (containerYardLocations.length) {
                        gotoActivity.destinationId = containerYardLocations[0].id
                        businessActivity.destinationId = containerYardLocations[0].id
                      }
                    }
                  } else if (type === TransportationActivityViewDTO.TypeEnum.EMPTY) {
                    // gotoActivity.destinationId = undefined
                    // businessActivity.destinationId = undefined
                  }

                  updateActivityRow(updatedRow, ActivityGroupAction.update)
                }}
              />
            )}
          </ActivityColumn>
        )
      }
    }

    return (
      <Icon
        onClick={onRouteBuilderLinkClick}
        children={
          transportationRow.activityGroup.businessActivity &&
          transportationRow.activityGroup.businessActivity.comboType &&
          activityIcons[transportationRow.activityGroup.businessActivity.comboType]
            ? activityIcons[transportationRow.activityGroup.businessActivity.comboType].icon()
            : null
        }
      />
    )
  }

  return (
    <Container className={cn(transportationRow.stage, { reference: isAvailableRouteBuilderLink })}>
      <Icon className={'mdi mdi-map-marker'} onClick={onRouteBuilderLinkClick} />
      <RenderBusinessActivityIcon />
    </Container>
  )
}

const Icon = styled(ActivityColumn)`
  position: relative;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    display: block;
    border-left: 2px dashed var(--activity-stage-color);
    left: calc(50% - 1px);
    opacity: 0.4;
  }

  &:first-child:after {
    top: calc(50% + 10px);
    bottom: 0;
  }
  &:last-child:after {
    top: 0;
    bottom: calc(50% + 10px);
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40px;
  flex-shrink: 0;
  --activity-stage-color: #c9cddd;

  > div {
    padding: 0 !important;
  }

  svg {
    width: 20px;
    fill: var(--activity-stage-color);
  }

  .mdi:before {
    font-size: 24px !important;
    width: 100%;
    display: inline-flex !important;
    justify-content: center;
    color: var(--activity-stage-color);
  }

  &.reference {
    &.${TransportationActivityViewDTO.StageEnum.PICKUP} {
      --activity-stage-color: ${activityStageColor.PICKUP};
    }
    &.${TransportationActivityViewDTO.StageEnum.DELIVERY} {
      --activity-stage-color: ${activityStageColor.DELIVERY};
    }
    &.${TransportationActivityViewDTO.StageEnum.RETURN} {
      --activity-stage-color: ${activityStageColor.RETURN};
    }

    ${Icon} {
      cursor: pointer;
    }

    &:hover {
      ${Icon} {
        &:after {
          opacity: 1;
        }
      }

      &.${TransportationActivityViewDTO.StageEnum.PICKUP} {
        --activity-stage-color: ${activityDirectory.stageStrongColor[TransportationActivityViewDTO.StageEnum.PICKUP]};
      }
      &.${TransportationActivityViewDTO.StageEnum.DELIVERY} {
        --activity-stage-color: ${activityDirectory.stageStrongColor[TransportationActivityViewDTO.StageEnum.DELIVERY]};
      }
      &.${TransportationActivityViewDTO.StageEnum.RETURN} {
        --activity-stage-color: ${activityDirectory.stageStrongColor[TransportationActivityViewDTO.StageEnum.RETURN]};
      }
    }
  }
`
