import * as React from 'react'
import { StyledRowActionsColumn, StyledRowActions } from '../../../../Grid/styles'
import { IconMoveRowProps } from '../../../../../../../UI/icons/QMP'
import { Portal } from 'react-portal'
import { RateType } from '../../../../../interfaces'

type Props = {
  rateId?: string
  rateNumber?: number
  rateType: RateType
  onDuplicate: () => void
  onCopyToAnotherRateList: () => void
  expire: () => void
}

export const RowActions = ({ rateId, rateType, rateNumber, onCopyToAnotherRateList, onDuplicate, expire }: Props) => {
  const timer = React.useRef(null)
  const ref = React.useRef(null)
  const [state, setState] = React.useState(undefined)

  const name = {
    ssq: 'BSQ',
    bsq: 'SSQ'
  }

  const clearTimer = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
  }, [])

  const toggle = React.useCallback(
    (e: any) => {
      clearTimer()
      setState(state ? undefined : { x: e.clientX, y: e.clientY })
    },
    [clearTimer, setState, state]
  )

  const { onMouseLeave, onMouseEnter } = React.useMemo(
    () =>
      state
        ? {
            onMouseLeave() {
              clearTimer()
              timer.current = setTimeout(() => (ref && ref.current ? setState(undefined) : undefined), 500)
            },
            onMouseEnter() {
              clearTimer()
            }
          }
        : {},
    [setState, clearTimer, state]
  )

  const ocClick = React.useCallback(
    (action: () => void) => () => {
      action()
      setState(undefined)
    },
    [setState]
  )

  const generateDataId = React.useCallback(
    (title: string): string => [rateId, rateType + 'RateColumn', title, rateNumber].filter(Boolean).join('-'),
    [rateType, rateId, rateNumber]
  )

  return (
    <>
      <StyledRowActionsColumn
        ref={ref}
        className={state ? 'active' : ''}
        onClick={toggle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span data-id={generateDataId('actions')} className={'mdi mdi-dots-horizontal'} />
      </StyledRowActionsColumn>
      {state && (
        <Portal>
          <StyledRowActions
            style={{
              zIndex: 999,
              position: 'fixed',
              transform: 'translateX(-100%)',
              top: state.y,
              left: state.x
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {Boolean(onCopyToAnotherRateList) && (
              <div data-id={generateDataId('actionCopy')} onClick={ocClick(onCopyToAnotherRateList)}>
                <IconMoveRowProps />
                Copy to {name[rateType]}
              </div>
            )}
            {Boolean(onDuplicate) && (
              <div
                data-id={generateDataId('actionDuplicate')}
                className={'mdi mdi-content-copy'}
                onClick={ocClick(onDuplicate)}
              >
                Duplicate
              </div>
            )}
            {Boolean(expire) && (
              <div data-id={generateDataId('actionExpire')} className={'mdi mdi-timer-off'} onClick={ocClick(expire)}>
                Expire
              </div>
            )}
          </StyledRowActions>
        </Portal>
      )}
    </>
  )
}
