import * as React from 'react'
import { Container } from '../../../UI/GridSystem'
import { ColumnTitles, AddRow } from '../../contact/views/styledComponents'
import { SubClientRow } from './SubClientRow'
import { useSubClientsTab } from '../../../../hooks/useSubClientsTab'

type Props = {
  subClientIds: string[]
}

const extraContainerStyles = { overflow: 'auto scroll' }

export const SubClientsPreviewTab = React.memo((props: Props) => {
  const { subClientIds } = props
  const { createSubClient } = useSubClientsTab()

  return (
    <Container
      style={extraContainerStyles}
      isGrid={true}
      background={'#f9f9f9'}
      rows={70}
      padding={{ top: 20, left: 20, right: 20 }}
    >
      {Boolean(subClientIds && subClientIds.length) && (
        <>
          <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 10 }}>
            <Container className={'required'} columns={8} margin={{ left: 20 }}>
              Name
            </Container>
            <Container className={'required'} columns={22}>
              Address
            </Container>
            <Container columns={9}>First Name</Container>
            <Container columns={9}>Last Name</Container>
            <Container columns={9}>Phone</Container>
            <Container columns={9}>Email</Container>
          </ColumnTitles>

          {subClientIds.map(id => (
            <SubClientRow key={id} id={id} />
          ))}
        </>
      )}

      <AddRow rows={9} alignCenter={true} justifyCenter={true} margin={{ bottom: 20 }} onClick={createSubClient}>
        Add Contact
      </AddRow>
    </Container>
  )
})
