import * as React from 'react'
import styled from 'styled-components'
import * as R from 'remeda'
import { DriverViewDTO } from '../../../../api/api'
import { NavigationChannelsSection } from './NavigationChannelsSection'
import { NavigationChannelsSectionBreakLine } from './Navigation'
import { NavigationChannelsSectionSearch } from './NavigationChannelsSectionSearch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { ChannelType } from '../../../../store/reducers/communicationHub/interfaces'
import { selectDrivers } from '../../../../store/select/driverSelect'
import { selectRouteBuilderSchedulerTabData } from '../../../../store/select/routeBuilderSelect'
import { filterDrivers } from '../../../../services/functions/filter/filterDrivers'
import { testDriverStatus } from '../../../../services/functions/test/testDriverStatus'

enum Filter {
  showAllDrivers = 'Show All Drivers',
  onlyOnDuty = 'Only On Duty'
}

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NavigationRouteBuilder = React.memo((props: Props) => {
  const driverMapping = useAppSelector(selectDrivers)
  const schedulerTabData = useAppSelector(selectRouteBuilderSchedulerTabData)
  const [search, setSearch] = React.useState('')
  const [filter, setFilter] = React.useState<Filter>(Filter.showAllDrivers)
  let noDeliveryStageAssignedDriverIds: string[] = undefined
  let deliveryStageAssignedDriverIds: string[] = undefined
  const sortedDriverIds: string[] = R.uniq(
    filterDrivers({ trim: search, drivers: Object.values(driverMapping), isCandidates: false }).reduce(
      (acc, driver) => {
        if (
          [DriverViewDTO.DriverTypeEnum.COMPANY, DriverViewDTO.DriverTypeEnum.OWNEROPERATOR].includes(
            driver.driverType
          ) &&
          (testDriverStatus(driver.status).isActive ||
            (filter === Filter.showAllDrivers && driver.status === DriverViewDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS))
        ) {
          acc.push(driver.id)
        }

        return acc
      },
      []
    )
  ).sort((driverIdA, driverIdB) => {
    const nameA: string = driverMapping[driverIdA].name || ''
    const nameB: string = driverMapping[driverIdB].name || ''

    return nameA.localeCompare(nameB)
  })

  if (schedulerTabData) {
    deliveryStageAssignedDriverIds = []
    noDeliveryStageAssignedDriverIds = []

    sortedDriverIds.forEach(driverId => {
      if (schedulerTabData.activeDeliveryStageVendorIds.includes(driverId)) {
        deliveryStageAssignedDriverIds.push(driverId)
      } else {
        noDeliveryStageAssignedDriverIds.push(driverId)
      }
    })
  }

  return (
    <>
      <NavigationChannelsSectionSearch
        title={'Search a driver'}
        search={search}
        setSearch={setSearch}
        innerSearchPlaceholder={'Search a driver'}
        filter={filter}
        setFilter={setFilter}
        filterItems={[Filter.showAllDrivers, Filter.onlyOnDuty]}
        isAllAreaSearch={true}
      />
      <NavigationChannelsSectionBreakLine />
      {search && sortedDriverIds.length === 0 ? (
        <NoSearchResults />
      ) : schedulerTabData ? (
        <>
          <NavigationChannelsSection
            title={'No Delivery Stage Assigned'}
            channelType={ChannelType.driver}
            channelIds={noDeliveryStageAssignedDriverIds}
            collapsible={true}
            maxHeight={'calc(50% - 80px)'}
          />
          <NavigationChannelsSectionBreakLine />
          <NavigationChannelsSection
            title={'Delivery Stage Assigned'}
            channelType={ChannelType.driver}
            channelIds={deliveryStageAssignedDriverIds}
            maxHeight={'calc(50% - 80px)'}
          />
        </>
      ) : (
        <NavigationChannelsSection
          title={'Select a driver'}
          channelType={ChannelType.driver}
          channelIds={sortedDriverIds}
        />
      )}
    </>
  )
})

const NoSearchResults = styled.div`
  text-align: center;
  font-size: 16px;
  color: #ffffff50;
  padding-top: 30px;

  &:after {
    content: 'No such drivers';
  }
`
