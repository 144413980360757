import * as React from 'react'
import styled from 'styled-components'
import { Container } from '../../../../../UI/GridSystem'
import { Delete } from '../../../../../UI/styledComponents'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { PowerUnitViewDTO, RepairDTO } from '../../../../../../api/origin/business-logic'
import { DateType } from '../../../../../UI/DatePicker/Interfaces'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { Select } from '../../../../../UI/DataFields/Select'
import { Row } from '../../../../contact/views/styledComponents'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { powerUnitLists } from '../../../../../../services/select/powerUnitLists'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'

const isValidDescription = createValidator(2, 512)

type Props = {
  repair: RepairDTO
  changeRepair: (fieldName: keyof RepairDTO) => (value: string | number | DateType | { id: string }) => void
  deleteRepair: (id: string) => void
}

export const RepairRow = (props: Props) => {
  const { repair, changeRepair, deleteRepair } = props

  return (
    <StyledRow rows={9} margin={{ bottom: 10 }}>
      <Container columns={8} margin={{ left: 20 }}>
        <DateTimePicker required={true} date={repair.startDate} onChange={changeRepair('startDate')} />
      </Container>
      <Container columns={8}>
        <DateTimePicker required={true} date={repair.finishDate} onChange={changeRepair('finishDate')} />
      </Container>
      <Container columns={6}>
        <Select
          required={true}
          list={powerUnitLists.repairType}
          selectedValue={repair.repairType}
          onSelect={value => changeRepair('repairType')(value as any)}
        />
      </Container>
      <Container columns={6}>
        <Input
          required={true}
          value={repair.description}
          valid={isValidDescription(repair.description)}
          maxLength={512}
          onChange={changeRepair('description')}
        />
      </Container>
      <Container columns={6}>
        <Input value={repair.shopName} maxLength={12} onChange={changeRepair('shopName')} />
      </Container>
      <Container columns={5}>
        <InputField.Money value={repair.costEstimate} onChange={changeRepair('costEstimate')} />
      </Container>
      <Container columns={5}>
        <InputField.Money value={repair.billActual} onChange={changeRepair('billActual')} />
      </Container>
      <Container columns={5}>
        <InputField.Integer value={repair.powerUnitMileage} onChange={changeRepair('powerUnitMileage')} />
      </Container>
      <Container columns={6}>
        <ViewingObjectFileUploader
          uploadingFileId={repair.id + ' repair.receiptDocumentFile'}
          file={repair.receiptDocumentFile}
          onFileUploaded={(receiptDocumentFile, modifiedObject) => ({
            ...modifiedObject,
            repairs: (modifiedObject as PowerUnitViewDTO).repairs.map(item =>
              item.id === repair.id ? { ...item, receiptDocumentFile } : item
            )
          })}
          onClearFile={() => changeRepair('receiptDocumentFile')(undefined)}
        />
      </Container>
      <Delete className="mdi mdi-delete" columns={2} isFixed={true} onClick={() => deleteRepair(repair.id)} />
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  > div > div {
    width: 100%;
  }
  .date-picker {
    transform: none;
  }
`
