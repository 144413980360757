import * as React from 'react'
import styled from 'styled-components'
import { Title } from '../styles'

type Props = {
  hasAttachment: boolean
  description: string
}

export const ActivityTroubleTicketDocumentDescription = React.memo((props: Props) => {
  const { hasAttachment, description } = props
  return (
    <>
      <Title>Description</Title>
      <DescriptionText style={hasAttachment ? undefined : { marginBottom: 20 }}>{description}</DescriptionText>
    </>
  )
})

const DescriptionText = styled.div`
  max-height: 100px;
  font-size: 12px;
  line-height: 1.2;
  color: #445366;
  overflow: auto;
`
