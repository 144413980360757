import { DispatchDeliveryOrderViewDTO } from '../../../api/api'

export const isDDOStatusWorking = (status: DispatchDeliveryOrderViewDTO.StatusEnum): boolean => {
  return ![
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].includes(status)
}
