import { RootState } from '..'
import { HazmatDTO } from '../../api/api'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectHazmat = (id: string) => (state: RootState): HazmatDTO | undefined => {
  return state.lists[EntityType.hazmat][id]
}

// SELECT MANY
export const selectHazmats = (state: RootState): Record<string, HazmatDTO> => {
  return state.lists[EntityType.hazmat]
}
