import * as React from 'react'
import { StyledFilter, StyledFieldContainer } from '../../Grid/styles'
import { Select } from '../../../../../UI/DataFields/Select'
import { Input } from '../../../../../UI/DataFields/Input'
import { QMPTab, RulesFieldType } from '../../../interfaces'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { useQMP } from '../../../../../../hooks/useQMP'

type OwnProps = {
  modifyMode: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & OwnProps & DispatchProps

type State = {}

export const Filter = () => {
  return <div>Filter</div>

  // const {} = useQMP()
  // const reducer = state[QMPTab.RULES]
  // const setFieldValue = (field: RulesFieldType) => (value: any) => {}
  // const selectedParameter = ''
  //
  // return (
  //   <StyledFilter className={'special-fields-container'}>
  //     <StyledFieldContainer style={{ width: 220 }}>
  //       <Input title={reducer.name ? 'Name' : null} value={reducer.name} placeholder={'Name'} onChange={() => {}} />
  //     </StyledFieldContainer>
  //     <StyledFieldContainer style={{ width: 220 }}>
  //       <Select
  //         title={selectedParameter ? 'Parameter' : null}
  //         placeholder={'Parameter'}
  //         selectedValue={selectedParameter}
  //         list={[]}
  //         onSelect={setFieldValue(RulesFieldType.parameter)}
  //       />
  //     </StyledFieldContainer>
  //     <StyledFieldContainer style={{ width: 220 }}>
  //       <Input
  //         title={reducer.name ? 'Function' : null}
  //         value={reducer.function}
  //         placeholder={'Function'}
  //         onChange={setFieldValue(RulesFieldType.function)}
  //       />
  //     </StyledFieldContainer>
  //     <StyledFieldContainer style={{ width: 105 }}>
  //       <DateField
  //         title={reducer.dateUpdated ? 'Date Updated' : null}
  //         setTimeNowOnEmpty={true}
  //         date={null}
  //         dateType={DateTypes.DateISOString}
  //         placeholder={'Date Updated'}
  //         onUpdate={setFieldValue(RulesFieldType.dateUpdated)}
  //       />
  //     </StyledFieldContainer>
  //     <StyledFieldContainer style={{ width: 220 }}>
  //       <Input
  //         title={reducer.createdBy ? 'Created By' : null}
  //         value={reducer.createdBy}
  //         placeholder={'Created By'}
  //         onChange={setFieldValue(RulesFieldType.createdBy)}
  //       />
  //     </StyledFieldContainer>
  //   </StyledFilter>
  // )
}
