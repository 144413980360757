import * as React from 'react'
import { Widget } from '../../'
import { Window } from './preview'
import { Popover } from './popover'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

type Props = {}

const Document = () => {
  const { deliveryOrder, isExport, isImport, isRepo } = useGridItemDDO()

  let isHighlighted = false
  let boldText = ''
  let text = ''

  if (isImport) {
    isHighlighted = !deliveryOrder.billOfLadingNumber
    boldText = deliveryOrder.billOfLadingNumber
    text = deliveryOrder.bookingNumber
  }

  if (isExport || isRepo) {
    isHighlighted = !deliveryOrder.bookingNumber
    boldText = deliveryOrder.bookingNumber
    text = deliveryOrder.billOfLadingNumber
  }

  if (!isHighlighted) {
    const isBookingNumberInvalid = Boolean(deliveryOrder.bookingNumber && deliveryOrder.bookingNumber.length < 4)
    const isBillOfLadingNumberInvalid = Boolean(
      deliveryOrder.billOfLadingNumber && deliveryOrder.billOfLadingNumber.length < 4
    )
    isHighlighted = isBookingNumberInvalid || isBillOfLadingNumberInvalid
  }

  return (
    <Widget
      title={'Documents'}
      highlighted={isHighlighted}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        bold: boldText,
        text
      }}
      detailsProps={undefined}
    />
  )
}

export default Document
