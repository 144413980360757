import { ChannelType, IChannels } from '../interfaces'
import * as R from 'remeda'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'

export const addRecentOpenedChannelId = (channels: IChannels, channelId: string): IChannels => {
  const recentlyOpened = R.uniq([channelId, ...channels.idListByType[ChannelType.recentlyOpened]])
  const maxChannelNumber = CommunicationHubConstants.maxChannelsNumber[ChannelType.recentlyOpened]

  if (recentlyOpened.length > maxChannelNumber) {
    recentlyOpened.length = maxChannelNumber
  }

  return {
    ...channels,
    idListByType: {
      ...channels.idListByType,
      [ChannelType.recentlyOpened]: recentlyOpened
    }
  }
}
