import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { FieldWarning } from '../../../../../../../../../UI/FieldWarning'
import { DateTimeRangeDTO } from '../../../../../../../../../../api/origin/business-logic'
import { ActivityGroupAction } from '../../../../../../../../../../services/DTO/activity/controller'
import { ActivityColumn, DabbleColumn } from '../../../styles'
import { DateOnFocus, DateTimePicker } from '../../../../../../../../../UI/DataFields/DateTimePicker/views'
import { dateService } from '../../../../../../../../../../services/timeService'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'

type Props = {}

export const PlannedDateActivityColumn = (props: Props) => {
  const {
    isLinkedActivityToBobtail,
    isDropBobtail,
    isPickBobtail,
    transportationRow,
    gotoActivity,
    businessActivity,
    disabled,
    updateActivityRow,
    rowNumber,
    stage,
    importDatesConfused,
    exportDatesConfused,
    appDateIsNotConfirmed,
    activityWithWrongOrderOfPlannedDates
  } = useTransportationActivityRow()

  const exportDateErrorMessage = 'You are scheduling return after Cutoff date'
  const importDateErrorMessage = 'You are scheduling pick up after LFD date'
  const disablePlannedDate = disabled || isDropBobtail

  return (
    <DabbleColumn>
      <ActivityColumn
        data-id={[
          'go-to-activity-startPlannedDateTimeRange',
          'dateFrom-' + oc(gotoActivity).startPlannedDateTimeRange.from(),
          'dateTo-' + oc(gotoActivity).startPlannedDateTimeRange.to(),
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={'border-bottom'}
      >
        {!(isDropBobtail || isLinkedActivityToBobtail) && (
          <DateTimePicker
            disabled={disablePlannedDate}
            dateOnFocus={DateOnFocus.startWorkDay}
            date={gotoActivity.startPlannedDateTimeRange}
            isRange={true}
            highlight={oc(activityWithWrongOrderOfPlannedDates).id() === gotoActivity.id}
            minDate={dateService.createStringDate.startWorkDay}
            // @ts-ignore
            onChange={(startPlannedDateTimeRange: DateTimeRangeDTO) =>
              updateActivityRow(
                {
                  ...transportationRow,
                  activityGroup: {
                    ...transportationRow.activityGroup,
                    gotoActivity: {
                      ...gotoActivity,
                      startPlannedDateTimeRange
                    }
                  }
                },
                ActivityGroupAction.update
              )
            }
          />
        )}
      </ActivityColumn>
      <ActivityColumn
        className={cn({ 'border-top': isPickBobtail })}
        data-id={[
          'business-activity-startPlannedDateTimeRange',
          'dateFrom-' + oc(transportationRow).activityGroup.businessActivity.startPlannedDateTimeRange.from(),
          'dateTo-' + oc(transportationRow).activityGroup.businessActivity.startPlannedDateTimeRange.to(),
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
      >
        {!isDropBobtail && (
          <>
            {exportDatesConfused && <FieldWarning style={{ left: -9, top: -20 }} message={exportDateErrorMessage} />}
            {importDatesConfused && <FieldWarning style={{ left: -9, top: -20 }} message={importDateErrorMessage} />}
            <DateTimePicker
              redText={appDateIsNotConfirmed}
              highlight={oc(activityWithWrongOrderOfPlannedDates).id() === businessActivity.id}
              disabled={disablePlannedDate}
              dateOnFocus={DateOnFocus.startWorkDay}
              date={businessActivity.startPlannedDateTimeRange}
              isRange={true}
              minDate={dateService.createStringDate.startWorkDay}
              // @ts-ignore
              onChange={(startPlannedDateTimeRange: DateTimeRangeDTO) =>
                updateActivityRow(
                  {
                    ...transportationRow,
                    activityGroup: {
                      ...transportationRow.activityGroup,
                      businessActivity: {
                        ...businessActivity,
                        startPlannedDateTimeRange
                      }
                    }
                  },
                  ActivityGroupAction.update
                )
              }
            />
          </>
        )}
      </ActivityColumn>
    </DabbleColumn>
  )
}
