// tslint:disable:max-line-length
import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { IChannelMeta } from '../../../../store/reducers/communicationHub/interfaces'
import theme from '../../../../styles/theme'
import { UnreadMentionMessagesCount } from '../Navigation/NavigationChannel'
import { AllUnreadChannelHeaderDDO } from './AllUnreadChannelHeaderDDO'
import { CheckmarkIcon } from '../../../UI/icons/CheckmarkIcon'
import { muteChannel, unmuteChannel } from '../../epics'
import { ArrowIcon } from '../../../UI/icons/ArrowIcon'
import { OpenInNewTabIcon } from '../../../UI/icons/OpenInNewTabIcon'

type Props = {
  channelMeta: IChannelMeta
  isExpanded: boolean
  setExpandedChannelId: React.Dispatch<React.SetStateAction<string>>
  markReadSecondsLeft: number
  setMarkRead: () => void
  clearMarkRead: () => void
}

export const AllUnreadChannelHeader = React.memo((props: Props) => {
  const { channelMeta, isExpanded, setExpandedChannelId, markReadSecondsLeft, setMarkRead, clearMarkRead } = props

  return (
    <Container className={'allUnreadChannelHeader'}>
      {markReadSecondsLeft ? (
        <Checkmark children={<CheckmarkIcon />} />
      ) : (
        <ShowDetailsButton
          className={isExpanded ? 'down' : ''}
          onClick={() => setExpandedChannelId(isExpanded ? undefined : channelMeta.id)}
        >
          <ArrowIcon />
        </ShowDetailsButton>
      )}
      <ChannelName>
        <AllUnreadChannelHeaderDDO channelId={channelMeta.id} />
        <i>
          <OpenInNewTabIcon />
        </i>
      </ChannelName>
      <ChannelActions className={cn({ markedRead: markReadSecondsLeft })}>
        {channelMeta.mute ? (
          <Button style={{ opacity: 0.5 }} onClick={() => unmuteChannel(channelMeta.id)}>
            Unmute
          </Button>
        ) : (
          <Button onClick={() => muteChannel(channelMeta.id)}>Mute</Button>
        )}
        {markReadSecondsLeft ? (
          <>
            <MarkedReadText>marked read</MarkedReadText>
            <UndoMarkedRead onClick={clearMarkRead}>Undo</UndoMarkedRead>
            <MarkedReadTimer>{markReadSecondsLeft}s</MarkedReadTimer>
          </>
        ) : (
          <Button className={'blue'} onClick={setMarkRead}>
            Mark as Read
          </Button>
        )}
        {channelMeta.mentionMsgIds && channelMeta.mentionMsgIds.length ? (
          <UnreadMentionMessagesCount children={channelMeta.mentionMsgIds.length} />
        ) : null}
      </ChannelActions>
      <ChannelActionsPlaceholder className={'actionsPlaceholder'}>
        <ButtonPlaceholder style={{ width: 42 }} />
        <ButtonPlaceholder style={{ width: 80 }} />
      </ChannelActionsPlaceholder>
    </Container>
  )
})

const Container = styled.div`
  height: 52px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 8px;
  padding: 0 24px;
`

const Button = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #697585;
  border-radius: 15px;
  font-size: 10px;
  color: #697585;
  padding: 0 8px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #697585;
    color: white !important;
  }

  &.blue {
    border-color: ${theme.colors.basicBlueColor};
    color: ${theme.colors.basicBlueColor};

    &:hover {
      background-color: ${theme.colors.basicBlueColor};
    }
  }
`
const Checkmark = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShowDetailsButton = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    border-color: rgba(70, 97, 191, 0.15);
    background: #e3edfd;
  }

  &.down {
    transform: rotate(90deg);
  }
`
const ChannelActions = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 16px;
  grid-column-gap: 16px;
  margin-left: auto;

  &.markedRead {
    grid-template-columns: auto auto auto auto 16px;
    grid-column-gap: 8px;
  }
`
const ChannelActionsPlaceholder = styled(ChannelActions)`
  display: none;
`
const ButtonPlaceholder = styled.div`
  height: 20px;
  background: #e3e8ea;
  border-radius: 15px;
`
const ChannelName = styled.div`
  display: flex;
  align-items: center;

  > i {
    flex-shrink: 0;
    opacity: 0;
    padding: 8px;
  }

  &:not(:has(.fetching)) {
    > *:first-child:hover + i {
      opacity: 1;
    }
  }

  &:has(.fetching) {
    ~ ${ChannelActions} {
      display: none;
    }

    ~ ${ChannelActionsPlaceholder} {
      display: grid;
    }
  }
`

const MarkedReadText = styled.div`
  color: #697585;
  font-size: 10px;
`
const UndoMarkedRead = styled.div`
  color: ${theme.colors.basicBlueColor};
  font-size: 10px;
  cursor: pointer;
`
const MarkedReadTimer = styled.div`
  width: 11px;
  color: ${theme.colors.basicBlueColor};
  font-weight: 300;
  font-size: 10px;
`
