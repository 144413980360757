import { RuleDTO } from '../../../../../api/origin/qmp-service'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.rule]: RuleDTO[]
}

export const parseRules = async (rules: RuleDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.rule]: []
  }

  try {
    rules.forEach(rule => {
      result[EntityType.rule].push(rule)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
