import { DateISOString, DateTimeRangeDTO } from '../../api/origin/business-logic/api'
import { convertTimePlusTimezoneOffsetToISOString } from '../../components/UI/DatePicker/Functions'
import { DateTime } from 'luxon'
import { APPLIED_TIMEZONE } from '../../config'

export const compareDates = (less: any, greater: any, result: 'boolean' | 'days' = 'boolean') => {
  if (!less || !greater) {
    return true
  }
  switch (result) {
    case 'days':
      return Math.floor(Math.abs(Date.parse(less) - Date.parse(greater)) / 1000) / 86400
    default: {
      return Date.parse(greater) - Date.parse(less) >= 0
    }
  }
}

export const resetLocalDay = (date: string | null, setEndDay?: boolean) => {
  if (!date) {
    return null
  }

  const parsedDate: any = new Intl.DateTimeFormat('en-US', {
    timeZone: APPLIED_TIMEZONE,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
    .formatToParts(new Date(date))
    .reduce((acc, curr) => {
      const props = ['month', 'year', 'day']

      if (props.includes(curr.type)) {
        acc[curr.type] = Number(curr.value)
      }

      return acc
    }, {})

  parsedDate.hour = setEndDay ? 23 : 0
  parsedDate.minute = setEndDay ? 59 : 0
  parsedDate.second = 0

  return new Date(
    DateTime.fromObject(
      {
        year: parsedDate.year,
        month: parsedDate.month,
        day: parsedDate.day,
        hour: parsedDate.hour,
        minute: parsedDate.minute,
        second: parsedDate.second
      },
      // @ts-ignore
      { zone: 'America/New_York' }
    )
      .toUTC()
      .toMillis()
  ).toISOString()
}

export const resetTimeToZero = (date: string | null, setEndDay?: boolean, setToday?: boolean) => {
  if (!date && !setToday) {
    return null
  }
  const parsedDate = !setToday ? new Date(Date.parse(date)) : new Date()
  return convertTimePlusTimezoneOffsetToISOString(
    setEndDay ? parsedDate.setUTCHours(23, 59, 59, 0) : parsedDate.setUTCHours(0, 0, 0, 0)
  )
}

export const getDateFromISOString = (dateISOString?: any, short: boolean = false, keepTimezone: boolean = false) => {
  if (!dateISOString) {
    return ''
  }
  return convertISODateToDateWithHoursMins(dateISOString, short, true, false, keepTimezone)
}

export const convertRangeISODateToDateWithHoursMins = (
  dateRangeISOString: DateTimeRangeDTO,
  short: boolean = false,
  hideTime: boolean = false,
  keepTimezone: boolean = false
) => {
  let dateToShow = ''
  if (!dateRangeISOString) {
    return ''
  }
  if (dateRangeISOString.from) {
    dateToShow += convertISODateToDateWithHoursMins(dateRangeISOString.from, short, false, false, keepTimezone)
  }
  if (dateRangeISOString.to) {
    if (!dateRangeISOString.from) {
      dateToShow += convertISODateToDateWithHoursMins(dateRangeISOString.to, short, false, false, keepTimezone)
    } else {
      const date = new Date(dateRangeISOString.to)
      if ((!hideTime && date.getHours()) || date.getMinutes() || date.getMilliseconds()) {
        dateToShow += ` - ${convertISODateToDateWithHoursMins(dateRangeISOString.to, short, false, true, keepTimezone)}`
      }
    }
  }
  return dateToShow
}

export const convertISODateToDateWithHoursMins = (
  dateISOString: DateISOString,
  short: boolean = false,
  hideTime: boolean = false,
  hideDate: boolean = false,
  keepTimezone: boolean = false
) => {
  if (!dateISOString) {
    return ''
  }

  const date = new Date(dateISOString)

  // if (!hideTime && !date.getHours() && !date.getMinutes() && !date.getMilliseconds()) {
  //   hideTime = true
  // }

  return new Intl.DateTimeFormat('en-US', {
    timeZone: keepTimezone ? undefined : APPLIED_TIMEZONE,
    hour12: false,
    day: hideDate ? undefined : '2-digit',
    month: hideDate ? undefined : '2-digit',
    year: hideDate || short ? undefined : '2-digit',
    hour: hideTime ? undefined : '2-digit',
    minute: hideTime ? undefined : '2-digit'
  })
    .formatToParts(date)
    .map(part => {
      return part.type === 'hour' && part.value === '24' ? '00' : part.value
    })
    .join('')
    .replace(',', '')
}

export const getStringConvertISODateToDateWithHoursMins = (
  dateISOString: DateISOString,
  short: boolean = false,
  hideTime: boolean = false,
  keepTimezone: boolean = false
) => (dateISOString ? convertISODateToDateWithHoursMins(dateISOString, short, hideTime, keepTimezone) : '')
