import { TransportationActivityViewDTO } from '../../../api/api'
import { isActualActivity } from '../../../services/functions/test/isActualActivity'
import { IMoveDTO, IMoveItemDTO } from '../../../store/reducers/communicationHub/interfaces'

type Props = {
  move: IMoveDTO
  transportationActivityMapping: Record<string, TransportationActivityViewDTO>
  withoutConnections?: boolean
}

export const getFirstActualMoveItem = (props: Props): IMoveItemDTO => {
  const { move, transportationActivityMapping, withoutConnections } = props

  if (!(move && move.items && move.items.length)) {
    return undefined
  }

  return move.items.find(({ gotoActivityId, businessActivityId }) => {
    const gotoActivity = gotoActivityId && transportationActivityMapping[gotoActivityId]
    const businessActivity = businessActivityId && transportationActivityMapping[businessActivityId]

    if (
      gotoActivity &&
      businessActivity &&
      isActualActivity(businessActivity) &&
      (!withoutConnections ||
        (!gotoActivity.bobtailFrom &&
          !gotoActivity.bobtailTo &&
          !businessActivity.bobtailFrom &&
          !businessActivity.bobtailTo))
    ) {
      return true
    }
  })
}
