import { DispatchDeliveryOrderDTO, DispatchDeliveryOrderViewDTO } from '../../../api/api'

export const isDDOStatusCancelled = (
  status: DispatchDeliveryOrderDTO.StatusEnum | DispatchDeliveryOrderViewDTO.StatusEnum
): boolean => {
  return [
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].includes(status)
}
