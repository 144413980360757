import * as React from 'react'
import { oc } from 'ts-optchain'
import { TListsState } from '../store/reducers/lists/interfaces'
import { GridItemLocationContext } from '../contexts/GridItemLocationContext'
import { useContact } from '../hooks/useContact'
import { useLocation } from '../hooks/useLocation'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemLocationProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const location = useLocation({ id, modifiedLists })
  const contact = useContact({ id: oc(location).primaryContactId(), modifiedLists })

  return (
    <GridItemLocationContext.Provider
      value={{ location, isExpanded: Boolean(modifiedLists), primaryContact: contact }}
      children={children}
    />
  )
})
