import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const userAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('USER/ASYNC');
import { FetchAPI, FetchArgs, AccessTokenDTO, CreateUserDTO, UpdatePasswordDTO, UpdateUserDTO, UserDTO, UserEventDTO, DateISOString } from './api';
export interface IRegistrationRegistrationControllerConfirmCodeRequest { phoneNumber: string, code: string, options?: any }
export type IRegistrationRegistrationControllerConfirmCodeSuccess = AccessTokenDTO;
export type IRegistrationRegistrationControllerConfirmCodeError = any;
export const registrationRegistrationControllerConfirmCodeAction = userAsyncActionCreator.async<
  IRegistrationRegistrationControllerConfirmCodeRequest, IRegistrationRegistrationControllerConfirmCodeSuccess, IRegistrationRegistrationControllerConfirmCodeError
>('REGISTRATION_REGISTRATIONCONTROLLERCONFIRMCODE');

export interface IRegistrationRegistrationControllerGetCodeRequest { phoneNumber: string, options?: any }
export type IRegistrationRegistrationControllerGetCodeSuccess = Response;
export type IRegistrationRegistrationControllerGetCodeError = any;
export const registrationRegistrationControllerGetCodeAction = userAsyncActionCreator.async<
  IRegistrationRegistrationControllerGetCodeRequest, IRegistrationRegistrationControllerGetCodeSuccess, IRegistrationRegistrationControllerGetCodeError
>('REGISTRATION_REGISTRATIONCONTROLLERGETCODE');

export interface IUserUserControllerCreateRequest { body: CreateUserDTO, options?: any }
export type IUserUserControllerCreateSuccess = UserDTO;
export type IUserUserControllerCreateError = any;
export const userUserControllerCreateAction = userAsyncActionCreator.async<
  IUserUserControllerCreateRequest, IUserUserControllerCreateSuccess, IUserUserControllerCreateError
>('USER_USERCONTROLLERCREATE');

export interface IUserUserControllerDeactivateRequest { id: string, options?: any }
export type IUserUserControllerDeactivateSuccess = UserDTO;
export type IUserUserControllerDeactivateError = any;
export const userUserControllerDeactivateAction = userAsyncActionCreator.async<
  IUserUserControllerDeactivateRequest, IUserUserControllerDeactivateSuccess, IUserUserControllerDeactivateError
>('USER_USERCONTROLLERDEACTIVATE');

export interface IUserUserControllerDeactivateByEmailRequest { email: string, options?: any }
export type IUserUserControllerDeactivateByEmailSuccess = UserDTO;
export type IUserUserControllerDeactivateByEmailError = any;
export const userUserControllerDeactivateByEmailAction = userAsyncActionCreator.async<
  IUserUserControllerDeactivateByEmailRequest, IUserUserControllerDeactivateByEmailSuccess, IUserUserControllerDeactivateByEmailError
>('USER_USERCONTROLLERDEACTIVATEBYEMAIL');

export interface IUserUserControllerFindUserRequest { phoneNumber?: string, email?: string, options?: any }
export type IUserUserControllerFindUserSuccess = UserDTO;
export type IUserUserControllerFindUserError = any;
export const userUserControllerFindUserAction = userAsyncActionCreator.async<
  IUserUserControllerFindUserRequest, IUserUserControllerFindUserSuccess, IUserUserControllerFindUserError
>('USER_USERCONTROLLERFINDUSER');

export interface IUserUserControllerGetAllRequest { role?: string, options?: any }
export type IUserUserControllerGetAllSuccess = UserDTO[];
export type IUserUserControllerGetAllError = any;
export const userUserControllerGetAllAction = userAsyncActionCreator.async<
  IUserUserControllerGetAllRequest, IUserUserControllerGetAllSuccess, IUserUserControllerGetAllError
>('USER_USERCONTROLLERGETALL');

export interface IUserUserControllerGetUserRequest { id: string, options?: any }
export type IUserUserControllerGetUserSuccess = UserDTO;
export type IUserUserControllerGetUserError = any;
export const userUserControllerGetUserAction = userAsyncActionCreator.async<
  IUserUserControllerGetUserRequest, IUserUserControllerGetUserSuccess, IUserUserControllerGetUserError
>('USER_USERCONTROLLERGETUSER');

export interface IUserUserControllerMigrateRequest { id: string, options?: any }
export type IUserUserControllerMigrateSuccess = Response;
export type IUserUserControllerMigrateError = any;
export const userUserControllerMigrateAction = userAsyncActionCreator.async<
  IUserUserControllerMigrateRequest, IUserUserControllerMigrateSuccess, IUserUserControllerMigrateError
>('USER_USERCONTROLLERMIGRATE');

export interface IUserUserControllerMigrateAllRequest { options?: any }
export type IUserUserControllerMigrateAllSuccess = Response;
export type IUserUserControllerMigrateAllError = any;
export const userUserControllerMigrateAllAction = userAsyncActionCreator.async<
  IUserUserControllerMigrateAllRequest, IUserUserControllerMigrateAllSuccess, IUserUserControllerMigrateAllError
>('USER_USERCONTROLLERMIGRATEALL');

export interface IUserUserControllerSyncUsersRequest { body: UserEventDTO, options?: any }
export type IUserUserControllerSyncUsersSuccess = Response;
export type IUserUserControllerSyncUsersError = any;
export const userUserControllerSyncUsersAction = userAsyncActionCreator.async<
  IUserUserControllerSyncUsersRequest, IUserUserControllerSyncUsersSuccess, IUserUserControllerSyncUsersError
>('USER_USERCONTROLLERSYNCUSERS');

export interface IUserUserControllerUpdateRequest { id: string, body: UpdateUserDTO, options?: any }
export type IUserUserControllerUpdateSuccess = UserDTO;
export type IUserUserControllerUpdateError = any;
export const userUserControllerUpdateAction = userAsyncActionCreator.async<
  IUserUserControllerUpdateRequest, IUserUserControllerUpdateSuccess, IUserUserControllerUpdateError
>('USER_USERCONTROLLERUPDATE');

export interface IUserUserControllerUpdatePasswordRequest { id: string, body: UpdatePasswordDTO, options?: any }
export type IUserUserControllerUpdatePasswordSuccess = UserDTO;
export type IUserUserControllerUpdatePasswordError = any;
export const userUserControllerUpdatePasswordAction = userAsyncActionCreator.async<
  IUserUserControllerUpdatePasswordRequest, IUserUserControllerUpdatePasswordSuccess, IUserUserControllerUpdatePasswordError
>('USER_USERCONTROLLERUPDATEPASSWORD');


