import { createSelectListFromDirectory } from './index'
import { containerDirectory } from '../DTO/container/directory'
import { ContainerTypeDTO } from '../../api/origin/business-logic'

const ownership = createSelectListFromDirectory(containerDirectory.ownership)

export const containerLists = {
  ownership,
  containerTypes: [] as ContainerTypeDTO[],
  containerTypeCodesList: [] as { label: string; value: string }[],
  containerTypeNamesList: [] as { label: string; value: string }[],
  containerTypeWithDescription: [] as { label: string; htmlLabel: string; value: string }[]
}
