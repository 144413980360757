import { ColumnDTO } from '../../store/reducers/tabs/interfaces'

// use "%%" as value to make request with empty filter property --> filterProperty%%;
export const createFilterRequest = (
  filter: ColumnDTO.Filter[],
  separator: string = ';',
  regExp?: RegExp
): string | undefined => {
  if (!filter) {
    return undefined
  }

  let result = ''

  filter.forEach((item: ColumnDTO.Filter) => {
    if (!item.value) {
      return
    }

    const equalSymbol = item.isEqualToValue === false ? '!%' : '%%'
    const valueType: 'string' | 'number' | 'object' | any = typeof item.value

    switch (valueType) {
      case 'number':
      case 'string': {
        const value = String(item.value)
        result += separator + item.column + equalSymbol + value.replace(regExp || /[%!><;]/g, '')
        return
      }
      case 'object': {
        const isArray = Array.isArray(item.value)
        if (isArray) {
          const value = item.value as string[]
          // multiple values
          if (!value.length) {
            return
          }
          result += separator + item.column + equalSymbol + value.join(',').replace(regExp || /[%!><;]/g, '')
        } else {
          // is date
          // for now we don't need date range
          const date = item.value as { from?: string; to?: string }
          if (date.from) {
            result += separator + item.column + '>' + date.from
          }

          if (date.to) {
            result += separator + item.column + '<' + date.to
          }
        }
        return
      }
      default:
        return
    }
  })

  return result.slice(1)
}
