import * as React from 'react'
import { oc } from 'ts-optchain'
import { SearchByList, TDropDownItem } from '.'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { UserType } from '../../../../api/origin/user-service'
import { selectUsers } from '../../../../store/select/userSelect'

type Props = {
  selectedUserId: string
  onChange: (userId: string | undefined) => void
  title?: string
  required?: boolean
}

export const SalesRepresentativeSearch = (props: Props) => {
  const { title, required, selectedUserId, onChange } = props
  const users = useAppSelector(selectUsers)
  const list: TDropDownItem[] = React.useMemo(
    () =>
      Object.values(users)
        .filter(item => item && item.type === UserType.SALESREP)
        .map(item => ({
          label: item.name,
          value: item.id
        })),
    [users]
  )
  const selectedUserName = oc(users)[selectedUserId].name()

  return (
    <SearchByList
      title={title}
      required={required}
      label={selectedUserId ? selectedUserName || 'Loading...' : undefined}
      isFetching={selectedUserId && !selectedUserName}
      dropDownList={list}
      onChange={onChange}
    />
  )
}
