import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
// tslint:disable-next-line:max-line-length
import { isBobtailableBusinessActivity } from '../../../../../../../../services/functions/test/isBobtailableBusinessActivity'
import { useTransportationActivityRow } from '../../../../../../../../hooks/useTransportationActivityRow'
import {
  ActivityGroupAction,
  TransportationActivityRow
} from '../../../../../../../../services/DTO/activity/controller'
import { TransportationActivityViewDTO } from '../../../../../../../../api/api'
import { activityDirectory } from '../../../../../../../../services/DTO/activity/directory'
import { isUnsuccessfulActivityGroup } from '../../../../../../../../services/functions/test/isUnsuccessfulActivity'
import { isBobtailGotoActivity } from '../../../../../../../../services/functions/test/isBobtailGotoActivity'

// mdi-arrow-up-thick

type Props = {
  bobtailType: 'drop' | 'pick'
}

export const AddBobtailButton = (props: Props) => {
  const {
    updateActivityRow,
    rows,
    isPickBobtail,
    isDropActivity,
    dropTypeActivityRowToCreateBobtail,
    pickTypeActivityRowToCreateBobtail,
    bobtailGroup
  } = useTransportationActivityRow()
  const { bobtailType } = props

  const rowForBobtailCreation =
    bobtailType === 'drop' ? dropTypeActivityRowToCreateBobtail : pickTypeActivityRowToCreateBobtail

  if (!rowForBobtailCreation) {
    return null
  }

  let isBobtailButtonAvailable = false
  let isAddDropBobtailButtonAvailable = false
  let isUndoBobtailButton = false

  if (bobtailType === 'drop') {
    isAddDropBobtailButtonAvailable =
      !bobtailGroup ||
      bobtailGroup.filter(group => isBobtailGotoActivity(group.gotoActivity) && !isUnsuccessfulActivityGroup(group))
        .length === 0
  }

  isUndoBobtailButton = isPickBobtail || (isDropActivity && !isAddDropBobtailButtonAvailable)
  isBobtailButtonAvailable = isBobtailableBusinessActivity({
    gotoActivity: oc(rowForBobtailCreation).activityGroup.gotoActivity(),
    businessActivity: oc(rowForBobtailCreation).activityGroup.businessActivity(),
    bobtailType,
    activityRows: rows,
    isVendorRequired: !isUndoBobtailButton
  })

  return (
    <Container className={cn(bobtailType)}>
      <Button
        className={cn('mdi mdi-plus-circle-outline', { disabled: !isBobtailButtonAvailable })}
        onClick={
          isBobtailButtonAvailable
            ? () => {
                if (bobtailType === 'drop') {
                  if (isUndoBobtailButton) {
                    const dropActivityBobtails = bobtailGroup.filter(
                      group => isBobtailGotoActivity(group.gotoActivity) && !isUnsuccessfulActivityGroup(group)
                    )
                    const rowsToDelete = (rows as TransportationActivityRow[])
                      .filter(row => dropActivityBobtails.some(group => oc(group).gotoActivity.id() === row.id))
                      .filter(Boolean)

                    updateActivityRow(rowsToDelete, ActivityGroupAction.remove)
                  } else {
                    updateActivityRow(rowForBobtailCreation, ActivityGroupAction.createDropActivityBobtail)
                  }
                } else if (bobtailType === 'pick') {
                  updateActivityRow(
                    {
                      ...rowForBobtailCreation,
                      activityGroup: {
                        ...rowForBobtailCreation.activityGroup,
                        gotoActivity: {
                          ...rowForBobtailCreation.activityGroup.gotoActivity,
                          type: isUndoBobtailButton
                            ? TransportationActivityViewDTO.TypeEnum.GOTO
                            : TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO
                        }
                      }
                    },
                    ActivityGroupAction.update
                  )
                }
              }
            : undefined
        }
        children={isUndoBobtailButton ? 'Undo Bobtail' : 'Add Bobtail'}
      />
    </Container>
  )
}

const Container = styled.div`
  z-index: 1;
  display: grid;
  align-items: center;
  grid-template-columns: 104px 104px;
  grid-gap: 12px;
  margin-left: -40px;

  &.drop {
    padding-bottom: 8px;
  }

  &.pick {
    padding-top: 8px;
  }
`

const Button = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #9ba8b8;
  cursor: pointer;
  user-select: none;

  &:not(.disabled):hover {
    color: ${activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY]};
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;

    &:before {
      color: #9ba8b8;
    }
  }

  &:before {
    width: 40px;
    font-size: 18px;
    text-align: center;
    color: ${activityDirectory.stageColor[TransportationActivityViewDTO.StageEnum.DELIVERY]};
    margin-right: 10px;
  }
`
