import * as React from 'react'
import { ContainerTypeDTO, ContainerViewDTO, SteamShipLineNameDTO, SteamShipLineViewDTO } from '../api/api'

export interface IGridItemContainerContext {
  isExpanded: boolean
  container: ContainerViewDTO
  steamShipLine: SteamShipLineNameDTO | SteamShipLineViewDTO
  containerType: ContainerTypeDTO
}

export const GridItemContainerContext = React.createContext<IGridItemContainerContext>({
  isExpanded: null,
  container: null,
  steamShipLine: null,
  containerType: null
})
