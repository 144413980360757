import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { BusinessPartnerViewDTO, ContactDTO, CustomerViewDTO, SubClientViewDTO } from '../../api/api'
import { isNewId, isNewObject } from '../DTO'
import { saveBusinessPartner } from './saveBusinessPartner'
import { requestUpdateCustomer } from '../../components/common/customer/epics'
import { saveSubClient } from './saveSubClient'

export interface ICustomerSubClient {
  subClient: SubClientViewDTO
  businessPartner: BusinessPartnerViewDTO
  contacts: ContactDTO[]
}

type Props = {
  customer: CustomerViewDTO | undefined
  subClients: ICustomerSubClient[] | undefined
  businessPartner: BusinessPartnerViewDTO | undefined
  contacts: ContactDTO[]
}

export const saveCustomer = async (props: Props): Promise<CustomerViewDTO> => {
  const { customer, businessPartner, contacts, subClients } = props
  const isNewCustomer = isNewObject(customer)

  // return Promise.reject(null)
  const savedBusinessPartner: BusinessPartnerViewDTO = await saveBusinessPartner({ businessPartner, contacts })
  let savedCustomer: CustomerViewDTO = customer
  let savedSubClients: SubClientViewDTO[] = []

  if (customer) {
    let customerToSave = customer

    if (isNewCustomer && savedBusinessPartner) {
      customerToSave = { ...customer, businessPartnerId: savedBusinessPartner.id }
    }

    savedCustomer = await requestUpdateCustomer({
      ...customerToSave,
      subClientIds: customerToSave.subClientIds.filter(id => !isNewId(id))
    })
  }

  if (subClients && subClients.length) {
    const customerId = oc(savedCustomer).id()
    const hasNewSubClient = subClients.some(item => isNewObject(item.subClient))

    savedSubClients = await Promise.all(
      subClients.map(item =>
        saveSubClient({
          subClient: customerId ? { ...item.subClient, customerId } : item.subClient,
          businessPartner: item.businessPartner,
          contacts: item.contacts
        })
      )
    )

    if (hasNewSubClient && savedCustomer) {
      savedCustomer = await requestUpdateCustomer({
        ...savedCustomer,
        subClientIds: R.uniq(
          savedCustomer.subClientIds.concat(savedSubClients.map(item => oc(item).id()).filter(Boolean))
        )
      })
    }
  }

  return savedCustomer
}
