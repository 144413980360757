import * as React from 'react'
import { Select } from '../../../components/UI/DataFields/Select'
import { LocationDto } from '../../../api/origin/location'
import { setModalWindowButtons } from '../../../store/reducers/modalWindow/functions'

type Props = {
  selectedPlaceId: string
  locations: LocationDto[]
  onSelect: (placeId: string) => void
}

export const SelectLocation = (props: Props) => {
  const { locations, onSelect } = props
  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string>(null)
  const list = (locations || []).map(item => ({ label: item.description, value: item.placeId }))

  React.useEffect(() => {
    if (props.selectedPlaceId) {
      setSelectedPlaceId(props.selectedPlaceId)
      setModalWindowButtons([
        { label: 'Cancel', outline: true },
        {
          label: 'Apply',
          onClick: () => onSelect(props.selectedPlaceId)
        }
      ])
    }
  }, [])

  return (
    <Select
      selectedValue={selectedPlaceId}
      list={list}
      onSelect={placeId => {
        setSelectedPlaceId(placeId)
        setModalWindowButtons([
          { label: 'Cancel', outline: true },
          {
            label: 'Apply',
            onClick: () => onSelect(placeId)
          }
        ])
      }}
    />
  )
}
