import { ISchedulerTabData } from '../../../components/common/scheduler/interfaces'
import { APPLIED_TIMEZONE } from '../../../config'

export const defaultSchedulerTabData = (): ISchedulerTabData => ({
  showMissed: false,
  showNow: true,
  favoriteItemIds: {}
})

export const getAppliedTimezoneDate = () =>
  new Date().toLocaleString('en-US', { timeZone: APPLIED_TIMEZONE }).split(',')[0]
