import * as React from 'react'
import { SingleValue } from '../styles'
import { ColumnValueWithPopover } from '../../../Column'
import { TSelecterWidgetListItem } from './index'

type Props = {
  selectedItems: TSelecterWidgetListItem[]
  label?: string | JSX.Element
  placeholder?: string
}

export const Label = (props: Props) => {
  const { label, placeholder, selectedItems } = props

  const createLabel = () => {
    if (!selectedItems) {
      return null
    }

    if (Array.isArray(selectedItems)) {
      if (selectedItems.length) {
        return selectedItems.length === 1 ? (
          <SingleValue>{selectedItems[0].label}</SingleValue>
        ) : (
          ColumnValueWithPopover({
            values: selectedItems.map(item => item.label),
            showNumberAsValue: true
          })
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  return <>{label || createLabel() || placeholder}</>
}
