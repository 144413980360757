import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { EquipmentDTO } from '../../../api/api'
import { saveEquipment as saveEquipmentPromise } from '../../saveEntity/saveEquipment'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { getListsState } from '../../../store'
import { isNewItem } from '../../utils'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'

export const saveEquipment = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const equipment: EquipmentDTO = parentItem
  const modifiedEquipment = oc(modifiedLists)[EntityType.equipment][parentItemId]()
  const store = getListsState()
  const condition = testEntityVersion(store, EntityType.equipment)(modifiedEquipment)

  const save = () => {
    return saveEquipmentPromise({ equipment }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(equipment) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
