import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { LocationViewDTO, callAPIWithErrorMessage, locationAPI } from '../../api/api'
import { generateAddressLabel } from './generate/generateAddressLabel'
import { locationControllerApi } from '../../api/location'
import {
  showErrorModalWindow,
  showModalWindow,
  showSuccessModalWindow
} from '../../store/reducers/modalWindow/functions'
import { SelectLocation } from '../DTO/location/SelectLocation'
import { getAddressState } from './get/getAddressState'

type Options = {
  setFetching?: (state: boolean) => void
}

export const validateLocationGEO = async (location: LocationViewDTO, options?: Options) => {
  const setFetching = oc(options).setFetching(() => {})
  const addressString = generateAddressLabel(location.address)

  setFetching(true)

  const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

  setFetching(false)

  if (searchResults.length) {
    return showModalWindow({
      width: 445,
      title: 'Select location',
      content: (
        <SelectLocation
          selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
          locations={searchResults}
          onSelect={selectedPlaceId => {
            if (selectedPlaceId) {
              setFetching(true)

              locationControllerApi
                .locationByPlaceId(selectedPlaceId)
                .then(async requestedLocation => {
                  const { latitude, longitude } = requestedLocation
                  const requestedAddress = oc(requestedLocation).address()
                  const updatedLocation = { ...location, placeId: selectedPlaceId, latitude, longitude }

                  if (requestedAddress) {
                    if (requestedAddress.city) {
                      updatedLocation.address = {
                        ...updatedLocation.address,
                        street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                        city: requestedAddress.city || location.address.city,
                        postalCode: requestedAddress.postalCode || location.address.postalCode,
                        stateId:
                          requestedAddress.stateCode && requestedAddress.countryCode
                            ? getAddressState({
                                codes: {
                                  stateCode: requestedAddress.stateCode,
                                  countryCode: requestedAddress.countryCode
                                }
                              }).id
                            : location.address.stateId
                      }
                    }
                  }

                  const resultedLocation = await callAPIWithErrorMessage(
                    locationAPI.update,
                    updatedLocation.id,
                    R.omit(updatedLocation, ['primaryContact'])
                  )
                    .then(() => {
                      showSuccessModalWindow({ title: 'Validation Success' })
                    })
                    .catch(() => null)

                  setFetching(false)
                })
                .catch(() => {
                  setFetching(false)

                  showErrorModalWindow({
                    title: 'Location search error'
                  })
                })
            }
          }}
        />
      ),
      buttons: [
        {
          label: 'Cancel'
        },
        {
          label: 'Apply',
          disabled: true
        }
      ]
    })
  } else {
    setFetching(false)

    return showErrorModalWindow({
      content: 'Location with the entered address is not found in Google'
    })
  }
}
