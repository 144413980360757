import { InsuranceDTO, PowerUnitDTO, PowerUnitRegistrationDTO, RepairDTO } from '../../../api/origin/business-logic'

const powerUnitType = {
  [PowerUnitDTO.PowerUnitTypeEnum.BOXTRUCK]: 'Box-truck',
  [PowerUnitDTO.PowerUnitTypeEnum.TRACTOR]: 'Tractor'
}

const ownership = {
  [PowerUnitDTO.OwnershipEnum.OWNED]: 'Owned',
  [PowerUnitDTO.OwnershipEnum.LEASED]: 'Leased',
  [PowerUnitDTO.OwnershipEnum.RENTED]: 'Rented',
  [PowerUnitDTO.OwnershipEnum.OWNEROPERATED]: 'Owner-Operated'
}

const restrictions = {
  [PowerUnitRegistrationDTO.RestrictionsEnum.NONE]: 'None',
  [PowerUnitRegistrationDTO.RestrictionsEnum.HIGHWAY]: 'Highway'
}

const inactiveReason = {
  [PowerUnitDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION]: 'Expired Maintenance Inspection',
  [PowerUnitDTO.InactiveReasonEnum.EXPIREDREGISTRATION]: 'Expired Registration',
  [PowerUnitDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION]: 'Expired Safety Inspection',
  [PowerUnitDTO.InactiveReasonEnum.MAINTENANCE]: 'Maintenance',
  [PowerUnitDTO.InactiveReasonEnum.OTHER]: 'Other Inactive Reason'
}

const insuranceType = {
  [InsuranceDTO.InsuranceTypeEnum.BOBTAIL]: 'Bobtail',
  [InsuranceDTO.InsuranceTypeEnum.LIABILITY]: 'Liability',
  [InsuranceDTO.InsuranceTypeEnum.PROPERTY]: 'Property'
}

const maintenanceInspectionFrequency = {
  [PowerUnitDTO.MaintenanceInspectionFrequencyEnum.ANNUALLY]: 'Annually',
  [PowerUnitDTO.MaintenanceInspectionFrequencyEnum.MONTHLY]: 'Monthly',
  [PowerUnitDTO.MaintenanceInspectionFrequencyEnum.OTHER]: 'Other',
  [PowerUnitDTO.MaintenanceInspectionFrequencyEnum.QUARTERLY]: 'Quarterly',
  [PowerUnitDTO.MaintenanceInspectionFrequencyEnum.SEMIANNUALLY]: 'Semi-Annually'
}

const truckType = {
  [PowerUnitDTO.TruckTypeEnum.SLEEPER]: 'Sleeper',
  [PowerUnitDTO.TruckTypeEnum.DAYCAP]: 'Day Cap'
}

const repairType = {
  [RepairDTO.RepairTypeEnum.BREAKDOWN]: 'Break Down',
  [RepairDTO.RepairTypeEnum.MAINTENANCEGREASE]: 'Maintenance Grease',
  [RepairDTO.RepairTypeEnum.MAINTENANCEOILFILTERS]: 'Maintenance Oil Filters',
  [RepairDTO.RepairTypeEnum.MAINTENANCEOTHER]: 'Maintenance Other',
  [RepairDTO.RepairTypeEnum.MAINTENANCETIRES]: 'Maintenance Tires',
  [RepairDTO.RepairTypeEnum.PREVENTIVEMAINTENANCE]: 'Preventive Maintenance'
}

export const powerUnitDirectory = {
  powerUnitType,
  ownership,
  restrictions,
  inactiveReason,
  insuranceType,
  maintenanceInspectionFrequency,
  truckType,
  repairType
}
