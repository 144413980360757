import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppNotification } from './interfaces'

const initialState: IAppNotification[] = []

const appNotificationSlice = createSlice({
  name: 'appNotifications',
  initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<IAppNotification[]>) => {
      return payload
    },
    push: (state, { payload }: PayloadAction<IAppNotification>) => {
      return state.filter(_ => _.id !== payload.id).concat(payload)
    },
    update: (state, { payload }: PayloadAction<IAppNotification>) => {
      let updated = false
      const result = state.map(item => {
        if (item.id === payload.id) {
          updated = true
          return payload
        }

        return item
      })

      return updated ? result : state.concat(payload)
    },
    delete: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      return state.filter(item => item.id !== id)
    }
  }
})

export const appNotificationsActions = appNotificationSlice.actions
export default appNotificationSlice.reducer
