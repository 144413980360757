import { UserDTO } from '../../../api/origin/user-service'

export class LocalStorageUser {
  public userId: string
  public user: UserDTO

  constructor(user: UserDTO) {
    if (user && user.id) {
      this.user = { ...user }
      this.userId = user.id
    }
  }
}
