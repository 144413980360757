import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { ActivityGroup } from '../../DTO/activity/interfaces'
import { getNeededActivityTypesByStages } from '../get/getNeededActivityTypesByStages'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroups: ActivityGroup[]
}

export const isLoadTypeActitiesValid = (props: Props): boolean => {
  return getNeededActivityTypesByStages(props)[TransportationActivityViewDTO.StageEnum.DELIVERY].length === 0
}
