import { oc } from 'ts-optchain'
import { dateService } from '../../../../services/timeService'
import { IMoveDTO, MoveStatus } from '../../../../store/reducers/communicationHub/interfaces'
import { ISchedulerTabFilterMapping } from '../interfaces'
import { DriverViewDTO } from '../../../../api/api'

export const filterAndSortVendorMoves = (props: {
  moves: IMoveDTO[]
  driverMapping: Record<string, DriverViewDTO>
  filterVendorId: string | undefined
  filter: ISchedulerTabFilterMapping
}): { vendorId: string; vendorName: string; dateMoves: { date: string; moves: IMoveDTO[] }[] }[] => {
  const { moves, driverMapping, filterVendorId, filter } = props
  const movesByVendorId: Record<string, IMoveDTO[]> = {}

  moves.forEach(move => {
    if (
      !move.vendorId ||
      move.status === MoveStatus.COMPLETED ||
      move.status === MoveStatus.UNSUCCESSFUL ||
      (filterVendorId && move.vendorId !== filterVendorId) ||
      (filter.dispatchDeliveryOrderId && move.dispatchDeliveryOrderId !== filter.dispatchDeliveryOrderId)
    ) {
      return
    }

    if (!movesByVendorId[move.vendorId]) {
      movesByVendorId[move.vendorId] = []
    }

    movesByVendorId[move.vendorId].push(move)
  })

  return Object.keys(movesByVendorId)
    .map(vendorId => {
      const sortedVendorMoves = movesByVendorId[vendorId].sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      const vendorMovesByDate = sortedVendorMoves.reduce((acc, move) => {
        if (move.plannedDate) {
          const date = dateService.getStringDay(move.plannedDate)

          if (!acc[date]) {
            acc[date] = []
          }

          acc[date].push(move)
        }

        return acc
      }, {})

      return {
        vendorId,
        vendorName: oc(driverMapping)[vendorId].name(''),
        dateMoves: Object.keys(vendorMovesByDate)
          .sort((date1, date2) => Date.parse(date1) - Date.parse(date2))
          .map(date => ({
            date,
            moves: vendorMovesByDate[date]
          }))
      }
    })
    .sort((a, b) => a.vendorName.localeCompare(b.vendorName))
}
