import * as React from 'react'
import { TablePreview } from '../../../common/grid/views/styles'
import { PreviewContent, PreviewHeader } from '../../../common/grid/views/styledComponents'
import { UnitStatus } from './UnitStatus'
import { CustomUnitHeader } from './CustomUnitHeader'
import { GridItemSpinner } from '../../../UI/Spinner/Spinner'
import { Navigation } from './Navigation'
import { useApplicationSize } from '../../../../hooks/useApplicationSize'
import { gridPreviews } from '../data'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { TExpandedItemContext } from '../../../../contexts/ExpandedItemContext'

type TUnitPreview = {
  headings: string[] | ((unit: any) => string[])
  tabs: Record<string, any>
  isValid: (expandedItemContext: TExpandedItemContext) => boolean
}

type Props = {}

export const Preview = React.memo((props: Props) => {
  const expandedItemContext = useExpandedItem()
  const { activeTab, entityType, isFetching, parentItem, isModified, setActiveTab } = expandedItemContext
  const { communicationHubWidth } = useApplicationSize()
  const unitPreview: TUnitPreview = gridPreviews[entityType]
  const isValid = isModified ? unitPreview.isValid(expandedItemContext) : true

  return (
    <TablePreview className={'table__row-details'}>
      <PreviewContent className={'grid-preview'} style={{ width: `calc(100vw - ${communicationHubWidth}px)` }}>
        {isFetching && <GridItemSpinner text={typeof isFetching === 'string' ? isFetching : undefined} />}
        <PreviewHeader>
          <Navigation
            parentItem={parentItem}
            headings={unitPreview.headings}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <CustomUnitHeader entityType={entityType} />
          <UnitStatus isValid={isValid} />
        </PreviewHeader>
        {unitPreview.tabs[activeTab]}
      </PreviewContent>
    </TablePreview>
  )

  // const RenderContent = (
  //   <TablePreview className={'table__row-details'}>
  //     ...
  //   </TablePreview>
  // )

  // switch (gridItemType) {
  //   case TabDTO.Type.dispatchDeliveryOrder:
  //     return (
  //       <DDOContext.Provider
  //         value={{
  //           state: commonDDOState({ dispatchDeliveryOrder: gridItem, isModifiedMode: isModified, isUnitValid }),
  //           functions: commonDDOFunctions(gridItem, actions),
  //           saveButton: (action: () => void = () => {}): JSX.Element =>
  //             isModified ? (
  //               <ActionsSaveCancel
  //                 disableSave={!isUnitValid}
  //                 showCancel={true}
  //                 onSave={() => {
  //                   action()
  //                   actions.save()
  //                 }}
  //                 onCancel={actions.cancel}
  //               />
  //             ) : null
  //         }}
  //         children={RenderContent}
  //       />
  //     )
  //   default:
  //     return RenderContent
  // }
})
