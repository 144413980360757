import { isMessageMuted } from '../../../services/functions/test/isMessageMuted'
import { IMessage } from '../../../store/reducers/communicationHub/interfaces'

export const checkIfNewMessageIncreasesChannelCounter = (message: IMessage, userId?: string): boolean => {
  try {
    const { senderId } = message || {}

    return !isMessageMuted(message) && (!userId || userId !== senderId)
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
    return false
  }
}
