import * as React from 'react'
import { SubClientsTab } from '../../../../subClient/views/SubClientsTab'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

export const SubClients = React.memo(() => {
  const { parentItem } = useExpandedItem()

  return <SubClientsTab parentEntity={parentItem} entityType={EntityType.customer} />
})
