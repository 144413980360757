import * as React from 'react'
import { Container, Column } from '../../../../../UI/GridSystem'
import { Credentials, Title, Date } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { Select } from '../../../../../UI/DataFields/Select'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { StyledGeneral } from '../General/styledComponents'
import Maintenance from './Maintenance'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { equipmentLists } from '../../../../../../services/select/equipmentLists'
// tslint:disable:max-line-length
import { generateMaintenanceInspection } from '../../../../../../services/functions/generate/generateMaintenanceInspection'
import { EquipmentDTO } from '../../../../../../api/api'
import { FileVersionDTO } from '../../../../../../api/origin/document-service'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'

type Props = {}

const isValidCarrier = createValidator(2, 64)
const isValidPolicyNumber = createValidator(8, 24)

export const EquipmentCredentials = (props: Props) => {
  const { parentItem, modifyParentObjectField } = useExpandedItem()
  const equipment: EquipmentDTO = parentItem

  return (
    <StyledGeneral minRows={70}>
      <Credentials rows={50} flexBasis={'auto'} margin={{ top: 40, left: 50, right: 50 }}>
        <Column columns={9} isGrid={true} margin={{ right: 100 }}>
          <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
            DOT Safety Inspection
          </Title>
          <Column columns={9} isGrid={true}>
            <Date margin={{ bottom: 25 }}>
              <DateTimePicker
                title={'Date'}
                date={equipment.dotSafetyInspection.performedDate}
                onChange={performedDate =>
                  modifyParentObjectField('dotSafetyInspection')({ ...equipment.dotSafetyInspection, performedDate })
                }
              />
            </Date>
            <Date margin={{ bottom: 20 }}>
              <DateTimePicker
                title={'Annual Exp. Date'}
                date={equipment.dotSafetyInspection.expirationDate}
                onChange={expirationDate =>
                  modifyParentObjectField('dotSafetyInspection')({ ...equipment.dotSafetyInspection, expirationDate })
                }
              />
            </Date>
            <ViewingObjectFileUploader
              uploadingFileId={equipment.id + ' equipment.dotSafetyInspection.documentFile'}
              title={'Doc. Image'}
              file={equipment.dotSafetyInspection.documentFile}
              historyProps={{ entityId: equipment.id, type: FileVersionDTO.TypeEnum.MACHINERYDOTSAFETYINSPECTION }}
              onFileUploaded={(documentFile, modifiedObject) => ({
                ...modifiedObject,
                dotSafetyInspection: { ...modifiedObject.dotSafetyInspection, documentFile }
              })}
              onClearFile={() =>
                modifyParentObjectField('dotSafetyInspection')({
                  ...equipment.dotSafetyInspection,
                  documentFile: undefined
                })
              }
            />
          </Column>
        </Column>

        <Column columns={9} isGrid={true} margin={{ right: 40 }}>
          <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
            Insurance
          </Title>
          <Select
            title={'Type'}
            selectedValue={equipment.insurance.insuranceType}
            list={equipmentLists.insuranceType}
            onSelect={insuranceType => modifyParentObjectField('insurance')({ ...equipment.insurance, insuranceType })}
          />
          <Container margin={{ top: 25, bottom: 20 }}>
            <Input
              title={'Carrier'}
              maxLength={64}
              valid={isValidCarrier(equipment.insurance.carrier)}
              value={equipment.insurance.carrier}
              onChange={carrier => modifyParentObjectField('insurance')({ ...equipment.insurance, carrier })}
            />
          </Container>
          <Date>
            <DateTimePicker
              title={'Exp. Date'}
              date={equipment.insurance.expirationDate}
              onChange={expirationDate =>
                modifyParentObjectField('insurance')({ ...equipment.insurance, expirationDate })
              }
            />
          </Date>
        </Column>

        <Column columns={9} rows={26} isGrid={true} margin={{ top: 120 }}>
          <Container margin={{ bottom: 20 }}>
            <Input
              title={'Policy #'}
              maxLength={24}
              valid={isValidPolicyNumber(equipment.insurance.policyNumber)}
              value={equipment.insurance.policyNumber}
              onChange={policyNumber => modifyParentObjectField('insurance')({ ...equipment.insurance, policyNumber })}
            />
          </Container>
          <ViewingObjectFileUploader
            uploadingFileId={equipment.id + ' equipment.insurance.coverageCardFile'}
            title={'Image of Ins. Coverage Card'}
            file={equipment.insurance.coverageCardFile}
            historyProps={{ entityId: equipment.id, type: FileVersionDTO.TypeEnum.MACHINERYINSURANCECOVERAGE }}
            onFileUploaded={(coverageCardFile, modifiedObject) => ({
              ...modifiedObject,
              insurance: { ...modifiedObject.insurance, coverageCardFile }
            })}
            onClearFile={() =>
              modifyParentObjectField('insurance')({
                ...equipment.insurance,
                coverageCardFile: undefined
              })
            }
          />
        </Column>
      </Credentials>

      <Maintenance
        equipment={equipment}
        addMaintenance={() =>
          modifyParentObjectField('maintenanceInspections')([
            ...equipment.maintenanceInspections,
            generateMaintenanceInspection()
          ])
        }
        deleteMaintenance={id =>
          modifyParentObjectField('maintenanceInspections')(
            equipment.maintenanceInspections.filter(item => item.id !== id)
          )
        }
        changeMaintenance={maintenance =>
          modifyParentObjectField('maintenanceInspections')(
            equipment.maintenanceInspections.map(item => (item.id === maintenance.id ? maintenance : item))
          )
        }
        changeMaintenanceInspectionFrequency={modifyParentObjectField('maintenanceInspectionFrequency')}
      />
    </StyledGeneral>
  )
}
