import { TransportationActivityViewDTO } from '../../../api/api'
import { TransportationActivityRow } from '../../DTO/activity/controller'
import { TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { generateTransportationActivity } from './generateTransportationActivity'

export const generateBobtailActivityRow = (
  activityGroupRow: TransportationActivityRow,
  bobtailType: 'drop' | 'pick'
): TransportationActivityRow => {
  const activityGroup = activityGroupRow.activityGroup as TransportationActivityGroup
  const gotoActivity = generateTransportationActivity({
    dispatchDeliveryOrderId: activityGroup.gotoActivity.dispatchDeliveryOrderId,
    stage: activityGroup.gotoActivity.stage,
    options: {
      type: TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO,
      status: TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
      vendorId: activityGroup.gotoActivity.vendorId
    }
  })
  const businessActivity = generateTransportationActivity({
    dispatchDeliveryOrderId: activityGroup.gotoActivity.dispatchDeliveryOrderId,
    stage: activityGroup.gotoActivity.stage,
    options: {
      groupId: gotoActivity.groupId,
      status: TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
      vendorId: activityGroup.businessActivity.vendorId
    }
  })

  if (bobtailType === 'drop') {
    businessActivity.comboType = gotoActivity.comboType = TransportationActivityViewDTO.ComboTypeEnum.EMPTY
    businessActivity.type = TransportationActivityViewDTO.TypeEnum.EMPTY
  } else if (bobtailType === 'pick') {
    businessActivity.comboType = gotoActivity.comboType = TransportationActivityViewDTO.ComboTypeEnum.PICKUPEMPTY
    businessActivity.type = TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
  }

  return { ...activityGroupRow, activityGroup: { gotoActivity, businessActivity }, id: gotoActivity.id }
}
