import * as React from 'react'
import styled from 'styled-components'
import { LocationGroup } from './LocationGroup'
import { LocationGroups } from './LocationGroups'
import { ILocationGroup, LocationGroupType } from '../../interfaces'
import { generateLocationGroup } from '../../../../../services/functions/generate/generateLocationGroup'

export const SideBar = () => {
  const [groups, setGroups] = React.useState<ILocationGroup[]>([])
  const [activeGroup, setActiveGroup] = React.useState<ILocationGroup>(null)

  React.useEffect(() => {
    const items: ILocationGroup[] = []

    for (let i = 0; i <= 50; i++) {
      items.push(generateLocationGroup({ name: makeString(30) }))
    }

    setGroups(items)
  }, [])

  return (
    <Container>
      {activeGroup ? (
        <LocationGroup group={activeGroup} onCreateGroupClick={() => setActiveGroup(generateLocationGroup())} />
      ) : (
        <LocationGroups groups={groups} onGroupSelect={setActiveGroup} />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 360px;
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
`

function makeString(length: number) {
  let result = ''
  const characters = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789 '
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
