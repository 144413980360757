import * as React from 'react'
import { ContainerGeneralTab } from '../Details/General'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Container

export const ContainerPreview = {
  headings: [T.General],
  tabs: {
    [T.General]: <ContainerGeneralTab />
  },
  isValid: checkValidation.container
}
