import styled from 'styled-components'
import theme from '../../../../../../../../../../styles/theme'

export const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`

export const Popup = styled.div`
  position: relative;
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  animation: appearElement 0.3s forwards;
  padding: 24px;
`
export const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 24px;
  cursor: pointer;

  &:not(:hover) {
    opacity: 0.5;
  }
`
export const Header = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;

  .mdi {
    font-size: 24px;
    color: ${theme.colors.defaultRed};
    margin-right: 8px;
  }
`
export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #6c707d;
  margin-bottom: 6px;

  &.required:after {
    content: '*';
    font-size: 12px;
    color: ${theme.colors.defaultRed};
    margin-left: 3px;
  }
`
export const Description = styled.div`
  font-size: 14px;
  line-height: 25px;
  padding-top: 6px;
`
