import * as React from 'react'
import {
  StyledRulesColumn,
  ColumnHeader,
  ColumnHeaderTitle,
  TableHeader,
  TableHeaderColumn,
  TableBody
} from '../../../../Grid/styles'
import { Row } from './Row'

export const columnProps: Record<string, { styles: object }> = {
  ID: {
    styles: { flex: '0 0 40px' }
  },
  Name: { styles: { flex: '0 0 165px' } },
  Definition: { styles: { flex: '1 0 0' } },
  Updated: { styles: { flex: '0 0 90px' } },
  'Created by': { styles: { flex: '0 0 90px' } }
}
export const columns = Object.keys(columnProps)

type Props = {
  title: string
}

export const RuleListColumn = (props: Props) => {
  const { title } = props
  return (
    <StyledRulesColumn>
      <ColumnHeader>
        <ColumnHeaderTitle>{title}</ColumnHeaderTitle>
        <TableHeader>
          {columns.map(column => (
            <TableHeaderColumn key={column} style={columnProps[column].styles}>
              {column}
            </TableHeaderColumn>
          ))}
        </TableHeader>
      </ColumnHeader>
      <TableBody>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
      </TableBody>
    </StyledRulesColumn>
  )
}
