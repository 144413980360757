import * as React from 'react'
import { DateTypes, IDateFieldProps, IDateFieldState } from './Interfaces'
import { cssMaskedDateInput, cssMaskedTimeInput, TimeWrapper, ResetDate, StyledDateField } from './styledComponents'
import Datepicker from './DatePicker'
import './DatePicker.scss'
import { DateISOString } from '../../../api/origin/business-logic'
import { LabelWithIcon, Title } from '../styledComponents'
import theme from '../../../styles/theme'
import { oc } from 'ts-optchain'
import { convertISODateToDateWithHoursMins } from '../../../services/timeService/dateUtils'
import { convertTimePlusTimezoneOffsetAndAppliedTimezone } from './Functions'
import cn from 'classnames'
import { debuggingMode } from '../../../services/debug'

// tslint:disable-next-line:no-var-requires
const ReactInputMask = require('react-input-mask')

export class DateField extends React.Component<IDateFieldProps, IDateFieldState> {
  private datePicker: any
  datePickerInput: any = null
  constructor(props: IDateFieldProps) {
    super(props)
    this.state = {
      showLabel: !!props.label,
      value: this.getDateByType(props.date),
      timeFrom: this.getTimeByType(props.date),
      timeTo: oc(props.date).to(null) ? this.formatTimeToHumanReadable(new Date(props.date.to)) : ''
    }
  }

  componentDidUpdate(prevProps: IDateFieldProps, prevState: IDateFieldState) {
    const { dateType, date } = this.props
    const propsDate = dateType === DateTypes.DateISOString ? date : date && (date.from || date.to)
    if (!propsDate && this.state.value === prevState.value && this.isDateValid(this.state.value)) {
      this.setState({
        value: '',
        timeFrom: '',
        timeTo: ''
      })
    }
    const propsDateTo = oc(date).to(null)
    const propsPrevDate =
      dateType === DateTypes.DateISOString
        ? prevProps.date
        : prevProps.date && (prevProps.date.from || prevProps.date.to)
    const propsPrevDateTo = oc(prevProps.date).to(null)
    if (propsDate !== propsPrevDate || propsDateTo !== propsPrevDateTo) {
      this.setState({
        value: this.getDateByType(propsDate),
        timeFrom: this.getTimeByType(propsDate),
        timeTo: propsDateTo ? this.formatTimeToHumanReadable(new Date(date.to)) : ''
      })
    }
  }

  formatDateToHumanReadable = (date: Date, keepTimezone?: boolean) =>
    convertISODateToDateWithHoursMins(date.toISOString(), this.props.small, true, false, keepTimezone)

  formatTimeToHumanReadable = (date: Date, notCheckForMs?: boolean) => {
    if (!notCheckForMs && (!date.getHours() && !date.getMinutes() && !date.getMilliseconds())) {
      return ''
    }
    return convertISODateToDateWithHoursMins(date.toISOString(), false, false, true)
  }

  formatDateFromHumanReadable = (date: string) => {
    return new Date(Date.parse(date))
  }

  getDateByType = (date: any) => {
    const possibleDate: DateISOString = typeof date === 'string' ? date : date && (date.from || date.to)
    return possibleDate ? this.formatDateToHumanReadable(new Date(Date.parse(possibleDate))) : ''
  }

  getTimeByType = (date: DateISOString | { from?: DateISOString; to?: DateISOString }) => {
    const possibleDate = typeof date === 'string' ? date : date && (date.from || date.to)
    return possibleDate ? this.formatTimeToHumanReadable(new Date(possibleDate)) : ''
  }

  initializeDatePicker = (dateSelected?: Date) => {
    if (!this.state.timeFrom && this.props.setTimeMorningOnEmpty) {
      dateSelected = dateSelected || new Date()
      this.setState({ timeFrom: '08:00' })
    }
    if (this.props.setDayStart) {
      dateSelected = dateSelected || new Date()
      this.setState({ timeFrom: '00:00' })
    }
    if (this.props.setDayEnd) {
      dateSelected = dateSelected || new Date()
      this.setState({ timeFrom: '23:59' })
    }
    if (!this.state.timeFrom && this.props.setTimeNowOnEmpty) {
      dateSelected = dateSelected || new Date()
      this.setState({
        timeFrom: this.formatTimeToHumanReadable(dateSelected, true)
      })
    }

    const inputProps = this.datePickerInput.getBoundingClientRect()
    let datepickerPosition = 'bl'
    if (inputProps.bottom + 220 > window.innerHeight) {
      datepickerPosition = 'tl'
    }
    // const datepickerPosition = 'tl'

    this.datePicker = Datepicker(this.datePickerInput, {
      dateSelected,
      showAllDates: true,
      formatter: (input: any, date: Date) => {
        if (!date) {
          return this.setState({ value: '' })
        }
        const outputValue = this.formatDateToHumanReadable(date, true)
        this.setState({ value: outputValue })
      },
      onSelect: (instance: any, date: Date) => {
        if (date === undefined) {
          this.resetAll()
        } else {
          const outputValue = this.formatDateToHumanReadable(date, true)
          this.setState({ value: outputValue })
          this.onDateUpdate(date)
        }
      },
      onHide: () => {
        this.closeDatePicker()
        if (this.props.label) {
          this.setState({ showLabel: true })
        }
      },
      customDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      position: datepickerPosition
    })
    this.datePicker.show()
    return this.datePicker
  }

  closeDatePicker = () => {
    if (this.datePicker) {
      if (this.getDateByType(this.props.date) !== this.state.value) {
        this.onDateUpdate(this.formatDateFromHumanReadable(this.state.value))
      }
      this.datePicker.remove()
      this.datePicker = null
    }
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      this.closeDatePicker()
    }
    if (e.key === 'Enter') {
      if (this.datePicker) {
        this.closeDatePicker()
      } else {
        this.initializeDatePicker(this.getConvertedDateValueFromInput())
      }
    }
  }

  resetAll = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation()
    }
    if (this.datePicker) {
      this.datePicker.remove()
      this.datePicker = null
      if (this.props.label) {
        this.setState({ showLabel: true })
      }
    }
    this.setState({ value: '', timeFrom: '', timeTo: '' }, () => this.props.date && this.onDateUpdate(null))
  }

  isDateValid = (date: any) => {
    if (date instanceof Date) {
      if (!isNaN(date.getTime())) {
        return true
      } else {
        return false
      }
    }
    if (!date || date.includes('-')) {
      return false
    }
    if (this.props.small && date && date.length === 5) {
      const currentYear = new Date()
        .getFullYear()
        .toString()
        .substr(-2)
      date = `${date}/${currentYear}`
    }
    if (isNaN(Date.parse(date))) {
      return false
    }
    return true
  }

  isTimeValid = (time: string) => {
    if (!time) {
      return true
    }
    return time.match(/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/)
  }

  getConvertedDateValueFromInput = () => {
    return this.datePickerInput.value && this.isDateValid(this.datePickerInput.value)
      ? this.formatDateFromHumanReadable(this.datePickerInput.value)
      : null
  }

  onDateInputClick = () => {
    if (!this.datePicker) {
      this.initializeDatePicker(this.getConvertedDateValueFromInput())
    }
    this.datePickerInput.focus()
  }

  onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    let datePicker = this.datePicker
    if (!datePicker) {
      datePicker = this.initializeDatePicker()
      if (!this.isDateValid(value)) {
        return
      }
    }
    if (this.isDateValid(value)) {
      const dateToSet = this.formatDateFromHumanReadable(value)
      datePicker.setDate(dateToSet, true)
      this.onDateUpdate(dateToSet)
    } else {
      this.setState({ value })
    }
  }

  onTimeChange = (event: React.ChangeEvent<HTMLInputElement>, timeType: 'timeTo' | 'timeFrom') => {
    const value = event.target.value || ''
    if (timeType === 'timeTo') {
      this.setState({ timeTo: value }, () =>
        this.isTimeValid(value) ? this.onDateUpdate(this.getConvertedDateValueFromInput()) : {}
      )
    }
    if (timeType === 'timeFrom') {
      this.setState({ timeFrom: value }, () =>
        this.isTimeValid(value) ? this.onDateUpdate(this.getConvertedDateValueFromInput()) : {}
      )
    }
  }

  setTimeToDate = (date: Date, time: any): DateISOString => {
    const timeParsed = time && time.match(/([0-9][0-9]):([0-9][0-9])/)
    if (!timeParsed) {
      return convertTimePlusTimezoneOffsetAndAppliedTimezone(
        new Date(date.setHours(0, 0, 0, 0)).getTime()
      ).toISOString()
    }
    return convertTimePlusTimezoneOffsetAndAppliedTimezone(
      date.setHours(parseInt(timeParsed[1], 0), parseInt(timeParsed[2], 0), 0, 0)
    ).toISOString()
    // TODO updated
    // return convertTimePlusTimezoneOffsetAndAppliedTimezone(
    //   date.setHours(parseInt(timeParsed[1], 0), parseInt(timeParsed[2], 0), 0, 1)
    // ).toISOString()
  }

  onDateUpdate = (date: Date) => {
    if (!date) {
      let result = null
      switch (this.props.dateType) {
        case DateTypes.DateISOString:
          result = null
          break
        case DateTypes.DateTimeRangeDTO:
          result = { from: null, to: null }
          break
        case DateTypes.ConfirmedDateTimeRange:
          result = { from: null, to: null, confirmed: null }
          break
        default:
      }
      if (debuggingMode.datepicker) {
        // tslint:disable-next-line:no-console
        console.log('date: ', result)
      }
      return this.props.onUpdate(result)
    }

    if (!this.isDateValid(date)) {
      if (debuggingMode.datepicker) {
        // tslint:disable-next-line:no-console
        console.log(oc(this.props).title('') + ' date in not valid: ', date)
      }
      return
    }

    const callback = () => {
      let { timeFrom } = this.state
      const { timeTo } = this.state

      let result = undefined

      switch (this.props.dateType) {
        case DateTypes.DateISOString:
          result = this.setTimeToDate(date, timeFrom)
          break
        case DateTypes.DateTimeRangeDTO:
        case DateTypes.ConfirmedDateTimeRange: {
          if (!timeFrom && timeTo) {
            timeFrom = timeTo
            this.setState({ timeFrom })
          }
          const dateFrom = this.setTimeToDate(date, timeFrom)
          const dateTo = timeTo ? this.setTimeToDate(date, timeTo) : null
          result = { from: dateFrom, to: dateTo }

          if (this.props.dateType === DateTypes.ConfirmedDateTimeRange) {
            result = { ...result, confirmed: Boolean(result.from || result.to) }
          }
          break
        }
        default:
      }

      if (debuggingMode.datepicker) {
        // tslint:disable-next-line:no-console
        console.log(oc(this.props).title('') + ' date: ', result)
      }

      this.props.onUpdate(result)
    }

    // force set time to handle date select
    if (this.props.setDayStart || this.props.setDayEnd) {
      if (this.props.setDayStart) {
        this.setState({ timeFrom: '00:00' }, () => callback())
      }
      if (this.props.setDayEnd) {
        this.setState({ timeFrom: '23:59' }, () => callback())
      }
    } else {
      callback()
    }
  }

  render() {
    const {
      id,
      placeholder,
      title,
      highlight,
      required,
      style,
      tabIndex,
      labelIcon,
      label,
      disabled,
      textView,
      isTable,
      dateType,
      showTime,
      redColor
    } = this.props
    const { value, timeFrom, timeTo, showLabel } = this.state
    const maskMap = this.props.small ? '99/99' : '99/99/99'
    let isDateTimeRange = false

    switch (dateType) {
      case DateTypes.DateISOString:
        isDateTimeRange = false
        break
      case DateTypes.DateTimeRangeDTO:
      case DateTypes.ConfirmedDateTimeRange:
        isDateTimeRange = true
        break
      default:
    }

    return (
      <div
        id={id}
        className={'date-picker-container'}
        style={{
          ...style,
          position: 'relative',
          borderTop: showLabel ? `solid 1px ${theme.colors.basicBorderColor}` : '',
          width: '100%'
        }}
        tabIndex={tabIndex}
      >
        {showLabel ? (
          <LabelWithIcon onClick={() => this.setState({ showLabel: false })}>
            {labelIcon}
            {label}
          </LabelWithIcon>
        ) : (
          <>
            {title ? (
              <Title className={'title' + (disabled ? ' disabled' : '')} isRequired={required}>
                {title}
              </Title>
            ) : null}
            <StyledDateField
              disabled={textView || disabled}
              background={this.props.date ? textView && 'none' : ''}
              className={cn('date-field', {
                highlight: (required && !value && !this.isDateValid(value)) || highlight
              })}
            >
              {disabled ? (
                <div style={{ marginLeft: isTable ? '11px' : '5px' }}>
                  {value} {showTime && timeFrom ? timeFrom : ''} {showTime && timeTo ? timeTo : ''}
                </div>
              ) : (
                <>
                  <ReactInputMask
                    className={!this.isDateValid(value) || redColor ? 'error' : ''}
                    type="text"
                    inputRef={(input: any) => {
                      this.datePickerInput = input
                    }}
                    alwaysShowMask={false}
                    mask={maskMap}
                    placeholder={!disabled && !value ? placeholder : ''}
                    maskChar="-"
                    value={value}
                    style={cssMaskedDateInput}
                    onChange={this.onDateChange}
                    onClick={this.onDateInputClick}
                    onKeyDown={this.onKeyDown}
                    onFocus={this.onDateInputClick}
                  />
                  {this.isDateValid(value) && !!showTime && (
                    <TimeWrapper>
                      <ReactInputMask
                        className={!this.isTimeValid(timeFrom) || redColor ? 'error' : ''}
                        type="text"
                        alwaysShowMask={false}
                        mask={'99:99'}
                        placeholder={'00:00'}
                        maskChar="-"
                        value={timeFrom}
                        style={cssMaskedTimeInput}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTimeChange(e, 'timeFrom')}
                      />
                      {isDateTimeRange && (
                        <>
                          <span> - </span>
                          <ReactInputMask
                            className={!this.isTimeValid(timeTo) || redColor ? 'error' : ''}
                            type="text"
                            alwaysShowMask={false}
                            mask={'99:99'}
                            placeholder={'00:00'}
                            maskChar="-"
                            value={timeTo}
                            style={cssMaskedTimeInput}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTimeChange(e, 'timeTo')}
                          />
                        </>
                      )}
                    </TimeWrapper>
                  )}
                </>
              )}
              {!disabled && (value || (showTime && (timeTo || timeFrom))) ? (
                <ResetDate onClick={this.resetAll} className="mdi mdi-close-circle b-reset-all" />
              ) : null}
            </StyledDateField>
          </>
        )}
      </div>
    )
  }
}

export default DateField
