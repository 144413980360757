export enum Special {
  Unidentified = 'Unidentified'
}

export enum Modifier {
  Alt = 'Alt',
  AltGraph = 'AltGraph',
  CapsLock = 'CapsLock',
  Control = 'Control',
  Fn = 'Fn',
  FnLock = 'FnLock',
  Meta = 'Meta',
  NumLock = 'NumLock',
  ScrollLock = 'ScrollLock',
  Shift = 'Shift',
  Symbol = 'Symbol',
  SymbolLock = 'SymbolLock',
  Hyper = 'Hyper',
  Super = 'Super'
}

export enum Whitespace {
  Enter = 'Enter',
  Tab = 'Tab',
  Spacebar = 'Spacebar',
  Escape = 'Escape'
}

export enum Navigation {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Down = 'Down',
  Left = 'Left',
  Right = 'Right',
  Up = 'Up',
  End = 'End',
  Home = 'Home',
  PageDown = 'PageDown',
  PageUp = 'PageUp'
}

export enum Editing {
  Backspace = 'Backspace',
  Clear = 'Clear',
  Copy = 'Copy',
  CrSel = 'CrSel',
  Cut = 'Cut',
  Delete = 'Delete',
  EraseEof = 'EraseEof',
  ExSel = 'ExSel',
  Insert = 'Insert',
  Paste = 'Paste',
  Redo = 'Redo',
  Undo = 'Undo'
}

export enum UI {
  Accept = 'Accept',
  Again = 'Again',
  Attn = 'Attn',
  Cancel = 'Cancel',
  ContextMenu = 'ContextMenu',
  Escape = 'Escape',
  Execute = 'Execute',
  Find = 'Find',
  Help = 'Help',
  Pause = 'Pause',
  Play = 'Play',
  Props = 'Props',
  Select = 'Select',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut'
}

export enum Device {
  BrightnessDown = 'BrightnessDown',
  BrightnessUp = 'BrightnessUp',
  Eject = 'Eject',
  LogOff = 'LogOff',
  Power = 'Power',
  PowerOff = 'PowerOff',
  PrintScreen = 'PrintScreen',
  Hibernate = 'Hibernate',
  Standby = 'Standby',
  WakeUp = 'WakeUp'
}

export enum IMEAndComposition {
  AllCandidates = 'AllCandidates',
  Alphanumeric = 'Alphanumeric',
  CodeInput = 'CodeInput',
  Compose = 'Compose',
  Convert = 'Convert',
  Dead = 'Dead',
  FinalMode = 'FinalMode',
  GroupFirst = 'GroupFirst',
  GroupLast = 'GroupLast',
  GroupNext = 'GroupNext',
  GroupPrevious = 'GroupPrevious',
  ModeChange = 'ModeChange',
  NextCandidate = 'NextCandidate',
  NonConvert = 'NonConvert',
  PreviousCandidate = 'PreviousCandidate',
  Process = 'Process',
  SingleCandidate = 'SingleCandidate',
  HangulMode = 'HangulMode',
  HanjaMode = 'HanjaMode',
  JunjaMode = 'JunjaMode',
  Eisu = 'Eisu',
  Hankaku = 'Hankaku',
  Hiragana = 'Hiragana',
  HiraganaKatakana = 'HiraganaKatakana',
  KanaMode = 'KanaMode',
  KanjiMode = 'KanjiMode',
  Katakana = 'Katakana',
  Romaji = 'Romaji',
  Zenkaku = 'Zenkaku',
  ZenkakuHankaku = 'ZenkakuHankaku'
}

export enum GeneralPurposeFunction {
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
  Soft1 = 'Soft1',
  Soft2 = 'Soft2',
  Soft3 = 'Soft3',
  Soft4 = 'Soft4'
}

export enum Multimedia {
  ChannelDown = 'ChannelDown',
  ChannelUp = 'ChannelUp',
  Close = 'Close',
  MailForward = 'MailForward',
  MailReply = 'MailReply',
  MailSend = 'MailSend',
  MediaClose = 'MediaClose',
  MediaFastForward = 'MediaFastForward',
  MediaPause = 'MediaPause',
  MediaPlay = 'MediaPlay',
  MediaPlayPause = 'MediaPlayPause',
  MediaRecord = 'MediaRecord',
  MediaRewind = 'MediaRewind',
  MediaStop = 'MediaStop',
  MediaTrackNext = 'MediaTrackNext',
  MediaTrackPrevious = 'MediaTrackPrevious',
  New = 'New',
  Open = 'Open',
  Print = 'Print',
  Save = 'Save',
  SpellCheck = 'SpellCheck'
}

export enum MultimediaNumpad {
  Key11 = 'Key11',
  Key12 = 'Key12'
}

export enum Audio {
  AudioBalanceLeft = 'AudioBalanceLeft',
  AudioBalanceRight = 'AudioBalanceRight',
  AudioBassBoostDown = 'AudioBassBoostDown',
  AudioBassBoostToggle = 'AudioBassBoostToggle',
  AudioBassBoostUp = 'AudioBassBoostUp',
  AudioFaderFront = 'AudioFaderFront',
  AudioFaderRear = 'AudioFaderRear',
  AudioSurroundModeNext = 'AudioSurroundModeNext',
  AudioTrebleDown = 'AudioTrebleDown',
  AudioTrebleUp = 'AudioTrebleUp',
  AudioVolumeDown = 'AudioVolumeDown',
  AudioVolumeUp = 'AudioVolumeUp',
  AudioVolumeMute = 'AudioVolumeMute',
  MicrophoneToggle = 'MicrophoneToggle',
  MicrophoneVolumeDown = 'MicrophoneVolumeDown',
  MicrophoneVolumeUp = 'MicrophoneVolumeUp',
  MicrophoneVolumeMute = 'MicrophoneVolumeMute'
}

export enum Speech {
  SpeechCorrectionList = 'SpeechCorrectionList',
  SpeechInputToggle = 'SpeechInputToggle'
}

export enum Application {
  LaunchApplication1 = 'LaunchApplication1',
  LaunchApplication2 = 'LaunchApplication2',
  LaunchCalendar = 'LaunchCalendar',
  LaunchContacts = 'LaunchContacts',
  LaunchMail = 'LaunchMail',
  LaunchMediaPlayer = 'LaunchMediaPlayer',
  LaunchMusicPlayer = 'LaunchMusicPlayer',
  LaunchPhone = 'LaunchPhone',
  LaunchScreenSaver = 'LaunchScreenSaver',
  LaunchSpreadsheet = 'LaunchSpreadsheet',
  LaunchWebBrowser = 'LaunchWebBrowser',
  LaunchWebCam = 'LaunchWebCam',
  LaunchWordProcessor = 'LaunchWordProcessor'
}

export enum Browser {
  BrowserBack = 'BrowserBack',
  BrowserFavorites = 'BrowserFavorites',
  BrowserForward = 'BrowserForward',
  BrowserHome = 'BrowserHome',
  BrowserRefresh = 'BrowserRefresh',
  BrowserSearch = 'BrowserSearch',
  BrowserStop = 'BrowserStop'
}

export enum MobilePhone {
  AppSwitch = 'AppSwitch',
  Call = 'Call',
  Camera = 'Camera',
  CameraFocus = 'CameraFocus',
  EndCall = 'EndCall',
  GoBack = 'GoBack',
  GoHome = 'GoHome',
  HeadsetHook = 'HeadsetHook',
  LastNumberRedial = 'LastNumberRedial',
  Notification = 'Notification',
  MannerMode = 'MannerMode',
  VoiceDial = 'VoiceDial'
}

export enum TV {
  TV = 'TV',
  TV3DMode = 'TV3DMode',
  TVAntennaCable = 'TVAntennaCable',
  TVAudioDescription = 'TVAudioDescription',
  TVAudioDescriptionMixDown = 'TVAudioDescriptionMixDown',
  TVAudioDescriptionMixUp = 'TVAudioDescriptionMixUp',
  TVContentsMenu = 'TVContentsMenu',
  TVDataService = 'TVDataService',
  TVInput = 'TVInput',
  TVInputComponent1 = 'TVInputComponent1',
  TVInputComponent2 = 'TVInputComponent2',
  TVInputComposite1 = 'TVInputComposite1',
  TVInputComposite2 = 'TVInputComposite2',
  TVInputHDMI1 = 'TVInputHDMI1',
  TVInputHDMI2 = 'TVInputHDMI2',
  TVInputHDMI3 = 'TVInputHDMI3',
  TVInputHDMI4 = 'TVInputHDMI4',
  TVInputVGA1 = 'TVInputVGA1',
  TVMediaContext = 'TVMediaContext',
  TVNetwork = 'TVNetwork',
  TVNumberEntry = 'TVNumberEntry',
  TVPower = 'TVPower',
  TVRadioService = 'TVRadioService',
  TVSatellite = 'TVSatellite',
  TVSatelliteBS = 'TVSatelliteBS',
  TVSatelliteCS = 'TVSatelliteCS',
  TVSatelliteToggle = 'TVSatelliteToggle',
  TVTerrestrialAnalog = 'TVTerrestrialAnalog',
  TVTerrestrialDigital = 'TVTerrestrialDigital',
  TVTimer = 'TVTimer'
}

export enum MediaController {
  AVRInput = 'AVRInput',
  AVRPower = 'AVRPower',
  ColorF0Red = 'ColorF0Red',
  ColorF1Green = 'ColorF1Green',
  ColorF2Yellow = 'ColorF2Yellow',
  ColorF3Blue = 'ColorF3Blue',
  ColorF4Grey = 'ColorF4Grey',
  ColorF5Brown = 'ColorF5Brown',
  ClosedCaptionToggle = 'ClosedCaptionToggle',
  Dimmer = 'Dimmer',
  DisplaySwap = 'DisplaySwap',
  DVR = 'DVR',
  Exit = 'Exit',
  FavoriteClear0 = 'FavoriteClear0',
  FavoriteClear1 = 'FavoriteClear1',
  FavoriteClear2 = 'FavoriteClear2',
  FavoriteClear3 = 'FavoriteClear3',
  FavoriteRecall0 = 'FavoriteRecall0',
  FavoriteRecall1 = 'FavoriteRecall1',
  FavoriteRecall2 = 'FavoriteRecall2',
  FavoriteRecall3 = 'FavoriteRecall3',
  FavoriteStore0 = 'FavoriteStore0',
  FavoriteStore1 = 'FavoriteStore1',
  FavoriteStore2 = 'FavoriteStore2',
  FavoriteStore3 = 'FavoriteStore3',
  Guide = 'Guide',
  GuideNextDay = 'GuideNextDay',
  GuidePreviousDay = 'GuidePreviousDay',
  Info = 'Info',
  InstantReplay = 'InstantReplay',
  Link = 'Link',
  ListProgram = 'ListProgram',
  LiveContent = 'LiveContent',
  Lock = 'Lock',
  MediaApps = 'MediaApps',
  MediaAudioTrack = 'MediaAudioTrack',
  MediaLast = 'MediaLast',
  MediaSkipBackward = 'MediaSkipBackward',
  MediaSkipForward = 'MediaSkipForward',
  MediaStepBackward = 'MediaStepBackward',
  MediaStepForward = 'MediaStepForward',
  MediaTopMenu = 'MediaTopMenu',
  NavigateIn = 'NavigateIn',
  NavigateNext = 'NavigateNext',
  NavigateOut = 'NavigateOut',
  NavigatePrevious = 'NavigatePrevious',
  NextFavoriteChannel = 'NextFavoriteChannel',
  NextUserProfile = 'NextUserProfile',
  OnDemand = 'OnDemand',
  Pairing = 'Pairing',
  PinPDown = 'PinPDown',
  PinPMove = 'PinPMove',
  PinPToggle = 'PinPToggle',
  PinPUp = 'PinPUp',
  PlaySpeedDown = 'PlaySpeedDown',
  PlaySpeedReset = 'PlaySpeedReset',
  PlaySpeedUp = 'PlaySpeedUp',
  RandomToggle = 'RandomToggle',
  RcLowBattery = 'RcLowBattery',
  RecordSpeedNext = 'RecordSpeedNext',
  RfBypass = 'RfBypass',
  ScanChannelsToggle = 'ScanChannelsToggle',
  ScreenModeNext = 'ScreenModeNext',
  Settings = 'Settings',
  SplitScreenToggle = 'SplitScreenToggle',
  STBInput = 'STBInput',
  STBPower = 'STBPower',
  Subtitle = 'Subtitle',
  Teletext = 'Teletext',
  VideoModeNext = 'VideoModeNext',
  Wink = 'Wink',
  ZoomToggle = 'ZoomToggle',
  AudioVolumeDown = 'AudioVolumeDown',
  AudioVolumeUp = 'AudioVolumeUp',
  AudioVolumeMute = 'AudioVolumeMute',
  BrowserBack = 'BrowserBack',
  BrowserForward = 'BrowserForward',
  ChannelDown = 'ChannelDown',
  ChannelUp = 'ChannelUp',
  ContextMenu = 'ContextMenu',
  Eject = 'Eject',
  End = 'End',
  Enter = 'Enter',
  Home = 'Home',
  MediaFastForward = 'MediaFastForward',
  MediaPlay = 'MediaPlay',
  MediaPlayPause = 'MediaPlayPause',
  MediaRecord = 'MediaRecord',
  MediaRewind = 'MediaRewind',
  MediaStop = 'MediaStop',
  MediaNextTrack = 'MediaNextTrack',
  MediaPause = 'MediaPause',
  MediaPreviousTrack = 'MediaPreviousTrack',
  Power = 'Power',
  Unidentified = 'Unidentified'
}

type All =
  | ' '
  | Special
  | Modifier
  | Whitespace
  | Navigation
  | Editing
  | UI
  | Device
  | IMEAndComposition
  | GeneralPurposeFunction
  | Multimedia
  | MultimediaNumpad
  | Audio
  | Speech
  | Application
  | Browser
  | MobilePhone
  | TV
  | MediaController

export { All as default }
