import * as React from 'react'
import { ContactsTab } from '../../../../contact/views/ContactsTab'
import { useGridItemCustomer } from '../../../../../../hooks/useGridItemCustomer'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

type Props = {}

export const Contacts = (props: Props) => {
  const { businessPartner } = useGridItemCustomer()

  return <ContactsTab parentEntity={businessPartner} entityType={EntityType.businessPartner} />
}
