export enum DriverColumn {
  EmployeeNumber = 'Employee #',
  ActiveAvailability = 'Active / Availability',
  Name = 'Name',
  OperationalPreference = 'Operational Preference',
  Vendor = 'Vendor',
  DateOfBirth = 'Date of Birth',
  MailingAddress = 'Mailing Address',
  PhoneNumber = 'Phone Number',
  Email = 'Email',
  MobileApp = 'Mobile App',
  MilageRestrictions = 'Milage Restrictions',
  OwnedTruck = 'Owned Truck',
  DriverType = 'Driver Type',
  ELD = 'ELD',
  LicenseNumber = 'License Number',
  LicenseExpDate = 'License Exp. Date',
  LicenseIssueDate = 'License Issue Date',
  LicenseIssueState = 'License Issue State',
  LicenseAddress = 'License Address',
  Endorsements = 'Endorsements',
  EndorsementsExpDate = 'Endorsements Exp. Date',
  MedExamExpDate = 'Med. Exam. Exp. Date',
  TWICExpDate = 'TWIC Exp. Date',
  PortCredentialsExpDate = 'Port Credentials Exp. Date'
}

export const driverColumnSettings = {
  [DriverColumn.EmployeeNumber]: { width: 110, active: true },
  [DriverColumn.ActiveAvailability]: { width: 260, active: true },
  [DriverColumn.Name]: { width: 160, active: true },
  [DriverColumn.Vendor]: { width: 200, minWidth: 200, active: true },
  [DriverColumn.MobileApp]: { width: 80, minWidth: 80, active: true },
  [DriverColumn.OperationalPreference]: { width: 160, active: true },
  [DriverColumn.DateOfBirth]: { width: 110, active: true },
  [DriverColumn.MailingAddress]: { width: 260, active: true },
  [DriverColumn.PhoneNumber]: { width: 120, active: true },
  [DriverColumn.Email]: { width: 200, active: true },
  [DriverColumn.MilageRestrictions]: { width: 200, active: true },
  [DriverColumn.OwnedTruck]: { width: 160, active: true },
  [DriverColumn.DriverType]: { width: 160, active: true },
  [DriverColumn.ELD]: { width: 80, active: true },
  [DriverColumn.LicenseNumber]: { width: 200, active: true },
  [DriverColumn.LicenseExpDate]: { width: 140, active: true },
  [DriverColumn.LicenseIssueDate]: { width: 140, active: true },
  [DriverColumn.LicenseIssueState]: { width: 160, active: true },
  [DriverColumn.LicenseAddress]: { width: 260, active: true },
  [DriverColumn.Endorsements]: { width: 200, active: true },
  [DriverColumn.EndorsementsExpDate]: { width: 170, active: true },
  [DriverColumn.MedExamExpDate]: { width: 160, active: true },
  [DriverColumn.TWICExpDate]: { width: 120, active: true },
  [DriverColumn.PortCredentialsExpDate]: { width: 170, active: true }
}
