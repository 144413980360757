export enum DispatchDeliveryOrderColumn {
  Notifications = 'Notifications',
  DOCreated = 'DO created',
  DDONumber = 'DDO #',
  DONumber = 'DO #',
  Status = 'Status',
  Type = 'Type',
  Equipment = 'Equipment',
  Container = 'Container',
  SSL = 'SSL',
  LoadType = 'Load Type',
  BookingBL = 'Booking / BL',
  Customer = 'Customer',
  Chassis = 'Chassis',
  Pickup = 'Pickup',
  PickupDriver = 'Pickup: Driver',
  Delivery = 'Delivery',
  DeliveryDriver = 'Delivery: Driver',
  DeliveryActivity = 'Delivery: Activity',
  ActivityPlanned = 'Activity Planned',
  ActivityCompletion = 'Activity Completion',
  PickUpPlanned = 'Pick-up Planned',
  Appointment = 'Appointment',
  ReturnPlanned = 'Return Planned',
  ApptDateStatus = 'Appt Date Status',
  Return = 'Return',
  ReturnDriver = 'Return: Driver',
  DaysOut = 'Days Out',
  CutOffLFD = 'CutOff / LFD',
  LFD = 'LFD',
  FirstReceiving = 'First Receiving',
  ETA = 'ETA',
  Availability = 'Availability',
  PerDiemFreeBy = 'Per Diem Free by'
}

export const dispatchDeliveryOrderColumnSettings = {
  [DispatchDeliveryOrderColumn.Notifications]: { active: true, width: 100, minWidth: 100 },
  [DispatchDeliveryOrderColumn.DOCreated]: { active: true, width: 120 },
  [DispatchDeliveryOrderColumn.DDONumber]: { active: true, width: 80 },
  [DispatchDeliveryOrderColumn.DONumber]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Status]: { active: true, width: 245, minWidth: 245 },
  [DispatchDeliveryOrderColumn.Type]: { active: true, width: 100 },
  [DispatchDeliveryOrderColumn.Equipment]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Container]: { active: true, width: 140, minWidth: 140 },
  [DispatchDeliveryOrderColumn.SSL]: { active: true, width: 140, minWidth: 140 },
  [DispatchDeliveryOrderColumn.LoadType]: { active: true, width: 90, minWidth: 90 },
  [DispatchDeliveryOrderColumn.BookingBL]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Customer]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.Chassis]: { active: true, width: 150 },
  [DispatchDeliveryOrderColumn.Pickup]: { active: true, width: 130 },
  [DispatchDeliveryOrderColumn.PickupDriver]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.PickUpPlanned]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.Delivery]: { active: true, width: 300 },
  [DispatchDeliveryOrderColumn.DeliveryDriver]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.DeliveryActivity]: { active: true, width: 190 },
  [DispatchDeliveryOrderColumn.ActivityPlanned]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.ActivityCompletion]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.Appointment]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.ApptDateStatus]: { active: true, width: 120, minWidth: 120 },
  [DispatchDeliveryOrderColumn.Return]: { active: true, width: 130 },
  [DispatchDeliveryOrderColumn.ReturnDriver]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.ReturnPlanned]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.DaysOut]: { active: true, width: 95, minWidth: 95 },
  [DispatchDeliveryOrderColumn.FirstReceiving]: { active: true, width: 250 },
  [DispatchDeliveryOrderColumn.CutOffLFD]: { active: true, width: 160, headerWidth: 320 },
  [DispatchDeliveryOrderColumn.LFD]: { active: true, width: 160, headerWidth: 0 },
  [DispatchDeliveryOrderColumn.ETA]: { active: true, width: 250 },
  [DispatchDeliveryOrderColumn.Availability]: { active: false, width: 250 },
  [DispatchDeliveryOrderColumn.PerDiemFreeBy]: { active: false, width: 250 }
}
