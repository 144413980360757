import { oc } from 'ts-optchain'
import { DriverViewDTO } from '../../api/api'
import { DeductionDTO, VendorDTO } from '../../api/origin/vendor-accounting-service'
import { requestUpdateDriver } from '../../components/common/drivers/epics'
import { deleteDeductionById, requestDriverDeductions, updateDeduction } from '../DTO/deduction/fetch'
import { requestVendorAccountingUpdate } from '../DTO/vendorAccounting/fetch'
import { sortDeductions } from '../functions/sort/sortDeductions'
import { encrypt } from '../Crypto/encrypt'
import { getActiveApplicationTabState, getApplicationTabsState, getDispatch } from '../../store'
import { IDriverAccountingTabData } from '../../store/reducers/tabs/interfaces'
import { tabActions } from '../../store/reducers/tabs'

type Props = {
  driver: DriverViewDTO
  vendorAccounting: VendorDTO
  deductions: DeductionDTO[]
  deleteDeductionIds: string[]
  onVendorAccountingSaveComplete?: (id: string) => void
  onDeductionSaveComplete?: (id: string) => void
}

export const saveDriver = async (props: Props): Promise<DriverViewDTO> => {
  const {
    driver,
    vendorAccounting,
    deductions,
    deleteDeductionIds,
    onDeductionSaveComplete,
    onVendorAccountingSaveComplete
  } = props
  const hasDeductionUpdates = Boolean(
    (deductions && deductions.length) || (deleteDeductionIds && deleteDeductionIds.length)
  )
  let savedDriver: DriverViewDTO = undefined
  let savedVendorAccounting: VendorDTO = undefined

  const tabId = getActiveApplicationTabState().id
  const updateDataProp = updateDataPropByTabId(tabId)

  if (vendorAccounting) {
    savedVendorAccounting = await requestVendorAccountingUpdate(vendorAccounting)

    if (savedVendorAccounting && onVendorAccountingSaveComplete) {
      onVendorAccountingSaveComplete(vendorAccounting.id)
    }
  }

  if (deleteDeductionIds && deleteDeductionIds.length) {
    await Promise.all(deleteDeductionIds.map(deleteDeductionById))
  }

  if (deductions && deductions.length) {
    for (const updatedDeduction of sortDeductions(deductions)) {
      await updateDeduction(updatedDeduction).then(deduction => {
        if (onDeductionSaveComplete && deduction) {
          onDeductionSaveComplete(updatedDeduction.id)
        }
      })
    }
  }

  if (hasDeductionUpdates) {
    await requestDriverDeductions(driver.id).then(requestedDeductions => {
      updateDataProp('deductionIds')(requestedDeductions.map(item => item.id))
    })
  }

  if (driver) {
    const isSSNUpdated = Boolean(driver.ssn)
    let driverToSave = driver

    if (isSSNUpdated) {
      const encryptedSSN = await encrypt(driverToSave.ssn)

      if (encryptedSSN) {
        driverToSave = { ...driverToSave, ssn: encryptedSSN }
      }
    }

    savedDriver = await requestUpdateDriver(driverToSave)
  }

  return savedDriver
}

const updateDataPropByTabId = (tabId: string) => (prop: keyof IDriverAccountingTabData) => (value: any) => {
  const dispatch = getDispatch()
  const currentTab = getApplicationTabsState().find(tab => tab.id === tabId)
  const driverAccountingTabData = oc(currentTab).expandedItem.data.driverAccountingTabData()

  if (!driverAccountingTabData) {
    return
  }

  dispatch(
    tabActions.mergeExpandedItemData({
      tabId,
      mergeProps: { driverAccountingTabData: { ...driverAccountingTabData, [prop]: value } }
    })
  )
}
