export enum DriverActivenessStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE'
}

export interface IDriverActivityDTO {
  id: string
  status: DriverActivenessStatus
  lastActiveDate: string
  updatedAt?: string | number
}

export interface ICalculatedDriverActivity {
  inactive: boolean
  showOffline: boolean
  showOnline: boolean
  showLastActiveDate: string | undefined
  showGeneratedLastActiveDate: string | undefined
}
