import { oc } from 'ts-optchain'
import { AddressDTO } from '../../../api/api'
import { schemaAddress } from '../../yupScheme'

export const isAddressValid = (address: AddressDTO) => {
  const context = {
    isStreetFilled: Boolean(oc(address).street())
  }

  return schemaAddress.isValidSync(address, { context })
}
