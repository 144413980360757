import * as React from 'react'
import { Container, SpinnerContainer, Info } from './styledComponents'
import { GridSpinner } from '../../../components/UI/Spinner/Spinner'

export const Authorizing = () => (
  <Container>
    <SpinnerContainer>
      <GridSpinner style={{ background: 'none' }} />
    </SpinnerContainer>
    <Info style={{ textAlign: 'center' }}>Authenticating...</Info>
  </Container>
)
