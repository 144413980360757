import * as R from 'remeda'
import { ITab } from '../../store/reducers/tabs/interfaces'

export enum SessionStorage {
  tabs = 'tabs',
  tabsSettings = 'tabs-settings',
  viewingObjects = 'viewing-objects',
  tabScrollPosition = 'tab-scroll-position'
}

// TABS
const tabs = {
  clear: () => sessionStorage.removeItem(SessionStorage.tabs),
  set: (_tabs: ITab[]) => sessionStorage.setItem(SessionStorage.tabs, JSON.stringify(_tabs)),
  get: (): ITab[] => JSON.parse(sessionStorage.getItem(SessionStorage.tabs))
}

// TAB SCROLL POSITION
type TScrollPosition = { top: number; left: number }
type TTabScrollPosition = {
  [tabId: string]: TScrollPosition[]
}

const setTabScrollPosition = (settings: TTabScrollPosition) =>
  sessionStorage.setItem(SessionStorage.tabScrollPosition, JSON.stringify(settings))

const getTabScrollPosition = () => JSON.parse(sessionStorage.getItem(SessionStorage.tabScrollPosition))

const tabScrollPosition = {
  clear: () => sessionStorage.removeItem(SessionStorage.tabScrollPosition),
  set: setTabScrollPosition,
  get: getTabScrollPosition,
  removeTabPosition: (tabId: string) => {
    const storage = getTabScrollPosition()
    if (storage) {
      setTabScrollPosition(R.omit(storage, [tabId]))
    }
  },
  setTabPosition: (tabId: string, position: TScrollPosition) =>
    setTabScrollPosition({ ...(getTabScrollPosition() || {}), [tabId]: position }),
  getTabPosition: (tabId: string): TScrollPosition => {
    const storage = getTabScrollPosition()
    return (storage && storage[tabId]) || { top: 0, left: 0 }
  }
}

export const tabSessionStorage = {
  tabs,
  scrollPosition: tabScrollPosition,
  clear: () => {
    tabScrollPosition.clear()
    tabs.clear()
  }
}

export const catchListErrors = (errors: any): any[] => {
  if (errors && errors.json) {
    errors.json()
  }

  return []
}
