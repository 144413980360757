import { v4 } from 'uuid'
import { omit } from 'remeda'
import { oc } from 'ts-optchain'

export const createId = (addToString: string = '') => addToString + 'fake_' + v4()

type NewItem = { id?: string }

export const isNewItem = (item: NewItem): boolean => {
  const id = oc(item).id('')

  if (!id) {
    return false
  }

  return id.includes('fake_')
}
export const isNotNewItem = (item: NewItem): boolean => !isNewItem(item)
export const deleteId = <T extends NewItem>(item: T) => omit(item, ['id'])
