import * as React from 'react'
import { documentServiceControllerApi } from '../../../../../../api/document-service'
import { allowActivitiesModifying } from '../../../../../../services/DTO/activity'
import { Button, Wrapper } from './styles'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { catchErrorMessages, DispatchDeliveryOrderViewDTO } from '../../../../../../api/api'
import { showWarningModalWindow } from '../../../../../../store/reducers/modalWindow/functions'

export const DownloadWorkOrder = React.memo(() => {
  const { setFetching, parentItem } = useExpandedItem()
  const dispatchDeliveryOrder = parentItem as DispatchDeliveryOrderViewDTO

  return (
    allowActivitiesModifying(parentItem) && (
      <Wrapper>
        <Button
          onClick={() => {
            setFetching(true)

            documentServiceControllerApi
              .generateWorkOrderUrl(dispatchDeliveryOrder.id)
              .then(({ url, name }) => {
                // this allow to fast get file from same tab
                // window.location.assign(url)

                // Fetch from google cloud need CORS, we can setup it or use some hacks.
                // Hack on the top reassign download url to same browser tab.
                // You'll have warning at console but it didn't affect anything.
                // Below you'll find a call when CORS is properly setup.
                downloadWorkOrderFile({ name, url })
              })
              .catch(err => {
                // catch empty doc, we use 422 for that
                if (err.status === 422) {
                  return showWarningModalWindow({
                    title: 'Please add driver to any Activity and save DDO to get Work Order'
                  })
                }

                catchErrorMessages(err)
                // tslint:disable-next-line:no-console
                console.error(err)
              })
              .finally(() => setFetching(false))
          }}
        >
          <i className={'mdi mdi-download'} />
          Download Work Order
        </Button>
      </Wrapper>
    )
  )
})

export const downloadWorkOrderFile = (props: { name?: string; url: string }) => {
  const { name = 'file', url } = props

  fetch(url).then(t => {
    return t.blob().then(b => {
      const link = document.createElement('a')
      link.target = '_blank'
      link.style.display = 'none'
      link.download = name
      link.href = URL.createObjectURL(b)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  })
}
