import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { dateService } from '../../../../services/timeService'
import { openFileLink } from '../../../../services/filesService'
import { StyledFileUploaderButton } from './styles'
import { FileVersionDTO } from '../../../../api/origin/document-service'

type Props = {
  fileHistory: FileVersionDTO[]
}

export const FileHistory = (props: Props) => {
  const { fileHistory } = props

  return (
    <Container>
      <Grid>
        <Header>Date</Header>
        <Header>Document</Header>
        {fileHistory
          .sort((file1, file2) => Date.parse(file2.createdAt) - Date.parse(file1.createdAt))
          .map(fileVersion => {
            const file = oc(fileVersion).data()

            if (file) {
              return (
                <React.Fragment key={file.id}>
                  <DateColumn>
                    {dateService
                      .makeLabel(fileVersion.createdAt, { shortSpace: true })
                      .split(' ')
                      .map((_, i) => (
                        <span key={i} style={i === 1 ? { marginLeft: 'auto' } : undefined}>
                          {_}
                        </span>
                      ))}
                  </DateColumn>
                  <FileColumn>
                    <StyledFileUploaderButton
                      style={{ width: 70 }}
                      className={'mdi mdi-eye'}
                      onClick={() => {
                        if (file.url) {
                          openFileLink(file.url)
                        }
                      }}
                    >
                      View
                    </StyledFileUploaderButton>
                  </FileColumn>
                </React.Fragment>
              )
            }
          })}
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  min-height: 200px;
  position: relative;
  margin-top: -10px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 20px;
`

const Header = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  color: #6c707d;
  font-size: 12px;
  user-select: none;

  &:first-child {
    position: relative;
    padding-left: 4px;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #e5e5e5;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -10px;
    }
  }
`

const DateColumn = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  color: #6c707d;
  font-size: 14px;
  border-radius: 4px;
  background: rgba(239, 240, 242, 0.8);
  padding: 8px;
`

const FileColumn = styled.div``
