import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  ddoIds: string[]
  modifiedLists: Partial<TListsState>
}

export const filterModifiedDDOs = (props: Props): DispatchDeliveryOrderViewDTO[] => {
  const { ddoIds, modifiedLists } = props
  let updatedDDOs: DispatchDeliveryOrderViewDTO[] = []

  if (modifiedLists[EntityType.dispatchDeliveryOrder] && ddoIds) {
    // @ts-ignore
    updatedDDOs = ddoIds.map(id => modifiedLists[EntityType.dispatchDeliveryOrder][id]).filter(Boolean)
  }

  return updatedDDOs
}
