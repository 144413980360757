import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useAppSelector } from './useAppSelector'
import { selectListItem } from '../store/select/listItemSelect'

type Props = {
  id: string
  type: EntityType
  modifiedLists?: Partial<TListsState>
}

export const useListItem = (props: Props) => {
  const { id, type, modifiedLists } = props
  const storeItem = useAppSelector(selectListItem({ id, type }))
  const modifiedItem = oc(modifiedLists)[type][id]()
  const item = modifiedItem || storeItem

  return item
}
