import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { convertFromRaw, EditorState } from 'draft-js'
import { ReactInput } from '../Editor/ReactInput'
import { sendChannelMessage } from '../../functions/sendChannelMessage'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { useCommunicationHubDraftMessage } from '../../../../hooks/useCommunicationHubDraftMessage'
import { convertMentionsToEntityMap } from '../../functions/mentions'
import { IMessage } from '../../../../store/reducers/communicationHub/interfaces'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { IAttachment } from '../../../../services/filesService/interfaces'

type OwnProps = {
  message: IMessage
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const MessageEditor = React.memo((props: Props) => {
  const { message } = props
  const dispatch = useAppDispatch()
  const { editMessage, setEditMessageState, setEditMessageEditor } = useCommunicationHubDraftMessage()
  const messageEditorState = React.useMemo(
    () =>
      message.text
        ? EditorState.createWithContent(
            // @ts-ignore
            convertFromRaw(convertMentionsToEntityMap(message.text, message.mentionUserIds))
          )
        : EditorState.createEmpty(),
    []
  )

  React.useEffect(() => {
    if (!editMessage || editMessage.messageId !== message.id) {
      setEditMessageState({
        state: {
          messageId: message.id,
          editor: messageEditorState,
          attachments: message.attachments
        }
      })
    }
  }, [])

  const editorState = React.useMemo(
    () => (oc(editMessage).messageId() === message.id ? oc(editMessage).editor() : undefined),
    [oc(editMessage).editor()]
  )

  const noAttachmentUploading = React.useMemo(() => {
    return oc(editMessage)
      .attachments([])
      .every((a: IAttachment) => a.uploadingProgress === undefined)
  }, [oc(editMessage).attachments()])

  const sendMessage = React.useCallback(
    editorState
      ? () => {
          sendChannelMessage({
            editorState,
            attachments: oc(editMessage).attachments([]),
            prevMessageState: message,
            channelId: message.channelId
          })

          dispatch(communicationHubActions.setEditMessageId(undefined))
          setEditMessageState({ state: undefined })
        }
      : undefined,
    [editMessage, message]
  )

  const cancelMessageEditing = React.useCallback(() => {
    dispatch(communicationHubActions.setEditMessageId(undefined))
    setEditMessageState({ state: undefined })
  }, [])

  const onEditorChange = React.useCallback((editor: EditorState) => setEditMessageEditor({ editor }), [])

  const RenderActions = React.useMemo(
    () => (
      <Actions>
        <ActionButton onClick={cancelMessageEditing} styles={{ marginRight: 10 }} round={true}>
          Cancel
        </ActionButton>
        <ActionButton
          disabled={!noAttachmentUploading}
          filled={true}
          onClick={noAttachmentUploading ? sendMessage : undefined}
          round={true}
        >
          Save
        </ActionButton>
      </Actions>
    ),
    [sendMessage, cancelMessageEditing, noAttachmentUploading]
  )

  return (
    <Container>
      <ReactInput
        focus={true}
        messageId={message.id}
        renderBottomSection={RenderActions}
        editorState={editorState || messageEditorState}
        onEditorChange={onEditorChange}
      />
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  font-size: 14px;
`
const Actions = styled.div`
  display: flex;
  user-select: none;
  justify-content: flex-end;
  padding: 0 15px 15px;
`
