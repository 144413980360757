import styled from 'styled-components'
import theme from '../../../../../styles/theme'

export const ButtonClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  padding: 2px;

  &:not(:hover) {
    opacity: 0.5;
  }
`
export const NotificationStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 5px;

  &.mdi:before {
    font-size: 18px;
    margin-right: 3px;
  }
`
export const NotificationTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
`
export const NotificationDescription = styled.div`
  font-size: 12px;
  line-height: 25px;
  color: #445366;
  margin-top: 4px;
`

export const NotificationContainer = styled.div`
  position: relative;
  width: 370px;
  background: white;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px 8px 8px 12px;
  margin-bottom: -55px;
  transition: margin 0.2s;
  overflow: hidden;

  &:hover {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    width: 4px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &.notification_error {
    &:before {
      background-color: ${theme.colors.defaultRed};
    }

    ${NotificationStatus}, ${NotificationTitle} {
      color: ${theme.colors.defaultRed};
    }
  }

  &.notification_success {
    &:before {
      background-color: ${theme.colors.blue};
    }

    ${NotificationStatus}, ${NotificationTitle} {
      color: ${theme.colors.blue};
    }
  }
`
