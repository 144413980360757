import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'

const StyledPopover = styled.div`
  position: relative;
`
export const StyledDropdownContainer = styled.div`
  z-index: 110;
  position: absolute;
  user-select: none;
  width: 165px;
  padding: 3px 14px 0 14px;
  border-top: solid 2px ${theme.colors.basicBlueColor};
  background: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  font-size: 12px;
  font-weight: 400;
  color: #333;
  transform: translateY(-2px);

  > div:last-child {
    border: 0;
  }

  > ul {
    padding: 0 14px;
    max-height: 175px;
    overflow: auto;
    margin: 0 -14px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow: hidden;
    scrollbar-3dlight-color: rgb(255, 255, 255);
    scrollbar-arrow-color: rgb(255, 255, 255);
    scrollbar-darkshadow-color: rgb(255, 255, 255);
    scrollbar-highlight-color: rgb(255, 255, 255);
    scrollbar-shadow-color: rgb(255, 255, 255);
    scrollbar-track-color: rgb(255, 255, 255);

    > ul {
      margin-right: -25px;
      margin-bottom: -17px;
      overflow: scroll;
    }
  }

  > div,
  li {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    border-bottom: solid 1px ${theme.colors.basicBorderColor};
    cursor: pointer;

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.basicBlueColor};
      font-size: 14px;
      width: 16px;
      height: 16px;
      background: ${theme.colors.basicBackgroundColor};
      border-radius: 5px;
      margin-right: 7px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .title {
    height: 30px;
    font-weight: 300;
    font-size: 10px;
    border-bottom: none;
    cursor: default;
  }
`

type Props = {
  side?: 'left' | 'right'
  isOpen: boolean
  changeState(state: boolean): void
  button?: any
  lifetime?: number
  width?: number | string
  byHover?: boolean
  style?: any
  children?: any
}

export const Popover = (props: Props) => {
  let hoverOffTimer: any
  const { children, button, isOpen, byHover, style, width, changeState, lifetime, side } = props

  // componentDidUpdate() {
  //   if (this.props.isOpen) {
  //     const popover = ReactDom.findDOMNode(this.popover) as HTMLDivElement
  //     const popover_info = popover.getBoundingClientRect()
  //     const windowWidth = document.body.clientWidth
  //
  //     if (popover_info.width + popover_info.left > windowWidth) {
  //       popover.style.right = '0px'
  //     }
  //   }
  // }

  const handleHoverOn = () => {
    if (hoverOffTimer) {
      clearTimeout(hoverOffTimer)
      hoverOffTimer = null
    }
  }

  const handleHoverOff = () => {
    hoverOffTimer = setTimeout(() => changeState(false), lifetime || 500)
  }

  return (
    <StyledPopover style={style} onMouseEnter={handleHoverOn} onMouseLeave={handleHoverOff}>
      {button &&
        (byHover ? (
          <span onMouseEnter={() => changeState(true)}>{button}</span>
        ) : (
          <span onClick={() => changeState(!isOpen)}>{button}</span>
        ))}
      {isOpen && (
        <StyledDropdownContainer style={{ width, ...(side ? { [side]: 0 } : {}) }}>{children}</StyledDropdownContainer>
      )}
    </StyledPopover>
  )
}
