import { RootState } from '..'
import { DeductionDTO } from '../../api/origin/vendor-accounting-service'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectDeduction = (id: string) => (state: RootState): DeductionDTO | undefined => {
  return state.lists[EntityType.deduction][id]
}

// SELECT MANY
export const selectDeductions = (state: RootState): Record<string, DeductionDTO> => {
  return state.lists[EntityType.deduction]
}
