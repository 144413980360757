import { CheckType, DeductionType, Frequency } from '../../../api/origin/vendor-accounting-service'

export const type = {
  [DeductionType.ELD]: 'ELD',
  [DeductionType.ESCROW]: 'Escrow',
  [DeductionType.LOAN]: 'Loan',
  [DeductionType.PARKING]: 'Parking',
  [DeductionType.PDINSURANCE]: 'PD Insurance',
  [DeductionType.TRUCKREGISTRATION]: 'Truck Registration',
  [DeductionType.WCINSURANCE]: 'WC Insurance'
}

export const frequency = {
  [Frequency.DAILY]: 'Daily',
  [Frequency.WEEKLY]: 'Weekly'
}

export const checkType = {
  [CheckType.CHECK]: 'Check',
  [CheckType.ACH]: 'ACH'
}

export const deductionDirectory = {
  type,
  frequency,
  checkType
}
