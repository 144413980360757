import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { stopPropagationEvent } from '../../../../../../../services/functions/basic'

type Props = {
  isFavorite: boolean
  onClick: () => void
  lfd?: boolean
  cutoff?: boolean
}

export const FavoritesButton = (props: Props) => {
  const { isFavorite, onClick, lfd, cutoff } = props

  return (
    <Container
      onClick={event => {
        stopPropagationEvent(event)
        onClick()
      }}
      className={cn('favoritesButton mdi mdi-star', { active: isFavorite })}
    >
      {lfd && <Flag>LFD</Flag>}
      {cutoff && <Flag>Cutoff</Flag>}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #fcfdff;
  outline: 1px solid #dce6f5;
  color: #c9cddd;
  font-size: 18px;
  cursor: pointer;
  user-select: none;

  &:hover,
  &.active {
    background-color: #e3edfd;
    color: #eaae76;
  }
`
const Flag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #eaae76;
  color: white;
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  border-radius: 2px 2px 0 0;
  padding: 2px 0;
  user-select: none;
`
