import * as React from 'react'
import { FileCategory, IAttachment } from '../../../../services/filesService/interfaces'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import { FilesContainer } from '../Attachments/Attachments'
import { stopPropagationEvent } from '../../../../services/functions/basic'
import { File } from '../Attachments/File'
import { axiosCallAPIWithErrorMessage } from '../../../../api/api'
import { fileServiceAPI } from '../../../../services/filesService'
import { generateFileTemplate } from '../../../../services/filesService/generateFileTemplate'
import { FileDTO } from '../../../../api/origin/document-service'
import { useCommunicationHubDraftMessage } from '../../../../hooks/useCommunicationHubDraftMessage'
import { oc } from 'ts-optchain'

type OwnProps = {
  messageId: string
  channelId: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const EditorAttachments = (props: Props) => {
  const { messageId, channelId } = props
  const {
    editMessage,
    draftMessages,
    setDraftMessageAttachment,
    setEditMessageAttachment,
    removeEditMessageAttachmentId,
    finishEditMessageAttachmentUploading,
    removeDraftMessageAttachmentId,
    finishDraftMessageAttachmentUploading
  } = useCommunicationHubDraftMessage()
  const inputRef = React.useRef<HTMLInputElement>(null)

  let attachments: IAttachment[] = undefined

  if (messageId) {
    attachments = oc(editMessage).attachments()
  } else if (channelId) {
    attachments = oc(draftMessages)[channelId].attachments()
  }

  const { setStoreAttachment, removeStoreAttachmentId, finishStoreAttachmentUploading } = React.useMemo(() => {
    return {
      setStoreAttachment: (attachment: IAttachment) =>
        channelId ? setDraftMessageAttachment({ channelId, attachment }) : setEditMessageAttachment({ attachment }),
      removeStoreAttachmentId: (attachmentId: string) =>
        channelId
          ? removeDraftMessageAttachmentId({ channelId, attachmentId })
          : removeEditMessageAttachmentId({ attachmentId }),
      finishStoreAttachmentUploading: (_props: { temporaryAttachmentId: string; attachment: IAttachment }) =>
        channelId
          ? finishDraftMessageAttachmentUploading({ channelId, ..._props })
          : finishEditMessageAttachmentUploading(_props)
    }
  }, [channelId, messageId])

  const onAttachedFilesButtonClick = React.useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return
    }

    inputRef.current.click()
  }, [])

  const clearInputFiles = React.useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return
    }

    inputRef.current.type = '' // It's a hack to reset input
    inputRef.current.type = 'file' // To allow attach same files multiple times
  }, [])

  const onFileInputChange = React.useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return
    }

    const MAX_FILE_SIZE = 1024 * 1024 * 1024
    const files = Array.from(inputRef.current.files)

    // validate files
    const errorMessages: string[] = []
    files.forEach(file => {
      const { size, name } = file

      if (size <= 0) {
        errorMessages.push(`Please send file: "${name}" with size more than 0 bytes`)
      }

      if (size > MAX_FILE_SIZE) {
        errorMessages.push(`Please send file: "${name}" with size less than ${MAX_FILE_SIZE} bytes`)
      }
    })

    if (errorMessages.length) {
      alert(errorMessages.join('. '))
    } else {
      files.forEach(file => {
        const temporaryAttachment = generateFileTemplate(file, {
          category: FileCategory.OTHER,
          uploadingProgress: 0
        }) as IAttachment

        try {
          setStoreAttachment(temporaryAttachment)
          axiosCallAPIWithErrorMessage(fileServiceAPI.uploadFile, {
            file,
            progressCallback: (uploadingProgress: number) => {
              setStoreAttachment({ ...temporaryAttachment, uploadingProgress })
            }
          }).then((res: FileDTO) => {
            finishStoreAttachmentUploading({
              temporaryAttachmentId: temporaryAttachment.id,
              attachment: { ...res, category: temporaryAttachment.category }
            })
          })
        } catch (e) {
          removeStoreAttachmentId(temporaryAttachment.id)
        }
      })
    }

    clearInputFiles()
  }, [])

  return (
    <>
      <Container onClick={stopPropagationEvent}>
        <AttachFilesButton className={'mdi mdi-paperclip attach-files-button'}>
          <input type={'file'} multiple={true} onChange={onFileInputChange} hidden={true} ref={inputRef} />
        </AttachFilesButton>
        {Boolean(attachments && attachments.length) && (
          <FilesContainer className={'files-container'}>
            {attachments.map(attachment => (
              <File key={attachment.id} file={attachment} remove={() => removeStoreAttachmentId(attachment.id)} />
            ))}
          </FilesContainer>
        )}
      </Container>
    </>
  )
}

// export const EditorAttachments = connect(
//   ({ communicationHub }: IStore, { messageId, channelId }: OwnProps) => {
//     let attachments: IAttachment[] = undefined
//
//     if (messageId) {
//       attachments = oc(communicationHub).editMessage.attachments()
//     } else if (channelId) {
//       attachments = oc(communicationHub).draftMessages[channelId].attachments()
//     }
//
//     return {
//       attachments
//     }
//   },
//   {
//     setDraftMessageAttachment,
//     removeDraftMessageAttachmentId,
//     finishDraftMessageAttachmentUploading,
//     setEditMessageAttachment,
//     removeEditMessageAttachmentId,
//     finishEditMessageAttachmentUploading
//   }
// )(React.memo(Component))

const Container = styled.div``
const AttachFilesButton = styled.label`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 30px;
  bottom: 14px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
