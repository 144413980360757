import { ContactDTO } from '../../../api/api'
import { createId } from '../../utils'

export const generateContact = (props?: Partial<ContactDTO>): ContactDTO => {
  return {
    id: createId(),
    email: undefined,
    fax: undefined,
    firstName: undefined,
    lastName: undefined,
    mobile: undefined,
    phone: undefined,
    phone2: undefined,
    notes: undefined,
    primary: false,
    ...(props || {})
  }
}
