import { ContainerViewDTO } from '../../api/api'
import { requestUpdateContainer } from '../../components/common/containers/epics'

type Props = {
  container: ContainerViewDTO
}

export const saveContainer = async (props: Props): Promise<ContainerViewDTO> => {
  const { container } = props
  let savedContainer: ContainerViewDTO = container

  if (container) {
    savedContainer = await requestUpdateContainer(container)
  }

  return savedContainer
}
