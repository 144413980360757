import * as React from 'react'
import {
  ColumnHeader as StyledColumnHeader,
  ColumnHeaderTitle,
  TableHeader,
  TableHeaderColumn
} from '../../../../Grid/styles'
import { columns, columnsSettings } from '../settings'
import { ColumnSize } from '../../../../../interfaces'

type Props = {
  title: string
  columnSize?: ColumnSize
  isNewCustomerQuotes?: boolean
}

export const Header = React.memo((props: Props) => {
  const { title, columnSize, isNewCustomerQuotes } = props

  return (
    <StyledColumnHeader>
      <ColumnHeaderTitle>{title}</ColumnHeaderTitle>
      <TableHeader style={{ paddingRight: 70, paddingLeft: isNewCustomerQuotes ? 30 : undefined }}>
        {columns.map(
          column =>
            (!columnsSettings[column].visibility || columnsSettings[column].visibility.includes(columnSize)) && (
              <TableHeaderColumn key={column} style={columnsSettings[column].styles}>
                {column}
              </TableHeaderColumn>
            )
        )}
      </TableHeader>
    </StyledColumnHeader>
  )
})
