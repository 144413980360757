import * as React from 'react'
import { Filter as RateFilter } from '../../Tabs/Rate/Filter'
import { Filter as RulesFilter } from '../../Tabs/Rules/Filter'
import { Filter as CustomerQuoteFilter } from '../../Tabs/CustomerQuote/Filter'
import { QMPTab, IQMPFilters } from '../../../interfaces'
import { useQMP } from '../../../../../../hooks/useQMP'

type Props = {
  activeTab: QMPTab
  isModifyMode: boolean
  filters: IQMPFilters
}

export const Filter = React.memo((props: Props) => {
  const { activeTab, isModifyMode, filters } = props
  const { setFilters } = useQMP()

  const updateFiltersState = React.useCallback(
    (tabFilters: any) => setFilters({ ...filters, [activeTab]: tabFilters }),
    [filters]
  )

  switch (activeTab) {
    case QMPTab.RATE:
      return <RateFilter filterState={filters[activeTab]} setFiltersState={updateFiltersState} />
    case QMPTab.CUSTOMERQUOTE:
      return (
        <CustomerQuoteFilter
          isModifyMode={isModifyMode}
          filterState={filters[activeTab]}
          setFiltersState={updateFiltersState}
        />
      )
    case QMPTab.RULES:
      return <RulesFilter />
    default:
      return null
  }
})
