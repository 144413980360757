import * as React from 'react'
import { oc } from 'ts-optchain'
import { EditorState, convertToRaw } from 'draft-js'
import { TestNotificationProps, testNotification } from '../services/functions/test/testNotification'
import { isEditorEmpty } from '../components/CommunicationHub/views/Editor/ReactInput'
import { generateNotification } from '../services/functions/generate/generateNotification'
import { useAppDispatch } from './useAppDispatch'
import { communicationHubActions } from '../store/reducers/communicationHub'
import { ApplicationFormDTO, OfficialFormDTO } from '../api/origin/document-service'
import {
  requestApplicationFormPreview,
  requestNotificationCreate,
  requestOfficialFormPreview
} from '../components/CommunicationHub/epics'
import { openFileLink } from '../services/filesService'
import { CreateNotificationDTO, NotificationDTO, NotificationOptionDTO } from '../api/origin/communication-hub-service'
import { useAppSelector } from './useAppSelector'
import { selectNotificationDocumentForms } from '../store/select/notificationSelect'

type DocumentDTO = (ApplicationFormDTO | OfficialFormDTO) & {
  isApplicationForm?: boolean
  isOfficialForm?: boolean
}

export interface IUseCreateNotification {
  fetch: boolean
  errorMessage: string
  availableCreateNotification: boolean
  newNotification: CreateNotificationDTO
  setNotificationType: (type: NotificationDTO.TypeEnum) => void
  setNotificationDriverStatus: (driverStatuses: CreateNotificationDTO.DriverStatusesEnum[]) => void
  setNotificationSubject: (subject: string) => void
  setRichText: (text: EditorState) => void
  setNotificationOptions: (options: NotificationOptionDTO[]) => void
  testNotificationProps: TestNotificationProps
  richText: EditorState
  createNotification: (() => void) | undefined
  documents: (DocumentDTO)[]
  selectedDocument: (DocumentDTO) | null
  setSelectedDocument: (document: DocumentDTO) => void
  selectedDocumentDriverType: ApplicationFormDTO.DriverTypesEnum
  setSelectedDocumentDriverType: (type: ApplicationFormDTO.DriverTypesEnum) => void
  openDocumentPriveiw: (lang: ApplicationFormDTO.LangEnum) => void
}

export const useCreateNotification = (props: {
  createNotificationCallBack?: (notification: NotificationDTO) => void
}): IUseCreateNotification => {
  const { createNotificationCallBack } = props
  const dispatch = useAppDispatch()
  // TODO remove test id
  const [errorMessage, setErrorMessage] = React.useState('')
  const [newNotification, setNewNotification] = React.useState<CreateNotificationDTO>(generateNotification())
  const [richText, setRichText] = React.useState<EditorState>(EditorState.createEmpty())
  const documents = useAppSelector(selectNotificationDocumentForms)
  const [selectedDocument, setSelectedDocument] = React.useState<DocumentDTO>(documents[0])
  const [fetch, setFetch] = React.useState<boolean>(false)
  const [selectedDocumentDriverType, setSelectedDocumentDriverType] = React.useState<
    ApplicationFormDTO.DriverTypesEnum
  >(ApplicationFormDTO.DriverTypesEnum.OWNEROPERATOR)
  const testNotificationProps = testNotification(newNotification)
  const isRichTextFilled = React.useMemo(() => !isEditorEmpty(richText), [richText])
  const isDriverStatusesSelected = Boolean((newNotification.driverStatuses || []).length)
  const availableCreateNotification = React.useMemo(() => {
    const { type, subject, options } = newNotification

    if (isDriverStatusesSelected && type) {
      if (type === CreateNotificationDTO.TypeEnum.SIMPLE) {
        return Boolean(subject && isRichTextFilled)
      } else if (type === CreateNotificationDTO.TypeEnum.ACKNOWLEDGMENT) {
        return Boolean(subject && isRichTextFilled)
      } else if (type === CreateNotificationDTO.TypeEnum.POLL) {
        return Boolean(subject && isRichTextFilled && (options || []).filter(item => item.text).length)
      } else if (type === CreateNotificationDTO.TypeEnum.RESIGNDOCUMENT) {
        return Boolean(selectedDocument && isRichTextFilled)
      }
    }

    return false
  }, [newNotification, isRichTextFilled, isDriverStatusesSelected, selectedDocument])

  React.useEffect(() => {
    if (selectedDocument) {
      if (selectedDocumentDriverType) {
        if (
          !oc(selectedDocument)
            .driverTypes([])
            .includes(selectedDocumentDriverType)
        ) {
          setSelectedDocumentDriverType(oc(selectedDocument).driverTypes([])[0])
        }
      } else {
        setSelectedDocumentDriverType(oc(selectedDocument).driverTypes([])[0])
      }
    } else {
      setSelectedDocumentDriverType(null)
    }
  }, [selectedDocument])

  const openDocumentPriveiw = React.useCallback(
    (lang: ApplicationFormDTO.LangEnum) => {
      let isExpiredData = false

      if (selectedDocumentDriverType && selectedDocument && lang) {
        setFetch(true)

        if (selectedDocument.isOfficialForm) {
          requestOfficialFormPreview({
            lang,
            type: (selectedDocument as OfficialFormDTO).type
          }).then(documetnPreview => {
            if (!isExpiredData) {
              if (documetnPreview && documetnPreview.signingUrl) {
                openFileLink(documetnPreview.signingUrl)
              }

              setFetch(false)
            }
          })
        } else {
          requestApplicationFormPreview({
            lang,
            documentType: (selectedDocument as ApplicationFormDTO).documentType,
            driverType: selectedDocumentDriverType as any
          }).then(documetnPreview => {
            if (!isExpiredData) {
              if (documetnPreview && documetnPreview.url) {
                openFileLink(documetnPreview.url)
              }

              setFetch(false)
            }
          })
        }
      }

      return () => {
        isExpiredData = true
      }
    },
    [oc(selectedDocument).key(), selectedDocumentDriverType]
  )

  React.useEffect(() => {
    if (selectedDocument && !documents.some(item => oc(item).key() === oc(selectedDocument).key())) {
      setSelectedDocument(null)
    }
  }, [selectedDocumentDriverType])

  const setNotificationType = (type: NotificationDTO.TypeEnum) => {
    setNewNotification(notification => {
      notification.type = type

      if (type !== NotificationDTO.TypeEnum.POLL) {
        notification.options = undefined
      }
      if (type === NotificationDTO.TypeEnum.RESIGNDOCUMENT) {
        setRichText(EditorState.createEmpty())
        notification.subject = undefined
      } else {
        notification.documentType = undefined
      }

      return { ...notification }
    })
  }
  const setNotificationDriverStatus = (driverStatuses: CreateNotificationDTO.DriverStatusesEnum[]) => {
    setNewNotification(notification => ({ ...notification, driverStatuses }))
  }
  const setNotificationSubject = (subject: string) => {
    setNewNotification(notification => ({ ...notification, subject }))
  }
  const setNotificationOptions = (options: NotificationOptionDTO[]) => {
    setNewNotification(notification => ({ ...notification, options }))
  }

  const createNotification = availableCreateNotification
    ? () => {
        const _notification = { ...newNotification }
        const { type } = newNotification

        setFetch(true)

        if (type === CreateNotificationDTO.TypeEnum.POLL) {
          _notification.options = _notification.options.filter(item => item.text)
        }

        if (type === CreateNotificationDTO.TypeEnum.RESIGNDOCUMENT) {
          _notification.subject = `Resign ${oc(selectedDocument).name()}`
          // @ts-ignore
          _notification.documentType =
            oc(selectedDocument as ApplicationFormDTO).documentType() || oc(selectedDocument as OfficialFormDTO).type()
        }

        if (isRichTextFilled) {
          // @ts-ignore
          _notification.text = convertToRaw(richText.getCurrentContent())
        }

        requestNotificationCreate(_notification)
          .then(notification => {
            setFetch(false)
            dispatch(communicationHubActions.pushNotificationToStorage(notification))

            if (createNotificationCallBack) {
              createNotificationCallBack(notification)
            }
          })
          .catch(e => {
            if (e && e.message) {
              setErrorMessage(e && e.message)
            }
            setFetch(false)
          })
      }
    : undefined

  return {
    fetch,
    errorMessage,
    availableCreateNotification,
    newNotification,
    setNotificationType,
    setNotificationDriverStatus,
    setNotificationSubject,
    setNotificationOptions,
    testNotificationProps,
    createNotification,
    richText,
    setRichText,
    documents,
    setSelectedDocument,
    selectedDocument,
    selectedDocumentDriverType,
    setSelectedDocumentDriverType,
    openDocumentPriveiw
  }
}
