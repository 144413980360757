import styled from '../../../styles/styledComponents'

export const Container = styled.div`
   {
    position: fixed;
    width: 300px;
    height: 170px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
  }
`

export const Title = styled.div`
   {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    margin-bottom: 24px;
  }
`

export const Info = styled.div`
   {
    font-size: 14px;
    line-height: 25px;
    color: #445366;
    margin-bottom: 30px;
  }
`

export const Button = styled.div`
   {
    background: #4555d0;
    border-radius: 15px;
    font-size: 12px;
    line-height: 32px;
    font-weight: normal;
    text-align: center;
    color: #fff;
    width: 268px;
    height: 32px;
    margin: 0 auto;
    cursor: pointer;
  }
`

export const SpinnerContainer = styled.div`
   {
    height: 100px;
    position: relative;
    //.lds-dual-ring {
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate(-50%, -50%);
    //}
  }
`
