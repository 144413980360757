import { RepairDTO } from '../../api/origin/business-logic'
import { v4 } from 'uuid'
import { schemaRepair } from '../yupScheme'

export const newRepair = (): RepairDTO => ({
  repairType: null,
  billActual: null,
  costEstimate: null,
  description: null,
  equipmentHours: null,
  finishDate: null,
  id: `fake_${v4()}`,
  powerUnitMileage: null,
  receiptDocument: null,
  shopName: null,
  startDate: null
})

export const checkRepairs = (repairs: RepairDTO[]) => {
  if (!repairs.length) {
    return true
  }

  return repairs.every(
    repair =>
      Boolean(repair.startDate) &&
      Boolean(repair.finishDate) &&
      Boolean(repair.repairType) &&
      Boolean(
        repair.description &&
          repair.description.length > 1 &&
          repair.description.length < 512 &&
          (!repair.shopName || repair.shopName.length <= 100) &&
          (!repair.receiptDocument || repair.shopName.length <= 255)
      )
  )
}

// export const isValidFieldRepair = (repair: RepairDTO, path: keyof RepairDTO) =>
//   isValidField<RepairDTO>(repair, schemaRepair, path)
