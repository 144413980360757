import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderGridItemDTO, DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  ids: string[]
  modifiedLists?: Partial<TListsState>
}

export const getDispatchDeliveryOrders = (
  props: Props
): (DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[] => {
  const { ids, modifiedLists } = props
  const storeDDOsMapping = getListsState()[EntityType.dispatchDeliveryOrder]
  const modifiedDDOsMapping = oc(modifiedLists)[EntityType.dispatchDeliveryOrder]({})

  return (ids || []).map(id => modifiedDDOsMapping[id] || storeDDOsMapping[id]).filter(Boolean)
}
