import * as React from 'react'
import { StyledTabActions } from './styles'
import { StreetTurn } from './StreetTurn'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'

type OwnProps = {
  actionsType: TabDTO.Actions
  fetchGridItems: (withoutSpinner?: boolean) => Promise<any>
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const GridActions = (props: Props) => {
  const { actionsType, fetchGridItems } = props

  return <StyledTabActions>{Content[actionsType]({ fetchGridItems })}</StyledTabActions>
}

const Content = {
  [TabDTO.Actions.streetTurn]: StreetTurn
}
