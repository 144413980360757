import { ITabExpandedItem } from '../../../store/reducers/tabs/interfaces'

export const generateTabExpandedItem = (options?: Partial<ITabExpandedItem>): ITabExpandedItem => {
  return {
    id: undefined,
    entityType: undefined,
    modifiedLists: {},
    initialLists: {},
    ...(options || {})
  }
}
