import styled from 'styled-components'
import { theme } from '../../../../../styles/theme'

export const Container = styled.div`
  min-width: 100%;
  display: inline-flex;
  flex-direction: column;
  background: ${theme.colors.basicBackgroundColor};
  height: calc(100vh - 85px);
`
export const Content = styled.div`
  min-width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  background: #f5f6fa;
  overflow-y: hidden;
  overflow-x: auto;
`

export const StyledHeader = styled.div`
  position: relative;
  background: #fff;
  width: 100%;

  &.disabled {
    z-index: 9999;

    &:before {
      content: '';
      z-index: 1;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
    //
    //&:after {
    //  content: '';
    //  z-index: 999;
    //  display: block;
    //  height: 85px;
    //  position: absolute;
    //  top: -85px;
    //  left: 0;
    //  right: 0;
    //  background-color: rgba(0, 0, 0, 0.3);
    //}
  }
`

export const StyledTabs = styled.div`
  display: flex;
  position: relative;
  padding: 0 32px;
  height: 47px;
  border-bottom: 1px solid #e5e5e5;
  user-select: none;
`
export const StyledTab = styled.div`
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  font-size: 14px;
  color: #445366;
  line-height: 46px;
  &.active {
    cursor: default;
    color: ${theme.colors.basicBlueColor};

    &:before {
      z-index: 1;
      content: '';
      background-color: ${theme.colors.basicBlueColor};
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
    }
  }
`

export const StyledToggle = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  > span {
    margin-right: 11px;
  }
`
export const StyledReset = styled.div`
  display: flex;
  align-items: center;
  color: #bdbdbd;
  user-select: none;
  margin-left: 30px;

  &.active {
    color: ${theme.colors.basicBlueColor};
    cursor: pointer;
    text-decoration: underline;
  }
`
export const StyledFilter = styled.div`
  position: relative;
  display: flex;
  padding: 20px 30px 15px 30px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;

  > div:not(:first-child) {
    margin-left: 15px;
  }
`

export const StyledFieldContainer = styled.div`
  position: relative;
`
export const ExtraFilterFieldTitle = styled.div`
  font-size: 13px;
  position: absolute;
  top: -15px;
  white-space: nowrap;
  right: 0;
  color: #ff5e5e;
`
export const StyledColumn = styled.div`
  width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid #e5e5e5;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  background-color: #fcfdff;
  cursor: default;

  &:not(:last-child) {
    margin-right: 8px;
  }

  .actions-save-cancel {
    height: 52px;
  }
`

export const ColumnFooter = styled.div`
  margin-top: auto;
`

export const StyledRulesColumn = styled(StyledColumn)`
  height: 100%;
  overflow: auto;
`

export const ResizeColumnButton = styled.div`
  z-index: 3;
  font-size: 20px;
  color: #bdbdbd;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`

export const ColumnHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;
  background: #ffffff;
  border-radius: inherit;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.0691236);
  padding: 8px 9px 2px;
`

export const ColumnHeaderTitle = styled.div`
  color: black;
  letter-spacing: 0.75px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
`

export const TableHeader = styled.div`
  height: 20px;
  display: flex;
`
export const TableHeaderColumn = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  color: #6c707d;
  font-size: 10px;
  padding-left: 8px;

  &:not(:first-child) {
    border-left: 1px solid #e5e5e5;
  }
`
export const TableBody = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 115px;
  padding: 5px;
  overflow: visible;

  &.customer_quote__table {
    bottom: 170px;
  }

  > .selected {
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    transform: translateY(-5px);
    padding-top: 5px;
  }
`
export const TableRow = styled.div`
  position: relative;
  height: 51px;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  border: 1px solid #e5e5e5;

  &.table__row_dark {
    background-color: #f5f6fa;
  }

  &.table__row_first {
    border-radius: 2px 2px 0 0;
  }

  &.table__row_last {
    height: 50px;
    border-radius: 0 0 2px 2px;
  }

  //&:not(:hover) .warning-box {
  //  display: none;
  //}

  &.new {
    background: white linear-gradient(to right, rgba(39, 174, 96, 0.1), rgba(39, 174, 96, 0.1));
  }
  &.edit {
    background: white linear-gradient(to right, rgba(69, 85, 208, 0.1), rgba(69, 85, 208, 0.1));
  }
  &.warning {
    background: white linear-gradient(to right, rgba(234, 174, 118, 0.1), rgba(234, 174, 118, 0.1));
  }

  &:hover {
    background: ${theme.colors.rowHoverColor};
  }
`
export const TableRowWithActions = styled(TableRow)`
  padding-right: 70px;
`
export const ColorRowLine = styled.div`
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 3px;

  &.new {
    background-color: #27ae60;
  }
  &.edit {
    background-color: #4555d0;
  }
  &.warning {
    background-color: #eaae76;
  }
`
export const StyledCell = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  color: #445366;
  flex-wrap: wrap;
  font-size: 14px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0 8px;
`
export const StyledRowActionsColumn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  cursor: pointer;
  padding-right: 4px;

  span {
    color: transparent;
  }

  ${TableRow}:hover > & {
    span {
      color: #bdbdbd;
    }

    &:hover {
      span {
        color: ${theme.colors.basicBlueColor};
      }
    }
  }

  &.active {
    span {
      color: ${theme.colors.basicBlueColor} !important;
    }
  }
`

export const StyledRowActions = styled.div`
  width: 135px;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 8px 0;
  user-select: none;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    color: rgba(79, 79, 79, 0.8);
    font-weight: 500;
    font-size: 12px;

    &:before,
    svg {
      margin-right: 8px;
      font-size: 22px;
    }
    svg {
      opacity: 0.8;
    }

    &:hover {
      color: rgba(79, 79, 79, 1);
      background-color: #e5e5e5;
      svg {
        opacity: 1;
      }
    }
  }
`
export const AdditionalRowActions = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  > span {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bdbdbd;
    font-size: 20px;
    padding-right: 4px;
    cursor: pointer;

    &:hover {
      color: ${theme.colors.basicBlueColor};
    }
  }
`

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 15px;

  .actions-save-cancel {
    border: 1px solid #e5e5e5;
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 5px 5px;
    padding-right: 26px;
    height: 52px;
    position: static;
    justify-content: flex-end;
  }
`

export const StyledRules = styled.div`
  display: flex;
  height: calc(100% - 56px);
  overflow: auto;
`

export const FullHeightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`

export const StyledAddNew = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.07);
  border-radius: 5px 5px 0 0;
  padding: 0 5px;

  ${StyledFieldContainer} {
    flex-grow: 1;
    margin-right: 8px;
  }
`
