import { oc } from 'ts-optchain'
import { RootState } from '..'
import { EntityType } from '../reducers/lists/interfaces'
import { DriverActivenessStatus, IDriverActivityDTO } from '../../services/DTO/driverActivity/interfaces'

// SELECT MANY
export const selectDriversActivity = (state: RootState): Record<string, IDriverActivityDTO> => {
  return state.lists[EntityType.driverActivity]
}

// SELECT ONE
export const selectDriverActivity = (userAuthOrDriverId: string) => (
  state: RootState
): IDriverActivityDTO | undefined => {
  const { lists } = state
  const driver = lists.driver[userAuthOrDriverId]
  let driverActivity = lists.driverActivity[userAuthOrDriverId]

  if (!driverActivity && driver) {
    const authUserId = driver.authUserId

    driverActivity = lists.driverActivity[authUserId]
  }

  return driverActivity
}

export const selectIsDriverOnline = (userAuthOrDriverId: string) => (state: RootState): boolean | undefined => {
  const { lists } = state
  const driver = lists.driver[userAuthOrDriverId]
  let driverActivity = lists.driverActivity[userAuthOrDriverId]

  if (driver) {
    const authUserId = driver.authUserId

    driverActivity = lists.driverActivity[authUserId]
  }

  return oc(driverActivity).status() ? driverActivity.status === DriverActivenessStatus.ACTIVE : undefined
}
