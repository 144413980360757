import styled from 'styled-components'
import { GridColumn } from '../../../../../../UI/GridSystem'
import { activityStageColor } from '../../Activities/Table/styles'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../../../../services/DTO/dispatchDeliveryOrder/directory'

export const StyledStatusBar = styled(GridColumn)``
export const Titles = styled(GridColumn)`
  > div {
    align-items: center;
    font-size: 14px;

    &.required:after {
      content: '*';
      margin-left: 3px;
      color: ${ddoDirectory.statusColor[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]};
    }
  }
`
export const Icon = styled(GridColumn)`
  z-index: 5;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Lines = styled(GridColumn)`
  > div {
    background-color: #eef0f6;
    &:before {
      content: '';
      display: block;
      width: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &.disabled {
      background-color: rgba(0, 0, 0, 0.3) !important;
      opacity: 0.3;

      ${Icon} {
        display: none !important;
      }
    }

    &.active,
    &.half {
      ${Icon}:before {
        content: '\f0150';
      }
    }

    &.finished {
      ${Icon}:before {
        content: '\f012c';
      }
    }

    &:not(.active):not(.half):not(.finished) {
      ${Icon} {
        display: none;
      }
    }

    &:nth-child(1) {
      border-radius: 5px 0 0 5px;

      &.active,
      &.half {
        background: repeating-linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0) 3px,
          rgba(124, 127, 207, 0.7) 3px,
          rgba(124, 127, 207, 0.7) 6px
        );
      }

      &.half:before {
        background-color: ${activityStageColor.PICKUP};
        border-radius: 5px 0 0 5px;
      }

      &.finished {
        background: ${activityStageColor.PICKUP};
      }
    }

    &:nth-child(2) {
      &.active,
      &.half {
        background: repeating-linear-gradient(
          -45deg,
          rgba(193, 121, 209, 0),
          rgba(193, 121, 209, 0) 3px,
          rgba(193, 121, 209, 0.7) 3px,
          rgba(193, 121, 209, 0.7) 6px
        );
      }

      &.half:before {
        background-color: ${activityStageColor.DELIVERY};
      }

      &.finished {
        background: ${activityStageColor.DELIVERY};
      }
    }

    &:nth-child(3) {
      border-radius: 0 5px 5px 0;

      &.active,
      &.half {
        background: repeating-linear-gradient(
          -45deg,
          rgba(69, 165, 155, 0),
          rgba(69, 165, 155, 0) 3px,
          rgba(69, 165, 155, 0.7) 3px,
          rgba(69, 165, 155, 0.7) 6px
        );
      }

      &.half:before {
        background-color: ${activityStageColor.RETURN};
      }

      &.finished {
        background: ${activityStageColor.RETURN};
      }
    }
  }
`
