import * as React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

type Props = {
  active: boolean
  style?: React.CSSProperties | undefined
  onClick?: () => void
  backgroundColor?: string
}

export const ToggleButton = (props: Props) => {
  const { active, style, onClick, backgroundColor } = props

  return (
    <ToggleButtonContainer className={cn({ active })} style={{ ...(style || {}), backgroundColor }} onClick={onClick} />
  )
}

const ToggleButtonContainer = styled.div`
  position: relative;
  width: 24px;
  height: 12px;
  background-color: #5c6fff;
  border-radius: 8px;
  transition: background 0.15s;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    transition: left 0.25s;
  }

  &:not(.active) {
    background-color: #e5e5e5 !important;
  }

  &.active {
    transition: background 0.25s 0.15s;

    &:before {
      left: calc(100% - 12px);
    }
  }
`
