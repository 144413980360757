import { tabActions } from '.'
import { getDispatch } from '../..'
import { createEntity } from '../../../services/DTO/createNewDTO'
import { generateBusinessPartner } from '../../../services/functions/generate/generateBusinessPartner'
import { generateCustomer } from '../../../services/functions/generate/generateCustomer'
import { generateSteamShipLine } from '../../../services/functions/generate/generateSteamShipLine'
import { generateSubClient } from '../../../services/functions/generate/generateSubClient'
import { EntityType } from '../lists/interfaces'
import { ITab, ITabUpdate, TGridColumns, TabDTO, ColumnDTO } from './interfaces'

export const setInitialTabsState = (tabs: ITab[]) => {
  getDispatch()(tabActions.set(tabs))
}

export const isTabIdPresents = (tabs: ITab[], tabId: string): boolean => {
  return (tabs || []).some(tab => tab.id === tabId)
}

export const setActiveTabId = (tabId: string) => {
  getDispatch()(tabActions.setActiveTabId(tabId))
}

export const createTab = (tabType: TabDTO.Type, options?: Partial<ITabUpdate>) => {
  getDispatch()(tabActions.createTab({ type: tabType, options }))
}

export const deleteTabById = (tabId: string) => {
  getDispatch()(tabActions.deleteTab(tabId))
}

export const duplicateTab = (tab: ITab) => {
  getDispatch()(tabActions.duplicateTab({ originalTab: tab }))
}

export const updateTab = (tabId: string, options: Partial<ITabUpdate>) => {
  getDispatch()(tabActions.updateTab({ tabId, options }))
}

export const updateTabData = (tabId: string, options: any, visited?: boolean) => {
  getDispatch()(tabActions.updateTabData({ tabId, options, visited }))
}

export const updateTabUISettings = (tabId: string, uiSettings: Partial<TabDTO.UISettings>) => {
  // @ts-ignore
  getDispatch()(tabActions.updateTab({ tabId, options: { uiSettings } }))
}

export const updateTabSorting = (tabId: string, sorting: string) => {
  getDispatch()(tabActions.updateTab({ tabId, options: { uiSettings: { sorting } as TabDTO.UISettings } }))
}

export const updateTabFilter = (tabId: string, filter: ColumnDTO.Filter[]) => {
  getDispatch()(tabActions.updateTab({ tabId, options: { uiSettings: { filter } as TabDTO.UISettings } }))
}

export const updateTabColumns = (tabId: string, columns: TGridColumns) => {
  getDispatch()(tabActions.updateTab({ tabId, options: { uiSettings: { columns } as TabDTO.UISettings } }))
}

export const setFavoriteOngoingActivityState = ({
  tabId,
  ongoingActivityId,
  favoriteState
}: {
  tabId?: string
  ongoingActivityId: string
  favoriteState: boolean
}) => {
  getDispatch()(tabActions.setFavoriteOngoingActivityState({ tabId, ongoingActivityId, favoriteState }))
}

export const moveTab = (props: { currentTab: ITab; insertBeforeTab: ITab }) => {
  getDispatch()(tabActions.moveTab(props))
}

export const deleteAllTabsExcept = (tabId: string) => {
  getDispatch()(tabActions.deleteAllTabsExcept({ tabId }))
}

export const deleteRightTabs = (tabId: string) => {
  getDispatch()(tabActions.deleteRightTabs({ tabId }))
}

export const addCreatedGridItem = (tabId: string, gridItemId: string) => {
  getDispatch()(tabActions.replaceCreatedGridItem({ tabId, gridItemId }))
}

export const addNewGridItem = (props: { tabId: string; type: TabDTO.Type; newGridItem?: any }) => {
  const { tabId, type } = props
  const modifiedLists = {}
  let expandedItemId = undefined

  if (type === TabDTO.Type.steamShipLine) {
    const newBusinessPartner = generateBusinessPartner()
    const newSteamShipLine = generateSteamShipLine({ businessPartnerId: newBusinessPartner.id })
    expandedItemId = newSteamShipLine.id

    modifiedLists[EntityType.businessPartner] = { [newBusinessPartner.id]: newBusinessPartner }
    modifiedLists[EntityType.steamShipLine] = { [newSteamShipLine.id]: newSteamShipLine }
  } else if (type === TabDTO.Type.subClient) {
    const newBusinessPartner = generateBusinessPartner()
    const newSubCliente = generateSubClient({ businessPartnerId: newBusinessPartner.id })
    expandedItemId = newSubCliente.id

    modifiedLists[EntityType.businessPartner] = { [newBusinessPartner.id]: newBusinessPartner }
    modifiedLists[EntityType.subClient] = { [newSubCliente.id]: newSubCliente }
  } else if (type === TabDTO.Type.customer) {
    const newBusinessPartner = generateBusinessPartner()
    const newCustomer = generateCustomer({ businessPartnerId: newBusinessPartner.id })
    expandedItemId = newCustomer.id

    modifiedLists[EntityType.businessPartner] = { [newBusinessPartner.id]: newBusinessPartner }
    modifiedLists[EntityType.customer] = { [newCustomer.id]: newCustomer }
  } else {
    const newGridItem = props.newGridItem || createEntity(type)
    modifiedLists[type] = { [newGridItem.id]: newGridItem }
    expandedItemId = newGridItem.id
  }

  getDispatch()(
    tabActions.setExpandedItem({
      tabId,
      expandedItem: {
        id: expandedItemId,
        modifiedLists
      }
    })
  )
}
