import * as R from 'remeda'
import { callAPI, callAPIWithErrorMessage, subClientAPI, SubClientViewDTO } from '../../../../api/api'
import { catchListErrors } from '../../../../services/tabs/functions'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewId, isNewObject } from '../../../../services/DTO'
import { getListsState } from '../../../../store'

export const requestSubClientById = (id: string): Promise<SubClientViewDTO> =>
  callAPI(subClientAPI.findById, id)
    .toPromise()
    .then(async subClient => {
      await pushListItemsToStore({ update: { [EntityType.subClient]: [subClient] } })

      return subClient
    })

export const requestSubClients = async ({ sort, filter }: any): Promise<SubClientViewDTO[]> => {
  return callAPI(subClientAPI.findAll, filter, sort)
    .toPromise()
    .then(async subClients => {
      if (subClients && subClients.length) {
        await pushListItemsToStore({ update: { [EntityType.subClient]: subClients } })
      }

      return subClients
    })
    .catch(catchListErrors)
}

export const requestSubClientIds = async (ids: string[]): Promise<SubClientViewDTO[]> => {
  if (ids && ids.length) {
    const storeSubClientMapping = getListsState()[EntityType.subClient]
    const requestIds = ids.filter(id => !isNewId(id) && !storeSubClientMapping[id])

    if (!requestIds.length) {
      return Promise.resolve([])
    }

    return Promise.all(requestIds.map(id => callAPI(subClientAPI.findById, id).toPromise())).then(async subClients => {
      await pushListItemsToStore({
        update: { [EntityType.subClient]: subClients }
      })

      return subClients
    })
  }

  return Promise.resolve([])
}

const omitSubClientProps: (keyof SubClientViewDTO)[] = ['customer', 'businessPartner']

export const requestCreateSubClient = (subClient: SubClientViewDTO): Promise<SubClientViewDTO> => {
  return callAPIWithErrorMessage(subClientAPI.create, R.omit(
    subClient,
    omitSubClientProps.concat('id')
  ) as SubClientViewDTO).then(async requestedSubClient => {
    await pushListItemsToStore({ update: { [EntityType.subClient]: [requestedSubClient] } })

    return requestedSubClient
  })
}

export const requestUpdateSubClient = (subClient: SubClientViewDTO): Promise<SubClientViewDTO> => {
  if (isNewObject(subClient)) {
    return requestCreateSubClient(subClient)
  }

  return callAPIWithErrorMessage(subClientAPI.update, subClient.id, R.omit(
    subClient,
    omitSubClientProps
  ) as SubClientViewDTO).then(async requestedSubClient => {
    await pushListItemsToStore({ update: { [EntityType.subClient]: [requestedSubClient] } })

    return requestedSubClient
  })
}
