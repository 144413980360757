import { oc } from 'ts-optchain'
import * as R from 'remeda'
import {
  ActivitiesViewDTO,
  callAPI,
  callAPIWithErrorMessage,
  dispatchDeliveryOrderAPI,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderViewDTO,
  nonPlannedActivityAPI,
  terminalMismatchAPI,
  TerminalNoteDTO,
  terminalNotesAPI
} from '../../../../api/api'
import { localStorageService } from '../../../../services/storageService/localStorage/LocalStorage'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'
import { TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../../../services/utils'

export const requestTerminalMismatches = (props: { ddoId: string }) => {
  const { ddoId } = props

  return callAPIWithErrorMessage(terminalMismatchAPI.getTerminalMismatches, ddoId)
}

export const requestDispatchDeliveryOrderById = (
  id: string,
  forceUpdate?: boolean
): Promise<DispatchDeliveryOrderViewDTO> => {
  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.findById, id).then(async dispatchDeliveryOrder => {
    // @ts-ignore
    dispatchDeliveryOrder.forceUpdate = forceUpdate
    await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: [dispatchDeliveryOrder] } })
    return dispatchDeliveryOrder
  })
}

export const findAllDispatchDeliveryOrders = (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderViewDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.findAll, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      if (pushToStore) {
        await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      }

      return dispatchDeliveryOrders
    })
}

export const requestDispatchDeliveryOrdersByIds = async (
  dispatchDeliveryOrderIds: string[],
  options?: { notFullObject?: boolean; notFullObjectWithActivities?: boolean }
): Promise<(DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[]> => {
  if (!(dispatchDeliveryOrderIds && dispatchDeliveryOrderIds.length)) {
    return Promise.resolve([])
  }

  try {
    const { notFullObject, notFullObjectWithActivities } = options || {}
    const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(dispatchDeliveryOrderIds)
    const requestedDispatchDeliveryOrders: (DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[] = []

    if (arrayOfRequestedIdsArrays.length) {
      let dispatchDeliveryOrderRequests: any = findAllDispatchDeliveryOrders

      if (notFullObjectWithActivities) {
        dispatchDeliveryOrderRequests = findAllGridDispatchDeliveryOrdersWithActivities
      } else if (notFullObject) {
        dispatchDeliveryOrderRequests = requestGridDispatchDeliveryOrders
      }

      await Promise.all(
        arrayOfRequestedIdsArrays.map(ids =>
          dispatchDeliveryOrderRequests({ filter: 'id%%' + ids.join(',') }, false).then((ddos: any[]) => {
            requestedDispatchDeliveryOrders.push(...ddos)
          })
        )
      )

      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: requestedDispatchDeliveryOrders } })
      return requestedDispatchDeliveryOrders
    } else {
      return Promise.resolve([])
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error)
  }
}

export const requestGridDispatchDeliveryOrders = async (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      if (pushToStore) {
        await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      }

      return dispatchDeliveryOrders
    })
}

export const findAllGridDispatchDeliveryOrdersWithActivities = async (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrdersWithActivities, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      if (pushToStore) {
        await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      }

      return dispatchDeliveryOrders
    })
}

export const findAllDroppedAtDeliveryLocation = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDroppedAtDeliveryLocation, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      return dispatchDeliveryOrders
    })
}
export const findAllDeliveryStageCompletedNoReturned = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDeliveryStageCompletedNoReturned, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      return dispatchDeliveryOrders
    })
}
export const findAllDroppedAtYard = async ({ sort, filter }: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDroppedAtYard, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      return dispatchDeliveryOrders
    })
}
export const findAllPickedUpNoDelivery = async ({ sort, filter }: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getPickedUpNoDelivery, filter, sort)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })
      return dispatchDeliveryOrders
    })
}
export const findRecentDispatchDeliveryOrders = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  const recentIds = localStorageService.getRecentForTabType(TabDTO.Type.dispatchDeliveryOrder)
  const _filter = 'id%%' + recentIds.join(',') + (filter ? ';' + filter : '')

  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, _filter)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      const mapping = (dispatchDeliveryOrders || []).reduce(
        (acc, curr) => {
          acc[curr.id] = curr

          return acc
        },
        {} as Record<string, DispatchDeliveryOrderGridItemDTO>
      )

      const sortedDispatchDeliveryOrders = recentIds.map(id => mapping[id]).filter(Boolean)

      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: sortedDispatchDeliveryOrders } })
      return sortedDispatchDeliveryOrders
    })
}

export const requestActivitiesByDispatchDeliveryOrderId = (id: string): Promise<ActivitiesViewDTO> => {
  return callAPIWithErrorMessage(dispatchDeliveryOrderAPI.findActivities, id).then(async activities => {
    await pushListItemsToStore({
      update: {
        // @ts-ignore
        [EntityType.activity]: [
          ...oc(activities).transportationActivities([]),
          ...oc(activities).documentationActivities([])
        ]
      }
    })
    return activities
  })
}

export const getDispatchDeliveryOrderNotes = (dispatchDeliveryOrderId: string): Promise<TerminalNoteDTO[]> =>
  callAPI(terminalNotesAPI.getTerminalNotes, dispatchDeliveryOrderId).toPromise()

export const requestCreateDispatchDeliveryOrder = (
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
): Promise<DispatchDeliveryOrderViewDTO> => {
  return callAPIWithErrorMessage(
    dispatchDeliveryOrderAPI.create,
    R.omit(dispatchDeliveryOrder, ['id', 'deliveryOrder', 'activities'])
  ).then(async _dispatchDeliveryOrder => {
    const { documentationActivities, transportationActivities } = _dispatchDeliveryOrder.activities

    await pushListItemsToStore({
      update: {
        [EntityType.dispatchDeliveryOrder]: [_dispatchDeliveryOrder],
        // @ts-ignore
        [EntityType.activity]: (documentationActivities || []).concat(transportationActivities || [])
      }
    })
    return _dispatchDeliveryOrder
  })
}

export const requestUpdateDispatchDeliveryOrder = (
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
): Promise<DispatchDeliveryOrderViewDTO> => {
  if (isNewItem(dispatchDeliveryOrder)) {
    return requestCreateDispatchDeliveryOrder(dispatchDeliveryOrder)
  }

  return callAPIWithErrorMessage(
    dispatchDeliveryOrderAPI.update,
    dispatchDeliveryOrder.id,
    R.omit(dispatchDeliveryOrder, [
      'deliveryOrder',
      'activities',
      'activityIds',
      'buySideQuoteIds',
      'container',
      'equipment'
    ])
  ).then(async _dispatchDeliveryOrder => {
    await pushListItemsToStore({
      update: { [EntityType.dispatchDeliveryOrder]: [_dispatchDeliveryOrder] }
    })
    return _dispatchDeliveryOrder
  })
}

export const requestDispatchDeliveryOrderBatchUpdate = (
  dispatchDeliveryOrders: DispatchDeliveryOrderViewDTO[]
): Promise<DispatchDeliveryOrderViewDTO[]> => {
  return callAPIWithErrorMessage(
    dispatchDeliveryOrderAPI.batchUpdate,
    dispatchDeliveryOrders.map(item => {
      const omit: (keyof DispatchDeliveryOrderViewDTO)[] = ['deliveryOrder', 'activities']

      if (isNewItem(item)) {
        omit.push('id')
      }

      return R.omit(item, omit)
    })
  ).then(async _dispatchDeliveryOrders => {
    await pushListItemsToStore({
      update: { [EntityType.dispatchDeliveryOrder]: _dispatchDeliveryOrders }
    })
    return _dispatchDeliveryOrders
  })
}
