import { SellSideQuoteRateDTO } from '../../../api/origin/qmp-service'
import { AnyRateDTO } from '../../../components/common/QMP/interfaces'
import { MiscSurchargeDTO, SurchargeDTO } from '../../DTO/rate/interfaces'

type Props = {
  mainRateAmount: number
  surcharge?: AnyRateDTO | SurchargeDTO | MiscSurchargeDTO
  defaultQuantity?: number
}

export const getSurchargeAmount = (props: Props): number => {
  const { mainRateAmount, surcharge, defaultQuantity } = props
  let amount: number = 0

  if (surcharge && surcharge.amount) {
    switch (surcharge.calculationType) {
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE: {
        amount += Math.ceil((mainRateAmount || 0) * surcharge.amount) / 100
        break
      }
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE:
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY: {
        amount += ((surcharge as any).quantity || defaultQuantity || 0) * surcharge.amount
        break
      }
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR: {
        const count = ((surcharge as any).quantity || defaultQuantity || 0) / 60
        amount += Math.ceil(count * surcharge.amount * 100) / 100

        break
      }
      default: {
        amount += surcharge.amount
        break
      }
    }
  }

  return amount
}
