import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import {
  authLoginWithRedirect,
  authProcessRedirect,
  authClientOrServerError,
  cleanUpDispatchedActionsAfterAuthorization,
  authGetTokens,
  logout
} from '../actions/authServiceActions'
import { AUTH_API_BASE_PATH } from '../../../api/authApi'
import {
  AuthConfig,
  AuthGetVerifier,
  authLogout,
  authRedirect,
  AUTHORIZATION_HEADERS,
  AuthorizationGrantTypes
} from '../'
import {
  getItemFromStorage,
  removeItemFromStorage,
  saveItemToStorage,
  StorageKeys
} from '../../storageService/storageService'
import ofAction from '../../rxjsService/operators/ofAction'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax'
import { Observable, of } from 'rxjs'
import { NOTHING } from '../../rxjsService/actions'
import { tabSessionStorage } from '../../tabs/functions'

const authLoginWithRedirectEpic = (action$: ActionsObservable<Action<any>>): Observable<any> =>
  action$.pipe(
    ofAction(authLoginWithRedirect),
    switchMap(() => {
      authRedirect()
      return of(NOTHING())
    })
  )

const authProcessRedirectEpic = (action$: ActionsObservable<Action<any>>): Observable<any> =>
  action$.pipe(
    ofAction(authProcessRedirect),
    switchMap(({ payload }) => {
      if (getItemFromStorage(StorageKeys.AuthState) !== payload.state) {
        authLoginWithRedirect()
        return of(NOTHING())
      }
      removeItemFromStorage(StorageKeys.AuthState)
      return ajax({
        url: `${AUTH_API_BASE_PATH}/realms/${AuthConfig.audience}/protocol/openid-connect/token`,
        body: {
          grant_type: AuthorizationGrantTypes.AuthorizationCode,
          client_id: AuthConfig.client_id,
          code_verifier: AuthGetVerifier(),
          redirect_uri: AuthConfig.redirect_uri,
          code: payload.code
        },
        method: 'POST',
        headers: AUTHORIZATION_HEADERS
      }).pipe(
        map((res: AjaxResponse) => {
          const { response, request } = res
          if (response === null) {
            return authClientOrServerError()
          }
          removeItemFromStorage(StorageKeys.AuthVerifier)
          saveItemToStorage(StorageKeys.AccessToken, response.access_token)
          saveItemToStorage(StorageKeys.RefreshToken, response.refresh_token)
          saveItemToStorage(StorageKeys.IdToken, response.id_token)
          return authGetTokens.done({
            params: request.body
          })
        }),
        catchError((error: AjaxError) => {
          const { request } = error
          return of(
            authGetTokens.failed({
              params: request.body,
              error: error.response
            })
          )
        })
      )
    })
  )

const authGetTokensDoneEpic = (action$: ActionsObservable<Action<any>>) =>
  action$.pipe(
    ofAction(authGetTokens.done),
    switchMap(() => {
      return [cleanUpDispatchedActionsAfterAuthorization()]
    })
  )

const logoutEpic = (action$: ActionsObservable<Action<any>>) =>
  action$.pipe(
    ofAction(logout),
    switchMap(() => {
      tabSessionStorage.clear()
      authLogout()
      return []
    })
  )

export default combineEpics(authLoginWithRedirectEpic, authProcessRedirectEpic, authGetTokensDoneEpic, logoutEpic)
