import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import theme from '../../../styles/theme'
import { DriverViewDTO } from '../../../api/origin/business-logic'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectDriver } from '../../../store/select/driverSelect'
import { UserDTO } from '../../../api/origin/user-service'
import { selectIsDriverOnline } from '../../../store/select/driverActivitySelect'
import { testDriverStatus } from '../../../services/functions/test/testDriverStatus'

export enum UserAvatarSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

type OwnProps = {
  user: UserDTO | undefined
  size: UserAvatarSize
  showUserDetails?: boolean
  round?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const UserAvatar = React.memo((props: Props) => {
  const { size, user, round, showUserDetails } = props
  const driver = useAppSelector(selectDriver(user && user.id))
  const isDriverOnline = useAppSelector(selectIsDriverOnline(user && user.id))
  const userAvatarURL = oc(user).avatar()
  const firstName = (driver && driver.firstName) || oc(user).firstName('')
  const lastName = (driver && driver.lastName) || oc(user).lastName('')
  const showDriverActivityIndicator = testDriverStatus(driver && driver.status).isActive

  const firstAlphabet = React.useMemo((): string => {
    if (userAvatarURL) {
      return undefined
    }

    const arrayOfLetters = [firstName, lastName].map(word => word[0])

    return arrayOfLetters.filter(Boolean).join('')
  }, [firstName, lastName, userAvatarURL])

  const style: React.CSSProperties = React.useMemo(
    () =>
      userAvatarURL
        ? { backgroundImage: userAvatarURL ? `url("${userAvatarURL}")` : undefined }
        : { backgroundColor: firstAlphabet ? getRandomColorByString(firstAlphabet) : undefined },
    [userAvatarURL, firstAlphabet]
  )

  const hintProps = React.useMemo(
    () =>
      showUserDetails
        ? {
            'data-for': 'user-details-hint',
            'data-tip': oc(user).id()
          }
        : {},
    [oc(user).id()]
  )

  return (
    <Container
      {...hintProps}
      // @ts-ignore
      style={style}
      className={cn('user-icon', `user-icon_${size}`, {
        round,
        clickable: showUserDetails,
        active: showDriverActivityIndicator && isDriverOnline,
        inactive: showDriverActivityIndicator && isDriverOnline === false
      })}
    >
      {firstAlphabet}
    </Container>
  )
})

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.defaultGray} center/cover;
  border-radius: 5px;
  font-weight: 400 !important;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  user-select: none;

  &.user-icon_small {
    width: 26px;
    height: 26px;
    font-size: 14px;
  }

  &.user-icon_medium {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  &.user-icon_large {
    width: 128px;
    height: 128px;
    font-size: 64px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.round {
    border-radius: 50%;
  }

  &.active,
  &.inactive {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid #f5f7f7;
      background-color: #9ba8b8;
      position: absolute;
      bottom: -3px;
      right: -3px;
    }
  }

  &.active:before {
    background-color: #65d16c;
  }
  &.inactive:before {
    background-color: #f5a85f;
  }
`

const getRandomColorByString = (name: string): string => {
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase()

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0)

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString()

  const num = Math.round(0xffffff * Number(colorNum))
  /* tslint:disable:no-bitwise */
  const r = (num >> 16) & 255
  const g = (num >> 8) & 255
  const b = num & 255

  return 'rgb(' + r + ', ' + g + ', ' + b + ')'
}
