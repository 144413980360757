import * as React from 'react'
import { TroubleTicketAlert } from './TroubleTicketAlert'
import { AppNotificationType, IAppNotification } from '../../../../../store/reducers/appNotifications/interfaces'

type StateProps = {
  notification: IAppNotification
  removeNotification: () => void
}

type Props = StateProps

export const Notification = React.memo((props: Props) => {
  const { notification, removeNotification } = props

  switch (notification.type) {
    case AppNotificationType.alert:
      return <TroubleTicketAlert data={notification.data} removeNotification={removeNotification} />
    default:
      return null
  }
})
