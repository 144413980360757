import { oc } from 'ts-optchain'
import {
  DeliveryOrderDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { IMove } from '../../../store/reducers/communicationHub/interfaces'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  move: IMove
}

export const isStreetTurnButtonsAvailible = (props: Props): boolean => {
  const { dispatchDeliveryOrder, move } = props
  try {
    let targetStage: TransportationActivityViewDTO.StageEnum
    let adjustmentActivityIndex: -1 | 1

    if (!(dispatchDeliveryOrder && !dispatchDeliveryOrder.troubleTicketId && move.deliveryOrderType && move.items)) {
      return false
    }

    if (move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.IMPORT) {
      targetStage = TransportationActivityViewDTO.StageEnum.RETURN
      adjustmentActivityIndex = -1
    } else if (move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.EXPORT) {
      targetStage = TransportationActivityViewDTO.StageEnum.PICKUP
      adjustmentActivityIndex = 1
    } else {
      return false
    }

    if (dispatchDeliveryOrder.streetTurnId) {
      let targetStreetTurnId: string
      const storeActivity = getListsState()[EntityType.activity]
      const sortedTargetStageActivityIds = dispatchDeliveryOrder.activityIds
        .filter(id => {
          const { stage, status, type } = storeActivity[id] || {}

          if (status !== DocumentationActivityDTO.StatusEnum.REJECTED) {
            if (stage === targetStage && type === DocumentationActivityDTO.TypeEnum.STREETTURN) {
              targetStreetTurnId = id
            }

            return true
          }
        })
        .sort((activity1Id, activity2Id) => {
          const activity1 = storeActivity[activity1Id]
          const activity2 = storeActivity[activity2Id]
          return oc(activity1).number(0) - oc(activity2).number(0)
        })

      if (!targetStreetTurnId) {
        return false
      } else {
        const targetStreetTurnIndex = sortedTargetStageActivityIds.findIndex(id => id === targetStreetTurnId)
        const nextToTransportationActivityId =
          sortedTargetStageActivityIds[targetStreetTurnIndex + adjustmentActivityIndex]

        return nextToTransportationActivityId
          ? move.items.some(
              ({ gotoActivityId, businessActivityId }) =>
                nextToTransportationActivityId === gotoActivityId ||
                nextToTransportationActivityId === businessActivityId
            )
          : false
      }
    } else if (dispatchDeliveryOrder.streetTurnCount) {
      return move.items.some(
        ({ businessActivity }) =>
          businessActivity && businessActivity.template && businessActivity.stage === targetStage
      )
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return false
}
