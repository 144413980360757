import * as React from 'react'
import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { LocationViewDTO } from '../../../api/api'
import { locationControllerApi } from '../../../api/location'
import {
  showModalWindow,
  showErrorModalWindow,
  showWarningModalWindow
} from '../../../store/reducers/modalWindow/functions'
import { SelectLocation } from './SelectLocation'
import { saveLocation as saveLocationPromise } from '../../saveEntity/saveLocation'
import { generateAddressLabel } from '../../functions/generate/generateAddressLabel'
import { getAddressState } from '../../functions/get/getAddressState'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isNewItem } from '../../utils'
import { isLocationValid } from '../../functions/test/isLocationValid'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { filterModifiedContacts } from '../../functions/filter/filterModifiedContacts'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'

export const saveLocation = async (expandedItemContext: TExpandedItemContext, placeIdPassed?: boolean) => {
  const {
    tabId,
    parentItem,
    parentItemId,
    modifiedLists,
    modifyParentObject,
    initialParentItemState,
    setFetching,
    clearExpandedItemModifies
  } = expandedItemContext
  let location: LocationViewDTO = parentItem
  const modifiedLocation = oc(modifiedLists)[EntityType.location][parentItemId]()
  const prevLocationVersion: LocationViewDTO = initialParentItemState
  const updatedContacts = filterModifiedContacts({ contactIds: location.contactIds, modifiedLists })

  const save = () => {
    return saveLocationPromise({
      location: oc(modifiedLists)[EntityType.location][parentItemId]() as LocationViewDTO,
      contacts: updatedContacts
    }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(location) }))
  }

  const store = getListsState()
  const condition =
    testEntityVersion(store, EntityType.location)(modifiedLocation) &&
    updatedContacts.every(testEntityVersion(store, EntityType.contact))

  if (!placeIdPassed) {
    let locationDataChanged = false
    const addressString = generateAddressLabel(location.address)
    const placeId = oc(location).placeId()
    const locationLatitude = oc(location).latitude()
    const locationLongitude = oc(location).longitude()

    if (prevLocationVersion) {
      const prevAddressString = generateAddressLabel(prevLocationVersion.address)
      locationDataChanged = prevAddressString !== addressString
    } else {
      locationDataChanged = true
    }

    if (locationDataChanged || !(placeId && locationLatitude && locationLongitude)) {
      const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

      if (searchResults.length) {
        // const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId
        const isPlaceIdCorrect = false // always show confirm popup

        if (!isPlaceIdCorrect) {
          setFetching(false)

          return showModalWindow({
            width: 445,
            title: 'Select location',
            content: (
              <SelectLocation
                selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
                locations={searchResults}
                onSelect={selectedPlaceId => {
                  if (selectedPlaceId) {
                    setFetching(true)

                    locationControllerApi
                      .locationByPlaceId(selectedPlaceId)
                      .then(requestedLocation => {
                        const { latitude, longitude } = requestedLocation
                        const requestedAddress = oc(requestedLocation).address()

                        location = { ...location, placeId: selectedPlaceId, latitude, longitude }

                        if (requestedAddress) {
                          if (requestedAddress.city) {
                            location.address = {
                              ...location.address,
                              street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                              city: requestedAddress.city || location.address.city,
                              postalCode: requestedAddress.postalCode || location.address.postalCode,
                              stateId:
                                requestedAddress.stateCode && requestedAddress.countryCode
                                  ? getAddressState({
                                      codes: {
                                        stateCode: requestedAddress.stateCode,
                                        countryCode: requestedAddress.countryCode
                                      }
                                    }).id
                                  : location.address.stateId
                            }
                          }
                        }

                        modifyParentObject(location)

                        if (!isLocationValid(location)) {
                          showWarningModalWindow({
                            title: 'Location is not valid'
                          })
                          setFetching(false)

                          return
                        }

                        saveLocation(
                          {
                            ...expandedItemContext,
                            parentItem: location,
                            modifiedLists: {
                              ...modifiedLists,
                              [EntityType.location]: {
                                ...modifiedLists[EntityType.location],
                                [location.id]: location
                              }
                            }
                          },
                          true
                        )
                      })
                      .catch(() => {
                        setFetching(false)

                        showErrorModalWindow({
                          title: 'Location search error'
                        })
                      })
                  }
                }}
              />
            ),
            buttons: [
              {
                label: 'Cancel'
              },
              {
                label: 'Apply',
                disabled: true
              }
            ]
          })
        }
      } else {
        setFetching(false)
        return showErrorModalWindow({
          content: 'Location with the entered address is not found in Google'
        })
      }
    }
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
