import styled from 'styled-components'

export const StyledTabActions = styled.div`
  height: 48px;
  display: flex;
  align-items: stretch;
  background-color: #e5edfb;
  cursor: default;
`
export const IconWithText = styled.div`
  display: flex;
  align-items: center;
  color: #4f4f4f;
  font-size: 14px;
  line-height: 17px;
  padding: 0 18px;

  &:before {
    font-size: 22px;
    margin-right: 8px;
  }
`
export const Button = styled(IconWithText)`
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`
export const DarkButton = styled(Button)``
export const BlueButton = styled(Button)`
  color: #4555d0;
`
export const GreenButton = styled(Button)`
  color: #3fb68e;
`
