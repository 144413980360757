import { transformAmount } from './transformAmount'

export const transformMoney = (
  _amount: number | string,
  hideThousandSeparators?: boolean
): string | null | undefined => {
  if (!(typeof _amount === 'number' || typeof _amount === 'string')) {
    return undefined
  }

  try {
    const amount = Number(_amount)

    const transform = (money: number): string => {
      return hideThousandSeparators
        ? transformAmount(money.toFixed(2))
        : transformAmount(money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    }

    return (amount < 0 ? '-$ ' : '$ ') + transform(Math.abs(amount))
  } catch (e) {
    return undefined
  }
}
