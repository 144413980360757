import * as React from 'react'
import { DetailsViewHeader } from '../DetailsView/DetailsViewHeader'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { IconName } from '../Icon'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { CreateNotificationPopup } from './CreateNotificationPopup'

export const NotificationsViewHeader = () => {
  const dispatch = useAppDispatch()

  const onCreateNotificationClick = React.useCallback(() => {
    showModalWindow({
      width: 600,
      title: 'Create Notification',
      closeButton: true,
      content: <CreateNotificationPopup />,
      buttons: [undefined]
    })
  }, [])

  const CreateNotoficationButton = React.useCallback(() => {
    return (
      <ActionButton filled={true} round={true} styles={{ marginRight: 16 }} onClick={onCreateNotificationClick}>
        Create Notification
      </ActionButton>
    )
  }, [onCreateNotificationClick])

  return (
    <DetailsViewHeader
      title={'All Drivers Notifications'}
      closeButtonId={'notifications'}
      extraRightBlock={<CreateNotoficationButton />}
      onCloseButtonClick={() => leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.closeDetailsView()))}
      closeButtonIcon={IconName.CLOSE_DETAILS}
    />
  )
}
