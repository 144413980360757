import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { RouteBuilderMoveGroup } from './RouteBuilderMoveGroup'
import { isChangeableMoveGroupsOrder } from '../../functions/movesOrder'
import { setDoubleMove, unsetDoubleMove, setBobtailDoubleMove } from '../../epics/routeBuilder'
import { useRouteBuilder } from '../../../../hooks/useRouteBuilder'
import { groupDoubleMoves, isDoubleMoveAvailableToConnect } from '../../functions/groupDoubleMoves'
import { filterVisibleMoves } from '../../functions/filterVisibleMoves'
import { TransportationActivityViewDTO } from '../../../../api/api'
// tslint:disable:max-line-length
import { IMoveDTO, RouteBuilderGridView } from '../../../../store/reducers/communicationHub/interfaces'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectActivities } from '../../../../store/select/activitySelect'

type Props = {
  view: RouteBuilderGridView
  moves: IMoveDTO[]
  onReplaceGroupPositions: (newMoveArray: IMoveDTO[]) => void
}

export const RouteBuilderMoves = React.memo((props: Props) => {
  const { moves, view, onReplaceGroupPositions } = props
  const transportationActivityMapping = useAppSelector(selectActivities) as Record<
    string,
    TransportationActivityViewDTO
  >
  const { setFetch, activeTabDate } = useRouteBuilder()
  const connectMoveIds = React.useCallback(
    (ids: string[], isBobtailConnection: boolean) => {
      setFetch(true)
      const setBobtailDoubleRequest = isBobtailConnection ? setBobtailDoubleMove : setDoubleMove

      return setBobtailDoubleRequest(ids).finally(() => setFetch(false))
    },
    [setFetch]
  )
  const disconnectDoubleMove = React.useCallback(
    (move: IMoveDTO) => {
      const ids = [move.id, move.prevDoubleMoveId].filter(Boolean)
      setFetch(true)
      unsetDoubleMove(ids).finally(() => setFetch(false))
    },
    [setFetch]
  )
  const visibleGroupedMoves = React.useMemo(() => {
    return groupDoubleMoves(filterVisibleMoves(activeTabDate, moves))
  }, [moves])

  return (
    <Container className={cn('view-' + view, { hasMoves: moves.length })}>
      {visibleGroupedMoves.map((moveGroup, index) => {
        const prevMoveIndex = index - 1
        const nextMoveIndex = index + 1
        const prevMoveGroup = visibleGroupedMoves[prevMoveIndex]
        const nextMoveGroup = visibleGroupedMoves[nextMoveIndex]
        const isDoubleMove = moveGroup.length > 1
        const firstMove = moveGroup[0]
        const doubleMoveAvailableToConnect = isDoubleMoveAvailableToConnect(
          moveGroup,
          nextMoveGroup,
          transportationActivityMapping
        )

        return (
          <RouteBuilderMoveGroup
            key={firstMove.id + firstMove.firstMoveOfDay + firstMove.lastMoveOfDay}
            tabMoves={moves}
            moves={moveGroup}
            moveUp={
              isChangeableMoveGroupsOrder(moveGroup, prevMoveGroup)
                ? () => {
                    visibleGroupedMoves[index] = visibleGroupedMoves[prevMoveIndex]
                    visibleGroupedMoves[index] = visibleGroupedMoves[prevMoveIndex]
                    visibleGroupedMoves[prevMoveIndex] = moveGroup
                    onReplaceGroupPositions(
                      visibleGroupedMoves.reduce((acc, currGroup) => {
                        acc.push(...currGroup)
                        return acc
                      }, [])
                    )
                  }
                : undefined
            }
            moveDown={
              isChangeableMoveGroupsOrder(moveGroup, nextMoveGroup)
                ? () => {
                    visibleGroupedMoves[index] = visibleGroupedMoves[nextMoveIndex]
                    visibleGroupedMoves[nextMoveIndex] = moveGroup
                    onReplaceGroupPositions(
                      visibleGroupedMoves.reduce((acc, currGroup) => {
                        acc.push(...currGroup)
                        return acc
                      }, [])
                    )
                  }
                : undefined
            }
            isDoubleMove={isDoubleMove}
            connectInDoubleMoves={
              doubleMoveAvailableToConnect.doubleMove || doubleMoveAvailableToConnect.bobtail
                ? () =>
                    connectMoveIds(
                      [moveGroup[moveGroup.length - 1].id, nextMoveGroup[0].id],
                      doubleMoveAvailableToConnect.bobtail
                    )
                : undefined
            }
            transportationActivityMapping={transportationActivityMapping}
            disconnectDoubleMove={disconnectDoubleMove}
          />
        )
      })}
    </Container>
  )
})

const Container = styled.div`
  &.hasMoves {
    flex-grow: 1;
    overflow: auto;
  }

  &.view-${RouteBuilderGridView.list} {
    background-color: #f5f6fa;
    padding: 0 15px 0 0;
  }
`
