import { CustomerViewDTO } from '../../../api/api'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { isBusinessPartnerValid } from '../../functions/test/isBusinessPartnerValid'
import { isContactValid } from '../../functions/test/isContactValid'
import { isCustomerValid } from '../../functions/test/isCustomerValid'
import { isSubClientValid } from '../../functions/test/isSubClientValid'
import { getModifiedCustomerEntities } from '../../functions/get/getModifiedCustomerEntities'

export const checkCustomerValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { parentItem, modifiedLists } = expandedItemContext
  const customer = parentItem as CustomerViewDTO

  const data = getModifiedCustomerEntities({ customer, modifiedLists })

  return (
    isCustomerValid(customer) &&
    data.subClient.every(isSubClientValid) &&
    data.businessPartner.every(bp => isBusinessPartnerValid(bp)) &&
    data.contact.every(isContactValid)
  )
}
