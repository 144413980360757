import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const locationAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('LOCATION/ASYNC');
import { FetchAPI, FetchArgs, Address, Contact, LocationDetailsDto, LocationDto, DateISOString } from './api';
export interface ILocationLocationByPlaceIdUsingGetRequest { placeId: string, options?: any }
export type ILocationLocationByPlaceIdUsingGetSuccess = LocationDetailsDto;
export type ILocationLocationByPlaceIdUsingGetError = any;
export const locationLocationByPlaceIdUsingGetAction = locationAsyncActionCreator.async<
  ILocationLocationByPlaceIdUsingGetRequest, ILocationLocationByPlaceIdUsingGetSuccess, ILocationLocationByPlaceIdUsingGetError
>('LOCATION_LOCATIONBYPLACEIDUSINGGET');

export interface ILocationLocationByPostalCodeUsingGetRequest { postalCode: string, options?: any }
export type ILocationLocationByPostalCodeUsingGetSuccess = LocationDetailsDto;
export type ILocationLocationByPostalCodeUsingGetError = any;
export const locationLocationByPostalCodeUsingGetAction = locationAsyncActionCreator.async<
  ILocationLocationByPostalCodeUsingGetRequest, ILocationLocationByPostalCodeUsingGetSuccess, ILocationLocationByPostalCodeUsingGetError
>('LOCATION_LOCATIONBYPOSTALCODEUSINGGET');

export interface ILocationSearchLocationUsingGetRequest { term: string, options?: any }
export type ILocationSearchLocationUsingGetSuccess = LocationDto[];
export type ILocationSearchLocationUsingGetError = any;
export const locationSearchLocationUsingGetAction = locationAsyncActionCreator.async<
  ILocationSearchLocationUsingGetRequest, ILocationSearchLocationUsingGetSuccess, ILocationSearchLocationUsingGetError
>('LOCATION_SEARCHLOCATIONUSINGGET');

export interface ILocationSearchPostalCodeUsingGetRequest { term: string, options?: any }
export type ILocationSearchPostalCodeUsingGetSuccess = LocationDetailsDto[];
export type ILocationSearchPostalCodeUsingGetError = any;
export const locationSearchPostalCodeUsingGetAction = locationAsyncActionCreator.async<
  ILocationSearchPostalCodeUsingGetRequest, ILocationSearchPostalCodeUsingGetSuccess, ILocationSearchPostalCodeUsingGetError
>('LOCATION_SEARCHPOSTALCODEUSINGGET');

export interface IRootGetServerStartInfoUsingGetRequest { options?: any }
export type IRootGetServerStartInfoUsingGetSuccess = string;
export type IRootGetServerStartInfoUsingGetError = any;
export const rootGetServerStartInfoUsingGetAction = locationAsyncActionCreator.async<
  IRootGetServerStartInfoUsingGetRequest, IRootGetServerStartInfoUsingGetSuccess, IRootGetServerStartInfoUsingGetError
>('ROOT_GETSERVERSTARTINFOUSINGGET');


