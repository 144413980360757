import { oc } from 'ts-optchain'
import { isNewObject } from '../../index'
import { SellSideQuoteDTO } from '../../../../api/origin/business-logic'
import { callAPIWithErrorMessage, sellSideQuoteAPI } from '../../../../api/api'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'

export const requestSellSideQuoteById = (id: string): Promise<SellSideQuoteDTO> => {
  return callAPIWithErrorMessage(sellSideQuoteAPI.findById, id).then(async requestedSSQ => {
    await pushListItemsToStore({ update: { [EntityType.sellSideQuote]: [requestedSSQ] } })

    return requestedSSQ
  })
}

export const requestUpdateSellSideQuote = (sellSideQuote: SellSideQuoteDTO) => {
  return callAPIWithErrorMessage(sellSideQuoteAPI.update, sellSideQuote.id, transformSSQForSaving(sellSideQuote)).then(
    async requestedSSQ => {
      await pushListItemsToStore({ update: { [EntityType.sellSideQuote]: [requestedSSQ] } })

      return requestedSSQ
    }
  )
}

const transformSSQForSaving = (sellSideQuote: SellSideQuoteDTO): SellSideQuoteDTO => {
  return {
    ...sellSideQuote,
    surcharges: oc(sellSideQuote)
      .surcharges([])
      .map(surcharge => (isNewObject(surcharge) ? { ...surcharge, id: undefined } : surcharge)),
    miscSurcharges: oc(sellSideQuote)
      .miscSurcharges([])
      .map(miscSurcharge => (isNewObject(miscSurcharge) ? { ...miscSurcharge, id: undefined } : miscSurcharge))
  }
}
