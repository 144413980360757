import * as React from 'react'
import { oc } from 'ts-optchain'
import { StyledGeneral } from './styledComponents'
import { Container, GridColumn } from '../../../../../UI/GridSystem'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { ContainerViewDTO, SteamShipLineViewDTO } from '../../../../../../api/origin/business-logic'
import { Select } from '../../../../../UI/DataFields/Select'
import { SteamShipLineSearch } from '../../../../../UI/DataFields/SearchRequest'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { schemaContainerNumber } from '../../../../../../services/yupScheme'
import { containerLists } from '../../../../../../services/select/containerLists'
import { commonLists } from '../../../../../../services/select/commonLists'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemContainer } from '../../../../../../hooks/useGridItemContainer'

type Props = {}

export const ContainerGeneralTab = (props: Props) => {
  const { container, containerType } = useGridItemContainer()
  const { modifyParentObjectField, modifyParentObject } = useExpandedItem()
  return (
    <StyledGeneral minRows={23}>
      <GridColumn flexBasis={'auto'} padding={{ top: 25, left: 30, right: 30 }}>
        <Container columns={10} margin={{ right: 40 }}>
          <InputField.TextMask
            title={'Container #'}
            valid={schemaContainerNumber.isValidSync(container.number)}
            required={true}
            transformOnInputChange={value => (value ? value.toUpperCase() : value)}
            value={container.number}
            textMask={[
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/,
              /(\d|[a-zA-Z])/
            ]}
            onChange={modifyParentObjectField('number')}
          />
        </Container>
        <Container margin={{ right: 40 }}>
          <FieldTemplate.ContainerTypeSearch
            id={oc(containerType).id()}
            title={'Type'}
            required={true}
            onChange={data => {
              modifyParentObjectField('containerTypeId')(oc(data).id())
            }}
          />
        </Container>
        <Container columns={10} margin={{ right: 40 }}>
          <Input title={'Specs'} maxLength={1024} value={container.specs} onChange={modifyParentObjectField('specs')} />
        </Container>
        <Container columns={4} isFixed={true} margin={{ right: 20 }}>
          <InputField.ThousandSeparator
            title={'MCW'}
            placeholder={'00000'}
            value={container.weight}
            onChange={weight => modifyParentObjectField('weight')(weight ? Number(weight) : null)}
          />
        </Container>
        <Container columns={4} isFixed={true} margin={{ right: 40 }}>
          <Select
            title={'Unit'}
            selectedValue={container.weightUnit}
            list={commonLists.weightUnit}
            onSelect={modifyParentObjectField('weightUnit')}
          />
        </Container>
        <Container columns={10} margin={{ right: 40 }}>
          <Select
            title={'Ownership'}
            required={true}
            selectedValue={container.ownership}
            list={containerLists.ownership}
            onSelect={(ownership: ContainerViewDTO.OwnershipEnum) =>
              ownership === ContainerViewDTO.OwnershipEnum.SHIPPEROWNED
                ? modifyParentObjectField('ownership')(ownership)
                : modifyParentObject({
                    ...container,
                    ownership,
                    steamShipLineId: null,
                    steamShipLine: undefined
                  })
            }
          />
        </Container>
        <Container columns={10}>
          {container.ownership === ContainerViewDTO.OwnershipEnum.LINEOWNED && (
            <SteamShipLineSearch
              title={'SSL'}
              required={true}
              id={container.steamShipLineId}
              onChange={(ssl: SteamShipLineViewDTO) => {
                modifyParentObjectField('steamShipLineId')(oc(ssl).id())
              }}
            />
          )}
        </Container>
      </GridColumn>
    </StyledGeneral>
  )
}
