import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { EquipmentDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useEquipment = (props: Props): EquipmentDTO => {
  const { id, modifiedLists } = props
  const equipment = useListItem({ id, type: EntityType.equipment, modifiedLists })

  return equipment
}
