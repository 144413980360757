import * as React from 'react'

type Props = {
  initialState?: boolean
  capture?: boolean
  withoutOutsideClick?: boolean
  hideOnScroll?: boolean
}

export const useOutsideClick = (props?: Props) => {
  const { initialState, capture, withoutOutsideClick, hideOnScroll } = props || {}
  const wrapperRef = React.useRef(null)
  const [statePopover, setPopover] = React.useState(initialState || false)
  const togglePopover = React.useCallback(() => setPopover(_ => !_), [])

  if (hideOnScroll) {
    React.useEffect(() => {
      if (!statePopover) {
        return
      }

      const handleScroll = () => {
        setPopover(false)
      }

      document.addEventListener('scroll', handleScroll, true)

      return () => {
        document.removeEventListener('scroll', handleScroll, true)
      }
    }, [statePopover])
  }

  if (!withoutOutsideClick) {
    React.useEffect(() => {
      const handleClick = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setPopover(false)
        }
      }

      document.addEventListener('click', handleClick, capture !== undefined ? capture : true)
      return () => {
        document.removeEventListener('click', handleClick, capture !== undefined ? capture : true)
      }
    }, [])
  }

  return { statePopover, togglePopover, wrapperRef, setPopoverState: setPopover }
}
