import * as React from 'react'
import { Contact } from './contact'
import { Container } from '../../../UI/GridSystem'
import { ColumnTitles, AddRow } from './styledComponents'
import { useContactsTab } from '../../../../hooks/useContactsTab'

type Props = {}

const extraContainerStyles = { overflow: 'auto scroll' }

export const ContactsPreviewTab = React.memo((props: Props) => {
  const { contacts, createContact } = useContactsTab()

  return (
    <Container
      style={extraContainerStyles}
      isGrid={true}
      background={'#f9f9f9'}
      rows={70}
      padding={{ top: 20, left: 20, right: 20 }}
    >
      {Boolean(contacts && contacts.length) && (
        <>
          <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 10 }}>
            <Container columns={3} isFixed={true}>
              Primary
            </Container>
            <Container columns={11} isFixed={true}>
              First Name
            </Container>
            <Container columns={11} isFixed={true}>
              Last Name
            </Container>
            <Container columns={9} isFixed={true}>
              Phone
            </Container>
            <Container columns={9} isFixed={true}>
              Phone 2
            </Container>
            <Container columns={11} isFixed={true}>
              Email
            </Container>
            <Container columns={9} isFixed={true}>
              Fax
            </Container>
            <Container columns={9}>Notes</Container>
          </ColumnTitles>

          {contacts.map(contact => (
            <Contact key={contact.id} contact={contact} />
          ))}
        </>
      )}

      <AddRow rows={9} alignCenter={true} justifyCenter={true} margin={{ bottom: 20 }} onClick={createContact}>
        Add Contact
      </AddRow>
    </Container>
  )
})
