import * as React from 'react'
import { StyledDropdown, Group, InputSearch, InputSearchContainer } from './styles'
import { ListItem } from './ListItem'
import { WindowPopover } from '../../windowPopover'
import ReactTooltip from 'react-tooltip'

export interface IDropdownItem {
  disabled?: boolean
  label: string
  htmlLabel?: string | JSX.Element
  value: string | any
  hint?: string
  onMouseOver?: () => void
  onMouseOut?: () => void
}

type Props = {
  wrapperRef: any
  search?: boolean
  searchValue: string
  setSearchValue: (value: string) => void
  mutuallyExclusive?: (string | IDropdownItem)[] | (string | IDropdownItem | (string | IDropdownItem)[])[]
  multiselect: boolean
  selectedValue: string | string[]
  list: IDropdownItem[][]
  onClick: (value: string | string[]) => void
  style?: object
}

export const Dropdown = (props: Props) => {
  const {
    wrapperRef,
    search,
    searchValue,
    setSearchValue,
    multiselect,
    style,
    list,
    selectedValue,
    onClick,
    mutuallyExclusive
  } = props
  const ref = React.useRef(null)

  const onItemClick = (value: string) => {
    if (multiselect) {
      if (selectedValue && Array.isArray(selectedValue)) {
        let resultCheckedValues: string[] = []

        if (selectedValue.includes(value)) {
          resultCheckedValues = selectedValue.filter(_ => _ !== value)
        } else {
          // >>> Mutually Exclusive
          if (mutuallyExclusive && mutuallyExclusive.length) {
            resultCheckedValues = [...selectedValue]

            const checkArrayOfMutuallyExclusiveValues = (mutuallyExclusiveArray: (string | IDropdownItem)[]) => {
              if (!mutuallyExclusiveArray || !mutuallyExclusiveArray.length) {
                return
              }

              const mutuallyExclusiveList = mutuallyExclusiveArray.map(_ => (typeof _ === 'string' ? _ : _.value))
              const hasMutuallyExclusiveItem = selectedValue.some(_ => mutuallyExclusiveList.includes(_))
              const newValueIsMutuallyExclusive = mutuallyExclusiveList.includes(value)

              if (hasMutuallyExclusiveItem && newValueIsMutuallyExclusive) {
                resultCheckedValues = resultCheckedValues.filter(_ => !mutuallyExclusiveList.includes(_))
              }
            }

            const testMutuallyExclusiveList = mutuallyExclusive.filter((item: any) => {
              if (Array.isArray(item)) {
                checkArrayOfMutuallyExclusiveValues(item)
                return false
              }

              return true
            })

            checkArrayOfMutuallyExclusiveValues(testMutuallyExclusiveList as (string | IDropdownItem)[])

            resultCheckedValues = [...resultCheckedValues, value]
            // <<<
          } else {
            resultCheckedValues = [...selectedValue, value]
          }
        }

        return onClick(resultCheckedValues.length ? resultCheckedValues : null)
      } else {
        return onClick([value])
      }
    } else {
      return onClick(value)
    }
  }

  const filteredList =
    multiselect || !selectedValue
      ? list
      : list.map((groupList: IDropdownItem[]) =>
          groupList.filter(
            _ =>
              _.value !== selectedValue &&
              (!search || !Boolean(searchValue) || _.label.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
          )
        )

  const sortedList =
    multiselect && selectedValue
      ? filteredList.reduce((acc: IDropdownItem[][], curr, index) => {
          const firstGroup = index === 0
          const selectedGroup: IDropdownItem[] = firstGroup ? [] : acc[0]
          const group = curr.reduce((innerAcc, innerCurr) => {
            if (selectedValue.includes(innerCurr.value)) {
              // push to selected group
              selectedGroup.push(innerCurr)
              return innerAcc
            }

            // do search filter
            if (Boolean(searchValue) && innerCurr.label.toUpperCase().indexOf(searchValue.toUpperCase()) === -1) {
              return innerAcc
            }

            return [...innerAcc, innerCurr]
          }, [])

          return firstGroup ? [selectedGroup, ...acc, group] : [...acc, group]
        }, [])
      : Boolean(searchValue)
      ? filteredList.map(group => group.filter(_ => _.label.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1))
      : filteredList

  return (
    <>
      <WindowPopover bindToRef={wrapperRef} containerStyles={extraContainerStyles}>
        <StyledDropdown ref={ref} style={search ? { ...(style || {}), paddingTop: 0 } : style}>
          {search && (
            <InputSearchContainer>
              <InputSearch
                autoFocus={true}
                value={searchValue}
                placeholder={'Search'}
                onChange={e => setSearchValue(e.target.value)}
              />
            </InputSearchContainer>
          )}
          {sortedList.map((itemProps: IDropdownItem[], index) => (
            <Group key={index}>
              {itemProps.map(groupItem => (
                <ListItem
                  key={groupItem.label + groupItem.value}
                  {...groupItem}
                  multiselect={multiselect}
                  isSelected={selectedValue && multiselect ? selectedValue.includes(groupItem.value) : false}
                  onClick={() => onItemClick(groupItem.value)}
                />
              ))}
            </Group>
          ))}
        </StyledDropdown>
      </WindowPopover>
      <ReactTooltip
        id={'select-hint'}
        className={'react-tooltip pre-line react-tooltip_no-opacity'}
        place={'right'}
        backgroundColor={'white'}
        textColor={'rgb(50, 62, 78)'}
        borderColor={'#e5e5e5'}
        border={true}
      />
    </>
  )
}

const extraContainerStyles = { zIndex: 999 }
