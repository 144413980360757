import { oc } from 'ts-optchain'
import { RootState } from '..'
import { CustomerViewDTO } from '../../api/api'

// SELECT ONE
export const selectCustomer = (id: string) => (state: RootState): CustomerViewDTO | undefined => {
  const {
    lists: { customer }
  } = state

  return customer[id]
}

export const selectCustomerName = (id: string) => (state: RootState): string | undefined => {
  const { lists } = state

  const customer = lists.customer[id]

  if (customer && customer.businessPartnerId) {
    const businessPartner = lists.businessPartner[customer.businessPartnerId]

    return oc(businessPartner).legalName() || oc(customer).name()
  }

  return oc(customer).name()
}

// SELECT MANY
export const selectCustomers = (state: RootState): Record<string, CustomerViewDTO> => {
  return state.lists.customer
}
