import { IMoveDTO } from '../../../communicationHub/interfaces'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.move]: IMoveDTO[]
}

export const parseMoves = async (moves: IMoveDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.move]: []
  }

  try {
    moves.forEach(move => {
      result[EntityType.move].push(move)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
