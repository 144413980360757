import * as React from 'react'
import { DispatchDeliveryOrderTabContent } from '../styles'
import LeftBlock from './Left'
import CenterBlock from './Center'
import RightBlock from './Right'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'
import { isFullObject } from '../../../../../../services/functions/test/isFullObject'

export const General = () => {
  const { dispatchDeliveryOrder } = useGridItemDDO()

  return (
    <DispatchDeliveryOrderTabContent style={{ display: 'flex' }}>
      {isFullObject(dispatchDeliveryOrder) && (
        <>
          <LeftBlock />
          <CenterBlock />
          <RightBlock />
        </>
      )}
    </DispatchDeliveryOrderTabContent>
  )
}
