import * as React from 'react'
import { Title, BoldLine } from '../../styles'

type Props = { title: string; value: string }

export const Window = ({ title, value }: Props) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      {title}
    </Title>
    <BoldLine className={'inTwoLines'} rows={3} maxRows={7}>
      {value}
    </BoldLine>
  </>
)
