import * as React from 'react'
import styled from 'styled-components'
import { CustomerInfo } from './CustomerInfo'
import { PrimaryContact } from '../../../../contact/views/PrimaryContact'
import { useGridItemCustomer } from '../../../../../../hooks/useGridItemCustomer'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

type Props = {}

export const General = (props: Props) => {
  const { businessPartner } = useGridItemCustomer()

  return (
    <Container>
      <CustomerInfo />
      <PrimaryContact
        parentEntity={businessPartner}
        entityType={EntityType.businessPartner}
        titleStyles={{ height: 25, minHeight: 25, maxHeight: 25 }}
      />
    </Container>
  )
}

const Container = styled.div`
  min-height: 350px;
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  grid-column-gap: 40px;
  padding: 25px 60px 0 60px;
`
