import { TransportationActivityViewDTO } from '../../../api/api'
import { relatedActivityTypes } from '../../../constants/relatedActivityTypes'

type Props = {
  transportationActivities: TransportationActivityViewDTO[]
  phaseType: 'pickup' | 'delivery' | 'pick' | 'return'
}

export const getRelatedActivityType = (props: Props): TransportationActivityViewDTO => {
  const { transportationActivities, phaseType } = props

  switch (phaseType) {
    case 'pickup':
      return transportationActivities.find(
        _ =>
          _.template &&
          _.stage === TransportationActivityViewDTO.StageEnum.PICKUP &&
          relatedActivityTypes[TransportationActivityViewDTO.StageEnum.PICKUP].includes(_.type)
      )
    case 'delivery':
      return transportationActivities.find(
        _ =>
          _.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
          _.template &&
          _.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
          relatedActivityTypes[TransportationActivityViewDTO.StageEnum.DELIVERY].includes(_.type)
      )
    case 'pick':
      return transportationActivities
        .slice()
        .reverse()
        .find(
          _ =>
            _.template &&
            _.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
            (_.type === TransportationActivityViewDTO.TypeEnum.PICKUPFULL ||
              _.type === TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY)
        )
    case 'return':
      return transportationActivities
        .slice()
        .reverse()
        .find(
          _ =>
            _.template &&
            _.stage === TransportationActivityViewDTO.StageEnum.RETURN &&
            relatedActivityTypes[TransportationActivityViewDTO.StageEnum.RETURN].includes(_.type)
        )
    default:
      return null
  }
}
