import * as R from 'remeda'
import { BusinessPartnerViewDTO, SteamShipLineViewDTO } from '../../../../../api/api'
import { parseBusinessPartners } from './parseBusinessPartners'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { mergeParsedLists } from './mergeParsedLists'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'

export const parseSteamShipLines = async (steamShipLines: SteamShipLineViewDTO[]): Promise<TListsStateUpdate> => {
  const businessPartnersToParse: BusinessPartnerViewDTO[] = []
  const result = generateEmptyListsState()

  try {
    steamShipLines.forEach(steamShipLine => {
      if (steamShipLine.businessPartner) {
        businessPartnersToParse.push(steamShipLine.businessPartner)
      }

      result[EntityType.steamShipLine].push(R.omit(steamShipLine, ['businessPartner']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (businessPartnersToParse.length) {
    await parseBusinessPartners(businessPartnersToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}
