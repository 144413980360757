import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { CommunicationHubSuggestionsContext } from '../contexts/CommunicationHubSuggestionsContext'
import { UserDTO, UserType } from '../api/origin/user-service'
import { TransportationActivityViewDTO, DispatchDeliveryOrderViewDTO } from '../api/origin/business-logic'
import { callAPI, dispatchDeliveryOrderAPI } from '../api/api'
import { requestDispatchDeliveryOrderById } from '../components/common/dispatchDeliveryOrder/epics'
import { useAppSelector } from '../hooks/useAppSelector'
import { selectUsers } from '../store/select/userSelect'
import { getListsState } from '../store'
import { useDispatchDeliveryOrder } from '../hooks/useDispatchDeliveryOrder'
import { EntityType } from '../store/reducers/lists/interfaces'

type Props = {
  dispatchDeliveryOrderId: string
  children: any
}

export const CommunicationHubSuggestionsProvider = React.memo((props: Props) => {
  const { children, dispatchDeliveryOrderId } = props
  const contactMapping = useAppSelector(selectUsers)
  const dispatchDeliveryOrder = useDispatchDeliveryOrder({ id: dispatchDeliveryOrderId })
  const [assignedVendorAuthIds, setAssignedVendorAuthIds] = React.useState<string[]>([])
  const [contacts, setContacts] = React.useState<UserDTO[]>([])

  React.useEffect(() => {
    if (!dispatchDeliveryOrder) {
      requestDispatchDeliveryOrderById(dispatchDeliveryOrderId)
    }
  }, [dispatchDeliveryOrderId, dispatchDeliveryOrder])

  React.useEffect(() => {
    if (Array.isArray(oc(dispatchDeliveryOrder).activityIds())) {
      const storeLists = getListsState()
      const driverMapping = storeLists[EntityType.driver]
      const activities = dispatchDeliveryOrder.activityIds
        .map(activityId => storeLists.activity[activityId])
        .filter(Boolean)
      const vendorAuthIds: string[] = R.uniq(
        activities.reduce((acc, activity) => {
          const vendorId = oc(activity as TransportationActivityViewDTO).vendorId()
          const vendor = driverMapping[vendorId]
          const vendorAuthUserId = oc(vendor).authUserId()

          if (vendorAuthUserId) {
            acc.push(vendorAuthUserId)
          }

          return acc
        }, [])
      )

      setAssignedVendorAuthIds(vendorAuthIds)
    } else {
      if (dispatchDeliveryOrderId) {
        callAPI(dispatchDeliveryOrderAPI.findVendors, dispatchDeliveryOrderId)
          .toPromise()
          .then(vendors => {
            setAssignedVendorAuthIds(vendors.map(vendor => vendor.authUserId).filter(Boolean))
          })
      }
    }
  }, [dispatchDeliveryOrder, dispatchDeliveryOrderId])

  React.useEffect(() => {
    const dispatchDeliveryOrderStatus = oc(dispatchDeliveryOrder).status()
    const operators = Object.values(contactMapping).filter(contact => contact.type === UserType.OPERATOR)
    const drivers = [
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED,
      DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED,
      DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED
    ].includes(dispatchDeliveryOrderStatus)
      ? []
      : assignedVendorAuthIds
          .map(authId => contactMapping[authId])
          .filter(Boolean)
          .filter(contact => contact.type === UserType.DRIVER)

    setContacts(drivers.concat(operators))
  }, [contactMapping, assignedVendorAuthIds, oc(dispatchDeliveryOrder).status()])

  return (
    <CommunicationHubSuggestionsContext.Provider
      value={{
        contacts
      }}
    >
      {children}
    </CommunicationHubSuggestionsContext.Provider>
  )
})
