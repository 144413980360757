import { oc } from 'ts-optchain'
import { getCommunicationHubState, getDispatch, getApplicationTabsState } from '../../../../store'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { CommunicationHubTab } from '../../../../store/reducers/communicationHub/interfaces'
import { leaveChatChannelIfNoEditing } from '../../../CommunicationHub/functions/leaveChatChannelIfNoEditing'
import { SchedulerFilter } from '../interfaces'
import { updateTabFilter } from '../../../../store/reducers/tabs/functions'

export const toggleSyncDriverActivitiesWithRouteBuilder = (state: boolean) => {
  const { activeRouteBuilder, activeTab } = getCommunicationHubState()
  const tabs = getApplicationTabsState()
  const currentTab = tabs.find(tab => tab.active)

  if (!currentTab) {
    return
  }

  const filter = oc(currentTab)
    .uiSettings.filter([])
    .filter(({ column }) => column !== SchedulerFilter.syncWithRouteBuilder)
    .concat({ column: SchedulerFilter.syncWithRouteBuilder, value: state })

  if (state === true && !(activeTab === CommunicationHubTab.routeBuilder && oc(activeRouteBuilder).vendorId())) {
    leaveChatChannelIfNoEditing(() =>
      getDispatch()(communicationHubActions.setActiveTab({ activeTab: CommunicationHubTab.routeBuilder, expand: true }))
    )
  }

  updateTabFilter(currentTab.id, filter)
}
