import * as React from 'react'
import { TroubleTicketActions } from './TroubleTicketActions'
import { DriverStatusColumnHint } from './DriverStatusColumnHint'
import { NotificationTagHint } from './NotificationTagHint'

export const Hints = () => {
  return (
    <>
      <TroubleTicketActions />
      <DriverStatusColumnHint />
      <NotificationTagHint />
    </>
  )
}
