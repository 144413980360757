import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../styles/theme'

type Props = {
  style?: React.CSSProperties
  children?: any
}

export const InputErrorIcon = (props: Props) => {
  const { children, style } = props

  return (
    <Container style={style}>
      <Icon className={'mdi mdi-alert-circle'} />
      <Text>{children}</Text>
    </Container>
  )
}

const Icon = styled.div`
  font-size: 16px;
  color: ${theme.colors.defaultRed};
`
const Text = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 100%;
  left: 100%;
  color: #000;
  font-size: 12px;
  border-radius: 4px 4px 4px 0px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 8px 10px;
`
const Container = styled.div`
  position: relative;

  &:not(:hover) ${Text} {
    display: none;
  }
`
