import * as React from 'react'
import styled from 'styled-components'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { SsqBsqWidgetColumnHeader } from './SsqBsqWidgetColumnHeader'
import { BsqWidgetRow } from './BsqWidgetRow'
import { sortBsqItemsListByActivityType } from '../../../../../services/functions/sort/sortBsqItemsListByActivityType'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'

export const BsqWidgetColumn = React.memo(() => {
  const {
    quoteAmounts: { buySideQuoteTotalAmount },
    buySideQuotes
  } = useSsqBsqWidget()
  const { activities } = useGridItemDDO()
  const [expandedBsqId, setExpandedBsqId] = React.useState<string>(null)

  return (
    <Container>
      <SsqBsqWidgetColumnHeader quoteType={'BSQ'} total={buySideQuoteTotalAmount} />
      {sortBsqItemsListByActivityType({
        buySideQuotes,
        transportationActivities: activities.transportationActivities
      }).map(buySideQuote => {
        const isExpanded = expandedBsqId === buySideQuote.id

        return (
          <BsqWidgetRow
            key={buySideQuote.id}
            isExpanded={isExpanded}
            toggleExpand={() => setExpandedBsqId(isExpanded ? null : buySideQuote.id)}
            buySideQuote={buySideQuote}
          />
        )
      })}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
