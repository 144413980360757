import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { Window } from './preview'
import { Popover } from './popover'
import { LocationViewDTO } from '../../../../../api/origin/business-logic'
import { makeLocationTitle } from '../../../../../services/DTO/location/functions'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'
import { isLocationValid } from '../../../../../services/functions/test/isLocationValid'
import { useLocation } from '../../../../../hooks/useLocation'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'

type Props = {
  widgetTitle: string
  shadowColor?: string
  id: string | undefined
  changeLocationId: (id: string) => void
  disabled?: boolean
  disableEditing?: boolean
  isShortName?: boolean
  isCompanyName?: boolean
}

const LocationWidget = (props: Props) => {
  const { widgetTitle, shadowColor, changeLocationId, id, disableEditing, disabled, isShortName, isCompanyName } = props
  const { modifiedLists } = useExpandedItem()
  const location = useLocation({ id, modifiedLists })
  const isModifiedMode = Boolean(oc(modifiedLists)[EntityType.location][id]())

  const previewProps = {
    isInvalid: isLocationGEOValid(location) === false,
    boldText: oc(location).name(),
    locationCompany: '',
    locationAddress: '',
    locationType: oc(location).type(),
    mismatchIndicator: oc(location).mismatchIndicator(),
    terminalIntegration: oc(location).terminalIntegration(),
    partialIndicator: oc(location).status(LocationViewDTO.StatusEnum.FULL) === LocationViewDTO.StatusEnum.PARTIAL
  }

  if (isCompanyName && oc(location).careOf() && oc(location).name() !== oc(location).careOf()) {
    previewProps.locationCompany = oc(location).careOf()
  }

  if (location) {
    previewProps.locationAddress = isFullObject(location)
      ? generateAddressLabel(oc(location).address())
      : [oc(location).address.street(), oc(location).cityStateZip()].filter(Boolean).join(', ')

    if (isShortName) {
      previewProps.boldText = isFullObject(location)
        ? oc(location).code() || oc(location).name() || makeLocationTitle(location)
        : oc(location).shortName() || oc(location).name()
    }
  }

  return (
    <Widget
      disable={disabled && !previewProps.isInvalid}
      red={previewProps.isInvalid}
      title={widgetTitle}
      highlighted={isFullObject(location) && !isLocationValid(location)}
      shadowColor={shadowColor}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={previewProps}
      detailsProps={{
        disableEditing,
        location,
        changeLocationId,
        isModifiedMode
      }}
    />
  )
}

export default LocationWidget
