import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { oc } from 'ts-optchain'
import { ColumnFooter, ResizeColumnButton, StyledColumn } from '../../../Grid/styles'
import { CreateRate } from './CreateRate'
import { Header } from './Header'
import { Body } from './Body'
import { CustomerQuoteDTO } from '../../../../../../../api/origin/qmp-service'
import {
  ColumnSize,
  IQMPFilters,
  IRateFiltersState,
  NewRateDTO,
  QMPTab,
  RateType,
  RateState,
  SellSideQuoteRateGroupDTO
} from '../../../../interfaces'
import { TNewCustomerQuotes } from '../../../../../../../services/DTO/customerQuote/interfaces'
import { saveGroupsOfRates, saveRates, updateRateFiltersOnSave, validateRate } from '../functions'
import { ActionButton } from '../../../../../../UI/Buttons/ActionButton'
import { ActionsSaveCancel } from '../../../../../../UI/Buttons/ActionsSaveCancel'
import { makeNewRates, matchRates, validateSave } from '../../../functions'
import { ColumnInfo } from '../../CustomerQuote/table/body/Preview/Column/ColumnInfo'
import { isNewObject } from '../../../../../../../services/DTO'
import { RateDTO } from '../../../../../../../services/DTO/rate/interfaces'
import {
  calculateCustomerQuoteProps,
  customerQuoteSelectedRatesFieldName,
  isDatesOfSelectedRatesValid
} from '../../CustomerQuote/functions'
import { GridItemSpinner } from '../../../../../../UI/Spinner/Spinner'
import { StyledColumnInfo } from '../../CustomerQuote/table/body/Preview/Column/styles'
import { useQMP } from '../../../../../../../hooks/useQMP'
import { createCustomerQuote } from '../../../../../../../services/DTO/customerQuote/reducer'
import { getListsState } from '../../../../../../../store'

type OwnProps = {
  tabType: QMPTab.RATE | QMPTab.CUSTOMERQUOTE
  rateType: RateType
  filters: IQMPFilters
  readOnly?: boolean
  rateIds: string[]
  newRates: NewRateDTO[]
  oppositeNewRates: NewRateDTO[]
  extraData?: any
  columnSize?: ColumnSize
  onResizeClick?: (props: RateType) => void
  // Customer Quote Props
  // newCustomerQuotes?: TNewCustomerQuotes
  groupsOfRates?: SellSideQuoteRateGroupDTO[]
  customerQuote?: TNewCustomerQuotes | CustomerQuoteDTO
  isNewCustomerQuotes?: boolean
  resultOfGroupsSaving?: (results: {
    rejectedRates: NewRateDTO[]
    resolvedRates: RateDTO[]
    duplicateRates?: RateDTO[]
    isError: boolean
  }) => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Column = React.memo((props: Props) => {
  const bodyRef = React.useRef(null)
  const {
    rateIds,
    newRates,
    oppositeNewRates,
    tabType,
    filters,
    rateType,
    columnSize,
    onResizeClick,
    readOnly,
    customerQuote,
    resultOfGroupsSaving,
    extraData,
    groupsOfRates,
    isNewCustomerQuotes
  } = props
  const {
    setNewRates,
    setFilters,
    setNewCustomerQuotes,
    setSSQIds,
    setBSQIds,
    updateQMPTabData,
    setNewSSQRates,
    setNewBSQRates
  } = useQMP()
  const appliedFilters = React.useMemo(() => filters[QMPTab.RATE], [filters[QMPTab.RATE]])
  const isCustomerQuoteParent = Boolean(customerQuote)
  const [fetching, setFetching] = React.useState(false)
  const enableCreateNew = React.useMemo(() => {
    switch (columnSize) {
      case ColumnSize.wideSSQ:
        return rateType === RateType.ssq
      case ColumnSize.wideBSQ:
        return rateType === RateType.bsq
      case ColumnSize.default:
        return true
      default:
        return true
    }
  }, [columnSize])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  })

  const updateRateInSelectedList = (rate: NewRateDTO, justUpdateState?: boolean) => {
    // if (!isCustomerQuoteParent || !isNewCustomerQuotes) {
    //   return
    // }
    // if (justUpdateState) {
    //   return newCustomerQuotes.updateSelectedRate(rate, rateType)
    // }
    //
    // const selectedRateTypes = {
    //   [SellSideQuoteRateDTO.TypeEnum.BASE]: false,
    //   [SellSideQuoteRateDTO.TypeEnum.FUEL]: false,
    //   [SellSideQuoteRateDTO.TypeEnum.ALLIN]: false
    // }
    // const selectedRates = isNewCustomerQuotes
    // ? customerQuote[customerQuoteSelectedRatesFieldName[props.rateType]]
    // : []
    // let presentsInSelectedRates = false
    // selectedRates.forEach((currentRate: AnyRateDTO) => {
    //   if (currentRate.id === rate.id) {
    //     presentsInSelectedRates = true
    //     // omit current rate from checking list
    //     return
    //   }
    //   if (currentRate.type === SellSideQuoteRateDTO.TypeEnum.FUEL) {
    //     selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.FUEL] = true
    //   }
    //   if (currentRate.type === SellSideQuoteRateDTO.TypeEnum.BASE) {
    //     selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.BASE] = true
    //   }
    //   if (currentRate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN) {
    //     selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.ALLIN] = true
    //   }
    // })
    // const suitableForListOfSelected =
    //   (rate.type === SellSideQuoteRateDTO.TypeEnum.FUEL &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.FUEL] &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.ALLIN]) ||
    //   (rate.type === SellSideQuoteRateDTO.TypeEnum.BASE &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.BASE] &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.ALLIN]) ||
    //   (rate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.ALLIN] &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.BASE] &&
    //     !selectedRateTypes[SellSideQuoteRateDTO.TypeEnum.FUEL])
    //
    // if (presentsInSelectedRates) {
    //   if (!suitableForListOfSelected) {
    //     // unselect
    //     newCustomerQuotes.selectRate(rate, rateType)
    //   } else {
    //     newCustomerQuotes.updateSelectedRate(rate, rateType)
    //   }
    // } else if (suitableForListOfSelected) {
    //   // select
    //   // newCustomerQuotes.selectRate(rate, rateType)
    // }
  }

  const editRate = React.useCallback(
    (rate: NewRateDTO, type = rateType) => {
      updateRateInSelectedList({ ...rate, state: RateState.edit }, true)
      setNewRates(
        type,
        newRates.map(r => (r.id === rate.id ? { ...r, state: RateState.edit } : { ...r, state: RateState.new }))
      )
    },
    [newRates]
  )

  const handleCancelEditing = React.useCallback(
    (newRate: NewRateDTO) => {
      setNewRates(rateType, newRates.map(r => ({ ...r, state: RateState.new })))

      updateRateInSelectedList({ ...newRate, state: RateState.new }, true)
    },
    [newRates, rateType]
  )

  const applyNewRate = React.useCallback(
    (newRate: NewRateDTO) => {
      const rate = {
        ...newRate,
        state: RateState.new
      }
      const ratesExcludingEditedOne = newRates.filter(({ id }) => id !== rate.id)
      const checkResult = matchRates([...ratesExcludingEditedOne, rate], isCustomerQuoteParent)

      if (checkResult) {
        return
      }

      const isNewRate = newRates.some(({ id }) => id === rate.id)
      setNewRates(rateType, isNewRate ? newRates.map(r => (r.id === rate.id ? rate : r)) : [...newRates, rate])

      updateRateInSelectedList(rate)
    },
    [newRates, rateType]
  )

  const adaptRateTabFilters = React.useCallback(
    tabType === QMPTab.RATE
      ? (resolvedRates: any[]) => {
          const correctFilters = updateRateFiltersOnSave(appliedFilters as IRateFiltersState, resolvedRates)

          if (correctFilters) {
            setFilters({ ...filters, [QMPTab.RATE]: correctFilters })
          }
        }
      : undefined,
    [tabType, appliedFilters, filters]
  )

  const createRates = React.useCallback(
    (newRate: NewRateDTO) => {
      const newRatesToCreate = makeNewRates(
        rateType,
        newRate,
        appliedFilters as IRateFiltersState,
        isCustomerQuoteParent
      )

      // @ts-ignore
      if (matchRates([...newRates, ...newRatesToCreate], isCustomerQuoteParent)) {
        return
      }

      const updatedNewRates = [...newRates, ...newRatesToCreate]

      if (rateType === RateType.ssq) {
        // @ts-ignore
        setNewSSQRates(updatedNewRates)
      } else {
        // @ts-ignore
        setNewBSQRates(updatedNewRates)
      }

      updateRateInSelectedList(newRate)
    },
    [newRates, isCustomerQuoteParent, appliedFilters, rateType]
  )

  return (
    <StyledColumn style={enableCreateNew ? undefined : { maxWidth: '25%' }} className={columnSize}>
      {fetching && <GridItemSpinner />}
      {onResizeClick && (
        <ResizeColumnButton
          data-id={`${rateType}Column-expand`}
          className={'mdi mdi-arrow-expand'}
          onClick={() => onResizeClick(rateType)}
        />
      )}
      <Header title={rateType} isNewCustomerQuotes={isNewCustomerQuotes} columnSize={columnSize} />
      <Body
        readOnly={readOnly}
        columnSize={columnSize}
        extraBodyStyles={
          isCustomerQuoteParent
            ? rateType === RateType.ssq
              ? isNewCustomerQuotes
                ? extraBodyStyles.padding170
                : extraBodyStyles.padding52
              : isNewCustomerQuotes
              ? extraBodyStyles.padding170
              : undefined
            : undefined
        }
        rateIds={rateIds}
        isNewCustomerQuotes={isNewCustomerQuotes}
        customerQuote={customerQuote}
        rateType={rateType}
        newRates={newRates}
        oppositeNewRates={oppositeNewRates}
        rates={groupsOfRates}
        editRate={editRate}
        bodyRef={bodyRef}
        adaptRateTabFilters={adaptRateTabFilters}
      />
      <ColumnFooter>
        {enableCreateNew && (
          <CreateRate
            isCustomerQuoteParent={isCustomerQuoteParent}
            extraData={extraData}
            rateType={rateType}
            appliedFilters={appliedFilters}
            newRates={newRates}
            validate={validateRate(appliedFilters, customerQuote, rateType)}
            onCreate={createRates}
            onApply={applyNewRate}
            onCancel={handleCancelEditing}
          />
        )}

        {isCustomerQuoteParent && (
          <>
            {rateType === RateType.ssq && (
              <ColumnInfo
                disabled={!isNewCustomerQuotes}
                updateCustomerQuoteField={(field, value) =>
                  setNewCustomerQuotes(calculateCustomerQuoteProps({ ...customerQuote, [field]: value }))
                }
                effectiveDate={customerQuote.effectiveDate}
                expirationDate={customerQuote.expirationDate}
                amount={customerQuote.amount}
              />
            )}
            {rateType === RateType.bsq && (
              <>
                {isNewCustomerQuotes ? (
                  <StyledColumnInfo />
                ) : (
                  <ActionButton
                    styles={copyButtonStyles}
                    filled={true}
                    onClick={() =>
                      updateQMPTabData({
                        newCustomerQuotes: createCustomerQuote({
                          ...customerQuote,
                          customerId: [
                            {
                              label: oc(getListsState().customer)[customerQuote.customerId].name(),
                              value: customerQuote.customerId
                            }
                          ],
                          containerTypeId: [customerQuote.containerTypeId],
                          loadType: [customerQuote.loadType],
                          deliveryOrderType: [customerQuote.deliveryOrderType],
                          effectiveDate: null
                        } as any),
                        openedCustomerQuoteId: null
                      })
                    }
                  >
                    Copy Customer Quote
                  </ActionButton>
                )}
              </>
            )}
          </>
        )}

        {!readOnly && (
          <ActionsSaveCancel
            buttonSaveId={`${rateType}-save`}
            buttonCancelId={`${rateType}-cancel`}
            disableSave={!validateSave(rateType, newRates, customerQuote)}
            showCancel={Boolean(newRates.length)}
            onSave={() => {
              setFetching(true)

              setTimeout(() => {
                if (isCustomerQuoteParent) {
                  if (rateType === RateType.ssq && !isDatesOfSelectedRatesValid(customerQuote)) {
                    setFetching(false)
                    return
                  }
                  // @ts-ignore
                  saveGroupsOfRates(rateType, newRates)
                    .then(resultOfGroupsSaving)
                    .finally(() => {
                      // setNewRate(defaultNewRate(isCustomerQuoteParent ? extraRateData : undefined))
                      setFetching(false)
                    })
                } else {
                  saveRates(rateType, newRates)
                    .then(({ resolvedRates, rejectedRates }) => {
                      if (resolvedRates.length) {
                        const ids = [...resolvedRates.map(({ id }) => id), ...rateIds]

                        if (rateType === RateType.ssq) {
                          setSSQIds(ids)
                        } else {
                          setBSQIds(ids)
                        }

                        if (adaptRateTabFilters) {
                          adaptRateTabFilters(resolvedRates)
                        }
                      }

                      setNewRates(rateType, rejectedRates.length ? rejectedRates : [])
                    })
                    .finally(() => {
                      // setNewRate(defaultNewRate(isCustomerQuoteParent ? extraRateData : undefined))
                      setFetching(false)
                    })
                }
              })
            }}
            onCancel={() => {
              // setNewRate(defaultNewRate(isCustomerQuoteParent ? extraRateData : undefined))
              setNewRates(rateType, [])

              // remove new rates from selected list
              const selectedList: any[] = isNewCustomerQuotes
                ? // @ts-ignore
                  (customerQuote as TNewCustomerQuotes)[customerQuoteSelectedRatesFieldName[props.rateType]] || []
                : []
              const removedNewRates = selectedList.filter((rate: any) => !isNewObject(rate))
              if (selectedList.length && selectedList.length !== removedNewRates.length) {
                setNewCustomerQuotes(
                  calculateCustomerQuoteProps({
                    ...customerQuote,
                    [customerQuoteSelectedRatesFieldName[props.rateType]]: removedNewRates
                  })
                )
              }
            }}
          />
        )}
      </ColumnFooter>
    </StyledColumn>
  )
})

const copyButtonStyles: React.CSSProperties = {
  position: 'absolute',
  left: 20,
  bottom: 12
}

const extraBodyStyles = {
  padding170: { bottom: 170 },
  padding52: { bottom: 52 }
}
