import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { ActiveChatChannelIdSpecialTypes } from '../../../../store/reducers/communicationHub/interfaces'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectNotificationsIsNotifications } from '../../../../store/select/notificationSelect'
import { NotificationSectionIcon } from '../../../UI/icons/NotificationSectionIcon'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NavigationNotifications = (props: Props) => {
  const {} = props
  const dispatch = useAppDispatch()
  const isNotificationsActive = useAppSelector(selectNotificationsIsNotifications)

  const onNotificationsClick = () => {
    leaveChatChannelIfNoEditing(() =>
      dispatch(
        communicationHubActions.setActiveChannel({
          activeChannel: {
            id: ActiveChatChannelIdSpecialTypes.NOTIFICATIONS,
            updateUnreadCount: false,
            isRecentOpenedChannel: false
          },
          omitRecentChannelsUpdate: true
        })
      )
    )
  }

  return (
    <NavigationChannelContainer
      className={cn({ active: isNotificationsActive, 'has-unread-messages': false })}
      onClick={isNotificationsActive ? undefined : onNotificationsClick}
    >
      <Box style={{ marginRight: 13 }}>
        <NotificationSectionIcon color={isNotificationsActive ? '#06182F' : undefined} />
      </Box>
      <ChannelName>All Drivers Notifications</ChannelName>
      <ChannelIndicators />
    </NavigationChannelContainer>
  )
}

const Box = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

const IconBox = styled(Box)`
  height: 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.active,
  &:hover {
    color: white;
  }

  &:hover {
    opacity: 0.7;
  }

  &.active {
    height: 24px;
    opacity: 1 !important;
  }
`
const IconMute = styled(IconBox)`
  font-size: 16px;
  opacity: 0.3;
  padding: 0 3px;
  cursor: pointer;
`

export const NavigationChannelContainer = styled.div`
  position: relative;
  min-height: 38px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #c9cddf;
  border-radius: 4px 0 0 4px;
  padding: 3px 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);

    ${IconMute} {
      height: 24px;
    }
  }

  &:not(.active).has-unread-messages {
    font-weight: 700;
    color: white;
  }

  &.active {
    background-color: #fcfdff;
    color: #06182f;
    cursor: default;
  }

  &:not(.active) .user-icon:before {
    border-color: #06182f;
  }
`
const ChannelNameTextLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ChannelName = styled(ChannelNameTextLine)`
  line-height: 1.2;
  padding-right: 4px;
`
const FirstLineChannelName = styled(ChannelNameTextLine)`
  font-size: 12px;
  line-height: 14px;
`
const SecondLineChannelName = styled(ChannelNameTextLine)`
  font-size: 11px;
  font-weight: 300;
  line-height: 1;

  &.placeholderLongLine {
    font-weight: 400;
    letter-spacing: -3px;
  }
`
const ChannelIndicators = styled(Box)`
  margin-left: auto;
`
export const UnreadMentionMessagesCount = styled.div`
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 500;
  background-color: ${theme.colors.defaultRed};
  border-radius: 8px;
  padding: 0 3px;
`
const LocationPlaceholderLine = styled.span`
  display: inline-flex;
  align-items: center;

  &::after {
    content: '――――';
  }
`
const LastActivityDate = styled.div`
  font-size: 11px;
  color: rgba(177, 181, 193, 0.9);
  user-select: none;

  ${NavigationChannelContainer}.active & {
    color: rgba(68, 83, 102, 0.8);
  }
`
