import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { OngoingActivityDTO, TransportationActivityViewDTO } from '../../../../../../../api/api'
import { activityDirectory } from '../../../../../../../services/DTO/activity/directory'
import { TCalcPhase } from '../../../../functions'
import { Column, TextLine } from './PreviewPhase'
import theme from '../../../../../../../styles/theme'
import { isGotoActivity as testIsGotoActivity } from '../../../../../../../services/functions/test/isGotoActivity'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../../../store/select/driverSelect'
import { openDDOinNewTab } from '../../../../../../../services/DTO/dispatchDeliveryOrder'
import { preventEvent } from '../../../../../../../services/functions/basic'

// tslint:disable:max-line-length

type Props = {
  phase: TCalcPhase
  isNowSection?: boolean
  isExtraActivity?: boolean
  isTwoActivitiesView?: boolean
}

export const PreviewPhaseTypeColumn = React.memo((props: Props) => {
  const { phase, isNowSection, isTwoActivitiesView, isExtraActivity } = props
  const { stage, isActive, isPending, isAssigned } = phase
  const activity = isExtraActivity ? phase.extraActivity : phase.activity
  const isStreetTurn = (activity.comboType || activity.type) === OngoingActivityDTO.TypeEnum.STREETTURN
  const isShuttle = activity.shuttle
  const driverId = oc(activity).driverId()
  const driverName = useAppSelector(selectDriverName(driverId))
  const isGotoActivity = testIsGotoActivity(activity)
  const activityType = activity.comboType || activity.type
  const activityComboType =
    activityDirectory.type[activityType] || activityDirectory.documentationType[activityType] || ''
  const hasDDOLink = Boolean(activity.bobtailToDdoId && activity.bobtailToDdoNumber)
  const isBobtail = activity.type === TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO

  return (
    <Column
      data-id={[
        `phase-activityType`,
        `${isTwoActivitiesView ? (isExtraActivity ? 'topA' : 'bottomA') : 'a'}ctivityType=${activityType}`,
        `isGotoActivity=${isGotoActivity}`,
        driverId && `activityDriverId=${driverId}`,
        driverName && `activityDriverName=${driverName}`
      ]
        .filter(Boolean)
        .join(' ')}
      style={isActive ? { order: 3 } : undefined}
      data-copy-value-on-mousedown={true}
    >
      <TextLine
        data-id={`activityStatus=${activity.status}`}
        style={{
          whiteSpace: 'unset',
          lineHeight: 1,
          fontSize: isTwoActivitiesView ? 11 : 12,
          fontWeight: 500,
          color: activity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL ? 'red' : undefined
        }}
      >
        {!isTwoActivitiesView && (isStreetTurn || isShuttle) && (
          <>
            <Icon
              style={
                isActive || isPending || isAssigned
                  ? {
                      background: activityDirectory.stageColor[stage]
                    }
                  : undefined
              }
            >
              {isShuttle ? <SHIcon /> : isStreetTurn ? <STIcon /> : undefined}
            </Icon>
            {isShuttle && <br />}
          </>
        )}

        {isStreetTurn && (
          <>
            {activityDirectory.documentationStatus[activity.status]}
            <br />
          </>
        )}

        {hasDDOLink ? (
          <BobtailDDOLink
            onClick={event => {
              preventEvent(event)
              openDDOinNewTab({ ddoId: activity.bobtailToDdoId, ddoNumber: activity.bobtailToDdoNumber })
            }}
          >
            DDO #{activity.bobtailToDdoNumber}
          </BobtailDDOLink>
        ) : (
          <span
            style={
              isActive || isPending || isAssigned ? { color: activityDirectory.stageStrongColor[stage] } : undefined
            }
          >
            {isGotoActivity && isBobtail ? (
              <>{activity.comboType !== TransportationActivityViewDTO.ComboTypeEnum.EMPTY ? activityComboType : null}</>
            ) : (
              <>
                {activityComboType.includes(activityDirectory.type[TransportationActivityViewDTO.TypeEnum.GOTO])
                  ? activityComboType.replace(activityDirectory.type[TransportationActivityViewDTO.TypeEnum.GOTO], '')
                  : activityComboType}
              </>
            )}
          </span>
        )}
      </TextLine>
    </Column>
  )
})

export const ActivityIcon = styled.div`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #b2b2b2;
  margin-right: 8px;

  svg,
  span {
    fill: #fff;
  }
`

const Icon = styled.div`
  flex-shrink: 0;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #b2b2b2;
  padding: 0 4px;
  margin: 0 4px 4px 0;
  color: white;
  user-select: none;
`

const SHIcon = styled.span`
  color: white;
  font-weight: 400;
  font-size: 16px;
  user-select: none;

  &:before {
    content: 'SH';
  }
`
const STIcon = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: white;
  user-select: none;

  &:before {
    content: 'ST';
  }
`

export const ShuttleIcon = styled.span`
  color: white;
  font-size: 17px;
  user-select: none;

  &:before {
    content: 'SH';
  }
`

export const StreetTurnIcon = styled.span`
  color: white;
  font-weight: 500;
  font-size: 12px;
  user-select: none;

  &:before {
    content: 'ST';
  }
`

export const BobtailDDOLink = styled.span`
  white-space: nowrap;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
