import * as React from 'react'
import { BusinessPartnerViewDTO, ContactDTO, CustomerViewDTO, SubClientViewDTO } from '../api/api'

export interface IGridItemSubClientContext {
  isExpanded: boolean
  subClient: SubClientViewDTO
  businessPartner: BusinessPartnerViewDTO
  primaryContact: ContactDTO
  customer: CustomerViewDTO
  customerBusinessPartner: BusinessPartnerViewDTO
}

export const GridItemSubClientContext = React.createContext<IGridItemSubClientContext>({
  isExpanded: null,
  subClient: null,
  businessPartner: null,
  primaryContact: null,
  customer: null,
  customerBusinessPartner: null
})
