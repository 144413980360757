import * as React from 'react'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../../../api/origin/qmp-service'
import { AnyRateDTO, NewRateDTO, RateType, RateState } from '../../../../interfaces'
import { TNewCustomerQuotes } from '../../../../../../../services/DTO/customerQuote/interfaces'
import { createId } from '../../../../../../../services/utils'
import { Checkbox, Radio } from '../../../../../../UI/Radio'
import { isNewObject } from '../../../../../../../services/DTO'

type TCopyRateToAnotherRateList = {
  rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO
  rateType: RateType
}
export const generateOppositeColumnRateCopy = ({ rate, rateType }: TCopyRateToAnotherRateList): NewRateDTO[] => {
  const newRate: NewRateDTO = {
    ...rate,
    id: createId('copied'),
    state: RateState.new,
    status: SellSideQuoteRateDTO.StatusEnum.NEW,
    duplicateIds: null,
    warningMessage: null,
    warningMessageDescription: null,
    number: null,
    expirationDate: null,
    effectiveDate: null
  }
  const resultRates: NewRateDTO[] = []

  if (rateType === RateType.ssq) {
    // add to BSQ list
    delete newRate.customerId
    // newRate.vendorId = appliedFilters.vendorId
    newRate.vendorId = undefined
    resultRates.push(
      ...(newRate.loadType === SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK
        ? [
            { ...newRate, loadType: BuySideQuoteRateDTO.LoadTypeEnum.DROP },
            { ...newRate, id: createId('copied'), loadType: BuySideQuoteRateDTO.LoadTypeEnum.PICK }
          ]
        : [newRate])
    )
  } else {
    // add to SSQ list
    delete newRate.vendorId
    // newRate.customerId = appliedFilters.customerId
    newRate.customerId = undefined
    resultRates.push(
      (newRate as NewRateDTO).loadType === BuySideQuoteRateDTO.LoadTypeEnum.DROP ||
        (newRate as NewRateDTO).loadType === BuySideQuoteRateDTO.LoadTypeEnum.PICK
        ? { ...newRate, loadType: SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK }
        : newRate
    )
  }

  resultRates[0].state = RateState.edit

  return resultRates
}

export const generateRateCopy = (rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO): NewRateDTO => {
  return {
    ...rate,
    id: createId('copied'),
    state: RateState.edit,
    status: SellSideQuoteRateDTO.StatusEnum.NEW,
    duplicateIds: undefined,
    warningMessage: undefined,
    warningMessageDescription: undefined,
    number: undefined,
    expirationDate: undefined,
    effectiveDate: undefined
  }
}

type TRenderRowCheckMark = {
  rate: AnyRateDTO
  selectedRates: (AnyRateDTO)[]
  rateType: RateType
  newSSQRates: NewRateDTO[]
  customerQuote: TNewCustomerQuotes
  selectRate: (rate: AnyRateDTO, rateType: RateType) => void
}
export const renderRowCheckMark = ({
  rate,
  selectedRates,
  rateType,
  newSSQRates,
  customerQuote,
  selectRate
}: TRenderRowCheckMark): JSX.Element => {
  if (!isNewObject(customerQuote)) {
    return undefined
  }

  // const isNewRate = isNewObject(rate)
  const isSelected = Boolean(selectedRates.find(r => r.id === rate.id))

  const CheckboxComponent =
    rate.type === SellSideQuoteRateDTO.TypeEnum.ALLIN ||
    rate.type === SellSideQuoteRateDTO.TypeEnum.BASE ||
    rate.type === SellSideQuoteRateDTO.TypeEnum.FUEL
      ? Radio
      : Checkbox

  return <CheckboxComponent active={isSelected} onClick={() => selectRate(rate, rateType)} />
}
