import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { moveDirectory } from './directory'
import theme from '../../../../styles/theme'
import { DeliveryOrderDTO, DispatchDeliveryOrderStreetTurnDTO, DispatchDeliveryOrderViewDTO } from '../../../../api/api'
import { ddoDirectory } from '../../../../services/DTO/dispatchDeliveryOrder/directory'
import { getStreetTurnStatus } from '../../../../services/DTO/dispatchDeliveryOrder/functions'
import { StreetTurnActions } from '../../../common/dispatchDeliveryOrder/views/Details/CustomHeader/StreetTurn'
import { openDDOinNewTab } from '../../../../services/DTO/dispatchDeliveryOrder'
import { showDispatchDeliveryOrderEquipmentPopup } from '../../../popups/DispatchDeliveryOrderEquipmentPopup'
import { MoveStatus } from '../../../../store/reducers/communicationHub/interfaces'
import { useDispatchDeliveryOrder } from '../../../../hooks/useDispatchDeliveryOrder'
import { useStreetTurn } from '../../../../hooks/useStreetTurn'
import { useDeliveryOrder } from '../../../../hooks/useDeliveryOrder'

const arrowButtonWidth = 22

type RouteBuilderVendorStatusButtonProps = {
  status: MoveStatus
  smallButton?: boolean
  onButtonClick?(): void
}

export const RouteBuilderVendorStatusButton = React.memo((props: RouteBuilderVendorStatusButtonProps) => {
  const { status, smallButton, onButtonClick } = props

  const RenderButton = React.useMemo(() => {
    if (onButtonClick) {
      if (status === MoveStatus.PLANNED) {
        return (
          <VendorStatusArrowButton
            className={cn('mdi mdi-menu-right')}
            style={{ borderRadius: '0 4px 4px 0', borderLeftWidth: 1, right: 0 }}
            onClick={onButtonClick}
          />
        )
      } else if (status === MoveStatus.ASSIGNED) {
        return (
          <VendorStatusArrowButton
            className={cn('mdi mdi-menu-left')}
            style={{ borderRadius: '4px 0 0 4px', borderRightWidth: 1, left: 0 }}
            onClick={onButtonClick}
          />
        )
      }
    }
    return null
  }, [status, onButtonClick, smallButton])

  return (
    <VendorStatusContainer
      style={{
        height: smallButton ? 20 : undefined,
        backgroundColor: moveDirectory.statusColor[status],
        paddingLeft: onButtonClick && status === MoveStatus.ASSIGNED ? arrowButtonWidth : undefined,
        paddingRight: onButtonClick && status === MoveStatus.PLANNED ? arrowButtonWidth : undefined
      }}
    >
      {RenderButton}
      {moveDirectory.status[status]}
    </VendorStatusContainer>
  )
})

export const RouteBuilderStreetTurnButton = React.memo(
  (props: { dispatchDeliveryOrderId: string; setFetching: (state: boolean) => void }) => {
    const { dispatchDeliveryOrderId, setFetching } = props
    const dispatchDeliveryOrder = useDispatchDeliveryOrder({ id: dispatchDeliveryOrderId })
    const deliveryOrder = useDeliveryOrder({ id: oc(dispatchDeliveryOrder).deliveryOrderId() })
    const streetTurn = useStreetTurn({ id: oc(dispatchDeliveryOrder).streetTurnId() })
    const streetTurnCount = oc(dispatchDeliveryOrder).streetTurnCount()
    const streetTurnStatus = getStreetTurnStatus({ streetTurn, streetTurnCount })
    const isAvailibleStatus = streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE
    const STDDODetails = {
      id: undefined as string,
      number: undefined as number
    }

    if (streetTurn) {
      if (oc(deliveryOrder).type() === DeliveryOrderDTO.TypeEnum.IMPORT) {
        STDDODetails.id = streetTurn.ddoExportId
        STDDODetails.number = streetTurn.ddoExportNumber
      } else if (oc(deliveryOrder).type() === DeliveryOrderDTO.TypeEnum.EXPORT) {
        STDDODetails.id = streetTurn.ddoImportId
        STDDODetails.number = streetTurn.ddoImportNumber
      }
    }

    if (!streetTurnStatus) {
      return null
    }

    const onStreetTurnDetailsClick = () => {
      showDispatchDeliveryOrderEquipmentPopup({
        title: 'Dispatch Delivery Order #' + dispatchDeliveryOrder.number,
        dispatchDeliveryOrderId: dispatchDeliveryOrder.id
      })
    }

    const onActiveStreetTurnClick = () => {
      openDDOinNewTab({
        ddoId: STDDODetails.id,
        ddoNumber: STDDODetails.number
      })
    }

    return (
      <Container>
        <StreetTurnContainer
          className={'mdi mdi-shuffle-variant'}
          onClick={STDDODetails.id ? onActiveStreetTurnClick : onStreetTurnDetailsClick}
          style={{
            padding: '0 4px',
            margin: 0,
            backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus]
          }}
        >
          {isAvailibleStatus ? (
            <>
              <span>{streetTurnCount}</span>ST Available
            </>
          ) : (
            <>
              ST
              {STDDODetails.id && <StreetTurnDDOLink>{STDDODetails.number}</StreetTurnDDOLink>}
            </>
          )}
        </StreetTurnContainer>
        <StreetTurnActions isMinimizedButtons={true} streetTurn={streetTurn} setFetching={setFetching} />
      </Container>
    )
  }
)

export const RouteBuilderDoubleMovesButton = React.memo(
  (props: { text?: string; disconnect?: boolean; onClick: () => any }) => {
    const { text, disconnect, onClick } = props
    return (
      <ConnectMovesContainer className={cn('mdi mdi-swap-vertical', { disconnect })} onClick={onClick}>
        {text || 'Double Moves'}
      </ConnectMovesContainer>
    )
  }
)

const Container = styled.div`
  display: flex;

  .widget-preview {
    padding: 0;
    background: none;
    border: none !important;
  }
`

const Button = styled.div`
  height: 30px;
  display: inline-flex;
  align-items: center;
  background: grey;
  white-space: nowrap;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  padding: 0 12px;
  margin-right: 30px;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 16px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const VendorStatusContainer = styled(Button)`
  position: relative;
  width: 115px;
  align-items: center;
  justify-content: center;
  padding: unset;
  cursor: unset;

  &:hover {
    opacity: unset;
  }
`
const VendorStatusArrowButton = styled.div`
  width: ${arrowButtonWidth}px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 0 solid white;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const StreetTurnDDOLink = styled.div`
  font-weight: 400;
  margin-left: 5px;
  cursor: pointer;
`

const StreetTurnContainer = styled(Button)`
  background-color: #dea01e;

  &:before {
    transform: rotate(-90deg) translateX(1px);
  }

  span {
    margin: 0 8px 0 3px;
  }

  &:hover ${StreetTurnDDOLink} {
    text-decoration: underline;
  }
`

const ConnectMovesContainer = styled(Button)`
  background-color: ${theme.colors.doubleMove};
  user-select: none;

  &:before {
    font-weight: 700;
  }

  &.disconnect {
    background: none;
    border: 1px solid ${theme.colors.defaultRed};
    color: ${theme.colors.defaultRed};

    &:hover {
      color: white;
      background-color: ${theme.colors.defaultRed};
    }
  }
`
