import * as React from 'react'
import styled from 'styled-components'
import { Title } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { Select } from '../../../../../UI/DataFields/Select'
import { AddressDTO } from '../../../../../../api/origin/business-logic/api'
import AddressField from '../../../../../UI/AddressField'
import { customerLists } from '../../../../../../services/select/customerLists'
import { SalesRepresentativeSearch } from '../../../../../UI/DataFields/SearchByList/SalesRepresentativeSearch'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { useGridItemCustomer } from '../../../../../../hooks/useGridItemCustomer'
import { isAddressValid } from '../../../../../../services/functions/test/isAddressValid'

type Props = {}

export const CustomerInfo = (props: Props) => {
  const { customer, businessPartner } = useGridItemCustomer()
  const { modifyParentObjectField, modifyListItems } = useExpandedItem()

  return (
    <div>
      <Title>Customer Info</Title>
      <Container>
        <Column>
          <Input
            title={'Name'}
            required={true}
            maxLength={200}
            value={businessPartner.legalName}
            onChange={(legalName: string) =>
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, legalName }] })
            }
          />
          <AddressField
            title={'Address'}
            required={true}
            address={businessPartner.legalAddress}
            highlighted={businessPartner.legalAddress && !isAddressValid(businessPartner.legalAddress)}
            onChange={(legalAddress: AddressDTO) =>
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, legalAddress }] })
            }
          />
          <Input
            title={'Company'}
            value={businessPartner.dbaName}
            maxLength={200}
            onChange={(dbaName: string) =>
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, dbaName }] })
            }
          />
        </Column>
        <Column>
          <SalesRepresentativeSearch
            title={'Sales Representative'}
            selectedUserId={customer.salesRepUserId}
            onChange={modifyParentObjectField('salesRepUserId')}
          />
          <Column style={{ gridTemplateColumns: '1fr 1fr' }}>
            <Select
              title={'Net Terms Unit'}
              required={true}
              selectedValue={customer.netTermsUnit}
              list={customerLists.netTermsUnit}
              onSelect={modifyParentObjectField('netTermsUnit')}
            />
            <Select
              title={'Net Terms'}
              required={true}
              selectedValue={customer.netTerms}
              list={customerLists.netTerms}
              onSelect={modifyParentObjectField('netTerms')}
            />
          </Column>
          <Input
            title={'Note'}
            value={businessPartner.notes}
            onChange={(notes: string) =>
              modifyListItems({ [EntityType.businessPartner]: [{ ...businessPartner, notes }] })
            }
          />
        </Column>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: calc(66.66% - 12.5px) calc(33.33% - 12.5px);

  label {
    white-space: nowrap;
  }
`
const Column = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-rows: 55px 55px 55px;
`
