import { oc } from 'ts-optchain'
import { UserDTO, UserType } from '../../../api/origin/user-service'
import { IUserAuth } from './interfaces'

export const convertAuthUserDataToUserDTO = (user: IUserAuth): UserDTO => ({
  id: user.sub,
  name: user.name,
  username: user.preferred_username,
  avatar: user.avatar,
  email: user.email,
  type: getUserType(user),
  phoneNumber: user.phone_number,
  firstName: user.given_name,
  lastName: user.family_name,
  mobileApp: user.mobile_app
  /* todo: remove?
  job: oc(user.user_metadata).job(),
  company: oc(user.user_metadata).company()
   */
})

function getUserType(user: IUserAuth): UserType {
  const roles = oc(user).realm_access.roles([])

  switch (true) {
    case roles.includes(UserType.OPERATOR):
      return UserType.OPERATOR
    case roles.includes(UserType.DRIVER):
      return UserType.DRIVER
    default:
  }
}
