import * as React from 'react'
import styled from '../../../../styles/styledComponents'
import { Title } from '../../../UI/styledComponents'
import { Container } from '../../../UI/GridSystem'

type Props = { orderNumber?: string }

export const OrderNumber = ({ orderNumber }: Props) => (
  <Container isGrid={true}>
    <Title>Order #</Title>
    <div>
      <Input id={'do-number'} disabled={true} value={orderNumber || ''} />
    </div>
  </Container>
)

const Input = styled.input`
  display: block;
  width: 100%;
  background: white;
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dfe1eb;
  border-radius: 5px;
  font-size: 18px;
`
