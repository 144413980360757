import * as React from 'react'
import styled from 'styled-components'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { Select } from '../../../../../UI/DataFields/Select'
import { Table } from '../../../../../UI/Table/Table'
import { DeductionDTO, DeductionStatus, DeductionType } from '../../../../../../api/origin/vendor-accounting-service'
import { isNewObject } from '../../../../../../services/DTO'
import { deductionLists } from '../../../../../../services/select/deductionLists'
import { regExpOnlyNumbersAndLetters } from '../../../../../../services/functions/regExp'
import { DownloadIcon } from '../../../../../UI/icons/DownloadIcon'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { ITableRowActionOption, RowActionButtonType } from '../../../../../../contexts/TableContext'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { dateService } from '../../../../../../services/timeService'
import { Note } from '../../../../../UI/DataFields/Note/Note'
import { isDeductionAmountValid } from '../../../../../../services/functions/test/isDeductionAmountValid'
import { getDeductionTotalAmount } from '../../../../../../services/functions/get/getDeductionTotalAmount'
import { deductionDirectory } from '../../../../../../services/DTO/deduction/directory'
import { requsetDudectionTransactionReport } from '../../../../../../services/DTO/deduction/fetch'
import { downloadCsvContent } from '../../../../../../services/functions/downloadCsvContent'

type Props = {}

export const AccountingTabDeductionsTable = (props: Props) => {
  const listRef = React.useRef(null)
  const {
    setSpinnerState,
    isModifiedMode,
    notWeeklyDeductions,
    createDeduction,
    removeDeductionById,
    updateDeductionField,
    setRefundEscrowDeduction,
    setCloseEscrowDeduction,
    setPayBackDeduction
  } = useDriverDeductions()

  React.useEffect(() => {
    if (listRef.current && (notWeeklyDeductions || []).some(isNewObject)) {
      listRef.current.scrollTo(0, listRef.current.scrollHeight)
    }
  }, [])

  const isDeductionEditable = (deduction: DeductionDTO) => {
    return [DeductionStatus.NEW, DeductionStatus.READY, DeductionStatus.ACTIVE].includes(deduction.status)
  }

  const noDeductionTransactions = (deduction: DeductionDTO) => {
    return [DeductionStatus.NEW, DeductionStatus.READY].includes(deduction.status)
  }

  const isDeductionClosed = (deduction: DeductionDTO) => {
    return deduction.status === DeductionStatus.CLOSED
  }

  return (
    <Container>
      <Table
        listRef={listRef}
        maxHeight={305}
        getRowClassName={(deduction: DeductionDTO) => String(deduction.status)}
        settings={[
          {
            label: 'Date',
            isRequired: true,
            getValue: (deduction: DeductionDTO) => (
              <DateTimePicker
                disabled={!noDeductionTransactions(deduction)}
                required={isDeductionEditable(deduction)}
                date={deduction.startDate}
                hideTimeInput={true}
                minDate={dateService.createStringDate.startWorkDay}
                onChange={startDate => updateDeductionField(deduction)('startDate')(startDate || null)}
              />
            ),
            width: 120
          },
          {
            label: 'Type',
            isRequired: true,
            getValue: (deduction: DeductionDTO) => (
              <Select
                disabled={!noDeductionTransactions(deduction)}
                required={isDeductionEditable(deduction)}
                selectedValue={deduction.type}
                list={deductionLists.tableType.map(item =>
                  item.value === DeductionType.ESCROW
                    ? {
                        ...item,
                        disabled:
                          isNewObject(deduction) &&
                          notWeeklyDeductions.some(_ => _.type === DeductionType.ESCROW && !isDeductionClosed(_))
                      }
                    : item
                )}
                mapping={deductionLists.type}
                onSelect={updateDeductionField(deduction)('type')}
              />
            ),
            width: 160
          },
          {
            label: 'Description',
            getValue: (deduction: DeductionDTO) => {
              const disabled =
                deduction.type === DeductionType.ESCROW ? isDeductionClosed(deduction) : !isDeductionEditable(deduction)
              return (
                <Descriotion className={'description'}>
                  <Input
                    disabled={disabled}
                    value={deduction.description}
                    maxLength={35}
                    onChange={updateDeductionField(deduction)('description')}
                  />
                  <Note
                    title={'Description'}
                    placeholder={'Enter your text here'}
                    disabled={disabled}
                    tooltipId={'deduction-note-' + deduction.id}
                    note={deduction.description}
                    onChange={updateDeductionField(deduction)('description')}
                    maxLength={35}
                    styleNoteButton={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      background: 'none',
                      borderRadius: '0 4px 4px 0'
                    }}
                  />
                </Descriotion>
              )
            },
            width: 165,
            maxWidth: '1fr'
          },
          {
            label: 'Principle',
            isRequired: true,
            getValue: (deduction: DeductionDTO) => (
              <InputField.Money
                disabled={!noDeductionTransactions(deduction)}
                required={isDeductionEditable(deduction)}
                value={deduction.principle}
                onChange={updateDeductionField(deduction)('principle')}
              />
            ),
            width: 150
          },
          {
            label: 'Interest %',
            getValue: (deduction: DeductionDTO) =>
              deduction.type !== DeductionType.ESCROW && (
                <InputField.Percentage
                  disabled={!noDeductionTransactions(deduction)}
                  required={isDeductionEditable(deduction)}
                  value={deduction.interest}
                  placeholder={'%'}
                  onChange={updateDeductionField(deduction)('interest')}
                />
              ),
            width: 120
          },
          {
            label: 'Total Amount',
            getValue: (deduction: DeductionDTO) =>
              deduction.type !== DeductionType.ESCROW && (
                <InputField.Money
                  disabled={true}
                  value={typeof deduction.principle === 'number' ? getDeductionTotalAmount(deduction) : undefined}
                  onChange={() => {}}
                />
              ),
            width: 150
          },
          {
            label: 'Current Bal.',
            getValue: (deduction: DeductionDTO) => (
              <InputField.Money disabled={true} value={deduction.currentBalance} onChange={undefined} />
            ),
            width: 150
          },
          {
            label: 'Check Type',
            isRequired: true,
            getValue: (deduction: DeductionDTO) =>
              deduction.type !== DeductionType.ESCROW && (
                <Select
                  disabled={!noDeductionTransactions(deduction)}
                  required={isDeductionEditable(deduction)}
                  selectedValue={deduction.checkType}
                  list={deductionLists.checkType}
                  onSelect={updateDeductionField(deduction)('checkType')}
                />
              ),
            width: 90
          },
          {
            label: 'Check #',
            getValue: (deduction: DeductionDTO) =>
              deduction.type !== DeductionType.ESCROW && (
                <Input
                  disabled={!noDeductionTransactions(deduction)}
                  required={isDeductionEditable(deduction)}
                  maxLength={8}
                  regExp={regExpOnlyNumbersAndLetters}
                  value={deduction.checkNumber}
                  placeholder={'#'}
                  onChange={updateDeductionField(deduction)('checkNumber')}
                />
              ),
            width: 150
          },
          {
            label: 'Ded. Amount',
            isRequired: true,
            getValue: (deduction: DeductionDTO) => {
              const amountValidationError = !isDeductionAmountValid(deduction)

              return (
                <InputField.Money
                  disabled={!isDeductionEditable(deduction)}
                  required={isDeductionEditable(deduction)}
                  highlighted={amountValidationError}
                  errorMessage={
                    amountValidationError ? (
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Ded. Amount can't be bigger than
                        {deduction.type === DeductionType.ESCROW ? ' Principle' : ' Total Amount'}
                      </span>
                    ) : (
                      undefined
                    )
                  }
                  value={deduction.deductionAmount}
                  placeholder={'Weekly'}
                  onChange={updateDeductionField(deduction)('deductionAmount')}
                />
              )
            },
            width: 150
          },
          {
            label: 'Ded. History',
            getValue: (deduction: DeductionDTO) => {
              return (
                <ActionButton
                  disabled={noDeductionTransactions(deduction)}
                  filled={true}
                  onClick={async () => {
                    setSpinnerState(true)
                    const csvFileContent = await requsetDudectionTransactionReport(deduction.id)
                    setSpinnerState(false)
                    downloadCsvContent(csvFileContent, 'report')
                  }}
                >
                  <DeductionDownloadButton>
                    <DownloadIcon /> Download
                  </DeductionDownloadButton>
                </ActionButton>
              )
            },
            width: 135
          }
        ]}
        list={notWeeklyDeductions}
        styleButtonAdd={{ backgroundColor: '#fafbfd' }}
        onButtonAddClick={() => {
          createDeduction()

          setTimeout(() => {
            if (listRef.current) {
              listRef.current.scrollTo(0, listRef.current.scrollHeight)
            }
          })
        }}
        rowActionButton={(deduction: DeductionDTO) => {
          const noTransactions = noDeductionTransactions(deduction)
          const disabled = deduction.status === DeductionStatus.CLOSED
          const isOptions = [
            DeductionType.ESCROW,
            DeductionType.LOAN,
            DeductionType.PDINSURANCE,
            DeductionType.TRUCKREGISTRATION
          ].includes(deduction.type)
          const options: ITableRowActionOption[] = []
          const isEscrow = deduction.type === DeductionType.ESCROW

          if (!isOptions) {
            return {
              type: RowActionButtonType.DELETE,
              disabled: !noTransactions,
              onClick: () => removeDeductionById(deduction.id)
            }
          }

          options.push({
            label: isEscrow ? 'Refund' : 'Pay Back',
            disabled: noTransactions || deduction.currentBalance === 0,
            onClick: () => (isEscrow ? setRefundEscrowDeduction(deduction) : setPayBackDeduction(deduction))
          })

          if (isEscrow) {
            options.push({
              label: `Close ${deductionDirectory.type[deduction.type]}`,
              onClick: () => setCloseEscrowDeduction(deduction)
            })
          }

          if (noTransactions) {
            options.push({ label: 'Delete', onClick: () => removeDeductionById(deduction.id) })
          }

          return {
            type: RowActionButtonType.OPTIONS,
            disabled,
            options
          }
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  .date-time-picker.disabled,
  .input-container.disabled,
  .select-container.disabled {
    background: #f2f3f5;
    border-color: #f2f3f5;
  }

  .tableRow.${DeductionStatus.COMPLETED}, .tableRow.${DeductionStatus.CLOSED} {
    background: #f2f3f5;

    .date-time-picker.disabled,
    .input-container.disabled,
    .select-container.disabled {
      color: #818182;

      * {
        color: #818182;
      }
    }
  }
`

const DeductionDownloadButton = styled.div`
  display: flex;
  align-items: center;
  color: white !important;
  font-size: 11px;
  font-weight: 500;

  svg {
    margin-right: 4px;
  }
`

const Descriotion = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  .input-container {
    padding-right: 27px;
  }
`
