import { oc } from 'ts-optchain'
import { processObject } from '../transform/processObject'

type Props = {
  prevObject: object
  nextObject: object
}

export const getObjectsDifference = (props: Props): Record<string, { before?: any; after?: any }> => {
  const { prevObject, nextObject } = props
  // console.log({
  //   processObject(deepDiffMapper.map(prevObject, nextObject)).hardClean,
  //   prevObject,
  //   nextObject
  // })

  return processObject(deepDiffMapper.map(prevObject, nextObject)).hardClean
}

const deepDiffMapper = (() => {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1: any, obj2: any) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw new Error('Invalid argument. Function given, object expected.')
      }

      const isDate = Boolean(oc(obj1).from() || oc(obj2).from())

      if (isDate) {
        if (
          oc(obj1).from() === oc(obj2).from() &&
          oc(obj1).to() === oc(obj2).to() &&
          oc(obj1).confirmed() === oc(obj2).confirmed()
        ) {
          return null
        }

        return {
          before: obj1,
          after: obj2
        }
      }

      if (this.isValue(obj1) || this.isValue(obj2)) {
        const type = this.compareValues(obj1, obj2)
        // const data = obj1 === undefined ? obj2 : obj1

        if (
          type === this.VALUE_UNCHANGED ||
          this.isObject(obj1) ||
          this.isObject(obj2) ||
          this.isArray(obj1) ||
          this.isArray(obj2)
        ) {
          return null
        }

        return {
          before: obj1 !== null && obj1 !== undefined ? String(obj1) : obj1,
          after: obj2 !== null && obj2 !== undefined ? String(obj2) : obj2
        }
      }

      const diff = {}

      Object.keys(obj1).forEach(key => {
        if (this.isFunction(obj1[key])) {
          return
        }

        let value2 = undefined
        if (obj2[key] !== undefined) {
          value2 = obj2[key]
        }

        // @ts-ignore
        diff[key] = this.map(obj1[key], value2)
      })

      Object.keys(obj2).forEach(key => {
        // @ts-ignore
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          return
        }

        // @ts-ignore
        diff[key] = this.map(undefined, obj2[key])
      })

      return diff
    },
    compareValues(value1: any, value2: any) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED
      }
      return this.VALUE_UPDATED
    },
    isFunction(x: any) {
      return Object.prototype.toString.call(x) === '[object Function]'
    },
    isArray(x: any) {
      return Object.prototype.toString.call(x) === '[object Array]'
    },
    isDate(x: any) {
      return Object.prototype.toString.call(x) === '[object Date]'
    },
    isObject(x: any) {
      return Object.prototype.toString.call(x) === '[object Object]'
    },
    isValue(x: any) {
      return !this.isObject(x) && !this.isArray(x)
    }
  }
})()
