import * as React from 'react'
import { configureStore } from '@reduxjs/toolkit'
import userSliceReducer from './reducers/user'
import listsSliceReducer from './reducers/lists'
import appNotificationsReducer from './reducers/appNotifications'
import communicationHubSliceReducer from './reducers/communicationHub'
import tabsReducer from './reducers/tabs'
import { UserDTO } from '../api/origin/user-service'
import { ICommunicationHub, INotifications } from './reducers/communicationHub/interfaces'
import { ITab, ITabExpandedItem } from './reducers/tabs/interfaces'
import { EntityType, TListsState } from './reducers/lists/interfaces'
import { ContainerTypeDTO, StateDTO } from '../api/api'

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    lists: listsSliceReducer,
    communicationHub: communicationHubSliceReducer,
    tabs: tabsReducer,
    appNotifications: appNotificationsReducer
  }
})

export const getDispatch = (): AppDispatch => store.dispatch

export const newStoreContext: any = React.createContext({})
export const useStore = () => store
export const getNewStore = () => store
export const getNewStoreState = (): RootState => store.getState()

export const getUserState = (): UserDTO => store.getState().user
export const getListsState = (): TListsState => store.getState().lists
export const getStateMapping = (): Record<string, StateDTO> => store.getState().lists[EntityType.state]
export const getContainerTypeMapping = (): Record<string, ContainerTypeDTO> =>
  store.getState().lists[EntityType.containerType]
export const getCommunicationHubState = (): ICommunicationHub => store.getState().communicationHub
export const getNotificationsState = (): INotifications => store.getState().communicationHub.notifications
export const getApplicationTabsState = (): ITab[] => store.getState().tabs
export const getActiveApplicationTabState = (): ITab => store.getState().tabs.find(tab => tab.active)
export const getExpandedItemState = (tabId?: string): ITabExpandedItem => {
  return store.getState().tabs.find(tab => {
    if (tabId) {
      return tabId === tab.id
    }

    return tab.active
  }).expandedItem
}
export const getModifiedListsState = (tabId?: string): Partial<TListsState> => {
  return getExpandedItemState(tabId).modifiedLists
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
