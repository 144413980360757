import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../api/api'
import { useDriver } from './useDriver'
import { useLocation } from './useLocation'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useActivity = (props: Props): TransportationActivityViewDTO | DocumentationActivityDTO => {
  const { id, modifiedLists } = props
  const activity = useListItem({ id, type: EntityType.activity, modifiedLists })
  const vendor = useDriver({ id: oc(activity).vendorId(), modifiedLists })
  const destination = useLocation({ id: oc(activity).destinationId(), modifiedLists })

  if (activity) {
    return { ...activity, vendor, destination }
  }
}
