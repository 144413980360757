import { createSelectListFromDirectory } from './index'
import { equipmentDirectory } from '../DTO/equipment/directory'
import { powerUnitLists } from './powerUnitLists'

const equipmentType = createSelectListFromDirectory(equipmentDirectory.equipmentType)

export const equipmentLists = {
  equipmentType,
  ownership: powerUnitLists.ownership,
  insuranceType: powerUnitLists.insuranceType,
  repairType: powerUnitLists.repairType,
  maintenanceInspectionFrequency: powerUnitLists.maintenanceInspectionFrequency
}
