import * as React from 'react'
import { createSelectListFromDirectory } from './index'
import { locationDirectory } from '../DTO/location/directory'
import { locationTypeIcons } from '../DTO/location'
import styled from 'styled-components'

const TypeIcon = styled.div`
  color: rgb(69, 85, 208);
  margin-right: 5px;
  font-size: 20px;
`

const type = createSelectListFromDirectory(locationDirectory.type)
const typeWithIcons = type.map(item => ({
  ...item,
  htmlLabel: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TypeIcon className={locationTypeIcons[item.value]} />
      {item.label}
    </div>
  )
}))

export const locationLists = {
  type,
  typeWithIcons
}
