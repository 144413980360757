import { SteamShipLineColumn } from '../../../components/common/grid/columnSettings/steamShipLineColumnSettings'
import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'

export const steamShipLineFilterSettings = {
  [SteamShipLineColumn.Name]: {
    name: 'businessPartner.legalName',
    type: ColumnDTO.Type.string
  },
  [SteamShipLineColumn.ReferenceNumber]: {
    name: 'referenceNumber',
    type: ColumnDTO.Type.string
  },
  [SteamShipLineColumn.FreeDetentionAllowance]: {
    name: 'freeDetentionAllowance',
    type: ColumnDTO.Type.number
  },
  [SteamShipLineColumn.PrimaryContact]: {
    name: 'businessPartner.primaryContact.firstName',
    type: ColumnDTO.Type.string
  }
}
