import { GridColumn } from '../../../../../UI/GridSystem'
import theme from '../../../../../../styles/theme'
import styled from '../../../../../../styles/styledComponents'

export const StyledCredentials = styled(GridColumn)`
  font-size: 14px;
  user-select: none;
  display: grid;
  grid-template-columns: 0.7fr 2fr 1fr 1fr;
  grid-gap: 40px;
`
export const Title = styled(GridColumn)`
  align-items: center;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  padding-left: 10px;
  margin-bottom: 20px;
`

export const Date = styled(GridColumn)`
  > div {
    width: 100%;
  }
`

export const CopyAddress = styled(GridColumn)`
  z-index: 3;
  align-items: center;
  height: 20px;
  color: ${theme.colors.basicBlueColor};
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &.disabled {
    color: ${theme.colors.defaultGray};
    cursor: default;
  }
`
