import theme from '../../../../styles/theme'
import { TransportationActivityViewDTO } from '../../../../api/origin/business-logic'
import { MoveStatus } from '../../../../store/reducers/communicationHub/interfaces'

const status: Record<MoveStatus, string> = {
  [MoveStatus.PLANNED]: 'Planned',
  [MoveStatus.ASSIGNED]: 'Assigned',
  [MoveStatus.INPROCESS]: 'In-Process',
  [MoveStatus.CONFIRMED]: 'Confirmed',
  [MoveStatus.REFUSED]: 'Refused',
  [MoveStatus.COMPLETED]: 'Completed',
  [MoveStatus.UNSUCCESSFUL]: 'Unsuccessful'
}

const statusColor: Record<MoveStatus, string> = {
  [MoveStatus.PLANNED]: '#EBCD33',
  [MoveStatus.ASSIGNED]: '#7B61FF',
  [MoveStatus.CONFIRMED]: '#7CCF81',
  [MoveStatus.REFUSED]: '#F5A85F',
  [MoveStatus.INPROCESS]: '#B947FF',
  [MoveStatus.COMPLETED]: '#9BA8B8',
  [MoveStatus.UNSUCCESSFUL]: theme.colors.defaultRed
}

const activityStatusColor: Record<TransportationActivityViewDTO.StatusEnum, string> = {
  [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: statusColor[MoveStatus.PLANNED],
  [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: statusColor[MoveStatus.ASSIGNED],
  [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: statusColor[MoveStatus.CONFIRMED],
  [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]: statusColor[MoveStatus.REFUSED],
  [TransportationActivityViewDTO.StatusEnum.INPROCESS]: statusColor[MoveStatus.INPROCESS],
  [TransportationActivityViewDTO.StatusEnum.COMPLETED]: statusColor[MoveStatus.COMPLETED],
  [TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]: statusColor[MoveStatus.UNSUCCESSFUL]
}

export const moveDirectory = {
  status,
  statusColor,
  activityStatusColor
}
