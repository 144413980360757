import { QMPTab } from '../../../components/common/QMP/interfaces'
import { createTab } from '../../../store/reducers/tabs/functions'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { defaultQMPTabData } from '../../DTO/qmp/defaultTabData'

type OpenRatesTab = {
  filledFilters: any
  extraData?: any
}
export const openQMPRatesTab = (props: OpenRatesTab) => {
  const { filledFilters, extraData } = props
  const defaultData = defaultQMPTabData()

  const tabData = {
    ...defaultData,
    extraData: {
      ...defaultData.extraData,
      [QMPTab.RATE]: extraData || {}
    },
    filters: {
      ...defaultData.filters,
      [QMPTab.RATE]: {
        ...defaultData.filters[QMPTab.RATE],
        ...filledFilters
      }
    }
  }

  createTab(TabDTO.Type.QMP, { data: tabData })
}
