import * as React from 'react'
import styled from 'styled-components'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'

type Props = {
  label: string
  onCreateGroupClick: () => void
}

export const SideBarHeader = (props: Props) => {
  const { label, onCreateGroupClick } = props

  return (
    <Container>
      <Label>{label}</Label>
      <RightSide>
        <ActionButton
          filled={true}
          styles={{ height: 24 }}
          round={true}
          children={'Create Group'}
          onClick={onCreateGroupClick}
        />
      </RightSide>
    </Container>
  )
}

const Container = styled.div`
  flex-shrink: 0;
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(201, 205, 221, 0.3);
  padding: 0 16px;
`

const Label = styled.div`
  color: #445366;
  font-size: 16px;
  font-weight: 500;
`

const RightSide = styled.div`
  margin-left: auto;
`
