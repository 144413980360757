import * as R from 'remeda'
import { BusinessPartnerViewDTO, CustomerViewDTO, SubClientViewDTO } from '../../../../../api/api'
import { parseBusinessPartners } from './parseBusinessPartners'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { mergeParsedLists } from './mergeParsedLists'
import { parseSubClients } from './parseSubClients'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'

export const parseCustomers = async (customers: CustomerViewDTO[]): Promise<TListsStateUpdate> => {
  const businessPartnersToParse: BusinessPartnerViewDTO[] = []
  const subClientsToParse: SubClientViewDTO[] = []
  const result = generateEmptyListsState()

  try {
    customers.forEach(customer => {
      if (customer.businessPartner) {
        businessPartnersToParse.push(customer.businessPartner)
      }

      if (customer.subClients && customer.subClients.length) {
        subClientsToParse.push(...customer.subClients)
      }

      result[EntityType.customer].push(R.omit(customer, ['businessPartner', 'subClients']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (businessPartnersToParse.length) {
    await parseBusinessPartners(businessPartnersToParse).then(mergeParsedLists(result))
  }
  if (subClientsToParse.length) {
    await parseSubClients(subClientsToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}
