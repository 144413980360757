import { AddressDTO } from '../../../api/api'

export const generateAddress = (props?: Partial<AddressDTO>): AddressDTO => ({
  city: null,
  postalCode: null,
  stateId: null,
  street: null,
  street2: null,
  ...(props || {})
})
