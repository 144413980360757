import * as React from 'react'
import * as R from 'remeda'
import DateField from '../../../../../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../../../../../UI/DatePicker/Interfaces'
import { TransportationActivityViewDTO, DateISOString } from '../../../../../../../../../../api/origin/business-logic'
import { ActivityGroupAction } from '../../../../../../../../../../services/DTO/activity/controller'
import { TransportationActivityGroup } from '../../../../../../../../../../services/DTO/activity/interfaces'
import { ActivityColumn, DabbleColumn } from '../../../styles'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'

type Props = {}

export const StartActualDateActivityColumn = (props: Props) => {
  const {
    isLinkedActivityToBobtail,
    isDropBobtail,
    isPickBobtail,
    linkedGotoActivity,
    transportationRow,
    updateActivityRow,
    isUnsuccessful,
    rowNumber,
    stage,
    gotoActivityStartDateRequired,
    businessActivityStartDateRequired,
    gotoActivityStartDateDisabled,
    businessActivityStartDateDisabled
  } = useTransportationActivityRow()

  const updateDate = (isGotoActivity: boolean) => (date?: DateISOString) => {
    const modifiedActivityGroup: TransportationActivityGroup = R.clone(transportationRow.activityGroup)
    const { gotoActivity, businessActivity } = modifiedActivityGroup

    if (isUnsuccessful) {
      if (isGotoActivity) {
        gotoActivity.startActualDate = date
      } else {
        businessActivity.startActualDate = date
      }
    } else if (isGotoActivity) {
      // >>> GOTO ACTIVITY
      if (date) {
        // Date has been changed
        gotoActivity.status = gotoActivity.completionActualDate
          ? TransportationActivityViewDTO.StatusEnum.COMPLETED
          : TransportationActivityViewDTO.StatusEnum.INPROCESS
      } else {
        // Date has been removed
        if (gotoActivity.startActualDate) {
          gotoActivity.status = businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
          gotoActivity.completionActualDate = undefined
          businessActivity.startActualDate = businessActivity.completionActualDate = undefined
        }
      }

      gotoActivity.startActualDate = date
      // <<<
    } else {
      // >>> BUSINESS ACTIVITY
      if (date) {
        // Date has been changed
        businessActivity.status = businessActivity.completionActualDate
          ? TransportationActivityViewDTO.StatusEnum.COMPLETED
          : TransportationActivityViewDTO.StatusEnum.INPROCESS
        gotoActivity.status = TransportationActivityViewDTO.StatusEnum.COMPLETED

        if (!gotoActivity.startActualDate) {
          gotoActivity.startActualDate = date
        }

        if (!gotoActivity.completionActualDate) {
          gotoActivity.completionActualDate = date
        }
      } else {
        // Date has been removed
        if (businessActivity.startActualDate) {
          businessActivity.startActualDate = businessActivity.completionActualDate = undefined
          businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
        }
      }

      businessActivity.startActualDate = date
    }
    // <<<

    updateActivityRow({ ...transportationRow, activityGroup: modifiedActivityGroup }, ActivityGroupAction.update)
  }

  return (
    <DabbleColumn>
      <ActivityColumn
        data-id={[
          'go-to-activity-startActualDate',
          transportationRow.activityGroup.gotoActivity.startActualDate,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={'border-bottom'}
      >
        {!isLinkedActivityToBobtail && (
          <DateField
            disabled={gotoActivityStartDateDisabled}
            required={gotoActivityStartDateRequired}
            showTime={true}
            setTimeNowOnEmpty={true}
            placeholder={'MM/DD/YY'}
            date={transportationRow.activityGroup.gotoActivity.startActualDate}
            dateType={DateTypes.DateISOString}
            onUpdate={updateDate(true)}
          />
        )}
      </ActivityColumn>
      <ActivityColumn
        data-id={[
          'business-activity-startActualDate',
          transportationRow.activityGroup.businessActivity.startActualDate,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
      >
        {!isDropBobtail && (
          <DateField
            disabled={businessActivityStartDateDisabled}
            required={businessActivityStartDateRequired}
            showTime={true}
            setTimeNowOnEmpty={true}
            placeholder={'MM/DD/YY'}
            date={transportationRow.activityGroup.businessActivity.startActualDate}
            dateType={DateTypes.DateISOString}
            onUpdate={updateDate(false)}
          />
        )}
      </ActivityColumn>
    </DabbleColumn>
  )
}
export const startActualDayNeedStatuses = [
  TransportationActivityViewDTO.StatusEnum.INPROCESS,
  TransportationActivityViewDTO.StatusEnum.COMPLETED,
  TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
]
