import * as R from 'remeda'
import { ContactDTO, LocationViewDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.location]: LocationViewDTO[]
  [EntityType.contact]: ContactDTO[]
}

export const parseLocations = async (locations: LocationViewDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.location]: [],
    [EntityType.contact]: []
  }

  try {
    locations.forEach(location => {
      if (location.primaryContact) {
        result[EntityType.contact].push(location.primaryContact)
      }

      result[EntityType.location].push(R.omit(location, ['primaryContact']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
