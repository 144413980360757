import * as React from 'react'
import styled from 'styled-components'
import { MessageDTO } from '../../../../api/origin/communication-hub-service'
import { dateService } from '../../../../services/timeService'
import { SMSStatusIconMapping } from '../../../UI/icons/SMSStatusIconMapping'
import { DeleveryMessageStatusIconMapping } from '../../../UI/icons/DeleveryMessageStatusIconMapping'
import { MessageDeliveryStatus } from '../../../../store/reducers/communicationHub/interfaces'

type TMessageInfo = {
  senderId: string
  senderName: string
  messageCreatedAt: string
  messageUpdatedAt: string
  smsStatus: MessageDTO.SmsStatusEnum
  // deliveryStatus: MessageDeliveryStatus | undefined
  hideModifiedStatus?: boolean
}
export const MessageHeader = React.memo((props: TMessageInfo) => {
  const {
    senderId,
    senderName,
    messageCreatedAt,
    messageUpdatedAt,
    hideModifiedStatus,
    smsStatus
    // deliveryStatus
  } = props
  const isSMS = Boolean(smsStatus)
  const modified = !hideModifiedStatus && messageUpdatedAt && messageCreatedAt !== messageUpdatedAt
  const time = React.useMemo(() => dateService.makeLabel(messageCreatedAt, { onlyTime: true }), [messageCreatedAt])
  const hintData = React.useMemo(
    () =>
      senderId
        ? {
            'data-for': 'user-details-hint',
            'data-tip': senderId
          }
        : {},
    [senderId]
  )

  return (
    <Info>
      <InfoSender {...hintData}>{senderName}</InfoSender>
      <InfoDate>
        {time}
        {modified ? ' (edited)' : ''}
      </InfoDate>
      {isSMS && SMSStatusIconMapping[smsStatus]}
      {/* {deliveryStatus && (
        <div style={isSMS ? { marginLeft: 8 } : undefined}>
          {deliveryStatus && DeleveryMessageStatusIconMapping[deliveryStatus]}
        </div>
      )} */}
    </Info>
  )
})

const Info = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
export const InfoSender = styled.div`
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const InfoDate = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 6px 0 4px;
`
