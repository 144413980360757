import {
  createSelectListFromDirectory,
  createFilteredSelectListFromDirectory as CFSLFD,
  createSelectListWithHTMLFromDirectory
} from './index'
import { quoteDirectory } from '../DTO/sellSideQuote/directory'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../api/origin/qmp-service'
import * as React from 'react'

const calculationType = createSelectListFromDirectory(quoteDirectory.calculationType)
// const surchargeTypes = createSelectListFromDirectory(quoteDirectory.surchargeTypes)
// const surchargeActivityTypes = createSelectListFromDirectory(quoteDirectory.surchargeActivityTypes)
const accountingCategory = createSelectListFromDirectory(quoteDirectory.accountingCategory)
const loadType = createSelectListFromDirectory(quoteDirectory.loadType)
const loadTypeAll = createSelectListFromDirectory(quoteDirectory.loadTypeAll)
// const baseRateTypes = createSelectListFromDirectory(quoteDirectory.baseRateTypes)
const typeOfRate = createSelectListFromDirectory(quoteDirectory.typeOfRate)
const ssqTypeOfRate = createSelectListFromDirectory(quoteDirectory.typeOfRate, [
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLE,
  BuySideQuoteRateDTO.TypeEnum.DEDUCTION,
  BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER
])
const bsqTypeOfRate = createSelectListFromDirectory(quoteDirectory.typeOfRate, [
  SellSideQuoteRateDTO.TypeEnum.PREPULL,
  SellSideQuoteRateDTO.TypeEnum.REDELIVERY,
  SellSideQuoteRateDTO.TypeEnum.STORAGE,
  SellSideQuoteRateDTO.TypeEnum.CONGESTION
])
const type = SellSideQuoteRateDTO.TypeEnum
const calcType = SellSideQuoteRateDTO.CalculationTypeEnum
const targetDirectory = quoteDirectory.calculationType
const calculationTypeOfRateType = {
  ssq: {
    [type.BASE]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.ALLIN]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.FUEL]: CFSLFD([calcType.PERCENTAGE], targetDirectory),
    [type.CHASSIS]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERDAY, calcType.PERHOUR], targetDirectory),
    [type.DROPANDPICKUP]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.TOLLS]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.DETENTION]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERHOUR], targetDirectory),
    [type.PREPULL]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.REDELIVERY]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.STORAGE]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERDAY], targetDirectory),
    [type.OVERWEIGHT]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.HAZMAT]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.REEFER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.LAYOVER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.TANKER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.DRYRUN]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.PERDIEM]: CFSLFD([calcType.PERDAY], targetDirectory),
    [type.DEMURRAGE]: CFSLFD([calcType.PERDAY], targetDirectory),
    [type.SCALE]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERCENTAGE], targetDirectory),
    [type.CONGESTION]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERCENTAGE], targetDirectory),
    [type.BOBTAIL]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory)
  },
  bsq: {
    [type.BASE]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.ALLIN]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.FUEL]: CFSLFD([calcType.PERCENTAGE], targetDirectory),
    [type.CHASSIS]: CFSLFD([calcType.PERDAY, calcType.FIXEDAMOUNT, calcType.PERHOUR], targetDirectory),
    [type.DROPANDPICKUP]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.TOLLS]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.DETENTION]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERHOUR], targetDirectory),
    [type.PREPULL]: CFSLFD([], targetDirectory),
    [type.REDELIVERY]: CFSLFD([], targetDirectory),
    [type.STORAGE]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERDAY], targetDirectory),
    [type.OVERWEIGHT]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.HAZMAT]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.REEFER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.LAYOVER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.TANKER]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.DRYRUN]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERMILE, calcType.PERCENTAGE], targetDirectory),
    [type.PERDIEM]: CFSLFD([calcType.PERDAY], targetDirectory),
    [type.DEMURRAGE]: CFSLFD([calcType.PERDAY], targetDirectory),
    [type.SCALE]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERCENTAGE], targetDirectory),
    [type.SHUTTLE]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.SHUTTLEHAZ]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.SHUTTLEOW]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.SHUTTLEREEFER]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.DEDUCTION]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.DEDUCTIONREEFER]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory),
    [type.CONGESTION]: CFSLFD([calcType.FIXEDAMOUNT, calcType.PERCENTAGE], targetDirectory),
    [type.BOBTAIL]: CFSLFD([calcType.FIXEDAMOUNT], targetDirectory)
  }
}
const loadTypeAllWithHTML = createSelectListWithHTMLFromDirectory({
  [SellSideQuoteRateDTO.LoadTypeEnum.LIVELOAD]: {
    label: 'Live'
  },
  [SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK]: {
    label: 'D&P',
    htmlLabel: (
      <div>
        D&P <p style={{ fontSize: 10, color: '#4555d0', marginTop: 2 }}>SSQ only</p>
      </div>
    )
  },
  [BuySideQuoteRateDTO.LoadTypeEnum.DROP]: {
    label: 'Drop',
    htmlLabel: (
      <div>
        Drop <p style={{ fontSize: 10, color: '#4555d0', marginTop: 2 }}>BSQ only</p>
      </div>
    )
  },
  [BuySideQuoteRateDTO.LoadTypeEnum.PICK]: {
    label: 'Pick',
    htmlLabel: (
      <div>
        Pick <p style={{ fontSize: 10, color: '#4555d0', marginTop: 2 }}>BSQ only</p>
      </div>
    )
  }
})

export const quoteLists = {
  calculationType,
  // surchargeTypes,
  ssqTypeOfRate,
  bsqTypeOfRate,
  // surchargeActivityTypes,
  accountingCategory,
  loadType,
  // baseRateTypes,
  typeOfRate,
  calculationTypeOfRateType,
  loadTypeAll,
  loadTypeAllWithHTML
}
