import { RootState } from '..'
import { StateDTO } from '../../api/api'

// SELECT ONE
export const selectState = (id: string) => (state: RootState): StateDTO | undefined => {
  return state.lists.state[id]
}

// SELECT MANY
export const selectStates = (state: RootState): Record<string, StateDTO> => {
  return state.lists.state
}
