import { IMessage } from '../../../store/reducers/communicationHub/interfaces'

export const clearMessageBlockType = (message: IMessage): IMessage => {
  if (message && message.text && message.text.blocks) {
    return {
      ...message,
      text: { ...message.text, blocks: message.text.blocks.map(block => ({ ...block, type: 'unstyled' })) }
    }
  }

  return message
}
