import * as React from 'react'
import { ILocationGroup } from '../../interfaces'
import { SideBarHeader } from './Header/SideBarHeader'
import { LocationGroupList } from './LocationGroupList'

type Props = {
  groups: ILocationGroup[]
  onGroupSelect: (group: ILocationGroup) => void
}

export const LocationGroups = (props: Props) => {
  const { groups, onGroupSelect } = props

  return (
    <>
      <SideBarHeader label={groups.length + ' Groups'} onCreateGroupClick={() => {}} />
      <LocationGroupList list={groups} onClick={group => onGroupSelect(group)} />
    </>
  )
}
