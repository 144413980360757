import styled from 'styled-components'

export const Groups = styled.div`
  z-index: 0;
  position: relative;
  flex-grow: 1;
  padding: 0 0 0 14px;
  overflow: auto;
`
export const InnerContainer = styled.div`
  position: relative;
  min-width: 100%;
  display: inline-flex;
  flex-direction: column;
`
export const NoActivity = styled.div`
  text-align: center;
  padding-top: 100px;
`
