import { DeliveryOrderViewDTO } from '../../../api/origin/business-logic'

const type = {
  [DeliveryOrderViewDTO.TypeEnum.IMPORT]: 'Import',
  [DeliveryOrderViewDTO.TypeEnum.EXPORT]: 'Export',
  [DeliveryOrderViewDTO.TypeEnum.REPOSITION]: 'Reposition'
}

const columnType = {
  [DeliveryOrderViewDTO.TypeEnum.EXPORT]: 'Exp',
  [DeliveryOrderViewDTO.TypeEnum.IMPORT]: 'Imp',
  [DeliveryOrderViewDTO.TypeEnum.REPOSITION]: 'Repo'
}

export const deliveryOrderDirectory = {
  type,
  columnType
}
