import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { StyledGeneral } from './styledComponents'
import { CustomerInfo } from './CustomerInfo'
import { PrimaryContact } from '../../../../contact/views/PrimaryContact'
import { useGridItemSubClient } from '../../../../../../hooks/useGridItemSubClient'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'

export const General = React.memo(() => {
  const { businessPartner } = useGridItemSubClient()

  return (
    <StyledGeneral minRows={70}>
      <Container minRows={38} margin={{ top: 25, left: 60, right: 60 }}>
        <CustomerInfo />
        <PrimaryContact parentEntity={businessPartner} entityType={EntityType.businessPartner} columns={3} />
      </Container>
    </StyledGeneral>
  )
})
