import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { Title } from './AccountingTab'
import { Select } from '../../../../../UI/DataFields/Select'
import { deductionLists } from '../../../../../../services/select/deductionLists'
import theme from '../../../../../../styles/theme'
import { ToggleButton } from '../../../../../UI/Toggle/ToggleButton'
import { AccountingTabDeductionsTable } from './AccountingTabDeductionsTable'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { DriverViewDTO } from '../../../../../../api/api'

type Props = {}

export const AccountingTabOtherDeductions = (props: Props) => {
  const {
    driver,
    vendorAccounting,
    showClosedDeductions,
    updateVendorAccountingField,
    requestDriverDeductionsWithSpinner,
    toggleShowClosedDeductions
  } = useDriverDeductions()
  const driverType = oc(driver).driverType()

  return driverType === DriverViewDTO.DriverTypeEnum.VENDOR ? (
    <Container />
  ) : (
    <Container>
      <Header>
        <Title
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Other Deductions <RefreshButton className={'mdi mdi-refresh'} onClick={requestDriverDeductionsWithSpinner} />
        </Title>
        <HeaderRightSide>
          <Label style={{ marginRight: 8 }}>Payment Frequency</Label>
          <Select
            disabled={!vendorAccounting}
            containerStyle={{ width: 125, marginRight: 30 }}
            selectedValue={oc(vendorAccounting).frequency()}
            list={deductionLists.frequency}
            onSelect={updateVendorAccountingField('frequency')}
          />

          <ShowClosedButton onClick={toggleShowClosedDeductions}>
            <Label style={{ color: theme.colors.basicBlueColor, fontWeight: 500, marginRight: 4 }}>Show Closed</Label>
            <ToggleButton style={{ marginRight: 4 }} active={showClosedDeductions} />
          </ShowClosedButton>
        </HeaderRightSide>
      </Header>

      <AccountingTabDeductionsTable />
    </Container>
  )
}

const Container = styled.div`
  background-color: #fafbfd;
  padding: 28px 50px 15px 30px;

  .select-container {
    background-color: #fff;
  }
`

const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

const Label = styled.div`
  font-size: 12px;
  color: #6c707d;
  user-select: none;
`

const ShowClosedButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const RefreshButton = styled.div`
  height: 0;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  color: #9ba8b8;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
