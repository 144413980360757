import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.rate]: (SellSideQuoteRateDTO | BuySideQuoteRateDTO)[]
}

export const parseRates = async (rates: (SellSideQuoteRateDTO | BuySideQuoteRateDTO)[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.rate]: []
  }

  try {
    rates.forEach(rate => {
      result[EntityType.rate].push(rate)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
