import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  IAuthorizationState,
  AuthorizationState,
  IActionsToDispatchAfterSuccessfulAuthorizationState
} from '../../../store/store.interface'
import {
  cleanUpDispatchedActionsAfterAuthorization,
  dispatchActionAfterAuthorization,
  authGetTokens,
  logout,
  authClientOrServerError,
  authProcessRedirect
} from '../actions/authServiceActions'
import { getItemFromStorage, StorageKeys } from '../../storageService/storageService'

export const authorizationState: IAuthorizationState = {
  state:
    getItemFromStorage(StorageKeys.AccessToken) && getItemFromStorage(StorageKeys.RefreshToken)
      ? AuthorizationState.Authorized
      : AuthorizationState.Initial
}

export const authorizationReducer = reducerWithInitialState<IAuthorizationState>(authorizationState)
  .cases(
    [authGetTokens.started, authProcessRedirect],
    (state: IAuthorizationState): IAuthorizationState => ({
      ...state,
      state: AuthorizationState.Authorizing
    })
  )
  .case(
    authGetTokens.done,
    (state: IAuthorizationState): IAuthorizationState => ({
      ...state,
      state: AuthorizationState.Authorized
    })
  )
  .case(
    authGetTokens.failed,
    (): IAuthorizationState => ({
      ...authorizationState,
      state: AuthorizationState.FailedWithAuth
    })
  )
  .case(
    authClientOrServerError,
    (state: IAuthorizationState): IAuthorizationState => ({
      ...state,
      state: AuthorizationState.ServerError
    })
  )
  .build()

export const actionsToDispatchAfterSuccessfulAuthorizationReducer = reducerWithInitialState<
  IActionsToDispatchAfterSuccessfulAuthorizationState
>([])
  .case(dispatchActionAfterAuthorization, (state, action) => [...state, action])
  .cases([logout, cleanUpDispatchedActionsAfterAuthorization], () => [])
  .build()
