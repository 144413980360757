import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useAppSelector } from './useAppSelector'
import { selectActivities } from '../store/select/activitySelect'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../api/api'
import { selectLocations } from '../store/select/locationSelect'
import { selectDrivers } from '../store/select/driverSelect'
import { isDocumentationActivity } from '../services/functions/test/isDocumentationActivity'

type Props = {
  ids: string[]
  modifiedLists?: Partial<TListsState>
}

export const useActivities = (props: Props) => {
  const { ids, modifiedLists } = props
  const storeActivitiesMapping = useAppSelector(selectActivities)
  const storeLocationMapping = useAppSelector(selectLocations)
  const storeDriverMapping = useAppSelector(selectDrivers)
  const modifiedActivitiesMapping = oc(modifiedLists)[EntityType.activity]({})
  const modifiedLocationsMapping = oc(modifiedLists)[EntityType.location]({})
  const activities = React.useMemo(() => {
    const activityList = (ids || [])
      .map(id => modifiedActivitiesMapping[id] || storeActivitiesMapping[id])
      .filter(Boolean)
    const transportationActivities: TransportationActivityViewDTO[] = []
    const documentationActivities: DocumentationActivityDTO[] = []

    activityList.forEach(activity => {
      if (isDocumentationActivity(activity)) {
        documentationActivities.push(activity as DocumentationActivityDTO)
      } else {
        const a = activity as TransportationActivityViewDTO
        const destination = modifiedLocationsMapping[a.destinationId] || storeLocationMapping[a.destinationId]

        transportationActivities.push({ ...a, destination })
      }
    })

    return {
      transportationActivities: transportationActivities.sort((a, b) => a.number - b.number),
      documentationActivities: documentationActivities.sort((a, b) => a.number - b.number)
    }
  }, [
    ids,
    oc(modifiedLists)[EntityType.activity](),
    oc(modifiedLists)[EntityType.location](),
    storeActivitiesMapping,
    storeDriverMapping,
    storeLocationMapping
  ])

  return activities
}
