import * as React from 'react'
import styled from 'styled-components'
import { Preview } from './Preview'
import { Details } from './Details'
import { Window } from '../../../../../../UI/Window'
import { OngoingActivityGroupDTO } from '../../../../../../../api/origin/business-logic'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { GridItemDDOProvider } from '../../../../../../../providers/GridItemDDOProvider'

type OwnProps = {
  isFavorite: boolean
  isMissed: boolean
  isNowSection: boolean
  activityGroup: OngoingActivityGroupDTO
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ViewingRowMode = (props: Props) => {
  const { activityGroup, isFavorite, isMissed, isNowSection } = props
  const { clearExpandedItem, isModified, parentItemId } = useExpandedItem()

  return (
    <Window
      // startBelowTabs={true}
      isCommunicationHubVisible={true}
      onButtonCloseClick={isModified ? undefined : clearExpandedItem}
      windowStyles={{ width: '100%', height: '100%', paddingBottom: 65, paddingTop: 52 }}
    >
      <GridItemDDOProvider id={parentItemId}>
        <WindowPreviewContainer className={'details'}>
          <Preview
            isFavorite={isFavorite}
            isMissed={isMissed}
            isNowSection={isNowSection}
            activityGroup={activityGroup}
          />
        </WindowPreviewContainer>
        <Details />
      </GridItemDDOProvider>
    </Window>
  )
}

const WindowPreviewContainer = styled.div`
  z-index: 1111;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 8px 1px 1px;
`
