import { oc } from 'ts-optchain'
import {
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../api/origin/business-logic'
import { callAPIWithErrorMessage, callAPI, dispatchDeliveryOrderAPI, streetTurnAPI } from '../../../../api/api'
import { getActiveApplicationTabState, getListsState } from '../../../../store'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { updateTabData } from '../../../../store/reducers/tabs/functions'

export const tryAddStreetTurnsToStore = (
  items: DispatchDeliveryOrderStreetTurnDTO | DispatchDeliveryOrderStreetTurnDTO[]
) => {
  // correctInsertionObjectsIntoStorage(getStore().getState().streetTurn, items, addToStore)
}

type TFindAllStreetTurnDispatchDeliveryOrders = {
  dispatchDeliveryOrderId: string
  saveToStore?: boolean
}
export const findAllStreetTurnDispatchDeliveryOrders = ({
  dispatchDeliveryOrderId,
  saveToStore = true
}: TFindAllStreetTurnDispatchDeliveryOrders): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(streetTurnAPI.findAll, dispatchDeliveryOrderId)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      if (saveToStore) {
        // parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      }
      return dispatchDeliveryOrders
    })
}

export const requestStreetTurnDispatchDeliveryOrdersForDdoId = (props: {
  dispatchDeliveryOrderId: string
}): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  const { dispatchDeliveryOrderId } = props
  const tabId = getActiveApplicationTabState().id

  return callAPI(streetTurnAPI.findAll, dispatchDeliveryOrderId)
    .toPromise()
    .then(async dispatchDeliveryOrders => {
      const streetTurnMappingByDdoId: Record<string, DispatchDeliveryOrderStreetTurnDTO> = {}

      dispatchDeliveryOrders.forEach(ddo => {
        if (ddo.streetTurn) {
          streetTurnMappingByDdoId[ddo.id] = ddo.streetTurn
        }
      })

      await pushListItemsToStore({ update: { [EntityType.dispatchDeliveryOrder]: dispatchDeliveryOrders } })

      if (Object.keys(streetTurnMappingByDdoId)) {
        updateTabData(tabId, { streetTurnMappingByDdoId })
      }

      return dispatchDeliveryOrders
    })
}

type TStreetTurnRequest = {
  streetTurnId: string
  saveToStore?: boolean
}

export const streetTurnRequests = {
  submit: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.submit, streetTurnId).then(streetTurn => {
      if (saveToStore) {
        tryAddStreetTurnsToStore(streetTurn)
      }

      return streetTurn
    })
  },
  approve: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.approve, streetTurnId).then(streetTurn => {
      if (saveToStore) {
        tryAddStreetTurnsToStore(streetTurn)
      }

      return streetTurn
    })
  },
  cancel: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.cancel, streetTurnId)
  },
  reject: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.reject, streetTurnId)
  }
}
