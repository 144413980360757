import * as React from 'react'
import { StyledCondition, BlockTitle, ConditionInputs } from '../../styles'
import { Select } from '../../../../../../../../../UI/DataFields/Select'
import { Input } from '../../../../../../../../../UI/DataFields/Input'
import { ActionButton } from '../../../../../../../../../UI/Buttons/ActionButton'

export type TRuleCondition = {
  number: string | number
  propertyName: string
  propertyType: string
  operator: string
  conditionType: string
  value: any
}

export const Condition = (props: TRuleCondition) => {
  const { propertyName, conditionType, operator, propertyType, value } = props

  return (
    <StyledCondition>
      <BlockTitle>Condition #{props.number}</BlockTitle>
      <ConditionInputs>
        <div className={'primary'} style={{ width: 250 }}>
          <Select
            title={null ? 'Property' : null}
            placeholder={'Property'}
            list={[]}
            label={propertyName}
            selectedValue={null}
            onSelect={() => {}}
          />
        </div>
        <div style={{ width: 120 }}>
          <Select
            title={null ? 'Property Type' : null}
            placeholder={'Property Type'}
            list={[]}
            label={propertyType}
            selectedValue={null}
            onSelect={() => {}}
          />
        </div>
        <div style={{ width: 120 }}>
          <Select
            title={null ? 'Operator' : null}
            placeholder={'Operator'}
            list={[]}
            label={operator}
            selectedValue={null}
            onSelect={() => {}}
          />
        </div>
        <div style={{ width: 120 }}>
          <Input title={null ? 'Value' : null} placeholder={'Value'} value={value} onChange={() => {}} />
        </div>
      </ConditionInputs>
      <div style={{ marginTop: 25 }}>
        <ActionButton
          disabled={true}
          filled={conditionType === 'ADD'}
          className={'mdi mdi-plus-circle-outline'}
          styles={{ minWidth: 80 }}
          onClick={() => {}}
        >
          Add
        </ActionButton>
        <ActionButton
          filled={conditionType === 'OR'}
          className={'mdi mdi-plus-circle-outline'}
          styles={{ minWidth: 80, marginLeft: 15 }}
          onClick={() => {}}
        >
          Or
        </ActionButton>
      </div>
    </StyledCondition>
  )
}
