import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import { highlightPhrase } from '../../../../services/functions/highlight-phrase'
import { dateService } from '../../../../services/timeService'
import { ddoDirectory } from '../../../../services/DTO/dispatchDeliveryOrder/directory'
import {
  ICHubChannel,
  IDispatchDeliveryOrderSearchResult
} from '../../../../store/reducers/communicationHub/interfaces'
// tslint:disable:max-line-length
import { generateDispatchDeliveryOrderChatChannel } from '../../../../services/functions/generate/generateDispatchDeliveryOrderChatChannel'

type Props = {
  searchItem: IDispatchDeliveryOrderSearchResult
  onClick: (channel: ICHubChannel) => void
  highlight: string
}

export const ChannelBrowserSearchResultItem = React.memo((props: Props) => {
  const { searchItem, onClick, highlight } = props
  // const placeholder = 'Angel Press Drop Empty w/ Chassis (Placeholder)'
  const channel = React.useMemo(() => generateDispatchDeliveryOrderChatChannel(searchItem), [searchItem])
  const handleClick = React.useCallback(() => onClick(channel), [channel, onClick])
  const modifiedAt = React.useMemo(() => dateService.makeLabel(oc(searchItem).updatedAt()) || 'No messages', [
    oc(searchItem).updatedAt()
  ])
  const channelName = React.useMemo(
    () =>
      highlight ? highlightPhrase(oc(channel).channelViewName(''), highlight.split(' ')) : channel.channelViewName,
    [oc(channel).channelViewName(), highlight]
  )

  return (
    <SearchResultItem data-id={'ChannelBrowser-SearchResultItem-' + searchItem.number} onClick={handleClick}>
      <ColorIcon style={{ backgroundColor: ddoDirectory.statusColor[searchItem.status] }} />
      <Details>
        <DetailsTopLine>
          <ChannelName>{channelName}</ChannelName>
          <ModifiedAt>{modifiedAt}</ModifiedAt>
        </DetailsTopLine>
        {/*<DetailsBottomLine>*/}
        {/*<SearchResultDetails>Details</SearchResultDetails>*/}
        {/*</DetailsBottomLine>*/}
      </Details>
    </SearchResultItem>
  )
})

const SearchResultItem = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  padding: 20px 0;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.rowHoverColor};
    padding: 20px 14px;
    margin: 0 -14px;
  }
`
const ColorIcon = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transform: translateY(-1px);
  margin-right: 11px;
`
const Details = styled.div`
  flex-grow: 1;
`
const DetailsTopLine = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
`
const DetailsBottomLine = styled.div``
const ChannelName = styled.div`
  font-size: 14px;

  span {
    line-height: 22px !important;
    background: rgba(255, 214, 0, 0.5);
    border-radius: 4px;
    color: #000 !important;
    padding: 4px;
  }
`
const ModifiedAt = styled.div`
  flex-shrink: 0;
  font-size: 12px;
  opacity: 0.5;
  padding-left: 10px;
  margin-left: auto;
`
const SearchResultDetails = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-top: 8px;
`
