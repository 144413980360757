import { debuggingMode } from '../../debug'
import { schemaLocationView } from '../../yupScheme'
import { LocationViewDTO } from '../../../api/api'

export const isLocationValid = (location: LocationViewDTO): boolean => {
  if (debuggingMode.common) {
    schemaLocationView.validate(location).catch(e => {
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkLocationValidation', e)
    })
  }

  return schemaLocationView.isValidSync(location)
}
