import * as React from 'react'
import { Scheduler } from '../common/scheduler/views'
import { DateTimeRangeDTO } from '../../api/origin/business-logic'

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export type GetSchedulerTabData = {
  noSpinner?: boolean
  loadMoreDates?: {
    requestDates: DateTimeRangeDTO
    resultFilterDates: DateTimeRangeDTO
  }
}

export const SchedulerPage = React.memo((props: Props) => {
  return <Scheduler />
})
