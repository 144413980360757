import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { OngoingActivityDTO, TransportationActivityViewDTO } from '../../../../../../../../api/api'
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../../../../store/select/driverSelect'
import theme from '../../../../../../../../styles/theme'
import { TCalcPhase } from '../../../../../functions'
import { Column } from '../PreviewPhase'
import { isGotoActivity } from '../../../../../../../../services/functions/test/isGotoActivity'
import { activityDirectory } from '../../../../../../../../services/DTO/activity/directory'
import { DriverPlaceholder } from './DriverPlaceholder'
import { TwoActivitiesView } from './TwoActivitiesView'
import { OneActivityView } from './OneActivityView'

type Props = {
  activityGroupId: string
  phase: TCalcPhase
  isActivePhase: boolean
  isDriverOnline: boolean
  isExtraActivity?: boolean
  isTwoActivitiesView?: boolean
}

export const PreviewPhaseVendorColumn = React.memo((props: Props) => {
  const { activityGroupId, phase, isActivePhase, isDriverOnline, isTwoActivitiesView, isExtraActivity } = props
  const { isActive, isPending, isAssigned } = phase
  const activity = isExtraActivity ? phase.extraActivity : phase.activity
  const reactTooltipId = activityGroupId + activity.id
  const driverId = oc(activity).driverId()
  const driverName = useAppSelector(selectDriverName(driverId))
  const isStreetTurn = (activity.comboType || activity.type) === OngoingActivityDTO.TypeEnum.STREETTURN
  const isGoto = isGotoActivity(activity)
  const isGotoCompleted = isGoto && activity.status === OngoingActivityDTO.StatusEnum.COMPLETED
  const isUnsuccessful = activity.status === OngoingActivityDTO.StatusEnum.UNSUCCESSFUL
  let activityStatusText: JSX.Element | string =
    activityDirectory.status[activity.status] || activityDirectory.documentationStatus[activity.status]
  const activityNotes = oc(activity).notes()
  const hasActivityNotes = Boolean(activityNotes)
  const showOfflineIndicator = isActivePhase && oc(activity).isLive() && driverName && isDriverOnline === false

  if (isGoto && (activity.status === OngoingActivityDTO.StatusEnum.INPROCESS || isGotoCompleted)) {
    activityStatusText = isGotoCompleted ? 'Arrived' : 'En Route'

    if (activity.type === TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO) {
      activityStatusText = (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <span style={{ color: theme.colors.defaultRed }}>Bobtail</span> En&nbsp;Route
        </div>
      )
    }
  }

  if (activityStatusText && typeof activityStatusText === 'string' && activityStatusText.includes('Driver')) {
    activityStatusText = activityStatusText.replace('Driver ', '')
  }

  if (isStreetTurn) {
    return (
      <Column
        data-id={[
          `phase-activityVendor`,
          `activityType=${activity.comboType || activity.type}`,
          `isStreetTurn=${isStreetTurn}`
        ]
          .filter(Boolean)
          .join(' ')}
      />
    )
  }

  return (
    <Column
      style={{ position: 'relative' }}
      className={'phase-activityVendor'}
      data-copy-value-on-mousedown={true}
      data-tip={true}
      data-for={reactTooltipId}
      data-id={[
        `phase-activityVendor`,
        `activityType=${activity.comboType || activity.type}`,
        `isStreetTurn=${isStreetTurn}`,
        driverId && `activityDriverId=${driverId}`,
        driverName &&
          `${isTwoActivitiesView ? (isExtraActivity ? 'topA' : 'bottomA') : 'a'}ctivityDriverName=${driverName}`
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {showOfflineIndicator && <OfflineIndicator>Offline</OfflineIndicator>}
      {driverName || isStreetTurn ? (
        isTwoActivitiesView ? (
          <TwoActivitiesView
            isActive={isActive}
            isPending={isPending}
            isAssigned={isAssigned}
            driverName={driverName}
            isExtraActivity={isExtraActivity}
            activityStatusText={activityStatusText}
            hasActivityNotes={hasActivityNotes}
            isUnsuccessful={isUnsuccessful}
          />
        ) : (
          <OneActivityView
            isActive={isActive}
            isPending={isPending}
            isAssigned={isAssigned}
            driverName={driverName}
            activityStatusText={activityStatusText}
            hasActivityNotes={hasActivityNotes}
            isUnsuccessful={isUnsuccessful}
          />
        )
      ) : (
        <DriverPlaceholder
          isActive={isActive}
          isPending={isPending}
          isAssigned={isAssigned}
          hasActivityNotes={hasActivityNotes}
          isActivePhase={isActivePhase}
          isUnsuccessful={isUnsuccessful}
          isTwoActivitiesView={isTwoActivitiesView}
        />
      )}
      {hasActivityNotes && (
        <ReactTooltip
          id={reactTooltipId}
          className={'react-tooltip pre-line react-tooltip_no-opacity'}
          place={'bottom'}
          effect={'solid'}
          backgroundColor={'white'}
          textColor={'rgb(50, 62, 78)'}
          borderColor={'#e5e5e5'}
          border={true}
        >
          <TextContainer>{activityNotes}</TextContainer>
        </ReactTooltip>
      )}
    </Column>
  )
})

const TextContainer = styled.div`
  font-size: 12px;
  line-height: 1.2;
`

const OfflineIndicator = styled.div`
  position: absolute;
  top: -8px;
  background-color: ${theme.colors.offline};
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 10px;
  padding: 3px 8px;
`
