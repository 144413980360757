import * as React from 'react'
import { IQMPContext, QMPContext } from '../contexts/QMPContext'
import { QMPTab, RateType } from '../components/common/QMP/interfaces'
import { updateTabData } from '../store/reducers/tabs/functions'

type OwnProps = {
  tabId: string
  children?: any
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const QMPProvider = React.memo((props: Props) => {
  const { children, tabId } = props
  const updateQMPTabData = React.useCallback((options: any) => updateTabData(tabId, options, true), [])

  const value = React.useMemo<IQMPContext>(
    () => ({
      setActiveTab(activeTab) {
        updateQMPTabData({ activeTab })
      },
      setOpenedCustomerQuoteId(openedCustomerQuoteId) {
        updateQMPTabData({ openedCustomerQuoteId })
      },
      setFilters(filters) {
        updateQMPTabData({ filters })
      },
      setExtraData(extraData) {
        updateQMPTabData({ extraData })
      },
      setSSQIds(ssqIds) {
        updateQMPTabData({ ssqIds, ratesTabVisited: true })
      },
      setBSQIds(bsqIds) {
        updateQMPTabData({ bsqIds, ratesTabVisited: true })
      },
      setRateIds({ ssqIds, bsqIds }) {
        updateQMPTabData({ ssqIds, bsqIds, ratesTabVisited: true })
      },
      setCustomerQuoteIds(customerQuoteIds) {
        updateQMPTabData({ customerQuoteIds, customerQuotesTabVisited: true })
      },
      setNewSSQRates(newSSQRates) {
        updateQMPTabData({ newSSQRates })
      },
      setNewBSQRates(newBSQRates) {
        updateQMPTabData({ newBSQRates })
      },
      setNewRates(rateType, newRates) {
        updateQMPTabData(rateType === RateType.ssq ? { newSSQRates: newRates } : { newBSQRates: newRates })
      },
      setNewCustomerQuotes(newCustomerQuotes) {
        updateQMPTabData({ newCustomerQuotes, customerQuotesTabVisited: true })
      },
      cancelCustomerQuoteEditing() {
        updateQMPTabData({ newCustomerQuotes: null, newSSQRates: [], newBSQRates: [] })
      },
      updateQMPTabData
    }),
    []
  )

  React.useEffect(() => {
    value.setExtraData({
      [QMPTab.RATE]: {},
      [QMPTab.CUSTOMERQUOTE]: {},
      [QMPTab.RULES]: {}
    })
  }, [])

  return <QMPContext.Provider value={value}>{children}</QMPContext.Provider>
})
