import * as React from 'react'
import styled from 'styled-components'

export const IconDDOPickupEmpty = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75H3V20.25H21V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path d="M16.4426 3L13 6.9875H15.6393V14H17.3607V6.9875H20L16.4426 3Z" />
    <path d="M5.88341 10.1243V14H4V3H7.56592C8.25232 3 8.85501 3.1511 9.37399 3.4533C9.89716 3.75549 10.299 4.18613 10.5794 4.74519C10.8598 5.29922 11 5.93132 11 6.64148C11 7.71932 10.6924 8.57051 10.0771 9.19506C9.46607 9.81456 8.61854 10.1243 7.53453 10.1243H5.88341ZM5.88341 8.28846H7.56592C8.06398 8.28846 8.44275 8.14744 8.70224 7.86538C8.96592 7.58333 9.09776 7.1804 9.09776 6.65659C9.09776 6.11767 8.96592 5.68201 8.70224 5.34959C8.43856 5.01717 8.07444 4.84592 7.60987 4.83585H5.88341V8.28846Z" />
  </svg>
)
export const IconDDOPickupEmptyEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.5H13C12.1716 5.5 11.5 6.17157 11.5 7V13.75C11.5 14.5784 12.1716 15.25 13 15.25H25C25.8284 15.25 26.5 14.5784 26.5 13.75V7C26.5 6.17157 25.8284 5.5 25 5.5ZM13 4C11.3431 4 10 5.34315 10 7V13.75C10 15.4069 11.3431 16.75 13 16.75H25C26.6569 16.75 28 15.4069 28 13.75V7C28 5.34315 26.6569 4 25 4H13Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125H12.25V19.1875H25.75V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M22.332 6.25L19.75 9.24063H21.7295V14.5H23.0205V9.24063H25L22.332 6.25Z" />
    <path d="M14.4126 11.5932V14.5H13V6.25H15.6744C16.1892 6.25 16.6413 6.36332 17.0305 6.58997C17.4229 6.81662 17.7242 7.13959 17.9345 7.55889C18.1448 7.97442 18.25 8.44849 18.25 8.98111C18.25 9.78949 18.0193 10.4279 17.5578 10.8963C17.0996 11.3609 16.4639 11.5932 15.6509 11.5932H14.4126ZM14.4126 10.2163H15.6744C16.048 10.2163 16.3321 10.1106 16.5267 9.89904C16.7244 9.6875 16.8233 9.3853 16.8233 8.99245C16.8233 8.58826 16.7244 8.2615 16.5267 8.01219C16.3289 7.76288 16.0558 7.63444 15.7074 7.62689H14.4126V10.2163Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)

export const IconDDOPickupFull = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V13C24 15.2091 22.2091 17 20 17H4C1.79086 17 0 15.2091 0 13V4ZM5.88341 10.1243V14H4V3H7.56592C8.25232 3 8.85501 3.1511 9.37399 3.4533C9.89716 3.75549 10.299 4.18613 10.5794 4.74519C10.8598 5.29922 11 5.93132 11 6.64148C11 7.71932 10.6924 8.57051 10.0771 9.19506C9.46607 9.81456 8.61854 10.1243 7.53453 10.1243H5.88341ZM5.88341 8.28846H7.56592C8.06398 8.28846 8.44275 8.14744 8.70224 7.86538C8.96592 7.58333 9.09776 7.1804 9.09776 6.65659C9.09776 6.11767 8.96592 5.68201 8.70224 5.34959C8.43856 5.01717 8.07444 4.84592 7.60987 4.83585H5.88341V8.28846ZM13 6.9875L16.4426 3L20 6.9875H17.3607V14H15.6393V6.9875H13Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
  </svg>
)
export const IconDDOPickupFullEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C10 5.79086 11.7909 4 14 4H24C26.2091 4 28 5.79086 28 8V12.75C28 14.9591 26.2091 16.75 24 16.75H14C11.7909 16.75 10 14.9591 10 12.75V8ZM14.4126 11.5932V14.5H13V6.25H15.6744C16.1892 6.25 16.6413 6.36332 17.0305 6.58997C17.4229 6.81662 17.7242 7.13959 17.9345 7.55889C18.1448 7.97442 18.25 8.44849 18.25 8.98111C18.25 9.78949 18.0193 10.4279 17.5578 10.8963C17.0996 11.3609 16.4639 11.5932 15.6509 11.5932H14.4126ZM14.4126 10.2163H15.6744C16.048 10.2163 16.3321 10.1106 16.5267 9.89904C16.7244 9.6875 16.8233 9.3853 16.8233 8.99244C16.8233 8.58826 16.7244 8.2615 16.5267 8.01219C16.3289 7.76288 16.0558 7.63444 15.7074 7.62689H14.4126V10.2163ZM19.75 9.24062L22.332 6.25L25 9.24062H23.0205V14.5H21.7295V9.24062H19.75Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
  </svg>
)

export const IconStreetTurn = () => <StyledStreetTurn className={'mdi mdi-shuffle-variant'} />

const StyledStreetTurn = styled.span`
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-weight: 400;
  font-size: 24px;
  transform: rotate(-90deg);
`

// export const IconDDOGetUnloaded = () => (
//   // tslint:disable:max-line-length
//   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M22.6304 16L2 1V7H3.6129V12.2087C3.6129 12.7254 3.73477 13.103 3.97849 13.3415C4.22581 13.5763 4.56631 13.6938 5 13.6938C5.90681 13.6938 6.36738 13.2132 6.38172 12.252V7H8V12.1978C8 13.0614 7.73118 13.7444 7.19355 14.2466C6.6595 14.7489 5.92832 15 5 15C4.08602 15 3.36022 14.7561 2.82258 14.2683C2.28495 13.7805 2.01075 13.1102 2 12.2575V16H22.6304Z"
//     />
//     <circle cx="3" cy="21" r="3" />
//     <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
//     <circle cx="21" cy="21" r="3" />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
//     />
//     <path d="M21 6L18.1 3L18.1 5.3L13 5.3L13 6.8L18.1 6.8L18.1 9.1L21 6Z" />
//   </svg>
// )
export const IconDDOGetUnloaded = () => (
  // tslint:disable:max-line-length
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5C0 2.23858 2.23858 0 5 0L27 0C29.7614 0 32 2.23858 32 5V27C32 29.7614 29.7614 32 27 32H5C2.23858 32 0 29.7614 0 27V5Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6304 20L6 5V20H26.6304ZM9.61133 15.8042H12.7051V14.5044H9.61133V12.4849H13.0918V11.1797H8V19H9.61133V15.8042Z"
    />
    <circle cx="7" cy="25" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25 25.75L7 25.75V24.25L25 24.25V25.75Z" />
    <circle cx="25" cy="25" r="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6H8C6.89543 6 6 6.89543 6 8V17C6 18.1046 6.89543 19 8 19H24C25.1046 19 26 18.1046 26 17V8C26 6.89543 25.1046 6 24 6ZM8 4C5.79086 4 4 5.79086 4 8V17C4 19.2091 5.79086 21 8 21H24C26.2091 21 28 19.2091 28 17V8C28 5.79086 26.2091 4 24 4H8Z"
    />
    <path d="M25 10L22.1 7L22.1 9.3L17 9.3L17 10.8L22.1 10.8L22.1 13.1L25 10Z" />
  </svg>
)
export const IconDDOGetUnloadedEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9728 16L11.5 4.75V9.25H12.7097V13.1565C12.7097 13.544 12.8011 13.8272 12.9839 14.0061C13.1694 14.1822 13.4247 14.2703 13.75 14.2703C14.4301 14.2703 14.7755 13.9099 14.7863 13.189V9.25H16V13.1484C16 13.7961 15.7984 14.3083 15.3952 14.685C14.9946 15.0617 14.4462 15.25 13.75 15.25C13.0645 15.25 12.5202 15.0671 12.1169 14.7012C11.7137 14.3354 11.5081 13.8327 11.5 13.1931V16H26.9728Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.5H13C12.1716 5.5 11.5 6.17157 11.5 7V13.75C11.5 14.5784 12.1716 15.25 13 15.25H25C25.8284 15.25 26.5 14.5784 26.5 13.75V7C26.5 6.17157 25.8284 5.5 25 5.5ZM13 4C11.3431 4 10 5.34315 10 7V13.75C10 15.4069 11.3431 16.75 13 16.75H25C26.6569 16.75 28 15.4069 28 13.75V7C28 5.34315 26.6569 4 25 4H13Z"
    />
    <path d="M25.75 8.5L23.575 6.25L23.575 7.975L19.75 7.975L19.75 9.1L23.575 9.1L23.575 10.825L25.75 8.5Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDOGetLoaded = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09135 6.875H4.21635V9.02885H7.57692V10H3V3H7.54327V3.98077H4.21635V5.91346H7.09135V6.875Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.36719 16L22 1V16H1.36719ZM15.9 14L13 11L15.9 7.9V10.2H21V11.7H15.9L15.9 14Z"
    />
  </svg>
)
// export const IconDDOGetLoaded = () => (
//   // tslint:disable:max-line-length
//   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M4.5 8.5H8V10H3V3H4.5V8.5Z" />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
//     />
//     <circle cx="3" cy="21" r="3" />
//     <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
//     <circle cx="21" cy="21" r="3" />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M1.36719 16L22 1V16H1.36719ZM15.9 14L13 11L15.9 7.9V10.2H21V11.7H15.9L15.9 14Z"
//     />
//   </svg>
// )
export const IconDDOGetLoadedEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.375 10.375H16V11.5H12.25V6.25H13.375V10.375Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.5H13C12.1716 5.5 11.5 6.17157 11.5 7V13.75C11.5 14.5784 12.1716 15.25 13 15.25H25C25.8284 15.25 26.5 14.5784 26.5 13.75V7C26.5 6.17157 25.8284 5.5 25 5.5ZM13 4C11.3431 4 10 5.34315 10 7V13.75C10 15.4069 11.3431 16.75 13 16.75H25C26.6569 16.75 28 15.4069 28 13.75V7C28 5.34315 26.6569 4 25 4H13Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0254 16L26.5 4.75V16H11.0254ZM21.925 14.5L19.75 12.25L21.925 9.925V11.65H25.75V12.775H21.925V14.5Z"
    />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDODropEmptyWithChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 14.0469V3.04688H6.81761C7.62264 3.04688 8.34172 3.26597 8.97484 3.70416C9.61216 4.13731 10.109 4.75681 10.4654 5.56267C10.8218 6.3635 11 7.27513 11 8.29756V8.80374C11 9.82618 10.8239 10.7353 10.4717 11.5311C10.1237 12.3269 9.63103 12.9439 8.99371 13.382C8.35639 13.8202 7.63732 14.0418 6.83648 14.0469H4ZM5.88679 4.88273V12.2261H6.79874C7.53669 12.2261 8.10063 11.9365 8.49057 11.3573C8.8805 10.7781 9.07966 9.94958 9.08805 8.87174V8.29001C9.08805 7.17187 8.89518 6.32572 8.50943 5.75155C8.12369 5.17233 7.55975 4.88273 6.81761 4.88273H5.88679Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path d="M16.5574 14L20 10.0125L17.3607 10.0125L17.3607 3L15.6393 3L15.6393 10.0125L13 10.0125L16.5574 14Z" />
  </svg>
)
export const IconDDODropEmptyWithChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 14.5352V6.28516H15.1132C15.717 6.28516 16.2563 6.44948 16.7311 6.77812C17.2091 7.10298 17.5818 7.56761 17.8491 8.172C18.1164 8.77262 18.25 9.45634 18.25 10.2232V10.6028C18.25 11.3696 18.1179 12.0515 17.8538 12.6483C17.5928 13.2451 17.2233 13.7079 16.7453 14.0365C16.2673 14.3652 15.728 14.5314 15.1274 14.5352H13ZM14.4151 7.66204V13.1696H15.0991C15.6525 13.1696 16.0755 12.9524 16.3679 12.518C16.6604 12.0836 16.8097 11.4622 16.816 10.6538V10.2175C16.816 9.37891 16.6714 8.74429 16.3821 8.31366C16.0928 7.87925 15.6698 7.66204 15.1132 7.66204H14.4151Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.5H13C12.1716 5.5 11.5 6.17157 11.5 7V13.75C11.5 14.5784 12.1716 15.25 13 15.25H25C25.8284 15.25 26.5 14.5784 26.5 13.75V7C26.5 6.17157 25.8284 5.5 25 5.5ZM13 4C11.3431 4 10 5.34315 10 7V13.75C10 15.4069 11.3431 16.75 13 16.75H25C26.6569 16.75 28 15.4069 28 13.75V7C28 5.34315 26.6569 4 25 4H13Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M22.418 14.5L25 11.5094L23.0205 11.5094L23.0205 6.25L21.7295 6.25L21.7295 11.5094L19.75 11.5094L22.418 14.5Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDODropFullWithChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V13C24 15.2091 22.2091 17 20 17H4C1.79086 17 0 15.2091 0 13V4ZM4 14.0469V3.04688H6.81761C7.62264 3.04688 8.34172 3.26597 8.97484 3.70416C9.61216 4.13731 10.109 4.75681 10.4654 5.56267C10.8218 6.3635 11 7.27513 11 8.29756V8.80374C11 9.82618 10.8239 10.7353 10.4717 11.5311C10.1237 12.3269 9.63103 12.9439 8.99371 13.382C8.35639 13.8202 7.63732 14.0418 6.83648 14.0469H4ZM5.88679 4.88273V12.2261H6.79874C7.53669 12.2261 8.10063 11.9365 8.49057 11.3573C8.8805 10.7781 9.07966 9.94958 9.08805 8.87174V8.29001C9.08805 7.17188 8.89518 6.32572 8.50943 5.75154C8.12369 5.17233 7.55975 4.88273 6.81761 4.88273H5.88679ZM20 10.0125L16.5574 14L13 10.0125L15.6393 10.0125V3L17.3607 3V10.0125H20Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
  </svg>
)
export const IconDDODropFullWithChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C10 5.79086 11.7909 4 14 4H24C26.2091 4 28 5.79086 28 8V12.75C28 14.9591 26.2091 16.75 24 16.75H14C11.7909 16.75 10 14.9591 10 12.75V8ZM13 14.5352V6.28516H15.1132C15.717 6.28516 16.2563 6.44948 16.7311 6.77812C17.2091 7.10298 17.5818 7.56761 17.8491 8.172C18.1164 8.77262 18.25 9.45634 18.25 10.2232V10.6028C18.25 11.3696 18.1179 12.0515 17.8538 12.6483C17.5928 13.2451 17.2233 13.7079 16.7453 14.0365C16.2673 14.3652 15.728 14.5314 15.1274 14.5352H13ZM14.4151 7.66205V13.1696H15.0991C15.6525 13.1696 16.0755 12.9524 16.3679 12.518C16.6604 12.0836 16.8097 11.4622 16.816 10.6538V10.2175C16.816 9.37891 16.6714 8.74429 16.3821 8.31366C16.0928 7.87925 15.6698 7.66205 15.1132 7.66205H14.4151ZM25 11.5094L22.418 14.5L19.75 11.5094H21.7295L21.7295 6.25H23.0205L23.0205 11.5094L25 11.5094Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDOPickChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75H3V20.25H21V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path d="M16.4426 3L13 6.9875H15.6393V14H17.3607V6.9875H20L16.4426 3Z" />
    <path d="M5.88341 10.1243V14H4V3H7.56592C8.25232 3 8.85501 3.1511 9.37399 3.4533C9.89716 3.75549 10.299 4.18613 10.5794 4.74519C10.8598 5.29922 11 5.93132 11 6.64148C11 7.71932 10.6924 8.57051 10.0771 9.19506C9.46607 9.81456 8.61854 10.1243 7.53453 10.1243H5.88341ZM5.88341 8.28846H7.56592C8.06398 8.28846 8.44275 8.14744 8.70224 7.86538C8.96592 7.58333 9.09776 7.1804 9.09776 6.65659C9.09776 6.11767 8.96592 5.68201 8.70224 5.34959C8.43856 5.01717 8.07444 4.84592 7.60987 4.83585H5.88341V8.28846Z" />
  </svg>
)
export const IconDDOPickChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125H12.25V19.1875H25.75V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M22.332 6.25L19.75 9.24063H21.7295V14.5H23.0205V9.24063H25L22.332 6.25Z" />
    <path d="M14.4126 11.5932V14.5H13V6.25H15.6744C16.1892 6.25 16.6413 6.36332 17.0305 6.58997C17.4229 6.81662 17.7242 7.13959 17.9345 7.55889C18.1448 7.97442 18.25 8.44849 18.25 8.98111C18.25 9.78949 18.0193 10.4279 17.5578 10.8963C17.0996 11.3609 16.4639 11.5932 15.6509 11.5932H14.4126ZM14.4126 10.2163H15.6744C16.048 10.2163 16.3321 10.1106 16.5267 9.89904C16.7244 9.6875 16.8233 9.3853 16.8233 8.99245C16.8233 8.58826 16.7244 8.2615 16.5267 8.01219C16.3289 7.76288 16.0558 7.63444 15.7074 7.62689H14.4126V10.2163Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDODropChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path d="M4 14V3H6.81761C7.62264 3 8.34172 3.21909 8.97484 3.65728C9.61216 4.09043 10.109 4.70994 10.4654 5.5158C10.8218 6.31662 11 7.22825 11 8.25069V8.75687C11 9.7793 10.8239 10.6884 10.4717 11.4842C10.1237 12.28 9.63103 12.897 8.99371 13.3352C8.35639 13.7734 7.63732 13.995 6.83648 14H4ZM5.88679 4.83585V12.1793H6.79874C7.53669 12.1793 8.10063 11.8897 8.49057 11.3104C8.8805 10.7312 9.07966 9.9027 9.08805 8.82486V8.24313C9.08805 7.125 8.89518 6.27885 8.50943 5.70467C8.12369 5.12546 7.55975 4.83585 6.81761 4.83585H5.88679Z" />
    <path d="M16.5574 14L20 10.0125L17.3607 10.0125L17.3607 3L15.6393 3L15.6393 10.0125L13 10.0125L16.5574 14Z" />
  </svg>
)
export const IconDDODropChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M13 14.5V6.25H15.1132C15.717 6.25 16.2563 6.41432 16.7311 6.74296C17.2091 7.06782 17.5818 7.53245 17.8491 8.13685C18.1164 8.73747 18.25 9.42119 18.25 10.188V10.5677C18.25 11.3345 18.1179 12.0163 17.8538 12.6132C17.5928 13.21 17.2233 13.6727 16.7453 14.0014C16.2673 14.33 15.728 14.4962 15.1274 14.5H13ZM14.4151 7.62689V13.1344H15.0991C15.6525 13.1344 16.0755 12.9172 16.3679 12.4828C16.6604 12.0484 16.8097 11.427 16.816 10.6186V10.1823C16.816 9.34375 16.6714 8.70913 16.3821 8.2785C16.0928 7.84409 15.6698 7.62689 15.1132 7.62689H14.4151Z" />
    <path d="M22.418 14.5L25 11.5094L23.0205 11.5094L23.0205 6.25L21.7295 6.25L21.7295 11.5094L19.75 11.5094L22.418 14.5Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDODropEmptyWithoutChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 17.0469V6.04688H6.81761C7.62264 6.04688 8.34172 6.26597 8.97484 6.70416C9.61216 7.13731 10.109 7.75681 10.4654 8.56267C10.8218 9.3635 11 10.2751 11 11.2976V11.8037C11 12.8262 10.8239 13.7353 10.4717 14.5311C10.1237 15.3269 9.63103 15.9439 8.99371 16.382C8.35639 16.8202 7.63732 17.0418 6.83648 17.0469H4ZM5.88679 7.88273V15.2261H6.79874C7.53669 15.2261 8.10063 14.9365 8.49057 14.3573C8.8805 13.7781 9.07966 12.9496 9.08805 11.8717V11.29C9.08805 10.1719 8.89518 9.32572 8.50943 8.75155C8.12369 8.17233 7.55975 7.88273 6.81761 7.88273H5.88679Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 5H4C2.89543 5 2 5.89543 2 7V16C2 17.1046 2.89543 18 4 18H20C21.1046 18 22 17.1046 22 16V7C22 5.89543 21.1046 5 20 5ZM4 3C1.79086 3 0 4.79086 0 7V16C0 18.2091 1.79086 20 4 20H20C22.2091 20 24 18.2091 24 16V7C24 4.79086 22.2091 3 20 3H4Z"
    />
    <path d="M16.5574 17L20 13.0125L17.3607 13.0125L17.3607 6L15.6393 6L15.6393 13.0125L13 13.0125L16.5574 17Z" />
  </svg>
)
export const IconDDODropEmptyWithoutChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 16.7852V8.53516H15.1132C15.717 8.53516 16.2563 8.69948 16.7311 9.02812C17.2091 9.35298 17.5818 9.81761 17.8491 10.422C18.1164 11.0226 18.25 11.7063 18.25 12.4732V12.8528C18.25 13.6196 18.1179 14.3015 17.8538 14.8983C17.5928 15.4951 17.2233 15.9579 16.7453 16.2865C16.2673 16.6152 15.728 16.7814 15.1274 16.7852H13ZM14.4151 9.91205V15.4196H15.0991C15.6525 15.4196 16.0755 15.2024 16.3679 14.768C16.6604 14.3336 16.8097 13.7122 16.816 12.9038V12.4675C16.816 11.6289 16.6714 10.9943 16.3821 10.5637C16.0928 10.1292 15.6698 9.91205 15.1132 9.91205H14.4151Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 7.75H13C12.1716 7.75 11.5 8.42157 11.5 9.25V16C11.5 16.8284 12.1716 17.5 13 17.5H25C25.8284 17.5 26.5 16.8284 26.5 16V9.25C26.5 8.42157 25.8284 7.75 25 7.75ZM13 6.25C11.3431 6.25 10 7.59315 10 9.25V16C10 17.6569 11.3431 19 13 19H25C26.6569 19 28 17.6569 28 16V9.25C28 7.59315 26.6569 6.25 25 6.25H13Z"
    />
    <path d="M22.418 16.75L25 13.7594L23.0205 13.7594L23.0205 8.5L21.7295 8.5L21.7295 13.7594L19.75 13.7594L22.418 16.75Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDODropFullWithoutChassis = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 4.79086 1.79086 3 4 3H20C22.2091 3 24 4.79086 24 7V16C24 18.2091 22.2091 20 20 20H4C1.79086 20 0 18.2091 0 16V7ZM4 17.0469V6.04688H6.81761C7.62264 6.04688 8.34172 6.26597 8.97484 6.70416C9.61216 7.13731 10.109 7.75681 10.4654 8.56267C10.8218 9.3635 11 10.2751 11 11.2976V11.8037C11 12.8262 10.8239 13.7353 10.4717 14.5311C10.1237 15.3269 9.63103 15.9439 8.99371 16.382C8.35639 16.8202 7.63732 17.0418 6.83648 17.0469H4ZM5.88679 7.88273V15.2261H6.79874C7.53669 15.2261 8.10063 14.9365 8.49057 14.3573C8.8805 13.7781 9.07966 12.9496 9.08805 11.8717V11.29C9.08805 10.1719 8.89518 9.32572 8.50943 8.75154C8.12369 8.17233 7.55975 7.88273 6.81761 7.88273H5.88679ZM20 13.0125L16.5574 17L13 13.0125L15.6393 13.0125V6L17.3607 6V13.0125H20Z"
    />
  </svg>
)
export const IconDDODropFullWithoutChassisEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 10.25C10 8.04086 11.7909 6.25 14 6.25H24C26.2091 6.25 28 8.04086 28 10.25V15C28 17.2091 26.2091 19 24 19H14C11.7909 19 10 17.2091 10 15V10.25ZM13 16.7852V8.53516H15.1132C15.717 8.53516 16.2563 8.69948 16.7311 9.02812C17.2091 9.35298 17.5818 9.81761 17.8491 10.422C18.1164 11.0226 18.25 11.7063 18.25 12.4732V12.8528C18.25 13.6196 18.1179 14.3015 17.8538 14.8983C17.5928 15.4951 17.2233 15.9579 16.7453 16.2865C16.2673 16.6152 15.728 16.7814 15.1274 16.7852H13ZM14.4151 9.91205V15.4196H15.0991C15.6525 15.4196 16.0755 15.2024 16.3679 14.768C16.6604 14.3336 16.8097 13.7122 16.816 12.9038V12.4675C16.816 11.6289 16.6714 10.9943 16.3821 10.5637C16.0928 10.1292 15.6698 9.91205 15.1132 9.91205H14.4151ZM25 13.7594L22.418 16.75L19.75 13.7594H21.7295L21.7295 8.5H23.0205L23.0205 13.7594L25 13.7594Z"
    />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDOScaleTare = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H4C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V4C22 2.89543 21.1046 2 20 2ZM4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
    <path d="M10.7183 9.06897V8.5H5V9.06897C5 10.5862 6.38028 11.9138 7.95775 11.9138C9.53521 11.7241 10.7183 10.5862 10.7183 9.06897Z" />
    <path d="M13.084 8.5V9.06897C13.084 10.5862 14.4643 11.9138 16.0417 11.9138C17.6192 11.9138 18.9995 10.5862 18.9995 9.06897V8.5H13.084Z" />
    <path d="M12.4932 5.08621H15.0566L13.6763 7.93103H14.8594L16.0425 5.84483L17.0284 7.93103H18.4087L16.8313 5.08621H17.62V4.13793H12.4932V3H11.3102V4.13793H6.18338V5.08621H6.9721L5.5918 7.93103H6.77491L7.95802 5.84483L8.94393 7.93103H10.3242L8.94393 5.08621H11.3102V12.8621H9.73265V14H14.2679V12.8621H12.4932V5.08621Z" />
  </svg>
)
export const IconDDOScaleTareEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5.5H13C12.1716 5.5 11.5 6.17157 11.5 7V13.75C11.5 14.5784 12.1716 15.25 13 15.25H25C25.8284 15.25 26.5 14.5784 26.5 13.75V7C26.5 6.17157 25.8284 5.5 25 5.5ZM13 4C11.3431 4 10 5.34315 10 7V13.75C10 15.4069 11.3431 16.75 13 16.75H25C26.6569 16.75 28 15.4069 28 13.75V7C28 5.34315 26.6569 4 25 4H13Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M18.0387 10.8017V10.375H13.75V10.8017C13.75 11.9397 14.7852 12.9353 15.9683 12.9353C17.1514 12.7931 18.0387 11.9397 18.0387 10.8017Z" />
    <path d="M19.8134 10.375V10.8017C19.8134 11.9397 20.8486 12.9353 22.0317 12.9353C23.2148 12.9353 24.25 11.9397 24.25 10.8017V10.375H19.8134Z" />
    <path d="M19.3697 7.81466H21.2922L20.257 9.94828H21.1444L22.0317 8.38362L22.7711 9.94828H23.8063L22.6232 7.81466H23.2148V7.10345H19.3697V6.25H18.4824V7.10345H14.6373V7.81466H15.2289L14.1936 9.94828H15.081L15.9683 8.38362L16.7077 9.94828H17.743L16.7077 7.81466H18.4824V13.6466H17.2993V14.5H20.7007V13.6466H19.3697V7.81466Z" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDOScaleFull = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V13C0 15.2091 1.79086 17 4 17H20C22.2091 17 24 15.2091 24 13V4C24 1.79086 22.2091 0 20 0H4ZM10.7184 8.5V9.06897C10.7184 10.5862 9.53527 11.7241 7.95779 11.9138C6.3803 11.9138 5 10.5862 5 9.06897V8.5H10.7184ZM13.0845 8.5V9.06897C13.0845 10.5862 14.4648 11.9138 16.0423 11.9138C17.6197 11.9138 19 10.5862 19 9.06897V8.5H13.0845ZM15.0563 5.08621H12.4928V12.8621H14.2675V14H9.73224V12.8621H11.3098V5.08621H8.94351L10.3238 7.93103H8.94351L7.95758 5.84483L6.77445 7.93103H5.59133L6.97165 5.08621H6.18292V4.13793H11.3098V3H12.4928V4.13793H17.6197V5.08621H16.8309L18.4084 7.93103H17.0281L16.0422 5.84483L14.8591 7.93103H13.676L15.0563 5.08621Z"
    />
    <circle cx="3" cy="21" r="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 21.75L3 21.75L3 20.25L21 20.25V21.75Z" />
    <circle cx="21" cy="21" r="3" />
  </svg>
)
export const IconDDOScaleFullEnRoute = () => (
  // tslint:disable:max-line-length
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4C11.7909 4 10 5.79086 10 8V12.75C10 14.9591 11.7909 16.75 14 16.75H24C26.2091 16.75 28 14.9591 28 12.75V8C28 5.79086 26.2091 4 24 4H14ZM18.0388 10.375V10.8017C18.0388 11.9397 17.1515 12.7931 15.9683 12.9353C14.7852 12.9353 13.75 11.9397 13.75 10.8017V10.375H18.0388ZM19.8134 10.375V10.8017C19.8134 11.9397 20.8486 12.9353 22.0317 12.9353C23.2148 12.9353 24.25 11.9397 24.25 10.8017V10.375H19.8134ZM21.2922 7.81466H19.3696V13.6466H20.7007V14.5H17.2992V13.6466H18.4823V7.81466H16.7076L17.7429 9.94828H16.7076L15.9682 8.38362L15.0808 9.94828H14.1935L15.2287 7.81466H14.6372V7.10345H18.4823V6.25H19.3696V7.10345H23.2148V7.81466H22.6232L23.8063 9.94828H22.7711L22.0317 8.38362L21.1443 9.94828H20.257L21.2922 7.81466Z"
    />
    <circle cx="12.25" cy="19.75" r="2.25" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.75 20.3125L12.25 20.3125V19.1875L25.75 19.1875V20.3125Z" />
    <circle cx="25.75" cy="19.75" r="2.25" />
    <path d="M3.88889 0C1.7284 0 0 1.68591 0 3.79329C0 6.63826 3.88889 10.9584 3.88889 10.9584C3.88889 10.9584 7.77778 6.74363 7.77778 3.79329C7.77778 1.68591 6.04938 0 3.88889 0ZM3.88889 5.16309C3.13272 5.16309 2.48457 4.53088 2.48457 3.79329C2.48457 3.05571 3.13272 2.42349 3.88889 2.42349C4.64506 2.42349 5.29321 3.05571 5.29321 3.79329C5.29321 4.53088 4.64506 5.16309 3.88889 5.16309Z" />
    <path d="M3.97973 11.7952C13.147 12.265 -1.21811 19.6856 9.01744 19.9516" strokeWidth="2" />
  </svg>
)
export const IconDDOReposition = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="-29 -19 618 618.66669">
    <path d="m89.667969 270c-49.683594.054688-89.949219 40.316406-90 90 0 38.890625 58.996093 129.539062 90 173 31-43.460938 90-134.117188 90-173-.054688-49.683594-40.320313-89.945312-90-90zm0 150c-33.136719 0-60-26.867188-60-60s26.863281-60 60-60c33.132812 0 60 26.867188 60 60-.035157 33.125-26.875 59.964844-60 60zm0 0" />
    <path d="m129.667969 360c0 22.089844-17.910157 40-40 40-22.089844 0-40-17.910156-40-40s17.910156-40 40-40c22.089843 0 40 17.910156 40 40zm0 0" />
    <path d="m559.667969 90c0-49.707031-40.292969-90-90-90s-90 40.292969-90 90c0 38.890625 58.996093 129.539062 90 173 31-43.46875 90-134.128906 90-173zm-150 0c0-33.132812 26.863281-60 60-60 33.132812 0 60 26.867188 60 60s-26.867188 60-60 60c-33.125-.035156-59.96875-26.875-60-60zm0 0" />
    <path d="m509.667969 90c0 22.089844-17.910157 40-40 40-22.089844 0-40-17.910156-40-40s17.910156-40 40-40c22.089843 0 40 17.910156 40 40zm0 0" />
    <path d="m342.605469 308c-20.886719 17.0625-32.984375 42.621094-32.9375 69.585938v72.414062c-.074219 60.722656-49.277344 109.925781-110 110h-90v20h90c71.761719-.082031 129.914062-58.238281 130-130v-72.414062c.011719-20.960938 9.421875-40.8125 25.636719-54.09375 16.21875-13.289063 37.535156-18.605469 58.09375-14.492188l54.265624 10.800781 3.921876-19.601562-54.296876-10.859375c-26.4375-5.3125-53.851562 1.539062-74.683593 18.660156zm0 0" />
  </svg>
)

export const IconOffDutyBobtail = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 16 16" fill="#B77CCC" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711C0.683417 9.09763 1.31658 9.09763 1.70711 8.70711L2 8.41421V15C2 15.5523 2.44772 16 3 16H5C5.55228 16 6 15.5523 6 15V13C6 12.4477 6.44772 12 7 12H9C9.55229 12 10 12.4477 10 13V15C10 15.5523 10.4477 16 11 16H13C13.5523 16 14 15.5523 14 15V8.41421L14.2929 8.70711C14.6834 9.09763 15.3166 9.09763 15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z" />
  </svg>
)
export const IconEmptyBobtail = () => (
  // tslint:disable:max-line-length
  <svg width="24" height="24" viewBox="0 0 20 20" fill="#B77CCC" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.6004C15.3019 19.6004 19.6 15.3023 19.6 10.0004C19.6 4.69846 15.3019 0.400391 10 0.400391C4.69807 0.400391 0.4 4.69846 0.4 10.0004C0.4 15.3023 4.69807 19.6004 10 19.6004ZM14.4485 9.15186L10.8485 5.55186C10.3799 5.08323 9.6201 5.08323 9.15147 5.55186C8.68284 6.02049 8.68284 6.78029 9.15147 7.24892L10.7029 8.80039L6.4 8.80039C5.73726 8.80039 5.2 9.33765 5.2 10.0004C5.2 10.6631 5.73726 11.2004 6.4 11.2004H10.7029L9.15147 12.7519C8.68284 13.2205 8.68284 13.9803 9.15147 14.4489C9.6201 14.9175 10.3799 14.9175 10.8485 14.4489L14.4485 10.8489C14.9172 10.3803 14.9172 9.62049 14.4485 9.15186Z"
    />
  </svg>
)
