import { RootState } from '..'
import { IAppNotification } from '../reducers/appNotifications/interfaces'

// SELECT ONE
export const selectAppNotification = (id: string) => (state: RootState): IAppNotification | undefined => {
  return state.appNotifications.find(item => item.id === id)
}

// SELECT MANY
export const selectAppNotifications = (state: RootState): IAppNotification[] => {
  return state.appNotifications
}
