import { GridColumn } from '../../../../../UI/GridSystem'
import styled from '../../../../../../styles/styledComponents'

export const Credentials = styled(GridColumn)`
  font-size: 14px;
`
export const Title = styled(GridColumn)`
  white-space: nowrap;
  user-select: none;
`
export const Date = styled(GridColumn)`
  > div {
    width: 100%;
  }
`
