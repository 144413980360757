import { oc } from 'ts-optchain'
import { LocationDTO, LocationViewDTO } from '../../../api/origin/business-logic'
import { isLocationValid } from '../../functions/test/isLocationValid'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isContactValid } from '../../functions/test/isContactValid'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'

export const locationTypeIcons = {
  [LocationDTO.TypeEnum.TERMINAL]: 'mdi mdi-switch',
  [LocationDTO.TypeEnum.WAREHOUSEWITHDOCK]: 'mdi mdi-garage',
  [LocationDTO.TypeEnum.WAREHOUSEWITHOUTDOCK]: 'mdi mdi-city',
  [LocationDTO.TypeEnum.STOREFRONT]: 'mdi mdi-store',
  [LocationDTO.TypeEnum.RESIDENTIAL]: 'mdi mdi-account-box',
  [LocationDTO.TypeEnum.CONTAINERYARD]: 'mdi mdi-window-closed-variant',
  // [LocationDTO.TypeEnum.GENERIC]: 'mdi mdi-map-marker',
  [LocationDTO.TypeEnum.DEPOT]: 'mdi mdi-mailbox',
  [LocationDTO.TypeEnum.OTHER]: 'mdi mdi-map-marker-question'
}

export const checkLocationValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { modifiedLists, parentItem } = expandedItemContext
  const location = parentItem as LocationViewDTO
  const updatedContactMapping = oc(modifiedLists)[EntityType.contact]({})
  const updatedContacts = location.contactIds.map(id => updatedContactMapping[id]).filter(Boolean)

  return isLocationValid(location) && updatedContacts.every(isContactValid)
}
