import { ContainerTypeDTO } from '../../../../../api/api'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.containerType]: ContainerTypeDTO[]
}

export const parseContainerTypes = async (containerTypes: ContainerTypeDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.containerType]: []
  }

  try {
    containerTypes.forEach(containerType => {
      result[EntityType.containerType].push(containerType)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
