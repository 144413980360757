import * as React from 'react'
import styled from 'styled-components'
// tslint:disable:max-line-length
import { toggleSyncDriverActivitiesWithRouteBuilder } from '../../../functions/toggleSyncDriverActivitiesWithRouteBuilder'
import theme from '../../../../../../styles/theme'
import { ToggleButton } from '../../../../../UI/Toggle/ToggleButton'

type Props = {
  syncWithRouteBuilder: boolean
}

export const SyncDriverActivitiesWithRouteBuilder = (props: Props) => {
  const { syncWithRouteBuilder } = props

  return (
    <Container>
      <ButtonWithLabel onClick={() => toggleSyncDriverActivitiesWithRouteBuilder(!syncWithRouteBuilder)}>
        <ToggleButton style={{ marginRight: 5 }} active={syncWithRouteBuilder} />
        Sync Drivers with Route Builder
      </ButtonWithLabel>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
`
const ButtonWithLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
