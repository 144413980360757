import * as React from 'react'
import { SsqBsqWidgetRateColumn, SsqBsqWidgetRateColumnType } from './SsqBsqWidgetRateColumn'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../../../services/DTO/rate/interfaces'

type Props = {
  rate: CombinedSurchargeRateDTO
  surcharges?: SurchargeDTO[]
  disable: boolean
  hideActivityColumn: boolean
  addSurcharge: (surcharge: SurchargeDTO) => void
  deleteSurcharge: () => void
  modifySurcharge: (surcharge: SurchargeDTO) => void
}

export const SsqBsqWidgetApplicableRate = React.memo((props: Props) => {
  const { rate, disable, surcharges, hideActivityColumn, addSurcharge, deleteSurcharge, modifySurcharge } = props

  return (
    <>
      {(hideActivityColumn ? applicableRateColumnsWOActivity : applicableRateColumns).map(columnType => {
        return (
          <SsqBsqWidgetRateColumn
            key={columnType}
            columnType={columnType}
            rate={rate}
            surcharges={surcharges}
            addSurcharge={addSurcharge}
            deleteSurcharge={deleteSurcharge}
            modifySurcharge={modifySurcharge}
            baseRate={undefined}
            disable={disable}
          />
        )
      })}
    </>
  )
})

export const applicableRateColumnsWOActivity = [
  SsqBsqWidgetRateColumnType.Select,
  SsqBsqWidgetRateColumnType.ID,
  SsqBsqWidgetRateColumnType.Surcharges,
  SsqBsqWidgetRateColumnType.Type,
  SsqBsqWidgetRateColumnType.CalculationType,
  SsqBsqWidgetRateColumnType.Rule,
  SsqBsqWidgetRateColumnType.EffectiveDate,
  SsqBsqWidgetRateColumnType.ExpirationDate,
  SsqBsqWidgetRateColumnType.Amount
]

export const applicableRateColumns = [
  SsqBsqWidgetRateColumnType.Select,
  SsqBsqWidgetRateColumnType.ID,
  SsqBsqWidgetRateColumnType.Activity,
  SsqBsqWidgetRateColumnType.Surcharges,
  SsqBsqWidgetRateColumnType.Type,
  SsqBsqWidgetRateColumnType.CalculationType,
  SsqBsqWidgetRateColumnType.Rule,
  SsqBsqWidgetRateColumnType.EffectiveDate,
  SsqBsqWidgetRateColumnType.ExpirationDate,
  SsqBsqWidgetRateColumnType.Amount
]
