import * as React from 'react'
import { QMPTab, NewRateDTO, IQMPFilters, IQMPExtraData, RateType } from '../components/common/QMP/interfaces'
import { TNewCustomerQuotes } from '../services/DTO/customerQuote/interfaces'

export interface IQMPContext {
  setActiveTab(tab: QMPTab): void
  setOpenedCustomerQuoteId(id: string): void
  setFilters(filters: IQMPFilters): void
  setExtraData(extraData: IQMPExtraData): void
  setSSQIds(ssqIds: string[]): void
  setBSQIds(bsqIds: string[]): void
  setRateIds(props: { ssqIds: string[]; bsqIds: string[] }): void
  setCustomerQuoteIds(customerQuoteIds: string[]): void
  setNewSSQRates(newSSQRates: NewRateDTO[]): void
  setNewBSQRates(newBSQRates: NewRateDTO[]): void
  setNewRates(rateType: RateType, newRates: NewRateDTO[]): void
  setNewCustomerQuotes(newCustomerQuotes?: TNewCustomerQuotes): void
  cancelCustomerQuoteEditing(): void
  updateQMPTabData(options: any): void
}

export const QMPContext = React.createContext<IQMPContext>({
  setActiveTab: null,
  setOpenedCustomerQuoteId: null,
  setFilters: null,
  setExtraData: null,
  setSSQIds: null,
  setBSQIds: null,
  setRateIds: null,
  setCustomerQuoteIds: null,
  setNewSSQRates: null,
  setNewBSQRates: null,
  setNewRates: null,
  setNewCustomerQuotes: null,
  cancelCustomerQuoteEditing: null,
  updateQMPTabData: null
})
