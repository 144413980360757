import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import ReactTooltip from 'react-tooltip'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { Navigation } from './Navigation/Navigation'
import { getGeneralChatCounter, getUserChatCounter, getUsers } from '../epics'
import {
  ActiveChatChannelIdSpecialTypes,
  ChannelType,
  CommunicationHubTab,
  IChannelMeta,
  ICHubChannel
} from '../../../store/reducers/communicationHub/interfaces'
import { debuggingMode } from '../../../services/debug'
import { DetailsView } from './DetailsView/DetailsView'
import { CommunicationHubDraftMessageProvider } from '../../../providers/CommunicationHubDraftMessageProvider'
import { CommunicationHubConstants } from '../../../constants/CommunicationHubConstants'
import { UserDetails } from './UserDetails'
import { getCommunicationHubState } from '../../../store'
import { useAppSelector } from '../../../hooks/useAppSelector'
import {
  selectCommunictaionHubIsVisible,
  selectCommunictaionHubTab
} from '../../../store/select/communicationHubSelect'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { NavigationHeader } from './Navigation/NavigationHeader'
import { pushListItemsToStore } from '../../../store/reducers/lists/functions/pushListItemsToStore'
// tslint:disable:max-line-length
import { generateDispatchDeliveryOrderChatChannel } from '../../../services/functions/generate/generateDispatchDeliveryOrderChatChannel'
import { requestGridDispatchDeliveryOrders } from '../../common/dispatchDeliveryOrder/epics'

type StateProps = {}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const CommunicationHub = (props: Props) => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectCommunictaionHubTab)
  const isCommunicationHubVisible =
    !debuggingMode.hideCommunicationHub && useAppSelector(selectCommunictaionHubIsVisible)

  React.useEffect(() => {
    const getInitialData = async () => {
      try {
        const ddoChannelsLimit = CommunicationHubConstants.maxChannelsNumber[ChannelType.dispatchDeliveryOrder]
        const ddoIds: string[] = []

        await Promise.all([getUsers(), getGeneralChatCounter(), getUserChatCounter()]).then(
          async ([users, generalChannelCounters, userChannelsCounters]) => {
            for (let i = 0; i < ddoChannelsLimit; i++) {
              const ddoId = generalChannelCounters[i] ? generalChannelCounters[i].id : undefined
              if (ddoId) {
                ddoIds.push(ddoId)
              }
            }

            // @ts-ignore
            const channelCounters: IChannelMeta[] = generalChannelCounters.concat(userChannelsCounters)

            const channelsMeta = channelCounters.reduce((acc, curr) => {
              const channelId = curr.id
              // @ts-ignore
              const existedChannelData = acc[channelId] || {}
              // @ts-ignore
              acc[channelId] = { ...existedChannelData, ...curr }

              return acc
            }, {})

            dispatch(communicationHubActions.setInitialData({ channelsMeta }))

            if (Boolean(Array.isArray(users) && users.length)) {
              await pushListItemsToStore({ update: { [EntityType.user]: users } })
            }

            dispatch(communicationHubActions.setInitialData({ channelsMeta }))
          }
        )

        const { activeChannel, channels } = getCommunicationHubState()
        const activeDDOChannelId = oc(activeChannel).id()

        if (
          activeDDOChannelId &&
          !Object.keys(ActiveChatChannelIdSpecialTypes).includes(activeDDOChannelId) &&
          !ddoIds.includes(activeDDOChannelId)
        ) {
          ddoIds.push(activeDDOChannelId)
        }

        const resentChannelIds = oc(channels).idListByType[ChannelType.recentlyOpened]([])
        ddoIds.push(...resentChannelIds)

        if (ddoIds.length) {
          requestGridDispatchDeliveryOrders({ filter: 'id%%' + ddoIds.join(',') }, false).then(ddos => {
            if (ddos.length) {
              const channelIdList: string[] = []
              const channelList: ICHubChannel[] = []

              ddos.forEach(ddo => {
                const channel = generateDispatchDeliveryOrderChatChannel(ddo)
                channelIdList.push(channel.id)
                channelList.push(channel)
              })

              dispatch(
                communicationHubActions.setChannelIdList({
                  channelType: ChannelType.dispatchDeliveryOrder,
                  channelIdList,
                  channelList
                })
              )
            }
          })
        }
      } catch (e) {}
    }

    getInitialData()
  }, [])

  return (
    <CommunicationHubDraftMessageProvider>
      {isCommunicationHubVisible && (
        <Container
          key={activeTab}
          style={{ backgroundColor: activeTab === CommunicationHubTab.messenger ? '#06182f' : '#071538' }}
        >
          <Navigation />
          <DetailsView />
        </Container>
      )}

      <ReactTooltip
        className={'react-tooltip-custom-inner'}
        id={'user-details-hint'}
        place={'right'}
        event={'click'}
        globalEventOff={'click'}
        clickable={true}
        getContent={(id: string) => <UserDetails userAuthOrVendorId={id} />}
      />
    </CommunicationHubDraftMessageProvider>
  )
}

const Container = styled.div`
  z-index: 1;
  display: flex;
`
