import * as React from 'react'
import { oc } from 'ts-optchain'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { activityDirectory } from '../../DTO/activity/directory'
import { getNeededActivityTypesByStages } from '../get/getNeededActivityTypesByStages'
import { ActivityGroup } from '../../DTO/activity/interfaces'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  activityGroups: ActivityGroup[]
}

// tslint:disable:max-line-length
export const generateRequiredLoadTypeActivitiesErrorMessage = (props: Props): JSX.Element => {
  const { dispatchDeliveryOrder } = props

  if (!dispatchDeliveryOrder) {
    return null
  }

  const NeededActivityTypes = () => (
    <span style={{ fontWeight: 500 }}>
      {getNeededActivityTypesByStages(props)
        [TransportationActivityViewDTO.StageEnum.DELIVERY].map(activityType => activityDirectory.type[activityType])
        .join(', ')}
    </span>
  )

  const deliveryStageLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
  const deliveryLocationName = deliveryStageLocationId
    ? oc(getListsState())[EntityType.location][deliveryStageLocationId].name()
    : undefined
  const deliveryLocationText = deliveryLocationName ? (
    <span>
      {' '}
      with the Delivery location <span style={{ fontWeight: 500 }}>{deliveryLocationName}</span> selected on the DDO
      General tab
    </span>
  ) : null

  const DropAndPickText = () => (
    <>
      DDO can't be saved. Default activities{deliveryLocationText} are missing:
      <br />
      <NeededActivityTypes />.
      <br />
      <br />
      D&P Load Type without one or both default activities allows only Unsuccessful activities with any location types
      and not Unsuccessful activities with locations having Container Yard type.
      <br />
      <br />
      Missing default activities can be added by click on Plus button for Delivery stage.
    </>
  )

  const LiveLoadText = () => (
    <>
      DDO can't be saved. Default activity{deliveryLocationText} is missing: <NeededActivityTypes />.
      <br />
      <br />
      Live Load Type without default activity allows only Unsuccessful activities with any location types and not
      Unsuccessful activities with locations having Container Yard type.
      <br />
      <br />
      Missing default activity can be added by click on Plus button for Delivery stage.
    </>
  )

  return (
    <div style={{ whiteSpace: 'pre-wrap', lineHeight: 1.2 }}>
      {dispatchDeliveryOrder.loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK ? (
        <DropAndPickText />
      ) : (
        <LiveLoadText />
      )}
    </div>
  )
}
