import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridItemCustomerContext } from '../contexts/GridItemCustomerContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useBusinessPartner } from '../hooks/useBusinessPartner'
import { useCustomer } from '../hooks/useCustomer'
import { useContact } from '../hooks/useContact'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemCustomerProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const customer = useCustomer({ id, modifiedLists })
  const businessPartner = useBusinessPartner({ id: oc(customer).businessPartnerId(), modifiedLists })
  const primaryContact = useContact({ id: oc(businessPartner).primaryContactId(), modifiedLists })

  return (
    <GridItemCustomerContext.Provider
      value={{ isExpanded: Boolean(modifiedLists), customer, businessPartner, primaryContact }}
      children={children}
    />
  )
})
