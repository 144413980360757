import * as React from 'react'
import { oc } from 'ts-optchain'
import Company from './company'
import { Address } from './address'
import { PrimaryContact } from '../../../../contact/views/PrimaryContact'
import { StyledGeneral } from './styledComponents'
import { connect } from 'react-redux'
import { AddressDTO } from '../../../../../../api/origin/business-logic'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemLocation } from '../../../../../../hooks/useGridItemLocation'
import { EntityType } from '../../../../../../store/reducers/lists/interfaces'
import { isFullObject } from '../../../../../../services/functions/test/isFullObject'

type Props = {}

export const General = (props: Props) => {
  const { modifyParentObject, modifyParentObjectField } = useExpandedItem()
  const { location } = useGridItemLocation()

  return (
    <StyledGeneral rows={70}>
      {isFullObject(location) && (
        <>
          <Company location={location} modifyParentObjectField={modifyParentObjectField} modify={modifyParentObject} />
          {/*<StyledMap columns={13}>*/}
          {/*<Map*/}
          {/*position={*/}
          {/*location.latitude && location.longitude*/}
          {/*? { lat: location.latitude, lng: location.longitude }*/}
          {/*: undefined*/}
          {/*}*/}
          {/*/>*/}
          {/*</StyledMap>*/}
          <Address
            location={location}
            address={oc(location).address(null)}
            changeAddress={(field: keyof AddressDTO, value: any) =>
              modifyParentObjectField('address')({
                ...location.address,
                [field]: value
              })
            }
            selectLocationBySearch={(locationDetails: any) =>
              locationDetails ? modifyParentObject(locationDetails) : null
            }
          />
          <PrimaryContact
            parentEntity={location}
            entityType={EntityType.location}
            padding={{ top: 20, left: 20, right: 60 }}
            titleStyles={{ margin: '25px 0 30px', fontWeight: 500 }}
          />
        </>
      )}
    </StyledGeneral>
  )
}

export default connect(
  null,
  null
)(General)
