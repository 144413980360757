import { oc } from 'ts-optchain'
import { OngoingActivityDTO, TransportationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { isPickActivity } from './isPickActivity'

const dropActivityComboTypes = [
  TransportationActivityViewDTO.ComboTypeEnum.EMPTY,
  TransportationActivityViewDTO.ComboTypeEnum.OFFDUTY
]

export const isBobtailGotoActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  return oc(activity).type() === TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO
}

export const isBobtailActivityType = (type: TransportationActivityViewDTO.TypeEnum): boolean => {
  return type === TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO
}

export const isDropBobtailGotoActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  // @ts-ignore
  return isBobtailGotoActivity(activity) && dropActivityComboTypes.includes(activity.comboType)
}

export const isPickBobtailGotoActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  return isBobtailGotoActivity(activity) && isPickActivity(activity)
}
