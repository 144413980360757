export enum StorageKeys {
  RefreshToken = 'RefreshToken',
  AccessToken = 'AccessToken',
  IdToken = 'IdToken',
  AuthVerifier = 'AuthVerifier',
  AuthState = 'AuthState',
  UserId = 'UserId'
}

export const getItemFromStorage = (key: StorageKeys): string | null => {
  return sessionStorage.getItem(key) || localStorage.getItem(key)
}

export const saveItemToStorage = (key: StorageKeys, value: string, local = true): void => {
  return local ? localStorage.setItem(key, value) : sessionStorage.setItem(key, value)
}

export const removeItemFromStorage = (key: StorageKeys, local = true): void => {
  return local ? localStorage.removeItem(key) : sessionStorage.removeItem(key)
}
