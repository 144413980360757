import { TransportationActivityBobtailToDTO, TransportationActivityViewDTO } from '../../../api/api'

export const getBobtailRefDispatchDeliveryOrderInfo = (
  activity: TransportationActivityViewDTO
): TransportationActivityBobtailToDTO | undefined => {
  return (
    activity &&
    [activity.bobtailTo, activity.bobtailFrom]
      .filter(
        bobtailLink => bobtailLink && bobtailLink.dispatchDeliveryOrderId && bobtailLink.dispatchDeliveryOrderNumber
      )
      .find(bobtailLink => bobtailLink.dispatchDeliveryOrderId !== activity.dispatchDeliveryOrderId)
  )
}
