import styled from 'styled-components'
import { GridColumn } from '../GridSystem'
import { withProps } from '../../../styles/styledComponents'
import theme from '../../../styles/theme'

/* Main */
export const PopoverContainer = styled.div`
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);

  &.isExternal {
    position: fixed;
  }
`
export const WidgetTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  padding-bottom: 16px;
  user-select: none;
  cursor: default;
`
export const ButtonClose = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1px;
  top: 8px;
  font-size: 20px;
  font-weight: 500;
  height: 40px;
  width: 43px;
  color: #bdbdbd;
  border-radius: 10px 10px 0 0;
  padding-bottom: 2px;
  cursor: pointer;

  &:hover {
    color: rgb(68, 83, 102);
  }
`
export const Preview = styled(GridColumn)`
  background-color: white;
  border: 1px solid #e3e6f1;
  border-radius: 5px;
  transition: box-shadow 0.25s;
  cursor: pointer;
  padding: 10px 10px 0 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);

  &.blue {
    border-color: rgba(124, 127, 207, 0.5);
    box-shadow: 5px 5px 10px rgba(124, 127, 207, 0.05);
  }
  &.pink {
    border-color: rgba(193, 121, 209, 0.5);
    box-shadow: 5px 5px 10px rgba(193, 121, 209, 0.05);
  }
  &.green {
    border-color: rgba(69, 165, 155, 0.5);
    box-shadow: 5px 5px 10px rgba(69, 165, 155, 0.05);
  }
  &.red {
    background-color: rgba(255, 94, 94, 0.1);
  }

  &.highlighted {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed} !important;
    transition: 0s;
  }
`
export const Popover = styled.div`
  z-index: 10;
  position: relative;
  min-width: 350px;
  border-radius: 5px;
  background-color: white;
  padding: 16px 16px 65px;
  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.25);

  &.fullScreen {
    width: calc(100% - 25px);
    height: calc(100% - 25px);
  }

  &.blue {
    box-shadow: 5px 5px 64px rgba(124, 127, 207, 0.25);
  }
  &.pink {
    box-shadow: 5px 5px 64px rgba(193, 121, 209, 0.25);
  }
  &.green {
    box-shadow: 5px 5px 64px rgba(69, 165, 155, 0.25);
  }
`
export const WidgetActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 16px;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`
export const StyledWidget = styled.div`
  width: 100%;
  height: 75px;
  display: flex;

  &:not(.open):not(.disable) {
    ${Preview}:hover {
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    }
  }

  &.disableEditing {
    ${Popover} {
      .isEmpty,
      .selected:before {
        opacity: 0;
      }
    }
  }

  &:not(.save) {
    ${Popover} {
      padding-bottom: 15px;
    }
  }

  &.fixedWidth ${Popover} {
    max-width: 100%;
  }

  &.tableWidget {
    height: 35px;

    ${Preview} {
      justify-content: center;
      padding-top: 0;
      box-shadow: none !important;
      border: 1px solid rgba(229, 229, 229, 1);

      &.errored {
        border-color: ${theme.colors.defaultRed};
      }

      &.highlighted {
        box-shadow: 0 0 0 1px ${theme.colors.defaultRed} !important;
      }
    }
  }

  &.disable {
    ${Preview} {
      cursor: default;
    }

    &.tableWidget {
      ${Preview} {
        border: 1px solid rgba(229, 229, 229, 0.4);
      }

      .mdi.mdi-menu-down {
        padding-right: 0;

        &:before {
          content: none;
        }
      }
    }
  }
`

/*  Other */

export const Title = styled(GridColumn)`
  font-size: 10px;
  color: #445366;
  user-select: none;
`
const DefaultTextProps = styled(GridColumn)`
  color: #445366;

  &.black {
    color: #333333;
  }
  &.blue {
    color: #7c7fcf;
  }
  &.darkBlue {
    color: #4555d0;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.lowercase {
    text-transform: lowercase;
  }
  &.letterSpacing {
    letter-spacing: 0.1em;
  }
  &.opacity {
    opacity: 0.5;
  }
  &.bold {
    font-weight: 500;
  }
  &.inTwoLines {
    line-height: 1.3;
    overflow: hidden;
  }
  &.fontSize18 {
    font-size: 18px !important;
  }
`
export const BoldLine = styled(DefaultTextProps)`
  font-size: 14px;
  font-weight: 500;
`
export const Text = styled(DefaultTextProps)`
  font-size: 12px;
`
export const WithIcon = styled.span`
  &:before {
    text-align: center;
    width: 30px;
    color: #4555d0 !important;
    font-size: 20px !important;
    line-height: 25px !important;
  }
`
export const Additional = withProps<{ fontSize?: number }, HTMLDivElement>(styled(DefaultTextProps))`
  align-items: center;
  font-size: ${props => props.fontSize || 12}px;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: 0;
  text-transform: none;
`
export const EditModeButton = styled.div`
  font-size: 12px;
  color: #4555d0;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
`
