import { oc } from 'ts-optchain'
import { RootState } from '..'
import {
  ITab,
  ITabExpandedItem,
  ITabExpandedItemData,
  ITabUISettings,
  TabDTO,
  TabExpandedItemDataProp
} from '../reducers/tabs/interfaces'
import { TListsState } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectActiveApplicationTabId = (state: RootState): string | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.id : undefined
}

export const selectActiveApplicationTab = (state: RootState): ITab | undefined => {
  const { tabs } = state

  return tabs.find(tab => tab.active)
}

export const selectActiveApplicationTabType = (state: RootState): TabDTO.Type => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.type : undefined
}

export const selectActiveApplicationTabListRequestType = (state: RootState): TabDTO.Request.List => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).request.listRequestType()
}

export const selectActiveApplicationTabGridItemDetailsRequestType = (
  state: RootState
): TabDTO.Request.GridItemDetailsRequestType => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).request.gridItemDetailsRequestType()
}

export const selectActiveApplicationTabPropsToRequest = (state: RootState): TabDTO.Request.PopsToRequest => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).request.propsToRequest()
}

export const selectActiveApplicationTabActionsType = (state: RootState): TabDTO.Actions => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).actionsType()
}

export const selectActiveApplicationTabSelectedIds = (state: RootState): string[] | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).data.selectedIds()
}

export const selectActiveApplicationTabPinnedIds = (state: RootState): string[] | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).data.pinnedIds()
}

export const selectActiveApplicationTabDisabledSelectIds = (state: RootState): string[] | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).data.disabledSelectIds()
}

export const selectActiveApplicationTabEnableEditing = (state: RootState): boolean => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).permissions.edit()
}

export const selectActiveApplicationTabData = (state: RootState): any => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.data : undefined
}

export const selectActiveApplicationTabPermissionsWebsoketsUpdate = (state: RootState): boolean => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).permissions.websoketsUpdate()
}

export const selectActiveApplicationTabRefrashOnTabEnter = (state: RootState): boolean => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.refrashOnTabEnter : false
}

export const selectActiveApplicationTabFetching = (state: RootState): boolean => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.fetching : false
}

export const selectActiveApplicationTabGridItemIds = (state: RootState): string[] | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.gridItemIds : undefined
}

export const selectActiveApplicationTabModifiedListsForEntityId = (entityId: string) => (
  state: RootState
): Partial<TListsState> | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  if (activeTab && activeTab.expandedItem.id === entityId) {
    return activeTab.expandedItem.modifiedLists
  }

  return undefined
}

export const selectActiveApplicationTabIsModifiedMode = (state: RootState): boolean | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab
    ? Object.values(activeTab.expandedItem.modifiedLists).some(mapping => Object.keys(mapping).length)
    : undefined
}

export const selectActiveApplicationTabUISettings = (state: RootState): ITabUISettings => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.uiSettings : undefined
}

export const selectActiveApplicationTabVisiteState = (state: RootState): boolean => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return activeTab ? activeTab.visited : false
}

export const selectApplicationTab = (tabId: string) => (state: RootState): ITab | undefined => {
  const { tabs } = state

  return tabs.find(tab => tab.id === tabId)
}

export const selectApplicationTabFavoriteOngoingActivityState = (activityGroupId: string) => (
  state: RootState
): boolean | undefined => {
  const { tabs } = state
  const activeTab = tabs.find(tab => tab.active)

  return oc(activeTab).data.favoriteItemIds({})[activityGroupId]
}

export const selectExpandedTabItemByTabId = (tabId: string) => (state: RootState): ITabExpandedItem => {
  const tab = state.tabs.find(_ => _.id === tabId)

  if (tab) {
    return tab.expandedItem
  }

  return undefined
}

export const selectExpandedTabItem = (state: RootState): ITabExpandedItem => {
  const tab = state.tabs.find(_ => _.active)

  if (tab) {
    return tab.expandedItem
  }

  return undefined
}

export const selectExpandedTabItemId = (state: RootState): string => {
  const tab = state.tabs.find(_ => _.active)

  if (tab) {
    return tab.expandedItem.id
  }

  return undefined
}

export const selectExpandedTabItemData = (state: RootState): ITabExpandedItemData => {
  const tab = state.tabs.find(_ => _.active)

  if (tab) {
    return tab.expandedItem.data
  }

  return undefined
}

export const selectExpandedTabItemDataProp = (prop: TabExpandedItemDataProp) => (state: RootState): any => {
  const tab = state.tabs.find(_ => _.active)

  if (tab) {
    return oc(tab).expandedItem.data[prop]()
  }

  return undefined
}

export const selectExpandedTabItemDataByTabId = (tabId: string | undefined) => (
  state: RootState
): ITabExpandedItemData => {
  const tab = state.tabs.find(_ => _.id === tabId)

  if (tab) {
    return tab.expandedItem.data
  }

  return undefined
}

// SELECT MANY
export const selectApplicationTabsCount = (state: RootState): number => {
  const { tabs } = state

  return tabs.length
}

export const selectApplicationTabs = (state: RootState): ITab[] => {
  const { tabs } = state

  return tabs
}

export const selectApplicationTabsByType = (tabType: TabDTO.Type) => (state: RootState): ITab[] => {
  const { tabs } = state

  return tabs.filter(tab => tab.type === tabType)
}
