import * as React from 'react'
import cn from 'classnames'
import MaskedInput from 'react-text-mask'
import { Container, StyledInput, ClearButton } from '../styles'
import { isHighlighted, handleKeyPress, getStringValue } from '../functions'
import { FieldContainer } from '../../FieldContainer'
import { InputErrorIcon } from '../InputErrorIcon'

export type TProps = {
  id?: string
  textMask: any[]
  dynamicTextMask?: (inputValue: string, editState: boolean) => any[]
  disabled?: boolean
  title?: string
  value: any
  transformOnInputChange?: (value: string) => string | number
  transformInputLabel?: (value: string) => string | number
  onChange: (value: any) => void
  required?: boolean
  valid?: boolean
  highlighted?: boolean
  placeholder?: string
  maxLength?: number
  inputContainerStyle?: React.CSSProperties
  errorMessage?: JSX.Element | string
}

export type TStateProps = {
  editState: boolean
  inputRef: React.RefObject<HTMLInputElement>
  value: any
}

export const TextMask = (props: TProps) => {
  const {
    id,
    required,
    title,
    maxLength,
    disabled,
    textMask,
    placeholder,
    inputContainerStyle,
    dynamicTextMask,
    transformOnInputChange,
    transformInputLabel
  } = props
  const inputRef = React.useRef(null)
  const [editState, setEditState] = React.useState(false)
  const [value, setValue] = React.useState(getStringValue(props.value) || '')
  const isEmptyValue = value === undefined || value === null || value === ''
  const state: TStateProps = {
    editState,
    inputRef,
    value
  }

  React.useEffect(() => {
    setValue(getStringValue(props.value) || '')
  }, [props])

  const onInputChange = (e: any) => {
    setValue(transformOnInputChange ? transformOnInputChange(e.target.value) : e.target.value)
  }

  const handleFocus = () => {
    setEditState(true)
  }

  const handleBlur = () => {
    if (value !== getStringValue(props.value)) {
      props.onChange(value ? value.trim() : null)
    }
    setEditState(false)
  }

  const inputValue = transformInputLabel ? transformInputLabel(value) : value

  return (
    <FieldContainer title={title} required={required} style={props.inputContainerStyle}>
      <Container
        style={{ width: '100%' }}
        className={cn('input-container', {
          'input-container': true,
          'extra-padding': isEmptyValue || disabled,
          highlighted: isHighlighted(props, state),
          disabled
        })}
      >
        {Boolean(props.errorMessage) && (
          <InputErrorIcon style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
            {props.errorMessage}
          </InputErrorIcon>
        )}
        <MaskedInput
          id={id}
          disabled={disabled}
          placeholder={placeholder || 'Type here'}
          mask={dynamicTextMask ? dynamicTextMask(inputValue, editState) : textMask}
          showMask={true}
          guide={false}
          maxLength={maxLength}
          value={inputValue}
          onChange={onInputChange}
          // @ts-ignore
          onKeyPress={handleKeyPress(props, state)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-copy-value-on-mousedown={true}
          render={(ref, inputProps) => <StyledInput type="text" ref={ref} {...inputProps} />}
        />
        {!isEmptyValue && !disabled && (
          <ClearButton className={'mdi mdi-close-circle'} onClick={() => props.onChange(null)} />
        )}
      </Container>
    </FieldContainer>
  )
}
