import { oc } from 'ts-optchain'
import { getActiveApplicationTabState, getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { _assembleDispatchDeliveryOrder } from './assembleDispatchDeliveryOrder'

type Props = {
  entityId: string
  entityType: EntityType
  deep?: boolean
  modifiedLists?: Partial<TListsState>
}

export const assembleEntity = (props: Props): any => {
  try {
    const { entityId, entityType, deep } = props
    const storeLists = getListsState()
    const modifiedLists = props.modifiedLists || oc(getActiveApplicationTabState()).expandedItem.modifiedLists()

    const getEntity = (id: string, type: EntityType): any => {
      if (!(id && type)) {
        return undefined
      }

      const storeItem = storeLists[type][id]
      const modifiedItem = oc(modifiedLists)[type][id]()

      return modifiedItem || storeItem
    }

    if (!deep) {
      return getEntity(entityId, entityType)
    }

    switch (entityType) {
      case EntityType.dispatchDeliveryOrder:
        return _assembleDispatchDeliveryOrder({ entityId, entityType, modifiedLists, getEntity })
      default:
        return getEntity(entityId, entityType)
    }
  } catch (e) {
    return undefined
  }
}
