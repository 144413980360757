import { hot } from 'react-hot-loader'
import * as React from 'react'
import { Store } from 'redux'
import '../styles/fonts.scss'
import { Provider } from 'react-redux'
import App from './App'
import { IStore } from '../store/store.interface'
import { store as newStore, newStoreContext } from '../store'

const Root = ({ store }: { store: Store<IStore> }) => (
  <Provider store={newStore} context={newStoreContext}>
    <Provider store={store as any}>
      <App />
    </Provider>
  </Provider>
)

export default hot(module)(Root)

export { Root }
