import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelMetaUnreadMessagesNumber } from '../../../../store/select/communicationHubSelect'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { ChatTab } from '../../../../store/reducers/communicationHub/interfaces'

type OwnProps = {
  channelId: string
  scrollBottom: () => void
  chatTab: ChatTab
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NewMessagesNotification = React.memo((props: Props) => {
  const { scrollBottom, chatTab, channelId } = props
  const dispatch = useAppDispatch()
  const unreadMessagesNumber = useAppSelector(selectCommunictaionHubChannelMetaUnreadMessagesNumber(channelId))

  const onUnreadMessagesClick = React.useCallback(() => {
    if (chatTab === ChatTab.Messages) {
      scrollBottom()
    } else {
      dispatch(communicationHubActions.setActiveChannelTab(ChatTab.Messages))
    }
  }, [chatTab, scrollBottom])

  const clearUnreadMessagesNumber = React.useCallback(() => {
    dispatch(communicationHubActions.clearChannelIdUnreadCount(channelId))
  }, [channelId])

  if (!unreadMessagesNumber) {
    return null
  }

  return (
    <Container>
      <LinkToBottom className={'mdi mdi-arrow-down'} onClick={onUnreadMessagesClick}>
        {unreadMessagesNumber} new message{unreadMessagesNumber > 1 ? 's' : ''}
      </LinkToBottom>
      <ClearButton className={'mdi mdi-close'} onClick={clearUnreadMessagesNumber} />
    </Container>
  )
})

const Container = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  top: 53px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  user-select: none;
  cursor: pointer;

  > div {
    background-color: ${theme.colors.basicBlueColor};

    &:hover {
      background-color: ${theme.colors.basicBlueColor}99;
    }
  }
`

const LinkToBottom = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: ${theme.colors.basicBlueColor};
  padding: 0 5px 0 10px;
  border-radius: 10px 0 0 10px;

  &:before {
    margin-right: 5px;
  }
`

const ClearButton = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-left: 1px solid white;
  border-radius: 0 10px 10px 0;
  padding: 0 10px 0 5px;
`
