import { TransportationActivityViewDTO } from '../../../api/api'

const inProgressStatusses = [
  TransportationActivityViewDTO.StatusEnum.INPROCESS,
  TransportationActivityViewDTO.StatusEnum.COMPLETED
]

export const isInProgressActivity = (activity: TransportationActivityViewDTO): boolean => {
  return activity && inProgressStatusses.includes(activity.status)
}
