import styled from 'styled-components'

export const Title = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 0 20px;
  margin: 20px 0 5px;
  cursor: default;
  user-select: none;

  &:first-child {
    margin-top: -20px;
  }
`
