import * as React from 'react'
import styled from 'styled-components'
import { RawDraftContentState } from 'draft-js'
import cn from 'classnames'
import { generateHTMLFormDraftJS } from '../../../../../services/functions/generate/generateHTMLFormDraftJS'

type OwnProps = {
  subject: string
  text: RawDraftContentState
  statusColor: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NotificationText = (props: Props) => {
  const { subject, text, statusColor } = props
  const containerRef = React.useRef(null)
  const [isExpandable, setIsExpandable] = React.useState<boolean>(false)
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (containerRef.current) {
      const elementClone = containerRef.current.cloneNode(true)
      elementClone.style.width = `${containerRef.current.offsetWidth}px`
      elementClone.style.maxHeight = 'unset'
      elementClone.style.overflow = 'unset'
      elementClone.style.visibility = 'hidden'
      elementClone.style.position = 'fixed'
      document.body.append(elementClone)

      setIsExpandable(elementClone.offsetHeight > containerRef.current.offsetHeight)

      elementClone.remove()
    }
  }, [subject, text])

  const textContent = React.useMemo(() => {
    return generateHTMLFormDraftJS(text)
  }, [text])

  return (
    <>
      <Container className={cn({ isExpanded: isExpandable && isExpanded })} ref={containerRef}>
        <Subject>{subject}</Subject>
        {textContent && <Text>{textContent}</Text>}
      </Container>
      {isExpandable && (
        <>
          {!isExpanded && (
            <TextOverflow
              style={{
                background: `linear-gradient(0deg, ${statusColor} 0%, ${statusColor} 30%, rgba(255, 255, 255, 0) 100%)`
              }}
            />
          )}
          <ShowMoreButton
            className={cn('mdi', { 'mdi-chevron-down': !isExpanded, 'mdi-chevron-up': isExpanded })}
            onClick={() => setIsExpanded(state => !state)}
            children={isExpanded ? 'Show Less' : 'Show More'}
          />
        </>
      )}
    </>
  )
}

const Container = styled.div`
  max-height: 100px;
  overflow: hidden;

  &.isExpanded {
    max-height: unset;
  }
`

const TextOverflow = styled.div`
  z-index: 1;
  position: relative;
  height: 20px;
  margin-top: -20px;
`

const Subject = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 4px;
`
const Text = styled.div`
  font-size: 14px;
  line-height: 1.75;
  margin-top: 4px;

  * {
    line-height: inherit;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  code {
    font-family: monospace;
    overflow-wrap: break-word;
  }
`

const ShowMoreButton = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #697585;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #333333;
  }

  &:before {
    font-size: 18px;
  }
`
