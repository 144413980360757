import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ContactDTO, LocationViewDTO } from '../../api/api'
import { requestUpdateContacts } from '../../components/common/contact/epics'
import { isNewId, isNewObject } from '../DTO'
import { requestCreateLocation, requestUpdateLocation } from '../../components/common/location/epics'

type Props = {
  location: LocationViewDTO | undefined
  contacts: ContactDTO[] | undefined
}

export const saveLocation = async (props: Props): Promise<LocationViewDTO> => {
  const { contacts } = props
  const location: LocationViewDTO = props.location ? R.clone(props.location) : undefined
  const isNewLocation = isNewObject(location)
  let savedLocation: LocationViewDTO = location
  let savedContacts: ContactDTO[] = undefined

  if (contacts && contacts.length) {
    savedContacts = await requestUpdateContacts(contacts)
  }

  if (location) {
    if (isNewId(location.primaryContactId)) {
      const primaryContactIdIndex = contacts.findIndex(contact => contact.id === location.primaryContactId)
      location.primaryContactId = oc(savedContacts)[primaryContactIdIndex].id()
    }

    savedLocation = await (isNewLocation
      ? requestCreateLocation({ ...location, contactIds: (savedContacts || []).map(({ id }) => id) })
      : requestUpdateLocation(
          savedContacts
            ? {
                ...location,
                contactIds: R.uniq(
                  location.contactIds.filter(id => !isNewId(id)).concat(savedContacts.map(({ id }) => id))
                )
              }
            : location
        ))
  }

  return savedLocation
}
