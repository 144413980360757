import styled from 'styled-components'

export const Item = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`

export const DetailsButtonSaveContainer = styled.div`
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
