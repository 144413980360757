import { IMoveDTO, MoveStatus } from '../../../store/reducers/communicationHub/interfaces'

export const filterVisibleMoves = (activeTabDate: string, moves: IMoveDTO[]): IMoveDTO[] => {
  if (!moves) {
    return moves
  }

  let visibleMoves: IMoveDTO[] = moves
  const completedStatuses = [MoveStatus.COMPLETED, MoveStatus.UNSUCCESSFUL]
  const hasCompletedUnsuccessfulMoves = moves.some(move => completedStatuses.includes(move.status))

  if (hasCompletedUnsuccessfulMoves) {
    const selectedDayMoves = moves.filter(({ _isPrevDayMove, _isNextDayMove }) => !_isPrevDayMove && !_isNextDayMove)

    if (selectedDayMoves.every(move => completedStatuses.includes(move.status))) {
      visibleMoves = selectedDayMoves
    } else {
      visibleMoves = visibleMoves.filter(({ id, status, _isPrevDayMove, _isNextDayMove }) => {
        if (_isPrevDayMove) {
          return !completedStatuses.includes(status)
        } else if (_isNextDayMove) {
          return (
            // move is not completed
            !completedStatuses.includes(status) ||
            // move is completed but has a ref with current day move
            moves.some(({ prevDoubleMoveId, nextDoubleMoveId }) => prevDoubleMoveId === id || nextDoubleMoveId === id)
          )
        } else {
          return true
        }
      })
    }
  }

  return visibleMoves
}
