import styled from 'styled-components'
import { theme } from '../../../../styles/theme'

export const StyledFieldContainer = styled.div`
  flex: none;
  width: 100%;

  &.highlighted {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }

  &.turnOver {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`

export const Title = styled.label`
  display: flex;
  font-size: 12px;
  line-height: 15px;
  color: rgba(108, 112, 125, 1);
  margin-bottom: 5px;
  user-select: none;

  &.disabled {
    color: rgba(108, 112, 125, 0.6);
  }

  &.required {
    &:after {
      content: '*';
      color: ${theme.colors.defaultRed};
      font-size: 14px;
      margin-left: 5px;
    }
  }
`

export const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`
