import { DeductionDTO, DeductionType, CheckType, DeductionStatus } from '../../../api/origin/vendor-accounting-service'
import { dateService } from '../../timeService'
import { createId } from '../../utils'

export const generateDeduction = (props: Partial<DeductionDTO> & { vendorId: string }): DeductionDTO => {
  return {
    id: createId(),
    vendorId: undefined,
    status: DeductionStatus.NEW,
    type: DeductionType.LOAN,
    startDate: undefined,
    deductionAmount: undefined,
    checkType: CheckType.CHECK,
    checkNumber: undefined,
    currentBalance: undefined,
    description: undefined,
    interest: undefined,
    principle: undefined,
    totalAmount: undefined,
    createdAt: dateService.createStringDate.now,
    updatedAt: undefined,
    updatedBy: undefined,
    createdBy: undefined,
    ...(props || {})
  }
}
