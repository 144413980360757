import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { ContactDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useContact = (props: Props): ContactDTO => {
  const { id, modifiedLists } = props
  const contact = useListItem({ id, type: EntityType.contact, modifiedLists })

  return contact
}
