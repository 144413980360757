import styled from 'styled-components'
import { theme } from '../../../../../../styles/theme'

export const StreetTurnSection = styled.div`
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 0 15px;
  margin: 0 15px;
  user-select: none;

  .widget-preview {
    padding: 0;
    background: none;
    border: none !important;
  }
`
export const StreetTurnButton = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  background-color: black;
  color: white;
  padding: 0 9px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:before {
    transform: rotate(-90deg) translateX(1px);
  }

  span {
    white-space: nowrap;
    margin-left: 5px;
  }
`
export const StreetTurnActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #999999;
  border-radius: 2px;
  color: #999999;
  padding: 0 9px;
  margin-left: 16px;
  cursor: pointer;

  &.cancel {
    &:after {
      content: 'Cancel';
    }
  }

  &.mdi:before {
    font-size: 20px;
    margin-right: 5px;
  }

  &:hover {
    background-color: #999999;
    color: white !important;
  }

  &.reject {
    border-color: #ff5e5e;
    color: #ff5e5e;
    &:hover {
      background-color: #ff5e5e;
    }

    &:after {
      content: 'Reject';
    }
  }

  &.approve {
    border-color: #27ae60;
    color: #27ae60;
    &:hover {
      background-color: #27ae60;
    }

    &:after {
      content: 'Approve';
    }
  }
`

export const StreetTurnOutlineActionButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  color: #697585;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: white !important;
  }

  &.cancel {
    width: 45px;
    font-weight: 400;
    font-size: 12px;
    border-color: #e0e0e0;
    color: #697585;

    &:hover {
      border-color: #697585;
      background-color: #697585;
    }

    &:after {
      content: 'Cancel';
    }
  }

  &.approve {
    border-color: #4ca36f;
    color: #4ca36f;

    &:hover {
      background-color: #4ca36f;
    }
  }

  &.reject {
    border-color: ${theme.colors.defaultRed};
    color: ${theme.colors.defaultRed};

    &:hover {
      background-color: ${theme.colors.defaultRed};
    }
  }
`

export const Container = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  color: ${theme.colors.basicBlueColor};
  font-size: 14px;
  margin-right: 30px;

  &.active:after {
    content: '';
    top: 50%;
    transform: translate(-50%, -50%);
    background: #4555d0;
    mix-blend-mode: normal;
    opacity: 0.1;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    z-index: 1;
    position: absolute;
    left: 12px;
  }

  .mdi {
    color: ${theme.colors.basicBlueColor};
    font-size: 24px;
    margin-right: 12px;

    &.send {
      transform: rotate(180deg) translateY(3px);
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
`

export const Dropdown = styled.div`
  margin-left: -8px;
  background: #ffffff;
  width: 208px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 4px 4px;
  padding: 19px 11px 16px;
  position: relative;
  .lds-dual-ring {
    width: 0; // fix allign
  }
`

export const SendingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`

export const DriverRow = styled.div`
  margin-bottom: 10px;
`
export const DriverName = styled.div`
  padding-left: 5px;
  line-height: 20px;
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`
export const InputContainer = styled.div`
  border-top: 1px solid #e5e5e5;
  padding-top: 16px;
  min-height: 72px;
  margin-bottom: 10px;
  .textarea {
    height: 72px;
  }
`

export const ButtonSend = styled.div`
  background: #4555d0;
  border-radius: 15px;
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  width: 75px;
  height: 24px;
  margin: 16px 0 0 auto;
  cursor: pointer;
  position: relative;
  &.disabled {
    cursor: not-allowed;
    background: ${theme.colors.defaultGray};
  }
  &.sending {
    cursor: not-allowed;
  }
  .lds-dual-ring {
    width: 18px;
    height: 18px;
    &:after {
      width: 14px;
      height: 14px;
      border: 2px solid;
      border-color: #fff rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
    }
  }
`

export const EntityCommentsOpener = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: 100px;
`

export const ChatOpenStatus = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #4555d0;
  }

  .mdi {
    font-size: 24px;
    margin-right: 10px;
  }
  .mdi-close-circle {
  }
  .mdi-forum {
  }
`

export const EntityCommentsChatWrapper = styled.div`
  z-index: 9;
  position: absolute;
  top: 45px;
  right: 0;
  bottom: 0;
  width: 330px;
  box-shadow: -4px 0 16px rgba(0, 0, 0, 0.05);
  animation: opening 0.3s;
  background: #fff;
  margin-top: 1px;

  @keyframes opening {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
  }
`
