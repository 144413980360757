export enum LocationGroupsView {
  LOCATION_GROUPS = 'LOCATION_GROUPS',
  LOCATION_GROUP = 'LOCATION_GROUP',
  EDIT_LOCATION_GROUP = 'EDIT_LOCATION_GROUP',
  CREATE_LOCATION_GROUP = 'CREATE_LOCATION_GROUP'
}

export enum LocationGroupType {
  GROUP_NAME = 'Group Name',
  CITY = 'City',
  STATE = 'State',
  POSTAL_CODE = 'Zip Code',
  LOCATION = 'Location'
}

export interface ILocationGroup {
  id: string
  type: LocationGroupType
  name: string
  ids: string[]
}

export interface ILocationGroupsTabData {
  view: LocationGroupsView
  filter: any
}

export interface ILocationGroupsFilter {
  postalCode?: string
  stateId?: string
  city?: string
}

export interface ILocationGroupsSearch {
  term: string
  type: LocationGroupType
}
