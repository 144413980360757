import * as React from 'react'
import { MiscSurchargeDTO } from '../../../../../services/DTO/rate/interfaces'
import { SsqBsqWidgetMiscSurchargeColumn, SsqBsqWidgetMiscSurchargeColumnType } from './SsqBsqWidgetMiscSurchargeColumn'

type Props = {
  miscSurcharge: MiscSurchargeDTO
  deleteMiscSurcharge: () => void
  modifyMiscSurcharge: (miscSurcharge: MiscSurchargeDTO) => void
  disable: boolean
  hideActivityColumn: boolean
}

export const SsqBsqWidgetMiscSurcharge = React.memo((props: Props) => {
  const { hideActivityColumn, miscSurcharge, disable, modifyMiscSurcharge, deleteMiscSurcharge } = props

  return (
    <>
      {(hideActivityColumn ? miscSurchargeColumnsWOActivity : miscSurchargeColumns).map(columnType => {
        return (
          <SsqBsqWidgetMiscSurchargeColumn
            key={columnType}
            columnType={columnType}
            miscSurcharge={miscSurcharge}
            disable={disable}
            modifyMiscSurcharge={modifyMiscSurcharge}
            deleteMiscSurcharge={deleteMiscSurcharge}
          />
        )
      })}
    </>
  )
})

export const miscSurchargeColumns = [
  SsqBsqWidgetMiscSurchargeColumnType.Activity,
  SsqBsqWidgetMiscSurchargeColumnType.CalculationType,
  SsqBsqWidgetMiscSurchargeColumnType.Description,
  SsqBsqWidgetMiscSurchargeColumnType.Amount,
  SsqBsqWidgetMiscSurchargeColumnType.Delete
]

export const miscSurchargeColumnsWOActivity = [
  SsqBsqWidgetMiscSurchargeColumnType.CalculationType,
  SsqBsqWidgetMiscSurchargeColumnType.Description,
  SsqBsqWidgetMiscSurchargeColumnType.Amount,
  SsqBsqWidgetMiscSurchargeColumnType.Delete
]
