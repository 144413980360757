export const isDatesEqual = (date1: string | null, date2: string | null): boolean => {
  if (!date1 && !date2) {
    return true
  }

  if (!date1 || !date2) {
    return false
  }

  const correctDate1 = new Date(Date.parse(date1)).setMilliseconds(0)
  const correctDate2 = new Date(Date.parse(date2)).setMilliseconds(0)

  return correctDate1 === correctDate2
}
