import * as R from 'remeda'
import { BusinessPartnerViewDTO, CustomerViewDTO, SubClientViewDTO } from '../../../../../api/api'
import { parseBusinessPartners } from './parseBusinessPartners'
import { parseCustomers } from './parseCustomers'
import { mergeParsedLists } from './mergeParsedLists'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'

export const parseSubClients = async (subClients: SubClientViewDTO[]): Promise<TListsStateUpdate> => {
  const customersToParse: CustomerViewDTO[] = []
  const businessPartnersToParse: BusinessPartnerViewDTO[] = []
  const result = generateEmptyListsState()

  try {
    subClients.forEach(subClient => {
      if (subClient.businessPartner) {
        businessPartnersToParse.push(subClient.businessPartner)
      }
      if (subClient.customer) {
        customersToParse.push(subClient.customer)
      }

      result[EntityType.subClient].push(R.omit(subClient, ['businessPartner', 'customer']))
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (customersToParse.length) {
    await parseCustomers(customersToParse).then(mergeParsedLists(result))
  }
  if (businessPartnersToParse.length) {
    await parseBusinessPartners(businessPartnersToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}
