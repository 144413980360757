import { EntityType } from '../../interfaces'
import { parseActivities } from './parseActivities'
import { parseBusinessPartners } from './parseBusinessPartners'
import { parseBuySideQuotes } from './parseBuySideQuotes'
import { parseContacts } from './parseContacts'
import { parseContainerTypes } from './parseContainerTypes'
import { parseContainers } from './parseContainers'
import { parseCustomers } from './parseCustomers'
import { parseDeductions } from './parseDeductions'
import { parseDeliveryOrders } from './parseDeliveryOrders'
import { parseDispatchDeliveryOrders } from './parseDispatchDeliveryOrders'
import { parseDrivers } from './parseDrivers'
import { parseDriversActivity } from './parseDriversActivity'
import { parseEquipments } from './parseEquipments'
import { parseHazmats } from './parseHazmats'
import { parseLocations } from './parseLocations'
import { parseMoves } from './parseMoves'
import {
  parseOngoingActivitiesDateStage,
  parseOngoingActivitiesDateStageNow,
  parseOngoingActivitiesDateDriver
} from './parseOngoingActivities'
import { parsePowerUnits } from './parsePowerUnits'
import { parseRates } from './parseRates'
import { parseRules } from './parseRules'
import { parseSellSideQuotes } from './parseSellSideQuotes'
import { parseStates } from './parseStates'
import { parseSteamShipLines } from './parseSteamShipLines'
import { parseSubClients } from './parseSubClients'
import { parseUsers } from './parseUsers'
import { parseVendorAccountings } from './parseVendorAccountings'

export const parseListItems = {
  [EntityType.businessPartner]: parseBusinessPartners,
  [EntityType.customer]: parseCustomers,
  [EntityType.contact]: parseContacts,
  [EntityType.subClient]: parseSubClients,
  [EntityType.steamShipLine]: parseSteamShipLines,
  [EntityType.container]: parseContainers,
  [EntityType.location]: parseLocations,
  [EntityType.driver]: parseDrivers,
  [EntityType.driverActivity]: parseDriversActivity,
  [EntityType.powerUnit]: parsePowerUnits,
  [EntityType.equipment]: parseEquipments,
  [EntityType.state]: parseStates,
  [EntityType.containerType]: parseContainerTypes,
  [EntityType.activity]: parseActivities,
  [EntityType.user]: parseUsers,
  [EntityType.deduction]: parseDeductions,
  [EntityType.vendorAccounting]: parseVendorAccountings,
  [EntityType.deliveryOrder]: parseDeliveryOrders,
  [EntityType.dispatchDeliveryOrder]: parseDispatchDeliveryOrders,
  [EntityType.buySideQuote]: parseBuySideQuotes,
  [EntityType.sellSideQuote]: parseSellSideQuotes,
  [EntityType.rate]: parseRates,
  [EntityType.rule]: parseRules,
  [EntityType.ongoingActivityDateDriver]: parseOngoingActivitiesDateDriver,
  [EntityType.ongoingActivityDateStage]: parseOngoingActivitiesDateStage,
  [EntityType.ongoingActivityDateStageNow]: parseOngoingActivitiesDateStageNow,
  [EntityType.hazmat]: parseHazmats,
  [EntityType.move]: parseMoves
}
