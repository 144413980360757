export const transformPhoneNumber = (tel: string | null): string => {
  return tel
    ? tel.split('').reduce((acc, char, index) => {
        if (index === 0) {
          return `(${char}`
        }
        if (index === 2) {
          return `${acc}${char})`
        }
        if (index === 3) {
          return `${acc} ${char}`
        }
        if (index === 6) {
          return `${acc}-${char}`
        }
        return `${acc}${char}`
      }, '')
    : ''
}
