import * as React from 'react'
import { Header as StyledHeader, Column } from '../styled'
import { Input } from '../../../../../../UI/DataFields/Input'
import { TAuditTrailFilter } from '../interfaces'
import { DateTypes } from '../../../../../../UI/DatePicker/Interfaces'
import DateField from '../../../../../../UI/DatePicker/DateField'
import cn from 'classnames'

type Props = {
  filter: TAuditTrailFilter
  setFilter: (filter: any) => void
}

export const Header = (props: Props) => {
  const { filter, setFilter } = props
  const setFilterField = (field: string) => (value: any) => {
    setFilter({ ...filter, [field]: value })
  }

  return (
    <StyledHeader>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.updatedAt
        })}
        style={{ overflow: 'visible' }}
      >
        <DateField
          date={filter.updatedAt}
          dateType={DateTypes.DateISOString}
          placeholder={'Datetime'}
          onUpdate={setFilterField('updatedAt')}
        />
      </Column>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.updatedBy
        })}
      >
        <Input value={filter.updatedBy} placeholder={'User'} onChange={setFilterField('updatedBy')} />
      </Column>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.activityNumber
        })}
        style={{ maxWidth: 180 }}
      >
        <Input value={filter.activityNumber} placeholder={'Activity #'} onChange={setFilterField('activityNumber')} />
      </Column>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.fieldName
        })}
      >
        <Input value={filter.fieldName} placeholder={'Field name'} onChange={setFilterField('fieldName')} />
      </Column>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.newValue
        })}
      >
        <Input value={filter.newValue} placeholder={'New Value'} onChange={setFilterField('newValue')} />
      </Column>
      <Column
        className={cn('mdi mdi-magnify', {
          active: filter.oldValue
        })}
      >
        <Input value={filter.oldValue} placeholder={'Old Value'} onChange={setFilterField('oldValue')} />
      </Column>
    </StyledHeader>
  )
}
