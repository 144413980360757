import { EditorState } from 'draft-js'
import { IAttachment } from '../../../services/filesService/interfaces'
import { generateChannelMessage } from './generateChannelMessage'
import { createChatMessage, updateChatMessage } from '../epics'
import { IMessage } from '../../../store/reducers/communicationHub/interfaces'

type Props = {
  channelId: string
  editorState: EditorState
  attachments: IAttachment[]
  prevMessageState?: IMessage
}

export const sendChannelMessage = (props: Props) => {
  const message = generateChannelMessage(props)

  if (!message) {
    return
  }

  const action = message.createdAt ? updateChatMessage : createChatMessage
  action(message)
}
