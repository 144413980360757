import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { NotificationDTO } from '../../../../../api/origin/communication-hub-service'
import { dateService } from '../../../../../services/timeService'
import { requestNotificationReplies } from '../../../epics'
import { downloadCsvContent } from '../../../../../services/functions/downloadCsvContent'
import { useAppSelector } from '../../../../../hooks/useAppSelector'

type OwnProps = {
  notification: NotificationDTO
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NotificationStatistic = (props: Props) => {
  const { notification } = props
  const { type, options, recipientUserIds, documentType, status, updatedBy, updatedAt } = notification
  const totalRecipientUserNumber = (recipientUserIds || []).length
  const replies = notification.replies || []
  const repliesNumber = replies.length

  if (type === NotificationDTO.TypeEnum.SIMPLE) {
    return null
  }

  const { lastReplyDate, completeDate, deleteDate, finishedByUser } = React.useMemo(() => {
    const result = {
      lastReplyDate: '',
      completeDate: '',
      deleteDate: '',
      finishedByUser: ''
    }

    if (status === NotificationDTO.StatusEnum.COMPLETED) {
      result.finishedByUser = updatedBy
      result.completeDate = updatedAt
    } else if (status === NotificationDTO.StatusEnum.DELETED) {
      result.finishedByUser = updatedBy
      result.deleteDate = updatedAt
    } else {
      replies.forEach(reply => {
        if (!result.lastReplyDate || result.lastReplyDate < reply.createdAt) {
          result.lastReplyDate = reply.createdAt
        }
      })
    }

    return result
  }, [replies, status])

  const calcPercentage = (current: number, total: number): string => {
    return (((current || 0) / (total || 0)) * 100).toFixed()
  }

  return (
    <Container>
      {Boolean(options && options.length) && (
        <Options>
          {options.map(option => {
            const _number: number = replies.filter(({ answerIds }) => (answerIds || []).includes(option.id)).length
            const _percentage = _number ? calcPercentage(_number, totalRecipientUserNumber) : 0

            return (
              <React.Fragment key={option.id}>
                <Percentage>
                  {_percentage}%<DarkDetails>( {_number} )</DarkDetails>
                </Percentage>
                <OptionText>{option.text}</OptionText>
              </React.Fragment>
            )
          })}
        </Options>
      )}

      <TotalResults>
        <Percentage
          style={
            totalRecipientUserNumber
              ? { color: repliesNumber === totalRecipientUserNumber ? '#3D8E5E' : theme.colors.defaultRed }
              : undefined
          }
        >
          {calcPercentage(repliesNumber, totalRecipientUserNumber)}%
        </Percentage>
        <Details>
          ( {repliesNumber} of {totalRecipientUserNumber} ) Replies
        </Details>
        {lastReplyDate && (
          <Details style={{ marginLeft: 12 }}>Last reply {dateService.makeDurationLabel(lastReplyDate)} ago</Details>
        )}

        {Boolean((completeDate || deleteDate) && finishedByUser) && (
          <>
            <Details style={{ marginLeft: 12 }}>
              {completeDate && 'Completed: ' + dateService.makeLabel(completeDate, { hideTime: true })}
              {deleteDate && 'Deleted: ' + dateService.makeLabel(deleteDate, { hideTime: true })}
            </Details>

            <Details style={{ marginLeft: 12 }}>by {finishedByUser}</Details>
          </>
        )}
      </TotalResults>

      <DownloadResultsButton
        onClick={() => {
          requestNotificationReplies(notification.id).then(content => {
            downloadCsvContent(content, 'Notification Replies')
          })
        }}
      >
        Download Results
      </DownloadResultsButton>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 8px;
`

const Options = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr;
  grid-auto-rows: 15px;
  grid-gap: 8px;
`

const Percentage = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-size: 10px;
  color: black;
`

const OptionText = styled.div`
  white-space: nowrap;
  font-size: 12px;
  color: #323e4e;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Details = styled.span`
  font-weight: 400;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 120%;
  margin-left: 5px;
`
const DarkDetails = styled(Details)`
  color: #445366;
`

const TotalResults = styled.div`
  display: flex;
  margin: 12px 0 4px;
`

const DownloadResultsButton = styled.div`
  height: 24px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  text-decoration: underline;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
`
