import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { WidgetActions } from '../../styles'
import { ContainerViewDTO } from '../../../../../api/origin/business-logic'
import { Input } from '../../../DataFields/Input'
import { EquipmentSearch, ContainerSearch } from '../../../DataFields/SearchRequest'
import { compareDates } from '../../../../../services/timeService/dateUtils'
import { FieldTemplate } from '../../../DataFields/Templates'
import { FieldContainer } from '../../../DataFields/FieldContainer'
import { requestEquipmentById } from '../../../../common/equipment/epics'
import { GridSpinner } from '../../../Spinner/Spinner'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { StreetTurnTable } from '../../../../popups/StreetTurnTable'
import { isNewObject } from '../../../../../services/DTO'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'
import {
  alertOnChangingMainStreetTurnPropsOfDDO,
  isContainerRequiredForDDO
} from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DateTimePicker } from '../../../DataFields/DateTimePicker/views'
import { isFullObject } from '../../../../../services/functions/test/isFullObject'

export const Popover = ({ disabledStreetTurn }: { disabledStreetTurn: boolean }) => {
  const { isModified, modifyListItems } = useExpandedItem()
  const {
    streetTurn,
    enableEditing,
    isChassisNumberRequired,
    dispatchDeliveryOrder,
    container,
    deliveryOrder,
    equipment,
    modifyDispatchDeliveryOrder,
    modifyDispatchDeliveryOrderField,
    modifyDeliveryOrderField
  } = useGridItemDDO()
  const { closePopover } = React.useContext(WidgetContext)
  const [fetching, setFetching] = React.useState(equipment && !isFullObject(equipment))
  const days = oc(equipment).days()
  const pickupDate = oc(equipment).pickupDate()
  const returnDate = oc(equipment).returnDate()

  React.useEffect(() => {
    if (equipment && !isFullObject(equipment)) {
      requestEquipmentById(equipment.id).finally(() => {
        setFetching(false)
      })
    }
  }, [])

  const isNewChassis = isNewObject(equipment)

  const estimate = {
    calcDates: pickupDate && returnDate ? Math.ceil(Number(compareDates(pickupDate, returnDate, 'days'))) : '',
    placeholder: isNewChassis ? 'MM/DD/YYYY' : ' '
  }

  const updateEquipmentDate = (dateField: string) => (value: any) => {
    modifyListItems({ [EntityType.equipment]: [{ ...equipment, [dateField]: value }] })
  }

  return (
    <>
      {fetching && <GridSpinner />}
      <Fields>
        <div>
          <FieldTemplate.ContainerTypeSearch
            id={dispatchDeliveryOrder.containerTypeId}
            disabled={enableEditing === false}
            title={'Container type'}
            required={true}
            onChange={_containerType => {
              const containerTypeId = oc(_containerType).id()
              const updatedDDO = { ...dispatchDeliveryOrder, containerTypeId }

              if (container && container.containerTypeId !== containerTypeId) {
                updatedDDO.containerId = undefined
                updatedDDO.containerTypeId = undefined

                modifyDispatchDeliveryOrder(updatedDDO)
              } else {
                modifyDispatchDeliveryOrderField('containerTypeId')(containerTypeId)
              }

              alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
            }}
          />
        </div>
        <div>
          <ContainerSearch
            disabled={enableEditing === false}
            allowCreate={true}
            required={isContainerRequiredForDDO(deliveryOrder.type, dispatchDeliveryOrder.status)}
            title={'Container #'}
            placeholder={dispatchDeliveryOrder.containerNumberPlaceholder}
            onChange={(_container: ContainerViewDTO) => {
              const containerId = oc(_container).id()
              const steamShipLineId = oc(_container).steamShipLineId()
              const containerTypeId = oc(_container).containerTypeId()
              const updatedDDO = {
                ...dispatchDeliveryOrder,
                containerId,
                containerTypeId: containerTypeId || oc(dispatchDeliveryOrder).containerTypeId()
              }

              modifyDispatchDeliveryOrder(updatedDDO)

              if (!deliveryOrder.steamShipLineId) {
                modifyDeliveryOrderField('steamShipLineId')(steamShipLineId)
              }

              alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
            }}
            id={dispatchDeliveryOrder.containerId}
            steamShipLineId={oc(deliveryOrder).steamShipLineId()}
            containerTypeId={dispatchDeliveryOrder.containerTypeId}
          />
        </div>
        <div>
          <Input
            disabled={enableEditing === false}
            title={'Seal'}
            maxLength={20}
            value={dispatchDeliveryOrder.sealNumber}
            onChange={modifyDispatchDeliveryOrderField('sealNumber')}
          />
        </div>
        <div>
          <EquipmentSearch
            disabled={enableEditing === false}
            required={isChassisNumberRequired}
            allowCreate={true}
            title={'Chassis #'}
            equipmentId={dispatchDeliveryOrder.equipmentId}
            onChange={_equipment => modifyDispatchDeliveryOrderField('equipmentId')(oc(_equipment).id())}
          />
        </div>
        <div>
          <DateTimePicker
            title={'Chassis Pickup'}
            disabled={true}
            placeholder={estimate.placeholder}
            date={pickupDate}
            onChange={() => {}}
          />
        </div>
        <div>
          <DateTimePicker
            title={'Chassis Return'}
            disabled={true}
            placeholder={estimate.placeholder}
            date={returnDate}
            onChange={() => {}}
          />
        </div>
        <div>
          <FieldContainer wrapChild={true} title={'Chassis Days'} childrenStyle={{ height: 35, fontSize: 13 }}>
            {days || estimate.calcDates}
          </FieldContainer>
        </div>
      </Fields>

      {!disabledStreetTurn && !dispatchDeliveryOrder.troubleTicketId && (
        <StreetTurnTable
          streetTurn={streetTurn}
          dispatchDeliveryOrder={dispatchDeliveryOrder}
          deliveryOrder={deliveryOrder}
          hideStreetTurnActions={isModified}
          setFetching={setFetching}
        />
      )}

      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}

const Fields = styled.div`
  width: 1012px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;

  > div {
    width: 33%;
    padding: 0 16px;
    margin-bottom: 24px;
  }
`
