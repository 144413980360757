import * as React from 'react'
import DateField from '../../../../../../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../../../../../../UI/DatePicker/Interfaces'
import { Title } from '../styles'

type Props = {
  isEditable: boolean
  completionActualDate: string
  onCompletionDateUpdate: (completionActualDate: string) => void
}

export const ActivityDocumentComplitionDate = (props: Props) => {
  const { isEditable, completionActualDate, onCompletionDateUpdate } = props

  return (
    <>
      <Title className={'required'} style={{ marginTop: 24, marginBottom: 8 }}>
        Set Completion Date and Time
      </Title>
      <div style={{ width: 170 }}>
        <DateField
          disabled={!isEditable}
          showTime={true}
          setTimeNowOnEmpty={true}
          placeholder={'MM/DD/YY'}
          date={completionActualDate}
          dateType={DateTypes.DateISOString}
          onUpdate={onCompletionDateUpdate}
        />
      </div>
    </>
  )
}
