import * as React from 'react'
import { oc } from 'ts-optchain'
import { ColumnVisibilitySettings } from './visibilitySettings'
import { Header } from '../header'
import { TGridColumns, ColumnDTO } from '../../../../../store/reducers/tabs/interfaces'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectActiveApplicationTab } from '../../../../../store/select/applicationTabSelect'
import { updateTabColumns, updateTabFilter, updateTabSorting } from '../../../../../store/reducers/tabs/functions'
import { filterPermission } from '../../../../../services/functions/generate/generateTab'

type Props = {
  disallowFilter?: boolean
  scrollable?: boolean
  scrollToZero: boolean
}

export const TableHeader = (props: Props) => {
  const { disallowFilter, scrollable, scrollToZero } = props
  const tab = useAppSelector(selectActiveApplicationTab)
  const tabType = tab.type
  const columns = oc(tab).uiSettings.columns()
  const filters = oc(tab).uiSettings.filter()
  const sorting = oc(tab).uiSettings.sorting()
  const enableFiltering = tab.permissions.filter
  const enableSorting = tab.permissions.sort

  const updateColumns = React.useCallback((updatedColumns: TGridColumns) => {
    updateTabColumns(tab.id, updatedColumns)
  }, [])

  const updateSorting = React.useCallback((updatedSorting: any) => {
    updateTabSorting(tab.id, updatedSorting)
  }, [])

  const updateFilters = React.useCallback(
    (updatedFilters: ColumnDTO.Filter[]) => {
      if (!filterPermission(tabType)) {
        return
      }

      if (!updatedFilters && !filters) {
        return
      }

      updateTabFilter(tab.id, updatedFilters ? updatedFilters : undefined)
    },
    [filters]
  )

  return (
    <>
      <ColumnVisibilitySettings columns={columns} updateColumns={updateColumns} />
      <Header
        disallowFilter={disallowFilter}
        scrollable={scrollable}
        scrollToZero={scrollToZero}
        sorting={sorting}
        filters={filters}
        columns={columns}
        updateColumns={updateColumns}
        updateSorting={updateSorting}
        updateFilters={updateFilters}
        tabType={tabType}
        withCheckbox={true}
        enableFiltering={enableFiltering}
        enableSorting={enableSorting}
      />
    </>
  )
}
