import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { ActivitiesViewDTO, DispatchDeliveryOrderViewDTO } from '../../../api/api'
import {
  requestActivitiesByDispatchDeliveryOrderId,
  requestDispatchDeliveryOrderById
} from '../../../components/common/dispatchDeliveryOrder/epics'
import { showModalWindow } from '../../../store/reducers/modalWindow/functions'
import { isDeliveryOrderSteamShipLineFieldCorrect } from '../deliveryOrder'
import { getListsState } from '../../../store'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { saveDeliveryOrder } from '../../saveEntity/saveDeliveryOrder'
import { requestUpdateActivities, requestDeleteActivities, requestAssignDriver } from '../activity/epics'
import { assembleEntity } from '../../functions/assembleListEntity/assembleEntity'
// tslint:disable:max-line-length
import { saveDispatchDeliveryOrder as saveDispatchDeliveryOrderPromise } from '../../saveEntity/saveDispatchDeliveryOrder'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { IGridItemDDOContext } from '../../../contexts/GridItemDDOContext'
import { checkDDODatesBeforeSave } from '../../functions/checkDDODatesBeforeSave'
import { checkDDOActivitiesBeforeSave } from '../../functions/checkDDOActivitiesBeforeSave'
import { isEntityModified } from '../../functions/test/isEntityModified'
import { getDispatchDeliveryOrdersConflicts } from '../../functions/get/getDispatchDeliveryOrdersConflicts'
import { ConflictsOnSave } from '../ConflictsOnSave'
import { IModalWindow } from '../../../store/reducers/modalWindow/interfaces'

type TSaveOptions = {
  ddoDatesPassed?: boolean
}

export const saveDispatchDeliveryOrder = async (
  expandedItemContext: TExpandedItemContext,
  extraSaveData: {
    gridItemDDOProviderData: IGridItemDDOContext
    retrySave: (options: TSaveOptions) => void
    options: TSaveOptions
  }
) => {
  const { gridItemDDOProviderData, retrySave, options } = extraSaveData
  const {
    initialLists,
    tabId,
    initialParentItemState,
    parentItemId,
    modifiedLists,
    setFetching,
    deleteModifiedListItems,
    clearExpandedItemModifies
  } = expandedItemContext
  const {
    activities,
    dispatchDeliveryOrder,
    deliveryOrderType,
    deliveryOrder,
    activityGroups,
    modifyDispatchDeliveryOrder,
    modifyDispatchDeliveryOrderField,
    modifyActivities
  } = gridItemDDOProviderData

  if (!options.ddoDatesPassed) {
    const ddoDatesPassed = checkDDODatesBeforeSave({
      dispatchDeliveryOrder,
      deliveryOrder,
      transportationActivities: activities.transportationActivities,
      modifyActivities,
      modifyDispatchDeliveryOrder,
      onOkButtonClick: () => retrySave({ ...options, ddoDatesPassed: true })
    })

    if (!ddoDatesPassed) {
      setFetching(false)
      return
    }
  }

  const isActivitiesCorrect = checkDDOActivitiesBeforeSave({
    dispatchDeliveryOrder,
    initialDispatchDeliveryOrder: initialParentItemState,
    deliveryOrderType,
    activityGroups,
    activities
  })

  if (!isActivitiesCorrect) {
    setFetching(false)
    return
  }

  const isSteamShipLineValid = await isDeliveryOrderSteamShipLineFieldCorrect({ deliveryOrder, dispatchDeliveryOrder })

  if (!isSteamShipLineValid) {
    showModalWindow({
      title: 'Steam Ship Line must be set',
      buttons: [{ label: 'Close' }]
    })
    setFetching(false)
    return
  }

  const ddoBeforeSaving = await requestDispatchDeliveryOrderById(parentItemId).catch(
    e => undefined as DispatchDeliveryOrderViewDTO
  )

  if (!ddoBeforeSaving) {
    setFetching(false)
    return
  }

  const store = getListsState()
  const storeDDO = oc(store)[EntityType.dispatchDeliveryOrder][parentItemId]() as DispatchDeliveryOrderViewDTO
  const modifiedActivityIds = dispatchDeliveryOrder.activityIds
  const modifiedBuySideQuoteIds = dispatchDeliveryOrder.buySideQuoteIds
  const isDDOUpdated = isEntityModified({
    entityType: EntityType.dispatchDeliveryOrder,
    modifiedEntity: oc(modifiedLists)[EntityType.dispatchDeliveryOrder][parentItemId](),
    storeEntity: storeDDO
  })
  const modifiedFullDispatchDeliveryOrder = R.clone(
    assembleEntity({
      deep: true,
      modifiedLists: {
        ...modifiedLists,
        [EntityType.dispatchDeliveryOrder]: {
          [parentItemId]: {
            ...dispatchDeliveryOrder,
            activityIds: modifiedActivityIds,
            buySideQuoteIds: modifiedBuySideQuoteIds
          }
        }
      },
      entityId: parentItemId,
      entityType: EntityType.dispatchDeliveryOrder
    })
  ) as DispatchDeliveryOrderViewDTO
  const modifiedDeliveryOrder = oc(modifiedLists)[EntityType.deliveryOrder][
    oc(dispatchDeliveryOrder).deliveryOrderId()
  ]()
  const modifiedActivities: ActivitiesViewDTO = {
    transportationActivities: activities.transportationActivities.filter(a =>
      isEntityModified({
        entityType: EntityType.activity,
        modifiedEntity: oc(modifiedLists)[EntityType.activity][a.id](),
        storeEntity: oc(store)[EntityType.activity][a.id]()
      })
    ),
    documentationActivities: activities.documentationActivities.filter(a =>
      isEntityModified({
        entityType: EntityType.activity,
        modifiedEntity: oc(modifiedLists)[EntityType.activity][a.id](),
        storeEntity: oc(store)[EntityType.activity][a.id]()
      })
    )
  }
  const deleteActivities: ActivitiesViewDTO = {
    transportationActivities: ddoBeforeSaving.activities.transportationActivities.filter(a =>
      activities.transportationActivities.every(b => b.id !== a.id)
    ),
    documentationActivities: ddoBeforeSaving.activities.documentationActivities.filter(a =>
      activities.documentationActivities.every(b => b.id !== a.id)
    )
  }

  const ddoCondition = testEntityVersion(store, EntityType.deliveryOrder)(modifiedDeliveryOrder)
  const doCondition = testEntityVersion(store, EntityType.dispatchDeliveryOrder)(modifiedFullDispatchDeliveryOrder)
  const activitiesCondition = modifiedActivityIds.every(id =>
    testEntityVersion(store, EntityType.activity)(oc(modifiedLists)[EntityType.activity][id]())
  )
  const condition = ddoCondition && doCondition && activitiesCondition

  const save = async () => {
    try {
      if (modifiedDeliveryOrder) {
        const savedDO = await saveDeliveryOrder({ deliveryOrder: modifiedDeliveryOrder })
        modifiedFullDispatchDeliveryOrder.deliveryOrder = savedDO

        deleteModifiedListItems({ [EntityType.deliveryOrder]: [savedDO.id] })
      }

      let activitiesUpdated = false
      if (deleteActivities.documentationActivities.length || deleteActivities.transportationActivities.length) {
        await requestDeleteActivities(deleteActivities, dispatchDeliveryOrder.id)
        activitiesUpdated = true
      }

      if (modifiedActivities.transportationActivities.length || modifiedActivities.documentationActivities.length) {
        await requestUpdateActivities(modifiedActivities, dispatchDeliveryOrder.id)
        activitiesUpdated = true
      }

      if (activitiesUpdated) {
        const currentActivities = await requestActivitiesByDispatchDeliveryOrderId(dispatchDeliveryOrder.id)
        modifiedFullDispatchDeliveryOrder.activities = currentActivities
        modifiedFullDispatchDeliveryOrder.activityIds = [
          ...currentActivities.transportationActivities.map(_ => _.id),
          ...currentActivities.documentationActivities.map(_ => _.id)
        ]
        modifyDispatchDeliveryOrderField('activityIds')(modifiedFullDispatchDeliveryOrder.activityIds)
        deleteModifiedListItems({ [EntityType.activity]: modifiedActivityIds })
      }

      await requestAssignDriver({ fullPrevDDO: ddoBeforeSaving, fullUpdatedDDO: modifiedFullDispatchDeliveryOrder })

      if (isDDOUpdated) {
        await saveDispatchDeliveryOrderPromise({ dispatchDeliveryOrder: modifiedFullDispatchDeliveryOrder })
      } else {
        await requestDispatchDeliveryOrderById(parentItemId, true)
      }

      handleSavedGridItem({ tabId, isNewItem: false })()
    } catch (e) {}
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    modalWindowProps: (() => {
      if (condition) {
        return
      }

      const conflicts = getDispatchDeliveryOrdersConflicts({ expandedItemContext, gridItemDDOProviderData })

      if (!activitiesCondition) {
        conflicts.push({ label: 'Activity list has been updated', isCritical: true, conflicts: [] })
      }

      if (conflicts && conflicts.length && conflicts.some(c => c.conflicts && c.conflicts.length)) {
        const modalWindowProps: Partial<IModalWindow> = {
          maxWidth: 'auto',
          content: <ConflictsOnSave conflicts={conflicts} />
        }

        if (!activitiesCondition) {
          modalWindowProps.buttons = [
            { label: 'Save anyway', onClick: () => {}, disabled: true },
            { label: 'Discard my changes', onClick: clearExpandedItemModifies },
            { label: 'Close', onClick: () => {} }
          ]
        }

        return modalWindowProps
      }

      return
    })(),
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
