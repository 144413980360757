import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { MessagesButton } from '../OngoingActivityRow/MessagesButton'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { CommunicationHubConstants } from '../../../../../../../constants/CommunicationHubConstants'
import { MoveRowActivity } from './MoveRowActivity'
import { MoveRowSpecs, Specs } from './MoveRowSpecs'
import { MoveRowQuoteColumn } from './MoveRowQuoteColumn'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../../../../services/debug'
import { requestDispatchDeliveryOrderById } from '../../../../../dispatchDeliveryOrder/epics'
import { dispatchDeliveryOrderStatusIndex } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../../api/api'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectRouteBuilderIncludesMoveId } from '../../../../../../../store/select/routeBuilderSelect'
import { IMoveDTO } from '../../../../../../../store/reducers/communicationHub/interfaces'
import { useDispatchDeliveryOrder } from '../../../../../../../hooks/useDispatchDeliveryOrder'
import { tabActions } from '../../../../../../../store/reducers/tabs'
import { TabExpandedItemDataProp } from '../../../../../../../store/reducers/tabs/interfaces'
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch'

type Props = {
  currentTabId: string
  move: IMoveDTO
}

export const MoveRow = React.memo((props: Props) => {
  const { currentTabId, move } = props
  const dispatch = useAppDispatch()
  const presentsInRouteBuilder = useAppSelector(selectRouteBuilderIncludesMoveId(move.id))
  const dispatchDeliveryOrderData = useDispatchDeliveryOrder({
    id: oc(move).dispatchDeliveryOrderId()
  })
  let dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO = undefined

  if (oc(dispatchDeliveryOrderData).completeness() >= 7) {
    dispatchDeliveryOrder = dispatchDeliveryOrderData
  }

  React.useEffect(() => {
    if (move && !dispatchDeliveryOrder) {
      requestDispatchDeliveryOrderById(move.dispatchDeliveryOrderId)
    }
  }, [])

  if (!(move && dispatchDeliveryOrder)) {
    return null
  }

  const dispatchDeliveryOrderStatusIsWorkable =
    dispatchDeliveryOrderStatusIndex[dispatchDeliveryOrder.status] >
    dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]

  const openDetails = (event: any) => {
    // >>> Debugging
    if (isShowDetailsOnClick(event)) {
      showDetailsOnClick({
        title: 'Move',
        data: move,
        blink: { class: 'move', event }
      })
      return
    }
    // <<<

    dispatch(
      tabActions.setExpandedItem({
        tabId: currentTabId,
        expandedItem: {
          id: move.dispatchDeliveryOrderId,
          data: {
            [TabExpandedItemDataProp.moveId]: move.id
          }
        }
      })
    )
  }

  return (
    <Container className={cn('move', { presentsInRouteBuilder })} onClick={openDetails}>
      <MessagesButton
        dispatchDeliveryOrderId={move.dispatchDeliveryOrderId}
        hasAlerts={Boolean(dispatchDeliveryOrder.troubleTicketId)}
      />
      <MoveRowActivities className={'move-activities'}>
        {move.items.map(moveItem => (
          <MoveRowActivity
            key={moveItem.id}
            moveDay={move.plannedDate}
            moveItem={moveItem}
            dispatchDeliveryOrderStatusIsWorkable={dispatchDeliveryOrderStatusIsWorkable}
            isConfirmedAppointmentDate={oc(
              dispatchDeliveryOrder
            ).deliveryStage.plannedAppointmentDateTimeRange.confirmed()}
            pickupStageShuttle={oc(dispatchDeliveryOrder).pickupStage.shuttle(false)}
            returnStageShuttle={oc(dispatchDeliveryOrder).returnStage.shuttle(false)}
          />
        ))}
      </MoveRowActivities>
      <Quotes>
        {move.items.map(moveItem => (
          <MoveRowQuoteColumn
            key={moveItem.id}
            gotoActivityId={moveItem.gotoActivityId}
            businessActivityId={moveItem.businessActivityId}
          />
        ))}
      </Quotes>
      <MoveRowSpecs dispatchDeliveryOrder={dispatchDeliveryOrder} />
    </Container>
  )
})

const {
  mediaQueriesBreakpoints: { large, medium, small }
} = SchedulerConstants
const { navigationViewWidth, channelViewWidth } = CommunicationHubConstants

const MoveRowActivities = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 1fr;
`
const Quotes = styled.div`
  order: 1;
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  border: none;
  border-radius: 2px;
  outline: 1px solid #dce6f5;
  overflow: hidden;
`
const Container = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 35px 2fr 3fr 80px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 6px;
  cursor: pointer;

  &:has(.move-activities:empty) {
    display: none;
  }

  &.presentsInRouteBuilder {
    box-shadow: 0 0 0 1px rgb(227, 232, 234), 0 0 0 3px #5cd8ff;
  }

  .communicationHubNotExpanded & {
    // prettier-ignore
    @media screen 
    and (max-width: ${medium + navigationViewWidth}px) {
      grid-template-columns: 35px 1fr 80px;

      .messagesButton {
        grid-row: 1/3;
      }

      ${Quotes} {
        order: unset;
      }

      ${Specs} {
        order: 1;
        grid-column: 2/4;
      }
    }
  }
  
  .communicationHubExpanded & {
    // prettier-ignore
    @media screen 
    and (max-width: ${medium + navigationViewWidth + channelViewWidth}px) {
      grid-template-columns: 35px 1fr 80px;

      .messagesButton {
        grid-row: 1/3;
      }

      ${Quotes} {
        order: unset;
      }

      ${Specs} {
        order: 1;
        grid-column: 2/4;
      }
    }
  }
`
