import * as React from 'react'
import { ActionButton } from '../../Buttons/ActionButton'
import { ModalWindow } from '../../../../store/reducers/modalWindow/interfaces'
import { useModalWindow } from '../../../../hooks/useModalWindow'

export const Button = (props: ModalWindow.Button) => {
  const { onClick, label, disabled, style, outline } = props
  const { closeModalWindow } = useModalWindow()

  return (
    <ActionButton
      disabled={disabled}
      round={true}
      outline={outline}
      filled={!outline}
      styles={style}
      children={label}
      onClick={() => {
        closeModalWindow()

        if (onClick) {
          onClick()
        }
      }}
    />
  )
}
