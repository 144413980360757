import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { TransportationActivityDTO, TransportationActivityViewDTO } from '../../../../../../../api/api'
import activityIcons from '../../../../../../../constants/activityIcons'
import { activityDirectory } from '../../../../../../../services/DTO/activity/directory'
import { locationTypeIcons } from '../../../../../../../services/DTO/location'
import { dateService, TDateServiceMakeLabelOptions } from '../../../../../../../services/timeService'
import { ActivityIcon, ShuttleIcon } from '../OngoingActivityRow/PreviewPhaseTypeColumn'
import theme from '../../../../../../../styles/theme'
import { IMoveItemDTO } from '../../../../../../../store/reducers/communicationHub/interfaces'
import { preventEvent } from '../../../../../../../services/functions/basic'
import { openDDOinNewTab } from '../../../../../../../services/DTO/dispatchDeliveryOrder'
import { useActivity } from '../../../../../../../hooks/useActivity'
import { useLocation } from '../../../../../../../hooks/useLocation'

type Props = {
  moveDay: string
  isConfirmedAppointmentDate: boolean
  dispatchDeliveryOrderStatusIsWorkable: boolean
  moveItem: IMoveItemDTO
  pickupStageShuttle: boolean
  returnStageShuttle: boolean
}

export const availibleActivityStatuses = [
  TransportationActivityDTO.StatusEnum.DRIVERPLANNED,
  TransportationActivityDTO.StatusEnum.DRIVERASSIGNED,
  TransportationActivityDTO.StatusEnum.DRIVERCONFIRMED
]
export const MoveRowActivity = React.memo((props: Props) => {
  const {
    moveDay,
    moveItem: { gotoActivityId, businessActivityId },
    dispatchDeliveryOrderStatusIsWorkable,
    isConfirmedAppointmentDate,
    pickupStageShuttle,
    returnStageShuttle
  } = props
  const gotoActivity = useActivity({ id: gotoActivityId }) as TransportationActivityViewDTO
  const businessActivity = useActivity({ id: businessActivityId }) as TransportationActivityViewDTO
  const destination = useLocation({ id: oc(businessActivity).destinationId() })

  if (!(businessActivity && availibleActivityStatuses.includes(businessActivity.status))) {
    return null
  }

  let ddoLinkData = oc(gotoActivity).bobtailFrom() || oc(gotoActivity).bobtailTo()
  const isShuttle =
    (businessActivity.stage === TransportationActivityDTO.StageEnum.PICKUP && pickupStageShuttle) ||
    (businessActivity.stage === TransportationActivityDTO.StageEnum.RETURN && returnStageShuttle)
  const plannedDate = oc(businessActivity).startPlannedDateTimeRange.from()
    ? businessActivity.startPlannedDateTimeRange
    : oc(gotoActivity).startPlannedDateTimeRange.from()
    ? gotoActivity.startPlannedDateTimeRange
    : undefined
  const redColorDate =
    isConfirmedAppointmentDate === false &&
    businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
    businessActivity.template

  const getDateOptions = (date: string, longSpace?: boolean): TDateServiceMakeLabelOptions => {
    return dateService.isTheSameDay(moveDay, date)
      ? { onlyTime: true }
      : { hideYear: true, monthName: 'short', hideWeekday: true, shortSpace: !longSpace }
  }

  if (ddoLinkData && !(ddoLinkData.dispatchDeliveryOrderNumber && ddoLinkData.dispatchDeliveryOrderId)) {
    ddoLinkData = undefined
  }

  return (
    <Container
      className={cn('move-row-activity', businessActivity.status, businessActivity.stage, {
        dispatchDeliveryOrderStatusIsWorkable
      })}
    >
      <>
        <Column style={{ paddingLeft: 15, border: 'none' }}>
          {plannedDate ? (
            <>
              <ColumnBoldText style={{ marginRight: 10, color: redColorDate ? theme.colors.defaultRed : undefined }}>
                {dateService.makeLabel(plannedDate, getDateOptions(plannedDate.from))}
              </ColumnBoldText>
              <ColumnThinText style={{ fontSize: 10 }}>Planned</ColumnThinText>
            </>
          ) : (
            <EmptyTextLine />
          )}
        </Column>
        <Column>
          <ActivityIcon className={'ActivityIcon'}>
            {isShuttle ? (
              <ShuttleIcon />
            ) : (
              activityIcons[businessActivity.type] && activityIcons[businessActivity.type].icon()
            )}
          </ActivityIcon>
          <div>
            <ColumnBoldText>{activityDirectory.status[businessActivity.status]}</ColumnBoldText>
            <ColumnThinText>
              {activityDirectory.type[businessActivity.type]}
              {ddoLinkData && (
                <Link
                  style={{ marginLeft: 4 }}
                  onClick={event => {
                    preventEvent(event)
                    openDDOinNewTab({
                      ddoId: ddoLinkData.dispatchDeliveryOrderId,
                      ddoNumber: ddoLinkData.dispatchDeliveryOrderNumber
                    })
                  }}
                >
                  DDO #{ddoLinkData.dispatchDeliveryOrderNumber}
                </Link>
              )}
            </ColumnThinText>
          </div>
        </Column>
        <Column>
          {destination && (
            <div>
              <ColumnText>
                {destination.type ? (
                  <span className={locationTypeIcons[destination.type]} style={{ fontSize: 16, marginRight: 3 }} />
                ) : (
                  <span style={{ fontSize: 20 }}>&mdash;</span>
                )}
                {destination.shortName || destination.name || ''}
              </ColumnText>
              <ColumnThinText>{destination.cityStateZip}</ColumnThinText>
            </div>
          )}
        </Column>
      </>
    </Container>
  )
})

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 35% 45%;
  background-color: #f5f7f7;
  border-radius: 2px;
  outline: 1px solid #dce6f5;
  color: rgb(114, 114, 114);

  &.dispatchDeliveryOrderStatusIsWorkable:first-child {
    background-color: white;
    color: #445366;

    &.${TransportationActivityDTO.StatusEnum.DRIVERPLANNED} {
      outline-color: #ebcd33;
    }

    &.${TransportationActivityDTO.StageEnum.PICKUP} {
      ${ActivityIcon} {
        background: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.PICKUP]};
      }
    }
    &.${TransportationActivityDTO.StageEnum.DELIVERY} {
      ${ActivityIcon} {
        background: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.DELIVERY]};
      }
    }
    &.${TransportationActivityDTO.StageEnum.RETURN} {
      ${ActivityIcon} {
        background: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.RETURN]};
      }
    }

    &.${TransportationActivityDTO.StatusEnum.DRIVERASSIGNED},
      &.${TransportationActivityDTO.StatusEnum.DRIVERCONFIRMED} {
      &.${TransportationActivityDTO.StageEnum.PICKUP} {
        outline-color: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.PICKUP]};
      }
      &.${TransportationActivityDTO.StageEnum.DELIVERY} {
        outline-color: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.DELIVERY]};
      }
      &.${TransportationActivityDTO.StageEnum.RETURN} {
        outline-color: ${activityDirectory.stageColor[TransportationActivityDTO.StageEnum.RETURN]};
      }
    }
  }
`
const Column = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  border-left: 1px solid #e3e8ea90;
  padding: 0 8px;
`
const ColumnText = styled.div`
  font-size: 12px;
  line-height: 1.4;
  /* color: #727272; */
`
const ColumnBoldText = styled(ColumnText)`
  /* color: #445366; */
  font-weight: 500;
`
const ColumnThinText = styled(ColumnText)`
  font-weight: 300;
`
const EmptyTextLine = styled.div`
  width: 18px;
  height: 1px;
  background-color: #b2b2b2;
`
const Link = styled.span`
  font-weight: 500;
  white-space: nowrap;
  color: ${theme.colors.basicBlueColor};

  &:hover {
    text-decoration: underline;
  }
`
