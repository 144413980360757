import * as React from 'react'
import { createSelectListFromDirectory } from './index'
import { activityDirectory } from '../DTO/activity/directory'
import constants from '../../constants'
import { TransportationActivityViewDTO } from '../../api/origin/business-logic'
import styled from 'styled-components'
import { activityStageColor } from '../../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/styles'
import { isBusinessActivityType, isDropBobtailBusinessActivityType } from '../functions/test/isBusinessActivity'
import { isPickActivityType } from '../functions/test/isPickActivity'

const phases = createSelectListFromDirectory(activityDirectory.phases)
const stage = createSelectListFromDirectory(activityDirectory.stage)
const status = createSelectListFromDirectory(activityDirectory.status)
const type = createSelectListFromDirectory(activityDirectory.type)
const businessType = type.filter(item => isBusinessActivityType(item.value))
const dropBobtailBusinessType = type.filter(item => isDropBobtailBusinessActivityType(item.value))
const pickBobtailBusinessType = type.filter(item => isPickActivityType(item.value))
const makeTypeListWithIconAndActivityNumber = (activities: TransportationActivityViewDTO[], showIcon: boolean) =>
  activities.map(activity => {
    return {
      label: activityDirectory.type[activity.type],
      value: activity.id,
      activityGroupId: activity.groupId,
      htmlLabel: (
        <div
          key={activity.id}
          style={{ display: 'flex', alignItems: 'center', color: activityStageColor[activity.stage] }}
        >
          {showIcon && (
            <StyledIcon
              color={activityStageColor[activity.stage]}
              children={constants.activityIcons[activity.comboType].icon()}
            />
          )}
          {constants.activityIcons[activity.comboType].title}
          {activity.number ? ` - ${activity.number}` : ''}
        </div>
      )
    }
  })

export const activityLists = {
  type,
  businessType,
  dropBobtailBusinessType,
  pickBobtailBusinessType,
  status,
  stage,
  phases,
  makeTypeListWithIconAndActivityNumber
}

export const StyledIcon = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #445366;
    ${props => (props.color ? `fill: ${props.color}` : '')}
  }

  margin-right: 6px;
`
