import * as React from 'react'
import { ActivitiesViewDTO, ContactDTO } from '../../../api/api'
import { DriverReportDTO } from '../../../api/origin/document-service'
import { DeductionDTO, VendorDTO } from '../../../api/origin/vendor-accounting-service'
import { IActivityDocumentPopup } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { IMessage } from '../communicationHub/interfaces'
import { EntityType, TListsState } from '../lists/interfaces'
import { ActivityGroup } from '../../../services/DTO/activity/interfaces'

export type ColumnTitle = {
  width: number
  maxWidth?: string | number
  minWidth?: string | number
  active: boolean
  headerWidth?: number
}

export type ColumnBody = ColumnTitle & {
  value: any
}

export type TColumns = Record<string, ColumnBody>

export type TGridColumn = ColumnBody & {
  className: string
  columnName: string
  style: React.CSSProperties
}

export enum GridHeaderColumnType {
  date = 'date',
  number = 'number',
  string = 'string',
  state = 'state',
  multiSelectList = 'multiSelectList',
  list = 'list'
}

interface IFilterItem {
  column: string
  path?: string
  sort?: string
  combinationOfValuePaths?: string[]
  value:
    | boolean
    | number
    | string
    | string[]
    | {
        from?: string
        to?: string
      }
  isEqualToValue?: boolean
  regExp?: RegExp
  newDateTimePicker?: boolean
}

enum TabType {
  scheduler = 'scheduler',
  powerUnit = 'powerUnit',
  equipment = 'equipment',
  container = 'container',
  location = 'location',
  driver = 'driver',
  customer = 'customer',
  steamShipLine = 'steamShipLine',
  subClient = 'subClient',
  dispatchDeliveryOrder = 'dispatchDeliveryOrder',
  deliveryOrder = 'deliveryOrder',
  QMP = 'QMP',
  locationGroup = 'locationGroup',
  user = 'user',
  serviceArea = 'serviceArea'
}

interface ITabPermissions {
  localStorage: boolean
  websoketsUpdate: boolean
  edit: boolean
  sort: boolean
  filter: boolean
}

enum ListRequestType {
  streetTurn = 'streetTurn',
  droppedAtDeliveryLocation = 'droppedAtDeliveryLocation',
  deliveryStageCompletedNoReturned = 'deliveryStageCompletedNoReturned',
  droppedAtYard = 'droppedAtYard',
  pickedUpNoDelivery = 'pickedUpNoDelivery',
  recentDispatchDeliverOrders = 'recentDispatchDeliverOrders'
}

export enum ActionsType {
  streetTurn = 'streetTurn'
}

export type TGridColumns = Record<string, ColumnTitle>

export interface ITabUISettings {
  columns: TGridColumns
  filter?: IFilterItem[]
  sorting?: any
}

interface IRequest {
  listRequestType?: ListRequestType | undefined
  gridItemDetailsRequestType?: TabType | ListRequestType | undefined
  propsToRequest?: any | undefined
}

interface ITabData {}

export namespace ColumnDTO {
  export const Type = GridHeaderColumnType
  export type Type = GridHeaderColumnType
  export type Filter = IFilterItem
}

export namespace TabDTO {
  export const Type = TabType
  export type Type = TabType
  export type Permissions = ITabPermissions
  export type UISettings = ITabUISettings
  export const Actions = ActionsType
  export type Actions = ActionsType
  export namespace Request {
    export const List = ListRequestType
    export type List = ListRequestType
    export type GridItemDetailsRequestType = TabType | ListRequestType | undefined
    export type PopsToRequest = any | undefined
  }
}

export interface ITab {
  id: string
  type: TabType
  label: string
  active: boolean
  fetching: boolean
  visited: boolean
  refrashOnTabEnter: boolean
  actionsType: ActionsType | undefined
  request: IRequest | undefined
  selectedIds: string[]
  pinnedIds: string[]
  disabledSelectIds: string[]
  hideUnselectedGridItemIds: string[]
  uiSettings: ITabUISettings
  permissions: ITabPermissions
  gridItemIds: string[] | undefined
  expandedItem: ITabExpandedItem
  data: any
}

// @ts-ignore
export interface ITabUpdate extends ITab {
  uiSettings: Partial<ITabUISettings>
  permissions: Partial<ITabPermissions>
}

// VIEWING OBJECT
export interface ITabExpandedItem {
  id: string
  entityType: EntityType
  modifiedLists: Partial<TListsState>
  initialLists: Partial<TListsState>
  activeTab?: string
  data?: ITabExpandedItemData
}

export enum TabExpandedItemDataProp {
  moveId = 'moveId',
  initialObjectState = 'initialObjectState',
  activityDocumentMessages = 'activityDocumentMessages',
  activityDocumentPopup = 'activityDocumentPopup',
  driverAccountingTabData = 'driverAccountingTabData',
  vendorAccountingReports = 'vendorAccountingReports',
  contactsTabData = 'contactsTabData'
}

export interface ITabExpandedItemData {
  [TabExpandedItemDataProp.moveId]?: string
  [TabExpandedItemDataProp.initialObjectState]?: any
  [TabExpandedItemDataProp.activityDocumentMessages]?: IMessage[]
  [TabExpandedItemDataProp.activityDocumentPopup]?: IActivityDocumentPopup
  [TabExpandedItemDataProp.driverAccountingTabData]?: IDriverAccountingTabData
  [TabExpandedItemDataProp.vendorAccountingReports]?: IVendorAccountingReports
}

// export interface IContactsTabData {
//   contacts?: ContactDTO[]
// }

export interface IDriverAccountingTabData {
  vendorAccountingId?: string
  deductionIds?: string[]
  showClosedDeductions?: boolean
  removeDeductionIds?: string[]
}

export type IVendorAccountingReports = { [key in DriverReportDTO.TypeEnum]?: Record<string, DriverReportDTO> }
