import { RootState } from '..'
import { TLocation } from '../../components/common/location/interfaces'

// SELECT ONE
export const selectLocation = (id: string) => (state: RootState): TLocation | undefined => {
  const {
    lists: { location }
  } = state

  return location[id]
}

// SELECT MANY
export const selectLocations = (state: RootState): Record<string, TLocation> => {
  return state.lists.location
}
