import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'
import { CommunicationHubConstants } from '../../../constants/CommunicationHubConstants'
import { StyledDropdownContainer } from '../../UI/Dropdowns/Popover'

export const ButtonWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  height: 50px;
  width: 50px;
  font-size: 18px;
  color: ${theme.colors.spaceGray};
  cursor: pointer;

  &:hover {
    transition: background 0.25s ease;
    color: white;
    background: #475264;
  }

  &.active {
    color: white;
  }

  &.notactive {
    cursor: not-allowed;
    color: #e5e7ef;
  }
`

export const StyledHeader = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  background: ${theme.colors.header};
`
export const Block = styled.div`
  display: flex;
`
export const CollectionMenuButton = styled.div`
  width: 130px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #f2f2f2;
  cursor: pointer;

  &:before {
    opacity: 0.5;
    font-size: 18px;
    margin-left: 10px;
  }

  &:hover {
    background-color: #475264;

    &:before {
      opacity: 1;
    }
  }
`
export const CollectionMenu = styled.div`
  display: flex;
`
export const CollectionContainer = styled.div`
  position: relative;
  margin-left: 20px;

  .communicationHubVisible.communicationHubExpanded & {
    @media screen and (max-width: ${1330 +
        CommunicationHubConstants.navigationViewWidth +
        CommunicationHubConstants.channelViewWidth}px) {
      ${CollectionMenuButton} {
        display: flex;
      }
      ${CollectionMenu} {
        display: none;
      }

      &.active {
        ${CollectionMenuButton} {
          background-color: #475264;

          &:before {
            opacity: 1;
          }
        }

        ${CollectionMenu} {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: #323e4e;

          .menu-title {
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 0;
            padding: 0 0 0 15px;
            margin: 0;
          }

          ${StyledDropdownContainer} {
            left: 100%;
            top: 0;
          }
        }
      }
    }
  }
  .communicationHubNotExpanded & {
    @media screen and (max-width: ${1330 + CommunicationHubConstants.navigationViewWidth}px) {
      ${CollectionMenuButton} {
        display: flex;
      }
      ${CollectionMenu} {
        display: none;
      }

      &.active {
        ${CollectionMenuButton} {
          background-color: #475264;

          &:before {
            opacity: 1;
          }
        }

        ${CollectionMenu} {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: #323e4e;

          .menu-title {
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 0;
            padding: 0 0 0 15px;
            margin: 0;
          }

          ${StyledDropdownContainer} {
            left: 100%;
            top: 0;
          }
        }
      }
    }
  }
`

export const TextBold = styled.div`
  position: absolute;
  top: -99999px;
  left: -99999px;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  font-weight: 700;
  overflow: hidden;
`
