import { getDispatch } from '../../store'
import { tabActions } from '../../store/reducers/tabs'

type Props = {
  tabId: string
  isNewItem: boolean
}

export const handleSavedGridItem = (props: Props) => (savedItem?: any) => {
  const { tabId, isNewItem } = props
  const dispatch = getDispatch()

  if (isNewItem && savedItem) {
    dispatch(tabActions.replaceCreatedGridItem({ tabId, gridItemId: savedItem.id }))
  } else {
    dispatch(tabActions.clearExpandedItemModifies({ tabId }))
  }
}
