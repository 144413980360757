import * as React from 'react'
import { DateISOString } from '../../../../../../api/origin/business-logic'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { CalendarIconButton } from '../../../../../UI/DataFields/DateTimePicker/views/DateTimePickerButton'
import styled from 'styled-components'
import cn from 'classnames'
import { dateService } from '../../../../../../services/timeService'

type Props = {
  disabled: boolean
  hideResetButton: boolean
  date: DateISOString
  minDate: string | undefined
  onChange: (date: string) => void
}

export const Datepicker = (props: Props) => {
  const { disabled, hideResetButton, date, minDate, onChange } = props
  const disableCalendarIconButton = !date || dateService.isCurrentDay(date)

  return (
    <Container data-id={`scheduler-header-datepicker date=${date}`}>
      <FieldContainer style={{ width: 'unset', flexGrow: 1 }} title={'Go to specific date'}>
        <DateTimePicker
          disabled={disabled}
          hideResetButton={hideResetButton}
          date={date}
          minDate={minDate}
          hideTimeInput={true}
          // @ts-ignore
          onChange={onChange}
        />
      </FieldContainer>
      <CalendarIconButton
        data-id={'scheduler-header-clear-datepicker'}
        style={{ margin: '20px 0 0 15px' }}
        className={cn('mdi mdi-calendar', { disabled: disableCalendarIconButton })}
        onClick={disableCalendarIconButton ? undefined : () => onChange(dateService.createStringDate.startDay)}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`
