import { DriverViewDTO, PowerUnitSearchDTO } from '../../api/origin/business-logic'
import { schemaDriverView } from '../yupScheme'

// export const alreadyDriver = (unit: IDriver): boolean => {
//   return !(
//     Boolean(unit.firstName) &&
//     Boolean(unit.lastName) &&
//     Boolean(unit.operationalPreference) &&
//     Boolean(unit.birthday) &&
//     Boolean(unit.millageRestriction) &&
//     Boolean(unit.medicalExamCardExpDate) &&
//     Boolean(unit.twicExpDate) &&
//     Boolean(unit.portCredentialsExpDate) &&
//     Boolean(unit.licenseExpDate) &&
//     Boolean(unit.licenseIssueCountryCode) &&
//     Boolean(unit.licenseIssueDate) &&
//     Boolean(unit.licenseIssueStateCode) &&
//     Boolean(unit.licenseNumber) &&
//     isValidLicenseNumber(unit.licenseNumber) &&
//     Boolean(unit.phone) &&
//     isValidPhone(unit.phone) &&
//     (unit.email ? isValidEmail(unit.email) : true) &&
//     Boolean(unit.driverType) &&
//     Boolean(unit.mailingAddress ? isAddressValid(unit.mailingAddress) : true) &&
//     Boolean(unit.licenseAddress ? isAddressValid(unit.licenseAddress) : true)
//   )
// }

export const transformPowerUnit = (value: PowerUnitSearchDTO | ''): string => {
  return value
    ? `${value.licensePlate ? value.licensePlate + ',' : ''}${value.year ? ' ' + value.year : ''}${
        value.make ? ' ' + value.make : ''
      }${value.model ? ' ' + value.model : ''}`
    : ''
}

export const isValidDriver = (driver: DriverViewDTO): boolean => {
  // schemaDriverView.validate(driver).catch(e =>
  //   getStore().dispatch(
  //     addServerMessage({
  //       type: 'error',
  //       message: e.message
  //     })
  //   )
  // )
  return schemaDriverView.isValidSync(driver)
}
