import { DeductionDTO } from '../../../../../api/origin/vendor-accounting-service'
import { EntityType } from '../../interfaces'

interface IParsedProps {
  [EntityType.deduction]: DeductionDTO[]
}

export const parseDeductions = async (deductions: DeductionDTO[]): Promise<IParsedProps> => {
  const result: IParsedProps = {
    [EntityType.deduction]: []
  }

  try {
    deductions.forEach(deduction => {
      result[EntityType.deduction].push(deduction)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return Promise.resolve(result)
}
