import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { useTabContentScrollPosition } from '../../../../../hooks/useTabContentScrollPosition'
import { selectDrivers } from '../../../../../store/select/driverSelect'
import { selectMoves } from '../../../../../store/select/moveSelect'
import {
  selectRouteBuilderDriverId,
  selectRouteBuilderIsRouteBuilderViewExpanded
} from '../../../../../store/select/routeBuilderSelect'
import { selectUndefined } from '../../../../../store/select/undefinedSelect'
import { GridItemSpinner } from '../../../../UI/Spinner/Spinner'
import { getMoves } from '../../epics'
import { filterAndSortVendorMoves } from '../../functions/filterAndSortVendorMoves'
import { toggleSyncDriverActivitiesWithRouteBuilder } from '../../functions/toggleSyncDriverActivitiesWithRouteBuilder'
import { ISchedulerTabFilterMapping, SchedulerSortBy } from '../../interfaces'
import { DispatchDeliveryOrderPopup } from './Group/DispatchDeliveryOrderPopup/DispatchDeliveryOrderPopup'
import { MovesGroup } from './Group/MovesGroup'
import { HeaderVendorName } from './Group/MovesGroupHeader'
import { updateTab } from '../../../../../store/reducers/tabs/functions'

type OwnProps = {
  currentTabId: string
  currentTabVisited: boolean
  fetching: boolean
  filter: ISchedulerTabFilterMapping
}

type StateProps = {}

type Props = OwnProps & StateProps

let fullMovesListHasBeenLoaded = false
const groupVisited: {
  [tabId: string]: boolean
} = {}

export const MoveGroupingView = React.memo((props: Props) => {
  const { fetching, currentTabVisited, currentTabId, filter } = props
  const driverMapping = useAppSelector(selectDrivers)
  const isRouteBuilderViewExpanded = useAppSelector(selectRouteBuilderIsRouteBuilderViewExpanded)
  const filterVendorId = useAppSelector(
    filter.syncWithRouteBuilder && isRouteBuilderViewExpanded ? selectRouteBuilderDriverId : selectUndefined
  )
  const mountedRef = React.useRef(false)
  const containerRef = useTabContentScrollPosition({
    tabId: currentTabId + SchedulerSortBy.driverActivities,
    bind: fetching === false
  })
  const moveList = useAppSelector(selectMoves)

  React.useEffect(() => {
    if (filter.syncWithRouteBuilder && !isRouteBuilderViewExpanded) {
      toggleSyncDriverActivitiesWithRouteBuilder(false)
    }
  }, [isRouteBuilderViewExpanded])

  React.useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true

      if (currentTabVisited && groupVisited[currentTabId]) {
        return
      }

      groupVisited[currentTabId] = true
    }

    if (fullMovesListHasBeenLoaded || fetching) {
      return
    }

    if (!filterVendorId) {
      fullMovesListHasBeenLoaded = true
    }

    updateTab(currentTabId, { fetching: true, visited: true })
    getMoves({ vendorId: filterVendorId }).finally(() => updateTab(currentTabId, { fetching: false }))
  }, [filterVendorId])

  const sortedAndFilteredVendorGroups = React.useMemo(() => {
    return filterAndSortVendorMoves({
      moves: Object.values(moveList),
      driverMapping,
      filterVendorId,
      filter
    })
  }, [moveList, filterVendorId, filter, driverMapping])

  return fetching ? (
    <GridItemSpinner />
  ) : (
    <>
      <Title>Planned, Assigned and Confirmed Activities</Title>
      <Container ref={containerRef}>
        <List>
          {sortedAndFilteredVendorGroups.map(
            item =>
              Boolean(item.dateMoves.length) && (
                <MovesGroup
                  key={item.vendorId}
                  currentTabId={currentTabId}
                  vendorId={item.vendorId}
                  label={item.vendorName}
                  dateMoves={item.dateMoves}
                />
              )
          )}
          <Placeholder>
            {filterVendorId && <HeaderVendorName>{oc(driverMapping)[filterVendorId].name()}</HeaderVendorName>}
            <PlaceholderText>No upcoming Activities{filterVendorId ? ' for this Driver' : ''}</PlaceholderText>
          </Placeholder>
        </List>
      </Container>
      <DispatchDeliveryOrderPopup />
    </>
  )
})

const Container = styled.div`
  z-index: 0;
  flex-grow: 1;
  padding: 0 6px 0 12px;
  overflow: auto;
`
const List = styled.div`
  border-radius: 0 0 4px 4px;
  background-color: #e4e8ea;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
`
const Title = styled.div`
  flex-shrink: 0;
  height: 35px;
  display: flex;
  align-items: center;
  background: #ebcd33;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 13px;
  color: white;
  padding-left: 15px;
  margin: 0 6px 0 12px;
  user-select: none;
`
const Placeholder = styled.section`
  padding: 16px 9px 12px;
`
const PlaceholderText = styled.div`
  font-size: 14px;
  text-align: center;
  color: #b2b2b2;
  padding: 8px 0 12px;
  user-select: none;
`
