import { GridColumn } from '../GridSystem'
import theme from '../../../styles/theme'
import styled from '../../../styles/styledComponents'

export const AvailabilitySelecter = styled.div`
  letter-spacing: 0.1px;
  user-select: none;
  cursor: default;
`
export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  opacity: 0.3;
`
export const Selected = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  padding-right: 30px;
  cursor: pointer;

  &.isActive {
    background: rgba(39, 174, 96, 0.85);
    ${Button} {
      color: #27ae60;
    }
  }
  &.isInactive {
    background: rgba(255, 94, 94, 0.85);
    ${Button} {
      color: #ff5e5e;
    }
  }
`

export const DropDown = styled.div`
  background: ${theme.colors.basicBorderColor};
  color: #4f4f4f;
  border: 2px solid #bdbdbd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  padding: 10px;

  span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    cursor: pointer;

    &:before {
      display: flex;
      justify-content: center;
      width: 30px;
      color: #999999 !important;
      font-size: 6px !important;
    }

    &:hover {
      color: ${theme.colors.basicBlueColor};
    }

    &.active {
      color: ${theme.colors.basicBlueColor};

      &:before {
        content: '12c';
        color: ${theme.colors.basicBlueColor} !important;
        font-size: 18px !important;
      }
    }
  }
`
