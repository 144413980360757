import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import styled from 'styled-components'
import { FieldContainer } from '../FieldContainer'
import { axiosCallAPIWithErrorMessage, callAPIWithErrorMessage, fileAPI } from '../../../../api/api'
import { fileServiceAPI } from '../../../../services/filesService'
import { FileDTO, FileVersionDTO } from '../../../../api/origin/document-service'
import { useUploadFilesStore } from '../../../../hooks/useUploadFilesStore'
import { generateFileTemplate } from '../../../../services/filesService/generateFileTemplate'
import { IAttachment } from '../../../../services/filesService/interfaces'
import theme from '../../../../styles/theme'
import { downloadFile } from '../../../../services/functions/downloadFile'
import { WindowPopover } from '../../windowPopover'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { regExpOnlyNumbersAndLetters } from '../../../../services/functions/regExp'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { FileHistory } from './FileHistory'
import { isNewId } from '../../../../services/DTO'

type OwnProps = {
  uploadingFileId: string
  file: FileDTO | undefined
  type: FileVersionDTO.TypeEnum
  entityId: string
  onChange: (file: FileDTO | undefined) => void
  activeTabId?: string
  title?: string
  required?: boolean
  disabled?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const RichFileUploader = React.memo((props: Props) => {
  const { title, required, disabled, file, type, entityId, uploadingFileId } = props
  const actionsButtonRef = React.useRef(null)
  const inputRef = React.useRef(null)
  const { uploadingFiles, setUploadingFiles, clearUploadingFiles } = useUploadFilesStore()
  const activeTabId = React.useMemo(() => props.activeTabId || 'general', [props.activeTabId])
  const [fileHistory, setFileHistory] = React.useState<FileVersionDTO[]>([])
  const uploadingFile = oc(uploadingFiles)[activeTabId][uploadingFileId]() as IAttachment
  // const [errorMessage, setErrorMessage] = React.useState<string>(undefined)
  const { wrapperRef, statePopover, togglePopover, setPopoverState } = useOutsideClick()
  const onChangeRef = React.useRef(props.onChange)
  onChangeRef.current = props.onChange
  const inputId = uploadingFileId.replace(regExpOnlyNumbersAndLetters, '')

  const onFileChange = React.useCallback((e: any) => {
    const files: File[] = oc(e).target.files()
    // setErrorMessage(undefined)

    if (files && files[0]) {
      const temporaryAttachment = generateFileTemplate(files[0], {
        uploadingProgress: 0
      }) as IAttachment

      onChangeRef.current(undefined)
      setUploadingFiles(activeTabId, uploadingFileId, temporaryAttachment)

      try {
        axiosCallAPIWithErrorMessage(fileServiceAPI.uploadFile, {
          file: files[0],
          progressCallback: (uploadingProgress: number) => {
            setUploadingFiles(activeTabId, uploadingFileId, { ...temporaryAttachment, uploadingProgress })
          }
        })
          .then(data => {
            onChangeRef.current(R.omit(data, ['id']))
            clearUploadingFiles(activeTabId, uploadingFileId)
          })
          .catch((error: any) => {
            clearUploadingFiles(activeTabId, uploadingFileId)

            // if (error && error.data) {
            //   setErrorMessage(convertErrorToMessage(error.data))
            // }
          })
          .finally(() => {
            if (inputRef && inputRef.current) {
              inputRef.current.type = ''
              inputRef.current.type = 'file'
            }
          })
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('FileUploader error', e)
        clearUploadingFiles(activeTabId, uploadingFileId)
      }
    }
  }, [])

  const ButtonProps = React.useMemo((): {
    button: JSX.Element
    actions: JSX.Element[]
  } => {
    let button = undefined
    const actions: JSX.Element[] = []
    const BasicButton = () => (
      <MainButton htmlFor={inputId} className={'mdi mdi-cloud-upload'}>
        Upload file
      </MainButton>
    )
    const UploadingButton = () => (
      <MainButton style={{ cursor: 'default' }}>{makeUploadingLabel(uploadingFile.uploadingProgress)}</MainButton>
    )
    const ViewFileButton = () => (
      <MainButton
        className={'mdi mdi-eye'}
        onClick={() => downloadFile({ url: file.url, contentType: file.contentType })}
      >
        View
      </MainButton>
    )
    const UploadAction = () => <Action htmlFor={inputId} children={'Upload'} />
    const ViewHistoryAction = () => {
      const _disabled = !fileHistory.length

      return (
        <Action
          className={cn({ disabled: _disabled })}
          onClick={
            _disabled
              ? undefined
              : () => {
                  setPopoverState(false)
                  showModalWindow({
                    width: 300,
                    title: `${title || ''} History`,
                    hasMaxHeight: true,
                    closeButton: true,
                    content: <FileHistory fileHistory={fileHistory} />
                  })
                }
          }
          children={'View History'}
        />
      )
    }

    const DeleteFileAction = () => (
      <Action
        style={{ color: theme.colors.defaultRed }}
        onClick={() =>
          showModalWindow({
            width: 320,
            title: `Delete the file?`,
            content: oc(file).name() ? (
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span>{file.name}</span>
              </div>
            ) : (
              undefined
            ),
            buttons: [
              {
                label: 'No'
              },
              {
                label: 'Yes',
                onClick: () => onChangeRef.current(undefined)
              }
            ]
          })
        }
        children={'Delete'}
      />
    )

    if (uploadingFile) {
      button = <UploadingButton />
    } else {
      actions.push(<UploadAction key={'Upload'} />)
    }

    if (!isNewId(entityId)) {
      actions.push(<ViewHistoryAction key={'View History'} />)
    }

    if (file) {
      button = <ViewFileButton />
      actions.push(<DeleteFileAction key={'delete'} />)
    }

    if (!button) {
      button = <BasicButton />
    }

    return {
      button,
      actions
    }
  }, [uploadingFile, file, uploadingFileId, onFileChange, statePopover, togglePopover, type, entityId, fileHistory])

  React.useEffect(() => {
    if (statePopover) {
      callAPIWithErrorMessage(fileAPI.getFileHistory, entityId, type).then(data => {
        if (data && data.length) {
          setFileHistory(data)
        }
      })
    }
  }, [statePopover])

  return (
    <FieldContainer title={title} required={required} disabled={disabled}>
      <input ref={inputRef} id={inputId} type={'file'} onChange={onFileChange} hidden={true} />
      <Container ref={wrapperRef} className={cn({ actions: ButtonProps.actions.length })}>
        {ButtonProps.button}
        {Boolean(ButtonProps.actions.length) && (
          <>
            <ActionButton ref={actionsButtonRef} className={'mdi mdi-dots-vertical'} onClick={togglePopover} />
            {statePopover && (
              <WindowPopover
                bindToRef={actionsButtonRef}
                flexibleWidth={true}
                containerStyles={{ transform: 'translate(25px, -35px)' }}
              >
                <ActionsPopover children={ButtonProps.actions} />
              </WindowPopover>
            )}
          </>
        )}
      </Container>
    </FieldContainer>
  )
})

const makeUploadingLabel = (progress: number, title?: string): string => {
  return `Uploading${title ? ' ' + title : ''}... ${typeof progress === 'number' ? '(' + progress + ')%' : ''}`
}

const Container = styled.div`
  height: 35px;
  width: 115px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr;
  color: white;
  border-radius: 4px;
  background: ${theme.colors.basicBlueColor};
  cursor: pointer;
  user-select: none;

  &.actions {
    grid-template-columns: 1fr 20px;
  }
`

const MainButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  line-height: 1.2;
  border-left: 1px solid white;
  padding: 4px 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const ActionsPopover = styled.div`
  width: 120px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 8px 0px;
`
const Action = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #404957;
  line-height: 1.2;
  padding: 5px 8px;
  cursor: pointer;

  &:hover {
    background: #f5f6fa;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`
