import { ContactDTO } from '../../../api/api'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  contactIds: string[]
  modifiedLists: Partial<TListsState>
}

export const filterModifiedContacts = (props: Props): ContactDTO[] => {
  const { contactIds, modifiedLists } = props
  let updatedContacts: ContactDTO[] = []

  if (modifiedLists[EntityType.contact]) {
    updatedContacts = contactIds.map(id => modifiedLists[EntityType.contact][id]).filter(Boolean)
  }

  return updatedContacts
}
