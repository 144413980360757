import { MutableRefObject } from 'react'

export const fadeOutElement = (
  elementRef: MutableRefObject<Element>,
  deleteElementFn: (props: { showElementFn: () => void }) => void
) => (event?: any) => {
  if (!(elementRef && elementRef.current)) {
    return
  }

  const element = elementRef.current

  if (event) {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  // @ts-ignore
  element.style.height = element.clientHeight + 'px'
  element.classList.add('fade-out-element')

  const showElementFn = () => {
    // @ts-ignore
    element.style.height = ''
    element.classList.remove('fade-out-element')
  }

  setTimeout(() => deleteElementFn({ showElementFn }), 500)
}
