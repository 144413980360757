import * as React from 'react'
import { oc } from 'ts-optchain'
import { StreetTurnActionButton, StreetTurnOutlineActionButton, StreetTurnSection, StreetTurnButton } from './styles'
import { getStreetTurnStatus } from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderStreetTurnDTO } from '../../../../../../api/origin/business-logic'
import { streetTurnRequests } from '../../../../../../services/DTO/streetTurn/epics'
import { Widget } from '../../../../../UI/Widget'
import { Popover as EquipmentPopover } from '../../../../../UI/Widget/widgets/equipment/popover'
import { requestDispatchDeliveryOrdersByIds } from '../../../epics'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { useGridItemDDO } from '../../../../../../hooks/useGridItemDDO'

export const StreetTurn = React.memo(() => {
  const { isModified, enableEditing, setFetching } = useExpandedItem()
  const { dispatchDeliveryOrder, streetTurn } = useGridItemDDO()
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn,
    streetTurnCount: oc(dispatchDeliveryOrder).streetTurnCount()
  })
  const disable = enableEditing === false

  return (
    <>
      {Boolean(streetTurnStatus && !dispatchDeliveryOrder.troubleTicketId) && (
        <StreetTurnSection>
          <StreetTurnWidget streetTurnStatus={streetTurnStatus} />
          {!isModified && !disable && <StreetTurnActions streetTurn={streetTurn} setFetching={setFetching} />}
        </StreetTurnSection>
      )}
    </>
  )
})

type Props = {
  streetTurnStatus: DispatchDeliveryOrderStreetTurnDTO.StatusEnum
}

export const StreetTurnWidget = (props: Props) => {
  const { streetTurnStatus } = props
  const { dispatchDeliveryOrder } = useGridItemDDO()

  return (
    <Widget
      title={'Equipment'}
      isTableWidget={true}
      RenderPreview={StreetTurnPreview}
      RenderDetails={EquipmentPopover}
      previewProps={{ streetTurnStatus, streetTurnCount: oc(dispatchDeliveryOrder).streetTurnCount(0) }}
      detailsProps={undefined}
    />
  )
}

const StreetTurnPreview = ({
  streetTurnStatus,
  streetTurnCount
}: {
  streetTurnStatus: DispatchDeliveryOrderStreetTurnDTO.StatusEnum
  streetTurnCount: number
}) => {
  return (
    <StreetTurnButton
      className={'mdi mdi-shuffle-variant'}
      style={{ backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus] }}
    >
      {streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE && streetTurnCount}
      <span>ST {ddoDirectory.streetTurnStatusLabel[streetTurnStatus]}</span>
    </StreetTurnButton>
  )
}

export const StreetTurnActions = (props: {
  streetTurn: DispatchDeliveryOrderStreetTurnDTO
  setFetching: ((state: boolean) => void) | undefined
  isMinimizedButtons?: boolean
}) => {
  const { streetTurn, isMinimizedButtons, setFetching } = props
  const streetTurnStatus = oc(streetTurn).status()
  const ActionButton = isMinimizedButtons ? StreetTurnOutlineActionButton : StreetTurnActionButton

  const doFetch = (action: any) => () => {
    if (setFetching) {
      setFetching(true)
    }

    const { ddoImportId, ddoExportId } = streetTurn
    const streetTurnDDOs = [ddoImportId, ddoExportId].filter(Boolean)

    action({ streetTurnId: streetTurn.id })
      .then(() => requestDispatchDeliveryOrdersByIds(streetTurnDDOs))
      .finally(() => {
        if (setFetching) {
          setFetching(false)
        }
      })
  }

  switch (streetTurnStatus) {
    case DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED:
      return (
        <>
          <ActionButton className={'approve mdi mdi-check'} onClick={doFetch(streetTurnRequests.approve)} />
          <ActionButton className={'reject mdi mdi-close'} onClick={doFetch(streetTurnRequests.reject)} />
          <ActionButton className={'cancel'} onClick={doFetch(streetTurnRequests.cancel)} />
        </>
      )
    case DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED:
      return <ActionButton className={'cancel'} onClick={doFetch(streetTurnRequests.cancel)} />
    default:
      return null
  }
}
