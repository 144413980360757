import { showModalWindow } from '../../../store/reducers/modalWindow/functions'
import { getCommunicationHubState, getDispatch } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'

export const leaveChatChannelIfNoEditing = (action: () => void) => {
  const { editMessageId } = getCommunicationHubState()

  if (editMessageId) {
    showModalWindow({
      width: 320,
      title: 'You will lose any unsaved changes to your message.',
      buttons: [
        {
          label: 'Keep Editing'
        },
        {
          label: 'Continue',
          onClick: () => {
            action()
            getDispatch()(communicationHubActions.setEditMessageId(undefined))
          }
        }
      ]
    })
  } else {
    action()
  }
}
