import styled from 'styled-components'
import theme from '../../../../../../styles/theme'

export const StyledColumnVisibility = styled.div`
  user-select: none;
  position: fixed;
  //top: 85px;
  //left: 5px;
  z-index: 800 !important;
`
export const Button = styled.div`
  display: flex;
  align-items: center;
  width: 35px;
  height: 30px;
  border-right: 1px solid ${theme.colors.basicBorderColor};
  cursor: pointer;
  color: ${theme.colors.darkGreyColor};
  font-size: 18px;
  background: #fff;

  &:after {
    content: '';
    display: block;
    width: 6px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -6px;
    background: #fff;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 26px;

    &.open {
      color: white !important;
      border-radius: 5px 5px 0 0;
      background-color: ${theme.colors.basicBlueColor};
    }
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
export const Item = styled.li`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.basicBlueColor};
    font-size: 14px;
    min-width: 16px;
    height: 16px;
    background: ${theme.colors.basicBackgroundColor};
    border-radius: 5px;
    margin-right: 7px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`
export const StyledList = styled.ul`
  padding: 0 14px 0 7px !important;
  max-height: 350px !important;
`
