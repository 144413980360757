import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'
import { locationLists } from '../../select/locationLists'
import { LocationColumn } from '../../../components/common/grid/columnSettings/locationColumnSettings'

export const locationFilterSettings = {
  [LocationColumn.Name]: {
    name: 'name',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.Type]: {
    name: 'type',
    type: ColumnDTO.Type.list,
    items: locationLists.type
  },
  [LocationColumn.Code]: {
    name: 'code',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.Company]: {
    name: 'careOf',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.Address]: {
    name: 'street',
    path: 'address.street',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.City]: {
    name: 'city',
    path: 'address.city',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.State]: {
    name: 'state.id',
    path: 'address.stateId',
    type: ColumnDTO.Type.state
  },
  [LocationColumn.ZIP]: {
    name: 'postalCode',
    path: 'address.postalCode',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.PCFirstName]: {
    name: 'primaryContact.firstName',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.PCLastName]: {
    name: 'primaryContact.lastName',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.PCPhone]: {
    name: 'primaryContact.phone',
    type: ColumnDTO.Type.string
  },
  [LocationColumn.PCEmail]: {
    name: 'primaryContact.email',
    type: ColumnDTO.Type.string
  }
}
