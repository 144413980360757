import styled from 'styled-components'
import theme from '../../../../../../styles/theme'
import { ColumnTitle } from '../../styledComponents'

export const SortButton = styled.span`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 15px;
  height: 15px;
  right: 3px;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.3;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  &.mdi-menu-up {
    top: 0;
    padding-top: 3px;
  }

  &.mdi-menu-down {
    bottom: 0;
    padding-bottom: 3px;
  }

  &.active {
    color: ${theme.colors.basicBlueColor} !important;
    opacity: 1 !important;
  }
`
export const ResizeButton = styled.div`
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  cursor: col-resize;

  & + span {
    display: none;
  }

  &:hover:before {
    content: '';
    display: block;
    position: absolute;
    border-left: 1px dashed red;
    top: 0;
    bottom: 0;
    left: 50%;
  }

  &.active {
    width: 401px;
    position: fixed;
    top: 85px;
    height: calc(100vh - 85px);
    right: unset;
    left: unset;
    transform: translateX(-50%);
  }
`
export const Highlight = styled.div`
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.basicBlueColor};
`
export const StyledColumn = styled(ColumnTitle)`
  &.last-column {
    .column-value {
      border: none;
    }
  }

  &.first-column:before {
    content: unset;
  }

  .column-value {
    border: none;
    width: calc(100% - 20px);
    color: #6c707d;

    &:after {
      content: none !important;
    }

    span {
      color: #6c707d;
      opacity: 1;
    }
  }

  .search-by-list-container {
    height: 30px;
    padding-right: 10px;

    .input-container {
      border: none;
      height: 30px;

      input {
        font-size: 10px;
        color: #6c707d;

        &:not(:focus)::placeholder {
          color: #6c707d;
        }
      }
    }
  }

  .select__container {
    .selected {
      border: none;

      .selected__label {
        min-height: 30px;
        max-height: 30px;
        height: 30px;
        border: none;

        &:before {
          content: none !important;
        }
      }

      .selected__label,
      .placeholder {
        color: #6c707d;
        font-size: 10px;
      }

      .selected__label {
        padding-right: 10px;
      }
    }

    & + .mdi-close {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
    }
  }

  .date-time-picker-container {
    width: calc(100% - 20px) !important;
    height: 100%;

    .date-time-picker {
      font-size: 10px;
      height: 100%;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .date-picker-container {
    width: calc(100% - 20px) !important;
    height: 100%;

    .date-field {
      height: 100%;
      background: none;
      border: none;
      padding-right: 20px;
      margin: 0;

      &:after {
        content: none;
      }

      .b-reset-all {
        display: block;
      }

      & > div:first-child {
        opacity: 1 !important;
        margin: 0 !important;

        span {
          display: inline-block !important;
          color: #6c707d;
          font-size: 10px;
          line-height: 15px;

          &:not(.date) {
            margin-right: 5px;
          }

          &:nth-child(3) {
            margin-left: 5px;
          }
        }
      }

      input {
        line-height: 15px;
        display: block;
        color: #6c707d;
        font-size: 10px;
        margin: 0 !important;
      }
    }

    .date-picker {
      transform: translateY(-2px);
    }

    .mdi-close {
      opacity: 0.4;
      font-size: 14px;
      right: 3px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`
