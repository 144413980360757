import { ContactDTO } from '../../../api/api'
import { schemaContact } from '../../yupScheme'
import { isContactFilled } from './isContactFilled'

export const isContactValid = (contact: ContactDTO): boolean => {
  // schemaContact.validate(contact).catch(e =>
  //   // getStore().dispatch(
  //   //   addServerMessage({
  //   //     type: 'error',
  //   //     message: e.message
  //   //   })
  //   // )
  //   console.log(e)
  // )
  return schemaContact.isValidSync(contact) && isContactFilled(contact)
}
