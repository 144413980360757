import { oc } from 'ts-optchain'
import { BusinessPartnerViewDTO, CustomerViewDTO, SubClientViewDTO } from '../../../api/api'
import { getExpandedItemState, getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { ICustomerSubClient } from '../../saveEntity/saveCustomer'
import { getCurrentListItemState } from './getCurrentListItemState'

type Props = {
  customer: CustomerViewDTO
  modifiedLists: Partial<TListsState>
  storeLists?: TListsState
}

export const getCustomerSubClients = (props: Props): ICustomerSubClient[] => {
  const { customer } = props
  const storeLists = props.storeLists || getListsState()
  const modifiedLists = props.modifiedLists || getExpandedItemState().modifiedLists

  const subClients: SubClientViewDTO[] = oc(customer)
    .subClientIds([])
    .map(id => getCurrentListItemState({ id, entityType: EntityType.subClient, storeLists, modifiedLists }))
    .filter(Boolean)

  return subClients
    .map(subClient => {
      const businessPartner: BusinessPartnerViewDTO = getCurrentListItemState({
        id: subClient.businessPartnerId,
        entityType: EntityType.businessPartner,
        storeLists,
        modifiedLists
      })

      return {
        subClient: oc(modifiedLists)[EntityType.subClient][subClient.id](),
        businessPartner: oc(modifiedLists)[EntityType.businessPartner][subClient.businessPartnerId](),
        contacts: oc(businessPartner)
          .contactIds([])
          .map(id => oc(modifiedLists)[EntityType.contact][id]())
          .filter(Boolean)
      }
    })
    .filter(item => item.subClient || item.businessPartner || (item.contacts && item.contacts.length))
}
