import { oc } from 'ts-optchain'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import {
  BuySideQuoteMiscSurchargeDTO,
  DispatchDeliveryOrderViewDTO,
  SellSideQuoteMiscSurchargeDTO
} from '../../../api/api'

export const checkQuotesValidation = (expandedItemContext: TExpandedItemContext): boolean => {
  const { modifiedLists, parentItem } = expandedItemContext
  const dispatchDeliveryOrder = parentItem as DispatchDeliveryOrderViewDTO
  const modifiedSellSideQuote = oc(modifiedLists)[EntityType.sellSideQuote][
    oc(dispatchDeliveryOrder).sellSideQuoteId()
  ]()
  const modifiedBuySideQuotes = oc(dispatchDeliveryOrder)
    .buySideQuoteIds([])
    .map(id => {
      return oc(modifiedLists)[EntityType.buySideQuote][id]()
    })
    .filter(Boolean)

  const modifiedMiscSurcharges = [...modifiedBuySideQuotes, modifiedSellSideQuote]
    .filter(Boolean)
    // @ts-ignore
    .reduce((acc, curr) => [...acc, ...oc(curr).miscSurcharges([])], [] as (
      | SellSideQuoteMiscSurchargeDTO
      | BuySideQuoteMiscSurchargeDTO)[])
    .filter(Boolean)

  return modifiedMiscSurcharges.every(m => m.description)
}
