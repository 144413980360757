import * as React from 'react'
import { FilteringAndSorting } from './Filtering'
import { Dashboard } from './Dashboard'
import styled from 'styled-components'
import { SchedulerSortBy } from '../../interfaces'
import { DateISOString } from '../../../../../api/origin/business-logic'

type Props = {
  dispatchDeliveryOrderNumber: number
  dispatchDeliveryOrderId: string
  syncWithRouteBuilder: boolean
  sortBy: SchedulerSortBy
  specificDate: DateISOString
}

export const Header = React.memo((props: Props) => {
  const { dispatchDeliveryOrderNumber, dispatchDeliveryOrderId, syncWithRouteBuilder, sortBy, specificDate } = props

  return (
    <Conrainer>
      <FilteringAndSorting
        sortBy={sortBy}
        syncWithRouteBuilder={syncWithRouteBuilder}
        dispatchDeliveryOrderNumber={dispatchDeliveryOrderNumber}
        dispatchDeliveryOrderId={dispatchDeliveryOrderId}
        specificDate={specificDate}
      />
      <Dashboard />
    </Conrainer>
  )
})

const Conrainer = styled.div`
  z-index: 1;
  display: flex;
  padding: 8px 16px 8px 14px;
`
