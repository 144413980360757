import * as React from 'react'
import styled from 'styled-components'
import { TransportationActivityViewDTO } from '../../../../api/origin/business-logic'
import { MoveStatus } from '../../../../store/reducers/communicationHub/interfaces'
import { moveDirectory } from './directory'

const ActivityIcon = styled.i`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  color: white;
  user-select: none;
`
const ActivityIconButton = styled(ActivityIcon)`
  color: #445366;
  font-size: 18px;
`
const MoveIcon = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid black;
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  user-select: none;
  overflow: hidden;

  &.mdi {
    &:before {
      position: relative;
      z-index: 1;
      font-size: 20px;
    }
    &:after {
      z-index: 0;
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #fff;
    }
  }
`

export const activityStatusIconMapping: Record<TransportationActivityViewDTO.StatusEnum, JSX.Element> = {
  [TransportationActivityViewDTO.StatusEnum.NEW]: <ActivityIconButton className={'mdi mdi-plus-circle-outline'} />,
  [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: (
    <ActivityIconButton className={'mdi mdi-minus-circle-outline'} />
  ),
  [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: (
    <ActivityIcon
      style={{
        backgroundColor: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]
      }}
    >
      A
    </ActivityIcon>
  ),
  [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: (
    <ActivityIcon
      style={{
        backgroundColor: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]
      }}
    >
      C
    </ActivityIcon>
  ),
  [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]: (
    <ActivityIcon
      style={{
        backgroundColor: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]
      }}
    >
      R
    </ActivityIcon>
  ),
  [TransportationActivityViewDTO.StatusEnum.INPROCESS]: (
    <ActivityIcon
      className={'mdi mdi-clock-time-five'}
      style={{
        fontSize: 20,
        color: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.INPROCESS]
      }}
    />
  ),
  [TransportationActivityViewDTO.StatusEnum.COMPLETED]: (
    <ActivityIcon
      className={'mdi mdi-checkbox-marked-circle'}
      style={{
        fontSize: 20,
        color: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.COMPLETED]
      }}
    />
  ),
  [TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]: (
    <ActivityIcon
      style={{
        backgroundColor: moveDirectory.activityStatusColor[TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL]
      }}
    >
      U
    </ActivityIcon>
  )
}

// tslint:disable:max-line-length
export const moveStatusIconMapping: Record<MoveStatus, JSX.Element> = {
  [MoveStatus.PLANNED]: (
    <MoveIcon
      style={{
        color: moveDirectory.statusColor[MoveStatus.PLANNED],
        borderColor: moveDirectory.statusColor[MoveStatus.PLANNED]
      }}
    >
      P
    </MoveIcon>
  ),
  [MoveStatus.ASSIGNED]: (
    <MoveIcon
      style={{
        color: moveDirectory.statusColor[MoveStatus.ASSIGNED],
        borderColor: moveDirectory.statusColor[MoveStatus.ASSIGNED]
      }}
    >
      A
    </MoveIcon>
  ),
  [MoveStatus.CONFIRMED]: (
    <MoveIcon
      style={{
        color: moveDirectory.statusColor[MoveStatus.CONFIRMED],
        borderColor: moveDirectory.statusColor[MoveStatus.CONFIRMED]
      }}
    >
      C
    </MoveIcon>
  ),
  [MoveStatus.REFUSED]: (
    <MoveIcon
      style={{
        color: moveDirectory.statusColor[MoveStatus.REFUSED],
        borderColor: moveDirectory.statusColor[MoveStatus.REFUSED]
      }}
    >
      R
    </MoveIcon>
  ),
  [MoveStatus.INPROCESS]: (
    <MoveIcon
      // className={'mdi mdi-clock-time-four'}
      style={{
        border: 'none',
        // color: moveDirectory.statusColor[MoveStatus.INPROCESS],
        backgroundColor: moveDirectory.statusColor[MoveStatus.INPROCESS]
      }}
    >
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H12C14.2091 0.5 16 2.29086 16 4.5V12.5C16 14.7091 14.2091 16.5 12 16.5H4C1.79086 16.5 0 14.7091 0 12.5V4.5Z"
          fill={moveDirectory.statusColor[MoveStatus.INPROCESS]}
        />
        <path
          d="M5.224 12.5C5.144 12.5 5.076 12.472 5.02 12.416C4.964 12.36 4.936 12.292 4.936 12.212V4.4C4.936 4.312 4.96 4.24 5.008 4.184C5.064 4.128 5.136 4.1 5.224 4.1H8.44C9.408 4.1 10.168 4.328 10.72 4.784C11.272 5.24 11.548 5.904 11.548 6.776C11.548 7.64 11.272 8.296 10.72 8.744C10.168 9.184 9.408 9.404 8.44 9.404H6.676V12.212C6.676 12.3 6.648 12.372 6.592 12.428C6.536 12.476 6.464 12.5 6.376 12.5H5.224ZM8.38 8.06C8.844 8.06 9.196 7.952 9.436 7.736C9.684 7.512 9.808 7.188 9.808 6.764C9.808 6.348 9.688 6.024 9.448 5.792C9.216 5.56 8.86 5.444 8.38 5.444H6.652V8.06H8.38Z"
          fill={moveDirectory.statusColor[MoveStatus.INPROCESS]}
        />
        <circle cx="7.9998" cy="8.49999" r="7" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.9998 15.7C11.9763 15.7 15.1998 12.4764 15.1998 8.49999C15.1998 4.52354 11.9763 1.29999 7.9998 1.29999C4.02335 1.29999 0.799805 4.52354 0.799805 8.49999C0.799805 12.4764 4.02335 15.7 7.9998 15.7ZM8.9998 4.89999C8.9998 4.3477 8.55209 3.89999 7.9998 3.89999C7.44752 3.89999 6.9998 4.3477 6.9998 4.89999V8.49999C6.9998 8.7652 7.10516 9.01956 7.2927 9.20709L9.83828 11.7527C10.2288 12.1432 10.862 12.1432 11.2525 11.7527C11.643 11.3622 11.643 10.729 11.2525 10.3385L8.9998 8.08577V4.89999Z"
          fill={moveDirectory.statusColor[MoveStatus.INPROCESS]}
        />
        <path
          d="M4 1.5H12V-0.5H4V1.5ZM15 4.5V12.5H17V4.5H15ZM12 15.5H4V17.5H12V15.5ZM1 12.5V4.5H-1V12.5H1ZM4 15.5C2.34315 15.5 1 14.1569 1 12.5H-1C-1 15.2614 1.23858 17.5 4 17.5V15.5ZM15 12.5C15 14.1569 13.6569 15.5 12 15.5V17.5C14.7614 17.5 17 15.2614 17 12.5H15ZM12 1.5C13.6569 1.5 15 2.84315 15 4.5H17C17 1.73858 14.7614 -0.5 12 -0.5V1.5ZM4 -0.5C1.23858 -0.5 -1 1.73858 -1 4.5H1C1 2.84315 2.34315 1.5 4 1.5V-0.5Z"
          fill={moveDirectory.statusColor[MoveStatus.INPROCESS]}
        />
      </svg>
    </MoveIcon>
  ),
  [MoveStatus.COMPLETED]: (
    <MoveIcon
      // className={'mdi mdi-checkbox-marked-circle'}
      style={{
        border: 'none',
        // color: moveDirectory.statusColor[MoveStatus.COMPLETED],
        backgroundColor: '#D7DAE5'
      }}
    >
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H12C14.2091 0.5 16 2.29086 16 4.5V12.5C16 14.7091 14.2091 16.5 12 16.5H4C1.79086 16.5 0 14.7091 0 12.5V4.5Z"
          fill="#D7DAE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7908 4.73431C13.0697 5.04673 13.0697 5.55327 12.7908 5.86569L7.0765 12.2657C6.79756 12.5781 6.3453 12.5781 6.06635 12.2657L3.20921 9.06569C2.93026 8.75327 2.93026 8.24673 3.20921 7.93431C3.48816 7.6219 3.94042 7.6219 4.21936 7.93431L6.57143 10.5686L11.7806 4.73431C12.0596 4.4219 12.5118 4.4219 12.7908 4.73431Z"
          fill="white"
        />
        <path
          d="M4 1.5H12V-0.5H4V1.5ZM15 4.5V12.5H17V4.5H15ZM12 15.5H4V17.5H12V15.5ZM1 12.5V4.5H-1V12.5H1ZM4 15.5C2.34315 15.5 1 14.1569 1 12.5H-1C-1 15.2614 1.23858 17.5 4 17.5V15.5ZM15 12.5C15 14.1569 13.6569 15.5 12 15.5V17.5C14.7614 17.5 17 15.2614 17 12.5H15ZM12 1.5C13.6569 1.5 15 2.84315 15 4.5H17C17 1.73858 14.7614 -0.5 12 -0.5V1.5ZM4 -0.5C1.23858 -0.5 -1 1.73858 -1 4.5H1C1 2.84315 2.34315 1.5 4 1.5V-0.5Z"
          fill="#D7DAE5"
        />
      </svg>
    </MoveIcon>
  ),
  [MoveStatus.UNSUCCESSFUL]: (
    <MoveIcon
      style={{
        color: moveDirectory.statusColor[MoveStatus.UNSUCCESSFUL],
        borderColor: moveDirectory.statusColor[MoveStatus.UNSUCCESSFUL]
      }}
    >
      U
    </MoveIcon>
  )
}
