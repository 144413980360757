import * as React from 'react'
import { IconName } from '../Icon'
import { AllUnreadIcon } from '../Navigation/NavigationAllUnread'
import { DetailsViewHeader, DetailsViewHeaderName } from '../DetailsView/DetailsViewHeader'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'

type Props = {
  hasUnreadMessages: boolean
}

export const AllUnreadHeader = (props: Props) => {
  const dispatch = useAppDispatch()

  const title = (
    <>
      <AllUnreadIcon color={'#333333'} />
      <DetailsViewHeaderName style={{ marginLeft: 8 }}>All unread</DetailsViewHeaderName>
    </>
  )

  return (
    <DetailsViewHeader
      title={title}
      // extraLeftBlock={extraLeftBlock}
      onCloseButtonClick={() => leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.closeDetailsView()))}
      closeButtonId={'All unread'}
      closeButtonIcon={IconName.CLOSE_DETAILS}
    />
  )
}
