import { PowerUnitDTO, PowerUnitRegistrationDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'
import { generateMaintenanceInspection } from './generateMaintenanceInspection'

export const generatePowerUnit = (props?: Partial<PowerUnitDTO>): PowerUnitDTO => {
  return {
    completeness: maxCompleteness,
    active: true,
    id: createId(),
    inactiveReason: null,
    availability: true,
    unavailabilityReason: null,
    powerUnitType: null,
    powerUnitSubType: '',
    truckType: null,
    year: null,
    make: '',
    model: '',
    vin: '',
    weight: null,
    weightUnit: PowerUnitDTO.WeightUnitEnum.LBS,
    specs: '',
    ownership: null,
    portPassRfid: false,
    rfid: '',
    licensePlate: '',
    registration: {
      registeredDate: null,
      expirationDate: null,
      weight: null,
      weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum.LBS,
      restrictions: null,
      stateId: null
    },
    dotSafetyInspection: {
      performedDate: null,
      expirationDate: null
    },
    insurance: {
      insuranceType: null,
      carrier: '',
      expirationDate: null,
      policyNumber: ''
    },
    maintenanceInspectionFrequency: null,
    maintenanceInspections: [generateMaintenanceInspection()],
    driverIds: [],
    repairs: [],
    ...(props || {})
  }
}
