import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'
import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { assembleActivities } from './assembleActivities'

type Props = {
  entityId: string
  entityType: EntityType
  getEntity: (id: string, type: EntityType) => any
  modifiedLists?: Partial<TListsState>
}

export const _assembleDispatchDeliveryOrder = (props: Props): DispatchDeliveryOrderViewDTO => {
  try {
    const { entityId, entityType, modifiedLists, getEntity } = props
    const dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO = getEntity(entityId, entityType)

    return {
      ...dispatchDeliveryOrder,
      activities: assembleActivities({ acivityIds: dispatchDeliveryOrder.activityIds, modifiedLists }),
      streetTurn: getEntity(dispatchDeliveryOrder.streetTurnId, EntityType.streetTurn),
      container: getEntity(dispatchDeliveryOrder.containerId, EntityType.container),
      equipment: getEntity(dispatchDeliveryOrder.equipmentId, EntityType.equipment),
      sellSideQuote: getEntity(dispatchDeliveryOrder.sellSideQuoteId, EntityType.sellSideQuote),
      buySideQuotes: (dispatchDeliveryOrder.buySideQuoteIds || [])
        .map(id => getEntity(id, EntityType.buySideQuote))
        .filter(Boolean),
      pickupStage: {
        ...oc(dispatchDeliveryOrder).pickupStage({}),
        location:
          getEntity(oc(dispatchDeliveryOrder).pickupStage.locationId(), EntityType.location) ||
          oc(dispatchDeliveryOrder).pickupStage.location()
      },
      deliveryStage: {
        ...oc(dispatchDeliveryOrder).deliveryStage({}),
        location:
          getEntity(oc(dispatchDeliveryOrder).deliveryStage.locationId(), EntityType.location) ||
          oc(dispatchDeliveryOrder).deliveryStage.location()
      },
      returnStage: {
        ...oc(dispatchDeliveryOrder).returnStage({}),
        location:
          getEntity(oc(dispatchDeliveryOrder).returnStage.locationId(), EntityType.location) ||
          oc(dispatchDeliveryOrder).returnStage.location()
      },
      deliveryOrder:
        getEntity(dispatchDeliveryOrder.deliveryOrderId, EntityType.deliveryOrder) ||
        dispatchDeliveryOrder.deliveryOrder
    } as DispatchDeliveryOrderViewDTO
  } catch (e) {
    return undefined
  }
}
