import * as React from 'react'
import { FileUploader } from './FileUploader'
import { FileDTO, FileVersionDTO } from '../../../../api/origin/document-service'
import { RichFileUploader } from './RichFileUploader'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectActiveApplicationTabId } from '../../../../store/select/applicationTabSelect'
import { getDispatch, getExpandedItemState } from '../../../../store'
import { getModifiedObjectState } from '../../../../services/functions/get/getModifiedObjectState'
import { tabActions } from '../../../../store/reducers/tabs'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { oc } from 'ts-optchain'

type OwnProps = {
  uploadingFileId: string
  file: FileDTO | undefined
  innerObjectData?: { entityType: EntityType; id: string }
  onFileUploaded: (file: FileDTO | undefined, modifiedObject: any) => any
  onClearFile: () => void
  title?: string
  required?: boolean
  disabled?: boolean
  historyProps?: { entityId: string; type: FileVersionDTO.TypeEnum }
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ViewingObjectFileUploader = React.memo((props: Props) => {
  const { title, required, disabled, innerObjectData, uploadingFileId, onClearFile, historyProps } = props
  const activeTabId = useAppSelector(selectActiveApplicationTabId)

  const onChange = React.useCallback(
    (file: FileDTO | undefined) => {
      if (!file) {
        return onClearFile()
      }

      const dispatch = getDispatch()
      const expandedItem = getExpandedItemState(activeTabId)
      let entityType = expandedItem.entityType
      let modifiedObject = getModifiedObjectState(expandedItem)

      if (innerObjectData) {
        entityType = innerObjectData.entityType
        modifiedObject = oc(expandedItem).modifiedLists[entityType][innerObjectData.id]()
      } else {
        modifiedObject = getModifiedObjectState(expandedItem)
      }

      if (!modifiedObject) {
        return
      }

      try {
        dispatch(
          tabActions.modifyListItems({
            tabId: activeTabId,
            items: [{ entityType, modifiedState: props.onFileUploaded(file, modifiedObject) }]
          })
        )
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error("File updating error. ViewingObject can't be build correctly")
      }
    },
    [props.onFileUploaded, onClearFile]
  )

  return historyProps ? (
    <RichFileUploader
      uploadingFileId={uploadingFileId}
      title={title}
      required={required}
      disabled={disabled}
      activeTabId={activeTabId}
      file={props.file}
      type={historyProps.type}
      entityId={historyProps.entityId}
      onChange={onChange}
    />
  ) : (
    <FileUploader
      uploadingFileId={uploadingFileId}
      title={title}
      required={required}
      disabled={disabled}
      activeTabId={activeTabId}
      file={props.file}
      onChange={onChange}
    />
  )
})
