import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { Column, TextLine, TextLines } from '../PreviewPhase'
import theme from '../../../../../../../../styles/theme'

type Props = {
  title: string
  value: string
  dataId?: string
  color?: string
  center?: boolean
  isTwoActivitiesView?: boolean
  onRefrashButtonClick?: () => void
}

export const SpecialColumn = React.memo((props: Props) => {
  const { title, center, color, isTwoActivitiesView, dataId, onRefrashButtonClick } = props
  const [fetching, setFetching] = React.useState(false)
  let value = props.value
  const isTwoLineDateColumn = value && value.includes(' ')
  const hasRefrashButton = Boolean(onRefrashButtonClick)

  if (isTwoLineDateColumn) {
    value = value.replace(' ', '\n')
  }

  return (
    <Column data-id={dataId} style={hasRefrashButton ? { position: 'relative' } : undefined}>
      {isTwoActivitiesView ? (
        <span
          style={{
            fontSize: 11,
            fontWeight: 500,
            color
          }}
        >
          {value || title}
        </span>
      ) : (
        <TextLines className={'space-between'} style={center ? { textAlign: 'center' } : undefined}>
          <TextLine
            style={{
              flexShrink: 0,
              fontSize: 10,
              color: '#727272'
            }}
          >
            {title}
          </TextLine>
          <TextLine
            style={{
              fontSize: 12,
              fontWeight: 500,
              color,
              ...(isTwoLineDateColumn
                ? { whiteSpace: 'pre-wrap', fontSize: 10, lineHeight: '11px', overflow: 'visible' }
                : {})
            }}
          >
            {value}
          </TextLine>
        </TextLines>
      )}
      {hasRefrashButton && (
        <RefreshButton
          style={isTwoActivitiesView ? undefined : { top: 4 }}
          className={cn('mdi mdi-refresh', { active: fetching })}
          onClick={
            fetching
              ? undefined
              : async (event: React.MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation()
                  setFetching(true)
                  await onRefrashButtonClick()
                  setFetching(false)
                }
          }
        />
      )}
    </Column>
  )
})

const RefreshButton = styled.div`
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  color: #9ba8b8;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }

  &.active {
    opacity: 0.5;
  }
`
