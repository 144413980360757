import { callAPIWithErrorMessage, ruleAPI } from '../../../../api/api'
import { RuleDTO } from '../../../../api/origin/qmp-service'
import { makeReadableDescription, qmpLists } from '../../../../services/select/qmpLists'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { EntityType } from '../../../../store/reducers/lists/interfaces'

export const requestRules = () => {
  return callAPIWithErrorMessage(ruleAPI.getRules).then(async rules => {
    rules.forEach((rule: RuleDTO) => {
      qmpLists.rules.push({
        label: rule.name,
        value: rule.id
      })
      qmpLists.rulesWithDescription.push({
        label: rule.name,
        popover: makeReadableDescription(rule.description),
        value: rule.id
      })
    })

    await pushListItemsToStore({ update: { [EntityType.rule]: rules } })
  })
}
