import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderGridItemDTO, DispatchDeliveryOrderSearchDTO } from '../../../api/api'
import { ChannelType, ICHubChannel } from '../../../store/reducers/communicationHub/interfaces'
import { generateChannelDataByEntity } from './generateChannelDataByEntity'

export const generateDispatchDeliveryOrderChatChannel = (
  dispatchDeliveryOrder: DispatchDeliveryOrderGridItemDTO | DispatchDeliveryOrderSearchDTO
): ICHubChannel => {
  const { id, name, subName, channelViewName } = generateChannelDataByEntity({
    channelType: ChannelType.dispatchDeliveryOrder,
    data: dispatchDeliveryOrder
  })

  return {
    id,
    type: ChannelType.dispatchDeliveryOrder,
    name,
    subName,
    channelViewName,
    metadata: {
      status: oc(dispatchDeliveryOrder).status(),
      archived: oc(dispatchDeliveryOrder).archived()
    }
  }
}
