import * as React from 'react'
import { NotificationsViewHeader } from './NotificationsViewHeader'
import { NotificationsViewBody } from './NotificationsViewBody'
import { NotificationsViewFilters } from './NotificationsViewFilters'
import { requestNotificationDocumentForms } from '../../epics'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectNotificationDocumentForms } from '../../../../store/select/notificationSelect'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const NavigationsView = (props: Props) => {
  const notificationForms = useAppSelector(selectNotificationDocumentForms)

  React.useEffect(() => {
    if (
      !(
        notificationForms &&
        notificationForms.some(_ => _.isApplicationForm) &&
        notificationForms.some(_ => _.isOfficialForm)
      )
    ) {
      requestNotificationDocumentForms()
    }
  }, [notificationForms])

  return (
    <>
      <NotificationsViewHeader />
      <NotificationsViewFilters />
      <NotificationsViewBody />
    </>
  )
}
