import * as React from 'react'
import { SearchByList, TDropDownItem } from '../../../SearchByList'
import { IStore } from '../../../../../../store/store.interface'
// @ts-ignore
import { debounce } from 'debounce'
import { getStore } from '../../../../../../store/configureStore'
import { isNewObject } from '../../../../../../services/DTO'

type OwnProps = {
  disabled?: boolean
  title?: string
  required?: boolean
  label: any
  onChange: (value: any) => void
  placeholder?: string
  symbolsNumberToFetch?: number
  getList: (inputValue: string) => Promise<any>
  findInGoogle: (inputValue: string) => Promise<any>
  getDetails: (value: any) => Promise<any>
  addToStore?: any
  assembleObject?: (props: { store: IStore; id: string }) => any
  makeDropDownItem: (searchItem: any) => TDropDownItem
  filtering?: boolean
  highlighted?: boolean
  focus?: boolean
  maxLength?: number
  minWidth?: number
}

type DispatchProps = {
  addToStoreList?: (object: any) => void
}

type Props = OwnProps & DispatchProps

export const SearchRequest = ({
  disabled,
  title,
  required,
  label,
  onChange,
  placeholder,
  symbolsNumberToFetch = 3,
  assembleObject,
  makeDropDownItem,
  addToStore,
  findInGoogle,
  getList,
  getDetails,
  filtering,
  highlighted,
  focus,
  maxLength,
  minWidth
}: Props) => {
  const [fetching, setFetching] = React.useState(false)
  const commonProps = {
    title,
    required,
    label,
    placeholder,
    symbolsNumberToFetch,
    filtering,
    highlighted,
    disabled,
    maxLength,
    minWidth,
    focus
  }
  const [dropDownList, setDropDownList] = React.useState([])
  const [googleSearch, setGoogleSearch] = React.useState(false)

  // FIND ALL ITEMS
  const getListRequest = (searchValue: string) => {
    setFetching(true)
    getList(searchValue.trim())
      .then(list => {
        setDropDownList(list.map(makeDropDownItem))
        setGoogleSearch(Boolean(!list.length))
      })
      .finally(() => {
        setFetching(false)
      })
    // .catch(() => {
    //   setDropDownList([])
    //   setFetching(false)
    // })
  }

  // GET ALL BUTTON
  const findAll = () => getListRequest('')

  // HANDLE INPUT CHANGE
  const handleInputChange = (inputValue: string) => {
    if (inputValue.length >= symbolsNumberToFetch) {
      getListRequest(inputValue)
    } else if (inputValue.length !== 0) {
      setDropDownList([])
    }
  }

  // ON ENTER PRESS
  const onEnterPress = (inputValue: string) => {
    if (inputValue.length >= symbolsNumberToFetch && googleSearch) {
      setFetching(true)
      setGoogleSearch(false)
      findInGoogle(inputValue.trim())
        .then(list => {
          setDropDownList(list.map(makeDropDownItem))
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }

  // SELECT ITEM FROM THE LIST
  const handleChange = async (value: any) => {
    if (value) {
      setFetching(true)
      await getDetails(value)
        .then(data => {
          if (addToStore && !isNewObject(data)) {
            addToStore(data)
          }
          onChange(assembleObject && !isNewObject(data) ? assembleObject({ store: undefined, id: data.id }) : data)
        })
        .finally(() => {
          setFetching(false)
        })
    } else {
      onChange(null)
    }
  }

  return (
    <SearchByList
      {...commonProps}
      onChange={handleChange}
      isFetching={fetching}
      onEnterPress={debounce(onEnterPress, 350)}
      handleInputChange={debounce(handleInputChange, 350, googleSearch)}
      dropDownList={dropDownList}
      findAll={findAll}
      enterPressText={'Press Enter to Google search'}
    />
  )
}
