import styled from 'styled-components'
import { CommunicationHubConstants } from '../../../../../../../constants/CommunicationHubConstants'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'

const {
  mediaQueriesBreakpoints: { small }
} = SchedulerConstants
const { navigationViewWidth, channelViewWidth } = CommunicationHubConstants

export const Cell = styled.div`
  height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &.border {
    border: 1px solid rgba(229, 229, 229, 0.5);
    border-radius: 2px;
  }

  &.text-color-white {
    color: white;
  }
`
export const CellLabel = styled.div`
  font-size: 14px;
  color: #e5e5e5;
`
export const CellValue = styled.div`
  display: inline-flex;
  justify-content: center;
  min-width: 18px;
  color: white;
  font-weight: 700;
`
export const CellIcon = styled.span`
  margin: 0 8px 0 15px;
`
export const StyledStatistics = styled.div`
  display: flex;
  user-select: none;
  cursor: default;

  .communicationHubExpanded & {
    @media screen and (max-width: ${small + navigationViewWidth + channelViewWidth}px) {
      ${Cell} {
        padding: 0 3px;
      }
      ${CellLabel} {
        font-size: 11px;
      }
    }
  }
  .communicationHubNotExpanded & {
    @media screen and (max-width: ${small + navigationViewWidth}px) {
      ${Cell} {
        padding: 0 3px;
      }

      ${CellLabel} {
        font-size: 11px;
      }
    }
  }
`
