import theme from '../../../../styles/theme'
import styled from '../../../../styles/styledComponents'

export const StickiedLabel = styled.div`
  display: flex;
  align-items: center;
  position: sticky;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`

export const Row = styled.div`
  display: inline-block;
  padding-top: 35px;
`
export const Header = styled.div`
  display: inline-flex;
  position: sticky;
  top: 0;
  left: 0;
  background: rgb(245, 246, 250);
  z-index: 3;
  padding-bottom: 6px;
  margin-bottom: -6px;
`
export const Items = styled.div`
  white-space: nowrap;
`
export const ActivitiesContainer = styled.div`
  //position: sticky;
  //left: 0;
  white-space: normal;
  background-color: white;
`
export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0 15px;
  background: none;
  border: none;
  font-weight: 300;
  color: white;
  font-size: 14px;

  &::placeholder {
    color: white;
    opacity: 0.5;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  background: ${theme.colors.basicBackgroundColor};
  min-height: calc(100vh - 85px);
`
