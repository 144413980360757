import * as React from 'react'
import { Column, ColumnRow, GridColumn } from '../../../../../../UI/GridSystem'
import { Title } from '../styledComponents'
import AddressField from '../../../../../../UI/AddressField'
import { InputField } from '../../../../../../UI/DataFields/Input'
import { DriverViewDTO } from '../../../../../../../api/origin/business-logic'
import { isAddressValid } from '../../../../../../../services/functions/test/isAddressValid'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { isDriverFieldRequired } from '../../../../../../../services/functions/test/isDriverFieldRequired'
import { useGridItemDriver } from '../../../../../../../hooks/useGridItemDriver'

type Props = {}

export const Address = (props: Props) => {
  const { modifyParentObjectField } = useExpandedItem()
  const { driver } = useGridItemDriver()
  const { mailingAddress, phone, email, driverType } = driver

  const isOwnedOperator = driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR
  const isVendor = driverType === DriverViewDTO.DriverTypeEnum.VENDOR
  const isCompany = driverType === DriverViewDTO.DriverTypeEnum.COMPANY
  const isDriver = isCompany || isOwnedOperator

  return (
    <div style={{ width: '33.3%', paddingRight: 40 }}>
      <Title>Address</Title>
      <AddressField
        title={'Mailing Address Search'}
        highlighted={mailingAddress ? !isAddressValid(mailingAddress) : false}
        required={!isDriver}
        address={mailingAddress}
        onChange={modifyParentObjectField('mailingAddress')}
      />

      <GridColumn>
        <Column columns={9} flexBasis={'auto'} margin={{ right: 20 }}>
          <ColumnRow margin={{ bottom: 15 }}>
            <InputField.PhoneNumber
              title={'Phone'}
              required={!isVendor}
              value={phone}
              onChange={modifyParentObjectField('phone')}
            />
          </ColumnRow>
        </Column>

        <Column columns={11} flexBasis={'auto'}>
          <ColumnRow margin={{ bottom: 15 }}>
            <InputField.Email
              required={isDriverFieldRequired(driver).email}
              title={'Email'}
              value={email}
              onChange={modifyParentObjectField('email')}
            />
          </ColumnRow>
        </Column>
      </GridColumn>
    </div>
  )
}
