import * as React from 'react'
import { Container, Success, Information, Text } from './styles'
import { deliveryOrderAPI } from '../../../api/api'

interface IProps {
  currentDeliveryOrderId: string
  value?: string
  style?: any
  minLength?: number
}

function renderStatusIcon(status: { status: string; message: string }) {
  switch (status.status) {
    case 'match found': {
      return (
        <>
          <Text>{status.message}</Text>
          <Information className={'mdi mdi-information'} />
        </>
      )
    }
    case 'no match found': {
      return <Success className={'mdi mdi-checkbox-marked-circle'} />
    }
    default: {
      return null
    }
  }
}

export const CheckFieldByRequest = ({ value = '', style, minLength, currentDeliveryOrderId }: IProps) => {
  const statusList = {
    default: {
      status: '',
      message: ''
    },
    success: { status: 'no match found', message: '' },
    error: (message: string) => ({ status: 'match found', message })
  }

  const [searchResultStatus, setSearchResultStatus] = React.useState(statusList.default)

  React.useEffect(() => {
    if (!value) {
      return
    }

    if (minLength && value.length < minLength) {
      setSearchResultStatus(statusList.default)
      return
    }

    deliveryOrderAPI.checkByReferenceNumber(value || '').then(result => {
      if (result.some(item => item.id === currentDeliveryOrderId)) {
        return setSearchResultStatus(statusList.default)
      }

      if (result.length) {
        return setSearchResultStatus(
          statusList.error(
            result.length === 1
              ? 'In use in Delivery Order #' + result[0].number
              : 'In use in Delivery Orders: ' + result.map(item => `#${item.number}`).join(', ')
          )
        )
      }

      return setSearchResultStatus(statusList.success)
    })
  }, [value, currentDeliveryOrderId])

  return <Container style={style}>{renderStatusIcon(searchResultStatus)}</Container>
}
