import * as React from 'react'
import styled from '../../styles/styledComponents'
import theme from '../../styles/theme'

type Props = {
  disabled?: boolean
  checked?: boolean | null
  onChange?: (value: boolean) => void
  style?: any
}

export const Checkbox = ({ disabled, checked, onChange, style }: Props) => {
  return (
    <div style={style} onClick={onChange && !disabled ? () => onChange(!checked) : undefined}>
      <Checked className={`mdi ${checked ? 'mdi-checkbox-marked' : 'outline'} ${disabled ? 'disabled' : ''}`} />
    </div>
  )
}

export const Checked = styled.i`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.basicBlueColor};
  font-size: 17px;

  &.outline {
    &:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border: solid 1px #e4e4e4;
      border-radius: 2px;
      background: #ffffff;
      transition: border 0.25s;
    }

    &:hover:after {
      border-color: ${theme.colors.basicBlueColor};
    }
  }

  &.disabled {
    cursor: default !important;
    opacity: 0;

    &:before {
      opacity: 0.1 !important;
    }

    &.outline {
      &:hover:after {
        border-color: #e4e4e4;
      }
    }
  }
`
