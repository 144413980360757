import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { showModal, TMsgType } from '../../../UI/Modal/actions'
import Close from '../../../UI/Buttons/Close'
import { Container } from '../../../UI/GridSystem'
import { localStorageService } from '../../../../services/storageService/localStorage/LocalStorage'
import { StyledDropdownContainer } from '../../../UI/Dropdowns/Popover'
import { ITab, TabDTO } from '../../../../store/reducers/tabs/interfaces'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectApplicationTabsByType, selectApplicationTabsCount } from '../../../../store/select/applicationTabSelect'
import {
  createTab,
  deleteAllTabsExcept,
  deleteTabById,
  setActiveTabId
} from '../../../../store/reducers/tabs/functions'
import { getStore } from '../../../../store/configureStore'

type Props = {
  tabType: TabDTO.Type
  hideDropdownAction: () => void
}

export const CollectionDropdown = (props: Props) => {
  const { tabType, hideDropdownAction } = props
  const tabsCount = useAppSelector(selectApplicationTabsCount)
  const tabs = useAppSelector(selectApplicationTabsByType(tabType))

  const recentItemsAction = () => {
    hideDropdownAction()
    createTab(tabType, {
      uiSettings: { filter: null },
      label: 'Recent DDOs',
      refrashOnTabEnter: true,
      request: {
        listRequestType: TabDTO.Request.List.recentDispatchDeliverOrders
      },
      permissions: {
        sort: false
      }
    })
  }

  const addNewTabAction = () => {
    hideDropdownAction()
    createTab(tabType)
  }

  const selectTabAction = (tab: ITab) => () => {
    hideDropdownAction()
    setActiveTabId(tab.id)
  }

  const closeAllTabsBesidesTheFirstTabAction = () => {
    hideDropdownAction()

    getStore().dispatch(
      showModal({
        msgType: TMsgType.delete,
        message: 'Do you really want to remove Tab?',
        onConfirm: () => deleteAllTabsExcept(tabs[0].id),
        onCancel: () => {}
      })
    )
  }

  const deleteTabAction = (tab: ITab) => () => {
    hideDropdownAction()

    if (tabsCount === 1) {
      getStore().dispatch(
        showModal({
          msgType: TMsgType.info,
          message: "Let's not remove this one for now",
          onConfirm: () => {},
          onCancel: () => {}
        })
      )
      return
    }

    getStore().dispatch(
      showModal({
        msgType: TMsgType.delete,
        message:
          'Do you really want to remove tab "' + tab.label.substr(0, 25) + (tab.label.length > 25 ? '...' : '') + '"?',
        onConfirm: () => deleteTabById(tab.id),
        onCancel: () => {}
      })
    )
  }
  let isCreateAvailable = true
  let hasRecentItems = false

  if (tabType === TabDTO.Type.dispatchDeliveryOrder) {
    hasRecentItems = Boolean(oc(localStorageService.getRecentForTabType(tabType))([]).length)
  } else if (tabType === TabDTO.Type.scheduler) {
    isCreateAvailable = !tabs.length
  }

  return (
    <StyledDropdownContainer>
      {hasRecentItems && <div onClick={recentItemsAction}>Recent</div>}
      {isCreateAvailable && <div onClick={addNewTabAction}>New Tab</div>}
      <div className="title">Displayed Tabs</div>
      <ul>
        {tabs.map(tab => {
          return (
            <li key={tab.id}>
              <span className={cn('check', { 'mdi mdi-check': tab.active })} onClick={selectTabAction(tab)} />
              <Container
                className={'textLine'}
                rows={7}
                alignCenter={true}
                flexBasis={'auto'}
                padding={{ right: 15 }}
                onClick={selectTabAction(tab)}
              >
                <span>{tab.label}</span>
              </Container>
              <Close key={tab.id} onClick={deleteTabAction(tab)} />
            </li>
          )
        })}
      </ul>
      <div onClick={closeAllTabsBesidesTheFirstTabAction}>Close All</div>
    </StyledDropdownContainer>
  )
}
