import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const documentAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('DOCUMENT/ASYNC');
import { FetchAPI, FetchArgs, AccountingReportFilter, ApiError, ApplicationFormDTO, ApplicationFormRequestDTO, BalanceDTO, Deduction, DocumentDTO, DocumentMetadataDTO, DocumentValidationDTO, DriverAccountingReportDTO, DriverReportDTO, DriverReportGenerationRequestDTO, FileDTO, FileVersionDTO, FormAddressDTO, FuelTransaction, FuelTransactionDetails, FuelTransactionGroup, Income, InputStream, ModelFile, MvrOrderCompletedEventDTO, OfficialFormDTO, OfficialFormRequestDTO, OfficialFormResponseDTO, PaymentCheckDTO, PreviewApplicationFormRequestDTO, PreviewOfficialFormRequestDTO, PreviewOfficialFormResponseDTO, ReportDetailsDTO, ReportHeader, Resource, Serializable, TollTransaction, TollTransactionDetails, TollTransactionGroup, TruckDTO, URI, URL, DateISOString } from './api';
export interface IAccountingReportGenerateDriverReportUrlUsingPostRequest { reportDTO: DriverAccountingReportDTO, options?: any }
export type IAccountingReportGenerateDriverReportUrlUsingPostSuccess = FileDTO;
export type IAccountingReportGenerateDriverReportUrlUsingPostError = any;
export const accountingReportGenerateDriverReportUrlUsingPostAction = documentAsyncActionCreator.async<
  IAccountingReportGenerateDriverReportUrlUsingPostRequest, IAccountingReportGenerateDriverReportUrlUsingPostSuccess, IAccountingReportGenerateDriverReportUrlUsingPostError
>('ACCOUNTINGREPORT_GENERATEDRIVERREPORTURLUSINGPOST');

export interface IAccountingReportGenerateReportUrlUsingPostRequest { filter: AccountingReportFilter, options?: any }
export type IAccountingReportGenerateReportUrlUsingPostSuccess = FileDTO;
export type IAccountingReportGenerateReportUrlUsingPostError = any;
export const accountingReportGenerateReportUrlUsingPostAction = documentAsyncActionCreator.async<
  IAccountingReportGenerateReportUrlUsingPostRequest, IAccountingReportGenerateReportUrlUsingPostSuccess, IAccountingReportGenerateReportUrlUsingPostError
>('ACCOUNTINGREPORT_GENERATEREPORTURLUSINGPOST');

export interface IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostRequest { request: PreviewApplicationFormRequestDTO, options?: any }
export type IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostSuccess = FileDTO;
export type IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostError = any;
export const applicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostAction = documentAsyncActionCreator.async<
  IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostRequest, IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostSuccess, IApplicationFormCreateAndRenderApplicationFormDocumentPreviewUsingPostError
>('APPLICATIONFORM_CREATEANDRENDERAPPLICATIONFORMDOCUMENTPREVIEWUSINGPOST');

export interface IApplicationFormCreateApplicationFormDocumentUsingPostRequest { request: ApplicationFormRequestDTO, options?: any }
export type IApplicationFormCreateApplicationFormDocumentUsingPostSuccess = string;
export type IApplicationFormCreateApplicationFormDocumentUsingPostError = any;
export const applicationFormCreateApplicationFormDocumentUsingPostAction = documentAsyncActionCreator.async<
  IApplicationFormCreateApplicationFormDocumentUsingPostRequest, IApplicationFormCreateApplicationFormDocumentUsingPostSuccess, IApplicationFormCreateApplicationFormDocumentUsingPostError
>('APPLICATIONFORM_CREATEAPPLICATIONFORMDOCUMENTUSINGPOST');

export interface IApplicationFormDeleteApplicationFormResponsesUsingDeleteRequest { documentType: 'APP_FORM' | 'LEASE_AGREEMENT', userIds: Array<string>, options?: any }
export type IApplicationFormDeleteApplicationFormResponsesUsingDeleteSuccess = Response;
export type IApplicationFormDeleteApplicationFormResponsesUsingDeleteError = any;
export const applicationFormDeleteApplicationFormResponsesUsingDeleteAction = documentAsyncActionCreator.async<
  IApplicationFormDeleteApplicationFormResponsesUsingDeleteRequest, IApplicationFormDeleteApplicationFormResponsesUsingDeleteSuccess, IApplicationFormDeleteApplicationFormResponsesUsingDeleteError
>('APPLICATIONFORM_DELETEAPPLICATIONFORMRESPONSESUSINGDELETE');

export interface IApplicationFormGetAllApplicationFormsUsingGetRequest { lang?: 'EN' | 'ES', options?: any }
export type IApplicationFormGetAllApplicationFormsUsingGetSuccess = ApplicationFormDTO[];
export type IApplicationFormGetAllApplicationFormsUsingGetError = any;
export const applicationFormGetAllApplicationFormsUsingGetAction = documentAsyncActionCreator.async<
  IApplicationFormGetAllApplicationFormsUsingGetRequest, IApplicationFormGetAllApplicationFormsUsingGetSuccess, IApplicationFormGetAllApplicationFormsUsingGetError
>('APPLICATIONFORM_GETALLAPPLICATIONFORMSUSINGGET');

export interface IApplicationFormRenderApplicationFormDocumentUsingPostRequest { options?: any }
export type IApplicationFormRenderApplicationFormDocumentUsingPostSuccess = FileDTO;
export type IApplicationFormRenderApplicationFormDocumentUsingPostError = any;
export const applicationFormRenderApplicationFormDocumentUsingPostAction = documentAsyncActionCreator.async<
  IApplicationFormRenderApplicationFormDocumentUsingPostRequest, IApplicationFormRenderApplicationFormDocumentUsingPostSuccess, IApplicationFormRenderApplicationFormDocumentUsingPostError
>('APPLICATIONFORM_RENDERAPPLICATIONFORMDOCUMENTUSINGPOST');

export interface IDocumentGeneratePodUsingPostRequest { metadata: any, signature: any, businessCard?: any, options?: any }
export type IDocumentGeneratePodUsingPostSuccess = DocumentDTO;
export type IDocumentGeneratePodUsingPostError = any;
export const documentGeneratePodUsingPostAction = documentAsyncActionCreator.async<
  IDocumentGeneratePodUsingPostRequest, IDocumentGeneratePodUsingPostSuccess, IDocumentGeneratePodUsingPostError
>('DOCUMENT_GENERATEPODUSINGPOST');

export interface IDocumentGenerateWorkOrderImageUsingGetRequest { dispatchDeliveryOrderId: string, driverId: string, hash: string, options?: any }
export type IDocumentGenerateWorkOrderImageUsingGetSuccess = string;
export type IDocumentGenerateWorkOrderImageUsingGetError = any;
export const documentGenerateWorkOrderImageUsingGetAction = documentAsyncActionCreator.async<
  IDocumentGenerateWorkOrderImageUsingGetRequest, IDocumentGenerateWorkOrderImageUsingGetSuccess, IDocumentGenerateWorkOrderImageUsingGetError
>('DOCUMENT_GENERATEWORKORDERIMAGEUSINGGET');

export interface IDocumentGenerateWorkOrderUrlUsingGetRequest { dispatchDeliveryOrderId: string, options?: any }
export type IDocumentGenerateWorkOrderUrlUsingGetSuccess = FileDTO;
export type IDocumentGenerateWorkOrderUrlUsingGetError = any;
export const documentGenerateWorkOrderUrlUsingGetAction = documentAsyncActionCreator.async<
  IDocumentGenerateWorkOrderUrlUsingGetRequest, IDocumentGenerateWorkOrderUrlUsingGetSuccess, IDocumentGenerateWorkOrderUrlUsingGetError
>('DOCUMENT_GENERATEWORKORDERURLUSINGGET');

export interface IDocumentGenerateWorkOrderUsingGetRequest { activityId: string, dispatchDeliveryOrderId: string, options?: any }
export type IDocumentGenerateWorkOrderUsingGetSuccess = string;
export type IDocumentGenerateWorkOrderUsingGetError = any;
export const documentGenerateWorkOrderUsingGetAction = documentAsyncActionCreator.async<
  IDocumentGenerateWorkOrderUsingGetRequest, IDocumentGenerateWorkOrderUsingGetSuccess, IDocumentGenerateWorkOrderUsingGetError
>('DOCUMENT_GENERATEWORKORDERUSINGGET');

export interface IDocumentUploadChassisPhotoUsingPostRequest { metadata: any, photo: any, options?: any }
export type IDocumentUploadChassisPhotoUsingPostSuccess = DocumentDTO;
export type IDocumentUploadChassisPhotoUsingPostError = any;
export const documentUploadChassisPhotoUsingPostAction = documentAsyncActionCreator.async<
  IDocumentUploadChassisPhotoUsingPostRequest, IDocumentUploadChassisPhotoUsingPostSuccess, IDocumentUploadChassisPhotoUsingPostError
>('DOCUMENT_UPLOADCHASSISPHOTOUSINGPOST');

export interface IDocumentUploadContainerPhotoUsingPostRequest { metadata: any, photo: any, options?: any }
export type IDocumentUploadContainerPhotoUsingPostSuccess = DocumentDTO;
export type IDocumentUploadContainerPhotoUsingPostError = any;
export const documentUploadContainerPhotoUsingPostAction = documentAsyncActionCreator.async<
  IDocumentUploadContainerPhotoUsingPostRequest, IDocumentUploadContainerPhotoUsingPostSuccess, IDocumentUploadContainerPhotoUsingPostError
>('DOCUMENT_UPLOADCONTAINERPHOTOUSINGPOST');

export interface IDocumentUploadPodUsingPostRequest { document: any, metadata: any, options?: any }
export type IDocumentUploadPodUsingPostSuccess = DocumentDTO;
export type IDocumentUploadPodUsingPostError = any;
export const documentUploadPodUsingPostAction = documentAsyncActionCreator.async<
  IDocumentUploadPodUsingPostRequest, IDocumentUploadPodUsingPostSuccess, IDocumentUploadPodUsingPostError
>('DOCUMENT_UPLOADPODUSINGPOST');

export interface IDocumentUploadTirUsingPostRequest { document: any, metadata: any, options?: any }
export type IDocumentUploadTirUsingPostSuccess = DocumentDTO;
export type IDocumentUploadTirUsingPostError = any;
export const documentUploadTirUsingPostAction = documentAsyncActionCreator.async<
  IDocumentUploadTirUsingPostRequest, IDocumentUploadTirUsingPostSuccess, IDocumentUploadTirUsingPostError
>('DOCUMENT_UPLOADTIRUSINGPOST');

export interface IDriverReportGenerateReportUsingPostRequest { driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO, userId: string, options?: any }
export type IDriverReportGenerateReportUsingPostSuccess = DriverReportDTO;
export type IDriverReportGenerateReportUsingPostError = any;
export const driverReportGenerateReportUsingPostAction = documentAsyncActionCreator.async<
  IDriverReportGenerateReportUsingPostRequest, IDriverReportGenerateReportUsingPostSuccess, IDriverReportGenerateReportUsingPostError
>('DRIVERREPORT_GENERATEREPORTUSINGPOST');

export interface IDriverReportGetReportHistoryUsingGetRequest { type: 'PSP' | 'MVR', userId: string, options?: any }
export type IDriverReportGetReportHistoryUsingGetSuccess = DriverReportDTO[];
export type IDriverReportGetReportHistoryUsingGetError = any;
export const driverReportGetReportHistoryUsingGetAction = documentAsyncActionCreator.async<
  IDriverReportGetReportHistoryUsingGetRequest, IDriverReportGetReportHistoryUsingGetSuccess, IDriverReportGetReportHistoryUsingGetError
>('DRIVERREPORT_GETREPORTHISTORYUSINGGET');

export interface IDriverReportGetReportUsingGetRequest { type: 'PSP' | 'MVR', userId: string, options?: any }
export type IDriverReportGetReportUsingGetSuccess = DriverReportDTO;
export type IDriverReportGetReportUsingGetError = any;
export const driverReportGetReportUsingGetAction = documentAsyncActionCreator.async<
  IDriverReportGetReportUsingGetRequest, IDriverReportGetReportUsingGetSuccess, IDriverReportGetReportUsingGetError
>('DRIVERREPORT_GETREPORTUSINGGET');

export interface IDriverReportOnMvrOrderCompletedUsingPostRequest { authorization: string, mvrOrderCompletedEventDTO: MvrOrderCompletedEventDTO, options?: any }
export type IDriverReportOnMvrOrderCompletedUsingPostSuccess = Response;
export type IDriverReportOnMvrOrderCompletedUsingPostError = any;
export const driverReportOnMvrOrderCompletedUsingPostAction = documentAsyncActionCreator.async<
  IDriverReportOnMvrOrderCompletedUsingPostRequest, IDriverReportOnMvrOrderCompletedUsingPostSuccess, IDriverReportOnMvrOrderCompletedUsingPostError
>('DRIVERREPORT_ONMVRORDERCOMPLETEDUSINGPOST');

export interface IDriverReportTestIpUsingGetRequest { testUrl: string, options?: any }
export type IDriverReportTestIpUsingGetSuccess = DriverReportDTO;
export type IDriverReportTestIpUsingGetError = any;
export const driverReportTestIpUsingGetAction = documentAsyncActionCreator.async<
  IDriverReportTestIpUsingGetRequest, IDriverReportTestIpUsingGetSuccess, IDriverReportTestIpUsingGetError
>('DRIVERREPORT_TESTIPUSINGGET');

export interface IFileDownloadFileUsingGetRequest { hash: string, options?: any }
export type IFileDownloadFileUsingGetSuccess = string;
export type IFileDownloadFileUsingGetError = any;
export const fileDownloadFileUsingGetAction = documentAsyncActionCreator.async<
  IFileDownloadFileUsingGetRequest, IFileDownloadFileUsingGetSuccess, IFileDownloadFileUsingGetError
>('FILE_DOWNLOADFILEUSINGGET');

export interface IFileGetFileHistoryUsingGetRequest { entityId: string, type: 'VENDOR_LICENSE' | 'VENDOR_LICENSE_BACK' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE' | 'MACHINERY_MAINTENANCE_INSPECTION' | 'MACHINERY_REPAIR', options?: any }
export type IFileGetFileHistoryUsingGetSuccess = FileVersionDTO[];
export type IFileGetFileHistoryUsingGetError = any;
export const fileGetFileHistoryUsingGetAction = documentAsyncActionCreator.async<
  IFileGetFileHistoryUsingGetRequest, IFileGetFileHistoryUsingGetSuccess, IFileGetFileHistoryUsingGetError
>('FILE_GETFILEHISTORYUSINGGET');

export interface IFileMergeFilesUsingPostRequest { fileUrls: Array<string>, options?: any }
export type IFileMergeFilesUsingPostSuccess = FileDTO;
export type IFileMergeFilesUsingPostError = any;
export const fileMergeFilesUsingPostAction = documentAsyncActionCreator.async<
  IFileMergeFilesUsingPostRequest, IFileMergeFilesUsingPostSuccess, IFileMergeFilesUsingPostError
>('FILE_MERGEFILESUSINGPOST');

export interface IFileUploadFileUsingPostRequest { file: any, options?: any }
export type IFileUploadFileUsingPostSuccess = FileDTO;
export type IFileUploadFileUsingPostError = any;
export const fileUploadFileUsingPostAction = documentAsyncActionCreator.async<
  IFileUploadFileUsingPostRequest, IFileUploadFileUsingPostSuccess, IFileUploadFileUsingPostError
>('FILE_UPLOADFILEUSINGPOST');

export interface IIndexGetUsingGetRequest { options?: any }
export type IIndexGetUsingGetSuccess = string;
export type IIndexGetUsingGetError = any;
export const indexGetUsingGetAction = documentAsyncActionCreator.async<
  IIndexGetUsingGetRequest, IIndexGetUsingGetSuccess, IIndexGetUsingGetError
>('INDEX_GETUSINGGET');

export interface IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostRequest { request: PreviewOfficialFormRequestDTO, options?: any }
export type IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostSuccess = OfficialFormResponseDTO;
export type IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostError = any;
export const officialFormCreateAndGetOfficialFormDocumentPreviewUsingPostAction = documentAsyncActionCreator.async<
  IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostRequest, IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostSuccess, IOfficialFormCreateAndGetOfficialFormDocumentPreviewUsingPostError
>('OFFICIALFORM_CREATEANDGETOFFICIALFORMDOCUMENTPREVIEWUSINGPOST');

export interface IOfficialFormCreateOfficialFormDocumentUsingPostRequest { request: OfficialFormRequestDTO, options?: any }
export type IOfficialFormCreateOfficialFormDocumentUsingPostSuccess = OfficialFormResponseDTO;
export type IOfficialFormCreateOfficialFormDocumentUsingPostError = any;
export const officialFormCreateOfficialFormDocumentUsingPostAction = documentAsyncActionCreator.async<
  IOfficialFormCreateOfficialFormDocumentUsingPostRequest, IOfficialFormCreateOfficialFormDocumentUsingPostSuccess, IOfficialFormCreateOfficialFormDocumentUsingPostError
>('OFFICIALFORM_CREATEOFFICIALFORMDOCUMENTUSINGPOST');

export interface IOfficialFormGetAllOfficialFormsUsingGetRequest { lang?: 'EN' | 'ES', options?: any }
export type IOfficialFormGetAllOfficialFormsUsingGetSuccess = OfficialFormDTO[];
export type IOfficialFormGetAllOfficialFormsUsingGetError = any;
export const officialFormGetAllOfficialFormsUsingGetAction = documentAsyncActionCreator.async<
  IOfficialFormGetAllOfficialFormsUsingGetRequest, IOfficialFormGetAllOfficialFormsUsingGetSuccess, IOfficialFormGetAllOfficialFormsUsingGetError
>('OFFICIALFORM_GETALLOFFICIALFORMSUSINGGET');

export interface IOfficialFormGetOfficialFormDocumentUsingGetRequest { id: string, options?: any }
export type IOfficialFormGetOfficialFormDocumentUsingGetSuccess = OfficialFormResponseDTO;
export type IOfficialFormGetOfficialFormDocumentUsingGetError = any;
export const officialFormGetOfficialFormDocumentUsingGetAction = documentAsyncActionCreator.async<
  IOfficialFormGetOfficialFormDocumentUsingGetRequest, IOfficialFormGetOfficialFormDocumentUsingGetSuccess, IOfficialFormGetOfficialFormDocumentUsingGetError
>('OFFICIALFORM_GETOFFICIALFORMDOCUMENTUSINGGET');

export interface IUserDriverReportGenerateUserReportUsingPostRequest { driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO, options?: any }
export type IUserDriverReportGenerateUserReportUsingPostSuccess = DriverReportDTO;
export type IUserDriverReportGenerateUserReportUsingPostError = any;
export const userDriverReportGenerateUserReportUsingPostAction = documentAsyncActionCreator.async<
  IUserDriverReportGenerateUserReportUsingPostRequest, IUserDriverReportGenerateUserReportUsingPostSuccess, IUserDriverReportGenerateUserReportUsingPostError
>('USERDRIVERREPORT_GENERATEUSERREPORTUSINGPOST');

export interface IUserDriverReportGetUserReportUsingGetRequest { type: 'PSP' | 'MVR', options?: any }
export type IUserDriverReportGetUserReportUsingGetSuccess = DriverReportDTO;
export type IUserDriverReportGetUserReportUsingGetError = any;
export const userDriverReportGetUserReportUsingGetAction = documentAsyncActionCreator.async<
  IUserDriverReportGetUserReportUsingGetRequest, IUserDriverReportGetUserReportUsingGetSuccess, IUserDriverReportGetUserReportUsingGetError
>('USERDRIVERREPORT_GETUSERREPORTUSINGGET');


