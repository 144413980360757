import * as HTTPStatusCode from 'http-status-codes'
import { defer, empty, Observable, throwError, concat, of, from } from 'rxjs'
import { ajax, AjaxResponse } from 'rxjs/ajax'
import { map, retryWhen, exhaustMap, catchError, mergeMap } from 'rxjs/operators'
import { AUTH_API_BASE_PATH } from './authApi'
import { getDefaultHeaders } from './defaultHeaders'
import {
  CustomerControllerApi,
  BusinessPartnerControllerApi,
  ContactControllerApi,
  SteamShipLineControllerApi,
  SubClientControllerApi,
  ContainerControllerApi,
  ContainerTypeControllerApi,
  LocationControllerApi,
  DispatchDeliveryOrderControllerApi,
  DeliveryOrderControllerApi,
  CargoControllerApi,
  TerminalMismatchControllerApi,
  DriverControllerApi,
  EquipmentControllerApi,
  HazmatControllerApi,
  PowerUnitControllerApi,
  SellSideQuoteControllerApi,
  BuySideQuoteControllerApi,
  StateControllerApi,
  AuditTrailControllerApi,
  TerminalNoteControllerApi,
  DispatchDeliveryOrderStreetTurnControllerApi,
  NonPlannedActivityControllerApi,
  ActivitySchedulerControllerApi,
  TroubleTicketControllerApi,
  TransportationActivityControllerApi,
  DashboardControllerApi
} from './origin/business-logic/'
import {
  BuySideQuoteRateControllerApi,
  CustomerQuoteControllerApi,
  SellSideQuoteRateControllerApi,
  RuleControllerApi
} from './origin/qmp-service'
import { ConfigurationParameters } from './origin/business-logic/configuration'
import { ConfigurationParameters as ConfigurationParametersDocument } from './origin/document-service/configuration'
import { ConfigurationParameters as ConfigurationParametersQMP } from './origin/qmp-service/configuration'
// tslint:disable:max-line-length
import { ConfigurationParameters as ConfigurationParametersCHub } from './origin/communication-hub-service/configuration'
import { ConfigurationParameters as ConfigurationParametersUser } from './origin/user-service/configuration'
import {
  getItemFromStorage,
  StorageKeys,
  saveItemToStorage,
  removeItemFromStorage
} from '../services/storageService/storageService'
import { NOTHING } from '../services/rxjsService/actions'
import { BUSINESS_LOGIC_API_BASE_PATH } from './businessLogicApi'
import { QMP_SERVICE_API_BASE_PATH } from './qmp'
import { DOCUMENT_SERVICE_API_BASE_PATH } from './document-service'
import { AuthConfig, authRedirect, AUTHORIZATION_HEADERS, AuthorizationGrantTypes } from '../services/authService'
export * from './origin/business-logic'
import * as Sentry from '@sentry/browser'
import { getStore } from '../store/configureStore'
import { convertErrorToMessage } from '../services/DTO/saveDTO'
import {
  AccountingReportControllerApi,
  ApplicationFormControllerApi,
  DocumentControllerApi,
  DriverReportControllerApi,
  FileControllerApi,
  OfficialFormControllerApi
} from './origin/document-service'
import {
  AlertControllerApi,
  ChannelControllerApi,
  CounterControllerApi,
  MessageControllerApi,
  DefaultApi as AppControllerApi,
  WorkOrderControllerApi,
  NotificationControllerApi
} from './origin/communication-hub-service'
import { UserControllerApi } from './origin/user-service'
import { authGetTokens } from '../services/authService/actions/authServiceActions'
import { COMMUNICATION_HUB_API_BASE_PATH } from './communication-hub'
import { USER_SERVICE_API_BASE_PATH } from './user-service'
import { VENDOR_ACCOUNTING_SERVICE_BASE_PATH } from './vendorAccounting'
import {
  VendorControllerApi,
  DeductionTransactionControllerApi,
  DeductionControllerApi
} from './origin/vendor-accounting-service'
import { showErrorModalWindow } from '../store/reducers/modalWindow/functions'
import { oc } from 'ts-optchain'
// import { toast } from 'react-toastify'
// import * as React from 'react'
// import { oc } from 'ts-optchain'

const configuration: ConfigurationParameters = {
  getDefaultHeaders,
  basePath: BUSINESS_LOGIC_API_BASE_PATH
}

const QMPConfiguration: ConfigurationParametersQMP = {
  getDefaultHeaders,
  basePath: QMP_SERVICE_API_BASE_PATH
}

const documentConfiguration: ConfigurationParametersDocument = {
  getDefaultHeaders,
  basePath: DOCUMENT_SERVICE_API_BASE_PATH
}

const communicationHubConfiguration: ConfigurationParametersCHub = {
  getDefaultHeaders,
  basePath: COMMUNICATION_HUB_API_BASE_PATH
}

const vendorAccountingConfiguration: ConfigurationParametersCHub = {
  getDefaultHeaders,
  basePath: VENDOR_ACCOUNTING_SERVICE_BASE_PATH
}

const userConfiguration: ConfigurationParametersUser = {
  getDefaultHeaders,
  basePath: USER_SERVICE_API_BASE_PATH
}

const makeAPI = <T, C = ConfigurationParameters>(Controller: new (configuration: C) => T, config: C): T => {
  const controller = new Controller(config)
  for (const prop in controller) {
    if (!controller.hasOwnProperty(prop) && prop !== 'constructor') {
      controller[prop] = (controller[prop] as any).bind(controller)
    }
  }
  return controller
}

// >> Business Logic Service
export const powerUnitAPI = makeAPI(PowerUnitControllerApi, configuration)
export const customerAPI = makeAPI(CustomerControllerApi, configuration)
export const steamShipLineAPI = makeAPI(SteamShipLineControllerApi, configuration)
export const businessPartnerAPI = makeAPI(BusinessPartnerControllerApi, configuration)
export const contactAPI = makeAPI(ContactControllerApi, configuration)
export const subClientAPI = makeAPI(SubClientControllerApi, configuration)
export const stateAPI = makeAPI(StateControllerApi, configuration)
export const containerAPI = makeAPI(ContainerControllerApi, configuration)
export const containerTypeAPI = makeAPI(ContainerTypeControllerApi, configuration)
export const locationAPI = makeAPI(LocationControllerApi, configuration)
export const dispatchDeliveryOrderAPI = makeAPI(DispatchDeliveryOrderControllerApi, configuration)
export const deliveryOrderAPI = makeAPI(DeliveryOrderControllerApi, configuration)
export const driverAPI = makeAPI(DriverControllerApi, configuration)
export const cargoAPI = makeAPI(CargoControllerApi, configuration)
export const terminalMismatchAPI = makeAPI(TerminalMismatchControllerApi, configuration)
export const transportationActivityAPI = makeAPI(TransportationActivityControllerApi, configuration)
export const equipmentAPI = makeAPI(EquipmentControllerApi, configuration)
export const hazmatAPI = makeAPI(HazmatControllerApi, configuration)
export const AuditTrailAPI = makeAPI(AuditTrailControllerApi, configuration)
export const sellSideQuoteAPI = makeAPI(SellSideQuoteControllerApi, configuration)
export const buySideQuoteAPI = makeAPI(BuySideQuoteControllerApi, configuration)
export const terminalNotesAPI = makeAPI(TerminalNoteControllerApi, configuration)
export const streetTurnAPI = makeAPI(DispatchDeliveryOrderStreetTurnControllerApi, configuration)
export const schedulerAPI = makeAPI(ActivitySchedulerControllerApi, configuration)
export const nonPlannedActivityAPI = makeAPI(NonPlannedActivityControllerApi, configuration)
export const troubleTicketDocumentAPI = makeAPI(TroubleTicketControllerApi, configuration)
export const dashboardAPI = makeAPI(DashboardControllerApi, configuration)
// <<

// QMP Service
export const sellSideQuoteRateAPI = makeAPI(SellSideQuoteRateControllerApi, QMPConfiguration)
export const buySideQuoteRateAPI = makeAPI(BuySideQuoteRateControllerApi, QMPConfiguration)
export const customerQuoteAPI = makeAPI(CustomerQuoteControllerApi, QMPConfiguration)
export const ruleAPI = makeAPI(RuleControllerApi, QMPConfiguration)
// <<

// >> Document Service
export const accountingReportAPI = makeAPI(AccountingReportControllerApi, documentConfiguration)
export const driverReportAPI = makeAPI(DriverReportControllerApi, documentConfiguration)
export const fileAPI = makeAPI(FileControllerApi, documentConfiguration)
export const applicationFormAPI = makeAPI(ApplicationFormControllerApi, documentConfiguration)
export const officialFormAPI = makeAPI(OfficialFormControllerApi, documentConfiguration)

// <<

// >> CHUB Service
export const communicationHubAlertAPI = makeAPI(AlertControllerApi, communicationHubConfiguration)
export const communicationHubChannelAPI = makeAPI(ChannelControllerApi, communicationHubConfiguration)
export const communicationHubMessageAPI = makeAPI(MessageControllerApi, communicationHubConfiguration)
export const communicationHubAppAPI = makeAPI(AppControllerApi, communicationHubConfiguration)
export const communicationHubCounterAPI = makeAPI(CounterControllerApi, communicationHubConfiguration)
export const communicationHubWorkOrderAPI = makeAPI(WorkOrderControllerApi, communicationHubConfiguration)

export const notificationAPI = makeAPI(NotificationControllerApi, communicationHubConfiguration)

export const userAPI = makeAPI(UserControllerApi, userConfiguration)
// <<

// >> VENDOR ACCOUNTING Service
export const vendorAccountingAPI = makeAPI(VendorControllerApi, vendorAccountingConfiguration)
export const deductionAPI = makeAPI(DeductionControllerApi, vendorAccountingConfiguration)
export const deductionTransactionControllerAPI = makeAPI(
  DeductionTransactionControllerApi,
  vendorAccountingConfiguration
)
// <<

export function callAPI<R>(makeRequest: () => Promise<R>): Observable<R>
export function callAPI<R, T1>(makeRequest: (t1: T1) => Promise<R>, t1: T1): Observable<R>
export function callAPI<R, T1, T2>(makeRequest: (t1: T1, t2: T2) => Promise<R>, t1: T1, t2: T2): Observable<R>
export function callAPI<R, T1, T2, T3>(
  makeRequest: (t1: T1, t2: T2, t3: T3) => Promise<R>,
  t1: T1,
  t2: T2,
  t3: T3
): Observable<R>
export function callAPI<R, T1, T2, T3, T4>(
  makeRequest: (t1: T1, t2: T2, t3: T3, t4: T4) => Promise<R>,
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4
): Observable<R>
export function callAPI<R, T1, T2, T3, T4, T5>(
  makeRequest: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5) => Promise<R>,
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  t5: T5
): Observable<R>
export function callAPI<R, T1, T2, T3, T4, T5, T6>(
  makeRequest: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5, t6: T6) => Promise<R>,
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  t5: T5,
  t6: T6
): Observable<R>

export function callAPI(makeRequest: (...args: any[]) => Promise<any>, ...args: any[]): Observable<any> {
  return defer(() => makeRequest(...args)).pipe(
    retryWhen(res$ =>
      res$.pipe(
        exhaustMap((res: Response) => {
          if (res.status === HTTPStatusCode.UNAUTHORIZED) {
            return ajax({
              url: `${AUTH_API_BASE_PATH}/realms/${AuthConfig.audience}/protocol/openid-connect/token`,
              body: {
                grant_type: AuthorizationGrantTypes.RefreshToken,
                client_id: AuthConfig.client_id,
                refresh_token: getItemFromStorage(StorageKeys.RefreshToken)
              },
              method: 'POST',
              headers: AUTHORIZATION_HEADERS
            }).pipe(
              map(({ response }: AjaxResponse) => {
                if (!response) {
                  return throwError(res)
                }
                const { refresh_token, access_token, id_token } = response
                if (refresh_token && access_token && id_token) {
                  saveItemToStorage(StorageKeys.RefreshToken, refresh_token)
                  saveItemToStorage(StorageKeys.AccessToken, access_token)
                  saveItemToStorage(StorageKeys.IdToken, id_token)
                  getStore().dispatch(authGetTokens.done({}))
                  return empty()
                }
                return throwError(res)
              }),
              catchError(() => {
                removeItemFromStorage(StorageKeys.RefreshToken)
                removeItemFromStorage(StorageKeys.AccessToken)
                removeItemFromStorage(StorageKeys.IdToken)
                authRedirect()
                return empty()
              })
            )
          } else {
            Sentry.captureException(res)
            return throwError(res)
          }
        })
      )
    )
  )
}

export const callAPIWithErrorMessage = async (
  makeRequest: (...args: any[]) => Promise<any>,
  ...args: any[]
): Promise<any> =>
  // @ts-ignore
  callAPI(makeRequest, ...args)
    .toPromise()
    .catch(catchErrorMessages)

export const catchErrorMessages = async (errors: any) => {
  const pushError = (errorData: any) => showErrorModalWindow({ content: convertErrorToMessage(errorData) })
  const defaultError = 'Undefined Error'
  const notFoundError = 'Not Found'

  try {
    const result = (errorsData: any) => {
      pushError(errorsData)
      return Promise.reject(errors)
    }

    if (errors && errors.json) {
      if (errors.status === 404) {
        return result(notFoundError)
      }

      const errorsData = await errors.json()
      return result(errorsData)
    }

    return result(errors)
  } catch (e) {
    pushError(defaultError)
    return Promise.reject(errors)
  }
}

export const axiosCallAPIWithErrorMessage = async (
  makeRequest: (...args: any[]) => Promise<any>,
  ...args: any[]
): Promise<any> =>
  // @ts-ignore
  callAPI(makeRequest, ...args)
    .toPromise()
    .catch((errors: any) => {
      const pushError = (errorData: any) => {
        showErrorModalWindow({ content: convertErrorToMessage(errorData) })
      }

      const result = (errorsData: any) => {
        if (Array.isArray(errorsData)) {
          errorsData.map(pushError)
        } else {
          pushError(errorsData)
        }

        return Promise.reject(errors)
      }

      return errors && errors.data ? result(errors.data) : result(errors)
    })

export const request = <T>(item$: Observable<T>) => {
  return concat(
    item$.pipe(
      catchError((response: Response) => {
        if (!response.status) {
          showErrorModalWindow({ content: `${response}` })
          return of(NOTHING())
        }
        switch (response.status) {
          case HTTPStatusCode.NOT_FOUND:
            return of(NOTHING())

          default:
            return concat(
              from(response.clone().json()).pipe(
                mergeMap((errors: any) => {
                  if (!Array.isArray(errors)) {
                    Sentry.captureException(errors)
                    showErrorModalWindow({ content: `${errors.message}` })
                    return of(NOTHING())
                  }
                  return from(errors)
                    .pipe(
                      map(error => {
                        Sentry.captureException(error)
                        showErrorModalWindow({ content: `${error.field}: ${error.message}` })
                        return of(NOTHING())
                      })
                    )
                    .pipe(mergeMap(contacts => from(contacts)))
                })
              )
            )
        }
      })
    )
  )
}

export enum ApiMethod {
  post = 'POST',
  get = 'GET',
  delete = 'DELETE',
  put = 'PUT',
  patch = 'PATCH'
}

export const callExternalApi = (url: string, method: ApiMethod, body?: any) =>
  callAPI(() =>
    fetch(url, {
      method,
      headers: {
        ...getDefaultHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw response
      }
    })
  )
