import * as React from 'react'
import styled from 'styled-components'
import { CommunicationHubTab } from '../../../../store/reducers/communicationHub/interfaces'
import { NavigationHeader } from './NavigationHeader'
import { CommunicationHubConstants } from '../../../../constants/CommunicationHubConstants'
import { NavigationMessenger } from './NavigationMessenger'
import { NavigationRouteBuilder } from './NavigationRouteBuilder'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubTab } from '../../../../store/select/communicationHubSelect'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Navigation = (props: Props) => {
  const activeTab = useAppSelector(selectCommunictaionHubTab)

  return (
    <Container key={activeTab}>
      <NavigationHeader />
      <NavigationBody>
        {activeTab === CommunicationHubTab.messenger && <NavigationMessenger />}
        {activeTab === CommunicationHubTab.routeBuilder && <NavigationRouteBuilder />}
      </NavigationBody>
    </Container>
  )
}

const Container = styled.div`
  width: ${CommunicationHubConstants.navigationViewWidth}px;
  display: flex;
  flex-direction: column;
  background-color: inherit;
  cursor: default;
  user-select: none;

  *::-webkit-scrollbar-track {
    background-color: rgba(245, 246, 250, 0);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(245, 246, 250);
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgb(245, 246, 250);
  }
`

const NavigationBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: inherit;
  padding-left: 10px;
  overflow: auto;
`
export const NavigationChannelsSectionBreakLine = styled.div`
  flex-shrink: 0;
  height: 1px;
  background-color: #445366;
  margin: 6px 0;
`
