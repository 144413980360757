import { LocalStorageRecent } from './LocalStorageRecent'
import { LocalStorageOptions } from './types'

class LocalStorage extends LocalStorageRecent {}

export let localStorageService = new LocalStorage({ user: undefined })

export const unitLocalStorageService = (options: LocalStorageOptions) => {
  localStorageService = new LocalStorage(options)
}
