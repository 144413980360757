import { oc } from 'ts-optchain'
import { TransportationActivityViewDTO } from '../../../api/api'
import { IMoveDTO } from '../../../store/reducers/communicationHub/interfaces'
import { isBobtailGotoActivity } from './isBobtailGotoActivity'

type Props = {
  move: IMoveDTO
  transportationActivityMapping: Record<string, TransportationActivityViewDTO>
  requiredCombotype?: TransportationActivityViewDTO.ComboTypeEnum
}

export const isLastMoveItemBobtail = (props: Props): boolean => {
  const { move, transportationActivityMapping, requiredCombotype } = props

  if (!(move && transportationActivityMapping && move.items)) {
    return false
  }

  const lastItem = move.items[move.items.length - 1]

  return Boolean(
    lastItem.gotoActivityId &&
      transportationActivityMapping[lastItem.gotoActivityId] &&
      isBobtailGotoActivity(transportationActivityMapping[lastItem.gotoActivityId]) &&
      (!requiredCombotype ||
        oc(transportationActivityMapping[lastItem.gotoActivityId]).comboType() === requiredCombotype)
  )
}
