import * as React from 'react'
import styled from 'styled-components'
import { dateService } from '../../../../services/timeService'
import theme from '../../../../styles/theme'
import { Icon, IconName } from '../Icon'
import { fadeOutElement } from '../../../../services/functions/fadeOutElement'
import { RouteBuilderIcons } from '../../../UI/icons/RouteBuilderIcons'
import { openDDOinNewTab } from '../../../../services/DTO/dispatchDeliveryOrder'
import { CheckmarkCircleIcon } from '../../../UI/icons/CheckmarkIcon'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { ErrorIcon } from '../../../UI/icons/ErrorIcon'
import { NotificationIcon } from '../../../UI/icons/NotificationIcon'

export enum RouteBuilderToastMessageType {
  setMoveDay = 'route-builder-message-type-setMoveDay',
  notification = 'route-builder-message-type-notification',
  error = 'route-builder-message-type-error',
  connectMovesError = 'route-builder-message-type-connect-moves-error'
}

const iconMapping: Record<RouteBuilderToastMessageType, JSX.Element> = {
  [RouteBuilderToastMessageType.setMoveDay]: <CheckmarkCircleIcon />,
  [RouteBuilderToastMessageType.connectMovesError]: RouteBuilderIcons.shield,
  [RouteBuilderToastMessageType.error]: <ErrorIcon />,
  [RouteBuilderToastMessageType.notification]: <NotificationIcon />
}

type Props<T extends RouteBuilderToastMessageType> = {
  type: RouteBuilderToastMessageType
  meta: {
    message?: string
    connectMovesErrorData?: {
      date: string
      ddoId: string
      ddoNumber: number
    }
  }
  noTimer?: boolean
  removeToastMessageAction?: () => void
}

export const RouteBuilderToastMessage = React.memo((props: Props<RouteBuilderToastMessageType>) => {
  const { type, removeToastMessageAction, meta, noTimer } = props
  const dispatch = useAppDispatch()
  const containerRef = React.useRef(null)

  React.useEffect(() => {
    let closeToastMessageTimer: any

    if (removeToastMessageAction && !noTimer) {
      closeToastMessageTimer = setTimeout(() => {
        fadeOutElement(containerRef, removeToastMessageAction)()
      }, 7000)
    }

    return () => {
      if (closeToastMessageTimer) {
        clearTimeout(closeToastMessageTimer)
      }
    }
  }, [])

  const RenderContent = () => {
    switch (type) {
      case RouteBuilderToastMessageType.setMoveDay:
        return (
          <Text>
            The move was successfully sent to
            <TextLink
              style={{ marginLeft: 5 }}
              onClick={() => dispatch(communicationHubActions.setActiveRouteBuilderTabDate(meta.message))}
            >
              {dateService.makeLabel(meta.message, { long: true, fullYear: true })}
            </TextLink>
          </Text>
        )
      case RouteBuilderToastMessageType.connectMovesError:
        return (
          meta.connectMovesErrorData && (
            <Text>
              Driver has Not Completed move on
              {meta.connectMovesErrorData.date && (
                <TextLink
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    dispatch(communicationHubActions.setActiveRouteBuilderTabDate(meta.connectMovesErrorData.date))
                  }
                >
                  {dateService.makeLabel(meta.connectMovesErrorData.date, { long: true, fullYear: true })}
                </TextLink>
              )}
              {meta.connectMovesErrorData.ddoNumber && (
                <TextLinkBold
                  style={{ marginLeft: 20 }}
                  onClick={() =>
                    openDDOinNewTab({
                      ddoId: meta.connectMovesErrorData.ddoId,
                      ddoNumber: meta.connectMovesErrorData.ddoNumber
                    })
                  }
                >
                  DDO {meta.connectMovesErrorData.ddoNumber}
                </TextLinkBold>
              )}
            </Text>
          )
        )
      case RouteBuilderToastMessageType.error:
      case RouteBuilderToastMessageType.notification:
        return <Text>{meta.message}</Text>
      default:
        return null
    }
  }

  return (
    <Container className={type} ref={containerRef}>
      {iconMapping[type]}
      <RenderContent />
      {removeToastMessageAction ? (
        <Icon icon={IconName.CLOSE} onClick={fadeOutElement(containerRef, removeToastMessageAction)} />
      ) : (
        <span />
      )}
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 24px 1fr 24px;
  border: 1px dashed #98a4b4;
  border-radius: 8px;
  background-color: white;
  padding: 25px;

  &.${RouteBuilderToastMessageType.setMoveDay} {
    z-index: 2;
    background-color: #f0fbf2;
    position: sticky;
    top: 0;
  }

  &.${RouteBuilderToastMessageType.error} {
    background-color: ${theme.colors.defaultRed}10;
  }

  &.${RouteBuilderToastMessageType.connectMovesError} {
    background-color: #fcf5ee;
  }

  &.${RouteBuilderToastMessageType.notification} {
    background-color: #fbf4ef;
  }
`

const Text = styled.div`
  color: #323e4e;
  line-height: 20px;
  font-size: 12px;
`
const TextLink = styled.span`
  text-decoration: underline;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;
  user-select: none;
`
const TextLinkBold = styled.span`
  color: ${theme.colors.basicBlueColor};
  font-weight: 500;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`
