export enum ContainerColumn {
  ContainerNumber = 'Container #',
  ActiveAvailability = 'Active / Availability',
  Type = 'Type',
  MaxCargoWeight = 'Max Cargo Weight',
  Ownership = 'Ownership',
  SSL = 'SSL'
}

export const containerColumnSettings = {
  [ContainerColumn.ContainerNumber]: { width: 160, active: true },
  [ContainerColumn.ActiveAvailability]: { width: 260, active: true },
  [ContainerColumn.Type]: { width: 180, active: true },
  [ContainerColumn.MaxCargoWeight]: { width: 160, active: true },
  [ContainerColumn.Ownership]: { width: 160, active: true },
  [ContainerColumn.SSL]: { width: 160, active: true }
}
