import * as React from 'react'
import { oc } from 'ts-optchain'
import { Preview } from './preview'
import Columns from './columns'
import { isNewObject } from '../../../services/DTO'
import { actionOnUnitExpand, getFullUnit } from './functions'
import { debuggingMode } from '../../../services/debug'
import { localStorageService } from '../../../services/storageService/localStorage/LocalStorage'
import { ApplicationSizeProvider } from '../../../providers/ApplicationSizeProvider'
import { useGrid } from '../../../hooks/useGrid'
import { GridItemProviderMapping } from '../../../providers'
import { TGridColumns, TabDTO } from '../../../store/reducers/tabs/interfaces'
import { useExpandedItem } from '../../../hooks/useExpandedItem'
import { DispatchDeliveryOrderViewDTO } from '../../../api/api'
import { isFullObject } from '../../../services/functions/test/isFullObject'

type OwnProps = {
  tabId: string
  tabType: TabDTO.Type
  columnsSettings: TGridColumns
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabDTO.Actions
}

type StateProps = {}

type DispatchProps = {}

export type TGridItemProps = StateProps & OwnProps & DispatchProps

export const ExpandedGridItem = React.memo((props: TGridItemProps) => {
  const { tabType, columnsSettings, isSelected, isDisabledSelect, isPinned, actionType } = props
  const {
    parentItemId,
    parentItem,
    modifiedLists,
    isModified,
    isFetching,
    clearExpandedItem,
    setFetching
  } = useExpandedItem()
  const { detailsRequestType } = useGrid()
  // @ts-ignore
  const Provider = GridItemProviderMapping[tabType]
  const isNew = isNewObject(parentItem)

  const togglePreview = () => {
    if (!isModified) {
      clearExpandedItem()
    }
  }

  React.useEffect(() => {
    if (!parentItem) {
      return
    }

    if (parentItemId) {
      localStorageService.pushRecentForTabType(tabType, parentItemId)
    }

    if (!isFetching && !isFullObject(parentItem)) {
      setFetching(true)
      getFullUnit(detailsRequestType || tabType, parentItemId).finally(() => {
        setFetching(false)
      })
    }
  }, [parentItemId])

  React.useEffect(() => {
    if (!parentItem) {
      return
    }

    if (!isNew && isFullObject(parentItem)) {
      actionOnUnitExpand(detailsRequestType || tabType, parentItem)
    }
  }, [isFullObject(parentItem)])

  if (!(parentItem && Provider)) {
    return null
  }

  if (debuggingMode.expandedGridItem) {
    // tslint:disable-next-line:no-console
    console.log('>>> Render Expanded Grid Item', parentItem)
    // tslint:disable-next-line:no-console
    console.log('>>> Render Expanded Grid Item Extra', {
      entityType: tabType,
      isSelected,
      isPinned,
      actionType
    })
  }

  return (
    <Provider id={parentItemId} modifiedLists={modifiedLists}>
      <Columns
        columnsSettings={columnsSettings}
        isExpanded={true}
        gridItemId={parentItemId}
        tabType={tabType}
        togglePreview={togglePreview}
        isHighlightRed={Boolean(
          tabType === TabDTO.Type.dispatchDeliveryOrder &&
            parentItem &&
            (parentItem as DispatchDeliveryOrderViewDTO).troubleTicketId
        )}
        isSelected={isSelected}
        isDisabledSelect={isDisabledSelect}
        isPinned={isPinned}
        actionType={actionType}
      />
      <ApplicationSizeProvider>
        <Preview />
      </ApplicationSizeProvider>
    </Provider>
  )
})
