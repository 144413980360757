import * as React from 'react'
import { Title } from '../../../styledComponents'
import cn from 'classnames'
import { DateISOString, DateTimeRangeDTO, TransportationActivityViewDTO } from '../../../../../api/api'
import {
  convertISODateToDateWithHoursMins,
  convertRangeISODateToDateWithHoursMins
} from '../../../../../services/timeService/dateUtils'
import { DateOnFocus, DateTimePicker } from './'
import styled from 'styled-components'
import { withProps } from '../../../../../styles/styledComponents'
import { IStyledComponentsProps, theme } from '../../../../../styles/theme'
import { oc } from 'ts-optchain'
import { dateService } from '../../../../../services/timeService'

enum Tab {
  Actual = 'Actual',
  Planned = 'Planned'
}

type Props = {
  totalDisabled?: boolean
  disabled?: boolean
  title: string
  actual?: {
    date?: DateISOString
    onUpdate?: (date: DateISOString) => void
  }
  planned?: {
    date?: DateTimeRangeDTO
    onUpdate: (date: DateTimeRangeDTO) => void
  }
  setTimeMorningOnEmpty?: boolean
  setTimeNowOnEmpty?: boolean
  activityWithWrongOrderOfPlannedDate?: TransportationActivityViewDTO
} & Partial<DefaultProps>

type DefaultProps = { showTime: boolean; required: boolean }
/*
export const isHavePlannedDate = (date: any): boolean => {
  if (date) {
    return date && typeof date === 'string' ? !!date : !!date.to || !!date.from
  }
  return false
}*/

export const ActualPlannedDates = React.memo((props: Props) => {
  const {
    totalDisabled,
    required,
    showTime,
    title,
    actual,
    planned,
    setTimeMorningOnEmpty,
    setTimeNowOnEmpty,
    disabled,
    activityWithWrongOrderOfPlannedDate
  } = props
  const [activeTab, setActiveTab] = React.useState<Tab>(actual && actual.date ? Tab.Actual : Tab.Planned)
  let dateOnFocus = undefined

  if (setTimeMorningOnEmpty) {
    dateOnFocus = DateOnFocus.startWorkDay
  }

  if (setTimeNowOnEmpty) {
    dateOnFocus = DateOnFocus.now
  }

  return (
    <Container>
      <Tabs>
        {[Tab.Actual, Tab.Planned].map(tab => (
          <span
            key={tab}
            className={cn({ active: !totalDisabled && activeTab === tab, disabled: totalDisabled })}
            onClick={totalDisabled ? undefined : () => setActiveTab(tab)}
          >
            <p>{tab}</p>
          </span>
        ))}
      </Tabs>
      {activeTab === Tab.Actual && (
        <div className="date-picker-container" style={{ width: '100%' }}>
          <Title className="title">{title}</Title>
          <StyledDateField className="date-field">
            {!actual || !actual.date ? (
              <span style={{ color: 'rgba(51, 51, 51, 0.68)', cursor: 'not-allowed' }}>MM/DD/YY</span>
            ) : typeof actual.date === 'string' ? (
              convertISODateToDateWithHoursMins(actual.date, false, false)
            ) : (
              convertRangeISODateToDateWithHoursMins(actual.date, false, false)
            )}
          </StyledDateField>
        </div>
      )}
      {activeTab === Tab.Planned && (
        <>
          <span />
          <DateTimePicker
            disabled={totalDisabled || disabled}
            redText={oc(planned as any).date.confirmed() === false}
            highlight={
              activityWithWrongOrderOfPlannedDate &&
              planned.date &&
              activityWithWrongOrderOfPlannedDate.startPlannedDateTimeRange.from === planned.date.from
            }
            date={planned.date}
            minDate={dateService.createStringDate.startWorkDay}
            isRange={true}
            title={title}
            dateOnFocus={dateOnFocus}
            // @ts-ignore
            onChange={planned.onUpdate}
          />
          {/*<DateField*/}
          {/*redColor={oc(planned as any).date.confirmed() === false}*/}
          {/*disabled={totalDisabled || disabled}*/}
          {/*required={required}*/}
          {/*showTime={showTime}*/}
          {/*title={title}*/}
          {/*date={planned.date}*/}
          {/*dateType={DateTypes.ConfirmedDateTimeRange}*/}
          {/*placeholder={'MM/DD/YY'}*/}
          {/*setTimeMorningOnEmpty={setTimeMorningOnEmpty}*/}
          {/*setTimeNowOnEmpty={setTimeNowOnEmpty}*/}
          {/*onUpdate={planned.onUpdate}*/}
          {/*/>*/}
        </>
      )}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const Tabs = styled.div`
  z-index: 1;
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;

  span {
    position: relative;
    display: block;
    height: 25px;
    padding: 5px 5px 0;
    border-radius: 5px 5px 0 0;
    font-size: 10px;
    cursor: default;
    user-select: none;
    transition: background 0.25s;
    overflow: hidden;

    &.active {
      position: relative;
      color: #445366;
      background-color: #f5f6fa;
    }

    p {
      display: block;
      padding-bottom: 3px;
      background-position: -1px bottom !important;
      background-size: 7px 1px !important;
      background-repeat: repeat-x !important;
      transition: background 0.25s;
    }

    &:not(.active) {
      color: #4555d0;
      cursor: pointer;

      p {
        background: linear-gradient(to right, #e3e6f1 50%, rgba(255, 255, 255, 0) 0%);
      }

      &:not(.disabled):hover p {
        background: linear-gradient(to right, rgba(69, 85, 208, 0.5) 50%, rgba(255, 255, 255, 0) 0%);
      }
    }

    &.disabled {
      color: rgba(68, 83, 102, 0.5);
      cursor: default;
    }
  }

  & ~ span + .date-picker-container .date-field {
    border-radius: 5px 0 5px 5px;
  }
`

const StyledDateField = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  position: relative;
  display: flex;
  padding-left: ${props => (props.padding && props.padding.left >= 0 ? props.padding.left + 'px' : '8px')};
  align-items: center;
  height: 35px;
  border-radius: 5px;
  background: ${props => (!props.disabled ? props.background : null)};
  border: 1px solid rgba(229, 229, 229, ${props => (props.disabled ? 0.4 : 1)});
  font-size: 13px;
  color: #333333;
  ${props => (props.margin && props.margin.bottom >= 0 ? `margin-bottom: ${props.margin.bottom}px;` : null)}
  
  &.highlight {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }

  span {
    color: ${theme.colors.basicGreyColor};
    margin: 0;

    &.date {
      color: #333;
    }
  }
  
  .b-reset-all {
    display: none;
  }
  
  :hover {
    .b-reset-all {
      display: block;
    }
   }
   
    .error{
       color: red;
    }
  }
`
