import * as React from 'react'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useListItem } from './useListItem'
import { SteamShipLineViewDTO } from '../api/api'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
}

export const useSteamShipLine = (props: Props): SteamShipLineViewDTO => {
  const { id, modifiedLists } = props
  const steamShipLine = useListItem({ id, type: EntityType.steamShipLine, modifiedLists })

  return steamShipLine
}
