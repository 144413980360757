import * as React from 'react'
import { RouteBuilderHeader } from './RouteBuilderHeader'
import { RouteBuilderBody } from './RouteBuilderBody'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectRouteBuilderDriverAuthUserId } from '../../../../store/select/routeBuilderSelect'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const RouteBuilderView = (props: Props) => {
  const authUserId = useAppSelector(selectRouteBuilderDriverAuthUserId)

  return (
    <React.Fragment key={authUserId}>
      <RouteBuilderHeader />
      <RouteBuilderBody />
    </React.Fragment>
  )
}
