import { oc } from 'ts-optchain'
import { tryToSave } from '../saveDTO'
import { saveSteamShipLine as saveSteamShipLineSavePropmise } from '../../saveEntity/saveSteamShipLine'
import { SteamShipLineViewDTO } from '../../../api/api'
import { TExpandedItemContext } from '../../../contexts/ExpandedItemContext'
import { getListsState } from '../../../store'
import { isNewItem } from '../../utils'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { testEntityVersion } from '../../functions/test/testEntityVersion'
import { filterModifiedContacts } from '../../functions/filter/filterModifiedContacts'
import { handleSavedGridItem } from '../../functions/handleSavedGridItem'

export const saveSteamShipLine = (expandedItemContext: TExpandedItemContext) => {
  const { tabId, parentItem, parentItemId, modifiedLists, setFetching, clearExpandedItemModifies } = expandedItemContext
  const store = getListsState()
  const steamShipLine: SteamShipLineViewDTO = parentItem
  const modifiedSteamShipLine = oc(modifiedLists)[EntityType.steamShipLine][parentItemId]()
  const storeBusinessPartner = store[EntityType.businessPartner][steamShipLine.businessPartnerId]
  const modifiedBusinessPartner = oc(modifiedLists)[EntityType.businessPartner][steamShipLine.businessPartnerId]()
  const contactIds = (modifiedBusinessPartner || storeBusinessPartner).contactIds
  const updatedContacts = filterModifiedContacts({ contactIds, modifiedLists })

  const condition =
    testEntityVersion(store, EntityType.steamShipLine)(modifiedSteamShipLine) &&
    testEntityVersion(store, EntityType.businessPartner)(modifiedBusinessPartner) &&
    updatedContacts.every(testEntityVersion(store, EntityType.contact))

  const save = () => {
    return saveSteamShipLineSavePropmise({
      steamShipLine: modifiedSteamShipLine,
      businessPartner: modifiedBusinessPartner,
      contacts: updatedContacts
    }).then(handleSavedGridItem({ tabId, isNewItem: isNewItem(steamShipLine) }))
  }

  return tryToSave({
    condition,
    save: () => {
      setFetching(true)
      save().finally(() => setFetching(false))
    },
    hideSpinner: () => setFetching(false),
    cancel: () => {},
    discardChanges: clearExpandedItemModifies
  })
}
