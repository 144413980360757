import * as React from 'react'
import { BusinessPartnerViewDTO, ContactDTO, LocationViewDTO } from '../api/api'
import { generateContact } from '../services/functions/generate/generateContact'
import { ContactsTabContext } from '../contexts/ContactsTabContext'
import { requestContactIds } from '../components/common/contact/epics'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { useContacts } from '../hooks/useContacts'
import { EntityType } from '../store/reducers/lists/interfaces'

type Props = {
  parentEntity: LocationViewDTO | BusinessPartnerViewDTO
  entityType: EntityType.businessPartner | EntityType.location
  children?: any
}

export const ContactsTabProvider = (props: Props) => {
  const { parentEntity, entityType, children } = props
  const { modifiedLists, isModified, modifyListItems, deleteModifiedListItems } = useExpandedItem()
  const { contacts, primaryContact } = useContacts({ parentEntity, modifiedLists })

  const createContact = () => {
    const newContact = generateContact()

    modifyListItems({
      [EntityType.contact]: [newContact],
      [entityType]: [{ ...parentEntity, contactIds: [...parentEntity.contactIds, newContact.id] }]
    })
  }

  const updateContactField = (contact: ContactDTO) => (prop: keyof ContactDTO) => (value: any) => {
    if (!(contact && prop)) {
      return
    }

    const updatedContacts = [{ ...contact, [prop]: value }]
    let newPrimaryContactId: string = parentEntity.primaryContactId

    if (prop === 'primary') {
      newPrimaryContactId = value ? contact.id : undefined

      if (value === true && primaryContact) {
        updatedContacts.push({ ...primaryContact, primary: false })
      }
    }

    modifyListItems({
      [EntityType.contact]: updatedContacts,
      ...(parentEntity.primaryContactId !== newPrimaryContactId
        ? { [entityType]: [{ ...parentEntity, primaryContactId: newPrimaryContactId }] }
        : {})
    })
  }

  const deleteContactById = (contactId: string) => {
    const updatedContactIds = parentEntity.contactIds.filter((id: string) => id !== contactId)
    const updateParentEntity = { ...parentEntity, contactIds: updatedContactIds }

    if (parentEntity.primaryContactId && !updatedContactIds.includes(parentEntity.primaryContactId)) {
      updateParentEntity.primaryContactId = undefined
    }

    modifyListItems({ [entityType]: [updateParentEntity] })
    deleteModifiedListItems({ [EntityType.contact]: [contactId] })
  }

  React.useEffect(() => {
    requestContactIds(parentEntity.contactIds)
  }, [])

  return (
    <ContactsTabContext.Provider
      value={{
        isModifiedMode: isModified,
        contacts,
        primaryContact,
        createContact,
        updateContactField,
        deleteContactById
      }}
      children={children}
    />
  )
}
