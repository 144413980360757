import * as React from 'react'
import { oc } from 'ts-optchain'
import { Select } from '../../../../../UI/DataFields/Select'
import { SchedulerSortBy } from '../../../interfaces'
import { updateSchedulerFilterGroupBy } from '../../../functions/updateSchedulerFilterGroupBy'
import { ITab } from '../../../../../../store/reducers/tabs/interfaces'
import { updateTabUISettings } from '../../../../../../store/reducers/tabs/functions'

type StateProps = {
  tab: ITab
  sortBy: SchedulerSortBy
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const Group = React.memo((props: Props) => {
  const { tab, sortBy } = props

  return (
    <Select
      title={'Group By'}
      selectedValue={sortBy}
      containerStyle={{ flexGrow: 1, width: 'unset' }}
      list={[
        SchedulerSortBy.date,
        SchedulerSortBy.deliveryLocationCity,
        SchedulerSortBy.deliveryStageDriver,
        SchedulerSortBy.driverActivities
      ].map(item => ({ value: item, label: item }))}
      onSelect={nextSortBy =>
        setTimeout(() => {
          updateTabUISettings(tab.id, {
            sorting: nextSortBy,
            filter: updateSchedulerFilterGroupBy({
              prevSortBy: sortBy,
              nextSortBy,
              filters: oc(tab).uiSettings.filter([])
            })
          })
        })
      }
    />
  )
})
