import * as React from 'react'

export interface IApplicationSizeContext {
  resize: number
  applicationWidth: number
  applicationHeight: number
  communicationHubWidth: number
  isCommunicationHubExpanded: boolean
}

export const ApplicationSizeContext = React.createContext<IApplicationSizeContext>({
  resize: 0,
  applicationWidth: 0,
  applicationHeight: 0,
  communicationHubWidth: 0,
  isCommunicationHubExpanded: false
})
