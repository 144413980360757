import * as React from 'react'
import { oc } from 'ts-optchain'
import { SteamShipLineViewDTO } from '../../../../../api/origin/business-logic'
import { ColumnRow, Container } from '../../../GridSystem'
import { Input } from '../../../DataFields/Input'
import { WidgetActions } from '../../styles'
import { SteamShipLineSearch } from '../../../DataFields/SearchRequest'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { DateTimePicker } from '../../../DataFields/DateTimePicker/views'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { alertOnChangingMainStreetTurnPropsOfDO } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'

type Props = {
  isSteamShipLineRequired: boolean
}

export const Popover = (props: Props) => {
  const { isSteamShipLineRequired } = props
  const { enableEditing, deliveryOrder, isExport, modifyDeliveryOrderField } = useGridItemDDO()
  const { closePopover } = React.useContext(WidgetContext)
  const disabled = enableEditing === false
  const changeSteamShipLine = (_steamShipLine: SteamShipLineViewDTO) => {
    alertOnChangingMainStreetTurnPropsOfDO(
      { ...deliveryOrder, steamShipLineId: oc(_steamShipLine).id() },
      deliveryOrder
    )
    modifyDeliveryOrderField('steamShipLineId')(oc(_steamShipLine).id())
  }

  return (
    <>
      <Container isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <SteamShipLineSearch
            disabled={disabled}
            title={'SSL Name'}
            required={isSteamShipLineRequired}
            id={oc(deliveryOrder).steamShipLineId()}
            onChange={changeSteamShipLine}
          />
        </ColumnRow>

        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            title={'Vessel Name'}
            maxLength={120}
            value={oc(deliveryOrder).vesselName()}
            onChange={modifyDeliveryOrderField('vesselName')}
          />
        </ColumnRow>

        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            title={'Voyage #'}
            maxLength={120}
            value={oc(deliveryOrder).voyageNumber()}
            onChange={modifyDeliveryOrderField('voyageNumber')}
          />
        </ColumnRow>

        <ColumnRow>
          <DateTimePicker
            title={isExport ? 'Vessel Departure (ETD)' : 'Vessel Arrival (ETA)'}
            disabled={disabled}
            date={isExport ? oc(deliveryOrder).vesselDepartureDate() : oc(deliveryOrder).vesselArrivalDate()}
            onChange={modifyDeliveryOrderField(isExport ? 'vesselDepartureDate' : 'vesselArrivalDate')}
          />
        </ColumnRow>
      </Container>
      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
