import * as React from 'react'
import { StyledHeader, StyledTabs, StyledTab } from './styles'
import { Filter } from './Filter'
import { IQMPFilters, QMPTab } from '../../interfaces'
import { useQMP } from '../../../../../hooks/useQMP'
import cn from 'classnames'

type Props = {
  isModifyMode: boolean
  activeTab: QMPTab
  filters: IQMPFilters
}

export const Header = React.memo((props: Props) => {
  const { activeTab, isModifyMode, filters } = props
  const { setActiveTab } = useQMP()

  const tabs = React.useMemo(() => [QMPTab.RATE], [])

  const RenderTabs = React.useMemo(
    () => (
      <StyledTabs>
        {tabs.map(tab => {
          const active = activeTab === tab

          return (
            <StyledTab key={tab} className={cn({ active })} onClick={active ? undefined : () => setActiveTab(tab)}>
              {tab}
            </StyledTab>
          )
        })}
      </StyledTabs>
    ),
    [activeTab]
  )

  return (
    <StyledHeader className={isModifyMode ? 'disabled' : ''}>
      {RenderTabs}
      <Filter activeTab={activeTab} isModifyMode={isModifyMode} filters={filters} />
    </StyledHeader>
  )
})
