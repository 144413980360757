import * as React from 'react'
import { EquipmentDTO } from '../../../../../api/origin/business-logic'
import { AvailabilityColumnSelecter } from '../../../../UI/oldAvailabilitySelecter'
import { useGridItemEquipment } from '../../../../../hooks/useGridItemEquipment'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'

const dropDownItems = {
  Active: ['Available', 'Not Available (Assigned)', 'Not Available (In-Use)'],
  Inactive: [
    'Expired Registration',
    'Expired Safety Inspection',
    'Expired Maintenance Inspection',
    'Maintenance',
    'Terminated',
    'Other Inactive Reason'
  ]
}

type Props = {}

export const AvailabilityColumn = (props: Props) => {
  const { equipment, isExpanded } = useGridItemEquipment()
  // @ts-ignore
  const { modifyParentObject } = isExpanded ? useExpandedItem() : {}
  let selectedItem: string

  const selectedValue = (powerUnit: any) => {
    if (powerUnit.active) {
      powerUnit.availability
        ? (selectedItem = 'Available')
        : powerUnit.unavailabilityReason === EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
        ? (selectedItem = 'Not Available (Assigned)')
        : (selectedItem = 'Not Available (In-Use)')
    } else {
      switch (powerUnit.inactiveReason) {
        case EquipmentDTO.InactiveReasonEnum.EXPIREDREGISTRATION:
          selectedItem = 'Expired Registration'
          break
        case EquipmentDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION:
          selectedItem = 'Expired Safety Inspection'
          break
        case EquipmentDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION:
          selectedItem = 'Expired Maintenance Inspection'
          break
        case EquipmentDTO.InactiveReasonEnum.MAINTENANCE:
          selectedItem = 'Maintenance'
          break
        case EquipmentDTO.InactiveReasonEnum.TERMINATED:
          selectedItem = 'Terminated'
          break
        case EquipmentDTO.InactiveReasonEnum.OTHER:
          selectedItem = 'Other Inactive Reason'
          break
        default:
          break
      }
    }

    return selectedItem
  }
  const currentSelectedItem: string = selectedValue(equipment)

  return (
    <AvailabilityColumnSelecter
      handleSelect={modifyParentObject ? handleSelect(equipment, modifyParentObject, currentSelectedItem) : undefined}
      activeList={dropDownItems.Active}
      inactiveList={dropDownItems.Inactive}
      selectedItem={currentSelectedItem}
      stateActive={equipment.active}
    />
  )
}

const handleSelect = (equipment: any, modifyParentObject: (item: any) => void, selectedItem: any) => (item: string) => {
  if (item !== selectedItem) {
    let updateEquipment: EquipmentDTO

    switch (item) {
      case 'Available':
        updateEquipment = {
          ...equipment,
          active: true,
          inactiveReason: null,
          availability: true,
          unavailabilityReason: null
        }
        break
      case 'Not Available (Assigned)':
        updateEquipment = {
          ...equipment,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
        }
        break
      case 'Not Available (In-Use)':
        updateEquipment = {
          ...equipment,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INUSE
        }
        break
      case 'Expired Registration':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDREGISTRATION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Safety Inspection':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Maintenance Inspection':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Maintenance':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.MAINTENANCE,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Terminated':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.TERMINATED,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Other Inactive Reason':
        updateEquipment = {
          ...equipment,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.OTHER,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      default:
    }

    if (updateEquipment) {
      modifyParentObject(updateEquipment)
    }
  }
}
