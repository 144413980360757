import { SteamShipLineViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateSteamShipLine = (props?: Partial<SteamShipLineViewDTO>): SteamShipLineViewDTO => {
  return {
    id: createId(),
    completeness: maxCompleteness,
    businessPartnerId: undefined,
    containerDetentionPerDiemFeeSchedule: [],
    freeDetentionAllowance: null,
    referenceNumber: null,
    scac: null,
    ...(props || {})
  }
}
