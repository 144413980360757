import * as R from 'remeda'
import { BusinessPartnerViewDTO, ContactDTO } from '../../api/api'
import { requestUpdateContacts } from '../../components/common/contact/epics'
import { isNewId, isNewObject } from '../DTO'
import { createBusinessPartner, updateBusinessPartner } from '../DTO/businessPartner/epics'
import { oc } from 'ts-optchain'

type Props = {
  businessPartner: BusinessPartnerViewDTO | undefined
  contacts: ContactDTO[] | undefined
}

export const saveBusinessPartner = async (props: Props): Promise<BusinessPartnerViewDTO> => {
  const { contacts } = props
  const businessPartner: BusinessPartnerViewDTO = props.businessPartner ? R.clone(props.businessPartner) : undefined
  const isNewBusinessPartner = isNewObject(businessPartner)
  let savedBusinessPartner: BusinessPartnerViewDTO = businessPartner
  let savedContacts: ContactDTO[] = undefined

  if (contacts && contacts.length) {
    savedContacts = await requestUpdateContacts(contacts)
  }

  if (businessPartner) {
    if (isNewId(businessPartner.primaryContactId)) {
      const primaryContactIdIndex = contacts.findIndex(contact => contact.id === businessPartner.primaryContactId)
      businessPartner.primaryContactId = oc(savedContacts[primaryContactIdIndex]).id()
    }

    savedBusinessPartner = await (isNewBusinessPartner
      ? createBusinessPartner(
          savedContacts ? { ...businessPartner, contactIds: savedContacts.map(({ id }) => id) } : businessPartner
        )
      : updateBusinessPartner(
          savedContacts
            ? {
                ...businessPartner,
                contactIds: R.uniq(
                  (businessPartner.contactIds || [])
                    .filter(id => !isNewId(id))
                    .concat(savedContacts.map(({ id }) => id))
                )
              }
            : businessPartner
        ))
  }

  return savedBusinessPartner
}
