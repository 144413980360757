import * as React from 'react'
import { DDOActivityTable } from './styles'
import { DDOActivityTableHeader } from './Header'
import { DDOActivityTableBody } from './Body'
import { CustomerQuoteDTO } from '../../../../../../../api/origin/qmp-service'

type Props = {
  allowModifying: boolean
  customerQuote?: CustomerQuoteDTO
  unlimitedHeight?: boolean
}

export const Table = (props: Props) => {
  return (
    <DDOActivityTable style={props.unlimitedHeight ? { height: 'auto' } : undefined}>
      <DDOActivityTableHeader />
      <DDOActivityTableBody {...props} />
    </DDOActivityTable>
  )
}
