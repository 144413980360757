import * as React from 'react'
import * as R from 'remeda'
import { EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import createMentionPlugin from 'draft-js-mention-plugin'
// import createEmojiPlugin from 'draft-js-emoji-plugin'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import '../../../../styles/chatRichInput.scss'
import theme from '../../../../styles/theme'
import { MentionLabel } from './MentionLabel'
import { MentionEntry } from './MentionEntry'
import { fixCursorBug, linkifyEditorState } from '../../functions/linkifyEditorState'
import { EditorAttachments } from './EditorAttachments'
import { useCommunicationHubSuggestions } from '../../../../hooks/useCommunicationHubSuggestions'
import { preventEvent } from '../../../../services/functions/basic'
import { UserDTO } from '../../../../api/origin/user-service'

enum KeyCommand {
  enterPressed = 'enter-pressed',
  handled = 'handled',
  notHandled = 'not-handled'
}

type OwnProps = {
  focus?: boolean
  messageId?: string
  channelId?: string
  placeholder?: string
  editorState: EditorState
  onEditorChange: (editorState: EditorState) => void
  renderBottomSection?: JSX.Element
  onSendMessage?: () => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ReactInput = React.memo((props: Props) => {
  const { focus, placeholder, renderBottomSection, onSendMessage, messageId, channelId } = props
  const { contacts } = useCommunicationHubSuggestions()
  const contactsRef = React.useRef([] as UserDTO[])
  const editorRef = React.useRef(null)
  const lastSearchValue = React.useRef('')
  const [suggestions, setSuggestions] = React.useState(contacts)
  const [editorState, onEditorChange] = React.useState(props.editorState || EditorState.createEmpty())
  contactsRef.current = contacts

  React.useEffect(() => {
    if (focus) {
      setTimeout(() => {
        if (editorRef && editorRef.current) {
          editorRef.current.focus()
        }
      }, 150)
    }
  }, [])

  const handleEditorChange = React.useCallback(
    (state: EditorState) => {
      onEditorChange(state)
      props.onEditorChange(state)
    },
    [props.onEditorChange]
  )

  const filterSuggestions = React.useCallback(
    () =>
      setSuggestions(
        contactsRef.current
          .filter(contact =>
            oc(contact)
              .name('')
              .toLowerCase()
              .includes(lastSearchValue.current.toLowerCase())
          )
          .map((u, i, array) => (i > 0 && array[i - 1].type !== u.type ? { ...u, isAnotherMentionTypeStart: true } : u))
      ),
    []
  )

  React.useEffect(() => {
    filterSuggestions()
  }, [contacts])

  const onSearchChange = React.useCallback((data: { value: string }) => {
    lastSearchValue.current = data.value
    filterSuggestions()
  }, [])

  // const onMentionSuggestionsOpen = React.useCallback(() => {
  //   filterSuggestions()
  // }, [])

  // HANDLE KEY COMMANDS
  const keyBindingFn = React.useCallback((e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      return KeyCommand.enterPressed // name this whatever you want
    }
    // If this wasn't keys above, we return Draft's default key bindings
    return getDefaultKeyBinding(e as any)
  }, [])

  const handleKeyCommand = React.useCallback(
    (command: any, newEditorState: any) => {
      if (command === KeyCommand.enterPressed) {
        // SUBMIT
        if (onSendMessage) {
          onSendMessage()
        }
        return KeyCommand.handled
      }

      const newState = RichUtils.handleKeyCommand(newEditorState, command)

      if (newState) {
        handleEditorChange(newState)
        return KeyCommand.handled
      }

      return KeyCommand.notHandled
    },
    [onSendMessage, onEditorChange]
  )

  // STYLED TEXT
  const { inlineToolbarPlugin, linkifyPlugin, mentionPlugin } = React.useMemo(() => {
    return {
      inlineToolbarPlugin: createInlineToolbarPlugin(),
      linkifyPlugin: createLinkifyPlugin({
        target: '_blank',
        // hot fix https://github.com/draft-js-plugins/draft-js-plugins/issues/1342
        component: (params: any) => <a {...R.omit(params, ['blockKey', 'start', 'end'])} />
      }),
      // emojiPlugin: createEmojiPlugin({
      //   useNativeArt: true,
      //   selectButtonContent: <i className={'mdi mdi-emoticon'} />
      // }),
      mentionPlugin: createMentionPlugin({
        positionSuggestions: (settings: any) => {
          const popupWidth = 320
          return {
            maxHeight: '200px',
            width: popupWidth + 'px',
            overflow: 'auto',
            position: 'fixed',
            left:
              (settings.decoratorRect.left + popupWidth < window.innerWidth
                ? settings.decoratorRect.left
                : window.innerWidth - popupWidth) + 'px',
            top: settings.decoratorRect.top - 20 + 'px',
            display: 'block',
            transform: 'scale(1) translateY(-100%)' // transition popover on the value of its height
            // transformOrigin: '1em 0% 0px',
            // transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)'
          }
        },
        // mentions: contacts.current,
        entityMutability: 'IMMUTABLE',
        mentionComponent: MentionLabel
      })
    }
  }, [])

  const plugins = React.useMemo(
    () => [
      inlineToolbarPlugin,
      // emojiPlugin,
      linkifyPlugin,
      mentionPlugin
    ],
    []
  )
  const { InlineToolbar } = inlineToolbarPlugin as any
  const { MentionSuggestions } = mentionPlugin
  // const { EmojiSuggestions, EmojiSelect } = emojiPlugin

  const onChange = React.useCallback(
    (state: EditorState) => handleEditorChange(fixCursorBug(editorState, linkifyEditorState(state))),
    [editorState, handleEditorChange]
  )

  return (
    <Container>
      <EditorContainer
        className={cn('editor-container', { 'has-bottom-section': renderBottomSection })}
        onClick={() => editorRef.current.focus()}
      >
        <Editor
          ref={editorRef}
          placeholder={placeholder || 'Type here...'}
          editorState={editorState}
          onChange={onChange}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
        />
        {/*<EmojiSuggestions />*/}
        <InlineToolbar />
        <EditorAttachments messageId={messageId} channelId={channelId} />
        {Boolean(onSendMessage) && (
          <SendMessageButton
            className={cn('mdi mdi-send')}
            onClick={event => {
              preventEvent(event)
              onSendMessage()
            }}
          />
        )}
        {renderBottomSection}
      </EditorContainer>

      <MentionSuggestions entryComponent={MentionEntry} onSearchChange={onSearchChange} suggestions={suggestions} />
      {/*<EmojiSelect />*/}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  padding: 5px 0 10px;
  margin-top: auto;
`
const EditorContainer = styled.div`
  position: relative;
  background: white;
  border: 1px solid rgba(198, 204, 227, 0.5);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .DraftEditor-root {
    position: relative;
  }

  .public-DraftEditor-content {
    max-height: 400px;
    overflow: auto;
  }

  .public-DraftEditor-content,
  .files-container {
    padding: 15px 55px 15px 15px;
  }

  .public-DraftEditorPlaceholder-root {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    padding: 15px;
  }

  .draftJsEmojiPlugin__emojiSelectButton__3sPol,
  .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    position: absolute;
    color: #6c707d;
    width: 14px;
    height: 14px;
    line-height: 15px;
    cursor: pointer;
    bottom: 24px;
    right: 71px;
    font-size: 24px;
    border: none;
    background: none;
    &:hover {
      color: ${theme.colors.basicBlueColor} !important;
    }
  }
  .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    color: ${theme.colors.basicBlueColor} !important;
  }

  &.has-bottom-section {
    .attach-files-button {
      right: unset;
      left: 15px;
    }

    .public-DraftEditor-content,
    .files-container {
      padding: 15px;
    }
  }
`
const SendMessageButton = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 24px;
  right: 5px;
  bottom: 14px;
  // color: #253447;
  // opacity: 0.5;
  //
  // &.active {
  //   opacity: 1;
  //   color: ${theme.colors.basicBlueColor};
  //   cursor: pointer;
  //
  //   &:hover {
  //     opacity: 0.85;
  //   }
  // }
  
  opacity: 1;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`

export const isEditorEmpty = (editorState: EditorState): boolean => {
  if (!editorState) {
    return true
  }

  const currentContent = editorState.getCurrentContent()
  const _isEditorEmpty = !currentContent.hasText()
  const currentPlainText = currentContent.getPlainText()
  const lengthOfTrimmedContent = currentPlainText.trim().length
  return _isEditorEmpty || !lengthOfTrimmedContent
}
