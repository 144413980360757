import * as R from 'remeda'
import { IDropdownItem } from './Dropdown'
import { Label, LabelPopover } from './Label'

type Props = {
  multiselect?: boolean
  separatePopoverRows?: boolean
  placeholder?: string
  popover?: string | JSX.Element
  label?: string | JSX.Element
  selectedValue: string | string[]
  list: IDropdownItem[][]
  mapping?: IDropdownItem[]
}

export const getLabelAndPopover = (props: Props) => {
  const { multiselect, separatePopoverRows, placeholder, selectedValue, list, label, popover, mapping } = props
  const value: IDropdownItem | IDropdownItem[] = selectedValue
    ? multiselect
      ? R.flatten(list).filter((_: IDropdownItem) => selectedValue.includes(_.value))
      : R.flatten(list).find((_: IDropdownItem) => _.value === selectedValue) ||
        (mapping || []).find((_: IDropdownItem) => _.value === selectedValue)
    : null

  return {
    Label: Label({ multiselect, label, placeholder, value }),
    Popover: LabelPopover({ multiselect, separatePopoverRows, popover, value })
  }
}
