import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { BoldLine, Text, Title } from '../../styles'
import { transformMoney } from '../../../../../services/functions/transform/transformMoney'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'

type Props = {
  small?: boolean
}

export const SsqBsqWidgetWindow = (props: Props) => {
  const { small } = props
  const {
    quoteAmounts: { sellSideQuoteTotalAmount, profit, buySideQuoteTotalAmount }
  } = useSsqBsqWidget()

  return (
    <Container className={cn({ small })}>
      <Column>
        <Title rows={2} margin={{ bottom: small ? 4 : 10 }}>
          SSQ
        </Title>
        <div style={small ? { display: 'flex' } : undefined}>
          <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
            {transformMoney(sellSideQuoteTotalAmount)}
          </BoldLine>
          <Text className={'opacity uppercase'} rows={3} alignCenter={true}>
            {transformMoney(profit)}
          </Text>
        </div>
      </Column>
      <Column className={small ? undefined : 'with-border'}>
        <Title rows={2} margin={{ bottom: small ? 4 : 10 }}>
          BSQ
        </Title>
        <div style={small ? { display: 'flex' } : undefined}>
          <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
            {transformMoney(buySideQuoteTotalAmount)}
          </BoldLine>
        </div>
      </Column>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  &.small {
    ${BoldLine} {
      display: inline-flex;
    }

    ${Text} {
      display: inline-block;
      font-size: 14px;
      margin-left: 8px;
    }
  }
`

const Column = styled.div`
  &.with-border {
    position: relative;
    padding-left: 15px;

    &:before {
      content: '';
      display: block;
      width: 1px;
      background-color: #e5e5e5;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
`
