import * as React from 'react'
import { oc } from 'ts-optchain'
import { Header } from './Header'
import { StageGroupingView } from './Body/StageGroupingView'
import { StyledScheduler } from './styled'
import { ISchedulerTab, ISchedulerTabFilterMapping, SchedulerFilter, SchedulerSortBy } from '../interfaces'
import { DateISOString, DateTimeRangeDTO, OngoingActivityGroupDTO } from '../../../../api/api'
import { MoveGroupingView } from './Body/MoveGroupingView'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectActiveApplicationTab } from '../../../../store/select/applicationTabSelect'

type OwnProps = {}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Scheduler = React.memo((props: Props) => {
  const tab = useAppSelector(selectActiveApplicationTab) as ISchedulerTab
  const sortBy = oc(tab).uiSettings.sorting(SchedulerSortBy.date)
  const showMissed = oc(tab).data.showMissed(false)
  const showNow = oc(tab).data.showNow(false)
  const filter: ISchedulerTabFilterMapping = React.useMemo(
    () =>
      oc(tab)
        .uiSettings.filter([])
        .reduce(
          (acc, { column, value }) => {
            if (column === SchedulerFilter.specificDate) {
              acc[column] = value as DateISOString
            } else if (column === SchedulerFilter.activityGroupDate) {
              acc[column] = value as DateTimeRangeDTO
            } else if (column === SchedulerFilter.grouping) {
              acc[column] = value as OngoingActivityGroupDTO.GroupingEnum
            } else if (column === SchedulerFilter.dispatchDeliveryOrderNumber) {
              acc[column] = (value && (Number(value) as number)) || undefined
            } else if (column === SchedulerFilter.dispatchDeliveryOrderId) {
              acc[column] = value
            } else if (column === SchedulerFilter.syncWithRouteBuilder) {
              acc[column] = (value as boolean) || false
            }

            return acc
          },
          {
            dispatchDeliveryOrderNumber: undefined,
            dispatchDeliveryOrderId: undefined,
            specificDate: undefined,
            activityGroupDate: undefined,
            grouping: undefined,
            syncWithRouteBuilder: undefined
          }
        ),
    [oc(tab).uiSettings.filter()]
  )

  return (
    <StyledScheduler>
      <Header
        sortBy={sortBy}
        dispatchDeliveryOrderNumber={filter.dispatchDeliveryOrderNumber}
        dispatchDeliveryOrderId={filter.dispatchDeliveryOrderId}
        specificDate={filter.specificDate}
        syncWithRouteBuilder={filter.syncWithRouteBuilder}
      />
      {/* {grouping === OngoingActivityGroupDTO.GroupingEnum.DRIVER && (
        <DriverGroupingView
          fetching={fetching}
          currentTabId={currentTab.id}
          sortBy={sortBy}
          deprecateLoadMoreContent={Boolean(dispatchDeliveryOrderNumber)}
          filter={props.filter}
        />
      )} */}
      {filter.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE && (
        <StageGroupingView
          currentTabVisited={tab.visited}
          currentTabId={tab.id}
          sortBy={sortBy}
          showMissed={showMissed}
          showNow={showNow}
          fetching={tab.fetching}
          filter={filter}
        />
      )}
      {sortBy === SchedulerSortBy.driverActivities && (
        <MoveGroupingView
          currentTabId={tab.id}
          currentTabVisited={tab.visited}
          fetching={tab.fetching}
          filter={filter}
        />
      )}
    </StyledScheduler>
  )
})
