import * as React from 'react'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
import cn from 'classnames'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import styled from 'styled-components'
import { theme } from '../../../../../../styles/theme'
import { ButtonClear, ButtonToggle } from '../../../../../UI/DataFields/Select/styles'

type Props = {
  disabled?: boolean
  placeholder: string
  selectedValues: DispatchDeliveryOrderViewDTO.StatusEnum[]
  clearStatuses: () => void
  archivedState: boolean
  toggleArchived: (state: boolean) => void
}

export const Label = (props: Props) => {
  const { placeholder = '', selectedValues, clearStatuses, archivedState, toggleArchived, disabled } = props

  return (
    <StyledLabel className={cn({ multiselect: !disabled && selectedValues && selectedValues.length > 1 })}>
      <Status>
        {!disabled && selectedValues.length ? (
          selectedValues.length === 1 ? (
            <Value>{ddoDirectory.status[selectedValues[0]]}</Value>
          ) : (
            <NumberOfValues>{selectedValues.length}</NumberOfValues>
          )
        ) : (
          <Value>{placeholder}</Value>
        )}
        {!disabled && Boolean(selectedValues.length) && (
          <ButtonClear
            className={'mdi mdi-close-circle'}
            onClick={event => {
              event.stopPropagation()
              return clearStatuses()
            }}
          />
        )}
      </Status>
      {!disabled && (
        <Archived>
          <ArchivedLabel>Archived</ArchivedLabel>
          <ButtonToggle
            onClick={event => {
              event.stopPropagation()
              toggleArchived(!archivedState)
            }}
            active={archivedState}
          />
        </Archived>
      )}
    </StyledLabel>
  )
}

const StyledLabel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;

  .multiselect {
    justify-content: center;
  }

  ${ButtonClear} {
    color: #e0e0e0 !important;

    &:hover {
      color: #445366 !important;
    }
  }
`
const Status = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`
const Value = styled.div`
  color: #6c707d;
  font-size: 10px;
`
const NumberOfValues = styled.div`
  display: inline-block;
  line-height: 18px;
  background: ${theme.colors.basicBlueColor};
  font-weight: 500;
  font-size: 14px;
  color: #f5f6fa;
  border-radius: 2px;
  padding: 0 5px;
  margin-left: auto;
`
const Archived = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: default;
`
const ArchivedLabel = styled.div`
  margin-right: 10px;
  user-select: none;
`
