import * as R from 'remeda'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../api/api'
import { DDOWorkingStatus } from '../DTO/activity/controller'
import { ActivityGroup, TransportationActivityGroup } from '../DTO/activity/interfaces'
import { getDDOWorkingStatus } from '../DTO/dispatchDeliveryOrder/functions'
import { filterUsefulActivityGroups } from './filter/filterUsefulActivityGroups'

export const calculateDDOProps = (props: {
  activityGroups: ActivityGroup[]
  dispatchDeliveryOrderStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
}): {
  ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  currentActivityGroup: TransportationActivityGroup
  declinedVendorIds: string[]
} => {
  const { activityGroups, deliveryOrderType, dispatchDeliveryOrderStatus } = props
  const isRepo = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const isDriverPlanning = getDDOWorkingStatus(dispatchDeliveryOrderStatus) === DDOWorkingStatus.planning
  let ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum = DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH
  let declinedVendorIds: string[] = []
  let currentActivityGroup: ActivityGroup = undefined

  const usefulActivityGroups = filterUsefulActivityGroups(activityGroups, true) as TransportationActivityGroup[]

  const mappings = {
    activityStatusPriority: {
      [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: 0,
      [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: 1,
      [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: 2,
      [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: 3,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: 1,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: 2,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: 3
    },
    ddoStatusByActivityDriverStatus: {
      [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
      [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
      [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED
    }
  }

  // >>> Find current activity group
  currentActivityGroup = usefulActivityGroups
    .slice()
    .reverse()
    .find(
      activityGroup =>
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )
  // <<<

  // >>> Calc drivers
  usefulActivityGroups.forEach(({ gotoActivity }) => {
    if (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED) {
      declinedVendorIds.push(gotoActivity.vendorId)
    }

    const currentPriorityNumber = mappings.activityStatusPriority[ddoStatus]
    const activityPriorityNumber = mappings.activityStatusPriority[gotoActivity.status] || -1

    if (currentPriorityNumber < activityPriorityNumber) {
      ddoStatus = mappings.ddoStatusByActivityDriverStatus[gotoActivity.status]
    }
  })

  declinedVendorIds = R.uniq(declinedVendorIds)

  if (declinedVendorIds.length) {
    ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH
  }
  // <<<

  if (currentActivityGroup) {
    // >>> Set DDO status by current activity group
    switch (currentActivityGroup.gotoActivity.stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP
        break
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY
        break
      case TransportationActivityViewDTO.StageEnum.RETURN:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
        break
      default:
        break
    }
  }
  // <<<

  const usefulActivityGroupsByStage: Record<TransportationActivityViewDTO.StageEnum, ActivityGroup[]> = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: [],
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: [],
    [TransportationActivityViewDTO.StageEnum.RETURN]: []
  }

  filterUsefulActivityGroups(activityGroups).filter((group: any) => {
    if (
      group.documentationActivity &&
      group.documentationActivity.status !== DocumentationActivityDTO.StatusEnum.REJECTED
    ) {
      usefulActivityGroupsByStage[group.documentationActivity.stage].push(group)
    } else if (group.gotoActivity) {
      usefulActivityGroupsByStage[group.gotoActivity.stage].push(group)
    }
  })

  // >>> Mark stages as Complete/In-Process
  const isGroupCompleted = (group: any): boolean => {
    if (group.documentationActivity) {
      return group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.APPROVED
    } else if (group.businessActivity) {
      return group.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    }
  }
  const isStageCompleted = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    return usefulActivityGroupsByStage[stage].every(isGroupCompleted)
  }

  const isPickupCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.PICKUP)
  const isDeliveryCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.DELIVERY)
  const isReturnCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.RETURN)

  const stagesCompletedStatus = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: isPickupCompleted,
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: isDeliveryCompleted,
    [TransportationActivityViewDTO.StageEnum.RETURN]: isReturnCompleted
  }

  const isStageEmpty = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    return !activityGroups.some((group: any) => {
      const currentGroupStage = (group.documentationActivity || group.businessActivity).stage

      return stage === currentGroupStage
    })
  }

  const isStageInProcess = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    let handledNotStartedActivityRow = false
    return (
      !stagesCompletedStatus[stage] &&
      activityGroups.some((group: any) => {
        const currentGroupStage = (group.documentationActivity || group.businessActivity).stage

        if (currentGroupStage !== stage) {
          return
        }

        const inProcessStatuses = [
          DocumentationActivityDTO.StatusEnum.SUBMITTED,
          DocumentationActivityDTO.StatusEnum.APPROVED,
          TransportationActivityViewDTO.StatusEnum.INPROCESS,
          TransportationActivityViewDTO.StatusEnum.COMPLETED,
          TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
        ]

        if (group.documentationActivity) {
          return (
            (stage !== TransportationActivityViewDTO.StageEnum.RETURN || !handledNotStartedActivityRow) &&
            inProcessStatuses.includes(group.documentationActivity.status)
          )
        } else if (group.businessActivity) {
          const isInProcess =
            inProcessStatuses.includes(group.gotoActivity.status) ||
            inProcessStatuses.includes(group.businessActivity.status)

          if (!isInProcess) {
            handledNotStartedActivityRow = true
          }

          return isInProcess
        }
      })
    )
  }

  const isDeliveryInProcess = isStageInProcess(TransportationActivityViewDTO.StageEnum.DELIVERY)
  const isReturnInProcess = isStageInProcess(TransportationActivityViewDTO.StageEnum.RETURN)
  // <<<

  // >>> Set DDO status according to completed stages
  switch (true) {
    case isRepo: {
      if (isPickupCompleted) {
        ddoStatus = isReturnInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
          : DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP
      }
      if (isPickupCompleted && isReturnCompleted) {
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
      }

      break
    }
    default: {
      if (isPickupCompleted) {
        ddoStatus = isDeliveryInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY
          : DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP
      }
      if (isPickupCompleted && isDeliveryCompleted) {
        ddoStatus = isReturnInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
          : !isStageEmpty(TransportationActivityViewDTO.StageEnum.DELIVERY)
          ? DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY
          : ddoStatus
      }
      if (isPickupCompleted && isDeliveryCompleted && isReturnCompleted) {
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
      }
    }
  }
  // <<<

  return {
    ddoStatus: isDriverPlanning ? dispatchDeliveryOrderStatus : ddoStatus,
    currentActivityGroup:
      ddoStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED ? currentActivityGroup : undefined,
    declinedVendorIds
  }
}
