import * as React from 'react'
import cn from 'classnames'
import { AvailabilitySelecter, Button, DropDown, Selected } from './Availability/styledComponents'
import { WindowPopover } from './windowPopover'

type Props = {
  selected: any
  disabled: boolean
  list: { title: string; items: { label: string; value: any }[] }[]
  onSelect: (item: any) => void
  labelElement?: (props: { containerRef: any; togglePopover: () => void }) => JSX.Element
  attributes?: any
}

export const AvailabilityColumnSelecter = (props: Props) => {
  const [open, setOpen] = React.useState(false)
  const columnRef = React.useRef(null)
  const { disabled, selected, attributes, list, onSelect, labelElement } = props
  const sections = React.useMemo(() => Object.values(list), [list || {}])
  let hoverOffTimer: any

  const { selectedLabel, isActive } = React.useMemo(() => {
    const result = {
      selectedLabel: '',
      isActive: false
    }

    sections.forEach(section => {
      section.items.forEach(item => {
        if (item.value === selected) {
          result.selectedLabel = item.label
          result.isActive = section.title === 'Active'
        }
      })
    })

    return result
  }, [selected, sections])

  const togglePopover = () => (!disabled ? setOpen(!open) : undefined)

  const handleHoverOn = () => {
    if (hoverOffTimer) {
      clearTimeout(hoverOffTimer)
      hoverOffTimer = null
    }
  }

  const handleHoverOff = () => {
    hoverOffTimer = setTimeout(() => {
      setOpen(false)
    }, 500)
  }

  return (
    <AvailabilitySelecter
      className={'availabilitySelecter'}
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
      onClick={event => {
        if (!disabled) {
          event.stopPropagation()
        }
      }}
    >
      {Boolean(labelElement) ? (
        labelElement({ containerRef: columnRef, togglePopover })
      ) : (
        <Selected
          ref={columnRef}
          className={cn({ isActive, isInactive: !isActive })}
          {...attributes || {}}
          onClick={togglePopover}
        >
          <Button
            className={cn('mdi', { 'mdi-menu-up': open, 'mdi-menu-down': !open })}
            style={!disabled ? { opacity: 1 } : undefined}
          />
          {selectedLabel}
        </Selected>
      )}

      {open && (
        <WindowPopover bindToRef={columnRef}>
          <DropDown>
            {sections.map(section => (
              <React.Fragment key={section.title}>
                <span>{section.title}</span>
                <ul>
                  {section.items.map(({ label, value }) => {
                    const active = value === selected

                    return (
                      <li
                        key={value}
                        className={cn('mdi mdi-checkbox-blank-circle', { active })}
                        onClick={active ? undefined : () => onSelect(value)}
                      >
                        {label}
                      </li>
                    )
                  })}
                </ul>
              </React.Fragment>
            ))}
          </DropDown>
        </WindowPopover>
      )}
    </AvailabilitySelecter>
  )
}
