import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { Portal } from 'react-portal'

export const Toasts = () => {
  return (
    <Portal>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnHover={true}
      />
    </Portal>
  )
}
