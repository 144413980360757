import * as React from 'react'
import { useDateDuration } from '../../../../../../../../hooks/useDateDuration'
import { SpecialColumn } from './SpecialCokumn'

type Props = {
  startTime: string
  color: string
  isTwoActivitiesView?: boolean
}

export const LapseTimeColumn = React.memo((props: Props) => {
  const { startTime, color, isTwoActivitiesView } = props
  const duration = useDateDuration({ date: startTime, delay: 60 * 1000 })

  return (
    <SpecialColumn
      title={isTwoActivitiesView ? 'LT' : 'LAPSE TIME'}
      value={duration}
      color={color}
      isTwoActivitiesView={isTwoActivitiesView}
    />
  )
})
