export namespace GridItemTab {
  export enum Driver {
    General = 'General',
    Credentials = 'Credentials',
    Accounting = 'Accounting',
    Documents = 'Documents'
  }
  export enum SubClient {
    General = 'General',
    Contacts = 'Contacts'
  }
  export enum SteamShipLine {
    General = 'General',
    Contacts = 'Contacts'
  }
  export enum Location {
    General = 'General',
    Contacts = 'Contacts'
  }
  export enum PowerUnit {
    General = 'General',
    Credentials = 'Credentials',
    Repairs = 'Repairs'
  }
  export enum Equipment {
    General = 'General',
    Credentials = 'Credentials',
    Repairs = 'Repairs'
  }
  export enum DispatchDeliveryOrder {
    General = 'General',
    Activities = 'Activities',
    Documents = 'Documents',
    Description = 'Description',
    AuditTrail = 'Audit Trail',
    TerminalNotes = 'Terminal Notes'
  }
  export enum Customer {
    General = 'General',
    Contacts = 'Contacts',
    SubClients = 'SubClients'
  }
  export enum Container {
    General = 'General'
  }
}
