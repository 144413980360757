import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { Whitespace } from '../../../../services/keyboardService/keys'
import { Icon, IconMode, IconName } from '../Icon'
import { preventEvent } from '../../../../services/functions/basic'

type Props = {
  title: string
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  innerSearchPlaceholder?: string
  filter?: string | symbol
  setFilter?: React.Dispatch<React.SetStateAction<any>>
  filterItems?: (string | symbol)[]
  isAllAreaSearch?: boolean
}

export const NavigationChannelsSectionSearch = (props: Props) => {
  const { title, search, setSearch, innerSearchPlaceholder, filter, setFilter, filterItems, isAllAreaSearch } = props
  const innerSearchInputRef = React.useRef(null)
  const tooltipRef = React.useRef(null)
  const hasFilter = Boolean(setFilter && filterItems)
  const isSearchActive = Boolean(search)

  const activateSearchInputOnClick = (event: any) => {
    preventEvent(event)
    if (innerSearchInputRef && innerSearchInputRef.current) {
      innerSearchInputRef.current.focus()
    }
  }

  return (
    <>
      <HeaderSearchContainer
        className={cn({ isAllAreaSearch, isSearchActive, hasFilter })}
        onClick={isAllAreaSearch && !isSearchActive ? activateSearchInputOnClick : undefined}
      >
        <HeaderSearchInput
          ref={innerSearchInputRef}
          className={cn({ active: isSearchActive })}
          value={search}
          onKeyDown={e => {
            if (e.key === Whitespace.Enter || e.key === Whitespace.Escape) {
              innerSearchInputRef.current.blur()
            }
          }}
          onChange={e => setSearch(e.target.value)}
          placeholder={innerSearchPlaceholder || 'Search...'}
        />
        <HeaderTitle>{title}</HeaderTitle>
        {isSearchActive ? (
          <Icon
            icon={IconName.CLEAR}
            fontSize={24}
            style={{ position: 'absolute', right: hasFilter ? 50 : 15 }}
            mode={IconMode.LIGHT}
            options={{ onMouseDown: preventEvent }}
            onClick={event => {
              activateSearchInputOnClick(event)
              setSearch('')
            }}
          />
        ) : (
          <Icon
            icon={IconName.SEARCH}
            fontSize={24}
            style={{ position: 'absolute', right: hasFilter ? 50 : 15 }}
            mode={IconMode.LIGHT}
            options={{ onMouseDown: preventEvent }}
            onClick={isAllAreaSearch ? () => {} : activateSearchInputOnClick}
          />
        )}
        {hasFilter && (
          <>
            <Icon
              icon={IconName.FILTER}
              fontSize={24}
              style={{ position: 'absolute', right: 15 }}
              mode={IconMode.LIGHT}
              options={{
                onMouseDown: preventEvent,
                'data-for': 'navigation-channels-section-filter',
                'data-tip': 'true'
              }}
              onClick={e => {
                preventEvent(e)
                if (innerSearchInputRef && innerSearchInputRef.current) {
                  innerSearchInputRef.current.focus()
                }
                if (isSearchActive) {
                  setSearch('')
                }
              }}
            />
          </>
        )}
      </HeaderSearchContainer>

      {hasFilter && (
        <ReactTooltip
          className={'react-tooltip-custom-inner'}
          id={'navigation-channels-section-filter'}
          place={'bottom'}
          event={'click'}
          globalEventOff={'click'}
          offset={{ left: 10, right: 10, top: 10, bottom: 10 }}
          clickable={true}
          ref={tooltipRef}
        >
          <FilterPopup>
            {filterItems.map(item => (
              <FilterPopupItem
                key={String(item)}
                className={cn('mdi mdi-check-circle', { active: filter === item })}
                onClick={
                  filter === item
                    ? undefined
                    : () => {
                        if (tooltipRef && tooltipRef.current) {
                          tooltipRef.current.tooltipRef = null
                          ReactTooltip.hide()
                        }

                        setFilter(item)
                      }
                }
              >
                {String(item)}
              </FilterPopupItem>
            ))}
          </FilterPopup>
        </ReactTooltip>
      )}
    </>
  )
}

const HeaderSearchContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 3px 15px 3px 5px;
  margin-top: 12px;

  &.isAllAreaSearch {
    cursor: pointer;
  }

  &.hasFilter {
    padding-right: 45px;
  }

  /* &.isSearchActive + *:empty {
    overflow: unset;

    &:after {
      content: 'No such drivers';
      display: block;
      text-align: center;
      font-size: 16px;
      color: #ffffff50;
      padding-top: 30px;
    }
  } */
`
const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  opacity: 0.5;
  color: white;
  padding-left: 10px;
`
const HeaderSearchInput = styled.input`
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
  overflow: hidden;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &:focus,
  &.active {
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(198, 204, 227, 0.5);
    background-color: #fff;
    padding: 0 30px 0 8px;
    font-size: 14px;

    & + * {
      display: none;
    }

    & ~ i.mdi-close-circle {
      color: #445366;
    }
  }
`
const FilterPopup = styled.div`
  min-width: 150px;
  background: white;
  box-shadow: 0px 2px 12px rgba(68, 83, 102, 0.3);
  border-radius: 4px;
  padding: 4px 0;
  user-select: none;
`
const FilterPopupItem = styled.div`
  min-height: 26px;
  display: flex;
  align-items: center;
  color: #131313;
  font-size: 10px;
  padding: 0 10px;
  cursor: pointer;

  &:before {
    visibility: hidden;
    font-size: 16px;
    margin-right: 10px;
  }

  &.active {
    cursor: default;

    &:before {
      visibility: unset;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
