import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { EntityType, TListsStateUpdate } from '../../interfaces'
import { LocationViewDTO, OngoingActivityGroupDTO } from '../../../../../api/api'
import { generateEmptyListsState } from '../../../../../services/functions/generate/generateEmptyListsState'
import { parseLocations } from './parseLocations'
import { mergeParsedLists } from './mergeParsedLists'

const parseOngoingActivities = (
  ongoingActivityType:
    | EntityType.ongoingActivityDateStage
    | EntityType.ongoingActivityDateStageNow
    | EntityType.ongoingActivityDateDriver
) => async (ongoingActivities: OngoingActivityGroupDTO[]): Promise<TListsStateUpdate> => {
  const result = generateEmptyListsState()
  const locationsToParse: LocationViewDTO[] = []

  try {
    ongoingActivities.forEach(ongoingActivity => {
      const ongoingLocations = [
        oc(ongoingActivity).pickupActivity.destination(),
        oc(ongoingActivity).deliveryActivity.destination(),
        oc(ongoingActivity).returnActivity.destination(),
        oc(ongoingActivity).extraActivity.destination()
      ].filter(Boolean)

      if (ongoingLocations.length) {
        // @ts-ignore
        locationsToParse.push(...ongoingLocations)
      }

      // @ts-ignore
      result[ongoingActivityType].push(ongoingActivity)
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  if (locationsToParse.length) {
    await parseLocations(locationsToParse).then(mergeParsedLists(result))
  }

  return Promise.resolve(result)
}

export const parseOngoingActivitiesDateStage = parseOngoingActivities(EntityType.ongoingActivityDateStage)
export const parseOngoingActivitiesDateStageNow = parseOngoingActivities(EntityType.ongoingActivityDateStageNow)
export const parseOngoingActivitiesDateDriver = parseOngoingActivities(EntityType.ongoingActivityDateDriver)
