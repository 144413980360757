import * as React from 'react'

export const NotificationSectionIcon = ({ color }: { color?: string }) => (
  // tslint:disable:max-line-length
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4001 2.49999V8.89999C13.4001 9.78365 12.6838 10.5 11.8001 10.5H7.8001L3.8001 13.7V10.5H2.2001C1.31644 10.5 0.600098 9.78365 0.600098 8.89999V2.49999C0.600098 1.61634 1.31644 0.899994 2.2001 0.899994H11.8001C12.6838 0.899994 13.4001 1.61634 13.4001 2.49999ZM4.6001 4.89999H3.0001V6.49999H4.6001V4.89999ZM6.2001 4.89999H7.8001V6.49999H6.2001V4.89999ZM11.0001 4.89999H9.4001V6.49999H11.0001V4.89999Z"
      fill={color || '#FFFFFF'}
    />
  </svg>
)
