import { oc } from 'ts-optchain'
import { getExpandedItemState, getListsState } from '../../../store'
import { EntityType, TListsState } from '../../../store/reducers/lists/interfaces'

type Props = {
  id: string
  entityType: EntityType
  storeLists?: TListsState
  modifiedLists?: Partial<TListsState>
}

export const getCurrentListItemState = (props: Props): any => {
  const { id, entityType } = props

  if (id && entityType) {
    const storeLists = props.storeLists || getListsState()
    const modifiedLists = props.modifiedLists || getExpandedItemState().modifiedLists

    return oc(modifiedLists)[entityType][id]() || oc(storeLists)[entityType][id]()
  }
}
