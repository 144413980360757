import { RootState } from '..'
import { BuySideQuoteDTO } from '../../api/api'
import { EntityType } from '../reducers/lists/interfaces'

// SELECT ONE
export const selectBSQ = (id: string) => (state: RootState): BuySideQuoteDTO | undefined => {
  return state.lists[EntityType.buySideQuote][id]
}

// SELECT MANY
export const selectBSQs = (state: RootState): Record<string, BuySideQuoteDTO> => {
  return state.lists[EntityType.buySideQuote]
}
