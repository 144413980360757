import * as React from 'react'
import { BlockTitle, RuleContent } from '../styles'
import { Input } from '../../../../../../../../UI/DataFields/Input'
import { ConditionList } from './Conditions'
import { TableBody } from '../../../../../Grid/styles'
import { TRuleCondition } from './Conditions/Condition'

type Props = {
  ruleNumber: string | number
  onTitleChange: (value: string) => void
  conditionList: TRuleCondition[]
}

export const RuleBody = (props: Props) => {
  const { conditionList, onTitleChange, ruleNumber } = props
  return (
    <TableBody style={{ height: 'calc(100% - 60px)' }}>
      <RuleContent className={'special-fields-container'}>
        <BlockTitle className={'border-bottom'} style={{ paddingBottom: 15 }}>
          Rule ID {ruleNumber}
        </BlockTitle>
        <BlockTitle>Rule Name</BlockTitle>
        <div style={{ width: 250 }}>
          <Input value={null} title={null ? 'Name' : null} placeholder={'Name'} onChange={onTitleChange} />
        </div>
        <ConditionList list={conditionList} />
      </RuleContent>
    </TableBody>
  )
}
