import * as React from 'react'
import { SpecialColumn } from './SpecialCokumn'
import { TDateServiceMakeLabelOptions, dateService } from '../../../../../../../../services/timeService'
import { activityDirectory } from '../../../../../../../../services/DTO/activity/directory'
import { LocalDateTimeRangeDTO, TransportationActivityViewDTO } from '../../../../../../../../api/api'
import theme from '../../../../../../../../styles/theme'

type Props = {
  isCurrentPhase: boolean
  deliveryPlannedAppointmentDateConfirmed: boolean
  isActive: boolean
  isTwoActivitiesView: boolean
  isExtraActivity: boolean
  plannedDateFrom: string
  plannedDate: LocalDateTimeRangeDTO
  stage: TransportationActivityViewDTO.StageEnum
  getDateOptions: (date: string, longSpace?: boolean) => TDateServiceMakeLabelOptions
}

export const PlannedDate = React.memo((props: Props) => {
  const {
    isCurrentPhase,
    deliveryPlannedAppointmentDateConfirmed,
    plannedDateFrom,
    isTwoActivitiesView,
    isExtraActivity,
    isActive,
    stage,
    plannedDate,
    getDateOptions
  } = props

  if (!plannedDateFrom) {
    return <>&nbsp;</>
  }

  return isActive || (isTwoActivitiesView && isExtraActivity) ? (
    <SpecialColumn
      title={'PLANNED'}
      dataId={`phaseDate=${plannedDateFrom} plannedDate=${plannedDateFrom} `}
      value={dateService.makeLabel(plannedDate, getDateOptions(plannedDateFrom, true))}
      color={activityDirectory.stageStrongColor[stage]}
      isTwoActivitiesView={isTwoActivitiesView}
    />
  ) : (
    <>
      <span
        style={{
          fontWeight: isCurrentPhase ? 500 : undefined,
          color:
            deliveryPlannedAppointmentDateConfirmed === false
              ? theme.colors.defaultRed
              : isCurrentPhase
              ? theme.colors.basicBlueColor
              : undefined
        }}
      >
        {dateService.makeLabel(plannedDate, getDateOptions(plannedDateFrom))}
      </span>
      <span style={{ marginLeft: 5, fontWeight: 300 }}>Planned</span>
    </>
  )
})
