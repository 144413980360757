import { BuySideQuoteSurchargeDTO, SellSideQuoteSurchargeDTO } from '../../../api/api'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../api/origin/qmp-service'
import { CombinedSurchargeRateDTO } from '../../DTO/rate/interfaces'

type Props = {
  rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO
  surcharge: SellSideQuoteSurchargeDTO | BuySideQuoteSurchargeDTO
}

export const generateCombinedSurchargeRate = (props: Props): CombinedSurchargeRateDTO => {
  const { rate, surcharge } = props
  // @ts-ignore
  return { ...(rate || {}), ...(surcharge || {}) }
}
