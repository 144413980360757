import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { LocalStorageOptions, LocalStorageProp } from './types'
import { LocalStorageUser } from './LocalStorageUser'

import { TabDTO } from '../../../store/reducers/tabs/interfaces'

export type TRecentObjects = {
  [tabType: string]: string[]
}

interface ILocalStorageRecent {
  getRecent(): TRecentObjects
  getRecentForTabType(tabType: TabDTO.Type): string[]
  pushRecentForTabType(tabType: TabDTO.Type, id: string): string[]
}

export class LocalStorageRecent extends LocalStorageUser implements ILocalStorageRecent {
  private maxLength = 20

  constructor(options: LocalStorageOptions) {
    super(options.user)
  }

  private saveRecent(recent: TRecentObjects) {
    if (!this.userId) {
      return
    }

    localStorage.setItem(LocalStorageProp.recent + '.' + this.userId, JSON.stringify(recent))
  }

  public getRecent(): TRecentObjects {
    if (!this.userId) {
      return {}
    }

    return JSON.parse(localStorage.getItem(LocalStorageProp.recent + '.' + this.userId)) || {}
  }

  public getRecentForTabType(tabType: TabDTO.Type) {
    const recent = this.getRecent()
    return recent[tabType] || []
  }

  public pushRecentForTabType(tabType: TabDTO.Type, id: string) {
    if (!this.userId) {
      return
    }

    const recent = this.getRecent()
    let recentForTabType = oc(recent)[tabType]([])
    recentForTabType.unshift(id)
    recentForTabType = R.uniq(recentForTabType)

    if (recentForTabType.length > this.maxLength) {
      recentForTabType.length = this.maxLength
    }

    recent[tabType] = recentForTabType
    this.saveRecent(recent)

    return recent[tabType]
  }
}
