import * as React from 'react'
import { SearchByList, TDropDownItem } from '../../../SearchByList'
// @ts-ignore
import { debounce } from 'debounce'

type OwnProps = {
  disabled?: boolean
  title?: string
  required?: boolean
  label: any
  onChange: (value: any) => void
  placeholder?: string
  symbolsNumberToFetch?: number
  getList: (inputValue: string) => Promise<any>
  makeDropDownItem: (searchItem: any) => TDropDownItem
  filtering?: boolean
  highlighted?: boolean
  focus?: boolean
  maxLength?: number
}

type DispatchProps = {
  addToStoreList?: (object: any) => void
}

type Props = OwnProps & DispatchProps

export const SearchRequest = ({
  disabled,
  title,
  required,
  label,
  onChange,
  placeholder,
  symbolsNumberToFetch = 3,
  makeDropDownItem,
  getList,
  filtering,
  highlighted,
  focus,
  maxLength
}: Props) => {
  const [fetching, setFetching] = React.useState(false)
  const commonProps = {
    title,
    required,
    label,
    placeholder,
    symbolsNumberToFetch,
    filtering,
    highlighted,
    disabled,
    maxLength,
    focus
  }
  const [dropDownList, setDropDownList] = React.useState([])

  // FIND ALL ITEMS
  const getListRequest = (searchValue: string) => {
    setFetching(true)
    getList(searchValue.trim())
      .then(list => {
        setDropDownList(list.map(makeDropDownItem))
      })
      .finally(() => {
        setFetching(false)
      })
  }

  // GET ALL BUTTON
  const findAll = () => getListRequest('')

  // HANDLE INPUT CHANGE
  const handleInputChange = (inputValue: string) => {
    if (inputValue.length >= symbolsNumberToFetch) {
      getListRequest(inputValue)
    } else if (inputValue.length !== 0) {
      setDropDownList([])
    }
  }

  return (
    <SearchByList
      {...commonProps}
      onChange={onChange}
      isFetching={fetching}
      handleInputChange={debounce(handleInputChange, 350)}
      dropDownList={dropDownList}
      findAll={findAll}
    />
  )
}
