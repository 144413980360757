import * as React from 'react'
import { TextLine } from '../PreviewPhase'
import theme from '../../../../../../../../styles/theme'
import { ActivityNotesButton } from './ActivityNotesButton'

type Props = {
  isActive: boolean
  isPending: boolean
  isAssigned: boolean
  isUnsuccessful: boolean
  driverName: string
  activityStatusText: JSX.Element | string
  hasActivityNotes: boolean
}

export const OneActivityView = React.memo((props: Props) => {
  const { isActive, isPending, isAssigned, isUnsuccessful, driverName, activityStatusText, hasActivityNotes } = props
  return (
    <>
      <TextLine style={{ width: '100%', fontSize: 13 }}>
        {driverName}
        {activityStatusText && (
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 3,
              color: isUnsuccessful ? theme.colors.defaultRed : undefined,
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {activityStatusText}
          </div>
        )}
      </TextLine>
      {hasActivityNotes && (
        <ActivityNotesButton
          active={isActive || isPending || isAssigned}
          style={{ position: 'absolute', bottom: 7, right: 7 }}
        />
      )}
    </>
  )
})
