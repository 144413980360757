// tslint:disable:max-line-length
import LocationColumn from '../../common/location/views/Column/'
import PowerUnitColumn from '../../common/powerUnit/views/Column/'
import EquipmentColumn from '../../common/equipment/views/Column/'
import ContainerColumn from '../../common/containers/views/Column/'
import DriverColumn from '../../common/drivers/views/Column/'
import CustomerColumn from '../../common/customer/views/Column/'
import SteamShipLineColumn from '../../common/steamShipLine/views/Column/'
import SubClientColumn from '../../common/subClient/views/Column/'
import dispatchDeliveryOrderColumn from '../../common/dispatchDeliveryOrder/views/Column/'
import { LocationPreview } from '../../common/location/views/Preview'
import { PowerUnitPreview } from '../../common/powerUnit/views/Preview'
import { EquipmentPreview } from '../../common/equipment/views/Preview'
import { ContainerPreview } from '../../common/containers/views/Preview'
import { DriverPreview } from '../../common/drivers/views/Preview'
import { CustomerPreview } from '../../common/customer/views/Preview'
import { SteamShipLinePreview } from '../../common/steamShipLine/views/Preview'
import { SubClientPreview } from '../../common/subClient/views/Preview'
import { DispatchDeliveryOrderPreview } from '../../common/dispatchDeliveryOrder/views/Preview'
import { TabDTO } from '../../../store/reducers/tabs/interfaces'
import { customerColumnSettings } from '../../common/grid/columnSettings/customerColumnSettings'
import { driverColumnSettings } from '../../common/grid/columnSettings/driverColumnSettings'
import { containerColumnSettings } from '../../common/grid/columnSettings/containerColumnSettings'
import { locationColumnSettings } from '../../common/grid/columnSettings/locationColumnSettings'
import { equipmentColumnSettings } from '../../common/grid/columnSettings/equipmentColumnSettings'
import { powerUnitColumnSettings } from '../../common/grid/columnSettings/powerUnitColumnSettings'
import { steamShipLineColumnSettings } from '../../common/grid/columnSettings/steamShipLineColumnSettings'
import { subClientColumnSettings } from '../../common/grid/columnSettings/subClientColumnSettings'
import { dispatchDeliveryOrderColumnSettings } from '../../common/grid/columnSettings/dispatchDeliveryOrderColumnSettings'

export const columnSettings = {
  [TabDTO.Type.dispatchDeliveryOrder]: dispatchDeliveryOrderColumnSettings,
  [TabDTO.Type.driver]: driverColumnSettings,
  [TabDTO.Type.container]: containerColumnSettings,
  [TabDTO.Type.powerUnit]: powerUnitColumnSettings,
  [TabDTO.Type.equipment]: equipmentColumnSettings,
  [TabDTO.Type.location]: locationColumnSettings,
  [TabDTO.Type.customer]: customerColumnSettings,
  [TabDTO.Type.steamShipLine]: steamShipLineColumnSettings,
  [TabDTO.Type.subClient]: subClientColumnSettings
}

export const columnsValues = {
  [TabDTO.Type.location]: LocationColumn,
  [TabDTO.Type.powerUnit]: PowerUnitColumn,
  [TabDTO.Type.equipment]: EquipmentColumn,
  [TabDTO.Type.container]: ContainerColumn,
  [TabDTO.Type.driver]: DriverColumn,
  [TabDTO.Type.customer]: CustomerColumn,
  [TabDTO.Type.steamShipLine]: SteamShipLineColumn,
  [TabDTO.Type.subClient]: SubClientColumn,
  [TabDTO.Type.dispatchDeliveryOrder]: dispatchDeliveryOrderColumn
}

export const gridPreviews = {
  [TabDTO.Type.location]: LocationPreview,
  [TabDTO.Type.powerUnit]: PowerUnitPreview,
  [TabDTO.Type.equipment]: EquipmentPreview,
  [TabDTO.Type.container]: ContainerPreview,
  [TabDTO.Type.driver]: DriverPreview,
  [TabDTO.Type.customer]: CustomerPreview,
  [TabDTO.Type.steamShipLine]: SteamShipLinePreview,
  [TabDTO.Type.subClient]: SubClientPreview,
  [TabDTO.Type.dispatchDeliveryOrder]: DispatchDeliveryOrderPreview
}

export const gridItemHeaders = {
  [TabDTO.Type.location]: LocationPreview.headings,
  [TabDTO.Type.powerUnit]: PowerUnitPreview.headings,
  [TabDTO.Type.equipment]: EquipmentPreview.headings,
  [TabDTO.Type.container]: ContainerPreview.headings,
  [TabDTO.Type.driver]: DriverPreview.headings,
  [TabDTO.Type.customer]: CustomerPreview.headings,
  [TabDTO.Type.steamShipLine]: SteamShipLinePreview.headings,
  [TabDTO.Type.subClient]: SubClientPreview.headings,
  [TabDTO.Type.dispatchDeliveryOrder]: DispatchDeliveryOrderPreview.headings
}
