import * as React from 'react'
import { Select } from '../../../../../../../UI/DataFields/Select'
import styled from 'styled-components'
import { ActionButton } from '../../../../../../../UI/Buttons/ActionButton'
import { getContainerYardLocations } from '../../../../../../../../services/functions/get/getContainerYardLocations'
import { TLocation } from '../../../../../../location/interfaces'

type Props = {
  clearState: () => void
  shuttleAction: (destination: TLocation) => void
}

export const SelectShuttlePopup = (props: Props) => {
  const { clearState, shuttleAction } = props
  const locations = getContainerYardLocations({ active: true })
  const [selectedLocationId, setSelectedLocation] = React.useState(null)

  React.useEffect(() => {
    if (locations.length === 1) {
      shuttleAction(locations[0])
      clearState()
    }
  }, [])

  return (
    <Overlay>
      <ShuttlePopup>
        <Select
          title={'Container yard'}
          list={locations.map(location => ({ value: location.id, label: location.name }))}
          selectedValue={selectedLocationId}
          onSelect={setSelectedLocation}
        />
        <Actions>
          <ActionButton outline={true} onClick={clearState}>
            Cancel
          </ActionButton>
          <ActionButton
            disabled={!selectedLocationId}
            filled={true}
            onClick={() => {
              shuttleAction(locations.find(_ => _.id === selectedLocationId))
              clearState()
            }}
          >
            Apply
          </ActionButton>
        </Actions>
      </ShuttlePopup>
    </Overlay>
  )
}

const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`

const ShuttlePopup = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
`
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`
