import * as React from 'react'
import { oc } from 'ts-optchain'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useAppSelector } from './useAppSelector'
import { selectBSQs } from '../store/select/bsqSelect'

type Props = {
  ids: string[]
  modifiedLists?: Partial<TListsState>
}

export const useBuySideQuotes = (props: Props) => {
  const { ids, modifiedLists } = props
  const storeBSQMapping = useAppSelector(selectBSQs)
  const modifiedBSQMapping = oc(modifiedLists)[EntityType.buySideQuote]({})

  const buySideQuotes = React.useMemo(() => {
    return ids.map(id => modifiedBSQMapping[id] || storeBSQMapping[id]).filter(Boolean)
  }, [ids, storeBSQMapping, oc(modifiedLists)[EntityType.buySideQuote]()])

  return buySideQuotes
}
