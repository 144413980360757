import * as React from 'react'
import { General } from '../Details/General'
import { PowerUnitCredentials } from '../Details/Credentials'
import { Repairs } from '../Details/Repairs'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.PowerUnit

export const PowerUnitPreview = {
  headings: [T.General, T.Credentials, T.Repairs],
  tabs: {
    [T.General]: <General />,
    [T.Credentials]: <PowerUnitCredentials />,
    [T.Repairs]: <Repairs />
  },
  isValid: checkValidation.powerUnit
}
