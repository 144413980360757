import * as React from 'react'
import { NavigationAllUnread } from './NavigationAllUnread'
import { NavigationChannelsSection } from './NavigationChannelsSection'
import { NavigationChannelsSectionBreakLine } from './Navigation'
import { ActiveChatChannelIdSpecialTypes, ChannelType } from '../../../../store/reducers/communicationHub/interfaces'
import { NavigationChannel } from './NavigationChannel'
import { NavigationNotifications } from './NavigationNotifications'

export const NavigationMessenger = React.memo(() => {
  return (
    <>
      {/* <NavigationAllUnread /> */}
      <NavigationNotifications />
      {/* <NavigationChannelsSectionBreakLine /> */}
      <NavigationChannelsSection channelType={ChannelType.recentlyOpened} maxHeight={200} collapsible={true} />
      <NavigationChannelsSectionBreakLine />
      <NavigationChannelsSection channelType={ChannelType.dispatchDeliveryOrder} collapsible={true} />
    </>
  )
})
