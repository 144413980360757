import * as React from 'react'
import * as R from 'remeda'
import {
  CommunicationHubDraftMessageContext,
  ICommunicationHubDraftMessageContext
} from '../contexts/CommunicationHubDraftMessageContext'
import { IDraftMessage, IDraftMessages, IEditMessage } from '../store/reducers/communicationHub/interfaces'
import { EditorState } from 'draft-js'
import { IAttachment } from '../services/filesService/interfaces'
import { isEditorEmpty } from '../components/CommunicationHub/views/Editor/ReactInput'

type OwnProps = {
  children?: any
}

type StateProps = {}

type Props = OwnProps & StateProps

export const CommunicationHubDraftMessageProvider = React.memo((props: Props) => {
  const [draftMessages, setDraftMessages] = React.useState<IDraftMessages>({})
  const [editMessage, setEditMessage] = React.useState<IEditMessage>(null)

  const actions = React.useMemo(
    (): ICommunicationHubDraftMessageContext => ({
      editMessage: undefined,
      draftMessages: undefined,
      setDraftMessageState({ channelId, draftMessage }) {
        setDraftMessages(state => (draftMessage ? { ...state, [channelId]: draftMessage } : R.omit(state, [channelId])))
      },
      setDraftMessageEditor({ channelId, editor }) {
        setDraftMessages(state => {
          const prevDraftMessageValue = state[channelId] || defaultDraftMessageState()

          if (!prevDraftMessageValue.attachments.length && isEditorEmpty(editor)) {
            return R.omit(state, [channelId])
          }

          return {
            ...state,
            [channelId]: {
              ...prevDraftMessageValue,
              editor
            }
          }
        })
      },
      setDraftMessageAttachment({ channelId, attachment }) {
        setDraftMessages(state => {
          const prevDraftMessageValue = state[channelId] || defaultDraftMessageState()

          return {
            ...state,
            [channelId]: {
              ...prevDraftMessageValue,
              attachments: setAttachment(prevDraftMessageValue.attachments, attachment)
            }
          }
        })
      },
      removeDraftMessageAttachmentId({ channelId, attachmentId }) {
        setDraftMessages(state => {
          const prevDraftMessageValue = state[channelId] || defaultDraftMessageState()
          const attachments = prevDraftMessageValue.attachments.filter(({ id }) => id !== attachmentId)

          if (!attachments.length && isEditorEmpty(prevDraftMessageValue.editor)) {
            return R.omit(state, [channelId])
          }

          return {
            ...state,
            [channelId]: {
              ...prevDraftMessageValue,
              attachments
            }
          }
        })
      },
      finishDraftMessageAttachmentUploading({ channelId, temporaryAttachmentId, attachment }) {
        setDraftMessages(state => {
          const prevDraftMessageValue = state[channelId] || defaultDraftMessageState()

          return {
            ...state,
            [channelId]: {
              ...prevDraftMessageValue,
              attachments: prevDraftMessageValue.attachments.map(a => (a.id === temporaryAttachmentId ? attachment : a))
            }
          }
        })
      },
      setEditMessageState({ state }) {
        setEditMessage(state)
      },
      setEditMessageEditor({ editor }) {
        setEditMessage(state =>
          state
            ? {
                ...state,
                editor
              }
            : state
        )
      },
      setEditMessageAttachment({ attachment }) {
        setEditMessage(state => ({
          ...state,
          attachments: setAttachment(state.attachments, attachment)
        }))
      },
      removeEditMessageAttachmentId({ attachmentId }) {
        setEditMessage(state => {
          const attachments = state.attachments.filter(({ id }) => id !== attachmentId)

          if (!attachments.length && isEditorEmpty(state.editor)) {
            return undefined
          }

          return {
            ...state,
            attachments
          }
        })
      },
      finishEditMessageAttachmentUploading({ temporaryAttachmentId, attachment }) {
        setEditMessage(state => ({
          ...state,
          attachments: state.attachments.map(a => (a.id === temporaryAttachmentId ? attachment : a))
        }))
      }
    }),
    []
  )

  return (
    <CommunicationHubDraftMessageContext.Provider
      value={{
        ...actions,
        draftMessages,
        editMessage
      }}
    >
      {props.children}
    </CommunicationHubDraftMessageContext.Provider>
  )
})

export const defaultDraftMessageState = (): IDraftMessage => ({
  editor: EditorState.createEmpty(),
  attachments: []
})

const setAttachment = (attachments: IAttachment[], attachment: IAttachment): IAttachment[] => {
  let isNewAttachment = true

  const updatedAttachments = attachments.map(a => {
    if (a.id === attachment.id) {
      isNewAttachment = false
      return attachment
    } else {
      return a
    }
  })

  if (isNewAttachment) {
    updatedAttachments.push(attachment)
  }

  return updatedAttachments
}
