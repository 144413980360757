import { EquipmentDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateEquipment = (props?: Partial<EquipmentDTO>): EquipmentDTO => {
  return {
    completeness: maxCompleteness,
    active: true,
    id: createId(),
    inactiveReason: null,
    availability: true,
    unavailabilityReason: null,
    equipmentType: null,
    equipmentSubType: null,
    year: null,
    make: null,
    chassisNumber: null,
    model: null,
    vin: null,
    weight: null,
    weightUnit: EquipmentDTO.WeightUnitEnum.LBS,
    specs: null,
    ownership: null,
    licensePlate: null,
    registration: {
      registeredDate: null,
      expirationDate: null,
      stateId: null
    },
    dotSafetyInspection: {
      performedDate: null,
      expirationDate: null
    },
    insurance: {
      insuranceType: null,
      carrier: null,
      expirationDate: null,
      policyNumber: null
    },
    maintenanceInspectionFrequency: null,
    maintenanceInspections: [],
    repairs: [],
    ...(props || {})
  }
}
