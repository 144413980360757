import * as React from 'react'
import { ContainerDTO, ContainerViewDTO } from '../../../../../api/origin/business-logic/api'
import { AvailabilityColumnSelecter } from '../../../../UI/oldAvailabilitySelecter'
import { useGridItemContainer } from '../../../../../hooks/useGridItemContainer'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'

const dropDownItems = {
  Active: ['Available', 'Not Available (Assigned)', 'Not Available (In-Use)'],
  Inactive: ['Repair']
}

type OwnProps = {}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const AvailabilityColumn = (props: Props) => {
  const { container, isExpanded } = useGridItemContainer()
  const { modifyParentObject } = useExpandedItem()
  let selectedItem: string

  if (container.active) {
    container.availability
      ? (selectedItem = 'Available')
      : container.unavailabilityReason === ContainerDTO.UnavailabilityReasonEnum.ASSIGNED
      ? (selectedItem = 'Not Available (Assigned)')
      : (selectedItem = 'Not Available (In-Use)')
  } else {
    switch (container.inactiveReason) {
      case ContainerDTO.InactiveReasonEnum.REPAIR:
        selectedItem = 'Repair'
        break
      default:
        break
    }
  }

  return (
    <AvailabilityColumnSelecter
      handleSelect={isExpanded ? handleSelect(container, modifyParentObject, selectedItem) : undefined}
      activeList={dropDownItems.Active}
      inactiveList={dropDownItems.Inactive}
      selectedItem={selectedItem}
      stateActive={container.active}
    />
  )
}

const handleSelect = (
  container: ContainerViewDTO,
  modifyContainer: (container: ContainerViewDTO) => void,
  selectedItem: any
) => (item: string) => {
  if (item !== selectedItem) {
    let updatedContainer: ContainerViewDTO

    switch (item) {
      case 'Available':
        updatedContainer = {
          ...container,
          active: true,
          inactiveReason: null,
          availability: true,
          unavailabilityReason: null
        }
        break
      case 'Not Available (Assigned)':
        updatedContainer = {
          ...container,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.ASSIGNED
        }
        break
      case 'Not Available (In-Use)':
        updatedContainer = {
          ...container,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.INUSE
        }
        break
      case 'Repair':
        updatedContainer = {
          ...container,
          active: false,
          inactiveReason: ContainerDTO.InactiveReasonEnum.REPAIR,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      default:
    }

    if (updatedContainer) {
      modifyContainer(updatedContainer)
    }
  }
}
