import styled from 'styled-components'
import { ClearButton } from '../../Input/styles'
import theme from '../../../../../styles/theme'

export const DateFieldContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 5px;
  border: 1px solid rgba(229, 229, 229, 1);
  font-size: 13px;
  color: #333333;
  background-color: #fff;
  padding-right: 25px;

  input {
    width: 100%;
    height: 33px;
    border: none;
    background: none;
  }

  .react-datepicker__input-container input {
    padding-left: 10px;
  }

  &.red-border {
    border-color: ${theme.colors.defaultRed};
  }

  &.red-text {
    color: red;
  }

  &.empty {
    padding-right: 0;
  }

  &.disabled {
    input::placeholder {
      color: transparent;
    }
  }
`

export const DateField = styled.div`
  flex-grow: 1;
  display: flex;
`

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 15px;
    background-color: #e2e5f1;
    margin-right: 10px;
  }

  input {
    padding: 0 !important;
  }
`
export const TimeField = styled.div`
  width: 36px;
  text-align: center;

  * {
    width: 100%;
  }
`
export const Separator = styled.div`
  &:before {
    display: inline-flex;
    align-items: center;
    content: '-';
    padding: 0 2px;
  }
`

export const ResetButton = styled(ClearButton)`
  position: absolute;
  right: 0;
  padding-right: 2px;
`
