import * as React from 'react'
import { oc } from 'ts-optchain'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TableCell } from '../../../grid/views/styles'
import { DriverColumn } from '../../../grid/columnSettings/driverColumnSettings'
import { useGridItemDriver } from '../../../../../hooks/useGridItemDriver'
import { getDateFromISOString } from '../../../../../services/timeService/dateUtils'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'
import { transformPowerUnit } from '../../../../../services/driverService'
import { generateAddressLabel } from '../../../../../services/functions/generate/generateAddressLabel'
import { getAddressState } from '../../../../../services/functions/get/getAddressState'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { getListsState } from '../../../../../store'
import { EntityType } from '../../../../../store/reducers/lists/interfaces'

const Column = (props: TGridColumn) => {
  const { className, columnName, style } = props
  const { driver } = useGridItemDriver()
  let text = columnName

  switch (columnName) {
    case DriverColumn.EmployeeNumber: {
      text = oc(driver).number()
      break
    }
    case DriverColumn.ActiveAvailability: {
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className}>
          <AvailabilityColumn />
        </TableCell>
      )
    }
    case DriverColumn.Name: {
      text = oc(driver).name()
      break
    }
    case DriverColumn.OperationalPreference: {
      text = String(driver.operationalPreference || '').toLowerCase()
      break
    }
    case DriverColumn.Vendor: {
      const parent = getListsState()[EntityType.driver][oc(driver).parentId()]
      text = oc(parent).name()
      break
    }
    case DriverColumn.MobileApp: {
      text = oc(driver).mobileApp() === true ? 'Yes' : oc(driver).mobileApp() === false ? 'No' : ''
      break
    }
    case DriverColumn.DateOfBirth: {
      text = getDateFromISOString(driver.birthday)
      break
    }
    case DriverColumn.MailingAddress: {
      text = generateAddressLabel(driver.mailingAddress)
      break
    }
    case DriverColumn.PhoneNumber: {
      text = oc(driver).phone()
      break
    }
    case DriverColumn.Email: {
      text = oc(driver).email()
      break
    }
    case DriverColumn.MilageRestrictions: {
      text = driverDirectory.millageRestriction[driver.millageRestriction]
      break
    }
    case DriverColumn.OwnedTruck: {
      text = transformPowerUnit(oc(driver).powerUnit())
      break
    }
    case DriverColumn.DriverType: {
      text = driverDirectory.type[driver.driverType]
      break
    }
    case DriverColumn.ELD: {
      text = driver.eld ? driver.eldNumber || 'Yes' : 'No'
      break
    }
    case DriverColumn.LicenseNumber: {
      text = oc(driver).licenseNumber()
      break
    }
    case DriverColumn.LicenseExpDate: {
      text = getDateFromISOString(driver.licenseExpDate)
      break
    }
    case DriverColumn.LicenseIssueDate: {
      text = getDateFromISOString(driver.licenseIssueDate)
      break
    }
    case DriverColumn.LicenseIssueState: {
      text = getAddressState({ stateId: driver.licenseIssueStateId }).code
      break
    }
    case DriverColumn.LicenseAddress: {
      text = generateAddressLabel(driver.licenseAddress)
      break
    }
    case DriverColumn.Endorsements: {
      text = (driver.endorsement || []).map(_ => driverDirectory.endorsement[_]).join(', ')
      break
    }
    case DriverColumn.EndorsementsExpDate: {
      text = getDateFromISOString(driver.endorsementExpDate)
      break
    }
    case DriverColumn.MedExamExpDate: {
      text = getDateFromISOString(driver.medicalExamCardExpDate)
      break
    }
    case DriverColumn.TWICExpDate: {
      text = getDateFromISOString(driver.twicExpDate)
      break
    }
    case DriverColumn.PortCredentialsExpDate: {
      text = getDateFromISOString(driver.portCredentialsExpDate)
      break
    }
    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
}

export default Column
