import styled from 'styled-components'
import theme from '../../../styles/theme'

export const Container = styled.div`
  z-index: 1;
  height: 0;
  width: 100%;
  position: absolute;
  left: -9px;
  top: -1px;
`
export const Indicator = styled.i`
  display: flex;
  align-items: center;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: -10px;
  color: ${theme.colors.basicBlueColor};
`
export const Success = styled(Indicator)`
  color: #45a59b;
`
export const Load = styled(Indicator)`
  @keyframes indicator {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation: indicator infinite 1.5s linear;
`
export const Information = styled(Indicator)`
  color: ${theme.colors.defaultRed};
`
export const Text = styled.p`
  width: 180px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 15px;
  right: 0;
  transform: translateY(-100%);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  color: ${theme.colors.defaultRed};
`
