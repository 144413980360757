import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

export const driverStatusColumnHintId = 'driverStatusColumnHintId'

export const DriverStatusColumnHint = () => {
  return (
    <ReactTooltip
      id={driverStatusColumnHintId}
      backgroundColor={'#8CA2AB'}
      className={'react-tooltip pre-line'}
      place={'right'}
    />
  )
}

const Dropdown = styled.div`
  width: 200px;
  background: white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 0;
`
const DropdownTitle = styled.div`
  font-size: 10px;
  color: #bdbdbd;
  padding: 0 10px;
  margin-bottom: 5px;
`
const DropdownOption = styled.div`
  box-sizing: content-box;
  height: 25px;
  display: flex;
  align-items: center;
  color: #484848;
  padding: 4px 6px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .mdi {
    font-size: 24px;
    margin-right: 6px;
  }
`

export const TroubleTicketActionsStyledComponents = {
  Dropdown,
  DropdownTitle,
  DropdownOption
}
