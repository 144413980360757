import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IModalState } from '../../../../store/store.interface'
import { closeModal, showModal } from '../actions'

const modalState: IModalState = {
  visible: false,
  message: '',
  msgType: '',
  onConfirm: () => {},
  onCancel: () => {}
}

const modalReducer = reducerWithInitialState(modalState)
  .case(showModal, (state: IModalState, { type, message, msgType, onConfirm, onCancel, buttonSettings }) => ({
    ...state,
    visible: Boolean(message),
    type,
    message,
    buttonSettings,
    msgType,
    onConfirm,
    onCancel
  }))
  .case(closeModal, (state: IModalState) => ({
    ...state,
    visible: false
  }))
  .build()

export default modalReducer
