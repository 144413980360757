import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'
import { connect } from 'react-redux'
import { IStore } from '../../../store/store.interface'
import { closeModal, showModal, TMsgType } from './actions'
import { Portal } from 'react-portal'
import { getStore } from '../../../store/configureStore'

// tslint:disable:no-var-requires
const combineIcon = require('../../../img/icons/modal/combine.svg')
const deleteIcon = require('../../../img/icons/modal/delete.svg')
const errorIcon = require('../../../img/icons/modal/error.svg')
const filterIcon = require('../../../img/icons/modal/filter.svg')
// tslint:enable:no-var-requires

const AlertScreen = styled.div`
  z-index: 12000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: default;
  text-align: center;
`

const Body = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const AlertBox = styled.div`
  width: 297px;
  padding: 95px 0 22px;
  background-color: ${theme.colors.white};
  border: solid 1px ${theme.colors.borderColor};
  box-shadow: 0 15px 67.5px 7.5px rgba(25, 28, 49, 0.3);
  background-repeat: no-repeat;
  background-size: auto 35px;
  background-position: center 41px;

  &.alert-combine {
    background-image: url(${combineIcon});
  }
  &.alert-delete {
    background-image: url(${deleteIcon});
  }
  &.alert-info {
    background-image: url(${errorIcon});
  }
  &.alert-warning {
    background-image: url(${filterIcon});
  }
`

// const AlertForCombine = styled(AlertBox)`
//   background-image: url(${combineIcon});
// `
// const AlertForDelete = styled(AlertBox)`
//   background-image: url(${deleteIcon});
// `
// const AlertForError = styled(AlertBox)`
//   background-image: url(${errorIcon});
// `
// const AlertForFilter = styled(AlertBox)`
//   background-image: url(${filterIcon});
// `

const Information = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 1.23;
  color: ${theme.colors.black};
  margin: 0 10px 20px;
`

const Button = styled.div`
  display: inline-block;
  border-radius: 5px;
  line-height: 1.2;
  min-width: 125px;
  letter-spacing: 0.8px;
  font-size: 13px;
  font-weight: bold;
  border: solid 1px ${theme.colors.borderColor};
  cursor: pointer;
  color: ${theme.colors.grey};
  padding: 6px 15px;
  margin: 10px 6px 0;

  &:hover {
    color: #fff;
  }

  &.alert__button_color_blue {
    &:hover {
      background-color: #4b83f0;
    }
  }
  &.alert__button_color_yellow {
    &:hover {
      background-color: #ffc107;
    }
  }
  &.alert__button_color_red {
    &:hover {
      background-color: #d50000;
    }
  }
`

const RedButton = styled(Button)`
  &:hover {
    background-color: #d50000;
  }
`
const YellowButton = styled(Button)`
  &:hover {
    background-color: #ffc107;
  }
`
const BlueButton = styled(Button)`
  &:hover {
    background-color: #4b83f0;
  }
`
const ErrorButton = styled(Button)`
  width: 167px;
  &:hover {
    background-color: #d50000;
  }
`

export enum AlertButtonColor {
  blue = 'blue',
  yellow = 'yellow',
  red = 'red'
}

type TButton = {
  label: string
  color?: AlertButtonColor
  action: () => void
}

export type TModalButtonSettings = {
  button1?: TButton
  button2?: TButton
  button3?: TButton
}

type Props = {
  visible: boolean
  message: string
  msgType: TMsgType | ''
  onConfirm?: () => void
  onCancel?: () => void
  closeModal?: () => void
  buttonSettings?: TModalButtonSettings
}
class Modal extends React.Component<Props> {
  handleConfirm = () => {
    this.props.closeModal()
    this.props.onConfirm()
  }

  handleCancel = () => {
    this.props.closeModal()
    this.props.onCancel()
  }

  renderButton = (buttonSettings: TButton) =>
    buttonSettings ? (
      <Button
        className={'alert__button_color_' + (buttonSettings.color || AlertButtonColor.blue)}
        onClick={() => {
          buttonSettings.action()
          this.props.closeModal()
        }}
      >
        {buttonSettings.label}
      </Button>
    ) : null

  renderButtons = () => {
    const { msgType, buttonSettings } = this.props

    if (buttonSettings) {
      return (
        <div>
          {this.renderButton(buttonSettings.button1)}
          {this.renderButton(buttonSettings.button2)}
          {this.renderButton(buttonSettings.button3)}
        </div>
      )
    }

    switch (msgType) {
      case TMsgType.delete:
        return (
          <div>
            <BlueButton
              onClick={() => {
                this.handleConfirm()
              }}
            >
              Confirm
            </BlueButton>
            <RedButton
              onClick={() => {
                this.handleCancel()
              }}
            >
              Cancel
            </RedButton>
          </div>
        )
      case TMsgType.info:
        return (
          <ErrorButton
            onClick={() => {
              this.handleCancel()
            }}
          >
            OK
          </ErrorButton>
        )
      case TMsgType.combine:
        return (
          <div>
            <BlueButton
              onClick={() => {
                this.handleConfirm()
              }}
            >
              Confirm
            </BlueButton>
            <RedButton
              onClick={() => {
                this.handleCancel()
              }}
            >
              Cancel
            </RedButton>
          </div>
        )
      case TMsgType.warning:
        return (
          <div>
            <YellowButton
              onClick={() => {
                this.handleConfirm()
              }}
            >
              I understood
            </YellowButton>
            <RedButton
              onClick={() => {
                this.handleCancel()
              }}
            >
              Clear selection
            </RedButton>
          </div>
        )
      default:
        return (
          <div>
            <YellowButton>I understood</YellowButton>
            <RedButton>Clear filters</RedButton>
          </div>
        )
    }
  }

  render() {
    const { msgType, visible, message } = this.props
    return visible ? (
      <Portal>
        <AlertScreen>
          <Body>
            <AlertBox className={'alert-' + msgType}>
              <Information>{message}</Information>
              {this.renderButtons()}
            </AlertBox>
          </Body>
        </AlertScreen>
      </Portal>
    ) : null
  }
}

export default connect(
  ({ modal: { visible, message, msgType, onConfirm, onCancel, buttonSettings } }: IStore) => ({
    visible,
    message,
    msgType,
    buttonSettings,
    onConfirm,
    onCancel
  }),
  { closeModal }
)(Modal)

export const showSimpleModal = (message: string) =>
  getStore().dispatch(
    showModal({
      msgType: TMsgType.info,
      message: message,
      onConfirm: () => {},
      onCancel: () => {}
    })
  )
