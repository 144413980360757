import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useSsqBsqWidget } from '../../../../../hooks/useSsqBsqWidget'
import { GridSpinner } from '../../../Spinner/Spinner'
import ActionsSaveCancel from '../../../Buttons/ActionsSaveCancel'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { SsqWidgetColumn } from './SsqWidgetColumn'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectRules } from '../../../../../store/select/ruleSelect'
import { BsqWidgetColumn } from './BsqWidgetColumn'
import { checkQuotesValidation } from '../../../../../services/DTO/sellSideQuote'
import { saveSsqBsqQuotes } from '../../../../../services/DTO/sellSideQuote/save'

type Props = {}

export const SsqBsqWidgetPopover = React.memo((props: Props) => {
  const expandedItemData = useExpandedItem()
  const { isFetching } = expandedItemData
  const { isModified, cancelModifies } = useSsqBsqWidget()
  const ruleMapping = useAppSelector(selectRules)

  React.useEffect(() => {
    if (Object.values(ruleMapping).length) {
      ReactTooltip.rebuild()
    }
  }, [ruleMapping])

  return (
    <Container>
      {isFetching && <GridSpinner />}
      <Columns>
        <SsqWidgetColumn />
        <BsqWidgetColumn />
      </Columns>
      {isModified && (
        <ActionsSaveCancel
          disableSave={!checkQuotesValidation(expandedItemData)}
          styles={{ height: 55, right: 20 }}
          showCancel={true}
          onSave={() => saveSsqBsqQuotes(expandedItemData)}
          onCancel={cancelModifies}
        />
      )}
      <ReactTooltip className={'react-tooltip pre-line'} id={'rules'} />
      <ReactTooltip className={'react-tooltip'} id={'hint'} />
    </Container>
  )
})

const Container = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 -16px;
  height: calc(100% - 40px);
`

const Columns = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
`
