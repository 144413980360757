import * as React from 'react'
import { GridItemEquipmentContext } from '../contexts/GridItemEquipmentContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useEquipment } from '../hooks/useEquipment'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemEquipmentProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const equipment = useEquipment({ id, modifiedLists })

  return (
    <GridItemEquipmentContext.Provider value={{ equipment, isExpanded: Boolean(modifiedLists) }} children={children} />
  )
})
