import { TroubleTicketDTO } from '../../../../api/origin/business-logic'
import { DocumentDTO } from '../../../../api/origin/document-service'
import { IAttachment } from '../../../../services/filesService/interfaces'
import { IMessage } from '../../../../store/reducers/communicationHub/interfaces'

export type IDispatchDeliveryOrderActivityDocumentMessages = IMessage[]

export enum ActivityDocumentPopupView {
  INFO = 'INFO',
  CREATEDOCUMENT = 'CREATEDOCUMENT',
  CREATETROUBLETICKET = 'CREATETROUBLETICKET',
  RESOLVETROUBLETICKETUNSUCCESSFUL = 'RESOLVETROUBLETICKETUNSUCCESSFUL',
  RESOLVETROUBLETICKETVALIDATE = 'RESOLVETROUBLETICKETVALIDATE',
  RESOLVETROUBLETICKETPROCEED = 'RESOLVETROUBLETICKETPROCEED'
}
export interface IActivityDocumentPopup {
  view: ActivityDocumentPopupView
  activityId: string
  completionActualDate?: string
  uploadedDocumentData?: DocumentDTO
  uploadingFile?: IAttachment
  validatedManually?: boolean
  troubleTicketId?: string
  troubleTicket?: TroubleTicketDTO
  alertMessage?: IMessage
  troubleTicketDescription?: string
  troubleTicketNote?: string
}

export enum DDOErrorTypes {
  activity = 'activity'
}
