import { BusinessPartnerViewDTO } from '../../../api/api'
import { maxCompleteness } from '../../../constants/completeness'
import { createId } from '../../utils'

export const generateBusinessPartner = (props?: Partial<BusinessPartnerViewDTO>): BusinessPartnerViewDTO => {
  return {
    id: createId(),
    completeness: maxCompleteness,
    contactIds: [],
    legalName: '',
    ...(props || {})
  }
}
