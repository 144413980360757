import * as React from 'react'
import { oc } from 'ts-optchain'
import { SearchRequest } from './searchRequest'
import {
  callAPI,
  steamShipLineAPI,
  customerAPI,
  powerUnitAPI,
  dispatchDeliveryOrderAPI,
  PowerUnitDTO,
  SteamShipLineViewDTO,
  CustomerViewDTO,
  HazmatDTO
} from '../../../../api/api'
import { transformPowerUnit } from '../../../../services/DTO/powerUnit/functions'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { pushListItemsToStore } from '../../../../store/reducers/lists/functions/pushListItemsToStore'
import { useSteamShipLine } from '../../../../hooks/useSteamShipLine'
import { useExpandedItem } from '../../../../hooks/useExpandedItem'
import { useCustomer } from '../../../../hooks/useCustomer'
import { useBusinessPartner } from '../../../../hooks/useBusinessPartner'
import { requestHazmatById, requestHazmatByTerm } from '../../../../services/DTO/hazmat/epics'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectHazmat } from '../../../../store/select/hazmatSelect'
export { EquipmentSearch } from './equipment'
export { LocationSearch } from './location'
export { ContainerSearch } from './container'

export type TSearchField = {
  disabled?: boolean
  title?: string
  required?: boolean
  value?: any
  onChange: (value: any) => void
  placeholder?: string
  symbolsNumberToFetch?: number
  allowCreate?: boolean
  requestByEnterPress?: boolean
  filtering?: boolean
  focus?: boolean
  highlighted?: boolean
  filterListResultFunction?: (object: any) => boolean
}

export const DispatchDeliveryOrderSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    filtering={false}
    symbolsNumberToFetch={1}
    label={oc(props).value.number()}
    getList={term => callAPI(dispatchDeliveryOrderAPI.searchByNumber, term, 10).toPromise()}
    getDetails={item => Promise.resolve(item)}
    makeDropDownItem={response => ({ label: response.number, value: response })}
  />
)

export const SteamShipLineSearch = (props: TSearchField & { id: string }) => {
  const { id } = props
  const { modifiedLists } = useExpandedItem()
  const steamShipLine = useSteamShipLine({ id, modifiedLists })

  return (
    <SearchRequest
      {...props}
      label={steamShipLine ? steamShipLine.name : null}
      getList={term => callAPI(steamShipLineAPI.search, null, term).toPromise()}
      getDetails={_id => callAPI(steamShipLineAPI.findById, _id).toPromise()}
      addToStore={(_steamShipLine: SteamShipLineViewDTO) =>
        pushListItemsToStore({
          update: {
            [EntityType.steamShipLine]: [_steamShipLine]
          }
        })
      }
      makeDropDownItem={response => ({ label: response.name, value: response.id })}
    />
  )
}

// export const DriverSearch = (props: TSearchField) => (
//   <SearchRequest
//     {...props}
//     // @ts-ignore
//     label={oc(props).value.name(null)}
//     getList={term => callAPI(driverAPI.search, null, term).toPromise()}
//     getDetails={id => callAPI(driverAPI.findById, id).toPromise()}
//     addToStore={tryAddDriverToStore}
//     makeDropDownItem={response => ({ label: response.name, value: response.id })}
//   />
// )

export const PowerUnitSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    label={transformPowerUnit(props.value)}
    getList={term => callAPI(powerUnitAPI.search, null, term).toPromise()}
    getDetails={id => callAPI(powerUnitAPI.findById, id).toPromise()}
    addToStore={(powerUnit: PowerUnitDTO) =>
      pushListItemsToStore({
        update: {
          [EntityType.powerUnit]: [powerUnit]
        }
      })
    }
    makeDropDownItem={response => ({ label: transformPowerUnit(response), value: response.id })}
  />
)

export const CustomerSearch = (props: TSearchField & { id: string }) => {
  const { id } = props
  const { modifiedLists } = useExpandedItem()
  const customer = useCustomer({ id, modifiedLists })
  const customerBusinessPartner = useBusinessPartner({ id: oc(customer).businessPartnerId(), modifiedLists })

  return (
    <SearchRequest
      {...props}
      label={customer ? oc(customerBusinessPartner).legalName(customer.name) : null}
      getList={term => callAPI(customerAPI.search, null, term).toPromise()}
      getDetails={_id => callAPI(customerAPI.findById, _id).toPromise()}
      addToStore={(_customer: CustomerViewDTO) =>
        pushListItemsToStore({
          update: {
            [EntityType.customer]: [_customer]
          }
        })
      }
      makeDropDownItem={response => ({ label: response.name, value: response.id })}
    />
  )
}

export const getValidationErrorMessage = (validation: boolean[], messageTypes: string[]): string =>
  validation.reduce((acc, curr, index) => {
    return !curr ? `${acc}${acc.length ? `\n` : ''}${messageTypes[index]}` : acc
  }, ``)
//
// export const SubClientSearch = (props: TSearchField & { customerId?: string }) => (
//   <SearchRequest
//     {...props}
//     label={props.value ? props.value.name : null}
//     getList={term => callAPI(subClientAPI.search, props.customerId, null, term).toPromise()}
//     getDetails={id => callAPI(subClientAPI.findById, id).toPromise()}
//     addToStore={subClientActions.addToList}
//     makeDropDownItem={response => ({ label: response.name, value: response.id })}
//   />
// )
//
export const HazmatSearch = (props: TSearchField & { id: string }) => {
  const { id } = props
  const hazmat = useAppSelector(selectHazmat(id))

  React.useEffect(() => {
    if (id && !hazmat) {
      requestHazmatById(id).catch(() => {})
    }
  }, [id])

  return (
    <SearchRequest
      {...props}
      required={id && !hazmat ? false : props.required}
      label={transformHazmat(hazmat)}
      getList={requestHazmatByTerm}
      getDetails={async value => {
        if (value) {
          await pushListItemsToStore({ update: { [EntityType.hazmat]: [value] } })
        }

        return Promise.resolve(value)
      }}
      makeDropDownItem={response => ({ label: transformHazmat(response), value: response })}
    />
  )
}

const transformHazmat = (hazmat: HazmatDTO): string => {
  if (hazmat) {
    const details = [oc(hazmat).hazardClass(), oc(hazmat).description()].filter(Boolean).join(' ')
    return [oc(hazmat).code(), details].filter(Boolean).join(', ')
  }
}
