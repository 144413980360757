import { OngoingActivityDTO, TransportationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { isGotoActivity, isGotoActivityType } from './isGotoActivity'

export const dropBobtailTypes = [
  TransportationActivityViewDTO.TypeEnum.EMPTY,
  TransportationActivityViewDTO.TypeEnum.OFFDUTY
]

export const isBusinessActivity = (
  activity: TransportationActivityDTO | TransportationActivityViewDTO | OngoingActivityDTO
): boolean => {
  return Boolean(activity) && !isGotoActivity(activity)
}

export const isBusinessActivityType = (type: TransportationActivityViewDTO.TypeEnum): boolean => {
  return Boolean(type) && !isGotoActivityType(type) && !dropBobtailTypes.includes(type)
}

export const isDropBobtailBusinessActivityType = (type: TransportationActivityViewDTO.TypeEnum): boolean => {
  return Boolean(type) && dropBobtailTypes.includes(type)
}

export const isPickBobtailBusinessActivityType = (type: TransportationActivityViewDTO.TypeEnum): boolean => {
  return Boolean(type) && dropBobtailTypes.includes(type)
}
