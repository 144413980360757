import * as React from 'react'
import { oc } from 'ts-optchain'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import {
  selectNotificationFilters,
  selectNotificationIdList,
  selectNotificationMapping
} from '../store/select/notificationSelect'
import { requestNotifications } from '../components/CommunicationHub/epics'
import { communicationHubActions } from '../store/reducers/communicationHub'
import { groupItemsByDate } from '../services/functions/group/groupItemsByDate'
import { NotificationDTO } from '../api/origin/communication-hub-service'

type Props = {}

const itemsPerPage = 30
const _temporaryProps = {
  initialScrollY: 0
}

export const useNotificationList = (props: Props) => {
  const dispatch = useAppDispatch()
  const mountedRef = React.useRef(false)
  const blockFetchRequestRef = React.useRef(false)
  const pageNumberRef = React.useRef(0)
  const requestNumberRef = React.useRef(0)
  const filters = useAppSelector(selectNotificationFilters)
  const notificationIdList = useAppSelector(selectNotificationIdList)
  const notificationMapping = useAppSelector(selectNotificationMapping)
  const [initialFetch, setInitialFetch] = React.useState<boolean>(false)
  const [fetchPrevPage, setFetchPrevPage] = React.useState<boolean>(false)
  const [isFullContentLoaded, setIsFullContentLoaded] = React.useState<boolean>(false)
  blockFetchRequestRef.current = initialFetch || fetchPrevPage || isFullContentLoaded

  const sortedNotificationsByDate = React.useMemo(() => {
    return groupItemsByDate({
      items: notificationIdList.map(id => notificationMapping[id]),
      getItemDateFn: (notification: NotificationDTO) => oc(notification).createdAt(),
      sort: 'DESC'
    })
  }, [notificationIdList])

  const requestPrevPageNotifications = React.useCallback(
    (_props?: { isInitialRequest?: boolean; force?: boolean }) => {
      const { isInitialRequest, force } = _props || {}

      if (!force && blockFetchRequestRef.current) {
        return
      }

      if (isInitialRequest) {
        setInitialFetch(true)
      } else {
        setFetchPrevPage(true)
      }

      requestNumberRef.current++
      const currentRequest = requestNumberRef.current

      requestNotifications({
        page: pageNumberRef.current,
        limit: itemsPerPage,
        type: filters.type,
        // @ts-ignore
        driverStatuses: filters.driverStatuses,
        status: filters.status,
        endDate: filters.endDate,
        startDate: filters.startDate,
        text: filters.text,
        senderUserIds: filters.senderUserIds,
        recipientUserIds: filters.recipientUserIds
      })
        .then(items => {
          if (currentRequest !== requestNumberRef.current) {
            return
          }

          const action = isInitialRequest
            ? communicationHubActions.setInitialNotificationsToStorage
            : communicationHubActions.pushPrevPageNotificationsToStorage

          dispatch(action(items))

          if (mountedRef.current) {
            pageNumberRef.current++

            if (items.length < itemsPerPage) {
              setIsFullContentLoaded(true)
            }
          }
        })
        .finally(() => {
          if (mountedRef.current && currentRequest === requestNumberRef.current) {
            if (isInitialRequest) {
              setInitialFetch(false)
            } else {
              setFetchPrevPage(false)
            }
          }
        })
    },
    [filters]
  )

  React.useEffect(() => {
    pageNumberRef.current = 0
    const isReturnToNotificationsView = !mountedRef.current && notificationIdList.length

    if (!isReturnToNotificationsView) {
      _temporaryProps.initialScrollY = 0
      requestPrevPageNotifications({ isInitialRequest: true, force: true })
    }

    mountedRef.current = true
  }, [filters])

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return {
    _temporaryProps,
    initialFetch,
    isFullContentLoaded,
    notificationIdList,
    notificationMapping,
    requestPrevPageNotifications,
    sortedNotificationsByDate
  }
}
