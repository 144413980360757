import { IAttachment } from '../../../services/filesService/interfaces'
import { getUserState } from '../../../store'
import { IDocumentMessage } from '../../../store/reducers/communicationHub/interfaces'

type Props = {
  channelId: string
  attachments: IAttachment[]
  metadata?: any
}

export const generateChannelDocumentMessage = (props: Props): IDocumentMessage => {
  const { channelId, attachments, metadata } = props

  return {
    channelId,
    senderId: getUserState().id,
    attachments,
    metadata
  }
}
