import * as React from 'react'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { Table } from './Table'

const extraContainerStyles = {
  padding: '24px 0 0'
}

export const AuditTrail = () => {
  return (
    <DispatchDeliveryOrderTabContent style={extraContainerStyles}>
      <Table />
    </DispatchDeliveryOrderTabContent>
  )
}
