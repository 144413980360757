import styled from 'styled-components'
import theme from '../../../../styles/theme'

const Container = styled.div`
  display: flex;
  align-items: center;
`
const Button = styled.div`
  height: 35px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${theme.colors.basicBlueColor};
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 15px;
  color: white;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }
`
const ButtonDelete = styled.div`
  display: flex;
  align-items: center;
  color: red;
  margin-left: 5px;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }
`

export const StyledFileUploaderButton = Button

export default {
  Container,
  Button,
  ButtonDelete
}
