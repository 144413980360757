import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import {
  callAPIWithErrorMessage,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO,
  troubleTicketDocumentAPI,
  TroubleTicketDTO
} from '../../../../../../../../../../../api/api'
import { FileCategory, IAttachment } from '../../../../../../../../../../../services/filesService/interfaces'
import { IMessage } from '../../../../../../../../../../../store/reducers/communicationHub/interfaces'
import theme from '../../../../../../../../../../../styles/theme'
// tslint:disable:max-line-length
import { createChatDocumentMessage, updateChatMessage } from '../../../../../../../../../../CommunicationHub/epics'
import { generateChannelDocumentMessage } from '../../../../../../../../../../CommunicationHub/functions/generateChannelDocumentMessage'
import { ActionButton } from '../../../../../../../../../../UI/Buttons/ActionButton'
import { TroubleTicketResolveAction } from '../../../../../../../../../activity/epics'
import { ActivityDocumentPopupView, IActivityDocumentPopup } from '../../../../../../../../interfaces'
import { ResolveButton } from '../TroubleTicket/ResolveButton'
import { ActivityDocumentStatus } from './interfaces'

type Props = {
  view: ActivityDocumentPopupView
  attachment: IAttachment
  troubleTicket: TroubleTicketDTO
  businessActivity: TransportationActivityViewDTO
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  documentType: FileCategory.POD | FileCategory.TIR | undefined
  activityDocumentStatus: ActivityDocumentStatus
  document: IAttachment | undefined
  documentMessage: IMessage | undefined
  activityDocumentPopupState: IActivityDocumentPopup
  updateActivityDocumentPopupState: (state: IActivityDocumentPopup) => void
  onCompletionDateUpdate: (completionActualDate: string) => void
  onClosePopupClick: () => void
  setFetching: (state: boolean) => void
}

export const ActivityDocumentActions = React.memo((props: Props) => {
  const {
    view,
    attachment,
    documentType,
    businessActivity,
    dispatchDeliveryOrder,
    troubleTicket,
    activityDocumentStatus,
    activityDocumentPopupState,
    updateActivityDocumentPopupState,
    onCompletionDateUpdate,
    onClosePopupClick,
    setFetching
  } = props
  const {
    completionActualDate,
    uploadedDocumentData,
    troubleTicketDescription,
    troubleTicketNote,
    validatedManually,
    alertMessage
  } = activityDocumentPopupState

  return (
    <Actions>
      {(view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE ||
        view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL ||
        view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED) && (
        <BackButton
          className={'mdi mdi-chevron-left'}
          onClick={() =>
            updateActivityDocumentPopupState({
              ...activityDocumentPopupState,
              view: ActivityDocumentPopupView.INFO
            })
          }
        >
          Back
        </BackButton>
      )}
      <ActionButton
        onlyText={true}
        styles={{ height: 25, minHeight: 25, color: theme.colors.basicBlueColor }}
        onClick={onClosePopupClick}
        children={'Cancel'}
      />
      {(view === ActivityDocumentPopupView.CREATEDOCUMENT ||
        view === ActivityDocumentPopupView.CREATETROUBLETICKET ||
        view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE ||
        view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL ||
        view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED) && (
        <ActionButton
          disabled={
            view === ActivityDocumentPopupView.CREATEDOCUMENT ||
            view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE
              ? !(
                  completionActualDate &&
                  (activityDocumentStatus === ActivityDocumentStatus.VERIFICATIONACCEPTED ||
                    activityDocumentStatus === ActivityDocumentStatus.VALIDATEDMANUALLY)
                )
              : undefined
          }
          filled={true}
          round={true}
          styles={{ height: 25 }}
          onClick={async () => {
            setFetching(true)

            if (view === ActivityDocumentPopupView.CREATEDOCUMENT) {
              const complete = () => {
                if (completionActualDate !== businessActivity.completionActualDate) {
                  onCompletionDateUpdate(completionActualDate)
                }

                updateActivityDocumentPopupState(undefined)
              }

              if (uploadedDocumentData) {
                createChatDocumentMessage(
                  generateChannelDocumentMessage({
                    channelId: dispatchDeliveryOrder.id,
                    metadata: {
                      ddo: {
                        id: dispatchDeliveryOrder.id,
                        number: dispatchDeliveryOrder.number
                      },
                      validation: {
                        ...oc(uploadedDocumentData).validation({}),
                        validatedManually
                      },
                      activities: [businessActivity]
                    },
                    attachments: [{ ...uploadedDocumentData.file, category: documentType }]
                  })
                )
                  .then(complete)
                  .catch(() => {
                    setFetching(false)
                  })
              } else {
                complete()
              }
            } else if (view === ActivityDocumentPopupView.CREATETROUBLETICKET) {
              callAPIWithErrorMessage(troubleTicketDocumentAPI.create, {
                files: uploadedDocumentData ? [uploadedDocumentData.file as any] : undefined,
                dispatchDeliveryOrderId: dispatchDeliveryOrder.id,
                status: TroubleTicketDTO.StatusEnum.NEW,
                transportationActivityId: businessActivity.id,
                description: troubleTicketDescription,
                // @ts-ignore
                metadata: {
                  ...oc(uploadedDocumentData).metadata({
                    dispatchDeliveryOrderId: dispatchDeliveryOrder.id,
                    dispatchDeliveryOrderNumber: dispatchDeliveryOrder.number
                  }),
                  type: documentType
                },
                validation: {
                  ...oc(uploadedDocumentData).validation({}),
                  validatedManually
                },
                // @ts-ignore
                type: documentType
              })
                .then(() => {
                  setTimeout(() => updateActivityDocumentPopupState(undefined), 2000)
                })
                .catch(() => {
                  setFetching(false)
                })
            } else if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) {
              if (uploadedDocumentData && alertMessage) {
                await updateChatMessage({
                  ...alertMessage,
                  attachments: [uploadedDocumentData.file],
                  metadata: {
                    ...oc(alertMessage).metadata({}),
                    // @ts-ignore
                    documentMetadata: uploadedDocumentData.metadata,
                    validation: {
                      ...oc(uploadedDocumentData).validation({}),
                      // @ts-ignore
                      validatedManually
                    }
                  }
                })
              }

              callAPIWithErrorMessage(troubleTicketDocumentAPI.proceed, troubleTicket.id, {
                id: troubleTicket.id,
                notes: troubleTicketNote,
                activityCompletionDate: completionActualDate
              })
                .then(() => {
                  createChatDocumentMessage(
                    generateChannelDocumentMessage({
                      channelId: dispatchDeliveryOrder.id,
                      metadata: {
                        ddo: {
                          id: dispatchDeliveryOrder.id,
                          number: dispatchDeliveryOrder.number
                        },
                        validation: {
                          // @ts-ignore
                          ...(uploadedDocumentData
                            ? oc(uploadedDocumentData).validation({})
                            : oc(troubleTicket).validation({})),
                          validatedManually
                        },
                        activities: [businessActivity]
                      },
                      attachments: [
                        {
                          ...attachment,
                          category: documentType
                        }
                      ]
                    })
                  )
                    .then(() => {
                      setTimeout(() => updateActivityDocumentPopupState(undefined), 2000)
                    })
                    .catch(() => {
                      setFetching(false)
                    })
                })
                .catch(() => {
                  setFetching(false)
                })
            } else if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL) {
              callAPIWithErrorMessage(troubleTicketDocumentAPI.unsuccessful, troubleTicket.id, {
                id: troubleTicket.id,
                notes: troubleTicketNote
              })
                .then(() => {
                  setTimeout(() => updateActivityDocumentPopupState(undefined), 2000)
                })
                .catch(() => {
                  setFetching(false)
                })
            } else if (view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED) {
              callAPIWithErrorMessage(troubleTicketDocumentAPI.proceed, troubleTicket.id, {
                id: troubleTicket.id,
                notes: troubleTicketNote
              })
                .then(() => {
                  setTimeout(() => updateActivityDocumentPopupState(undefined), 2000)
                })
                .catch(() => {
                  setFetching(false)
                })
            }
          }}
        >
          {view === ActivityDocumentPopupView.CREATEDOCUMENT && 'Complete'}
          {view === ActivityDocumentPopupView.CREATETROUBLETICKET && 'Create Trouble Ticket'}
          {view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED && 'Proceed'}
          {view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE && 'Complete'}
          {view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL && 'Unsuccessful'}
        </ActionButton>
      )}

      {view === ActivityDocumentPopupView.INFO && troubleTicket && (
        <ResolveButton
          isValidation={troubleTicket.type !== TroubleTicketDTO.TypeEnum.DEFAULT}
          onSelect={selected => {
            updateActivityDocumentPopupState({
              ...activityDocumentPopupState,
              view:
                selected === TroubleTicketResolveAction.proceed
                  ? troubleTicket.type === TroubleTicketDTO.TypeEnum.DEFAULT
                    ? ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED
                    : ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE
                  : selected === TroubleTicketResolveAction.unsuccessful
                  ? ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL
                  : activityDocumentPopupState.view
            })
          }}
        />
      )}
    </Actions>
  )
})

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`
const BackButton = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  color: ${theme.colors.basicBlueColor};
  font-size: 14px;
  margin-left: -10px;
  margin-right: auto;
  user-select: none;
  cursor: pointer;

  &:before {
    font-size: 26px;
  }
`
