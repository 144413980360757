import * as React from 'react'
import { StyledWindow } from './index'

type Props = {
  title: string
  disabled?: boolean
}

export const Window = (props: Props) => {
  const { title, disabled } = props

  return (
    <StyledWindow
      className={'one-line-with-commas mdi mdi-menu-down'}
      style={disabled ? { color: 'rgba(108, 112, 125, 0.6)' } : undefined}
    >
      {title}
    </StyledWindow>
  )
}
