import { LocationDTO } from '../../../api/origin/business-logic'

const type = {
  [LocationDTO.TypeEnum.TERMINAL]: 'Terminal',
  [LocationDTO.TypeEnum.WAREHOUSEWITHDOCK]: 'Warehouse w/ Dock',
  [LocationDTO.TypeEnum.WAREHOUSEWITHOUTDOCK]: 'Warehouse w/o Dock',
  [LocationDTO.TypeEnum.STOREFRONT]: 'Store Front',
  [LocationDTO.TypeEnum.RESIDENTIAL]: 'Residential',
  [LocationDTO.TypeEnum.CONTAINERYARD]: 'Container Yard',
  // [LocationDTO.TypeEnum.GENERIC]: 'Generic',
  [LocationDTO.TypeEnum.DEPOT]: 'Depot',
  [LocationDTO.TypeEnum.OTHER]: 'Other'
}
export const locationDirectory = {
  type
}
