import styled from 'styled-components'

export const StyledTable = styled.div`
  max-width: 425px;
  margin: 0 -16px;
`

export const ManualStatusesTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  margin: 16px 0 8px;
`
export const SManualStatus = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom-width: 0;
  padding: 9px 15px;

  &:before {
    content: '';
    display: block;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #ff5e5e;
  }

  &:last-child {
    border-bottom-width: 1px;
  }

  &.RELEASED {
    &:before {
      background-color: #e1af7e;
    }
  }

  &.NEW {
    &:before {
      background-color: rgba(128, 170, 221, 1);
    }
  }
`
export const SManualStatusLabel = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #445366;
  margin-left: 10px;
  user-select: none;
  cursor: default;
`
