import * as React from 'react'
import { BoldLine, Text, Title } from '../../styles'
import { CargoType } from './index'
import { transformThousandSeparators } from '../../../../../services/functions/transform/transformThousandSeparators'

type Props = { cargo: CargoType }

export const Window = ({ cargo }: Props) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      Cargo
    </Title>
    <BoldLine className={'textLine'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
      <span>{cargo.description}</span>
    </BoldLine>
    {cargo.weight && (
      <Text className={'bold lowercase darkBlue'} rows={3} alignCenter={true}>
        {(transformThousandSeparators(String(cargo.weight)) + ' ' + cargo.weightUnit).slice(0, -1)}
      </Text>
    )}
  </>
)
