import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { DriverReportDTO } from '../../../../api/origin/document-service'
import theme from '../../../../styles/theme'
import { WindowPopover } from '../../windowPopover'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { downloadFile } from '../../../../services/functions/downloadFile'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { DriverReportHistory } from './DriverReportHistory'

type Props = {
  reportType: DriverReportDTO.TypeEnum
  report: DriverReportDTO
  reportHistory: DriverReportDTO[]
  onGenerateReportClick: () => void
  disable: boolean
}

export const DriverReportButton = (props: Props) => {
  const { reportType, report, reportHistory, disable, onGenerateReportClick } = props
  const actionsButtonRef = React.useRef(null)
  const { wrapperRef, statePopover, togglePopover, setPopoverState } = useOutsideClick()
  const actions: JSX.Element[] = []
  const isReportPending = oc(report).status() === DriverReportDTO.StatusEnum.PENDING
  const isReportFaild = oc(report).status() === DriverReportDTO.StatusEnum.FAILED

  if (report && !isReportPending) {
    if (!disable) {
      actions.push(
        <Action
          key={'Run New Report'}
          children={'Run New Report'}
          onClick={() => {
            setPopoverState(false)
            onGenerateReportClick()
          }}
        />
      )
    }

    if (reportHistory.length) {
      actions.push(
        <Action
          key={'View History'}
          children={'View History'}
          onClick={() => {
            setPopoverState(false)
            showModalWindow({
              width: 300,
              title: reportType + ' Report History',
              content: <DriverReportHistory reportHistory={reportHistory} />,
              buttons: [{ label: 'Close', outline: true }],
              closeButton: true
            })
          }}
        />
      )
    }
  }

  return (
    <Container
      ref={wrapperRef}
      className={cn({
        actions: actions.length,
        isReportPending,
        isReportFaild,
        disable,
        red: !report,
        hasReport: report
      })}
    >
      {isReportPending ? (
        <Button children={'Processing'} style={{ cursor: 'default' }} />
      ) : isReportFaild ? (
        <Button children={'Failed'} style={{ cursor: 'default' }} />
      ) : report ? (
        <Button
          children={'View'}
          className={'mdi mdi-eye'}
          onClick={() => downloadFile({ url: report.url, contentType: report.contentType })}
        />
      ) : (
        <Button
          style={disable ? { cursor: 'default' } : undefined}
          children={disable ? 'Report' : 'Run Report'}
          onClick={disable ? undefined : onGenerateReportClick}
        />
      )}
      {Boolean(actions.length) && (
        <>
          <ActionButton ref={actionsButtonRef} className={'mdi mdi-dots-vertical'} onClick={togglePopover} />
          {statePopover && (
            <WindowPopover
              bindToRef={actionsButtonRef}
              flexibleWidth={true}
              containerStyles={{ transform: 'translate(25px, -35px)' }}
            >
              <ActionsPopover children={actions} />
            </WindowPopover>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 35px;
  width: 115px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr;
  color: white;
  border-radius: 4px;
  background: ${theme.colors.basicBlueColor};
  user-select: none;

  &.actions {
    grid-template-columns: 1fr 20px;
  }

  &.isReportPending,
  &.disable:not(.hasReport) {
    background: #b2b2b2;
  }

  &.isReportFaild,
  &.red {
    background: ${theme.colors.defaultRed};
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  line-height: 1.2;
  border-left: 1px solid white;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const ActionsPopover = styled.div`
  width: 120px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 8px 0px;
`
const Action = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #404957;
  line-height: 1.2;
  padding: 5px 8px;
  cursor: pointer;

  &:hover {
    background: #f5f6fa;
  }
`
