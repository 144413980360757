import { TransportationActivityViewDTO } from '../api/api'

export const relatedActivityTypes: Record<
  TransportationActivityViewDTO.StageEnum,
  TransportationActivityViewDTO.TypeEnum[]
> = {
  [TransportationActivityViewDTO.StageEnum.PICKUP]: [
    TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY,
    TransportationActivityViewDTO.TypeEnum.PICKUPFULL
  ],
  [TransportationActivityViewDTO.StageEnum.DELIVERY]: [
    TransportationActivityViewDTO.TypeEnum.GETLOADED,
    TransportationActivityViewDTO.TypeEnum.GETUNLOADED,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ],
  [TransportationActivityViewDTO.StageEnum.RETURN]: [
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ]
}
