import { EditorState, Modifier, SelectionState, ContentBlock } from 'draft-js'
import * as linkifyIt from 'linkify-it'

const linkify = linkifyIt()
// tslint:disable-next-line:no-var-requires
linkify.tlds(require('tlds'))

const findLinks = (
  contentBlock: ContentBlock,
  callback: (firstIndex: number, lastIndex: number, href: string) => void
) => {
  const contentBlockText = contentBlock.get('text')
  const links = linkify.match(contentBlockText)
  if (typeof links !== 'undefined' && links !== null) {
    for (const link of links) {
      callback(link.index, link.lastIndex, link.url)
    }
  }
}

export const linkifyEditorState = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent()
  const blocks = contentState.getBlockMap()
  let newContentState = contentState

  blocks.forEach(block => {
    const plainText = block.getText()

    const addEntityToLink = (start: number, end: number, href: string) => {
      const existingEntityKey = block.getEntityAt(start)
      if (existingEntityKey) {
        // avoid manipulation in case the link already has an entity
        const entity = contentState.getEntity(existingEntityKey)
        if (entity && entity.getType() === 'LINK' && entity.getData().href === href) {
          return
        }
      }

      const selection = editorState
        .getSelection()
        .set('anchorOffset', start)
        .set('focusOffset', end)

      const linkText = plainText.substring(start, end)
      const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', { text: linkText, href })
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      if (selection instanceof SelectionState) {
        newContentState = Modifier.replaceText(newContentState, selection, linkText, null, entityKey)
      }
    }

    findLinks(block, addEntityToLink)
  })

  if (!newContentState.equals(contentState)) {
    return EditorState.push(editorState, newContentState, 'apply-entity')
  }

  return editorState
}

// fix draft-js bug https://github.com/facebook/draft-js/issues/1198
export function fixCursorBug(prevEditorState: EditorState, nextEditorState: EditorState) {
  const prevSelection = prevEditorState.getSelection()
  const nextSelection = nextEditorState.getSelection()
  if (
    prevSelection.getAnchorKey() === nextSelection.getAnchorKey() &&
    prevSelection.getAnchorOffset() === 0 &&
    nextSelection.getAnchorOffset() === 1 &&
    prevSelection.getFocusKey() === nextSelection.getFocusKey() &&
    prevSelection.getFocusOffset() === 0 &&
    nextSelection.getFocusOffset() === 1 &&
    prevSelection.getHasFocus() === false &&
    nextSelection.getHasFocus() === false
  ) {
    const fixedSelection = nextSelection.merge({ hasFocus: true }) as any
    return EditorState.forceSelection(nextEditorState, fixedSelection)
  }
  return nextEditorState
}
