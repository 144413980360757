import styled from 'styled-components'
import * as React from 'react'
import { oc } from 'ts-optchain'
import {
  getStringConvertISODateToDateWithHoursMins,
  resetLocalDay,
  resetTimeToZero
} from '../../../../../services/timeService/dateUtils'
import { Checkbox } from '../../../../UI/Radio'
import DateField from '../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../UI/DatePicker/Interfaces'
// import { DatePicker } from '../../../../UI/DataFields/DatePicker/views/'

export const TestDatepickerComponent = () => {
  const [dateRange, setDate] = React.useState(null)
  // const [selectedDateRange, setSelectedDateRange] = React.useState(null)
  const [dayStart, setDayStart] = React.useState(false)
  const [dayEnd, setDayEnd] = React.useState(false)
  const [showTime, setShowTime] = React.useState(true)
  const [small, setSmall] = React.useState(false)
  const [timeNowOnEmpty, setTimeNowOnEmpty] = React.useState(false)
  const [timeMorningOnEmpty, setTimeMorningOnEmpty] = React.useState(false)

  const date = oc(dateRange).from()

  const readnableDate = (_date: string) =>
    getStringConvertISODateToDateWithHoursMins(resetTimeToZero(_date), small, !showTime)

  return (
    <TestDatepickerContainer>
      <div>
        <TestDatepickerFieldBox>
          <div>
            <Checkbox active={small} onClick={() => setSmall(_ => !_)} /> Small
          </div>
          <div>
            <Checkbox active={showTime} onClick={() => setShowTime(_ => !_)} /> Show Time
          </div>
          <div>
            <Checkbox
              active={dayStart}
              onClick={() => {
                const newState = !dayStart
                setDayStart(newState)

                if (newState) {
                  setDayEnd(false)
                }
              }}
            />{' '}
            Day Start
          </div>
          <div>
            <Checkbox
              active={dayEnd}
              onClick={() => {
                const newState = !dayEnd
                setDayEnd(newState)

                if (newState) {
                  setDayStart(false)
                }
              }}
            />{' '}
            Day End
          </div>
        </TestDatepickerFieldBox>
        <div>
          <Checkbox
            active={timeNowOnEmpty}
            onClick={() => {
              const newState = !timeNowOnEmpty
              setTimeNowOnEmpty(newState)

              if (newState) {
                setTimeMorningOnEmpty(false)
              }
            }}
          />{' '}
          Time Now On Empty
        </div>
        <div>
          <Checkbox
            active={timeMorningOnEmpty}
            onClick={() => {
              const newState = !timeMorningOnEmpty
              setTimeMorningOnEmpty(newState)

              if (newState) {
                setTimeNowOnEmpty(false)
              }
            }}
          />{' '}
          Time Morning On Empty
        </div>

        <div style={{ marginTop: 10 }}>
          <DateField
            dateType={DateTypes.DateTimeRangeDTO}
            placeholder={'MM/DD/YY'}
            date={dateRange}
            onUpdate={setDate}
            showTime={showTime}
            small={small}
            setTimeNowOnEmpty={timeNowOnEmpty}
            setTimeMorningOnEmpty={timeMorningOnEmpty}
            setDayStart={dayStart}
            setDayEnd={dayEnd}
          />
        </div>

        <TestDatepickerField>
          Basic:
          <TestDatepickerDoubleField>
            <span>{readnableDate(date)}</span>
            <span>{date}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset time to zero:
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetTimeToZero(date))}</span>
            <span>{resetTimeToZero(date)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset time to zero (setEndDay = true):
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetTimeToZero(date, true))}</span>
            <span>{resetTimeToZero(date, true)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset time to zero (setToday = true):
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetTimeToZero(date, false, true))}</span>
            <span>{resetTimeToZero(date, false, true)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset time to zero (setEndDay = true; setToday = true):
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetTimeToZero(date, true, true))}</span>
            <span>{resetTimeToZero(date, true, true)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset Local Day:
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetLocalDay(date))}</span>
            <span>{resetLocalDay(date)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
        <TestDatepickerField>
          Reset Local Day (setEndDay = true):
          <TestDatepickerDoubleField>
            <span>{readnableDate(resetLocalDay(date, true))}</span>
            <span>{resetLocalDay(date, true)}</span>
          </TestDatepickerDoubleField>
        </TestDatepickerField>
      </div>
      {/*<div style={{ width: 400 }}>*/}
      {/*<DatePicker dateRange={selectedDateRange} onChange={setSelectedDateRange} />*/}
      {/*</div>*/}
    </TestDatepickerContainer>
  )
}

const TestDatepickerContainer = styled.div`
  display: flex;

  > div {
    flex-shrink: 0;
    padding-left: 40px;
  }
`
const TestDatepickerFieldBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-shrink: 0;
    width: 50%;
  }
`
const TestDatepickerField = styled.div`
  font-size: 14px;
  margin-top: 10px;

  > div {
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 5px;
  }
`
const TestDatepickerDoubleField = styled.div`
  display: flex;

  span:first-child {
    width: 130px;
    margin-right: auto;
  }
`
