import { GridColumn } from '../../../../../UI/GridSystem'
import theme from '../../../../../../styles/theme'
import styled from '../../../../../../styles/styledComponents'

export const StyledGeneral = styled(GridColumn)`
  font-size: 14px;
  user-select: none;
`
export const MaintenanceHeader = styled(GridColumn)`
  justify-content: space-between;
  span {
    font-size: 18px;
    font-weight: 500;
  }
`
export const ColumnTitles = styled(GridColumn)`
  border: 1px solid transparent;

  > div {
    align-items: center;
    border-left: 1px solid ${theme.colors.darkBorderColor};
    font-size: 10px;
    color: ${theme.colors.basicGreyColor};
    padding-left: 10px;

    &:first-child {
      border: none;
    }
  }
`
export const Row = styled(GridColumn)`
  border: 1px solid ${theme.colors.darkBorderColor};
  border-radius: 5px;
  background: white;

  > div {
    align-items: center;
    padding: 0 10px;
  }
`
export const AddRow = styled(GridColumn)`
  border: 1px dashed ${theme.colors.darkBorderColor};
  border-radius: 5px;
  color: ${theme.colors.basicGreyColor};
  cursor: pointer;
`
export const Date = styled(GridColumn)`
  > div {
    width: 100%;
  }

  .date-picker {
    transform: none;
  }
`

export const StyledMap = styled(GridColumn)`
  &:after {
    content: '';
    display: block;
    width: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 12;
    box-shadow: inset -60px 0px 30px -11px rgba(255, 255, 255, 1);
  }
`
