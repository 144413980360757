export enum CustomerColumn {
  DateCreated = 'Date Created',
  Name = 'Name',
  Address = 'Address',
  SalesRep = 'Sales Rep',
  NetTermsUnit = 'Net Terms Unit',
  NetTerms = 'Net Terms',
  PrimaryContact = 'Primary Contact'
}

export const customerColumnSettings = {
  [CustomerColumn.DateCreated]: { width: 120, active: true },
  [CustomerColumn.Name]: { width: 160, active: true },
  [CustomerColumn.Address]: { width: 260, active: true },
  [CustomerColumn.SalesRep]: { width: 260, active: true },
  [CustomerColumn.NetTermsUnit]: { width: 120, active: true },
  [CustomerColumn.NetTerms]: { width: 100, active: true },
  [CustomerColumn.PrimaryContact]: { width: 160, active: true }
}
