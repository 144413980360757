// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url'

import { Configuration } from './configuration'

const BASE_PATH = 'https://intermodal-gateway.dev.port24.com'.replace(/\/+$/, '')

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration

  constructor(configuration?: Configuration, protected basePath: string = configuration.basePath) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

/**
 *
 * @export
 * @interface AccountingDataFilter
 */
export interface AccountingDataFilter {
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingDataFilter
   */
  activityCompletionDateTimeFrom?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingDataFilter
   */
  activityCompletionDateTimeTill?: DateISOString
  /**
   *
   * @type {Array<string>}
   * @memberof AccountingDataFilter
   */
  customerIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingDataFilter
   */
  ddoCompletionDateTimeFrom?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingDataFilter
   */
  ddoCompletionDateTimeTill?: DateISOString
  /**
   *
   * @type {Array<string>}
   * @memberof AccountingDataFilter
   */
  vendorIds?: Array<string>
}

/**
 *
 * @export
 * @interface ActiveEquipmentDTO
 */
export interface ActiveEquipmentDTO {
  /**
   *
   * @type {string}
   * @memberof ActiveEquipmentDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {number}
   * @memberof ActiveEquipmentDTO
   */
  completeness?: number
  /**
   *
   * @type {number}
   * @memberof ActiveEquipmentDTO
   */
  days?: number
  /**
   *
   * @type {string}
   * @memberof ActiveEquipmentDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof ActiveEquipmentDTO
   */
  pickupDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof ActiveEquipmentDTO
   */
  returnDate?: DateISOString
}

/**
 *
 * @export
 * @interface ActivitiesDTO
 */
export interface ActivitiesDTO {
  /**
   *
   * @type {Array<DocumentationActivityDTO>}
   * @memberof ActivitiesDTO
   */
  documentationActivities?: Array<DocumentationActivityDTO>
  /**
   *
   * @type {Array<TransportationActivityDTO>}
   * @memberof ActivitiesDTO
   */
  transportationActivities?: Array<TransportationActivityDTO>
}

/**
 *
 * @export
 * @interface ActivitiesGridItemDTO
 */
export interface ActivitiesGridItemDTO {
  /**
   *
   * @type {Array<TransportationActivityGridItemDTO>}
   * @memberof ActivitiesGridItemDTO
   */
  transportationActivities?: Array<TransportationActivityGridItemDTO>
}

/**
 *
 * @export
 * @interface ActivitiesViewDTO
 */
export interface ActivitiesViewDTO {
  /**
   *
   * @type {Array<DocumentationActivityDTO>}
   * @memberof ActivitiesViewDTO
   */
  documentationActivities?: Array<DocumentationActivityDTO>
  /**
   *
   * @type {Array<TransportationActivityViewDTO>}
   * @memberof ActivitiesViewDTO
   */
  transportationActivities?: Array<TransportationActivityViewDTO>
}

/**
 *
 * @export
 * @interface ActivityLocationNameDTO
 */
export interface ActivityLocationNameDTO {
  /**
   *
   * @type {AddressDTO}
   * @memberof ActivityLocationNameDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  cityStateZip?: string
  /**
   *
   * @type {number}
   * @memberof ActivityLocationNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  longName?: string
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof ActivityLocationNameDTO
   */
  type?: ActivityLocationNameDTO.TypeEnum
}

/**
 * @export
 * @namespace ActivityLocationNameDTO
 */
export namespace ActivityLocationNameDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  city: string
  /**
   *
   * @type {number}
   * @memberof AddressDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  stateId: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof AddressDTO
   */
  street2?: string
}

/**
 *
 * @export
 * @interface AddressSimpleDTO
 */
export interface AddressSimpleDTO {
  /**
   *
   * @type {string}
   * @memberof AddressSimpleDTO
   */
  city: string
  /**
   *
   * @type {number}
   * @memberof AddressSimpleDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof AddressSimpleDTO
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof AddressSimpleDTO
   */
  stateId: string
}

/**
 *
 * @export
 * @interface ApiError
 */
export interface ApiError {
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  field?: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  value?: string
}

/**
 *
 * @export
 * @interface BankingDetailsDTO
 */
export interface BankingDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  accountNumber?: string
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  frequency?: BankingDetailsDTO.FrequencyEnum
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  paymentMethod?: BankingDetailsDTO.PaymentMethodEnum
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  routingCode?: string
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  userId?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof BankingDetailsDTO
   */
  validateCheckFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof BankingDetailsDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace BankingDetailsDTO
 */
export namespace BankingDetailsDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum FrequencyEnum {
    DAILY = <any>'DAILY',
    WEEKLY = <any>'WEEKLY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PaymentMethodEnum {
    ACH = <any>'ACH',
    CHECK = <any>'CHECK'
  }
}

/**
 *
 * @export
 * @interface BooleanDTO
 */
export interface BooleanDTO {
  /**
   *
   * @type {boolean}
   * @memberof BooleanDTO
   */
  value?: boolean
}

/**
 *
 * @export
 * @interface BsqRateDTO
 */
export interface BsqRateDTO {
  /**
   *
   * @type {number}
   * @memberof BsqRateDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  calculationType: BsqRateDTO.CalculationTypeEnum
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  deliveryOrderType?: BsqRateDTO.DeliveryOrderTypeEnum
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  deliveryPostalCode?: string
  /**
   *
   * @type {DateISOString}
   * @memberof BsqRateDTO
   */
  effectiveDate: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof BsqRateDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  loadType?: BsqRateDTO.LoadTypeEnum
  /**
   *
   * @type {number}
   * @memberof BsqRateDTO
   */
  number?: number
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  partyId?: string
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  pickupLocationId?: string
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  returnLocationId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof BsqRateDTO
   */
  ruleIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  status?: BsqRateDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  type: BsqRateDTO.TypeEnum
  /**
   *
   * @type {string}
   * @memberof BsqRateDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace BsqRateDTO
 */
export namespace BsqRateDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DeliveryOrderTypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROP = <any>'DROP',
    PICK = <any>'PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    EXPIRED = <any>'EXPIRED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    BASE = <any>'BASE',
    ALLIN = <any>'ALL_IN',
    FUEL = <any>'FUEL',
    CHASSIS = <any>'CHASSIS',
    DROPANDPICKUP = <any>'DROP_AND_PICKUP',
    TOLLS = <any>'TOLLS',
    DETENTION = <any>'DETENTION',
    PREPULL = <any>'PREPULL',
    REDELIVERY = <any>'REDELIVERY',
    STORAGE = <any>'STORAGE',
    OVERWEIGHT = <any>'OVERWEIGHT',
    HAZMAT = <any>'HAZMAT',
    REEFER = <any>'REEFER',
    LAYOVER = <any>'LAY_OVER',
    TANKER = <any>'TANKER',
    DRYRUN = <any>'DRY_RUN',
    PERDIEM = <any>'PER_DIEM',
    DEMURRAGE = <any>'DEMURRAGE',
    SCALE = <any>'SCALE',
    SHUTTLE = <any>'SHUTTLE',
    SHUTTLEHAZ = <any>'SHUTTLE_HAZ',
    SHUTTLEOW = <any>'SHUTTLE_OW',
    SHUTTLEREEFER = <any>'SHUTTLE_REEFER',
    DEDUCTION = <any>'DEDUCTION',
    DEDUCTIONREEFER = <any>'DEDUCTION_REEFER',
    CONGESTION = <any>'CONGESTION',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface BusinessPartnerDTO
 */
export interface BusinessPartnerDTO {
  /**
   *
   * @type {number}
   * @memberof BusinessPartnerDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessPartnerDTO
   */
  contactIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  dbaName?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  id?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerDTO
   */
  legalAddress?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  legalName: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  parentId?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerDTO
   */
  postalAddress?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  type?: BusinessPartnerDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  webSite?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerDTO
   */
  workingHours?: string
}

/**
 * @export
 * @namespace BusinessPartnerDTO
 */
export namespace BusinessPartnerDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DIVISION = <any>'DIVISION',
    ORGANIZATION = <any>'ORGANIZATION',
    INDIVIDUAL = <any>'INDIVIDUAL'
  }
}

/**
 *
 * @export
 * @interface BusinessPartnerGridItemDTO
 */
export interface BusinessPartnerGridItemDTO {
  /**
   *
   * @type {number}
   * @memberof BusinessPartnerGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerGridItemDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  dbaName?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  id?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerGridItemDTO
   */
  legalAddress?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  legalName: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  parentId?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerGridItemDTO
   */
  postalAddress?: AddressDTO
  /**
   *
   * @type {ContactDTO}
   * @memberof BusinessPartnerGridItemDTO
   */
  primaryContact?: ContactDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  type?: BusinessPartnerGridItemDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerGridItemDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  webSite?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerGridItemDTO
   */
  workingHours?: string
}

/**
 * @export
 * @namespace BusinessPartnerGridItemDTO
 */
export namespace BusinessPartnerGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DIVISION = <any>'DIVISION',
    ORGANIZATION = <any>'ORGANIZATION',
    INDIVIDUAL = <any>'INDIVIDUAL'
  }
}

/**
 *
 * @export
 * @interface BusinessPartnerViewDTO
 */
export interface BusinessPartnerViewDTO {
  /**
   *
   * @type {number}
   * @memberof BusinessPartnerViewDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<string>}
   * @memberof BusinessPartnerViewDTO
   */
  contactIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  dbaName?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  id?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerViewDTO
   */
  legalAddress?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  legalName: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  parentId?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof BusinessPartnerViewDTO
   */
  postalAddress?: AddressDTO
  /**
   *
   * @type {ContactDTO}
   * @memberof BusinessPartnerViewDTO
   */
  primaryContact?: ContactDTO
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  type?: BusinessPartnerViewDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof BusinessPartnerViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  webSite?: string
  /**
   *
   * @type {string}
   * @memberof BusinessPartnerViewDTO
   */
  workingHours?: string
}

/**
 * @export
 * @namespace BusinessPartnerViewDTO
 */
export namespace BusinessPartnerViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DIVISION = <any>'DIVISION',
    ORGANIZATION = <any>'ORGANIZATION',
    INDIVIDUAL = <any>'INDIVIDUAL'
  }
}

/**
 *
 * @export
 * @interface BuySideQuoteAmountDTO
 */
export interface BuySideQuoteAmountDTO {
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteAmountDTO
   */
  activityId?: string
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteAmountDTO
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteAmountDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteAmountDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteAmountDTO
   */
  id?: string
}

/**
 *
 * @export
 * @interface BuySideQuoteDTO
 */
export interface BuySideQuoteDTO {
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  activityId: string
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteDTO
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof BuySideQuoteDTO
   */
  completionDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof BuySideQuoteDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  createdBy?: string
  /**
   *
   * @type {DateISOString}
   * @memberof BuySideQuoteDTO
   */
  date: DateISOString
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  id?: string
  /**
   *
   * @type {Array<BuySideQuoteMiscSurchargeDTO>}
   * @memberof BuySideQuoteDTO
   */
  miscSurcharges: Array<BuySideQuoteMiscSurchargeDTO>
  /**
   *
   * @type {Array<BuySideQuoteSurchargeDTO>}
   * @memberof BuySideQuoteDTO
   */
  surcharges: Array<BuySideQuoteSurchargeDTO>
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  type: BuySideQuoteDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof BuySideQuoteDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace BuySideQuoteDTO
 */
export namespace BuySideQuoteDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    SHUTTLEPICKUPEMPTY = <any>'SHUTTLE_PICKUP_EMPTY',
    SHUTTLEPICKUPFULL = <any>'SHUTTLE_PICKUP_FULL',
    DROPEMPTY = <any>'DROP_EMPTY',
    DROPFULL = <any>'DROP_FULL',
    PICKEMPTY = <any>'PICK_EMPTY',
    PICKFULL = <any>'PICK_FULL',
    LIVELOAD = <any>'LIVE_LOAD',
    LIVEUNLOAD = <any>'LIVE_UNLOAD',
    SHUTTLERETURNEMPTY = <any>'SHUTTLE_RETURN_EMPTY',
    SHUTTLERETURNFULL = <any>'SHUTTLE_RETURN_FULL',
    ADDITIONALSURCHARGE = <any>'ADDITIONAL_SURCHARGE',
    REPOSITION = <any>'REPOSITION',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface BuySideQuoteMiscSurchargeDTO
 */
export interface BuySideQuoteMiscSurchargeDTO {
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  calculationType: BuySideQuoteMiscSurchargeDTO.CalculationTypeEnum
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  quantity?: number
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  total?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteMiscSurchargeDTO
   */
  type: BuySideQuoteMiscSurchargeDTO.TypeEnum
}

/**
 * @export
 * @namespace BuySideQuoteMiscSurchargeDTO
 */
export namespace BuySideQuoteMiscSurchargeDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GENERAL = <any>'GENERAL',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface BuySideQuoteSurchargeDTO
 */
export interface BuySideQuoteSurchargeDTO {
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteSurchargeDTO
   */
  amount: number
  /**
   *
   * @type {boolean}
   * @memberof BuySideQuoteSurchargeDTO
   */
  automatic: boolean
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteSurchargeDTO
   */
  calculationType: BuySideQuoteSurchargeDTO.CalculationTypeEnum
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteSurchargeDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteSurchargeDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteSurchargeDTO
   */
  quantity?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteSurchargeDTO
   */
  rateId: string
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteSurchargeDTO
   */
  rules?: string
  /**
   *
   * @type {number}
   * @memberof BuySideQuoteSurchargeDTO
   */
  total?: number
  /**
   *
   * @type {string}
   * @memberof BuySideQuoteSurchargeDTO
   */
  type: BuySideQuoteSurchargeDTO.TypeEnum
}

/**
 * @export
 * @namespace BuySideQuoteSurchargeDTO
 */
export namespace BuySideQuoteSurchargeDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    BASE = <any>'BASE',
    ALLIN = <any>'ALL_IN',
    FUEL = <any>'FUEL',
    CHASSIS = <any>'CHASSIS',
    DROPANDPICKUP = <any>'DROP_AND_PICKUP',
    TOLLS = <any>'TOLLS',
    DETENTION = <any>'DETENTION',
    PREPULL = <any>'PREPULL',
    REDELIVERY = <any>'REDELIVERY',
    STORAGE = <any>'STORAGE',
    OVERWEIGHT = <any>'OVERWEIGHT',
    HAZMAT = <any>'HAZMAT',
    REEFER = <any>'REEFER',
    LAYOVER = <any>'LAY_OVER',
    TANKER = <any>'TANKER',
    DRYRUN = <any>'DRY_RUN',
    PERDIEM = <any>'PER_DIEM',
    DEMURRAGE = <any>'DEMURRAGE',
    SCALE = <any>'SCALE',
    SHUTTLE = <any>'SHUTTLE',
    SHUTTLEHAZ = <any>'SHUTTLE_HAZ',
    SHUTTLEOW = <any>'SHUTTLE_OW',
    SHUTTLEREEFER = <any>'SHUTTLE_REEFER',
    DEDUCTION = <any>'DEDUCTION',
    DEDUCTIONREEFER = <any>'DEDUCTION_REEFER',
    CONGESTION = <any>'CONGESTION',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface CargoDTO
 */
export interface CargoDTO {
  /**
   *
   * @type {number}
   * @memberof CargoDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CargoDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  purchaseOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  referenceNumber: string
  /**
   *
   * @type {DateISOString}
   * @memberof CargoDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CargoDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface ConfirmedLocalDateTimeRangeDTO
 */
export interface ConfirmedLocalDateTimeRangeDTO {
  /**
   *
   * @type {number}
   * @memberof ConfirmedLocalDateTimeRangeDTO
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof ConfirmedLocalDateTimeRangeDTO
   */
  confirmed?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof ConfirmedLocalDateTimeRangeDTO
   */
  from?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof ConfirmedLocalDateTimeRangeDTO
   */
  to?: DateISOString
}

/**
 *
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
  /**
   *
   * @type {number}
   * @memberof ContactDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof ContactDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  fax?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  mobile?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  phone2?: string
  /**
   *
   * @type {boolean}
   * @memberof ContactDTO
   */
  primary?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof ContactDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContactDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface ContainerDTO
 */
export interface ContainerDTO {
  /**
   *
   * @type {boolean}
   * @memberof ContainerDTO
   */
  active?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerDTO
   */
  availability?: boolean
  /**
   *
   * @type {number}
   * @memberof ContainerDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  containerTypeId: string
  /**
   *
   * @type {DateISOString}
   * @memberof ContainerDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  inactiveReason?: ContainerDTO.InactiveReasonEnum
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  ownership: ContainerDTO.OwnershipEnum
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  serialNumber?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  specs?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  steamShipLineId?: string
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  unavailabilityReason?: ContainerDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof ContainerDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  updatedBy?: string
  /**
   *
   * @type {number}
   * @memberof ContainerDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof ContainerDTO
   */
  weightUnit?: ContainerDTO.WeightUnitEnum
  /**
   *
   * @type {number}
   * @memberof ContainerDTO
   */
  year?: number
}

/**
 * @export
 * @namespace ContainerDTO
 */
export namespace ContainerDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    REPAIR = <any>'REPAIR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    SHIPPEROWNED = <any>'SHIPPER_OWNED',
    LINEOWNED = <any>'LINE_OWNED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface ContainerSearchDTO
 */
export interface ContainerSearchDTO {
  /**
   *
   * @type {number}
   * @memberof ContainerSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof ContainerSearchDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {string}
   * @memberof ContainerSearchDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContainerSearchDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof ContainerSearchDTO
   */
  ownership?: ContainerSearchDTO.OwnershipEnum
  /**
   *
   * @type {string}
   * @memberof ContainerSearchDTO
   */
  steamShipLineId?: string
}

/**
 * @export
 * @namespace ContainerSearchDTO
 */
export namespace ContainerSearchDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    SHIPPEROWNED = <any>'SHIPPER_OWNED',
    LINEOWNED = <any>'LINE_OWNED'
  }
}

/**
 *
 * @export
 * @interface ContainerTypeDTO
 */
export interface ContainerTypeDTO {
  /**
   *
   * @type {string}
   * @memberof ContainerTypeDTO
   */
  code: string
  /**
   *
   * @type {number}
   * @memberof ContainerTypeDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof ContainerTypeDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContainerTypeDTO
   */
  isoCode: string
  /**
   *
   * @type {string}
   * @memberof ContainerTypeDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof ContainerTypeDTO
   */
  rank?: number
}

/**
 *
 * @export
 * @interface ContainerViewDTO
 */
export interface ContainerViewDTO {
  /**
   *
   * @type {boolean}
   * @memberof ContainerViewDTO
   */
  active?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerViewDTO
   */
  availability?: boolean
  /**
   *
   * @type {number}
   * @memberof ContainerViewDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  containerTypeId: string
  /**
   *
   * @type {DateISOString}
   * @memberof ContainerViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  inactiveReason?: ContainerViewDTO.InactiveReasonEnum
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  ownership: ContainerViewDTO.OwnershipEnum
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  serialNumber?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  specs?: string
  /**
   *
   * @type {SteamShipLineNameDTO}
   * @memberof ContainerViewDTO
   */
  steamShipLine?: SteamShipLineNameDTO
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  steamShipLineId?: string
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  unavailabilityReason?: ContainerViewDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof ContainerViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {number}
   * @memberof ContainerViewDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof ContainerViewDTO
   */
  weightUnit?: ContainerViewDTO.WeightUnitEnum
  /**
   *
   * @type {number}
   * @memberof ContainerViewDTO
   */
  year?: number
}

/**
 * @export
 * @namespace ContainerViewDTO
 */
export namespace ContainerViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    REPAIR = <any>'REPAIR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    SHIPPEROWNED = <any>'SHIPPER_OWNED',
    LINEOWNED = <any>'LINE_OWNED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface CountryDTO
 */
export interface CountryDTO {
  /**
   *
   * @type {string}
   * @memberof CountryDTO
   */
  code: string
  /**
   *
   * @type {number}
   * @memberof CountryDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof CountryDTO
   */
  name: string
}

/**
 *
 * @export
 * @interface CustomerBusinessPartnerViewDTO
 */
export interface CustomerBusinessPartnerViewDTO {
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  netTerms?: CustomerBusinessPartnerViewDTO.NetTermsEnum
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  netTermsUnit?: CustomerBusinessPartnerViewDTO.NetTermsUnitEnum
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  salesRepUserId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  subClientIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerBusinessPartnerViewDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace CustomerBusinessPartnerViewDTO
 */
export namespace CustomerBusinessPartnerViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsEnum {
    NET7 = <any>'NET7',
    NET15 = <any>'NET15',
    NET30 = <any>'NET30',
    NET45 = <any>'NET45',
    NET60 = <any>'NET60',
    NET90 = <any>'NET90',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsUnitEnum {
    NET = <any>'NET',
    ONDEMAND = <any>'ON_DEMAND'
  }
}

/**
 *
 * @export
 * @interface CustomerDTO
 */
export interface CustomerDTO {
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof CustomerDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  netTerms?: CustomerDTO.NetTermsEnum
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  netTermsUnit?: CustomerDTO.NetTermsUnitEnum
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  salesRepUserId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerDTO
   */
  subClientIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace CustomerDTO
 */
export namespace CustomerDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsEnum {
    NET7 = <any>'NET7',
    NET15 = <any>'NET15',
    NET30 = <any>'NET30',
    NET45 = <any>'NET45',
    NET60 = <any>'NET60',
    NET90 = <any>'NET90',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsUnitEnum {
    NET = <any>'NET',
    ONDEMAND = <any>'ON_DEMAND'
  }
}

/**
 *
 * @export
 * @interface CustomerDdoCount
 */
export interface CustomerDdoCount {
  /**
   *
   * @type {string}
   * @memberof CustomerDdoCount
   */
  customerName?: string
  /**
   *
   * @type {number}
   * @memberof CustomerDdoCount
   */
  ddoCount?: number
}

/**
 *
 * @export
 * @interface CustomerGridItemDTO
 */
export interface CustomerGridItemDTO {
  /**
   *
   * @type {BusinessPartnerGridItemDTO}
   * @memberof CustomerGridItemDTO
   */
  businessPartner?: BusinessPartnerGridItemDTO
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof CustomerGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerGridItemDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  netTerms?: CustomerGridItemDTO.NetTermsEnum
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  netTermsUnit?: CustomerGridItemDTO.NetTermsUnitEnum
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  salesRepUserId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerGridItemDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerGridItemDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace CustomerGridItemDTO
 */
export namespace CustomerGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsEnum {
    NET7 = <any>'NET7',
    NET15 = <any>'NET15',
    NET30 = <any>'NET30',
    NET45 = <any>'NET45',
    NET60 = <any>'NET60',
    NET90 = <any>'NET90',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsUnitEnum {
    NET = <any>'NET',
    ONDEMAND = <any>'ON_DEMAND'
  }
}

/**
 *
 * @export
 * @interface CustomerNameDTO
 */
export interface CustomerNameDTO {
  /**
   *
   * @type {number}
   * @memberof CustomerNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof CustomerNameDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CustomerNameDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface CustomerViewDTO
 */
export interface CustomerViewDTO {
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof CustomerViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof CustomerViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  netTerms?: CustomerViewDTO.NetTermsEnum
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  netTermsUnit?: CustomerViewDTO.NetTermsUnitEnum
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  salesRepUserId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerViewDTO
   */
  subClientIds?: Array<string>
  /**
   *
   * @type {Array<SubClientBusinessPartnerViewDTO>}
   * @memberof CustomerViewDTO
   */
  subClients?: Array<SubClientBusinessPartnerViewDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof CustomerViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof CustomerViewDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace CustomerViewDTO
 */
export namespace CustomerViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsEnum {
    NET7 = <any>'NET7',
    NET15 = <any>'NET15',
    NET30 = <any>'NET30',
    NET45 = <any>'NET45',
    NET60 = <any>'NET60',
    NET90 = <any>'NET90',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum NetTermsUnitEnum {
    NET = <any>'NET',
    ONDEMAND = <any>'ON_DEMAND'
  }
}

/**
 *
 * @export
 * @interface DashboardDTO
 */
export interface DashboardDTO {
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  activeDriverCount?: number
  /**
   *
   * @type {Array<string>}
   * @memberof DashboardDTO
   */
  activeDrivers?: Array<string>
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  cancelledOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  completedOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<NewOrderCount>}
   * @memberof DashboardDTO
   */
  cutOffNewOrders?: Array<NewOrderCount>
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  inProcessExportOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  inProcessImportOrderCount?: number
  /**
   *
   * @type {Array<NewOrderCount>}
   * @memberof DashboardDTO
   */
  lfdNewOrders?: Array<NewOrderCount>
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  newExportOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  newImportOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  readyExportForDispatchOrderCount?: number
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  readyImportForDispatchOrderCount?: number
  /**
   *
   * @type {Array<CustomerDdoCount>}
   * @memberof DashboardDTO
   */
  topFiveCustomers?: Array<CustomerDdoCount>
  /**
   *
   * @type {number}
   * @memberof DashboardDTO
   */
  unassignedDriverCount?: number
  /**
   *
   * @type {Array<string>}
   * @memberof DashboardDTO
   */
  unassignedDrivers?: Array<string>
}

/**
 *
 * @export
 * @interface DateTimeRangeDTO
 */
export interface DateTimeRangeDTO {
  /**
   *
   * @type {number}
   * @memberof DateTimeRangeDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DateTimeRangeDTO
   */
  from?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DateTimeRangeDTO
   */
  to?: DateISOString
}

/**
 *
 * @export
 * @interface DeliveryOrderDTO
 */
export interface DeliveryOrderDTO {
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DeliveryOrderDTO
   */
  appointmentDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  autoCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  cargoId: string
  /**
   *
   * @type {number}
   * @memberof DeliveryOrderDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  completionMonitor?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  customerId: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  date: DateISOString
  /**
   *
   * @type {boolean}
   * @memberof DeliveryOrderDTO
   */
  dateLocked?: boolean
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  deliveryLocationId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DeliveryOrderDTO
   */
  dispatchDeliveryOrderIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  equipmentFirstPickupDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  firstReceivingDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  generalCutoffDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  hazmatCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  lastFreeDateDemurrage?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  lastFreeDatePerDiem?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  loadType?: DeliveryOrderDTO.LoadTypeEnum
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  pickupLocationId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  reeferCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  returnLocationId?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  steamShipLineId?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  type: DeliveryOrderDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  updatedBy?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  vesselArrivalDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderDTO
   */
  vesselDepartureDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  vesselName?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderDTO
   */
  voyageNumber?: string
}

/**
 * @export
 * @namespace DeliveryOrderDTO
 */
export namespace DeliveryOrderDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
}

/**
 *
 * @export
 * @interface DeliveryOrderGridItemDTO
 */
export interface DeliveryOrderGridItemDTO {
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {number}
   * @memberof DeliveryOrderGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  completionMonitor?: string
  /**
   *
   * @type {CustomerNameDTO}
   * @memberof DeliveryOrderGridItemDTO
   */
  customer?: CustomerNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  customerId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  date?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  docNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  equipmentFirstPickupDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  firstReceivingDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  generalCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  lastFreeDateDemurrage?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  lastFreeDatePerDiem?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  number?: string
  /**
   *
   * @type {SteamShipLineNameDTO}
   * @memberof DeliveryOrderGridItemDTO
   */
  steamShipLine?: SteamShipLineNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  steamShipLineId?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderGridItemDTO
   */
  type?: DeliveryOrderGridItemDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderGridItemDTO
   */
  vesselArrivalDate?: DateISOString
}

/**
 * @export
 * @namespace DeliveryOrderGridItemDTO
 */
export namespace DeliveryOrderGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
}

/**
 *
 * @export
 * @interface DeliveryOrderSearchDTO
 */
export interface DeliveryOrderSearchDTO {
  /**
   *
   * @type {number}
   * @memberof DeliveryOrderSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderSearchDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderSearchDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderSearchDTO
   */
  type?: DeliveryOrderSearchDTO.TypeEnum
}

/**
 * @export
 * @namespace DeliveryOrderSearchDTO
 */
export namespace DeliveryOrderSearchDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
}

/**
 *
 * @export
 * @interface DeliveryOrderViewDTO
 */
export interface DeliveryOrderViewDTO {
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DeliveryOrderViewDTO
   */
  appointmentDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  autoCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {CargoDTO}
   * @memberof DeliveryOrderViewDTO
   */
  cargo?: CargoDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  cargoId: string
  /**
   *
   * @type {number}
   * @memberof DeliveryOrderViewDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  completionMonitor?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {CustomerNameDTO}
   * @memberof DeliveryOrderViewDTO
   */
  customer?: CustomerNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  customerId: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  date: DateISOString
  /**
   *
   * @type {boolean}
   * @memberof DeliveryOrderViewDTO
   */
  dateLocked?: boolean
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DeliveryOrderViewDTO
   */
  deliveryLocation?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  deliveryLocationId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DeliveryOrderViewDTO
   */
  dispatchDeliveryOrderIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  docNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  equipmentFirstPickupDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  firstReceivingDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  generalCutoffDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  hazmatCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  lastFreeDateDemurrage?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  lastFreeDatePerDiem?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  loadType?: DeliveryOrderViewDTO.LoadTypeEnum
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  number?: string
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DeliveryOrderViewDTO
   */
  pickupLocation?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  pickupLocationId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  reeferCutoffDate?: DateISOString
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DeliveryOrderViewDTO
   */
  returnLocation?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  returnLocationId?: string
  /**
   *
   * @type {SteamShipLineNameDTO}
   * @memberof DeliveryOrderViewDTO
   */
  steamShipLine?: SteamShipLineNameDTO
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  steamShipLineId?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  type: DeliveryOrderViewDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  vesselArrivalDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DeliveryOrderViewDTO
   */
  vesselDepartureDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  vesselName?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderViewDTO
   */
  voyageNumber?: string
}

/**
 * @export
 * @namespace DeliveryOrderViewDTO
 */
export namespace DeliveryOrderViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderAuditTrailDTO
 */
export interface DispatchDeliveryOrderAuditTrailDTO {
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  activityNumber?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  fieldName?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  newValue?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  oldValue?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderAuditTrailDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderDTO
 */
export interface DispatchDeliveryOrderDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof DispatchDeliveryOrderDTO
   */
  activityIds?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  archived?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof DispatchDeliveryOrderDTO
   */
  buySideQuoteIds?: Array<string>
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  containerId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  containerNumberPlaceholder?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  containerTypeId: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  createdBy?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  declinedVendorIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  deliveryOrderId: string
  /**
   *
   * @type {DispatchDeliveryOrderDeliveryStageDTO}
   * @memberof DispatchDeliveryOrderDTO
   */
  deliveryStage?: DispatchDeliveryOrderDeliveryStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  hazmatId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  loadType?: DispatchDeliveryOrderDTO.LoadTypeEnum
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderDTO
   */
  mileage?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  number?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderPickupStageDTO}
   * @memberof DispatchDeliveryOrderDTO
   */
  pickupStage: DispatchDeliveryOrderPickupStageDTO
  /**
   *
   * @type {DispatchDeliveryOrderReturnStageDTO}
   * @memberof DispatchDeliveryOrderDTO
   */
  returnStage: DispatchDeliveryOrderReturnStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  sealNumber?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  sellSideQuoteId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  status: DispatchDeliveryOrderDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  streetTurnId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  temperatureControl?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderDTO
   */
  terminalMismatchIndicator?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  updatedBy?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDTO
   */
  weightUnit?: DispatchDeliveryOrderDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace DispatchDeliveryOrderDTO
 */
export namespace DispatchDeliveryOrderDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderDeliveryStageDTO
 */
export interface DispatchDeliveryOrderDeliveryStageDTO {
  /**
   *
   * @type {TransportationActivityViewDTO}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  activity?: TransportationActivityViewDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  activityId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  actualAppointmentDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  actualPickDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  appointmentDate?: DateISOString
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  completeness?: number
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  location?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  locationId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  pickDate?: DateISOString
  /**
   *
   * @type {ConfirmedLocalDateTimeRangeDTO}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  plannedAppointmentDateTimeRange?: ConfirmedLocalDateTimeRangeDTO
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  plannedPickDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  spentTimeSpan?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderDeliveryStageDTO
   */
  vendorId?: string
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderGridItemDTO
 */
export interface DispatchDeliveryOrderGridItemDTO {
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  archived?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  cargoCutoffDate?: DateISOString
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {ContainerDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  container?: ContainerDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  containerId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  daysOut?: number
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  declinedVendorIndicator?: boolean
  /**
   *
   * @type {DeliveryOrderGridItemDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  deliveryOrder?: DeliveryOrderGridItemDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  deliveryOrderId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  deliveryPlannedAppointmentDateConfirmed?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderDeliveryStageDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  deliveryStage?: DispatchDeliveryOrderDeliveryStageDTO
  /**
   *
   * @type {ActiveEquipmentDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  equipment?: ActiveEquipmentDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  hazmatId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  loadType?: DispatchDeliveryOrderGridItemDTO.LoadTypeEnum
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  nextDoubleMoveDispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  number?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderPickupStageDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  pickupStage?: DispatchDeliveryOrderPickupStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  prevDoubleMoveDispatchDeliveryOrderId?: string
  /**
   *
   * @type {DispatchDeliveryOrderReturnStageDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  returnStage?: DispatchDeliveryOrderReturnStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  status?: DispatchDeliveryOrderGridItemDTO.StatusEnum
  /**
   *
   * @type {DispatchDeliveryOrderStreetTurnDTO}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  streetTurn?: DispatchDeliveryOrderStreetTurnDTO
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  streetTurnCount?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  streetTurnId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  terminalMismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemDTO
   */
  weightUnit?: DispatchDeliveryOrderGridItemDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace DispatchDeliveryOrderGridItemDTO
 */
export namespace DispatchDeliveryOrderGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderGridItemWithActivitiesDTO
 */
export interface DispatchDeliveryOrderGridItemWithActivitiesDTO {
  /**
   *
   * @type {ActivitiesGridItemDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  activities?: ActivitiesGridItemDTO
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  archived?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {Array<BuySideQuoteAmountDTO>}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  buySideQuotes?: Array<BuySideQuoteAmountDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  cargoCutoffDate?: DateISOString
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  completeness?: number
  /**
   *
   * @type {ContainerDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  container?: ContainerDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  containerId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  daysOut?: number
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  declinedVendorIndicator?: boolean
  /**
   *
   * @type {DeliveryOrderGridItemDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  deliveryOrder?: DeliveryOrderGridItemDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  deliveryOrderId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  deliveryPlannedAppointmentDateConfirmed?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderDeliveryStageDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  deliveryStage?: DispatchDeliveryOrderDeliveryStageDTO
  /**
   *
   * @type {ActiveEquipmentDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  equipment?: ActiveEquipmentDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  hazmatId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  loadType?: DispatchDeliveryOrderGridItemWithActivitiesDTO.LoadTypeEnum
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  nextDoubleMoveDispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  number?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderPickupStageDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  pickupStage?: DispatchDeliveryOrderPickupStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  prevDoubleMoveDispatchDeliveryOrderId?: string
  /**
   *
   * @type {DispatchDeliveryOrderReturnStageDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  returnStage?: DispatchDeliveryOrderReturnStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  status?: DispatchDeliveryOrderGridItemWithActivitiesDTO.StatusEnum
  /**
   *
   * @type {DispatchDeliveryOrderStreetTurnDTO}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  streetTurn?: DispatchDeliveryOrderStreetTurnDTO
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  streetTurnCount?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  streetTurnId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  terminalMismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderGridItemWithActivitiesDTO
   */
  weightUnit?: DispatchDeliveryOrderGridItemWithActivitiesDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace DispatchDeliveryOrderGridItemWithActivitiesDTO
 */
export namespace DispatchDeliveryOrderGridItemWithActivitiesDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderNumber
 */
export interface DispatchDeliveryOrderNumber {
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderNumber
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderNumber
   */
  number?: number
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderPickupStageDTO
 */
export interface DispatchDeliveryOrderPickupStageDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  actualAppointmentDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  appointmentDate?: DateISOString
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  completeness?: number
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  location?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  locationId?: string
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  plannedAppointmentDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  shuttle?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderPickupStageDTO
   */
  vendorId?: string
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderReturnStageDTO
 */
export interface DispatchDeliveryOrderReturnStageDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  actualAppointmentDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  appointmentDate?: DateISOString
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  completeness?: number
  /**
   *
   * @type {LocationNameDTO}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  location?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  locationId?: string
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  plannedAppointmentDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  shuttle?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderReturnStageDTO
   */
  vendorId?: string
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderSearchDTO
 */
export interface DispatchDeliveryOrderSearchDTO {
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  archived?: boolean
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {ContainerSearchDTO}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  container?: ContainerSearchDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {DeliveryOrderSearchDTO}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  deliveryOrder?: DeliveryOrderSearchDTO
  /**
   *
   * @type {DispatchDeliveryOrderDeliveryStageDTO}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  deliveryStage?: DispatchDeliveryOrderDeliveryStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  number?: string
  /**
   *
   * @type {DispatchDeliveryOrderPickupStageDTO}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  pickupStage?: DispatchDeliveryOrderPickupStageDTO
  /**
   *
   * @type {DispatchDeliveryOrderReturnStageDTO}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  returnStage?: DispatchDeliveryOrderReturnStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderSearchDTO
   */
  status?: DispatchDeliveryOrderSearchDTO.StatusEnum
}

/**
 * @export
 * @namespace DispatchDeliveryOrderSearchDTO
 */
export namespace DispatchDeliveryOrderSearchDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderSearchRequestDTO
 */
export interface DispatchDeliveryOrderSearchRequestDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof DispatchDeliveryOrderSearchRequestDTO
   */
  ids?: Array<string>
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderSearchRequestDTO
   */
  limit?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderSearchRequestDTO
   */
  term?: string
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderStreetTurnDTO
 */
export interface DispatchDeliveryOrderStreetTurnDTO {
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  ddoExportId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  ddoExportNumber?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  ddoImportId?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  ddoImportNumber?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  status?: DispatchDeliveryOrderStreetTurnDTO.StatusEnum
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderStreetTurnDTO
   */
  version?: number
}

/**
 * @export
 * @namespace DispatchDeliveryOrderStreetTurnDTO
 */
export namespace DispatchDeliveryOrderStreetTurnDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    SUBMITTED = <any>'SUBMITTED',
    APPROVED = <any>'APPROVED',
    REJECTED = <any>'REJECTED'
  }
}

/**
 *
 * @export
 * @interface DispatchDeliveryOrderViewDTO
 */
export interface DispatchDeliveryOrderViewDTO {
  /**
   *
   * @type {ActivitiesViewDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  activities?: ActivitiesViewDTO
  isAcceptRejectRequired?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  activityIds?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  archived?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  buySideQuoteIds?: Array<string>
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  completeness?: number
  /**
   *
   * @type {ContainerViewDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  container?: ContainerViewDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  containerId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  containerNumberPlaceholder?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  containerTypeId: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  daysOut?: number
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  declinedVendorIndicator?: boolean
  /**
   *
   * @type {DeliveryOrderViewDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  deliveryOrder?: DeliveryOrderViewDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  deliveryOrderId: string
  /**
   *
   * @type {DispatchDeliveryOrderDeliveryStageDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  deliveryStage?: DispatchDeliveryOrderDeliveryStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  description?: string
  /**
   *
   * @type {ActiveEquipmentDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  equipment?: ActiveEquipmentDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  hazmatId?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  loadType?: DispatchDeliveryOrderViewDTO.LoadTypeEnum
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  mileage?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  number?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {DispatchDeliveryOrderPickupStageDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  pickupStage: DispatchDeliveryOrderPickupStageDTO
  /**
   *
   * @type {ProgressBarState}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  progressBarState?: ProgressBarState
  /**
   *
   * @type {DispatchDeliveryOrderReturnStageDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  returnStage: DispatchDeliveryOrderReturnStageDTO
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  sealNumber?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  sellSideQuoteId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  status: DispatchDeliveryOrderViewDTO.StatusEnum
  /**
   *
   * @type {DispatchDeliveryOrderStreetTurnDTO}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  streetTurn?: DispatchDeliveryOrderStreetTurnDTO
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  streetTurnCount?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  streetTurnId?: string
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  temperatureControl?: string
  /**
   *
   * @type {boolean}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  terminalMismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {number}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof DispatchDeliveryOrderViewDTO
   */
  weightUnit?: DispatchDeliveryOrderViewDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace DispatchDeliveryOrderViewDTO
 */
export namespace DispatchDeliveryOrderViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
  /**
   *
   * @type {number}
   * @memberof DocumentDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  id: string
}

/**
 *
 * @export
 * @interface DocumentFileDTO
 */
export interface DocumentFileDTO {
  /**
   *
   * @type {number}
   * @memberof DocumentFileDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  contentType: string
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof DocumentFileDTO
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  thumb?: string
  /**
   *
   * @type {string}
   * @memberof DocumentFileDTO
   */
  url: string
}

/**
 *
 * @export
 * @interface DocumentMetadataDTO
 */
export interface DocumentMetadataDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityType?: DocumentMetadataDTO.ActivityTypeEnum
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderNumber: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  type?: DocumentMetadataDTO.TypeEnum
}

/**
 * @export
 * @namespace DocumentMetadataDTO
 */
export namespace DocumentMetadataDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ActivityTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TIR = <any>'TIR',
    POD = <any>'POD',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface DocumentValidationDTO
 */
export interface DocumentValidationDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  bookingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  dispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  message?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentValidationDTO
   */
  messages?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedChassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedContainerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedDispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  referenceNumberRecognized?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  signatureRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  status?: DocumentValidationDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  validatedManually?: boolean
}

/**
 * @export
 * @namespace DocumentValidationDTO
 */
export namespace DocumentValidationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    ACCEPTED = <any>'ACCEPTED',
    FAILED = <any>'FAILED'
  }
}

/**
 *
 * @export
 * @interface DocumentationActivityDTO
 */
export interface DocumentationActivityDTO {
  /**
   *
   * @type {number}
   * @memberof DocumentationActivityDTO
   */
  activityNumber?: number
  /**
   *
   * @type {number}
   * @memberof DocumentationActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentationActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentationActivityDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  groupId: string
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  matchedContainerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  matchedDDOId: string
  /**
   *
   * @type {number}
   * @memberof DocumentationActivityDTO
   */
  matchedDDONumber: number
  /**
   *
   * @type {number}
   * @memberof DocumentationActivityDTO
   */
  number: number
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  stage: DocumentationActivityDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentationActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof DocumentationActivityDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  status: DocumentationActivityDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof DocumentationActivityDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  type: DocumentationActivityDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DocumentationActivityDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DocumentationActivityDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace DocumentationActivityDTO
 */
export namespace DocumentationActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    SUBMITTED = <any>'SUBMITTED',
    APPROVED = <any>'APPROVED',
    REJECTED = <any>'REJECTED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    STREETTURN = <any>'STREET_TURN'
  }
}

/**
 *
 * @export
 * @interface DotSafetyInspectionDTO
 */
export interface DotSafetyInspectionDTO {
  /**
   *
   * @type {DocumentDTO}
   * @memberof DotSafetyInspectionDTO
   */
  document?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DotSafetyInspectionDTO
   */
  documentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DotSafetyInspectionDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DotSafetyInspectionDTO
   */
  performedDate?: DateISOString
}

/**
 *
 * @export
 * @interface DriverAvailabilityDTO
 */
export interface DriverAvailabilityDTO {
  /**
   *
   * @type {boolean}
   * @memberof DriverAvailabilityDTO
   */
  availability?: boolean
  /**
   *
   * @type {number}
   * @memberof DriverAvailabilityDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DriverAvailabilityDTO
   */
  driverType?: DriverAvailabilityDTO.DriverTypeEnum
  /**
   *
   * @type {string}
   * @memberof DriverAvailabilityDTO
   */
  inactiveReason?: DriverAvailabilityDTO.InactiveReasonEnum
  /**
   *
   * @type {string}
   * @memberof DriverAvailabilityDTO
   */
  status?: DriverAvailabilityDTO.StatusEnum
}

/**
 * @export
 * @namespace DriverAvailabilityDTO
 */
export namespace DriverAvailabilityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    EXPIREDCREDENTIALS = <any>'EXPIRED_CREDENTIALS',
    DRUGTESTNOTCOMPLETED = <any>'DRUG_TEST_NOT_COMPLETED',
    DOTSTOP = <any>'DOT_STOP',
    BIINSPECTIONNOTCOMPLETED = <any>'BI_INSPECTION_NOT_COMPLETED',
    QUARTERLYINSPECTIONNOTCOMPLETED = <any>'QUARTERLY_INSPECTION_NOT_COMPLETED',
    OUTOFDRIVINGHOURS = <any>'OUT_OF_DRIVING_HOURS',
    HOSVIOLATION = <any>'HOS_VIOLATION',
    PAPERLOGNOTCOMPLETED = <any>'PAPER_LOG_NOT_COMPLETED',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
}

/**
 *
 * @export
 * @interface DriverDTO
 */
export interface DriverDTO {
  /**
   *
   * @type {boolean}
   * @memberof DriverDTO
   */
  active?: boolean
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  applicationDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof DriverDTO
   */
  availability?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  birthday?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof DriverDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  driverType: DriverDTO.DriverTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverDTO
   */
  eld?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  eldNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  email?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DriverDTO
   */
  endorsement?: Array<DriverDTO.EndorsementEnum>
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  endorsementExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  ezPassTagNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  fuelCardNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DriverDTO
   */
  hasSSN?: boolean
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  i9DocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  inactiveReason?: DriverDTO.InactiveReasonEnum
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  lastName?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  leaseDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverDTO
   */
  licenseAddress?: AddressDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  licenseBackDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  licenseDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  licenseExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  licenseIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  licenseIssueStateId?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  licenseNumber?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverDTO
   */
  mailingAddress?: AddressDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  medicalExamCardDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  medicalExamCardExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  millageRestriction?: DriverDTO.MillageRestrictionEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverDTO
   */
  mobileApp?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  operationalPreference?: DriverDTO.OperationalPreferenceEnum
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  phone?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  portCredentialsDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  portCredentialsExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  proofEinTaxIdDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  ssn?: string
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  status?: DriverDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  taxId?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  twicDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  twicExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  unavailabilityReason?: DriverDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DriverDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverDTO
   */
  updatedBy?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  w4DocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverDTO
   */
  w9DocumentFile?: DocumentFileDTO
}

/**
 * @export
 * @namespace DriverDTO
 */
export namespace DriverDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum EndorsementEnum {
    HAZARDOUSMATERIALS = <any>'HAZARDOUS_MATERIALS',
    TANKERWITHHAZARDOUSMATERIALS = <any>'TANKER_WITH_HAZARDOUS_MATERIALS',
    TANKER = <any>'TANKER',
    DOUBLESTRIPLES = <any>'DOUBLES_TRIPLES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    EXPIREDCREDENTIALS = <any>'EXPIRED_CREDENTIALS',
    DRUGTESTNOTCOMPLETED = <any>'DRUG_TEST_NOT_COMPLETED',
    DOTSTOP = <any>'DOT_STOP',
    BIINSPECTIONNOTCOMPLETED = <any>'BI_INSPECTION_NOT_COMPLETED',
    QUARTERLYINSPECTIONNOTCOMPLETED = <any>'QUARTERLY_INSPECTION_NOT_COMPLETED',
    OUTOFDRIVINGHOURS = <any>'OUT_OF_DRIVING_HOURS',
    HOSVIOLATION = <any>'HOS_VIOLATION',
    PAPERLOGNOTCOMPLETED = <any>'PAPER_LOG_NOT_COMPLETED',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MillageRestrictionEnum {
    LESSTHEN100MILES = <any>'LESS_THEN_100_MILES',
    BETWEEN100AND150MILES = <any>'BETWEEN_100_AND_150_MILES',
    BETWEEN150AND300MILES = <any>'BETWEEN_150_AND_300_MILES',
    MORETHEN300MILES = <any>'MORE_THEN_300_MILES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OperationalPreferenceEnum {
    SINGLE = <any>'SINGLE',
    TEAM = <any>'TEAM'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
}

/**
 *
 * @export
 * @interface DriverEntitiesDTO
 */
export interface DriverEntitiesDTO {
  /**
   *
   * @type {BankingDetailsDTO}
   * @memberof DriverEntitiesDTO
   */
  bankingDetailsDTO?: BankingDetailsDTO
  /**
   *
   * @type {number}
   * @memberof DriverEntitiesDTO
   */
  completeness?: number
  /**
   *
   * @type {DriverDTO}
   * @memberof DriverEntitiesDTO
   */
  driverDTO?: DriverDTO
  /**
   *
   * @type {PowerUnitDTO}
   * @memberof DriverEntitiesDTO
   */
  powerUnitDTO?: PowerUnitDTO
  /**
   *
   * @type {string}
   * @memberof DriverEntitiesDTO
   */
  requestInitiator?: string
  /**
   *
   * @type {string}
   * @memberof DriverEntitiesDTO
   */
  userId?: string
  /**
   *
   * @type {DriverDTO}
   * @memberof DriverEntitiesDTO
   */
  vendorDTO?: DriverDTO
}

/**
 *
 * @export
 * @interface DriverGridItemDTO
 */
export interface DriverGridItemDTO {
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  authUserId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  birthday?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof DriverGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  driverType: DriverGridItemDTO.DriverTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverGridItemDTO
   */
  eld?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  eldNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  email?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DriverGridItemDTO
   */
  endorsement?: Array<DriverGridItemDTO.EndorsementEnum>
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  endorsementExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  ezPassTagNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  fuelCardNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  lastName?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverGridItemDTO
   */
  licenseAddress?: AddressDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  licenseExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  licenseIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  licenseIssueStateId?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  licenseNumber?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverGridItemDTO
   */
  mailingAddress?: AddressDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  medicalExamCardExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  millageRestriction?: DriverGridItemDTO.MillageRestrictionEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverGridItemDTO
   */
  mobileApp?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  operationalPreference?: DriverGridItemDTO.OperationalPreferenceEnum
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  phone?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  portCredentialsExpDate?: DateISOString
  /**
   *
   * @type {PowerUnitSearchDTO}
   * @memberof DriverGridItemDTO
   */
  powerUnit?: PowerUnitSearchDTO
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  status?: DriverGridItemDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  taxId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  twicExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DriverGridItemDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverGridItemDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace DriverGridItemDTO
 */
export namespace DriverGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum EndorsementEnum {
    HAZARDOUSMATERIALS = <any>'HAZARDOUS_MATERIALS',
    TANKERWITHHAZARDOUSMATERIALS = <any>'TANKER_WITH_HAZARDOUS_MATERIALS',
    TANKER = <any>'TANKER',
    DOUBLESTRIPLES = <any>'DOUBLES_TRIPLES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MillageRestrictionEnum {
    LESSTHEN100MILES = <any>'LESS_THEN_100_MILES',
    BETWEEN100AND150MILES = <any>'BETWEEN_100_AND_150_MILES',
    BETWEEN150AND300MILES = <any>'BETWEEN_150_AND_300_MILES',
    MORETHEN300MILES = <any>'MORE_THEN_300_MILES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OperationalPreferenceEnum {
    SINGLE = <any>'SINGLE',
    TEAM = <any>'TEAM'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
}

/**
 *
 * @export
 * @interface DriverNameDTO
 */
export interface DriverNameDTO {
  /**
   *
   * @type {number}
   * @memberof DriverNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DriverNameDTO
   */
  driverType?: DriverNameDTO.DriverTypeEnum
  /**
   *
   * @type {string}
   * @memberof DriverNameDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverNameDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DriverNameDTO
   */
  number?: string
}

/**
 * @export
 * @namespace DriverNameDTO
 */
export namespace DriverNameDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
}

/**
 *
 * @export
 * @interface DriverSimpleDTO
 */
export interface DriverSimpleDTO {
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  authUserId?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof DriverSimpleDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  driverType: DriverSimpleDTO.DriverTypeEnum
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  middleName?: string
  /**
   *
   * @type {boolean}
   * @memberof DriverSimpleDTO
   */
  mobileApp?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  operationalPreference?: DriverSimpleDTO.OperationalPreferenceEnum
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {string}
   * @memberof DriverSimpleDTO
   */
  status?: DriverSimpleDTO.StatusEnum
}

/**
 * @export
 * @namespace DriverSimpleDTO
 */
export namespace DriverSimpleDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OperationalPreferenceEnum {
    SINGLE = <any>'SINGLE',
    TEAM = <any>'TEAM'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
}

/**
 *
 * @export
 * @interface DriverStatusDTO
 */
export interface DriverStatusDTO {
  /**
   *
   * @type {string}
   * @memberof DriverStatusDTO
   */
  authUserId?: string
  /**
   *
   * @type {number}
   * @memberof DriverStatusDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof DriverStatusDTO
   */
  driverType?: DriverStatusDTO.DriverTypeEnum
  /**
   *
   * @type {string}
   * @memberof DriverStatusDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverStatusDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof DriverStatusDTO
   */
  status?: DriverStatusDTO.StatusEnum
}

/**
 * @export
 * @namespace DriverStatusDTO
 */
export namespace DriverStatusDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
}

/**
 *
 * @export
 * @interface DriverViewDTO
 */
export interface DriverViewDTO {
  /**
   *
   * @type {boolean}
   * @memberof DriverViewDTO
   */
  active?: boolean
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  applicationDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof DriverViewDTO
   */
  availability?: boolean
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  birthday?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof DriverViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  driverType: DriverViewDTO.DriverTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverViewDTO
   */
  eld?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  eldNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  email?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DriverViewDTO
   */
  endorsement?: Array<DriverViewDTO.EndorsementEnum>
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  endorsementExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  ezPassTagNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  fuelCardNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DriverViewDTO
   */
  hasSSN?: boolean
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  i9DocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  inactiveReason?: DriverViewDTO.InactiveReasonEnum
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  lastName?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  leaseDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverViewDTO
   */
  licenseAddress?: AddressDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  licenseBackDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  licenseDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  licenseExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  licenseIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  licenseIssueStateId?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  licenseNumber?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof DriverViewDTO
   */
  mailingAddress?: AddressDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  medicalExamCardDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  medicalExamCardExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  millageRestriction?: DriverViewDTO.MillageRestrictionEnum
  /**
   *
   * @type {boolean}
   * @memberof DriverViewDTO
   */
  mobileApp?: boolean
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  operationalPreference?: DriverViewDTO.OperationalPreferenceEnum
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  phone?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  portCredentialsDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  portCredentialsExpDate?: DateISOString
  /**
   *
   * @type {PowerUnitSearchDTO}
   * @memberof DriverViewDTO
   */
  powerUnit?: PowerUnitSearchDTO
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  proofEinTaxIdDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  ssn?: string
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  status?: DriverViewDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  taxId?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  twicDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  twicExpDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  unavailabilityReason?: DriverViewDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof DriverViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  w4DocumentFile?: DocumentFileDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof DriverViewDTO
   */
  w9DocumentFile?: DocumentFileDTO
}

/**
 * @export
 * @namespace DriverViewDTO
 */
export namespace DriverViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR',
    VENDOR = <any>'VENDOR',
    OUTSOURCE = <any>'OUTSOURCE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum EndorsementEnum {
    HAZARDOUSMATERIALS = <any>'HAZARDOUS_MATERIALS',
    TANKERWITHHAZARDOUSMATERIALS = <any>'TANKER_WITH_HAZARDOUS_MATERIALS',
    TANKER = <any>'TANKER',
    DOUBLESTRIPLES = <any>'DOUBLES_TRIPLES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    EXPIREDCREDENTIALS = <any>'EXPIRED_CREDENTIALS',
    DRUGTESTNOTCOMPLETED = <any>'DRUG_TEST_NOT_COMPLETED',
    DOTSTOP = <any>'DOT_STOP',
    BIINSPECTIONNOTCOMPLETED = <any>'BI_INSPECTION_NOT_COMPLETED',
    QUARTERLYINSPECTIONNOTCOMPLETED = <any>'QUARTERLY_INSPECTION_NOT_COMPLETED',
    OUTOFDRIVINGHOURS = <any>'OUT_OF_DRIVING_HOURS',
    HOSVIOLATION = <any>'HOS_VIOLATION',
    PAPERLOGNOTCOMPLETED = <any>'PAPER_LOG_NOT_COMPLETED',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MillageRestrictionEnum {
    LESSTHEN100MILES = <any>'LESS_THEN_100_MILES',
    BETWEEN100AND150MILES = <any>'BETWEEN_100_AND_150_MILES',
    BETWEEN150AND300MILES = <any>'BETWEEN_150_AND_300_MILES',
    MORETHEN300MILES = <any>'MORE_THEN_300_MILES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OperationalPreferenceEnum {
    SINGLE = <any>'SINGLE',
    TEAM = <any>'TEAM'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
}

/**
 *
 * @export
 * @interface EquipmentDTO
 */
export interface EquipmentDTO {
  /**
   *
   * @type {boolean}
   * @memberof EquipmentDTO
   */
  active?: boolean
  /**
   *
   * @type {boolean}
   * @memberof EquipmentDTO
   */
  availability?: boolean
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  chassisNumber: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  company?: string
  /**
   *
   * @type {number}
   * @memberof EquipmentDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof EquipmentDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  createdBy?: string
  /**
   *
   * @type {number}
   * @memberof EquipmentDTO
   */
  days?: number
  /**
   *
   * @type {DotSafetyInspectionDTO}
   * @memberof EquipmentDTO
   */
  dotSafetyInspection?: DotSafetyInspectionDTO
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  equipmentSubType?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  equipmentType: EquipmentDTO.EquipmentTypeEnum
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  inactiveReason?: EquipmentDTO.InactiveReasonEnum
  /**
   *
   * @type {InsuranceDTO}
   * @memberof EquipmentDTO
   */
  insurance?: InsuranceDTO
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  licensePlate?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  maintenanceInspectionFrequency?: EquipmentDTO.MaintenanceInspectionFrequencyEnum
  /**
   *
   * @type {Array<MaintenanceInspectionDTO>}
   * @memberof EquipmentDTO
   */
  maintenanceInspections?: Array<MaintenanceInspectionDTO>
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  ownership: EquipmentDTO.OwnershipEnum
  /**
   *
   * @type {DateISOString}
   * @memberof EquipmentDTO
   */
  pickupDate?: DateISOString
  /**
   *
   * @type {RegistrationDTO}
   * @memberof EquipmentDTO
   */
  registration?: RegistrationDTO
  /**
   *
   * @type {Array<RepairDTO>}
   * @memberof EquipmentDTO
   */
  repairs?: Array<RepairDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof EquipmentDTO
   */
  returnDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  specs?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  unavailabilityReason?: EquipmentDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof EquipmentDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  vin?: string
  /**
   *
   * @type {number}
   * @memberof EquipmentDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof EquipmentDTO
   */
  weightUnit?: EquipmentDTO.WeightUnitEnum
  /**
   *
   * @type {number}
   * @memberof EquipmentDTO
   */
  year?: number
}

/**
 * @export
 * @namespace EquipmentDTO
 */
export namespace EquipmentDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum EquipmentTypeEnum {
    FLATBEDTRAILER = <any>'FLATBED_TRAILER',
    DRYVANS = <any>'DRY_VANS',
    REFRIGERATEDTRAILER = <any>'REFRIGERATED_TRAILER',
    LOWBOYTRAILER = <any>'LOWBOY_TRAILER',
    STEPDECKTRAILER = <any>'STEP_DECK_TRAILER',
    EXTENDABLEFLATBEDTRAILER = <any>'EXTENDABLE_FLATBED_TRAILER',
    STRETCHSINGLEDROPDECKTRAILER = <any>'STRETCH_SINGLE_DROP_DECK_TRAILER',
    REMOVABLEGOOSENECKTRAILER = <any>'REMOVABLE_GOOSENECK_TRAILER',
    SPECIALTYTRAILER = <any>'SPECIALTY_TRAILER',
    SIDEKITTRAILER = <any>'SIDEKIT_TRAILER',
    EXTENDABLEDOUBLEDROPTRAILER = <any>'EXTENDABLE_DOUBLE_DROP_TRAILER',
    STRETCHRNGTRAILER = <any>'STRETCH_RNG_TRAILER',
    CONESTOGATRAILER = <any>'CONESTOGA_TRAILER',
    POWERONLYTRAILER = <any>'POWER_ONLY_TRAILER',
    MULTICARTRAILER = <any>'MULTI_CAR_TRAILER',
    SPECIALIZEDTRAILER = <any>'SPECIALIZED_TRAILER',
    OCEANCONTAINERCHASSIS = <any>'OCEAN_CONTAINER_CHASSIS'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    EXPIREDREGISTRATION = <any>'EXPIRED_REGISTRATION',
    EXPIREDSAFETYINSPECTION = <any>'EXPIRED_SAFETY_INSPECTION',
    EXPIREDMAINTENANCEINSPECTION = <any>'EXPIRED_MAINTENANCE_INSPECTION',
    MAINTENANCE = <any>'MAINTENANCE',
    OTHER = <any>'OTHER',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MaintenanceInspectionFrequencyEnum {
    MONTHLY = <any>'MONTHLY',
    QUARTERLY = <any>'QUARTERLY',
    SEMIANNUALLY = <any>'SEMI_ANNUALLY',
    ANNUALLY = <any>'ANNUALLY',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    OWNED = <any>'OWNED',
    LEASED = <any>'LEASED',
    RENTED = <any>'RENTED',
    OWNEROPERATED = <any>'OWNER_OPERATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface EquipmentSearchDTO
 */
export interface EquipmentSearchDTO {
  /**
   *
   * @type {string}
   * @memberof EquipmentSearchDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {number}
   * @memberof EquipmentSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof EquipmentSearchDTO
   */
  id?: string
}

/**
 *
 * @export
 * @interface FeeScheduleDTO
 */
export interface FeeScheduleDTO {
  /**
   *
   * @type {number}
   * @memberof FeeScheduleDTO
   */
  completeness?: number
  /**
   *
   * @type {number}
   * @memberof FeeScheduleDTO
   */
  cost?: number
  /**
   *
   * @type {DateISOString}
   * @memberof FeeScheduleDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof FeeScheduleDTO
   */
  createdBy?: string
  /**
   *
   * @type {number}
   * @memberof FeeScheduleDTO
   */
  fromDay?: number
  /**
   *
   * @type {string}
   * @memberof FeeScheduleDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof FeeScheduleDTO
   */
  toDay?: number
  /**
   *
   * @type {DateISOString}
   * @memberof FeeScheduleDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof FeeScheduleDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface Filter
 */
export interface Filter {
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  operation?: string
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  value?: string
}

/**
 *
 * @export
 * @interface FuelPrice
 */
export interface FuelPrice {
  /**
   *
   * @type {string}
   * @memberof FuelPrice
   */
  date?: string
  /**
   *
   * @type {number}
   * @memberof FuelPrice
   */
  price?: number
}

/**
 *
 * @export
 * @interface HazmatDTO
 */
export interface HazmatDTO {
  /**
   *
   * @type {string}
   * @memberof HazmatDTO
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof HazmatDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof HazmatDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof HazmatDTO
   */
  hazardClass?: string
  /**
   *
   * @type {string}
   * @memberof HazmatDTO
   */
  id?: string
}

/**
 *
 * @export
 * @interface InsuranceDTO
 */
export interface InsuranceDTO {
  /**
   *
   * @type {string}
   * @memberof InsuranceDTO
   */
  carrier?: string
  /**
   *
   * @type {DocumentDTO}
   * @memberof InsuranceDTO
   */
  coverageCard?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof InsuranceDTO
   */
  coverageCardFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof InsuranceDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof InsuranceDTO
   */
  insuranceType?: InsuranceDTO.InsuranceTypeEnum
  /**
   *
   * @type {string}
   * @memberof InsuranceDTO
   */
  policyNumber?: string
}

/**
 * @export
 * @namespace InsuranceDTO
 */
export namespace InsuranceDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InsuranceTypeEnum {
    LIABILITY = <any>'LIABILITY',
    PROPERTY = <any>'PROPERTY',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface JobOrderTrackingDataDTO
 */
export interface JobOrderTrackingDataDTO {
  /**
   *
   * @type {string}
   * @memberof JobOrderTrackingDataDTO
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof JobOrderTrackingDataDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof JobOrderTrackingDataDTO
   */
  documentNumber?: string
  /**
   *
   * @type {string}
   * @memberof JobOrderTrackingDataDTO
   */
  jobOrderNumber?: string
}

/**
 *
 * @export
 * @interface LocalDateTimeRangeDTO
 */
export interface LocalDateTimeRangeDTO {
  /**
   *
   * @type {number}
   * @memberof LocalDateTimeRangeDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof LocalDateTimeRangeDTO
   */
  from?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof LocalDateTimeRangeDTO
   */
  to?: DateISOString
}

/**
 *
 * @export
 * @interface LocationDTO
 */
export interface LocationDTO {
  /**
   *
   * @type {boolean}
   * @memberof LocationDTO
   */
  active: boolean
  /**
   *
   * @type {AddressDTO}
   * @memberof LocationDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  altitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  careOf?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  cityStateZip?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<string>}
   * @memberof LocationDTO
   */
  contactIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof LocationDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  duplicateOfId?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  externalAddress?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  latitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  longName?: string
  /**
   *
   * @type {number}
   * @memberof LocationDTO
   */
  longitude?: number
  /**
   *
   * @type {boolean}
   * @memberof LocationDTO
   */
  mismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  placeId?: string
  /**
   *
   * @type {boolean}
   * @memberof LocationDTO
   */
  primary?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  rating?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  status: LocationDTO.StatusEnum
  /**
   *
   * @type {TerminalIntegrationDTO}
   * @memberof LocationDTO
   */
  terminalIntegration?: TerminalIntegrationDTO
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  timezone?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  type: LocationDTO.TypeEnum
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  unlocode?: string
  /**
   *
   * @type {DateISOString}
   * @memberof LocationDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  webSite?: string
  /**
   *
   * @type {string}
   * @memberof LocationDTO
   */
  workingHours?: string
}

/**
 * @export
 * @namespace LocationDTO
 */
export namespace LocationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PARTIAL = <any>'PARTIAL',
    FULL = <any>'FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface LocationGridItemDTO
 */
export interface LocationGridItemDTO {
  /**
   *
   * @type {boolean}
   * @memberof LocationGridItemDTO
   */
  active?: boolean
  /**
   *
   * @type {AddressDTO}
   * @memberof LocationGridItemDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  careOf?: string
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof LocationGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  externalAddress?: string
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof LocationGridItemDTO
   */
  latitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  longName?: string
  /**
   *
   * @type {number}
   * @memberof LocationGridItemDTO
   */
  longitude?: number
  /**
   *
   * @type {boolean}
   * @memberof LocationGridItemDTO
   */
  mismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  placeId?: string
  /**
   *
   * @type {ContactDTO}
   * @memberof LocationGridItemDTO
   */
  primaryContact?: ContactDTO
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  status?: LocationGridItemDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof LocationGridItemDTO
   */
  type?: LocationGridItemDTO.TypeEnum
}

/**
 * @export
 * @namespace LocationGridItemDTO
 */
export namespace LocationGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PARTIAL = <any>'PARTIAL',
    FULL = <any>'FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface LocationNameDTO
 */
export interface LocationNameDTO {
  /**
   *
   * @type {AddressDTO}
   * @memberof LocationNameDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  careOf?: string
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  cityStateZip?: string
  /**
   *
   * @type {number}
   * @memberof LocationNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof LocationNameDTO
   */
  latitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  longName?: string
  /**
   *
   * @type {number}
   * @memberof LocationNameDTO
   */
  longitude?: number
  /**
   *
   * @type {boolean}
   * @memberof LocationNameDTO
   */
  mismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  placeId?: string
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  status?: LocationNameDTO.StatusEnum
  /**
   *
   * @type {TerminalIntegrationDTO}
   * @memberof LocationNameDTO
   */
  terminalIntegration?: TerminalIntegrationDTO
  /**
   *
   * @type {string}
   * @memberof LocationNameDTO
   */
  type?: LocationNameDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof LocationNameDTO
   */
  updatedAt?: DateISOString
}

/**
 * @export
 * @namespace LocationNameDTO
 */
export namespace LocationNameDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PARTIAL = <any>'PARTIAL',
    FULL = <any>'FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface LocationSimpleDTO
 */
export interface LocationSimpleDTO {
  /**
   *
   * @type {AddressSimpleDTO}
   * @memberof LocationSimpleDTO
   */
  address?: AddressSimpleDTO
  /**
   *
   * @type {number}
   * @memberof LocationSimpleDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof LocationSimpleDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof LocationSimpleDTO
   */
  latitude?: number
  /**
   *
   * @type {number}
   * @memberof LocationSimpleDTO
   */
  longitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationSimpleDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LocationSimpleDTO
   */
  placeId?: string
  /**
   *
   * @type {string}
   * @memberof LocationSimpleDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof LocationSimpleDTO
   */
  type?: LocationSimpleDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof LocationSimpleDTO
   */
  updatedAt?: DateISOString
}

/**
 * @export
 * @namespace LocationSimpleDTO
 */
export namespace LocationSimpleDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface LocationViewDTO
 */
export interface LocationViewDTO {
  /**
   *
   * @type {boolean}
   * @memberof LocationViewDTO
   */
  active: boolean
  /**
   *
   * @type {AddressDTO}
   * @memberof LocationViewDTO
   */
  address?: AddressDTO
  /**
   *
   * @type {number}
   * @memberof LocationViewDTO
   */
  altitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  careOf?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  cityStateZip?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof LocationViewDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<string>}
   * @memberof LocationViewDTO
   */
  contactIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof LocationViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  duplicateOfId?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  externalAddress?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof LocationViewDTO
   */
  latitude?: number
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  longName?: string
  /**
   *
   * @type {number}
   * @memberof LocationViewDTO
   */
  longitude?: number
  /**
   *
   * @type {boolean}
   * @memberof LocationViewDTO
   */
  mismatchIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  placeId?: string
  /**
   *
   * @type {boolean}
   * @memberof LocationViewDTO
   */
  primary?: boolean
  /**
   *
   * @type {ContactDTO}
   * @memberof LocationViewDTO
   */
  primaryContact?: ContactDTO
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  primaryContactId?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  rating?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  status: LocationViewDTO.StatusEnum
  /**
   *
   * @type {TerminalIntegrationDTO}
   * @memberof LocationViewDTO
   */
  terminalIntegration?: TerminalIntegrationDTO
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  timezone?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  type: LocationViewDTO.TypeEnum
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  unlocode?: string
  /**
   *
   * @type {DateISOString}
   * @memberof LocationViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  webSite?: string
  /**
   *
   * @type {string}
   * @memberof LocationViewDTO
   */
  workingHours?: string
}

/**
 * @export
 * @namespace LocationViewDTO
 */
export namespace LocationViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PARTIAL = <any>'PARTIAL',
    FULL = <any>'FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface MaintenanceInspectionDTO
 */
export interface MaintenanceInspectionDTO {
  /**
   *
   * @type {number}
   * @memberof MaintenanceInspectionDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MaintenanceInspectionDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof MaintenanceInspectionDTO
   */
  createdBy?: string
  /**
   *
   * @type {DocumentDTO}
   * @memberof MaintenanceInspectionDTO
   */
  document?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof MaintenanceInspectionDTO
   */
  documentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof MaintenanceInspectionDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof MaintenanceInspectionDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof MaintenanceInspectionDTO
   */
  performedDate: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof MaintenanceInspectionDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof MaintenanceInspectionDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface MoveBuildDTO
 */
export interface MoveBuildDTO {
  /**
   *
   * @type {number}
   * @memberof MoveBuildDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveBuildDTO
   */
  deliveryOrderType?: MoveBuildDTO.DeliveryOrderTypeEnum
  /**
   *
   * @type {string}
   * @memberof MoveBuildDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {Array<MoveBuildDocumentationActivityDTO>}
   * @memberof MoveBuildDTO
   */
  documentationActivities?: Array<MoveBuildDocumentationActivityDTO>
  /**
   *
   * @type {string}
   * @memberof MoveBuildDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof MoveBuildDTO
   */
  status?: MoveBuildDTO.StatusEnum
  /**
   *
   * @type {Array<MoveBuildTransportationActivityDTO>}
   * @memberof MoveBuildDTO
   */
  transportationActivities?: Array<MoveBuildTransportationActivityDTO>
}

/**
 * @export
 * @namespace MoveBuildDTO
 */
export namespace MoveBuildDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DeliveryOrderTypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
}

/**
 *
 * @export
 * @interface MoveBuildDocumentationActivityDTO
 */
export interface MoveBuildDocumentationActivityDTO {
  /**
   *
   * @type {number}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  groupId?: string
  /**
   *
   * @type {string}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  number?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof MoveBuildDocumentationActivityDTO
   */
  status?: MoveBuildDocumentationActivityDTO.StatusEnum
}

/**
 * @export
 * @namespace MoveBuildDocumentationActivityDTO
 */
export namespace MoveBuildDocumentationActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    SUBMITTED = <any>'SUBMITTED',
    APPROVED = <any>'APPROVED',
    REJECTED = <any>'REJECTED'
  }
}

/**
 *
 * @export
 * @interface MoveBuildTransportationActivityDTO
 */
export interface MoveBuildTransportationActivityDTO {
  /**
   *
   * @type {number}
   * @memberof MoveBuildTransportationActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveBuildTransportationActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof MoveBuildTransportationActivityDTO
   */
  groupId?: string
  /**
   *
   * @type {string}
   * @memberof MoveBuildTransportationActivityDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveBuildTransportationActivityDTO
   */
  number?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveBuildTransportationActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof MoveBuildTransportationActivityDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof MoveBuildTransportationActivityDTO
   */
  status?: MoveBuildTransportationActivityDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof MoveBuildTransportationActivityDTO
   */
  type?: MoveBuildTransportationActivityDTO.TypeEnum
  /**
   *
   * @type {MoveBuildVendorDTO}
   * @memberof MoveBuildTransportationActivityDTO
   */
  vendor?: MoveBuildVendorDTO
}

/**
 * @export
 * @namespace MoveBuildTransportationActivityDTO
 */
export namespace MoveBuildTransportationActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface MoveBuildVendorDTO
 */
export interface MoveBuildVendorDTO {
  /**
   *
   * @type {string}
   * @memberof MoveBuildVendorDTO
   */
  authUserId?: string
  /**
   *
   * @type {number}
   * @memberof MoveBuildVendorDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveBuildVendorDTO
   */
  id?: string
}

/**
 *
 * @export
 * @interface MoveDeliveryOrderDetailsDTO
 */
export interface MoveDeliveryOrderDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof MoveDeliveryOrderDetailsDTO
   */
  cargoDescription?: string
  /**
   *
   * @type {number}
   * @memberof MoveDeliveryOrderDetailsDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveDeliveryOrderDetailsDTO
   */
  deliveryOrderDocNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDeliveryOrderDetailsDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MoveDeliveryOrderDetailsDTO
   */
  sslName?: string
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderContainerDTO
 */
export interface MoveDispatchDeliveryOrderContainerDTO {
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderContainerDTO
   */
  number: string
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderDTO
 */
export interface MoveDispatchDeliveryOrderDTO {
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  containerTypeCode?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  deliveryOrderDocNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  equipmentChassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  equipmentChassisOwnership?: MoveDispatchDeliveryOrderDTO.EquipmentChassisOwnershipEnum
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  number?: number
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDTO
   */
  refrigeratorIndicator?: boolean
}

/**
 * @export
 * @namespace MoveDispatchDeliveryOrderDTO
 */
export namespace MoveDispatchDeliveryOrderDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum EquipmentChassisOwnershipEnum {
    OWNED = <any>'OWNED',
    LEASED = <any>'LEASED',
    RENTED = <any>'RENTED',
    OWNEROPERATED = <any>'OWNER_OPERATED'
  }
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderDetailsDTO
 */
export interface MoveDispatchDeliveryOrderDetailsDTO {
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  autoIndicator?: boolean
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  containerTypeCode?: string
  /**
   *
   * @type {MoveDeliveryOrderDetailsDTO}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  deliveryOrder?: MoveDeliveryOrderDetailsDTO
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  equipmentChassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  equipmentChassisOwnership?: MoveDispatchDeliveryOrderDetailsDTO.EquipmentChassisOwnershipEnum
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  hazmatCode?: string
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  hazmatDescription?: string
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  number?: number
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {boolean}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  refrigeratorIndicator?: boolean
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderDetailsDTO
   */
  weightUnit?: MoveDispatchDeliveryOrderDetailsDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace MoveDispatchDeliveryOrderDetailsDTO
 */
export namespace MoveDispatchDeliveryOrderDetailsDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum EquipmentChassisOwnershipEnum {
    OWNED = <any>'OWNED',
    LEASED = <any>'LEASED',
    RENTED = <any>'RENTED',
    OWNEROPERATED = <any>'OWNER_OPERATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderEquipmentDTO
 */
export interface MoveDispatchDeliveryOrderEquipmentDTO {
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderEquipmentDTO
   */
  chassisNumber: string
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderEquipmentMapDTO
 */
export interface MoveDispatchDeliveryOrderEquipmentMapDTO {
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderEquipmentMapDTO
   */
  chassisNumber: string
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderEquipmentMapDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderEquipmentMapDTO
   */
  ddoId: string
}

/**
 *
 * @export
 * @interface MoveDispatchDeliveryOrderWeightDTO
 */
export interface MoveDispatchDeliveryOrderWeightDTO {
  /**
   *
   * @type {number}
   * @memberof MoveDispatchDeliveryOrderWeightDTO
   */
  weight: number
  /**
   *
   * @type {string}
   * @memberof MoveDispatchDeliveryOrderWeightDTO
   */
  weightUnit: MoveDispatchDeliveryOrderWeightDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace MoveDispatchDeliveryOrderWeightDTO
 */
export namespace MoveDispatchDeliveryOrderWeightDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface MoveItemActivityDTO
 */
export interface MoveItemActivityDTO {
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDTO
   */
  bsqAmount?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDTO
   */
  bsqId?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveItemActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {MoveItemActivityLocationDTO}
   * @memberof MoveItemActivityDTO
   */
  destination?: MoveItemActivityLocationDTO
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDTO
   */
  number?: number
  /**
   *
   * @type {boolean}
   * @memberof MoveItemActivityDTO
   */
  shuttle?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDTO
   */
  stage?: MoveItemActivityDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof MoveItemActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof MoveItemActivityDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDTO
   */
  status?: MoveItemActivityDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof MoveItemActivityDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDTO
   */
  type?: MoveItemActivityDTO.TypeEnum
}

/**
 * @export
 * @namespace MoveItemActivityDTO
 */
export namespace MoveItemActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface MoveItemActivityDetailsDTO
 */
export interface MoveItemActivityDetailsDTO {
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDetailsDTO
   */
  bsqAmount?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDetailsDTO
   */
  bsqId?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDetailsDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof MoveItemActivityDetailsDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {MoveItemActivityLocationDetailsDTO}
   * @memberof MoveItemActivityDetailsDTO
   */
  destination?: MoveItemActivityLocationDetailsDTO
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDetailsDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityDetailsDTO
   */
  number?: number
  /**
   *
   * @type {boolean}
   * @memberof MoveItemActivityDetailsDTO
   */
  shuttle?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDetailsDTO
   */
  stage?: MoveItemActivityDetailsDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof MoveItemActivityDetailsDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof MoveItemActivityDetailsDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDetailsDTO
   */
  status?: MoveItemActivityDetailsDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof MoveItemActivityDetailsDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityDetailsDTO
   */
  type?: MoveItemActivityDetailsDTO.TypeEnum
}

/**
 * @export
 * @namespace MoveItemActivityDetailsDTO
 */
export namespace MoveItemActivityDetailsDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface MoveItemActivityLocationContactDTO
 */
export interface MoveItemActivityLocationContactDTO {
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationContactDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationContactDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationContactDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationContactDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationContactDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationContactDTO
   */
  phone?: string
}

/**
 *
 * @export
 * @interface MoveItemActivityLocationDTO
 */
export interface MoveItemActivityLocationDTO {
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  city?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDTO
   */
  latitude?: number
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDTO
   */
  longitude?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  stateCode?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDTO
   */
  type?: MoveItemActivityLocationDTO.TypeEnum
}

/**
 * @export
 * @namespace MoveItemActivityLocationDTO
 */
export namespace MoveItemActivityLocationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface MoveItemActivityLocationDetailsDTO
 */
export interface MoveItemActivityLocationDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  city?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  latitude?: number
  /**
   *
   * @type {number}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  longitude?: number
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  postalCode?: string
  /**
   *
   * @type {MoveItemActivityLocationContactDTO}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  primaryContact?: MoveItemActivityLocationContactDTO
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  shortName?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  stateCode?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof MoveItemActivityLocationDetailsDTO
   */
  type?: MoveItemActivityLocationDetailsDTO.TypeEnum
}

/**
 * @export
 * @namespace MoveItemActivityLocationDetailsDTO
 */
export namespace MoveItemActivityLocationDetailsDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TERMINAL = <any>'TERMINAL',
    WAREHOUSEWITHDOCK = <any>'WAREHOUSE_WITH_DOCK',
    WAREHOUSEWITHOUTDOCK = <any>'WAREHOUSE_WITHOUT_DOCK',
    STOREFRONT = <any>'STORE_FRONT',
    RESIDENTIAL = <any>'RESIDENTIAL',
    CONTAINERYARD = <any>'CONTAINER_YARD',
    DEPOT = <any>'DEPOT',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface NewOrderCount
 */
export interface NewOrderCount {
  /**
   *
   * @type {string}
   * @memberof NewOrderCount
   */
  dateName?: string
  /**
   *
   * @type {number}
   * @memberof NewOrderCount
   */
  ddoCount?: number
}

/**
 *
 * @export
 * @interface NonPlannedActivityCountDTO
 */
export interface NonPlannedActivityCountDTO {
  /**
   *
   * @type {number}
   * @memberof NonPlannedActivityCountDTO
   */
  deliveryStageCompletedNoReturned?: number
  /**
   *
   * @type {number}
   * @memberof NonPlannedActivityCountDTO
   */
  droppedAtDeliveryLocation?: number
  /**
   *
   * @type {number}
   * @memberof NonPlannedActivityCountDTO
   */
  droppedAtYard?: number
  /**
   *
   * @type {number}
   * @memberof NonPlannedActivityCountDTO
   */
  pickedUpNoDelivery?: number
}

/**
 *
 * @export
 * @interface OngoingActivityDTO
 */
export interface OngoingActivityDTO {
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  bobtailToDdoId?: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityDTO
   */
  bobtailToDdoNumber?: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  comboType: OngoingActivityDTO.ComboTypeEnum
  /**
   *
   * @type {number}
   * @memberof OngoingActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityDTO
   */
  deliveryPlannedAppointmentDateConfirmed?: boolean
  /**
   *
   * @type {LocationSimpleDTO}
   * @memberof OngoingActivityDTO
   */
  destination?: LocationSimpleDTO
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  destinationId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  driverId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  groupId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityDTO
   */
  isLive?: boolean
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  matchedDDOId?: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityDTO
   */
  matchedDDONumber?: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityDTO
   */
  number: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  phase: OngoingActivityDTO.PhaseEnum
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof OngoingActivityDTO
   */
  plannedDate?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  referenceId?: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityDTO
   */
  referenceNumber?: number
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityDTO
   */
  shuttle?: boolean
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  stage: OngoingActivityDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  status: OngoingActivityDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityDTO
   */
  type: OngoingActivityDTO.TypeEnum
}

/**
 * @export
 * @namespace OngoingActivityDTO
 */
export namespace OngoingActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ComboTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL',
    STREETTURN = <any>'STREET_TURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PhaseEnum {
    PLANNED = <any>'PLANNED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    SUBMITTED = <any>'SUBMITTED',
    APPROVED = <any>'APPROVED',
    REJECTED = <any>'REJECTED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL',
    STREETTURN = <any>'STREET_TURN'
  }
}

/**
 *
 * @export
 * @interface OngoingActivityGroupDTO
 */
export interface OngoingActivityGroupDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityGroupDTO
   */
  activityGroupDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  activityGroupDriver?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  activityGroupStage?: OngoingActivityGroupDTO.ActivityGroupStageEnum
  /**
   *
   * @type {number}
   * @memberof OngoingActivityGroupDTO
   */
  bsqAmount?: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityGroupDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  containerTypeCode?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  customerName?: string
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityGroupDTO
   */
  cutoff?: boolean
  /**
   *
   * @type {number}
   * @memberof OngoingActivityGroupDTO
   */
  daysOut?: number
  /**
   *
   * @type {OngoingActivityDTO}
   * @memberof OngoingActivityGroupDTO
   */
  deliveryActivity?: OngoingActivityDTO
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  deliveryOrderId: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityGroupDTO
   */
  deliveryOrderNumber: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {number}
   * @memberof OngoingActivityGroupDTO
   */
  dispatchDeliveryOrderNumber: number
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  dispatchDeliveryOrderStatus: OngoingActivityGroupDTO.DispatchDeliveryOrderStatusEnum
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  docNumber?: string
  /**
   *
   * @type {OngoingActivityDTO}
   * @memberof OngoingActivityGroupDTO
   */
  extraActivity?: OngoingActivityDTO
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityGroupDTO
   */
  generalCutoffDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  grouping: OngoingActivityGroupDTO.GroupingEnum
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityGroupDTO
   */
  hazmatIndicator?: boolean
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  highlight?: OngoingActivityGroupDTO.HighlightEnum
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  id?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityGroupDTO
   */
  lastFreeDateDemurrage?: DateISOString
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityGroupDTO
   */
  lfd?: boolean
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  loadType: OngoingActivityGroupDTO.LoadTypeEnum
  /**
   *
   * @type {boolean}
   * @memberof OngoingActivityGroupDTO
   */
  overweightIndicator?: boolean
  /**
   *
   * @type {OngoingActivityDTO}
   * @memberof OngoingActivityGroupDTO
   */
  pickupActivity?: OngoingActivityDTO
  /**
   *
   * @type {OngoingActivityDTO}
   * @memberof OngoingActivityGroupDTO
   */
  returnActivity?: OngoingActivityDTO
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  steamShipLineName?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {string}
   * @memberof OngoingActivityGroupDTO
   */
  type: OngoingActivityGroupDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof OngoingActivityGroupDTO
   */
  updatedAt?: DateISOString
}

/**
 * @export
 * @namespace OngoingActivityGroupDTO
 */
export namespace OngoingActivityGroupDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ActivityGroupStageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DispatchDeliveryOrderStatusEnum {
    NEW = <any>'NEW',
    NEWACCEPTED = <any>'NEW_ACCEPTED',
    NEWREJECTED = <any>'NEW_REJECTED',
    HOLDLOADED = <any>'HOLD_LOADED',
    HOLDARRIVED = <any>'HOLD_ARRIVED',
    HOLDUNLOADED = <any>'HOLD_UNLOADED',
    NOTONFILE = <any>'NOT_ON_FILE',
    RELEASEDLOADED = <any>'RELEASED_LOADED',
    RELEASEDARRIVED = <any>'RELEASED_ARRIVED',
    RELEASEDUNLOADED = <any>'RELEASED_UNLOADED',
    ONFILE = <any>'ON_FILE',
    READYFORDISPATCH = <any>'READY_FOR_DISPATCH',
    DISPATCHEDPLANNED = <any>'DISPATCHED_PLANNED',
    DISPATCHEDASSIGNED = <any>'DISPATCHED_ASSIGNED',
    DISPATCHEDCONFIRMED = <any>'DISPATCHED_CONFIRMED',
    INPROCESSPICKUP = <any>'IN_PROCESS_PICKUP',
    COMPLETEDPICKUP = <any>'COMPLETED_PICKUP',
    INPROCESSDELIVERY = <any>'IN_PROCESS_DELIVERY',
    COMPLETEDDELIVERY = <any>'COMPLETED_DELIVERY',
    INPROCESSRETURN = <any>'IN_PROCESS_RETURN',
    COMPLETED = <any>'COMPLETED',
    CANCELLED = <any>'CANCELLED',
    ARCHIVED = <any>'ARCHIVED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum GroupingEnum {
    STAGE = <any>'DATE_STAGE',
    STAGENOW = <any>'DATE_STAGE_NOW',
    DRIVER = <any>'DATE_DRIVER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum HighlightEnum {
    NONE = <any>'NONE',
    ROW = <any>'ROW',
    CANCELEDROW = <any>'CANCELED_ROW',
    COMPLETEDROW = <any>'COMPLETED_ROW',
    PENDINGPICKUP = <any>'PENDING_PICKUP',
    ASSIGNEDPICKUP = <any>'ASSIGNED_PICKUP',
    ACTIVEPICKUP = <any>'ACTIVE_PICKUP',
    PENDINGDELIVERY = <any>'PENDING_DELIVERY',
    ASSIGNEDDELIVERY = <any>'ASSIGNED_DELIVERY',
    ACTIVEDELIVERY = <any>'ACTIVE_DELIVERY',
    PENDINGRETURN = <any>'PENDING_RETURN',
    ASSIGNEDRETURN = <any>'ASSIGNED_RETURN',
    ACTIVERETURN = <any>'ACTIVE_RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
}

/**
 *
 * @export
 * @interface OrganizationDTO
 */
export interface OrganizationDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  annualFederalHutDueDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  annualReportDueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof OrganizationDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  dot?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  dotIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  dotStatus?: OrganizationDTO.DotStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  feinNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  hutDueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  id?: string
  /**
   *
   * @type {Array<OrganizationInsuranceDTO>}
   * @memberof OrganizationDTO
   */
  insurances?: Array<OrganizationInsuranceDTO>
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  motorCarrierNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  motorCarrierNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  motorCarrierNumberStatus?: OrganizationDTO.MotorCarrierNumberStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  organizationType: OrganizationDTO.OrganizationTypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  quarterlyFuelTaxDueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  scac?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  scacExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  scacIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  scacStatus?: OrganizationDTO.ScacStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  stateEntityNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  stateEntityNumberExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  stateEntityNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  stateEntityNumberStatus?: OrganizationDTO.StateEntityNumberStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  uiiaAgreement?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace OrganizationDTO
 */
export namespace OrganizationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DotStatusEnum {
    ACTIVE = <any>'ACTIVE',
    SUSPENDED = <any>'SUSPENDED',
    REVOKED = <any>'REVOKED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MotorCarrierNumberStatusEnum {
    ACTIVE = <any>'ACTIVE',
    SUSPENDED = <any>'SUSPENDED',
    REVOKED = <any>'REVOKED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OrganizationTypeEnum {
    COMMONCARRIER = <any>'COMMON_CARRIER',
    BROKER = <any>'BROKER',
    PRIVATECARRIER = <any>'PRIVATE_CARRIER',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum ScacStatusEnum {
    INFORCE = <any>'INFORCE',
    EXPIRED = <any>'EXPIRED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StateEntityNumberStatusEnum {
    INFORCE = <any>'INFORCE',
    EXPIRED = <any>'EXPIRED'
  }
}

/**
 *
 * @export
 * @interface OrganizationInsuranceDTO
 */
export interface OrganizationInsuranceDTO {
  /**
   *
   * @type {number}
   * @memberof OrganizationInsuranceDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationInsuranceDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  description?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationInsuranceDTO
   */
  effectiveDate: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationInsuranceDTO
   */
  expirationDate: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  insuranceType: OrganizationInsuranceDTO.InsuranceTypeEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  policyNumber: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationInsuranceDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationInsuranceDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace OrganizationInsuranceDTO
 */
export namespace OrganizationInsuranceDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InsuranceTypeEnum {
    COMMERCIAL = <any>'COMMERCIAL',
    LIABILITY = <any>'LIABILITY',
    CARGO = <any>'CARGO',
    AUTO = <any>'AUTO',
    CUSTOM = <any>'CUSTOM'
  }
}

/**
 *
 * @export
 * @interface OrganizationViewDTO
 */
export interface OrganizationViewDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  annualFederalHutDueDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  annualReportDueDate?: DateISOString
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof OrganizationViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof OrganizationViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  dot?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  dotIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  dotStatus?: OrganizationViewDTO.DotStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  feinNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  hutDueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  id?: string
  /**
   *
   * @type {Array<OrganizationInsuranceDTO>}
   * @memberof OrganizationViewDTO
   */
  insurances?: Array<OrganizationInsuranceDTO>
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  motorCarrierNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  motorCarrierNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  motorCarrierNumberStatus?: OrganizationViewDTO.MotorCarrierNumberStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  organizationType: OrganizationViewDTO.OrganizationTypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  quarterlyFuelTaxDueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  scac?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  scacExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  scacIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  scacStatus?: OrganizationViewDTO.ScacStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  stateEntityNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  stateEntityNumberExpDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  stateEntityNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  stateEntityNumberStatus?: OrganizationViewDTO.StateEntityNumberStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  uiiaAgreement?: string
  /**
   *
   * @type {DateISOString}
   * @memberof OrganizationViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof OrganizationViewDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace OrganizationViewDTO
 */
export namespace OrganizationViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DotStatusEnum {
    ACTIVE = <any>'ACTIVE',
    SUSPENDED = <any>'SUSPENDED',
    REVOKED = <any>'REVOKED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MotorCarrierNumberStatusEnum {
    ACTIVE = <any>'ACTIVE',
    SUSPENDED = <any>'SUSPENDED',
    REVOKED = <any>'REVOKED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OrganizationTypeEnum {
    COMMONCARRIER = <any>'COMMON_CARRIER',
    BROKER = <any>'BROKER',
    PRIVATECARRIER = <any>'PRIVATE_CARRIER',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum ScacStatusEnum {
    INFORCE = <any>'INFORCE',
    EXPIRED = <any>'EXPIRED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StateEntityNumberStatusEnum {
    INFORCE = <any>'INFORCE',
    EXPIRED = <any>'EXPIRED'
  }
}

/**
 *
 * @export
 * @interface PhaseKeyPerformanceIndicator
 */
export interface PhaseKeyPerformanceIndicator {
  /**
   *
   * @type {number}
   * @memberof PhaseKeyPerformanceIndicator
   */
  completed?: number
  /**
   *
   * @type {number}
   * @memberof PhaseKeyPerformanceIndicator
   */
  inProcess?: number
  /**
   *
   * @type {number}
   * @memberof PhaseKeyPerformanceIndicator
   */
  unsuccessful?: number
}

/**
 *
 * @export
 * @interface PowerUnitDTO
 */
export interface PowerUnitDTO {
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitDTO
   */
  active?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitDTO
   */
  availability?: boolean
  /**
   *
   * @type {number}
   * @memberof PowerUnitDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  createdBy?: string
  /**
   *
   * @type {DotSafetyInspectionDTO}
   * @memberof PowerUnitDTO
   */
  dotSafetyInspection?: DotSafetyInspectionDTO
  /**
   *
   * @type {Array<string>}
   * @memberof PowerUnitDTO
   */
  driverIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  inactiveReason?: PowerUnitDTO.InactiveReasonEnum
  /**
   *
   * @type {InsuranceDTO}
   * @memberof PowerUnitDTO
   */
  insurance?: InsuranceDTO
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  licensePlate?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  maintenanceInspectionFrequency?: PowerUnitDTO.MaintenanceInspectionFrequencyEnum
  /**
   *
   * @type {Array<MaintenanceInspectionDTO>}
   * @memberof PowerUnitDTO
   */
  maintenanceInspections?: Array<MaintenanceInspectionDTO>
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  ownership: PowerUnitDTO.OwnershipEnum
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitDTO
   */
  portPassRfid?: boolean
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  powerUnitSubType?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  powerUnitType: PowerUnitDTO.PowerUnitTypeEnum
  /**
   *
   * @type {PowerUnitRegistrationDTO}
   * @memberof PowerUnitDTO
   */
  registration: PowerUnitRegistrationDTO
  /**
   *
   * @type {Array<RepairDTO>}
   * @memberof PowerUnitDTO
   */
  repairs?: Array<RepairDTO>
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  rfid?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  specs?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  truckType: PowerUnitDTO.TruckTypeEnum
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  unavailabilityReason?: PowerUnitDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  vin: string
  /**
   *
   * @type {number}
   * @memberof PowerUnitDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof PowerUnitDTO
   */
  weightUnit?: PowerUnitDTO.WeightUnitEnum
  /**
   *
   * @type {number}
   * @memberof PowerUnitDTO
   */
  year?: number
}

/**
 * @export
 * @namespace PowerUnitDTO
 */
export namespace PowerUnitDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    EXPIREDREGISTRATION = <any>'EXPIRED_REGISTRATION',
    EXPIREDSAFETYINSPECTION = <any>'EXPIRED_SAFETY_INSPECTION',
    EXPIREDMAINTENANCEINSPECTION = <any>'EXPIRED_MAINTENANCE_INSPECTION',
    MAINTENANCE = <any>'MAINTENANCE',
    OTHER = <any>'OTHER',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MaintenanceInspectionFrequencyEnum {
    MONTHLY = <any>'MONTHLY',
    QUARTERLY = <any>'QUARTERLY',
    SEMIANNUALLY = <any>'SEMI_ANNUALLY',
    ANNUALLY = <any>'ANNUALLY',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    OWNED = <any>'OWNED',
    LEASED = <any>'LEASED',
    RENTED = <any>'RENTED',
    OWNEROPERATED = <any>'OWNER_OPERATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PowerUnitTypeEnum {
    TRACTOR = <any>'TRACTOR',
    BOXTRUCK = <any>'BOX_TRUCK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TruckTypeEnum {
    SLEEPER = <any>'SLEEPER',
    DAYCAP = <any>'DAY_CAP'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface PowerUnitRegistrationDTO
 */
export interface PowerUnitRegistrationDTO {
  /**
   *
   * @type {DocumentDTO}
   * @memberof PowerUnitRegistrationDTO
   */
  document?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof PowerUnitRegistrationDTO
   */
  documentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitRegistrationDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitRegistrationDTO
   */
  registeredDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof PowerUnitRegistrationDTO
   */
  restrictions: PowerUnitRegistrationDTO.RestrictionsEnum
  /**
   *
   * @type {string}
   * @memberof PowerUnitRegistrationDTO
   */
  stateId?: string
  /**
   *
   * @type {number}
   * @memberof PowerUnitRegistrationDTO
   */
  weight: number
  /**
   *
   * @type {string}
   * @memberof PowerUnitRegistrationDTO
   */
  weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace PowerUnitRegistrationDTO
 */
export namespace PowerUnitRegistrationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum RestrictionsEnum {
    NONE = <any>'NONE',
    HIGHWAY = <any>'HIGHWAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface PowerUnitSearchDTO
 */
export interface PowerUnitSearchDTO {
  /**
   *
   * @type {number}
   * @memberof PowerUnitSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof PowerUnitSearchDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitSearchDTO
   */
  licensePlate?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitSearchDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitSearchDTO
   */
  model?: string
  /**
   *
   * @type {number}
   * @memberof PowerUnitSearchDTO
   */
  year?: number
}

/**
 *
 * @export
 * @interface PowerUnitViewDTO
 */
export interface PowerUnitViewDTO {
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitViewDTO
   */
  active?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitViewDTO
   */
  availability?: boolean
  /**
   *
   * @type {number}
   * @memberof PowerUnitViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {DotSafetyInspectionDTO}
   * @memberof PowerUnitViewDTO
   */
  dotSafetyInspection?: DotSafetyInspectionDTO
  /**
   *
   * @type {Array<string>}
   * @memberof PowerUnitViewDTO
   */
  driverIds?: Array<string>
  /**
   *
   * @type {Array<DriverNameDTO>}
   * @memberof PowerUnitViewDTO
   */
  drivers?: Array<DriverNameDTO>
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  inactiveReason?: PowerUnitViewDTO.InactiveReasonEnum
  /**
   *
   * @type {InsuranceDTO}
   * @memberof PowerUnitViewDTO
   */
  insurance?: InsuranceDTO
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  licensePlate?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  maintenanceInspectionFrequency?: PowerUnitViewDTO.MaintenanceInspectionFrequencyEnum
  /**
   *
   * @type {Array<MaintenanceInspectionDTO>}
   * @memberof PowerUnitViewDTO
   */
  maintenanceInspections?: Array<MaintenanceInspectionDTO>
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  ownership: PowerUnitViewDTO.OwnershipEnum
  /**
   *
   * @type {boolean}
   * @memberof PowerUnitViewDTO
   */
  portPassRfid?: boolean
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  powerUnitSubType?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  powerUnitType: PowerUnitViewDTO.PowerUnitTypeEnum
  /**
   *
   * @type {PowerUnitRegistrationDTO}
   * @memberof PowerUnitViewDTO
   */
  registration: PowerUnitRegistrationDTO
  /**
   *
   * @type {Array<RepairDTO>}
   * @memberof PowerUnitViewDTO
   */
  repairs?: Array<RepairDTO>
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  rfid?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  specs?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  truckType: PowerUnitViewDTO.TruckTypeEnum
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  unavailabilityReason?: PowerUnitViewDTO.UnavailabilityReasonEnum
  /**
   *
   * @type {DateISOString}
   * @memberof PowerUnitViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  vin: string
  /**
   *
   * @type {number}
   * @memberof PowerUnitViewDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof PowerUnitViewDTO
   */
  weightUnit?: PowerUnitViewDTO.WeightUnitEnum
  /**
   *
   * @type {number}
   * @memberof PowerUnitViewDTO
   */
  year?: number
}

/**
 * @export
 * @namespace PowerUnitViewDTO
 */
export namespace PowerUnitViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum InactiveReasonEnum {
    EXPIREDREGISTRATION = <any>'EXPIRED_REGISTRATION',
    EXPIREDSAFETYINSPECTION = <any>'EXPIRED_SAFETY_INSPECTION',
    EXPIREDMAINTENANCEINSPECTION = <any>'EXPIRED_MAINTENANCE_INSPECTION',
    MAINTENANCE = <any>'MAINTENANCE',
    OTHER = <any>'OTHER',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum MaintenanceInspectionFrequencyEnum {
    MONTHLY = <any>'MONTHLY',
    QUARTERLY = <any>'QUARTERLY',
    SEMIANNUALLY = <any>'SEMI_ANNUALLY',
    ANNUALLY = <any>'ANNUALLY',
    OTHER = <any>'OTHER'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum OwnershipEnum {
    OWNED = <any>'OWNED',
    LEASED = <any>'LEASED',
    RENTED = <any>'RENTED',
    OWNEROPERATED = <any>'OWNER_OPERATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PowerUnitTypeEnum {
    TRACTOR = <any>'TRACTOR',
    BOXTRUCK = <any>'BOX_TRUCK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TruckTypeEnum {
    SLEEPER = <any>'SLEEPER',
    DAYCAP = <any>'DAY_CAP'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailabilityReasonEnum {
    ASSIGNED = <any>'ASSIGNED',
    INUSE = <any>'IN_USE',
    INACTIVE = <any>'INACTIVE'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface ProgressBarState
 */
export interface ProgressBarState {
  /**
   *
   * @type {string}
   * @memberof ProgressBarState
   */
  stage?: ProgressBarState.StageEnum
  /**
   *
   * @type {string}
   * @memberof ProgressBarState
   */
  value?: ProgressBarState.ValueEnum
}

/**
 * @export
 * @namespace ProgressBarState
 */
export namespace ProgressBarState {
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum ValueEnum {
    EMPTY = <any>'EMPTY',
    DASHED = <any>'DASHED',
    HALFFULL = <any>'HALF_FULL',
    FULL = <any>'FULL'
  }
}

/**
 *
 * @export
 * @interface RegistrationDTO
 */
export interface RegistrationDTO {
  /**
   *
   * @type {DocumentDTO}
   * @memberof RegistrationDTO
   */
  document?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof RegistrationDTO
   */
  documentFile?: DocumentFileDTO
  /**
   *
   * @type {DateISOString}
   * @memberof RegistrationDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof RegistrationDTO
   */
  registeredDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof RegistrationDTO
   */
  stateId?: string
}

/**
 *
 * @export
 * @interface RepairDTO
 */
export interface RepairDTO {
  /**
   *
   * @type {number}
   * @memberof RepairDTO
   */
  billActual?: number
  /**
   *
   * @type {number}
   * @memberof RepairDTO
   */
  completeness?: number
  /**
   *
   * @type {number}
   * @memberof RepairDTO
   */
  costEstimate?: number
  /**
   *
   * @type {DateISOString}
   * @memberof RepairDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof RepairDTO
   */
  equipmentHours?: number
  /**
   *
   * @type {DateISOString}
   * @memberof RepairDTO
   */
  finishDate: DateISOString
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof RepairDTO
   */
  powerUnitMileage?: number
  /**
   *
   * @type {DocumentDTO}
   * @memberof RepairDTO
   */
  receiptDocument?: DocumentDTO
  /**
   *
   * @type {DocumentFileDTO}
   * @memberof RepairDTO
   */
  receiptDocumentFile?: DocumentFileDTO
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  repairType: RepairDTO.RepairTypeEnum
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  shopName?: string
  /**
   *
   * @type {DateISOString}
   * @memberof RepairDTO
   */
  startDate: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof RepairDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof RepairDTO
   */
  updatedBy?: string
}

/**
 * @export
 * @namespace RepairDTO
 */
export namespace RepairDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum RepairTypeEnum {
    MAINTENANCEOILFILTERS = <any>'MAINTENANCE_OIL_FILTERS',
    MAINTENANCETIRES = <any>'MAINTENANCE_TIRES',
    MAINTENANCEGREASE = <any>'MAINTENANCE_GREASE',
    MAINTENANCEOTHER = <any>'MAINTENANCE_OTHER',
    BREAKDOWN = <any>'BREAKDOWN',
    PREVENTIVEMAINTENANCE = <any>'PREVENTIVE_MAINTENANCE'
  }
}

/**
 *
 * @export
 * @interface SellSideQuoteDTO
 */
export interface SellSideQuoteDTO {
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteDTO
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof SellSideQuoteDTO
   */
  completionDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof SellSideQuoteDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  customerQuoteId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof SellSideQuoteDTO
   */
  date: DateISOString
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  deliveryVendorId?: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  id: string
  /**
   *
   * @type {Array<SellSideQuoteMiscSurchargeDTO>}
   * @memberof SellSideQuoteDTO
   */
  miscSurcharges: Array<SellSideQuoteMiscSurchargeDTO>
  /**
   *
   * @type {Array<SellSideQuoteSurchargeDTO>}
   * @memberof SellSideQuoteDTO
   */
  surcharges: Array<SellSideQuoteSurchargeDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof SellSideQuoteDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface SellSideQuoteMiscSurchargeDTO
 */
export interface SellSideQuoteMiscSurchargeDTO {
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  activityId?: string
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  calculationType: SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  quantity?: number
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteMiscSurchargeDTO
   */
  type: SellSideQuoteMiscSurchargeDTO.TypeEnum
}

/**
 * @export
 * @namespace SellSideQuoteMiscSurchargeDTO
 */
export namespace SellSideQuoteMiscSurchargeDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GENERAL = <any>'GENERAL',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface SellSideQuoteSurchargeDTO
 */
export interface SellSideQuoteSurchargeDTO {
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  activityId?: string
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteSurchargeDTO
   */
  amount: number
  /**
   *
   * @type {boolean}
   * @memberof SellSideQuoteSurchargeDTO
   */
  automatic: boolean
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  calculationType: SellSideQuoteSurchargeDTO.CalculationTypeEnum
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteSurchargeDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof SellSideQuoteSurchargeDTO
   */
  date?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof SellSideQuoteSurchargeDTO
   */
  quantity?: number
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  rateId: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  rules?: string
  /**
   *
   * @type {string}
   * @memberof SellSideQuoteSurchargeDTO
   */
  type: SellSideQuoteSurchargeDTO.TypeEnum
}

/**
 * @export
 * @namespace SellSideQuoteSurchargeDTO
 */
export namespace SellSideQuoteSurchargeDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    BASE = <any>'BASE',
    ALLIN = <any>'ALL_IN',
    FUEL = <any>'FUEL',
    CHASSIS = <any>'CHASSIS',
    DROPANDPICKUP = <any>'DROP_AND_PICKUP',
    TOLLS = <any>'TOLLS',
    DETENTION = <any>'DETENTION',
    PREPULL = <any>'PREPULL',
    REDELIVERY = <any>'REDELIVERY',
    STORAGE = <any>'STORAGE',
    OVERWEIGHT = <any>'OVERWEIGHT',
    HAZMAT = <any>'HAZMAT',
    REEFER = <any>'REEFER',
    LAYOVER = <any>'LAY_OVER',
    TANKER = <any>'TANKER',
    DRYRUN = <any>'DRY_RUN',
    PERDIEM = <any>'PER_DIEM',
    DEMURRAGE = <any>'DEMURRAGE',
    SCALE = <any>'SCALE',
    SHUTTLE = <any>'SHUTTLE',
    SHUTTLEHAZ = <any>'SHUTTLE_HAZ',
    SHUTTLEOW = <any>'SHUTTLE_OW',
    SHUTTLEREEFER = <any>'SHUTTLE_REEFER',
    DEDUCTION = <any>'DEDUCTION',
    DEDUCTIONREEFER = <any>'DEDUCTION_REEFER',
    CONGESTION = <any>'CONGESTION',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface SsqRateDTO
 */
export interface SsqRateDTO {
  /**
   *
   * @type {number}
   * @memberof SsqRateDTO
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  calculationType: SsqRateDTO.CalculationTypeEnum
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  containerTypeId?: string
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  customerId?: string
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  deliveryOrderType?: SsqRateDTO.DeliveryOrderTypeEnum
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  deliveryPostalCode?: string
  /**
   *
   * @type {DateISOString}
   * @memberof SsqRateDTO
   */
  effectiveDate: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof SsqRateDTO
   */
  expirationDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  loadType?: SsqRateDTO.LoadTypeEnum
  /**
   *
   * @type {number}
   * @memberof SsqRateDTO
   */
  number?: number
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  partyId?: string
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  pickupLocationId?: string
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  returnLocationId?: string
  /**
   *
   * @type {Array<string>}
   * @memberof SsqRateDTO
   */
  ruleIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  status?: SsqRateDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof SsqRateDTO
   */
  type: SsqRateDTO.TypeEnum
}

/**
 * @export
 * @namespace SsqRateDTO
 */
export namespace SsqRateDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum CalculationTypeEnum {
    FIXEDAMOUNT = <any>'FIXED_AMOUNT',
    PERCENTAGE = <any>'PERCENTAGE',
    PERMILE = <any>'PER_MILE',
    PERHOUR = <any>'PER_HOUR',
    PERDAY = <any>'PER_DAY'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DeliveryOrderTypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LoadTypeEnum {
    LIVELOAD = <any>'LIVE_LOAD',
    DROPANDPICK = <any>'DROP_AND_PICK'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    EXPIRED = <any>'EXPIRED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    BASE = <any>'BASE',
    ALLIN = <any>'ALL_IN',
    FUEL = <any>'FUEL',
    CHASSIS = <any>'CHASSIS',
    DROPANDPICKUP = <any>'DROP_AND_PICKUP',
    TOLLS = <any>'TOLLS',
    DETENTION = <any>'DETENTION',
    PREPULL = <any>'PREPULL',
    REDELIVERY = <any>'REDELIVERY',
    STORAGE = <any>'STORAGE',
    OVERWEIGHT = <any>'OVERWEIGHT',
    HAZMAT = <any>'HAZMAT',
    REEFER = <any>'REEFER',
    LAYOVER = <any>'LAY_OVER',
    TANKER = <any>'TANKER',
    DRYRUN = <any>'DRY_RUN',
    PERDIEM = <any>'PER_DIEM',
    DEMURRAGE = <any>'DEMURRAGE',
    SCALE = <any>'SCALE',
    SHUTTLE = <any>'SHUTTLE',
    SHUTTLEHAZ = <any>'SHUTTLE_HAZ',
    SHUTTLEOW = <any>'SHUTTLE_OW',
    SHUTTLEREEFER = <any>'SHUTTLE_REEFER',
    DEDUCTION = <any>'DEDUCTION',
    DEDUCTIONREEFER = <any>'DEDUCTION_REEFER',
    CONGESTION = <any>'CONGESTION',
    BOBTAIL = <any>'BOBTAIL'
  }
}

/**
 *
 * @export
 * @interface StateDTO
 */
export interface StateDTO {
  /**
   *
   * @type {string}
   * @memberof StateDTO
   */
  code: string
  /**
   *
   * @type {number}
   * @memberof StateDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof StateDTO
   */
  countryCode: string
  /**
   *
   * @type {string}
   * @memberof StateDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof StateDTO
   */
  name: string
}

/**
 *
 * @export
 * @interface SteamShipLineDTO
 */
export interface SteamShipLineDTO {
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof SteamShipLineDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<FeeScheduleDTO>}
   * @memberof SteamShipLineDTO
   */
  containerDetentionPerDiemFeeSchedule?: Array<FeeScheduleDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof SteamShipLineDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  externalId?: string
  /**
   *
   * @type {number}
   * @memberof SteamShipLineDTO
   */
  freeDetentionAllowance?: number
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  scac: string
  /**
   *
   * @type {DateISOString}
   * @memberof SteamShipLineDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SteamShipLineDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface SteamShipLineNameDTO
 */
export interface SteamShipLineNameDTO {
  /**
   *
   * @type {number}
   * @memberof SteamShipLineNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof SteamShipLineNameDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineNameDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface SteamShipLineViewDTO
 */
export interface SteamShipLineViewDTO {
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof SteamShipLineViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof SteamShipLineViewDTO
   */
  completeness?: number
  /**
   *
   * @type {Array<FeeScheduleDTO>}
   * @memberof SteamShipLineViewDTO
   */
  containerDetentionPerDiemFeeSchedule?: Array<FeeScheduleDTO>
  /**
   *
   * @type {DateISOString}
   * @memberof SteamShipLineViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  externalId?: string
  /**
   *
   * @type {number}
   * @memberof SteamShipLineViewDTO
   */
  freeDetentionAllowance?: number
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  scac: string
  /**
   *
   * @type {DateISOString}
   * @memberof SteamShipLineViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SteamShipLineViewDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface SubClientBusinessPartnerViewDTO
 */
export interface SubClientBusinessPartnerViewDTO {
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  name?: string
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientBusinessPartnerViewDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface SubClientDTO
 */
export interface SubClientDTO {
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof SubClientDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  name?: string
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface SubClientSearchDTO
 */
export interface SubClientSearchDTO {
  /**
   *
   * @type {number}
   * @memberof SubClientSearchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof SubClientSearchDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SubClientSearchDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface SubClientViewDTO
 */
export interface SubClientViewDTO {
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof SubClientViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  businessPartnerId: string
  /**
   *
   * @type {number}
   * @memberof SubClientViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {CustomerBusinessPartnerViewDTO}
   * @memberof SubClientViewDTO
   */
  customer?: CustomerBusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  name?: string
  /**
   *
   * @type {DateISOString}
   * @memberof SubClientViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof SubClientViewDTO
   */
  updatedBy?: string
}

/**
 *
 * @export
 * @interface TerminalIntegrationDTO
 */
export interface TerminalIntegrationDTO {
  /**
   *
   * @type {boolean}
   * @memberof TerminalIntegrationDTO
   */
  active: boolean
  /**
   *
   * @type {string}
   * @memberof TerminalIntegrationDTO
   */
  code: string
  /**
   *
   * @type {number}
   * @memberof TerminalIntegrationDTO
   */
  completeness?: number
  /**
   *
   * @type {boolean}
   * @memberof TerminalIntegrationDTO
   */
  fault: boolean
  /**
   *
   * @type {string}
   * @memberof TerminalIntegrationDTO
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof TerminalIntegrationDTO
   */
  syncInterval: number
  /**
   *
   * @type {string}
   * @memberof TerminalIntegrationDTO
   */
  webSite: string
}

/**
 *
 * @export
 * @interface TerminalMismatchDTO
 */
export interface TerminalMismatchDTO {
  /**
   *
   * @type {number}
   * @memberof TerminalMismatchDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  fieldName: string
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  systemValue: string
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  systemValueDescription?: string
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  terminalValue: string
  /**
   *
   * @type {string}
   * @memberof TerminalMismatchDTO
   */
  terminalValueDescription?: string
}

/**
 *
 * @export
 * @interface TerminalNoteDTO
 */
export interface TerminalNoteDTO {
  /**
   *
   * @type {number}
   * @memberof TerminalNoteDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof TerminalNoteDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TerminalNoteDTO
   */
  notes?: string
}

/**
 *
 * @export
 * @interface TransportationActivityBobtailFromDTO
 */
export interface TransportationActivityBobtailFromDTO {
  /**
   *
   * @type {string}
   * @memberof TransportationActivityBobtailFromDTO
   */
  activityId?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityBobtailFromDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityBobtailFromDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityBobtailFromDTO
   */
  dispatchDeliveryOrderNumber?: number
}

/**
 *
 * @export
 * @interface TransportationActivityBobtailToDTO
 */
export interface TransportationActivityBobtailToDTO {
  /**
   *
   * @type {string}
   * @memberof TransportationActivityBobtailToDTO
   */
  activityId?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityBobtailToDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityBobtailToDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityBobtailToDTO
   */
  dispatchDeliveryOrderNumber?: number
}

/**
 *
 * @export
 * @interface TransportationActivityDTO
 */
export interface TransportationActivityDTO {
  /**
   *
   * @type {number}
   * @memberof TransportationActivityDTO
   */
  activityNumber?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  comboType: TransportationActivityDTO.ComboTypeEnum
  /**
   *
   * @type {number}
   * @memberof TransportationActivityDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  destinationId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  groupId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityDTO
   */
  number: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  originId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  stage: TransportationActivityDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof TransportationActivityDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  status: TransportationActivityDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof TransportationActivityDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  type: TransportationActivityDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace TransportationActivityDTO
 */
export namespace TransportationActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ComboTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface TransportationActivityGridItemDTO
 */
export interface TransportationActivityGridItemDTO {
  /**
   *
   * @type {number}
   * @memberof TransportationActivityGridItemDTO
   */
  activityNumber?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  buySideQuoteId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  comboType: TransportationActivityGridItemDTO.ComboTypeEnum
  /**
   *
   * @type {number}
   * @memberof TransportationActivityGridItemDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityGridItemDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityGridItemDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  createdBy?: string
  /**
   *
   * @type {ActivityLocationNameDTO}
   * @memberof TransportationActivityGridItemDTO
   */
  destination?: ActivityLocationNameDTO
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  destinationId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  groupId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityGridItemDTO
   */
  number: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  originId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  stage: TransportationActivityGridItemDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityGridItemDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof TransportationActivityGridItemDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  status: TransportationActivityGridItemDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof TransportationActivityGridItemDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  type: TransportationActivityGridItemDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityGridItemDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityGridItemDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace TransportationActivityGridItemDTO
 */
export namespace TransportationActivityGridItemDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ComboTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface TransportationActivityViewDTO
 */
export interface TransportationActivityViewDTO {
  /**
   *
   * @type {number}
   * @memberof TransportationActivityViewDTO
   */
  activityNumber?: number
  /**
   *
   * @type {TransportationActivityBobtailFromDTO}
   * @memberof TransportationActivityViewDTO
   */
  bobtailFrom?: TransportationActivityBobtailFromDTO
  /**
   *
   * @type {TransportationActivityBobtailToDTO}
   * @memberof TransportationActivityViewDTO
   */
  bobtailTo?: TransportationActivityBobtailToDTO
  buySideQuoteAmount?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  buySideQuoteId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  comboType: TransportationActivityViewDTO.ComboTypeEnum
  /**
   *
   * @type {number}
   * @memberof TransportationActivityViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityViewDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {LocationNameDTO}
   * @memberof TransportationActivityViewDTO
   */
  destination?: LocationNameDTO
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  destinationId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  equipmentId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  groupId: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  notes?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityViewDTO
   */
  number: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  originId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  powerUnitId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  referenceId?: string
  /**
   *
   * @type {number}
   * @memberof TransportationActivityViewDTO
   */
  referenceNumber?: number
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  stage: TransportationActivityViewDTO.StageEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityViewDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {LocalDateTimeRangeDTO}
   * @memberof TransportationActivityViewDTO
   */
  startPlannedDateTimeRange?: LocalDateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  status: TransportationActivityViewDTO.StatusEnum
  /**
   *
   * @type {boolean}
   * @memberof TransportationActivityViewDTO
   */
  template?: boolean
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  troubleTicketId?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  type: TransportationActivityViewDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TransportationActivityViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof TransportationActivityViewDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace TransportationActivityViewDTO
 */
export namespace TransportationActivityViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ComboTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StageEnum {
    PICKUP = <any>'PICKUP',
    DELIVERY = <any>'DELIVERY',
    RETURN = <any>'RETURN'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface TroubleTicketDTO
 */
export interface TroubleTicketDTO {
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  alertId?: string
  /**
   *
   * @type {number}
   * @memberof TroubleTicketDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof TroubleTicketDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  createdBy?: string
  /**
   *
   * @type {DateISOString}
   * @memberof TroubleTicketDTO
   */
  date?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  dispatchDeliveryOrderId: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  externalId?: string
  /**
   *
   * @type {Array<DocumentFileDTO>}
   * @memberof TroubleTicketDTO
   */
  files?: Array<DocumentFileDTO>
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  id?: string
  /**
   *
   * @type {DocumentMetadataDTO}
   * @memberof TroubleTicketDTO
   */
  metadata?: DocumentMetadataDTO
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  notes?: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  status: TroubleTicketDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  transportationActivityId: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  type: TroubleTicketDTO.TypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof TroubleTicketDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TroubleTicketDTO
   */
  updatedBy?: string
  /**
   *
   * @type {DocumentValidationDTO}
   * @memberof TroubleTicketDTO
   */
  validation?: DocumentValidationDTO
}

/**
 * @export
 * @namespace TroubleTicketDTO
 */
export namespace TroubleTicketDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    PROCEED = <any>'PROCEED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DEFAULT = <any>'DEFAULT',
    TIR = <any>'TIR',
    POD = <any>'POD'
  }
}

/**
 *
 * @export
 * @interface TroubleTicketUpdateDTO
 */
export interface TroubleTicketUpdateDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof TroubleTicketUpdateDTO
   */
  activityCompletionDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof TroubleTicketUpdateDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TroubleTicketUpdateDTO
   */
  notes?: string
}

/**
 *
 * @export
 * @interface UISettingsDTO
 */
export interface UISettingsDTO {
  /**
   *
   * @type {number}
   * @memberof UISettingsDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof UISettingsDTO
   */
  settings: string
  /**
   *
   * @type {string}
   * @memberof UISettingsDTO
   */
  userId: string
}

/**
 *
 * @export
 * @interface VendorDTO
 */
export interface VendorDTO {
  /**
   *
   * @type {boolean}
   * @memberof VendorDTO
   */
  active?: boolean
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof VendorDTO
   */
  availability?: boolean
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof VendorDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  dot?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  dotIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  motorCarrierNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  motorCarrierNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  status: VendorDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  taxId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof VendorDTO
   */
  vendorType?: VendorDTO.VendorTypeEnum
}

/**
 * @export
 * @namespace VendorDTO
 */
export namespace VendorDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum VendorTypeEnum {
    DRIVER = <any>'DRIVER',
    TRUCKINGCOMPANY = <any>'TRUCKING_COMPANY'
  }
}

/**
 *
 * @export
 * @interface VendorNameDTO
 */
export interface VendorNameDTO {
  /**
   *
   * @type {string}
   * @memberof VendorNameDTO
   */
  authUserId?: string
  /**
   *
   * @type {number}
   * @memberof VendorNameDTO
   */
  completeness?: number
  /**
   *
   * @type {string}
   * @memberof VendorNameDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof VendorNameDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof VendorNameDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof VendorNameDTO
   */
  name?: string
}

/**
 *
 * @export
 * @interface VendorViewDTO
 */
export interface VendorViewDTO {
  /**
   *
   * @type {boolean}
   * @memberof VendorViewDTO
   */
  active?: boolean
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  authUserId?: string
  /**
   *
   * @type {boolean}
   * @memberof VendorViewDTO
   */
  availability?: boolean
  /**
   *
   * @type {BusinessPartnerViewDTO}
   * @memberof VendorViewDTO
   */
  businessPartner?: BusinessPartnerViewDTO
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  businessPartnerId?: string
  /**
   *
   * @type {number}
   * @memberof VendorViewDTO
   */
  completeness?: number
  /**
   *
   * @type {DateISOString}
   * @memberof VendorViewDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  dot?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorViewDTO
   */
  dotIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  motorCarrierNumber?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorViewDTO
   */
  motorCarrierNumberIssueDate?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  status: VendorViewDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  taxId?: string
  /**
   *
   * @type {DateISOString}
   * @memberof VendorViewDTO
   */
  updatedAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  updatedBy?: string
  /**
   *
   * @type {string}
   * @memberof VendorViewDTO
   */
  vendorType?: VendorViewDTO.VendorTypeEnum
}

/**
 * @export
 * @namespace VendorViewDTO
 */
export namespace VendorViewDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    AVAILABLE = <any>'AVAILABLE',
    NOTAVAILABLEASSIGNED = <any>'NOT_AVAILABLE_ASSIGNED',
    NOTAVAILABLEINUSE = <any>'NOT_AVAILABLE_IN_USE',
    INACTIVEEXPIREDCREDENTIALS = <any>'INACTIVE_EXPIRED_CREDENTIALS',
    INACTIVENODRUGTEST = <any>'INACTIVE_NO_DRUG_TEST',
    INACTIVEDOTSTOP = <any>'INACTIVE_DOT_STOP',
    INACTIVENOBIINSPECTION = <any>'INACTIVE_NO_BI_INSPECTION',
    INACTIVENOQUARTERLYINSPECTION = <any>'INACTIVE_NO_QUARTERLY_INSPECTION',
    INACTIVEOUTOFDRIVINGHOURS = <any>'INACTIVE_OUT_OF_DRIVING_HOURS',
    INACTIVEHOSVIOLATION = <any>'INACTIVE_HOS_VIOLATION',
    INACTIVENOPAPERLOG = <any>'INACTIVE_NO_PAPER_LOG',
    POWERUNITISINACTIVE = <any>'POWER_UNIT_IS_INACTIVE',
    OFFDUTY = <any>'OFF_DUTY',
    CANDIDATE = <any>'CANDIDATE',
    CANDIDATEREVIEW = <any>'CANDIDATE_REVIEW',
    CANDIDATEAPPROVED = <any>'CANDIDATE_APPROVED',
    CANDIDATEREJECTED = <any>'CANDIDATE_REJECTED',
    TERMINATED = <any>'TERMINATED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum VendorTypeEnum {
    DRIVER = <any>'DRIVER',
    TRUCKINGCOMPANY = <any>'TRUCKING_COMPANY'
  }
}

/**
 *
 * @export
 * @interface WorkOrderActivityDTO
 */
export interface WorkOrderActivityDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof WorkOrderActivityDTO
   */
  completionActualDate?: DateISOString
  /**
   *
   * @type {WorkOrderLocationDTO}
   * @memberof WorkOrderActivityDTO
   */
  destination?: WorkOrderLocationDTO
  /**
   *
   * @type {number}
   * @memberof WorkOrderActivityDTO
   */
  number?: number
  /**
   *
   * @type {DateISOString}
   * @memberof WorkOrderActivityDTO
   */
  startActualDate?: DateISOString
  /**
   *
   * @type {DateTimeRangeDTO}
   * @memberof WorkOrderActivityDTO
   */
  startPlannedDateTimeRange?: DateTimeRangeDTO
  /**
   *
   * @type {string}
   * @memberof WorkOrderActivityDTO
   */
  status?: WorkOrderActivityDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof WorkOrderActivityDTO
   */
  type?: WorkOrderActivityDTO.TypeEnum
  /**
   *
   * @type {string}
   * @memberof WorkOrderActivityDTO
   */
  vendorId?: string
}

/**
 * @export
 * @namespace WorkOrderActivityDTO
 */
export namespace WorkOrderActivityDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NEW = <any>'NEW',
    DRIVERPLANNED = <any>'DRIVER_PLANNED',
    DRIVERREFUSED = <any>'DRIVER_REFUSED',
    DRIVERASSIGNED = <any>'DRIVER_ASSIGNED',
    DRIVERCONFIRMED = <any>'DRIVER_CONFIRMED',
    INPROCESS = <any>'IN_PROCESS',
    COMPLETED = <any>'COMPLETED',
    UNSUCCESSFUL = <any>'UNSUCCESSFUL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
}

/**
 *
 * @export
 * @interface WorkOrderContactDTO
 */
export interface WorkOrderContactDTO {
  /**
   *
   * @type {string}
   * @memberof WorkOrderContactDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderContactDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderContactDTO
   */
  phone?: string
}

/**
 *
 * @export
 * @interface WorkOrderDTO
 */
export interface WorkOrderDTO {
  /**
   *
   * @type {Array<WorkOrderActivityDTO>}
   * @memberof WorkOrderDTO
   */
  activities?: Array<WorkOrderActivityDTO>
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  cargoDescription?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  containerType?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  deliveryOrderType?: WorkOrderDTO.DeliveryOrderTypeEnum
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {number}
   * @memberof WorkOrderDTO
   */
  dispatchDeliveryOrderNumber?: number
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  hazmatCode?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  purchaseOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  sealNumber?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  ssl?: string
  /**
   *
   * @type {number}
   * @memberof WorkOrderDTO
   */
  weight?: number
  /**
   *
   * @type {string}
   * @memberof WorkOrderDTO
   */
  weightUnit?: WorkOrderDTO.WeightUnitEnum
}

/**
 * @export
 * @namespace WorkOrderDTO
 */
export namespace WorkOrderDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DeliveryOrderTypeEnum {
    IMPORT = <any>'IMPORT',
    EXPORT = <any>'EXPORT',
    REPOSITION = <any>'REPOSITION'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum WeightUnitEnum {
    LBS = <any>'LBS',
    KGS = <any>'KGS'
  }
}

/**
 *
 * @export
 * @interface WorkOrderHistoryDTO
 */
export interface WorkOrderHistoryDTO {
  /**
   *
   * @type {Array<DispatchDeliveryOrderAuditTrailDTO>}
   * @memberof WorkOrderHistoryDTO
   */
  auditTrail?: Array<DispatchDeliveryOrderAuditTrailDTO>
  /**
   *
   * @type {WorkOrderDTO}
   * @memberof WorkOrderHistoryDTO
   */
  finalWorkOrder?: WorkOrderDTO
  /**
   *
   * @type {WorkOrderDTO}
   * @memberof WorkOrderHistoryDTO
   */
  initialWorkOrder?: WorkOrderDTO
}

/**
 *
 * @export
 * @interface WorkOrderLocationDTO
 */
export interface WorkOrderLocationDTO {
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  careOf?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  longName?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  postalCode?: string
  /**
   *
   * @type {WorkOrderContactDTO}
   * @memberof WorkOrderLocationDTO
   */
  primaryContact?: WorkOrderContactDTO
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  stateCode?: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderLocationDTO
   */
  street?: string
}

/**
 *
 * @export
 * @interface WorkOrderRefDTO
 */
export interface WorkOrderRefDTO {
  /**
   *
   * @type {string}
   * @memberof WorkOrderRefDTO
   */
  authDriverId: string
  /**
   *
   * @type {string}
   * @memberof WorkOrderRefDTO
   */
  dispatchDeliveryOrderId: string
}

/**
 * AccountingDataControllerApi - fetch parameter creator
 * @export
 */
export const AccountingDataControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {AccountingDataFilter} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(body: AccountingDataFilter, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling findAll.')
      }
      const localVarPath = `/accounting-data/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'AccountingDataFilter' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AccountingDataControllerApi - functional programming interface
 * @export
 */
export const AccountingDataControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {AccountingDataFilter} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      body: AccountingDataFilter,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderViewDTO>> {
      const localVarFetchArgs = AccountingDataControllerApiFetchParamCreator(configuration).findAll(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * AccountingDataControllerApi - factory interface
 * @export
 */
export const AccountingDataControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {AccountingDataFilter} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(body: AccountingDataFilter, options?: any) {
      return AccountingDataControllerApiFp(configuration).findAll(body, options)(basePath)
    }
  }
}

/**
 * AccountingDataControllerApi - object-oriented interface
 * @export
 * @class AccountingDataControllerApi
 * @extends {BaseAPI}
 */
export class AccountingDataControllerApi extends BaseAPI {
  /**
   *
   * @param {AccountingDataFilter} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingDataControllerApi
   */
  public findAll(body: AccountingDataFilter, options?: any) {
    return AccountingDataControllerApiFp(this.configuration).findAll(body, options)(this.basePath)
  }
}

/**
 * ActivitySchedulerControllerApi - fetch parameter creator
 * @export
 */
export const ActivitySchedulerControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/activity-scheduler`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendorsAssignedOnDeliveryStage(date: string, options: any = {}): FetchArgs {
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError(
          'date',
          'Required parameter date was null or undefined when calling findVendorsAssignedOnDeliveryStage.'
        )
      }
      const localVarPath = `/activity-scheduler/vendors-assigned-on-delivery-stage`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = date
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} timezoneOffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKeyPerformanceIndicator(timezoneOffset: number, options: any = {}): FetchArgs {
      // verify required parameter 'timezoneOffset' is not null or undefined
      if (timezoneOffset === null || timezoneOffset === undefined) {
        throw new RequiredError(
          'timezoneOffset',
          'Required parameter timezoneOffset was null or undefined when calling getKeyPerformanceIndicator.'
        )
      }
      const localVarPath = `/activity-scheduler/phase-key-performance-indicator`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (timezoneOffset !== undefined) {
        localVarQueryParameter['timezoneOffset'] = timezoneOffset
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [ddoFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroups(ddoFilter?: string, options: any = {}): FetchArgs {
      const localVarPath = `/activity-scheduler/rebuild-all-ongoing-activity-groups`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (ddoFilter !== undefined) {
        localVarQueryParameter['ddo-filter'] = ddoFilter
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroupsForDdo(dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling rebuildOngoingActivityGroupsForDdo.'
        )
      }
      const localVarPath = `/activity-scheduler/rebuild/{dispatch-delivery-order-id}`.replace(
        `{${'dispatch-delivery-order-id'}}`,
        encodeURIComponent(String(dispatchDeliveryOrderId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ActivitySchedulerControllerApi - functional programming interface
 * @export
 */
export const ActivitySchedulerControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<OngoingActivityGroupDTO>> {
      const localVarFetchArgs = ActivitySchedulerControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendorsAssignedOnDeliveryStage(date: string, options?: any): (basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = ActivitySchedulerControllerApiFetchParamCreator(
        configuration
      ).findVendorsAssignedOnDeliveryStage(date, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} timezoneOffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKeyPerformanceIndicator(
      timezoneOffset: number,
      options?: any
    ): (basePath?: string) => Promise<PhaseKeyPerformanceIndicator> {
      const localVarFetchArgs = ActivitySchedulerControllerApiFetchParamCreator(
        configuration
      ).getKeyPerformanceIndicator(timezoneOffset, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [ddoFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroups(ddoFilter?: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ActivitySchedulerControllerApiFetchParamCreator(
        configuration
      ).rebuildOngoingActivityGroups(ddoFilter, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroupsForDdo(
      dispatchDeliveryOrderId: string,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ActivitySchedulerControllerApiFetchParamCreator(
        configuration
      ).rebuildOngoingActivityGroupsForDdo(dispatchDeliveryOrderId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ActivitySchedulerControllerApi - factory interface
 * @export
 */
export const ActivitySchedulerControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return ActivitySchedulerControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendorsAssignedOnDeliveryStage(date: string, options?: any) {
      return ActivitySchedulerControllerApiFp(configuration).findVendorsAssignedOnDeliveryStage(date, options)(basePath)
    },
    /**
     *
     * @param {number} timezoneOffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKeyPerformanceIndicator(timezoneOffset: number, options?: any) {
      return ActivitySchedulerControllerApiFp(configuration).getKeyPerformanceIndicator(timezoneOffset, options)(
        basePath
      )
    },
    /**
     *
     * @param {string} [ddoFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroups(ddoFilter?: string, options?: any) {
      return ActivitySchedulerControllerApiFp(configuration).rebuildOngoingActivityGroups(ddoFilter, options)(basePath)
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rebuildOngoingActivityGroupsForDdo(dispatchDeliveryOrderId: string, options?: any) {
      return ActivitySchedulerControllerApiFp(configuration).rebuildOngoingActivityGroupsForDdo(
        dispatchDeliveryOrderId,
        options
      )(basePath)
    }
  }
}

/**
 * ActivitySchedulerControllerApi - object-oriented interface
 * @export
 * @class ActivitySchedulerControllerApi
 * @extends {BaseAPI}
 */
export class ActivitySchedulerControllerApi extends BaseAPI {
  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitySchedulerControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return ActivitySchedulerControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitySchedulerControllerApi
   */
  public findVendorsAssignedOnDeliveryStage(date: string, options?: any) {
    return ActivitySchedulerControllerApiFp(this.configuration).findVendorsAssignedOnDeliveryStage(date, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {number} timezoneOffset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitySchedulerControllerApi
   */
  public getKeyPerformanceIndicator(timezoneOffset: number, options?: any) {
    return ActivitySchedulerControllerApiFp(this.configuration).getKeyPerformanceIndicator(timezoneOffset, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} [ddoFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitySchedulerControllerApi
   */
  public rebuildOngoingActivityGroups(ddoFilter?: string, options?: any) {
    return ActivitySchedulerControllerApiFp(this.configuration).rebuildOngoingActivityGroups(ddoFilter, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivitySchedulerControllerApi
   */
  public rebuildOngoingActivityGroupsForDdo(dispatchDeliveryOrderId: string, options?: any) {
    return ActivitySchedulerControllerApiFp(this.configuration).rebuildOngoingActivityGroupsForDdo(
      dispatchDeliveryOrderId,
      options
    )(this.basePath)
  }
}

/**
 * AuditTrailControllerApi - fetch parameter creator
 * @export
 */
export const AuditTrailControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditTrail(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getAuditTrail.')
      }
      const localVarPath = `/audit-trail/dispatch-delivery-order/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AuditTrailControllerApi - functional programming interface
 * @export
 */
export const AuditTrailControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditTrail(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderAuditTrailDTO>> {
      const localVarFetchArgs = AuditTrailControllerApiFetchParamCreator(configuration).getAuditTrail(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * AuditTrailControllerApi - factory interface
 * @export
 */
export const AuditTrailControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditTrail(id: string, options?: any) {
      return AuditTrailControllerApiFp(configuration).getAuditTrail(id, options)(basePath)
    }
  }
}

/**
 * AuditTrailControllerApi - object-oriented interface
 * @export
 * @class AuditTrailControllerApi
 * @extends {BaseAPI}
 */
export class AuditTrailControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditTrailControllerApi
   */
  public getAuditTrail(id: string, options?: any) {
    return AuditTrailControllerApiFp(this.configuration).getAuditTrail(id, options)(this.basePath)
  }
}

/**
 * BusinessPartnerControllerApi - fetch parameter creator
 * @export
 */
export const BusinessPartnerControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/business-partner/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: BusinessPartnerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/business-partner`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BusinessPartnerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/business-partner`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/business-partner/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: BusinessPartnerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/business-partner/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BusinessPartnerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * BusinessPartnerControllerApi - functional programming interface
 * @export
 */
export const BusinessPartnerControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BusinessPartnerControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: BusinessPartnerDTO, options?: any): (basePath?: string) => Promise<BusinessPartnerViewDTO> {
      const localVarFetchArgs = BusinessPartnerControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<BusinessPartnerViewDTO>> {
      const localVarFetchArgs = BusinessPartnerControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<BusinessPartnerViewDTO> {
      const localVarFetchArgs = BusinessPartnerControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      id: string,
      body: BusinessPartnerDTO,
      options?: any
    ): (basePath?: string) => Promise<BusinessPartnerViewDTO> {
      const localVarFetchArgs = BusinessPartnerControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * BusinessPartnerControllerApi - factory interface
 * @export
 */
export const BusinessPartnerControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return BusinessPartnerControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: BusinessPartnerDTO, options?: any) {
      return BusinessPartnerControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return BusinessPartnerControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return BusinessPartnerControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {BusinessPartnerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: BusinessPartnerDTO, options?: any) {
      return BusinessPartnerControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * BusinessPartnerControllerApi - object-oriented interface
 * @export
 * @class BusinessPartnerControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPartnerControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPartnerControllerApi
   */
  public _delete(id: string, options?: any) {
    return BusinessPartnerControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {BusinessPartnerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPartnerControllerApi
   */
  public create(body: BusinessPartnerDTO, options?: any) {
    return BusinessPartnerControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPartnerControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return BusinessPartnerControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPartnerControllerApi
   */
  public findById(id: string, options?: any) {
    return BusinessPartnerControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {BusinessPartnerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessPartnerControllerApi
   */
  public update(id: string, body: BusinessPartnerDTO, options?: any) {
    return BusinessPartnerControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * BuySideQuoteControllerApi - fetch parameter creator
 * @export
 */
export const BuySideQuoteControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {BsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: BsqRateDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling applyRate.')
      }
      const localVarPath = `/buy-side-quote/apply-rate`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BsqRateDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuySideQuote(body: BuySideQuoteDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling createBuySideQuote.'
        )
      }
      const localVarPath = `/buy-side-quote`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BuySideQuoteDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBuySideQuote(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteBuySideQuote.')
      }
      const localVarPath = `/buy-side-quote/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/buy-side-quote/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBuySideQuoteByDispatchDeliveryOrderId(dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling getBuySideQuoteByDispatchDeliveryOrderId.'
        )
      }
      const localVarPath = `/buy-side-quote/ddo/{dispatchDeliveryOrderId}`.replace(
        `{${'dispatchDeliveryOrderId'}}`,
        encodeURIComponent(String(dispatchDeliveryOrderId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: BuySideQuoteDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/buy-side-quote/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'BuySideQuoteDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * BuySideQuoteControllerApi - functional programming interface
 * @export
 */
export const BuySideQuoteControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {BsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: BsqRateDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(configuration).applyRate(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuySideQuote(body: BuySideQuoteDTO, options?: any): (basePath?: string) => Promise<BuySideQuoteDTO> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(configuration).createBuySideQuote(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBuySideQuote(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(configuration).deleteBuySideQuote(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<BuySideQuoteDTO> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBuySideQuoteByDispatchDeliveryOrderId(
      dispatchDeliveryOrderId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<BuySideQuoteDTO>> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(
        configuration
      ).getBuySideQuoteByDispatchDeliveryOrderId(dispatchDeliveryOrderId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: BuySideQuoteDTO, options?: any): (basePath?: string) => Promise<BuySideQuoteDTO> {
      const localVarFetchArgs = BuySideQuoteControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * BuySideQuoteControllerApi - factory interface
 * @export
 */
export const BuySideQuoteControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {BsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: BsqRateDTO, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).applyRate(body, options)(basePath)
    },
    /**
     *
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBuySideQuote(body: BuySideQuoteDTO, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).createBuySideQuote(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBuySideQuote(id: string, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).deleteBuySideQuote(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBuySideQuoteByDispatchDeliveryOrderId(dispatchDeliveryOrderId: string, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).getBuySideQuoteByDispatchDeliveryOrderId(
        dispatchDeliveryOrderId,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {BuySideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: BuySideQuoteDTO, options?: any) {
      return BuySideQuoteControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * BuySideQuoteControllerApi - object-oriented interface
 * @export
 * @class BuySideQuoteControllerApi
 * @extends {BaseAPI}
 */
export class BuySideQuoteControllerApi extends BaseAPI {
  /**
   *
   * @param {BsqRateDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public applyRate(body: BsqRateDTO, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).applyRate(body, options)(this.basePath)
  }

  /**
   *
   * @param {BuySideQuoteDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public createBuySideQuote(body: BuySideQuoteDTO, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).createBuySideQuote(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public deleteBuySideQuote(id: string, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).deleteBuySideQuote(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public findById(id: string, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public getBuySideQuoteByDispatchDeliveryOrderId(dispatchDeliveryOrderId: string, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).getBuySideQuoteByDispatchDeliveryOrderId(
      dispatchDeliveryOrderId,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {BuySideQuoteDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuySideQuoteControllerApi
   */
  public update(id: string, body: BuySideQuoteDTO, options?: any) {
    return BuySideQuoteControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * CargoControllerApi - fetch parameter creator
 * @export
 */
export const CargoControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/cargo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CargoDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/cargo`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CargoDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/cargo`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/cargo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CargoDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/cargo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CargoDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CargoControllerApi - functional programming interface
 * @export
 */
export const CargoControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CargoControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CargoDTO, options?: any): (basePath?: string) => Promise<CargoDTO> {
      const localVarFetchArgs = CargoControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<CargoDTO>> {
      const localVarFetchArgs = CargoControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<CargoDTO> {
      const localVarFetchArgs = CargoControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CargoDTO, options?: any): (basePath?: string) => Promise<CargoDTO> {
      const localVarFetchArgs = CargoControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * CargoControllerApi - factory interface
 * @export
 */
export const CargoControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return CargoControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CargoDTO, options?: any) {
      return CargoControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return CargoControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return CargoControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {CargoDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CargoDTO, options?: any) {
      return CargoControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * CargoControllerApi - object-oriented interface
 * @export
 * @class CargoControllerApi
 * @extends {BaseAPI}
 */
export class CargoControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoControllerApi
   */
  public _delete(id: string, options?: any) {
    return CargoControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {CargoDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoControllerApi
   */
  public create(body: CargoDTO, options?: any) {
    return CargoControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return CargoControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoControllerApi
   */
  public findById(id: string, options?: any) {
    return CargoControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {CargoDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoControllerApi
   */
  public update(id: string, body: CargoDTO, options?: any) {
    return CargoControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * ContactControllerApi - fetch parameter creator
 * @export
 */
export const ContactControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/contact/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContactDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/contact`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContactDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/contact`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/contact/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContactDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/contact/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContactDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ContactControllerApi - functional programming interface
 * @export
 */
export const ContactControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ContactControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContactDTO, options?: any): (basePath?: string) => Promise<ContactDTO> {
      const localVarFetchArgs = ContactControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<ContactDTO>> {
      const localVarFetchArgs = ContactControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<ContactDTO> {
      const localVarFetchArgs = ContactControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContactDTO, options?: any): (basePath?: string) => Promise<ContactDTO> {
      const localVarFetchArgs = ContactControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ContactControllerApi - factory interface
 * @export
 */
export const ContactControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return ContactControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContactDTO, options?: any) {
      return ContactControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return ContactControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return ContactControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {ContactDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContactDTO, options?: any) {
      return ContactControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * ContactControllerApi - object-oriented interface
 * @export
 * @class ContactControllerApi
 * @extends {BaseAPI}
 */
export class ContactControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactControllerApi
   */
  public _delete(id: string, options?: any) {
    return ContactControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {ContactDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactControllerApi
   */
  public create(body: ContactDTO, options?: any) {
    return ContactControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return ContactControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactControllerApi
   */
  public findById(id: string, options?: any) {
    return ContactControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {ContactDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactControllerApi
   */
  public update(id: string, body: ContactDTO, options?: any) {
    return ContactControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * ContainerControllerApi - fetch parameter creator
 * @export
 */
export const ContainerControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/container/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/container`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContainerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/container`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/container/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [containerTypeId]
     * @param {number} [limit]
     * @param {string} [steamShipLineId]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      containerTypeId?: string,
      limit?: number,
      steamShipLineId?: string,
      term?: string,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/container/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (containerTypeId !== undefined) {
        localVarQueryParameter['containerTypeId'] = containerTypeId
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (steamShipLineId !== undefined) {
        localVarQueryParameter['steamShipLineId'] = steamShipLineId
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/container/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContainerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ContainerControllerApi - functional programming interface
 * @export
 */
export const ContainerControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerDTO, options?: any): (basePath?: string) => Promise<ContainerViewDTO> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<ContainerViewDTO>> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<ContainerViewDTO> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [containerTypeId]
     * @param {number} [limit]
     * @param {string} [steamShipLineId]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      containerTypeId?: string,
      limit?: number,
      steamShipLineId?: string,
      term?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<ContainerSearchDTO>> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration).search(
        containerTypeId,
        limit,
        steamShipLineId,
        term,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerDTO, options?: any): (basePath?: string) => Promise<ContainerViewDTO> {
      const localVarFetchArgs = ContainerControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ContainerControllerApi - factory interface
 * @export
 */
export const ContainerControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return ContainerControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerDTO, options?: any) {
      return ContainerControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return ContainerControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return ContainerControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} [containerTypeId]
     * @param {number} [limit]
     * @param {string} [steamShipLineId]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(containerTypeId?: string, limit?: number, steamShipLineId?: string, term?: string, options?: any) {
      return ContainerControllerApiFp(configuration).search(containerTypeId, limit, steamShipLineId, term, options)(
        basePath
      )
    },
    /**
     *
     * @param {string} id
     * @param {ContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerDTO, options?: any) {
      return ContainerControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * ContainerControllerApi - object-oriented interface
 * @export
 * @class ContainerControllerApi
 * @extends {BaseAPI}
 */
export class ContainerControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public _delete(id: string, options?: any) {
    return ContainerControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {ContainerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public create(body: ContainerDTO, options?: any) {
    return ContainerControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return ContainerControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public findById(id: string, options?: any) {
    return ContainerControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [containerTypeId]
   * @param {number} [limit]
   * @param {string} [steamShipLineId]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public search(containerTypeId?: string, limit?: number, steamShipLineId?: string, term?: string, options?: any) {
    return ContainerControllerApiFp(this.configuration).search(containerTypeId, limit, steamShipLineId, term, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} id
   * @param {ContainerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerControllerApi
   */
  public update(id: string, body: ContainerDTO, options?: any) {
    return ContainerControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * ContainerTypeControllerApi - fetch parameter creator
 * @export
 */
export const ContainerTypeControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/container-type/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerTypeDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/container-type`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContainerTypeDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/container-type`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/container-type/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerTypeDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/container-type/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ContainerTypeDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ContainerTypeControllerApi - functional programming interface
 * @export
 */
export const ContainerTypeControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = ContainerTypeControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerTypeDTO, options?: any): (basePath?: string) => Promise<ContainerTypeDTO> {
      const localVarFetchArgs = ContainerTypeControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<ContainerTypeDTO>> {
      const localVarFetchArgs = ContainerTypeControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<ContainerTypeDTO> {
      const localVarFetchArgs = ContainerTypeControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerTypeDTO, options?: any): (basePath?: string) => Promise<ContainerTypeDTO> {
      const localVarFetchArgs = ContainerTypeControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ContainerTypeControllerApi - factory interface
 * @export
 */
export const ContainerTypeControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return ContainerTypeControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: ContainerTypeDTO, options?: any) {
      return ContainerTypeControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return ContainerTypeControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return ContainerTypeControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {ContainerTypeDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: ContainerTypeDTO, options?: any) {
      return ContainerTypeControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * ContainerTypeControllerApi - object-oriented interface
 * @export
 * @class ContainerTypeControllerApi
 * @extends {BaseAPI}
 */
export class ContainerTypeControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerTypeControllerApi
   */
  public _delete(id: string, options?: any) {
    return ContainerTypeControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {ContainerTypeDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerTypeControllerApi
   */
  public create(body: ContainerTypeDTO, options?: any) {
    return ContainerTypeControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerTypeControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return ContainerTypeControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerTypeControllerApi
   */
  public findById(id: string, options?: any) {
    return ContainerTypeControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {ContainerTypeDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerTypeControllerApi
   */
  public update(id: string, body: ContainerTypeDTO, options?: any) {
    return ContainerTypeControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * CountryControllerApi - fetch parameter creator
 * @export
 */
export const CountryControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options: any = {}): FetchArgs {
      const localVarPath = `/country`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CountryControllerApi - functional programming interface
 * @export
 */
export const CountryControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options?: any): (basePath?: string) => Promise<Array<CountryDTO>> {
      const localVarFetchArgs = CountryControllerApiFetchParamCreator(configuration).findAll(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * CountryControllerApi - factory interface
 * @export
 */
export const CountryControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options?: any) {
      return CountryControllerApiFp(configuration).findAll(options)(basePath)
    }
  }
}

/**
 * CountryControllerApi - object-oriented interface
 * @export
 * @class CountryControllerApi
 * @extends {BaseAPI}
 */
export class CountryControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountryControllerApi
   */
  public findAll(options?: any) {
    return CountryControllerApiFp(this.configuration).findAll(options)(this.basePath)
  }
}

/**
 * CustomerControllerApi - fetch parameter creator
 * @export
 */
export const CustomerControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/customer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CustomerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/customer`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CustomerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/customer`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/customer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/customer/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CustomerDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/customer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'CustomerDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CustomerControllerApi - functional programming interface
 * @export
 */
export const CustomerControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CustomerDTO, options?: any): (basePath?: string) => Promise<CustomerViewDTO> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<CustomerGridItemDTO>> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<CustomerViewDTO> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<CustomerNameDTO>> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CustomerDTO, options?: any): (basePath?: string) => Promise<CustomerViewDTO> {
      const localVarFetchArgs = CustomerControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * CustomerControllerApi - factory interface
 * @export
 */
export const CustomerControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return CustomerControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: CustomerDTO, options?: any) {
      return CustomerControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return CustomerControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return CustomerControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return CustomerControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {CustomerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: CustomerDTO, options?: any) {
      return CustomerControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * CustomerControllerApi - object-oriented interface
 * @export
 * @class CustomerControllerApi
 * @extends {BaseAPI}
 */
export class CustomerControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public _delete(id: string, options?: any) {
    return CustomerControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {CustomerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public create(body: CustomerDTO, options?: any) {
    return CustomerControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return CustomerControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public findById(id: string, options?: any) {
    return CustomerControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return CustomerControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {CustomerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public update(id: string, body: CustomerDTO, options?: any) {
    return CustomerControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * DashboardControllerApi - fetch parameter creator
 * @export
 */
export const DashboardControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard(options: any = {}): FetchArgs {
      const localVarPath = `/dashboard/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard(options?: any): (basePath?: string) => Promise<DashboardDTO> {
      const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDashboard(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard(options?: any) {
      return DashboardControllerApiFp(configuration).getDashboard(options)(basePath)
    }
  }
}

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardControllerApi
   */
  public getDashboard(options?: any) {
    return DashboardControllerApiFp(this.configuration).getDashboard(options)(this.basePath)
  }
}

/**
 * DeliveryOrderControllerApi - fetch parameter creator
 * @export
 */
export const DeliveryOrderControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} referenceNumber
     * @param {string} [steamShipLineId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkByReferenceNumber(referenceNumber: string, steamShipLineId?: string, options: any = {}): FetchArgs {
      // verify required parameter 'referenceNumber' is not null or undefined
      if (referenceNumber === null || referenceNumber === undefined) {
        throw new RequiredError(
          'referenceNumber',
          'Required parameter referenceNumber was null or undefined when calling checkByReferenceNumber.'
        )
      }
      const localVarPath = `/delivery-order/check-reference-number`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (referenceNumber !== undefined) {
        localVarQueryParameter['referenceNumber'] = referenceNumber
      }

      if (steamShipLineId !== undefined) {
        localVarQueryParameter['steamShipLineId'] = steamShipLineId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DeliveryOrderDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/delivery-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DeliveryOrderDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/delivery-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling notify.')
      }
      const localVarPath = `/delivery-order/{id}/notify`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DeliveryOrderDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DeliveryOrderDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DeliveryOrderControllerApi - functional programming interface
 * @export
 */
export const DeliveryOrderControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} referenceNumber
     * @param {string} [steamShipLineId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkByReferenceNumber(
      referenceNumber: string,
      steamShipLineId?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DeliveryOrderSearchDTO>> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).checkByReferenceNumber(
        referenceNumber,
        steamShipLineId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DeliveryOrderDTO, options?: any): (basePath?: string) => Promise<DeliveryOrderViewDTO> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DeliveryOrderViewDTO>> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<DeliveryOrderViewDTO> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).notify(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DeliveryOrderDTO, options?: any): (basePath?: string) => Promise<DeliveryOrderViewDTO> {
      const localVarFetchArgs = DeliveryOrderControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DeliveryOrderControllerApi - factory interface
 * @export
 */
export const DeliveryOrderControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return DeliveryOrderControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {string} referenceNumber
     * @param {string} [steamShipLineId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkByReferenceNumber(referenceNumber: string, steamShipLineId?: string, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).checkByReferenceNumber(
        referenceNumber,
        steamShipLineId,
        options
      )(basePath)
    },
    /**
     *
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DeliveryOrderDTO, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).notify(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {DeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DeliveryOrderDTO, options?: any) {
      return DeliveryOrderControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * DeliveryOrderControllerApi - object-oriented interface
 * @export
 * @class DeliveryOrderControllerApi
 * @extends {BaseAPI}
 */
export class DeliveryOrderControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public _delete(id: string, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} referenceNumber
   * @param {string} [steamShipLineId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public checkByReferenceNumber(referenceNumber: string, steamShipLineId?: string, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).checkByReferenceNumber(
      referenceNumber,
      steamShipLineId,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {DeliveryOrderDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public create(body: DeliveryOrderDTO, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public findById(id: string, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public notify(id: string, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).notify(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {DeliveryOrderDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryOrderControllerApi
   */
  public update(id: string, body: DeliveryOrderDTO, options?: any) {
    return DeliveryOrderControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * DispatchDeliveryOrderControllerApi - fetch parameter creator
 * @export
 */
export const DispatchDeliveryOrderControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<DispatchDeliveryOrderDTO>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(body: Array<DispatchDeliveryOrderDTO>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling batchUpdate.')
      }
      const localVarPath = `/dispatch-delivery-order/batch`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DispatchDeliveryOrderDTO[]' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling cancelOrder.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/cancel`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTerminal(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling checkTerminal.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/check-terminal`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countAllNonCompleted(options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/non-completed-count`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DispatchDeliveryOrderDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/dispatch-delivery-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DispatchDeliveryOrderDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivities(id: string, body: ActivitiesDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteActivities.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling deleteActivities.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/activities`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ActivitiesDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findActivities(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findActivities.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/activities`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllNonCompleted(options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/non-completed`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDeclinedVendors(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findDeclinedVendors.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/declined-vendors`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendors(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findVendors.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/vendors`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrders(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/grid`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrdersWithActivities(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/grid-activities`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHazmat(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getHazmat.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/hazmat`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaxDispatchDeliveryOrderNumber(options: any = {}): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/max-number`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageableGridDispatchDeliveryOrders(
      filter?: string,
      pageNo?: number,
      pageSize?: number,
      sort?: string,
      options: any = {}
    ): FetchArgs {
      const localVarPath = `/dispatch-delivery-order/pageable-grid`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling notify.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/notify`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DispatchDeliveryOrderSearchRequestDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(body: DispatchDeliveryOrderSearchRequestDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling search.')
      }
      const localVarPath = `/dispatch-delivery-order/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DispatchDeliveryOrderSearchRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} term
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchByNumber(term: string, limit?: number, options: any = {}): FetchArgs {
      // verify required parameter 'term' is not null or undefined
      if (term === null || term === undefined) {
        throw new RequiredError('term', 'Required parameter term was null or undefined when calling searchByNumber.')
      }
      const localVarPath = `/dispatch-delivery-order/search/number`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DispatchDeliveryOrderDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DispatchDeliveryOrderDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivities(id: string, body: ActivitiesDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateActivities.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateActivities.')
      }
      const localVarPath = `/dispatch-delivery-order/{id}/activities`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ActivitiesDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DispatchDeliveryOrderControllerApi - functional programming interface
 * @export
 */
export const DispatchDeliveryOrderControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<DispatchDeliveryOrderDTO>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(
      body: Array<DispatchDeliveryOrderDTO>,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderViewDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).batchUpdate(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).cancelOrder(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTerminal(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).checkTerminal(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countAllNonCompleted(options?: any): (basePath?: string) => Promise<number> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).countAllNonCompleted(
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      body: DispatchDeliveryOrderDTO,
      options?: any
    ): (basePath?: string) => Promise<DispatchDeliveryOrderViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivities(id: string, body: ActivitiesDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).deleteActivities(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findActivities(id: string, options?: any): (basePath?: string) => Promise<ActivitiesViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findActivities(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderViewDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllNonCompleted(options?: any): (basePath?: string) => Promise<Array<DispatchDeliveryOrderNumber>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findAllNonCompleted(
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDeclinedVendors(id: string, options?: any): (basePath?: string) => Promise<Array<VendorNameDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findDeclinedVendors(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendors(id: string, options?: any): (basePath?: string) => Promise<Array<VendorNameDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).findVendors(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrders(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(
        configuration
      ).getGridDispatchDeliveryOrders(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrdersWithActivities(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemWithActivitiesDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(
        configuration
      ).getGridDispatchDeliveryOrdersWithActivities(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHazmat(id: string, options?: any): (basePath?: string) => Promise<HazmatDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).getHazmat(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaxDispatchDeliveryOrderNumber(options?: any): (basePath?: string) => Promise<number> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(
        configuration
      ).getMaxDispatchDeliveryOrderNumber(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageableGridDispatchDeliveryOrders(
      filter?: string,
      pageNo?: number,
      pageSize?: number,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(
        configuration
      ).getPageableGridDispatchDeliveryOrders(filter, pageNo, pageSize, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).notify(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DispatchDeliveryOrderSearchRequestDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      body: DispatchDeliveryOrderSearchRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderSearchDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).search(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} term
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchByNumber(
      term: string,
      limit?: number,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderNumber>> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).searchByNumber(
        term,
        limit,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      id: string,
      body: DispatchDeliveryOrderDTO,
      options?: any
    ): (basePath?: string) => Promise<DispatchDeliveryOrderViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).update(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivities(
      id: string,
      body: ActivitiesDTO,
      options?: any
    ): (basePath?: string) => Promise<ActivitiesViewDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderControllerApiFetchParamCreator(configuration).updateActivities(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DispatchDeliveryOrderControllerApi - factory interface
 * @export
 */
export const DispatchDeliveryOrderControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {Array<DispatchDeliveryOrderDTO>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdate(body: Array<DispatchDeliveryOrderDTO>, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).batchUpdate(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).cancelOrder(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTerminal(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).checkTerminal(id, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countAllNonCompleted(options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).countAllNonCompleted(options)(basePath)
    },
    /**
     *
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DispatchDeliveryOrderDTO, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActivities(id: string, body: ActivitiesDTO, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).deleteActivities(id, body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findActivities(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findActivities(id, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllNonCompleted(options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findAllNonCompleted(options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDeclinedVendors(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findDeclinedVendors(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findVendors(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).findVendors(id, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrders(filter?: string, sort?: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).getGridDispatchDeliveryOrders(filter, sort, options)(
        basePath
      )
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridDispatchDeliveryOrdersWithActivities(filter?: string, sort?: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).getGridDispatchDeliveryOrdersWithActivities(
        filter,
        sort,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHazmat(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).getHazmat(id, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaxDispatchDeliveryOrderNumber(options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).getMaxDispatchDeliveryOrderNumber(options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageableGridDispatchDeliveryOrders(
      filter?: string,
      pageNo?: number,
      pageSize?: number,
      sort?: string,
      options?: any
    ) {
      return DispatchDeliveryOrderControllerApiFp(configuration).getPageableGridDispatchDeliveryOrders(
        filter,
        pageNo,
        pageSize,
        sort,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify(id: string, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).notify(id, options)(basePath)
    },
    /**
     *
     * @param {DispatchDeliveryOrderSearchRequestDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(body: DispatchDeliveryOrderSearchRequestDTO, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).search(body, options)(basePath)
    },
    /**
     *
     * @param {string} term
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchByNumber(term: string, limit?: number, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).searchByNumber(term, limit, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {DispatchDeliveryOrderDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DispatchDeliveryOrderDTO, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).update(id, body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {ActivitiesDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivities(id: string, body: ActivitiesDTO, options?: any) {
      return DispatchDeliveryOrderControllerApiFp(configuration).updateActivities(id, body, options)(basePath)
    }
  }
}

/**
 * DispatchDeliveryOrderControllerApi - object-oriented interface
 * @export
 * @class DispatchDeliveryOrderControllerApi
 * @extends {BaseAPI}
 */
export class DispatchDeliveryOrderControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public _delete(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {Array<DispatchDeliveryOrderDTO>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public batchUpdate(body: Array<DispatchDeliveryOrderDTO>, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).batchUpdate(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public cancelOrder(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).cancelOrder(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public checkTerminal(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).checkTerminal(id, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public countAllNonCompleted(options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).countAllNonCompleted(options)(this.basePath)
  }

  /**
   *
   * @param {DispatchDeliveryOrderDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public create(body: DispatchDeliveryOrderDTO, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {ActivitiesDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public deleteActivities(id: string, body: ActivitiesDTO, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).deleteActivities(id, body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findActivities(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findActivities(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findAllNonCompleted(options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findAllNonCompleted(options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findById(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findDeclinedVendors(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findDeclinedVendors(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public findVendors(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).findVendors(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public getGridDispatchDeliveryOrders(filter?: string, sort?: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).getGridDispatchDeliveryOrders(
      filter,
      sort,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public getGridDispatchDeliveryOrdersWithActivities(filter?: string, sort?: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).getGridDispatchDeliveryOrdersWithActivities(
      filter,
      sort,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public getHazmat(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).getHazmat(id, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public getMaxDispatchDeliveryOrderNumber(options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).getMaxDispatchDeliveryOrderNumber(options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} [filter]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public getPageableGridDispatchDeliveryOrders(
    filter?: string,
    pageNo?: number,
    pageSize?: number,
    sort?: string,
    options?: any
  ) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).getPageableGridDispatchDeliveryOrders(
      filter,
      pageNo,
      pageSize,
      sort,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public notify(id: string, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).notify(id, options)(this.basePath)
  }

  /**
   *
   * @param {DispatchDeliveryOrderSearchRequestDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public search(body: DispatchDeliveryOrderSearchRequestDTO, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).search(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} term
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public searchByNumber(term: string, limit?: number, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).searchByNumber(term, limit, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {DispatchDeliveryOrderDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public update(id: string, body: DispatchDeliveryOrderDTO, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {ActivitiesDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderControllerApi
   */
  public updateActivities(id: string, body: ActivitiesDTO, options?: any) {
    return DispatchDeliveryOrderControllerApiFp(this.configuration).updateActivities(id, body, options)(this.basePath)
  }
}

/**
 * DispatchDeliveryOrderStreetTurnControllerApi - fetch parameter creator
 * @export
 */
export const DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling approve.')
      }
      const localVarPath = `/dispatch-delivery-order-street-turn/{id}/approve`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling cancel.')
      }
      const localVarPath = `/dispatch-delivery-order-street-turn/{id}/cancel`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(ddoId: string, options: any = {}): FetchArgs {
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError('ddoId', 'Required parameter ddoId was null or undefined when calling findAll.')
      }
      const localVarPath = `/dispatch-delivery-order-street-turn/{ddoId}`.replace(
        `{${'ddoId'}}`,
        encodeURIComponent(String(ddoId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reject(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling reject.')
      }
      const localVarPath = `/dispatch-delivery-order-street-turn/{id}/reject`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submit(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling submit.')
      }
      const localVarPath = `/dispatch-delivery-order-street-turn/{id}/submit`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DispatchDeliveryOrderStreetTurnControllerApi - functional programming interface
 * @export
 */
export const DispatchDeliveryOrderStreetTurnControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderStreetTurnDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator(configuration).approve(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderStreetTurnDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator(configuration).cancel(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(ddoId: string, options?: any): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator(configuration).findAll(
        ddoId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reject(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderStreetTurnDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator(configuration).reject(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submit(id: string, options?: any): (basePath?: string) => Promise<DispatchDeliveryOrderStreetTurnDTO> {
      const localVarFetchArgs = DispatchDeliveryOrderStreetTurnControllerApiFetchParamCreator(configuration).submit(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DispatchDeliveryOrderStreetTurnControllerApi - factory interface
 * @export
 */
export const DispatchDeliveryOrderStreetTurnControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string
) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approve(id: string, options?: any) {
      return DispatchDeliveryOrderStreetTurnControllerApiFp(configuration).approve(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(id: string, options?: any) {
      return DispatchDeliveryOrderStreetTurnControllerApiFp(configuration).cancel(id, options)(basePath)
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(ddoId: string, options?: any) {
      return DispatchDeliveryOrderStreetTurnControllerApiFp(configuration).findAll(ddoId, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reject(id: string, options?: any) {
      return DispatchDeliveryOrderStreetTurnControllerApiFp(configuration).reject(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submit(id: string, options?: any) {
      return DispatchDeliveryOrderStreetTurnControllerApiFp(configuration).submit(id, options)(basePath)
    }
  }
}

/**
 * DispatchDeliveryOrderStreetTurnControllerApi - object-oriented interface
 * @export
 * @class DispatchDeliveryOrderStreetTurnControllerApi
 * @extends {BaseAPI}
 */
export class DispatchDeliveryOrderStreetTurnControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderStreetTurnControllerApi
   */
  public approve(id: string, options?: any) {
    return DispatchDeliveryOrderStreetTurnControllerApiFp(this.configuration).approve(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderStreetTurnControllerApi
   */
  public cancel(id: string, options?: any) {
    return DispatchDeliveryOrderStreetTurnControllerApiFp(this.configuration).cancel(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} ddoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderStreetTurnControllerApi
   */
  public findAll(ddoId: string, options?: any) {
    return DispatchDeliveryOrderStreetTurnControllerApiFp(this.configuration).findAll(ddoId, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderStreetTurnControllerApi
   */
  public reject(id: string, options?: any) {
    return DispatchDeliveryOrderStreetTurnControllerApiFp(this.configuration).reject(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DispatchDeliveryOrderStreetTurnControllerApi
   */
  public submit(id: string, options?: any) {
    return DispatchDeliveryOrderStreetTurnControllerApiFp(this.configuration).submit(id, options)(this.basePath)
  }
}

/**
 * DocumentationActivityControllerApi - fetch parameter creator
 * @export
 */
export const DocumentationActivityControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/documentation-activity/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/documentation-activity`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/documentation-activity/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DocumentationActivityControllerApi - functional programming interface
 * @export
 */
export const DocumentationActivityControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DocumentationActivityControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DocumentationActivityDTO>> {
      const localVarFetchArgs = DocumentationActivityControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<DocumentationActivityDTO> {
      const localVarFetchArgs = DocumentationActivityControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DocumentationActivityControllerApi - factory interface
 * @export
 */
export const DocumentationActivityControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return DocumentationActivityControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return DocumentationActivityControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return DocumentationActivityControllerApiFp(configuration).findById(id, options)(basePath)
    }
  }
}

/**
 * DocumentationActivityControllerApi - object-oriented interface
 * @export
 * @class DocumentationActivityControllerApi
 * @extends {BaseAPI}
 */
export class DocumentationActivityControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentationActivityControllerApi
   */
  public _delete(id: string, options?: any) {
    return DocumentationActivityControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentationActivityControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return DocumentationActivityControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentationActivityControllerApi
   */
  public findById(id: string, options?: any) {
    return DocumentationActivityControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }
}

/**
 * DriverControllerApi - fetch parameter creator
 * @export
 */
export const DriverControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/driver/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(options: any = {}): FetchArgs {
      const localVarPath = `/driver/availability`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} taxId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(taxId: string, options: any = {}): FetchArgs {
      // verify required parameter 'taxId' is not null or undefined
      if (taxId === null || taxId === undefined) {
        throw new RequiredError('taxId', 'Required parameter taxId was null or undefined when calling checkTaxId.')
      }
      const localVarPath = `/driver/check-tax-id/{taxId}`.replace(`{${'taxId'}}`, encodeURIComponent(String(taxId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DriverDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/driver`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/driver`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllSimple(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/driver/simple`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByDriverStatus(statuses: string, options: any = {}): FetchArgs {
      // verify required parameter 'statuses' is not null or undefined
      if (statuses === null || statuses === undefined) {
        throw new RequiredError(
          'statuses',
          'Required parameter statuses was null or undefined when calling findByDriverStatus.'
        )
      }
      const localVarPath = `/driver/status/{statuses}`.replace(`{${'statuses'}}`, encodeURIComponent(String(statuses)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/driver/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntities(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getEntities.')
      }
      const localVarPath = `/driver/{id}/entities`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offDuty(options: any = {}): FetchArgs {
      const localVarPath = `/driver/off-duty`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onDuty(options: any = {}): FetchArgs {
      const localVarPath = `/driver/on-duty`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/driver/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DriverDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/driver/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAuthId(id: string, body: DriverDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateAuthId.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateAuthId.')
      }
      const localVarPath = `/driver/{id}/restore`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsTest(options: any = {}): FetchArgs {
      const localVarPath = `/driver/ws-test`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DriverControllerApi - functional programming interface
 * @export
 */
export const DriverControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(options?: any): (basePath?: string) => Promise<DriverAvailabilityDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).availability(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} taxId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(taxId: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).checkTaxId(taxId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DriverDTO, options?: any): (basePath?: string) => Promise<DriverViewDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<DriverGridItemDTO>> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllSimple(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DriverSimpleDTO>> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).findAllSimple(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByDriverStatus(statuses: string, options?: any): (basePath?: string) => Promise<Array<DriverStatusDTO>> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).findByDriverStatus(
        statuses,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<DriverViewDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntities(id: string, options?: any): (basePath?: string) => Promise<DriverEntitiesDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).getEntities(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offDuty(options?: any): (basePath?: string) => Promise<DriverAvailabilityDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).offDuty(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onDuty(options?: any): (basePath?: string) => Promise<DriverAvailabilityDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).onDuty(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<DriverNameDTO>> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DriverDTO, options?: any): (basePath?: string) => Promise<DriverViewDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAuthId(id: string, body: DriverDTO, options?: any): (basePath?: string) => Promise<DriverDTO> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).updateAuthId(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsTest(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DriverControllerApiFetchParamCreator(configuration).wsTest(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DriverControllerApi - factory interface
 * @export
 */
export const DriverControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return DriverControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availability(options?: any) {
      return DriverControllerApiFp(configuration).availability(options)(basePath)
    },
    /**
     *
     * @param {string} taxId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(taxId: string, options?: any) {
      return DriverControllerApiFp(configuration).checkTaxId(taxId, options)(basePath)
    },
    /**
     *
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: DriverDTO, options?: any) {
      return DriverControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return DriverControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAllSimple(filter?: string, sort?: string, options?: any) {
      return DriverControllerApiFp(configuration).findAllSimple(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByDriverStatus(statuses: string, options?: any) {
      return DriverControllerApiFp(configuration).findByDriverStatus(statuses, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return DriverControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntities(id: string, options?: any) {
      return DriverControllerApiFp(configuration).getEntities(id, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offDuty(options?: any) {
      return DriverControllerApiFp(configuration).offDuty(options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onDuty(options?: any) {
      return DriverControllerApiFp(configuration).onDuty(options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return DriverControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: DriverDTO, options?: any) {
      return DriverControllerApiFp(configuration).update(id, body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {DriverDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAuthId(id: string, body: DriverDTO, options?: any) {
      return DriverControllerApiFp(configuration).updateAuthId(id, body, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsTest(options?: any) {
      return DriverControllerApiFp(configuration).wsTest(options)(basePath)
    }
  }
}

/**
 * DriverControllerApi - object-oriented interface
 * @export
 * @class DriverControllerApi
 * @extends {BaseAPI}
 */
export class DriverControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public _delete(id: string, options?: any) {
    return DriverControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public availability(options?: any) {
    return DriverControllerApiFp(this.configuration).availability(options)(this.basePath)
  }

  /**
   *
   * @param {string} taxId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public checkTaxId(taxId: string, options?: any) {
    return DriverControllerApiFp(this.configuration).checkTaxId(taxId, options)(this.basePath)
  }

  /**
   *
   * @param {DriverDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public create(body: DriverDTO, options?: any) {
    return DriverControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return DriverControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public findAllSimple(filter?: string, sort?: string, options?: any) {
    return DriverControllerApiFp(this.configuration).findAllSimple(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} statuses
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public findByDriverStatus(statuses: string, options?: any) {
    return DriverControllerApiFp(this.configuration).findByDriverStatus(statuses, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public findById(id: string, options?: any) {
    return DriverControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public getEntities(id: string, options?: any) {
    return DriverControllerApiFp(this.configuration).getEntities(id, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public offDuty(options?: any) {
    return DriverControllerApiFp(this.configuration).offDuty(options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public onDuty(options?: any) {
    return DriverControllerApiFp(this.configuration).onDuty(options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return DriverControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {DriverDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public update(id: string, body: DriverDTO, options?: any) {
    return DriverControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {DriverDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public updateAuthId(id: string, body: DriverDTO, options?: any) {
    return DriverControllerApiFp(this.configuration).updateAuthId(id, body, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverControllerApi
   */
  public wsTest(options?: any) {
    return DriverControllerApiFp(this.configuration).wsTest(options)(this.basePath)
  }
}

/**
 * EquipmentControllerApi - fetch parameter creator
 * @export
 */
export const EquipmentControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/equipment/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: EquipmentDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/equipment`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'EquipmentDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/equipment`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/equipment/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/equipment/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: EquipmentDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/equipment/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'EquipmentDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * EquipmentControllerApi - functional programming interface
 * @export
 */
export const EquipmentControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: EquipmentDTO, options?: any): (basePath?: string) => Promise<EquipmentDTO> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<EquipmentDTO>> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<EquipmentDTO> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<EquipmentSearchDTO>> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: EquipmentDTO, options?: any): (basePath?: string) => Promise<EquipmentDTO> {
      const localVarFetchArgs = EquipmentControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * EquipmentControllerApi - factory interface
 * @export
 */
export const EquipmentControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return EquipmentControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: EquipmentDTO, options?: any) {
      return EquipmentControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return EquipmentControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return EquipmentControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return EquipmentControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {EquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: EquipmentDTO, options?: any) {
      return EquipmentControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * EquipmentControllerApi - object-oriented interface
 * @export
 * @class EquipmentControllerApi
 * @extends {BaseAPI}
 */
export class EquipmentControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public _delete(id: string, options?: any) {
    return EquipmentControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {EquipmentDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public create(body: EquipmentDTO, options?: any) {
    return EquipmentControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return EquipmentControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public findById(id: string, options?: any) {
    return EquipmentControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return EquipmentControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {EquipmentDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentControllerApi
   */
  public update(id: string, body: EquipmentDTO, options?: any) {
    return EquipmentControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * FileControllerApi - fetch parameter creator
 * @export
 */
export const FileControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {'VENDOR_LICENSE' | 'VENDOR_LICENSE_BACK' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE'} type
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetVendorFiles(
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_LICENSE_BACK'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE',
      body: Array<string>,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling resetVendorFiles.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling resetVendorFiles.')
      }
      const localVarPath = `/file/reset`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {'VENDOR_LICENSE' | 'VENDOR_LICENSE_BACK' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE'} type
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetVendorFiles(
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_LICENSE_BACK'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE',
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).resetVendorFiles(type, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {'VENDOR_LICENSE' | 'VENDOR_LICENSE_BACK' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE'} type
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetVendorFiles(
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_LICENSE_BACK'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE',
      body: Array<string>,
      options?: any
    ) {
      return FileControllerApiFp(configuration).resetVendorFiles(type, body, options)(basePath)
    }
  }
}

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
  /**
   *
   * @param {'VENDOR_LICENSE' | 'VENDOR_LICENSE_BACK' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE'} type
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public resetVendorFiles(
    type:
      | 'VENDOR_LICENSE'
      | 'VENDOR_LICENSE_BACK'
      | 'VENDOR_MEDICAL_EXAM'
      | 'VENDOR_TWIC'
      | 'VENDOR_PORT'
      | 'VENDOR_W4'
      | 'VENDOR_W9'
      | 'VENDOR_I9'
      | 'VENDOR_APPLICATION'
      | 'VENDOR_LEASE'
      | 'VENDOR_PROOF_EIN_TAX_ID'
      | 'MACHINERY_REGISTRATION'
      | 'MACHINERY_DOT_SAFETY_INSPECTION'
      | 'MACHINERY_INSURANCE_COVERAGE',
    body: Array<string>,
    options?: any
  ) {
    return FileControllerApiFp(this.configuration).resetVendorFiles(type, body, options)(this.basePath)
  }
}

/**
 * FuelPriceControllerApi - fetch parameter creator
 * @export
 */
export const FuelPriceControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFuelPrice(options: any = {}): FetchArgs {
      const localVarPath = `/fuel-price`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FuelPriceControllerApi - functional programming interface
 * @export
 */
export const FuelPriceControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFuelPrice(options?: any): (basePath?: string) => Promise<FuelPrice> {
      const localVarFetchArgs = FuelPriceControllerApiFetchParamCreator(configuration).getFuelPrice(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * FuelPriceControllerApi - factory interface
 * @export
 */
export const FuelPriceControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFuelPrice(options?: any) {
      return FuelPriceControllerApiFp(configuration).getFuelPrice(options)(basePath)
    }
  }
}

/**
 * FuelPriceControllerApi - object-oriented interface
 * @export
 * @class FuelPriceControllerApi
 * @extends {BaseAPI}
 */
export class FuelPriceControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FuelPriceControllerApi
   */
  public getFuelPrice(options?: any) {
    return FuelPriceControllerApiFp(this.configuration).getFuelPrice(options)(this.basePath)
  }
}

/**
 * HazmatControllerApi - fetch parameter creator
 * @export
 */
export const HazmatControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/hazmat`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/hazmat/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/hazmat/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * HazmatControllerApi - functional programming interface
 * @export
 */
export const HazmatControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<HazmatDTO>> {
      const localVarFetchArgs = HazmatControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<HazmatDTO> {
      const localVarFetchArgs = HazmatControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<HazmatDTO>> {
      const localVarFetchArgs = HazmatControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * HazmatControllerApi - factory interface
 * @export
 */
export const HazmatControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return HazmatControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return HazmatControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return HazmatControllerApiFp(configuration).search(limit, term, options)(basePath)
    }
  }
}

/**
 * HazmatControllerApi - object-oriented interface
 * @export
 * @class HazmatControllerApi
 * @extends {BaseAPI}
 */
export class HazmatControllerApi extends BaseAPI {
  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HazmatControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return HazmatControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HazmatControllerApi
   */
  public findById(id: string, options?: any) {
    return HazmatControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HazmatControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return HazmatControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }
}

/**
 * IndexControllerApi - fetch parameter creator
 * @export
 */
export const IndexControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options: any = {}): FetchArgs {
      const localVarPath = `/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * IndexControllerApi - functional programming interface
 * @export
 */
export const IndexControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = IndexControllerApiFetchParamCreator(configuration).get(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * IndexControllerApi - factory interface
 * @export
 */
export const IndexControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any) {
      return IndexControllerApiFp(configuration).get(options)(basePath)
    }
  }
}

/**
 * IndexControllerApi - object-oriented interface
 * @export
 * @class IndexControllerApi
 * @extends {BaseAPI}
 */
export class IndexControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexControllerApi
   */
  public get(options?: any) {
    return IndexControllerApiFp(this.configuration).get(options)(this.basePath)
  }
}

/**
 * JobOrderControllerApi - fetch parameter creator
 * @export
 */
export const JobOrderControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} cargoReferenceNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackingDataByCargoReferenceNumber(cargoReferenceNumber: string, options: any = {}): FetchArgs {
      // verify required parameter 'cargoReferenceNumber' is not null or undefined
      if (cargoReferenceNumber === null || cargoReferenceNumber === undefined) {
        throw new RequiredError(
          'cargoReferenceNumber',
          'Required parameter cargoReferenceNumber was null or undefined when calling getTrackingDataByCargoReferenceNumber.'
        )
      }
      const localVarPath = `/job-order/{cargoReferenceNumber}/tracking-data`.replace(
        `{${'cargoReferenceNumber'}}`,
        encodeURIComponent(String(cargoReferenceNumber))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * JobOrderControllerApi - functional programming interface
 * @export
 */
export const JobOrderControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} cargoReferenceNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackingDataByCargoReferenceNumber(
      cargoReferenceNumber: string,
      options?: any
    ): (basePath?: string) => Promise<JobOrderTrackingDataDTO> {
      const localVarFetchArgs = JobOrderControllerApiFetchParamCreator(
        configuration
      ).getTrackingDataByCargoReferenceNumber(cargoReferenceNumber, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * JobOrderControllerApi - factory interface
 * @export
 */
export const JobOrderControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} cargoReferenceNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackingDataByCargoReferenceNumber(cargoReferenceNumber: string, options?: any) {
      return JobOrderControllerApiFp(configuration).getTrackingDataByCargoReferenceNumber(
        cargoReferenceNumber,
        options
      )(basePath)
    }
  }
}

/**
 * JobOrderControllerApi - object-oriented interface
 * @export
 * @class JobOrderControllerApi
 * @extends {BaseAPI}
 */
export class JobOrderControllerApi extends BaseAPI {
  /**
   *
   * @param {string} cargoReferenceNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobOrderControllerApi
   */
  public getTrackingDataByCargoReferenceNumber(cargoReferenceNumber: string, options?: any) {
    return JobOrderControllerApiFp(this.configuration).getTrackingDataByCargoReferenceNumber(
      cargoReferenceNumber,
      options
    )(this.basePath)
  }
}

/**
 * LocationControllerApi - fetch parameter creator
 * @export
 */
export const LocationControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/location/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: LocationDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/location`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'LocationDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/location`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/location/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridLocations(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/location/grid`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/location/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: LocationDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/location/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'LocationDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * LocationControllerApi - functional programming interface
 * @export
 */
export const LocationControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: LocationDTO, options?: any): (basePath?: string) => Promise<LocationViewDTO> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<LocationViewDTO>> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<LocationViewDTO> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridLocations(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<LocationGridItemDTO>> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).getGridLocations(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<LocationNameDTO>> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: LocationDTO, options?: any): (basePath?: string) => Promise<LocationViewDTO> {
      const localVarFetchArgs = LocationControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * LocationControllerApi - factory interface
 * @export
 */
export const LocationControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return LocationControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: LocationDTO, options?: any) {
      return LocationControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return LocationControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return LocationControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGridLocations(filter?: string, sort?: string, options?: any) {
      return LocationControllerApiFp(configuration).getGridLocations(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return LocationControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {LocationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: LocationDTO, options?: any) {
      return LocationControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * LocationControllerApi - object-oriented interface
 * @export
 * @class LocationControllerApi
 * @extends {BaseAPI}
 */
export class LocationControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public _delete(id: string, options?: any) {
    return LocationControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {LocationDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public create(body: LocationDTO, options?: any) {
    return LocationControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return LocationControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public findById(id: string, options?: any) {
    return LocationControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public getGridLocations(filter?: string, sort?: string, options?: any) {
    return LocationControllerApiFp(this.configuration).getGridLocations(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return LocationControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {LocationDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationControllerApi
   */
  public update(id: string, body: LocationDTO, options?: any) {
    return LocationControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * MoveApiControllerApi - fetch parameter creator
 * @export
 */
export const MoveApiControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignBobtail(fromId: string, toId: string, options: any = {}): FetchArgs {
      // verify required parameter 'fromId' is not null or undefined
      if (fromId === null || fromId === undefined) {
        throw new RequiredError('fromId', 'Required parameter fromId was null or undefined when calling assignBobtail.')
      }
      // verify required parameter 'toId' is not null or undefined
      if (toId === null || toId === undefined) {
        throw new RequiredError('toId', 'Required parameter toId was null or undefined when calling assignBobtail.')
      }
      const localVarPath = `/moves/activity/{fromId}/assign-bobtail/{toId}`
        .replace(`{${'fromId'}}`, encodeURIComponent(String(fromId)))
        .replace(`{${'toId'}}`, encodeURIComponent(String(toId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsLastDdoActivity(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling checkIsLastDdoActivity.'
        )
      }
      const localVarPath = `/moves/activity/{id}/is-last`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} authUserId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVendorAccess(authUserId: string, id: string, options: any = {}): FetchArgs {
      // verify required parameter 'authUserId' is not null or undefined
      if (authUserId === null || authUserId === undefined) {
        throw new RequiredError(
          'authUserId',
          'Required parameter authUserId was null or undefined when calling checkVendorAccess.'
        )
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling checkVendorAccess.')
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/check-vendor-access/{authUserId}`
        .replace(`{${'authUserId'}}`, encodeURIComponent(String(authUserId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeActivity(date: DateISOString, id: string, userName: string, options: any = {}): FetchArgs {
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError('date', 'Required parameter date was null or undefined when calling completeActivity.')
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling completeActivity.')
      }
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          'userName',
          'Required parameter userName was null or undefined when calling completeActivity.'
        )
      }
      const localVarPath = `/moves/activity/{id}/complete/{date}`
        .replace(`{${'date'}}`, encodeURIComponent(String(date)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userName !== undefined) {
        localVarQueryParameter['userName'] = userName
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyEquipment(fromId: string, toId: string, options: any = {}): FetchArgs {
      // verify required parameter 'fromId' is not null or undefined
      if (fromId === null || fromId === undefined) {
        throw new RequiredError('fromId', 'Required parameter fromId was null or undefined when calling copyEquipment.')
      }
      // verify required parameter 'toId' is not null or undefined
      if (toId === null || toId === undefined) {
        throw new RequiredError('toId', 'Required parameter toId was null or undefined when calling copyEquipment.')
      }
      const localVarPath = `/moves/dispatch-delivery-order/{fromId}/copy-equipment/{toId}`
        .replace(`{${'fromId'}}`, encodeURIComponent(String(fromId)))
        .replace(`{${'toId'}}`, encodeURIComponent(String(toId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivity(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getActivity.')
      }
      const localVarPath = `/moves/activity/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsCompletedByNumber(activityNumber: number, ddoId: string, options: any = {}): FetchArgs {
      // verify required parameter 'activityNumber' is not null or undefined
      if (activityNumber === null || activityNumber === undefined) {
        throw new RequiredError(
          'activityNumber',
          'Required parameter activityNumber was null or undefined when calling getActivityIsCompletedByNumber.'
        )
      }
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError(
          'ddoId',
          'Required parameter ddoId was null or undefined when calling getActivityIsCompletedByNumber.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{ddoId}/activity/{activityNumber}/is-completed`
        .replace(`{${'activityNumber'}}`, encodeURIComponent(String(activityNumber)))
        .replace(`{${'ddoId'}}`, encodeURIComponent(String(ddoId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsTransportationByNumber(activityNumber: number, ddoId: string, options: any = {}): FetchArgs {
      // verify required parameter 'activityNumber' is not null or undefined
      if (activityNumber === null || activityNumber === undefined) {
        throw new RequiredError(
          'activityNumber',
          'Required parameter activityNumber was null or undefined when calling getActivityIsTransportationByNumber.'
        )
      }
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError(
          'ddoId',
          'Required parameter ddoId was null or undefined when calling getActivityIsTransportationByNumber.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{ddoId}/activity/{activityNumber}/is-transportation`
        .replace(`{${'activityNumber'}}`, encodeURIComponent(String(activityNumber)))
        .replace(`{${'ddoId'}}`, encodeURIComponent(String(ddoId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityList(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling getActivityList.')
      }
      const localVarPath = `/moves/activity/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityTroubleTicketList(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getActivityTroubleTicketList.'
        )
      }
      const localVarPath = `/moves/activity/trouble-ticket-list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDdoStreetTurnLinkedDdoId(
      ddoId: string,
      deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError(
          'ddoId',
          'Required parameter ddoId was null or undefined when calling getDdoStreetTurnLinkedDdoId.'
        )
      }
      // verify required parameter 'deliveryOrderType' is not null or undefined
      if (deliveryOrderType === null || deliveryOrderType === undefined) {
        throw new RequiredError(
          'deliveryOrderType',
          'Required parameter deliveryOrderType was null or undefined when calling getDdoStreetTurnLinkedDdoId.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{ddoId}/street-turn/linked-ddo-id`.replace(
        `{${'ddoId'}}`,
        encodeURIComponent(String(ddoId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (deliveryOrderType !== undefined) {
        localVarQueryParameter['deliveryOrderType'] = deliveryOrderType
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrder(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getDispatchDeliveryOrder.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderEquipmentList(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getDispatchDeliveryOrderEquipmentList.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/equipment/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<Filter>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderIdList(body: Array<Filter>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getDispatchDeliveryOrderIdList.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order-id/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'Filter[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderList(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getDispatchDeliveryOrderList.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderNumber(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getDispatchDeliveryOrderNumber.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/number`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderStatus(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getDispatchDeliveryOrderStatus.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/status`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullActivityList(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getFullActivityList.'
        )
      }
      const localVarPath = `/moves/activity/list/full`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildData(ddoId: string, options: any = {}): FetchArgs {
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError(
          'ddoId',
          'Required parameter ddoId was null or undefined when calling getMovesBuildData.'
        )
      }
      const localVarPath = `/moves/build-data/{ddo-id}`.replace(`{${'ddo-id'}}`, encodeURIComponent(String(ddoId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildDocumentationActivitiesByDdo(ddoId: string, options: any = {}): FetchArgs {
      // verify required parameter 'ddoId' is not null or undefined
      if (ddoId === null || ddoId === undefined) {
        throw new RequiredError(
          'ddoId',
          'Required parameter ddoId was null or undefined when calling getMovesBuildDocumentationActivitiesByDdo.'
        )
      }
      const localVarPath = `/moves/build-data/ddo/{ddo-id}/documentation-activity/list`.replace(
        `{${'ddo-id'}}`,
        encodeURIComponent(String(ddoId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildTransportationActivities(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling getMovesBuildTransportationActivities.'
        )
      }
      const localVarPath = `/moves/build-data/transportation-activity/list`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTroubleTicketDocuments(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getTroubleTicketDocuments.'
        )
      }
      const localVarPath = `/moves/activity/{id}/trouble-ticket-documents`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVendorUserId(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getVendorUserId.')
      }
      const localVarPath = `/moves/vendor/{id}/user-id`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesFrom(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling rollbackBobtailByActivitiesFrom.'
        )
      }
      const localVarPath = `/moves/activity/rollback-bobtail-by-activities-from`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesTo(body: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling rollbackBobtailByActivitiesTo.'
        )
      }
      const localVarPath = `/moves/activity/rollback-bobtail-by-activities-to`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startActivity(date: DateISOString, id: string, userName: string, options: any = {}): FetchArgs {
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError('date', 'Required parameter date was null or undefined when calling startActivity.')
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling startActivity.')
      }
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          'userName',
          'Required parameter userName was null or undefined when calling startActivity.'
        )
      }
      const localVarPath = `/moves/activity/{id}/start/{date}`
        .replace(`{${'date'}}`, encodeURIComponent(String(date)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userName !== undefined) {
        localVarQueryParameter['userName'] = userName
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivitiesStatus(
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      body: Array<string>,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError(
          'status',
          'Required parameter status was null or undefined when calling updateActivitiesStatus.'
        )
      }
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          'userName',
          'Required parameter userName was null or undefined when calling updateActivitiesStatus.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateActivitiesStatus.'
        )
      }
      const localVarPath = `/moves/activity/update-status/{status}`.replace(
        `{${'status'}}`,
        encodeURIComponent(String(status))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userName !== undefined) {
        localVarQueryParameter['userName'] = userName
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityActualDates(date: DateISOString, id: string, userName: string, options: any = {}): FetchArgs {
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError(
          'date',
          'Required parameter date was null or undefined when calling updateActivityActualDates.'
        )
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateActivityActualDates.'
        )
      }
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          'userName',
          'Required parameter userName was null or undefined when calling updateActivityActualDates.'
        )
      }
      const localVarPath = `/moves/activity/{id}/actual-date/{date}`
        .replace(`{${'date'}}`, encodeURIComponent(String(date)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userName !== undefined) {
        localVarQueryParameter['userName'] = userName
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityStatus(
      id: string,
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateActivityStatus.')
      }
      // verify required parameter 'status' is not null or undefined
      if (status === null || status === undefined) {
        throw new RequiredError(
          'status',
          'Required parameter status was null or undefined when calling updateActivityStatus.'
        )
      }
      // verify required parameter 'userName' is not null or undefined
      if (userName === null || userName === undefined) {
        throw new RequiredError(
          'userName',
          'Required parameter userName was null or undefined when calling updateActivityStatus.'
        )
      }
      const localVarPath = `/moves/activity/{id}/update-status/{status}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'status'}}`, encodeURIComponent(String(status)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userName !== undefined) {
        localVarQueryParameter['userName'] = userName
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderContainer(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderContainerDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateDispatchDeliveryOrderContainer.'
        )
      }
      // verify required parameter 'vendorUserId' is not null or undefined
      if (vendorUserId === null || vendorUserId === undefined) {
        throw new RequiredError(
          'vendorUserId',
          'Required parameter vendorUserId was null or undefined when calling updateDispatchDeliveryOrderContainer.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateDispatchDeliveryOrderContainer.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/container`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorUserId !== undefined) {
        localVarQueryParameter['vendorUserId'] = vendorUserId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'MoveDispatchDeliveryOrderContainerDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderEquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderEquipment(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderEquipmentDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateDispatchDeliveryOrderEquipment.'
        )
      }
      // verify required parameter 'vendorUserId' is not null or undefined
      if (vendorUserId === null || vendorUserId === undefined) {
        throw new RequiredError(
          'vendorUserId',
          'Required parameter vendorUserId was null or undefined when calling updateDispatchDeliveryOrderEquipment.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateDispatchDeliveryOrderEquipment.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/equipment`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorUserId !== undefined) {
        localVarQueryParameter['vendorUserId'] = vendorUserId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'MoveDispatchDeliveryOrderEquipmentDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderWeightDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderWeight(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderWeightDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateDispatchDeliveryOrderWeight.'
        )
      }
      // verify required parameter 'vendorUserId' is not null or undefined
      if (vendorUserId === null || vendorUserId === undefined) {
        throw new RequiredError(
          'vendorUserId',
          'Required parameter vendorUserId was null or undefined when calling updateDispatchDeliveryOrderWeight.'
        )
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateDispatchDeliveryOrderWeight.'
        )
      }
      const localVarPath = `/moves/dispatch-delivery-order/{id}/weight`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (vendorUserId !== undefined) {
        localVarQueryParameter['vendorUserId'] = vendorUserId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'MoveDispatchDeliveryOrderWeightDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * MoveApiControllerApi - functional programming interface
 * @export
 */
export const MoveApiControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignBobtail(fromId: string, toId: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).assignBobtail(
        fromId,
        toId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsLastDdoActivity(id: string, options?: any): (basePath?: string) => Promise<BooleanDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).checkIsLastDdoActivity(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} authUserId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVendorAccess(authUserId: string, id: string, options?: any): (basePath?: string) => Promise<BooleanDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).checkVendorAccess(
        authUserId,
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeActivity(
      date: DateISOString,
      id: string,
      userName: string,
      options?: any
    ): (basePath?: string) => Promise<MoveItemActivityDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).completeActivity(
        date,
        id,
        userName,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyEquipment(fromId: string, toId: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).copyEquipment(
        fromId,
        toId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivity(id: string, options?: any): (basePath?: string) => Promise<MoveItemActivityDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getActivity(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsCompletedByNumber(
      activityNumber: number,
      ddoId: string,
      options?: any
    ): (basePath?: string) => Promise<BooleanDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getActivityIsCompletedByNumber(
        activityNumber,
        ddoId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsTransportationByNumber(
      activityNumber: number,
      ddoId: string,
      options?: any
    ): (basePath?: string) => Promise<BooleanDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).getActivityIsTransportationByNumber(activityNumber, ddoId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityList(body: Array<string>, options?: any): (basePath?: string) => Promise<Array<MoveItemActivityDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getActivityList(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityTroubleTicketList(
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Array<TroubleTicketDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getActivityTroubleTicketList(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDdoStreetTurnLinkedDdoId(
      ddoId: string,
      deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDdoStreetTurnLinkedDdoId(
        ddoId,
        deliveryOrderType,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrder(
      id: string,
      options?: any
    ): (basePath?: string) => Promise<MoveDispatchDeliveryOrderDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDispatchDeliveryOrder(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderEquipmentList(
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Array<MoveDispatchDeliveryOrderEquipmentMapDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).getDispatchDeliveryOrderEquipmentList(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<Filter>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderIdList(body: Array<Filter>, options?: any): (basePath?: string) => Promise<Array<string>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDispatchDeliveryOrderIdList(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderList(
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Array<MoveDispatchDeliveryOrderDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDispatchDeliveryOrderList(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderNumber(id: string, options?: any): (basePath?: string) => Promise<number> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDispatchDeliveryOrderNumber(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderStatus(id: string, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getDispatchDeliveryOrderStatus(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullActivityList(
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Array<MoveItemActivityDetailsDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getFullActivityList(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildData(ddoId: string, options?: any): (basePath?: string) => Promise<MoveBuildDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getMovesBuildData(ddoId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildDocumentationActivitiesByDdo(
      ddoId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<MoveBuildDocumentationActivityDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).getMovesBuildDocumentationActivitiesByDdo(ddoId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildTransportationActivities(
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Array<MoveBuildTransportationActivityDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).getMovesBuildTransportationActivities(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTroubleTicketDocuments(id: string, options?: any): (basePath?: string) => Promise<Array<TroubleTicketDTO>> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getTroubleTicketDocuments(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVendorUserId(id: string, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).getVendorUserId(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesFrom(body: Array<string>, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).rollbackBobtailByActivitiesFrom(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesTo(body: Array<string>, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).rollbackBobtailByActivitiesTo(
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startActivity(
      date: DateISOString,
      id: string,
      userName: string,
      options?: any
    ): (basePath?: string) => Promise<MoveItemActivityDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).startActivity(
        date,
        id,
        userName,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivitiesStatus(
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      body: Array<string>,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).updateActivitiesStatus(
        status,
        userName,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityActualDates(
      date: DateISOString,
      id: string,
      userName: string,
      options?: any
    ): (basePath?: string) => Promise<MoveItemActivityDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).updateActivityActualDates(
        date,
        id,
        userName,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityStatus(
      id: string,
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      options?: any
    ): (basePath?: string) => Promise<MoveItemActivityDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).updateActivityStatus(
        id,
        status,
        userName,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderContainer(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderContainerDTO,
      options?: any
    ): (basePath?: string) => Promise<MoveDispatchDeliveryOrderDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).updateDispatchDeliveryOrderContainer(id, vendorUserId, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderEquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderEquipment(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderEquipmentDTO,
      options?: any
    ): (basePath?: string) => Promise<MoveDispatchDeliveryOrderDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(
        configuration
      ).updateDispatchDeliveryOrderEquipment(id, vendorUserId, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderWeightDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderWeight(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderWeightDTO,
      options?: any
    ): (basePath?: string) => Promise<MoveDispatchDeliveryOrderDetailsDTO> {
      const localVarFetchArgs = MoveApiControllerApiFetchParamCreator(configuration).updateDispatchDeliveryOrderWeight(
        id,
        vendorUserId,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * MoveApiControllerApi - factory interface
 * @export
 */
export const MoveApiControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignBobtail(fromId: string, toId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).assignBobtail(fromId, toId, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsLastDdoActivity(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).checkIsLastDdoActivity(id, options)(basePath)
    },
    /**
     *
     * @param {string} authUserId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkVendorAccess(authUserId: string, id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).checkVendorAccess(authUserId, id, options)(basePath)
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeActivity(date: DateISOString, id: string, userName: string, options?: any) {
      return MoveApiControllerApiFp(configuration).completeActivity(date, id, userName, options)(basePath)
    },
    /**
     *
     * @param {string} fromId
     * @param {string} toId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyEquipment(fromId: string, toId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).copyEquipment(fromId, toId, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivity(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getActivity(id, options)(basePath)
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsCompletedByNumber(activityNumber: number, ddoId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getActivityIsCompletedByNumber(activityNumber, ddoId, options)(
        basePath
      )
    },
    /**
     *
     * @param {number} activityNumber
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityIsTransportationByNumber(activityNumber: number, ddoId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getActivityIsTransportationByNumber(activityNumber, ddoId, options)(
        basePath
      )
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityList(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getActivityList(body, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityTroubleTicketList(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getActivityTroubleTicketList(body, options)(basePath)
    },
    /**
     *
     * @param {string} ddoId
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDdoStreetTurnLinkedDdoId(ddoId: string, deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION', options?: any) {
      return MoveApiControllerApiFp(configuration).getDdoStreetTurnLinkedDdoId(ddoId, deliveryOrderType, options)(
        basePath
      )
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrder(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrder(id, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderEquipmentList(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrderEquipmentList(body, options)(basePath)
    },
    /**
     *
     * @param {Array<Filter>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderIdList(body: Array<Filter>, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrderIdList(body, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderList(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrderList(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderNumber(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrderNumber(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDispatchDeliveryOrderStatus(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getDispatchDeliveryOrderStatus(id, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFullActivityList(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getFullActivityList(body, options)(basePath)
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildData(ddoId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getMovesBuildData(ddoId, options)(basePath)
    },
    /**
     *
     * @param {string} ddoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildDocumentationActivitiesByDdo(ddoId: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getMovesBuildDocumentationActivitiesByDdo(ddoId, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovesBuildTransportationActivities(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).getMovesBuildTransportationActivities(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTroubleTicketDocuments(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getTroubleTicketDocuments(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVendorUserId(id: string, options?: any) {
      return MoveApiControllerApiFp(configuration).getVendorUserId(id, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesFrom(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).rollbackBobtailByActivitiesFrom(body, options)(basePath)
    },
    /**
     *
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rollbackBobtailByActivitiesTo(body: Array<string>, options?: any) {
      return MoveApiControllerApiFp(configuration).rollbackBobtailByActivitiesTo(body, options)(basePath)
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startActivity(date: DateISOString, id: string, userName: string, options?: any) {
      return MoveApiControllerApiFp(configuration).startActivity(date, id, userName, options)(basePath)
    },
    /**
     *
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {Array<string>} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivitiesStatus(
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      body: Array<string>,
      options?: any
    ) {
      return MoveApiControllerApiFp(configuration).updateActivitiesStatus(status, userName, body, options)(basePath)
    },
    /**
     *
     * @param {DateISOString} date
     * @param {string} id
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityActualDates(date: DateISOString, id: string, userName: string, options?: any) {
      return MoveApiControllerApiFp(configuration).updateActivityActualDates(date, id, userName, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateActivityStatus(
      id: string,
      status:
        | 'NEW'
        | 'DRIVER_PLANNED'
        | 'DRIVER_REFUSED'
        | 'DRIVER_ASSIGNED'
        | 'DRIVER_CONFIRMED'
        | 'IN_PROCESS'
        | 'COMPLETED'
        | 'UNSUCCESSFUL',
      userName: string,
      options?: any
    ) {
      return MoveApiControllerApiFp(configuration).updateActivityStatus(id, status, userName, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderContainerDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderContainer(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderContainerDTO,
      options?: any
    ) {
      return MoveApiControllerApiFp(configuration).updateDispatchDeliveryOrderContainer(
        id,
        vendorUserId,
        body,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderEquipmentDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderEquipment(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderEquipmentDTO,
      options?: any
    ) {
      return MoveApiControllerApiFp(configuration).updateDispatchDeliveryOrderEquipment(
        id,
        vendorUserId,
        body,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} vendorUserId
     * @param {MoveDispatchDeliveryOrderWeightDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDispatchDeliveryOrderWeight(
      id: string,
      vendorUserId: string,
      body: MoveDispatchDeliveryOrderWeightDTO,
      options?: any
    ) {
      return MoveApiControllerApiFp(configuration).updateDispatchDeliveryOrderWeight(id, vendorUserId, body, options)(
        basePath
      )
    }
  }
}

/**
 * MoveApiControllerApi - object-oriented interface
 * @export
 * @class MoveApiControllerApi
 * @extends {BaseAPI}
 */
export class MoveApiControllerApi extends BaseAPI {
  /**
   *
   * @param {string} fromId
   * @param {string} toId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public assignBobtail(fromId: string, toId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).assignBobtail(fromId, toId, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public checkIsLastDdoActivity(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).checkIsLastDdoActivity(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} authUserId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public checkVendorAccess(authUserId: string, id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).checkVendorAccess(authUserId, id, options)(this.basePath)
  }

  /**
   *
   * @param {DateISOString} date
   * @param {string} id
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public completeActivity(date: DateISOString, id: string, userName: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).completeActivity(date, id, userName, options)(this.basePath)
  }

  /**
   *
   * @param {string} fromId
   * @param {string} toId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public copyEquipment(fromId: string, toId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).copyEquipment(fromId, toId, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getActivity(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getActivity(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} activityNumber
   * @param {string} ddoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getActivityIsCompletedByNumber(activityNumber: number, ddoId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getActivityIsCompletedByNumber(activityNumber, ddoId, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {number} activityNumber
   * @param {string} ddoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getActivityIsTransportationByNumber(activityNumber: number, ddoId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getActivityIsTransportationByNumber(
      activityNumber,
      ddoId,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getActivityList(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getActivityList(body, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getActivityTroubleTicketList(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getActivityTroubleTicketList(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} ddoId
   * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDdoStreetTurnLinkedDdoId(
    ddoId: string,
    deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).getDdoStreetTurnLinkedDdoId(ddoId, deliveryOrderType, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrder(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrder(id, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrderEquipmentList(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrderEquipmentList(body, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {Array<Filter>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrderIdList(body: Array<Filter>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrderIdList(body, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrderList(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrderList(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrderNumber(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrderNumber(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getDispatchDeliveryOrderStatus(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getDispatchDeliveryOrderStatus(id, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getFullActivityList(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getFullActivityList(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} ddoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getMovesBuildData(ddoId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getMovesBuildData(ddoId, options)(this.basePath)
  }

  /**
   *
   * @param {string} ddoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getMovesBuildDocumentationActivitiesByDdo(ddoId: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getMovesBuildDocumentationActivitiesByDdo(ddoId, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getMovesBuildTransportationActivities(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getMovesBuildTransportationActivities(body, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getTroubleTicketDocuments(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getTroubleTicketDocuments(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public getVendorUserId(id: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).getVendorUserId(id, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public rollbackBobtailByActivitiesFrom(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).rollbackBobtailByActivitiesFrom(body, options)(this.basePath)
  }

  /**
   *
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public rollbackBobtailByActivitiesTo(body: Array<string>, options?: any) {
    return MoveApiControllerApiFp(this.configuration).rollbackBobtailByActivitiesTo(body, options)(this.basePath)
  }

  /**
   *
   * @param {DateISOString} date
   * @param {string} id
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public startActivity(date: DateISOString, id: string, userName: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).startActivity(date, id, userName, options)(this.basePath)
  }

  /**
   *
   * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
   * @param {string} userName
   * @param {Array<string>} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateActivitiesStatus(
    status:
      | 'NEW'
      | 'DRIVER_PLANNED'
      | 'DRIVER_REFUSED'
      | 'DRIVER_ASSIGNED'
      | 'DRIVER_CONFIRMED'
      | 'IN_PROCESS'
      | 'COMPLETED'
      | 'UNSUCCESSFUL',
    userName: string,
    body: Array<string>,
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).updateActivitiesStatus(status, userName, body, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {DateISOString} date
   * @param {string} id
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateActivityActualDates(date: DateISOString, id: string, userName: string, options?: any) {
    return MoveApiControllerApiFp(this.configuration).updateActivityActualDates(date, id, userName, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} id
   * @param {'NEW' | 'DRIVER_PLANNED' | 'DRIVER_REFUSED' | 'DRIVER_ASSIGNED' | 'DRIVER_CONFIRMED' | 'IN_PROCESS' | 'COMPLETED' | 'UNSUCCESSFUL'} status
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateActivityStatus(
    id: string,
    status:
      | 'NEW'
      | 'DRIVER_PLANNED'
      | 'DRIVER_REFUSED'
      | 'DRIVER_ASSIGNED'
      | 'DRIVER_CONFIRMED'
      | 'IN_PROCESS'
      | 'COMPLETED'
      | 'UNSUCCESSFUL',
    userName: string,
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).updateActivityStatus(id, status, userName, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {string} vendorUserId
   * @param {MoveDispatchDeliveryOrderContainerDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateDispatchDeliveryOrderContainer(
    id: string,
    vendorUserId: string,
    body: MoveDispatchDeliveryOrderContainerDTO,
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).updateDispatchDeliveryOrderContainer(
      id,
      vendorUserId,
      body,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {string} vendorUserId
   * @param {MoveDispatchDeliveryOrderEquipmentDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateDispatchDeliveryOrderEquipment(
    id: string,
    vendorUserId: string,
    body: MoveDispatchDeliveryOrderEquipmentDTO,
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).updateDispatchDeliveryOrderEquipment(
      id,
      vendorUserId,
      body,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {string} vendorUserId
   * @param {MoveDispatchDeliveryOrderWeightDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MoveApiControllerApi
   */
  public updateDispatchDeliveryOrderWeight(
    id: string,
    vendorUserId: string,
    body: MoveDispatchDeliveryOrderWeightDTO,
    options?: any
  ) {
    return MoveApiControllerApiFp(this.configuration).updateDispatchDeliveryOrderWeight(
      id,
      vendorUserId,
      body,
      options
    )(this.basePath)
  }
}

/**
 * NonPlannedActivityControllerApi - fetch parameter creator
 * @export
 */
export const NonPlannedActivityControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStageCompletedNoReturned(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/non-planned-activity-ddo/delivery-stage-completed-no-return`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtDeliveryLocation(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/non-planned-activity-ddo/dropped-at-delivery-location`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtYard(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/non-planned-activity-ddo/dropped-at-yard`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonPlannedActivityCounts(options: any = {}): FetchArgs {
      const localVarPath = `/non-planned-activity-ddo/counts`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPickedUpNoDelivery(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/non-planned-activity-ddo/picked-up-no-delivery`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * NonPlannedActivityControllerApi - functional programming interface
 * @export
 */
export const NonPlannedActivityControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStageCompletedNoReturned(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = NonPlannedActivityControllerApiFetchParamCreator(
        configuration
      ).getDeliveryStageCompletedNoReturned(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtDeliveryLocation(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = NonPlannedActivityControllerApiFetchParamCreator(
        configuration
      ).getDroppedAtDeliveryLocation(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtYard(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = NonPlannedActivityControllerApiFetchParamCreator(configuration).getDroppedAtYard(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonPlannedActivityCounts(options?: any): (basePath?: string) => Promise<NonPlannedActivityCountDTO> {
      const localVarFetchArgs = NonPlannedActivityControllerApiFetchParamCreator(
        configuration
      ).getNonPlannedActivityCounts(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPickedUpNoDelivery(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DispatchDeliveryOrderGridItemDTO>> {
      const localVarFetchArgs = NonPlannedActivityControllerApiFetchParamCreator(configuration).getPickedUpNoDelivery(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * NonPlannedActivityControllerApi - factory interface
 * @export
 */
export const NonPlannedActivityControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStageCompletedNoReturned(filter?: string, sort?: string, options?: any) {
      return NonPlannedActivityControllerApiFp(configuration).getDeliveryStageCompletedNoReturned(
        filter,
        sort,
        options
      )(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtDeliveryLocation(filter?: string, sort?: string, options?: any) {
      return NonPlannedActivityControllerApiFp(configuration).getDroppedAtDeliveryLocation(filter, sort, options)(
        basePath
      )
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDroppedAtYard(filter?: string, sort?: string, options?: any) {
      return NonPlannedActivityControllerApiFp(configuration).getDroppedAtYard(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonPlannedActivityCounts(options?: any) {
      return NonPlannedActivityControllerApiFp(configuration).getNonPlannedActivityCounts(options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPickedUpNoDelivery(filter?: string, sort?: string, options?: any) {
      return NonPlannedActivityControllerApiFp(configuration).getPickedUpNoDelivery(filter, sort, options)(basePath)
    }
  }
}

/**
 * NonPlannedActivityControllerApi - object-oriented interface
 * @export
 * @class NonPlannedActivityControllerApi
 * @extends {BaseAPI}
 */
export class NonPlannedActivityControllerApi extends BaseAPI {
  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonPlannedActivityControllerApi
   */
  public getDeliveryStageCompletedNoReturned(filter?: string, sort?: string, options?: any) {
    return NonPlannedActivityControllerApiFp(this.configuration).getDeliveryStageCompletedNoReturned(
      filter,
      sort,
      options
    )(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonPlannedActivityControllerApi
   */
  public getDroppedAtDeliveryLocation(filter?: string, sort?: string, options?: any) {
    return NonPlannedActivityControllerApiFp(this.configuration).getDroppedAtDeliveryLocation(filter, sort, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonPlannedActivityControllerApi
   */
  public getDroppedAtYard(filter?: string, sort?: string, options?: any) {
    return NonPlannedActivityControllerApiFp(this.configuration).getDroppedAtYard(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonPlannedActivityControllerApi
   */
  public getNonPlannedActivityCounts(options?: any) {
    return NonPlannedActivityControllerApiFp(this.configuration).getNonPlannedActivityCounts(options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonPlannedActivityControllerApi
   */
  public getPickedUpNoDelivery(filter?: string, sort?: string, options?: any) {
    return NonPlannedActivityControllerApiFp(this.configuration).getPickedUpNoDelivery(filter, sort, options)(
      this.basePath
    )
  }
}

/**
 * OrganizationControllerApi - fetch parameter creator
 * @export
 */
export const OrganizationControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/organization/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: OrganizationDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/organization`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'OrganizationDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/organization`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/organization/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: OrganizationDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/organization/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'OrganizationDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
export const OrganizationControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = OrganizationControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: OrganizationDTO, options?: any): (basePath?: string) => Promise<OrganizationViewDTO> {
      const localVarFetchArgs = OrganizationControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<OrganizationViewDTO>> {
      const localVarFetchArgs = OrganizationControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<OrganizationViewDTO> {
      const localVarFetchArgs = OrganizationControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: OrganizationDTO, options?: any): (basePath?: string) => Promise<OrganizationViewDTO> {
      const localVarFetchArgs = OrganizationControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * OrganizationControllerApi - factory interface
 * @export
 */
export const OrganizationControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return OrganizationControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: OrganizationDTO, options?: any) {
      return OrganizationControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return OrganizationControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return OrganizationControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {OrganizationDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: OrganizationDTO, options?: any) {
      return OrganizationControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApi
   */
  public _delete(id: string, options?: any) {
    return OrganizationControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {OrganizationDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApi
   */
  public create(body: OrganizationDTO, options?: any) {
    return OrganizationControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return OrganizationControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApi
   */
  public findById(id: string, options?: any) {
    return OrganizationControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {OrganizationDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApi
   */
  public update(id: string, body: OrganizationDTO, options?: any) {
    return OrganizationControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * PowerUnitControllerApi - fetch parameter creator
 * @export
 */
export const PowerUnitControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/power-unit/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(vin: string, options: any = {}): FetchArgs {
      // verify required parameter 'vin' is not null or undefined
      if (vin === null || vin === undefined) {
        throw new RequiredError('vin', 'Required parameter vin was null or undefined when calling checkTaxId.')
      }
      const localVarPath = `/power-unit/check-vin/{vin}`.replace(`{${'vin'}}`, encodeURIComponent(String(vin)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: PowerUnitDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/power-unit`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'PowerUnitDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/power-unit`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/power-unit/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/power-unit/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: PowerUnitDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/power-unit/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'PowerUnitDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PowerUnitControllerApi - functional programming interface
 * @export
 */
export const PowerUnitControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(vin: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).checkTaxId(vin, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: PowerUnitDTO, options?: any): (basePath?: string) => Promise<PowerUnitViewDTO> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<PowerUnitViewDTO>> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<PowerUnitViewDTO> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<PowerUnitSearchDTO>> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: PowerUnitDTO, options?: any): (basePath?: string) => Promise<PowerUnitViewDTO> {
      const localVarFetchArgs = PowerUnitControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * PowerUnitControllerApi - factory interface
 * @export
 */
export const PowerUnitControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return PowerUnitControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTaxId(vin: string, options?: any) {
      return PowerUnitControllerApiFp(configuration).checkTaxId(vin, options)(basePath)
    },
    /**
     *
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: PowerUnitDTO, options?: any) {
      return PowerUnitControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return PowerUnitControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return PowerUnitControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return PowerUnitControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {PowerUnitDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: PowerUnitDTO, options?: any) {
      return PowerUnitControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * PowerUnitControllerApi - object-oriented interface
 * @export
 * @class PowerUnitControllerApi
 * @extends {BaseAPI}
 */
export class PowerUnitControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public _delete(id: string, options?: any) {
    return PowerUnitControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public checkTaxId(vin: string, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).checkTaxId(vin, options)(this.basePath)
  }

  /**
   *
   * @param {PowerUnitDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public create(body: PowerUnitDTO, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public findById(id: string, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {PowerUnitDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PowerUnitControllerApi
   */
  public update(id: string, body: PowerUnitDTO, options?: any) {
    return PowerUnitControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * SellSideQuoteControllerApi - fetch parameter creator
 * @export
 */
export const SellSideQuoteControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {SsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: SsqRateDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling applyRate.')
      }
      const localVarPath = `/sell-side-quote/apply-rate`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SsqRateDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/sell-side-quote/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {SellSideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SellSideQuoteDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/sell-side-quote/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SellSideQuoteDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * SellSideQuoteControllerApi - functional programming interface
 * @export
 */
export const SellSideQuoteControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {SsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: SsqRateDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SellSideQuoteControllerApiFetchParamCreator(configuration).applyRate(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<SellSideQuoteDTO> {
      const localVarFetchArgs = SellSideQuoteControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {SellSideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SellSideQuoteDTO, options?: any): (basePath?: string) => Promise<SellSideQuoteDTO> {
      const localVarFetchArgs = SellSideQuoteControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * SellSideQuoteControllerApi - factory interface
 * @export
 */
export const SellSideQuoteControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {SsqRateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyRate(body: SsqRateDTO, options?: any) {
      return SellSideQuoteControllerApiFp(configuration).applyRate(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return SellSideQuoteControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {SellSideQuoteDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SellSideQuoteDTO, options?: any) {
      return SellSideQuoteControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * SellSideQuoteControllerApi - object-oriented interface
 * @export
 * @class SellSideQuoteControllerApi
 * @extends {BaseAPI}
 */
export class SellSideQuoteControllerApi extends BaseAPI {
  /**
   *
   * @param {SsqRateDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SellSideQuoteControllerApi
   */
  public applyRate(body: SsqRateDTO, options?: any) {
    return SellSideQuoteControllerApiFp(this.configuration).applyRate(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SellSideQuoteControllerApi
   */
  public findById(id: string, options?: any) {
    return SellSideQuoteControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {SellSideQuoteDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SellSideQuoteControllerApi
   */
  public update(id: string, body: SellSideQuoteDTO, options?: any) {
    return SellSideQuoteControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * StateControllerApi - fetch parameter creator
 * @export
 */
export const StateControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options: any = {}): FetchArgs {
      const localVarPath = `/state`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * StateControllerApi - functional programming interface
 * @export
 */
export const StateControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options?: any): (basePath?: string) => Promise<Array<StateDTO>> {
      const localVarFetchArgs = StateControllerApiFetchParamCreator(configuration).findAll(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * StateControllerApi - factory interface
 * @export
 */
export const StateControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(options?: any) {
      return StateControllerApiFp(configuration).findAll(options)(basePath)
    }
  }
}

/**
 * StateControllerApi - object-oriented interface
 * @export
 * @class StateControllerApi
 * @extends {BaseAPI}
 */
export class StateControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StateControllerApi
   */
  public findAll(options?: any) {
    return StateControllerApiFp(this.configuration).findAll(options)(this.basePath)
  }
}

/**
 * SteamShipLineControllerApi - fetch parameter creator
 * @export
 */
export const SteamShipLineControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/steam-ship-line/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SteamShipLineDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/steam-ship-line`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SteamShipLineDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/steam-ship-line`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/steam-ship-line/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options: any = {}): FetchArgs {
      const localVarPath = `/steam-ship-line/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SteamShipLineDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/steam-ship-line/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SteamShipLineDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * SteamShipLineControllerApi - functional programming interface
 * @export
 */
export const SteamShipLineControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SteamShipLineDTO, options?: any): (basePath?: string) => Promise<SteamShipLineViewDTO> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<SteamShipLineViewDTO>> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<SteamShipLineViewDTO> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any): (basePath?: string) => Promise<Array<SteamShipLineNameDTO>> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration).search(limit, term, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SteamShipLineDTO, options?: any): (basePath?: string) => Promise<SteamShipLineViewDTO> {
      const localVarFetchArgs = SteamShipLineControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * SteamShipLineControllerApi - factory interface
 * @export
 */
export const SteamShipLineControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return SteamShipLineControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SteamShipLineDTO, options?: any) {
      return SteamShipLineControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return SteamShipLineControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return SteamShipLineControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(limit?: number, term?: string, options?: any) {
      return SteamShipLineControllerApiFp(configuration).search(limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {SteamShipLineDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SteamShipLineDTO, options?: any) {
      return SteamShipLineControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * SteamShipLineControllerApi - object-oriented interface
 * @export
 * @class SteamShipLineControllerApi
 * @extends {BaseAPI}
 */
export class SteamShipLineControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public _delete(id: string, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {SteamShipLineDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public create(body: SteamShipLineDTO, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public findById(id: string, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public search(limit?: number, term?: string, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration).search(limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {SteamShipLineDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SteamShipLineControllerApi
   */
  public update(id: string, body: SteamShipLineDTO, options?: any) {
    return SteamShipLineControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * SubClientControllerApi - fetch parameter creator
 * @export
 */
export const SubClientControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/sub-client/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SubClientDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/sub-client`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SubClientDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/sub-client`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/sub-client/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} customerId
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(customerId: string, limit?: number, term?: string, options: any = {}): FetchArgs {
      // verify required parameter 'customerId' is not null or undefined
      if (customerId === null || customerId === undefined) {
        throw new RequiredError(
          'customerId',
          'Required parameter customerId was null or undefined when calling search.'
        )
      }
      const localVarPath = `/sub-client/search`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (customerId !== undefined) {
        localVarQueryParameter['customerId'] = customerId
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SubClientDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/sub-client/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'SubClientDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * SubClientControllerApi - functional programming interface
 * @export
 */
export const SubClientControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SubClientDTO, options?: any): (basePath?: string) => Promise<SubClientViewDTO> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<SubClientViewDTO>> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<SubClientViewDTO> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} customerId
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      customerId: string,
      limit?: number,
      term?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<SubClientSearchDTO>> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration).search(
        customerId,
        limit,
        term,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SubClientDTO, options?: any): (basePath?: string) => Promise<SubClientViewDTO> {
      const localVarFetchArgs = SubClientControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * SubClientControllerApi - factory interface
 * @export
 */
export const SubClientControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return SubClientControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: SubClientDTO, options?: any) {
      return SubClientControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return SubClientControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return SubClientControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} customerId
     * @param {number} [limit]
     * @param {string} [term]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(customerId: string, limit?: number, term?: string, options?: any) {
      return SubClientControllerApiFp(configuration).search(customerId, limit, term, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {SubClientDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: SubClientDTO, options?: any) {
      return SubClientControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * SubClientControllerApi - object-oriented interface
 * @export
 * @class SubClientControllerApi
 * @extends {BaseAPI}
 */
export class SubClientControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public _delete(id: string, options?: any) {
    return SubClientControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {SubClientDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public create(body: SubClientDTO, options?: any) {
    return SubClientControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return SubClientControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public findById(id: string, options?: any) {
    return SubClientControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} customerId
   * @param {number} [limit]
   * @param {string} [term]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public search(customerId: string, limit?: number, term?: string, options?: any) {
    return SubClientControllerApiFp(this.configuration).search(customerId, limit, term, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {SubClientDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubClientControllerApi
   */
  public update(id: string, body: SubClientDTO, options?: any) {
    return SubClientControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * TerminalMismatchControllerApi - fetch parameter creator
 * @export
 */
export const TerminalMismatchControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalMismatches(dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling getTerminalMismatches.'
        )
      }
      const localVarPath = `/terminal-mismatch/{dispatchDeliveryOrderId}`.replace(
        `{${'dispatchDeliveryOrderId'}}`,
        encodeURIComponent(String(dispatchDeliveryOrderId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TerminalMismatchControllerApi - functional programming interface
 * @export
 */
export const TerminalMismatchControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalMismatches(
      dispatchDeliveryOrderId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<TerminalMismatchDTO>> {
      const localVarFetchArgs = TerminalMismatchControllerApiFetchParamCreator(configuration).getTerminalMismatches(
        dispatchDeliveryOrderId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * TerminalMismatchControllerApi - factory interface
 * @export
 */
export const TerminalMismatchControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalMismatches(dispatchDeliveryOrderId: string, options?: any) {
      return TerminalMismatchControllerApiFp(configuration).getTerminalMismatches(dispatchDeliveryOrderId, options)(
        basePath
      )
    }
  }
}

/**
 * TerminalMismatchControllerApi - object-oriented interface
 * @export
 * @class TerminalMismatchControllerApi
 * @extends {BaseAPI}
 */
export class TerminalMismatchControllerApi extends BaseAPI {
  /**
   *
   * @param {string} dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TerminalMismatchControllerApi
   */
  public getTerminalMismatches(dispatchDeliveryOrderId: string, options?: any) {
    return TerminalMismatchControllerApiFp(this.configuration).getTerminalMismatches(dispatchDeliveryOrderId, options)(
      this.basePath
    )
  }
}

/**
 * TerminalNoteControllerApi - fetch parameter creator
 * @export
 */
export const TerminalNoteControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalNotes(dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling getTerminalNotes.'
        )
      }
      const localVarPath = `/terminal-note/{dispatchDeliveryOrderId}`.replace(
        `{${'dispatchDeliveryOrderId'}}`,
        encodeURIComponent(String(dispatchDeliveryOrderId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TerminalNoteControllerApi - functional programming interface
 * @export
 */
export const TerminalNoteControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalNotes(
      dispatchDeliveryOrderId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<TerminalNoteDTO>> {
      const localVarFetchArgs = TerminalNoteControllerApiFetchParamCreator(configuration).getTerminalNotes(
        dispatchDeliveryOrderId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * TerminalNoteControllerApi - factory interface
 * @export
 */
export const TerminalNoteControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminalNotes(dispatchDeliveryOrderId: string, options?: any) {
      return TerminalNoteControllerApiFp(configuration).getTerminalNotes(dispatchDeliveryOrderId, options)(basePath)
    }
  }
}

/**
 * TerminalNoteControllerApi - object-oriented interface
 * @export
 * @class TerminalNoteControllerApi
 * @extends {BaseAPI}
 */
export class TerminalNoteControllerApi extends BaseAPI {
  /**
   *
   * @param {string} dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TerminalNoteControllerApi
   */
  public getTerminalNotes(dispatchDeliveryOrderId: string, options?: any) {
    return TerminalNoteControllerApiFp(this.configuration).getTerminalNotes(dispatchDeliveryOrderId, options)(
      this.basePath
    )
  }
}

/**
 * TransportationActivityControllerApi - fetch parameter creator
 * @export
 */
export const TransportationActivityControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/transportation-activity/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/transportation-activity`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/transportation-activity/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {'LIVE_LOAD' | 'DROP_AND_PICK'} loadType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(
      deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
      loadType: 'LIVE_LOAD' | 'DROP_AND_PICK',
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'deliveryOrderType' is not null or undefined
      if (deliveryOrderType === null || deliveryOrderType === undefined) {
        throw new RequiredError(
          'deliveryOrderType',
          'Required parameter deliveryOrderType was null or undefined when calling getTemplate.'
        )
      }
      // verify required parameter 'loadType' is not null or undefined
      if (loadType === null || loadType === undefined) {
        throw new RequiredError(
          'loadType',
          'Required parameter loadType was null or undefined when calling getTemplate.'
        )
      }
      const localVarPath = `/transportation-activity/template`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (deliveryOrderType !== undefined) {
        localVarQueryParameter['deliveryOrderType'] = deliveryOrderType
      }

      if (loadType !== undefined) {
        localVarQueryParameter['loadType'] = loadType
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TransportationActivityControllerApi - functional programming interface
 * @export
 */
export const TransportationActivityControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = TransportationActivityControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(
      filter?: string,
      sort?: string,
      options?: any
    ): (basePath?: string) => Promise<Array<TransportationActivityViewDTO>> {
      const localVarFetchArgs = TransportationActivityControllerApiFetchParamCreator(configuration).findAll(
        filter,
        sort,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<TransportationActivityViewDTO> {
      const localVarFetchArgs = TransportationActivityControllerApiFetchParamCreator(configuration).findById(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {'LIVE_LOAD' | 'DROP_AND_PICK'} loadType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(
      deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
      loadType: 'LIVE_LOAD' | 'DROP_AND_PICK',
      options?: any
    ): (basePath?: string) => Promise<Array<TransportationActivityDTO>> {
      const localVarFetchArgs = TransportationActivityControllerApiFetchParamCreator(configuration).getTemplate(
        deliveryOrderType,
        loadType,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * TransportationActivityControllerApi - factory interface
 * @export
 */
export const TransportationActivityControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return TransportationActivityControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return TransportationActivityControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return TransportationActivityControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
     * @param {'LIVE_LOAD' | 'DROP_AND_PICK'} loadType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplate(
      deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
      loadType: 'LIVE_LOAD' | 'DROP_AND_PICK',
      options?: any
    ) {
      return TransportationActivityControllerApiFp(configuration).getTemplate(deliveryOrderType, loadType, options)(
        basePath
      )
    }
  }
}

/**
 * TransportationActivityControllerApi - object-oriented interface
 * @export
 * @class TransportationActivityControllerApi
 * @extends {BaseAPI}
 */
export class TransportationActivityControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransportationActivityControllerApi
   */
  public _delete(id: string, options?: any) {
    return TransportationActivityControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransportationActivityControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return TransportationActivityControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransportationActivityControllerApi
   */
  public findById(id: string, options?: any) {
    return TransportationActivityControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {'IMPORT' | 'EXPORT' | 'REPOSITION'} deliveryOrderType
   * @param {'LIVE_LOAD' | 'DROP_AND_PICK'} loadType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransportationActivityControllerApi
   */
  public getTemplate(
    deliveryOrderType: 'IMPORT' | 'EXPORT' | 'REPOSITION',
    loadType: 'LIVE_LOAD' | 'DROP_AND_PICK',
    options?: any
  ) {
    return TransportationActivityControllerApiFp(this.configuration).getTemplate(deliveryOrderType, loadType, options)(
      this.basePath
    )
  }
}

/**
 * TroubleTicketControllerApi - fetch parameter creator
 * @export
 */
export const TroubleTicketControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {TroubleTicketDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: TroubleTicketDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/trouble-ticket`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'TroubleTicketDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/trouble-ticket/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proceed(id: string, body: TroubleTicketUpdateDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling proceed.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling proceed.')
      }
      const localVarPath = `/trouble-ticket/{id}/proceed`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'TroubleTicketUpdateDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsuccessful(id: string, body: TroubleTicketUpdateDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling unsuccessful.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling unsuccessful.')
      }
      const localVarPath = `/trouble-ticket/{id}/unsuccessful`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'TroubleTicketUpdateDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TroubleTicketControllerApi - functional programming interface
 * @export
 */
export const TroubleTicketControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {TroubleTicketDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: TroubleTicketDTO, options?: any): (basePath?: string) => Promise<TroubleTicketDTO> {
      const localVarFetchArgs = TroubleTicketControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<TroubleTicketDTO> {
      const localVarFetchArgs = TroubleTicketControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proceed(id: string, body: TroubleTicketUpdateDTO, options?: any): (basePath?: string) => Promise<TroubleTicketDTO> {
      const localVarFetchArgs = TroubleTicketControllerApiFetchParamCreator(configuration).proceed(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsuccessful(
      id: string,
      body: TroubleTicketUpdateDTO,
      options?: any
    ): (basePath?: string) => Promise<TroubleTicketDTO> {
      const localVarFetchArgs = TroubleTicketControllerApiFetchParamCreator(configuration).unsuccessful(
        id,
        body,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * TroubleTicketControllerApi - factory interface
 * @export
 */
export const TroubleTicketControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {TroubleTicketDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: TroubleTicketDTO, options?: any) {
      return TroubleTicketControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return TroubleTicketControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    proceed(id: string, body: TroubleTicketUpdateDTO, options?: any) {
      return TroubleTicketControllerApiFp(configuration).proceed(id, body, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {TroubleTicketUpdateDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsuccessful(id: string, body: TroubleTicketUpdateDTO, options?: any) {
      return TroubleTicketControllerApiFp(configuration).unsuccessful(id, body, options)(basePath)
    }
  }
}

/**
 * TroubleTicketControllerApi - object-oriented interface
 * @export
 * @class TroubleTicketControllerApi
 * @extends {BaseAPI}
 */
export class TroubleTicketControllerApi extends BaseAPI {
  /**
   *
   * @param {TroubleTicketDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TroubleTicketControllerApi
   */
  public create(body: TroubleTicketDTO, options?: any) {
    return TroubleTicketControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TroubleTicketControllerApi
   */
  public findById(id: string, options?: any) {
    return TroubleTicketControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {TroubleTicketUpdateDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TroubleTicketControllerApi
   */
  public proceed(id: string, body: TroubleTicketUpdateDTO, options?: any) {
    return TroubleTicketControllerApiFp(this.configuration).proceed(id, body, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {TroubleTicketUpdateDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TroubleTicketControllerApi
   */
  public unsuccessful(id: string, body: TroubleTicketUpdateDTO, options?: any) {
    return TroubleTicketControllerApiFp(this.configuration).unsuccessful(id, body, options)(this.basePath)
  }
}

/**
 * UiSettingsControllerApi - fetch parameter creator
 * @export
 */
export const UiSettingsControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: UISettingsDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/ui-settings`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UISettingsDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAll(options: any = {}): FetchArgs {
      const localVarPath = `/ui-settings`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteByUserId(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling deleteByUserId.'
        )
      }
      const localVarPath = `/ui-settings/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByUserId(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling findByUserId.')
      }
      const localVarPath = `/ui-settings/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateByUserId(body: UISettingsDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateByUserId.')
      }
      const localVarPath = `/ui-settings`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'UISettingsDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * UiSettingsControllerApi - functional programming interface
 * @export
 */
export const UiSettingsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: UISettingsDTO, options?: any): (basePath?: string) => Promise<UISettingsDTO> {
      const localVarFetchArgs = UiSettingsControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAll(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = UiSettingsControllerApiFetchParamCreator(configuration).deleteAll(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteByUserId(userId: string, options?: any): (basePath?: string) => Promise<UISettingsDTO> {
      const localVarFetchArgs = UiSettingsControllerApiFetchParamCreator(configuration).deleteByUserId(userId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByUserId(userId: string, options?: any): (basePath?: string) => Promise<UISettingsDTO> {
      const localVarFetchArgs = UiSettingsControllerApiFetchParamCreator(configuration).findByUserId(userId, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateByUserId(body: UISettingsDTO, options?: any): (basePath?: string) => Promise<UISettingsDTO> {
      const localVarFetchArgs = UiSettingsControllerApiFetchParamCreator(configuration).updateByUserId(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * UiSettingsControllerApi - factory interface
 * @export
 */
export const UiSettingsControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: UISettingsDTO, options?: any) {
      return UiSettingsControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAll(options?: any) {
      return UiSettingsControllerApiFp(configuration).deleteAll(options)(basePath)
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteByUserId(userId: string, options?: any) {
      return UiSettingsControllerApiFp(configuration).deleteByUserId(userId, options)(basePath)
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByUserId(userId: string, options?: any) {
      return UiSettingsControllerApiFp(configuration).findByUserId(userId, options)(basePath)
    },
    /**
     *
     * @param {UISettingsDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateByUserId(body: UISettingsDTO, options?: any) {
      return UiSettingsControllerApiFp(configuration).updateByUserId(body, options)(basePath)
    }
  }
}

/**
 * UiSettingsControllerApi - object-oriented interface
 * @export
 * @class UiSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UiSettingsControllerApi extends BaseAPI {
  /**
   *
   * @param {UISettingsDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UiSettingsControllerApi
   */
  public create(body: UISettingsDTO, options?: any) {
    return UiSettingsControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UiSettingsControllerApi
   */
  public deleteAll(options?: any) {
    return UiSettingsControllerApiFp(this.configuration).deleteAll(options)(this.basePath)
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UiSettingsControllerApi
   */
  public deleteByUserId(userId: string, options?: any) {
    return UiSettingsControllerApiFp(this.configuration).deleteByUserId(userId, options)(this.basePath)
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UiSettingsControllerApi
   */
  public findByUserId(userId: string, options?: any) {
    return UiSettingsControllerApiFp(this.configuration).findByUserId(userId, options)(this.basePath)
  }

  /**
   *
   * @param {UISettingsDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UiSettingsControllerApi
   */
  public updateByUserId(body: UISettingsDTO, options?: any) {
    return UiSettingsControllerApiFp(this.configuration).updateByUserId(body, options)(this.basePath)
  }
}

/**
 * VendorControllerApi - fetch parameter creator
 * @export
 */
export const VendorControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.')
      }
      const localVarPath = `/vendor/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: VendorDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling create.')
      }
      const localVarPath = `/vendor`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'VendorDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options: any = {}): FetchArgs {
      const localVarPath = `/vendor`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling findById.')
      }
      const localVarPath = `/vendor/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} id
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: VendorDTO, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.')
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling update.')
      }
      const localVarPath = `/vendor/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'VendorDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * VendorControllerApi - functional programming interface
 * @export
 */
export const VendorControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration)._delete(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: VendorDTO, options?: any): (basePath?: string) => Promise<VendorViewDTO> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).create(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any): (basePath?: string) => Promise<Array<VendorViewDTO>> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).findAll(filter, sort, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any): (basePath?: string) => Promise<VendorViewDTO> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).findById(id, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} id
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: VendorDTO, options?: any): (basePath?: string) => Promise<VendorViewDTO> {
      const localVarFetchArgs = VendorControllerApiFetchParamCreator(configuration).update(id, body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * VendorControllerApi - factory interface
 * @export
 */
export const VendorControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: string, options?: any) {
      return VendorControllerApiFp(configuration)._delete(id, options)(basePath)
    },
    /**
     *
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: VendorDTO, options?: any) {
      return VendorControllerApiFp(configuration).create(body, options)(basePath)
    },
    /**
     *
     * @param {string} [filter]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAll(filter?: string, sort?: string, options?: any) {
      return VendorControllerApiFp(configuration).findAll(filter, sort, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findById(id: string, options?: any) {
      return VendorControllerApiFp(configuration).findById(id, options)(basePath)
    },
    /**
     *
     * @param {string} id
     * @param {VendorDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: string, body: VendorDTO, options?: any) {
      return VendorControllerApiFp(configuration).update(id, body, options)(basePath)
    }
  }
}

/**
 * VendorControllerApi - object-oriented interface
 * @export
 * @class VendorControllerApi
 * @extends {BaseAPI}
 */
export class VendorControllerApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public _delete(id: string, options?: any) {
    return VendorControllerApiFp(this.configuration)._delete(id, options)(this.basePath)
  }

  /**
   *
   * @param {VendorDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public create(body: VendorDTO, options?: any) {
    return VendorControllerApiFp(this.configuration).create(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} [filter]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public findAll(filter?: string, sort?: string, options?: any) {
    return VendorControllerApiFp(this.configuration).findAll(filter, sort, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public findById(id: string, options?: any) {
    return VendorControllerApiFp(this.configuration).findById(id, options)(this.basePath)
  }

  /**
   *
   * @param {string} id
   * @param {VendorDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VendorControllerApi
   */
  public update(id: string, body: VendorDTO, options?: any) {
    return VendorControllerApiFp(this.configuration).update(id, body, options)(this.basePath)
  }
}

/**
 * WorkOrderControllerApi - fetch parameter creator
 * @export
 */
export const WorkOrderControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmDriver(body: WorkOrderRefDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling confirmDriver.')
      }
      const localVarPath = `/work-order/confirm`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WorkOrderRefDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorOrderHistory(dispatchDeliveryOrderId: string, vendorId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling getWorOrderHistory.'
        )
      }
      // verify required parameter 'vendorId' is not null or undefined
      if (vendorId === null || vendorId === undefined) {
        throw new RequiredError(
          'vendorId',
          'Required parameter vendorId was null or undefined when calling getWorOrderHistory.'
        )
      }
      const localVarPath = `/work-order/history`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (dispatchDeliveryOrderId !== undefined) {
        localVarQueryParameter['dispatchDeliveryOrderId'] = dispatchDeliveryOrderId
      }

      if (vendorId !== undefined) {
        localVarQueryParameter['vendorId'] = vendorId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refuseDriver(body: WorkOrderRefDTO, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling refuseDriver.')
      }
      const localVarPath = `/work-order/refuse`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'WorkOrderRefDTO' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * WorkOrderControllerApi - functional programming interface
 * @export
 */
export const WorkOrderControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmDriver(body: WorkOrderRefDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = WorkOrderControllerApiFetchParamCreator(configuration).confirmDriver(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorOrderHistory(
      dispatchDeliveryOrderId: string,
      vendorId: string,
      options?: any
    ): (basePath?: string) => Promise<WorkOrderHistoryDTO> {
      const localVarFetchArgs = WorkOrderControllerApiFetchParamCreator(configuration).getWorOrderHistory(
        dispatchDeliveryOrderId,
        vendorId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refuseDriver(body: WorkOrderRefDTO, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = WorkOrderControllerApiFetchParamCreator(configuration).refuseDriver(body, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * WorkOrderControllerApi - factory interface
 * @export
 */
export const WorkOrderControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmDriver(body: WorkOrderRefDTO, options?: any) {
      return WorkOrderControllerApiFp(configuration).confirmDriver(body, options)(basePath)
    },
    /**
     *
     * @param {string} dispatchDeliveryOrderId
     * @param {string} vendorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorOrderHistory(dispatchDeliveryOrderId: string, vendorId: string, options?: any) {
      return WorkOrderControllerApiFp(configuration).getWorOrderHistory(dispatchDeliveryOrderId, vendorId, options)(
        basePath
      )
    },
    /**
     *
     * @param {WorkOrderRefDTO} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refuseDriver(body: WorkOrderRefDTO, options?: any) {
      return WorkOrderControllerApiFp(configuration).refuseDriver(body, options)(basePath)
    }
  }
}

/**
 * WorkOrderControllerApi - object-oriented interface
 * @export
 * @class WorkOrderControllerApi
 * @extends {BaseAPI}
 */
export class WorkOrderControllerApi extends BaseAPI {
  /**
   *
   * @param {WorkOrderRefDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderControllerApi
   */
  public confirmDriver(body: WorkOrderRefDTO, options?: any) {
    return WorkOrderControllerApiFp(this.configuration).confirmDriver(body, options)(this.basePath)
  }

  /**
   *
   * @param {string} dispatchDeliveryOrderId
   * @param {string} vendorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderControllerApi
   */
  public getWorOrderHistory(dispatchDeliveryOrderId: string, vendorId: string, options?: any) {
    return WorkOrderControllerApiFp(this.configuration).getWorOrderHistory(dispatchDeliveryOrderId, vendorId, options)(
      this.basePath
    )
  }

  /**
   *
   * @param {WorkOrderRefDTO} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkOrderControllerApi
   */
  public refuseDriver(body: WorkOrderRefDTO, options?: any) {
    return WorkOrderControllerApiFp(this.configuration).refuseDriver(body, options)(this.basePath)
  }
}

export type DateISOString = string // 2018-04-15T08:36:01.711Z
