import * as React from 'react'

export const RouteBuilderIcons = {
  timeLineArrowUp: (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 5L4 1.74846e-07L8 5L5 5L5 10L3 10L3 5L0 5Z" fill="white" />
    </svg>
  ),
  timeLineArrowDown: (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 5L4 10L0 5L3 5V0H5L5 5H8Z" fill="white" />
    </svg>
  ),
  doubleMoves: (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8682 16.2861L17.636 18.0539" stroke="#9CA8B8" strokeLinecap="round" />
      <path d="M17.7786 14.6006H19.7786" stroke="#9CA8B8" strokeLinecap="round" />
      <path d="M14.3592 18.0199V20.0199" stroke="#9CA8B8" strokeLinecap="round" />
      <path d="M8.29718 8.30078L6.52941 6.53301" stroke="#9CA8B8" strokeLinecap="round" />
      <path d="M6.38672 9.98645H4.38672" stroke="#9CA8B8" strokeLinecap="round" />
      <path d="M9.80615 6.56714V4.56714" stroke="#9CA8B8" strokeLinecap="round" />
      <path
        // tslint:disable:max-line-length
        d="M11.2721 8.15442L13.3934 6.0331C13.3934 6.0331 15.8683 3.55823 18.3432 6.0331C20.818 8.50797 18.3432 10.9828 18.3432 10.9828L16.2218 13.1042"
        stroke="#9CA8B8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        // tslint:disable:max-line-length
        d="M13.0399 16.2861L10.9185 18.4075C10.9185 18.4075 8.44366 20.8823 5.96879 18.4075C3.49392 15.9326 5.96879 13.4577 5.96879 13.4577L8.09011 11.3364"
        stroke="#9CA8B8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  shield: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9999 0.333252C7.49824 2.5723 4.20915 3.94961 0.599231 3.99859C0.468139 4.77952 0.399902 5.58176 0.399902 6.39994C0.399902 12.6698 4.40698 18.0037 9.9999 19.9805C15.5928 18.0037 19.5999 12.6698 19.5999 6.39994C19.5999 5.58176 19.5317 4.77952 19.4006 3.99859C15.7907 3.94961 12.5016 2.5723 9.9999 0.333252ZM11.1999 14.7999C11.1999 15.4626 10.6626 15.9999 9.9999 15.9999C9.33716 15.9999 8.7999 15.4626 8.7999 14.7999C8.7999 14.1372 9.33716 13.5999 9.9999 13.5999C10.6626 13.5999 11.1999 14.1372 11.1999 14.7999ZM11.1999 6.3999C11.1999 5.73716 10.6626 5.1999 9.9999 5.1999C9.33716 5.1999 8.7999 5.73716 8.7999 6.3999V9.9999C8.7999 10.6626 9.33716 11.1999 9.9999 11.1999C10.6626 11.1999 11.1999 10.6626 11.1999 9.9999V6.3999Z"
        fill="#F5A85F"
      />
    </svg>
  )
}
