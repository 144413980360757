import * as R from 'remeda'
import { oc } from 'ts-optchain'
import {
  ActivitiesDTO,
  ActivitiesViewDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { isNewObject } from '../index'
import { getStore } from '../../../store/configureStore'
import { showModal, TMsgType } from '../../../components/UI/Modal/actions'
import { AlertButtonColor } from '../../../components/UI/Modal'
import { ActivityGroup, DocumentationActivityGroup, TransportationActivityGroup } from '../activity/interfaces'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { DDOWorkingStatus } from '../activity/controller'
import { isBusinessActivity } from '../../functions/test/isBusinessActivity'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isUnsuccessfulActivityGroup } from '../../functions/test/isUnsuccessfulActivity'
import { getListsState } from '../../../store'
import { EntityType } from '../../../store/reducers/lists/interfaces'
import { isDDOStatusWorking } from '../../functions/test/isDDOStatusWorking'
import { filterUsefulActivityGroups } from '../../functions/filter/filterUsefulActivityGroups'

export const isChassisNumberRequired = (activities: ActivitiesDTO): boolean => {
  // required if completed activity is the last one of actyvity list
  // or
  // has particular type:
  // DROPCHASSIS | DROPEMPTYWITHOUTCHASSIS | DROPFULLWITHOUTCHASSIS

  if (!(activities && activities.transportationActivities)) {
    return false
  }

  const searchActivityTypes = [
    TransportationActivityViewDTO.TypeEnum.DROPCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ]
  const reversedTransportationActivities = activities.transportationActivities
    .slice()
    .sort((a, b) => b.number - a.number)
  const lastCompletedBusinessActivity = reversedTransportationActivities.find(activity => {
    return isBusinessActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
  })
  const isLastCompletedBusinessActivityLast = Boolean(
    reversedTransportationActivities.length &&
      lastCompletedBusinessActivity &&
      oc(reversedTransportationActivities)[0].id() === oc(lastCompletedBusinessActivity).id()
  )

  return Boolean(
    lastCompletedBusinessActivity &&
      (!searchActivityTypes.includes(lastCompletedBusinessActivity.type) || isLastCompletedBusinessActivityLast)
  )
}

export const findActivityWithWrongOrderOfPlannedDate = (
  activities: ActivityViewDTO[]
): TransportationActivityViewDTO => {
  const sortedActivities = activities
    .filter(
      activity =>
        activity.type !== DocumentationActivityDTO.TypeEnum.STREETTURN &&
        activity.startPlannedDateTimeRange &&
        activity.startPlannedDateTimeRange.from
    )
    .sort((a, b) => a.number - b.number) as TransportationActivityViewDTO[]

  return sortedActivities.find((activity, index, array) => {
    if (index === 0) {
      return false
    }

    const prevPlannedDate = array[index - 1].startPlannedDateTimeRange.from
    const currPlannedDate = activity.startPlannedDateTimeRange.from

    return prevPlannedDate > currPlannedDate
  })
}

const alertOnChangingMainStreetTurnProps = () =>
  getStore().dispatch(
    showModal({
      msgType: TMsgType.info,
      buttonSettings: {
        button1: {
          color: AlertButtonColor.blue,
          label: 'Ok',
          action: () => {}
        }
      },
      message: 'Street Turn will be canceled with changes on Container Type/SSL'
    })
  )

// export const shouldUpdateDispatchDeliveryOrderTabWithTroubleTicketByPinnedIds = (
//   tab: ITabState,
//   activeMainTabId: string
// ): boolean => {
//   const { visited, type, ignoreWS, id } = tab
//   return type === TabType.dispatchDeliveryOrder && !ignoreWS && (visited || activeMainTabId === id)
// }

export const alertOnChangingMainStreetTurnPropsOfDDO = (
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO,
  dispatchDeliveryOrderBeforeUpdate: DispatchDeliveryOrderViewDTO
) => {
  if (!dispatchDeliveryOrder || isNewObject(dispatchDeliveryOrder)) {
    return
  }

  const storeLists = getListsState()
  const streetTurn = storeLists[EntityType.streetTurn][oc(dispatchDeliveryOrder).streetTurnId()]

  if (
    oc(streetTurn).status() === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED ||
    oc(streetTurn).status() === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED
  ) {
    const storeDispatchDeliveryOrder = storeLists[EntityType.dispatchDeliveryOrder][dispatchDeliveryOrder.id]
    const containerTypeIdBeforeUpdate = dispatchDeliveryOrderBeforeUpdate.containerTypeId
    const containerTypeIdAfterUpdate = dispatchDeliveryOrder.containerTypeId

    if (
      storeDispatchDeliveryOrder &&
      containerTypeIdBeforeUpdate === storeDispatchDeliveryOrder.containerTypeId &&
      containerTypeIdAfterUpdate !== storeDispatchDeliveryOrder.containerTypeId
    ) {
      return alertOnChangingMainStreetTurnProps()
    }
  }
}

export const alertOnChangingMainStreetTurnPropsOfDO = (
  deliveryOrder: DeliveryOrderViewDTO,
  deliveryOrderBeforeUpdate: DeliveryOrderViewDTO
) => {
  const storeLists = getListsState()
  const storeDeliveryOrder = storeLists[EntityType.deliveryOrder][deliveryOrder.id]

  if (!storeDeliveryOrder || isNewObject(deliveryOrder)) {
    return
  }

  if (
    storeDeliveryOrder.steamShipLineId !== deliveryOrder.steamShipLineId &&
    deliveryOrderBeforeUpdate.steamShipLineId === storeDeliveryOrder.steamShipLineId &&
    oc(storeDeliveryOrder)
      .dispatchDeliveryOrderIds([])
      .some(ddoId => {
        const storeDDO = storeLists[EntityType.dispatchDeliveryOrder][ddoId]

        if (!storeDDO) {
          return false
        }

        const storeStreetTurnStatus =
          oc(storeLists[EntityType.streetTurn])[storeDDO.streetTurnId].status() || oc(storeDDO).streetTurn.status()

        return (
          storeStreetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED ||
          storeStreetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED
        )
      })
  ) {
    return alertOnChangingMainStreetTurnProps()
  }
}

export const checkDates = ({
  dispatchDeliveryOrder,
  deliveryOrder,
  updatedBefore,
  updatedAfter
}: {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  deliveryOrder: DeliveryOrderViewDTO
  updatedBefore?: string | null
  updatedAfter?: string | null
}): boolean => {
  let before
  let after

  if (deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
    before =
      updatedBefore === undefined
        ? oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange.from()
        : updatedBefore
    after = updatedAfter === undefined ? oc(deliveryOrder).generalCutoffDate() : updatedAfter
  } else {
    before =
      updatedBefore === undefined
        ? oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange.from()
        : updatedBefore
    after = updatedAfter === undefined ? oc(deliveryOrder).lastFreeDateDemurrage() : updatedAfter
  }

  return Boolean(after && before && new Date(before).setHours(0, 0, 0, 0) > new Date(after).setHours(0, 0, 0, 0))
}

export const showModalOnDateConfuse = (condition: boolean, message: string, cancel: () => void, save: () => void) => {
  if (condition) {
    getStore().dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.blue,
            label: 'Cancel',
            action: cancel
          },
          button2: {
            color: AlertButtonColor.yellow,
            label: 'OK',
            action: save
          }
        },
        message
      })
    )
  }
}

export const cleanDispatchDeliveryOrder = (
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
): DispatchDeliveryOrderViewDTO => {
  const omitFieldsOnStages: any[] = ['location', 'vendor']
  const omitDDOFields: (keyof DispatchDeliveryOrderViewDTO)[] = [
    'activityIds',
    'activities',
    'deliveryOrder',
    'container',
    'equipment',
    'progressBarState',
    'streetTurn',
    'streetTurnCount'
  ]

  return dispatchDeliveryOrder
    ? (R.omit(
        {
          ...dispatchDeliveryOrder,
          pickupStage: R.omit(dispatchDeliveryOrder.pickupStage || {}, omitFieldsOnStages),
          deliveryStage: R.omit(dispatchDeliveryOrder.deliveryStage || {}, omitFieldsOnStages),
          returnStage: R.omit(dispatchDeliveryOrder.returnStage || {}, omitFieldsOnStages)
        },
        omitDDOFields as any
      ) as DispatchDeliveryOrderViewDTO)
    : dispatchDeliveryOrder
}

export type InitialActivitiesCalculation = {
  transportationActivities: TransportationActivityViewDTO[]
  documentationActivities: DocumentationActivityDTO[]
  activityGroups?: ActivityGroup[]
  error: string
}

export const initialActivitiesCalculation = (initialActivities: ActivitiesViewDTO): InitialActivitiesCalculation => {
  let error: string

  const throwError = () => {
    return {
      transportationActivities: [] as TransportationActivityViewDTO[],
      documentationActivities: [] as DocumentationActivityDTO[],
      activityGroups: [] as ActivityGroup[],
      error
    }
  }

  // >>> check
  if (initialActivities.transportationActivities.length % 2 !== 0) {
    error = 'Incorrect Transportation Activity count'
    return throwError()
  }
  // <<<

  try {
    const activityByGroups: {
      [groupId: string]: TransportationActivityViewDTO[]
    } = oc(initialActivities)
      .transportationActivities([])
      .reduce(
        (acc, activity) => {
          if (acc[activity.groupId]) {
            if (isGotoActivity(activity)) {
              acc[activity.groupId].unshift(activity)
            } else {
              acc[activity.groupId].push(activity)
            }
          } else {
            acc[activity.groupId] = [activity]
          }

          // >>> check groupId
          if (!activity.groupId) {
            error = `Activity must have a groupId: DDO ID: ${activity.dispatchDeliveryOrderId}`
          }
          // <<<
          return acc
        },
        {} as Record<string, TransportationActivityViewDTO[]>
      )

    // >>> check
    if (error) {
      return throwError()
    }
    // <<<

    const activityGroupArray = Object.values(activityByGroups)

    // >>> check
    // activityGroupArray.forEach(activityGroup => {
    //   const gotoActivity = activityGroup[0]
    //   const businessActivity = activityGroup[1]
    //
    //   if (gotoActivity.activityNumber >= businessActivity.activityNumber) {
    //     error = `Incorrect Transportation Activity Numeration: gotoActivity# ${
    //       gotoActivity.activityNumber
    //     } <<<>>> businessActivity# ${businessActivity.activityNumber}`
    //   }
    // })
    //
    // if (error) {
    //   return throwError()
    // }
    // <<<

    const allActivityGroupArray: ActivityViewDTO[][] = activityGroupArray.concat(
      oc(initialActivities)
        .documentationActivities([])
        .map(documentationActivity => [documentationActivity as any])
    )
    const sortedAllActivityGroupArray: ActivityViewDTO[][] = allActivityGroupArray.sort(
      (a, b) => a[0].number - b[0].number
    )

    const { transportationActivities, activityGroups, documentationActivities } = updateActivityGroupsNumeration({
      activityGroupsArrays: sortedAllActivityGroupArray
    })

    return {
      transportationActivities,
      documentationActivities,
      activityGroups,
      error
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log(e)

    return throwError()
  }
}

export type CorrectActivityData = {
  activityGroups: ActivityGroup[]
  transportationActivities: TransportationActivityViewDTO[]
  documentationActivities: DocumentationActivityDTO[]
}

export const updateActivityGroupsNumeration = ({
  activityGroups,
  activityGroupsArrays
}: {
  activityGroups?: ActivityGroup[]
  activityGroupsArrays?: ActivityViewDTO[][]
}): CorrectActivityData => {
  let activityNumber = 0
  const transportationActivities: TransportationActivityViewDTO[] = []
  const documentationActivities: DocumentationActivityDTO[] = []
  const updatedActivityGroups: ActivityGroup[] = []

  const activityGroupsArray: ActivityViewDTO[][] = activityGroupsArrays
    ? activityGroupsArrays
    : activityGroups.map(
        group =>
          [
            (group as DocumentationActivityGroup).documentationActivity,
            (group as TransportationActivityGroup).gotoActivity,
            (group as TransportationActivityGroup).businessActivity
          ].filter(Boolean) as ActivityViewDTO[]
      )

  const sortedActivityGroupsByStage: Record<TransportationActivityViewDTO.StageEnum, ActivityViewDTO[][]> = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.PICKUP
    ),
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.DELIVERY
    ),
    [TransportationActivityViewDTO.StageEnum.RETURN]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.RETURN
    )
  }

  sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.PICKUP]
    .concat(
      sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.DELIVERY],
      sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.RETURN]
    )
    .forEach(group => {
      switch (group.length) {
        case 1: {
          // Documentation Activity
          const documentationActivity = { ...group[0], number: activityNumber }
          activityNumber++

          documentationActivities.push(documentationActivity as DocumentationActivityDTO)
          updatedActivityGroups.push({ documentationActivity } as DocumentationActivityGroup)
          break
        }
        case 2: {
          // GOTO + Business Activity
          const gotoActivity = { ...group[0], number: activityNumber }
          activityNumber++
          const businessActivity = { ...group[1], number: activityNumber }
          activityNumber++

          transportationActivities.push(
            gotoActivity as TransportationActivityViewDTO,
            businessActivity as TransportationActivityViewDTO
          )
          updatedActivityGroups.push({ gotoActivity, businessActivity } as TransportationActivityGroup)
          break
        }
        default: {
          return
        }
      }
    })

  return {
    activityGroups: updatedActivityGroups,
    transportationActivities,
    documentationActivities
  }
}

export const filterDocumentationTypeGroups = (activityGroups: ActivityGroup[]): DocumentationActivityGroup[] => {
  return activityGroups.filter(group => 'documentationActivity' in group) as DocumentationActivityGroup[]
}

export const getCurrentActivityGroup = (
  activityGroups: ActivityGroup[]
): {
  currentActivityGroup: TransportationActivityGroup
  currentActivityGroupIndex: number
  lastUnsuccessfulActivityGroup: TransportationActivityGroup
  lastUnsuccessfulActivityGroupIndex: number
} => {
  const currentActivityGroup = (filterUsefulActivityGroups(activityGroups, true) as TransportationActivityGroup[])
    .slice()
    .reverse()
    .find(
      activityGroup =>
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )
  let lastUnsuccessfulActivityGroup: TransportationActivityGroup = activityGroups
    .slice()
    .reverse()
    .find(group => isUnsuccessfulActivityGroup(group as any)) as TransportationActivityGroup

  if (
    !oc(lastUnsuccessfulActivityGroup).gotoActivity.id() ||
    !oc(lastUnsuccessfulActivityGroup).businessActivity.id()
  ) {
    lastUnsuccessfulActivityGroup = undefined
  }

  return {
    currentActivityGroup,
    currentActivityGroupIndex: currentActivityGroup
      ? activityGroups.findIndex(
          group => oc(group as TransportationActivityGroup).gotoActivity.id() === currentActivityGroup.gotoActivity.id
        )
      : -1,
    lastUnsuccessfulActivityGroup,
    lastUnsuccessfulActivityGroupIndex: lastUnsuccessfulActivityGroup
      ? activityGroups.findIndex(
          group =>
            oc(group as TransportationActivityGroup).gotoActivity.id() === lastUnsuccessfulActivityGroup.gotoActivity.id
        )
      : -1
  }
}

export const getStreetTurnStatus = (props: {
  streetTurn: DispatchDeliveryOrderStreetTurnDTO
  streetTurnCount: number
}): DispatchDeliveryOrderStreetTurnDTO.StatusEnum | undefined => {
  const { streetTurn, streetTurnCount } = props
  let result = undefined

  if (streetTurnCount) {
    result = DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE
  }

  if (streetTurn && streetTurn.status) {
    result = streetTurn.status
  }

  return result
}

export const getDDOWorkingStatus = (status: DispatchDeliveryOrderViewDTO.StatusEnum): DDOWorkingStatus => {
  switch (true) {
    case isDDOStatusWorking(status):
      return DDOWorkingStatus.working
    case [
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
      DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE
    ].includes(status):
      return DDOWorkingStatus.planning
    default:
      return DDOWorkingStatus.none
  }
}

export const isContainerRequiredForDDO = (
  doType: DeliveryOrderViewDTO.TypeEnum,
  ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
): boolean => {
  switch (doType) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return true
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
    case DeliveryOrderViewDTO.TypeEnum.REPOSITION:
      return (
        dispatchDeliveryOrderStatusIndex[ddoStatus] >=
          dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP] &&
        dispatchDeliveryOrderStatusIndex[ddoStatus] <
          dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]
      )
    default:
      return false
  }
}

type UserModelForDDO = {
  Status: any
  'Availability Date': any
  'Planned Appointment DateDateTimeRange': any
  'Actual Appointment Date': any
  'Per Diem Free By Date': any
  'Planned Pickup DateDateTimeRange': any
  'Actual Pickup Date': any
  'Load Type': any
  'Planned Return DateDateTimeRange': any
  'Actual Return Date': any
  'Cutoff Date': any
  'Planned Pick DateDateTimeRange': any
  'Actual Pick Date': any
  'First Receiving Date': any
  'Hazmat Cutoff Date': any
  'Last Free Date': any
  'Auto Cutoff Date': any
  'Reefer Cutoff Date': any
  'Spent (hours)': any
  Description: any
  'Pickup Location': any
  'Delivery Location': any
  'Return Location': any
  Customer: any
  Equipment: {
    'Container Type': any
    'Container #': any
    Seal: any
    'Chassis #': any
    'Chassis Pickup Date': any
    'Chassis Return Date': any
  }
  Cargo: {
    'Reference #': any
    Weight: any
    'Weight Unit': any
    Overweight: any
    Miles: any
    Description: any
    'Auto Indicator': any
    'Hazmat Indicator': any
    'Hazmat Description': any
  }
  Documents: {
    'Booking #': any
    'Bill of Landing #': any
  }
  SSL: {
    'SSL Name': any
    'Vessel Name': any
    'Voyage #': any
    'Vessel Departure (ETD)Date': any
  }
}

export const makeUserModelForDDO = (fullDispatchDeliveryOrder: DispatchDeliveryOrderViewDTO): UserModelForDDO => {
  const storeLists = getListsState()
  const isExport = oc(fullDispatchDeliveryOrder).deliveryOrder.type() === DeliveryOrderViewDTO.TypeEnum.EXPORT

  const uppercaseBooleanValue = (value: true | false) => {
    if (value !== true && value !== false) {
      return
    }

    return String(value).toUpperCase()
  }

  return {
    Status: oc(fullDispatchDeliveryOrder).status(),
    'Availability Date': oc(fullDispatchDeliveryOrder).deliveryOrder.equipmentFirstPickupDate(),
    'Planned Appointment DateDateTimeRange': oc(
      fullDispatchDeliveryOrder
    ).deliveryStage.plannedAppointmentDateTimeRange(),
    'Actual Appointment Date': oc(fullDispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
    'Per Diem Free By Date': oc(fullDispatchDeliveryOrder).deliveryOrder.lastFreeDatePerDiem(),
    'Planned Pickup DateDateTimeRange': oc(fullDispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange(),
    'Actual Pickup Date': oc(fullDispatchDeliveryOrder).pickupStage.actualAppointmentDate(),
    'Load Type': oc(fullDispatchDeliveryOrder).loadType(),
    'Planned Return DateDateTimeRange': oc(fullDispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange(),
    'Actual Return Date': oc(fullDispatchDeliveryOrder).returnStage.actualAppointmentDate(),
    'Cutoff Date': oc(fullDispatchDeliveryOrder).deliveryOrder.generalCutoffDate(),
    'Planned Pick DateDateTimeRange': oc(fullDispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
    'Actual Pick Date': oc(fullDispatchDeliveryOrder).deliveryStage.actualPickDate(),
    'First Receiving Date': oc(fullDispatchDeliveryOrder).deliveryOrder.firstReceivingDate(),
    'Hazmat Cutoff Date': oc(fullDispatchDeliveryOrder).deliveryOrder.hazmatCutoffDate(),
    'Last Free Date': oc(fullDispatchDeliveryOrder).deliveryOrder.lastFreeDateDemurrage(),
    'Auto Cutoff Date': oc(fullDispatchDeliveryOrder).deliveryOrder.autoCutoffDate(),
    'Reefer Cutoff Date': oc(fullDispatchDeliveryOrder).deliveryOrder.reeferCutoffDate(),
    'Spent (hours)': oc(fullDispatchDeliveryOrder).deliveryStage.spentTimeSpan(),
    'Pickup Location': oc(fullDispatchDeliveryOrder).pickupStage.location.name(),
    'Delivery Location': oc(fullDispatchDeliveryOrder).deliveryStage.location.name(),
    'Return Location': oc(fullDispatchDeliveryOrder).returnStage.location.name(),
    Description: oc(fullDispatchDeliveryOrder).description(),
    Customer: oc(fullDispatchDeliveryOrder).deliveryOrder.customer.name(),
    Equipment: {
      'Container Type': oc(storeLists[EntityType.containerType])[
        oc(fullDispatchDeliveryOrder).containerTypeId()
      ].name(),
      'Container #': oc(fullDispatchDeliveryOrder).container.number(),
      Seal: oc(fullDispatchDeliveryOrder).sealNumber(),
      'Chassis #': oc(fullDispatchDeliveryOrder).equipment.chassisNumber(),
      'Chassis Pickup Date': oc(fullDispatchDeliveryOrder).equipment.pickupDate(),
      'Chassis Return Date': oc(fullDispatchDeliveryOrder).equipment.returnDate()
    },
    Cargo: {
      'Reference #': oc(fullDispatchDeliveryOrder).deliveryOrder.cargo.referenceNumber(),
      Weight: oc(fullDispatchDeliveryOrder).weight(),
      'Weight Unit': oc(fullDispatchDeliveryOrder).weightUnit(),
      Overweight: oc(fullDispatchDeliveryOrder).overweightIndicator(),
      Miles: oc(fullDispatchDeliveryOrder).mileage(),
      Description: oc(fullDispatchDeliveryOrder).deliveryOrder.cargo.description(),
      'Auto Indicator': uppercaseBooleanValue(oc(fullDispatchDeliveryOrder).autoIndicator()),
      'Hazmat Indicator': uppercaseBooleanValue(oc(fullDispatchDeliveryOrder).hazmatIndicator()),
      'Hazmat Description': undefined
      // oc(fullDispatchDeliveryOrder).hazmat()
      //   ? [oc(fullDispatchDeliveryOrder).hazmat.code(), oc(fullDispatchDeliveryOrder).hazmat.description()]
      //       .filter(Boolean)
      //       .join(', ')
      //   : undefined
    },
    Documents: {
      'Booking #': oc(fullDispatchDeliveryOrder).deliveryOrder.bookingNumber(),
      'Bill of Landing #': oc(fullDispatchDeliveryOrder).deliveryOrder.billOfLadingNumber()
    },
    SSL: {
      'SSL Name': oc(fullDispatchDeliveryOrder).deliveryOrder.steamShipLine.name(),
      'Vessel Name': oc(fullDispatchDeliveryOrder).deliveryOrder.vesselName(),
      'Voyage #': oc(fullDispatchDeliveryOrder).deliveryOrder.voyageNumber(),
      'Vessel Departure (ETD)Date': oc(fullDispatchDeliveryOrder).deliveryOrder[
        isExport ? 'vesselDepartureDate' : 'vesselArrivalDate'
      ]()
    }
  }
}

export const dispatchDeliveryOrderStatusIndex: Record<DispatchDeliveryOrderViewDTO.StatusEnum, number> = (() =>
  [
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].reduce(
    (acc, currStatus, index) => {
      acc[currStatus] = index
      return acc
    },
    {
      '': -1,
      undefined: -1,
      null: -1
    } as Record<DispatchDeliveryOrderViewDTO.StatusEnum, number>
  ))()
