import { DeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'

type Props = {
  stage: TransportationActivityViewDTO.StageEnum
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  ddoStreetTurnId: string
}

export const isStageStreetTurn = (props: Props): boolean => {
  const { stage, deliveryOrderType, ddoStreetTurnId } = props

  switch (stage) {
    case TransportationActivityViewDTO.StageEnum.PICKUP: {
      return deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT && Boolean(ddoStreetTurnId)
    }
    case TransportationActivityViewDTO.StageEnum.DELIVERY:
    case TransportationActivityViewDTO.StageEnum.RETURN: {
      return deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT && Boolean(ddoStreetTurnId)
    }
    default:
      return false
  }
}
