import * as React from 'react'
import { ContactDTO } from '../api/api'

export interface IContactsTabContext {
  isModifiedMode: boolean
  contacts: ContactDTO[]
  primaryContact: ContactDTO | undefined
  createContact: () => void
  updateContactField: (contact: ContactDTO) => (prop: keyof ContactDTO) => (value: any) => void
  deleteContactById: (id: string) => void
}

export const ContactsTabContext = React.createContext<IContactsTabContext>({
  isModifiedMode: false,
  contacts: null,
  primaryContact: undefined,
  createContact: null,
  updateContactField: null,
  deleteContactById: null
})
