import { CreateNotificationDTO, NotificationDTO } from '../../../api/origin/communication-hub-service'

export type TestNotificationProps = {
  isSimpleType: boolean
  isAcknowledgementType: boolean
  isPullType: boolean
  isResignDocumnetType: boolean
  showFiledMapping: {
    subject: boolean
    richText: boolean
    options: boolean
    notifyDriverStatus: boolean
    document: boolean
  }
}

export const testNotification = (notification: NotificationDTO | CreateNotificationDTO): TestNotificationProps => {
  const isSimpleType = notification.type === NotificationDTO.TypeEnum.SIMPLE
  const isAcknowledgementType = notification.type === NotificationDTO.TypeEnum.ACKNOWLEDGMENT
  const isPullType = notification.type === NotificationDTO.TypeEnum.POLL
  const isResignDocumnetType = notification.type === NotificationDTO.TypeEnum.RESIGNDOCUMENT

  const showFiledMapping = {
    subject: isSimpleType || isAcknowledgementType || isPullType,
    richText: true,
    options: isPullType,
    notifyDriverStatus: true, // all
    document: isResignDocumnetType
  }

  return {
    isSimpleType,
    isAcknowledgementType,
    isPullType,
    isResignDocumnetType,
    showFiledMapping
  }
}
