import * as React from 'react'
import styled from 'styled-components'
import { TableHeader } from './TableHeader'
import { TableProvider } from '../../../providers/TableProvider'
import { ITableColumnSetting, ITableRowActionButton } from '../../../contexts/TableContext'
import { TableList } from './TableList'

type Props = {
  settings: ITableColumnSetting[]
  list: any[]
  styleContainer?: React.CSSProperties
  styleHeader?: React.CSSProperties
  styleList?: React.CSSProperties
  styleRow?: React.CSSProperties
  styleButtonAdd?: React.CSSProperties
  styleButtonRemove?: React.CSSProperties | ((rowItem: any) => React.CSSProperties)
  getRowClassName?: (rowItem: any) => string
  maxHeight?: number
  onButtonAddClick?: () => void
  rowActionButton?: (rowItem: any) => ITableRowActionButton
  listRef?: React.MutableRefObject<any>
}

export const Table = (props: Props) => {
  const {
    listRef,
    maxHeight,
    settings,
    list,
    styleContainer,
    styleHeader,
    styleList,
    styleRow,
    styleButtonAdd,
    styleButtonRemove,
    getRowClassName,
    onButtonAddClick,
    rowActionButton
  } = props

  return (
    <TableProvider
      settings={settings}
      list={list}
      styleContainer={styleContainer}
      styleHeader={styleHeader}
      styleList={styleList}
      styleRow={styleRow}
      styleButtonAdd={styleButtonAdd}
      styleButtonRemove={styleButtonRemove}
      getRowClassName={getRowClassName}
      onButtonAddClick={onButtonAddClick}
      rowActionButton={rowActionButton}
      listRef={listRef}
    >
      <Container className={'table'} style={{ maxHeight, ...(styleContainer || {}) }}>
        <TableHeader />
        <TableList />
      </Container>
    </TableProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
