import * as React from 'react'
import cn from 'classnames'
import { Column } from '../../../../../../UI/GridSystem'
import { activityStageColor } from '../../Activities/Table/styles'
import { StyledStatusBar, Titles, Lines, Icon } from './styles'
import { ProgressBarState } from '../../../../../../../api/api'
import { useGridItemDDO } from '../../../../../../../hooks/useGridItemDDO'

const status = {
  [ProgressBarState.ValueEnum.DASHED]: 'active',
  [ProgressBarState.ValueEnum.EMPTY]: '',
  [ProgressBarState.ValueEnum.FULL]: 'finished',
  [ProgressBarState.ValueEnum.HALFFULL]: 'half'
}

const makePickupStatus = ({ stage, value }: ProgressBarState) => {
  if (stage === ProgressBarState.StageEnum.PICKUP) {
    return status[value]
  }
  return status[ProgressBarState.ValueEnum.FULL]
}

const makePickupDelivery = ({ stage, value }: ProgressBarState) => {
  if (stage === ProgressBarState.StageEnum.DELIVERY) {
    return status[value]
  }
  if (stage === ProgressBarState.StageEnum.RETURN) {
    return status[ProgressBarState.ValueEnum.FULL]
  }
  return status[ProgressBarState.ValueEnum.EMPTY]
}

const makeReturnStatus = ({ stage, value }: ProgressBarState) => {
  if (stage === ProgressBarState.StageEnum.RETURN) {
    return status[value]
  }
  return status[ProgressBarState.ValueEnum.EMPTY]
}

export const StatusBar = ({ progressBarState }: { progressBarState: ProgressBarState }) => {
  const { isImport, isExport, isRepo } = useGridItemDDO()
  const pickupStatus = makePickupStatus(progressBarState)
  const deliveryStatus = makePickupDelivery(progressBarState)
  const returnStatus = makeReturnStatus(progressBarState)

  return (
    <StyledStatusBar rows={11} isGrid={true}>
      <Titles rows={5}>
        <Column className={'required'} style={{ color: activityStageColor.PICKUP }}>
          Pickup
        </Column>
        <Column
          className={cn({ required: isImport || isExport })}
          style={{ color: activityStageColor.DELIVERY }}
          padding={{ left: 10 }}
        >
          Delivery
        </Column>
        <Column className={'required'} style={{ color: activityStageColor.RETURN }} padding={{ left: 10 }}>
          Return
        </Column>
      </Titles>
      <Lines rows={6} padding={{ top: 10, bottom: 10 }}>
        <Column className={pickupStatus}>
          <Icon className={'mdi'} background={activityStageColor.PICKUP} />
        </Column>
        <Column className={cn(deliveryStatus, { disabled: isRepo })} margin={{ left: 5, right: 5 }}>
          <Icon className={'mdi'} background={activityStageColor.DELIVERY} />
        </Column>
        <Column className={returnStatus}>
          <Icon className={'mdi'} background={activityStageColor.RETURN} />
        </Column>
      </Lines>
    </StyledStatusBar>
  )
}
