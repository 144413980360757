import * as React from 'react'
import { useNotificationFilters } from '../../../../hooks/useNotificationFilters'
import styled from 'styled-components'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { useModalWindow } from '../../../../hooks/useModalWindow'
import { Select } from '../../../UI/DataFields/Select'
import { NotificationDTO } from '../../../../api/origin/communication-hub-service'
import { notificationDirectory } from '../../../../services/DTO/notification/directory'
import { NotificationFilter } from '../../../../store/reducers/communicationHub/interfaces'
import { SelecterWidget } from '../../../UI/DataFields/Select/SelecterWidget'
import { SelectDriverStatatus } from './Notification/SelectDriverStatatus'
import { DateRange } from '../DateRange'

type Props = {}

export const NotificationsViewFiltersPopup = (props: Props) => {
  const { closeModalWindow } = useModalWindow()
  const { filters, drivers, operators, usersMapping, updateFilter, saveFilters } = useNotificationFilters({})
  const notificationType = filters[NotificationFilter.type]
  const driverStatuses = filters[NotificationFilter.driverStatuses]
  const senderUserIds = filters[NotificationFilter.senderUserIds]
  const recipientUserIds = filters[NotificationFilter.recipientUserIds]
  const startDate = filters[NotificationFilter.startDate]
  const endDate = filters[NotificationFilter.endDate]

  return (
    <Container>
      <Select
        title={'Notification Type'}
        containerStyle={{ marginBottom: 24 }}
        label={notificationType ? undefined : 'All Types'}
        list={[{ label: 'All Types', value: undefined }].concat(
          [
            NotificationDTO.TypeEnum.SIMPLE,
            NotificationDTO.TypeEnum.ACKNOWLEDGMENT,
            NotificationDTO.TypeEnum.POLL,
            NotificationDTO.TypeEnum.RESIGNDOCUMENT
          ].map(value => ({ label: notificationDirectory.type[value], value }))
        )}
        selectedValue={notificationType}
        onSelect={value => updateFilter(NotificationFilter.type, value)}
      />

      <SelectDriverStatatus
        title={'Recipients'}
        containerStyle={{ marginBottom: 24 }}
        // @ts-ignore
        selected={driverStatuses}
        onChange={value => updateFilter(NotificationFilter.driverStatuses, value)}
      />

      <SelectUserContainer>
        <SelecterWidget
          title={'Sender'}
          selectedItems={(senderUserIds || [])
            .map(id => usersMapping[id])
            .filter(Boolean)
            .map(user => ({ label: user.name, value: user.id }))}
          staticList={[]}
          getAllItems={() => Promise.resolve(operators)}
          onSelect={value => {
            if (Array.isArray(value)) {
              return updateFilter(
                NotificationFilter.senderUserIds,
                (value as { label: string; value: string }[]).map(item => item.value)
              )
            }

            updateFilter(NotificationFilter.senderUserIds, undefined)
          }}
        />
        <SelecterWidget
          title={'Driver'}
          selectedItems={(recipientUserIds || [])
            .map(id => usersMapping[id])
            .filter(Boolean)
            .map(user => ({ label: user.name, value: user.id }))}
          staticList={[]}
          getAllItems={() => Promise.resolve(drivers)}
          onSelect={value => {
            if (Array.isArray(value)) {
              return updateFilter(
                NotificationFilter.recipientUserIds,
                (value as { label: string; value: string }[]).map(item => item.value)
              )
            }

            updateFilter(NotificationFilter.recipientUserIds, undefined)
          }}
        />
      </SelectUserContainer>
      <DateRange
        title={'Creation Date or Period'}
        dateFrom={startDate}
        dateTo={endDate}
        onChangeDateFrom={date => updateFilter(NotificationFilter.startDate, date)}
        onChangeDateTo={date => updateFilter(NotificationFilter.endDate, date)}
      />
      <Actions>
        <ActionButton round={true} onClick={closeModalWindow}>
          Cancel
        </ActionButton>
        <ActionButton
          round={true}
          filled={true}
          disabled={false}
          onClick={() => {
            closeModalWindow()
            saveFilters()
          }}
        >
          Apply
        </ActionButton>
      </Actions>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`

const SelectUserContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  margin-bottom: 24px;
`
