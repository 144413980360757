import * as React from 'react'
import { GridItemDriverContext } from '../contexts/GridItemDriverContext'
import { TListsState } from '../store/reducers/lists/interfaces'
import { useDriver } from '../hooks/useDriver'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemDriverProvider = React.memo((props: Props) => {
  const { id, modifiedLists, children } = props
  const driver = useDriver({ id, modifiedLists })

  return <GridItemDriverContext.Provider value={{ driver, isExpanded: Boolean(modifiedLists) }} children={children} />
})
