import * as React from 'react'

export enum RowActionButtonType {
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS'
}

export interface ITableRowActionButton {
  type: RowActionButtonType
  disabled?: boolean
  onClick?: () => void
  options?: ITableRowActionOption[]
}

export interface ITableRowActionOption {
  label: string | JSX.Element
  onClick: () => void
  disabled?: boolean
  hidden?: boolean
}

export interface ITableColumnSetting {
  label: string
  width: number | string
  getValue: (item: any) => number | string | JSX.Element | null | undefined
  isRequired?: boolean
  maxWidth?: number | string
}

type TTableRowItem = any

export interface ITableRowItem extends TTableRowItem {
  id: string
}

export interface ITableContext {
  settings: ITableColumnSetting[]
  listRef: React.MutableRefObject<any>
  list: any[]
  styleContainer: React.CSSProperties
  styleHeader: React.CSSProperties
  styleList: React.CSSProperties
  styleRow: React.CSSProperties
  styleButtonAdd: React.CSSProperties
  styleButtonRemove: React.CSSProperties | ((rowItem: any) => React.CSSProperties)
  CSSRuleGridTemplateColumns: string
  onButtonAddClick: () => void
  getRowClassName: (rowItem: any) => string
  rowActionButton: (rowItem: any) => ITableRowActionButton
}

export const TableContext = React.createContext<ITableContext>({
  settings: undefined,
  list: undefined,
  styleContainer: undefined,
  styleHeader: undefined,
  styleList: undefined,
  styleRow: undefined,
  styleButtonAdd: undefined,
  styleButtonRemove: undefined,
  CSSRuleGridTemplateColumns: undefined,
  onButtonAddClick: undefined,
  rowActionButton: undefined,
  getRowClassName: undefined,
  listRef: undefined
})
