import styled from 'styled-components'
import { GridColumn, Container } from '../GridSystem'
import { StyledInput } from '../Input/styledComponents'

export const StyledWeightContainer = styled(GridColumn)`
  padding-right: 60px;
  i {
    position: absolute;
    bottom: 9px;
    right: 0;
    color: #5c6fff;
    cursor: pointer;
    &.not-active {
      cursor: not-allowed;
      color: #999;
    }
  }
`
export const StyledWeightUnit = styled(GridColumn)`
  z-index: 4;
  position: absolute;
  bottom: 0;
  right: 20px;
  flex: unset;
  user-select: none;
  cursor: pointer;
  overflow: hidden;

  & + ${Container} ${StyledInput} > * {
    padding-right: 40px;
  }

  div {
    position: relative;
    transition: all 0.35s;
    top: 0;

    span {
      display: block;
      height: 21px;
      line-height: 18px;
      font-size: 12px;
      text-align: center;
      color: #5c6fff;
      font-weight: 500;
      border-bottom: 1px dotted #5c6fff;
      padding-bottom: 3px;
      transition: all 0.35s;
      margin: 7px 0;

      &:last-child {
        margin-top: 14px;
      }
    }

    &.kg {
      span:last-child {
        opacity: 0;
        transform: scale(0.3);
      }
    }

    &.lb {
      top: -35px;
      span:first-child {
        opacity: 0;
        transform: scale(0.3);
      }
    }
  }
`
